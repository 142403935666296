import TOC from '@/components/views/form/TOC';
import Source from '@/components/views/form/Source';
import Emails from '@/components/views/form/EmailRules';
import EmailHistory from '@/components/views/EmailHistory';
import Rules from '@/components/views/form/Rules';
import SubmitRules from '@/components/views/form/SubmitRules';
import DisplayRules from '@/components/views/form/DisplayRules';
import RecordRules from '@/components/views/form/RecordRules';
import Inputs from '@/components/views/form/Inputs';
import InputProperties from '@/components/views/form/InputProperties';
import Design from '@/components/views/form/Design';
import Advanced from '@/components/views/form/Advanced';
import Settings from '@/components/views/form/Settings';
import AddPaymentView from '@/components/views/form/AddPaymentView';

export default [
  {
    path: ':pageKey/views/:viewKey/form',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/action',
    components: {
      toolbox: Source,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/rules',
    components: {
      toolbox: Rules,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/rules/submit',
    components: {
      toolbox: SubmitRules,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/rules/display',
    components: {
      toolbox: DisplayRules,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/rules/record',
    components: {
      toolbox: RecordRules,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/emails',
    components: {
      toolbox: Emails,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/emails/history',
    components: {
      toolbox: Emails,
      modal: EmailHistory,
    },
    props: {
      modal: {
        backLink: './',
      },
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/inputs',
    name: 'formInputAdd',
    components: {
      toolbox: Inputs,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/inputs/rows/:rowIndex/columns/:columnIndex/inputs/:inputIndex',
    name: 'formInputProperties',
    components: {
      toolbox: InputProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/design',
    components: {
      toolbox: Design,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/advanced',
    components: {
      toolbox: Advanced,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/form/settings/ecommerce',
    components: {
      toolbox: Settings,
      modal: AddPaymentView,
    },
    props: {
      modal: {
        backLink: './',
      },
    },
  },
];
