<template>
  <div class="inputGroup">
    <div class="level-left spacing gap-2">
      <div
        class="w-full mr-0"
        style="margin-right: 1rem;"
      >
        <label class="text-default text-sm font-medium mb-2 leading-4">Decimals</label>
        <div>
          <select
            v-model="markDecimal"
            class="w-full text-base py-2 pl-3 leading-5"
          >
            <option value="none">
              None
            </option>
            <option value="period">
              Period
            </option>
            <option value="comma">
              Comma
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="showDecimalPlaces"
        class="w-full"
      >
        <label class="text-default text-sm font-medium mb-2 leading-4">Decimal places</label>
        <div>
          <select
            v-model="precision"
            class="w-full text-base py-2 pl-3 leading-5"
          >
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>10</option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Rounding</label>
      <div class="input-labels spacing tw-input-labels">
        <label><input
          v-model="rounding"
          type="radio"
          value="none"
        >Default</label>
        <label><input
          v-model="rounding"
          type="radio"
          value="up"
        >Round Up</label>
        <label><input
          v-model="rounding"
          type="radio"
          value="down"
        >Round Down</label>
      </div>
    </div>
    <div class="inherit-width spacing">
      <label class="text-default text-sm font-medium mb-2 leading-4">Thousands delimiter</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="markThousands"
          type="radio"
          value="none"
        >None</label>
        <label><input
          v-model="markThousands"
          type="radio"
          value="comma"
        >Comma</label>
        <label><input
          v-model="markThousands"
          class="m-0"
          type="radio"
          value="period"
        >Period</label>
        <label><input
          v-model="markThousands"
          type="radio"
          value="space"
        >Space</label>
      </div>
    </div>
    <div class="inherit-width">
      <label class="text-default text-sm font-medium mb-2 leading-4">Number Format</label>
      <div class="w-full">
        <select
          v-model="fieldLocal.format.format"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="">
            None
          </option>
          <option value="$">
            $
          </option>
          <option value="£">
            £
          </option>
          <option value="€_after">
            € (after, 99 = 99 €)
          </option><option value="€">
            € (before, 99 = €99)
          </option>
          <option value="%">
            % (0.5 = 50%)
          </option>
          <option value="custom">
            Custom
          </option>
        </select>
      </div>
    </div>
    <div
      v-if="fieldLocal.format.format === 'custom'"
      class="level-left"
    >
      <div style="margin-right: 1em;">
        <label for="pre">
          Before
        </label>
        <input
          v-model="fieldLocal.format.pre"
          name="pre"
        >
      </div>
      <div>
        <label for="post">
          After
        </label>
        <input
          v-model="fieldLocal.format.post"
          name="post"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    format() {
      return this.fieldLocal.format || {};
    },
    showDecimalPlaces() {
      return this.markDecimal !== 'none';
    },
    precision: {
      get() {
        return this.format.precision || 0;
      },
      set(newValue) {
        this.fieldLocal.format.precision = newValue;
      },
    },
    rounding: {
      get() {
        if (!this.format.rounding) {
          return 'none';
        }

        if (this.format.rounding === 'default') {
          return 'none';
        }

        return this.format.rounding;
      },
      set(newVal) {
        this.fieldLocal.format.rounding = newVal;
      },
    },
    markThousands: {
      get() {
        if (typeof this.format.mark_thousands === 'undefined' && typeof this.format.show_commas !== 'undefined') {
          return this.format.show_commas ? 'comma' : 'none';
        }

        return this.format.mark_thousands;
      },
      set(newVal) {
        this.fieldLocal.format.mark_thousands = newVal;
      },
    },
    markDecimal: {
      get() {
        if (typeof this.format.mark_decimal === 'undefined') {
          return 'period';
        }

        return this.format.mark_decimal;
      },
      set(newVal) {
        this.fieldLocal.format.mark_decimal = newVal;
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.spacing {
  margin-bottom: 1.5rem;
}

</style>
