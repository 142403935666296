<template>
  <Numeric :field-local="fieldLocal" />
</template>

<script>
import Numeric from '@/components/fields/settings/shared/Numeric';

export default {
  name: 'Number',
  components: {
    Numeric,
  },
  props: {
    fieldLocal: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
