<template>
  <div
    class="field-types-list"
    :class="{'show-active-state': showActiveState}"
  >
    <template
      v-for="(types, group) in fieldTypes"
      :key="group"
    >
      <Toggle>
        <template #title>
          <span class="field-type-group text-emphasis">{{ group }}</span>
        </template>
        <template #content>
          <div class="field-group-types">
            <template
              v-for="fieldType in types"
              :key="fieldType.name"
            >
              <div
                v-if="object.canAddFieldType(fieldType)"
                class="field-type bg-subtle p-2 rounded text-default border-none hover:border-none hover:shadow-none"
                :class="{'is-active': fieldType.type === activeType}"
                :data-type="fieldType.type"
                @click="onClick(fieldType)"
              >
                <TypeIcon
                  :field="{type: fieldType.type}"
                  :title="true"
                  icon-classes="text-subtle"
                  field-title-classes="text-default"
                />
              </div>
            </template>
          </div>
        </template>
      </Toggle>
    </template>
  </div>
</template>

<script>
import TypeIcon from '@/components/fields/TypeIcon';
import Toggle from '@/components/ui/Toggle';
import FieldUtils from '@/components/fields/FieldUtils';

export default {
  components: {
    TypeIcon,
    Toggle,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    showActiveState: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  data() {
    return {
      activeType: null,
    };
  },
  computed: {
    fieldTypes() {
      return window.Knack.getFieldTypes();
    },
  },
  methods: {
    onClick(field) {
      this.activeType = field.type;

      this.$emit('click', field.type);
    },
  },
};
</script>

<style lang="scss">
.field-types-list {
  font-size: 1em;

  .toggle-trigger {
    text-transform: capitalize;
  }

  .field-type {
    background-color: $gray50;
    border-radius: .1875rem;
    border: 1px solid $gray50;
    padding: .4375rem .5rem;
    margin-bottom: .25rem;
    position: relative;
    transition: all 150ms ease-out;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 1.5em;
    }

    &:hover {
      border: 1px solid $gray100;
    }

    &:hover .field-icon svg, &:hover .field-title {
      color: $primary600;
    }

    .field-title {
      margin-left: .5rem;
      color: $gray600;
      line-height: 1;

    }

  }

  &.show-active-state {
    .field-type.is-active {
      font-weight: 500;
      background-color: $gray100;
      border: 1px solid $gray100;

      svg, .field-title {
        color: $fuchsia-text;
      }
    }
  }
}
</style>
