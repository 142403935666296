<template>
  <div
    class="wizard-section transition p-0 m-0"
    :class="{'visible': wizardStep >= step, 'active': step === wizardStep}"
  >
    <div
      ref="content"
      class="wizard-active transition p-0"
    >
      <div class="wizard-intro level-left mb-0">
        <slot name="intro" />
      </div>
      <slot />
    </div>

    <div v-if="step !== wizardStep">
      <transition name="fade">
        <div
          class="wizard-edit border-0 hover:text-default p-0 items-baseline"
          @click="$emit('update:wizardStep', step)"
        >
          <div class="content">
            <slot name="edit" />
          </div>
          <div class="edit opacity-100">
            <a class="underline text-default text-sm font-medium">
              Change
            </a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import UIUtil from '@/util/UI';

export default {
  props: {
    step: {
      type: Number,
      required: true,
    },
    wizardStep: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:wizardStep'],
  data() {
    return {
      toggle: false,
    };
  },
  watch: {
    wizardStep(newStep, oldStep) {
      if (!this.$refs.content) {
        return;
      }

      if (!this.toggle) {
        this.toggle = new UIUtil.SlideToggle(this.$refs.content, {
          isOpen: oldStep === this.step,
        });
      }

      if (oldStep === this.step) {
        this.toggle.toggle(false);
      }

      if (newStep === this.step) {
        log(`${this.step} is toggling on`);

        this.$nextTick(() => {
          const timeout = (oldStep > newStep) ? 0 : 400;

          setTimeout(() => {
            this.toggle.toggle(true);
          }, timeout);
        });
      }
    },
  },
};
</script>

<style lang="scss">
.wizard-section {
  max-height: 0px;
  overflow: hidden;

  &.visible {
    max-height: inherit;
    overflow: visible;
  }

  &.active {
    padding-top: 1em;
  }

  &.first {
    padding-top: 0;
  }

  > p {
    margin-bottom: 1em;
  }

  .button.save {
    margin: .5em 0 1.5em;
  }

  .submit-buttons {
    margin: .5em 0 1em !important;
    padding: 0 !important;
  }

  .number {
    width: 18px;
    min-width: 18px;
    height: 18px;
    @include font-caption;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray100;
    border-radius: 33rem;
    padding: 0;
    margin-right: .5rem;
    color: $gray600;
  }

  .wizard-intro, .intro {
    margin-bottom: 1.5rem;
    @include font-body;
    font-weight: 500;
  }

  .wizard-active {
    padding: 0 1.75rem;

    .number {
      background-color: $primary500;
      color: $white100;
      margin-left: -1.75rem;
    }
  }
}

.toolbox-body {

  .wizard-section .wizard-active {
    padding: 0 1rem;
  }
  .wizard-intro, .intro {
    margin-bottom: 1rem;
  }
  .number {
    margin-left: 0 !important;
  }
}

.wizard-edit {
  border-bottom: 1px solid $gray100;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  @include font-body;

  &:hover {
    color: $primary500;

    .number {
      background-color: $primary100;
      color: $primary500;
    }
  }

  .content {
    flex-grow: 1;
  }

  .edit {
    color: $primary500;
    opacity: .5;
  }

  &:hover .edit {
    opacity: 1;
    color: $fuchsia;
    text-decoration: none;
  }
}
</style>
