<template>
  <form class="body-wrapper m-0 px-6 py-2 max-w-xl">
    <div>
      <label
        for="region"
        :class="labelClasses"
      >Data Location</label>
      <span
        id="region"
        :class="valueClasses"
        name="region"
        v-html="region"
      />
    </div>
    <div>
      <label
        for="hipaa"
        :class="labelClasses"
      >HIPAA Compliant (US Only)</label>
      <span
        id="hipaa"
        :class="valueClasses"
        name="hipaa"
        v-html="hipaa"
      />
    </div>
    <div>
      <label
        for="info_agreements"
        :class="labelClasses"
      >Agreements</label>
      <span
        id="info_agreements"
        :class="valueClasses"
        name="info_agreements"
        v-html="agreements"
      />
    </div>
    <div>
      <label
        for="info_records"
        :class="labelClasses"
      >Total Records</label>
      <span
        id="info_records"
        :class="valueClasses"
        name="info_records"
      >{{ totalRecords }}</span>
    </div>
    <div>
      <label
        for="info_storage"
        :class="labelClasses"
      >Total Storage</label>
      <span
        id="info_storage"
        :class="valueClasses"
        name="info_storage"
      >{{ totalStorage }}</span>
    </div>
    <div>
      <label
        for="info_builder_version"
        :class="labelClasses"
      >Builder Version</label>
      <span
        id="info_builder_version"
        :class="valueClasses"
        name="info_builder_version"
      >{{ currentSha }}</span>
      <span
        id="info_builder_timestamp"
        :class="valueClasses"
        name="info_builder_timestamp"
      >{{ currentShaDateString }}</span>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import hasIn from 'lodash/hasIn';

export default {
  name: 'Info',
  computed: {
    ...mapGetters([
      'app',
    ]),
    agreements() {
      let agreement = 'EU Data Processing Agreement & Sub-Processor List: <br />';

      agreement += (hasIn(this.app.get('account'), 'settings.agreements.dpa_gdpr')) ? '<strong>Signed</strong><br />' : 'None on file';

      agreement += ' - <a href="https://s3.amazonaws.com/public_site/downloads/knack-eu-dpa.pdf" class="text-default font-medium underline">View & Sign</a><br />';

      agreement += '<small>We will update this document if we add or change any sub-processors in the future, so please check back from time to time for the most recent list of sub-processors.</small>';

      agreement += (hasIn(this.app.get('account'), 'settings.hipaa.enabled') && this.app.get('account').settings.hipaa.enabled) ? 'Business Associate Agreement: <strong>Signed</strong><br />' : '';

      return agreement;
    },
    hipaa() {
      return (hasIn(this.app.get('account'), 'settings.hipaa.enabled') && this.app.get('account').settings.hipaa.enabled) ? 'Yes' : 'No - <a href="https://www.knack.com/hipaa" class="text-default font-medium underline" target="_blank">Become Compliant</a>';
    },
    region() {
      switch (this.app.settings.cluster) {
        case 'eu-central':

          return 'EU - Frankfurt';
        case 'us-govcloud':

          return 'US - California (GovCloud)';
        case 'ap-southeast-2':

          return 'AUS - Sydney';
        case 'custom':

          return 'Private Server';
        default:

          return 'US - Virginia';
      }
    },
    totalRecords() {
      return this.app.get('counts').total_entries;
    },
    totalStorage() {
      return window.Knack.formatNumberForStorage(this.app.get('counts').asset_size);
    },
    currentSha() {
      return process.env.VUE_APP_GIT_SHA;
    },
    currentShaDateString() {
      const date = new Date(process.env.VUE_APP_GIT_TIMESTAMP * 1000);

      return date.toISOString();
    },
    labelClasses() {
      return 'text-sm text-default font-medium mb-2';
    },
    valueClasses() {
      return 'block text-base font-normal';
    },
  },
};
</script>

<style lang="scss">
span {
&#region, &#hipaa, &#info_agreements, &#info_records, &#info_storage {
  @include font-body;
}

}
</style>
