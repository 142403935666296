import hasIn from 'lodash/hasIn';
import store from '@/store/index';

// Operator collections to build other collections
const isOperators = [
  'is',
  'is not',
];

const blankOperators = [
  'is blank',
  'is not blank',
];

const containsOperators = [
  'contains',
  'does not contain',
];

const stringOperators = [
  'starts with',
  'ends with',
];

const collectionOperators = [
  'is any',
];

const numberComparisonOperators = [
  'higher than',
  'lower than',
];

const timeComparisonOperators = [
  'is before',
  'is after',
  'is before current time',
  'is after current time',
];

const characterCountOperators = [
  'character count is not',
  'character count is',
  'character count is higher than',
  'character count is lower than',
];

const regexOperators = [
  'matches regular expression',
  'does not match regular expression',
];

const domainOperators = [
  'domain must equal',
  'domain must not equal',
];

const hasChangedOperators = [
  'has changed',
];

const hasChangedSpecificOperators = [
  'has changed to',
  'has changed from',
];

// Operator collections for specific field types
const defaultOperators = ({ includeChangeOperators }) => {
  const operators = [
    ...containsOperators,
    ...isOperators,
    ...stringOperators,
    ...blankOperators,
  ];

  if (includeChangeOperators) {
    operators.push(
      ...hasChangedOperators,
      ...hasChangedSpecificOperators,
    );
  }

  return operators;
};

const timeOperators = ({ includeChangeOperators }) => {
  const operators = [
    ...isOperators,
    ...timeComparisonOperators,
    ...blankOperators,
  ];

  if (includeChangeOperators) {
    operators.push(
      ...hasChangedOperators,
      ...hasChangedSpecificOperators,
    );
  }

  return operators;
};

const dateTimeOperators = ({ includeValidationOperators, includeChangeOperators }) => {
  const operators = [
    ...isOperators,
    ...timeComparisonOperators,
    'is during the current',
    'is during the previous',
    'is during the next',
    'is before the previous',
    'is after the next',
    'is today',
    'is today or before',
    'is today or after',
    'is before today',
    'is after today',
    ...blankOperators,
  ];

  if (includeChangeOperators) {
    operators.push(
      ...hasChangedOperators,
      ...hasChangedSpecificOperators,
    );
  }

  if (includeValidationOperators) {
    operators.push(
      'is a day of the week',
      'is between days of the week',
      'is between dates',
      'is a future date',
      'is not a future date',
    );
  }

  return operators;
};

const passwordOperators = ({ includeValidationOperators, includeChangeOperators }) => {
  const operators = [
    ...containsOperators,
    ...stringOperators,
  ];

  if (includeChangeOperators) {
    operators.push(
      ...hasChangedOperators,
      ...hasChangedSpecificOperators,
    );
  }

  if (includeValidationOperators) {
    operators.push(
      ...characterCountOperators,
      ...regexOperators,
    );
  }

  return operators;
};

const booleanOperators = ({ includeChangeOperators }) => {
  const operators = [
    ...isOperators,
    ...blankOperators,
  ];

  if (includeChangeOperators) {
    operators.push(
      ...hasChangedOperators,
      ...hasChangedSpecificOperators,
    );
  }

  return operators;
};

const multipleChoiceOperators = ({ includeChangeOperators }) => {
  const operators = [
    ...isOperators,
    ...containsOperators,
    ...collectionOperators,
    ...blankOperators,
  ];

  if (includeChangeOperators) {
    operators.push(
      ...hasChangedOperators,
      ...hasChangedSpecificOperators,
    );
  }

  return operators;
};

const fileOperators = ({ includeValidationOperators, includeChangeOperators }) => {
  const operators = [
    ...blankOperators,
  ];

  if (includeChangeOperators) {
    operators.push(
      ...hasChangedOperators,
    );
  }

  if (includeValidationOperators) {
    operators.push(
      'size is less than',
      'size is greater than',
      'file type is',
      'file type is not',
    );
  }

  return operators;
};

const signatureOperators = ({ includeChangeOperators }) => {
  const operators = [
    ...blankOperators,
  ];

  if (includeChangeOperators) {
    operators.push(
      ...hasChangedOperators,
    );
  }

  return operators;
};

const numberOperators = ({ includeValidationOperators, includeChangeOperators }) => {
  const operators = [
    ...isOperators,
    ...numberComparisonOperators,
    ...blankOperators,
  ];

  if (includeChangeOperators) {
    operators.push(
      ...hasChangedOperators,
      ...hasChangedSpecificOperators,
    );
  }

  if (includeValidationOperators) {
    operators.push(
      'is one of',
      'is not one of',
    );
  }

  return operators;
};

const emailOperators = (options) => {
  const operators = defaultOperators(options);

  if (options.includeValidationOperators) {
    operators.push(...domainOperators);
  }

  return operators;
};

const shortTextOperators = (options) => {
  const operators = defaultOperators(options);

  if (options.includeValidationOperators) {
    operators.push(
      ...characterCountOperators,
      ...regexOperators,
    );
  }

  return operators;
};

// rich text and paragraphs
const bigTextOperators = (options) => {
  const operators = defaultOperators(options);

  if (options.includeValidationOperators) {
    operators.push(
      ...characterCountOperators,
    );
  }

  return operators;
};

const addressOperators = (options) => {
  const operators = defaultOperators(options);

  // Address fields need to check if near searches via zipcode can be added to filters
  if (store.getters.app.hasFeatureFlagGeo()) {
    operators.push({
      value: 'near',
      label: 'is near (a zip code)',
    });
  }

  return operators;
};

const hideValueOperators = new Set([
  'is blank',
  'is not blank',
  'is any',
  'is today',
  'is before today',
  'is after today',
  'is before current time',
  'is after current time',
  'is today or before',
  'is today or after',
  'user',
  'is a future date',
  'is not a future date',
  'has changed',
]);

export default {
  hideValueBasedOnOperator(operator) {
    return hideValueOperators.has(operator);
  },
  getOperators(field, options = {
    includeValidationOperators: false,
    includeChangeOperators: true,
  }) {
    const { Knack } = window;

    let fieldType = field.type;

    // check for equations that store dates
    if (fieldType === Knack.config.EQUATION && field.storesDateValues()) {
      fieldType = Knack.config.DATE_TIME;
    }

    // first check for dates
    if (fieldType === Knack.config.DATE_TIME) {
      // for date_times that are ignoring date and just storing times
      const timeOnly = field.isTimeOnly();

      if (timeOnly) {
        return timeOperators(options);
      }

      return dateTimeOperators(options);
    }

    // set operator methods by type
    // these values should all be function references that take in our options
    const operators = {
      [Knack.config.PASSWORD]: passwordOperators,
      [Knack.config.BOOLEAN]: booleanOperators,
      [Knack.config.MULTIPLE_CHOICE]: multipleChoiceOperators,
      [Knack.config.USER_ROLES]: multipleChoiceOperators,
      [Knack.config.CONNECTION]: multipleChoiceOperators,
      [Knack.config.FILE]: fileOperators,
      [Knack.config.IMAGE]: fileOperators,
      [Knack.config.SIGNATURE]: signatureOperators,
      [Knack.config.LINK]: booleanOperators,
      [Knack.config.NUMBER]: numberOperators,
      [Knack.config.CURRENCY]: numberOperators,
      [Knack.config.RATING]: numberOperators,
      [Knack.config.TIMER]: numberOperators,
      [Knack.config.SUM]: numberOperators,
      [Knack.config.MIN]: numberOperators,
      [Knack.config.MAX]: numberOperators,
      [Knack.config.AVERAGE]: numberOperators,
      [Knack.config.EQUATION]: numberOperators,
      [Knack.config.COUNT]: numberOperators,
      [Knack.config.AUTO_INCREMENT]: numberOperators,
      [Knack.config.EMAIL]: emailOperators,
      [Knack.config.SHORT_TEXT]: shortTextOperators,
      [Knack.config.PARAGRAPH_TEXT]: bigTextOperators,
      [Knack.config.RICH_TEXT]: bigTextOperators,
      [Knack.config.ADDRESS]: addressOperators,
    };

    const fieldOperators = hasIn(operators, fieldType) ? operators[fieldType](options) : defaultOperators(options);

    return fieldOperators;
  },
  getFirstOperator(field) {
    return this.getOperators(field)[0];
  },
};
