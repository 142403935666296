import { h } from 'vue';

const LinkRender = (props) => h(
  'a', {
    attrs: {
      to: 'detail',
    },
    innerHTML: props.displayValue,
  },
);

LinkRender.props = {
  displayValue: {
    type: null,
    required: true,
  },
};

export default LinkRender;
