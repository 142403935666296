<template>
  <div class="error-banner">
    <Icon
      type="warning"
      class="error-banner-icon"
    />
    <slot />
  </div>
</template>

<script>

import Icon from '@/components/ui/Icon';

export default {
  name: 'ErrorBanner',
  components: {
    Icon,
  },
};
</script>

<style scoped>
.error-banner {
  display: flex;
  width: 100%;
  gap: 12px;
  padding: 9px 16px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #EE93A0;
  background-color: #FDF4F5;
  color: #222B33;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
}
.error-banner-icon {
  color: #DD2740;
  width: 18px;
  height: 18px;
}

</style>
