<template>
  <ViewToolbox
    back-title="Form Actions & Rules"
    title="Display Rules"
  >
    <p
      class="text-sm leading-5 mb-0"
      style="margin-bottom: 1em"
    >
      Add rules to show and hide inputs based on form values.
    </p>

    <Notification
      v-if="shouldShowCorruptedRuleWarning"
      class="text-warning-emphasis"
      icon="exclamation-triangle"
    >
      <p class="text-base text-warning-emphasis pt-2">
        One of your rules below contained fields that were deleted or no longer apply to this page.
      </p>
      <p class="text-base text-warning-emphasis">
        Please inspect your rules carefully and correct any rules that show blank fields, or reach out
        to support if you believe this is an error.
      </p>
    </Notification>

    <GroupList
      v-model:items="listItems"
      class="display-rules small mt-4"
      group-list-item-classes="bg-subtle"
      item-title="Display Rule"
    >
      <template #default="{item}">
        <!-- WHEN CRITERIA -->
        <div
          class="margin-bottom-half"
          data-testid="display-rules-if"
        >
          <p class="mb-2">
            <strong>If</strong> the following rules match
          </p>
          <FieldList
            :object-key="object.key"
            :items="item.criteria"
            :available-fields="availableFields"
            :rule-type="RuleType.Display"
            class="is-grouped is-contained bg-transparent p-0"
            @update:items="onUpdateCriteria(item, $event)"
          />
        </div>

        <div data-testid="display-rules-then">
          <label class="tw-toolbox-label"><strong>Then</strong> do the following</label>

          <ActionList
            ref="list"
            v-model:items="item.actions"
            :default-item="defaultActionItem"
            :can-be-empty="false"
            scope-name="actionItem"
            class="naked display-rule-actions"
          >
            <template #default="{actionItem}">
              <div class="form-display-rule w-full">
                <select
                  v-model="actionItem.action"
                  class="text-base py-2 pl-3 leading-5 truncate"
                  data-testid="display-rules-then-action"
                >
                  <option value="show-hide">
                    Show/Hide
                  </option>
                  <option value="show">
                    Show
                  </option>
                  <option value="hide-show">
                    Hide/Show
                  </option>
                  <option value="hide">
                    Hide
                  </option>
                  <option value="label">
                    Rename label
                  </option>
                </select>

                <span
                  v-if="actionItem.action === 'label'"
                  class="self-center"
                >
                  of&nbsp;
                </span>

                <select
                  v-model="actionItem.field"
                  class="text-base py-2 pl-3 leading-5 truncate"
                  data-testid="display-rules-then-target"
                >
                  <option
                    v-for="option in inputOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>

                <span
                  v-if="actionItem.action === 'label'"
                  class="self-center"
                >
                  to&nbsp;
                </span>
                <input
                  v-if="actionItem.action === 'label'"
                  v-model="actionItem.value"
                  type="text"
                >
              </div>
            </template>
          </ActionList>
        </div>
      </template>
    </GroupList>
    <div>
      <a
        class="button small green-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base"
        data-testid="display-rules-add-rule-button"
        @click="onAddListItem"
      >
        <Icon
          class="text-subtle h-4 w-4 group-hover:text-brand-400 mr-2 fill-current"
          type="plus-thin"
        /> Add Rule
      </a>
    </div>
  </ViewToolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import GroupList from '@/components/ui/lists/GroupList';
import ActionList from '@/components/ui/lists/ActionList';
import FieldList from '@/components/ui/lists/FieldList';
import Icon from '@/components/ui/Icon';
import Notification from '@/components/ui/notifications/Notification';
import { RuleType } from '@/constants/rules';

export default {
  name: 'FormDisplayRules',
  components: {
    ViewToolbox,
    GroupList,
    ActionList,
    FieldList,
    Icon,
    Notification,
  },
  mixins: [
    ViewUtils,
  ],
  data() {
    return {
      RuleType,
    };
  },
  computed: {
    listItems: {

      get() {
        return this.viewRaw.rules.fields;
      },

      set(newVal) {
        this.viewRaw.rules.fields = newVal;
      },

    },
    inputOptions() {
      return this.$store.getters.activeView.getInputFieldOptions();
    },
    hasUserRoles() {
      return this.object.get('type') === 'UserObject' && this.$store.getters.hasRoleObjects;
    },
    availableFields() {
      if (!this.view) {
        return [];
      }

      const ignoreTypes = [];

      // ignore the user roles field if no user roles exist
      if (!this.hasUserRoles) {
        ignoreTypes.push(window.Knack.config.USER_ROLES);
      }

      return this.view.getFields('inputs', { ignoreTypes })
        .map(({ key: fieldKey }) => {
          const { key: value, name: label } = this.$store.getters.getField(fieldKey);

          return {
            value,
            label,
          };
        });
    },
    shouldShowCorruptedRuleWarning() {
      // Do not render corrupted rule warning when there aren't any inputs on a form view.
      // Needed since under this condition, a display rule's criteria field input defaults to rendering all of the
      // source object's fields as options for the input. The criteria field dropdown input is not blank under this
      // condition, thus rendering the warning banner would not be appropriate since the input is not blank.
      if (!this.availableFields.length) {
        return false;
      }

      // Check if any fields exist in the criteria that may have come from a deleted field
      return this.listItems.some(
        (item) => item.criteria.some(
          (criteria) => !this.availableFields.some((field) => field.value === criteria.field),
        ),
      );
    },
  },
  methods: {
    defaultCriteria() {
      // Set default criteria values based on the first of the available fields.
      // Needed to prevent rendering criteria list field inputs without a selected input value because
      // it is possible for the first field in the source object to not be on a form.
      if (this.availableFields.length) {
        const fieldKey = this.availableFields[0].value;
        const field = this.$store.getters.getField(fieldKey);

        return field.getDefaultRule();
      }

      return this.object.fields[0].getDefaultRule();
    },
    defaultItem() {
      return {
        criteria: [
          this.defaultCriteria(),
        ],
        actions: [
          this.defaultActionItem(),
        ],
      };
    },
    defaultActionItem() {
      return {
        action: 'hide',
        field: this.inputOptions[0].value,
        value: '',
      };
    },
    onAddListItem(event) {
      log('addListItem!!!!!!!!!');

      if (!this.viewRaw.rules || !this.viewRaw.rules.fields) {
        this.addViewProperties({
          fields: [],
        }, 'rules');
      }

      return this.viewRaw.rules.fields.push(this.defaultItem());
    },

    onUpdateCriteria(item, newCriteria) {
      item.criteria = newCriteria;
    },
  },
};
</script>

<style lang="scss">
.form-display-rule {
  & > * {
    min-width: 0;
    flex-shrink: 1;
  }
}
</style>
