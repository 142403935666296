<template>
  <span
    class="ribbonLink_text"
    :class="[{ [ribbonLabelClass]: collapsible }, classes]"
  >
    {{ text }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('ui', [
      'ribbonLabelClass',
    ]),
  },
};
</script>
