<template>
  <div
    v-if="view.get('content')"
    class="content"
    v-html="view.get('content')"
  />

  <p v-else>
    <em>This Image view is empty. Edit this view to add content.</em>
  </p>
</template>

<script>

export default {
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>
