<template>
  <div>
    <div
      id="add-view-source-paths"
      class="source-object-options mt-0"
      style="margin-top: 1em;"
    >
      <p>
        <template v-if="pathsHaveOptionsForBothDirectAndConnected">
          <div class="tw-input-labels">
            <label>
              <input
                v-model="directConnectionType"
                data-cy="source-type-all"
                type="radio"
                value="all"
              /><span
                class="capitalize-firstLetter"
                v-html="directConnectionLabel"
              />
            </label>
          </div>
          <div class="tw-input-labels">
            <label style="display: flex;">
              <input
                v-model="directConnectionType"
                data-cy="source-type-connected"
                type="radio"
                value="connected"
              />
              <div style="margin-top: 4px;">
                <span
                  class="capitalize-firstLetter"
                  v-html="sourceObjectName"
                /> connected to
                <template v-if="connectedPathDropdownOptions.length > 1">
                  <span v-if="directConnectionType === `all`">...</span>
                  <template v-else>
                    <Dropdown
                      v-model="connectionPathKey"
                      v-tippy="{followCursor: `initial`}"
                      content="Click to change this connection."
                      data-cy="view-source-path-picker"
                      :options="connectedPathDropdownOptions"
                      class="is-inline"
                    />
                    <span
                      v-if="connectedParentPathsAreSame"
                      v-html="`&nbsp;${sameConnectedParentPathLabel}`"
                    />
                  </template>
                </template>
                <template v-else>
                  <span v-if="directConnectionType === `all`">...</span>
                  <template v-else>
                    <span v-html="`${connectedPathDropdownOptions[0].label}`" />
                    <span
                      v-if="connectedParentPathsAreSame"
                      v-html="`&nbsp;${sameConnectedParentPathLabel}`"
                    />
                  </template>
                </template>
              </div>
            </label>
          </div>
        </template>

        <template v-else-if="connectedPathDropdownOptions.length">
          <span v-html="sourceObjectName" /> connected to
          <span
            v-if="connectedPathDropdownOptions.length === 1"
            v-html="`${connectedPathDropdownOptions[0].label}`"
          />
          <Dropdown
            v-else
            v-model="connectionPathKey"
            v-tippy="{followCursor: `initial`}"
            content="Click to change this connection."
            data-cy="view-source-path-picker"
            :options="connectedPathDropdownOptions"
            class="is-inline"
          />
          <span
            v-if="connectedParentPathsAreSame"
            v-html="`&nbsp;${sameConnectedParentPathLabel}`"
          />
          <span v-else-if="connectedPathDropdownOptions.length === 1">.</span>
        </template>

        <template v-else>
          <span
            v-if="selectedPath?.user"
            v-html="loggedInObjectLabel"
          />
          <span
            v-else
            v-html="directConnectionLabel"
          />
        </template>
      </p>

      <div
        v-if="showConnectionDisambiguation"
        class="source-connections"
      >
        <div v-if="selectedPath?.connections.direct.length > 1">
          <p>
            Which connection will connect <span
              style="font-weight: 500;"
              v-html="selectedPath.labelParts.from"
            /> to <span v-html="selectedPath.labelParts.to" />?
          </p>

          <label
            v-for="connection in selectedPath.connections.direct"
            :key="connection.field.key"
            class="level-left"
          >
            <input
              v-model="directConnectionFieldKey"
              type="radio"
              :value="connection.field.key"
            >
            <div>
              The {{ connection.field.names.object }}'s <span><Icon
                type="database-lookup"
                style="margin-left: 2px;"
              />{{ connection.field.names.field }}</span> field
            </div>
          </label>
          <br>
        </div>
        <div v-if="selectedPath?.connections.parent.length > 1">
          <p>Which connection will connect <span v-html="selectedPath.labelParts.to" /> to <span v-html="selectedPath.labelParts.parent" />?</p>

          <label
            v-for="connection in selectedPath.connections.parent"
            :key="connection.field.key"
            class="level-left"
          >
            <input
              v-model="parentConnectionFieldKey"
              type="radio"
              :value="connection.field.key"
            >
            <div>
              The {{ connection.field.names.object }}'s <span><Icon
                type="database-lookup"
                style="margin-left: 2px;"
              />{{ connection.field.names.field }}</span> field
            </div>
          </label>
          <br>
        </div>
      </div>

      <div class="margin-bottom-double flex justify-end mb-0">
        <button
          type="button"
          data-cy="view-source-wizard-continue"
          class="button small orange-fill p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium h-10"
          @click="onSubmit"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import Dropdown from '@/components/ui/Dropdown';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
    Dropdown,
  },
  props: {
    page: {
      type: Object,
      default() {
        return {
          object: {},
        };
      },
    },
    source: {
      type: Object,
      default: () => ({}),
    },
    paths: {
      type: Object,
      default: () => ({}),
    },
    pathDefaults: {
      type: Object,
      default: () => ({}),
    },
    recordsQuantity: {
      type: String,
      default: 'all', // one | many | all
    },
    // add-view needs to account for forms: they can sometimes either insert or update records
    viewType: {
      type: String,
      default: null,
    },
  },
  emits: [
    'submit',
  ],
  data() {
    return {
      connectionPathKey: '', // paths are a set of object keys separated by :
      directConnectionFieldKey: '',
      parentConnectionFieldKey: '',
      directConnectionType: 'connected', // connected | all. When avalaible, controls if the path is showing all records, or using a connected path
    };
  },
  computed: {
    ...mapGetters([
      'getObject',
    ]),
    directConnectionLabel() {
      const path = this.paths[Object.keys(this.paths)[0]];
      if (!path) {
        return '';
      }

      const objectName = path.labelParts.from;

      // E.g. This page's X or The logged-in X
      if (objectName.includes('this') || objectName.includes('logged')) {
        return objectName;
      }

      if (this.viewType === 'form') {
        return `A new ${this.sourceObject.inflections.singular}`;
      }

      return `All ${this.sourceObject.inflections.plural}`;
    },
    inflection() {
      if (this.viewType === 'form') {
        return 'singular';
      }

      return this.recordsQuantity === 'one' ? 'singular' : 'plural';
    },
    sourceObject() {
      return this.$store.getters.getObject(this.source.object);
    },
    sourceObjectName() {
      const objectName = this.sourceObject.inflections[this.inflection];

      let article = 'The';

      if (this.viewType === 'form' && this.recordsQuantity === 'many') {
        article = 'A new';
      }

      return `${this.inflection === 'singular' && article ? `${article} ` : ''}${objectName}`;
    },
    // This is only required when confirming the logged-in user. This mean the path has no connection options
    loggedInObjectLabel() {
      return `The <strong>${this.sourceObject.inflections.singular}</strong> logged-in to this page.`;
    },
    connectedPathDropdownOptions() {
      let options = [];

      Object.keys(this.paths).forEach((key) => {
        // ignore direct paths
        if ((key.match(/:/g) || []).length === 0) {
          return;
        }

        const path = this.paths[key];

        let label = (path.labelParts) ? path.labelParts.to : options.label;

        if (path.labelParts && path.labelParts.parent) {
          label = `the same <span>${path.labelParts.to}</span>`;

          if (!this.connectedParentPathsAreSame) {
            label += ` connected to <span>${path.labelParts.parent}</span>`;
          }
        }

        const option = {
          value: key,
          label,
        };

        // if connection disambiguation is required, show an ellipsis to indicate further options
        if (path.connections.direct.length > 1 || path.connections.parent.length > 1) {
          option.showEllipsis = true;
        }

        options.push(option);
      });

      // order paths so any direct connections are at the top
      options = sortBy(options, (option) => option.value.split(':').length);

      return options;
    },
    // Determine if all the parent paths are pointing to the same object.
    // We use this to truncate the dropdown options
    connectedParentPathsAreSame() {
      // Get all paths that have a parent connection
      const parentConnectionPathKeys = [];
      const connectedPathKeys = [];

      Object.keys(this.paths).forEach((key) => {
        if (this.paths[key].connections.parent.length > 0) {
          parentConnectionPathKeys.push(key);
        }

        if (this.paths[key].connections.direct.length > 0) {
          connectedPathKeys.push(key);
        }
      });

      // There aren't any parent connections
      if (!parentConnectionPathKeys.length) {
        return false;
      }

      // can't be the same if we have some parent paths and some direct paths without parents
      if (parentConnectionPathKeys.length !== connectedPathKeys.length) {
        return false;
      }

      // ensure the parent object key is the same
      const uniqueParentObjectKeys = uniq(parentConnectionPathKeys.map((path) => path.split(':').pop()));

      return (uniqueParentObjectKeys.length === 1);
    },
    // If all the parent paths are pointing to the same object, exclude from the dropdown
    sameConnectedParentPathLabel() {
      if (!this.connectedParentPathsAreSame) {
        return false;
      }

      // Since these are all the same, we can get the last parent label of the first option tht has a parent connection
      const firstParentPathKey = Object.keys(this.paths).find((key) => this.paths[key].connections.parent.length > 0);

      return `connected to ${this.paths[firstParentPathKey].labelParts.parent}`;
    },
    pathsHaveOptionsForBothDirectAndConnected() {
      const keys = Object.keys(this.paths);

      // Direct means all the records of an object like a parent page, so the path key only has one object in it
      const hasDirect = keys.find((key) => (key.match(/:/g) || []).length === 0);

      // Connected paths have one or two colons in the path key, e.g. object_1:object_3:object_1
      const hasConnected = keys.find((key) => (key.match(/:/g) || []).length > 0);

      return hasDirect && hasConnected;
    },
    pageObject() {
      return this.getObject(this.page.object);
    },
    selectedPath() {
      return this.paths[this.connectionPathKey];
    },
    // If either the parent or direct connections have multiple ways to connect, we need the user to choose which field to use
    showConnectionDisambiguation() {
      if (!this.selectedPath) {
        return false;
      }

      const { connections } = this.selectedPath;

      if (connections.direct.length > 1) {
        return true;
      }

      if (connections.parent.length > 1) {
        return true;
      }

      return false;
    },
  },
  watch: {
    directConnectionType(newVal, oldVal) {
      if (newVal === 'connected') {
        this.connectionPathKey = this.connectedPathDropdownOptions[0].value;
      } else {
        this.connectionPathKey = Object.keys(this.paths)[0];
      }

      this.setDefaultConnectionKeys();
    },
    connectionPathKey(newVal, oldVal) {
      this.setDefaultConnectionKeys();
    },
  },
  created() {
    // Set defaults. These are saved in ViewAdd so they can be restored if the step is edited
    if (this.pathDefaults.directConnectionFieldKey) {
      this.connectionPathKey = this.pathDefaults.connectionPathKey;
      this.directConnectionFieldKey = this.pathDefaults.directConnectionFieldKey;
      this.parentConnectionFieldKey = this.pathDefaults.parentConnectionFieldKey;
      this.directConnectionType = this.pathDefaults.directConnectionType;

      return;
    }

    this.directConnectionType = this.pathsHaveOptionsForBothDirectAndConnected ? 'all' : 'connected';

    this.connectionPathKey = Object.keys(this.paths)[0];

    this.setDefaultConnectionKeys();
  },
  methods: {
    setDefaultConnectionKeys() {
      this.directConnectionFieldKey = this.selectedPath?.connections?.direct?.[0]?.field.key ?? '';

      this.parentConnectionFieldKey = this.selectedPath?.connections?.parent?.[0]?.field.key ?? '';
    },
    onSubmit() {
      let label = '';

      if (!this.selectedPath.labelParts.to) {
        label = 'the same for every user';
      }

      if (this.selectedPath.labelParts.to) {
        label += `connected to ${this.selectedPath.labelParts.to}`;
      }

      if (this.selectedPath.labelParts.parent) {
        label += ` connected to ${this.selectedPath.labelParts.parent}`;
      }

      const sourcePath = {
        source: this.getLocalSource(),
        label,
        path: {
          connectionPathKey: this.connectionPathKey,
          directConnectionFieldKey: this.directConnectionFieldKey,
          parentConnectionFieldKey: this.parentConnectionFieldKey,
          directConnectionType: this.directConnectionType,
        },
      };

      this.$emit('submit', sourcePath);
    },
    // This is what will ultimately be saved with the view in the schema
    getLocalSource() {
      const localSource = {
        object: this.source.object,
      };

      // Add criteria from active view if available
      const existingViewCriteria = this.$store.getters.activeView?.source?.criteria;
      if (existingViewCriteria) {
        localSource.criteria = existingViewCriteria;
      }

      if (this.selectedPath?.user) {
        localSource.authenticated_user = true;
      }

      // We're showing all records, so no need to augment source
      if (this.directConnectionType === 'all') {
        return localSource;
      }

      // There are no connections, so no need to add anything further.
      // This is likely working with the logged-in user record.
      if (!this.selectedPath?.connections.direct.length) {
        return localSource;
      }

      // Get the direct connection
      const directField = this.$store.getters.getField(this.directConnectionFieldKey);

      const directConnection = this.selectedPath?.connections.direct
        .find((conn) => conn.field.key === this.directConnectionFieldKey);

      // Add to the source
      localSource.connection_key = directField.key;

      // The relationship type is in the context of the parent object or the logged-in user
      localSource.relationship_type = directConnection.relationship_type;

      if (this.selectedPath?.connections.parent.length) {
        const parentConnection = this.selectedPath.connections.parent
          .find((conn) => conn.field.key === this.parentConnectionFieldKey);

        localSource.parent_source = {
          connection: parentConnection.field.key,
          object: parentConnection.object.key,
        };
      }

      return localSource;
    },
  },
};
</script>

<style lang="scss">
.capitalize-firstLetter {
  display: inline-block;
}

.capitalize-firstLetter:first-letter {
  text-transform: uppercase;
}

#add-view-source-paths {

  ul {
    padding-left: 1em;
  }

  li {
    margin-bottom: .5em;
    margin-left: 1em;
    list-style: disc;
  }

  strong {
    font-weight: 500;
  }

  .dropdown-trigger {

    background: rgba(141, 144, 152, 0.15);
    border-bottom: 1px solid #8a337a;

    &:hover {
      background: rgba(141, 144, 152, 0.2);
    }
  }

  .dropdown-picker.is-bold {
    .dropdown-label {
      font-weight: 500;
    }
  }
}

.source-connections {

  label {
    display: flex;
    align-items: flex-start;
    font-size: .925em;
    margin-bottom: .32em;

    input {

      margin-top: .25em !important;
    }

    .icon {
      flex-shrink: 0;
      margin-right: 2px;
      position: relative;
      top: 3px;

      opacity: .9;

      height: 15px;
      width: 15px;
    }

    span {
      display: inline;
      margin: 0 1px;
      padding: 2px 5px 2px 2px;
      background: rgba(50, 58, 61, .12);
      border-radius: .25em;
      font-weight: 500;
      opacity: 1;
    }
  }

  p {
    margin: .5em 0;
  }
}
</style>
