import isString from 'lodash/isString';

export default {
  name: 'RouteUtils',
  methods: {

    /**
     * Pushes a new route location while keeping all the current query params.
     *
     * @param {object | string} newLocation
     */
    routerPushMaintainQuery(newLocation) {
      this.$router.push(
        this.routerParseLocationMaintainQuery(newLocation),
      );
    },

    /**
     * Parses the new location object so that it keeps all the current query params.
     *
     * @param {object | string} newLocation
     * @returns {object}
     */
    routerParseLocationMaintainQuery(newLocation) {
      let locationWithQuery = {};

      if (isString(newLocation)) {
        locationWithQuery.path = newLocation;
      } else {
        locationWithQuery = { ...newLocation };
      }

      locationWithQuery.query = this.$route.query;

      return locationWithQuery;
    },
  },
};
