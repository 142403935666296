<template>
  <div class="center center-align mt-12 w-[480px]" style="margin-top: 4em;">
    <h1 class="margin-bottom center-align text-left text-emphasis text-xl font-medium mb-6 mt-0">
      {{ isUser ? 'Add a User Role Table' : 'Add a New Table From Scratch' }}
    </h1>

    <!-- <form @submit.prevent="onAdd" class="margin-bottom center"> -->
    <FormWrapper class="margin-bottom center" style="max-width: 500px;" :errors="errors" :show-submit="true"
      :submit-text="submitButtonText" @submit="onAdd">
      <div data-field-name="name">
        <label class="mb-2">
          {{ capitalizeEachWord(objectType) }} Name
        </label>
        <input v-model="object.name" v-focus type="text" data-cy="new-object-name" style="width: 500px;">
      </div>
    </FormWrapper>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import { mapGetters } from 'vuex';
import { sanitizeObjectName } from '@/util/FormatHelper';
import FormWrapper from '@/components/ui/FormWrapper';
import RequestUtils from '@/components/util/RequestUtils';
import KnackObject from '@/store/models/Object';
import { logEvent } from '@/lib/segment-helper';

export default {
  components: {
    FormWrapper,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      object: {
        name: '',
        user: this.$route.query.user || false, // if it's not set then we assume we're adding a normal object
      },
    };
  },
  computed: {
    ...mapGetters([
      'standardObjects',
      'roleObjects',
      'app',
    ]),
    isUser() {
      return this.$route.query.user === 'true';
    },
    objectType() {
      return this.isUser ? 'user role' : 'table';
    },
    backLink() {
      return `${this.$route.path.split('/objects')[0]}/objects`;
    },
    submitButtonText() {
      return `Add ${capitalize(this.objectType)}`;
    },
  },
  methods: {
    capitalize,
    map,
    onAdd() {
      const objectNew = new KnackObject({
        name: sanitizeObjectName(this.object.name),
        user: this.object.user,
      });

      this.commitRequest({
        validate: () => objectNew.validate(),
        request: () => objectNew.create(),
        onSuccess: ({ object }) => {
          this.$router.push(`${this.backLink}/${object.key}`);
        },
      });
    },
    capitalizeEachWord(string) {
      return map(string.split(' '), capitalize).join(' ');
    },
  },
};
</script>
