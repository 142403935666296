import { isEqual } from 'lodash';

/* To use this mixin the consumer must specific a queryRecordsRequest() method. This */

export default {
  data() {
    return {
      records: [],
      totalRecords: 0,
      totalPages: 0,
      queryVars: null,
      isRequestingRecords: false,
      initRecords: false,
    };
  },
  emits: ['updateTotalRecordsCount'],
  watch: {

    /* This watch function is what determines when
        a new record request should be made.
        It watches the URL query parameters,
        which contains all the user defined parameters that can be updated,
        like pagination or filters.
        Any changes to queryVars from the UI first update the URL params.
        This watch function picks up on those changes and will request new records.
        queryVars are not updated to current state until a successful
        response from the server is recieved.
        This also ensures reactive UI states aren't updated until the records are returned
        to match that state, like pagination and current page.
        This watch approach also enables refresh,
        browser history navigation, and URL query updates to trigger record requests.
    */
    '$route.query': function (newVar, oldVar) {
      if (isEqual(newVar, oldVar)) return;

      // merge any URL vars with defaults so we can test equality
      const urlVars = { ...this.getDefaultQueryVars(), ...this.getUrlParams() };

      this.normalizeQueryVars(urlVars);

      // if not isEqual to current query vars some paramaters has changed,
      // so we need to request records
      if (!_.isEqual(urlVars, this.queryVars)) {
        this.queryRecords(urlVars);
      }
    },
    'view.attributes.rows_per_page': function (newVar, oldVar) {
      if (!oldVar) {
        return;
      }

      this.queryVars.recordsPerPage = Number(newVar);

      this.queryRecords();
    },
    'view.attributes.preset_filters': function (filterRules) {
      this.queryVars.filters.rules = filterRules;
    },
  },
  computed: {
    formatVars() {
      return this.view ? this.view.raw() : {};
    },

    keywordSearch() {
      return this.formatVars.keyword_search;
    },

    allowLimit() {
      return this.formatVars.allow_limit;
    },

    showExportButton() {
      return this.formatVars.allow_exporting;
    },

    showFilters() {
      return this.formatVars.filter_type === 'fields';
    },

    showFilterMenu() {
      return this.formatVars.filter_type === 'menu';
    },

    filterMenuLinks() {
      return this.formatVars.menu_filters;
    },

    filters() {
      return this.queryVars.filters;
    },

    // key used for URL params
    queryUrlKey() {
      // if local
      if (this.queryKey) {
        return this.queryKey;
      }

      // if view?
      return '';
    },

    currentRecordsCount() {
      return this.records?.length;
    },
  },
  methods: {

    getDefaultQueryVars() {
      const defaults = {
        currentPage: 1,
        recordsPerPage: 25,
        sort: [],
        filters: this.getDefaultFilters(),
        search: '',
      };

      if (this.view && this.view.get('rows_per_page')) {
        defaults.recordsPerPage = this.view.get('rows_per_page');
      } else if (this.object) {
        defaults.sort = this.object.sort;
      }

      return defaults;
    },

    getDefaultFilters() {
      if (this.formatVars.preset_filters) {
        return {
          match: 'and',
          rules: this.formatVars.preset_filters,
        };
      }

      return {
        match: 'and',
        rules: [],
      };
    },

    // we're using a local queryVars over this.queryVars to prevent immediate reactive updates
    queryRecords(queryVars = this.queryVars) {
      if (!this.queryRecordsRequest) {
        return;
      }

      if (!queryVars) {
        this.initQueryVars();
        queryVars = this.queryVars;
      }

      this.isRequestingRecords = true;

      const requestObject = {

        onSuccess: ({ records, total_records: totalRecords, total_pages: totalPages }) => {
          this.records = records || [];
          this.totalRecords = Number(totalRecords);
          this.totalPages = Number(totalPages);

          // since this is reactive we only want to update records component on response
          this.queryVars = queryVars;
          this.normalizeQueryVars();

          this.initRecords = true;

          this.isRequestingRecords = false;

          this.$emit('updateTotalRecordsCount', this.totalRecords);
        },
        onError: (err) => {
          log('QueryMixin.queryRecordsRequest.catch error:', err);

          this.isRequestingRecords = false;
        },
        onAbort: () => {
          this.isRequestingRecords = false;
        },
      };

      return this.queryRecordsRequest(
        queryVars,
        requestObject.onSuccess,
        requestObject.onError,
        requestObject.onAbort,
      );
    },

    validateQueryVars(queryVars) {
      // if records per page was changed
      // so currentPage is now out of the total record bounds, reset page
      if (((queryVars.currentPage - 1) * queryVars.recordsPerPage + 1) > this.totalRecords) {
        queryVars.currentPage = 1;
      }

      return queryVars;
    },

    // handle updates (e.g. changed pagination/filter/sort)
    // triggered by the consumer's record component
    onUpdateQueryVars(queryVars) {
      // first get a new copy of existing query vars to merge in.
      const currentQueryVars = { ...this.getDefaultQueryVars(), ...this.getUrlParams() };

      // merge in for a new query vars object
      queryVars = { ...currentQueryVars, ...queryVars };

      // validate
      queryVars = this.validateQueryVars(queryVars);

      // update urlParams, which will trigger a watch and queryRecords
      this.updateUrlParams(queryVars);
    },

    updateUrlParams(queryVars) {
      const keyTranslations = {
        currentPage: 'page',
        recordsPerPage: 'per_page',
      };

      const query = {};

      // first keep any other url params this query doesn't use
      Object.keys(this.$route.query).forEach((key) => {
        if (key.indexOf(this.queryUrlKey) !== 0) {
          query[key] = this.$route.query[key];
        }
      });

      const defaultQueryVars = this.getDefaultQueryVars();

      // push in any vars that aren't the default
      Object.keys(queryVars).forEach((key) => {
        if (queryVars[key] !== defaultQueryVars[key]) {
          const urlKey = keyTranslations[key] || key;
          let urlVar = queryVars[key];

          if (urlKey === 'filters') {
            // ignore filters if no rules exist
            if (!urlVar || !urlVar.rules || urlVar.rules.length === 0) {
              return;
            }

            urlVar = JSON.stringify(urlVar);
          }

          // sorts need translating
          if (urlKey === 'sort') {
            urlVar = `${urlVar.field}|${urlVar.order}`;
          }

          query[`${this.queryUrlKey}_${urlKey}`] = urlVar;
        }
      });

      // Note: this doesn't automatically trigger a refresh from VueRouter
      this.$router.push({
        path: this.$route.path, query,
      });
    },

    getUrlParams() {
      const keyTranslations = {
        page: 'currentPage',
        per_page: 'recordsPerPage',
      };

      const query = {};

      // first add any other query keys that aren't relevant
      Object.keys(this.$route.query).forEach((key) => {
        if (key.indexOf(this.queryUrlKey) === 0) {
          let knackKey = key.replace(`${this.queryUrlKey}_`, '');

          knackKey = keyTranslations[knackKey] || knackKey;
          let urlVar = this.$route.query[key];

          if (knackKey === 'filters' && urlVar) {
            urlVar = JSON.parse(urlVar);
          }

          // sorts need translated
          if (knackKey === 'sort') {
            urlVar = urlVar.split('|');
            urlVar = {
              field: urlVar[0],
              order: urlVar[1] || 'asc',
            };
          }

          query[knackKey] = urlVar;
        }
      });

      return query;
    },

    resetQueryVars() {
      this.initRecords = false;
      this.queryVars = { ...this.getDefaultQueryVars() };
    },

    initQueryVars() {
      // check for URL params
      const urlParams = this.getUrlParams();

      // merge in with local defaults
      this.queryVars = { ...this.getDefaultQueryVars(), ...urlParams };

      this.normalizeQueryVars();
    },

    normalizeQueryVars(queryVars) {
      queryVars = queryVars || this.queryVars;

      queryVars.currentPage = Number(queryVars.currentPage);
      queryVars.recordsPerPage = Number(queryVars.recordsPerPage);
    },

    // ensure the query paramaters reflect the new records for any updates coming from the component
    setRecords(recordVars) {
      this.records = recordVars?.records || [];
      this.currentPage = Number(recordVars.current_page);
      this.totalRecords = Number(recordVars.total_records) // eslint-disable-line
      this.totalPages = Number(recordVars.total_pages) // eslint-disable-line

      this.$emit('updateTotalRecordsCount', this.totalRecords);
    },

    incrementTotalRecords() {
      this.totalRecords += 1;
      this.$emit('updateTotalRecordsCount', this.totalRecords);
    },
  },
};
