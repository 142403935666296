<template>
  <div>
    <GlobalLoading
      :is-loading="isLoading"
      :local="true"
    />
    <div
      v-if="reportData"
      class="kn-table-wrapper"
    >
      <table class="kn-table is-striped is-bordered">
        <thead>
          <tr>
            <th
              v-for="(group, groupIndex) in reportGroups"
              :key="`${groupIndex}-${group.label}`"
            >
              {{ group.label }}
            </th>
            <th
              v-for="(calculation, calculationIndex) in reportCalculations"
              :key="`${calculationIndex}-${calculation.label}`"
            >
              {{ calculation.label || calculation }}
            </th>
            <th
              v-for="(rowSummary, rowSummaryIndex) in reportRowSummaries"
              :key="`${rowSummaryIndex}-${rowSummary.label}`"
            >
              {{ rowSummary.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(record, recordIndex) in reportData.records"
            :key="recordIndex"
          >
            <td
              v-for="(group, groupIndex) in reportGroups"
              :key="group.group"
              class="kn-pivot-group"
              style="text-align: right;"
              v-html="record['group_' + groupIndex]"
            />
            <td
              v-for="(calculation, calculationIndex) in reportCalculations"
              :key="`${calculationIndex}-${calculation.label}`"
              class="kn-pivot-calc"
              style="text-align: right;"
            >
              {{ record['calc_' + calculationIndex] }}
            </td>
            <td
              v-for="(rowSummary, rowSummaryIndex) in reportRowSummaries"
              :key="`${rowSummaryIndex}-${rowSummary.label}`"
              style="text-align: right;"
            >
              <strong>{{ calculateRowSummary(record, rowSummary) }}</strong>
            </td>
          </tr>
          <tr v-if="!reportData.records || !reportData.records.length">
            <td :colspan="reportColSpan">
              {{ noDataMessage }}
            </td>
          </tr>
          <tr
            v-for="(summary, summaryIndex) in reportData.summaries"
            :key="`${summaryIndex}-${summary.label}`"
            class="kn-table_summary"
          >
            <td
              :colspan="summaryColSpan"
              style="text-align: left;"
            >
              <strong>{{ summary.label }}</strong>
            </td>
            <td
              v-for="(calculation, calculationIndex) in summary.calcs"
              :key="`${calculationIndex}-${calculation}`"
              style="text-align: right;"
            >
              <strong>{{ calculation }}</strong>
            </td>
            <td
              v-for="(rowSummary, rowSummaryIndex) in reportRowSummaries"
              :key="`${rowSummaryIndex}-${rowSummary.label}`"
              style="text-align: right;"
            >
              <strong>{{ calculateRowSummary(summary, rowSummary) }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import hasIn from 'lodash/hasIn';
import compact from 'lodash/compact';
import RequestUtils from '@/components/util/RequestUtils';
import GlobalLoading from '@/components/ui/GlobalLoading';
import TableSummariesUtils from '@/components/renderer/mixins/TableSummariesUtils';
import { eventBus } from '@/store/bus';

export default {
  components: {
    GlobalLoading,
  },
  mixins: [
    TableSummariesUtils,
    RequestUtils,
  ],
  props: {
    view: {
      type: Object,
      default: () => {},
    },
    report: {
      type: Object,
      default: () => {},
    },
    reportData: {
      type: Object,
      default: () => {},
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    reportIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    hasReportData() {
      return !isEmpty(this.reportData);
    },
    reportCalculations() {
      if (this.reportData.pivot_cols && this.reportData.pivot_cols.length) {
        return compact(this.reportData.pivot_cols);
      }

      return compact(this.report.calculations);
    },
    reportGroups() {
      if (this.reportData.pivot_cols && this.reportData.pivot_cols.length) {
        return this.report.groups.filter((group) => !group.pivot);
      }

      return this.report.groups;
    },
    reportRowSummaries() {
      return this.report.row_summaries;
    },
    reportColSpan() {
      if (this.reportData.pivot_cols && this.reportData.pivot_cols.length) {
        return this.report.groups.length + this.reportData.pivot_cols.length;
      }

      return this.reportGroups.length + this.reportCalculations.length;
    },
    summaryColSpan() {
      let colSpan = 0;

      _.each(this.reportData.summaries, (summary) => {
        colSpan = this.reportGroups.length;
      });

      return colSpan;
    },
    noDataMessage() {
      let message = 'No data';

      if (hasIn(this.reportData, 'max_data')) {
        message += '. This report has too many groupings to render efficiently.';

        if (this.reportData.filter_length) {
          message += ` The current count is ${this.reportData.filter_length}.`;
        }

        message += ' Try to add some filters so the number of groupings is below 3,000.';
      }

      return message;
    },
  },
  created() {
    eventBus.$on(`reloadReportData.${this.view.key}`, ({ rowIndex, reportIndex }) => {
      if (this.rowIndex === Number(rowIndex) && this.reportIndex === Number(reportIndex)) {
        this.reloadReportData();
      }
    });
  },
  methods: {
    renderTotals() {
      // remove any existing totals (cell editing refreshes these)
      this.$el.querySelectorAll('table tbody tr.kn-table-totals').forEach((element) => {
        element.parentNode.removeChild(element);
      });

      const options = {
        context: this.$el,
      };

      this.renderRowTotals(options);
    },
    reloadReportData() {
      this.isLoading = true;

      return this.commitRequest({
        request: () => this.view.loadIndividualReportData(this.rowIndex, this.reportIndex),
        globalLoading: false,
        onSuccess: () => {
          log('PivotTable on Success!~~~~~!');

          this.isLoading = false;
        },
      });
    },
  },
};
</script>

<style lang="scss">
.kn-table-wrapper > table {
  td,
  th {
    border: 1px solid transparent;
    border-bottom-color: #dbdbdb;
    padding: 6px 8px;
    vertical-align: top;
    white-space: nowrap;
    text-align: left;
    position: relative;
  }
  thead {
    transition: opacity 0.1s ease-in;
    tr:hover {
      background-color: inherit;
    }
    td,
    th {
      border-bottom-width: 2px;

      .kn-item {
        position: absolute;
        top: -1px; right: -1px; bottom: -1px; left: -1px;
        border: 1px solid transparent;
        border-bottom-color: #dbdbdb;
        padding: 6px 8px;
        cursor: move;
        margin: 0;
        border-radius: 0;
      }
    }
  }
  tbody {
    tr {
      &:last-child {
        td,
        th {
          border-bottom-width: 0;
        }
      }
    }
  }
  tfoot {
    td,
    th {
      border-width: 2px 0 0;
    }
  }
  // Modifiers
  &.is-bordered {
    td,
    th,
    .kn-item {
      border-width: 1px;
      border-color: #dbdbdb;
    }
    th {
      background-color: #ecedef;
    }
    tr:last-child {
      td,
      th,
      .kn-item {
        border-bottom-width: 1px;
      }
    }
  }
  &.small-spacing {
    font-size: .94em;
    td,
    .kn-item {
      padding: 3px 6px;
      // Modifiers
      &.is-icon {
        padding: 2px;
        &.is-link {
          padding: 0;
          & > a {
            padding: 2px;
          }
        }
      }
      &.is-link {
        padding: 0;
        & > a {
          padding: 5px 10px;
        }
      }
    }
  }
  &.large-spacing {
    font-size: 1em;
    td,
    .kn-item {
      padding: 10px 15px;
      // Modifiers
      &.is-icon {
        padding: 2px;
        &.is-link {
          padding: 0;
          & > a {
            padding: 2px;
          }
        }
      }
      &.is-link {
        padding: 0;
        & > a {
          padding: 5px 10px;
        }
      }
    }
  }

  &.is-striped {
    tbody tr:nth-child(even) {
      background-color: #fafafa;
    }
  }

  &.is-unbordered {
    td,
    .kn-item {
      border-color: transparent;
    }
    th {
      background-color: rgba(0, 0, 0, 0.05); // + Added to make details view match old styles
    }
    tr:last-child {
      td,
      .kn-item {
        border-bottom-width: 0px;
      }
    }
  }

  &.cell-editable {
    td.cell-edit {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &.expand-records {
    td.kn-pivot-calc {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .kn-table-group {
    background-color: rgba(0, 0, 0, 0.2) !important;
    td {
      font-weight: bold;
    }
  }

  .kn-table-table {
    // Modifiers
    &.can-overflow-x {
      overflow-x: auto;
    }
  }
}

.kn-table-wrapper > table {
  .add-column {
    position: absolute;
    border: 0;
    top: 0;
    right: -40px;
    padding: 5px;
    background-color: rgba(119, 27, 102, .95);
    border-radius: 4px;
    color: #fff;
    height: 25px;
    width: 25px;
    opacity: 0;
  }

  > th:hover > .item-links, > th.router-link-active > .item-links, .kn-table > table:hover .add-column, .kn-table.is-active > table th > a {
    opacity: 1;
    visibility: visible;
  }

  .item-wrapper:hover .kn-item, .item-wrapper.router-link-active .kn-item {
    border: 1px solid rgba(42, 178, 123, 1);
    border-bottom-width: 2px;
    z-index: 2;
  }

  &.is-bordered {
      .item-wrapper:hover .kn-item, th.router-link-active .kn-item {
      border-bottom-width: 1px;
    }
  }
}

.kn-table.is-active th > .item-links {
  background-color: rgba(42, 178, 123, .85);
}

.kn-table {
  .item-wrapper.is-dragging .kn-item {
    border: 1px solid #aaa;
  }
}
</style>
