<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import ObjectsEmpty from '@/components/objects/ObjectsEmpty';
import ObjectsIntro from '@/components/objects/ObjectsIntro';

const store = useStore();

const noObjects = computed(() => store.getters.objects.length === 0);
</script>

<template>
  <div class="body-wrapper">
    <ObjectsEmpty v-if="noObjects" />

    <ObjectsIntro v-else />
  </div>
</template>
