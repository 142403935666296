<template>
  <div class="view-icon">
    <!-- Form -->
    <Icon
      v-if="type === 'form'"
      type="view-form"
      :class="viewTypeIconClasses"
    />

    <!-- Details -->
    <Icon
      v-else-if="type === 'details'"
      type="view-details"
      :class="viewTypeIconClasses"
    />

    <!-- Table -->
    <Icon
      v-else-if="type === 'table'"
      type="view-table"
      :class="viewTypeIconClasses"
    />

    <!-- Search -->
    <Icon
      v-else-if="type === 'search'"
      type="view-search"
      :class="viewTypeIconClasses"
    />

    <!-- List -->
    <Icon
      v-else-if="type === 'list'"
      type="view-list"
      :class="viewTypeIconClasses"
    />

    <!-- Map -->
    <Icon
      v-else-if="type === 'map'"
      type="view-map"
      :class="viewTypeIconClasses"
    />

    <!-- Calendar -->
    <Icon
      v-else-if="type === 'calendar'"
      type="view-calendar"
      :class="viewTypeIconClasses"
    />

    <!-- Report -->
    <Icon
      v-else-if="type === 'report' && reportType"
      :type="`view-${reportType}`"
      :class="viewTypeIconClasses"
    />

    <!-- E-Commerce - Accept Payment -->
    <Icon
      v-else-if="type === 'accept_payment'"
      type="view-accept-payment"
      :class="viewTypeIconClasses"
    />

    <!-- E-Commerce - Update Payment -->
    <Icon
      v-else-if="type === 'update_payment'"
      type="view-update-payment-method"
      :class="viewTypeIconClasses"
    />

    <!-- Menu -->
    <Icon
      v-else-if="type === 'menu'"
      type="view-menu-alt"
      :class="viewTypeIconClasses"
    />

    <!-- Rich Text -->
    <Icon
      v-else-if="type === 'rich_text'"
      type="view-rich-text"
      :class="viewTypeIconClasses"
    />

    <!-- Image -->
    <Icon
      v-else-if="type === 'image'"
      type="view-image"
      :class="viewTypeIconClasses"
    />

    <!-- Divider -->
    <Icon
      v-else-if="type === 'divider'"
      type="view-divider"
      :class="viewTypeIconClasses"
    />
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  name: 'ViewIcon',
  components: {
    Icon,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    reportType: {
      type: String,
      default: '',
    },
  },
  computed: {
    viewTypeIconClasses() {
      return 'text-subtle hover:text-brand-400 w-12 h-12';
    },
  },
};
</script>

<style lang="scss">
.view-icon {

  svg {
    width: 48px;
    height: 48px;
    transition: all 200ms ease-out;
  }

  // Set the svg colors: see https://tympanus.net/codrops/2015/07/16/styling-svg-use-content-css/.
  & > svg > use {
    color: $add500;
    --background-color: #fff;
    --secondary-color: #{$add100};
    --tertiary-color: #228E62;
  }
}
</style>
