<template>
  <ConfirmModal
    title="Delete Field"
    :confirm-type="(!isWarning) ? `alert` : `confirm`"
    :confirm-text="(isWarning) ? `Close` : `Delete`"
    :has-cancel="(!isWarning)"
    @close="onCloseModal"
    @confirm="onClickDeleteField"
  >
    <template #titleContent>
      <h1 v-if="!isWarning">
        <template v-if="isIntegrationConnectionField">
          <span class="text-xl text-emphasis font-medium">
            Delete {{ fieldNameToDelete }} connection?
          </span>
        </template>
        <template v-else>
          <span class="text-xl text-emphasis font-medium">
            Delete the {{ fieldNameToDelete }} field?
          </span>
        </template>
      </h1>
      <h1 v-else>
        This field has been deleted
      </h1>
    </template>

    <template v-if="!isWarning">
      <template v-if="isIntegrationConnectionField">
        <p>
          <strong>Connection from Integration</strong>
        </p>
        <p>
          This will delete the {{ fieldNameToDelete }} connection.
          <strong>Every</strong> instance of this connected field from this table within the entire app will be removed.
        </p>
        <p>
          <strong>This will have irreversible consequences for tables created by an integration.</strong>
        </p>
        <p class="m-0">
          This can't be undone!
        </p>
      </template>
      <template v-else>
        <p>
          This will permanently delete the {{ fieldNameToDelete }} field <strong>everywhere</strong>
          it is used in the app.
        </p>
        <p>
          All data in this field will also be deleted from records.
        </p>
        <p class="m-0">
          <strong>This can't be undone!</strong>
        </p>
      </template>
    </template>
    <template v-else>
      <p>
        Deleting this field has changed other fields using it.
      </p>
      <p>
        Please review these fields to make sure they are still working as intended:
      </p>
      <ul>
        <li
          v-for="field in changedFields"
          :key="field.field.key"
        >
          {{ getObject(field.object.key).name }} > {{ field.field.name }}
        </li>
      </ul>
    </template>
  </ConfirmModal>
</template>

<script>
import { mapGetters } from 'vuex';
import FieldUtils from '@/components/fields/FieldUtils';
import RequestUtils from '@/components/util/RequestUtils';
import ConfirmModal from '@/components/ui/ConfirmModal';

export default {
  name: 'FieldDelete',
  components: {
    ConfirmModal,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  data() {
    return {
      backLink: '',
      isWarning: false,
      changedFields: [],
    };
  },
  computed: {
    ...mapGetters([
      'getObject',
      'activeObject',
    ]),
    fieldNameToDelete() {
      const field = this.object.getField(this.$route.params.fieldKey);

      return field ? field.get('name') : '';
    },
    modalTitle() {
      return this.isWarning ? 'Warning' : 'Delete Field';
    },
    isIntegrationConnectionField() {
      const field = this.object.getField(this.$route.params.fieldKey);
      return !!(field?.get('integration') && field?.get('type') === 'connection');
    },
  },
  created() {
    this.setBackLink();
  },
  methods: {
    setBackLink() {
      if (this.backLink) {
        return;
      }

      if (this.$route.path.indexOf('records') > -1) {
        this.backLink = `/records/objects/${this.$route.params.objectKey}`;
      } else {
        this.backLink = this.$route.path.split(`/${this.$route.params.fieldKey}`)[0];
      }
    },

    onClickDeleteField() {
      if (this.isWarning) {
        return this.$router.push(this.backLink);
      }

      this.commitRequest({
        request: () => this.activeObject.deleteField(this.$route.params.fieldKey),
        onSuccess: ({ changes }) => {
          log('deleteField onSuccess()', changes, changes.updates.fields);

          if (_.isNil(changes) || _.isNil(changes.updates) || _.isEmpty(changes.updates.fields)) {
            return this.$router.push(this.backLink);
          }

          this.isWarning = true;
          this.changedFields = changes.updates.fields;
        },
        onError: (errors) => {
          const errorMessage = errors[0]?.error
            || 'Try refreshing the page and attempting the action again, or contact Knack support.';
          this.$notify({
            group: 'top-right',
            type: 'error',
            duration: 8000,
            'animation-type': 'velocity',
            title: 'Request Error',
            text: errorMessage,
            data: {
              titleIcon: 'dialogue',
            },
          });

          return this.$router.push(this.backLink);
        },
      });
    },
    onCloseModal() {
      this.$router.push(this.backLink);
    },
  },
};
</script>
