import BaseJoi from '@hapi/joi';
import moment from 'moment-timezone';

import {
  testEquation,
  buildCalc,
  normalizeEquationValue,
  equationType,
  equationObject,
  getRawEquationValue,
} from '@/util/EquationProcessing';

const ObjectJoi = BaseJoi.extend((joi) => ({
  base: joi.object(),
  name: 'object',
  language: {
    validEquation: 'Equation is invalid',
  },
  rules: [
    {
      name: 'validEquation',
      params: {
        fieldLocal: joi.object(),
      },
      validate(params, value, state, options) {
        const { fieldLocal } = params;
        const eqObject = equationObject(fieldLocal.objectKey);
        const eqType = equationType(fieldLocal);
        const editorFields = eqObject.getEligibleFieldsForEquationField(fieldLocal.key, eqType);
        const normalizedValue = normalizeEquationValue(value.equation);
        const editorValue = getRawEquationValue(normalizedValue, editorFields);
        const { calc } = buildCalc(editorValue, eqType, fieldLocal, editorFields);
        const { isError } = testEquation(calc, eqType, fieldLocal.format);

        if (isError) {
          return this.createError('object.validEquation', {
            v: value,
          }, state, options);
        }

        return editorValue;
      },
    },
  ],
}));

const StringJoi = ObjectJoi.extend((joi) => ({
  base: joi.string(),
  name: 'string',
  language: {
    notBeforeToday: 'Date cannot be before today',
  },
  rules: [
    {
      name: 'notBeforeToday',
      validate(params, value, state, options) {
        const momentValue = moment(value, 'MM/DD/YYYY');

        if (momentValue.isBefore(moment(), 'day')) {
          return this.createError('string.notBeforeToday', {
            v: value,
          }, state, options);
        }

        return value;
      },
    },
  ],
}));

export const Joi = StringJoi;
