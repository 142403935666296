<template>
  <div class="overlay">
    <div class="title border-0 py-4 px-6 items-center flex-[0_0_72px] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.05),0px_1px_2px_0px_rgba(0,0,0,0.03)] inline-flex items-center">
      <slot name="title">
        <h1>{{ title }}</h1>
      </slot>
      <a
        class="text-default hover:text-emphasis right-6 -mt-3"
        @click="onClose"
      >
        <Icon
          class="h-6 w-6 text-inherit"
          type="close"
        />
      </a>
    </div>
    <div class="body bg-default">
      <slot />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: [
    'title',
    'back',
  ],
  emits: ['close'],
  methods: {
    onClose() {
      log('overlay.onClose', this.back);

      // we can't assume router.back because the modal itself could have many child routes
      if (this.back) {
        this.$router.push(this.back);
      }

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 91;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  > .title {
    padding: 1.25em 1.5em 0;
    border-bottom: 1px solid $gray100;
    flex: 0 0 60px;
    text-align: center;
    position: relative;

    h1 {
      line-height: 120%;
      font-weight: 500;
      margin: 0;
    }

    &> a {
      color: $gray500;
      opacity: .75;
      position: absolute;
      right: 1em;
      top: 50%;
      margin-top: -15px;

      &:hover {
        opacity: 1;
      }

      svg {
        width: 32px; height: 32px;
      }
    }
  }

  &.left-title > .title {

    flex: 0 0 40px;

    h1 {
      display: flex;
      justify-content: flex-start;
      @include font-h3;
      line-height: 1;
      height: 40px;

      svg.icon {
        width: 24px;
        height: 24px;
        margin-right: .75rem;
        margin-top: -.125rem;
        // color: #771b66;
      }
    }
  }

  > .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 800px;
    margin: auto;
    margin-top: 2em;
    font-size: 1.05em;
    overflow: auto;
  }

  &.full-width > .body {
    width: 100%;
  }

  &.full-body > .body {
    margin: 0;
  }

  &.gray-bg > .body {
    background-color: #f4f5fa;
  }
}
</style>
