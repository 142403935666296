<template>
  <div
    class="kn-view-table-wrapper"
    style="position: relative;"
  >
    <ConfirmModal
      v-if="showConfirmDeleteColumn"
      title="Delete this column?"
      confirm-type="alert"
      @close="showConfirmDeleteColumn = false"
      @confirm="onConfirmDeleteColumn"
    >
      <p v-if="showDeleteLinkedPagesWarning">You'll also be deleting any Pages and Views this column links to</p>

      <p class="mb-0">
        <strong>Once the view changes are saved, this can't be undone!</strong>
      </p>
    </ConfirmModal>

    <ViewUtilityDropdown
      class="static block"
      style="position: absolute; top: -5px; right: 8px;"
    />

    <TableWrapper>
      <template #navigation>
        <RecordNavigation
          v-if="columns.length"
          :allow-limit="allowLimit"
          :current-records-page="queryVars.currentPage"
          :filter-menu-links="filterMenuLinks"
          :records-filters="filters"
          :object-key="objectKey"
          :records="records"
          :records-per-page="queryVars.recordsPerPage"
          :show-interceptor="true"
          :show-keyword-search="keywordSearch"
          :show-export-button="showExportButton"
          :show-filters="showFilters"
          :show-filter-menu="showFilterMenu"
          :current-records-count="currentRecordsCount"
          :total-records="totalRecords"
          :total-records-pages="totalPages"
        />
      </template>
      <template #table>
        <VTable
          :view="view"
          :allow-sorting="!isViewOnly"
          :draggable="isDraggable"
          :columns="columns"
          :records="records"
          :total-records="totalRecords"
          :route-prefix="routePrefix"
          :show-inline-edit="false"
          @activate-column="onActivateColumn"
          @delete-column="onDeleteColumn"
          @drop-view-item="onDropColumn"
        />
      </template>
      <template #lowerNavigation>
        <RecordNavigation
          v-if="showBottomPagination"
          :allow-limit="allowLimit"
          :current-records-page="queryVars.currentPage"
          :filter-menu-links="filterMenuLinks"
          :records-filters="filters"
          :object-key="objectKey"
          :records="records"
          :records-per-page="queryVars.recordsPerPage"
          :show-keyword-search="false"
          :show-export-button="false"
          :show-pagination-range="false"
          :show-filters="false"
          :show-filter-menu="false"
          :current-records-count="currentRecordsCount"
          :total-records="totalRecords"
          :total-records-pages="totalPages"
        />
      </template>
    </TableWrapper>
  </div>
</template>

<script>
import ColumnActionsMixin from '@/components/renderer/mixins/ColumnActionsMixin';
import ConfirmModal from '@/components/ui/ConfirmModal';
import QueryMixin from '@/components/renderer/mixins/QueryMixin';
import RecordNavigation from '@/components/renderer/shared/RecordNavigation';
import RequestUtils from '@/components/util/RequestUtils';
import TableWrapper from '@/components/renderer/table/TableWrapper';
import ViewUtilityDropdown from '@/components/renderer/shared/ViewUtilityDropdown';
import VTable from '@/components/renderer/table/Table';
import { eventBus } from '@/store/bus';

export default {
  components: {
    ConfirmModal,
    TableWrapper,
    RecordNavigation,
    ViewUtilityDropdown,
    VTable,
  },
  mixins: [
    QueryMixin,
    ColumnActionsMixin,
    RequestUtils,
  ],
  props: {
    view: {
      type: Object,
      default: () => ({}),
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    isViewOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    columns: {
      get() {
        return this.view.columns;
      },
      set(newColumns) {
        this.view.columns = newColumns;
      },
    },
    isDraggable() {
      return this.isViewOnly ? false : this.draggable;
    },
    showBottomPagination() {
      return this.view.attributes.display_pagination_below;
    },
    objectKey() {
      return this.view.source.object;
    },
    routePrefix() {
      return `/pages/${this.$route.params.pageKey}/views/${this.view.key}/${this.view.type}`;
    },
  },
  watch: {
    'view.data': function (newVal) {
      this.setRecords(newVal);
    },
  },
  created() {
    this.setRecords(this.view.data);

    // transform view object into data for props
    // of RecordTools and TableComponent
    this.initQueryVars();
  },
  beforeUnmount() {
    eventBus.$off(`addViewItem.${this.view.key}.table`);
  },
  methods: {
    queryRecordsRequest(queryVars, onSuccess, onError) {
      this.commitRequest({
        request: () => this.view.getData(queryVars),
        onSuccess: (response) => this.view.onGetDataSuccess(response, onSuccess),
        onError,
      });
    },
  },
};
</script>
