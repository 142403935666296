<template>
  <ViewToolbox
    back-title="Form Actions & Rules"
    title="Record Actions"
  >
    <p
      class="text-sm leading-5 mb-0"
      style="margin-bottom: 1em"
    >
      Add rules to update or insert records after the form is submitted.
    </p>
    <RulesList
      v-model:rules="rules"
      :input-field-options="inputFieldOptions"
      :criteria-values-can-be-fields="criteriaValuesCanBeFields"
      group-list-item-classes="bg-subtle"
    >
      <template #no-criteria>
        <p>
          This rule runs on every form submission
        </p>
      </template>
    </RulesList>
  </ViewToolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import RulesList from '@/components/rules/RulesList';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'FormRecordRules',
  components: {
    RulesList,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    criteriaValuesCanBeFields() {
      return this.viewRaw.action === 'update';
    },
    rules: {
      get() {
        return this.viewRaw.rules.records;
      },
      set(newVal) {
        this.viewRaw.rules.records = newVal;
      },
    },
    inputFieldOptions() {
      console.log(`inputFieldOptions, this.rules.action: ${this.viewRaw.action}`);

      // insert forms should return just the inputs that have been added to the form as options for record rules
      if (this.viewRaw.action && this.viewRaw.action !== 'update') {
        return this.$store.getters.activeView.getInputFieldOptions();
      }

      // else we can just use the object inputs
      return this.object.getFieldOptions();
    },
  },
};
</script>
