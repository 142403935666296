import FormHelpReportBug from '@/store/forms/help/FormHelpReportBug';
import getApiBaseModule from '@/store/modules/api/api-base';

export const REPORT_BUG_RESET_FORM = '🐛 Report A Bug - Resetting Form';
export const REPORT_BUG_UPDATE_FORM = '🐛 Report A Bug - Updating Form';
export const REPORT_BUG_VALIDATION = '🐛 Report A Bug - After Validation';
export const REPORT_BUG_READY = '🐛🌱 Report A Bug - Ready';
export const REPORT_BUG_PENDING = '🐛⏳ Report A Bug - Pending';
export const REPORT_BUG_SUCCESS = '🐛✅ Report A Bug - Success';
export const REPORT_BUG_FAILURE = '🐛❌ Report A Bug - Failure';

const events = {
  RESET_FORM: REPORT_BUG_RESET_FORM,
  UPDATE_FORM: REPORT_BUG_UPDATE_FORM,
  VALIDATION: REPORT_BUG_VALIDATION,
  READY: REPORT_BUG_READY,
  PENDING: REPORT_BUG_PENDING,
  SUCCESS: REPORT_BUG_SUCCESS,
  FAILURE: REPORT_BUG_FAILURE,
};

/**
 * Gets the form.
 *
 * @returns {FormHelpReportBug}
 */
const getForm = () => new FormHelpReportBug();

const apiBase = getApiBaseModule(events, getForm);

export default {
  // Full namespace: api/help/reportBug
  reportBug: {
    namespaced: true,
    state: apiBase.state,
    getters: apiBase.getters,
    mutations: apiBase.mutations,
    actions: apiBase.actions,
  },
};
