<template>
  <div class="mb-0">
    <form
      class="margin-bottom-double mb-0"
      @submit.prevent="onSubmit"
      @keyup.enter="onSubmit"
    >
      <div data-field-name="name">
        <label class="text-default text-sm font-medium mb-2 leading-4">Name</label>
        <input
          v-model="fieldLocal.name"
          v-focus
          type="text"
        >
      </div>
      <div>
        <label class="text-default text-sm font-medium mb-2 leading-4">Data Type</label>
        <Dropdown
          v-if="isNew"
          v-model="fieldLocal.type"
          v-tippy
          placement="bottom-end"
          content="Select a different field type"
          :options="fieldTypeOptions"
          class="type-selector inline"
        />
        <TypeIcon
          v-else
          field-title-classes="text-default"
          icon-classes="text-subtle"
          :field="fieldLocal"
          :title="true"
        />
        <p
          v-if="fieldLocal.type === 'auto_increment'"
          class="type-helper-text text-subtle not-italic"
        >
          An auto increment field generates a unique number for each record. The number may not be sequential.
        </p>
        <p
          v-if="fieldLocal.type === 'equation'"
          class="type-helper-text text-subtle not-italic"
        >
          Equations run in order of field placement. Make sure to place this field after any dependent fields.
        </p>
        <p
          v-if="fieldLocal.type === 'concatenation'"
          class="type-helper-text text-subtle not-italic"
        >
          Formulas run in order of field placement. Make sure to place this field after any dependent fields.
        </p>
      </div>

      <hr class="block my-6 h-px bg-subtle border-none">

      <template v-if="fieldLocal.type !== `connection`">
        <div
          class="mb-4"
          v-if="isRequired && !isUserField"
        >
          <label class="text-default text-sm font-medium leading-4"><input
            v-model="fieldLocal.required"
            type="checkbox"
          > Required</label>
        </div>
        <div v-if="isUnique && !isUserField">
          <label class="text-default text-sm font-medium leading-4"><input
            v-model="fieldLocal.unique"
            type="checkbox"
          > Must be unique</label>
        </div>
        <div v-if="hasDefaultValue">
          <label class="text-default text-sm font-medium mb-2 leading-4">Default Value</label>
          <input
            v-model="fieldLocal.default"
            type="text"
          >
        </div>
      </template>

      <div class="fieldSettings_description">
        <label class="fieldSettings_descriptionLabel text-default text-sm font-medium mb-2 leading-4">
          <span>Description</span>
          <HelpIcon
            class="fieldSettings_descriptionHelp"
            :text="`Descriptions will show up as tooltips in record columns and when adding fields.`"
          />
        </label>
      </div>
      <RichText
        v-model="fieldMetaDescriptionValue"
        class="fieldSettings_descriptionInput"
        :exclude-buttons="DESCRIPTION_EXCLUDE_BUTTONS"
        :exclude-plugins="DESCRIPTION_EXCLUDE_PLUGINS"
        :additional-options="RICH_TEXT_OPTIONS"
        :is-single-line="true"
        :object="activeObject"
      />

      <Component
        :is="settingsComponent()"
        key="field-settings-component"
        v-model:field-local="localField"
        :is-new="isNew"
      />
    </form>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import Dropdown from '@/components/ui/Dropdown';
import FieldUtils from '@/components/fields/FieldUtils';
import TypeIcon from '@/components/fields/TypeIcon';
import Address from '@/components/fields/settings/Address';
import BooleanComponent from '@/components/fields/settings/Boolean';
import Currency from '@/components/fields/settings/Currency';
import ConnectionSettings from '@/components/fields/settings/Connection';
import DateTime from '@/components/fields/settings/DateTime';
import Email from '@/components/fields/settings/Email';
import Equation from '@/components/fields/settings/Equation';
import File from '@/components/fields/settings/File';
import HelpIcon from '@/components/ui/HelpIcon';
import ImageField from '@/components/fields/settings/ImageField';
import Link from '@/components/fields/settings/Link';
import MultipleChoice from '@/components/fields/settings/MultipleChoice';
import Name from '@/components/fields/settings/Name';
import Number from '@/components/fields/settings/Number';
import Phone from '@/components/fields/settings/Phone';
import Rating from '@/components/fields/settings/Rating';
import RichText from '@/components/renderer/form/inputs/RichText';
import Signature from '@/components/fields/settings/Signature';
import TextFormula from '@/components/fields/settings/TextFormula';
import Texts from '@/components/fields/settings/Texts';
import Timer from '@/components/fields/settings/Timer';
import Formula from '@/components/fields/settings/Formula';

const DESCRIPTION_EXCLUDE_BUTTONS = [
  'html',
  'formatting',
  'deleted',
  'unorderedlist',
  'orderedlist',
  'outdent',
  'indent',
  'alignment',
  'horizontalrule',
];

const DESCRIPTION_EXCLUDE_PLUGINS = [
  'fontcolor',
  'image_web_link',
  'video',
  'table',
];

const componentMappings = {
  address: Address,
  boolean: BooleanComponent,
  concatenation: TextFormula,
  connection: ConnectionSettings,
  currency: Currency,
  date_time: DateTime,
  email: Email,
  equation: Equation,
  file: File,
  image: ImageField,
  link: Link,
  multiple_choice: MultipleChoice,
  name: Name,
  number: Number,
  phone: Phone,
  rating: Rating,
  signature: Signature,
  paragraph_text: Texts,
  rich_text: Texts,
  short_text: Texts,
  timer: Timer,
  sum: Formula,
  average: Formula,
  min: Formula,
  max: Formula,
  count: Formula,
};

export default {
  name: 'SchemaFieldSettings',
  components: {
    Icon,
    Dropdown,
    RichText,
    HelpIcon,
    TypeIcon,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    // TODO: refactor for one sensible field object?
    fieldNew: {
      type: Object,
      default: null,
    },
    fieldLocal: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'submit',
    'update:fieldLocal',
  ],
  data() {
    return {
      DESCRIPTION_EXCLUDE_BUTTONS,
      DESCRIPTION_EXCLUDE_PLUGINS,
      RICH_TEXT_OPTIONS: {
        breakline: true,
        maxHeight: '150px',
        tabKey: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'activeObject',
      'objectsByMenuOrder',
    ]),
    isRequired() {
      const fieldsNotRequired = [
        'auto_increment',
        'boolean',
        'concatenation',
        'equation',
        'rating',
        'user_roles',
        'min',
        'max',
        'sum',
        'count',
        'average',
      ];

      return !fieldsNotRequired.includes(this.fieldRaw.type);
    },
    isUnique() {
      const field = this.fieldNew || this.field;

      return field.canBeUnique();
    },
    hasDefaultValue() {
      const field = this.fieldNew || this.field;

      return field.hasDefaultValue();
    },
    isUserField() {
      return this.objectRaw.profile_key !== undefined && this.fieldRaw.immutable;
    },
    localField: {
      get() {
        return this.fieldLocal;
      },
      set(newValue) {
        this.$emit('update:fieldLocal', newValue);
      },
    },
    fieldTypes() {
      let options = [];

      const fieldTypes = window.Knack.getFieldTypes();

      for (const groupType of Object.keys(fieldTypes)) {
        options = [
          ...options,
          ...fieldTypes[groupType],
        ];
      }

      return options;
    },
    fieldTypeOptions() {
      let options = this.fieldTypes;

      // Ensure all types can be added to this Object
      options = options.filter((option) => this.object.canAddFieldType(option));

      return options.map((option) => ({
        label: option.name,
        value: option.type,
        icon: option.type,
      }));
    },
    fieldMetaDescriptionValue: {
      get() {
        if (!this.fieldLocal?.meta?.description) {
          return '';
        }

        return this.fieldLocal?.meta?.description;
      },
      set(value) {
        if (!this.fieldLocal.meta) {
          this.fieldLocal.meta = {};
        }

        const strippedHtmlRegex = /(<([^>]+)>)/ig;
        const strippedHtmlContent = value.replace(strippedHtmlRegex, '');
        const content = strippedHtmlContent === '' ? '' : value;

        this.fieldLocal.meta.description = content;
      },
    },
  },
  watch: {
    'fieldLocal.type': function (newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      this.fieldLocal.format = this.fieldTypes.find((field) => field.type === newValue).format;

      // If we're changing to a connection we have to set a default connection
      if (newValue === 'connection') {
        let connectedObjectKey = this.objectsByMenuOrder[0].key;

        // don't suggest the same object to connect to if possible
        if (connectedObjectKey === this.object.key && this.objectsByMenuOrder.length > 1) {
          connectedObjectKey = this.objectsByMenuOrder[1].key;
        }

        this.fieldLocal.relationship = {
          belongs_to: 'many',
          has: 'one',
          object: connectedObjectKey,
        };
      }
    },
  },
  methods: {
    settingsComponent() {
      return componentMappings[this.fieldLocal.type];
    },
    onSubmit: debounce(function (event) {
      this.$emit('submit');
    }, 500),
  },
};
</script>

<style lang="scss">
.fieldSettings_description {
  margin-bottom: 0;
}

.fieldSettings_descriptionLabel {
  display: inline-flex;
}

.type-selector {
  position: relative;
  cursor: pointer;
  padding: 4px 5px;
  border: 1px solid #d6d6de;
  border-radius: .23em;
  width: fit-content;
  max-width: 160px;

  &:hover {
    border: 1px solid #8a337a;
  }
}

.type-helper-text {
  @include font-caption;
  font-style: italic;
  color: $gray600;
}

.type-selector + .type-helper-text {
  margin-top: 6px;
}
</style>
