<template>
  <div class="kn-item">
    <FormInput
      :input="input"
      :field="field"
      :model-value="modelValue"
      :view-key="viewKey"
    />

    <div
      v-if="input.allow_option_inserts"
      style="margin-top: .5em;"
    >
      <a
        href="#"
        class="kn-add-option"
        title="Add a new option"
        style="display: inline-flex; align-items: center; position: relative; padding-right: 30px;"
      >
        <FontAwesome
          name="plus-circle"
          class="fa"
          style="width: 16px; height: 16px; margin-right: 4px;"
        />
        Add a new option
        <ViewChildLink
          v-if="input.type === `connection`"
          :url="`/pages/${$route.params.pageKey}/views/${input.view}/form`"
          tooltip="Edit this form to add a new option"
        />
      </a>
    </div>

    <span
      v-if="showTooltip"
      v-tippy="{
        delay: [200, 100],
        hideOnClick: false,
        interactive: true,
        placement: 'top',
        showOnCreate: true,
        sticky: true,
        trigger: 'manual',
      }"
      content="Click to edit, drag to move"
    />
    <div
      class="overlay"
      @click="onActivateInput"
      @mousedown="onHideTooltip"
      @mouseover="onShowTooltip"
      @mouseout="onHideTooltip"
    />

    <div class="item-links gap-1 bg-subtle rounded-t-lg border-b-0 p-1">
      <a
        v-tippy
        class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
        content="Edit this input"
      >
        <Icon
          class="text-default"
          type="edit"
        />
      </a>
      <div
        v-tippy
        content="Delete this form input"
        class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0 text-default"
        @click.prevent="$emit('deleteInput', $event)"
      >
        <Icon
          classs="fill-inherit"
          type="delete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import delay from 'lodash/delay';

import FormInput from '@/components/renderer/form/FormInput';
import ViewChildLink from '@/components/renderer/shared/ViewChildLink';
import FontAwesome from '@/components/ui/FontAwesome';
import Icon from '@/components/ui/Icon';

export default {
  name: 'FormItem',
  components: {
    Icon,
    FontAwesome,
    FormInput,
    ViewChildLink,
  },
  props: {
    field: {
      type: Object,
      default: () => {},
    },
    input: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      default: () => '',
    },
    viewKey: {
      type: String,
      default: '',
    },
  },
  emits: ['deleteInput'],
  data() {
    return {
      showTooltip: false, // v-tippy won't hide when dragging so using this to ensure it goes away
      showingTooltip: false, // used to delay the tooltip
    };
  },
  methods: {
    onShowTooltip() {
      this.showingTooltip = true;

      delay(() => {
        if (this.showingTooltip) {
          this.showTooltip = true;
        }
      }, 800);
    },
    onHideTooltip() {
      this.showTooltip = false;
      this.showingTooltip = false;
    },
    onActivateInput(event) {
      return event.target.nextElementSibling.querySelector('a').click();
    },
  },
};
</script>

<style lang="scss">
</style>
