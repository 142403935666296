import { buildViewSchema, buildPageSchema } from '@/lib/schema-helper';
import store from '@/store';

export default {
  methods: {
    setPageSchemaFromWizardOptions(options = {
      loginVars: {},
    }) {
      if (options.isSinglePage) {
        return {};
      }

      const newPage = {
        parent: null,
      };

      if (options.isUserPage || (options.loginVars && options.loginVars.authenticated)) {
        newPage.type = 'authentication';
        newPage.authenticated = true;

        if (options.isUserPage) {
          newPage.type = 'user';
        }

        if (options.isUserPage || options.loginVars.limit_profile_access) {
          newPage.authentication_profiles = options.loginVars.allowed_profiles;
        }
      }

      const globalLoginPage = store.getters['page/globalLoginPage'];

      if (!options.isUserPage && globalLoginPage) {
        newPage.parent = globalLoginPage.slug;
        newPage.authenticated = true;
      }

      return newPage;
    },

    buildPageModel: buildPageSchema,

    buildViewSchema,
  },
};
