<template>
  <ViewToolbox
    back-title="Pages"
    title="Report"
  >
    <p class="text-xs text-default mb-0">
      Click on a chart to the right to edit or add a new chart below.
    </p>
    <Toggle
      v-if="hasActiveView"
      id="report-settings-toggle"
      :is-open="true"
    >
      <template #title>
        <span>Report Settings</span>
      </template>

      <template #content>
        <div class="inputGroup border-solid border-t-0 border-x-0 border-subtle">
          <div>
            <label class="tw-toolbox-label">Title</label>
            <input
              v-model="view.attributes.title"
              type="text"
            >
          </div>
          <div class="mb-0">
            <label class="tw-toolbox-label">Description</label>
            <textarea v-model="view.attributes.description" />
          </div>
        </div>
      </template>
    </Toggle>

    <Toggle
      id="report-settings-toggle"
      :is-open="true"
    >
      <template #title>
        <span class="mt-2">
          Add Charts
        </span>
      </template>

      <template #content>
        <div id="report-links-toolbox">
          <p class="text-xs text-default mb-4">
            Add new charts by clicking or dragging below.
          </p>
          <div id="report-links-options">
            <div
              v-for="(report, key) in REPORT_TYPES"
              :key="key"
            >
              <a
                :data-report="report.type"
                draggable="true"
                class="bg-transparent p-2 rounded-lg text-default border border-solid border-default hover:text-emphasis hover:border-brand-600 hover:bg-brand-50 group"
                @click="onAddReport"
                @dragstart="onDragStart"
                @dragend="onDragEnd"
              >
                <span><Icon
                  :type="report.icon"
                  class="small h-4 w-4 text-subtle group-hover:text-brand-400"
                /> <b>{{ report.name }}</b></span>
              </a>
            </div>
          </div>
        </div>
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import RequestUtils from '@/components/util/RequestUtils';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

import { eventBus } from '@/store/bus';

const REPORT_TYPES = [
  {
    type: 'bar',
    name: 'Bar Chart',
    icon: 'bar-chart',
  },
  {
    type: 'pie',
    name: 'Pie Chart',
    icon: 'pie-chart',
  },
  {
    type: 'line',
    name: 'Line Chart',
    icon: 'line-chart',
  },
  {
    type: 'area',
    name: 'Area Chart',
    icon: 'area-chart',
  },
  {
    type: 'pivot',
    name: 'Pivot Table',
    icon: 'pivot-table',
  },
];

export default {
  name: 'AddReports',
  components: {
    ViewToolbox,
    Toggle,
    Icon,
  },
  mixins: [
    ViewUtils,
    RequestUtils,
  ],
  data() {
    return {
      REPORT_TYPES,
    };
  },
  computed: {
    ...mapGetters([
      'getObject',
    ]),

    hasMultipleSourceOptions() {
      return this.view?.sourceOptions && this.view.sourceOptions.length > 1;
    },
  },
  methods: {
    ...mapActions([
      'setChartType',
    ]),

    createChartAddRoute(viewKey) {
      return `/pages/${this.$route.params.pageKey}/views/${viewKey}/report/chart/add`;
    },

    async onAddReport() {
      if (this.hasMultipleSourceOptions) {
        const chartType = event.currentTarget.getAttribute('data-report');

        await this.setChartType(chartType);

        // take the user to another edit sidebar, within the existing edit sidebar flow
        this.$router.push(this.createChartAddRoute(this.view.key));
      } else {
        this.addReport();
      }
    },

    async addReport(source) {
      const chartType = event.currentTarget.getAttribute('data-report');

      this.view.addChartToReport(chartType, source);

      // Tell the new report to load in its data
      await this.$nextTick();

      eventBus.$emit(`reloadReportData.${this.view.key}`, {
        rowIndex: this.view.rows.length - 1,
        reportIndex: 0,
      });
    },

    onDragStart(event) {
      event.stopPropagation();

      // views check this to ensure eligible drop zones
      this.$store.commit('dragStartNewViewItem', {
        viewKey: this.view.key,
        viewArea: this.view.type,
      });

      const type = event.currentTarget.getAttribute('data-report');

      log('AddItems > > > onDragStart, adding view.key:', this.view.key, 'localViewType', this.localViewType);

      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('type', type);
      event.dataTransfer.setData('new', type);
      event.target.classList.add('is-dragging');
      event.target.style.cursor = 'move';

      eventBus.$emit('dragStartItemNew', event);
    },

    onDragEnd(event) {
      // remove this from store
      this.$store.commit('dragEndNewViewItem');

      event.target.classList.remove('is-dragging');
      const dropTarget = document.querySelector('.drop-target.over');

      if (dropTarget) {
        dropTarget.classList.remove('over');
      }

      return eventBus.$emit('dragEndItemNew', event);
    },
  },
};
</script>

<style lang="scss">

#report-links-options {
  transition: all 300ms ease-in;
  overflow: hidden;
}

#report-links-toolbox {

  a {
    background-color: #e2e4ea;
    padding: 7px 12px;
    margin-bottom: 7px;
    border-radius: 4px;
    display: block;
    color: #5d5d63;
    font-size: .96em;

    span {
      display: inline-block;
      margin-top: 1px;
      margin-left: 2px;
    }

    svg {

      position: relative;
      top: 3px;
      margin-right: 4px;
    }
  }
  a:hover {
    color: #219667;
  }

  .is-dragging a {
    opacity: .5;
  }
  .expanded-items {
    overflow: hidden;
  }
}
</style>
