<template>
  <div class="kn-datetime">
    <div class="kn-datetime_controls kn-form_row group-[&:not(.kn-view)]/shared:gap-2">
      <div class="kn-datetime_inputs group-[&:not(.kn-view)]/shared:self-start">
        <div
          v-if="!ignoreDate"
          class="control kn-inputWrapper kn-datetime_control"
        >
          <Datepicker
            :id="field.key"
            v-model="localDate"
            name="date"
            class="kn-datepicker"
            input-class="input"
            placeholder=""
            :typeable="true"
            :parse-typed-date="parseTypedDateForDatepicker"
            :format="customFormatter"
            :disabled="isAnyDateEnabled"
          />
          <div
            v-if="allowsAnyDate && !ignoreTime"
            class="kn-datetime__any-value-container group-[&:not(.kn-view)]/shared:mt-2"
          >
            <input
              v-model="isAnyDateEnabled"
              class="kn-datetime__any-value-checkbox"
              name="any_date"
              type="checkbox"
              @change="onAnyDateToggle"
            >
            <label class="kn-datetime__any-value-label group-[&:not(.kn-view)]/shared:text-emphasis group-[&:not(.kn-view)]/shared:text-sm">Any date</label>
          </div>
        </div>

        <div
          v-if="!ignoreTime"
          class="control kn-inputWrapper kn-datetime_control"
        >
          <TimePicker
            v-model="localTime"
            class="kn-time input right group-[&:not(.kn-view)]/shared:max-w-[120px] group-[&:not(.kn-view)]/shared:mr-0 group-[&:not(.kn-view)]/shared:ml-2"
            input-classes="group-[&:not(.kn-view)]/shared:max-w-[120px] group-[&:not(.kn-view)]/shared:mr-0"
            name="to_time"
            :format="getMomentTimeFormatFromKnackTimeFormat(input.format.time_format)"
            :minute-interval="input.format.minutes"
            :is-in-popover="isInPopover"
            :is-disabled="isAnyTimeEnabled"
          />
          <div
            v-if="allowsAnyTime && !ignoreDate"
            class="kn-datetime__any-value-container group-[&:not(.kn-view)]/shared:mt-2"
          >
            <input
              v-model="isAnyTimeEnabled"
              class="kn-datetime__any-value-checkbox"
              name="any_time"
              type="checkbox"
              @change="onAnyTimeToggle"
            >
            <label class="kn-datetime__any-value-label group-[&:not(.kn-view)]/shared:text-emphasis group-[&:not(.kn-view)]/shared:text-sm">Any time</label>
          </div>
        </div>
      </div>

      <div
        v-if="hasCalendarOptions"
        class="kn-datetime_separator control kn-inputWrapper group-[&:not(.kn-view)]/shared:self-start group-[&:not(.kn-view)]/shared:mt-2"
      >
        <span>{{ trans('to') }}</span>
      </div>

      <div
        v-if="hasCalendarOptions"
        class="kn-datetime_inputs group-[&:not(.kn-view)]/shared:self-start"
      >
        <div
          v-if="!ignoreTime"
          class="control kn-inputWrapper kn-datetime_control"
        >
          <TimePicker
            v-model="localToTime"
            class="kn-time input group-[&:not(.kn-view)]/shared:max-w-[120px] group-[&:not(.kn-view)]/shared:mr-2"
            input-classes="group-[&:not(.kn-view)]/shared:max-w-[120px] group-[&:not(.kn-view)]/shared:mr-0"
            name="to_time"
            :format="getMomentTimeFormatFromKnackTimeFormat(input.format.time_format)"
            :minute-interval="input.format.minutes"
            :is-in-popover="isInPopover"
          />
        </div>
        <div class="control kn-inputWrapper kn-datetime_control">
          <Datepicker
            v-model="localToDate"
            class="kn-datepicker right"
            input-class="input"
            placeholder=""
            :typeable="true"
            :parse-typed-date="parseTypedDateForDatepicker"
            :format="dateFormat"
          />
        </div>
      </div>
    </div>

    <div v-if="hasCalendarOptions">
      <div class="control kn-inputWrapper is-grouped kn-calendar-options kn-form_row">
        <label>
          <input
            v-model="localValue.all_day"
            class="checkbox"
            type="checkbox"
            @click="onAllDayToggle"
          >
          <span class="group-[&:not(.kn-view)]/shared:text-emphasis group-[&:not(.kn-view)]/shared:text-sm group-[&:not(.kn-view)]/shared:font-normal">{{ trans('all_day') }}</span></label>
        <label>
          <input
            v-model="showRepeat"
            class="checkbox"
            type="checkbox"
          >
          <span class="group-[&:not(.kn-view)]/shared:text-emphasis group-[&:not(.kn-view)]/shared:text-sm group-[&:not(.kn-view)]/shared:font-normal">{{ trans('repeat') }}</span>
        </label>
      </div>
      <!-- TODO: Repeat edit -->
      <div v-if="showRepeat">
        <div class="kn-datetime_optionRow">
          <label>Repeats:</label>
          <select
            v-model="localRepeat.frequency"
            class="repeat-frequency text-base py-2 pl-3 leading-5 w-max"
          >
            <option value="daily">
              Daily
            </option>
            <option value="weekly">
              Weekly
            </option>
            <option value="monthly">
              Monthly
            </option>
            <option value="yearly">
              Yearly
            </option>
          </select>
          &nbsp;
          every
          &nbsp;
          <select
            v-model="localRepeat.interval"
            class="repeat-interval text-base py-2 pl-3 leading-5"
          >
            <option value="1">
              1
            </option>
            <option value="2">
              2
            </option>
            <option value="3">
              3
            </option>
            <option value="4">
              4
            </option>
            <option value="5">
              5
            </option>
            <option value="6">
              6
            </option>
            <option value="7">
              7
            </option>
            <option value="8">
              8
            </option>
            <option value="9">
              9
            </option>
            <option value="10">
              10
            </option>
            <option value="11">
              11
            </option>
            <option value="12">
              12
            </option>
            <option value="13">
              13
            </option>
            <option value="14">
              14
            </option>
            <option value="15">
              15
            </option>
            <option value="16">
              16
            </option>
            <option value="17">
              17
            </option>
            <option value="18">
              18
            </option>
            <option value="19">
              19
            </option>
            <option value="20">
              20
            </option>
            <option value="21">
              21
            </option>
            <option value="22">
              22
            </option>
            <option value="23">
              23
            </option>
            <option value="24">
              24
            </option>
            <option value="25">
              25
            </option>
            <option value="26">
              26
            </option>
            <option value="27">
              27
            </option>
            <option value="28">
              28
            </option>
            <option value="29">
              29
            </option>
            <option value="30">
              30
            </option>
          </select>
          &nbsp;
          {{ repeatIntervalUnits }}
        </div>
        <div
          v-if="[`weekly`, `monthly`].includes(localRepeat.frequency)"
          class="kn-datetime_optionRow"
        >
          <label>Repeat on:</label>
          <div
            v-if="localRepeat.frequency === `weekly`"
            class="input-labels is-horizontal tw-input-labels"
          >
            <label><input
              v-model="localRepeat.SU"
              type="checkbox"
            >Su</label>
            <label><input
              v-model="localRepeat.MO"
              type="checkbox"
            >Mo</label>
            <label><input
              v-model="localRepeat.TU"
              type="checkbox"
            >Tu</label>
            <label><input
              v-model="localRepeat.WE"
              type="checkbox"
            >We</label>
            <label><input
              v-model="localRepeat.TH"
              type="checkbox"
            >Th</label>
            <label><input
              v-model="localRepeat.FR"
              type="checkbox"
            >Fr</label>
            <label><input
              v-model="localRepeat.SA"
              type="checkbox"
            >Sa</label>
          </div>
          <div
            v-else-if="localRepeat.frequency === `monthly`"
            class="input-labels tw-input-labels"
          >
            <label><input
              v-model="localRepeat.repeatby"
              value="dom"
              type="radio"
            >Day of the month</label>
            <label><input
              v-model="localRepeat.repeatby"
              value="dow"
              type="radio"
            >Day of week</label>
          </div>
        </div>
        <div class="kn-datetime_optionRow">
          <label>Ends:</label>
          <div class="input-labels">
            <label><input
              v-model="localRepeat.endson"
              value="never"
              type="radio"
            >Never</label>
            <label><input
              v-model="localRepeat.endson"
              value="limit"
              type="radio"
            >After&nbsp;<input
              v-model="localRepeat.end_count"
              class="end-count"
              type="text"
            >&nbsp;occurences</label>
            <label><input
              v-model="localRepeat.endson"
              value="date"
              type="radio"
            >On&nbsp;
              <Datepicker
                :id="field.key"
                v-model="localRepeatEndDate"
                name="endDate"
                class="kn-datepicker input end-date"
                placeholder=""
                :typeable="true"
                :parse-typed-date="parseTypedDateForDatepicker"
                :format="dateFormat"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import DateUtils from '@/components/renderer/mixins/DateUtils';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';
import Datepicker from '@/components/ui/inputs/datepicker/Datepicker';
import TimePicker from '@/components/ui/inputs/TimePicker';

export default {
  name: 'DateInput',
  components: {
    Datepicker,
    TimePicker,
  },
  mixins: [
    TranslationUtils,
    DateUtils,
  ],
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: [
        Object,
        String, // can be an empty string
      ],
      required: true,
    },
    hideTime: {
      type: Boolean,
      default: false,
    },
    ignoreCalendarOptions: {
      type: Boolean,
      default: false,
    },
    ignoreTimeOptions: {
      type: Boolean,
      default: false,
    },
    numericTimeFormat: {
      type: Boolean,
      default: true,
    },
    // In case we need to model any different behavior if it's in a popover
    // E.g. timepicker renders as static so if you click in it, it doesn't close the popover
    isInPopover: {
      type: Boolean,
      default: false,
    },
    allowsAnyDate: {
      type: Boolean,
      default: false,
    },
    allowsAnyTime: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isAnyTimeEnabled: false,
      storedTimeValue: '',
      isAnyDateEnabled: false,
      storedDateValue: '',
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    // localDate expects the date property to be formatted in this.dateFormat
    localDate: {
      get() {
        // Blank date is saved as an empty string
        if (this.ignoreDate || this.localValue.date === '') {
          return '';
        }

        if (!this.localValue.date) {
          return this.defaultDate;
        }

        return moment(this.localValue.date, this.dateFormat).format();
      },
      set(newVal) {
        if (this.ignoreDate) {
          return;
        }

        let newDate;
        if (this.isAnyDateEnabled || !newVal) {
          newDate = { date: '' };
          this.storedDateValue = this.localDate;
        } else {
          newDate = { date: this.parsedDate(newVal, this.dateFormat) };
        }

        this.localValue = { ...this.localValue, ...newDate };
      },
    },
    localToDate: {
      get() {
        if (this.ignoreDate) {
          return '';
        }

        if (!hasIn(this.localValue, 'to.date')) {
          return this.defaultDate;
        }

        return moment(this.localValue.to.date, this.dateFormat).format();
      },
      set(newVal) {
        if (this.ignoreDate) {
          return;
        }

        const newTo = {
          to: { ...this.localValue.to, date: this.parsedDate(newVal, this.dateFormat) },
        };

        this.localValue = { ...this.localValue, ...newTo };
      },
    },
    localRepeatEndDate: {
      get() {
        if (!hasIn(this.localValue, 'repeat.end_date') || !this.localValue.repeat.end_date) {
          return this.defaultDate;
        }

        return moment(this.localValue.repeat.end_date, this.dateFormat).format();
      },
      set(newVal) {
        const newRepeat = {
          repeat: { ...this.localValue.repeat, end_date: this.parsedDate(newVal, this.dateFormat) },
        };

        this.localValue = { ...this.localValue, ...newRepeat };
      },
    },
    localTime: {
      get() {
        if (hasIn(this.localValue, 'time')) {
          return this.numericTimeFormat
            ? this.parsedTimeRawValue(this.localValue.time, this.timeFormat)
            : this.localValue.time.toLowerCase();
        }

        return this.input.format.time_type !== 'none' ? this.getDefaultTime(this.input) : '';
      },
      set(newValue) {
        let newTime;
        if (this.isAnyTimeEnabled || !newValue) {
          newTime = {
            hours: '',
            minutes: '',
            am_pm: '',
            time: '',
          };
          this.storedTimeValue = this.localTime;
        } else {
          newTime = {
            hours: String(this.parsedHours(newValue, this.timeFormat)),
            minutes: String(this.parsedMinutes(newValue, this.timeFormat)),
            am_pm: this.parsedMeridiem(newValue, this.timeFormat),
            time: this.numericTimeFormat
              ? this.parsedMinutesFromMidnight(newValue, this.timeFormat)
              : newValue.toLowerCase(),
          };
        }

        this.localValue = { ...this.localValue, ...newTime };
      },
    },
    localToTime: {
      get() {
        if (hasIn(this.localValue, 'to.time')) {
          return this.parsedTimeRawValue(this.localValue.to.time, this.timeFormat);
        }

        const isEndTime = true;

        return this.input.format.time_type !== 'none' ? this.getDefaultTime(this.input, isEndTime) : '';
      },
      set(newValue) {
        const newTo = {
          to: {
            ...this.localValue.to,
            hours: String(this.parsedHours(newValue, this.timeFormat)),
            minutes: String(this.parsedMinutes(newValue, this.timeFormat)),
            am_pm: this.parsedMeridiem(newValue, this.timeFormat),
            time: this.parsedMinutesFromMidnight(newValue, this.timeFormat),
          },
        };

        this.localValue = { ...this.localValue, ...newTo };
      },
    },
    localRepeat: {
      get() {
        // If we have current value return that
        if (!isEmpty(this.localValue.repeat)) {
          return this.localValue.repeat;
        }

        // otherwise return default
        return {
          interval: 1,
          frequency: 'daily',
          endson: 'never',
          end_count: 1,
          end_date: '',
        };
      },
      set(newValue) {
        const newRepeat = {
          repeat: { ...this.localValue.repeat, ...newValue },
        };

        this.localValue = { ...this.localValue, ...newRepeat };
      },
    },
    showRepeat: {
      get() {
        if (!hasIn(this.localValue, 'repeat') || isEmpty(this.localValue.repeat)) {
          return false;
        }

        // check against empty repeat object
        return hasIn(this.localValue, 'repeat.frequency');
      },
      set(newValue) {
        if (newValue) {
          const newRepeat = {
            repeat: { ...this.localValue.repeat, ...this.localRepeat },
          };

          this.localValue = { ...this.localValue, ...newRepeat };
        } else {
          delete this.localValue.repeat;
        }
      },
    },
    dateFormat() {
      return this.getMomentDateFormatFromKnackDateFormat(this.input.format.date_format);
    },
    defaultDate() {
      return moment(this.getDefaultDate(this.input)).format();
    },
    ignoreDate() {
      return this.input.format.date_format === 'Ignore Date';
    },
    ignoreTime() {
      if (this.ignoreTimeOptions) {
        return true;
      }

      return this.isAllDay || this.hideTime || this.input.format.time_format === 'Ignore Time';
    },
    hasNoTimeType() {
      return this.input.format.time_type === 'none';
    },
    hasCalendarOptions() {
      return !this.ignoreCalendarOptions && this.input.format.calendar;
    },
    timeFormat() {
      return this.input.format.time_format;
    },
    repeatIntervalUnits() {
      switch (this.localRepeat.frequency) {
        case 'yearly':

          return 'years';

        case 'monthly':

          return 'months';

        case 'weekly':

          return 'weeks';

        case 'daily':

          return 'days';
        default:

          return '';
      }
    },

    /**
       * Whether the "All Day" checkbox is selected or not
       * @returns {Boolean}
       */
    isAllDay() {
      return this.localValue.all_day;
    },
  },
  mounted() {
    // remove timepicker plugin styles because they were overriding ours
    const $timepickers = this.$el.querySelectorAll('.date-time-picker input');

    for (const $timepicker of $timepickers) {
      $timepicker.classList.remove('field-input');
    }
  },
  created() {
    // We want to store a version of localDate and localTime to maintain the value
    // after we toggle the 'any' checkboxes on
    this.storedDateValue = this.localDate;
    this.storedTimeValue = this.localTime;

    // If a user has an empty date/time value stored and the field has both date and time,
    // we want to toggle the 'any' checkbox on by default
    if (!this.ignoreDate && !this.ignoreTime) {
      if (this.localDate === '' && this.allowsAnyDate) {
        this.isAnyDateEnabled = true;
      }

      if (this.localTime === '' && this.allowsAnyTime) {
        this.isAnyTimeEnabled = true;
      }
    }

    // if a Date field was added to an existing record the initial value will be an empty string
    // so we will need to set the value manually for editing purposes
    if (this.localValue === '') {
      const defaultValue = {
        all_day: false,
      };

      if (!this.ignoreDate) {
        defaultValue.date = this.getFormattedDefaultDate(this.input);
      }

      if (!this.ignoreTime) {
        defaultValue.hours = this.parsedHours(this.localTime, this.timeFormat);
        defaultValue.minutes = this.parsedMinutes(this.localTime, this.timeFormat);
        defaultValue.am_pm = this.parsedMeridiem(this.localTime, this.timeFormat);
        defaultValue.time = this.numericTimeFormat
          ? this.parsedMinutesFromMidnight(this.localTime, this.timeFormat)
          : this.localTime;
      }

      if (this.hasCalendarOptions) {
        defaultValue.to = {};

        if (!this.ignoreDate) {
          defaultValue.to.date = this.getFormattedDefaultDate(this.input);
        }

        if (!this.ignoreTime) {
          defaultValue.to.hours = this.parsedHours(this.localToTime, this.timeFormat);
          defaultValue.to.minutes = this.parsedMinutes(this.localToTime, this.timeFormat);
          defaultValue.to.am_pm = this.parsedMeridiem(this.localToTime, this.timeFormat);
          defaultValue.to.time = this.numericTimeFormat
            ? this.parsedMinutesFromMidnight(this.localToTime, this.timeFormat)
            : this.localToTime;
        }
      }

      this.localValue = defaultValue;
    }
  },
  methods: {
    customFormatter(date) {
      return moment(date).format(this.dateFormat);
    },

    /**
       * When the "All Day" checkbox is toggled on, set the time fields to midnight.
       */
    onAllDayToggle() {
      const start = '12:00AM';
      const end = '12:00PM';

      // This is done via an event emit to avoid using `$nextTick` when
      // modifying both `localTime` then `localToTime` in local state,
      // since the `localToTime`event will clobber the `localTime` if it isn't given time to re-calculate `localValue`
      this.$emit('update:modelValue', {
        ...this.localValue,
        hours: this.parsedHours(start, this.timeFormat),
        minutes: this.parsedMinutes(start, this.timeFormat),
        am_pm: this.parsedMeridiem(start, this.timeFormat),
        time: this.parsedMinutesFromMidnight(start, this.timeFormat),
        to: {
          ...this.localValue.to,
          hours: this.parsedHours(end, this.timeFormat),
          minutes: this.parsedMinutes(end, this.timeFormat),
          am_pm: this.parsedMeridiem(end, this.timeFormat),
          time: this.parsedMinutesFromMidnight(end, this.timeFormat),
        },
      });
    },

    onAnyDateToggle() {
      if (this.isAnyDateEnabled) {
        this.localDate = '';
      } else {
        this.localDate = this.storedDateValue;
      }
    },

    onAnyTimeToggle() {
      if (this.isAnyTimeEnabled) {
        this.localTime = '';
      } else {
        this.localTime = this.storedTimeValue;
      }
    },
  },
};
</script>

<style lang="scss">
// anchors the right side of the rightmost calendar dropdown
// to the right side of its parent input so it doesn't render off screen
.kn-datepicker.right > .vdp-datepicker__calendar {
  right: 0;
}

// aligns right side of time-picker with right side of its parent input
.kn-time.input.right .rightMost {
  right: 0;
}
</style>

<style lang="scss" scoped>
.kn-datetime_controls {
  display: grid;
  min-width: 0;
  max-width: max-content;
  align-items: center;
  grid-template-columns: auto auto auto;
}

.kn-datetime_control {
  .kn-datepicker {
    max-width: 120px;
  }
  .kn-time {
    max-width: 75px;
  }
}
.kn-calendar-options {
  margin-top: .5em;

  span {
    font-weight: 500;
    margin-right: .5em;
  }
}

// override
.input-labels label {
  align-items: center;
  margin-bottom: .25em;
}

.kn-date-wrapper {
  display:flex;
  align-items:center;
}

.kn-date-separator {
  padding: 0 10px;
}

.kn-datetime {
  margin-bottom: 0;
  flex-basis: 65%;
}

.kn-datetime_inputs {
  display: grid;
  grid-template-columns: auto auto;
}

.kn-datetime__any-value-container {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}

.kn-datetime__any-value-checkbox {
  margin-right: 0.22rem;
}

.kn-datetime__any-value-label {
  font-size: 0.85rem;
  margin-bottom: 0;
  font-weight: normal;
}

input.end-count {
  width: 40px;
}

input.end-date {
  width: 150px;
}

select.repeat-frequency {
  width: 150px;
}

select.repeat-interval {
  width: 60px;
}
</style>
