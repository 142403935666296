const storeState = {
  visibilityByPageKey: {},
};

const storeGetters = {
  getPageNavVisibility: (state) => (pageKey) => Boolean(state.visibilityByPageKey[pageKey]),
};

const storeMutations = {

  /**
   * Updates the child visibility for a page.
   *
   * @param {object} state
   * @param {object} payload
   * @param {string} payload.pageKey
   * @param {boolean} payload.isVisible
   */
  updateVisibility(state, { pageKey, isVisible }) {
    state.visibilityByPageKey[pageKey] = Boolean(isVisible);
  },

  /**
   * Clears the child visibility for a page.
   *
   * @param {object} state
   * @param {object} payload
   * @param {string} payload.pageKey
   */
  clearVisibility(state, { pageKey }) {
    delete state.visibilityByPageKey[pageKey];
  },
};

const storeActions = {

  /**
   * Shows the children for a page on the nav.
   *
   * @param {object} context
   * @param {function(string, object)} context.commit
   * @param {string} pageKey
   */
  showNavChildren({ commit }, pageKey) {
    commit('updateVisibility', {
      pageKey,
      isVisible: true,
    });
  },

  /**
   * Hides the children for a page on the nav.
   *
   * @param {object} context
   * @param {function(string, object)} context.commit
   * @param {string} pageKey
   */
  hideNavChildren({ commit }, pageKey) {
    commit('updateVisibility', {
      pageKey,
      isVisible: false,
    });
  },

  /**
   * Clears the visibility for a page.
   *
   * @param {object} context
   * @param {function(string, object)} context.commit
   * @param {string} pageKey
   */
  clear({ commit }, pageKey) {
    commit('clearVisibility', {
      pageKey,
    });
  },
};

export default {
  // Full namespace: page/navVisibility
  navVisibility: {
    namespaced: true,
    state: storeState,
    getters: storeGetters,
    mutations: storeMutations,
    actions: storeActions,
  },
};
