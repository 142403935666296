<template>
  <div
    :id="filterId"
    class="kn-filters"
    data-cy="filter-summary"
  >
    <FilterLink
      v-for="(filter, index) in filters"
      class="group-[&:not(.kn-view)]/shared:rounded group-[&:not(.kn-view)]/shared:bg-brand-100"
      :key="index"
      :filter="filter"
      :index="index"
      @delete="onDeleteFilter"
      @edit="onEditFilter"
    />
  </div>
</template>

<script>
import FilterLink from '@/components/renderer/shared/FilterLink';

export default {
  components: {
    FilterLink,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['deleteFilter', 'editFilter'],
  computed: {
    filterId() {
      return `something_filters`;
    },
  },
  methods: {
    onDeleteFilter(index) {
      log('Summary.onDeletFilter() index: ', index);

      this.$emit('deleteFilter', index);
    },
    onEditFilter(index) {
      this.$emit('editFilter', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.kn-filters {
  display: flex;
  white-space: nowrap;
  overflow: hidden;

  .kn-button {
    border: none;
  }
}
</style>
