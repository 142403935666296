<template>
  <form
    class="form-wrapper"
    @submit.prevent="onSubmit"
  >
    <ValidationError
      v-if="errors.length"
      :errors="errors"
    />

    <slot />

    <div
      v-if="showSubmit"
      class="kn-submit submit-buttons text-end p-0 m-0"
    >
      <button
        data-cy="save"
        class="button save text-white px-3 py-2 bg-gradient-primary rounded-lg border-0 text-base font-medium"
        :disabled="isSubmitButtonDisabled"
        :data-feature="submitText === 'Add Field' ? 'save_field_button' : ''"
        data-feature-x-offset="-26"
        data-feature-y-offset="-26"
        @click="onSubmit"
      >
        {{ submitText }}
      </button>
    </div>
  </form>
</template>

<script>
import ValidationError from '@/components/ui/ValidationError';

export default {
  name: 'FormWrapper',
  components: {
    ValidationError,
  },
  props: {
    showSubmit: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: 'Submit',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isSubmitButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submit'],
  methods: {
    onSubmit(event) {
      if (this.isSubmitButtonDisabled) return;

      // prevent enter form submits
      event.preventDefault();

      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss">
.kn-submit {
  margin: 0;

  .button {
    font-weight: 500;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
