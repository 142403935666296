<template>
  <ViewToolbox
    back-title="Form Actions & Rules"
    title="Submit Actions"
  >
    <p
      class="text-sm leading-5 mb-0"
      style="margin-bottom: 1em;"
    >
      Add rules for where to direct the user after the form is submitted. The first rule that matches will be used.
    </p>
    <SubmitRules />
  </ViewToolbox>
</template>

<script>
import SubmitRules from '@/components/views/shared/SubmitRules';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  components: {
    SubmitRules,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  watch: {
    'viewRaw.rules.submits': function (newVal) {
      // This will ensure ecommerce = false when any submit rules are removed
      this.viewRaw.ecommerce = Boolean(newVal?.some((rule) => rule.ecommerce));
    },
  },
};
</script>

<style lang="scss">
</style>
