<template>
  <select
    :value="modelValue"
    data-cy="page-select"
    @change="onChange($event.target.value)"
  >
    <option
      v-for="page in pages"
      :key="page.key"
      :value="page.value"
    >
      {{ page.label }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    objectKey: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    pages() {
      const pageOptions = [];

      const pages = (this.objectKey)
        ? this.$store.getters.getStarterPlusChildPagesByObject(this.objectKey, false)
        : this.$store.getters.getStarterPlusGlobalChildPages();

      pages.forEach((page) => {
        pageOptions.push({
          value: page.slug,
          label: page.label,
        });
      });

      return pageOptions;
    },
  },
  created() {
    if (!this.modelValue) {
      this.onChange(this.pages[0].value);
    }
  },
  methods: {
    onChange(newValue) {
      this.$emit('update:modelValue', newValue);
    },
  },
};
</script>
