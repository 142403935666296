<template>
  <svg
    ref="svg"
    :viewBox="icon.viewBox"
    :class="classes"
  >
    <use :xlink:href="icon.id" />
  </svg>
</template>

<script>
import {
  ref,
  toRefs,
  watch,
  getCurrentInstance,
} from 'vue';

const ICON_ALIASES = {
  address: 'address-dropmark',
  auto_increment: 'auto-increment',
  boolean: 'check-box',
  connection: 'database-lookup',
  date_time: 'date-time',
  file: 'attach-file',
  min: 'sigma',
  max: 'sigma',
  average: 'sigma',
  sum: 'sigma',
  count: 'sigma',
  multiple_choice: 'radio-button-checked',
  paragraph_text: 'paragraph-text',
  password: 'key',
  rating: 'rating-star',
  rich_text: 'rich-text',
  short_text: 'short-text',
  concatenation: 'text-formula',
  user_roles: 'users',
};

export default {
  name: 'Icon',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { type } = toRefs(props);

    const icon = ref({});
    const classes = ref(['icon', props.class]);
    const svg = ref(null);

    const loadSpriteData = () => {
      const iconType = ICON_ALIASES[type.value] || type.value;

      // The svg file is processed using svg-sprite-loader
      // @see https://github.com/JetBrains/svg-sprite-loader#runtime-configuration
      const iconSpriteSymbol = require(`@/assets/svgs/${iconType}.svg`).default;

      classes.value = [
        'icon',
        `icon-${iconType}`,
        props.class,
      ];

      icon.value.id = `#${iconSpriteSymbol.id}`;
      icon.value.viewBox = iconSpriteSymbol.viewBox;
    };

    loadSpriteData();

    watch(type, loadSpriteData);

    const instance = getCurrentInstance();
    if (instance) {
      instance.exposeProxy = { svg };
    }

    return {
      classes,
      icon,
      svg,
    };
  },
};

/**
 * Notes about extract mode vs inline mode.
 *
 * We were using extract mode to pull the svg sprite out into its own file. The worked well but
 * required a custom route on the server to fetch the file to fix XSS browser issues. We went back
 * to inline because this was causing icons to blip whenever the Icon re-rendered. It seems the
 * way it is loaded from the external file takes a noticeable amount of time.
 *
 * See commit `8378e936b0b4ae1b24eddc571c248da87b3a8051` for how to use extract mode here.
 */
</script>
