<template>
  <div class="pagination-select__wrapper">
    <div
      v-if="showPageSelect"
      class="kn-select group-[&:not(.kn-view)]/shared:mx-2"
    >
      <select
        name="page_select"
        class="kn-page-select group-[&:not(.kn-view)]/shared:text-base group-[&:not(.kn-view)]/shared:py-2 group-[&:not(.kn-view)]/shared:pl-3 group-[&:not(.kn-view)]/shared:leading-5"
        :value="currentPage"
        @change="$emit('currentPageSelect', $event.target.value)"
      >
        <option
          v-for="page in totalPages"
          :value="page"
        >
          {{ pageTranslation }} {{ page }}
        </option>
      </select>
    </div>
    <div
      v-if="showPageSelect"
      class="kn-total-pages group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-base"
    >
      {{ trans('of') }} {{ totalPages }}
    </div>
  </div>
</template>

<script>
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';

export default {
  mixins: [
    TranslationUtils,
  ],
  props: [
    'totalPages',
    'currentPage',
  ],
  emits: ['currentPageSelect'],
  computed: {
    showPageSelect() {
      return this.totalPages > 1;
    },
    pageTranslation() {
      return this.trans('page');
    },
  },
};
</script>
<style scoped lang="scss">
.pagination-select {

  &__wrapper {
    display:flex;
    align-items:center;
  }
}

.kn-select {
  margin-right:5px;
}
</style>
