<template>
  <Modal
    title="Add Payment View"
    :back="backLink"
  >
    <ViewAddPaymentTotalField
      v-if="showWizardStep(1)"
      v-model:wizard-step="currentWizardStep"
      :step="1"
      :view="newLocalView"
      @next="nextWizardStep"
    />

    <ViewAddPaymentProcessors
      v-if="showWizardStep(2)"
      v-model:wizard-step="currentWizardStep"
      :step="2"
      :view="newLocalView"
      @next="nextWizardStep"
    />

    <ViewAddPaymentMethod
      v-if="showWizardStep(3)"
      v-model:wizard-step="currentWizardStep"
      :step="3"
      :page="page"
      :view="newLocalView"
      @next="nextWizardStep"
    />

    <ViewAddPaymentConfirm
      v-if="showWizardStep(4)"
      v-model:wizard-step="currentWizardStep"
      :step="4"
      :view="newLocalView"
      @submit="updateView"
    />
  </Modal>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import Modal from '@/components/ui/Modal';
import ViewAddPaymentTotalField from '@/components/pages/page/ViewAddPaymentTotalField';
import ViewAddPaymentProcessors from '@/components/pages/page/ViewAddPaymentProcessors';
import ViewAddPaymentMethod from '@/components/pages/page/ViewAddPaymentMethod';
import ViewAddPaymentConfirm from '@/components/pages/page/ViewAddPaymentConfirm';
import ViewUtils from '@/components/views/ViewUtils';
import View from '@/store/models/View';
import { buildViewSchema } from '@/lib/schema-helper';
import { NEW_VIEW_KEY } from '@/lib/page/page-constants';

export default {
  name: 'ChangeAction',
  components: {
    Modal,
    ViewAddPaymentTotalField,
    ViewAddPaymentProcessors,
    ViewAddPaymentMethod,
    ViewAddPaymentConfirm,
  },
  mixins: [
    ViewUtils,
  ],
  props: {
    backLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentWizardStep: 1,
      newLocalView: {},
    };
  },
  created() {
    let newView = {
      key: NEW_VIEW_KEY,
      type: 'checkout',
      source: this.view.source,
    };

    newView = buildViewSchema(this.page.raw, newView, {
      formAddView: true,
    });
    this.newLocalView = new View(newView, this.page);
  },
  methods: {
    showWizardStep(step) {
      return this.currentWizardStep >= step;
    },
    nextWizardStep() {
      this.currentWizardStep += 1;
    },
    updateView() {
      // this route will actually set the key to `new` if we don't remove it
      delete this.newLocalView.attributes.key;

      const formName = this.viewRaw.title || `${upperFirst(this.view.type)}Charge`;

      const scene = {
        name: `${formName} Payment`,
        object: this.view.source.object,
        parent: this.page.slug,
        modal: false,
        source: {
          view: this.view.key,
        },
        views: [
          this.newLocalView.raw(),
        ],
      };

      const existingSubmits = this.viewRaw.rules.submits;

      this.viewRaw.ecommerce = true;
      this.viewRaw.rules.submits = [
        {
          key: 'submit_1',
          action: 'child_page',
          criteria: [
            {
              field: this.newLocalView.totalField,
              operator: 'higher than',
              value: 0,
            },
          ],
          ecommerce: true,
          is_default: false,
          scene,
        },
      ];

      let sceneIndex = 2;

      existingSubmits.forEach((submit) => {
        this.viewRaw.rules.submits.push({
          ...submit,
          key: `submit_${sceneIndex}`,
        });

        sceneIndex += 1;
      });

      this.$router.push(this.backLink);
    },
  },
};
</script>
