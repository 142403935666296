<template>
  <Modal
    :classes="[`field-type-modal`]"
    size="large"
    :title="modalTitle"
    title-size="small"
    :back="backLink"
  >
    <div>
      <div class="field-type-modal__wrapper">
        <section class="field-type-modal__sidebar border-r-gray-100">
          <div
            class="field-type-modal__list"
            style="max-height: calc(100vh - 280px);"
          >
            <FieldTypesList
              @click="onUpdateActiveField"
            />
          </div>
        </section>
        <main class="field-type-modal__content">
          <section id="field-convert-description">
            <header
              v-if="!isSameField && fieldToConvertTo"
              class="text-emphasis"
            >
              Change {{ field.name }} to a {{ fieldToConvertTo.name }} field
            </header>
            <header
              v-else
              class="text-emphasis"
            >
              Change {{ field.name }} to another field type
            </header>

            <template v-if="isSameField || !fieldToConvertTo">
              <p class="text-default mb-0">
                Select a new type to convert from a <b>{{ currentFieldSchema.name }}</b> field.<br>
              </p>
            </template>
            <p
              v-else
              class="text-default"
              v-html="fieldToConvertTo.description"
            />

            <article
              v-if="compatibility && !isSameField"
              class="rounded-lg border-none p-4"
              :class="articleClasses"
            >
              <div class="icon-wrapper text-inherit">
                <Icon
                  class="text-inherit"
                  :type="compatibilityIcon"
                />
              </div>
              <div class="compatibility">
                <template v-if="compatibility === 'always'">
                  <template v-if="exceedsRecordLimitForAutoConversion">
                    <p class="m-0 mb-2">
                      <b>{{ currentFieldTypeName }}</b> to <b>{{ fieldToConvertName }}</b> is compatible.
                    </p>
                    <p class="m-0">
                      However, the number of records in the <strong>{{ object.name }}</strong> table exceeds the limit
                      for automatic conversion. Please contact our Customer Support team and they will be happy to
                      assist with completing this larger conversion.
                    </p>
                  </template>
                  <template v-else>
                    <b>{{ currentFieldTypeName }}</b> to <b>{{ fieldToConvertName }}</b> is compatible.
                    Your data can be safely converted.
                  </template>
                </template>

                <template v-else>
                  <b>{{ currentFieldTypeName }}</b> to <b>{{ fieldToConvertName }}</b> is incompatible.
                  <ul class="list-disc mt-2">
                    <li>All data in this field will be <span class="bold-red">deleted</span> with this change</li>
                    <li>
                      The "Required" setting will be <span class="bold-red">unchecked</span>
                      and the "Default Value" will be <span class="bold-red">deleted</span>
                    </li>
                    <li><span class="bold-red">No record history</span> will be logged</li>
                    <li>Records linked by formulas or equations will <span class="bold-red">not be updated</span></li>
                    <li>Tasks or rules using this field <span class="bold-red">may need updating</span> to run as expected</li>
                  </ul>
                </template>
              </div>
            </article>

            <div
              v-if="shouldShowCompatibilityAcknowledgement"
              class="compatibility-acknowledgement"
            >
              <label class="mt-1">
                <input
                  v-model="hasAcknowledgedCompatibilityWarning"
                  type="checkbox"
                >
                <span>I understand</span>
              </label>
            </div>

            <div
              v-if="fieldToConvertTo.type === `connection`"
              class="connection border-t-gray-100"
            >
              <div class="margin-bottom-half">
                What object should <b>{{ activeObject.name }}</b> connect to?
              </div>
              <form>
                <div>
                  <select
                    v-model="connectedObjectKey"
                    data-cy="connection-object-select"
                  >
                    <option
                      v-for="obj in objects"
                      :key="obj.key"
                      :value="obj.key"
                    >
                      {{ obj.name }}
                    </option>
                  </select>
                </div>
                <div>
                  Each <b>{{ object.inflections.singular }}</b> connects with<select
                    v-model="connectedHas"
                    class="inline"
                    style="display: inline; width: max-content;"
                  >
                    <option value="one">
                      one
                    </option>
                    <option value="many">
                      many
                    </option>
                  </select><b>{{ fromName }}</b>
                </div>
                <div>
                  Each <b>{{ connectedObject.inflections.singular }}</b> connects with<select
                    v-model="connectedBelongsTo"
                    class="inline"
                    style="display: inline; width: max-content;"
                  >
                    <option value="one">
                      one
                    </option>
                    <option value="many">
                      many
                    </option>
                  </select><b>{{ toName }}</b>
                </div>
              </form>
            </div>
          </section>

          <FieldTypeConversionPreview />
        </main>
      </div>
    </div>

    <div class="submit-buttons flex justify-end gap-4 border-t-gray-100">
      <button
        class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base font-medium m-0 leading-4 order-2"
        :disabled="isSubmitDisabled"
        @click="onSubmit"
      >
        Save
      </button>
      <button
        class="button gray-outline p-3 rounded-lg bg-transparent border-0 text-emphasis font-medium m-0 leading-4 order-1"
        @click="$router.push(backLink)"
      >
        Cancel
      </button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import isNil from 'lodash/isNil';
import {
  isCRM,
} from '@/lib/env-helper';
import { FIELD_CONVERSION_RECORD_LIMIT } from '@/constants/field';
import FieldUtils from '@/components/fields/FieldUtils';
import RequestUtils from '@/components/util/RequestUtils';
import QueryMixin from '@/components/renderer/mixins/QueryMixin';
import Modal from '@/components/ui/Modal';
import Icon from '@/components/ui/Icon';
import FieldTypesList from '@/components/fields/FieldTypesList';
import FieldTypeConversionPreview from '@/components/fields/FieldTypeEdit/FieldTypeConversionPreview';

export default {
  components: {
    FieldTypesList,
    FieldTypeConversionPreview,
    Icon,
    Modal,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
    QueryMixin,
  ],
  data() {
    return {
      searchText: '',
      fieldType: null,
      submitIsLoading: false,
      connectedObjectKey: '',
      connectedHas: 'one',
      connectedBelongsTo: 'many',
      hasAcknowledgedCompatibilityWarning: false,
    };
  },
  computed: {
    ...mapGetters([
      'objects',
      'getObject',
      'getField',
      'activeObject',
    ]),
    isSameField() {
      // if no field type has been selected, treat it as a conversion to the same field type
      if (!this.fieldToConvertTo) {
        return true;
      }

      return this.currentFieldSchema.type === this.fieldToConvertTo.type;
    },
    isSubmitDisabled() {
      // The selected field type is the same as the existing field type
      if (this.isSameField) {
        return true;
      }

      // The conversion is compatible but the table has more records than the limit
      // for automatic conversion, and it's not a CRM domain
      if (this.compatibility === 'always') {
        return this.exceedsRecordLimitForAutoConversion && !isCRM();
      }

      // The conversion is incompatible and user hasn't acknowledged the incompatibility warning
      return this.shouldShowCompatibilityAcknowledgement && !this.hasAcknowledgedCompatibilityWarning;
    },
    currentFieldSchema() {
      return window.Knack.getFieldByType(this.field.type);
    },
    currentFieldTypeName() {
      return this.currentFieldSchema.name;
    },
    fieldToConvertTo() {
      if (isNil(this.fieldType)) {
        return false;
      }

      return window.Knack.getFieldByType(this.fieldType);
    },
    fieldToConvertName() {
      return this.fieldToConvertTo ? this.fieldToConvertTo.name : '';
    },
    modalTitle() {
      return 'Change Field Type'; // - ${this.field.name}`
    },
    exceedsRecordLimitForAutoConversion() {
      if (isNil(this.fieldType)) {
        return false;
      }

      return this.totalRecords > FIELD_CONVERSION_RECORD_LIMIT;
    },
    compatibility() {
      if (isNil(this.fieldType)) {
        return null;
      }

      if (this.currentFieldSchema.conversions.always.includes(this.fieldType)) {
        return 'always';
      }

      return 'never';
    },
    backLink() {
      return this.$route.path.split(`/${this.field.key}`)[0];
    },
    compatibilityIcon() {
      if (this.compatibility === 'always') {
        if (this.exceedsRecordLimitForAutoConversion) return 'warning';
        return 'check-circle';
      }

      return 'error';
    },
    articleClasses() {
      return {
        'article--success bg-success text-success-emphasis': this.compatibility === 'always' && !this.exceedsRecordLimitForAutoConversion,
        'article--warning bg-warning bg-warning-emphasis': this.compatibility === 'always' && this.exceedsRecordLimitForAutoConversion,
        'article--failure bg-destructive text-destructive-emphasis': this.compatibility === 'never',
      };
    },
    connectedObject() {
      return this.$store.getters.getObject(this.connectedObjectKey);
    },
    fromName() {
      const inflection = (this.connectedHas === 'one') ? 'singular' : 'plural';

      return this.connectedObject.inflections[inflection];
    },
    toName() {
      const inflection = (this.connectedBelongsTo === 'one') ? 'singular' : 'plural';

      return this.object.inflections[inflection];
    },
    shouldShowCompatibilityAcknowledgement() {
      // Only show the acknowledgement checkbox if data can be lost in the conversion
      return !this.isSameField && this.compatibility === 'never';
    },
    fieldUpdates() {
      const updates = {
        type: this.fieldType,
      };

      if (this.shouldShowCompatibilityAcknowledgement) {
        updates.unique = false;
        updates.required = false;
      }

      if (this.fieldType === 'connection') {
        updates.relationship = {
          object: this.connectedObjectKey,
          has: this.connectedHas,
          belongs_to: this.connectedBelongsTo,
        };
      }

      return updates;
    },
  },
  created() {
    const queryVars = {
      currentPage: 1,
      recordsPerPage: 1,
      sort: [],
      filters: {
        match: 'and',
        rules: [],
      },
      search: '',
    };

    this.queryRecords(queryVars);

    this.connectedObjectKey = this.objects[0].key;

    // don't suggest the same object to connect to if possible
    if (this.connectedObjectKey === this.object.key && this.objects.length > 1) {
      this.connectedObjectKey = this.objects[1].key;
    }
  },

  methods: {
    onSubmit() {
      // Prevent submission if submit button is supposed to be disabled. This is for cases where a user might manually
      // remove the "disabled" property of the button in the DOM and execute a submission.
      if (this.isSubmitDisabled) {
        return;
      }

      if (this.isSameField && this.field.type !== 'connection') {
        this.$router.push(this.backLink);
        return;
      }

      this.submitIsLoading = true;

      this.commitRequest({
        request: () => this.field.update(this.fieldUpdates),
        onSuccess: () => {
          this.submitIsLoading = false;

          this.field.type = this.fieldUpdates.type;
          if (this.fieldUpdates.relationship) {
            this.field.set('relationship', this.fieldUpdates.relationship);
          }

          this.$router.push(this.backLink);
        },
        onError: (errors) => {
          const errorMessage = errors?.find((error) => error.message)?.message
            || 'Try refreshing the page and attempting the action again, or contact Knack support.';
          this.$notify({
            group: 'top-right',
            type: 'error',
            duration: 8000,
            'animation-type': 'velocity',
            title: 'Request Error',
            text: errorMessage,
            data: {
              titleIcon: 'dialogue',
            },
          });
        },
      });
    },
    onUpdateActiveField(fieldType) {
      log('onUpdateActiveField', fieldType);

      this.fieldType = fieldType;
    },
    queryRecordsRequest(queryVars, onSuccess, onError, onAbort) {
      if (!this.$route.params.objectKey) {
        onAbort();
        return;
      }

      this.commitRequest({
        request: () => window.Knack.Api.getRecords(
          this.$route.params.objectKey,
          queryVars.filters,
          queryVars.search,
          queryVars.currentPage,
          queryVars.recordsPerPage,
          queryVars.sort.field,
          queryVars.sort.order,
        ),
        onSuccess,
        onError,
        globalLoading: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.field-type-modal {

  .intro {
    padding: 1em 1em 0;
    margin-bottom: 1em;
  }

  .submit-buttons {
    border-top: 1px solid $gray100;
  }

  &__wrapper {
    display:flex;
  }

  &__sidebar {
    flex: 3;
    //background-color: $white500;
    border-right: 1px solid $gray100;
    overflow: hidden;
  }

  &__content {
    flex: 6.5;
    padding: 0 0 1em 1em;
    display: flex;
    flex-direction: column;
  }

  &__search {
    padding:.5em;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  .field-type-modal__list {
    padding-right: 1em;
    font-size: 1em;
    overflow-y: auto;
  }
  .kn-submit {
    margin-top: auto;
  }
}

.field-types-list .toggle-wrapper:first-child {
  margin-top: 10em;
}

#field-convert-description {
  section {
    font-size:1rem;

    & > p {
      color: $gray600;
    }
  }
  header {
    @include font-h6;
    font-weight: 600;
    margin-bottom: 1em;
  }
  p {
    color: $gray600;
  }
  article {
    padding: 1em;
    border-radius: .1875em;
    display:flex;
  }

  article svg {
    margin: 0 6px;
  }

  article .icon-wrapper {
    display: flex;
    height: 18px;
    width: 18px;
    color: white;
    margin: 0;
    background: none;

    svg.icon {
      margin:0;
      height: 100%;
    }
  }

  .compatibility {
    margin-left: .5em;
    @include font-body;

    ul {
      list-style-type: disc;
      margin-top: 0.5rem;
    }

    p:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .compatibility-acknowledgement {
    label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      line-height: 1;
      font-size: 14px;
    }
    input:checked ~ span {
      font-weight: bold;
    }
  }

  form > div {
    margin-bottom: 1em;
  }

  .connection {
    margin: 2em 0;
    @include font-body;
    padding-top: 1.5em;
    border-top: 1px solid $gray100;
  }

  article.article--success {
    background-color: $success50;
    border-radius: .25em;
    border: 1px solid $success100;

    .icon-wrapper {
      color: $success500;
    }
  }
  article.article--warning {
    background-color: $warning50;
    border-radius: .25em;
    border: 1px solid $warning100;

    .icon-wrapper {
      color: $warning500;
    }
  }
  article.article--failure {
    background-color: $error50;
    border-radius: .25em;
    border: 1px solid $error100;

    .icon-wrapper {
      color: $error500;
    }
  }

  .bold-red {
    font-weight: bold;
    color: $error600;
  }
}
</style>
