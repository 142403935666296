<template>
  <div
    id="topbar"
    data-cy="top-bar"
    class="topbarNav_wrapper"
  >
    <div class="logo builderLayout_knackLogo bg-transparent hover:bg-brand-50 rounded-lg h-14 w-14 m-1 flex-override">
      <a
        v-tippy
        content="Go to Account Dashboard"
        class="logo-link transition h-full w-full flex justify-center items-center"
        :href="appDashboardUrl"
        @click="trackLogRocket"
      >
        <span
          id="kn-logo"
          class="icon-knack flex justify-center items-center m-0 w-auto mb-0"
        >
          <Icon
            class="w-6 h-6"
            type="home-hi"
          />
        </span>
      </a>
    </div>
    <div class="builderLayout_topbarNav topbarNav w-full">
      <div
        id="topbar-nav-left"
        class="topbarNav_left"
      >
        <h1
          v-if="!isEditingAppName"
          v-tippy="appNameTooltipConfig"
          data-cy="nav-app-name"
          class="appName m-0 truncate rounded px-1 py-2 font-semibold text-emphasis hover:bg-brand-50 hover:h-10 border-0 bg-transparent text-base tracking-[0.32px]"
          @click="isEditingAppName = true"
        >
          {{ name }}
        </h1>
        <input
          v-else
          v-model="name"
          v-focus
          class="appName_input h-10 !text-emphasis !text-base px-1 py-2 font-semibold tracking-[0.32px] m-0 focus:!bg-white"
          name="name"
          type="text"
          @blur="handleSaveAppName"
          @keyup.enter="handleSaveAppName"
        >
        <AppOptionsMenu />
      </div>

      <nav class="topbarNav_right">
        <div
          v-if="isTrialing"
          class="flex justify-center items-center mr-4 gap-2"
        >
          <p class="mb-0 text-default">
            {{ daysLeftInTrialText }}
          </p>
          <a
            v-if="!isSharedBuilder && daysLeftInTrial < getTrialDaysInit"
            href="/#billing"
            class="text-brand-gradient font-medium"
          >
            Upgrade
          </a>
        </div>

        <div
          v-if="hasChecklists"
          class="mr-4"
        >
          <ChecklistsNavButton />
        </div>

        <div
          v-if="shouldRenderSwitchBuilderLink"
          class="flex justify-center items-center mr-4"
        >
          <a
            :href="builderNextRecordsUrl"
            class="font-medium underline text-emphasis text-sm"
          >
            <span class="relative bottom-0.5">Next-Gen Builder </span>
            <span class="relative bottom-0.5">🔀</span>
          </a>
        </div>

        <LiveAppAccessMenu />

        <button
          v-tippy
          content="Help, Support..."
          data-cy="nav-help-menu"
          class="topbarNav_icon text-default bg-transparent p-0 flex justify-center items-center hover:bg-brand-50 rounded"
          type="button"
          @click="toggleHelpDrawer"
        >
          <Icon
            v-if="isTrialing"
            class="h-6 w-6"
            type="get-started-2"
          />
          <Icon
            v-else
            class="h-7 w-7"
            type="help-circle"
          />
        </button>
        <Popover
          placement="bottom-end"
          boundary="viewport"
          :arrow-offset="6"
        >
          <template #trigger>
            <button
              v-tippy="accountButtonTooltipConfig"
              data-cy="nav-profile-menu"
              class="topbarNav_icon ml-1 mr-4 text-default bg-transparent hover:bg-brand-50 p-0 flex justify-center items-center rounded"
              type="button"
            >
              <Icon
                class="h-7 w-7"
                type="user-circle"
              />
            </button>
          </template>
          <template #content>
            <div class="links">
              <a
                href="/#account"
                :class="linkItemClasses"
              >
                <Icon
                  class="text-inherit"
                  type="knack"
                /> Account
              </a>
              <a
                v-if="!isSharedBuilder"
                href="/#billing"
                :class="linkItemClasses"
              >
                <Icon
                  class="text-inherit"
                  type="credit-card"
                /> Billing
              </a>
              <a
                v-close-popper
                :class="linkItemClasses"
                @click="logout"
              >
                <Icon
                  class="text-inherit"
                  type="log-out"
                /> Sign Out
              </a>
            </div>
          </template>
        </Popover>
      </nav>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { mapGetters } from 'vuex';
import AppOptionsMenu from '@/components/builder/menus/AppOptions';
import Icon from '@/components/ui/Icon';
import LiveAppAccessMenu from '@/components/builder/menus/LiveAppAccess';
import Popover from '@/components/ui/Popover';
import RequestUtils from '@/components/util/RequestUtils';
import { safeLogRocketTrack } from '@/lib/logrocket-helper';
import { getBuilderNextBaseUrl } from '@/lib/url-helper';
import { DATA_TABLE_V1 } from '@/constants/featureflags';
import ChecklistsNavButton from '@/components/checklists/ChecklistsNavButton.vue';
import { TRIAL_DAYS_INIT } from '@/constants/account';

export default {
  name: 'Topbar',
  components: {
    ChecklistsNavButton,
    AppOptionsMenu,
    Icon,
    LiveAppAccessMenu,
    Popover,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      isEditingAppName: false,
      initialAppName: '',
      appDashboardUrl: process.env.VUE_APP_DASHBOARD_URL || '/',
    };
  },
  computed: {
    ...mapGetters([
      'app',
      'isSharedBuilder',
      'hasFeature',
    ]),
    appIsLoaded() {
      return Boolean(this.app && this.app.get);
    },
    name: {
      get() {
        if (!this.app || !this.app.get) {
          return '';
        }
        return this.app.get('name');
      },
      set(newVal) {
        this.app.name = newVal;
      },
    },
    isTrialing() {
      return this.app.isTrialing();
    },
    getTrialDaysInit() {
      return TRIAL_DAYS_INIT;
    },
    hasChecklists() {
      return !!this.$store.getters['checklists/data'];
    },
    daysLeftInTrial() {
      return this.app.getDaysLeftInTrial();
    },
    daysLeftInTrialText() {
      const daysLeft = this.daysLeftInTrial >= 0 ? this.daysLeftInTrial : 0;

      if (daysLeft === 14) {
        return '';
      }
      return daysLeft === 1 ? '1 trial day left' : `${daysLeft} trial days left`;
    },
    showTrialWarning() {
      return Boolean(this.daysLeftInTrial < 7);
    },
    accountButtonTooltipConfig() {
      return {
        content: 'Account, Billing...',
        placement: 'bottom-start',
      };
    },
    appNameTooltipConfig() {
      return {
        content: 'Rename',
        placement: 'bottom',
      };
    },
    linkItemClasses() {
      return 'rounded hover:bg-brand-50 text-base text-default hover:text-emphasis';
    },
    shouldRenderSwitchBuilderLink() {
      return this.hasFeature(DATA_TABLE_V1);
    },
    builderNextRecordsUrl() {
      const { objectKey } = this.$route.params;
      return objectKey ? `${getBuilderNextBaseUrl()}/tables/${objectKey}` : `${getBuilderNextBaseUrl()}/tables`;
    },
  },
  created() {
    if (this.app && this.app.get) {
      this.initialAppName = this.app.get('name');
    }
  },
  methods: {
    logout() {
      this.commitRequest({
        request: () => this.app.logout(),
        onSuccess: () => this.$router.push('/logout'),
      });
    },
    toggleHelpDrawer() {
      const router = this.$router;
      const { query } = this.$route;

      if (!isEmpty(query.help)) {
        // Closes the help drawer by removing the help query param from the url.
        router.replace({
          ...router.currentRoute,
          query: omit(query, ['help']),
        });
      } else {
        // Closes the help drawer by adding the help query param to the url.
        router.replace({
          ...router.currentRoute,
          query: { ...query, help: 'home' },
        });
      }
    },
    handleSaveAppName() {
      this.isEditingAppName = false;
      if (!this.name) {
        this.name = this.initialAppName;
        return;
      }
      if (this.name === this.initialAppName) {
        return;
      }
      this.saveAppName();
    },
    saveAppName() {
      this.commitRequest({
        validate: () => this.app.validate(this.name),
        request: () => this.app.update({ name: this.name }),
        onSuccess: () => {
          document.title = `${this.app.get('name')} - Knack Builder`;
        },
        onError: (error) => {
          if (error[0] === 'This app name is already being used') {
            this.name = this.initialAppName;
            this.$notify({
              group: 'top-right',
              type: 'error',
              duration: 5000,
              'animation-type': 'velocity',
              title: 'App Names Must Be Unique',
              text: 'Rename the existing app in the account to use that name here.',
              data: {
                titleIcon: 'error',
              },
            });
          }
        },
      });
    },
    trackLogRocket() {
      safeLogRocketTrack('NavigateToDashboard');
    },
  },
};
</script>

<style lang="scss">
#kn-logo {
  width: unset;
  margin-bottom: unset;
}

.topbarNotice {
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 2.286em;
  background-color: #3a0f33;
  border-radius: 1.143em;
  overflow: hidden;
  font-size: .875rem;
  font-weight: 600;
  line-height: 2em;
  margin-right: 1em;
  padding-left: 1em;

  &.showWarning {
    .topbarNotice_icon {
      display: inline-flex;
    }
    .topbarNotice_warning {
      color: $warning400;
    }
  }
}
.topbarNotice_icon {
  margin-right: 4px;
  color: $warning500;
  display: none;

  svg {
    height: 18px;
    width: 18px;
  }
}
.topbarNotice_trigger {
  align-items: center;
  appearance: none;
  display: flex;
  cursor: pointer;
  padding-right: .75em;
  padding-left: .25em;
  background-color: transparent;
  border: none;

  svg {
    height: 18px;
    width: 18px;
  }
}
#topbar {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .logo {
    flex: 0 0 64px;
    transition: .2s all;

    .logo-link {
      display: block;
      width: 100%;
      padding: 1em 0;
    }
    svg {
      fill: currentColor;
      width: 28px;
      height: 32px;
      display: block;
      margin: auto;

      path {
        fill: $primary500;
        opacity: .9;
      }
    }

    :hover {

      svg path {
        opacity: 1;
      }
    }
  }
}

.appName {
  margin: 0 .75em 0 0;
  color: $white75;
  @include font-h3;
  &:hover {
    height: 2rem;
    line-height: 2rem;
    padding: 0px 8px;
    margin: -8px;
    margin-right: 7px;
    background-color: $primary800;
    border-radius: 3px;
  }
}

.topbarNav_left .appName_input {
  margin: 0 .75em 0 0;
  color: $white75;
  @include font-h3;
  color: $white50;
  background-color: $primary800;
  &:focus {
    border-color: rgba(255,255,255,0.25) !important;
    outline: 0;
  }
}

.topbarNav_left,
.topbarNav_right {
  align-items: center;
  display: flex;
  flex-shrink: 0;
}

.topbarNav {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;

  padding: 4px 16px;

  &_wrapper {
    color: #818287;
  }

  &_icon{
    appearance: none;
    border: none;
    color: $white50;
    background-color: $primary800;
    display: block;
    line-height: 1em;
    padding: .375em;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin-left: .75em;
    cursor: pointer;

    svg {
      @include icon-20;
    }

    &:hover,
    &:focus {
      background-color: #200A22;
      color: $white75;
    }
  }
}
</style>
