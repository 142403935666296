import axios from 'axios';
import { hideCrispChat } from './crisp-helper';

// eslint-disable-next-line import/prefer-default-export
export async function getHSTokenAndShowChat() {
  hideCrispChat();
  if (window.Knack.Api.email) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}hubspot-auth/identify`, { email: window.Knack.Api.email, firstName: window.Knack.Api.first_name, lastName: window.Knack.Api.last_name });
      if (response) {
        window.hsConversationsSettings = {
          identificationEmail: window.Knack.Api.email,
          identificationToken: response.data.hubspotToken,
        };
        if (!window.HubSpotConversations) {
          window.hsConversationsOnReady = [
            () => {
              console.log('Now the api is ready');
            },
          ];
        } else {
          const status = window.HubSpotConversations.widget.status();

          if (status.loaded) {
            window.HubSpotConversations.widget.refresh();
          } else {
            window.HubSpotConversations.widget.load();
          }
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  }
}
