<template>
  <div>
    <div
      v-if="showFormOptions"
      id="select-source-options"
    >
      <div class="level-left block">
        <span style="font-weight: 500;">
          Show options to
        </span>
        <Dropdown
          v-model="formQuantity"
          v-tippy="{ allowHTML: true }"
          content="Click to change whether the<br />form adds or updates a record"
          :options="formOptions"
          class="is-text mt-2"
        />
      </div>
    </div>
    <div
      v-if="sourcesAreEmpty"
      id="select-source-empty"
    >
      <p><span v-html="selectedRoleName" /> no options to {{ viewActionSummary }}.</p>
      <p>Please try another option.</p>
    </div>
    <div v-else>
      <div
        id="select-source-objects"
        class="source-links"
      >
        <div
          v-for="(source, key) in sources"
          :key="key"
          class="source-link bg-white p-2 rounded-lg text-default border border-solid border-default"
          data-cy="data-source-option"
          @click="onClickSourceObject(source)"
        >
          <div class="source-link-title items-center">
            <Icon
              class="mr-2 text-subtle"
              :type="source.icon === 'object' ? 'table-cells' : source.icon"
            />
            <span v-html="source.name" />
            <span
              v-if="source.optionCount > 1"
              v-tippy="{ allowHTML: true }"
              content="This object has multiple ways to<br />display records you can choose from"
              style="font-size: .85em; opacity: .75;"
            >&nbsp;&nbsp;({{ source.optionCount }})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import Dropdown from '@/components/ui/Dropdown';
import Icon from '@/components/ui/Icon';
import { getSourcesForView } from '@/lib/page/view-source-helper';

export default {
  components: {
    Icon,
    Dropdown,
  },
  props: {
    page: {
      type: Object,
      default() {
        return {
          source: {},
        };
      },
    },
    recordsQuantity: {
      type: String,
      default: 'all', // one | many | all
    },
    // add-view needs to account for forms: they can sometimes either insert or update records
    viewType: {
      type: String,
      default: null,
    },
  },
  emits: [
    'submit',
  ],
  data() {
    return {
      userSourceType: 'public', // the selection on allowing all users or defining a role
      objectsWithAddressFields: () => ({}),
      objectsWithDateFields: () => ({}),
      formOptions: [
        {
          value: 'many',
          label: '<b>add</b> a <b>new</b> record',
        },
        {
          value: 'one',
          label: '<b>update</b> a record',
        },
      ],
      formQuantity: 'many', // relevant when viewType = form (see prop below)
    };
  },
  computed: {
    ...mapGetters([
      'userObjects',
      'getObject',
    ]),
    showFormOptions() {
      if (this.viewType !== 'form') {
        return false;
      }

      const hasMany = this.options.find((option) => option.quantity === 'many');
      const hasOne = this.options.find((option) => option.quantity === 'one');

      if (hasMany && hasOne) {
        return true;
      }

      this.formQuantity = (hasMany) ? 'many' : 'one';

      return false;
    },
    options() {
      const inflectionsType = (this.viewType === 'form' || this.recordsQuantity === 'one') ? 'singular' : 'plural';

      return this.page.getViewSourceOptions(inflectionsType);
    },
    sources() {
      let sources = {};
      const { options } = this;

      // track these to save on some processing in addOption
      this.objectsWithAddressFields = {};
      this.objectsWithDateFields = {};

      options.forEach((option) => {
        this.addOption(sources, option);
      });

      // Get object keys of the objects in order so these look like the schema order
      const objectKeys = this.$store.getters.objectsByMenuOrder.map((object) => object.key);

      // Sort by object
      sources = sortBy(sources, (source) => objectKeys.indexOf(source.objectKey));

      return sources;
    },
    sourcesAreEmpty() {
      return isEmpty(this.sources);
    },
    showUserSource() {
      return this.userSourceType !== 'public';
    },
    profileObjects() {
      return this.userObjects.filter((obj) => {
        const hasProfileAccess = (obj.profile_key && this.page.allowed_profiles && this.page.allowed_profiles.length && this.page.allowed_profiles.indexOf(obj.profile_key) > -1);

        return obj.profile_key === 'all_users' || hasProfileAccess;
      });
    },
    pageObject() {
      return this.getObject(this.page.object);
    },
    selectedRoleName() {
      if (this.userSourceType === 'public') {
        return 'Users that aren\'t logged-in have';
      }

      const obj = this.$store.getters.getObject(this.userSourceType);

      return `The <b>logged-in ${obj.inflections.singular}</b> has`;
    },
    viewActionSummary() {
      log('viewActionSummary!!!', this.formQuantity);

      if (this.viewType === 'form') {
        return (this.formQuantity === 'many') ? 'add a new record with a form' : 'update a record with a form';
      }

      let type = this.viewType;

      if (this.viewType === 'details') {
        type = 'details view';
      }

      return `display records on this page with a ${type}`;
    },
  },
  methods: {
    onClickSourceObject(source) {
      log(':: ::: :: onClickSourceObject', source);

      const submit = {
        label: source.name,
        source: {
          object: source.objectKey,
        },
        quantity: source.quantity,
        paths: source.options,
        pathCount: source.optionCount,
      };

      this.$emit('submit', submit);
    },
    // index options by object
    addOption(sources, pageViewSourceOption) {
      sources = getSourcesForView(this.viewType, this.objectsWithDateFields, this.objectsWithAddressFields, this.recordsQuantity, this.formQuantity, sources, pageViewSourceOption);
    },
  },
};
</script>

<style lang="scss" scoped>
#select-source-objects {

  .source-link-title {
    display: flex;

    .source-link-label {
      flex-grow: 1;
      display: flex;
      font-weight: 500;
    }

    .icon {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }

  .source-link-confirm {
    margin-top: 10px;
  }

  .source-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: inherit;
    font-size: .95em;
    line-height: 1.2em;
    // border-top: 1px solid #d9dce6;
    padding: .65em .6em .55em;
    border-radius: .35em;
    margin-bottom: .5em;
    background-color: rgba(0, 0, 0, .035);
    cursor: pointer;

    .toggle-trigger {
      margin: 0;
      border: 0;
      padding: 0;
      color: inherit;
    }

    .toggle-wrapper {
      margin: 0;
    }

    .link-label-wrapper {
      display: flex;
      align-items: flex-start;
    }

    .link-label b.source-label {
      font-weight: 500;
      // display: none;

      svg {
        opacity: .55;
      }
    }

    .icon-arrow-forward {
      height: 18px;
      width: 18px;
      opacity: 0.5;
      flex-shrink: 0;
    }

    &:hover {

      svg.icon-arrow-forward {
        color: $fuchsia;
        opacity: 1;
      }
    }
  }
}

#select-source-empty {
  background-color: #fff;
  padding: 0.75em;
  border-radius: 0.33em;

  p:last-child {
    margin: 0;
  }
}

#select-source-options {
  // border-bottom: 1px solid #d9dce6;
  margin-bottom: 1em;
  font-size: .95em;
  line-height: 1.15em;

  > div {

    label {
      display: block;

      svg {
        width: 16px;
        height: 16px;
        opacity: 0.8;
        margin-right: 3px;
        top: 2px;
        position: relative;
      }
    }

    &:first-child {
      margin-bottom: .45em;
    }
  }
}
</style>
