import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';

const storeState = {

  /**
   * Filters applied to an active object's schema fields.
   *
   * @type {object<string, string>}
   */
  schemaFieldFilters: {},

  /**
   * Properties used to filter an active object's schema fields.
   *
   * @type {Array<string>}
   */
  schemaFieldFilterByProperties: [
    'name',
  ],
};

const storeGetters = {

  /**
   * Gets currently applied schema field filters.
   *
   * @returns {object<string, string>} | {}
   */
  getSchemaFieldFilters: (state) => state.schemaFieldFilters,

  /**
   * Gets remaining schema fields after filters are applied.
   *
   * @param {object} state
   * @returns {function(Field[]): Field[]}
   */
  getFilteredSchemaFields: (state) => (fields) => {
    const filtersApplied = {
      ...state.schemaFieldFilters,
    };

    if (isEmpty(filtersApplied)) {
      return fields;
    }

    return fields.filter((field) => {
      const matchesFound = Object.keys(filtersApplied).filter((fieldAttribute) => {
        const attributeValue = toLower(field.attributes[fieldAttribute]);
        const searchValue = toLower(filtersApplied[fieldAttribute]);

        return attributeValue.includes(searchValue);
      });

      return !isEmpty(matchesFound);
    });
  },

  /**
   * Gets unfiltered fields with updated sort order.
   *
   * @param {object} state
   * @param {Field[] | []} allFields
   * @param {Field[] | []} filteredFields filtered fields with updated sort order
   * @returns {function({ allFields: Field[], filteredFields: Field[] }): Field[]}
   */
  getSortedListFromFilters: (state) => ({ allFields, filteredFields }) => {
    const fieldInList = (field, list) => list.some((listField) => listField.key === field.key);

    return allFields.reduce((updatedFieldList, field) => {
      // skip field if it was already added
      if (fieldInList(field, updatedFieldList)) {
        return updatedFieldList;
      }

      // push updated field and any filtered fields that come before it (if applicable)
      if (fieldInList(field, filteredFields)) {
        const fieldSubset = [
          ...filteredFields,
        ];

        while (fieldInList(field, fieldSubset)) {
          updatedFieldList.push(fieldSubset.shift());
        }
      } else {
        updatedFieldList.push(field);
      }

      return updatedFieldList;
    }, []);
  },

  /**
   * Gets currently applied schema field filtering properties.
   *
   * @param {object} state
   * @returns {Array<string>}
   */
  getSchemaFieldFilterByProperties: (state) => state.schemaFieldFilterByProperties,

  /**
   * Gets current state of whether filtering by schema field keys is enabled.
   *
   * @param {object} state
   * @returns {Boolean}
   */
  getFilteringByKeyEnabled: (state) => state.schemaFieldFilterByProperties
    .some((filterProperty) => filterProperty === 'key'),
};

const storeMutations = {

  /**
   * Mutates currently applied schema field filters.
   *
   * @param {object} state
   * @param {object<string, string>} filters
   */
  setSchemaFieldFilters(state, filters) {
    state.schemaFieldFilters = {
      ...filters,
    };
  },

  /**
   * Removes single schema field filter by filter attribute type (e.g. 'name').
   *
   * @param {object} state
   * @param {string} filterAttribute
   */
  removeSchemaFieldFilter(state, filterAttribute) {
    delete state.schemaFieldFilters[filterAttribute];
  },

  /**
   * Sets new filter field properties to filter by.
   *
   * @param {object} state
   * @param {Array<string>} filterBy
   */
  setSchemaFieldFilterByProperties(state, filterBy) {
    state.schemaFieldFilterByProperties = [...filterBy];
  },
};

const storeActions = {

  /**
   * Applies filters to schema fields.
   *
   * @param {object} context
   * @param {string} filterText
   */
  async applySchemaFieldFilters({
    state,
    commit,
    dispatch,
  }, {
    filterText,
  } = {}) {
    if (!filterText) {
      dispatch('clearSchemaFieldFilters');

      return;
    }

    const filterOptions = state.schemaFieldFilterByProperties
      .reduce((options, fieldProperty) => {
        /* eslint-disable-next-line no-param-reassign */
        options[fieldProperty] = filterText;

        return options;
      }, {});

    commit('setSchemaFieldFilters', filterOptions);
  },

  /**
   * Clears filters currently applied to schema fields.
   *
   * @param {object} context
   */
  async clearSchemaFieldFilters({ state, commit }) {
    Object.keys(state.schemaFieldFilters).forEach((filterAttribute) => {
      commit('removeSchemaFieldFilter', filterAttribute);
    });
  },
};

export default {
  // Full namespace: ui/filterSchemaFields
  filterSchemaFields: {
    namespaced: true,
    state: storeState,
    getters: storeGetters,
    mutations: storeMutations,
    actions: storeActions,
  },
};
