import FormHelpGetHelp from '@/store/forms/help/FormHelpGetHelp';
import getApiBaseModule from '@/store/modules/api/api-base';

export const GET_HELP_RESET_FORM = '🙋 Get Help - Resetting Form';
export const GET_HELP_UPDATE_FORM = '🙋 Get Help - Updating Form';
export const GET_HELP_VALIDATION = '🙋 Get Help - After Validation';
export const GET_HELP_READY = '🙋🌱 Get Help - Ready';
export const GET_HELP_PENDING = '🙋⏳ Get Help - Pending';
export const GET_HELP_SUCCESS = '🙋✅ Get Help - Success';
export const GET_HELP_FAILURE = '🙋❌ Get Help - Failure';

const events = {
  RESET_FORM: GET_HELP_RESET_FORM,
  UPDATE_FORM: GET_HELP_UPDATE_FORM,
  VALIDATION: GET_HELP_VALIDATION,
  READY: GET_HELP_READY,
  PENDING: GET_HELP_PENDING,
  SUCCESS: GET_HELP_SUCCESS,
  FAILURE: GET_HELP_FAILURE,
};

/**
 * Gets the form.
 *
 * @returns {FormHelpGetHelp}
 */
const getForm = () => new FormHelpGetHelp();

const apiBase = getApiBaseModule(events, getForm);

export default {
  // Full namespace: api/help/getHelp
  getHelp: {
    namespaced: true,
    state: apiBase.state,
    getters: apiBase.getters,
    mutations: apiBase.mutations,
    actions: apiBase.actions,
  },
};
