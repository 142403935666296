<template>
  <div
    class="choiceWrapper rounded-lg border-default"
    :class="{capitals: capitalize, 'choiceWrapper--borderless': borderless}"
  >
    <div
      v-for="(option, index) in options"
      :key="option.label"
      :class="getChoiceClasses(option)"
      @click="onClick(index)"
    >
      <Icon
        v-if="option.icon"
        v-tippy
        :content="option.label"
        :type="option.icon"
        :class="twMerge('choice_icon', iconClasses)"
      />
      <span
        v-else
        v-tippy="option.tooltip"
        class="label"
      >
        <FontAwesome
          v-if="option.faIcon"
          :name="option.faIcon"
        />
        <template v-else>
          {{ option.label }}
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';
import { twMerge } from 'tailwind-merge';
import FontAwesome from '@/components/ui/FontAwesome';
import Icon from '@/components/ui/Icon';

export default {
  name: 'ChoicePicker',
  components: {
    FontAwesome,
    Icon,
  },
  props: {
    choices: {
      type: Array,
      required: true,
    },
    defaultChoice: {
      type: null,
      required: false,
    },
    modelValue: {
      type: null,
      required: false,
    },
    capitalize: {
      type: [
        Boolean,
        String,
      ],
      default: true,
    },
    isBoolean: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    autoSelect: {
      type: [
        Boolean,
        String,
      ],
      default: true,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    choiceClasses: {
      type: String,
      default: 'px-2 py-1 text-sm',
    },
    choiceActiveClasses: {
      type: String,
      default: '',
    },
    iconClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      active: this.modelValue,
    };
  },
  computed: {
    options() {
      return this.choices.map((choice) => {
        if (typeof choice.value !== 'undefined') {
          return choice;
        }

        return {
          label: choice, value: choice,
        };
      });
    },
    activeOption() {
      if (this.active !== undefined) {
        return this.options.find((option) => option.value === this.active);
      }

      if (!this.autoSelect) {
        return;
      }

      return isNil(this.defaultChoice) ? this.options[0] : this.defaultChoice;
    },
  },
  watch: {
    modelValue(newValue) {
      if (this.active !== newValue) {
        this.active = newValue;
      }
    },
  },
  methods: {
    getChoiceClasses(option) {
      let classes = ['choice px-3 py-2 text-base font-semibold text-default'];

      if (this.choiceClasses) {
        classes = [twMerge(classes, this.choiceClasses)];
      }

      const isOptionActive = this.activeOption !== undefined && option.value === this.activeOption.value;

      if (isOptionActive) {
        classes.push('active rounded-md bg-brand-100 text-emphasis');
        if (this.choiceActiveClasses) {
          classes = [twMerge(classes, this.choiceActiveClasses)];
        }
      } else {
        classes.push('hover:bg-brand-50 hover:text-emphasis');
      }

      return classes;
    },
    onClick(optionIndex) {
      if (this.isDisabled) {
        return;
      }

      this.active = this.options[optionIndex].value;

      if (this.isBoolean) {
        this.active = (this.active !== null);
      }

      this.$emit('update:modelValue', this.active);
    },
    twMerge,
  },
};
</script>

<style lang="scss">
.choiceWrapper {
  display: flex;
  justify-content: space-between;

  width: fit-content;

  background-color: $white100;
  border: 1px solid $gray200;
  border-radius: .25rem;
  @include font-body;
  line-height: 1;

  &--borderless {
    border: none;
  }

  &.capitals span {
    text-transform: capitalize;
  }

  &.no-background {
    background: none;
    padding: 0;

    div:first-child {
      margin-left: 0;
    }
    div:last-child {
      margin-right: 0;
    }
  }

  .choice {
    display: flex;

    margin: .1875rem;
    padding: .3125rem .5rem;
    border-radius: .1875rem;

    transition: all 200ms ease-in;
    cursor: pointer;

    &.active {
      color: $primary500;
      background-color: $gray100;
    }

    &:not(.active):hover {
      color: #923081;
    }
  }

  .choice_icon {
    align-self: center;

    width: 16px;
    height: 16px;
  }
}
</style>
