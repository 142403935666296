import isEmpty from 'lodash/isEmpty';

export default {
  methods: {

    getReportByKey(view, reportKey) {
      return view.reports.find((report) => report.key === reportKey);
    },

    getReport(columnIndex, groupIndex, index = 0) {
      const column = this.view.groups[groupIndex].columns[columnIndex];

      // TODO: default to first key (unless we decide we want columns for reports)
      const reportKey = column.keys[index];

      const report = this.view.reports.find((report) => report.key === reportKey);

      // TODO: handle w/ route guards for report view having data loaded
      if (isEmpty(report)) {
        return {};
      }

      return report;
    },
    getReportData(rowIndex, itemIndex) {
      let index = itemIndex;

      for (let i = 0; i < rowIndex; i++) {
        index += this.view.rows[i].reports.length;
      }

      return this.view.data.reports[index];
    },

    hasReportData(columnIndex, groupIndex) {
      return !isEmpty(this.getReportData(columnIndex, groupIndex));
    },
  },
  computed: {
    object() {
      if (isEmpty(this.report)) {
        return null;
      }

      if (this.report.source && this.report.source.object) {
        return this.$store.getters.getObject(this.report.source.object);
      }

      return null;
    },
  },
};
