<template>
  <Modal
    title="Copy Page"
    :back="backLink"
  >
    <div class="body">
      <p>Copy this page, including all views and child pages</p>
      <FormWrapper
        :errors="errors"
        :show-submit="true"
        submit-text="Copy Page"
        @submit="onSubmit"
      >
        <div class="max-w-none">
          <label class="text-default text-sm font-medium mb-2 leading-4">
            Page Name
          </label>
          <input
            v-model="newPageName"
            v-focus
            type="text"
          >
        </div>
      </FormWrapper>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import FormWrapper from '@/components/ui/FormWrapper';
import Modal from '@/components/ui/Modal';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  name: 'PageCopy',
  components: {
    FormWrapper,
    Modal,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    backLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      submitIsLoading: false,
      newPageName: '',
    };
  },
  computed: {
    ...mapGetters([
      'activePage',
    ]),
    page() {
      return this.activePage;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.newPageName = `${this.activePage.name} Copy`;
    });
  },
  methods: {
    onSubmit() {
      this.commitRequest({
        request: () => this.page.copy(this.newPageName),
        onSuccess: () => this.$router.push(this.backLink),
      });
    },
  },
};
</script>
