<template>
  <div
    id="step-confirm"
    class="w-[480px] mt-12"
  >
    <h1 class="center center-align text-left text-emphasis text-xl font-medium mb-1 mt-0 capitalize">
      Confirm your import
    </h1>
    <div class="box margin-bottom-double bg-transparent shadow-none p-0">
      <div class="padding-top p-0">
        <div class="margin-bottom">
          <div v-if="localObjectName">
            <p
              v-if="importAction === 'create' && !isObjectNameUnique"
              class="importConfirm__name text-default text-base"
            >
              Table name <b>{{ objectName }}</b> already exists. Please choose a
              different table name.
            </p>
            <div
              v-else
              class="importConfirm__name text-default text-base"
            >
              <p>
                You’re about to import <b>{{ totalRecords }} records</b> records into the <b>{{ objectName }}</b> table.
                Your import may take some time to process. We’ll notify you when it’s done.
              </p>
              <div>
                <b>Error Handling: Choose how to handle records with import errors</b>
              </div>
              <div>
                <div>
                  <label>
                    <input
                      v-model="localSkipRecordsWithErrors"
                      type="radio"
                      value="blank"
                      :checked="true"
                    >
                    <span class="text-base font-normal">Leave fields blank for records with errors</span>
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      v-model="localSkipRecordsWithErrors"
                      type="radio"
                      value="skip"
                    >
                    <span class="text-base font-normal">Exclude records with errors from the import</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="importConfirm__name text-default text-base">
              A table name is required.
            </p>
          </div>
        </div>

        <FormWrapper
          v-if="importAction === 'create'"
          class="margin-bottom"
          style="max-width: 500px"
          :errors="errors"
          :show-submit="false"
        >
          <div data-field-name="name">
            <label class="mb-2">
              Name Your Table
            </label>
            <input
              v-model="localObjectName"
              v-focus
              type="text"
            >
          </div>
        </FormWrapper>

        <div
          v-if="importAction === 'update'"
          class="import-defaults margin-bottom-double mb-6 p-6 bg-muted rounded-lg"
        >
          <label class="text-xs mb-2">
            Optional
          </label>
          <p class="text-base text-emphasis font-semibold mb-4">
            Set default for any empty or missing fields
          </p>

          <p
            v-if="defaultValues.length"
            class="text-xs tracking-[0.12px] font-medium mb-2"
          >
            Set
          </p>

          <ValueList
            :object="importObject"
            :values="defaultValues"
            :can-be-empty="true"
            class="gap-2"
            empty-text="Add a default"
            @update:values="onUpdateDefaultValues"
          />
        </div>

        <div class="flex justify-end">
          <a
            class="button gray-outline p-3 rounded-lg bg-white border border-solid border-default text-base leading-4 font-medium text-emphasis mr-2"
            @click="$emit('back')"
          >
            Back
          </a>

          <button
            class="button orange-fill  p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium"
            data-cy="start-import"
            :disabled="shouldPreventImport"
            @click="onSubmit"
          >
            Start Import
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sanitizeObjectName } from '@/util/FormatHelper';
import FormWrapper from '@/components/ui/FormWrapper';
import ValueList from '@/components/ui/lists/ValueList';

export default {
  components: {
    FormWrapper,
    ValueList,
  },
  props: {
    objectName: {
      type: String,
      default: '',
    },
    skipRecordsWithErrors: {
      type: Boolean,
      default: false,
    },
    importAction: {
      type: String,
      default: 'update',
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
  },
  emits: ['back', 'submit', 'update:objectName', 'update:skipRecordsWithErrors'],
  data() {
    return {
      errors: [],
      localObjectName: '',
      localSkipRecordsWithErrors: false,
      defaultValues: [],
    };
  },
  computed: {
    importObject() {
      if (this.importAction) {
        return this.$store.getters.activeObject;
      }

      return null;
    },
    isObjectNameUnique() {
      return (
        this.localObjectName
        && this.$store.getters.isObjectNameUnique(sanitizeObjectName(this.localObjectName))
      );
    },
    shouldPreventImport() {
      return (
        !this.localObjectName
        || (this.importAction === 'create' && !this.isObjectNameUnique)
      );
    },
  },
  watch: {
    localObjectName(newVal) {
      this.$emit('update:objectName', newVal);
    },
    localSkipRecordsWithErrors(newVal) {
      this.$emit('update:skipRecordsWithErrors', newVal === 'skip');
    },
  },
  created() {
    this.localObjectName = this.objectName;
  },
  methods: {
    onSubmit() {
      log('onSubmit', this.defaultValues);

      if (this.shouldPreventImport) return;

      this.$emit('submit', this.defaultValues);
    },
    onUpdateDefaultValues(newDefaultValues) {
      this.defaultValues = newDefaultValues;
    },
  },
};
</script>

<style lang="scss">
#step-confirm {
  width: 800px;
  margin: auto;
  margin-top: 1em;
  font-size: 1rem;

  & > h1 {
    margin: 1.5em 0 1em;
  }

  p {
    margin-bottom: 1em;
  }

  .icon-upload {
    height: 72px;
    width: 72px;
  }
}

.import-defaults {
  padding: 1em;
  background: #eff1f5;
  border-radius: 0.25em;
  font-size: 0.975em;
}

.importConfirm__name {
  font-size: 1.1rem;
}
</style>
