<template>
  <Popover
    placement="bottom-start"
    @show="activeItem = null"
  >
    <template #trigger>
      <div class="dropdown-trigger">
        <div v-if="triggerText">
          <div
            data-cy="functions-list-dropdown"
            class="equation-editor-button bg-white h-10 py-2 px-3 inline-flex items-center rounded-lg"
          >
            <label
              class="mb-0 text-base font-semibold"
              :style="{color: triggerTextColor}"
            >
              {{ triggerText }}
            </label>
            <div class="equation-editor-icon-wrapper">
              <Icon
                class="arrow-icon text-default"
                type="arrow-drop-down"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="button-wrapper"
        >
          <Icon
            class="icon-merge-field"
            type="merge-field"
          />
          <span
            v-if="textButton"
            class="button-text"
          >
            Merge Fields
          </span>
          <Icon
            class="icon-expand"
            type="arrow-drop-down"
          />
        </div>
      </div>
    </template>
    <template #content>
      <div
        :class="{ 'field-list-dropdown': true }"
        class="level-left shadow-none"
        style="align-items: flex-start;"
      >
        <div
          class="border-r-gray-100 border-l-0 border-y-0 w-auto"
          style="width: 210px; border-right: 1px solid #d6d6de;"
        >
          <div>
            <div class="search-wrapper p-0 pr-2">
              <TextInputSearch
                v-model="searchText"
                placeholder="Filter by function name"
                css-class="modal__search"
                class="mb-1 w-max"
                :autocomplete="false"
                :full="true"
                :input-classes="['pr-6']"
              />
              <Icon
                class="search-icon top-[5px]"
                type="filter-indicator"
              />
            </div>
            <ul class="field-list-dropdown-items border-t-gray-100 p-0 pr-2">
              <li
                v-for="(item, listItemIndex) of filteredList(searchText, list)"
                :key="listItemIndex"
                class="field-list-dropdown-item"
              >
                <div
                  v-close-popper
                  class="field-list-dropdown-item-cell rounded hover:bg-brand-50 text-base group"
                  @click="$emit(`click`, item)"
                  @mouseover="activeItem = item"
                >
                  <span class="field-list-dropdown-sublist-item text-default group-hover:text-emphasis">
                    {{ item[displayProperty] }}
                  </span>
                </div>
              </li>
              <div
                v-if="isListEmpty(filteredList(searchText, list))"
                class="p-2"
                style="padding-left: 6px;"
              >
                {{ emptyText }}
              </div>
            </ul>
          </div>
        </div>
        <div
          v-if="activeItem"
          style="width: 250px; padding: 1em;"
        >
          <div class="margin-bottom">
            <b class="text-emphasis">{{ activeItem.detail }}</b>
          </div>
          <div class="text-default">
            {{ activeItem.documentation }}
          </div>
        </div>
      </div>
    </template>
  </Popover>
</template>

<script>
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import { mapGetters } from 'vuex';

import Popover from '@/components/ui/Popover';
import Icon from '@/components/ui/Icon';
import TextInputSearch from '@/components/builder/inputs/Text';

export default {
  name: 'FunctionsListDropdown',
  components: {
    TextInputSearch,
    Popover,
    Icon,
  },
  props: {
    emptyText: {
      type: String,
      default: 'No results',
    },
    list: {
      type: Array,
      default: [],
    },
    displayProperty: {
      type: String,
      default: 'detail',
    },
    textButton: {
      type: Boolean,
      default: false,
    },
    triggerText: {
      type: String,
    },
    triggerTextColor: {
      type: String,
      default: 'royalblue',
    },
    filterFunction: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      searchText: '',
      activeItem: null,
    };
  },
  computed: {
    ...mapGetters([
      'activeObject',
    ]),
    parentObjectName() {
      return flatten(this.list)[0].parentObject.name;
    },
    currentObjectName() {
      return this.activeObject.name;
    },
  },
  methods: {
    filteredList(searchText, listItems) {
      const filteredList = listItems.filter((item) => this.isInSearch(item[this.displayProperty], searchText)).filter((item) => !isEmpty(item));

      return filteredList;
    },
    isValueArray(value) {
      return isArray(value);
    },
    isInSearch(item, search) {
      return item.toLowerCase().includes(search.toLowerCase());
    },
    fieldObjectName(field) {
      return field.parentObject.name;
    },
    isListEmpty(list) {
      return isEmpty(list);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-trigger {
  cursor: pointer
}

.field-list-dropdown {
  padding: 4px 0 10;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
  font-size: .88rem;
  line-height: 1.5em;

  .field-icon {
    margin-right: 3px;
  }

  &-items {
    min-width: 176px;
    max-height: 176px;
    overflow-y: auto;
    border-top: 1px solid $gray200;
    padding-top:.25rem;
    padding-bottom:.25rem;
    display:flex;
    flex-direction:column;
  }

  &-item {
    cursor:pointer;
    display: block;
    // padding: 0px 15px;
    color: #000;
    text-decoration: none;

    &-cell {

      padding: .3125rem .5rem .375rem;

      &:hover {
        background-color:#ededed;
        transition: .2s;

        * {
          color: $fuchsia-text;
        }
      }
    }

    &-subcell {
      padding: 0px 8px;
    }
  }

  &-sublist > div {

    padding: .5em;

    &:hover {
      background-color:$gray100;
      transition: .2s;

      * {
        color: $fuchsia-text;
      }
    }
  }

  &-sublist-item {
    cursor: pointer;
    color: $text;
  }
}

.field-list-dropdown-item-cell > div > span {
  display: block;
  width: 100%;
}

.connection-item {
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:100%;
}

span .icon-connection {
  width: 16px;
  height:10px;
  padding-right:5px;
}

span .arrow-icon {
  width: 15px;
  height: 22px;
  color: #aaa;
}

span .icon-expand {
  width: 15px;
  height: 22px;
  margin-left:0px;
  color: #999;
}

.search-wrapper {
  padding: .5rem;
  position: relative;
}

.search-icon {
  position: absolute;
  right: 20px;
  width: 16px;
  color: #888;
  top: 4px;
}

.sub-dropdown-header {
  display: flex;
  flex-direction: column;
}

.sub-dropdown-description {
  display: flex;
  align-items: center;
  font-size:.7rem;
  color:#aaa;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 33px;

  svg {
    max-height: 18px;
  }

  svg:nth-of-type(2) {
    margin-left: -12px;
  }
}
span .icon-merge-field {
  height: 19px;
  width: 19px;
  margin-right:1px;
  color:#777;
}

.button-text {
  margin: 0 4px;
}

.field-list-dropdown-item-cell header {
  position:relative;
}

.connection-item {

  position:relative;

  svg {
    max-height: 18px;
  }
}

.equation-editor-button {
  width: 130px;
  margin: 0 4px;
  background-color: #fff;
  border-radius: 2px;
  padding: 2px 4px;
  padding-left:6px;
  font-size: .8rem;
  display:inline-flex;
  align-items:center;
  justify-content:space-between;

  & label {
    margin-bottom:0;
  }
}

.equation-editor-icon-wrapper {
  color: #888;
  max-height: 18px;

  svg {
    max-height: 18px;
    max-width: 18px;
  }
}
</style>
