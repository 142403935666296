import moment from 'moment-timezone';
import { handleChanges } from '@/lib/response-helper';
import { logEvent } from '@/lib/segment-helper';

const storeState = {
  socketNotifications: [],
  globalSocketNotification: null,
  batchDeleteCompleted: false,
  batchDeleteCanceled: false,
  batchTaskCompleted: false,
  batchUpdateCompleted: false,
  batchUpdateCanceled: false,
  importCompleted: false,
  importCanceled: false,
  importFailed: false,
};

const storeMutations = {

  SOCKET_connect(state, status) {
    log('Socket connection established');

    // state.isSocketConnectionAvailable = true
  },
  updateSocketNotification(state, socketNotification) {
    state.socketNotifications = state.socketNotifications
      .filter((candidateSocketNotification) => candidateSocketNotification.object_key !== socketNotification.object_key)
      .concat([
        socketNotification,
      ]);
  },
  clearSocketNotificationsForObjectByKey(state, objectKey) {
    state.socketNotifications = state.socketNotifications
      .filter((candidateSocketNotification) => candidateSocketNotification.object_key !== objectKey);
  },
  updateBatchDeleteCompleted(state, status) {
    state.batchDeleteCompleted = status;
  },
  updateBatchDeleteCanceled(state, status) {
    state.batchDeleteCanceled = status;
  },
  updateBatchTaskCompleted(state, status) {
    state.batchTaskCompleted = status;
  },
  updateBatchUpdateCompleted(state, status) {
    state.batchUpdateCompleted = status;
  },
  updateBatchUpdateCanceled(state, status) {
    state.batchUpdateCanceled = status;
  },
  updateImportCompleted(state, status) {
    state.importCompleted = status;
  },
  updateImportCanceled(state, status) {
    state.importCanceled = status;
  },
  updateImportFailed(state, status) {
    state.importFailed = status;
  },
  updateGlobalSocketNotification(state, socketNotification) {
    state.globalSocketNotification = socketNotification;
  },
};

const storeActions = {
  clearSocketNotificationsForObject({ state, rootState, commit }, { key }) {
    commit('clearSocketNotificationsForObjectByKey', key);
  },
  updateSocketNotification({ state, rootState, commit }, { socketMessage, jobType, category }) {
    socketMessage.timestamp = moment().unix();
    socketMessage.jobType = jobType;
    socketMessage.category = category;

    // Old v2 code piggybacks off the progress event to indicate indexing is happenign through a step_name attribute
    // so here we look for progress + said step_name in order to switch category to indexing for UI/UX purposes
    if (socketMessage.category === 'progress' && socketMessage.jobs[socketMessage.object_key].step_name === 'run_entry_formula') {
      socketMessage.category = 'indexing';
    }

    commit('updateSocketNotification', socketMessage);
  },
  jobQueued({ commit }, { jobType, objectKey }) {
    const payload = {
      jobType,
      object_key: objectKey,
      category: 'queued',
    };

    // The server doesn't send a 'queued' event when the batch job is initially submitted, but we still want to show a notification
    // to let the user know that the job is being processed (for scenarios where the job is not processed immediately).
    // This 'queued' notification:
    // - only lives in the Vuex store so it doesn't persist across page refreshes.
    // - is eventually replaced by the progress notification for the queued job (or by other notifications associated with the object).
    commit('updateSocketNotification', payload);
  },
  'SOCKET_csv:import:progress': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;

    const payload = {
      socketMessage,
      jobType: 'import',
      category: 'progress',
    };

    // temporarily removed due to Segment API limit
    // logEvent('created_record', {
    //   import: true
    // });

    commit('updateImportCompleted', false);
    commit('updateImportCanceled', false);
    commit('updateImportFailed', false);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_csv:import:retry': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'import',
      category: 'retry',
    };

    if (socketMessage.new_job_id) {
      // Retries happen in new jobs with new job ids, so update the job id to refer to the new one.
      socketMessage.old_job_id = socketMessage.job_id;
      socketMessage.job_id = socketMessage.new_job_id;
    }

    commit('updateImportCompleted', false);
    commit('updateImportCanceled', false);
    commit('updateImportFailed', false);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_csv:import:complete': async function ({ state, commit, dispatch }, data) {
    await handleChanges(data.changes);

    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'import',
      category: 'complete',
    };

    commit('updateImportCompleted', true);
    commit('updateImportCanceled', false);
    commit('updateImportFailed', false);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_csv:import:canceled': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'import',
      category: 'canceled',
    };

    commit('updateImportCompleted', false);
    commit('updateImportCanceled', true);
    commit('updateImportFailed', false);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_csv:import:failed': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'import',
      category: 'failed',
    };

    commit('updateImportCompleted', false);
    commit('updateImportCanceled', false);
    commit('updateImportFailed', true);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_batch:delete:progress': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'delete',
      category: 'progress',
    };

    commit('updateBatchDeleteCompleted', false);
    commit('updateBatchDeleteCanceled', false);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_batch:delete:complete': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'delete',
      category: 'complete',
    };

    // from userInterface module
    commit('setCheckedRecordIds', [], {
      root: true,
    });

    commit('updateBatchDeleteCompleted', true);
    commit('updateBatchDeleteCanceled', false);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_batch:delete:canceled': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'delete',
      category: 'canceled',
    };

    commit('updateBatchDeleteCompleted', false);
    commit('updateBatchDeleteCanceled', true);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_batch:task:complete': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'task',
      category: 'complete',
    };

    commit('updateBatchTaskCompleted', true);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_batch:update:progress': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'update',
      category: 'progress',
    };

    commit('updateBatchUpdateCompleted', false);
    commit('updateBatchUpdateCanceled', false);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_batch:update:complete': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'update',
      category: 'complete',
    };

    commit('updateBatchUpdateCompleted', true);
    commit('updateBatchUpdateCanceled', false);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_batch:update:canceled': function ({ state, commit, dispatch }, data) {
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'update',
      category: 'canceled',
    };

    commit('updateBatchUpdateCompleted', false);
    commit('updateBatchUpdateCanceled', true);

    dispatch('updateSocketNotification', payload);
  },
  'SOCKET_message': function ({ state, commit, dispatch }, data) {
    if (data?.topic !== 'integrations:quickbooks') {
      return;
    }
    const socketMessage = data;
    const payload = {
      socketMessage,
      jobType: 'import',
      category: socketMessage.status,
    };

    commit('updateGlobalSocketNotification', payload);
  },
};

const storeGetters = {
  socketNotifications: (state) => state.socketNotifications,
  activeSocketNotification: (state, getters, rootState) => state.socketNotifications.find((socketNotification) => socketNotification.object_key === rootState.activeObject?.key),
  getSocketNotificationsByObjectKey: (state, getters, rootState) => (objectKey) => state.socketNotifications.filter((socketNotification) => socketNotification.object_key === objectKey),
  batchDeleteCompleted: (state) => state.batchDeleteCompleted,
  batchDeleteCanceled: (state) => state.batchDeleteCanceled,
  batchTaskCompleted: (state) => state.batchTaskCompleted,
  batchUpdateCompleted: (state) => state.batchUpdateCompleted,
  batchUpdateCanceled: (state) => state.batchUpdateCanceled,
  importCompleted: (state) => state.importCompleted,
  importCanceled: (state) => state.importCanceled,
  importFailed: (state) => state.importFailed,
  globalSocketNotification: (state) => state.globalSocketNotification,
};

export default {
  namespaced: true,
  state: storeState,
  mutations: storeMutations,
  actions: storeActions,
  getters: storeGetters,
};
