<template>
  <div
    v-tippy
    :content="showTooltip ? typeTitle : ''"
    class="field-icon"
    :class="{ 'has-title': title }"
  >
    <!-- Address -->
    <Icon
      v-if="field.type === 'address'"
      class="icon-field-address"
      :class="iconClasses"
      type="address-dropmark"
    />

    <!-- Auto Increment -->
    <Icon
      v-else-if="field.type === 'auto_increment'"
      class="icon-field-auto_increment"
      :class="iconClasses"
      type="auto-increment"
    />

    <!-- Boolean -->
    <Icon
      v-else-if="field.type === 'boolean'"
      class="icon-field-boolean"
      :class="iconClasses"
      type="check-box"
    />

    <!-- Connection -->
    <Icon
      v-else-if="field.type === 'connection'"
      class="icon-field-connection"
      :class="iconClasses"
      type="database-lookup"
    />

    <!-- Currency -->
    <Icon
      v-else-if="field.type === 'currency'"
      class="icon-field-currency"
      :class="iconClasses"
      type="currency"
    />

    <!-- Date/Time -->
    <Icon
      v-else-if="field.type === 'date_time'"
      class="icon-field-date_time"
      :class="iconClasses"
      type="date-time"
    />

    <!-- Email -->
    <Icon
      v-else-if="field.type === 'email'"
      class="icon-field-email"
      :class="iconClasses"
      type="email"
    />

    <!-- Equation -->
    <Icon
      v-else-if="field.type === 'equation'"
      class="icon-field-equation"
      :class="iconClasses"
      type="equation"
    />

    <!-- File -->
    <Icon
      v-else-if="field.type === 'file'"
      class="icon-field-file"
      :class="iconClasses"
      type="attach-file"
    />

    <!-- Formula -->
    <Icon
      v-else-if="isFormula()"
      class="icon-field-formula"
      :class="iconClasses"
      type="sigma"
    />

    <!-- Image -->
    <Icon
      v-else-if="field.type === 'image'"
      class="icon-field-image"
      :class="iconClasses"
      type="image"
    />

    <!-- Link -->
    <Icon
      v-else-if="field.type === 'link'"
      class="icon-field-link"
      :class="iconClasses"
      type="link"
    />

    <!-- Multiple Choice -->
    <Icon
      v-else-if="field.type === 'multiple_choice'"
      class="icon-field-multiple_choice"
      :class="iconClasses"
      type="radio-button-checked"
    />

    <!-- Name -->
    <Icon
      v-else-if="field.type === 'name'"
      class="icon-field-name"
      :class="iconClasses"
      type="name"
    />

    <!-- Number -->
    <Icon
      v-else-if="field.type === 'number'"
      class="icon-field-number"
      :class="iconClasses"
      type="number"
    />

    <!-- Paragraph -->
    <Icon
      v-else-if="field.type === 'paragraph_text'"
      class="icon-field-paragraph_text"
      :class="iconClasses"
      type="paragraph-text"
    />

    <!-- Password -->
    <Icon
      v-else-if="field.type === 'password'"
      class="icon-field-password"
      :class="iconClasses"
      type="key"
    />

    <!-- Phone -->
    <Icon
      v-else-if="field.type === 'phone'"
      class="icon-field-phone"
      :class="iconClasses"
      type="phone"
    />

    <!-- Rating -->
    <Icon
      v-else-if="field.type === 'rating'"
      class="icon-field-rating"
      :class="iconClasses"
      type="rating-star"
    />

    <!-- Rich Text -->
    <Icon
      v-else-if="field.type === 'rich_text'"
      class="icon-field-signature"
      :class="iconClasses"
      type="rich-text"
    />

    <!-- Short Text -->
    <Icon
      v-else-if="field.type === 'short_text'"
      class="icon-field-short_text"
      :class="iconClasses"
      type="short-text"
    />

    <!-- Signature -->
    <Icon
      v-else-if="field.type === 'signature'"
      class="icon-field-signature"
      :class="iconClasses"
      type="signature"
    />

    <!-- Text Formula -->
    <Icon
      v-else-if="field.type === 'concatenation'"
      class="icon-field-signature"
      :class="iconClasses"
      type="text-formula"
    />

    <!-- Timer -->
    <Icon
      v-else-if="field.type === 'timer'"
      class="icon-field-timer"
      :class="iconClasses"
      type="timer"
    />

    <!-- User Roles -->
    <Icon
      v-else-if="field.type === 'user_roles'"
      class="icon-field-user_roles"
      :class="iconClasses"
      type="users"
    />

    <div v-else>
      {{ field.type }}
    </div>

    <div
      v-if="title"
      class="field-title"
      :class="fieldTitleClasses"
    >
      {{ typeTitle }}
    </div>
  </div>
</template>

<script>
import startCase from 'lodash/startCase';

import Icon from '@/components/ui/Icon';

export default {
  name: 'TypeIcon',
  components: {
    Icon,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    title: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    iconClasses: {
      type: String,
      default: '',
    },
    fieldTitleClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    typeTitle() {
      if (!this.field.type) {
        return '';
      }

      const titles = {
        date_time: 'Date/Time',
        concatenation: 'Text Formula',
        boolean: 'Yes/No',
        name: 'Person',
      };

      return titles[this.field.type] || startCase(this.field.type.split('_').join(' '));
    },
  },
  methods: {
    isFormula() {
      const formulaFields = [
        'count',
        'average',
        'sum',
        'min',
        'max',
      ];

      return formulaFields.includes(this.field?.type?.toLowerCase());
    },
  },
};
</script>

<style lang="scss">
.field-icon {
  display: inline-flex;
  align-items: center;
  color: $gray500;

  svg {
    width: 16px !important;
    height: 16px !important;
  }

  &:hover {
    color: $primary500;
  }
  .is-disabled &:hover {
    color: $gray500;
  }
}

.field-title {
  color: $gray500;
  @include font-body;
  line-height: 1;
  display: inline-block;
  text-transform: capitalize;
  margin-left: $spacing-xxs;
}

.icon.has-title {
  width: auto;
}
</style>
