<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Overlay from '@/components/ui/Overlay';
import IntegrationsListItem from '@/components/settings/integrations/IntegrationsListItem.vue';

const backLink = '/settings/integrations';

const store = useStore();

const connectedServices = computed(() => store.getters['integrations/connectedServices']);
const availableServices = computed(() => store.getters['integrations/availableServices']);
const filteredConnectedServices = computed(() => availableServices.value.filter((service) =>
  !connectedServices.value.some((connectedService) => connectedService.service_id === service.id)));

const refetchConnectedServices = async () => {
  await store.dispatch('integrations/fetchConnectedServices');
};
</script>

<template>
  <Overlay
    class="full-width full-body gray-bg left-title"
    :back="backLink"
  >
    <template #title>
      <h1>
        Add Integration
      </h1>
    </template>

    <div class="integrations-list-container">
      <template v-if="filteredConnectedServices.length > 0">
        <h1 class="center center-align">
          Select the integration you want to add
        </h1>
        <IntegrationsListItem
          v-for="service in filteredConnectedServices"
          :key="service.id"
          :service-id="service.id"
          :service-name="service.name"
          :service-icon-url="service.icon_url"
          @close="refetchConnectedServices"
        />
      </template>
      <div v-else>
        More integrations coming soon
      </div>
    </div>
  </Overlay>
</template>

<style lang="scss">
.integrations-list-container {
  width: 468px;
  margin: 0 auto;
  padding: 50px 0;
  h1 {
    margin: 0;
    margin-bottom: 1.5rem;
    @include font-h2;
  }
  .integration {
    max-width: initial;

    &.disabled {
      background-color: transparent;
      cursor: default;
      &:hover {
        border-color: #E7E4E6;
      }
      .integration-text {
        color: #898088;
      }
    }
  }

  .more-integrations-container {
    margin-top: 48px;
  }
}
</style>
