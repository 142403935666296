import moment from 'moment-timezone';

const utils = {
  /**
   * @type {Boolean}
   */
  useUtc: false,
  /**
   * @type {String}
   */
  language: 'en',
  /**
   * Returns the full year, using UTC or not
   * @param {Date} date
   */
  getFullYear(date) {
    return this.useUtc ? date.getUTCFullYear() : date.getFullYear();
  },

  /**
   * Returns the month, using UTC or not
   * @param {Date} date
   */
  getMonth(date) {
    return this.useUtc ? date.getUTCMonth() : date.getMonth();
  },

  /**
   * Returns the date, using UTC or not
   * @param {Date} date
   */
  getDate(date) {
    return this.useUtc ? date.getUTCDate() : date.getDate();
  },

  /**
   * Returns the day, using UTC or not
   * @param {Date} date
   */
  getDay(date) {
    return this.useUtc ? date.getUTCDay() : date.getDay();
  },

  /**
   * Returns the hours, using UTC or not
   * @param {Date} date
   */
  getHours(date) {
    return this.useUtc ? date.getUTCHours() : date.getHours();
  },

  /**
   * Returns the minutes, using UTC or not
   * @param {Date} date
   */
  getMinutes(date) {
    return this.useUtc ? date.getUTCMinutes() : date.getMinutes();
  },

  /**
   * Sets the full year, using UTC or not
   * @param {Date} date
   */
  setFullYear(date, value) {
    return this.useUtc ? date.setUTCFullYear(value) : date.setFullYear(value);
  },

  /**
   * Sets the month, using UTC or not
   * @param {Date} date
   */
  setMonth(date, value) {
    return this.useUtc ? date.setUTCMonth(value) : date.setMonth(value);
  },

  /**
   * Sets the date, using UTC or not
   * @param {Date} date
   * @param {Number} value
   */
  setDate(date, value) {
    return this.useUtc ? date.setUTCDate(value) : date.setDate(value);
  },

  /**
   * Check if date1 is equivalent to date2, without comparing the time
   * @see https://stackoverflow.com/a/6202196/4455925
   * @param {Date} date1
   * @param {Date} date2
   */
  compareDates(date1, date2) {
    const d1 = new Date(date1.getTime());
    const d2 = new Date(date2.getTime());

    if (this.useUtc) {
      d1.setUTCHours(0, 0, 0, 0);
      d2.setUTCHours(0, 0, 0, 0);
    } else {
      d1.setHours(0, 0, 0, 0);
      d2.setHours(0, 0, 0, 0);
    }
    return d1.getTime() === d2.getTime();
  },

  /**
   * Validates a date object
   * @param {Date} date - an object instantiated with the new Date constructor
   * @return {Boolean}
   */
  isValidDate(date) {
    if (Object.prototype.toString.call(date) !== '[object Date]') {
      return false;
    }
    return !isNaN(date.getTime());
  },

  /**
   * Return abbreviated week day name
   * @param {Date}
   * @param {Array}
   * @return {String}
   */
  getDayNameAbbr(date) {
    if (typeof date !== 'object') {
      throw TypeError('Invalid Type');
    }
    return moment()
      .setDay(this.getDay(date))
      .locale(this.language)
      .format('ddd');
  },

  /**
   * Return name of the month
   * @param {Number|Date}
   * @param {Array}
   * @return {String}
   */
  getMonthName(date) {
    if (typeof date === 'object') {
      return moment(date)
        .locale(this.language)
        .format('MMMM');
    }
    if (typeof date === 'number') {
      return moment()
        .month(date)
        .locale(this.language)
        .format('MMMM');
    }
    throw TypeError('Invalid type');
  },

  /**
   * Return an abbreviated version of the month
   * @param {Number|Date}
   * @return {String}
   */
  getMonthNameAbbr(date) {
    if (typeof date === 'object') {
      return moment(date)
        .locale(this.language)
        .format('MMM');
    }
    if (typeof date === 'number') {
      return moment()
        .month(date)
        .locale(this.language)
        .format('MMM');
    }
    throw TypeError('Invalid type');
  },

  /**
   * Alternative get total number of days in month
   * @param {Number} year
   * @param {Number} m
   * @return {Number}
   */
  daysInMonth(year, month) {
    return /8|3|5|10/.test(month)
      ? 30
      : month === 1
        ? (!(year % 4) && year % 100) || !(year % 400)
          ? 29
          : 28
        : 31;
  },

  /**
   * Get nth suffix for date
   * @param {Number} day
   * @return {String}
   */
  getNthSuffix(day) {
    switch (day) {
      case 1:
      case 21:
      case 31:
        return 'st';
      case 2:
      case 22:
        return 'nd';
      case 3:
      case 23:
        return 'rd';
      default:
        return 'th';
    }
  },

  getDaysOfWeek(mondayFirst) {
    const date = moment().day(0);
    if (mondayFirst) {
      date.day(1);
    }

    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(date.locale(this.language).format('ddd'));
      date.day(date.day() + 1);
    }

    return days;
  },

  /**
   * Formats date object
   * @param {Date}
   * @param {String}
   * @param {Object}
   * @return {String}
   */
  formatDate(date, format) {
    return moment(date)
      .locale(this.language)
      .format(format);
  },

  parseDate(dateString, format) {
    return moment(dateString, format).toDate();
  },

  /**
   * Creates an array of dates for each day in between two dates.
   * @param {Date} start
   * @param {Date} end
   * @return {Array}
   */
  createDateArray(start, end) {
    const dates = [];
    while (start <= end) {
      dates.push(new Date(start));
      start = this.setDate(new Date(start), this.getDate(new Date(start)) + 1);
    }
    return dates;
  },

  /**
   * method used as a prop validator for input values
   * @param {*} val
   * @return {Boolean}
   */
  validateDateInput(val) {
    return (
      val === null
      || val instanceof Date
      || typeof val === 'string'
      || typeof val === 'number'
    );
  },
};

export const makeDateUtils = (useUtc = false, language = 'en') => ({
  ...utils,
  useUtc,
  language,
});

export default {
  ...utils,
};

/** special cases to keep backwards compatibilty for older vue js datepicker versions */
// 1. right to left languages
export const rtlLangs = [
  'dv',
  'fa',
  'ha',
  'he',
  'kwh',
  'ks',
  'ku',
  'ps',
  'ur',
  'yi',
];

// 2. translation using year/month/day format
export const ymdLangs = ['ja', 'lt', 'mn', 'ko'];

// 3. special year suffix
export const langYearSuffix = {
  zh: '年',
  ja: '年',
  ko: '년',
};
