<template>
  <div class="inputGroup">
    <div>
      <label
        v-if="isMin"
        class="text-default text-sm font-medium mb-2 leading-4"
      >
        Field to min
      </label>
      <label
        v-if="isMax"
        class="text-default text-sm font-medium mb-2 leading-4"
      >
        Field to max
      </label>
      <label
        v-if="isSum"
        class="text-default text-sm font-medium mb-2 leading-4"
      >
        Field to sum
      </label>
      <label
        v-if="isAverage"
        class="text-default text-sm font-medium mb-2 leading-4"
      >
        Field to average
      </label>
      <label
        v-if="isCount"
        class="text-default text-sm font-medium mb-2 leading-4"
      >
        Connection to count
      </label>

      <div v-if="isCount">
        <select
          v-model="connectionField"
          class="text-base py-2 pl-3 leading-5"
        >
          <option
            v-for="connection in object.getManyConnectionsAsOptions()"
            :key="connection.value"
            :value="connection.value"
          >
            {{ connection.label }}
          </option>
        </select>
      </div>

      <div v-else>
        <select
          v-model="formulaField"
          class="text-base py-2 pl-3 leading-5"
        >
          <option
            v-for="field in eligibleFormulaFields"
            :key="field.value"
            :value="field.value"
          >
            {{ field.label }}
          </option>
        </select>
      </div>
    </div>

    <Numeric :field-local="fieldLocal" />

    <div class="mb-0">
      <label class="text-default text-sm font-medium mb-2 leading-4">Formula Filters</label>
      <div>
        <FieldList
          v-if="fieldLocal.format.filters && fieldLocal.format.filters.length"
          :object-key="filterObjectKey"
          :items="fieldLocal.format.filters"
          :rule-type="RuleType.Filter"
          :can-be-empty="true"
          @update:items="onUpdateFormulaFilters"
        />
        <div v-else>
          <a
            class="underline fuchsia no-underline inline-flex items-center mt-2 p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium capitalize"
            @click="onAddFilter"
          >
            <Icon
              class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
              type="plus-thin"
            />
            Add filters
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Numeric from '@/components/fields/settings/shared/Numeric';
import FieldList from '@/components/ui/lists/FieldList';
import FieldUtils from '@/components/fields/FieldUtils';
import Icon from '@/components/ui/Icon';
import { RuleType } from '@/constants/rules';

export default {
  name: 'Formula',
  components: {
    FieldList,
    Numeric,
    Icon,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    fieldLocal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      RuleType,
    };
  },
  computed: {
    ...mapGetters([
      'getObject',
      'getField',
      'getFieldByObject',
    ]),
    isMin() {
      return this.fieldLocal.type === 'min';
    },
    isMax() {
      return this.fieldLocal.type === 'max';
    },
    isSum() {
      return this.fieldLocal.type === 'sum';
    },
    isAverage() {
      return this.fieldLocal.type === 'average';
    },
    isCount() {
      return this.fieldLocal.type === 'count';
    },
    eligibleFormulaFields() {
      const includeDateEquations = true;

      return this.object.getManyConnectedFieldsThatStoreNumbersAsOptions(includeDateEquations);
    },
    filterObjectKey() {
      const connectionField = (this.isCount)
        ? this.getField(this.connectionField)
        : this.getField(this.fieldLocal.format.connection.key);

      if (!connectionField) {
        return '';
      }

      // If the connection lives on this field's object, get it's connecting object.
      const connectionFieldObjectKey = (connectionField.objectKey === this.object.key)
        ? connectionField.get('relationship').object
        : connectionField.objectKey;

      return (connectionFieldObjectKey !== this.object.key)
        ? connectionFieldObjectKey
        : connectionField.objectKey;
    },
    connectionField: {
      get() {
        if (!this.fieldLocal.format.connection) {
          this.setConnectionField(this.object.getManyConnectionsAsOptions()[0].value);

          return this.object.getManyConnectionsAsOptions()[0].value;
        }

        return this.fieldLocal.format.connection;
      },
      set(connectionField) {
        this.fieldLocal.format.connection = connectionField;
      },
    },
    formulaField: {
      get() {
        // return a default if empty or new
        if (!this.fieldLocal?.format?.field?.key) {
          this.setFormulaField(this.eligibleFormulaFields[0].value);

          return this.eligibleFormulaFields[0].value;
        }

        return `${this.fieldLocal.format.field.key}.${this.fieldLocal.format.connection.key}`;
      },
      set(formulaField) {
        this.setFormulaField(formulaField);
      },
    },
  },
  methods: {
    setFormulaField(formulaField) {
      const formulaFieldKeys = formulaField.split('.');

      this.fieldLocal.format.field = {
        key: formulaFieldKeys[0],
      };

      this.fieldLocal.format.connection = {
        key: formulaFieldKeys[1],
      };
    },
    setConnectionField(connectionFieldKey) {
      this.fieldLocal.format.connection = connectionFieldKey;
    },
    onAddFilter() {
      if (!this.fieldLocal.format.filters) {
        this.fieldLocal.format.filters = [];
      }

      const filterObject = this.getObject(this.filterObjectKey);
      if (!filterObject) {
        return;
      }

      const defaultField = filterObject.fields[0];

      const defaultItem = defaultField.getDefaultRule();

      this.fieldLocal.format.filters.push(defaultItem);
    },
    onUpdateFormulaFilters(newFilters) {
      this.fieldLocal.format.filters = newFilters;
    },
  },
};
</script>
