<template>
  <div class="appNotFound">
    <EmptyState
      class="appNotFound_message"
      title="App not found"
      image="not-found"
      action="Go to App Dashboard"
      secondary-action="Sign in with another account"
      description="Please verify that you have the correct application slug. If you believe this is due to an error, reach out to the app owner."
      @action="goToDashboard"
      @secondary-action="loginNewAccount"
    />
  </div>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState';
import RequestUtils from '@/components/util/RequestUtils';
import Api from '@/lib/api-wrapper';

export default {
  name: 'ApplicationNotFound',
  components: {
    EmptyState,
  },
  mixins: [
    RequestUtils,
  ],
  methods: {
    goToDashboard() {
      this.$router.push(process.env.VUE_APP_DASHBOARD_URL || '/');
    },
    loginNewAccount() {
      this.commitRequest({
        request: async () => {
          if (this.app?.id) {
            await this.app.logout();
          } else {
            // If the app never loaded, then just log out the user.
            await Api.logout();
          }
        },
        onSuccess: () => this.$router.push('/logout'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appNotFound {
  height: 100vh;
}

.appNotFound_message {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
