<template>
  <div class="page-access-info w-96">
    <div class="content p-0 mb-2">
      <div class="text-with-icon margin-right-sm margin-bottom-xs margin-left-sm mt-4">
        <h1 class="text-emphasis text-xl leading-6 font-medium">
          {{ heading }}
        </h1>
        <a
          class="flex"
          href="https://learn.knack.com/article/iqyr40dmbz-logins-and-registrations"
          target="_blank"
        >
          <Icon
            v-tippy="{placement: 'right'}"
            content="Click to learn more about managing logins"
            class="hoverable text-subtle ml-2"
            type="help-circle"
          />
        </a>
      </div>

      <template v-if="showAccordion">
        <Accordion>
          <template #description>
            <span class="text-default text-sm font-medium tracking-[0.14px]">{{ userRoleAccessDescription }}</span>
          </template>

          <template #items>
            <ul class="margin-right-sm margin-bottom-xs margin-left-sm mb-0 mt-6">
              <template
                v-for="(role, index) in userRolesWithPageAccess"
                :key="index"
              >
                <li class="text-with-icon text-default text-base">
                  <Icon
                    class="user-icon text-subtle w-4 h-4"
                    type="user"
                  />
                  <span class="font-normal">{{ role }}</span>
                </li>
              </template>
            </ul>
          </template>
        </Accordion>
      </template>

      <template v-else>
        <p class="description text-default text-sm font-medium tracking-[0.14px] mb-0 mt-4">
          <template v-if="isLoginPage || limitedRolesHaveAccess">
            {{ userRoleAccessDescription }}{{ limitedRolesHaveAccess ? ':' : '' }}
          </template>
          <template v-else-if="isPublicNonLoginPage">
            This page <strong>does not require a login</strong> to access.
          </template>
          <template v-else>
            <strong>All user roles</strong> have permission to access this page.
          </template>
        </p>

        <template v-if="!showAccordion && limitedRolesHaveAccess">
          <ul class="margin-right-sm margin-bottom-xs margin-left-sm mb-0 mt-6">
            <template
              v-for="(role, index) in userRolesWithPageAccess"
              :key="index"
            >
              <li class="text-with-icon text-default text-base">
                <Icon
                  class="user-icon text-subtle w-4 h-4"
                  type="user"
                />
                <span class="font-normal">{{ role }}</span>
              </li>
            </template>
          </ul>
        </template>
      </template>
    </div>

    <div class="footer border-0 p-4">
      <RouterLink
        v-close-popper
        class="inlaid-link p-3 bg-white border border-solid border-default rounded-lg"
        :to="linkDestination"
      >
        <div class="text-with-icon text-base font-semibold text-default">
          <span>
            {{ isPublicNonLoginPage ? 'Require Login' : 'Edit Page Permissions' }}
          </span>
          <Icon
            class="hoverable"
            type="arrow-go"
          />
        </div>

        <span class="link-description text-default text-xs font-normal mt-1">{{ linkDescription }}</span>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import Accordion from '@/components/ui/Accordion';

export default {
  components: {
    Icon,
    Accordion,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    userRolesWithPageAccess: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'getViewsByPageKey',
      'getPageByKey',
    ]),
    heading() {
      if (this.isLoginPage) {
        return 'Login Page';
      }

      if (this.isPublicNonLoginPage) {
        return 'Publicly Accessible';
      }

      return 'Login Required To Access';
    },
    limitedRolesHaveAccess() {
      const [first] = this.userRolesWithPageAccess;

      return first && first !== 'any user';
    },
    userRoleAccessDescription() {
      if (this.isPublicNonLoginPage) {
        return '';
      }

      const numRoles = this.userRolesWithPageAccess.length;
      const pluralized = numRoles !== 1 ? 's' : '';
      const verb = pluralized ? 'have' : 'has';

      if (this.isLoginPage) {
        return this.limitedRolesHaveAccess
          ? `This page allows ${numRoles} user role${pluralized} to access the child pages`
          : 'This page allows all user roles to access the child pages';
      }

      return `${numRoles} user role${pluralized} ${verb} permission to access this page`;
    },
    linkDescription() {
      if (this.isPublicNonLoginPage) {
        return this.usersAreEnabled
          ? 'Add a new parent page with a login view'
          : 'Activate user logins to require login access';
      }

      return 'Go to the login view that sets these page permissions';
    },
    linkDestination() {
      const {
        params: { pageKey },
      } = this.$route;

      if (this.isPublicNonLoginPage) {
        return this.usersAreEnabled
          ? `/pages/${pageKey}/login`
          : '/schema/list/objects/enable-users';
      }

      const {
        loginPageKey,
        loginViewKey,
      } = this.getPagesLoginPageData();

      return loginPageKey && loginViewKey
        ? `/pages/${loginPageKey}/views/${loginViewKey}/login/settings`
        : `/pages/${pageKey}/settings`;
    },
    isLoginPage() {
      return this.page.type === 'authentication';
    },
    isPublicNonLoginPage() {
      return this.userRolesWithPageAccess.length === 0 && !this.isLoginPage;
    },
    showAccordion() {
      return this.userRolesWithPageAccess.length > 10;
    },
    usersAreEnabled() {
      return this.$store.state.app.usersAreEnabled();
    },
  },
  methods: {
    getPagesLoginPageData() {
      if (this.isPublicNonLoginPage) {
        return {};
      }

      const getAssociatedLoginPage = (page) => {
        if (!page?.key) {
          return undefined;
        }
        if (page.type === 'authentication') {
          return page;
        }

        const parentPage = this.getPageByKey(page?.parent.key);

        return parentPage?.key
          ? getAssociatedLoginPage(parentPage)
          : undefined;
      };

      const loginPage = getAssociatedLoginPage(this.page);

      if (!loginPage?.key) {
        return {};
      }

      const getLoginViewKey = (loginPageKey) => {
        const [loginView] = this.getViewsByPageKey(loginPageKey).filter(
          (view) => view.type === 'login',
        );
        return loginView?.key;
      };

      const loginPageKey = loginPage.key;
      const loginViewKey = getLoginViewKey(loginPageKey);

      return {
        loginPageKey,
        loginViewKey,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@function spacing($multiplier) {
  $baseSpacing: 4px;
  @return $baseSpacing * $multiplier;
}

$cardPadding: spacing(4);
$lineHeight: 1.4;
$maxHeight: calc(100vh - 148px);

.page-access-info {
  display: flex;
  flex-direction: column;
  width: 240px + ($cardPadding * 2);
  max-height: $maxHeight;
  font-size: 13px;
  line-height: $lineHeight;

  .content {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    padding: spacing(4) 0 spacing(2);
    overflow-y: auto;

    li {
      height: 18px;
    }

    li + li {
      margin-top: spacing(1);
    }

    .description {
      margin: spacing(2) spacing(4);
    }

    span {
      line-height: $lineHeight;
    }
  }

  .footer {
    flex: 0 1;
    padding: $cardPadding;
    border-top: 1px solid $gray100;
  }
}

.text-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    font-size: 16px;
    line-height: $lineHeight;
    padding: 0;
    margin: 0;
  }

  h1, span {
    font-weight: 600;
  }

  a {
    color: unset;
  }
}

.icon {
  width: 18px;
  height: 18px;
  margin-left: spacing(1);

  &.hoverable:hover {
    color: $fuchsia;
    cursor: pointer;
  }

  &.user-icon {
    width: 14px;
    height: 14px;
    margin-left: 0;
    margin-right: 6px;
  }
}

.inlaid-link {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray100;
  border-radius: 4px;
  padding: spacing(2);
  background-color: $gray50;

  &:hover {
    background-color: $gray100;
  }

  .link-text {
    font-size: 13px;
    line-height: $lineHeight;
    font-weight: 600;
    margin-right: spacing(1);
  }

  .link-description {
    min-height: 17px;
    font-size: 12px;
    line-height: $lineHeight;
    color: $gray500;
    margin-top: spacing(0.5);
  }
}
</style>
