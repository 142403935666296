import FormHelpReportEmergency from '@/store/forms/help/FormHelpReportEmergency';
import getApiBaseModule from '@/store/modules/api/api-base';

export const REPORT_EMERGENCY_RESET_FORM = '🚨 Report Emergency - Resetting Form';
export const REPORT_EMERGENCY_UPDATE_FORM = '🚨 Report Emergency - Updating Form';
export const REPORT_EMERGENCY_VALIDATION = '🚨 Report Emergency - After Validation';
export const REPORT_EMERGENCY_READY = '🚨🌱 Report Emergency - Ready';
export const REPORT_EMERGENCY_PENDING = '🚨⏳ Report Emergency - Pending';
export const REPORT_EMERGENCY_SUCCESS = '🚨✅ Report Emergency - Success';
export const REPORT_EMERGENCY_FAILURE = '🚨❌ Report Emergency - Failure';

const events = {
  RESET_FORM: REPORT_EMERGENCY_RESET_FORM,
  UPDATE_FORM: REPORT_EMERGENCY_UPDATE_FORM,
  VALIDATION: REPORT_EMERGENCY_VALIDATION,
  READY: REPORT_EMERGENCY_READY,
  PENDING: REPORT_EMERGENCY_PENDING,
  SUCCESS: REPORT_EMERGENCY_SUCCESS,
  FAILURE: REPORT_EMERGENCY_FAILURE,
};

/**
 * Gets the form.
 *
 * @returns {FormHelpReportEmergency}
 */
const getForm = () => new FormHelpReportEmergency();

const apiBase = getApiBaseModule(events, getForm);

const actions = {

  /**
   * Submits the form.
   *
   * @param {Object} context
   * @param {function} context.commit
   * @param {function} context.dispatch
   * @param {function} context.getters
   * @param {function} context.rootGetters
   * @returns {Promise<void>}
   */
  async submitForm({
    commit, dispatch, getters, rootGetters,
  }) {
    commit(events.PENDING);

    const wasValid = await dispatch('validateForm');

    if (!wasValid) {
      commit(events.FAILURE, new Error('One or more fields were invalid.'));

      return;
    }

    try {
      // We are overriding the base submitForm specifically so we can send this accountObject to the submit().
      const response = await getters.form.submit(rootGetters.accountObject);

      commit(events.SUCCESS, response);
    } catch (requestError) {
      commit(events.FAILURE, requestError);
    }
  },
};

export default {
  // Full namespace: api/help/reportEmergency
  reportEmergency: {
    namespaced: true,
    state: apiBase.state,
    getters: apiBase.getters,
    mutations: apiBase.mutations,
    actions: {
      ...apiBase.actions,
      ...actions,
    },
  },
};
