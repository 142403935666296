<template>
  <view-toolbox
    back-title="Edit Grid"
    title="Add Actions & Columns"
  >
    <AddItems
      view="view"
      :object="object"
      view-type="table"
    >
      <p>Click or <b>drag</b> options below to add new columns.</p>
    </AddItems>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import AddItems from '@/components/views/AddItems';

export default {
  name: 'TableColumnsBuild',
  components: {
    ViewToolbox,
    AddItems,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    addType() {
      return (this.$route.query.slot) ? 'click' : 'drag';
    },
  },
};
</script>
