<template>
  <div class="criteria-list bg-transparent p-0">
    <template v-if="!criteria || !criteria.length">
      <p>
        <slot name="no-criteria">
          Currently runs with every form submission
        </slot>
      </p>
      <a
        class="fuchsia underline h-10 w-fit p-3 rounded-lg border border-solid border-default bg-white leading-4 text-emphasis text-base no-underline font-medium hover:bg-brand-50 hover:border-brand-600 group inline-flex items-center"
        data-cy="add-criteria"
        @click="onAddCriteria()"
      >
        <Icon
          class="text-default fill-current h-4 w-4 mr-1 group-hover:text-brand-400"
          type="plus-thin"
        />
        Add Criteria
      </a>
    </template>
    <template v-else>
      <p v-if="showTitle">
        <slot name="criteria-title mb-2">
          Run when the following criteria is true
        </slot>
      </p>
      <FieldList
        :object-key="object.key"
        :items="criteria"
        :can-be-empty="canBeEmpty"
        :values-can-be-field="valuesCanBeFields"
        :values-restrict-calculated-fields="valuesRestrictCalculatedFields"
        :rule-type="ruleType"
        class="naked"
        @update:items="onUpdateCriteria"
      />
    </template>
  </div>
</template>

<script>
import FieldList from '@/components/ui/lists/FieldList';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    FieldList,
    Icon,
  },
  props: {
    criteria: {
      type: Array,
      default() {
        return [];
      },
    },
    valuesRestrictCalculatedFields: {
      type: Boolean,
      default: false,
    },
    valuesCanBeFields: {
      type: Boolean,
      default: false,
    },
    canBeEmpty: {
      type: Boolean,
      default: true,
    },
    object: {
      type: Object,
      default: () => ({}),
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    ruleType: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:criteria',
  ],
  methods: {
    onAddCriteria() {
      const shouldUseIsBlankOperator = this.object.fields[0].type === 'image' || this.object.fields[0].type === 'file';

      const newCriteriaItem = {
        field: this.object.fields[0].key,
        operator: shouldUseIsBlankOperator ? 'is blank' : 'contains',
        value: '',
      };

      this.$emit('update:criteria', [
        ...this.criteria,
        newCriteriaItem,
      ]);
    },
    onUpdateCriteria(newCriteria) {
      this.$emit('update:criteria', newCriteria);
    },
  },
};
</script>

<style lang="scss">
.criteria-list{
  @include font-body;
  p {
    margin-bottom: .25em;
  }
}
</style>
