import { formatNumber, formatTimer } from '@/util/FormatHelper';

export default {
  methods: {

    getVal($td, field) {
      let val = $td.html();

      if (field.get('type') === window.Knack.config.TIMER) {
        if (val) {
          val = val.split('=').pop();
        } else {
          val = 0;
        }
      } else {
        const format = field.get('format');

        if (format && format.format === 'custom') {
          val = val.replace(format.pre, '');
          val = val.replace(format.post, '');
        }

        if (format && format.mark_decimal === 'comma') {
          if (val.indexOf(',') > -1) {
            val = val.replace(',', '.');
          }
        }
      }

      val = String(val).replace(/[^0-9.-]+/g, '');
      return this.isBlank(val) ? '' : Number(val);
    },

    sanitizeCellValue(string, format) {
      if (typeof string === 'number') {
        return string;
      }

      if (!format || !format.mark_decimal || format.mark_decimal !== 'comma') {
        return String(string).replace(/[^0-9.-]+/g, '');
      }

      return String(string).replace(/[^0-9,-]+/g, '').replace(',', '.');
    },

    buildCalc(total, viewKey, col, field, calc, val) {
      const { Knack } = window;
      const format = field.get('format') || {};

      // check for connection?
      if (col.connection) {
        switch (field.get('type')) {
          case Knack.config.CURRENCY:

            // multi-connection currency values
            if (Array.isArray(val) && val.length > 1) {
              const currencyVals = val;

              val = '';

              currencyVals.forEach((currencyVal) => {
                if (!this.isBlank(currencyVal)) {
                  currencyVal = Knack.formatCurrency(String(currencyVal).replace(/[^0-9.-]+/g, ''), format);
                  currencyVal = Number(this.sanitizeCellValue(currencyVal, format));
                  val = this.isBlank(val) ? currencyVal : val + currencyVal;
                }
              });
            }

            break;

          default:

            const vals = String(val).split(',');

            val = '';

            vals.forEach((currencyVal) => {
              if (!this.isBlank(currencyVal)) {
                currencyVal = formatNumber(String(currencyVal).replace(/[^0-9.-]+/g, ''), format);
              }

              if (format.post) {
                currencyVal = String(currencyVal).substring(0, currencyVal.length - format.post.length);
              }

              if (format.pre) {
                currencyVal = String(currencyVal).substring(format.pre.length, currencyVal.length);
              }

              if (format.mark_thousands === 'period') {
                currencyVal = String(currencyVal).replace(/\./g, '');
              }

              currencyVal = this.sanitizeCellValue(currencyVal, format);

              if (!this.isBlank(currencyVal)) {
                currencyVal = Number(currencyVal);

                if (format.format === '%') {
                  currencyVal *= 0.01;
                }

                val = this.isBlank(val) ? currencyVal : val + currencyVal;
              }
            });

            break;
        }
      } else {
        switch (field.get('type')) {
          case Knack.config.CURRENCY:
            if (!this.isBlank(val)) {
              val = Knack.formatCurrency(String(val).replace(/[^0-9.-]+/g, ''), format);
            }
            break;
          default:
            if (!this.isBlank(val)) {
              val = formatNumber(String(val).replace(/[^0-9.-]+/g, ''), format);
            }

            if (format.pre) {
              val = String(val).substring(format.pre.length, val.length);
            }

            if (format.post) {
              val = String(val).substring(0, val.length - format.post.length);
            }

            if (format.mark_thousands === 'period') {
              val = String(val).replace(/\./g, '');
            }

            val = this.sanitizeCellValue(val, format);

            if (!this.isBlank(val)) {
              val = Number(val);

              if (format.format === '%') {
                val *= 0.01;
              }
            }

            break;
        }
      }

      val = this.sanitizeCellValue(val, format);

      if (!this.isBlank(val)) {
        val = Number(val);
      }

      return this.performCalculations(total.calc, calc, val);
    },

    formatCalc(summary, field, calc) {
      const { Knack } = window;
      let format = field ? field.get('format') : {};

      format = JSON.parse(JSON.stringify(format));

      if (field && field.get('type') === Knack.config.CURRENCY) {
        if (!format.format) {
          format.format = '$';
        }

        format.precision = 2;
        format.mark_decimal = 'period';
        format.mark_thousands = 'comma';

        return formatNumber(calc, format);
      } if (field && field.get('type') === Knack.config.TIMER) {
        return formatTimer(calc, field.get('format'));
      }

      // make sure averages have a minimum of 2 precisions
      if (summary.calc === 'average' && calc !== 0 && (!format.precision || format.precision < 2)) {
        format.precision = 2;
      }

      return formatNumber(calc, format);
    },

    // Table column viewSummaries
    renderRowTotals(options, groupRows) {
      const { Knack } = window;
      const viewSummaries = this.view.get('totals');
      const viewColumns = this.view.get('columns');
      const viewKey = this.view.key;

      if (!viewSummaries || !viewSummaries.length) {
        return;
      }

      let rows;
      let count;
      let field;
      let labelCount;
      const columnFields = {};

      // get rows
      if (groupRows && groupRows.length) {
        rows = groupRows;
      } else {
        rows = [
          ...this.$el.querySelectorAll('table tbody tr:not(.kn-table-totals):not(.kn-table-group):not(.kn-tr-nodata)'),
        ];
      }

      // return if no rows
      if (!rows.length) {
        return;
      }

      // set last row
      const $lastRow = rows[rows.length - 1];

      // exclude grouping columns
      const cols = viewColumns.filter((col) => !col.grouping);

      // create array of visible columns
      const visibleColumns = [
        ...this.$el.querySelectorAll('thead th'),
      ].map((tableHead) => tableHead.textContent.trim());

      // clone summaries
      const totals = JSON.parse(JSON.stringify(viewSummaries));

      totals.reverse();

      totals.forEach((total) => {
        // var html = '<tr class="kn-table-totals">'
        let html = '';

        labelCount = false;
        count = 0;

        cols.forEach((col) => {
          // Only continue if this col is in the visible column array.  Skipping hidden/empty columns
          if (visibleColumns.indexOf(col.header.replace(/<(?:.|\n)*?>/gm, '').trim()) === -1) {
            return;
          }

          count++;

          // to compute average, we need to know the total and the count. we can't just use row count because
          // blank values should not be factored into average
          let calc = total.calc === 'average' ? { sum: '', count: 0 } : '';

          if ((col.type === 'field' || (col.type === 'link' && col.link_type === 'field')) && !col.ignore_summary) {
            if (columnFields[col.field.key]) {
              field = columnFields[col.field.key];
            } else {
              field = this.$store.getters.getField(col.field.key);
              columnFields[col.field.key] = field;
            }

            if (field) {
              const format = field.get('format') || {};

              const isDateEquation = (field.get('type') === Knack.config.EQUATION && format.equation_type && format.equation_type === 'date' && (!format.date_result || format.date_result === 'date'));

              if (Knack.config.fields[field.get('type')].numeric && field.get('type') !== Knack.config.AUTO_INCREMENT && !isDateEquation) {
                rows.forEach(($row) => {
                  let val;

                  let $tdVal = [
                    ...$row.querySelectorAll('td'),
                  ][count - 1];

                  if ($tdVal.querySelectorAll('a').length) {
                    $tdVal = $tdVal.querySelector('a');
                  }

                  if ($tdVal.querySelectorAll('span').length) {
                    $tdVal = $tdVal.querySelector('span');
                  }

                  // timer?
                  if (field.get('type') === Knack.config.TIMER) {
                    val = this.view.getDataRecord($row.id)[`${field.key}_raw`] || '';

                    if (val) {
                      val = val.total_time;
                    }

                    calc = this.performCalculations(total.calc, calc, val);

                    return calc;
                  }

                  if (field.get('type') === 'equation' && format.equation_type && format.equation_type === 'date') {
                    // these equation values are stored with record ID, so we need to format up a new value without any of the id attributes to get at our proper formatted value
                    const equationValue = document.createElement('span');

                    equationValue.innerHTML = $tdVal.first().text();

                    val = this.getVal(equationValue, field);

                    calc = this.performCalculations(total.calc, calc, val);

                    return calc;
                  }

                  // not dealing with a timer or a date equation... we need to sanitize the value then perform or calculation with it
                  const row = this.view.getDataRecord($row.id);

                  if (!row) {
                    return;
                  }

                  val = row[`${field.key}_raw`] || '';

                  calc = this.buildCalc(total, viewKey, col, field, calc, val);

                  return calc;
                });

                // finish average calculation
                if (total.calc === 'average') {
                  calc = (calc.count > 0 ? calc.sum / calc.count : '');
                }

                calc = this.formatCalc(total, field, calc);
              }
            }
          }

          const style = (col.align && col.align !== 'left') ? ` text-align: ${col.align}` : '';

          if (calc) {
            if (labelCount === false && count > 1) {
              labelCount = count - 2;
            }

            html += `<td style="background-color: #eee; border-top: 1px solid #dadada;${style}"><strong>${calc}</strong></td>`;
          } else if (col.type === 'link' || col.type === 'delete') {
            html += '<td class="kn-table-link" style="background-color: #eee; border-top: 1px solid #dadada;">&nbsp</td>';
          } else {
            html += '<td style="background-color: #eee; border-top: 1px solid #dadada;">&nbsp</td>';
          }
        });

        // html += '</tr>'

        // var $totalRow = new Element(html)

        const $totalRow = document.createElement('tr');

        $totalRow.classList.add('kn-table-totals');
        $totalRow.innerHTML = html;

        // add title
        if (labelCount !== false) {
          let { label } = total;

          if (options.label) {
            label += ` - ${options.label}`;
          }

          const $td = [
            ...$totalRow.querySelectorAll('td'),
          ][labelCount];

          $td.style.textAlign = 'right';
          $td.innerHTML = `<strong>${label}</strong>`;
        }

        $lastRow.after($totalRow);
      });

      this.$el.querySelectorAll('table tbody tr').forEach(($tr) => {

        // $tr.style.display = 'block'
      });
    },

    performCalculations(summaryCalc, calc, val) {
      // blank values do not factor into summary calculations
      if (this.isBlank(val)) {
        return calc;
      }

      switch (summaryCalc) {
        case 'min':
          if (this.isBlank(calc) || val < calc) {
            calc = val;
          }

          break;

        case 'max':
          if (this.isBlank(calc) || val > calc) {
            calc = val;
          }

          break;

        case 'average':
          if (this.isBlank(calc.sum)) {
            calc.sum = val;
          } else {
            calc.sum += val;
          }
          calc.count += 1;
          break;

        case 'sum':
        default:
          if (this.isBlank(calc)) {
            calc = val;
          } else {
            calc += val;
          }
          break;
      }

      return calc;
    },
    calculateRowSummary(record, summary) {
      const pickedValues = _.pickBy(record, (value, key) => key.includes('raw_'));

      let calcValues = Object.values(pickedValues);

      // we're processing a summary of column summaries
      if (record.calcs) {
        // column summaries don't have a raw_ option
        // so we'll need to convert values that are commonly strings (Eg. currency) to numbers
        const calcPickedValues = _.pickBy(record.calcs, (value, key) => key.includes('calc_'));

        const values = Object.values(calcPickedValues);

        calcValues = values.map((val) => Number(formatNumber(val)));
      }

      // determines field format to use for all summaries
      const lastUsedField = this.reportCalculations[calcValues.length - 1]
        ? this.$store.getters.getField(this.reportCalculations[calcValues.length - 1].field)
        : null;

      const calculatedValue = this.performCalculationsForRowSummary(calcValues, summary);

      return this.formatCalc(summary, lastUsedField, calculatedValue);
    },
    performCalculationsForRowSummary(values, summary) {
      // exclude null/blank values from summaries
      const numValues = values.filter((num) => !this.isBlank(num));

      switch (summary.calc) {
        case 'sum':

          return numValues.reduce((num1, num2) => num1 + num2, 0);

        case 'average':

          return numValues.reduce((num1, num2) => num1 + num2, 0) / numValues.length;

        case 'min':

          return Math.min(...numValues);

        case 'max':

          return Math.max(...numValues);
      }
    },
    /**
     * Checks for values that should not be considered in table summaries.
     * @param {any} val
     * @returns {boolean}
     */
    isBlank(val) {
      return val === '' || val === undefined || val === null || (Array.isArray(val) && val.length === 0) || Number.isNaN(val);
    },
  },
};
