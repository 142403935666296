export default {
  methods: {
    trans(phrase) {
      const defaults = {
        export_format: 'Select the format to export the data in',
        forgot_pass: 'Forgot your Password?',
        pass_copy: ' Enter your email address below and we will send you a link to reset your password',
        pass_email: ' An email containing a link to reset your password has been sent.',
        pass_reset_1: 'Please click on the following link to reset the password for your ',
        pass_reset_2: ' account. If clicking the above link does not work, please copy and paste the URL into a new browser window.',
        reg_confirm: 'Your registration is complete! Click the back link to log in.',
        reg_pending: 'Your registration has been submitted for approval. Once approved you will receive an email with further instructions.',
        account_update_copy: 'Use these forms to update your account information or change your password.',
        account_confirm: 'Your account information has successfully been updated.',
        pass_confirm: 'Your password has been successfully updated.',
        pass_reset_confirm: 'Your password has been reset.',
        pass_reset_copy: 'Enter your email address below and we will send you a link to reset your password.',
        email_not_found: 'Email address not found. This email address does not have a current account.',
        form_submit_confirm: 'Form successfully submitted',
        reload_form: 'Reload form',
        login_permission: 'doesn\'t have permission to access this page',
        pass_reset_exceeded: 'Too many password reset requests. Please try again later.',
        submit_button_text: 'Submit',
        per_page: 'per page',
        page: 'page',
        repeat: 'Repeat...',
        all_day: 'All day',
      };

      if (window.Knack.translations && window.Knack.translations[phrase]) {
        return window.Knack.translations[phrase];
      }

      return defaults[phrase] || phrase;
    },
  },
};
