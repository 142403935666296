<template>
  <ConfirmModal
    title="You have unsaved changes"
    confirm-type="info"
    confirm-button-type="confirm"
    :confirm-text="confirmText"
    :cancel-text="cancelText"
    @confirm="onConfirmSaveChanges"
    @cancel="onConfirmCancelChanges"
    @close="onClose"
  >
    <slot>
      <p class="text-left mb-0">
        Do you want to save these changes before continuing?
      </p>
    </slot>
  </ConfirmModal>
</template>

<script>
import ConfirmModal from '@/components/ui/ConfirmModal';

export default {
  components: {
    ConfirmModal,
  },
  props: {
    confirmText: {
      type: String,
      default: 'Yes, save & continue',
    },
    cancelText: {
      type: String,
      default: 'No, continue without saving',
    },
    path: {
      type: String,
      required: true,
    },
  },
  emits: ['cancel', 'close', 'save'],
  methods: {
    onConfirmSaveChanges() {
      this.$emit('save');
    },
    onConfirmCancelChanges() {
      this.$emit('cancel');

      this.$router.push(this.path);
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
