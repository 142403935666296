import { colord } from 'colord';
import { defaultTheme, type ThemeObject } from '@knack/asterisk-react';

type ColorDeclaration = string | ColorDeclarationObject;

type ColorDeclarationObject = {
  [key: string | number]: ColorDeclaration;
};

export function getRgbChannels(hex: string) {
  const { r, g, b } = colord(hex).toRgb();
  return `${r} ${g} ${b}`;
}

export function isValidForAlpha(value: string) {
  // Only values that are valid colors and have no alpha channel are considered valid for
  // extracting RGB channels, and for generating the proper color declaration for Tailwind.
  return colord(value).isValid() && colord(value).alpha() === 1;
}

function getCssVariableDeclarations(
  input: ColorDeclaration,
  path: string[] = [],
  output: Record<string, string> = {},
) {
  let newOutput = output;

  Object.entries(input).forEach(([key, value]) => {
    const newPath = path.concat(key);
    if (typeof value !== 'string') {
      getCssVariableDeclarations(value, newPath, newOutput);
    } else {
      if (key === 'DEFAULT') return;

      newOutput = Object.assign(newOutput, {
        [`--${newPath.join('-')}`]: isValidForAlpha(value) ? getRgbChannels(value) : value,
      });
    }
  });

  return newOutput;
}

function getCssVariablesAsString(input: ColorDeclaration) {
  let output = '';
  Object.entries(getCssVariableDeclarations(input)).forEach(([key, value]) => {
    output += `${key}: ${value};\n\t`;
  });

  return output;
}

export function generateCssVariables() {
  const { colorPalette, colorAliases } = defaultTheme as ThemeObject;

  const colorPaletteColors = getCssVariablesAsString(colorPalette);
  const gradientColors = getCssVariablesAsString(colorAliases.gradients);
  const lightModeColors = getCssVariablesAsString(colorAliases.light);

  return `
  :root {
    ${colorPaletteColors}
    ${gradientColors}
  }
  [data-theme='light'] {
    ${lightModeColors}
  }
  `;
}
