import { mapGetters } from 'vuex';
import Field from '@/store/models/Field';

export default {
  computed: {
    ...mapGetters([
      'getObject',
      'getField',
    ]),
    objectKey() {
      return this.$route.params.objectKey;
    },
    backLink() {
      let backLink = `/records/objects/${this.objectKey}`;

      if (this.$route.fullPath.includes('?')) {
        backLink += `?${this.$route.fullPath.split('?')[1]}`;
      }

      return backLink;
    },
    inputs() {
      const object = this.object || this.getObject(this.objectKey);

      // Start with object inputs
      const inputs = object.getFieldInputs();

      // If a user record, we need to add a fake input to send an email introduction
      if (this.object.isUser()) {
        inputs.push({
          field: new Field({
            key: 'send_email_intro',
            format: {
              format: 'yes_no',
              input: 'dropdown',
            },
          }, this.object),
          type: window.Knack.config.BOOLEAN,
          format: {
            input: 'dropdown',
            format: 'yes_no',
            options: [
              {
                id: 'false',
                identifier: 'No',
              },
              {
                id: 'true',
                identifier: 'Yes',
              },
            ],
          },
          label: 'Send an email intro',
        });
      }

      return inputs;
    },

    defaultRecordValues() {
      const object = this.object || this.getObject(this.objectKey);

      const values = object.getFieldDefaults();

      // If a user record, we need to add a fake input to send an email introduction
      if (this.object.isUser()) {
        values.send_email_intro = false;
      }

      // If a role record, we need to default to that role
      if (this.object.isRole()) {
        // Get the user roles field
        const rolesField = this.object.getUserRoleField();

        // Assign as the default value
        values[rolesField.key] = [
          object.get('profile_key'),
        ];
      }

      return values;
    },

    recordId() {
      return this.$route.params.recordID;
    },

    fieldErrors() {
      return (this.errors || []).reduce((memo, error) => {
        memo[error.field] = error;

        return memo;
      }, {});
    },
  },
};
