<template>
  <button
    :class="buttonClasses"
    :style="themeOverride"
    :type="type"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <div
      v-if="isLoading"
      class="knButton__loader"
    >
      <transition name="fade">
        <Icon type="loading-spinner" />
      </transition>
    </div>
    <span :class="buttonContentClasses">
      <slot />
    </span>
  </button>
</template>

<script>
import Icon from '@/components/ui/Icon';

const BUTTON_THEMES = [
  'confirm',
  'cancel',
  'add',
  'info',
  'link',
  'delete',
];

const BUTTON_THEMES_MAP = {
  confirm: 'bg-gradient-primary',
  cancel: 'bg-transparent',
  delete: 'bg-red-600',
};

export default {
  name: 'BuilderButton',
  components: {
    Icon,
  },
  props: {
    size: {
      type: String,
      default: 'md',
    },
    type: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      required: true,
      validator: (value) => BUTTON_THEMES.includes(value),
    },
    themeOverride: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['click', 'submit'],
  computed: {
    buttonClasses() {
      return [
        'knButton',
        'button',
        `knButton--${this.size}`,
        `buttonTheme--${this.theme}`,
        'rounded-lg border-0 p-3 text-base leading-4 font-medium',
        {
          save: this.type === 'submit',
        },
        this.buttonThemeTailwindClass,
      ];
    },
    buttonContentClasses() {
      return [
        'knButton__content',
        'text-base',
        'leading-4',
        'font-medium',
        {
          'knButton__content--hide': this.isLoading,
        },
      ];
    },
    buttonThemeTailwindClass() {
      return BUTTON_THEMES_MAP[this.theme];
    },
  },
};
</script>

<style lang="scss" scoped>
.knButton {
  position: relative;
  display: inline-block;
  appearance: none;
  font-weight: 500 !important;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  transition:
    background-color .2s ease-in,
    border-color .2s ease-in,
    color .2s ease-in;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  &.buttonTheme--add {
    background-color: $add500;
    color: $white;

    &:hover {
      background-color: $add500;
      border-color: $add500;
    }
  }

  &.buttonTheme--cancel {
    background-color: $offWhite;
    border-color: $lightGray;
    color: $middleGray;

    &:hover {
      background-color: $lightGray;
      border-color: $offWhite;
      color: $darkerGray;
    }
  }

  &.buttonTheme--confirm {
    background-color: $confirmFill;
    color: $white;

    &:hover {
      background-color: $confirmFillHover;
      border-color: $confirmFillHover;
    }
  }

  &.buttonTheme--delete {
    background-color: $deleteFill;
    color: $white;

    &:hover {
      background-color: $deleteFillHover;
      border-color: $deleteFillHover;
      color: $white;
    }
  }

  &.buttonTheme--info {
    background-color: $infoFill;
    border-color: $infoOutline;
    color: $fuchsia-text;

    &:hover {
      background-color: $infoFillHover;
    }

    &.is-active,
    &.is-active:hover,
    &.is-active:focus {
      background-color: $blue-fill;
      border-color: $blue-fill;
      color: $white;
    }
  }

  &.buttonTheme--link {
    background-color: transparent;
    border-color: transparent;
    color: $fuchsia-text;
  }
}

.knButton:not(.knButton--isLoading):disabled,
.knButton:not(.knButton--isLoading)[disabled] {
  opacity: .6;
  cursor: default;
}

.knButton__loader {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.knButton__spinner {
  height: 80%;
}

.knButton__content {
  display: inline-flex;
  align-items: center;
  transition: opacity .2s ease;
  opacity: 1;
}

.knButton__content--hide {
  opacity: 0;
}

// Button sizes
.knButton--xs {
  font-size: calculateRem(12px);
  padding: 0 calculateEm(12px, 6px);
  height: calculateEm(12px, 20px);
  line-height: calculateEm(12px, 18px);
  border-radius: 3px;
}

.knButton--sm {
  font-size: calculateRem(12px);
  padding: 0 calculateEm(12px, 8px);
  height: calculateEm(12px, 24px);
  line-height: calculateEm(12px, 22px);
  border-radius: 3px;
}

.knButton--md {
  font-size: calculateRem(14px);
  padding: 0 calculateEm(14px, 16px);
  height: calculateEm(14px, 32px);
  line-height: calculateEm(14px, 30px);
  border-radius: 3px;
}

.knButton--lg {
  font-size: calculateRem(16px);
  padding: 0 calculateEm(16px, 16px);
  height: calculateEm(16px, 40px);
  line-height: calculateEm(16px, 38px);
  border-radius: 3px;
}

.knButton--xl {
  font-size: calculateRem(18px);
  padding: 0 calculateEm(18px, 16px);
  height: calculateEm(18px, 48px);
  line-height: calculateEm(18px, 46px);
  border-radius: 3px;
}
</style>
