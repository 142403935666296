<template>
  <span class="flex bg-white h-10 py-2 px-3 border border-solid border-default rounded-lg text-base text-emphasis hover:border-emphasis leading-5">
    <Popover>
      <template #trigger>
        <span class="add-view-picker-trigger text-default text-base w-full h-full m-0 p-0 border-0">
          {{ viewTypeName }}&nbsp;
          <Icon
            class="text-default"
            type="arrow-drop-down"
          />
        </span>
      </template>
      <template #content>
        <div class="links add-view-picker-links">
          <a
            v-if="canShowViewsForSingleRecord"
            v-close-popper
            :class="listItemClasses"
            data-cy="add-view-type"
            @click="onClickType('details')"
          >
            Details
          </a>
          <a
            v-close-popper
            :class="listItemClasses"
            data-cy="add-view-type"
            @click="onClickType('form')"
          >
            Form
          </a>
          <a
            v-if="canShowViewsForMultipleRecords"
            v-close-popper
            :class="listItemClasses"
            data-cy="add-view-type"
            @click="onClickType('table')"
          >
            Grid
          </a>
          <a
            v-if="canShowViewsForMultipleRecords"
            v-close-popper
            :class="listItemClasses"
            data-cy="add-view-type"
            @click="onClickType('list')"
          >
            List
          </a>
          <a
            v-if="canShowViewsForMultipleRecords"
            v-close-popper
            :class="listItemClasses"
            data-cy="add-view-type"
            @click="onClickType('search')"
          >
            Search
          </a>
          <a
            v-if="canShowViewsForMultipleRecords && canShowCalendarViews"
            v-close-popper
            :class="listItemClasses"
            data-cy="add-view-type"
            @click="onClickType('calendar')"
          >
            Calendar
          </a>
          <a
            v-if="canShowViewsForMultipleRecords && canShowMapViews"
            v-close-popper
            :class="listItemClasses"
            data-cy="add-view-type"
            @click="onClickType('map')"
          >
            Map
          </a>
        </div>
      </template>
    </Popover>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import Popover from '@/components/ui/Popover';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Popover,
    Icon,
  },
  props: {
    viewType: {
      type: String,
      default: '',
    },
    canShowViewsForSingleRecord: {
      type: Boolean,
      default: true,
    },
    canShowViewsForMultipleRecords: {
      type: Boolean,
      default: true,
    },
    canShowMapViews: {
      type: Boolean,
      default: true,
    },
    canShowCalendarViews: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:viewType'],
  data() {
    return {
      type: this.viewType,
    };
  },
  computed: {
    ...mapGetters([
      'objectHasDateField',
      'objectHasMapField',
    ]),
    viewTypeName() {
      return this.type === 'table' ? 'grid' : this.type;
    },
    listItemClasses() {
      return 'rounded-lg hover:bg-brand-50 text-base text-default';
    },
  },
  methods: {
    onClickType(whichType) {
      this.type = whichType;

      log(`changing viewType to: ${whichType}`);

      this.$emit('update:viewType', whichType);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-view-picker-trigger {
  border-bottom: 1px solid #a8abb3;
  cursor: pointer;
  font-weight: bold;
  line-height: 1.1em;
  margin: 0 3px;
  min-width: 0;
  padding: .25em .2em .15em;
  position: relative;
  white-space: nowrap;

  display: inline-flex;
  align-items: center;

  .icon-arrow-drop-down {
    width: 15px;
    height: 15px;
    color: #91267f;
    flex-shrink: 0;
    margin-left: -1px;
    margin-right: -4px !important;
  }
}
</style>
