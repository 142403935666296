import Builder from '@/components/Builder.vue';
import Connections from '@/components/connections/Connections.vue';
import ObjectsNav from '@/components/connections/ObjectsNav.vue';
import ConnectionsIntro from '@/components/connections/ConnectionsIntro.vue';
import ConnectionsContent from '@/components/connections/ConnectionsContent.vue';
import ConnectionAdd from '@/components/connections/ConnectionAdd.vue';
import Field from '@/components/fields/Field.vue';
import FieldSettings from '@/components/fields/FieldSettings.vue';
import FieldRulesValidation from '@/components/fields/FieldRulesValidation.vue';
import FieldRulesConditional from '@/components/fields/FieldRulesConditional.vue';

export default [
  {
    path: '/connections',
    component: Builder,
    children: [
      {
        path: '',
        component: Connections,
        children: [
          {
            path: '',
            components: {
              toolbox: ObjectsNav,
              body: ConnectionsIntro,
            },
          },

          {
            path: 'objects/:objectKey',
            components: {
              toolbox: ObjectsNav,
              body: ConnectionsContent,
            },
            props: {
              body: true,
            },
            children: [
              {
                path: 'add',
                components: {
                  modal: ConnectionAdd,
                },
              },
              {
                path: 'fields/:fieldKey',
                components: {
                  modal: Field,
                },
                children: [
                  {
                    path: 'settings',
                    component: FieldSettings,
                  },
                  {
                    path: 'rules/validation',
                    component: FieldRulesValidation,
                  },
                  {
                    path: 'rules/conditional',
                    component: FieldRulesConditional,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
