<template>
  <ViewToolbox
    :back-link="`/pages/${page.key}/views/${view.key}/map`"
    back-title="Map Menu"
    title="Add Map Details"
  >
    <Toggle
      :is-open="true"
      class="no-top-styles"
    >
      <template #title>
        <span>Details Layout</span>
      </template>
      <template #content>
        <DetailsLayout />
      </template>
    </Toggle>
    <Toggle :is-open="true">
      <template #title>
        <span>Add Details</span>
      </template>
      <template #content>
        <AddItems
          :view="view"
          :object="object"
        >
          <p class="text-sm leading-5">
            Drag or click options below to add new details to the locations list.
          </p>
        </AddItems>
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import { mapGetters } from 'vuex';
import AddItems from '@/components/views/AddItems';
import DetailsLayout from '@/components/views/shared/DetailsLayout';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'MapDetailsBuild',
  components: {
    AddItems,
    DetailsLayout,
    Toggle,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
};
</script>
