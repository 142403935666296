import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import hasIn from 'lodash/hasIn';

export default {
  methods: {
    formattedDate(date) {
      return date;
    },
    formattedTime(input, valueRaw, isEndTime) {
      const format = this.getMomentTimeFormatFromKnackTimeFormat(input.format.time_format);
      const time = isEndTime ? moment(valueRaw.timestamp).add(1, 'hours') : moment(valueRaw.timestamp);

      return time.format(format);
    },

    /**
     * Returns US format of defaultDate for input
     *
     * @param {Object} input the input object to acquire default date value for
     * @returns {String} date string
     */
    getDefaultDate(input) {
      if (input.format.default_type === 'none') {
        return '';
      }

      if (!isEmpty(input) && input.format.default_type === 'date') {
        return input.format.default_date;
      }

      return moment().format(this.getMomentDateFormatFromKnackDateFormat());
    },

    /**
     * Returns formatted value of defaultDate for input
     *
     * @param {Object} input the input object to acquire default date value for
     * @returns {String} date string
     */
    getFormattedDefaultDate(input) {
      const defaultDate = this.getDefaultDate(input);
      const dateFormat = this.getMomentDateFormatFromKnackDateFormat(input.format.date_format);

      return this.parsedDate(defaultDate, dateFormat);
    },
    getDefaultTime(input, isEndTime) {
      if (!input) {
        return moment().format(this.getMomentTimeFormatFromKnackTimeFormat());
      }

      const format = input.format.time_format ? this.getMomentTimeFormatFromKnackTimeFormat(input.format.time_format) : null;
      const time = isEndTime ? moment().add(1, 'hours') : moment();

      if (hasIn(input, 'format.time_type') && input.format.time_type === 'time') {
        return input.format.default_time;
      }

      if (!format) {
        return time;
      }

      return time.format(format);
    },
    getDefaultTimer(input) {
      const timer = {};
      const format = input.format.time_format ? this.getMomentTimeFormatFromKnackTimeFormat(input.format.time_format) : null;
      const minutes = moment(new Date(), format).minutes();
      const fromTime = moment(new Date(), format);

      timer.from = {
        hours: fromTime.hours(),
        minutes,
        amPm: fromTime.format('A'),
      };

      if (input.format.time_format === 'HH:MM am' && timer.from.amPm === 'PM' && timer.from.hours > 12) {
        timer.from.hours -= 12;
      } else if (input.format.time_format === 'HH:MM am' && timer.from.hours === 0) {
        timer.from.hours = 12;
      }

      const toTime = moment(new Date(), format);

      timer.to = {
        hours: toTime.add(1, 'hours').hours(),
        minutes,
        amPm: toTime.format('A'),
      };

      if (input.format.time_format === 'HH:MM am' && timer.to.amPm === 'PM' && timer.to.hours > 12) {
        timer.to.hours -= 12;
      } else if (input.format.time_format === 'HH:MM am' && timer.to.hours === 0) {
        timer.to.hours = 12;
      }

      return timer;
    },
    getMomentDateFormatFromKnackDateFormat(dateFormat) {
      switch (dateFormat) {
        case 'Ignore Date':
          return null;

        case 'dd/mm/yyyy':
          return 'DD/MM/YYYY';

        case 'M D, yyyy':
          return 'MM/DD/YYYY'; // Should probably be `MMMM DD, YYYY`, but V2 returns this

        case 'mm/dd/yyyy':
          return 'MM/DD/YYYY';

        default:
          return 'MM/DD/YYYY';
      }
    },
    getMomentTimeFormatFromKnackTimeFormat(timeFormat) {
      switch (timeFormat) {
        case 'Ignore Time':
          return null;

        case 'HH:MM am':
          return 'h:mma';

        case 'HH MM (military)':
          return 'HH:mm';

        default:
          return 'h:mma';
      }
    },
    convertToEuDate(date, format) {
      const dateFormat = this.getMomentDateFormatFromKnackDateFormat(format);
      const month = moment(date).month() + 1; // months are 0-11
      const day = moment(date).date();
      const year = moment(date).year();

      let newDate = moment(`${day}/${month}/${year}`, dateFormat).format(dateFormat);

      // handle case where the user deleted the date via typing
      if (newDate === 'Invalid date') {
        newDate = '';
      }

      return newDate;
    },
    parseTypedDateForDatepicker(date) {
      let momentDate;

      // date_time & timer inputs
      if (hasIn(this.input, 'format.date_format')) {
        const dateFormat = this.getMomentDateFormatFromKnackDateFormat(this.input.format.date_format);

        momentDate = moment(date, dateFormat);
      } else {
        momentDate = moment(date, 'MM/DD/YYYY');
      }

      return momentDate.toDate();
    },
    parsedDate(date, format = this.getMomentDateFormatFromKnackDateFormat()) {
      let newDate = moment(date).format(format);

      // handle case where the user deleted the date via typing
      if (newDate === 'Invalid date') {
        newDate = '';
      }

      return newDate;
    },
    parsedTimeRawValue(time, format) {
      return moment().startOf('day')
        .add(time, 'minutes')
        .format(this.getMomentTimeFormatFromKnackTimeFormat(format));
    },
    parsedTime(time, format) {
      return moment(time, this.getMomentTimeFormatFromKnackTimeFormat(format));
    },
    parsedHours(time, format) {
      return this.parsedTime(time, format).hours();
    },
    parsedMinutes(time, format) {
      return this.parsedTime(time, format).minutes();
    },
    parsedMeridiem(time, format) {
      return this.parsedTime(time, format).format('A');
    },
    parsedMinutesFromMidnight(time, format) {
      return this.parsedHours(time, format) * 60 + this.parsedMinutes(time, format);
    },
    pad(valueA, valueB) {
      return (String(1e15 + valueA)).slice(-valueB);
    },
  },
};
