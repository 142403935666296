<script setup>
import {
  defineProps, ref, onMounted, onUpdated, onBeforeUnmount,
} from 'vue';
import FontAwesome from '@/components/ui/FontAwesome';

// Props
const props = defineProps({
  entryPage: {
    type: Object,
    required: true,
  },
  dropdownPages: {
    type: Array,
    required: true,
  },
  dropdownToggleClasses: {
    type: Array,
    default: () => [],
  },
  isDropdownOpenByDefault: {
    type: Boolean,
    default: false,
  },
  isMobileDropdown: {
    type: Boolean,
    default: false,
  },
  onDropdownLinkClick: {
    type: Function,
    default: undefined,
  },
});

// State
const isDropdownMenuOpen = ref(props.isDropdownOpenByDefault);

// Refs to hold the references to elements
const dropdownListElement = ref(null);
const dropdownToggleElement = ref(null);

// Methods
const toggleDropdownMenu = () => {
  isDropdownMenuOpen.value = !isDropdownMenuOpen.value;
};

const onLinkClick = () => {
  isDropdownMenuOpen.value = false;

  if (props.onDropdownLinkClick) {
    props.onDropdownLinkClick();
  }
};

const onClickOutside = (event) => {
  // We close the dropdown menu when a click occurs outside of itself
  const isClickOutsideDropdown = dropdownListElement.value.contains(event.target);
  const isClickOutsideToggle = dropdownToggleElement.value.contains(event.target);

  if (!dropdownListElement.value || isClickOutsideDropdown || isClickOutsideToggle) {
    return;
  }

  isDropdownMenuOpen.value = false;
};

const isElementInViewport = (element) => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const adjustDropdownPosition = () => {
  const directionClass = 'knHeader__menu-dropdown-list--right';

  // On desktop, in some screen sizes we need to position edge of the dropdown menu relative
  // to the top-right side of the menu link to avoid the element going out of the viewport
  if (!isElementInViewport(dropdownListElement.value)) {
    dropdownListElement.value.classList.add(directionClass);
  } else {
    dropdownListElement.value.classList.remove(directionClass);
  }
};

// Lifecycle
onMounted(() => {
  if (!props.isMobileDropdown) {
    document.addEventListener('click', onClickOutside);
  }
});
onUpdated(() => {
  if (!props.isMobileDropdown) {
    adjustDropdownPosition();
  }
});
onBeforeUnmount(() => {
  if (!props.isMobileDropdown) {
    document.removeEventListener('click', onClickOutside);
  }
});
</script>

<template>
  <div>
    <button
      ref="dropdownToggleElement"
      :class="['knHeader__menu-dropdown-toggle knHeader__menu-link', props.dropdownToggleClasses]"
      @click="toggleDropdownMenu"
    >
      {{ props.entryPage.name }}
      <span class="knHeader__menu-link-icon knHeader__menu-link-icon--right">
        <i class="fa fa-angle-down" />
      </span>
    </button>
    <ul
      ref="dropdownListElement"
      :class="['knHeader__menu-dropdown-list', {'knHeader__menu-dropdown-list--open': isDropdownMenuOpen}]"
    >
      <template
        v-for="dropdownPage in props.dropdownPages"
        :key="dropdownPage.key"
      >
        <li class="knHeader__menu-dropdown-list-item">
          <a
            :href="dropdownPage.slug"
            :class="[
              'knHeader__menu-link knHeader__menu-dropdown-link',
              {'knHeader__mobile-nav-link': props.isMobileDropdown}
            ]"
            @click="onLinkClick"
          >
            <span
              v-if="dropdownPage.icon?.icon && dropdownPage.icon?.align === 'left'"
              class="knHeader__menu-link-icon knHeader__menu-link-icon--left"
            >
              <FontAwesome :name="dropdownPage.icon.icon" />
            </span>

            {{ dropdownPage.name }}

            <span
              v-if="dropdownPage.icon?.icon && dropdownPage.icon?.align === 'right'"
              class="knHeader__menu-link-icon knHeader__menu-link-icon--right"
            >
              <FontAwesome :name="dropdownPage.icon.icon" />
            </span>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>
