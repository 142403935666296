<template>
  <Toolbox
    back-title="Tables List"
    title="Add a Field"
    theme="add"
    class="no-padding"
  >
    <!-- this will show field settings in a modal -->
    <FieldAddSettings
      v-if="showAddFieldSettings"
      :field-new="fieldNew"
      :close-on-event="true"
      @close="onCloseModal"
      @save="onSave"
    />

    <RouterView
      name="addFieldModal"
      :base-url="`/schema/list/objects/${object.key}/fields/add`"
    />

    <p class="description text-xs text-default mb-1.5">
      Click or drag a field type to add to <b>{{ object.name }}</b>. <span class="keyboard-hint bg-subtle m-0">Shift + Click</span> to quick add.
    </p>

    <div id="add-field">
      <template
        v-for="(types, group) in fieldTypes"
        :key="group"
      >
        <Toggle :data-cy="group">
          <template
            #title
            class="field-add-title"
          >
            <span class="text-default text-sm capitalize mb-0.5">{{ group }}</span>
          </template>
          <template #content>
            <div class="field-add-types pb-4 border border-solid border-t-0 border-x-0 border-subtle mb-1.5">
              <template
                v-for="fieldType in types"
                :key="fieldType.type"
              >
                <div
                  v-if="canShowFieldType(fieldType)"
                  class="add-field-type bg-subtle p-2 rounded-lg text-default border-none"
                  :data-feature="`${fieldType.type}_field`"
                  data-feature-x-offset="-10"
                  data-feature-y-offset="-10"
                  :data-type="fieldType.type"
                  draggable="true"
                  @click.exact="onAddField($event, fieldType, {showAddFieldSettings: true})"
                  @click.shift.exact="onAddField($event, fieldType)"
                  @dragstart="onDragStart($event, fieldType)"
                  @dragend="onDragEnd"
                >
                  <TypeIcon
                    icon-classes="text-subtle"
                    :field="{type: fieldType.type}"
                  />
                  <span>{{ fieldType.name }}</span>
                </div>
              </template>
            </div>
          </template>
        </Toggle>
      </template>
    </div>
  </Toolbox>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { findDeep } from 'deepdash-es/standalone';
import Toolbox from '@/components/layout/Toolbox';
import FieldUtils from '@/components/fields/FieldUtils';
import TypeIcon from '@/components/fields/TypeIcon';
import FieldAddSettings from '@/components/fields/FieldAddSettings';
import Toggle from '@/components/ui/Toggle';
import RequestUtils from '@/components/util/RequestUtils';
import { logEvent } from '@/lib/segment-helper';

import { eventBus } from '@/store/bus';

export default {
  components: {
    Toolbox,
    TypeIcon,
    Toggle,
    FieldAddSettings,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  data() {
    return {
      showAddFieldSettings: false,
      fieldNew: {},
    };
  },
  computed: {
    fieldTypes() {
      return window.Knack.getFieldTypes();
    },
  },
  methods: {
    canShowFieldType(type) {
      return this.object.canAddFieldType(type);
    },
    onAddField(event, { type }, { showAddFieldSettings } = {}) {
      // Since this is a new field, always use the global definition
      const fieldDefinition = findDeep(window.Knack.getFieldTypes(), (value, key) => key === 'type' && value === type);

      if (!fieldDefinition) {
        return;
      }

      this.fieldNew = this.fieldMixin_createNewField(fieldDefinition.parent);

      // returns empty when field needs to go through it's own setup wizard (ie connections)
      if (isEmpty(this.fieldNew)) {
        return;
      }

      if (showAddFieldSettings || this.fieldNew.hasRequiredSettings) {
        this.showAddFieldSettings = true;

        return;
      }

      this.commitRequest({
        validate: () => this.fieldNew.validate(),
        request: () => this.fieldNew.create(),
        onSuccess: () => {
          if (this.fieldNew.type === 'connection') {
            logEvent('connection_added');
          }
        },
      });
    },
    onCloseModal() {
      this.showAddFieldSettings = false;
    },
    onSave(field) {
      this.showAddFieldSettings = false;
      this.fieldNew = {};
    },
    onDragStart(event, field) {
      event.stopPropagation();

      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('field', JSON.stringify(field));

      event.target.classList.add('is-dragging');
      event.target.style.cursor = 'grab';

      eventBus.$emit('dragStartFieldNew', event);
    },
    onDragEnd(event) {
      event.target.classList.remove('is-dragging');
      const dropTarget = document.querySelector('.drop-target.over');

      if (dropTarget) {
        dropTarget.classList.remove('over');
      }

      return eventBus.$emit('dragEndFieldNew', event);
    },
  },
};
</script>

<style lang="scss">
.description {
  margin-bottom: 1rem;
}

.keyboard-hint {
  display: inline-block;
  padding: 0 .25rem;
  margin: 0 .125rem;
  border-radius: .1875rem;
  background: $gray100;
  @include font-mono-caption;
}

#add-field {

  .toggle-trigger {
    text-transform: capitalize;
    line-height: 1;
    padding: .625rem 1rem;

    .field-icon {
      color: inherit;
      margin: 0 8px 0 4px;

      svg {
        width: 17px;
        height: 17px;
      }
    }
  }

  .toggle-content > *:last-child {
      padding-bottom: 1.25rem;
  }

  svg {
    width: 18px;
    height: 18px;
    transition: all 200ms ease-out;
  }

  &.open {
    > svg {
      transform: rotate(90deg);
    }
    > .field-icon {
      margin-right: 0;

      svg {
        width: 0px;
      }
    }
    span {
      color: inherit;
      font-weight: 600;
    }
  }
}

.field-add-types {
  transition: all .2s ease-out;

  .add-field-type {
    background-color: $gray100;
    border-radius: .1875rem;
    border: 1px solid $gray100;
    padding: .4375rem .5rem;
    margin-bottom: .25rem;
    position: relative;
    transition: all 150ms ease-out;
    display: flex;
    align-items: center;
    @include font-body;
    line-height: 1;
    color: $gray800;

    svg {
      display: inline-block;
      margin-right: 8px;
      width: 18px;
      height: 18px;
      color: $gray800;
      vertical-align: top;
    }

    .field-icon {
      display: inline-block;
    }

    .field-title {
      color: $gray800;
      @include font-body;
      display: inline-block;
    }

    &:hover {
      background-color: $gray100;
      border: 1px solid $gray200;
      color: $primary500;
      cursor: grab;

      .field-title {
        color: $primary500;
      }

      svg {
        color: $primary500;
      }
    }
  }
}
</style>
