<template>
  <RouterLink
    v-slot="{ isActive, navigate }"
    :to="settingsURL"
    custom
  >
    <button
      class="tile objectList field-item bg-muted py-1 pl-3 pr-2 rounded-lg border-0 hover:bg-brand-50 h-10 shadow-none"
      :class="{
        'router-link-active': isActive,
        'h-12': fieldKeyFilteringEnabled,
      }"
      data-cy="object-list-field"
      :style="tileStyle"
      @click="navigate"
    >
      <div
        class="tile_type objectList_type"
        :class="{ 'hidden-label': !shouldShowFieldType }"
      >
        <button
          v-tippy
          :content="isImmutable ? 'Field type cannot be changed' : 'Change field type'"
          type="button"
          class="type"
          :class="{ 'is-disabled': isImmutable }"
          @click.stop="navigateEditFieldType()"
        >
          <TypeIcon
            icon-classes="text-subtle"
            field-title-classes="text-default text-xs h-fit"
            :field="field"
            :title="shouldShowFieldType"
            :show-tooltip="false"
          />
        </button>
      </div>
      <div
        v-if="isImmutable"
        class="immutableIcon"
      >
        <Icon
          v-tippy
          :content="getFieldTooltipString()"
          class="immutableIcon_icon text-default"
          :type="getFieldRequiredIcon()"
        />
      </div>
      <div
        :class="[
          'tile_name_wrapper',
          'justify-center',
          { 'two-line': fieldKeyFilteringEnabled }
        ]"
      >
        <div
          class="tile_name text-sm font-medium"
          data-cy="field-name"
        >
          <label class="truncate text-emphasis mb-0">{{ field.get('name') }}</label>
          <HelpIcon
            v-if="description"
            class="objectList_description flex self-center"
            icon="info-circle"
            actionable
            :text="description"
          />
        </div>
        <template v-if="fieldKeyFilteringEnabled">
          <span class="field-key-display text-default">{{ field.get('key') }}</span>
        </template>
      </div>
      <div
        v-if="isIntegration"
        class="field-icon"
      >
        <Icon :type="field?.get('integration')?.toLowerCase()" />
      </div>

      <Popover
        ref="warningPopover"
        boundary="body"
      >
        <template #content>
          <div class="objectList_lastFieldWarning">
            <div class="wrapper medium">
              Each table needs at least one field. Add a new field and then you can delete this one.
            </div>
          </div>
        </template>
      </Popover>

      <div class="tile_indicators">
        <RouterLink
          v-if="shouldShowValidationIndicator"
          v-tippy
          :content="`${field.validationCount} Active Validation Rules`"
          :to="validationURL"
        >
          <FieldIndicator
            icon="validation-rules"
            :class="rightItemButtonClasses"
            :count="field.validationCount"
            :is-interactive="true"
          />
        </RouterLink>
        <RouterLink
          v-if="shouldShowRulesIndicator"
          v-tippy
          :content="`${field.rulesCount} Active Conditional Rules`"
          :to="conditionalURL"
        >
          <FieldIndicator
            icon="conditional-rules"
            :class="rightItemButtonClasses"
            :count="field.rulesCount"
            :is-interactive="true"
          />
        </RouterLink>
        <RouterLink
          v-if="shouldShowFilterIndicator"
          v-tippy
          content="Filters applied"
          :to="settingsURL"
        >
          <FieldIndicator
            icon="filter-indicator"
            :class="rightItemButtonClasses"
            :count="0"
            :is-interactive="true"
          />
        </RouterLink>
      </div>

      <Popover
        ref="optionsPopover"
        placement="bottom-end"
      >
        <template #trigger>
          <button
            v-tippy
            content="Settings, rules, delete & more"
            type="button"
            class="button-square settings"
            :class="rightItemButtonClasses"
            data-cy="field-settings-icon"
            @click.stop.prevent
          >
            <Icon
              class="text-default"
              type="more-horiz"
            />
          </button>
        </template>

        <template #content>
          <div class="links">
            <RouterLink
              v-close-popper
              :to="settingsURL"
              class="hover:text-emphasis"
              :class="fieldOptionsListItemClasses"
              data-cy="field-settings"
            >
              <Icon
                class="text-inherit"
                type="settings"
              /> Settings
            </RouterLink>
            <RouterLink
              v-if="canUseRules"
              v-close-popper
              :to="validationURL"
              class="hover:text-emphasis"
              :class="fieldOptionsListItemClasses"
              data-cy="field-validation-rules"
            >
              <Icon
                class="text-inherit"
                type="validation-rules"
              /> Validation Rules
            </RouterLink>
            <RouterLink
              v-if="canUseRules"
              v-close-popper
              :to="conditionalURL"
              class="hover:text-emphasis"
              :class="fieldOptionsListItemClasses"
              data-cy="field-conditional-rules"
            >
              <Icon
                class="text-inherit"
                type="conditional-rules"
              /> Conditional Rules
            </RouterLink>
            <RouterLink
              v-if="!isImmutable"
              v-close-popper
              :to="editFieldTypeURL"
              class="hover:text-emphasis"
              :class="fieldOptionsListItemClasses"
              data-cy="field-change-type"
            >
              <Icon
                class="text-inherit"
                type="change-replace"
              /> Change Field Type
            </RouterLink>
            <RouterLink
              v-if="showPerformanceLink"
              v-close-popper
              :to="performanceURL"
              class="hover:text-emphasis"
              :class="fieldOptionsListItemClasses"
              data-cy="field-performance"
            >
              <Icon
                class="text-inherit"
                type="action-task"
              /> Performance
            </RouterLink>
            <hr
              v-if="!canDelete || !isImmutable"
              class="block m-2 h-px bg-subtle border-none"
            >
            <a
              v-if="!canDelete"
              data-cy="field-delete"
              class="text-destructive hover:text-destructive"
              :class="fieldOptionsListItemClasses"
              @click="onClickLastFieldDelete"
            >
              <Icon
                class="text-inherit"
                type="delete"
              /> Delete
            </a>
            <RouterLink
              v-else-if="!isImmutable"
              v-close-popper
              :to="deleteURL"
              class="text-destructive hover:text-destructive"
              :class="fieldOptionsListItemClasses"
              data-cy="field-delete"
            >
              <Icon
                class="text-inherit"
                type="delete"
              /> Delete
            </RouterLink>
          </div>
        </template>
      </Popover>
    </button>
  </RouterLink>
</template>

<script>
import hasIn from 'lodash/hasIn';
import { mapGetters } from 'vuex';
import {
  isCRM,
  isDevelopment,
} from '@/lib/env-helper';
import FieldIndicator from '@/components/fields/FieldIndicator';
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';
import HelpIcon from '@/components/ui/HelpIcon';
import TypeIcon from '@/components/fields/TypeIcon';

const breakpoint1 = 600;

export default {
  name: 'SchemaObjectListField',
  components: {
    TypeIcon,
    Icon,
    HelpIcon,
    Popover,
    FieldIndicator,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('ui', [
      'rightBodyWidth',
    ]),
    ...mapGetters('ui/filterSchemaFields', {
      fieldKeyFilteringEnabled: 'getFilteringByKeyEnabled',
    }),
    showPerformanceLink() {
      return isCRM() || isDevelopment();
    },
    URLPrefix() {
      return `/schema/list/objects/${this.field.objectKey}/fields/${this.field.id}`;
    },
    settingsURL() {
      return `${this.URLPrefix}/settings`;
    },
    editFieldTypeURL() {
      return `${this.URLPrefix}/type`;
    },
    validationURL() {
      return `${this.URLPrefix}/rules/validation`;
    },
    conditionalURL() {
      return `${this.URLPrefix}/rules/conditional`;
    },
    performanceURL() {
      return `${this.URLPrefix}/performance`;
    },
    deleteURL() {
      return `${this.URLPrefix}/delete`;
    },
    description() {
      return this.field.get('meta').description;
    },
    canUseRules() {
      return this.field.canUseRules();
    },
    isImmutable() {
      return this.field.immutable;
    },
    isUserField() {
      return this.field.get('user');
    },
    isEcommerceField() {
      return this.field.get('ecommerce');
    },
    shouldShowValidationIndicator() {
      return this.canUseRules && (this.field.validationCount > 0);
    },
    shouldShowRulesIndicator() {
      return this.canUseRules && (this.field.rulesCount > 0);
    },
    shouldShowFilterIndicator() {
      const isFormula = this.field.isFormula();
      const hasFilters = hasIn(this.field.format, 'filters') && this.field.format.filters.length > 0;

      return isFormula && hasFilters;
    },
    shouldShowFieldType() {
      return this.rightBodyWidth > breakpoint1;
    },
    tileStyle() {
      if (this.fieldKeyFilteringEnabled) {
        return {
          height: '56px',
          paddingTop: '9px',
          paddingBottom: '9px',
        };
      }

      return null;
    },
    isIntegration() {
      return !!this.field?.get('integration');
    },
    isIntegrationReadOnlyField() {
      return this.isIntegration && this.isImmutable && !this.field.read_only;
    },
    rightItemButtonClasses() {
      return 'bg-transparent hover:bg-brand-100';
    },
    fieldOptionsListItemClasses() {
      return 'rounded hover:bg-brand-50 text-base text-default';
    },
  },
  methods: {
    onClickLastFieldDelete() {
      this.$refs.optionsPopover.$refs.popper.hide();

      this.$refs.warningPopover.$refs.popper.show();
    },
    navigateEditFieldType() {
      if (!this.isImmutable) {
        this.$router.push(this.editFieldTypeURL);
      }
    },
    getFieldRequiredIcon() {
      if (this.isEcommerceField || !this.isUserField) {
        return this.isIntegrationReadOnlyField ? 'lock' : 'credit-card';
      }

      return 'user';
    },
    getFieldTooltipString() {
      let fieldString;

      if (this.isEcommerceField || !this.isUserField) {
        if (this.isIntegrationReadOnlyField) {
          return 'Read-only field from integration';
        }
        fieldString = 'payment';
      } else {
        fieldString = 'user role';
      }

      return `This is a required ${fieldString} field`;
    },
  },
};
</script>

<style lang="scss">
.objectList {
  width: 100%;
  text-align: left;

  &:hover,
  &:focus {
    background-color: $gray50;
    border: 1px solid $gray100;
  }
}

.objectList_type {
  .type {
    display: flex;

    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;

    &.is-disabled {
      cursor: default;
    }

    .field-title {
      margin-left: $spacing-xs;
      height: 18px;
      @include font-body;
    }

      &:hover {
        text-decoration: none;
        color: $primary500;
      }
  }

  .type:not(.is-disabled):hover .field-title,
  .type:not(.is-disabled):focus .field-title {
    color: $fuchsia-text;
    text-decoration: none;
  }
}

.objectList_description {
  display: inline-block;
  vertical-align: middle;
  margin-left: .5rem;
}

.objectList_lastFieldWarning {
  max-width: 360px;
  padding: 1em;
}

.immutableIcon {
  width: 16px;
  display: flex;
  margin-right: $spacing-xs;

  &_icon {
    width: 16px !important;
    height: 16px !important;
    color: $fuchsia;
  }
}
</style>

<style lang="scss" scoped>
  .tile_type.hidden-label {
    flex-basis: 32px;
  }

  .tile_name_wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &.two-line {
      height: 37px;
    }
  }

  .tile_name {
    flex: 0 0 20px;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;

    label {
      overflow: hidden;
    }
  }

  .field-key-display {
    flex: 0 0 17px;
    color: $gray500;
    font-size: 12px;
    line-height: 1.4;
  }
</style>
