<template>
  <view-toolbox
    back-title="Column"
    title="Display Rules"
  >
    <p clas="text-sm leading-5 mb-0">
      Add rules to update the column's display.
    </p>
    <form
      v-if="column"
      id="display-rules-editor"
      class="rules-editor"
    >
      <div>
        <GroupList
          v-model:items="column.rules"
          item-title="Display Rule"
          class="display-rules small"
        >
          <template #default="{item}">
            <div>
              <div class="margin-bottom-half">
                <p><b>If</b> the following rules match</p>
                <FieldList
                  :object-key="object.key"
                  :items="item.criteria"
                  :rule-type="RuleType.Display"
                  class="is-grouped is-contained"
                  @update:items="onUpdateCriteria(item, $event)"
                />
              </div>

              <div>
                <p><b>Then</b></p>
                <ActionList
                  v-model:items="item.actions"
                  :default-item="defaultItemAction"
                  class="is-grouped is-contained"
                >
                  <template #default="props">
                    <div class="display-rule-actions items-center">
                      <select
                        v-model="props.item.action"
                        data-cy="display-rule-action"
                      >
                        <option value="text-color">
                          Set Text Color
                        </option>
                        <option value="text-style">
                          Set Text Style
                        </option>
                        <option value="bg-color">
                          Set Background Color
                        </option>
                        <option value="icon">
                          Display Icon
                        </option>
                        <option value="hide">
                          Hide Value
                        </option>
                      </select>

                      <ColorPicker
                        v-if="props.item.action === 'text-color' || props.item.action === 'bg-color'"
                        v-model="props.item.color"
                      />

                      <div
                        v-if="props.item.action === 'text-style'"
                        class="text-style-options"
                      >
                        <a
                          :class="{active: props.item.bold}"
                          @click="props.item.bold = !props.item.bold"
                        >
                          <b>B</b>
                        </a>
                        <a
                          :class="{active: props.item.italic}"
                          @click="props.item.italic = !props.item.italic"
                        >
                          <i>I</i>
                        </a>
                        <a
                          :class="{active: props.item.strikethrough}"
                          @click="props.item.strikethrough = !props.item.strikethrough"
                        >
                          <s>S</s>
                        </a>
                      </div>

                      <template v-if="props.item.action === 'icon'">
                        <IconPicker
                          v-model:icon="props.item.icon"
                          :ignore-delete="true"
                          :ignore-edit-button="true"
                        />
                        <ColorPicker
                          v-if="props.item.icon.icon"
                          v-model="props.item.color"
                        />
                      </template>
                    </div>
                  </template>
                </ActionList>
              </div>
            </div>
          </template>
        </GroupList>
        <div>
          <a
            class="button small add-fill"
            @click="onAddListItem"
          >
            <Icon type="add" /> Add rule
          </a>
        </div>
      </div>
    </form>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import GroupList from '@/components/ui/lists/GroupList';
import FieldList from '@/components/ui/lists/FieldList';
import ActionList from '@/components/ui/lists/ActionList';
import IconPicker from '@/components/ui/inputs/IconPicker';
import Icon from '@/components/ui/Icon';
import ColorPicker from '@/components/ui/inputs/ColorPicker';
import { RuleType } from '@/constants/rules';

export default {
  name: 'TableColumnDisplayRules',
  components: {
    ViewToolbox,
    GroupList,
    FieldList,
    ActionList,
    IconPicker,
    Icon,
    ColorPicker,
  },
  mixins: [
    ViewUtils,
  ],
  data() {
    return {
      RuleType,
    };
  },
  computed: {
    column() {
      if (!this.hasActiveView) {
        return undefined;
      }

      return this.viewRaw.columns[this.$route.params.columnIndex];
    },

    field() {
      if (!this.column.field) {
        return undefined;
      }

      return this.getField(this.column.field.key);
    },
  },
  methods: {
    onAddListItem() {
      if (!this.column.rules) {
        this.column.rules = [];
      }

      this.column.rules.push(this.defaultItem());
    },
    defaultCriteria() {
      const field = this.field || this.object.fields[0];

      return {
        field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
      };
    },
    onUpdateCriteria(item, newCriteria) {
      item.criteria = newCriteria;
    },
    defaultItem() {
      return {
        actions: [
          this.defaultItemAction(),
        ],
        criteria: [
          this.defaultCriteria(),
        ],
      };
    },
    defaultItemAction() {
      return {
        action: 'text-color',
        color: '#2ab27b',
        icon: {
          icon: '', align: 'left',
        },
        bold: true,
        italic: false,
        strikethrough: false,
      };
    },
  },
};
</script>

<style lang="scss">
.display-rule-actions {
  select {
    flex: 1 1 160px;
    margin-right: 6px;
  }
}

.text-style-options {
  display: flex;

  a {
    background-color: #eee;
    border-radius: .35em;
    border: 1px solid #eee;
    height: 2em;
    line-height: 2em;
    display: block;
    width: 2em;
    text-align: center;
    margin-left: 5px;

    &.active {
      border: 1px solid #771b66;
    }
  }

  a:first-child {
    margin: 0;
  }
}
</style>
