<template>
  <div class="body-wrapper">
    <ObjectsEmpty v-if="noObjects" />

    <PagesEmpty
      v-else-if="isEmptyPages"
      :render-add-page-wizard="renderAddPageWizard"
    />

    <EmptyStateGeneric
      v-else
      top
    >
      <div class="mb-2">
        <Icon
          type="arrow-right-circle"
          class="tw-rotate-180 h-auto w-[110px] fill-[url(#svg-brand-gradient)] opacity-40"
        />
      </div>

      <h2 class="margin-bottom-lg text-xl text-emphasis font-medium mb-2">
        Select a Page
      </h2>

      <p class="text-default mb-6">
        Select a page from the left panel or create a new one
      </p>

      <BuilderButton
        theme="confirm"
        size="lg"
        class="mb-6"
        data-feature="add_page_button"
        data-feature-x-offset="-26"
        data-feature-y-offset="-26"
        @click="renderAddPageWizard"
      >
        <icon type="add" /> Add a Page
      </BuilderButton>
    </EmptyStateGeneric>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ObjectsEmpty from '@/components/objects/ObjectsEmpty';
import EmptyStateGeneric from '@/components/ui/EmptyStateGeneric';
import PagesEmpty from '@/components/pages/PagesEmpty';
import Icon from '@/components/ui/Icon';
import BuilderButton from '@/components/ui/BuilderButton';

export default {
  components: {
    ObjectsEmpty,
    PagesEmpty,
    Icon,
    BuilderButton,
    EmptyStateGeneric,
  },
  props: {
    layout: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'objects',
    ]),

    activePage() {
      return this.$store.getters.activePage; // this.getPageByKey(this.$route.params.pageKey)
    },

    noObjects() {
      return this.objects.length === 0;
    },
    isEmptyPages() {
      return this.$store.getters.isEmptyPages();
    },
    isNewPage() {
      return Boolean(this.activePage && this.activePage.isNewPage());
    },
  },
  created() {
    return this.$store.commit('activeView', false);
  },
  methods: {
    renderAddPageWizard() {
      this.$router.push('/pages/add/option');
    },
  },
};
</script>
