<template>
  <div>
    <ChoicePicker
      v-model="detailsLayout"
      :choices="layoutOptions"
    />
  </div>
</template>

<script>
import ChoicePicker from '@/components/ui/inputs/ChoicePicker';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  components: {
    ChoicePicker,
  },
  mixins: [
    ViewUtils,
  ],
  props: {
    layout: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      layoutOptions: [
        {
          value: 'full', icon: 'layout-1-col', label: 'One Column - Full Width',
        },
        {
          value: 'one_quarter', icon: 'layout-2-col-xs', label: 'Two Columns - Left Quarter',
        },
        {
          value: 'one_third', icon: 'layout-2-col-s', label: 'Two Columns - Left Third',
        },
        {
          value: 'half', icon: 'layout-2-col-m', label: 'Two Columns - Half',
        },
        {
          value: 'two_thirds', icon: 'layout-2-col-l', label: 'Two Columns - Right Third',
        },
        {
          value: 'three_quarters', icon: 'layout-2-col-xl', label: 'Two Columns - Right Quarter',
        },
      ],
    };
  },
  computed: {
    detailsLayout: {
      get() {
        return this.view.getDetailsLayout() || this.layoutOptions[0].value;
      },
      set(newVal) {
        this.view.setDetailsLayout(newVal);

        this.updateLayout(newVal);
      },
    },
  },
  methods: {
    updateLayout(newVal) {
      log('watch layout!~!!', newVal);

      const columns = this.view.getDetailsSchema();

      // Remove the second column if the new layout is for one column
      if (newVal === 'full' && columns.length === 2) {
        columns.splice(1, 1);
      }

      // Add a second column if the new layout is for two columns
      if (newVal !== 'full' && columns.length === 1) {
        columns.push({
          groups: [
            {
              label_format: this.view.getDetailsLabelFormat(),
              columns: [
                [],
              ],
            },
          ],
        });
      }

      switch (newVal) {
        case 'one_quarter':
          columns[0].width = 25;
          columns[1].width = 75;
          break;

        case 'one_third':
          columns[0].width = 33;
          columns[1].width = 66;
          break;

        case 'half':
          columns[0].width = 50;
          columns[1].width = 50;
          break;

        case 'two_thirds':
          columns[0].width = 66;
          columns[1].width = 33;
          break;

        case 'three_quarters':
          columns[0].width = 75;
          columns[1].width = 25;
          break;

          // `full`
        default:
          columns[0].width = 100;
          columns.splice(1, 1); // Our second column will always be hidden on full-width
          break;
      }

      this.view.setDetailsSchema(columns);
    },
  },
};
</script>

<style lang="scss">
</style>
