<template>
  <input
    :value="localValue"
    class="kn-input"
    :name="name"
    :disabled="disabled"
    :placeholder="placeholder"
    :autocomplete="isAutocomplete"
    type="text"
    @input="onInput"
    @blur="onBlur"
  >
</template>

<script>
import isNil from 'lodash/isNil';
import NamedColors from '@/util/NamedColors';

export default {
  name: 'TextInput',
  props: {
    name: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'error'],
  data() {
    return {
      error: false,
    };
  },
  computed: {
    localValue: {
      get() {
        // If we're getting a new value from our parent and it's valid clear errors
        if (this.validateColorHex(this.modelValue)) {
          this.syncError(null);
        }

        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    isAutocomplete() {
      return this.autocomplete ? 'on' : 'off';
    },
  },
  methods: {
    onInput($event) {
      const color = $event.target.value;

      // only sync color value to parent if the string is a valid hex color
      // to prevent shade calculations from erroring in style-engine
      if (this.validateColorHex(color)) {
        this.syncError(null);
        this.localValue = color;
      }
    },
    onBlur($event) {
      // validate color value on blur so they aren't fighting errors as they're typing
      // but they can't move on to the next input without knowing it's not valid

      const color = $event.target.value;

      if (!this.validateColorHex(color)) {
        // if we don't have a valid color update state and sync message to parent
        return this.syncError('Please provide a valid color value');
      }

      // color is valid, if we're in an error state undo it
      if (this.error) {
        this.syncError(null);
      }
    },
    validateColorHex(value) {
      const regex = /^#([0-9A-F]{3}){1,2}$/i;

      return regex.test(value) || NamedColors.includes(value.toLowerCase());
    },
    syncError(message) {
      this.error = !isNil(message);
      this.$emit('error', message);
    },
  },
};
</script>

<style lang="scss" scoped>
.kn-input {
  min-width: 78px;
  padding-right: 2px;
}
</style>
