<template>
  <div
    class="toolbox-title-wrapper bg-transparent"
    data-cy="toolbox-title"
    :class="theme"
  >
    <div class="toolbox-title p-4">
      <a
        class="toolbox-back text-default mt-0 py-1 mb-1"
        data-cy="toolbox-back"
        @click="navigateBack"
      >
        <Icon type="arrow-back" />
        <span class="capitalize text-xs text-default">{{ backTitle }}</span>
      </a>
      <h2 class="truncate text-xl text-emphasis font-medium leading-6">
        {{ title }}
      </h2>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    backCount: {
      type: Number,
      default: 0,
    },
    backTitle: {
      type: String,
      default: '',
    },
    backLink: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    navigateBack() {
      if (this.backLink) {
        log('navigating to :', this.backLink);

        return this.$router.push(this.backLink);
      }

      const pathSplit = this.$route.path.split('/');

      const pathBackCount = this.backCount || 1;

      if (this.$route.path.includes('/report/')) {
        return this.$router.push(`/pages/${this.$route.params.pageKey}/views/${this.$route.params.viewKey}/report`);
      }

      if (this.$route.path.includes('/pages/') && pathSplit.length <= 6 && this.$route.params.pageKey) {
        const { activePage } = this.$store.getters;

        const pageRoute = activePage ? activePage.getPageRoute : '/pages';

        return this.$router.push(pageRoute);
      }

      return this.$router.push(pathSplit.slice(0, pathSplit.length - pathBackCount).join('/'));
    },
  },
};
</script>

<style lang="scss">
.toolbox-title-wrapper {
  background-color: #2e8aaf;
  display: flex;
}

.toolbox-title {
  padding: 1px 1em;
  flex-grow: 1;
  min-height: 48px;
  max-width: 100%;

  .toolbox-back {
    color: $white75;
    text-transform: uppercase;
    margin: .25rem 0 .0625rem;
    display: flex;
    align-items: center;

    &:hover {
      color: $white100;
    }

    * {
      vertical-align: middle;
    }

    svg {
      width: 1rem;
      height: 1rem;
      display: inline-block;
      vertical-align: middle;

      g {
        path:first-child {
          fill: currentColor;
        }
      }
    }

    span {
      @include font-h5;
      font-weight: 500;
      line-height: 1em;
      display: inline-block;
      margin-left: .25rem;
    }
  }

  > h2 {
    margin: 0;
    color: #fff;
    @include font-h6;
    font-weight: 600;
  }
}

.toolbox-title-wrapper.add {
  background-color: $add500;

  > h2 {
    color: #fff;
  }
}

.toolbox-title-wrapper.active {
  background-color: #1875ac;

  > h2 {
    color: #fff;
  }

  .toolbox-back {
    color: #dbeae4;
  }
}
</style>
