<template>
  <div>
    <!-- INTRO -->
    <form class="kn-builder-form margin-bottom-double">
      <div>
        <div class="input-labels tw-input-labels">
          <label style="margin-bottom: 1em;">
            <input
              v-model="pageTypeLocal"
              type="radio"
              value="public"
            ><div><b>Public</b>: anyone with the URL can access.</div>
          </label>

          <label>
            <input
              v-model="pageTypeLocal"
              type="radio"
              value="login"
            >
            <div v-if="isUsersEnabled"><b>Protected</b>: a user must login to access.</div>
            <div v-else><b>Protected</b>: click to enable users and require a login to access.</div>
          </label>
        </div>
      </div>
    </form>
    <div class="flex justify-end">
      <a
        class="button orange-fill small rounded-lg border-0 p-3 text-base leading-4 font-medium save bg-gradient-primary button green-fill text-white h-auto m-0"
        data-cy="page-add-option-continue"
        @click="submit()"
      >
        Continue
      </a>
    </div>

    <ObjectEnableUsers
      v-if="showEnableUsersWizard"
      @close="onCloseOverlay"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ObjectEnableUsers from '@/components/objects/ObjectEnableUsers';

export default {
  components: {
    ObjectEnableUsers,
  },
  props: {
    pageType: {
      type: String,
      default: 'public',
    },
  },
  emits: ['submit'],
  data() {
    return {
      pageTypeLocal: this.pageType,
      showEnableUsersWizard: false,
    };
  },
  computed: {
    ...mapGetters([
      'isUsersEnabled',
    ]),
  },
  watch: {
    pageTypeLocal(newVal) {
      if (newVal === 'login' && !this.isUsersEnabled) {
        this.showEnableUsersWizard = true;
      }
    },
  },
  methods: {
    submit() {
      log('add option save');

      return this.$emit('submit', this.pageTypeLocal);
    },
    onCloseOverlay() {
      this.pageTypeLocal = !this.isUsersEnabled ? 'public' : 'login';
      this.showEnableUsersWizard = false;
    },
  },
};
</script>
