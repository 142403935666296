<template>
  <div
    class="search_wrapper"
    :class="{ 'is-collapsed': !expanded }"
  >
    <form
      class="search"
      @submit.prevent="searchSubmit"
    >
      <button
        type="button"
        class="search_expand"
        @click="showSearch"
      >
        <Icon
          type="search"
          class="search_icon"
          :class="{ 'is-active': expanded }"
        />
      </button>
      <input
        ref="searchInput"
        v-model="searchQuery"
        type="text"
        class="search_input outline-0"
        placeholder="Search"
        @keyup.esc="cancelSearch"
      >
      <button
        type="button"
        class="search_close"
        @click="cancelSearch"
      >
        Cancel
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/ui/Icon';
import { mapApiFormGetters } from '@/lib/vuex-helper';

export default {
  components: {
    Icon,
  },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    searchKeyword: {
      type: String,
      default: '',
    },
  },
  emits: ['update:expanded'],
  computed: {
    ...mapApiFormGetters('api/help/search', {
      searchQuery: 'query',
    }),
  },
  methods: {
    ...mapActions('api/help/search', {
      searchReset: 'reset',
      searchResetForm: 'resetForm',
      searchSubmit: 'submitForm',
      searchUpdateForm: 'updateForm',
    }),
    toggleExpand(value) {
      this.$emit('update:expanded', value);

      if (value) {
        this.$refs.searchInput.focus();
      }
    },
    showSearch() {
      this.toggleExpand(true);
    },
    cancelSearch() {
      this.toggleExpand(false);

      const searchReset = () => {
        this.searchReset();
        this.searchResetForm();
      };

      // setting a timeout so the expand animation has completed
      // before we unset the input value to prevent flickering
      setTimeout(() => {
        searchReset();
      }, 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  align-items: center;
  display: flex;
  flex-direction: row;

  width: 100%;

  &_wrapper {
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;

    width: 100%;

    background-color: #fff;
    overflow: hidden;

    transition: width .2s ease-out;

    &.is-collapsed {
      width: 20px;
    }
  }

  &_expand {
    align-items: center;
    appearance: none;
    display: flex;
    flex-basis: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;

    padding: 0;

    background: transparent;
    border: 0;
  }

  &_icon {
    height: 18px;
    width: 18px;

    color: #8E9293;

    transition: color .2s ease-out;

    &.is-active {
      color: $fuchsia;
    }
  }

  &_input {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;

    height: 20px;
    margin: 0 $spacing-xs;
    padding: 0;

    background: transparent;
    border: none;

    &:focus {
      border: none !important;
    }
  }

  &_close {
    appearance: none;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;

    padding: 0;
    margin: 0;

    background: transparent;
    color: rgba($fuchsia, 0.7);
    font-size: calculateRem(13px);
    line-height: 1.23;

    border: none;
  }
}
</style>
