<template>
  <ConfirmModal
    confirm-type="alert"
    :back="backLink"
    :is-centered="true"
    @confirm="onConfirmDeletePage"
  >
    <template #titleContent>
      <h1 class="text-xl">
        Delete the {{ pageNameToDelete }} {{ pageTypeToDelete }}
      </h1>
    </template>
    <p class="text-left">
      This will delete the {{ pageNameToDelete }} {{ pageTypeToDelete }}.
    </p>
    <p class="text-left mb-0">
      <span v-if="pageType === 'menu'">
        Any pages and views underneath will be separated and placed on the navigation menu.
      </span>
      <span v-else>
        Any child pages and views will also be deleted.
      </span>
    </p>
  </ConfirmModal>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmModal from '@/components/ui/ConfirmModal';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  components: {
    ConfirmModal,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    backLink: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'app',
    ]),

    page() {
      return this.$store.getters.activePage;
    },
    pageNameToDelete() {
      return this.page ? this.page.name : '';
    },
    pageType() {
      return this.page ? this.page.type : '';
    },
    pageTypeToDelete() {
      return this.page.type === 'menu' ? 'dropdown menu' : 'page';
    },
  },
  created() {
    this.notifyErrors = true;
  },
  methods: {
    onConfirmDeletePage() {
      const { pageKey } = this.$route.params;

      const pageParent = this.page.parent;
      const parentKey = (pageParent) ? pageParent.key : null;

      this.commitRequest({
        validate: () => this.app.validatePageCount(),
        request: () => window.Knack.Api.deletePage(pageKey),
        onSuccess: async () => {
          await this.$store.dispatch('removePage', pageKey);

          // if this was a child page, show the parent
          if (parentKey) {
            return this.$router.push(`/pages/${parentKey}`);
          }

          // default to the general page
          this.$router.push('/pages');
        },
      });
    },
  },
};
</script>
