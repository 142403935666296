import { mapMutations } from 'vuex';
import hasIn from 'lodash/hasIn';
import SchemaUtils from '@/store/utils/SchemaUtils';
import { eventBus } from '@/store/bus';

/*
  Requirements for consumers:
  * this.routePrefix: the hash prefix to route different actions to
  * this.columns: the array of columns to act on
*/
export default {
  data() {
    return {
      showConfirmDeleteColumn: false,
      showDeleteLinkedPagesWarning: false,
    };
  },
  mounted() {
    this.addColumnEventListener();
  },
  updated() {
    this.addColumnEventListener();
  },
  beforeUnmount() {
    eventBus.$off(`addViewItem.${this.view.key}.table`);
  },
  methods: {
    ...mapMutations([
      'addLinkedPageStart',
    ]),
    addColumnEventListener() {
      eventBus.$off(`addViewItem.${this.view.key}.table`);
      eventBus.$on(`addViewItem.${this.view.key}.table`, ({ item, event }) => {
        // drag event recorded
        if (event) {
          return this.onDropColumn(event, item);
        }

        this.onClickAddColumn(item);
      });
    },
    deleteColumn(itemIndex) {
      this.columns.splice(itemIndex, 1);
    },
    onDeleteColumn(itemIndex) {
      // if this column is of type `link` and links to an existing page then confirm before deletion
      if (hasIn(this, 'columns') && this.columns[itemIndex].type === 'link' && !hasIn(this, 'newItem.scene')) {
        // If the link has a remote property, means it's a linked page and we can delete it
        // edit and view record details don't have this property
        if (!this.columns[itemIndex].remote) {
          this.showDeleteLinkedPagesWarning = true;
        }

        this.showConfirmDeleteColumn = true;
        this.confirmedIndex = itemIndex;

        return;
      }

      this.deleteColumn(itemIndex);

      this.routeToItems();
    },
    onConfirmDeleteColumn() {
      this.deleteColumn(this.confirmedIndex);

      this.showConfirmDeleteColumn = false;
      this.showDeleteLinkedPagesWarning = false;
      this.confirmedIndex = null;

      this.routeToItems();
    },
    onActivateColumn(itemIndex) {
      this.$router.push(`${this.routePrefix}/columns/${itemIndex}`);
    },
    routeToItems() {
      this.$router.push(`${this.routePrefix}/columns`);
    },
    onClickAddColumn(item) {
      this.addColumnItem(item, this.columns.length);
    },
    onDropColumn(event, newItem) {
      log('onDropColumn', event, event.dataTransfer.getData('key'));

      const oldItemIndex = Number(event.dataTransfer.getData('item'));

      newItem = newItem || event.dataTransfer.getData('new');

      const $parent = event.target.parentElement;

      let itemIndex = Number($parent.getAttribute('data-item'));

      if (event.target.classList.contains('right')) {
        itemIndex++;
      }

      let oldItems;
      let item;

      if (!newItem) {
        item = this.columns.splice(oldItemIndex, 1)[0];

        // extract items from old column
        oldItems = this.columns.splice(0);

        if (oldItemIndex < itemIndex) {
          itemIndex--;
        }

        // insert active item
        if (oldItems) {
          oldItems.splice(itemIndex, 0, item);
          this.columns = oldItems;
        }

        return;
      }

      // item maybe stringified from add menu
      newItem = (typeof newItem === 'string') ? JSON.parse(newItem) : newItem;

      // if new item is type link but does not have a scene, this column is linking to an existing page and we have to now select it
      if (newItem.type === 'link' && !newItem.scene) {
        this.newItem = newItem;

        this.itemIndex = itemIndex;

        return this.addLinkedPageStart(event);
      }

      // normal new item
      this.addColumnItem(newItem, itemIndex);
    },
    addColumnItem(item, itemIndex) {
      item = { ...SchemaUtils.columnDefaults(), ...item };

      const newItems = this.columns.splice(0);

      newItems.splice(itemIndex, 0, item);

      this.columns = newItems;

      // property used to track if item is new and does not contain a linked scene for faster, unconfirmed deletion
      this.newItem = this.columns[itemIndex];

      eventBus.$emit(`addViewItemEnd.${this.view.key}.table`, {
        item, itemIndex,
      });
    },
  },
};
