import TOC from '@/components/views/search/TOC';
import Source from '@/components/views/search/Source';
import Inputs from '@/components/views/search/Inputs';
import InputProperties from '@/components/views/search/InputProperties';
import ColumnProperties from '@/components/views/search/ColumnProperties';
import DetailProperties from '@/components/views/search/DetailProperties';
import Results from '@/components/views/search/Results';
import Settings from '@/components/views/search/Settings';
import Design from '@/components/views/search/Design';

export default [
  {
    path: ':pageKey/views/:viewKey/search',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/search/source',
    components: {
      toolbox: Source,
    },
  },
  {
    path: ':pageKey/views/:viewKey/search/inputs',
    name: 'searchInputAdd',
    components: {
      toolbox: Inputs,
    },
  },
  {
    path: ':pageKey/views/:viewKey/search/rows/:rowIndex/columns/:columnIndex/inputs/:inputIndex',
    name: 'searchInputProperties',
    components: {
      toolbox: InputProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/search/results/table',
    alias: ':pageKey/views/:viewKey/search/results/table/columns/',
    components: {
      toolbox: Results,
    },
  },
  {
    path: ':pageKey/views/:viewKey/search/results/table/columns/:columnIndex',
    alias: ':pageKey/views/:viewKey/search/results/table/columns/:columnIndex/properties',
    components: {
      toolbox: ColumnProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/search/results/list/',
    components: {
      toolbox: Results,
    },
  },
  {
    path: ':pageKey/views/:viewKey/search/results/list/layouts/:layoutIndex/groups/:groupIndex/columns/:columnIndex/items/:itemIndex',
    components: {
      toolbox: DetailProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/search/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/search/design',
    components: {
      toolbox: Design,
    },
  },
];
