<template>
  <div
    id="knack-login"
    class="fullscreen-bg"
  >
    <GlobalLoading :is-loading="isLoadingRequest" />
    <div
      id="login-wrapper"
      class="fullscreen-wrapper"
    >
      <div id="kn-logo">
        <a href="https://knack.com">
          <Icon
            class="icon-logo"
            type="knack"
          />
        </a>
      </div>
      <div
        id="login-form"
        class="kn-login kn-view register-form"
      >
        <div class="view-header" />
        <div class="kn-login-form">
          <FormWrapper
            :errors="errors"
          >
            <form
              class="login_form"
              @submit.prevent="submitForm"
            >
              <div class="kn-input">
                <label for="email">
                  Email Address
                </label>
                <input
                  id="email"
                  v-model="email"
                  v-focus
                  name="email"
                  type="email"
                  autocomplete="off"
                >
              </div>
              <div
                class="kn-input"
                :class="passwordInputClass"
              >
                <label for="password">
                  Password
                </label>
                <input
                  id="password"
                  v-model="password"
                  name="password"
                  type="password"
                  autocomplete="off"
                >
              </div>
              <div class="kn-input margin-bottom-xs">
                <div v-if="using2fa">
                  <label for="totp-token">
                    Two-Factor Code
                  </label>
                  <input
                    id="totp-token"
                    v-model="totpToken"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>
              <div class="kn-input">
                <div class="kn-password__options-wrapper">
                  <label class="remember">
                    <input
                      type="checkbox"
                      name="remember"
                    > Remember me
                  </label>
                  <RouterLink
                    id="forgot-pass"
                    to="/forgot-password"
                  >
                    Forgot your Password?
                  </RouterLink>
                </div>
              </div>
              <input
                type="submit"
                value="Sign In"
              >
            </form>
          </FormWrapper>
        </div>
      </div>
      <p
        id="login-register"
        class="footer"
      >
        <em>
          Need a Knack account?
          <a href="/#welcome">
            Register here
          </a>
        </em>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RequestUtils from '@/components/util/RequestUtils';
import FormWrapper from '@/components/ui/FormWrapper';
import Icon from '@/components/ui/Icon';
import GlobalLoading from '@/components/ui/GlobalLoading';

export default {
  components: {
    Icon,
    FormWrapper,
    GlobalLoading,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      email: '',
      password: '',
      totpToken: '',
      using2fa: false,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'isLoadingRequest',
      'reauthenticationRequired',
    ]),
    passwordInputClass() {
      return {
        'margin-bottom-xs': !this.using2fa,
      };
    },
  },
  methods: {
    submitForm() {
      log('login.vue submitForm()');

      const submitLoginArguments = [
        this.email,
        this.password,
      ];

      if (this.using2fa === true) {
        submitLoginArguments.push(this.totpToken);
      }

      this.commitRequest({
        request: () => this.user.submitLogin(...submitLoginArguments),
        onSuccess: ({ using_2fa: using2fa }) => {
          if (using2fa === true) {
            this.using2fa = true;

            return;
          }

          log(`login.vue onSuccess() reauthenticationRequired: ${this.reauthenticationRequired}`);

          if (this.reauthenticationRequired === true) {
            log('>>>***>>>***>>>***>>>***>>> reauthenticationRequired');

            this.$store.commit('clearReauthenticationRequired');

            document.querySelector('#kn-login-overlay-background').style.display = 'none';

            return this.externallyScopedRequestUtilsGenerator.next();
          }

          this.$router.push(this.$route.query && this.$route.query.redirect ? this.$route.query.redirect : '/');
        },
      });
    },
  },
};
</script>

<style lang="scss">
.fullscreen-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #eee;
    overflow: hidden;
}

#login-wrapper {
  max-width: 440px;
}

#login-form input[type=submit] {
  width: 100%;
  background-color: #f4820a !important;
  border-radius: .35em;
  line-height: 1em;
  border-color: inherit !important;
  padding: .65em .8em;
  height: auto;
  color: white !important;
  text-shadow: none !important;
}

.fullscreen-wrapper {
    max-width: 560px;
    margin: auto;
    padding: 30px;
}

#kn-logo {
  margin: auto;
  width: 80px;
  margin-bottom: 10px;
}

#kn-logo .icon-logo {
  font-size: 80px;
  color: #821b79;
  width: 72px;
  height: 72px;
}

.icon-logo:before {
    content: "j";
}

[class*=" icon-"]:before, [class^=icon-]:before, [data-icon]:before {
  font-family: knack-builder!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-variant: normal!important;
  text-transform: none!important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.register-form {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dadada;
    padding: 30px;
}

.register-form .kn-login-form {
    width: 100%;
}

.kn-password__options-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.register-form input[type=submit], #knack-login input[type=submit], .register-form button {
    color: #fff;
}

.register-form label.remember, #forgot-pass {
    display: inherit;
    font-weight: 400;
    font-size: .85rem;
    line-height: inherit;
    margin-bottom: inherit;
    margin:0;
}

.fullscreen-wrapper p.footer {
    text-align: center;
    font-size: .95em;
    margin-top: 30px;
    color: #999;
}

@import '../../styles/scss/loader.scss'
</style>
