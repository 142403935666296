import TOC from '@/components/views/report/TOC';
import Build from '@/components/views/report/Build';
import ChartAdd from '@/components/views/report/ChartAdd';
import Settings from '@/components/views/report/Settings';
import ReportProperties from '@/components/views/report/ReportProperties';

export default [
  {
    path: ':pageKey/views/:viewKey/report',
    components: {
      toolbox: Build,
    },
  },
  {
    path: ':pageKey/views/:viewKey/report/build',
    components: {
      toolbox: Build,
    },
  },
  {
    path: ':pageKey/views/:viewKey/report/chart/add',
    components: {
      toolbox: ChartAdd,
    },
  },
  {
    path: ':pageKey/views/:viewKey/report/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/report/rows/:rowIndex/reports/:reportIndex',
    name: 'reportProperties',
    components: {
      toolbox: ReportProperties,
    },
  },
];
