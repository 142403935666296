<template>
  <div class="appError">
    <EmptyState
      class="appError_message"
      title="App error"
      image="not-found"
      action="Go to App Dashboard"
      description="There is a problem with this app. Please reach out to support for assistance."
      @action="goToDashboard"
    />
  </div>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState';

export default {
  name: 'ApplicationError',
  components: {
    EmptyState,
  },
  methods: {
    goToDashboard() {
      const sourceDomain = process.env.VUE_APP_DASHBOARD_URL || String(window.location).split('/').splice(0, 3).join('/');
      const accountName = window.location.pathname.split('/')[1];

      // This is moving us out of the app, so we can't use the vue router.
      window.location.assign(`${sourceDomain}/${accountName}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.appError {
  height: 100vh;
}

.appError_message {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
