<template>
  <div>
    <label class="text-emphasis text-sm font-medium mb-2 leading-4"><input
      v-model="fieldLocal.required"
      type="checkbox"
    > Required</label>
  </div>
</template>

<script>
export default {
  name: 'RequiredSetting',
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
