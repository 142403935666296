<template>
  <a
    v-tippy
    :content="tooltip"
    data-cy="view-child-link"
    class="view-child-link h-5 w-5 bg-transparent border border-solid border-brand rounded"
    :style="styles"
    @click.prevent="onClick"
  >
    <Icon
      class="fill-[url(#svg-brand-gradient)]"
      type="arrow-forward"
    />
  </a>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    top: {
      type: String,
      default: '0px',
    },
    right: {
      type: String,
      default: '0px',
    },
    url: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    styles() {
      return {
        top: `${this.top}`,
        right: `${this.right}`,
        cursor: this.url ? 'pointer!important' : 'default',
      };
    },
  },
  methods: {
    onClick(event) {
      log('click, this:', this);

      if (this.url) {
        this.$router.push(this.url);
      }

      return false;
    },
  },
};
</script>

<style lang="scss">
.view-child-link {
  position: absolute;
  color: #fff;
  padding: 1px;
  border-radius: .32em;
  background-color: #fe762c;
  z-index: 33!important;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, .75);
  pointer-events: initial;

  svg {
    width: 17px;
    height: 17px;
    color: white;
  }
}
</style>
