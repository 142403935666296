<template>
  <notifications
    class="kn-toast-group--top-right m-0 right-[20px] top-[80px]"
    group="top-right"
    position="top right"
    ignore-duplicates
  >
    <template #body="{item, close}">
      <GlobalNotificationContent
        :item="item"
        :close="close"
      />
    </template>
  </notifications>
  <notifications
    class="kn-toast-group--bottom-right m-0 right-[20px] bottom-[20px]"
    group="bottom-right"
    position="bottom right"
    ignore-duplicates
  >
    <template #body="{item, close}">
      <GlobalNotificationContent
        :item="item"
        :close="close"
      />
    </template>
  </notifications>
</template>

<script>
import { mapGetters } from 'vuex';
import GlobalNotificationContent from '@/components/ui/notifications/GlobalNotificationContent.vue';

export default {
  components: {
    GlobalNotificationContent,
  },
  data() {
    return {
      appHasExistingIntegrationTable: false,
    };
  },
  computed: {
    ...mapGetters('notifications', [
      'globalSocketNotification',
    ]),
  },
  watch: {
    globalSocketNotification: {
      handler(newValue) {
        const publicNameMapping = {
          'integrations:quickbooks': 'QuickBooks',
        };
        const integrationDataAction = this.appHasExistingIntegrationTable ? 'refreshed' : 'imported';
        this.appHasExistingIntegrationTable = this.getAppHasExistingIntegrationTable();
        const notificationMappings = {
          complete: {
            type: 'success',
            title: `${publicNameMapping[newValue.socketMessage.topic]} data ${integrationDataAction}`,
            text: `Your data has been successfully ${integrationDataAction}.`,
            data: {
              titleIcon: 'check',
            },
          },
          failed: {
            type: 'error',
            title: 'Error',
            text: 'Unable to import data.',
            data: {
              titleIcon: 'error',
            },
          },
          canceled: {
            type: 'error',
            title: 'Import canceled',
            text: 'Your import has been canceled.',
            data: {
              titleIcon: 'warning',
            },
          },
        };

        const notificationArgs = notificationMappings[newValue.category];
        if (!notificationArgs) return;
        this.$notify({
          group: 'top-right',
          duration: 8000,
          'animation-type': 'velocity',
          ...notificationArgs,
        });
        if (newValue.socketMessage.topic === 'integrations:quickbooks' && notificationArgs.type === 'success') {
          this.$store.dispatch('integrations/updateIsIntegrationImportInProgress', { isIntegrationImportInProgress: false });
        }
      },
    },
  },
  created() {
    this.appHasExistingIntegrationTable = this.getAppHasExistingIntegrationTable();
  },
  methods: {
    getAppHasExistingIntegrationTable() {
      return this.$store.getters.objects.some((object) => object.get('integration') === 'QuickBooks');
    },
  },
};
</script>

<style lang="scss">
.vue-notification-group.kn-toast-group {
  &--top-right {
    margin: 52px 6px 6px 0;
  }
  &--bottom-right {
    margin: 6px 6px 52px 0;
  }
}
</style>
