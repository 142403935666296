<template>
  <view-toolbox
    back-title="Edit Grid"
    title="Design"
  >
    <form>
      <div>
        <label for="title">
          Title
        </label>
        <input
          id="title-input"
          v-model="viewRaw.title"
          type="text"
          name="title"
        >
      </div>
      <div>
        <label for="title">
          Description
        </label>
        <textarea
          v-model="viewRaw.description"
          name="description"
        />
      </div>

      <!--
      <div class="customization-options">
        <div class="customization-options">
          <label>Cell Options</label>
          <DesignPropertyPicker
            design-property="borders"
            label-text="Cell Border"
            input-type="checkbox"
            :is-inline="true" />
          <DesignPropertyPicker
            design-property="stripes"
            label-text="Alternate Row Colors"
            input-type="checkbox"
            :is-inline="true" />
        </div>
      </div>
      -->
    </form>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DesignUtils from '@/components/views/DesignUtils';
import DesignPropertyPicker from '@/components/settings/design/DesignPropertyPicker';

export default {
  name: 'TableSettings',
  components: {
    ViewToolbox,
    DesignPropertyPicker,
  },
  mixins: [
    ViewUtils,
    DesignUtils,
  ],
};
</script>
