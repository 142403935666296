import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import store from '@/store';

/**
 * Gets the rawPage children pages order using the layout order of the views.
 *
 * @param {RawPage} rawPage
 */
export function getChildPageOrder(rawPage) {
  if (isEmpty(rawPage.views) || isEmpty(rawPage.children)) {
    return;
  }

  const { views } = rawPage;
  let orderedChildPageKeys = [];

  // iterate through views by page layout order
  rawPage.groups.forEach((group) => {
    group.columns.forEach((column) => {
      column.keys.forEach((viewKey) => {
        // get this view
        const view = views.find((searchView) => searchView.key === viewKey);

        if (!view) {
          return;
        }

        // add any pages to pageKeys that this view links to
        orderedChildPageKeys = [
          ...orderedChildPageKeys,
          ...getViewChildPageKeys(view),
        ];
      });
    });
  });

  return orderedChildPageKeys;
}

/**
 * Gets the keys for all child pages of the view.
 *
 * @param {object} rawView
 * @returns {string[]}
 */
export function getViewChildPageKeys(rawView) {
  const { type } = rawView;

  // TODO:: move these into inherited view classes when that split happens
  switch (type) {
    case 'calendar':
    case 'map':

      // event details
      return getChildPageKeysFromDetails(
        get(rawView, 'details.columns'),
      );

    case 'checkout':
    case 'charge':
    case 'customer':

      // both details and submit rules
      return [
        ...getChildPageKeysFromDetails(
          get(rawView, 'checkout_page.columns'),
        ),
        ...getChildPageKeysFromSubmitRules(
          get(rawView, 'rules.submits'),
        ),
      ];

    case 'details':
    case 'list':

      return getChildPageKeysFromDetails(rawView.columns);

    case 'form':
    case 'registration':

      return getChildPageKeysFromSubmitRules(
        get(rawView, 'rules.submits'),
      );

    case 'table':

      return getChildPageKeysFromColumns(rawView.columns);

    case 'menu':

      return getChildPageKeysFromMenu(rawView.links);

    case 'report':

      return getChildPageKeysFromReports(rawView.rows);

    case 'search':

      if (rawView.results_type === 'table') {
        return getChildPageKeysFromColumns(
          get(rawView, 'results.columns'),
        );
      }

      return getChildPageKeysFromDetails(
        get(rawView, 'results.columns'),
      );

    default:

      return [];
  }
}

function getChildPageKeysFromDetails(columns = []) {
  const pageKeys = [];

  for (const column of columns) {
    for (const group of column.groups) {
      for (const column2 of group.columns) {
        for (const item of column2) {
          if (!item) {
            continue;
          }

          if (item.type === 'scene_link' || item.type === 'scene') {
            pageKeys.push(getLinkedPageKeyBySlug(item.scene));
          }
        }
      }
    }
  }

  return pageKeys;
}

function getChildPageKeysFromColumns(columns = []) {
  const pageKeys = [];

  for (const item of columns) {
    if (item.type === 'link' || item.type === 'scene') {
      pageKeys.push(getLinkedPageKeyBySlug(item.scene));
    }
  }

  return pageKeys;
}

function getChildPageKeysFromMenu(links = []) {
  const pageKeys = [];

  for (const item of links) {
    if (item.type === 'scene') {
      pageKeys.push(getLinkedPageKeyBySlug(item.scene));
    }
  }

  return pageKeys;
}

function getChildPageKeysFromSubmitRules(rules = []) {
  const pageKeys = [];

  for (const item of rules) {
    if (item.action === 'child_page') {
      pageKeys.push(getLinkedPageKeyBySlug(item.scene));
    }
  }

  return pageKeys;
}

function getChildPageKeysFromReports(rows = []) {
  const pageKeys = [];

  for (const row of rows) {
    for (const report of row.reports) {
      if (report.options?.child_records && report.child_page) {
        pageKeys.push(getLinkedPageKeyBySlug(report.child_page.scene));
      }
    }
  }

  return pageKeys;
}

function getLinkedPageKeyBySlug(pageSlug) {
  const page = store.getters.getPageBySlug(pageSlug);

  if (!page) {
    return null;
  }

  return page.key;
}
