<template>
  <div class="kn-search-results-table-wrapper">
    <ConfirmModal
      v-if="showConfirmDeleteColumn"
      title="Delete this column?"
      confirm-type="alert"
      @close="showConfirmDeleteColumn = false"
      @confirm="onConfirmDeleteColumn"
    >
      <p v-if="showDeleteLinkedPagesWarning">You'll also be deleting any Pages and Views this column links to.</p>
      <p class="mb-0">
        <strong>Once the view changes are saved, this can't be undone!</strong>
      </p>
    </ConfirmModal>

    <ViewUtilityDropdown
      v-if="columns.length"
      class="static block"
      style="position: absolute; top: -7px; right: 1px;"
    />

    <TableWrapper>
      <template #navigation>
        <RecordNavigation
          v-if="columns.length"
          :allow-limit="allowLimit"
          :current-records-page="queryVars.currentPage"
          :object-key="objectKey"
          :records="records"
          :records-per-page="queryVars.recordsPerPage"
          :show-interceptor="true"
          :show-keyword-search="false"
          :show-export-button="showExportButton"
          :show-filters="false"
          :show-filter-menu="showFilterMenu"
          :current-records-count="currentRecordsCount"
          :total-records="totalRecords"
          :total-records-pages="totalPages"
        />
      </template>
      <template #table>
        <VTable
          class="mt-2.5"
          :view="view"
          :draggable="draggable"
          :columns="columns"
          :records="records"
          :total-records="totalRecords"
          :route-prefix="routePrefix"
          :show-inline-edit="false"
          @activate-column="onActivateColumn"
          @delete-column="onDeleteColumn"
          @drop-view-item="onDropColumn"
        />
      </template>
      <template #lowerNavigation>
        <RecordNavigation
          v-if="view.get(`display_pagination_below`) && columns.length"
          :allow-limit="allowLimit"
          :current-records-page="queryVars.currentPage"
          :filter-menu-links="filterMenuLinks"
          :records-filters="filters"
          :object-key="objectKey"
          :records="records"
          :records-per-page="queryVars.recordsPerPage"
          :show-interceptor="true"
          :show-keyword-search="false"
          :show-export-button="false"
          :show-filters="false"
          :show-filter-menu="false"
          :current-records-count="currentRecordsCount"
          :show-pagination-range="false"
          :total-records="totalRecords"
          :total-records-pages="totalPages"
        />
      </template>
    </TableWrapper>
  </div>
</template>

<script>
import ConfirmModal from '@/components/ui/ConfirmModal';
import RecordNavigation from '@/components/renderer/shared/RecordNavigation';
import RequestUtils from '@/components/util/RequestUtils';
import TableWrapper from '@/components/renderer/table/TableWrapper';
import ViewUtilityDropdown from '@/components/renderer/shared/ViewUtilityDropdown';
import VTable from '@/components/renderer/table/Table';

import ColumnActionsMixin from '@/components/renderer/mixins/ColumnActionsMixin';
import QueryMixin from '@/components/renderer/mixins/QueryMixin';

export default {
  components: {
    ConfirmModal,
    RecordNavigation,
    TableWrapper,
    ViewUtilityDropdown,
    VTable,
  },
  mixins: [
    QueryMixin,
    ColumnActionsMixin,
    RequestUtils,
  ],
  props: {
    view: {
      type: Object,
      default: () => ({}),
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    isViewOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    columns: {

      get() {
        return this.view.get('results.columns');
      },

      set(columns) {
        this.view.get('results').columns = columns;
      },
    },

    objectKey() {
      return this.view.source.object;
    },

    routePrefix() {
      return `/pages/${this.$route.params.pageKey}/views/${this.view.key}/search/results/table`;
    },
  },
  watch: {
    'view.data': function (newVal) {
      this.setRecords(newVal);
    },
  },
  created() {
    log('created', this.view.data);
    this.setRecords(this.view.data);

    // transform view object into data for props
    // of RecordTools and TableComponent
    this.initQueryVars();
  },
  methods: {
    queryRecordsRequest(queryVars, onSuccess, onError) {
      this.commitRequest({
        request: () => this.view.getData(queryVars),
        onSuccess: (response) => this.view.onGetDataSuccess(response, onSuccess),
        onError,
      });
    },
  },
};
</script>

<style lang="scss">
.kn-search-results-table-wrapper {
  position: relative;

  .kn-records-nav {
    font-size: 1em;
  }
}
</style>
