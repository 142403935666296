<template>
  <div />
</template>

<script>

export default {
  data() {
    return {};
  },
  computed: {},
  created() {
  },
  methods: {},
};
</script>
