<template>
  <Ribbon>
    <div class="ribbon_mainRow border-none h-auto p-6">
      <h2 class="ribbonName p-0 mr-12">
        <Icon
          type="settings-embeds-outline"
          class="ribbonName_icon h-8 w-8 mr-2 text-subtle"
        />
        <span class="ribbonName_title text-emphasis text-xl font-medium">Embed</span>
      </h2>
      <RouterLink
        :to="'/settings/embeds/add'"
        class="iconLink ribbonLink h-auto p-3 rounded-lg border border-solid border-default bg-white
          hover:bg-brand-50 hover:border-brand-600 leading-none text-emphasis text-base font-medium group"
        data-cy="add-new-embed"
      >
        <Icon
          type="plus-thin"
          class="iconLink_icon iconLink_icon-add ribbonLink_icon mr-2 group-hover:text-brand text-default h-4 w-4"
        />
        <RibbonLinkText
          text="Add New Embed Code"
          classes="m-0"
        />
      </RouterLink>
    </div>
  </Ribbon>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Ribbon from '@/components/layout/Ribbon';
import RibbonLinkText from '@/components/layout/RibbonLinkText';

export default {
  components: {
    Ribbon,
    Icon,
    RibbonLinkText,
  },
};
</script>
