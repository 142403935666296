<script setup>
import { useStore } from 'vuex';
import { computed, defineProps } from 'vue';

import { designOptionsCollection } from '@knack/style-engine';
import AppDesignPropertyPicker from '@/components/settings/design/AppDesignPropertyPicker';

const props = defineProps({
  menuFormat: {
    type: String,
    default: 'buttons',
  },
  customDesignObject: {
    type: Object,
    default: undefined,
  },
  onlyColors: {
    type: Boolean,
    default: false,
  },
});

const design = computed(() => {
  const store = useStore();
  const appDesign = store.getters.app.get('design');

  return props.customDesignObject || appDesign.general.menus.links[props.menuFormat];
});

const designPropertyBasePath = computed(() => {
  if (props.customDesignObject) {
    return '';
  }
  return `general.menus.links.${props.menuFormat}.`;
});

const {
  buttonFormatOptions,
  buttonSizeOptions,
  borderThicknessOptions,
  buttonCustomColorOptions,
  tabFormatOptions,
} = designOptionsCollection;

const buttonCustomFormatOptions = buttonFormatOptions.filter((option) => option.value !== 'text');

const buttonCustomBorderColorOptions = buttonCustomColorOptions.map((option) => {
  if (option.value === false) {
    return {
      ...option,
      label: 'Same as text color',
    };
  }
  return option;
});
</script>

<template>
  <div class="customization-options">
    <AppDesignPropertyPicker
      v-if="!onlyColors"
      :custom-design-object="customDesignObject"
      :design-property="`${designPropertyBasePath}format`"
      :options="menuFormat === 'tabs' ? tabFormatOptions : buttonCustomFormatOptions"
      label-text="Style"
    />

    <template v-if="design.format !== 'legacy'">
      <!-- Size -->
      <AppDesignPropertyPicker
        v-if="!onlyColors"
        :custom-design-object="customDesignObject"
        :design-property="`${designPropertyBasePath}size`"
        :options="buttonSizeOptions"
        label-text="Size"
      />

      <!-- Fill format -->
      <template v-if="design.format === 'fill'">
        <!-- Text Color -->
        <AppDesignPropertyPicker
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}colors.fill.text.custom`"
          :options="buttonCustomColorOptions"
          input-type="radio"
          label-text="Text color"
        />
        <div
          v-if="design.colors.fill.text.custom"
          class="selectionGroup -mt-2 mb-4"
          style="margin-top: -18px;"
        >
          <AppDesignPropertyPicker
            :custom-design-object="customDesignObject"
            :design-property="`${designPropertyBasePath}colors.fill.text.color`"
            input-type="color"
            label-text=""
          />
        </div>

        <!-- Background Color -->
        <AppDesignPropertyPicker
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}colors.fill.background.custom`"
          :options="buttonCustomColorOptions"
          input-type="radio"
          label-text="Fill color"
        />
        <div
          v-if="design.colors.fill.background.custom"
          class="selectionGroup -mt-2 mb-4"
          style="margin-top: -18px;"
        >
          <AppDesignPropertyPicker
            :custom-design-object="customDesignObject"
            :design-property="`${designPropertyBasePath}colors.fill.background.color`"
            input-type="color"
            label-text=""
          />
        </div>
      </template>

      <!-- Outline format -->
      <template v-if="design.format === 'outline'">
        <!-- Text Color -->
        <AppDesignPropertyPicker
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}colors.outline.text.custom`"
          :options="buttonCustomColorOptions"
          input-type="radio"
          label-text="Text color"
        />
        <div
          v-if="design.colors.outline.text.custom"
          class="selectionGroup -mt-2 mb-4"
          style="margin-top: -18px;"
        >
          <AppDesignPropertyPicker
            :custom-design-object="customDesignObject"
            :design-property="`${designPropertyBasePath}colors.outline.text.color`"
            input-type="color"
            label-text=""
          />
        </div>

        <!-- Border Color -->
        <AppDesignPropertyPicker
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}colors.outline.border.custom`"
          :options="buttonCustomBorderColorOptions"
          input-type="radio"
          label-text="Outline color"
        />
        <div
          v-if="design.colors.outline.border.custom"
          class="selectionGroup -mt-2 mb-4"
          style="margin-top: -18px;"
        >
          <AppDesignPropertyPicker
            :custom-design-object="customDesignObject"
            :design-property="`${designPropertyBasePath}colors.outline.border.color`"
            input-type="color"
            label-text=""
          />
        </div>
      </template>

      <!-- Underline format -->
      <template v-if="design.format === 'underline'">
        <!-- Text/Active Color -->
        <AppDesignPropertyPicker
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}colors.underline.text.custom`"
          :options="buttonCustomColorOptions"
          input-type="radio"
          label-text="Active color"
        />
        <div
          v-if="design.colors.underline.text.custom"
          class="selectionGroup -mt-2 mb-4"
          style="margin-top: -18px;"
        >
          <AppDesignPropertyPicker
            :custom-design-object="customDesignObject"
            :design-property="`${designPropertyBasePath}colors.underline.text.color`"
            input-type="color"
            label-text=""
          />
        </div>
      </template>

      <template v-if="!onlyColors">
        <!-- Text options -->
        <AppDesignPropertyPicker
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}uppercase`"
          input-type="checkbox"
          description-text="Uppercase"
          label-text="Text Options"
        />

        <!-- Outline/Fill options -->
        <AppDesignPropertyPicker
          v-if="design.format === 'outline' || design.format === 'underline'"
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}borderThickness`"
          :options="borderThicknessOptions"
          :label-text="`${design.format === 'outline' ? 'Outline' : 'Underline'} Options`"
        />
        <AppDesignPropertyPicker
          v-if="design.format !== 'underline'"
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}rounded`"
          input-type="checkbox"
          description-text="Round Corners"
          :label-text="design.format === 'fill' ? 'Fill Options' : ''"
          style="margin-bottom: 0;"
        />
        <AppDesignPropertyPicker
          v-if="design.format !== 'underline'"
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}raised`"
          input-type="checkbox"
          description-text="Raised"
          label-text=""
        />
      </template>
    </template>
  </div>
</template>
