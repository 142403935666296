<template>
  <div class="input-labels tw-input-labels">
    <label
      v-for="startPage in availablePages"
      :key="startPage.key"
      :class="{disabled: Boolean(startPage.isPageInOtherMenu)}"
      style="display: block;"
    >
      <input
        v-model="page.menu_pages"
        type="checkbox"
        :disabled="Boolean(startPage.isPageInOtherMenu)"
        :value="startPage.key"
      >
      {{ startPage.name }} <span v-if="startPage.isPageInOtherMenu">
        > currently under {{ startPage.isPageInOtherMenu.name }}
      </span>
    </label>
  </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';
import PageNavUtils from '@/components/pages/PageNavUtils';

export default {
  mixins: [
    PageNavUtils,
  ],
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('page', [
      'menuListPages',
      'topLevelPages',
    ]),
    availablePages() {
      const currentPagKey = get(this, 'page.key');

      return this.menuListPages.map((startRawPage) => {
        const pageKey = startRawPage.key;
        const isPageInOtherMenu = this.menuForPage(pageKey, currentPagKey);

        return {
          key: pageKey,
          name: startRawPage.name,
          isPageInOtherMenu,
        };
      });
    },
    menuPages() {
      return this.topLevelPages.filter((page) => page.isMenuPage());
    },
  },
  methods: {
    menuForPage(pageKey, currentPagKey) {
      return this.menuPages.find((menuPage) => {
        if (menuPage.key === currentPagKey) {
          return false;
        }

        if (isEmpty(menuPage.raw.menu_pages)) {
          return false;
        }

        return menuPage.raw.menu_pages.includes(pageKey);
      });
    },
  },
};
</script>
