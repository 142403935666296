import TOC from '@/components/views/divider/TOC';
import Settings from '@/components/views/divider/Settings';

export default [
  {
    path: ':pageKey/views/:viewKey/divider',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/divider/settings',
    components: {
      toolbox: Settings,
    },
  },
];
