import FormHelpSearch from '@/store/forms/help/FormHelpSearch';
import getApiBaseModule from '@/store/modules/api/api-base';

export const HELP_SEARCH_RESET_FORM = '🔍 Help Search - Resetting Form';
export const HELP_SEARCH_UPDATE_FORM = '🔍 Help Search - Updating Form';
export const HELP_SEARCH_VALIDATION = '🔍 Help Search - After Validation';
export const HELP_SEARCH_READY = '🔍🌱 Help Search - Ready';
export const HELP_SEARCH_PENDING = '🔍⏳ Help Search - Pending';
export const HELP_SEARCH_SUCCESS = '🔍✅ Help Search - Success';
export const HELP_SEARCH_FAILURE = '🔍❌ Help Search - Failure';

const events = {
  RESET_FORM: HELP_SEARCH_RESET_FORM,
  UPDATE_FORM: HELP_SEARCH_UPDATE_FORM,
  VALIDATION: HELP_SEARCH_VALIDATION,
  READY: HELP_SEARCH_READY,
  PENDING: HELP_SEARCH_PENDING,
  SUCCESS: HELP_SEARCH_SUCCESS,
  FAILURE: HELP_SEARCH_FAILURE,
};

/**
 * Gets the form.
 *
 * @returns {FormHelpSearch}
 */
const getForm = () => new FormHelpSearch();

const apiBase = getApiBaseModule(events, getForm);

export default {
  // Full namespace: api/help/search
  search: {
    namespaced: true,
    state: apiBase.state,
    getters: apiBase.getters,
    mutations: apiBase.mutations,
    actions: apiBase.actions,
  },
};
