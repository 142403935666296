<template>
  <ObjectsNav nav-context="fields" />
</template>

<script>
import ObjectsNav from '@/components/objects/ObjectsNav';

export default {
  components: {
    ObjectsNav,
  },
};
</script>
