<template>
  <ul
    ref="pageListSortable"
    class="page-list-sortable"
  >
    <slot />
  </ul>
</template>

<script>
import { pageDragAddContainer } from '@/components/pages/dragPages';

export default {
  name: 'PageListSortable',
  props: {
    canSort: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.canSort) {
      // Register the sortable list with the page dragging but only if children sorting is enabled.
      pageDragAddContainer(this.$refs.pageListSortable);
    }
  },
};
</script>
