/**
 * The key used for new pages.
 * @type {string}
 */
export const NEW_PAGE_KEY = 'newPage';

/**
 * The key used for new views.
 * @type {string}
 */
export const NEW_VIEW_KEY = 'new';
