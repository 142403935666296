<template>
  <Toolbox
    back-link="/settings/app"
    back-title="settings"
    :class="{'has-active-updates': false}"
    :theme="`active`"
    title="Live App Design"
  >
    <ToolboxLinks :items="items" />
  </Toolbox>
</template>

<script>
import { mapGetters } from 'vuex';
import Toolbox from '@/components/layout/Toolbox';
import ToolboxLinks from '@/components/layout/ToolboxLinks';

export default {
  name: 'DesignSettingsTOC',
  components: {
    Toolbox,
    ToolboxLinks,
  },
  data() {
    return {
      items: [
        {
          title: 'Look & Feel',
          copy: 'Customize fonts, colors, and links',
          link: 'theme',
          featureId: 'look_and_feel_button',
        },
        {
          title: 'Header & Page Menu',
          copy: 'Customize the logo and page menu',
          link: 'header',
        },
        {
          title: 'Menus',
          copy: 'Design defaults for menus',
          link: 'menus',
          requiredTheme: 2,
        },
        {
          title: 'Grids',
          copy: 'Design defaults for grids',
          link: 'tables',
          requiredTheme: 2,
        },
        {
          title: 'View Links',
          copy: 'Customize how links appear in views',
          link: 'views/links',
          requiredTheme: 2,
        },

        /*

          NOTE: These sections have not been implemented yet
          {
            title: `Buttons`,
            copy: `Design defaults for buttons`,
            link: `buttons`
          },

          {
            title: `Forms`,
            copy: `Design defaults for forms`,
            link: `forms`
          },
          {
            title: `Details & Lists`,
            copy: `Design defaults for detail and list views`,
            link: `details`
          },
          {
            title: `Reset Defaults`,
            copy: `Revert design settings`,
            link: `defaults`
          },
          {
            title: 'Footer',
            copy: 'optional footer content',
            link: 'footer'
          }
          */
      ],
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
  },
};
</script>
