<script>
export default {
  name: 'EmptyStateGeneric',
  props: {
    top: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <section
    class="empty-state"
    :class="{ 'empty-state--top': top }"
  >
    <div class="empty-state__content w-[480px] max-w-full">
      <slot />
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .empty-state {
    display: flex;
    justify-content: center;
    margin-top: 10%;

    &--top {
      margin-top: 4em;
    }

    &__content {
      text-align: center;
      max-width: 450px;
    }

    :slotted(.empty-state__title) {
      font-size: 1.4em;
      font-weight: 600;
      color: $fuchsia-text;
    }

    :slotted(.empty-state__paragraph) {
      line-height: 1.5em;
    }

    :slotted(.empty-state__image) {
      width: 115px;
      height: auto;
    }

    :slotted(.empty-state__image--sm) {
      width: 42px;
    }
  }
</style>
