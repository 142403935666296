<template>
  <view-toolbox
    back-title="Search Menu"
    title="Design"
  >
    <form>
      <div>
        <label for="title">
          Title
        </label>
        <input
          v-model="viewRaw.title"
          type="text"
          name="title"
        >
      </div>
      <div>
        <label for="title">
          Description
        </label>
        <textarea
          v-model="viewRaw.description"
          name="description"
        />
      </div>
    </form>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DesignUtils from '@/components/views/DesignUtils';

export default {
  name: 'SearchDesign',
  components: {
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
    DesignUtils,
  ],
};
</script>
