import Joi from '@hapi/joi';

import api from '@/lib/api-wrapper';
import { getDevUrl } from '@/lib/url-helper';
import FormBase from '@/store/forms/FormBase';

/**
 * The form for reporting a bug.
 */
export default class FormHelpReportBug extends FormBase {
  /**
   * The location (builder or live app) where the bug occurred.
   * @type {string}
   */
  bugLocation = '';

  /**
   * The browser URL where the bug occurred.
   * @type {string}
   */
  bugUrl = '';

  /**
   * The description of the bug.
   * @type {string}
   */
  bugDescription = '';

  /**
   * Validates the form.
   *
   * @returns {{passed: boolean, errors: Error[]}}
   */
  async validate() {
    const validationSchema = {
      bugLocation: Joi.string().allow('').optional().label('The bug location'),
      bugUrl: Joi.string().allow('').optional().label('The URL when you see the bug'),
      bugDescription: Joi.string().allow('').optional().label('The description of the bug'),
    };

    return this._validateForm(validationSchema);
  }

  /**
   * Submits the form to the server.
   *
   * @returns {Promise<Object>}
   */
  async submit() {
    const ticketData = {
      bugDescription: this.bugDescription,
      bugLocation: this.bugLocation,
      bugUrl: this.bugUrl,
      devLink: getDevUrl(),
    };

    return api.helpReportBug(ticketData);
  }
}
