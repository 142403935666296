// Assets object helper
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import hasIn from 'lodash/hasIn';
import get from 'lodash/get';

export const assetInputTypes = [
  'image',
  'file',
];

/**
 * Gets filepond asset config for initialization.
 *
 * @param {String} filename - name of the file
 * @param {String} [type="image"] - type of asset, must be one of: image, file
 * @param {Number} size - size of asset in bytes
 * @param {String} assetId - Knack asset ID assigned by the BE
 * @param {String} assetUrl - direct link to asset in s3, required for filepond image poster
 * @returns {Object} filepond asset config for passed file
 */
function getFilePondAssetConfig(filename, type, size, assetId, assetUrl) {
  const assetConfig = {
    options: {
      type: 'local',
      file: {
        name: filename,
        type: type ?? 'image',
        size,
        // Assign the knack ID for the asset to the file
        // fallback to the filename for logo assets
        id: assetId || filename,
      },
      metadata: {
        url: assetUrl,
      },
    },
  };

  // If we have an image type add the metadata property to trigger the poster filepond plugin
  if (type === 'image') {
    assetConfig.options.metadata.poster = assetUrl;
  }

  return assetConfig;
}

/**
 * Get object the Knack backend expects asset values to be in to pass to Api-wrapper.
 *
 * @param {String|Boolean} fieldKey - the field key the asset should be tied to
 * @param {Object|Undefined} file - the file object to be uploaded
 * @param {String} type - type of asset, must be one of: image, file
 * @returns {Object} - knack db asset object
 */
function getDBAssetObject(fieldKey, file, type) {
  const asset = {};

  if (fieldKey !== false) {
    asset.fieldKey = fieldKey;
  }

  if (!isNil(file)) {
    asset.type = type;
    asset.content = file.file;
    asset.filename = file.filename;
  }

  return asset;
}

/**
 * Gets initial local files array for a filepond input based on what the DB is returning
 * for our asset.
 *
 * States:
 * - no file (creating a record, a record with no value)
 * - existing file in DB with all required data (record response) - mocks the asset
 * - existing file in DB with no data (tasks & rules) - makes a request for info then mocks
 *   the asset
 *
 * @param {Object|String} initialValue - the starting value for the asset input
 * @returns {Array} - array of filepond config objects per asset
 */
export async function getInitialLocalFilesArray(initialValue) {
  if (isEmpty(initialValue)) {
    return [];
  }

  const files = [];

  if (isString(initialValue)) {
    const {
      asset: {
        filename, type, size, url,
      },
    } = await window.Knack.Api.getAssetInfo(initialValue);

    files.push(getFilePondAssetConfig(filename, type, size, initialValue, url));

    return files;
  }

  const {
    filename, type, size, id, url,
  } = initialValue;

  const result = getFilePondAssetConfig(filename, type, size, id, url);

  // If the filepond config doesn't have a url, don't return anything so that filepond
  // doesn't show `NaN GB` for an empty entry.
  if (!get(result, 'options.metadata.url', undefined)) {
    return [];
  }

  files.push(result);

  return files;
}

/**
 * Gets a formatted object ready to be saved to a Knack schema
 * @param {Object} input - the field input
 * @param {Object} [object] - the file object
 * @returns {Object} - knack object with value and asset properties
 */
export function getKnackFormatFromFilepondObject(input, object) {
  const fieldKey = hasIn(input, 'field.key') && input.field.key;
  const assetType = (hasIn(input, 'type') && input.type) || 'image';

  const asset = getDBAssetObject(fieldKey, object, assetType);

  // No object means removing the asset: pass asset of just fieldKey
  if (isNil(object)) {
    return {
      value: null,
      asset,
    };
  }

  // If file has an id this is a pre-existing asset
  // pass just the knack id with no asset to not save over
  if (object.file.id) {
    return object.file.id;
  }

  // Otherwise pass up the asset alongside the value for parsing
  return {
    value: {
      filename: object.filename,
      size: object.file.size,
    },
    asset,
  };
}

/**
 * Extracts assets objects from knack objects defined above to pass as separate param to api
 * @param {Array} inputs - array of inputs being modified
 * @param {Object} values - new values to be saved for the record to extract assets frome
 * @returns {Array} - array of DB asset objects
 */
export function getAssetsFromValues(inputs, values) {
  return inputs.filter((input) => (
    // Filter out non-asset input types & values that are not new assets
    assetInputTypes.includes(input.type) && values[input.field.key].hasOwnProperty('asset')
  )).map((input) => values[input.field.key].asset);
}

/**
 * Asynchronously uploads individual assets.
 *
 * @param {String} fieldKey - the field key the asset should be tied to
 * @param {Array} files - array of filepond assets to upload
 * @param {String} [type="image"] - type of asset, must be one of: image, file
 * @returns {Object} asset id's keyed by fieldKey
 */
export async function uploadFilepondFiles(fieldKey, files, type = 'image') {
  const assets = files.map((file) => getDBAssetObject(fieldKey, file, type));

  return window.Knack.Api.createAssets(assets, {});
}
