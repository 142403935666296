<template>
  <view-toolbox
    back-title="Edit Page"
    title="RichText"
    :errors="errors"
  >
    <div class="kn-rich-text-redactor">
      <Redactor
        v-model="viewRaw.content"
        name="content"
        :input="viewRaw"
        :config="redactorOptions"
      />
    </div>
  </view-toolbox>
</template>

<script>
import Redactor from '@/lib/redactor/VueRedactor';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'RichText',
  components: {
    Redactor,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  props: {
    errors: {
      type: Array,
    },
  },
  data() {
    return {
      redactorOptions: {
        autoresize: true,
        removeComments: true,
        buttons: [
          'html',
          'formatting',
          'bold',
          'italic',
          'deleted',
          'link',
          'unorderedlist',
          'orderedlist',
          'outdent',
          'indent',
          'alignment',
          'horizontalrule',
        ],
        plugins: [
          'fontcolor',
          'image_web_link',
          'video',
          'table',
        ],
      },
    };
  },
};
</script>

<style lang="scss">
.kn-rich-text-redactor {
  textarea {
    height: 100%;
  }
  .redactor-editor {
    max-height: none;
  }
}
</style>
