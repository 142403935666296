<template>
  <div :class="viewClass">
    <DetailsView
      v-if="showChargeSummary"
      should-show-utility-dropdown
      class="group has-util-dropdown"
      :class="viewClass"
      :data="data"
      :empty-title="`payment details`"
      :hide-empty="!showPaymentDetails"
      :schema="detailsSchema"
      :view="view"
      :route-prefix="routePrefixDetails"
      @delete="onDeleteDetail"
      @drop="onDropDetail"
    />
    <div class="kn-details-group">
      <div
        v-tippy
        content="Click to edit the payment summary"
        class="click-interceptor"
        style="cursor: pointer;"
        @click="onClickPaymentSummary"
      />

      <div class="kn-details-group-column">
        <div
          v-for="(field, index) in view.summaryFields"
          :key="index"
          class="kn-item"
          style="border: none !important;"
        >
          <label>{{ field.text }}</label>
          <div class="kn-value">
            <span><em>{{ field.text }}</em></span>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative;">
      <div class="click-interceptor" />
      <PaymentForm :view="view" />
    </div>
  </div>
</template>

<script>
import DetailsView from '@/components/renderer/shared/Details';
import RenderUtils from '@/components/renderer/RenderUtils.js';
import DetailActionsMixin from '@/components/renderer/mixins/DetailActionsMixin.js';
import PaymentForm from '@/components/renderer/shared/PaymentForm.vue';
import { eventBus } from '@/store/bus';

export default {
  components: {
    DetailsView,
    PaymentForm,
  },
  mixins: [
    RenderUtils,
    DetailActionsMixin,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPaymentDetails: false,
    };
  },
  computed: {
    routePrefix() {
      return `/pages/${this.$route.params.pageKey}/views/${this.view.key}/checkout`;
    },
    routePrefixDetails() {
      return `${this.routePrefix}/details`;
    },
    showChargeSummary() {
      if (this.view.type === 'customer') {
        return false;
      }

      return true;
    },
    detailsIsEmpty() {
      return !this.view.get('checkout_page').columns.some((layout) => layout.groups.some((group) => group.columns.some((column) => column.length > 0)));
    },
    viewClass() {
      const classes = {};

      return classes;
    },
    detailsSchema: {
      get() {
        if (this.view.get('checkout_page')) {
          return this.view.get('checkout_page').columns || [];
        }

        return [];
      },
      set(newVal) {
        this.view.get('checkout_page').columns = newVal;
      },
    },
    data() {
      return this.view.data.records[0];
    },
  },
  created() {
    this.showPaymentDetails = !this.detailsIsEmpty;

    eventBus.$off(`checkout.${this.view.key}.showAddDetails`);
    eventBus.$on(`checkout.${this.view.key}.showAddDetails`, () => {
      this.showPaymentDetails = true;
    });
  },
  methods: {
    onClickPaymentSummary() {
      this.$router.push(`${this.routePrefix}/summary`);
    },
  },
};
</script>

<style lang="scss">
.kn-checkout {
  min-width: 600px;
}
</style>
