<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Date Format</label>
      <div>
        <select
          v-model="fieldLocal.format.date_format"
          class="text-base py-2 pl-3 leading-5"
        >
          <option>mm/dd/yyyy</option>
          <option>M D, yyyy</option>
          <option>dd/mm/yyyy</option>
          <option>Ignore Date</option>
        </select>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Time Format</label>
      <div>
        <select
          v-model="fieldLocal.format.time_format"
          class="text-base py-2 pl-3 leading-5"
        >
          <option>HH:MM am</option>
          <option value="HH MM (military)">HH:MM (24 hour)</option>
        </select>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Minutes Format</label>
      <div>
        <select
          v-model="fieldLocal.format.minutes"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="1">
            Every minute
          </option>
          <option value="5">
            Every 5 minutes
          </option>
          <option value="10">
            Every 10 minutes
          </option>
          <option value="15">
            Every 15 minutes
          </option>
          <option value="30">
            Every 30 minutes
          </option>
        </select>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Total Format</label>
      <div>
        <select
          v-model="fieldLocal.format.total_format"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="from-to">
            From-To
          </option>
          <option value="seconds">
            Seconds
          </option>
          <option value="minutes">
            Minutes
          </option>
          <option value="hours">
            Hours
          </option>
          <option value="days">
            Days
          </option>
          <option value="weeks">
            Weeks
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timer',
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
