<template>
  <ViewToolbox
    back-title="Edit List"
    title="Settings"
  >
    <div>
      <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label">List Layout</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="viewRaw.list_layout"
          type="radio"
          value="one-column"
        >1 Column</label>
        <label><input
          v-model="viewRaw.list_layout"
          type="radio"
          value="two-column"
        >2 Columns</label>
        <label><input
          v-model="viewRaw.list_layout"
          type="radio"
          value="three-column"
        >3 Columns</label>
      </div>
    </div>

    <div>
      <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label">Options</label>
      <div class="input-labels tw-input-labels">
        <label class="text-emphasis text-base mb-3 font-normal items-baseline"><input
          v-model="viewRaw.hide_fields"
          type="checkbox"
        >Hide fields that don't have values</label>
        <label class="text-emphasis text-base mb-3 font-normal items-baseline"><input
          v-model="viewRaw.keyword_search"
          type="checkbox"
        >Keyword search</label>
        <template v-if="viewRaw.keyword_search && isSQL">
          <div class="ml-5">
            <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label mt-3">Which fields can be searched?</label>
            <select
              v-model="viewRaw.keyword_search_fields"
              class="margin-bottom text-base py-2 pl-3 leading-5"
            >
              <option value="view">
                The fields being used by this view
              </option>
              <option value="all">
                All fields in table
              </option>
            </select>
          </div>
        </template>
        <label class="text-emphasis text-base mb-3 font-normal items-baseline"><input
          v-model="viewRaw.allow_exporting"
          type="checkbox"
        >Allow record exporting</label>
      </div>
    </div>

    <div class="mb-0 pb-6 border border-solid border-subtle border-x-0 border-t-0">
      <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label">Empty List Text <HelpIcon copy="Text to show when the list<br />has no records to display" /></label>
      <input
        v-model="viewRaw.no_data_text"
        type="text"
      >
    </div>

    <Toggle
      :is-open="false"
      class="mt-2.5"
    >
      <template #title>
        <span>Pagination</span>
      </template>

      <template #content>
        <Pagination
          v-model:rows-per-page="viewRaw.rows_per_page"
          v-model:allow-limit="viewRaw.allow_limit"
          v-model:display-below="viewRaw.display_pagination_below"
        />
      </template>
    </Toggle>

    <Toggle
      :is-open="false"
      class="mt-2.5"
    >
      <template #title>
        <span>Filter Options</span>
      </template>

      <template #content>
        <Filters
          v-model:filter-type="viewRaw.filter_type"
          v-model:filter-fields="viewRaw.filter_fields"
          v-model:preset-filters="viewRaw.preset_filters"
          v-model:allow-preset-filters="viewRaw.allow_preset_filters"
          v-model:menu-filters="viewRaw.menu_filters"
          v-model:connection-sources="viewRaw.filter_connection_sources"
          :object="object"
        />
      </template>
    </Toggle>

    <Toggle
      :is-open="false"
      class="mt-2.5"
    >
      <template #title>
        <span>Title & Description</span>
      </template>

      <template #content>
        <div class="grid-verticalGaps">
          <div>
            <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label">Title</label>
            <input
              v-model="viewRaw.title"
              type="text"
            >
          </div>
          <div>
            <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label">Description</label>
            <textarea v-model="viewRaw.description" />
          </div>
        </div>
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import isNil from 'lodash/isNil';
import Filters from '@/components/views/shared/settings/Filters';
import HelpIcon from '@/components/ui/HelpIcon';
import Pagination from '@/components/views/shared/settings/Pagination';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'ListSettings',
  components: {
    Filters,
    HelpIcon,
    Pagination,
    Toggle,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    totals() {
      return this.viewRaw.totals ? this.viewRaw.totals : [];
    },
    customFilters() {
      return (this.viewRaw.preset_filters && this.viewRaw.allow_preset_filters) ? this.viewRaw.preset_filters : [];
    },
    isSQL() {
      return this.$store.getters.app.isSQL();
    },
  },
  created() {
    // Allow empty strings
    if (isNil(this.viewRaw.no_data_text)) {
      this.viewRaw.no_data_text = 'No Data';
    }
  },
  methods: {},
};
</script>
