<template>
  <div class="level control kn-inputWrapper  is-grouped">
    <div
      v-if="ignoreDate"
      class="control kn-inputWrapper  is-expanded"
    >
      <Datepicker
        :id="`${field.key}-date-from`"
        v-model="localFromDate"
        name="date-from"
        type="hidden"
        class="kn-datepicker"
        input-class="input"
        placeholder=""
        :typeable="true"
        :parse-typed-date="parseTypedDateForDatepicker"
        :format="getMomentDateFormatFromKnackDateFormat(input.format.date_format)"
      />
    </div>

    <div
      v-else
      class="control kn-inputWrapper  is-expanded"
    >
      <Datepicker
        :id="`${field.key}-date-from`"
        v-model="localFromDate"
        name="date-from"
        class="kn-datepicker"
        input-class="input"
        placeholder=""
        :typeable="true"
        :parse-typed-date="parseTypedDateForDatepicker"
        :format="getMomentDateFormatFromKnackDateFormat(input.format.date_format)"
      />
    </div>

    <div class="control kn-inputWrapper  is-expanded">
      <TimePicker
        :id="`${field.key}-time-from`"
        v-model="localFromTime"
        class="kn-time input"
        :format="getMomentTimeFormatFromKnackTimeFormat(input.format.time_format)"
        :minute-interval="input.format.minutes"
        name="time-from"
      />
    </div>

    <div class="control kn-inputWrapper group-[&:not(.kn-view)]/shared:flex-[0_0_auto] group-[&:not(.kn-view)]/shared:w-fit">
      <span>{{ trans('to') }}</span>
    </div>

    <div class="control kn-inputWrapper  is-expanded">
      <TimePicker
        :id="`${field.key}-time-to`"
        v-model="localToTime"
        class="kn-time input"
        :format="getMomentTimeFormatFromKnackTimeFormat(input.format.time_format)"
        :minute-interval="input.format.minutes"
        name="time-to"
      />
    </div>

    <div
      v-if="ignoreDate"
      class="control kn-inputWrapper  is-expanded"
    >
      <Datepicker
        :id="`${field.key}-date-to`"
        v-model="localToDate"
        name="date-to"
        type="hidden"
        class="kn-datepicker input"
        placeholder=""
        :typeable="true"
        :parse-typed-date="parseTypedDateForDatepicker"
        :format="getMomentDateFormatFromKnackDateFormat(input.format.date_format)"
      />
    </div>

    <div
      v-else
      class="control kn-inputWrapper  is-expanded"
    >
      <Datepicker
        :id="`${field.key}-date-to`"
        v-model="localToDate"
        name="date-to"
        class="kn-datepicker input"
        placeholder=""
        :typeable="true"
        :parse-typed-date="parseTypedDateForDatepicker"
        :format="getMomentDateFormatFromKnackDateFormat(input.format.date_format)"
      />
    </div>

    <input
      name="key"
      type="hidden"
      :value="field.key"
    >
  </div>
</template>

<script>
import hasIn from 'lodash/hasIn';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';
import DateUtils from '@/components/renderer/mixins/DateUtils.js';
import Datepicker from '@/components/ui/inputs/datepicker/Datepicker';
import TimePicker from '@/components/ui/inputs/TimePicker';

export default {
  name: 'TimerInput',
  components: {
    Datepicker,
    TimePicker,
  },
  mixins: [
    TranslationUtils,
    DateUtils,
  ],
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: [
        Object,
        String, // can be an empty string
      ],
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    localFromDate: {
      get() {
        if (hasIn(this.localValue, 'times[0].from.date')) {
          return this.localValue.times[0].from.date;
        }

        return this.getDefaultDate(this.input);
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          times: [
            {
              ...this.localValue.times[0],
              from: {
                ...this.localValue.times[0].from,
                date: this.parsedDate(newValue),
              },
            },
          ],
        });
      },
    },
    localToDate: {
      get() {
        if (hasIn(this.localValue, 'times[0].to.date')) {
          return this.localValue.times[0].to.date;
        }

        return this.getDefaultDate(this.input);
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          times: [
            {
              ...this.localValue.times[0],
              to: {
                ...this.localValue.times[0].to,
                date: this.parsedDate(newValue),
              },
            },
          ],
        });
      },
    },
    localFromTime: {
      get() {
        if (hasIn(this.localValue, 'times[0].from.time')) {
          return this.parsedTimeRawValue(this.localValue.times[0].from.time, this.timeFormat);
        }

        return this.input.format.time_type !== 'none' ? this.getDefaultTime(this.input) : '';
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          times: [
            {
              ...this.localValue.times[0],
              from: {
                ...this.localValue.times[0].from,
                hours: this.parsedHours(newValue, this.timeFormat),
                minutes: this.parsedMinutes(newValue, this.timeFormat),
                am_pm: this.parsedMeridiem(newValue, this.timeFormat),
                time: this.parsedMinutesFromMidnight(newValue, this.timeFormat), // required for Timepicker syncing
              },
            },
          ],
        });
      },
    },
    localToTime: {
      get() {
        if (hasIn(this.localValue, 'times[0].to.time')) {
          return this.parsedTimeRawValue(this.localValue.times[0].to.time, this.timeFormat);
        }

        return this.input.format.time_type !== 'none' ? this.getDefaultTime(this.input) : '';
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          times: [
            {
              ...this.localValue.times[0],
              to: {
                ...this.localValue.times[0].to,
                hours: this.parsedHours(newValue, this.timeFormat),
                minutes: this.parsedMinutes(newValue, this.timeFormat),
                am_pm: this.parsedMeridiem(newValue, this.timeFormat),
                time: this.parsedMinutesFromMidnight(newValue, this.timeFormat), // required for Timepicker syncing
              },
            },
          ],
        });
      },
    },
    ignoreDate() {
      return this.input.format.date_format === 'Ignore Date';
    },
    timeFormat() {
      return this.input.format.time_format;
    },
  },
  created() {
    // if a Timer field was added to an existing record the initial value will be an empty string
    // so we will need to set the value manually for editing purposes
    if (this.localValue === '') {
      this.localValue = {
        times: [
          {
            from: {
              date: this.parsedDate(this.localFromDate),
              hours: this.parsedHours(this.localFromTime, this.timeFormat),
              minutes: this.parsedMinutes(this.localFromTime, this.timeFormat),
              am_pm: this.parsedMeridiem(this.localFromTime, this.timeFormat),
              time: this.parsedMinutesFromMidnight(this.localFromTime, this.timeFormat),
            },
            to: {
              date: this.parsedDate(this.localToDate),
              hours: this.parsedHours(this.localToTime, this.timeFormat),
              minutes: this.parsedMinutes(this.localToTime, this.timeFormat),
              am_pm: this.parsedMeridiem(this.localToTime, this.timeFormat),
              time: this.parsedMinutesFromMidnight(this.localToTime, this.timeFormat),
            },
          },
        ],
      };
    }
  },
  mounted() {
    // remove timepicker plugin styles because they were overriding ours
    const $timepickers = this.$el.querySelectorAll('.date-time-picker input');

    for (const $timepicker of $timepickers) {
      $timepicker.classList.remove('field-input');
    }
  },
};
</script>

<style lang="scss">
</style>
