<template>
  <DynamicScroller
    v-if="itemSize === null"
    ref="scroller"
    v-slot="{ item, index, active }"
    :items="itemsWithKeys"
    :min-item-size="38"
    key-field="id"
  >
    <DynamicScrollerItem
      :item="item"
      :active="active"
    >
      <slot
        :item="item"
        :index="index"
      />
    </DynamicScrollerItem>
  </DynamicScroller>
  <RecycleScroller
    v-else
    ref="scroller"
    v-slot="{ item, index }"
    :items="itemsWithKeys"
    :item-size="itemSize"
    :min-item-size="itemSize"
    key-field="id"
    type-field="scrollerType"
  >
    <slot
      :item="item"
      :index="index"
    />
  </RecycleScroller>
</template>

<script>
import { Sortable } from '@shopify/draggable';
import { RecycleScroller, DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

export default {
  components: {
    RecycleScroller,
    DynamicScroller,
    DynamicScrollerItem,
  },
  props: {
    /**
     * Height (or width in horizontal mode) of the items in pixels used to calculate the scroll size and position.
     */
    itemSize: {
      type: Number,
      default: null,
    },
    /**
     * List of items to display in the scroller.
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * Allows items sorting/ordering functionality
     */
    canSort: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['sort', 'sortDrag', 'update:items'],
  data() {
    return {
      sortable: null,
    };
  },
  computed: {
    itemsWithKeys() {
      const duplicatedItems = Array.from(this.items);

      // Not great.
      // We have to modify in-place which to maintain responsiveness
      // but also causes it to modify for everyone.
      return duplicatedItems.map((item, index) => {
        if (isNil(item.id)) {
          // Default value is index
          item.id = get(item, 'attributes.key', index);
        }

        item.scrollerType = 'scroll-item';

        return item;
      });
    },
  },
  mounted() {
    if (this.canSort !== true) {
      return;
    }

    this.$nextTick(() => {
      const { scroller } = this.$refs;

      const itemWrapper = scroller.$refs.wrapper;

      this.sortable = new Sortable(itemWrapper, {
        draggable: '.vue-recycle-scroller__item-view',
        distance: 5, // prevents click conflicts
        mirror: {
          constrainDimensions: true,
        },
        classes: {
          'source:dragging': 'is-dragging',
        },
      });

      let initialPosition = null;

      this.sortable.on('sortable:start', (event) => {
        // Get the ID of the element that is being dragged.
        // Every list that uses SortableItems will need their
        // element that is wrapped by RecycleScroller to have
        // their RecycleScroller key (field_1, object_1, a database id)
        // as the id
        const { startContainer, startIndex } = event.data;
        const startId = startContainer.children[startIndex].firstElementChild.id
          .split('-')
          .slice(-1)[0];

        // Get the initial position of the item being dragged
        initialPosition = startIndex;

        // Set it so we don't have to look this up every time the drag
        // event is fired.
        this.sortable.draggingId = startId;
      });

      // Update the items on every position change due to dragging
      this.sortable.on('sortable:sorted', (event) => {
        const { scroller } = this.$refs;

        // What key is being hovered over currently
        // This will be the contents of the keyField
        // for each item in RecycleScroller
        const { hoverKey } = scroller;

        const draggingIndex = this.items.findIndex((item) => item.id === this.sortable.draggingId);

        // You could also use .draggable--over and query the DOM.
        // The current method won't be broken if the element's structure
        // is expanded or changed. In addition, you'll have to rely on
        // the child node having attributes like data-item to know
        // the index in the list. While this has an ok runtime,
        // there could be a bit more squeezed out.
        const replacingIndex = this.items.findIndex((item) => item.id === hoverKey);

        const sortedItem = this.items[draggingIndex];

        // "Why can't we rely on event.data.oldIndex and event.data.newIndex"
        // which are created by Draggable?"
        // Those index are accurate to the index of the currently shown list
        // generated by RecycleScroller so those indexes alone will not be enough.
        //
        // "OK, we can offset by this.$refs.recycleScroller.$_startIndex
        // to get the correct index in context of the full list."
        // This works in most cases actually. The big issue is that we can't
        // rely on RecycleScroller always being accurate because the indexing
        // goes wonky if you scroll quickly, which causes RecycleScroller to not load in
        // items it hasn't yet needed to render causing indexing to be off.
        // Again, the offset method *does* work in most cases, but for consistentcy + reliabilty,
        // the thought was to use a dumber, slower (n/2 runtime vs constant)
        // to be sure this consistently works or else customers could complain
        // that fields are being deleted (they aren't) or that this has odd behavior.
        this.items.splice(draggingIndex, 1);
        this.items.splice(replacingIndex, 0, sortedItem);

        this.$emit('sortDrag', event, this.items);
        this.$emit('update:items', this.items);
      });

      // Dragging has emit final sort
      this.sortable.on('sortable:stop', (event) => {
        // Get the final position of the item being dragged
        const finalPosition = this.items.findIndex((item) => item.id === this.sortable.draggingId);

        this.sortable.draggingId = null;

        // Trigger the sort if there is a change in position of the item and the list is bigger than 1
        if (initialPosition !== finalPosition && this.items.length > 1) {
          this.$emit('sort', event, this.items);
        }
      });
    });
  },
  beforeUnmount() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  },
};
</script>

<style lang="scss">
.vue-recycle-scroller {
  position:relative;

  li {
    list-style-type: none;
  }
}
.vue-recycle-scroller.direction-vertical:not(.page-mode){
  overflow-y:auto
}
.vue-recycle-scroller.direction-horizontal:not(.page-mode){
  overflow-x:auto
}
.vue-recycle-scroller.direction-horizontal{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
}
.vue-recycle-scroller__slot{
  -webkit-box-flex:1;
  -ms-flex:auto 0 0px;
  flex:auto 0 0
}
.vue-recycle-scroller__item-wrapper{
  -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  overflow:hidden;
  position:relative
}
.vue-recycle-scroller.ready .vue-recycle-scroller__item-view{
  position:absolute;
  top:0;
  left:0;
  will-change:transform
}
.vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper{
  width:100%
}
.vue-recycle-scroller.direction-horizontal .vue-recycle-scroller__item-wrapper{
  height:100%
}
.vue-recycle-scroller.ready.direction-vertical .vue-recycle-scroller__item-view{
  width:100%
}
.vue-recycle-scroller.ready.direction-horizontal .vue-recycle-scroller__item-view{
  height:100%
}
.resize-observer {
  position:absolute;
  top:0;
  left:0;
  z-index:-1;
  width:100%;
  height:100%;
  border:none;
  background-color:transparent;
  pointer-events:none;
  display:block;
  overflow:hidden;
  opacity:0
}
.resize-observer object{
  display:block;
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  overflow:hidden;
  pointer-events:none;
  z-index:-1
}
</style>
