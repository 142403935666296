<template>
  <div class="kn-pagination level-right">
    <PaginationSelect
      v-if="allowLimit"
      :records-per-page="recordsPerPage"
      @changeRecordsPerPage="onChangeRecordsPerPage"
    />
    <PaginationSelection
      :total-pages="totalPages"
      :current-page="currentPage"
      @currentPageSelect="onChangePage"
    />
    <PaginationArrows
      v-if="showPaginationArrows"
      :current-page="currentPage"
      :total-pages="totalPages"
      @changePage="onChangePage"
    />
  </div>
</template>

<script>
import PaginationArrows from '@/components/renderer/shared/PaginationArrows';
import PaginationSelect from '@/components/renderer/shared/PaginationSelect';
import PaginationSelection from '@/components/renderer/shared/PaginationSelection';

export default {
  components: {
    PaginationArrows,
    PaginationSelect,
    PaginationSelection,
  },
  props: {
    recordsPerPage: {
      type: Number,
      default: 25,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    allowLimit: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:currentPage',
    'update:recordsPerPage',
  ],
  computed: {

    showPaginationArrows() {
      return true;
    },
  },
  methods: {
    onChangeRecordsPerPage(value) {
      this.$emit('update:recordsPerPage', value);
    },

    onChangePage(value) {
      this.$emit('update:currentPage', value);
    },
  },
};
</script>

<style>
.kn-pagination {
  margin-left: .5em;
}
</style>
