<template>
  <div
    class="accordion"
    :class="{ 'is-collapsed': !expanded }"
  >
    <button
      ref="trigger"
      type="button"
      class="drawerItem accordion_trigger"
      @click="toggleExpanded"
    >
      <div class="drawerItem_iconWrapper">
        <Icon
          class="drawerItem_icon"
          :type="icon"
        />
      </div>
      <div class="drawerItem_content">
        <h2 class="drawerItem_header">
          {{ config.title }}
        </h2>
        <p class="drawerItem_caption">
          {{ config.caption }}
        </p>
      </div>
      <Icon
        type="arrow-drop-right"
        class="accordion_indicator"
      />
    </button>
    <ul
      ref="expandable"
      class="accordion_content"
    >
      <li
        v-for="(item, index) in config.items"
        :key="index"
      >
        <HelpLink
          :config="item"
          class="drawerItem-subItem accordion_item"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import UIUtil from '@/util/UI';
import Icon from '@/components/ui/Icon';
import HelpLink from '@/components/builder/help/HelpLink';

export default {
  name: 'HelpAccordion',
  components: {
    Icon,
    HelpLink,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    icon() {
      return this.config.icon;
    },
  },
  mounted() {
    this.initSlideToggle(this.expanded);
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    initSlideToggle(openState) {
      this.$nextTick(() => {
        /* eslint-disable no-unused-vars */
        this.slideToggle = new UIUtil.SlideToggle(this.$refs.expandable, {
          trigger: this.$refs.trigger,
          isOpen: openState,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  background-color: #F7F7F8;

  transition: background-color .2s ease-out;

  &.is-collapsed {
    background-color: transparent;

    .accordion_trigger {
      box-shadow: none;
    }

    .accordion_indicator {
      transform: rotate(0deg);
    }
  }

  &_item {
    border-bottom-color: #ffffff;

    &:hover {
      background-color: #EFF0F1;
    }
  }

  &_trigger {
    box-shadow:  0 6px 12px -2px rgba(#F2F2F3, 1);
  }

  &_indicator {
    height: 25px;
    margin-right: -10px;
    width: 25px;

    color: #202426;

    transition: transform .2s ease-out;
    transform: rotate(90deg);
  }

  &_content {
    max-height: 0px;

    transition: max-height .2s ease-out;
  }
}
</style>
