<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Image Source</label>
      <div>
        <select
          v-model="fieldLocal.format.source"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="upload">
            Upload image files
          </option>
          <option value="url">
            Load images from an external URL
          </option>
        </select>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4"><input
        type="checkbox"
        :checked="fieldLocal.format.resize"
        @click="onToggleResize()"
      >Resize Image</label>
      <div
        v-if="fieldLocal.format.resize"
        class="resize-wrapper selectionGroup ml-0 border-0 pl-0"
      >
        <div
          v-if="showResizeWarning"
          class="margin-bottom"
        >
          <section
            class="alert alert-warning border-0 rounded-lg bg-[#FEF4D7] text-base text-warning-emphasis"
            style="font-size: .9rem;"
          >
            <div class="alert-left">
              <Icon type="warning" />
            </div>
            <div class="alert-right">
              <strong>All existing images</strong> on this field will be resized after enabling this option.
            </div>
          </section>
        </div>
        <label class="text-default text-sm font-medium mb-2 leading-4">Resize Dimensions</label>
        <div>
          <label
            v-for="({label, value, description}, index) in resizeOptions"
            :key="value"
            class="option radio text-emphasis text-base mb-3 font-normal"
          >
            <span class="inline-flex">
              <input
                v-model="resizeOption"
                type="radio"
                class="radio my-0"
                :value="value"
                @change="onUpdateResizeOption(value)"
              >
              <span>{{ label }}:&nbsp</span>
            </span>
            <span class="resize-type-description text-subtle not-italic ml-0">{{ description }}</span>
          </label>
        </div>

        <div
          v-if="fieldLocal.format.resize && fieldLocal.format.resize.type === `custom`"
          class="input_labels horizontal-labels"
          style="margin-left: 20px; margin-top: 10px;"
        >
          <div style="margin-right: 1em;">
            <label class="text-default text-sm font-medium mb-2 leading-4">Max Width&nbsp;(px)</label>
            <input
              v-model="fieldLocal.format.resize.maxWidth"
              class="h-10 border border-default rounded-lg py-2 pl-3 pr-3 text-base text-emphasis hover:!border-emphasis focus:hover:!border-emphasis focus:!border-default focus:outline-default placeholder:text-subtle"
              type="number"
              min="1"
            >
          </div>
          <div>
            <label class="text-default text-sm font-medium mb-2 leading-4">Max Height (px)</label>
            <input
              v-model="fieldLocal.format.resize.maxHeight"
              class="h-10 border border-default rounded-lg py-2 pl-3 pr-3 text-base text-emphasis hover:!border-emphasis focus:hover:!border-emphasis focus:!border-default focus:outline-default placeholder:text-subtle"
              type="number"
              min="1"
            >
          </div>
        </div>
      </div>
    </div>
    <div id="field-thumbnail-list">
      <label class="text-default text-sm font-medium mb-2 leading-4">Thumbnails</label>
      <div>
        <p>
          Add thumbnails to display smaller versions of your images.
        </p>
        <ActionList
          v-if="thumbs.length > 0"
          v-model:items="thumbs"
          :default-item="defaultThumb"
          :can-be-empty="true"
          class="is-grouped bg-muted rounded-lg p-4"
        >
          <template #default="props">
            <select
              v-model="props.item.thumb_type"
              class="text-base py-2 pl-3 leading-5"
            >
              <option value="pixel">
                Resize by pixel
              </option>
              <option value="percentage">
                Resize by percentage
              </option>
              <option value="square">
                Resize to a square
              </option>
            </select>
            <div v-if="showPixelOptions(props.item)">
              <span>Width&nbsp;</span>
              <input
                v-model="props.item.width"
                type="text"
                class="min-w-[56px]"
              >
              <span>Height&nbsp;</span>
              <input
                v-model="props.item.height"
                class="min-w-[56px]"
                type="text"
              >
            </div>
            <div
              v-if="showPercentageOptions(props.item)"
              class="mr-6"
            >
              <input
                v-model="props.item.percentage"
                type="text"
                class="min-w-[56px] w-14 mr-1.5"
                style="margin-right: 2px;"
              >
              <span>%</span>
            </div>
            <div v-if="showSquareOptions(props.item)">
              <span class="truncate">Side length&nbsp;</span>
              <input
                v-model="props.item.length"
                type="text"
                class="min-w-[56px] mr-1.5"
                style="margin-right: 2px;"
              >
              <span>px</span>
            </div>
          </template>
        </ActionList>
        <div v-if="thumbs.length === 0">
          <a
            class="underline p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 no-underline hover:text-emphasis"
            @click="addInitialThumb()"
          >
            Add Thumbnail
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';

import ActionList from '@/components/ui/lists/ActionList';
import HelpIcon from '@/components/ui/HelpIcon';
import Icon from '@/components/ui/Icon';

const RESIZE_TYPE_WEB_FRIENDLY = 'web-friendly';
const RESIZE_TYPE_CUSTOM = 'custom';

export default {
  name: 'ImageField',
  components: {
    ActionList,
    HelpIcon,
    Icon,
  },
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
    isNew: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      resizeOption: RESIZE_TYPE_WEB_FRIENDLY,
      showResizeWarning: false,
      resizeOptions: [
        {
          label: 'Web Friendly',
          value: RESIZE_TYPE_WEB_FRIENDLY,
          description: 'The maximum width and height will be 1500px',
        },
        {
          label: 'Custom',
          value: RESIZE_TYPE_CUSTOM,
          description: 'You define the maximum width and height',
        },
      ],
    };
  },
  computed: {
    thumbs: {
      get() {
        return this.fieldLocal?.format?.thumbs ?? [];
      },
      set(newThumbs) {
        if (!this.fieldLocal?.format) {
          return;
        }
        this.fieldLocal.format.thumbs = newThumbs;
      },
    },
  },
  created() {
    const resize = get(this.fieldLocal, 'format.resize');

    if (resize) {
      if (resize.type) {
        this.resizeOption = resize.type;
      } else {
        this.onUpdateResizeOption(this.resizeOptions[0].value);
      }
    }
  },
  methods: {
    addInitialThumb() {
      this.fieldLocal.format.thumbs.push(this.defaultThumb());
    },
    defaultThumb() {
      return {
        thumb_type: 'pixel',
        width: 100,
        height: 100,
        length: 100,
        percentage: 100,
      };
    },
    showPixelOptions(item) {
      return item.thumb_type === 'pixel';
    },
    showPercentageOptions(item) {
      return item.thumb_type === 'percentage';
    },
    showSquareOptions(item) {
      return item.thumb_type === 'square';
    },
    onToggleResize() {
      const { format } = this.fieldLocal;

      if (format.resize) {
        this.showResizeWarning = false;
        this.onUpdateResizeOption(undefined);

        return;
      }

      this.showResizeWarning = !this.isNew;
      this.resizeOption = RESIZE_TYPE_WEB_FRIENDLY;
      this.onUpdateResizeOption(RESIZE_TYPE_WEB_FRIENDLY);
    },
    onUpdateResizeOption(newValue) {
      const { format } = this.fieldLocal;

      if (newValue === RESIZE_TYPE_WEB_FRIENDLY) {
        format.resize = {
          type: newValue,
        };
      } else if (newValue === RESIZE_TYPE_CUSTOM) {
        format.resize = {
          type: newValue,
          maxHeight: 1500,
          maxWidth: 1500,
        };
      } else {
        format.resize = undefined;
      }
    },
  },
};
</script>

<style lang="scss">
#field-thumbnail-list {

  label {
    margin: 0;
    margin-right: 4px;
    white-space: nowrap;
  }

  select, input {
    min-width: 50px;
    margin-right: 10px;
  }
}

.resize-wrapper {
  margin-left: 1.5rem;

  .alert {
    border: 1px solid black;
    display: flex;
    padding: 1rem;
  }

  .alert-left {
    margin-right: .75rem;
  }

  .icon-warning {
    color: $warning500;
  }

  .alert-warning {
    background-color: #FFFBF2;
    border: 1px solid #FEEDC0;
    border-radius: 4px;
    box-sizing: border-box;
    color: #222B33;
  }

  .resize-type-description {
    color: #222B33;
    font-style: italic;
    font-weight: normal;
    margin-left: 1rem;
  }
}
</style>
