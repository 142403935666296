<script setup>
import {
  ref, computed, nextTick, onMounted,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Icon from '@/components/ui/Icon.vue';
import checklistStrings from './checklist-strings';
import { completeChecklistStep, replaceUrlVariables } from '@/checklists/helpers';

const props = defineProps({
  step: {
    required: true,
    type: Object,
  },
  isLocked: Boolean,
});

const shouldShowVideo = ref(false);
const router = useRouter();
const store = useStore();
const shouldShowDescription = ref(false);
const stepFromDashboard = computed(() => store.getters['checklists/stepFromDashboard']);

function toggleVideo() {
  shouldShowVideo.value = !shouldShowVideo.value;
  nextTick(() => {
    if (shouldShowVideo.value) {
      const videoElement = document.querySelector(`#video-${props.step.definition.id}`);
      if (videoElement) {
        videoElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  });
}

function toggleDescription() {
  shouldShowDescription.value = !shouldShowDescription.value;
}

const isCompleted = computed(() => !!props.step.completed_at);

const actionIconType = computed(() => {
  if (props.step.definition.type === 'video' || !!props.step.definition.video_url) {
    return 'play';
  }

  if (props.step.definition.type === 'in-product' || props.step.definition.type === 'documentation') {
    return 'arrow-right';
  }

  return null;
});

const iconType = computed(() => {
  if (isCompleted.value) return 'check-circle';

  return 'circle';
});

const iconClass = computed(() => {
  if (isCompleted.value) return 'w-5 h-5 justify-c fill-green-400';
  // if (props.isLocked) return 'w-4 h-4 justify-c text-muted';
  return 'w-5 h-5 justify-c fill-green-400';
});

const onStartTour = () => {
  const [area, url, highlight] = props.step.definition.extra.link.split('::');

  if (area === 'builder') {
    if (highlight) {
      store.commit('checklists/setHighlightedFeatures', highlight.split(','));
    }

    if (url === '{current}') return;

    const replacedUrl = replaceUrlVariables(url, store);

    if (replacedUrl) router.push(`/${replacedUrl}`);
  } else if (area === 'dashboard') {
    window.location.href = `${process.env.VUE_APP_DASHBOARD_URL || '/'}?checklist_step=${props.step.definition.id}`;
  }
};

const onClick = () => {
  if (props.step.definition.type === 'video' || !!props.step.definition.video_url) {
    toggleVideo();
    if (!isCompleted.value && shouldShowVideo.value) {
      onStartTour();
    }
  } else if (props.step.definition.type === 'documentation') {
    completeChecklistStep(props.step.definition.id);

    window.open(props.step.definition.extra.url, '_blank');
  } else if (props.step.definition.type === 'in-product') {
    onStartTour();
  }
};

const description = computed(() => {
  if (props.step.definition.type === 'in-product' && !!props.step.definition.video_url) {
    // In these cases the description goes above the video to also allow the users to start the tour
    return null;
  }

  return checklistStrings.steps[`${props.step.definition.id}_description`];
});

const onLoadVideo = () => {
  // Some steps can have both video and in-product tour but they're completed only after executing the task
  if (props.step.definition.type === 'in-product') {
    return;
  }

  completeChecklistStep(props.step.definition.id);
};

watch(() => stepFromDashboard.value, (newValue) => {
  if (newValue === props.step.definition.id) {
    if (props.step.definition.type === 'video' || !!props.step.definition.video_url) {
      toggleVideo();
    }
    shouldShowDescription.value = true;

    store.commit('checklists/setStepFromDashboard', null);
  }
});
</script>

<template>
  <div @click="toggleDescription">
    <div
      :class="[
        'group flex gap-3 rounded p-2 text-start transition-colors',
        {
          'items-center': !step.definition.description,
          'bg-default hover:bg-muted cursor-pointer': !isCompleted
        },
      ]"
      :data-step-id="step.definition.id"
      @click="onClick"
    >
      <Icon
        :type="iconType"
        :class="iconClass"
      />
      <div class="flex flex-1 flex-col gap-1">
        <div
          style="text-wrap: wrap"
          :class="[
            'text-sm text-emphasis',
            {
              'text-muted': isCompleted,
            // 'text-subtle': isLocked,
            },
          ]"
        >
          {{ checklistStrings.steps[step.definition.id] }}
          <div
            v-if="(step.definition.description) && !isCompleted"
            style="text-wrap: wrap"
            class="text-xs"
            :class="{
              'text-subtle': !isLocked,
            // 'text-muted': isLocked
            }"
          />
          <span
            v-if="step.definition.type === 'in-product' && shouldShowDescription"
            class="text-xs text-muted sm-singleline"
            style="text-wrap: wrap; margin-top: 10px;"
          >
            {{ description }}
          </span>
        </div>
      </div>
      <div
        :class="[
          'self-center rounded p-1 transition-colors leading-none',
          {
            'group-hover:bg-subtle': !isCompleted && actionIconType,
          // 'opacity-50': isLocked,
          },
        ]"
      >
        <a
          v-if="isCompleted && !!step.definition.article_url"
          :tabindex="-1"
          :href="step.definition.article_url"
          target="_blank"
          rel="noreferrer"
          class="hover:bg-transparent p-0"
        >
          <div class="text-emphasis underline text-xs font-normal">
            Learn more
          </div>
        </a>
        <template v-else>
          <Icon
            :type="actionIconType"
            :class="['w-4 h-4', {
            // 'text-muted': isLocked,
            }]"
          />
        </template>
      </div>
    </div>

    <div
      v-if="shouldShowVideo"
      :id="`video-${step.definition.id}`"
      class="flex flex-col gap-1"
    >
      <div class="relative h-0 w-full pb-[56.25%]">
        <iframe
          class="absolute left-0 top-0 h-full w-full rounded-md"
          :src="step.definition.type === 'video'
            ? step.definition.extra.source
            : step.definition.video_url
          "
          allow="autoplay; fullscreen"
          @load="onLoadVideo"
        />
      </div>
    </div>
  </div>
</template>
