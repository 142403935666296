import { eventBus } from '@/store/bus';

export default {
  emits: [
    'dropViewItem',
  ],
  data() {
    return {
      viewDropArea: null, // used if a view has multiple areas like search inputs vs. search results
    };
  },
  methods: {
    initDragDrop() {
      // drag from available items
      eventBus.$on('dragStartItemNew', (event) => {
        this.$el.classList.add('is-dragging-over');
      });

      eventBus.$on('dragEndItemNew', () => {
        this.$el.classList.remove('is-dragging-over');
      });
    },

    // triggered when dragging from the same view
    onDragStart(event) {
      event.stopPropagation();

      // Deselect any elements when starting drag.
      // Because we have to use `document.getSelection`, we can't only deselect within the element being dragged.
      document.getSelection().removeAllRanges();

      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('layout', event.target.getAttribute('data-layout'));
      event.dataTransfer.setData('group', event.target.getAttribute('data-group'));
      event.dataTransfer.setData('column', event.target.getAttribute('data-column'));
      event.dataTransfer.setData('row', event.target.getAttribute('data-row'));
      event.dataTransfer.setData('item', event.target.getAttribute('data-item'));

      this.$el.classList.add('is-dragging-over');

      // If the .kn-item class is not available, try using the custom data attribute.
      const dragImageElement = event.target.querySelector('.kn-item')
        || event.target.querySelector('[data-drag-image]');

      event.dataTransfer.setDragImage(dragImageElement, event.layerX, event.layerY);

      // views check this to ensure eligible drop zones
      this.$store.commit('dragStartSortViewItem', {
        viewKey: this.view.key,
        viewArea: this.viewDropArea,
      });
    },
    onDragEnd(event) {
      this.$el.classList.remove('is-dragging-over');

      this.$el.querySelectorAll('.is-dragging-over').forEach(($el) => $el.classList.remove('is-dragging-over'));

      const dropTarget = document.querySelector('.drop-target.over');

      if (dropTarget) {
        dropTarget.classList.remove('over');
      }

      // views check this to ensure eligible drop zones
      this.$store.commit('dragEndSortViewItem', {
        viewKey: this.view.key,
        viewArea: this.localViewType,
      });
    },
    onDragEndGroup(event) {
      this.$el.querySelectorAll('.is-dragging-over').forEach(($el) => $el.classList.remove('is-dragging-over'));
      event.currentTarget.classList.remove('is-dragging-over');
    },
    onDragOver(event) {
      return event.preventDefault();
    },
    onDragOverGroup(event) {
      event.currentTarget.classList.add('is-dragging-over');

      return event.preventDefault();
    },
    onDragEnter(event) {
      // if this isn't the right view, we should bail
      if (!this.$store.getters.isDraggingViewItem(this.view.key, this.viewDropArea)) {
        return false;
      }

      return event.target.classList.add('over');
    },
    onDragLeave(event) {
      this.resetDragDrop();

      return event.target.classList.remove('over');
    },
    onDragLeaveGroup(event) {
      this.$el.querySelectorAll('.is-dragging-over').forEach(($el) => $el.classList.remove('is-dragging-over'));
      event.currentTarget.classList.remove('is-dragging-over');
    },
    onDrop(event) {
      if (!this.$store.getters.isDraggingViewItem(this.view.key, this.viewDropArea)) {
        return false;
      }

      this.$el.querySelectorAll('.is-dragging-over').forEach(($el) => $el.classList.remove('is-dragging-over'));

      event.preventDefault();
      event.stopPropagation();

      // if this component is handling drag/drop events it should have a local function
      if (this.onDropViewItem) {
        this.onDropViewItem(event);
      }

      // otherwise we'll emit to the parent component
      this.$emit('dropViewItem', event);
    },
    resetDragDrop() {
      this.groupIndex = null;
      this.columnIndex = null;
      this.itemIndex = null;
      this.addLocationType = null;
    },
    // TODO: delete this once search drag/drop gets refactored
    getElementIndex(node, type) {
      let index = 0;

      while (node && (node = node.previousElementSibling)) {
        if (type) {
          if (node.getAttribute('data-type') === type) {
            index++;
          }

          continue;
        }

        index++;
      }

      return index;
    },
  },
};
