<template>
  <FormWrapper
    :show-submit="false"
    class="recordsNav pr-7 overflow-auto py-4 mb-0"
    @submit="recordsNavMixin_triggerUpdate"
  >
    <div class="recordsNav_left group/shared flex-[0_0_auto]">
      <TextInput
        v-if="recordsNavMixin_displaySettings.keywordSearch"
        v-model="recordsNavMixin_localSearch"
        class="recordsNav_search max-w-xs"
        :input-classes="['border-r-[0.5px]', 'hover:border-r', 'w-48']"
        placeholder="Search by keyword"
        name="keyword"
        :has-button="true"
        :show-reset="showReset"
        @reset="resetSearch"
      />
      <PaginationRange
        v-if="recordsNavMixin_displaySettings.paginationRange"
        class="recordsNav_range"
        :current-page="Number(recordsNavMixin_localCurrentPage)"
        :records-per-page="Number(recordsNavMixin_localRecordsPerPage)"
        :total-records="Number(totalRecords)"
      />
      <Filters
        v-if="recordsNavMixin_displaySettings.filters"
        :key="recordsNavMixin_localFilters.rules.length"
        v-model:filters="recordsNavMixin_localFilters"
        class="recordsNav_filters"
        :object-key="objectKey"
      />
    </div>
    <div class="recordsNav_right group/shared">
      <Pagination
        v-if="recordsNavMixin_displaySettings.pagination"
        v-model:records-per-page="recordsNavMixin_localRecordsPerPage"
        v-model:current-page="recordsNavMixin_localCurrentPage"
        :total-pages="totalRecordsPages"
        :allow-limit="allowLimit"
      />
    </div>
  </FormWrapper>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

import FormWrapper from '@/components/ui/FormWrapper';
import TextInput from '@/components/builder/inputs/Text';
import PaginationRange from '@/components/renderer/shared/PaginationRange';
import Filters from '@/components/renderer/shared/Filters';
import Pagination from '@/components/renderer/shared/Pagination';
import RecordsNavMixin from '@/components/renderer/mixins/RecordsNavMixin';

export default {
  components: {
    FormWrapper,
    TextInput,
    PaginationRange,
    Filters,
    Pagination,
  },
  mixins: [
    RecordsNavMixin,
  ],
  computed: {
    showReset() {
      return !isEmpty(this.recordsKeywordSearch);
    },
  },
  methods: {
    resetSearch() {
      this.recordsNavMixin_localSearch = '';
      this.recordsNavMixin_triggerUpdate();
    },
  },

};
</script>

<style lang="scss" scoped>
.recordsNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: .75em 0 .25em;
  margin-bottom: .5em;
  font-size: 1em;

  &_left {
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin-bottom: 0;
    overflow: hidden;
  }

  &_right {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 0;
  }

  &_search {
    max-width: 200px;
    margin-right: 10px;
  }

  &_range {
    white-space: nowrap;
  }
}
</style>

<style lang="scss">
  .recordsNav {
    .kn-filters {
      flex-wrap: nowrap;
      overflow: hidden;
    }

    .kn-add-filter {
      min-width: 88px;
    }

    .kn-tag-filter {
      margin-top: 1.5px;
      margin-bottom: 1.5px;
    }

    .kn-select select {
      padding-right: 1.75em;
    }
  }
</style>
