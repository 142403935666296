<template>
  <view-toolbox
    back-title="page"
    :title="title"
  >
    <div>
      <toc-links :items="menuItems" />
    </div>
  </view-toolbox>
</template>
<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import TocLinks from '@/components/views/TocLinks';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  components: {
    ViewToolbox,
    TocLinks,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    title() {
      if (this.view.type === 'customer') {
        return 'Edit Payment Method';
      }

      return 'Edit Payment';
    },
    menuItems() {
      const menuItems = [
        {
          title: 'Settings',
          copy: 'checkout settings',
          link: 'settings',
        },
        {
          title: 'Summary',
          copy: 'payment summary to display with your totals.',
          link: 'summary',
        },
        {
          title: 'Payment Details',
          copy: 'provide information to the user before they\'re charged',
          link: 'details',
        },
        {
          title: 'Emails',
          copy: 'send emails after a payment is submitted',
          link: 'emails',
        },
        {
          title: 'Rules',
          copy: 'submit and record rules after a payment is submitted',
          link: 'rules',
        },
      ];

      if (this.view.type === 'customer') {
        // Payment views don't need "Payment Summary" or "Payment Details"
        menuItems.splice(1, 2);
      }

      return menuItems;
    },
  },
};
</script>
