<template>
  <div id="step-headers">
    <h1 class="bg-transparent text-emphasis text-xl font-medium p-6 pb-4">
      {{ sheetNames.length > 1 ? 'Select your Sheet and define headers' : 'Define Headers' }}
    </h1>
    <div class="toolbox-body px-6 py-0">
      <div
        v-if="sheetNames.length > 1"
        class="selection-row is-padded flex padding-top"
      >
        <p class="text-emphasis text-base font-normal leading-4">
          Which tab from this Google sheet would you like to import?
        </p>
        <select
          v-model="sheetIndexValue"
          class="h-10 text-base font-normal leading-5"
        >
          <option
            v-for="(sheet, index) in sheetNames"
            :key="index"
            :value="index"
          >
            {{ sheet }}
          </option>
        </select>
      </div>
      <p class="is-padded padding-top p-0 font-medium">
        Does the first row of your data contain column headers?
      </p>
      <form
        class="is-padded p-0"
        @submit.prevent="onSubmit"
      >
        <div class="tw-input-labels">
          <label><input
            v-model="headerValue"
            type="radio"
            name="hasHeaderRow"
            class="mt-0 mr-2"
            :value="true"
          > Yes</label>
          <label><input
            v-model="headerValue"
            type="radio"
            name="hasHeaderRow"
            class="mt-0 mr-2"
            :value="false"
          > No</label>
        </div>
      </form>
    </div>
    <div
      id="submit-mapping"
      class="flex justify-end border-0 px-6"
    >
      <a
        class="button gray-outline px-3 py-2.5 rounded-lg bg-transparent border border-solid border-default text-base leading-4 font-medium text-emphasis"
        @click="$emit('back')"
      >
        Back
      </a>
      <a
        class="button orange-fill px-3 py-2.5 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium mr-0"
        data-cy="submit-mapping"
        @click="onSubmit"
      >
        Next
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasHeaderRow: {
      type: Boolean,
      default: false,
    },
    selectedSheetIndex: {
      type: Number,
      required: true,
    },
    sheetNames: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'back',
    'submit',
    'update:has-header-row',
    'update:selected-sheet-index',
    'sheet-switched',
  ],
  data() {
    return {
      sheetIndexValue: this.selectedSheetIndex,
      headerValue: this.hasHeaderRow,
    };
  },
  watch: {
    headerValue(newValue) {
      this.$emit('update:has-header-row', newValue);
    },
    sheetIndexValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('update:selected-sheet-index', newValue);
        this.$emit('update:has-header-row', true);
        this.$emit('sheet-switched');
      }
    },
    hasHeaderRow(newVal) {
      this.headerValue = newVal;
    },
  },
  methods: {
    onSubmit() {
      log('ImportHeaders.onSubmit()');

      this.$emit('submit', {
        data: 'test submit',
      });
    },
  },
};
</script>

<style lang="scss">
#step-headers {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.selection-row.flex {
    display: flex;
    gap: 20px;
}
</style>
