<template>
  <ConfirmModal
    v-if="isEditing"
    :title="modalTitle"
    :description="action === 'create' ?
      'Create a code you can use to embed your Knack app into your website.' : undefined"
    confirm-text="Save"
    :with-content-margin="false"
    @close="handleCloseEditModal"
    @confirm="onSave"
  >
    <FormWrapper
      class="horizontal-labels small"
      :errors="errors"
      :show-submit="false"
      @submit="onSave"
    >
      <div class="is-horizontal block mb-5">
        <label class="block text-left text-default font-medium p-0 mb-2 leading-4">Embed Code Name</label>
        <input
          v-model="embed.name"
          class="m-0"
          type="text"
          data-cy="embed-name"
        >
      </div>
      <div class="is-horizontal block mb-5">
        <label class="block text-left text-default font-medium p-0 mb-2 leading-4">Page to Embed</label>
        <select
          v-model="embed.scene"
          class="m-0 leading-5"
          data-cy="embed-page"
        >
          <option
            v-if="action === 'create'"
            disabled
            hidden
            value=""
          >
            Select a page
          </option>
          <option
            v-for="page in pageOptions"
            :key="page.key"
            :value="page.slug"
            :selected="embed.scene"
          >
            {{ page.name }}
          </option>
        </select>
      </div>
      <div class="is-horizontal block mb-5">
        <label class="block text-left text-default font-medium p-0 mb-2 leading-4">Font Options</label>
        <div class="input-labels embed-design--input-labels m-0">
          <label class="input-label--with-help-icon items-center mb-0">
            <input
              v-model="embed.design.general.fonts.show"
              type="checkbox"
              data-cy="embed-display-app-fonts"
            >
            <span>
              Include
              <router-link
                class="underline text-default font-semibold"
                to="/settings/design/theme"
              >
                Knack font settings
              </router-link>
            </span>
          </label>
        </div>
        <p class="text-xs text-default m-0 mt-2">
          {{ fontOptionsHelpText }}
        </p>
      </div>
      <div class="is-horizontal block">
        <label class="block text-left text-default font-medium p-0 mb-2 leading-4">Header & Page Menu</label>
        <div class="input-labels input-labels--centered m-0">
          <Radios
            v-model="embed.design.header.display"
            :options="embedHeaderDesignOptions"
            text-classes="text-emphasis text-base font-normal"
            data-cy="embed-header-design-options"
          />
        </div>
      </div>
    </FormWrapper>
  </ConfirmModal>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import { embedDesignOptionsCollection } from '@knack/style-engine';
import FormWrapper from '@/components/ui/FormWrapper';
import ConfirmModal from '@/components/ui/ConfirmModal';
import HelpIcon from '@/components/ui/HelpIcon';
import RequestUtils from '@/components/util/RequestUtils';
import Embed from '@/store/models/Embed';
import { logEvent } from '@/lib/segment-helper';
import Radios from '@/components/renderer/form/inputs/Radios';

export default {
  name: 'EmbedEdit',
  components: {
    FormWrapper,
    ConfirmModal,
    Radios,
    HelpIcon,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    action: {
      type: String,
      default: 'create', // create | update
    },
  },
  data() {
    const FONT_OPTIONS_HELP_TEXT = 'If Knack font settings are not included, the app will use the font settings from the embedded website.';

    return {
      initialEmbed: {},
      isEditing: true,
      fontOptionsHelpText: FONT_OPTIONS_HELP_TEXT,
    };
  },
  computed: {
    ...mapGetters([
      'activePage',
      'app',
    ]),
    ...mapGetters('page', [
      'startPages',
    ]),
    embed() {
      if (this.action === 'create') {
        let embedName = '';
        let embedScene = '';

        if (this.isFromPageSettings && this.activePage) {
          embedName = `${this.activePage.name} Embed`;
          embedScene = this.activePage.slug;
        }

        const design = {
          header: {
            display: 'menu', // menu | menuWithBackgroundColor | fullHeader
          },
          general: {
            fonts: {
              show: false,
            },
          },
        };

        return new Embed({
          key: 'new_embed',
          name: embedName,
          scene: embedScene,
          code: '',
          design,
        });
      }

      return cloneDeep(this.app.distributions.find((embed) => embed.key === this.$route.params.distKey));
    },
    pages() {
      return this.startPages;
    },
    modalTitle() {
      return (this.action === 'create') ? 'Add an Embed Code' : 'Edit Embed Code';
    },
    pageOptions() {
      if (this.isFromPageSettings) {
        const { pageKey } = this.$route.params;

        const pages = this.pages.filter((page) => page.key === pageKey);

        if (!isEmpty(pages)) {
          return pages;
        }

        const page = this.findValidStartPageFromKey(pageKey);

        if (page) {
          return [page];
        }
      }

      return this.pages;
    },
    backLink() {
      if (this.isFromPageSettings) {
        return `/pages/${this.$route.params.pageKey}`;
      }

      return '/settings/embeds';
    },
    isFromPageSettings() {
      return hasIn(this.$route.params, 'pageKey');
    },
    embedHeaderDesignOptions() {
      return embedDesignOptionsCollection.headerOptions;
    },
  },
  created() {
    // make copy of embed properties to be used in case of a cancelled update
    this.initialEmbed = cloneDeep(this.embed);
  },
  methods: {
    findValidStartPageFromKey(pageKey) {
      const rawPage = this.$store.getters['page/getPageByKey'](pageKey);

      if (!rawPage) {
        return;
      }

      return rawPage.getFirstChildStartPage();
    },
    handleCloseEditModal() {
      // reset embed state if update is aborted
      this.embed.name = this.initialEmbed.name;
      this.embed.scene = this.initialEmbed.scene;

      // close modal and route back to main embeds page
      this.isEditing = false;
      this.$router.push(this.backLink);
    },
    onSave() {
      if (this.action === 'create') {
        return this.createEmbed();
      }

      return this.updateEmbed();
    },
    createEmbed() {
      this.commitRequest({
        validate: () => this.embed.validate(),
        request: () => this.embed.create(),
        onSuccess: () => {
          this.$router.push('/settings/embeds');

          // trigger click on the last (newest) embed element to open the embed code modal
          this.$nextTick(() => document.querySelector('#embeds-list .list-embed:last-child .embed-code a').click());
        },
      });
    },
    updateEmbed() {
      this.commitRequest({
        validate: () => this.embed.validate(),
        request: () => this.embed.update(),
        onSuccess: () => {
          logEvent('embed_app', {
            embed_name: this.embed.name,
          });

          // update local embed state
          this.initialEmbed.name = this.embed.name;
          this.initialEmbed.scene = this.embed.scene;

          // close modal and route back to main embeds page
          this.isEditing = false;
          this.$router.push(this.backLink);
        },
      });
    },
  },
};
</script>

<style lang="scss">
.embed-design--input-labels {
  display: flex;

  .input-label--with-help-icon {
    margin-right: 0px;
  }

  input {
    margin-top: 0.5em;
  }
}
</style>
