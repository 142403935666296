<template>
  <div class="record-action">
    <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Values</label>
    <ValueList
      :excluded-object-field-keys="excludedObjectFieldKeys"
      :object="localValuesObject"
      :connection-options="objectConnections"
      :input-field-options="fieldOptions"
      :values="item.values"
      :allow-connection-option="allowConnectionOption"
      :allow-location-option="allowLocationOption"
      :show-value-type-options="true"
      :show-field-options="showFieldOptions"
      :field="field"
      class="naked record-action-values bg-transparent p-0"
      @update:values="onUpdateItemValues"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import ValueList from '@/components/ui/lists/ValueList';

export default {
  name: 'RecordAction',
  components: {
    ValueList,
  },
  props: {
    ruleAction: {
      type: String,
      default: 'update',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    object: {
      type: Object,
      default: () => ({}),
    },
    valuesObject: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    inputFieldOptions: {
      type: Array,
      default: () => [],
    },
    allowConnectionOption: {
      type: Boolean,
      default: true,
    },
    allowLocationOption: {
      type: Boolean,
      default: true,
    },
    showFieldOptions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    localValuesObject() {
      return !isEmpty(this.valuesObject) ? this.valuesObject : this.object;
    },
    excludedObjectFieldKeys() {
      const excludedObjectFieldKeys = [];

      // Actions inserting a new connected record should exclude the connection field from the list you can set values for
      // The rule itself will automatically set this value
      if (this.item.action === 'insert' && this.item.connection) {
        // Get what we need from the connection key (objectKey.fieldKey)
        const [
          connectedObjectKey,
          connectedFieldKey,
        ] = this.item.connection.split('.');

        // If the connection field is on this object, exclude it
        if (this.localValuesObject.key === connectedObjectKey) {
          excludedObjectFieldKeys.push(connectedFieldKey);
        }
      }

      return excludedObjectFieldKeys;
    },
    objectConnections() {
      const conns = [];

      this.object.conns.forEach((conn) => {
        const connectionObject = this.$store.getters.getObject(conn.object);

        // you can't insert ecommerce
        if (!connectionObject || connectionObject.get('ecommerce')) {
          return;
        }

        conns.push({
          key: conn.key,
          value: `${conn.object}.${conn.key}`,
          label: `${connectionObject.inflections.singular} connected to this record (${connectionObject.inflections.singular} > ${conn.name})`,
          object: conn.object,
          name: conn.name,
          has: conn.has,
        });
      });

      return conns;
    },
    hasConnections() {
      return this.objectConnections.length > 0;
    },
    fieldOptions() {
      // Inserting or updating a connected record should always use the source object for field values
      if (this.item.action === 'connection' || this.item.action === 'insert') {
        return this.object.getFieldOptions();
      }

      if (this.inputFieldOptions.length) {
        return this.inputFieldOptions;
      }

      return this.valuesObject.getFieldOptions();
    },
  },
  methods: {
    getConnectionActions(action) {
      action = action || 'connection'; // connection | insert

      const options = [];

      this.objectConnections.forEach((conn) => {
        let label;

        if (action === 'insert') {
          label = `A new ${conn.label}`;
        } else {
          label = `${(conn.has === 'one') ? 'The' : 'Each'} ${conn.label}`;
        }

        options.push({
          value: conn.value,
          label,
        });
      });

      return options;
    },
    onAddListItem(event) {
      this.rules.push({
        action: 'record',
        connection: (this.objectConnections.length > 0) ? this.objectConnections[0].value : null,
        criteria: [],
        values: [
          {
            field: '',
            type: 'value',
            input: '',
            connection_field: '',
            value: '',
          },
        ],
      });
    },
    onUpdateItemValues(newValues) {
      // TODO: Change this to emit an event instead of mutating.
      this.item.values = newValues;
    },
  },
};
</script>

<style lang="scss">
.form-emails textarea {
  min-height: 200px !important
}
</style>
