<template>
  <HelpForm
    class="help-form-get-help"
    store-namespace="api/help/getHelp"
    @submit="submitForm"
    @clear="clearForm"
    @dismissErrors="dismissErrors"
    @dismissSuccess="dismissSuccess"
  >
    <div class="margin-bottom-md mb-4">
      <label class="block text-default font-medium mb-2 leading-4 tracking-[0.14px]">
        What is your question about?
      </label>
      <div class="input-labels tw-input-labels">
        <label>
          <input
            v-model="helpSubject"
            type="radio"
            value="my-account"
          >
          My account plan and billing
        </label>
        <label>
          <input
            v-model="helpSubject"
            type="radio"
            value="my-app"
          >
          My use case and building my app
        </label>
        <label>
          <input
            v-model="helpSubject"
            type="radio"
            value="how-to-knack"
          >
          How to use Knack features
        </label>
      </div>
    </div>
    <div class="margin-bottom-md mb-4">
      <label
        for="help-question"
        class="text-default font-medium mb-2 leading-4 tracking-[0.14px]"
      >
        How can we help?
      </label>

      <p class="help-description margin-0 text-[#7B7079] leading-4 tracking-[0.14px] mb-2">
        Do not include sensitive data such as login information via this form.
      </p>

      <textarea
        id="help-question"
        v-model="helpQuestion"
      />
    </div>
  </HelpForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HelpForm from '@/components/builder/help/forms/HelpForm';
import { mapApiFormGetters } from '@/lib/vuex-helper';

export default {
  name: 'HelpFormGetHelp',
  components: {
    HelpForm,
  },
  emits: ['navigateToHome'],
  computed: {
    ...mapGetters('api/help/getHelp', [
      'validation',
      'isInvalid',
      'isSuccess',
    ]),
    ...mapApiFormGetters('api/help/getHelp', [
      'helpSubject',
      'helpQuestion',
    ]),
  },
  methods: {
    ...mapActions('api/help/getHelp', {
      getHelpUpdateForm: 'updateForm',
      getHelpReset: 'reset',
      getHelpResetForm: 'resetForm',
      getHelpSubmitForm: 'submitForm',
    }),
    submitForm() {
      this.getHelpSubmitForm().then(() => {
        if (this.isSuccess) {
          this.clearForm();
        }
      });
    },
    clearForm() {
      this.getHelpResetForm();
    },
    dismissErrors() {
      this.getHelpReset();
    },
    dismissSuccess() {
      this.getHelpResetForm();
      this.getHelpReset();

      this.$emit('navigateToHome');
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  display: block;
}
</style>
