<template>
  <div class="kn-checkbox">
    <div
      v-for="option in options"
      :key="option.label"
      class="control kn-inputWrapper"
    >
      <label class="option checkbox">
        <input
          v-if="option.value !== 'kn-blank'"
          v-model="localValue"
          :name="option.label"
          type="checkbox"
          class="checkbox"
          :value="option.value"
        >
        {{ option.label }}
      </label>
    </div>
  </div>
</template>
<script>
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

const BLANK = 'kn-blank';

export default {
  name: 'MultipleChoiceCheckboxes',
  props: {
    modelValue: {
      type: [
        String,
        Array,
      ],
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        if (isArray(this.modelValue)) {
          if (this.modelValue.length === 1 && this.modelValue[0] === BLANK) {
            return [];
          }

          return this.modelValue;
        }

        // Value must be a string since it isn't an array.
        if (this.modelValue === BLANK) {
          return [];
        }

        return this.modelValue.split(', ');
      },

      set(newValue) {
        // in some instances the first array index may be empty so remove it
        // otherwise if the field is required it won't pass validation
        if (isEmpty(newValue[0])) {
          newValue.shift();
        }

        // if the value array is empty we want to return an empty string not an empty array
        if (isEmpty(newValue)) {
          newValue = '';
        }

        this.$emit('update:modelValue', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.option {
  display: flex;
  align-items: center;
}

label.option.checkbox {
  font-weight: 400;
  font-style: normal;
}
</style>
