<template>
  <view-toolbox
    back-title="Page"
    title="Add a View"
    theme="add"
    :on-submit="addView"
  >
    <NavigationGuardConfirm
      v-if="navigationGuard.showConfirm"
      confirm-text="Yes, save & continue"
      cancel-text="No, continue without saving"
      :path="navigationGuard.path"
      @save="onCancelNavigationGuardExit(true)"
      @cancel="onConfirmNavigationGuardExit"
      @close="onCancelNavigationGuardExit(false)"
    >
      <p class="text-left">
        Your changes will be lost if you leave this page.
      </p>
    </NavigationGuardConfirm>

    <div
      ref="addview"
      class="section-wrapper pb-4"
    >
      <!-- Select the view type -->
      <WizardStep
        v-if="showWizardStep('type')"
        id="add-view-type"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('type')"
        class="first"
      >
        <template #edit>
          <div :class="wizardEditTextClasses">
            Add a <strong class="capitalize">{{ viewTypeName }}</strong>
          </div>
        </template>
        <div id="add-view-type-links">
          <ViewAddTypes
            :mode="(viewIsCreated) ? 'edit' : 'insert'"
            @submit="onSelectType"
          />
        </div>
      </WizardStep>

      <!-- Select the object source -->
      <WizardStep
        v-if="showWizardStep('source')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('source')"
      >
        <template #edit>
          <div
            :class="wizardEditTextClasses"
            v-html="viewAction"
          />
        </template>
        <template #intro>
          <div
            class="mb-2 font-medium leading-5 text-sm tracking-[.14px]"
            v-html="sourceIntro"
          />
        </template>
        <ViewAddSourceObject
          :page="activePage"
          :records-quantity="recordsQuantity"
          :view-type="primaryView.type"
          @submit="onSelectSource"
        />
      </WizardStep>

      <!-- Select the object path -->
      <WizardStep
        v-if="showWizardStep('path')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('path')"
      >
        <template #edit>
          <div
            :class="wizardEditTextClasses"
            v-html="sourcePathDescription"
          />
        </template>
        <template #intro>
          <div
            class="mb-2"
            v-html="pathIntro"
          />
        </template>
        <ViewAddSourcePath
          :page="activePage"
          :source="source"
          :paths="sourcePaths"
          :path-defaults="pathSelections"
          :records-quantity="recordsQuantity"
          :view-type="primaryView.type"
          @submit="onSelectSourcePath"
        />
      </WizardStep>

      <!-- Add potential links to the view to new child pages or other pages-->
      <WizardStep
        v-if="showWizardStep('links')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('links')"
      >
        <template #edit>
          <div
            :class="wizardEditTextClasses"
            v-html="childPageLinksSummary"
          />
        </template>
        <template #intro>
          <span class="mb-2">Add additional <strong>links</strong> to:</span>
        </template>
        <ViewAddChildPageLinks
          v-model:options="localView"
          :page="activePage"
          @submit="onAddChildPageLinks"
        />
      </WizardStep>

      <!-- Confirm specific view fields -->
      <WizardStep
        v-if="showWizardStep('confirmField')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('confirmField')"
      >
        <template #intro>
          <strong class="mb-2">Confirm {{ viewTypeName }} fields</strong>
        </template>
        <p
          v-if="viewType === `calendar`"
          class="margin-bottom-half"
        >
          Which <strong>date field</strong> should be used for calendar events?
        </p>
        <p
          v-else
          class="margin-bottom-half"
        >
          Which <strong>address field</strong> should be used for map locations?
        </p>
        <div class="margin-bottom">
          <select v-model="viewFieldKey">
            <option
              v-for="field in viewFields"
              :key="field.key"
              :value="field.key"
            >
              {{ field.name }}
            </option>
          </select>
        </div>
        <div class="flex justify-end">
          <a
            class="button green-fill capitalize p-3 rounded-lg bg-gradient-primary border-0 text-base font-medium m-0 leading-4"
            data-cy="save-view-add"
            @click="addView"
          >
            Add {{ viewTypeName }}
          </a>
        </div>
      </WizardStep>

      <!-- Confirm/Add view -->
      <WizardStep
        v-if="showWizardStep('confirm')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('confirm')"
      >
        <div
          style="font-size: .875em;"
          class="small-inputs margin-bottom-double"
        >
          <div>
            <label class="tw-toolbox-label">View Name</label>
            <input
              ref="viewName"
              v-model="viewName"
              v-tippy="{
                hideOnClick: false,
                interactive: true,
                showOnCreate: !viewName,
                sticky: true,
                trigger: 'manual',
              }"
              content="A view name is required."
              type="text"
              class="rounded-lg h-10 text-base py-2 px-3 text-emphasis"
              style="font-size: 1em; width: 100%;"
            >
          </div>
          <div class="margin-top mt-4">
            <label class="items-baseline">
              <input
                v-model="linkViewFromMenu"
                type="checkbox"
              >
              <strong class="text-base text-emphasis font-normal">Link to this <span class="capitalize">{{ viewTypeName }}</span> from a Menu</strong>
            </label>
            <div
              v-if="linkViewFromMenu"
              class="childInputGroup padding-top ml-0 p-0 border-l-0 mt-4"
            >
              <div
                v-if="menuCount > 0"
                class="inlineDropdown margin-bottom mt-4 mb-0"
              >
                <label class="tw-toolbox-label">Add the Link to</label>
                <Dropdown
                  v-model="menuKeyToAddLinkTo"
                  data-cy="source-picker"
                  :options="addToMenuOptions"
                  class="border-solid"
                  placement="bottom-end"
                  @change="onChangeLinkViewFromMenu"
                  @update="onChangeLinkViewFromMenu"
                />
              </div>
              <div class="mt-4">
                <label class="tw-toolbox-label">
                  Link Text
                </label>
                <input
                  v-model="menuLinkText"
                  v-focus
                  type="text"
                  class="rounded-lg py-2 pl-3 text-base text-emphasis"
                  style="font-size: 1em; width: 100%;"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <button
            type="button"
            :disabled="viewName.length === 0"
            data-cy="save-view-add"
            data-feature="save_view_button"
            data-feature-x-offset="-26"
            data-feature-y-offset="-26"
            :class="{disabled: viewName.length === 0}"
            class="button orange-fill large capitalize p-3 rounded-lg bg-gradient-primary border-0 text-base font-medium m-0 leading-4"
            @click="addView"
          >
            Add {{ viewTypeName }}
          </button>
        </div>
      </WizardStep>

      <ViewAddPaymentTotalField
        v-if="showWizardStep('paymentTotalField')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('paymentTotalField')"
        :view="localView"
        @next="nextWizardStep"
      />

      <ViewAddPaymentProcessors
        v-if="showWizardStep('paymentProcessor')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('paymentProcessor')"
        :view="localView"
        @next="nextWizardStep"
      />

      <ViewAddPaymentMethod
        v-if="showWizardStep('paymentMethod')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('paymentMethod')"
        :page="activePage"
        :view="localView"
        @next="nextWizardStep"
      />

      <ViewAddPaymentConfirm
        v-if="showWizardStep('paymentConfirm')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('paymentConfirm')"
        :view="localView"
        @submit="addView"
      />
    </div>
  </view-toolbox>
</template>

<script>
import { mapGetters } from 'vuex';
import Dropdown from '@/components/ui/Dropdown';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewAddTypes from '@/components/pages/page/ViewAddTypes';
import ViewAddChildPageLinks from '@/components/pages/page/ViewAddChildPageLinks';
import ViewAddPaymentTotalField from '@/components/pages/page/ViewAddPaymentTotalField';
import ViewAddPaymentProcessors from '@/components/pages/page/ViewAddPaymentProcessors';
import ViewAddPaymentMethod from '@/components/pages/page/ViewAddPaymentMethod';
import ViewAddPaymentConfirm from '@/components/pages/page/ViewAddPaymentConfirm';
import ViewAddSourceObject from '@/components/pages/page/ViewAddSourceObject';
import ViewAddSourcePath from '@/components/pages/page/ViewAddSourcePath';
import WizardStep from '@/components/ui/WizardSection';
import NavigationGuardConfirm from '@/components/ui/NavigationGuardConfirm';
import PageAddUtils from '@/components/pages/PageAddUtils.js';
import RequestUtils from '@/components/util/RequestUtils';
import View from '@/store/models/View';
import { NEW_VIEW_KEY } from '@/lib/page/page-constants';
import { logEvent } from '@/lib/segment-helper';

export default {
  name: 'ViewAdd',
  components: {
    Dropdown,
    NavigationGuardConfirm,
    ViewToolbox,
    ViewAddTypes,
    ViewAddChildPageLinks,
    ViewAddPaymentTotalField,
    ViewAddPaymentProcessors,
    ViewAddPaymentMethod,
    ViewAddPaymentConfirm,
    ViewAddSourceObject,
    ViewAddSourcePath,
    WizardStep,
  },
  mixins: [
    PageAddUtils,
    RequestUtils,
  ],
  data() {
    return {
      navigationGuard: {
        showConfirm: false,
        path: '',
      },
      wizardStep: 1,
      wizardStepMax: 1,
      wizardSteps: {
        recordsLinks: [
          'type',
          'source',
          'path',
          'links',
          'confirm',
        ],
        confirmFields: [
          'type',
          'source',
          'path',
          'links',
          'confirmField',
          'confirm',
        ],
        recordsOnly: [
          'type',
          'source',
          'path',
          'confirm',
        ],
        form: [
          'type',
          'source',
          'path',
          'confirm',
        ],
        report: [
          'type',
          'source',
          'path',
          'confirm',
        ],
        static: [
          'type',
          'confirm',
        ],
        checkout: [
          'type',
          'source',
          'path',
          'paymentTotalField',
          'paymentProcessor',
          'paymentMethod',
          'paymentConfirm',
        ],
        customer: [
          'type',
          'paymentProcessor',
          'paymentMethod',
          'paymentConfirm',
        ],
      },
      recordsQuantity: 'single',
      reportType: 'pivot',
      view: {},
      source: {},
      sourceDescription: '',
      sourcePathDescription: '',
      pathSelections: {}, // store the path selections to use as defaults when editing the path
      childPageLinkOptions: {},
      updatePageLayout: false, // if a column split was triggered on drag, we'll need to save the new page layout before we save the view
      linkViewFromMenu: false, // checkbox at end to optionally add a menu linking to this view instead
      menuKeyToAddLinkTo: NEW_VIEW_KEY, // new | view key
      existingMenuOptions: [],
      viewTheMenuIsLinkingTo: {},
      addLocation: {
        addLocationType: 'group',
        groupIndex: 0,
        columnIndex: 0,
        itemIndex: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      'activePage',
      'getObject',
      'isBuilderAuthenticated',
    ]),
    // We need a variable to store the view that was built regardless if the preview is displaying it linked from a menu or not.
    primaryView: {
      get() {
        if (this.linkViewFromMenu) {
          return this.viewTheMenuIsLinkingTo;
        }

        return this.view;
      },
      set(newValue) {
        if (this.linkViewFromMenu) {
          this.viewTheMenuIsLinkingTo = newValue;

          return;
        }

        this.view = newValue;
      },
    },
    menuLinkText: {
      get() {
        const menuView = this.page.getView(this.menuKeyToAddLinkTo);

        if (!menuView?.links) {
          return '';
        }

        return menuView.links[menuView.links.length - 1].name;
      },
      set(newValue) {
        log('set', this.view);

        const menuView = this.page.getView(this.menuKeyToAddLinkTo);

        menuView.links[menuView.links.length - 1].name = newValue;
      },
    },
    addToMenuOptions() {
      return [
        {
          value: 'new',
          label: 'a new menu',
        },
        {
          type: 'section',
          label: 'an existing menu',
        },
        ...this.existingMenuOptions,
      ];
    },
    viewName: {
      get() {
        return this.primaryView.name;
      },
      set(newValue) {
        this.primaryView.name = newValue;
      },
    },
    // the number of menu views this page has
    menuCount() {
      return this.existingMenuOptions.length;
    },
    localView: {
      get() {
        return this.primaryView;
      },
      set(newValue) {
        this.primaryView = newValue;
      },
    },
    page() {
      return this.$store.getters.activePage;
    },
    wizardType() {
      if (!this.view || !this.view.type) {
        return 'recordsLinks';
      }

      // If you check the box to link with a menu, use the original view
      const viewType = this.primaryView.type;

      if (viewType === 'form') {
        return 'form';
      }

      if (viewType === 'report') {
        return 'report';
      }

      if (viewType === 'checkout' || this.view.type === 'charge') {
        return 'checkout';
      }

      if (viewType === 'customer') {
        return 'customer';
      }

      if (this.primaryView.isStatic()) {
        return 'static';
      }

      const confirmFields = [
        'calendar',
        'map',
      ];

      if (confirmFields.includes(this.view.type)) {
        // check the number of fields
        if (this.viewFields.length > 1) {
          return 'confirmFields';
        }
      }

      const recordLinks = [
        'table',
        'list',
        'search',
        'calendar',
        'map',
      ];

      if (recordLinks.includes(viewType)) {
        return 'recordsLinks';
      }

      // details, reports, payments
      return 'recordsOnly';
    },
    viewIsCreated() {
      return this.view && this.view.key;
    },
    viewType() {
      if (this.primaryView.isCheckoutType()) {
        return 'checkout';
      }

      return this.primaryView.type;
    },
    viewTypeName() {
      switch (this.primaryView.type) {
        case 'rich_text':
          return 'rich text';

        case 'checkout':
          return 'payment view';

        case 'customer':
          return 'payment method view';

        case 'table':
          return 'grid';

        default:
          return this.primaryView.type;
      }
    },
    viewAction() {
      if (this.primaryView.type === 'form') {
        const formAction = (this.primaryView.get('action') === 'create') ? 'inserts a new' : 'updates';

        return `that ${formAction} <strong>${this.sourceDescription}</strong>`;
      }

      return `that displays ${this.recordsQuantity === 'one' ? 'the ' : ''}<strong>${this.sourceDescription}</strong>`;
    },
    sourceIntro() {
      if (this.primaryView.type === 'form') {
        return 'Select the Table for Form Submissions';
      }

      return `Which <strong>records</strong> will this ${this.viewTypeName} display?`;
    },
    pathIntro() {
      return `Which ${this.sourceDescription}?`;
    },
    childPageLinksSummary() {
      if (this.childPageLinkOptions.multiForm && this.childPageLinkOptions.multiDetails) {
        return 'and includes links to <strong>multiple child pages</strong>';
      }

      if (this.childPageLinkOptions.multiForm) {
        return 'and includes a <strong>form link</strong>';
      }

      if (this.childPageLinkOptions.multiDetails) {
        return 'and includes a <strong>details link</strong>';
      }

      return 'with no links to child pages';
    },
    viewFields() {
      if (!this.source.object) {
        return [];
      }

      const sourceObject = this.getObject(this.source.object);

      if (this.viewType === 'calendar') {
        return sourceObject.getFieldsThatStoreDates();
      }

      if (this.viewType === 'map') {
        return sourceObject.getAddressFields();
      }

      return [];
    },
    viewFieldKey: {
      get() {
        if (this.viewType === 'calendar') {
          return this.primaryView.get('events').event_field.key;
        }

        if (this.viewType === 'map') {
          return this.primaryView.get('address_field').key;
        }

        return '';
      },
      set(newVal) {
        if (this.viewType === 'map') {
          this.primaryView.get('address_field').key = newVal;
        }

        if (this.viewType === 'calendar') {
          this.primaryView.get('events').event_field.key = newVal;
        }
      },
    },
    wizardEditTextClasses() {
      return 'mb-4 text-base font-semibold tracking-[.32px]';
    },
  },
  beforeRouteLeave(to) {
    if (to.path.includes('/views') || !this.isBuilderAuthenticated) {
      return true;
    }

    if (this.$store.getters.activeView) {
      // store to for later
      this.navigationGuard.path = to.path;

      // show modal
      this.navigationGuard.showConfirm = true;

      return false;
    }

    return true;
  },
  watch: {
    async wizardStep(newVal, oldVal) {
      log('watch wizardStep', newVal, oldVal, this.wizardSteps[this.wizardType][newVal], this.wizardSteps[this.wizardType]);

      // Reset menu for any previous wizard steps
      if (this.linkViewFromMenu) {
        // first ensure the menu is new so it can be replaced
        if (this.menuKeyToAddLinkTo !== NEW_VIEW_KEY) {
          log('updateTheMenu!!!');

          await this.updateMenuThatViewIsLinkingTo(NEW_VIEW_KEY, this.menuKeyToAddLinkTo);
        }

        this.linkViewFromMenu = false;
      }

      // ensures reactivity for resetting the view from any menu resets
      await this.$nextTick();

      // If we're returning to source selection, clear out the view so it's clear a new selection needs to be made to progress
      if (oldVal && newVal < oldVal) {
        if (this.wizardSteps[this.wizardType][newVal - 1] === 'source') {
          this.resetViewSourceToNull();
        }
      }
    },
    async linkViewFromMenu(newVal) {
      log('watch linkViewFromMenu()', newVal);

      // Link the new view to a new menu
      if (newVal) {
        // Copy old view for future reference
        this.viewTheMenuIsLinkingTo = new View(this.view.raw(), this.page);

        // Create the new menu to replace the active view with
        const newMenuView = this.createNewMenuToLinkViewTo();

        // Add the link to the view being added
        this.addMenuLinkToNewView(newMenuView);

        // Replace the new view
        this.view = await this.$store.dispatch('page/view/replaceViewLocally', {
          viewData: newMenuView.raw(),
        });
      }

      // Restore our new view to the preview and remove the menu link
      if (!newVal) {
        // Remove the link from that existing menu
        this.page.getView(this.menuKeyToAddLinkTo).links.pop();

        // Use the old view this menu was linking to
        const newView = this.viewTheMenuIsLinkingTo;

        // Make sure the key is set to new
        newView.key = NEW_VIEW_KEY;

        // If this was linking to an existing menu we need to add the new view since there's no local view to replace
        if (this.menuKeyToAddLinkTo !== NEW_VIEW_KEY) {
          // Clear the active menu from linking to the new view
          this.$store.commit('menuKeyForAddingNewView', null);

          log('ADDING NEW VIEW!!!! !!!! !!', newView);

          // Add the new view to the page
          await this.addNewViewToPage(newView.raw());

          // reset the menu key
          this.menuKeyToAddLinkTo = NEW_VIEW_KEY;

          return;
        }

        // Otherwise replace the new view
        this.view = await this.$store.dispatch('page/view/replaceViewLocally', {
          viewData: newView.raw(),
        });
      }
    },
    viewName(newVal) {
      const tippy = this.$refs.viewName?._tippy; // eslint-disable-line no-underscore-dangle
      if (!tippy) {
        return;
      }

      if (!newVal) {
        tippy.show();
      } else {
        tippy.hide();
      }
    },
  },
  created() {
    if (!this.page) {
      return;
    }

    log('created!', this.page.getMenuViews());

    // populate our menus
    this.existingMenuOptions = this.page.getMenuViews().map((view, viewIndex) => {
      const viewName = (view.name === 'Menu') ? `Menu ${viewIndex + 1}` : view.name;

      return {
        value: view.key,
        label: viewName,
      };
    });
  },
  methods: {
    async onChangeLinkViewFromMenu(newValue, newOption, oldValue) {
      log('onChangeLinkViewFromMenu !!!!', newValue, newOption, oldValue);

      await this.updateMenuThatViewIsLinkingTo(newValue, oldValue);
    },
    async addNewViewToPage(viewVars) {
      const {
        addLocationType: locationType, groupIndex, columnIndex, itemIndex,
      } = this.addLocation;

      this.view = await this.$store.dispatch('page/view/addViewLocally', {
        viewData: viewVars,
        locationType,
        groupIndex,
        columnIndex,
        itemIndex,
      });

      // Otherwise replace the new view

      this.$store.commit('activeView', this.view);
    },
    async updateMenuThatViewIsLinkingTo(newMenuKey, oldMenuKey) {
      this.menuKeyToAddLinkTo = newMenuKey;

      // Pop the link from the old menu
      const menuLink = this.page.getView(oldMenuKey).links.pop();

      if (newMenuKey !== NEW_VIEW_KEY) {
        // Set the active menu as being used for adding a view
        this.$store.commit('menuKeyForAddingNewView', newMenuKey);

        // Set the active view to the menu getting the new link
        this.view = this.page.getView(newMenuKey);

        // Delete the old menu that was added as new
        if (oldMenuKey === NEW_VIEW_KEY) {
          this.$store.dispatch('page/view/deleteViewLocally', {
            viewKey: NEW_VIEW_KEY,
          });
        }

        // Set the active view to the selected menu
        this.$store.commit('activeView', this.view);
      }

      if (newMenuKey === NEW_VIEW_KEY) {
        // Double check the old view isn't new
        if (this.view.key === NEW_VIEW_KEY) {
          return;
        }

        // Clear the active view and menu as being used for adding a view
        this.$store.commit('activeView', false);
        this.$store.commit('menuKeyForAddingNewView', null);

        // Add a new menu
        const menuView = this.createNewMenuToLinkViewTo();

        // Add the new menu to the page
        await this.addNewViewToPage(menuView.raw());

        log('We just added a view!', this.view);
      }

      // Add to the selected menu
      this.view.links.push(menuLink);
    },
    createNewMenuToLinkViewTo() {
      const viewVars = {
        key: NEW_VIEW_KEY,
        label: 'Menu',
        name: 'Menu',
        type: 'menu',
      };

      return new View(this.buildViewSchema(this.page.raw, viewVars), this.page);
    },
    addMenuLinkToNewView(menuView) {
      const newView = this.viewTheMenuIsLinkingTo.raw();

      // Server doesn't want a key=`new`
      delete newView.key;

      // Create the child page to link to
      const newPage = {
        name: this.viewTheMenuIsLinkingTo.name,
        object: this.page.object,
        parent: this.page.slug,
        views: [
          newView,
        ],
      };

      // Add the menu link to this new page
      menuView.links = [
        ...menuView.links,
        {
          name: this.viewTheMenuIsLinkingTo.name,
          type: 'scene',
          scene: newPage,
        },
      ];
    },
    resetViewSourceToNull() {
      // selectedViewType: used for distinguishing
      const viewVars = {
        type: this.view.type,
        reportType: this.reportType,
        key: NEW_VIEW_KEY,
        title: '',
        source: {},
      };

      this.$store.dispatch('page/view/replaceViewLocally', {
        viewData: viewVars,
      });
    },
    async onConfirmNavigationGuardExit() {
      this.navigationGuard.showConfirm = false;
      // Clear the active view and menu as being used for adding a view
      this.$store.commit('activeView', false);
      this.$store.commit('menuKeyForAddingNewView', null);

      await this.$store.dispatch('page/view/deleteViewLocally', {
        viewKey: NEW_VIEW_KEY,
      });
    },
    onCancelNavigationGuardExit(shouldSave) {
      this.navigationGuard.showConfirm = false;
      if (shouldSave) {
        this.addView();
      }
    },
    getWizardStep(whichStep) {
      return this.wizardSteps[this.wizardType].indexOf(whichStep) + 1;
    },
    showWizardStep(whichStep) {
      return (this.wizardSteps[this.wizardType].indexOf(whichStep) > -1 && this.getWizardStep(whichStep) <= this.wizardStep);
    },
    nextWizardStep() {
      this.wizardStep++;

      log('+ + = = + = nextWizardStep(), now:', this.wizardStep);

      if (this.wizardStep > this.wizardStepMax) {
        this.wizardStepMax = this.wizardStep;
      }
    },
    async addStaticView(type, addLocationType, groupIndex, columnIndex, itemIndex) {
      this.addLocation = {
        addLocationType,
        groupIndex,
        columnIndex,
        itemIndex,
      };

      const staticViewType = {
        rich_text: {
          key: NEW_VIEW_KEY,
          type: 'rich_text',
          name: 'Rich Text',
          content: '<p>New Rich Text\n</p>',
        },
        menu: {
          key: NEW_VIEW_KEY,
          label: 'Menu',
          name: 'Menu',
          type: 'menu',
        },
        image: {
          key: NEW_VIEW_KEY,
          label: 'Image',
          name: 'Image',
          type: 'image',
        },
        divider: {
          key: NEW_VIEW_KEY,
          label: 'Divider',
          name: 'Divider',
          type: 'divider',
        },
      };

      this.view = await this.$store.dispatch('page/view/addViewLocally', {
        viewData: staticViewType[type],
        locationType: addLocationType,
        groupIndex,
        columnIndex,
        itemIndex,
      });
    },
    async onSelectType({
      viewType, reportType, recordsQuantity, addLocationType, groupIndex = 0, columnIndex = 0, itemIndex = 0,
    }) {
      this.addLocation = {
        addLocationType,
        groupIndex,
        columnIndex,
        itemIndex,
      };

      this.recordsQuantity = recordsQuantity;

      // if column split then we need to recast as a new column save the split page layout
      if (addLocationType === 'column-split') {
        addLocationType = 'column';

        this.updatePageLayout = true;
      }

      // End wizard if this is a static view type. We'll immediately add the view
      const staticViewTypes = [
        'rich_text',
        'menu',
        'image',
        'divider',
      ];

      if (staticViewTypes.includes(viewType)) {
        await this.addStaticView(viewType, addLocationType, groupIndex, columnIndex, itemIndex);

        await this.addView();

        return;
      }

      // selectedViewType: used for distinguishing
      let viewVars = {
        type: viewType,
        reportType,
        key: NEW_VIEW_KEY,
        title: '',
        source: {},
      };

      this.reportType = reportType;

      // customer view has no source step which is where this normally happens, should this go somewhere else?
      if (viewType === 'customer') {
        viewVars = this.buildViewSchema(this.page.raw, viewVars, {});
      }

      if (this.viewIsCreated) {
        await this.$store.dispatch('page/view/replaceViewLocally', {
          viewData: viewVars,
        });
      } else {
        await this.$store.dispatch('page/view/addViewLocally', {
          viewData: viewVars,
          locationType: addLocationType,
          groupIndex,
          columnIndex,
          itemIndex,
        });
      }

      this.view = this.page.getView(NEW_VIEW_KEY);

      this.$store.commit('activeView', this.view);

      this.nextWizardStep();
    },
    async onSelectSource(source) {
      this.sourceDescription = source.label;

      this.source = source.source;
      this.sourcePaths = source.paths;

      // we need to update quantity in case insert/update was chosen in the source slection for form types
      if (source.quantity) {
        this.recordsQuantity = source.quantity;
      }

      await this.buildRecordsView();

      let addSourcePathStep = true;

      // Check if we should skip the path selection step
      if (Object.keys(this.sourcePaths).length === 1) {
        const pathKey = Object.keys(this.sourcePaths)[0];

        const sourceUsesLoggedInUser = this.sourcePaths[pathKey].user;

        const sourceUsesConnections = pathKey.indexOf(':') > -1;

        // Skip the path step if this source has no connections or logged-in user attribute. It's just a regular object source so nothing to confirm.
        if (!sourceUsesConnections && !sourceUsesLoggedInUser) {
          addSourcePathStep = false;

          // remove the path selection step
          if (this.wizardSteps[this.wizardType].includes('path')) {
            this.wizardSteps[this.wizardType].splice(this.wizardSteps[this.wizardType].indexOf('path'), 1);
          }
        }
      }

      // multiple paths exist so ensure the path step exists to select it
      if (addSourcePathStep) {
        if (this.wizardSteps[this.wizardType].indexOf('path') === -1) {
          // insert path step
          this.wizardSteps[this.wizardType].splice(this.wizardSteps[this.wizardType].indexOf('source') + 1, 0, 'path');
        }
      }

      this.nextWizardStep();
    },
    onSelectSourcePath(path) {
      this.source = path.source;
      this.sourcePathDescription = path.label;
      this.pathSelections = path.path;

      // add to the actual view
      this.view.source = this.source;

      // KLUDGE:: ViewAdd should not need to know this much about reports, but this should go away when reports get refactored for a single source
      if (this.view.type === 'report') {
        this.view.rows[0].reports[0].source = this.source;
      }

      this.nextWizardStep();
    },
    onAddChildPageLinks(childPageLinkOptions) {
      this.childPageLinkOptions = childPageLinkOptions;

      this.nextWizardStep();
    },
    async buildRecordsView(loadData = false) {
      const sourceObject = this.getObject(this.source.object);

      // Create records view
      const viewVars = {
        type: this.view.type,
        reportType: this.reportType,
        name: (this.recordsQuantity === 'many') ? sourceObject.inflections.plural : sourceObject.inflections.singular,
        source: {},
        key: NEW_VIEW_KEY,
      };

      if (this.view.type === 'details') {
        viewVars.name += ' Details';
      }

      // form action
      if (this.view.type === 'form') {
        viewVars.action = (this.recordsQuantity === 'many') ? 'create' : 'update';

        viewVars.name = `${(this.recordsQuantity === 'many') ? 'Add' : 'Update'} ${sourceObject.inflections.singular}`;
      }

      if (this.view.type === 'search') {
        viewVars.name = `Search ${viewVars.name}`;
      }

      // Clear view and wait for next tick to ensure reactive update
      this.view.setView(viewVars);

      await this.$nextTick();

      // Configure the view with fields and defaults
      viewVars.source = this.source;

      const view = this.buildViewSchema(this.page.raw, viewVars, {});

      // Replace the existing one we added when the view type was selected
      this.view = await this.$store.dispatch('page/view/replaceViewLocally', {
        viewData: view,
      });
    },
    createRoute(viewKey) {
      const viewType = (this.linkViewFromMenu) ? 'menu' : this.viewType;

      let routerLink = `/pages/${this.$route.params.pageKey}/views/${viewKey}/${viewType}?confirmNewView=${viewKey}`;

      if (this.viewType === 'report') {
        routerLink = `${routerLink.substr(0, routerLink.indexOf('?confirmNewView'))}/rows/0/reports/0`;
      }

      return routerLink;
    },
    async addView() {
      // Prevent creating a view if viewName is empty
      if (!this.viewName) {
        return;
      }

      // Clear the active menu from linking to the new view
      this.$store.commit('menuKeyForAddingNewView', null);

      this.$store.commit('dragNewViewEnd');

      // this was a split column so we first need to save the new page layout
      if (this.updatePageLayout) {
        await this.page.updateLayout();
      }

      this.commitRequest({
        request: async () => {
          const response = await this.view.create();

          // Delete this temporary `new` view from the page. The handleChanges() will add the actual view back to the page.
          // We need to do this because handleChanges() is called, so onSuccess() is not an option.
          await this.$store.dispatch('page/view/deleteViewLocally', {
            viewKey: NEW_VIEW_KEY,
          });

          return response;
        },
        onSuccess: ({ view }) => {
          log('success!!!');

          logEvent('view_add', {
            view_name: view.name,
            view_id: view.key,
            view_type: view.type,
          });

          this.$router.push(this.createRoute(view.key));
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.inlineDropdown .dropdown-picker {
  display: inline-flex !important
}
.section {
  margin-top: 1.5em;
  padding-top: 1.5em;
  border-top: 1px solid #d9dade;
}
.childInputGroup {
  margin-left: 6px;
  padding-left: 15px;
  border-left: 1px solid #dddee2;
}
</style>
