<template>
  <div
    v-intersect="{ bind: 'isIntersecting' }"
    class="kn-filters-nav"
  >
    <FiltersEdit
      v-if="editingFilters"
      ref="filtersEdit"
      :filters="localFilters"
      :object-key="objectKey"
      @close="editingFilters = false"
      @update="onUpdateFilters"
    />

    <FilterSummary
      v-if="showIndividualFilters"
      :filters="localFilters.rules"
      @deleteFilter="onDeleteFilter"
      @editFilter="onEditFilter"
    />

    <a
      v-else-if="filterCount > 0"
      v-tippy
      content="Click to view and edit these filters"
      class="kn-add-filter kn-button is-small fuchsia filter-count group-[&:not(.kn-view)]/shared:text-emphasis group-[&:not(.kn-view)]/shared:text-sm group-[&:not(.kn-view)]/shared:rounded group-[&:not(.kn-view)]/shared:bg-brand-100"
      @click="onEditFilter"
    >
      <Icon
        class="funnel-icon"
        type="filter-indicator"
      />
      <span>{{ filterCount }} filter{{ filterCount > 1 ? 's' : '' }}</span>
    </a>

    <a
      class="kn-add-filter kn-button is-small group-[&:not(.kn-view)]/shared:border group-[&:not(.kn-view)]/shared:border-solid group-[&:not(.kn-view)]/shared:border-default group-[&:not(.kn-view)]/shared:p-3 group-[&:not(.kn-view)]/shared:rounded-lg group-[&:not(.kn-view)]/shared:bg-white group-[&:not(.kn-view)]/shared:text-emphasis group-[&:not(.kn-view)]/shared:h-10 group-[&:not(.kn-view)]/shared:text-base group-[&:not(.kn-view)]/shared:font-medium group-[&:not(.kn-view)]/shared:hover:bg-brand-50 group-[&:not(.kn-view)]/shared:hover:border-brand-600 group"
      data-cy="add-filters"
      @click="onAddFilter"
    >
      <FontAwesome
        name="filter"
        class="fa h-4 w-4 text-base mb-2 group-[&:not(.kn-view)]/shared:text-default group-hover:text-brand-400"
        style="width: 16px; height: 16px; margin-right: 4px;"
      />
      <span class="group-[&:not(.kn-view)]/shared:capitalize">{{ trans('Add filters') }}</span>
    </a>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';

import FiltersEdit from '@/components/renderer/shared/FiltersEdit';
import FilterSummary from '@/components/renderer/shared/FilterSummary';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';
import FontAwesome from '@/components/ui/FontAwesome';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    FontAwesome,
    FiltersEdit,
    FilterSummary,
    Icon,
  },
  mixins: [
    TranslationUtils,
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({
        match: 'and',
        rules: [],
      }),
    },
    objectKey: {
      type: String,
      default: '',
    },
  },
  emits: ['update:filters'],
  data() {
    return {

      localFilters: cloneDeep(this.filters),
      editingFilters: false,

      // width at which an intersection is detected
      intersectionWidth: 0,
      isIntersecting: false,
    };
  },
  computed: {
    ...mapGetters('ui', [
      'rightBodyWidth',
    ]),
    filterCount() {
      return this.localFilters.rules.length;
    },
    showIndividualFilters() {
      return this.rightBodyWidth > this.intersectionWidth;
    },
  },
  watch: {
    filters: {
      handler(newVal) {
        this.localFilters = cloneDeep(newVal);
      },
      deep: true,
    },
    rightBodyWidth(newWidth, oldWidth) {
      // resetting intersection breakpoint so filters won't
      // 'break' prematurely if right body size expanded
      if (!this.isIntersecting && newWidth > oldWidth) {
        this.intersectionWidth = 0;
      }
    },
    isIntersecting(intersecting) {
      // store width at which intersection detected
      if (intersecting) {
        this.intersectionWidth = this.rightBodyWidth;
      }
    },
  },
  methods: {
    onAddFilter() {
      this.editingFilters = true;
    },
    onEditFilter() {
      this.editingFilters = true;
    },
    onUpdateFilters(filters) {
      this.editingFilters = false;
      this.localFilters = filters;
      this.intersectionWidth = 0;

      this.$emit('update:filters', this.localFilters);
    },
    onDeleteFilter(index) {
      this.localFilters.rules.splice(index, 1);

      this.$emit('update:filters', this.localFilters);
    },
  },
};
</script>

<style lang="scss">
.kn-filters-nav {
  display: flex;
  align-items: center;
  flex-grow: 0;

  .field-list > li {
    border-bottom: none;
    padding: .65em .75em;
    margin-bottom: .5em;
  }
}
</style>

<style lang="scss" scoped>
  .funnel-icon {
    width: 16px;
    height: 16px;
    margin-right: 0.25em;
    font-weight: bold;
  }

  .kn-add-filter.filter-count {
    background: $primary50;
    border: none;
    margin-right: 10px;

    span {
      font-weight: bold;
    }
  }
</style>
