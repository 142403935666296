import Joi from '@hapi/joi';

import api from '@/lib/api-wrapper';
import { getDevUrl } from '@/lib/url-helper';
import FormBase from '@/store/forms/FormBase';

/**
 * The form for sharing feedback about the site.
 */
export default class FormHelpShareFeedback extends FormBase {
  /**
   * The type of feedback.
   * @type {string}
   */
  shareType = '';

  /**
   * The feedback the user wishes to give.
   * @type {string}
   */
  shareFeedback = '';

  /**
   * Validates the form.
   *
   * @returns {{passed: boolean, errors: Error[]}}
   */
  async validate() {
    const validationSchema = {
      shareType: Joi.string().allow('').optional().label('The type of feedback'),
      shareFeedback: Joi.string().allow('').optional().label('Your feedback'),
    };

    return this._validateForm(validationSchema);
  }

  /**
   * Submits the form to the server.
   *
   * @returns {Promise<Object>}
   */
  async submit() {
    const ticketData = {
      devLink: getDevUrl(),
      shareType: this.shareType,
      shareFeedback: this.shareFeedback,
    };

    return api.helpShareFeedback(ticketData);
  }
}
