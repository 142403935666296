import help from './help/api-help';

export default {
  // Full namespace: api
  api: {
    namespaced: true,
    modules: {
      ...help,
    },
  },
};
