<template>
  <Ribbon id="records-ribbon">
    <AccountsBatchActions
      v-if="showAccountBatchActions"
      :action="accountBatchAction"
      :object-key="object.key"
      :record-ids="checkedRecordIds"
      @close="showAccountBatchActions = false"
    />

    <ObjectTypeTabs
      :object="object"
      active-tab="connections"
    />

    <div class="ribbon_subRow border-0 gap-2 py-3 h-auto">
      <div
        class="flexCenter gap-4"
      >
        <RouterLink
          v-tippy
          content="Add a new connection"
          :to="`/connections/objects/${objectKey}/add`"
          class="iconLink ribbonLink ribbonLink-persist border border-solid border-default p-3 rounded-lg bg-transparent text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group"
          data-cy="add-connection-button"
          style="margin-left: -8px;"
        >
          <Icon
            type="plus-thin"
            class="green-text iconLink_icon iconLink_icon-add ribbonLink_icon text-default mr-0 group-hover:text-brand-400 h-4 w-4"
          />
          <RibbonLinkText
            class="text-base font-medium"
            text="Add Connection"
          />
        </RouterLink>
      </div>
    </div>
  </Ribbon>
</template>

<script>
import {
  mapGetters,
} from 'vuex';

import AccountsBatchActions from '@/components/records/AccountsBatchActions';
import Ribbon from '@/components/layout/Ribbon';
import Icon from '@/components/ui/Icon';
import RibbonLinkText from '@/components/layout/RibbonLinkText';
import RequestUtils from '@/components/util/RequestUtils';
import FieldUtils from '@/components/fields/FieldUtils';
import RouteUtils from '@/components/util/RouteUtils';

import ObjectTypeTabs from '@/components/objects/ObjectTypeTabs.vue';

export default {
  name: 'ConnectionsRibbon',
  components: {
    ObjectTypeTabs,
    AccountsBatchActions,
    Ribbon,
    Icon,
    RibbonLinkText,
  },
  mixins: [
    RequestUtils,
    FieldUtils,
    RouteUtils,
  ],
  props: {
    hasRecords: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showAccountBatchActions: false,
      accountBatchAction: '',
      showDeleteSelectedRecordsConfirmModal: false,
    };
  },
  computed: {
    ...mapGetters('notifications', [
      'activeSocketNotification',
    ]),
    ...mapGetters([
      'getObject',
      'checkedRecordIds',
    ]),
    app() {
      return this.$store.state.app;
    },
    objectKey() {
      return this.$route.params.objectKey;
    },
    activeObject() {
      return this.getObject(this.objectKey);
    },
  },
};
</script>
