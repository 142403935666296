const addCommas = (num, format) => {
  if ((format.show_commas && typeof format.mark_thousands === 'undefined') || (format.mark_thousands && format.mark_thousands !== 'none')) {
    if (!format.mark_thousands) {
      format.mark_thousands = 'comma';
    }

    const decimalPlace = '.';

    let markThousands = format.mark_thousands || ',';

    switch (markThousands) {
      case 'comma':
        markThousands = ',';
        break;

      case 'space':
        markThousands = ' ';
        break;

      case 'period':
        markThousands = '.';
        break;
    }

    num = String(num);

    const numSplit = num.split(decimalPlace);
    let numSplitPart1 = numSplit[0];
    const numSplitPart2 = numSplit.length > 1 ? decimalPlace + numSplit[1] : '';
    const rgx = /(\d+)(\d{3})/;

    while (rgx.test(numSplitPart1)) {
      numSplitPart1 = numSplitPart1.replace(rgx, `$1${markThousands}$2`);
    }

    return numSplitPart1 + numSplitPart2;
  }

  return num;
};

const addPrefixOrPostfix = (num, format) => {
  if (format.format && format.format.toLowerCase() !== 'none') {
    switch (format.format) {
      case 'custom':

        format.pre || (format.pre = '');
        format.post || (format.post = '');
        num = String(format.pre) + num + String(format.post);
        break;

      case '€_after':

        num = `${num}&nbsp;€`;
        break;

      case '%':

        num = `${num}%`;
        break;
      default:

        const neg = (String(num).indexOf('-') > -1);

        num = String(format.format) + num;

        if (neg) {
          num = num.replace(`${format.format}-`, `-${format.format}`);
        }

        break;
    }
  } else if (format.prefix && format.prefix.toLowerCase() !== 'none') {
    num = String(format.prefix) + num;
  }

  return num;
};

export const formatNumber = (val, format) => {
  let num = Number(String(val).replace(/[^0-9.-]+/g, ''));

  if (!format) {
    format = {};
  }

  if (!num && num !== 0) {
    return '';
  }

  if (format.format && format.format === '%') {
    num *= 100;
  }

  num = roundNumber(num, format);
  num = addCommas(num, format);
  num = addPrefixOrPostfix(num, format);
  num = markDecimals(num, format);

  return num;
};

const markDecimals = (num, format) => {
  // decimal comma format
  if (format.mark_decimal && format.mark_decimal === 'comma' && format.precision && !isNaN(format.precision) && Number(format.precision) > 0) {
    const parts = String(num).split('.');

    // if the parts length is 1, that means we will display the whole number as a decimal
    if (parts.length > 1) {
      const decimal = parts.pop();

      num = `${parts.join('.')},${decimal}`;
    }
  }

  return num;
};

const roundNumber = (num, format) => {
  if (format.rounding && (format.rounding === 'up' || format.rounding === 'down')) {
    let multiplier = '1';

    const precision = Number(format.precision) || 0;
    for (let i = 0; i < precision; i += 1) {
      multiplier += '0';
    }

    const multiplierNumber = Number(multiplier);

    if (format.rounding === 'up') {
      num = Math.ceil(num * multiplierNumber) / multiplierNumber;
    } else {
      num = Math.floor(num * multiplierNumber) / multiplierNumber;
    }
  }

  if ((format.precision && (!format.mark_decimal || format.mark_decimal !== 'none')) || format.format === '%') {
    let precision = Number(format.precision);

    if (isNaN(precision)) {
      precision = 0;
    }

    if (format.format === '%') {
      precision += 2;
    }

    const precisionDecimals = Math.pow(10, precision);

    num = Math.round(num * precisionDecimals) / precisionDecimals;

    if (format.format === '%') {
      precision -= 2;
    }

    num = num.toFixed(precision);

    if (num === '-0') {
      num = '0';
    }

    return num;
  }

  return Math.round(num);
};

export const stripNumber = (field, val, raw) => {
  if (!val || val === 0) {
    return val;
  } // return zeros and blanks

  if (field && field.type && field.type === 'timer' && raw) {
    return raw; // timer could have a formatted value like 0.5 hours
  }

  val = String(val);

  const format = (field && field.format) ? field.format : {};

  let markDecimal = '.';

  if (format.mark_decimal && format.mark_decimal === 'comma' && format.precision) {
    markDecimal = ',';
  }

  let markThousands = ',';

  if (format.mark_thousands && format.mark_thousands !== 'none' && format.mark_thousands !== 'comma') {
    markThousands = (format.mark_thousands === 'space') ? ' ' : '\\.';
  }

  if (markThousands !== ',') {
    val = val.replace(new RegExp(markThousands, 'g'), '');
  }

  if (markDecimal !== '.') {
    val = val.replace(new RegExp(markDecimal, 'g'), '.');
  }

  val = Number(val.replace(/[^0-9\.\-]+/g, ``)) // eslint-disable-line

  return val;
};

export const toNumber = (value) => {
  return Number(String(value).replace(/[^0-9.-]+/g, ''));
};

export const formatTimer = (value, format) => {
  if (!value) {
    return '0';
  }

  let days = value / (24 * 60 * 60 * 1000);
  let hours = value / (60 * 60 * 1000);
  let minutes = 0;
  let time = '';

  switch (format.total_format) {
    case 'hours':

      time = formatNumber(hours, {
        precision: 2,
        commas: true,
      });

      break;
    default:

      days = Math.floor(days);
      hours = Math.floor(hours);
      minutes = (value - (hours * 60 * 60 * 1000)) / 60000;

      if (hours === 0) {
        time += `${minutes} minutes`;
      } else if (hours < 24) {
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }

        time += `${hours}:${minutes} hours`;
      } else {
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }

        time += days;
        time += (days > 1) ? ' days, ' : ' day, ';
        time += `${(hours % 24)}:${minutes} hours`;
      }

      break;
  }

  return time;
};

export const sanitizeObjectName = (name) => name.trim().replace(/\s+/g, ' ');
