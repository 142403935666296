import ObjectsNav from '@/components/schema/ObjectsNav';
import ObjectAdd from '@/components/objects/ObjectAdd';
import ObjectAddScratch from '@/components/objects/ObjectAddScratch';
import ObjectEnableUsers from '@/components/objects/ObjectEnableUsers';
import ObjectImport from '@/components/objects/ObjectImport';
import ObjectAddIntegration from '@/components/objects/ObjectAddIntegration';
import ObjectAddTemplate from '@/components/objects/ObjectAddTemplate';

export const getSharedObjectsPaths = (bodyComponent) => [
  {
    path: 'objects/add',
    redirect: (to) => {
      if (to.query.user === 'true') {
        return '/schema/list/objects/add/scratch';
      }
      return '/schema/list/objects/add/import';
    },
    components: {
      modal: ObjectAdd,
      toolbox: ObjectsNav,
      body: bodyComponent,
    },
    children: [
      {
        path: 'import',
        component: ObjectImport,
      },
      {
        path: 'template',
        component: ObjectAddTemplate,
      },
      {
        path: 'scratch',
        component: ObjectAddScratch,
      },
      {
        path: 'integration',
        component: ObjectAddIntegration,
      },
    ],
  },
  {
    path: 'objects/enable-users',
    components: {
      modal: ObjectEnableUsers,
      toolbox: ObjectsNav,
      body: bodyComponent,
    },
  },
];
