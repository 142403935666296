import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';

import getScript from '@/lib/getScript';
import Api from '@/lib/api-wrapper';

/**
 * Get Knack GoogleMaps API Key
 *
 * @returns {string}
 */
function getKnackGoogleMapApiKey() {
  if (!process.env.VUE_APP_GOOGLE_MAP_API_KEY || typeof process.env.VUE_APP_GOOGLE_MAP_API_KEY !== 'string') {
    return '';
  }

  return process.env.VUE_APP_GOOGLE_MAP_API_KEY;
}

/**
 * Verify GoogleMaps API Key
 *
 * @param {string} apiKey
 * @returns {Promise<boolean>}
 */
async function verifyGoogleMapsApiKey(apiKey) {
  const address = '1401 S Joyce St, Arlington, VA 22202';
  const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

  try {
    const response = await fetch(geocodingApiUrl);
    const data = await response.json();
    return data.status === 'OK';
  } catch (error) {
    return false;
  }
}

/**
 * Get the GoogleMaps API Key
 *
 * @param {string} googleMapsApiKeyHash
 * @returns {Promise<string|null>}
 */
async function getGoogleMapsApiKeyFromHash(googleMapsApiKeyHash) {
  try {
    const options = {
      url: `credentials/${googleMapsApiKeyHash}`,
      method: 'GET',
      encoding: null,
    };
    const response = await Api.axios(options);
    if (!response || !response.key) return null;

    return response.key;
  } catch (error) {
    console.error('Error fetching google maps api key');
    return null;
  }
}

const storeState = {
  googleMapsIsLoaded: false,
  googleMapsIsLoading: false,
  googleAPIIsLoaded: false,
  googleGSIIsLoaded: false,
  isGoogleMapsKeyInvalid: false,
};

const storeGetters = {
  googleMapsIsLoaded: (state) => state.googleMapsIsLoaded,
  isGoogleMapsKeyInvalid: (state) => state.isGoogleMapsKeyInvalid,
};

const storeActions = {
  /**
   * Loads the GoogleMaps API Script
   *
   * @param {object} context
   * @param {object} context.state
   * @param {object} payload
   * @param {string|null} payload.googleMapsApiKeyHash
   * @returns {Promise<void>}
   */
  async loadGoogleMapsApi({ state }, { googleMapsApiKeyHash } = {}) {
    state.isGoogleMapsKeyInvalid = false;

    if (state.googleMapsIsLoaded) {
      return;
    }

    if (state.googleMapsIsLoading) {
      await new Promise((resolve) => {
        this.watch((watchState) => watchState.externalScripts.googleMapsIsLoading, () => resolve());
      });

      return;
    }

    state.googleMapsIsLoading = true;

    let userApiKey = null;
    if (googleMapsApiKeyHash) {
      userApiKey = await getGoogleMapsApiKeyFromHash(googleMapsApiKeyHash);
    }

    if (userApiKey) {
      const isKeyValid = await verifyGoogleMapsApiKey(userApiKey);
      if (!isKeyValid) {
        state.googleMapsIsLoading = false;
        state.googleMapsIsLoaded = false;
        state.isGoogleMapsKeyInvalid = true;
        return;
      }
    }

    const apiKey = userApiKey || getKnackGoogleMapApiKey();
    const loader = new GoogleMapsLoader({
      apiKey,
      libraries: ['core', 'marker', 'places'],
    });

    await new Promise((resolve) => {
      loader.load().then(resolve);
    });

    state.googleMapsIsLoaded = true;
    state.googleMapsIsLoading = false;
  },

  async loadGoogleAPI({ state }) {
    if (state.googleAPIIsLoaded) return;

    await getScript('https://apis.google.com/js/api.js');
    state.googleAPIIsLoaded = true;
  },

  async loadGoogleGSI({ state }) {
    if (state.googleGSIIsLoaded) return;

    await getScript('https://accounts.google.com/gsi/client');
    state.googleGSIIsLoaded = true;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
};
