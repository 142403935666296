import Builder from '@/components/Builder';
import Settings from '@/components/settings/Settings';
import SettingsNav from '@/components/settings/SettingsNav';
import SettingsApp from '@/components/settings/app/App';
import AppRibbon from '@/components/settings/app/AppRibbon';
import AppSettings from '@/components/settings/app/Settings';
import AppInfo from '@/components/settings/app/Info';
import AppMapProvider from '@/components/settings/app/MapProvider';
import AppSecurity from '@/components/settings/app/Security';
import UsersRibbon from '@/components/settings/UsersRibbon';
import Users from '@/components/settings/Users';
import Ecommerce from '@/components/settings/ecommerce/Ecommerce';
import EcommerceRibbon from '@/components/settings/ecommerce/EcommerceRibbon';
import EcommerceProcessor from '@/components/settings/ecommerce/EcommerceProcessor';
import EcommerceDeleteProcessor from '@/components/settings/ecommerce/EcommerceDeleteProcessor';
import API from '@/components/settings/api/API';
import APIRibbon from '@/components/settings/api/APIRibbon';
import APISettings from '@/components/settings/api/Settings';
import APIJavascript from '@/components/settings/api/Javascript';
import APICSS from '@/components/settings/api/CSS';
import EmbedRibbon from '@/components/settings/embeds/EmbedRibbon';
import EmbedList from '@/components/settings/embeds/EmbedList';
import EmbedSettings from '@/components/settings/embeds/EmbedSettings';
import IntegrationsList from '@/components/settings/integrations/IntegrationsList';
import IntegrationsRibbon from '@/components/settings/integrations/IntegrationsRibbon';
import IntegrationsAdd from '@/components/settings/integrations/IntegrationsAdd.vue';
import DesignTOC from '@/components/settings/design/TOC';
import DesignPreview from '@/components/settings/design/Preview';
import DesignViewsLinks from '@/components/settings/design/views/Links';
import DesignApplicationTheme from '@/components/settings/design/Theme';
import DesignApplicationHeader from '@/components/settings/design/Header';
import DesignApplicationTables from '@/components/settings/design/Tables';
import DesignApplicationMenus from '@/components/settings/design/Menus';

export default [
  {
    path: '/settings',
    component: Builder,
    children: [
      {
        path: '',
        component: Settings,
        props: {
          showIndex: true,
        },
        children: [
          {
            path: '',
            redirect: '/settings/app',
            props: {
              showIndex: true,
            },
          },
          {
            path: 'app',
            components: {
              nav: SettingsNav,
              body: SettingsApp,
              ribbon: AppRibbon,
            },
            children: [
              {
                name: 'settings',
                path: '',
                alias: 'settings',
                component: AppSettings,
              },
              {
                name: 'security',
                path: 'security',
                component: AppSecurity,
              },
              {
                name: 'info',
                path: 'info',
                component: AppInfo,
              },
              {
                name: 'map',
                path: 'map',
                component: AppMapProvider,
              },
            ],
          },
          {
            path: 'design',
            components: {
              nav: DesignTOC,
              body: DesignPreview,
            },
            props: {
              body: {
                showInstructions: true,
              },
            },
          },
          {
            path: 'design/theme',
            components: {
              nav: DesignApplicationTheme,
              body: DesignPreview,
            },
          },
          {
            path: 'design/header',
            components: {
              nav: DesignApplicationHeader,
              body: DesignPreview,
            },
          },
          {
            path: 'design/tables',
            components: {
              nav: DesignApplicationTables,
              body: DesignPreview,
            },
          },
          {
            path: 'design/views/links',
            components: {
              nav: DesignViewsLinks,
              body: DesignPreview,
            },
          },
          {
            path: 'design/menus',
            components: {
              nav: DesignApplicationMenus,
              body: DesignPreview,
            },
          },
          {
            path: 'users',
            components: {
              nav: SettingsNav,
              body: Users,
              ribbon: UsersRibbon,
            },
          },
          {
            path: 'ecommerce',
            components: {
              nav: SettingsNav,
              body: Ecommerce,
              ribbon: EcommerceRibbon,
            },
            children: [
              {
                path: 'add',
                components: {
                  modal: EcommerceProcessor,
                },
              },
              {
                path: ':processorKey/edit',
                components: {
                  modal: EcommerceProcessor,
                },
                props: {
                  modal: true,
                },
              },
              {
                path: ':processorKey/delete',
                components: {
                  modal: EcommerceDeleteProcessor,
                },
                props: {
                  modal: true,
                },
              },
            ],
          },
          {
            path: 'api',
            components: {
              nav: SettingsNav,
              body: API,
              ribbon: APIRibbon,
            },
            children: [
              {
                path: '',
                name: 'api',
                alias: 'api',
                component: APISettings,
              },
              {
                path: 'javascript',
                name: 'javascript',
                component: APIJavascript,
              },
              {
                path: 'css',
                name: 'css',
                component: APICSS,
              },
            ],
          },
          {
            path: 'embeds',
            components: {
              nav: SettingsNav,
              body: EmbedList,
              ribbon: EmbedRibbon,
            },
            children: [
              {
                path: 'add',
                components: {
                  modal: EmbedSettings,
                },
                props: {
                  modal: {
                    action: 'create',
                  },
                },
              },
              {
                path: ':distKey',
                components: {
                  modal: EmbedSettings,
                },
                props: {
                  modal: {
                    action: 'update',
                  },
                },
              },
            ],
          },
          {
            path: 'integrations',
            components: {
              nav: SettingsNav,
              body: IntegrationsList,
              ribbon: IntegrationsRibbon,
            },
            children: [
              {
                path: 'add',
                components: {
                  modal: IntegrationsAdd,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
