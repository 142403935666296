import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import store from '@/store';

const _CONFIG = window.Knack.config;

class Record {
  constructor(values, objectKey) {
    this.values = values;

    if (objectKey) {
      this.object = store.getters.getObject(objectKey);
    }
  }

  // Strip any thumbnail keys or other additional information from a key that a View may store
  sanitizeFieldKey(fieldKey) {
    return fieldKey.split(':', 1)[0];
  }

  getValuesForInputs() {
    const values = {};

    this.object.fields.map((field) => {
      values[field.key] = this.getValueForInput(field.key, field);
    });

    return values;
  }

  // Input values default to raw formats
  getValueForInput(fieldKey, field) {
    fieldKey = this.sanitizeFieldKey(fieldKey);

    if (!field) {
      field = this.object.getField(fieldKey);
    }

    const value = cloneDeep(this.getRawValue(fieldKey));

    log('field.type ', field.type);

    // Process any special field demands
    if (field.type === _CONFIG.DATE_TIME) {
      return this.getValueForDateInput(value, field);
    }

    return value;
  }

  getValueForDateInput(value, field) {
    // Dates should also convert date_formatted values so the date is formatted
    if (value.date_formatted) {
      value.date = value.date_formatted;
      delete value.date_formatted;
    }

    if (value.to && value.to.date_formatted) {
      value.to.date = value.to.date_formatted;
      delete value.to.date_formatted;
    }

    // We don't need these
    let valsToDelete = [
      'unix_timestamp',
      'iso_timestamp',
      'timestamp',
    ];

    log('** ** ** getValueForDateInput() format: ', get(field, 'format.date_format'));

    // Remove dates if only time values are being recorded
    if (get(field, 'format.date_format') === 'Ignore Date') {
      valsToDelete = [
        ...valsToDelete,
        'date',
      ];
    }

    valsToDelete.forEach((valToDelete) => {
      delete value[valToDelete];

      if (value.to) {
        delete value.to[valToDelete];
      }
    });

    log('getValueForDateInput', value);

    return value;
  }

  getDisplayValue(fieldKey) {
    fieldKey = this.sanitizeFieldKey(fieldKey);

    const value = this.values[fieldKey];

    if (!this.object) {
      return value;
    }

    const field = this.object.getField(fieldKey);

    if (!field) {
      return value;
    }

    if (field.type === _CONFIG.FILE) {
      return get(this.values, `${fieldKey}_raw.filename`) || '';
    }

    return value;
  }

  getRawValue(fieldKey) {
    fieldKey = this.sanitizeFieldKey(fieldKey);

    const recordValueRaw = this.values[`${fieldKey}_raw`];

    // we need to use both isNil and isEmpty for our check here
    // because these values can be: numbers, strings, or objects
    // and for example !isEmpty(10) returns false
    if (!isNil(recordValueRaw) || !isEmpty(recordValueRaw)) {
      return recordValueRaw;
    }

    const recordValue = this.values[fieldKey];

    if (!isNil(recordValue) || !isEmpty(recordValue)) {
      return recordValue;
    }

    return '';
  }
}

export default Record;
