<template>
  <view-toolbox
    back-title="page"
    title="Edit Login"
  >
    <div>
      <toc-links :items="items" />
    </div>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import TocLinks from '@/components/views/TocLinks';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'LoginTOC',
  components: {
    ViewToolbox,
    TocLinks,
  },
  mixins: [
    ViewUtils,
  ],
  data() {
    return {
      items: [
        {
          title: 'Settings',
          copy: 'options and settings',
          link: 'settings',
        },

        /*
          {
            title: `Registration Forms`,
            copy: `dummy link to show an idea`,
            link: `registrations`
          },
          */
        {
          title: 'Design',
          copy: 'Title & description',
          link: 'design',
        },
      ],
    };
  },
};
</script>
