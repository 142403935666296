<template>
  <div>
    <input
      name="key"
      type="hidden"
      :value="key"
    >
    <div class="input__message input__message--password" />

    <div
      v-if="actionIsAuthenticate"
      class="control"
    >
      <label
        for="password_authentication"
        class="knack-input-label group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:font-medium group-[&:not(.kn-view)]/shared:mb-2"
      >
        {{ input.label_authenticate }}
        <span class="kn-required group-[&:not(.kn-view)]/shared:text-brand inline-flex self-baseline">
          *
        </span>
      </label>
      <input
        name="password_authentication"
        type="password"
        class="input"
      >
    </div>

    <div
      v-if="actionIsUpdate"
      class="control"
    >
      <label
        for="password"
        class="knack-input-label group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:font-medium group-[&:not(.kn-view)]/shared:mb-2"
      >
        {{ labelUpdate }}
        <span class="kn-required group-[&:not(.kn-view)]/shared:text-brand inline-flex self-baseline">
          &nbsp;*
        </span>
      </label>
      <input
        v-model="localPassword"
        name="password"
        type="password"
        class="input"
      >

      <span v-if="!input.ignore_confirmation">
        <label
          class="hint group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
          for="password"
        >
          {{ trans('Password') }}
        </label>
        <input
          v-model="localPasswordConfirmation"
          name="password_confirmation"
          type="password"
          class="input"
        >
        <label
          class="hint group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
          for="password_confirmation"
        >
          {{ trans('Confirm Password') }}
        </label>
      </span>
    </div>
  </div>
</template>

<script>
import isString from 'lodash/isString';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';

export default {
  name: 'PasswordInput',
  mixins: [
    TranslationUtils,
  ],
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      type: [
        Object,
        String,
      ],
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    actionIsAuthenticate() {
      return this.input.action_authenticate === true;
    },
    actionIsUpdate() {
      return !this.actionIsAuthenticate;
    },
    labelUpdate() {
      return this.input.label_update || this.input.label || 'password';
    },
    localValue: {
      get() {
        if (isString(this.modelValue)) {
          return {
            password: this.modelValue,
            password_confirmation: this.modelValue,
            hasChanged: false,
          };
        }

        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    localPassword: {
      get() {
        return this.localValue.password;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          password: newValue,
        });
      },
    },
    localPasswordConfirmation: {
      get() {
        return this.localValue.password_confirmation;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          password_confirmation: newValue,
        });
      },
    },
    key() {
      return this.input?.field?.key || 'password';
    },
  },
  watch: {
    localValue() {
      this.localValue.hasChanged = true;
    },
  },
};
</script>

<style lang="scss">
.hint {
  font-size: 85%;
  font-weight: 500;
  padding: .125em 0 .5em;
}

.kn-required {
  color: red;
}
</style>
