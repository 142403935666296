<template>
  <div
    id="schema-wrapper"
    class="builderLayout_builderPane"
    :class="{'builderPane-noToolbox': !hasObjects }"
    @mousemove="handleLeftPanelResizeOnDrag"
    @mouseup="cancelLeftPanelResizing"
    @mouseleave="cancelLeftPanelResizing"
  >
    <RouterView name="modal" />

    <ToolboxRouter
      v-if="hasObjects"
      id="schema-toolbox"
      class="main-panel builderLayout_toolbox"
      :class="toolboxWidth"
      :style="{ flexBasis: leftPanelFlexBasis}"
    />

    <PanelDivider />

    <div
      id="schema-body-wrapper"
      class="main-body builderLayout_main left-border border-solid border-x-0 border-b-0 border-t border-subtle -ml-px"
    >
      <SchemaRibbon
        v-if="hasObjects"
        class="builderLayout_ribbon border-0"
      />
      <BodyWrapper id="schema-body">
        <RouterView
          name="body"
        />
      </BodyWrapper>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import BodyWrapper from '@/components/layout/BodyWrapper';
import PanelDivider from '@/components/layout/PanelDivider';
import ToolboxRouter from '@/components/layout/ToolboxRouter';
import FieldUtils from '@/components/fields/FieldUtils';
import SchemaRibbon from '@/components/schema/SchemaRibbon';

export default {
  components: {
    BodyWrapper,
    ToolboxRouter,
    SchemaRibbon,
    PanelDivider,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    showConnection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'objects',
    ]),
    ...mapGetters('ui', [
      'leftPanelFlexBasis',
      'leftPanelWidth',
    ]),
    ...mapGetters('notifications', [
      'activeSocketNotification',
    ]),
    hasObjects() {
      return this.objects.length > 0;
    },
    toolboxWidth() {
      return this.$store.getters.toolboxExpandClass(this.$route);
    },
  },
  mounted() {
    this.setUseSampleData(true);

    if (!this.leftPanelWidth) {
      this.setInitialLeftPanelWidth();
    }
  },
  methods: {
    ...mapActions('ui', [
      'handleLeftPanelResizeOnDrag',
      'cancelLeftPanelResizing',
      'setInitialLeftPanelWidth',
    ]),
    ...mapMutations([
      'setUseSampleData',
    ]),
  },
};
</script>

<style lang="scss">
#object-list {
  display: flex;
  height: 100%;

  #object-list-add-field {
    padding-top: .75em;
  }

  #object-list-fields-wrapper {
    flex: 2 0;
    //padding: 1.25em 0 1em 1.75em;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 848px;

    #object-list-fields {
      flex-grow: 1;
      overflow: auto;
    }
  }
}

#connections {
  flex: 0 0 33%;
  min-width: 202px;
  max-width: 340px;
  border-left: 1px solid $gray100;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 1.5em;
}
</style>
