<template>
  <view-toolbox
    back-title="Form Menu"
    :back-count="1"
    title="Add Form Inputs"
  >
    <AddItems
      view-type="form"
      :view="view"
      :object="object"
    >
      <p class="text-sm leading-5 mb-0">
        Drag or click options below to add new field inputs.
      </p>
    </AddItems>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import AddItems from '@/components/views/AddItems';

export default {
  name: 'FormInputsBuild',
  components: {
    ViewToolbox,
    AddItems,
  },
  mixins: [
    ViewUtils,
  ],
};
</script>

<style lang="scss">
</style>
