import get from 'lodash/get';
import noop from 'lodash/noop';
import FormBase from '@/store/forms/FormBase';
import {
  STATUS_READY, STATUS_PENDING, STATUS_SUCCESS, STATUS_FAILURE,
} from '@/constants/status';

/**
 * The base module for API requests.
 *
 * @param {Object} events
 * @param {string} events.RESET_FORM
 * @param {string} events.UPDATE_FORM
 * @param {string} events.VALIDATION
 * @param {string} events.READY
 * @param {string} events.PENDING
 * @param {string} events.SUCCESS
 * @param {string} events.FAILURE
 * @param {function()} [getForm]
 * @returns {{state: Object, getters: Object, mutations: Object, actions: Object}}
 */
function getApiBaseModule(events, getForm = noop) {
  const {
    RESET_FORM, UPDATE_FORM, VALIDATION, READY, PENDING, SUCCESS, FAILURE,
  } = events;

  const initialForm = getForm();

  const storeState = {
    status: STATUS_READY,
    results: [],
    errors: [],
    form: initialForm,
    validation: FormBase.initValidation(),
  };

  const storeGetters = {
    results: (state) => state.results,
    errors: (state) => state.errors,
    form: (state) => state.form,
    validation: (state) => state.validation,
    isInvalid: (state) => (!state.validation.passed),

    isReady: (state) => (state.status === STATUS_READY),
    isPending: (state) => (state.status === STATUS_PENDING),
    isSuccess: (state) => (state.status === STATUS_SUCCESS),
    isFailure: (state) => (state.status === STATUS_FAILURE),

    getFormValue: (state) => (key) => get(state.form, key),
  };

  const storeMutations = {
    /**
     * Resets the form to its initial state.
     *
     * @param {Object} state
     */
    [RESET_FORM](state) {
      state.form = getForm();
    },

    /**
     * Updates an item in the form using the given key and new value.
     *
     * @param {Object} state
     * @param {Object} payload
     * @param {string} payload.key
     * @param {*} payload.newValue
     */
    [UPDATE_FORM](state, { key, newValue }) {
      state.form[key] = newValue;
    },

    /**
     * Sets the results of validation.
     *
     * @param {Object} state
     * @param {{passed: boolean, errors: Array, errorsByKey: Object}} validationResult
     */
    [VALIDATION](state, validationResult) {
      state.validation = validationResult;
    },

    /**
     * Sets the request status to ready.
     * This means that the previous request results or errors have been cleared out.
     *
     * @param {Object} state
     */
    [READY](state) {
      state.results = [];
      state.errors = [];
      state.form = getForm();
      state.validation = FormBase.initValidation();
      state.status = STATUS_READY;
    },

    /**
     * Sets the request status to pending.
     * This means a request is currently in progress.
     *
     * @param {Object} state
     */
    [PENDING](state) {
      state.results = [];
      state.error = [];
      state.validation = FormBase.initValidation();
      state.status = STATUS_PENDING;
    },

    /**
     * Sets the request results and updates the status to success.
     * This means the request was successful.
     *
     * @param {Object} state
     * @param {Object[]} results
     */
    [SUCCESS](state, results) {
      state.results = results || [];
      state.status = STATUS_SUCCESS;
    },

    /**
     * Sets the request error and updates the status to failed.
     * This means the request had an error.
     *
     * @param {Object} state
     * @param {Error} searchError
     */
    [FAILURE](state, searchError) {
      if (searchError.errors) {
        state.errors = searchError.errors;
      } else {
        state.errors = [searchError];
      }

      state.status = STATUS_FAILURE;
    },
  };

  const storeActions = {
    /**
     * Resets the store back to its ready state.
     *
     * @param {Object} context
     * @param {function} context.commit
     */
    reset({ commit }) {
      commit(READY);
    },

    /**
     * Resets the form to its initial state.
     *
     * @param {Object} context
     * @param {function} context.commit
     */
    resetForm({ commit }) {
      commit(RESET_FORM);
    },

    /**
     * Updates an item in the form using the given key and new value.
     *
     * @param {Object} context
     * @param {function} context.commit
     * @param {Object} payload
     * @param {string} payload.key
     * @param {*} payload.newValue
     */
    updateForm({ commit }, { key, newValue }) {
      commit(UPDATE_FORM, {
        key,
        newValue,
      });
    },

    /**
     * Runs validation on the form.
     *
     * @param {Object} context
     * @param {function} context.commit
     * @param {Object} context.getters
     * @returns {Promise<boolean>} - Whether or not the validation passed.
     */
    async validateForm({ commit, getters }) {
      const { form } = getters;

      if (!form || !form.validate) {
        return true;
      }

      const validationResult = await form.validate();

      commit(VALIDATION, validationResult);

      return validationResult.passed;
    },

    /**
     * Submits the form.
     *
     * @param {Object} context
     * @param {function} context.commit
     * @param {function} context.dispatch
     * @param {function} context.getters
     * @returns {Promise<void>}
     */
    async submitForm({ commit, dispatch, getters }) {
      commit(PENDING);

      const wasValid = await dispatch('validateForm');

      if (!wasValid) {
        commit(FAILURE, new Error('One or more fields were invalid.'));

        return;
      }

      try {
        const response = await getters.form.submit();

        commit(SUCCESS, response);
      } catch (requestError) {
        commit(FAILURE, requestError);
      }

      dispatch('afterSubmit');
    },

    /**
     * Does any effects needed after the submit was finished.
     */
    async afterSubmit() {

      // Override this as needed.
    },
  };

  return {
    state: storeState,
    getters: storeGetters,
    mutations: storeMutations,
    actions: storeActions,
  };
}

export default getApiBaseModule;
