<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Equation Type</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="fieldLocal.format.equation_type"
          type="radio"
          value="numeric"
        >Numeric</label>
        <label><input
          v-model="fieldLocal.format.equation_type"
          type="radio"
          value="date"
        >Date</label>
      </div>
    </div>
    <div v-if="isDate">
      <label class="text-default text-sm font-medium mb-2 leading-4">Date Type</label>
      <div>
        <select
          v-model="fieldLocal.format.date_type"
          class="text-base py-2 pl-3 leading-5"
        >
          <option>seconds</option>
          <option>hours</option>
          <option>days</option>
          <option>weeks</option>
          <option>years</option>
        </select>
      </div>
    </div>
    <div v-if="isDate">
      <label class="text-default text-sm font-medium mb-2 leading-4">Result Type</label>
      <div>
        <select
          v-model="fieldLocal.format.date_result"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="number">
            Number
          </option>
          <option value="date">
            Date
          </option>
        </select>
      </div>
    </div>

    <div class="mb-1.5">
      <EquationEditor
        v-model:equation="fieldLocal.format.equation"
        :field-local="fieldLocal"
      />
    </div>

    <Toggle>
      <template
        #title
        class="field-add-title"
      >
        <span>Result Format</span>
      </template>
      <template #content>
        <div
          class="mt-1.5"
          style="margin-top: 1.5rem;"
        >
          <Numeric
            v-if="showNumeric"
            :field-local="fieldLocal"
          />
          <div
            v-if="isDate && !showNumeric"
            class="mb-6"
          >
            <label class="text-default text-sm font-medium mb-2 leading-4">Date Format</label>
            <div>
              <select
                v-model="fieldLocal.format.date_format"
                class="text-base py-2 pl-3 leading-5"
              >
                <option>mm/dd/yyyy</option>
                <option>M D, yyyy</option>
                <option>dd/mm/yyyy</option>
                <option>Ignore Date</option>
              </select>
            </div>
          </div>
          <div v-if="isDate && !showNumeric">
            <label class="text-default text-sm font-medium mb-2 leading-4">Time Format</label>
            <div>
              <select
                v-model="fieldLocal.format.time_format"
                class="text-base py-2 pl-3 leading-5"
              >
                <option>Ignore Time</option>
                <option>HH:MM am</option>
                <option value="HH MM (military)">
                  HH:MM (24 hour)
                </option>
              </select>
            </div>
          </div>
        </div>
      </template>
    </Toggle>
  </div>
</template>

<script>
import EquationEditor from '@/components/fields/settings/shared/EquationEditor';
import Numeric from '@/components/fields/settings/shared/Numeric';
import Toggle from '@/components/ui/Toggle';

export default {
  name: 'Equation',
  components: {
    EquationEditor,
    Numeric,
    Toggle,
  },
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isDate() {
      return this.fieldLocal.format.equation_type === 'date';
    },
    showNumeric() {
      return (this.fieldLocal.format.equation_type === 'numeric' || this.fieldLocal.format.date_result === 'number');
    },
  },
  watch: {
    isDate(isDate) {
      // unfortunately necessary hack, in v2 these properties were not automatically added and thus we have a lot of existing equation fields
      // without them and if you switch from the default 'numeric' field type to 'date' then these would all start as empty dropdowns.
      // this ensures they have a default and makes them reactive due to strangeness of 'watch'
      if (isDate) {
        const dateType = (this.fieldLocal.format.date_type) ? this.fieldLocal.format.date_type : 'hours';
        const dateResult = (this.fieldLocal.format.date_result) ? this.fieldLocal.format.date_result : 'number';
        const dateFormat = (this.fieldLocal.format.date_format) ? this.fieldLocal.format.date_format : 'mm/dd/yyyy';
        const timeFormat = (this.fieldLocal.format.time_format) ? this.fieldLocal.format.time_format : 'Ignore Time';

        this.fieldLocal.format.date_type = dateType;
        this.fieldLocal.format.date_result = dateResult;
        this.fieldLocal.format.date_format = dateFormat;
        this.fieldLocal.format.time_format = timeFormat;
      }
    },
  },
};
</script>

<style lang="scss">
.equation-input {
  height: 100%;
  width: 24em;
  font-size: 1em;
  padding: 1.250em;
  overflow: hidden;
  resize: none;
}

.monaco-editor, .monaco-editor .inputarea.ime-input {
  color: $gray100;
}

.monaco-editor .parameter-hints-widget .signature {
  padding: .3125rem;
}

.monaco-editor .parameter-hints-widget .signature.has-docs {
  border-bottom: 1px solid $gray600;
}

.monaco-editor .parameter-hints-widget {
  background-color: $gray900;
  border: 1px solid $gray600;
  border-radius: .1875em;
}

.monaco-editor .parameter-hints-widget .docs {
  padding: .5rem .5rem .5rem .25rem !important;
}

.monaco-editor .parameter-hints-widget p, .monaco-editor .parameter-hints-widget ul {
  margin: 0 !important;
}
</style>
