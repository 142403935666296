<template>
  <template v-if="isGoogleMapsKeyInvalid">
    <div
      class="validation-error bg-destructive text-destructive-emphasis rounded-lg p-4"
    >
      <p class="mb-0">
        Please enter a valid Google Maps API key in App Settings.
      </p>
    </div>
  </template>
  <template v-if="googleMapsIsLoaded">
    <GoogleMap
      :ref="onMapLoaded"
      :center="boundsCenter"
      :zoom="12"
      :style="`width: ${width}px; height: ${height}px`"
    >
      <Marker
        v-for="(marker, index) in markers"
        :key="index"
        :options="{
          position: marker.position.toJSON(),
          icon: marker.icon,
          clickable: true,
          draggable: false,
        }"
      />
    </GoogleMap>
  </template>
</template>

<script>
import RuleHelper from '@knack/rules-helper';
import { mapActions, mapGetters } from 'vuex';
import { GoogleMap, Marker } from 'vue3-google-map';
import Icon from '@/components/ui/Icon.vue';

export default {
  components: {
    GoogleMap,
    Marker,
  },
  props: {
    view: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    records: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mapEl: null,
    };
  },
  computed: {
    ...mapGetters([
      'app',
      'getField',
      'googleMapsIsLoaded',
      'isGoogleMapsKeyInvalid',
    ]),
    markers() {
      const { google } = window;
      const markers = [];

      const rules = this.view.get('pin_colors');

      this.records.forEach((record) => {
        const add = record[`${this.view.get('address_field').key}_raw`];

        // return if not valid address
        if (!add || !add.latitude) {
          return;
        }

        const position = new google.maps.LatLng(add.latitude, add.longitude);

        let pinColor = this.view.get('pin_color_default') || '#FE7569';

        // Get custom pin color
        rules.forEach((rule) => {
          const passed = RuleHelper.checkRule(
            rule,
            record[`${rule.field}_raw`],
            this.getField(rule.field),
            this.app,
          );

          if (passed) {
            pinColor = rule.color;
          }
        });

        const pin = new google.maps.marker.PinElement({
          background: pinColor,
        });

        const marker = new google.maps.marker.AdvancedMarkerElement({
          position,
          content: pin.element,
        });

        markers.push(marker);
      });

      return markers;
    },
    bounds() {
      const bounds = new window.google.maps.LatLngBounds();

      this.markers.forEach((marker) => {
        bounds.extend(marker.position.toJSON());
      });

      return bounds;
    },
    boundsCenter() {
      if (this.mapEl?.map?.fitBounds) {
        this.mapEl.map.fitBounds(this.bounds);
      }

      return this.bounds.getCenter().toJSON();
    },
  },
  async mounted() {
    const { googleMapsApiKey } = this.$store.getters.app.get('settings');
    await this.loadGoogleMapsApi({ googleMapsApiKeyHash: googleMapsApiKey });
  },
  methods: {
    ...mapActions([
      'loadGoogleMapsApi',
    ]),
    async onMapLoaded(el) {
      if (this.mapEl) {
        return;
      }

      this.mapEl = el;

      if (el.map?.fitBounds) {
        el.map.fitBounds(this.bounds);
      }
    },
  },
};
</script>
