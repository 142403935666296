<template>
  <div id="field-conditional-rules">
    <p>
      Use rules to set the values of the <b>{{ fieldRaw.name }}</b> field based on other record values.
    </p>
    <p>
      <label class="text-emphasis text-sm font-medium mb-2 leading-4"><input
        v-model="enabled"
        type="checkbox"
        data-cy="add-conditional-rules"
      >Add conditional rules</label>
    </p>
    <RulesList
      v-if="enabled"
      v-model:rules="conditionalRules"
      item-title="Rule"
      :object="object"
      :field-default="field"
      :allow-connection-option="false"
      :criteria-values-can-be-fields="true"
      :show-criteria-title="false"
      :show-field-options="false"
      :show-action-selection="false"
      :rule-type="RuleType.Conditional"
      group-list-item-classes="bg-muted"
    >
      <template #no-criteria>
        <p>
          Run with every record
        </p>
      </template>
    </RulesList>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import FieldUtils from '@/components/fields/FieldUtils';
import RulesList from '@/components/rules/RulesList';

import { RuleType } from '@/constants/rules';

export default {
  name: 'FieldRulesConditional',
  components: {
    RulesList,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    fieldLocal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      enabled: false,
      RuleType,
    };
  },
  computed: {
    conditionalRules: {
      get() {
        return this.fieldLocal.rules;
      },
      set(newValue) {
        this.fieldLocal.rules = newValue;
      },
    },
    showDeleteRuleIcons() {
      return this.conditionalRules.length > 1;
    },
  },
  watch: {
    enabled(newValue) {
      this.fieldLocal.conditional = newValue;

      if (newValue && !this.conditionalRules.length) {
        const criteria = [];

        if (this.fieldLocal.type === 'image' || this.fieldLocal.type === 'file') {
          criteria.push(
            {
              field: this.object.fields[0].key,
              operator: 'is blank',
              value: '',
            },
          );
        }
        this.fieldLocal.rules.push({
          criteria,
          values: [
            {
              field: this.field.key,
              type: 'value',
              value: '',
              connection_field: null,
            },
          ],
        });
      }

      if (!newValue) {
        this.fieldLocal.rules = [];
      }
    },
    conditionalRules(newValue) {
      if (isEmpty(newValue)) {
        this.enabled = false;
      }
    },
  },
  created() {
    if (!this.fieldLocal.rules) {
      this.fieldLocal.rules = [];
    }

    if (this.fieldLocal.rules.length) {
      this.enabled = true;
      this.fieldLocal.conditional = true;
    }
  },
};
</script>

<style lang="scss">
#field-conditional-rules {

  .value-item {
    display: flex;

    > * {
      width: auto;
      margin-right: .5em;
    }
  }

  .delete-rule {
    display: flex;
    justify-content: flex-end;

    svg {
      color: #af4343;
      width: 15px;
      height: 15px;
    }
  }

  .record-action > label {
    display: none;
  }

  .record-action-values {
    // "Set" label
    .value-list-item > span {
      @include font-body;
      font-weight: 600;
      line-height: 2rem;
      color: $gray800;
    }
    .actions {
      display: none;
    }
  }
}
</style>
