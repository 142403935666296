<template>
  <view-toolbox
    back-title="Edit Checkout"
    title="Payment Summary"
  >
    <p class="mb-4 text-sm font-normal leading-4">
      Add fields that should be included with the charge total, like shipping or handling. This will display with any <strong>payment details</strong>, and is also an available placeholder in email rules.
    </p>
    <form>
      <div>
        <ActionList
          v-model:items="viewRaw.summary_fields"
          :can-be-empty="true"
          :default-item="defaultField"
        >
          <template #default="props">
            <div class="flex-1">
              <input
                v-model="props.item.text"
                type="text"
              >
            </div>
            <div class="flex-1">
              <select
                v-model="props.item.field"
                class="text-base py-2 pl-3 leading-5 ml-0"
              >
                <option
                  v-for="field in numericFields"
                  :key="field.key"
                  :value="field.key"
                >
                  {{ field.name }}
                </option>
              </select>
            </div>
          </template>
        </ActionList>
        <a
          v-if="!viewRaw.summary_fields.length"
          class="button save"
        >
          Add Field
        </a>
      </div>
      <div>
        <label class="text-emphasis text-base font-normal">
          <input
            v-model="viewRaw.hide_fields"
            type="checkbox"
          > Hide empty fields
        </label>
      </div>
    </form>
  </view-toolbox>
</template>
<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ActionList from '@/components/ui/lists/ActionList';
import PaymentUtils from '@/components/settings/ecommerce/PaymentUtils';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  components: {
    ViewToolbox,
    ActionList,
  },
  mixins: [
    PaymentUtils,
    ViewUtils,
  ],
  methods: {
    defaultField() {
      return {
        text: '',
        field: this.numericFields[0].key,
      };
    },
  },
};
</script>
