import 'core-js';

// This needs to be removed and all references to window.Knack should import this instead.
window.Knack = require('@/init/knack').knack;

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import $ from 'jquery';
import { createApp, defineComponent } from 'vue';
import {
  mapMutations,
  mapActions,
} from 'vuex';

import App from '@/App.vue';
import initAppPlugins from '@/init/vue-plugins';

import '@/init/styles';

// Destructuring to get around eslint import module bug.
const { hot } = module || {};
if (hot) {
  hot.accept();
}

localStorage.debug = process.env.VUE_APP_DEBUG_MODULE_SCOPE;

// Instead of loading via inline scrip, load script and setup defaults in script entry.
$.getScript('https://ajax.googleapis.com/ajax/libs/webfont/1.5.18/webfont.js', () => {
  window.WebFont.load({
    google: {
      families: [
        'Fira Sans:400,400italic,500,500italic,600,600italic,700,700italic',
        'Lato:400,400italic,500,500italic,600,600italic,700,700italic',
        'Libre Franklin:400,400italic,500,500italic,600,600italic,700,700italic',
        'Merriweather Sans:400,400italic,500,500italic,600,600italic,700,700italic',
        'Nunito:400,400italic,500,500italic,600,600italic,700,700italic',
        'Nunito Sans:400,400italic,500,500italic,600,600italic,700,700italic',
        'Open Sans:400,400italic,500,500italic,600,600italic,700,700italic',
        'PT Sans:400,400italic,500,500italic,600,600italic,700,700italic',
        'Roboto:400,400italic,500,500italic,600,600italic,700,700italic',
        'Rubik:400,400italic,500,500italic,600,600italic,700,700italic',
        'Source Sans Pro:400,400italic,500,500italic,600,600italic,700,700italic',
      ],
    },
    classes: false,
  });
});

const mainComponent = defineComponent({
  components: {
    App,
  },
  created() {
    this.setViewportDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    this.initiateListeners();
  },
  unmounted() {
    this.removeListeners();
  },
  methods: {
    ...mapMutations('ui', [
      'setViewportDimensions',
    ]),
    ...mapActions('ui', [
      'initiateListeners',
      'removeListeners',
    ]),
  },
  template: '<App/>',
});

// Remove this after Vue 3 is out and stable.
console.log('VUE 3 APP'); // eslint-disable-line no-console

const app = createApp(mainComponent);

// Define plugins.
initAppPlugins(app);

// Mount the app.
const mounted = app.mount('#app');

/**
 * Do NOT reference or use this in the code!
 * This is only to be used by the browser extension.
 * @deprecated
 */
window.KnackVue = mounted;
