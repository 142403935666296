<template>
  <view-toolbox
    back-title="Payment Rules"
    title="Record Rules"
  >
    <p
      class="text-sm leading-5 mb-0"
      style="margin-bottom: 1em"
    >
      Add rules to update or insert records after the payment is successfully processed.
    </p>
    <RulesList
      v-model:rules="rules"
      :input-field-options="inputFieldOptions"
      group-list-item-classes="bg-subtle"
    >
      <template #no-criteria>
        <p>
          This rule runs on every form submission
        </p>
      </template>
    </RulesList>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import RulesList from '@/components/rules/RulesList';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'CheckoutRecordRules',
  components: {
    RulesList,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    rules: {
      get() {
        return this.viewRaw.rules.records || [];
      },
      set(newValue) {
        this.viewRaw.rules.records = newValue;
      },
    },
    inputFieldOptions() {
      // else we can just use the object inputs
      return this.object.getFieldOptions();
    },
  },
};
</script>
