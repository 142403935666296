import mitt from 'mitt';

const emitter = mitt();

// create a global event bus
// we use this for cross-component events that are not state based (e.g. drag/drop)
// this needs to be declared before our main App component since it's used within it
export const eventBus = {
  $on: (...args) => emitter.on(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};
