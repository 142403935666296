<template>
  <a
    :href="link"
    class="drawerItem"
    target="_blank"
  >
    <div class="drawerItem_iconWrapper">
      <Icon
        class="drawerItem_icon"
        :type="icon"
      />
    </div>
    <div class="drawerItem_content">
      <h2 class="drawerItem_header">
        {{ config.title }}<Icon
          type="open-in-new"
          class="drawerItem_headerIcon"
        />
      </h2>
      <p class="drawerItem_caption">
        {{ config.caption }}
      </p>
    </div>
  </a>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  name: 'HelpLink',
  components: {
    Icon,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    icon() {
      return this.config.icon;
    },
    link() {
      return this.config.link.replace('{{DASHBOARD_URL}}', process.env.VUE_APP_DASHBOARD_URL);
    },
  },
};
</script>
