<template>
  <view-toolbox
    back-title="Payment Summary"
    title="Summary Details"
  >
    <p class="mb-0.5 text-sm font-normal leading-4">
      <strong>Payment Details</strong> provide information to the user before they're charged. This section will automatically contain your <strong>payment summary</strong>.
    </p>

    <Toggle
      id="column-properties-toggle"
      :is-open="true"
    >
      <template #title>
        <span>Title & Description</span>
      </template>

      <template #content>
        <form class="mb-0 pb-6 border border-solid border-subtle border-x-0 border-t-0">
          <div class="mb-4">
            <label class="tw-toolbox-label">Checkout Title</label>
            <input
              v-model="viewRaw.checkout_page.title"
              type="text"
            >
          </div>
          <div class="mb-0">
            <label class="tw-toolbox-label">Checkout Description</label>
            <textarea v-model="viewRaw.checkout_page.description" />
          </div>
        </form>
      </template>
    </Toggle>

    <Toggle
      :is-open="true"
      class="no-top-styles mt-6"
    >
      <template #title>
        <span>Layout</span>
      </template>
      <template #content>
        <div class="mb-0 pb-6 border border-solid border-subtle border-x-0 border-t-0">
          <DetailsLayout />
        </div>
      </template>
    </Toggle>

    <Toggle
      id="column-properties-toggle"
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Add Details</span>
      </template>

      <template
        v-if="!showAddDetails"
        #content
      >
        <div>
          <p class="mb-4 text-sm font-normal leading-4">
            Add optional details to display above the payment summary.
          </p>
          <a
            class="button blue-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium"
            @click="onClickAddDetails"
          >
            <Icon
              class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
              type="plus-thin"
            />
            Add details
          </a>
        </div>
      </template>

      <template
        v-else
        #content
      >
        <AddItems
          :view="view"
          :object="object"
          view-type="details"
        >
          <p class="text-sm leading-5">
            Drag or click options below to add new details.
          </p>
        </AddItems>
      </template>
    </Toggle>
  </view-toolbox>
</template>
<script>
import AddItems from '@/components/views/AddItems';
import PaymentUtils from '@/components/settings/ecommerce/PaymentUtils';
import Toggle from '@/components/ui/Toggle';
import DetailsLayout from '@/components/views/shared/DetailsLayout';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import Icon from '@/components/ui/Icon';
import { eventBus } from '@/store/bus';

export default {
  components: {
    AddItems,
    DetailsLayout,
    Toggle,
    ViewToolbox,
    Icon,
  },
  mixins: [
    PaymentUtils,
    ViewUtils,
  ],
  data() {
    return {

      showAddDetails: true,
    };
  },
  computed: {
    layout() {
      return this.viewRaw.checkout_page.columns[0].groups;
    },
    detailsIsEmpty() {
      return !this.view.get('checkout_page').columns.some((layout) => layout.groups.some((group) => group.columns.some((column) => column.length > 0)));
    },
  },
  mounted() {
    this.showAddDetails = !this.detailsIsEmpty;
  },
  methods: {
    onClickAddDetails() {
      this.showAddDetails = true;

      eventBus.$emit(`checkout.${this.view.key}.showAddDetails`);
    },
  },
};
</script>
