import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import shortid from 'shortid';

import { addItemKey } from '@/lib/groups-helper';
import _Knack from '@/lib/Knack';
import store from '@/store';

const Knack = new _Knack();

export const buildViewSchema = (scene, view, options = {}) => {
  view.title = (view.type === 'menu' || view.type === 'rich_text') ? '' : view.name;

  view.description = '';

  view.source = (view.source) ? view.source : {
    type: 'database',
    object: scene.source,
  };

  switch (view.type) {
    case 'table':

      view = buildTableModel(scene, view, options);

      break;

    case 'search':

      view = buildSearchModel(scene, view, options);

      break;

    case 'list':

      view = buildListModel(scene, view, options);

      break;

    case 'calendar':

      view = buildCalendarModel(scene, view, options);

      break;

    case 'map':

      view = buildMapModel(scene, view, options);

      break;

    case 'form':

      view = buildFormModel(scene, view);

      break;

    case 'details':

      view = buildDetailsModel(scene, view, options);

      break;

    case 'rich_text':

      view = buildRichTextModel(scene, view);

      break;

    case 'menu':

      view = buildMenuModel(scene, view, options);

      break;

    case 'report':

      const defaultReportSchema = defaultReport(view.reportType, view.source);

      view.rows = [
        {
          reports: [
            defaultReportSchema,
          ],
        },
      ];

      delete view.addLocationType;
      delete view.reportType;

      break;

    case 'checkout':

      view = buildEcommModel(scene, view, options);

      break;

    case 'charge':

      view = buildEcommModel(scene, view, options);

      break;

    case 'customer':

      view = buildEcommModel(scene, view, options);

      break;
  }

  // if no object add keyword and filters
  if (!view.source.connection_key) {
    switch (view.type) {
      case 'table':

        view.keyword_search = true;
        view.allow_preset_filters = false;
        view.filter_type = 'fields';

        break;

      case 'list':

        view.keyword_search = true;
        view.allow_preset_filters = false;
        view.filter_type = 'fields';

        break;
    }
  }

  return view;
};

/**
 * Gets the object for either the parent page or the current page.
 *
 * @param {object} pageData
 * @returns {object | undefined}
 */
const getPageOrParentObject = (pageData) => {
  /** @type {RawPage | undefined} parentPage */
  let parentPage;

  if (pageData.slug) {
    parentPage = store.getters.getPageBySlug(pageData.slug);
  } else if (pageData.parent) {
    parentPage = store.getters.getPageBySlug(pageData.parent);
  }

  return get(parentPage, 'object');
};

/**
 * Gets the schema for a default submit rule, used in forms and checkouts
 *
 * @param {string} viewType can be form or checkout
 * @returns {object}
 */
export const getDefaultSubmitRuleSchema = (viewType = 'form') => ({
  key: 'submit_1',
  action: 'message',
  message: (viewType === 'form') ? 'Form successfully submitted.' : 'Your payment was successfully submitted.',
  reload_show: true,
  is_default: true,
});

const buildFormModel = (scene, view) => {
  view.action = view.action || 'update';
  view.alert = 'none';

  // view.title = view.action === `update` ? `Edit ${view.name}` : `Add ${view.name}`

  view.rules = {
    submits: [
      getDefaultSubmitRuleSchema(),
    ],
  };

  const object = store.getters.getObject(view.source.object);

  const inputs = [];

  let count = 0;

  object.fields.forEach((field) => {
    if (count >= 7) {
      return;
    }

    field = field.raw();

    const inputType = Knack.config.fields[field.type].input_type;

    const isConnection = Boolean((view.source.connection_key && view.source.connection_key === field.key));

    if (!isConnection && inputType !== 'none' && !field.conditional && !field.ecommerce) {
      const newInput = {
        field: {
          key: field.key,
        },
        label: field.name,
        type: Knack.config.fields[field.type].input_type,
      };

      inputs.push(newInput);

      count++;
    }
  });

  view.groups = [
    {
      columns: [
        {
          inputs,
        },
      ],
    },
  ];

  return view;
};

const buildDetailsModel = (page, view) => {
  view.title = view.name.includes('Details') ? view.name : `${view.name} Details`;

  const object = store.getters.getObject(view.source.object);

  const detailsColumn = [];

  let count = 0;

  object.fields.forEach((field) => {
    if (count >= 7) {
      return;
    }

    field = field.raw();

    const isPassword = (field.type === Knack.config.PASSWORD);
    const isUserField = (field.user && String('multiple_choice,user_roles').indexOf(field.type) > -1);

    if (isUserField || isPassword) {
      return;
    }

    const column = {
      key: field.key,
      name: field.name,
    };

    detailsColumn.push(column);

    count++;
  });

  view.columns = [
    {
      groups: [
        {
          columns: [
            detailsColumn,
          ],
        },
      ],
      width: 100,
    },
  ];

  view.layout = 'full';
  view.label_format = 'left';

  return view;
};

const buildTableModel = (page, view, viewOptions) => {
  const object = store.getters.getObject(view.source.object);

  view.columns = [];

  const parentObject = getPageOrParentObject(page);

  let count = 0;

  object.fields.forEach((field) => {
    count++;

    if (count >= 7) {
      return;
    }

    field = field.raw();

    // is this the connection field to the same parent object?
    const isConnectionField = (parentObject && field.type === Knack.config.CONNECTION && field.relationship.object === parentObject);
    const isPassword = (field.type === Knack.config.PASSWORD);
    const isUserField = (view.source.authenticated_user && field.user && String('multiple_choice,user_roles').indexOf(field.type) > -1);

    if (isConnectionField || isUserField || isPassword) {
      return;
    }

    return view.columns.push({
      field: {
        key: field.key,
      },
      header: field.name,
      type: 'field',
    });
  });

  if (viewOptions && viewOptions.pagesToAdd) {
    view = addNewPagesToView(view, viewOptions.pagesToAdd);
  }

  view.rows_per_page = '25';
  view.keyword_search = false;
  view.allow_exporting = false;

  const sortField = (view.columns[0]) ? view.columns[0].field.key : object.fields[0].get('key');

  view.source.sort = [
    {
      field: sortField,
      order: 'asc',
    },
  ];
  view.source.criteria = [];
  view.source.limit = null;

  view.allow_preset_filters = false;
  view.filter_type = 'none';

  return view;
};

const buildListModel = (page, view, viewOptions) => {
  const object = store.getters.getObject(view.source.object);

  const detailsColumn = [];

  const parentObject = getPageOrParentObject(page);

  let count = 0;

  object.fields.forEach((field) => {
    if (count >= 7) {
      return;
    }

    field = field.raw();

    const includeField = (!(parentObject && field.type === Knack.config.CONNECTION && field.relationship.object === parentObject) && !(view.source.authenticated_user && field.immutable) && field.type !== Knack.config.PASSWORD && !field.user);

    if (!includeField && field.type !== Knack.config.EMAIL && field.type !== Knack.config.NAME) {
      return;
    }

    detailsColumn.push({
      key: field.key,
      name: field.name,
    });

    count++;
  });

  view.columns = [
    {
      groups: [
        {
          columns: [
            detailsColumn,
          ],
        },
      ],
      width: 100,
    },
  ];

  view.layout = 'full';
  view.label_format = 'left';
  view.rows_per_page = '25';
  view.allow_limit = true;
  view.allow_exporting = false;
  view.source.sort = [
    {
      field: detailsColumn[0].key,
      order: 'asc',
    },
  ];
  view.source.criteria = [];
  view.source.limit = null;

  if (viewOptions && viewOptions.pagesToAdd) {
    view = addNewPagesToView(view, viewOptions.pagesToAdd);
  }

  return view;
};

const buildCalendarModel = (page, view, viewOptions) => {
  const object = store.getters.getObject(view.source.object);
  const detailsColumn = [];

  const parentObject = getPageOrParentObject(page);

  const eventKey = object.fields.find((field) => field.get('type') === Knack.config.DATE_TIME).get('key');

  let labelField = object.fields.find((field) => field.get('type') === Knack.config.SHORT_TEXT);

  if (!labelField) {
    labelField = object.fields[0];
  }

  const labelKey = labelField.get('key');

  // get event (date_time) and label (short_text) keys
  view.events = {
    event_field: {
      key: eventKey,
    },
    label_field: {
      key: labelKey,
    },
    show_details: true,
    allow_add: true,
    allow_edit: true,
    allow_multiple_per_slot: true,
    allow_all_day: true,
    view: 'agendaWeek',
  };

  let count = 0;

  object.fields.forEach((field) => {
    count++;

    if (count >= 7) {
      return;
    }

    field = field.raw();

    const includeField = (!(parentObject && field.type === Knack.config.CONNECTION && field.relationship.object === parentObject) && !(view.source.authenticated_user && field.immutable) && field.type !== Knack.config.PASSWORD && !field.user);

    if (!includeField && field.type !== Knack.config.EMAIL && field.type !== Knack.config.NAME) {
      return;
    }

    return detailsColumn.push({
      key: field.key,
      name: field.name,
    });
  });

  view.details = {
    columns: [
      {
        groups: [
          {
            columns: [
              detailsColumn,
            ],
          },
        ],
        width: 100,
      },
    ],
    list_layout: 'one-column',
    label_format: 'left',
    layout: 'full',
  };

  let iCount = 0;
  const inputs = [];

  object.fields.forEach((field) => {
    iCount++;

    if (iCount >= 7) {
      return;
    }

    field = field.raw();
    const inputType = Knack.config.fields[field.type].input_type;

    if (inputType === 'none') {
      return;
    }

    // used to have this to prevent user inputs...but these are built in to work with renderer now: && !(view.source.authenticated_user && field.immutable)
    if (parentObject && field.type === Knack.config.CONNECTION && field.relationship.object === parentObject) {
      return;
    }

    const newInput = {
      field: {
        key: field.key,
      },
      label: field.name,
      type: Knack.config.fields[field.type].input_type,
    };

    if (field.format) {
      newInput.format = field.format;
    }

    return inputs.push(newInput);
  });

  view.form = {
    groups: [
      {
        columns: [
          {
            inputs,
          },
        ],
      },
    ],
  };

  view.source.sort = [
    {
      field: detailsColumn[0].key,
      order: 'asc',
    },
  ];
  view.source.criteria = [];
  view.source.limit = null;

  if (viewOptions && viewOptions.pagesToAdd) {
    view = addNewPagesToView(view, viewOptions.pagesToAdd);
  }

  return view;
};

const buildMapModel = (page, view, viewOptions) => {
  const object = store.getters.getObject(view.source.object);

  const addressField = object.fields.find((field) => field.get('type') === Knack.config.ADDRESS);

  let labelField = object.fields.find((field) => field.get('type') === Knack.config.SHORT_TEXT);

  if (!labelField) {
    labelField = object.fields[0];
  }

  const labelKey = labelField.get('key');

  view.address_field = {
    key: addressField.get('key'),
  };

  view.title_field = {
    key: labelKey,
  };

  view.starting_point = 'blank';
  view.map_width = 600;
  view.map_height = 400;
  view.list_width = 300;
  view.rows_per_page = 10;
  view.default_range = 10;

  const detailsColumn = [];

  detailsColumn.push({
    key: labelKey,
    name: labelField.get('name'),
    format: {
      styles: [
        'strong',
      ],
    },
  });

  detailsColumn.push({
    key: addressField.get('key'),
    name: addressField.get('name'),
  });

  view.details = {
    columns: [
      {
        groups: [
          {
            columns: [
              detailsColumn,
            ],
          },
        ],
        width: 100,
      },
    ],
    label_format: 'none',
    list_layout: 'one-column',
    layout: 'full',
  };

  view.source.sort = [
    {
      field: detailsColumn[0].key,
      order: 'asc',
    },
  ];
  view.source.criteria = [];
  view.source.limit = null;

  if (viewOptions && viewOptions.pagesToAdd) {
    view = addNewPagesToView(view, viewOptions.pagesToAdd);
  }

  return view;
};

const buildSearchModel = (page, view, viewOptions) => {
  const searchResultColumns = [];

  const criteriaFields = [
    {
      field: 'keyword_search',
      name: 'Keyword Search',
    },
  ];
  const object = store.getters.getObject(view.source.object);

  let count = 0;

  object.fields.forEach((field) => {
    count++;

    if (count >= 7) {
      return;
    }

    field = field.raw();

    if (field.type === Knack.config.PASSWORD || field.user) {
      return;
    }

    searchResultColumns.push({
      field: {
        key: field.key,
      },
      header: field.name,
      type: 'field',
    });

    return criteriaFields.push({
      field: field.key,
      name: field.name,
    });
  });

  view.results = {
    type: 'table',
    source: {
      type: 'database',
      object: object.key,
    },
    columns: searchResultColumns,
    label_format: 'left',
  };

  view.groups = [
    {
      columns: [
        {
          fields: criteriaFields,
        },
      ],
    },
  ];
  view.results_type = 'table';
  view.allow_exporting = false;
  view.label_format = 'top';

  if (viewOptions && viewOptions.pagesToAdd) {
    view = addNewPagesToView(view, viewOptions.pagesToAdd);
  }

  return view;
};

const buildRichTextModel = (page, view) => {
  view.content = '';

  return view;
};

const buildEcommModel = (page, view, options) => {
  const account = store.getters.app.get('account');

  view.label = 'Payment';
  view.name = 'Charge a payment';
  view.description = '';
  view.update_billing_button_text = 'Update payment information';
  view.submit_button_text = 'Submit Payment';
  view.remove_card_button_text = 'Remove payment information';
  view.summary_fields = [];
  view.ecommerce_test_mode = (account && account.product_plan && account.product_plan.level === 1);
  view.rules = {
    submits: [
      getDefaultSubmitRuleSchema('checkout'),
    ],
    records: [],
    emails: [],
  };
  view.checkout_page = {
    title: 'Complete Payment',
    description: 'The following is a summary of your order. Fill out the form below to enter your payment information and complete the order.',
    columns: [
      {
        groups: [
          {
            columns: [[]],
          },
        ],
        width: 100,
      },
    ],
    layout: 'full',
    label_format: 'left',
    source: {
      object: view.source.object,
    },
  };

  if (!options.formAddView) {
    view.description = '';
    view.submit_button_text = 'Charge';

    switch (view.type) {
      case 'charge':

        view.checkout_page.name = 'Charge';
        view.name = 'Charge a payment';

        break;

      case 'checkout':

        view.submit_button_text = 'Charge';
        view.name = 'Charge a payment';

        break;

      case 'customer':

        view.name = 'Manage a payment method';
        view.checkout_page.name = 'Payment Method';
        view.submit_button_text = 'Save payment information';
        view.checkout_page.title = 'Save or edit payment information';
        view.checkout_page.description = '';

        break;
    }
  }

  return view;
};

const buildMenuModel = (page, view, viewOptions) => {
  view.links = view.links || [];

  if (viewOptions && viewOptions.pagesToAdd) {
    view = addNewPagesToView(view, viewOptions.pagesToAdd);
  }

  return view;
};

const addNewPagesToView = (view, pagesToAdd) => {
  const setItem = function (pageToAdd) {
    if (pageToAdd.type === 'link') {
      return {
        type: view.type === 'calendar' ? 'scene_link' : 'link',
        scene: pageToAdd.slug,
        header: pageToAdd.name,
        link_text: pageToAdd.name,
        remote: true, // Without this, view copy/move will copy/move the linked pages as well.
      };
    }

    // Annoying: item type is not consistent for links to Knack pages
    let childPageType = 'scene_link';

    if (view.type === 'table') {
      childPageType = 'link';
    }

    if (view.type === 'search' && view.results_type === 'table') {
      childPageType = 'link';
    }

    return {
      header: pageToAdd.page.name,
      type: childPageType,
      scene: pageToAdd.page,
      link_text: (pageToAdd.type === 'details') ? 'view' : 'edit',
    };
  };

  switch (view.type) {
    case 'table':

      pagesToAdd.forEach((pageToAdd) => {
        const item = setItem(pageToAdd);

        item.sortable = false;

        if (view.type === 'table') {
          return view.columns.push(item);
        }

        return view.results.columns.push(item);
      });

      break;

    case 'search':

      pagesToAdd.forEach((pageToAdd) => {
        const item = setItem(pageToAdd);

        item.sortable = false;

        if (view.type === 'table') {
          return view.columns.push(item);
        }

        return view.results.columns.push(item);
      });

      break;

    case 'list':

      pagesToAdd.forEach((pageToAdd) => {
        const item = setItem(pageToAdd);

        return view.columns[0].groups[0].columns[0].push(item);
      });

      break;

    case 'details':

      pagesToAdd.forEach((pageToAdd) => {
        const item = setItem(pageToAdd);

        return view.columns[0].groups[0].columns[0].push(item);
      });

      break;

    case 'map':

      pagesToAdd.forEach((pageToAdd) => {
        const item = setItem(pageToAdd);

        return view.details.columns[0].groups[0].columns[0].push(item);
      });

      break;

    case 'calendar':

      pagesToAdd.forEach((pageToAdd) => {
        const item = setItem(pageToAdd);

        return view.details.columns[0].groups[0].columns[0].push(item);
      });

      break;

    case 'menu':

      pagesToAdd.forEach((pageToAdd) => {
        const item = setItem(pageToAdd);

        item.name = item.header;
        item.type = 'scene';
        delete item.header;

        return view.links.push(item);
      });

      break;
  }

  return view;
};

export const buildPageSchema = (page, views, viewOptions = {}) => {
  const viewObject = (viewOptions.object) ? viewOptions.object : page.object;

  if (views && views.length) {
    page.views = [];

    views.forEach((view) => {
      if (isEmpty(view.source)) {
        if (viewOptions.source) {
          view.source = viewOptions.source;
        } else {
          view.source = {
            object: viewObject,
          };
        }
      }

      view = buildViewSchema(page, view);

      return page.views.push(view);
    });
  }

  return page;
};

export const columnDefaults = () => ({
  grouping: false,
  group_sort: 'asc',
  ignore_edit: false,
  ignore_summary: false,
  conn_separator: '',
  conn_link: '',
  link_type: 'text',
  link_text: '',
  link_field: '',
  link_design: undefined,
  link_design_active: false,
  icon: {
    icon: '',
    align: 'left',
  },
  img_gallery: '',
  width: {
    type: 'default',
    units: 'px',
    amount: '50',
  },
  align: 'left',
  rules: [],
});

export const baseChart = (source) => {
  const object = store.getters.getObject(source.object);

  // get first eligible report field for the grouping
  const field = object.getField(object.getFieldsForReports()[0].key);

  return {
    title: '',
    type: 'bar',
    source,
    filters: {
      filter_type: 'none',
      preset_filters: [
        {
          value: '',
          operator: 'contains',
          field: field.key,
        },
      ],
      menu_filters: [
        {
          value: '',
          text: '',
          operator: 'contains',
          field: field.key,
        },
      ],
      allow_preset_filters: false,
    },
    groups: [
      {
        group: `${field.type}-${field.key}`,
        type: field.type,
        field: field.key,
        label: field.name,
      },
    ],
    calculations: [
      {
        field: 'count',
        calculation: 'total',
        label: 'Total',
      },
    ],
    description: '',
    options: {
      exclude_empties: false,
      export_links: false,
      child_records: false,
      hide_negatives: false,
      shouldShowDataTable: false,
    },
    layout: {
      legend: 'bottom',
      stacking: 'none',
      tilt_labels: false,
      data_labels: true,
      dimensions: 'auto',
      chart_width: '500',
      chart_height: '350',
      legend_width: '170',
    },
    preview: false,
  };
};

export const defaultReport = (reportType, source) => {
  const reportDefaultSchemaMapping = {
    bar: defaultReportBar,
    pivot: defaultReportPivot,
    pie: defaultReportPie,
    area: defaultReportArea,
    line: defaultReportLine,
  };

  const defaultReportSchemaFunction = reportDefaultSchemaMapping[reportType];

  return defaultReportSchemaFunction(source);
};

export const defaultReportBar = (source) => {
  const defaultChart = baseChart(source);

  return {
    ...defaultChart,
    type: 'bar',
    layout: { ...defaultChart.layout, bar_type: 'bar' },
  };
};

export const defaultReportPivot = (source) => {
  const defaultChart = baseChart(source);

  delete defaultChart.layout;

  return {
    ...defaultChart,
    type: 'table',
    calculations: [
      {
        field: 'count',
        calculation: 'count',
        label: 'Count',
      },
    ],
    summaries: [],
    row_summaries: [],
  };
};

export const defaultReportPie = (source) => {
  const defaultChart = baseChart(source);

  return {
    ...defaultChart,
    type: 'pie',
    layout: { ...defaultChart.layout, legend: 'right' },
  };
};

export const defaultReportLine = (source) => {
  const defaultChart = baseChart(source);

  return { ...defaultChart, type: 'line' };
};

export const defaultReportArea = (source) => {
  const defaultChart = baseChart(source);

  return { ...defaultChart, type: 'area' };
};

export const defaultReportChildPageSchema = (reportTitle, reportType, sourceObject, sourceObjectFields, sourceViewKey, parentSlug) => {
  const columns = sourceObjectFields.map(({ key, name }) => ({
    field: {
      key,
    },
    header: name,
    type: 'field',
  }));

  const name = `${reportTitle || `${reportType.substr(0, 1).toUpperCase() + reportType.substr(1)} Report`} Records`;

  return {
    name,
    parent: parentSlug,
    modal: true,
    source: {
      view: sourceViewKey,
    },
    views: [
      {
        name,
        type: 'table',
        source: sourceObject,
        title: '',
        description: '',
        columns,
        rows_per_page: '25',
        keyword_search: true,
        allow_exporting: false,
        allow_preset_filters: false,
        filter_type: 'fields',
        display_pagination_below: true,
      },
    ],
  };
};

export const addReportToView = (groups, reports, report, addLocationType = 'group', groupIndex = 0, columnIndex = 0, itemIndex = 0) => {
  report.key = shortid.generate();

  if (isEmpty(reports)) {
    reports = [];
  }

  groups = addItemKey(report.key, groups, addLocationType, groupIndex, columnIndex, itemIndex);

  return {
    reports: [
      ...reports,
      report,
    ],
    groups,
  };
};

export const buildInputFromField = (field) => ({
  field: {
    key: field.get('key'),
  },
  label: field.get('name'),
  required: field.get('required'),
  type: field.get('type'),
  read_only: field.get('read_only'),
  format: field.get('format'),
});

export const defaultEmptyPaymentProcessor = () => ({
  currency: 'USD',
});

export const defaultCharacterLimit = 75;

export const isCharacterLimitValid = (value) => (value && value > 0);
