<template>
  <div
    v-if="showPageSelect"
    class="kn-pagination-arrows__container group group-[&:not(.kn-view)]/shared:gap-2 group-[&:not(.kn-view)]/shared:ml-2"
  >
    <div
      :class="[buttonClasses, previousPageClasses]"
      style="margin-right: 4px;"
      @click="$emit('changePage', (currentPage - 1))"
    >
      <Icon
        class="group-[&:not(.kn-view)]/shared:text-default group-hover:text-brand-400"
        type="arrow-back"
      />
    </div>
    <div
      :class="[buttonClasses, nextPageClasses]"
      @click="$emit('changePage', (currentPage + 1))"
    >
      <Icon
        class="group-[&:not(.kn-view)]/shared:text-default group-hover:text-brand-400"
        type="arrow-forward"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    currentPage: {
      type: Number,
      default: 0,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
  },
  emits: ['changePage'],
  computed: {
    buttonClasses() {
      return 'group-[&:not(.kn-view)]/shared:p-3 group-[&:not(.kn-view)]/shared:rounded-lg group-[&:not(.kn-view)]/shared:border group-[&:not(.kn-view)]/shared:border-solid group-[&:not(.kn-view)]/shared:border-default group-[&:not(.kn-view)]/shared:bg-white group-[&:not(.kn-view)]/shared:text-emphasis group-[&:not(.kn-view)]/shared:h-10 group-[&:not(.kn-view)]/shared:m-0 group-[&:not(.kn-view)]/shared:hover:bg-brand-50 group-[&:not(.kn-view)]/shared:hover:border-brand-600';
    },

    previousPageClasses() {
      return {
        'kn-pagination-arrows__arrow': true,
        'kn-pagination-arrows__arrow--disabled': this.currentPage === 1,
      };
    },

    nextPageClasses() {
      return {
        'kn-pagination-arrows__arrow': true,
        'kn-pagination-arrows__arrow--disabled': this.totalPages === 0 || this.currentPage === this.totalPages,
      };
    },

    showPageSelect() {
      return this.totalPages > 1;
    },
  },
};
</script>
<style scoped lang="scss">
.kn-pagination-arrows {

  &__container {
    padding: 0;
    margin: 0;
    margin-left: 1em;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__arrow {

    cursor:pointer;
    opacity: .6;
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:hover {
      opacity: .8;
    }

    &--disabled {
      opacity:.2;
      pointer-events: none;
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }
}
</style>
