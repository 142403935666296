<template>
  <Modal
    v-if="field"
    :title="`Edit Field - ${field.name}`"
    @close="closeModal"
  >
    <nav
      id="field-settings-modal"
      class="tabsMenu tabsMenu-big border-0 -mt-2 mb-6"
      style="margin-bottom: 1.5em;"
    >
      <router-link
        v-slot="{ isActive }"
        class="tabsMenu_tab"
        :class="routerLinkClasses"
        :to="routerLink('settings')"
        data-cy="field-settings"
      >
        <span :class="[linkItemContentWrapperClasses, getLinkItemContentWrapperActiveClasses(isActive), 'group']">
          <Icon
            type="settings"
            class="tabsMenu_icon fill-current h-4 w-4"
            :class="getLinkItemIconActiveClasses(isActive)"
          /> Settings
        </span>
      </router-link>
      <router-link
        v-if="canHaveValidationRules"
        v-slot="{ isActive }"
        class="tabsMenu_tab"
        :class="routerLinkClasses"
        :to="routerLink('rules/validation')"
        data-cy="field-validation-rules"
      >
        <FieldIndicator
          icon="validation-rules"
          title="Validation"
          :count="validationRulesCount"
          :wrapper-classes="[linkItemContentWrapperClasses, getLinkItemContentWrapperActiveClasses(isActive), 'group']"
          :icon-classes="getLinkItemIconActiveClasses(isActive)"
        />
      </router-link>
      <router-link
        v-if="canHaveConditionalRules"
        v-slot="{ isActive }"
        class="tabsMenu_tab"
        :class="routerLinkClasses"
        :to="routerLink('rules/conditional')"
        data-cy="field-conditional-rules"
      >
        <FieldIndicator
          icon="conditional-rules"
          title="Conditional Rules"
          :count="conditionalRulesCount"
          :wrapper-classes="[linkItemContentWrapperClasses, getLinkItemContentWrapperActiveClasses(isActive), 'group']"
          :icon-classes="getLinkItemIconActiveClasses(isActive)"
        />
      </router-link>
    </nav>
    <FormWrapper
      :is-submit-button-disabled="shouldDisableButton || fieldSettingsAreEqual"
      class="margin-bottom"
      :errors="errors"
      :show-submit="true"
      submit-text="Update Field"
      @submit="onUpdate"
    >
      <div
        class="margin-bottom-double"
        style="max-width: none;"
      >
        <RouterView v-model:field-local="fieldLocal" />
      </div>
    </FormWrapper>
  </Modal>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import Modal from '@/components/ui/Modal';
import Icon from '@/components/ui/Icon';
import FieldUtils from '@/components/fields/FieldUtils';
import FormWrapper from '@/components/ui/FormWrapper';
import RequestUtils from '@/components/util/RequestUtils';
import FieldIndicator from '@/components/fields/FieldIndicator';

export default {
  components: {
    Modal,
    Icon,
    FormWrapper,
    FieldIndicator,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  data() {
    return {
      rootURL: {
        type: String,
        default: '',
      },
      backLink: {
        type: String,
        default: '',
      },
      fieldLocal: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  computed: {
    canHaveValidationRules() {
      return this.field.canUseRules();
    },
    canHaveConditionalRules() {
      return this.field.canUseRules();
    },
    fieldSettingsAreEqual() {
      const allCurrentFormValues = this.fieldLocal;
      const previousFormValues = pick(
        { ...this.field.raw(), default: this.field.raw().default || '' },
        Object.keys(allCurrentFormValues),
      );
      return isEqual(previousFormValues, allCurrentFormValues);
    },
    validationRulesCount() {
      return isEmpty(this.fieldLocal.validation) ? 0 : this.fieldLocal.validation.length;
    },
    conditionalRulesCount() {
      return this.fieldLocal.conditional ? this.fieldLocal.rules.length : 0;
    },
    routerLinkClasses() {
      return 'border-0 p-0 flex justify-center mr-2 h-8';
    },
    linkItemContentWrapperClasses() {
      return 'flex items-center text-default w-full h-full rounded-lg px-2 py-1';
    },
    shouldDisableButton() {
      if (this.fieldLocal.type === 'image' || this.fieldLocal.type === 'file') {
        return this.fieldLocal.rules.some((rule) => _.isEmpty(rule.criteria));
      }
      return false;
    },
  },
  // set the backlink to the previous route
  created() {
    if (!isEmpty(this.field) && this.field.raw()) {
      this.fieldLocal = JSON.parse(JSON.stringify(this.field.raw()));
    }

    if (!isEmpty(this.field)) {
      [this.backLink] = this.$route.path.split(`/${this.field.key}`);

      this.rootURL = `${this.backLink}/${this.$route.params.fieldKey}/`;
    } else {
      [this.backLink] = this.rootURL = this.$route.path.split('/add');
    }
  },
  methods: {
    // KLUDGE: ensure the links can be active; has to be a better way here
    routerLink(which) {
      return this.rootURL + which;
    },
    onUpdate() {
      log('field.onUpdate(), fieldLocal:', this.fieldLocal);

      this.commitRequest({
        validate: () => this.field.validate(this.fieldLocal),
        request: () => this.field.update(this.fieldLocal),
        onSuccess: () => this.closeModal(),
      });
    },
    closeModal() {
      this.$router.push({
        path: this.backLink,
        query: this.$route.query, // Retain any query vars, e.g. record filters if this was triggerd from a record column
      });
    },
    getLinkItemContentWrapperActiveClasses(isActive) {
      return [
        {
          'hover:bg-brand-50 hover:text-emphasis': !isActive,
          'text-emphasis bg-brand-100': isActive,
        },
      ];
    },
    getLinkItemIconActiveClasses(isActive) {
      return [
        {
          'text-default': !isActive,
          'group-hover:text-brand-400': !isActive,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
#field-current {
  font-size: 1.05em;
  strong {
    margin-right: 6px;
    vertical-align: middle;
  }
}

#field-settings-modal {

  form > div {
    width: 100%;
  }
}
</style>
