export default {
  methods: {
    addViewProperties(propertyObject, parentProperty) {
      const source = this.$store.state.activeView.attributes[parentProperty];

      this.$store.state.activeView.attributes[parentProperty] = { ...source, ...propertyObject };
    },

    getField(fieldKey) {
      if (!fieldKey || !this.object?.fields) {
        return null;
      }

      // does field key have thumbs?
      const safeFieldKey = fieldKey.split(':')[0];

      // try view object first
      let field = this.object.fields.find((objectField) => objectField.key === safeFieldKey);

      // it could be a connected field that would not originate with the source object
      if (!field) {
        field = this.$store.getters.getField(safeFieldKey);
      }

      return field;
    },

    onUpdateViewProperty({ propertyName, newValue }) {
      if (!this.hasActiveView) {
        throw new Error('Cannot update property when no active view is defined.');
      }

      this.view.attributes[propertyName] = newValue;
    },
  },
  computed: {

    // TODO: separate raw vs. model computed page property
    page() {
      return this.$store.getters.activePage;
    },

    pageRaw() {
      return this.page?.raw;
    },

    // TODO: rename other computed property pageRaw
    localPage() {
      return this.$store.getters.activePage;
    },

    view() {
      return this.$store.state.activeView;
    },

    activeView() {
      return this.$store.state.activeView;
    },

    hasActiveView() {
      return Boolean(this.$store.state.activeView);
    },

    viewRaw() {
      const defaultViewRaw = {
        source: {},
      };

      return (this.view?.raw) ? this.view.raw() : defaultViewRaw;
    },

    object() {
      if (this.view?.source?.object) {
        return this.$store.getters.getObject(this.view.source.object);
      }

      return null;
    },
  },
};
