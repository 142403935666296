<template>
  <Modal
    :size="modalSize"
    title="Edit Filters"
    @close="onClose"
  >
    <FieldList
      v-model:boolean-match="localFilters.match"
      class="margin-bottom-double"
      :object-key="objectKey"
      :items="localFilters.rules"
      :can-be-empty="true"
      empty-text="Add Filter"
      :rule-type="RuleType.Filter"
      :show-boolean-options="showBooleanOptions"
      :start-with-default="true"
      @update:items="onUpdateRules"
    />

    <div class="save submit-buttons flex justify-end">
      <BuilderButton
        type="submit"
        theme="confirm"
        data-cy="save-filters"
        class="text-base text-white rounded-lg p-3 border-0 leading-4 h-auto"
        @click="onSubmit"
      >
        Submit
      </BuilderButton>
    </div>
  </Modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

import FieldList from '@/components/ui/lists/FieldList';
import Modal from '@/components/ui/Modal';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';
import BuilderButton from '@/components/ui/BuilderButton';
import { RuleType } from '@/constants/rules';

export default {
  components: {
    FieldList,
    Modal,
    BuilderButton,
  },
  mixins: [
    TranslationUtils,
  ],
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    modalSize: {
      type: String,
      default: 'large',
    },
    objectKey: {
      type: String,
      default: '',
    },
    showBooleanOptions: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'close',
    'update',
  ],
  data() {
    return {
      localFilters: cloneDeep(this.filters),
      RuleType,
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSubmit() {
      this.$emit('update', this.localFilters);
    },
    onUpdateRules(newRules) {
      this.localFilters.rules = newRules;
    },
  },
};
</script>

<style lang="scss">
</style>
