import store from '@/store';

/**
 * The plan level where emergency alerts become available.
 * @type {number}
 */
const FEATURE_LEVEL_EMERGENCY_ALERT = 4;

/**
 * Return whether the account is a trial or not
 * @returns {Boolean}
 */
export function isTrial() {
  if (!store.state.app || !store.state.app.get) {
    return false;
  }

  const account = store.state.app.get('account');

  const { id } = account.product_plan;

  return id === 'trial';
}

/**
 * Return whether the account is a starter or not
 * @returns {Boolean}
 */
export function isStarter() {
  if (!store.state.app || !store.state.app.get) {
    return false;
  }

  const account = store.state.app.get('account');

  const { id } = account.product_plan;

  return id.startsWith('starter');
}

/**
 * Returns whether to nor the plan level is at least the given level.
 * Do not export this, instead export a new function for the particular feature.
 *
 * @param {number} minimumLevel
 * @returns {boolean}
 * @private
 */
function _isAtLeastLevel(minimumLevel) {
  const account = store.state.app.get('account');

  if (!account.product_plan) {
    return (minimumLevel === 0);
  }

  const accountLevel = account.product_plan.level;

  return (accountLevel >= minimumLevel);
}

/**
 * Whether or not the current account is allowed to issue an emergency alert.
 *
 * @returns {boolean}
 */
export function isEmergencyAlertAllowed() {
  return _isAtLeastLevel(FEATURE_LEVEL_EMERGENCY_ALERT);
}

/**
 * Simple check to see if the plan is an internal plan
 * @return {Boolean}
 */
export function isKnackInternal() {
  if (!store.state.app || !store.state.app.get) {
    return false;
  }

  const account = store.state.app.get('account');

  return account.product_plan.id === 'knack_internal';
}

/**
 * Check to see if the user is working on a shared builder.
 * Sometimes there may be UI we don't show to non-owners, like billing updates
 * @return {Boolean}
 */
export function isSharedBuilder() {
  if (!store.state.app || !store.state.app.get) {
    return false;
  }

  const appId = store.state.app.get('id');
  // eslint-disable-next-line camelcase
  const sharedApps = window.Knack.Api.app_rights.filter((app_right) => app_right.role === 'app_editor');

  // eslint-disable-next-line camelcase
  if (sharedApps.some((apps) => apps.app_id === appId)) {
    return true;
  }

  return false;
}
