import castArray from 'lodash/castArray';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { NEW_PAGE_KEY } from '@/lib/page/page-constants';
import { parseHierarchyRecursive, sortPages } from '@/lib/page/page-helper';
import { getChildPageOrder } from '@/lib/page/view-child-page-helper';
import Page from '@/store/models/Page';
import viewCache from '@/store/utils/ViewCache';

const storeState = {
  all: [],
  pageKeys: [],
  isCancelling: false,
  pageHasActiveUpdates: false,
  viewHasActiveUpdates: false,
  previewRecordId: null,
  useSampleData: null,
  chartType: null,
  viewErrors: [],
};

/**
 * Note about using view getters.
 * Since straight getters are cached until the state changes and all these view getters now
 * call the viewCache which is not watched, they must all be accessed through a function call
 * in order to avoid caching.
 *
 * Example:
 *   someView: () => (viewKey) => viewCache.getViewBySomething(viewKey)
 */
const storeGetters = {

  pageKeys: (state) => state.pageKeys,
  isCancelling: (state) => state.isCancelling,
  pageHasActiveUpdates: (state) => state.pageHasActiveUpdates,
  viewHasActiveUpdates: (state) => state.viewHasActiveUpdates,
  getViewErrors: (state) => state.viewErrors,

  pagesOrder: (state, getters) => getters['page/pagesOrder'],

  /**
   * Gets all the pages.
   *
   * @returns {function(): RawPage[]}
   */
  allPages: (state, getters) => getters['page/pages'],

  /**
   * Gets the page by its key.
   *
   * @returns {function(string): (RawPage | undefined)}
   */
  getPageByKey: (state, getters) => getters['page/getPageByKey'],

  /**
   * Gets the page by its slug.
   *
   * @returns {function(string): (RawPage | undefined)}
   */
  getPageBySlug: (state, getters) => getters['page/getPageBySlug'],

  /**
   * Returns whether or not any pages exist.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {function(): (boolean)}
   */
  isEmptyPages: (state, getters) => () => {
    const allPages = getters.allPages.filter((page) =>

      // Do not allow temporary new pages to count here.
      page.key !== NEW_PAGE_KEY);

    if (allPages.length > 1) {
      return false;
    }

    // Every new app gets a blank homepage, so pages are effectively empty if this is the only page
    const key = get(allPages, '0.key');
    const views = viewCache.getViewsByPage(key);

    return (key === 'scene_1' && isEmpty(views));
  },

  /**
   * Gets a Page instance for the page data based on the given key.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {function(string): (Page | undefined)}
   */
  initPageByKey: (state, getters) => (key) => {
    const rawPage = getters.getPageByKey(key);
    const rawViews = viewCache.getViewsByPage(key);

    return (rawPage) ? new Page(rawPage, rawViews) : undefined;
  },

  /**
   * Gets a Page instance for the page data based on the given slug.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {function(string): (Page | undefined)}
   */
  initPageBySlug: (state, getters) => (slug) => {
    const rawPage = getters.getPageBySlug(slug);
    const rawViews = (rawPage) ? viewCache.getViewsByPage(rawPage.key) : [];

    return (rawPage) ? new Page(rawPage, rawViews) : undefined;
  },

  /**
   * Returns whether or not the page exists.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {function(string): boolean}
   */
  doesPageExist: (state, getters) => (pageKey) => Boolean(getters.getPageByKey(pageKey)),

  /**
   * Returns whether a login page exists.
   *
   * @returns {boolean}
   */
  doesLoginPageExist: (state, getters) => getters.allPages.some((page) => page.type === 'authentication' && !page.isOrphan),

  /**
   * Returns whether or not the view exists.
   *
   * @returns {function(string): (boolean)}
   */
  doesViewExist: () => (viewKey) => Boolean(viewCache.getViewByKey(viewKey)),

  /**
   * Gets a single raw view.
   *
   * @returns {function(string): object}
   */
  getViewByKey: () => (viewKey) => viewCache.getViewByKey(viewKey),

  /**
   * Gets all the raw views for a page.
   *
   * @returns {function(string): object[]}
   */
  getViewsByPageKey: () => (pageKey) => viewCache.getViewsByPage(pageKey),

  // Return all pages for dropdowns
  getStarterPlusChildPages: (state, getters) => () => {
    // recursive function to follow children
    const addChildPages = function addChildPages(allLinks, rawPage, label) {
      rawPage.children.forEach((childRawPage) => {
        const childLabel = `${label} > ${childRawPage.name}`;

        allLinks.push({
          label: childLabel,
          slug: childRawPage.slug,
          key: childRawPage.key,
        });

        addChildPages(allLinks, childRawPage, childLabel);
      });
    };

    const links = [];

    // don't need menu pages
    getters['page/startPages'].forEach((rawPage) => {
      links.push({
        label: rawPage.name,
        slug: rawPage.slug,
        key: rawPage.key,
      });

      addChildPages(links, rawPage, rawPage.name);
    });

    return links;
  },

  // Form > Submit Rules uses this to find eligible pages to redirect to
  getStarterPlusGlobalChildPages: (state, getters) => (ignoreRecordPages = true) => {
    // recursive function to follow children
    const addChildPages = function addChildPages(allLinks, rawPage, label) {
      rawPage.children.forEach((childRawPage) => {
        if (childRawPage.object && ignoreRecordPages) {
          return;
        }

        const childLabel = `${label} > ${childRawPage.name}`;

        allLinks.push({
          label: childLabel,
          slug: childRawPage.slug,
          key: childRawPage.key,
        });

        addChildPages(allLinks, childRawPage, childLabel);
      });
    };

    const links = [];

    // don't need menu pages
    getters['page/startPages'].forEach((rawPage) => {
      links.push({
        label: rawPage.name,
        slug: rawPage.slug,
        key: rawPage.key,
      });

      if (!rawPage.object) {
        addChildPages(links, rawPage, rawPage.name);
      }
    });

    return links;
  },

  // Form > Submit Rules uses this to find eligible pages to redirect to
  // Optional second parameter to limit returning children of children as per https://knack.atlassian.net/browse/BV3-1474
  getStarterPlusChildPagesByObject: (state, getters) => (objectKey, includeChildrenOfChildren = true) => {
    // recursive function to follow children
    const addChildPages = function addChildPages(allLinks, rawPage, label) {
      rawPage.children.forEach((childRawPage) => {
        const childLabel = `${label} > ${childRawPage.name}`;

        const hasNoObject = !childRawPage.object;
        const hasMatchingObject = (childRawPage.object && childRawPage.object === objectKey);

        if (hasNoObject || hasMatchingObject) {
          allLinks.push({
            label: childLabel,
            page: childRawPage.slug,
            slug: childRawPage.slug,
            key: childRawPage.key,
          });
        }

        if (hasNoObject && includeChildrenOfChildren) {
          addChildPages(allLinks, childRawPage, childLabel);
        }
      });
    };

    const links = [];

    // don't need menu pages
    getters['page/startPages'].forEach((rawPage) => {
      links.push({
        label: rawPage.name,
        page: rawPage.slug,
        slug: rawPage.slug,
        key: rawPage.key,
      });

      addChildPages(links, rawPage, rawPage.name);
    });

    return links;
  },

  getDetailPagesByObject: (state, getters) => (objectKey) => {
    const links = [];

    getters.allPages.forEach((page) => {
      if (page.raw.object !== objectKey) {
        return;
      }

      links.push({
        label: parseHierarchyRecursive(page, true),
        parentKey: page.getTopLevelParent().key,
        page: page.slug,
        key: page.key,
      });
    });

    const order = getters.pagesOrder;

    // Sort these links by their top level page.
    links.sort((aLink, bLink) => order.indexOf(aLink.parentKey) - order.indexOf(bLink.parentKey));

    log('getDetailPagesByObject()', links);

    return links;
  },

  getChartType: (state) => () => state.chartType,
};

const storeMutations = {

  cancelViewUpdates(state) {
    return state.activeView.cancelUpdates();
  },
  setPreviewRecordId(state, val) {
    state.previewRecordId = val;
  },
  setUseSampleData(state, val) {
    state.useSampleData = val;
  },

  setChartType(state, chartType) {
    state.chartType = chartType;
  },

  setIsCancelling(state, newValue) {
    state.isCancelling = Boolean(newValue);
  },

  setPageHasActiveUpdates(state, newValue) {
    state.pageHasActiveUpdates = Boolean(newValue);
  },

  setViewHasActiveUpdates(state, newValue) {
    state.viewHasActiveUpdates = Boolean(newValue);
  },

  /**
   * Sets a view in the cache or adds it if it doesn't exist.
   *
   * @param {object} state
   * @param {object} payload
   * @param {object} payload.viewData
   * @param {string} payload.pageKey
   */
  setView(state, { viewData, pageKey }) {
    viewCache.setView(viewData, pageKey);
  },

  /**
   * Removes a page from the cache.
   *
   * @param {object} state
   * @param {object} payload
   * @param {string} payload.viewKey
   * @param {string} payload.pageKey
   */
  removeView(state, { viewKey, pageKey }) {
    viewCache.removeView(viewKey, pageKey);
  },

  /**
   * Sets view errors.
   *
   * @param {object} state
   * @param {array} viewErrors
   */
  setViewErrors(state, viewErrors) {
    state.viewErrors = castArray(viewErrors || []);
  },

  /**
   * Clears the view errors.
   *
   * @param {object} state
   */
  clearViewErrors(state) {
    state.viewErrors = [];
  },
};

const storeActions = {
  async setChartType({ commit }, chartType) {
    commit('setChartType', chartType);
  },

  /**
   * Adds a page to the page cache.
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {function(string, *)} context.dispatch
   * @param {object} context.getters
   * @param {object} context.state
   * @param {object} pageData
   * @returns {Promise<RawPage>}
   */
  async addPage({
    commit, dispatch, getters, state,
  }, pageData) {
    log('pages.addPage()', pageData);

    const pageExists = getters.getPageByKey(pageData.key);

    if (pageExists) {
      throw new Error(`Cannot add page ${pageData.key}/${pageData.slug} because it already exists.`);
    }

    const addPageOptions = {};
    const loginChildren = [];

    if (pageData.type === 'authentication') {
      // If we are adding a login to a child page we want to maintain the child page index.
      // Adding a single login page will only have a single login child.
      // Adding a global login page will result in multiple login children.
      getters.allPages.forEach((otherPage, otherPageIndex) => {
        if (otherPage.raw.parent === pageData.slug) {
          if (isEmpty(loginChildren)) {
            // Set the add login page index to the first login child.
            addPageOptions.addIndex = otherPageIndex;
          }

          loginChildren.push(otherPage);
        }
      });
    } else if (pageData.type !== 'user') {
      // Place the new page just before the first user page.
      const firstUserPageIndex = getters.allPages.findIndex((rawPage) => rawPage.isUserPage());

      if (firstUserPageIndex !== -1) {
        addPageOptions.addIndex = firstUserPageIndex;
      }
    }

    // TODO: this needs to reflect the sort order of the inserting page
    const [page] = await dispatch('page/addPages', {
      pages: [pageData],
      ...addPageOptions,
    });

    if (!isEmpty(loginChildren)) {
      // Sometimes with login pages the child page exists before the parent page and so the
      // parent/child relationship isn't set correctly, so reset it here.
      loginChildren.forEach((childPage) => {
        dispatch('page/processPage', {
          page: childPage,
        });
      });
    }

    if (page.parent) {
      // const parentPage = page.parent
      //
      // parentPage.addChildPage(page)

      await dispatch('page/navVisibility/showNavChildren', page.parent.key);

      // return
    }

    // no parent
    // add any child pages to this page just in case
    // getters.getPagesByParentSlug(page.slug).forEach((childPage) => {
    //
    //   page.addChildPage(childPage)
    // })

    // if any children, make sure this page is open in the pages nav
    if (page.hasChildPages()) {
      await dispatch('page/navVisibility/showNavChildren', page.key);
    }

    return page;
  },

  /**
   * Removes a page.
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {function(string, *)} context.dispatch
   * @param {object} context.getters
   * @param {string} pageKey
   * @returns {Promise}
   */
  async removePage({ commit, dispatch, getters }, pageKey) {
    log('pages.removePage()', pageKey);

    /** @type {RawPage} pageToDelete */
    const pageToDelete = getters.getPageByKey(pageKey);

    if (!pageToDelete) {
      return;
    }

    await dispatch('page/removePage', { page: pageToDelete });

    await dispatch('page/navVisibility/clear', pageKey);

    // Delete child from parent and menuParent (as needed).
    if (pageToDelete.parent) {
      /** @type {RawPage} parent */
      const { parent } = pageToDelete;

      parent.removeChild(pageKey);

      // If the parent now has no children, hide the children in the nav visibility store.
      if (isEmpty(parent.children)) {
        await dispatch('page/navVisibility/hideNavChildren', parent.key);
      }
    }

    if (pageToDelete.menuParent) {
      /** @type {RawPage} menuParent */
      const { menuParent } = pageToDelete;

      menuParent.removeMenuChild(pageKey);

      // If the menu parent now has no children, hide the children in the nav visibility store.
      if (isEmpty(menuParent)) {
        await dispatch('page/navVisibility/hideNavChildren', menuParent.key);
      }
    }

    // If a login page is deleted we need to reset the authenticated properties of child pages
    if (pageToDelete.isLoginPage()) {

      // Jon Note: I am not exactly sure what to do here because I don't know how the page flows
      // are handled. If this page had children, do they can promoted to top level pages?

      // let authenticated = false
      // let authenticationProfiles = []
      //
      // // if this login page had a parent, use the parent's auth properties
      // if (pageToDelete.parent) {
      //
      //   /** @type {RawPage} parent */
      //   const { parent } = pageToDelete
      //
      //   authenticated = parent.authenticated
      //   authenticationProfiles = parent.authentication_profiles
      // }
      //
      // dispatch(`page/processPage`, page)
      //
      // pageToDelete.children.forEach((childPage) => {
      //
      //
      // })
      // // reset any child pages
      // getters.getPagesByParentSlug(pageToDelete.slug).forEach((childPage) => {
      //
      //   // Jon: TODO: Update the page structure instead? Or just the pages?
      //   childPage.checkAuthenticationProperties(authenticated, authentication_profiles)
      // })
    }
  },

  /**
   * Updates the RawPage data.
   *
   * @param {object} context
   * @param {function(string, *)} context.dispatch
   * @param {object} context.getters
   * @param {object} payload
   * @param {string} payload.pageKey
   * @param {object} payload.updates
   */
  async updatePageLocally({ dispatch, getters }, { pageKey, updates }) {
    log('pages.updatePageLocally()', pageKey);

    /** @type {RawPage} page */
    const page = getters.getPageByKey(pageKey);

    if (!page) {
      return;
    }

    log('pages.updatePageLocally()', { page, updates });

    // If a new login page is created, the page added has an undefined type.
    // If type returned from the server is also undefined, then set it to `page` here.
    if (!page.type && !updates.type) {
      updates.type = 'page';
    }

    const pageViews = updates.views;

    if (pageViews) {
      await dispatch('page/setPageViews', { pageKey, views: pageViews });
    }

    // Make sure views aren't added to the pages.
    delete updates.views;

    // Check to see if the page had a parent and that parent changed.
    if (page.raw.parent && page.raw.parent !== updates.parent && page.parent) {
      // If this page's parent is changing, then make sure to remove this page from the parent's children list.
      page.parent.children = page.parent.children.filter((childPage) => childPage.key !== pageKey);
    }

    page.updatePageData(updates);

    // Re-process this page in the page cache to make sure everything is properly updated.
    await dispatch('page/processPage', { page });

    const { activePage } = getters;

    if (activePage && activePage.key === pageKey) {
      activePage.updatePageSchema(page.raw, pageViews);
      activePage.loadPropertiesFromRawPage(page);
    }
  },

  /**
   * Sorts the local child page order.
   *
   * @param {object} context
   * @param {object} context.getters
   * @param {object} payload
   * @param {string} pageKey
   * @returns {Promise<void>}
   */
  async sortChildPagesLocally({ getters }, { pageKey }) {
    /** @type {RawPage} page */
    const page = getters.getPageByKey(pageKey);

    if (!page) {
      return;
    }

    page.children = sortPages(page.children, getChildPageOrder(page));
  },

  /**
   * Re-processes the page for authentication and redoes the page structure.
   *
   * @param {object} context
   * @param {function(string, *)} context.dispatch
   * @param {object} context.getters
   * @param {object} payload
   * @param {string} payload.pageKey
   */
  async reprocessPage({ dispatch, getters }, { pageKey }) {
    /** @type {RawPage} page */
    const page = getters.getPageByKey(pageKey);

    if (!page) {
      return;
    }

    dispatch('page/processPage', { page });
  },

  async setActivePageByKey({ commit, getters }, { pageKey, setIfNotFound = false }) {
    const rawPage = getters.getPageByKey(pageKey);

    if (!rawPage) {
      log(`Page with key ${pageKey} not found in cache.`);

      if (setIfNotFound) {
        commit('clearActivePage');
      }

      return;
    }

    const rawViews = getters.getViewsByPageKey(pageKey);

    const page = new Page(rawPage, rawViews);

    commit('activePage', page);
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions,
};
