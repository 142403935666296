<template>
  <div id="settings">
    <NavigationGuardConfirm
      v-if="navigationGuard.showConfirm"
      :path="navigationGuard.path"
      @save="onSaveAppDesignSettings"
      @cancel="onCancelAppDesignSettings"
      @close="navigationGuard.showConfirm = false"
    />

    <template v-if="showIndex">
      <Transition name="fade">
        <SettingsIndex />
      </Transition>
    </template>

    <template v-else>
      <div
        id="settings-wrapper"
        class="builderLayout_builderPane"
        @mousemove="handleLeftPanelResizeOnDrag"
        @mouseup="cancelLeftPanelResizing"
        @mouseleave="cancelLeftPanelResizing"
      >
        <div
          id="settings-menu"
          class="panel builderLayout_toolbox builderLayout_toolbox-medium"
          :class="toolboxWidth"
          :style="{ flexBasis: leftPanelFlexBasis}"
        >
          <RouterView
            v-slot="{ Component }"
            name="nav"
          >
            <Transition :name="transitionName">
              <component :is="Component" />
            </Transition>
          </RouterView>
          <Transition name="slide-up">
            <ToolboxSave
              v-if="applicationHasPendingDesignChanges && isDesignSettingsPath"
              data-test="settings-toolbox-save"
              :data-feature="isDesignSettingsPath ? 'save_design_button' : ''"
              @save="onSaveAppDesignSettings"
              @cancel="onCancelAppDesignSettings"
            />
          </Transition>
        </div>
        <PanelDivider />
        <div
          id="settings-body"
          class="builderLayout_main border-solid border-x-0 border-b-0 border-t border-subtle"
        >
          <RouterView
            ref="settingsRibbon"
            name="ribbon"
          />
          <RouterView name="body" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';
import ApplicationDesignUtils from '@/components/util/ApplicationDesignUtils';
import Icon from '@/components/ui/Icon';
import NavigationGuardConfirm from '@/components/ui/NavigationGuardConfirm';
import RequestUtils from '@/components/util/RequestUtils';
import SettingsIndex from '@/components/settings/SettingsIndex';
import ToolboxSave from '@/components/layout/ToolboxSave';
import PanelDivider from '@/components/layout/PanelDivider';

export default {
  components: {
    Icon,
    NavigationGuardConfirm,
    SettingsIndex,
    ToolboxSave,
    PanelDivider,
  },
  mixins: [
    ApplicationDesignUtils,
    RequestUtils,
  ],
  beforeRouteLeave(to, from) {
    log('Settings.beforeRouteLeave () heading to', to.path, 'from', from.path);

    if (!this.canLeave(to.path)) {
      // store to for later
      this.navigationGuard.path = to.path;

      // show modal
      this.navigationGuard.showConfirm = true;

      return false;
    }

    return true;
  },
  beforeRouteUpdate(to, from) {
    // TODO: figure out more elegant navigation guard, depending on how we want
    // users to be notified (model etc)
    // we also have other route changes we might want to handle differently e.g. someone has
    // updated a view content but then also wants to "move" or "copy" it (those trigger route changes)
    if (!this.canLeave(to.path)) {
      // store to for later
      this.navigationGuard.path = to.path;

      // show modal
      this.navigationGuard.showConfirm = true;

      return false;
    }

    // more pronounced transition when first activating a view
    if (to.path.indexOf('design') > -1) {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    } else if (from.path.indexOf('design') > -1) {
      this.transitionName = 'slide-right';
    } else {
      this.transitionName = 'replace';
    }

    return true;
  },
  data() {
    return {
      transitionName: 'replace',
      navigationGuard: {
        showConfirm: false,
        path: '',
      },
    };
  },
  computed: {
    ...mapGetters('ui', [
      'leftPanelFlexBasis',
      'leftPanelWidth',
    ]),
    showIndex() {
      return this.$route.path === '/settings';
    },
    toolboxWidth() {
      return this.$store.getters.toolboxExpandClass(this.$route);
    },
    isDesignSettingsPath() {
      return this.$route.path.includes('/design');
    },
  },
  mounted() {
    if (!this.leftPanelWidth) {
      this.setInitialLeftPanelWidth();
    }
  },
  methods: {
    ...mapActions('ui', [
      'handleLeftPanelResizeOnDrag',
      'cancelLeftPanelResizing',
      'setInitialLeftPanelWidth',
    ]),
    // Check for any unsaved changes
    canLeave(toPath) {
      // logout/login should always leave
      if (toPath === '/login') {
        return true;
      }

      // If we're going to another design path, we're fine
      if (toPath.includes('/design')) {
        return true;
      }

      // If we're not currently on a design path, we're fine
      if (!this.isDesignSettingsPath) {
        return true;
      }

      // can't leave if we're currently on a design path and have pending changes
      if (this.applicationHasPendingDesignChanges) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
#settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  .st0{fill:#FFFFFF;}
  .st1{fill:#1a8e5f;}
  .st2{fill:#1a8e5f;}
  .st3{fill: #d4e5d4;stroke: #1a8e5f; stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
}

#settings-body {
  flex-grow: 1;

  .body-wrapper > h1 {
    color: #d26c12;
    font-size: 1.4em;
    font-weight: 500;
    padding-bottom: .5em;
    border-bottom: 1px solid #eaecf0;
    margin-bottom: 1em;
  }

  h3.toggle-trigger {
    color: $gray800;
    font-weight: 600;
    font-size: 1em;
  }

  em {
    font-weight: 400;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

#settings-menu {
  .toolbox-body {
    position: relative;
  }

  #toolbox {
    position: absolute;
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  h3 {
    font-size: 1.05em;
    font-weight: 500;
  }
}
</style>
