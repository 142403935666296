<template>
  <div
    id="knack-logout"
    class="fullscreen-bg"
  >
    <GlobalLoading :is-loading="true" />
  </div>
</template>

<script>
import GlobalLoading from '@/components/ui/GlobalLoading';

export default {
  components: {
    GlobalLoading,
  },
  created() {
    if (process.env.VUE_APP_DASHBOARD_URL) {
      return window.location.replace(`${process.env.VUE_APP_DASHBOARD_URL}`);
    }

    return window.location.replace('/');
  },
};
</script>
