<template>
  <div class="input-labels tw-input-labels">
    <div v-if="showFormatToggle">
      <label>
        <input
          v-model="labelCustom"
          type="radio"
          name="label_custom"
          :value="false"
        >
        Use the view format: {{ viewLabelFormat }}
      </label>
      <label>
        <input
          v-model="labelCustom"
          type="radio"
          name="label_custom"
          :value="true"
        >
        Set a custom format
        <span v-if="labelCustom !== true">...</span>
      </label>
    </div>
    <div
      v-if="showLabelFormatOptions"
      :class="{'selectionGroup border-0 mt-2 ml-0 pl-5': showFormatToggle}"
    >
      <select
        v-model="labelFormat"
        class="text-base py-2 pl-3 leading-5"
      >
        <option
          v-if="!ignoreHideOption"
          value="none"
        >
          None - hide label
        </option>
        <option
          v-if="showAdditionalFormatOptions"
          value="top"
        >
          Top - above the value
        </option>
        <option
          v-if="showAdditionalFormatOptions"
          value="left"
        >
          Left aligned
        </option>
        <option
          v-if="showAdditionalFormatOptions"
          value="right"
        >
          Right aligned
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ignoreHideOption: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    viewLabelFormat: {
      type: String,
      default: '',
    },
    defaultLabelFormat: {
      type: String,
      default: 'left',
    },
    showFormatToggle: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'updateProperty:modelValue',
  ],
  computed: {
    showLabelFormatOptions() {
      return this.modelValue?.label_custom || !this.showFormatToggle;
    },
    showAdditionalFormatOptions() {
      // since v3 no longer allows a format on a whole group of items,
      // we should always allow additional formats
      return true;
    },
    labelCustom: {
      get() {
        return this.modelValue?.label_custom;
      },
      set(newValue) {
        this.$emit('updateProperty:modelValue', {
          propertyName: 'label_custom',
          newValue,
        });
      },
    },
    labelFormat: {
      get() {
        return this.modelValue?.label_format ?? this.defaultLabelFormat;
      },
      set(newValue) {
        this.$emit('updateProperty:modelValue', {
          propertyName: 'label_format',
          newValue,
        });
      },
    },
  },
};
</script>
