<template>
  <th
    v-if="!isGrouping"
    :style="columnStyle"
    :class="[
      columnClass,
      {
        'p-0 h-10 bg-gray-50 shadow-[8px_0px_0px_0px_#FAFAFA]': isLastColumn,
        'border-l-0': isFirstColumn,
      }
    ]"
  >
    <span :class="{ 'inline-flex h-full w-full bg-subtle rounded-tr-lg px-2 py-1': isLastColumn }">
      <a
        v-if="allowSorting && isSortable"
        :key="index"
        class="level-left kn-sort text-default"
        :data-cy="`column-header-${column.field.key}`"
        @click="onSort"
      >

        <span
          v-if="isField && field"
          class="column-field-icon"
        >
          <Icon
            v-if="integration"
            class="mr-2"
            :type="integration"
          />
          <TypeIcon
            v-else
            icon-classes="text-default mr-2"
            :field="field"
          />
        </span>

        <span class="mr-2">{{ columnHeader }}</span>

        <HelpIcon
          v-if="description"
          class="sortableColumn_description text-default mr-2"
          icon="info-circle"
          actionable
          tooltip-placement="bottom"
          :text="description"
          @click.stop="onEditFieldSettings"
        />

        <Icon
          v-if="showSortIcon"
          :type="sortIcon"
          class="sortableColumn_sort text-default mr-2"
        />

        <template v-if="allowEditing && isEditable">
          <a
            v-tippy="{ placement: 'top' }"
            content="Settings, copy, & delete"
            data-cy="object-settings-icon"
            class="column-edit-trigger bg-transparent hover:bg-brand-50"
            @click.prevent.stop="onOpenPopover()"
          >
            <Icon
              class="text-default"
              type="chevron-single"
            />
          </a>

          <Popover
            v-if="popoverIsOpen"
            :open="true"
            @hide="onClosePopover()"
          >
            <template #content>
              <div class="links">
                <button
                  v-close-popper
                  data-cy="field-settings"
                  class="link hover:text-emphasis"
                  :class="dropdownListItemClasses"
                  @click="onEditFieldSettings"
                >
                  <Icon
                    class="text-inherit"
                    type="settings"
                  /> Edit field settings
                </button>
                <button
                  v-close-popper
                  data-cy="field-add-before"
                  class="link hover:text-emphasis"
                  :class="dropdownListItemClasses"
                  @click="onCreateField(index)"
                >
                  <Icon
                    class="text-inherit"
                    type="arrow-back"
                  /> Insert field left
                </button>
                <button
                  v-close-popper
                  data-cy="field-add-after"
                  class="link hover:text-emphasis"
                  :class="dropdownListItemClasses"
                  @click="onCreateField(index + 1)"
                >
                  <Icon
                    class="text-inherit"
                    type="arrow-forward"
                  /> Insert field right
                </button>
                <button
                  v-if="!field.immutable"
                  v-close-popper
                  data-cy="field-delete"
                  class="link text-destructive hover:text-destructive"
                  :class="dropdownListItemClasses"
                  @click="onDeleteField"
                >
                  <Icon
                    class="text-inherit"
                    type="delete"
                  /> Delete field...
                </button>
              </div>
            </template>
          </Popover>
        </template>
      </a>
      <span v-else>
        {{ columnHeader }}
      </span>
    </span>
  </th>
</template>

<script>
import { mapGetters } from 'vuex';
import Popover from '@/components/ui/Popover';
import HelpIcon from '@/components/ui/HelpIcon';
import Icon from '@/components/ui/Icon';
import TypeIcon from '@/components/fields/TypeIcon';
import { ColumnUtilsMixin } from '@/components/renderer/mixins/ColumnUtils';

export default {
  name: 'SortableColumn',
  components: {
    HelpIcon,
    Icon,
    Popover,
    TypeIcon,
  },
  mixins: [
    ColumnUtilsMixin,
  ],
  props: {
    column: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    allowSorting: {
      type: Boolean,
      required: true,
    },
    sort: {
      type: Object,
      required: true,
    },
    allowEditing: {
      type: Boolean,
      default: true,
    },
    showColumnFieldIcon: {
      type: Boolean,
      default: true,
    },
    isFirstColumn: {
      type: Boolean,
      default: false,
    },
    isLastColumn: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clickFieldAction', 'sort'],
  data() {
    return {
      popoverIsOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'getFieldByObject',
    ]),
    description() {
      // When importing and creating new fields, the schema
      // may not be updated. Use the field header to prevent
      // the column label from being blank.
      if (!this.field) {
        return this.column.field?.header;
      }

      return this.field.get('meta').description;
    },
    localSort: {
      get() {
        return this.sort;
      },
      set(newValue) {
        this.$emit('sort', newValue);
      },
    },
    field() {
      if (!this.isField) {
        return null;
      }

      return this.getFieldByObject(
        this.column.objectKey,
        this.column.field.key,
      );
    },
    isField() {
      return Boolean(this.column.field);
    },
    showSortIcon() {
      if (!this.localSort || !this.localSort.field) {
        return false;
      }

      return this.column.field.key === this.localSort.field;
    },
    sortIcon() {
      return (this.localSort.order && this.localSort.order === 'asc') ? 'sort-ascending' : 'sort-descending';
    },
    isSortable() {
      return (this.column.field);
    },
    isEditable() {
      return (this.column.field);
    },
    integration() {
      return this.field?.get('integration')?.toLowerCase();
    },
    dropdownListItemClasses() {
      return 'rounded hover:bg-brand-50 text-base text-default';
    },
  },
  methods: {
    onSort() {
      const sort = {
        field: this.column.field.key,
        order: 'asc',
      };

      // invert the sort order if the current sort is this field ascending
      if (this.localSort.field && this.localSort.field === this.column.field.key && this.localSort.order === 'asc') {
        sort.order = 'desc';
      }

      this.localSort = sort;
    },
    onEditFieldSettings() {
      this.$emit('clickFieldAction', 'settings', {
        fieldKey: this.column.field.key,
      });
    },
    onEditFieldType() {
      this.$emit('clickFieldAction', 'type', {
        fieldKey: this.column.field.key,
      });
    },
    onCreateField(columnIndex) {
      this.$emit('clickFieldAction', 'create', {
        columnIndex,
      });
    },
    onDeleteField() {
      this.$emit('clickFieldAction', 'delete', {
        fieldKey: this.column.field.key,
      });
    },
    onOpenPopover() {
      this.popoverIsOpen = true;
    },
    onClosePopover() {
      this.popoverIsOpen = false;
    },
  },
};
</script>

<style lang="scss">
.sortableColumn_description {
  display: inline-flex;
  align-content: center;
}

// .kn-table-wrapper is overriding the HelpIcon component styles, this over-overrides it
.sortableColumn_description svg {
  color: #8E9293 !important;

  &:hover {
    color: #982A86 !important;
  }
}

.sortableColumn_sort {
  color: #7f7f84;
  margin-left: $spacing-xxs;
}

.column-edit-trigger {
  height: 18px;
  opacity: 0;
  margin-left: auto;
  margin-right: 0;
  padding: 1px;
  background: $gray100;
  border-radius: .1875rem;
  display: inline-flex;

  svg {
    width: 18px;
    height: 18px;
    position: relative;
  }
}

.column-field-icon {
  display: flex;
  align-self: center;
  svg {
    margin-right: $spacing-xxs;
    color: $gray400 !important;
  }
}

th:hover .column-edit-trigger, .v-popover.open .column-edit-trigger {
  opacity: 1;
}
</style>
