<template>
  <Overlay
    class="full-width full-body gray-bg left-title"
    :back="backLink"
    @close="$emit('close')"
  >
    <template #title>
      <h1 class="h-auto text-base font-semibold">
        Enable User Roles
      </h1>
    </template>

    <div
      class="enable-users-content mt-12 w-[480px]"
      style="margin-top: 4em;"
    >
      <h1 class="center-align text-left text-emphasis text-xl mt-0 mb-2">
        Add User Roles to Your App
      </h1>
      <p class="center-align text-left text-default text-xs mb-2">
        User roles define different types of users and which pages they can access.
        <br>Examples: Managers, Admins, Employees, etc.
      </p>
      <p class="center-align text-left mb-6">
        <a
          href="https://learn.knack.com/article/o7zqrvduj7-about-users"
          class="underline text-default text-base font-medium"
          style="font-size: .95em;"
        >Learn more about user access</a>.
      </p>
      <div
        id="enable-user-roles"
        class="box margin-bottom-double center w-auto bg-muted shadow-none"
        style="max-width: 500px;"
      >
        <p class="text-xs text-default mb-1">
          Optional
        </p>
        <p
          v-if="rolesToAdd.length === 0"
          class="text-base text-emphasis mb-4 font-semibold"
        >
          Define user roles now
        </p>
        <button
          v-if="rolesToAdd.length === 0"
          class="button green-gray small p-3 gap-1 rounded-lg bg-white border border-solid border-default text-emphasis leading-4 text-base hover:bg-brand-50 hover:border-brand-600 font-medium"
          data-cy="add-user-role"
          @click="$refs.quicklist.addItem()"
        >
          <Icon
            class="fill-gray-700 w-4 h-4 text-subtle"
            type="add"
          />
          Add User Role
        </button>
        <QuickList
          ref="quicklist"
          class="small add-users mt-4 p-0 bg-transparent"
          :class="{'is-grouped': rolesToAdd.length > 0}"
          :items="rolesToAdd"
          @update:items="rolesToAdd = $event"
        />
      </div>
      <ValidationError
        v-if="errors.length"
        class="mb-6"
        :errors="errors"
      />
      <div class="save center-align text-right">
        <button
          class="button save bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium"
          data-cy="enable-users-save"
          @click="onEnableUsers"
        >
          {{ submitText }}
        </button>
      </div>
    </div>
  </Overlay>
</template>

<script>
import Joi from '@hapi/joi';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import { mapGetters } from 'vuex';
import RequestUtils from '@/components/util/RequestUtils';
import Overlay from '@/components/ui/Overlay';
import Icon from '@/components/ui/Icon';
import ValidationError from '@/components/ui/ValidationError';
import QuickList from '@/components/ui/lists/QuickList';
import Obj from '@/store/models/Object';
import { mergedResponseChanges } from '@/lib/response-helper';
import { validate } from '@/lib/validation-helper';

export default {
  name: 'ObjectEnableUsers',
  components: {
    Overlay,
    Icon,
    QuickList,
    ValidationError,
  },
  mixins: [
    RequestUtils,
  ],
  emits: ['close'],
  data() {
    return {
      backLink: '',
      rolesToAdd: [],
    };
  },
  computed: {
    ...mapGetters([
      'app',
      'objects',
    ]),
    submitText() {
      return isEmpty(this.rolesToAdd) ? 'Enable Users' : 'Add Roles and Enable Users';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.backLink = from.path;
    });
  },
  methods: {
    onEnableUsers() {
      this.commitRequest({
        validate: this.getValidator(),
        request: async () => {
          const enableUsersResponse = await this.enableUsers();

          const roleCreateResponses = await this.createRoles();

          return {
            ...enableUsersResponse,
            changes: mergedResponseChanges(...roleCreateResponses, enableUsersResponse),
          };
        },
        onSuccess: () => {
          if (this.backLink) {
            this.$router.push(this.backLink);
          }

          this.$emit('close');
        },
      });
    },

    /**
       * Generates the validation function for the user roles.
       *
       * @returns {function(): object}
       */
    getValidator() {
      const currentObjectNames = this.objects.map((object) => object.name?.toLowerCase());

      // Validate:
      //  * The user role names are not the same as existing objects.
      //  * There are not duplicate user role names entered by the user.
      const validationSchema = Joi
        .array()
        .items({
          value: Joi.string().invalid(currentObjectNames).insensitive().allow(''),
        })
        .unique(
          (itemA, itemB) => (toLower(itemA.value) === toLower(itemB.value)),
        );

      const errorMap = {
        '': {
          types: {
            'array.unique': 'Duplicate Role name <b><%= context.value.value %></b> found.',
          },
        },
        value: {
          label: 'Role',
          types: {
            'any.invalid': 'Please enter a unique Role name. <b><%= context.value %></b> is already being used.',
          },
        },
      };

      return () => {
        const { errors } = validate(
          this.rolesToAdd,
          validationSchema,
          errorMap,
        );

        if (isEmpty(errors)) {
          return {};
        }

        return {
          error: {
            details: errors,
          },
        };
      };
    },

    /**
       * Enables users for the app.
       *
       * @returns {Promise<object>}
       */
    async enableUsers() {
      return this.app.enableUsers();
    },

    /**
       * Creates a new role object for each of the roles defined.
       *
       * @returns {Promise<object[]>}
       */
    async createRoles() {
      const roleObjects = this.rolesToAdd
        .filter((roleToAdd) => roleToAdd.value)
        .map((roleToAdd) => new Obj({
          name: roleToAdd.value,
          user: true,
        }));

      if (isEmpty(roleObjects)) {
        return [];
      }

      const responses = [];

      // Object creation can not happen in parallel, so we must force sync here.
      for (const roleObject of roleObjects) {
        const response = await roleObject.create();

        responses.push(response);
      }

      return responses;
    },
  },
};
</script>

<style lang="scss" scoped>
.enable-users-content {
  max-width: 50%;
  margin: 0 auto;
  margin-top: 10px;
}

.multiselect {
  margin: 10px;
}
</style>
