<template>
  <div class="kn-export-button">
    <a
      class="export-data kn-button is-button is-small"
      @click="onExport"
    >
      <Icon type="download-export" />
      <span>{{ trans('Export') }}</span>
    </a>
  </div>
</template>

<script>
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  mixins: [
    TranslationUtils,
  ],
  methods: {
    onExport() {
      return log('onExport!');
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #8c0e76;
  font-size:.9rem;
  margin-right:10px;
}

.kn-export-button {
  svg {
    height: 16px;
    width: 16px;
    margin-right: 6px;
  }
}
</style>
