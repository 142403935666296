<template>
  <div :class="`fileUpload fileUpload-${theme}`">
    <FileUpload
      ref="upload"
      :name="name"
      label-idle="Drop files here or click here to upload"
      :allow-multiple="false"
      :allow-file-type-validation="true"
      :accepted-file-types="['image/*']"
      :files="localFiles"
      :required="false"
      @updatefiles="updateLocalFile"
    />
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import { getInitialLocalFilesArray, getKnackFormatFromFilepondObject } from '@/util/AssetsHelper';

const FileUpload = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFilePoster);

const THEMES = new Set([
  'default',
  'primary',
]);

export default {
  components: {
    FileUpload,
  },
  props: {
    modelValue: {
      default: () => {},
    },
    name: {
      type: String,
      default: 'image-upload',
    },
    theme: {
      type: String,
      default: 'default',
      validator(val) {
        return THEMES.has(val);
      },
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      localFiles: [],
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  created() {
    getInitialLocalFilesArray(this.modelValue).then((result) => {
      this.localFiles = result;
    });
  },
  methods: {
    updateLocalFile(files) {
      // This component is used to upload logo assets, it cannot upload assets on its own
      this.localFiles = files;
      this.localValue = getKnackFormatFromFilepondObject(undefined, files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.fileUpload {
  min-width: 200px;
}
</style>
