<template>
  <FormWrapper
    :errors="errors"
    class="center"
    :show-submit="true"
    submit-text="Save Changes"
    @submit="onSubmit"
  >
    <div data-field-name="name">
      <label class="text-default text-sm font-medium mb-2 leading-4">Table Name</label>
      <input
        v-model="localObject.name"
        v-focus
        type="text"
      >
    </div>
    <div ref="identifier">
      <label class="text-default text-sm font-medium mb-2 leading-4">Display Field <HelpIcon copy="A single field used to identify a record" /></label>
      <select
        v-model="localObject.identifier"
        class="text-base py-2 pl-3 leading-5"
        data-cy="display-field"
      >
        <option
          v-for="field in object.getDisplayFieldOptions()"
          :key="field.key"
          :value="field.value"
        >
          {{ field.label }}
        </option>
      </select>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Sort Order <HelpIcon copy="The default sort order for these table records" /></label>
      <div class="is-horizontal">
        <select
          v-model="localObject.sort.field"
          class="text-base py-2 pl-3 leading-5"
          data-cy="sort-order-field"
        >
          <option
            v-for="field in object.getSortFieldOptions()"
            :key="field.key"
            :value="field.value"
          >
            {{ field.label }}
          </option>
        </select>
        <select
          v-model="localObject.sort.order"
          class="text-base py-2 pl-3 leading-5"
          data-cy="sort-order-asc-desc"
        >
          <option value="asc">
            {{ fieldAscending }}
          </option>
          <option value="desc">
            {{ fieldDescending }}
          </option>
        </select>
      </div>
    </div>
  </FormWrapper>
</template>

<script>
import { sanitizeObjectName } from '@/util/FormatHelper';
import HelpIcon from '@/components/ui/HelpIcon';
import FormWrapper from '@/components/ui/FormWrapper';
import FieldUtils from '@/components/fields/FieldUtils';
import RequestUtils from '@/components/util/RequestUtils';
import Obj from '@/store/models/Object';

export default {
  components: {
    FormWrapper,
    HelpIcon,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  props: {
    backLink: {
      type: String,
      default: '',
    },
    localObjectRaw: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localObject: this.localObjectRaw,
    };
  },
  computed: {

    sortField() {
      return this.object.getField(this.localObject.sort.field) || this.object.fields[0];
    },

    fieldAscending() {
      return this.sortField.getSortAscending();
    },

    fieldDescending() {
      return this.sortField.getSortDescending();
    },
  },
  methods: {
    onSubmit() {
      const newObject = new Obj({
        key: this.object.key,
        name: sanitizeObjectName(this.localObject.name),
        identifier: this.localObject.identifier,
        sort: this.localObject.sort,
      });

      this.commitRequest({
        validate: () => newObject.validate(),
        request: () => newObject.update(),
        onSuccess: () => this.$router.push(this.backLink),
      });
    },
  },
};
</script>
