<template>
  <RouterLink
    v-if="!isGrouping"
    v-slot="{ isActive, navigate }"
    :key="index"
    :to="route"
    custom
  >
    <th
      class="item-wrapper dragdrop-adjacent"
      :class="[
        {'router-link-active': isActive},
        columnClass,
      ]"
      :data-item="index"
      :draggable="true"
      :style="columnStyle"
      @click="navigate"
      @dragstart="onDragStart"
      @dragend="onDragEnd"
    >
      <div
        class="drop-target left"
        :draggable="true"
        @dragover="onDragOver"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @drop="onDrop"
      />
      <span>{{ columnHeader }}</span>
      <div
        class="kn-item"
      >
        <!-- TODO: fix overlapping / show logic -->
        <!-- {{renderColumnHeader(column)}} -->
        <div
          v-tippy="{ placement: 'bottom' }"
          content="Click to edit, drag to move"
          class="overlay"
          @click="onActivate"
        />
        <div class="item-links gap-1 bg-subtle rounded-t-lg border-b-0 p-1">
          <a
            v-tippy
            content="Edit this column"
            class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
            @click.stop="onActivate"
          >
            <Icon
              class="text-default"
              type="edit"
            />
          </a>
          <a
            v-tippy
            content="Delete this column"
            class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
            @click.stop="onDelete"
          >
            <Icon
              class="text-default"
              type="delete"
            />
          </a>
        </div>
      </div>
      <div
        class="drop-target right"
        :draggable="true"
        @dragover="onDragOver"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @drop="onDrop"
      />
    </th>
  </RouterLink>
</template>

<script>
import Icon from '@/components/ui/Icon';
import { ColumnUtilsMixin } from '@/components/renderer/mixins/ColumnUtils';

export default {
  name: 'DraggableColumn',
  components: {
    Icon,
  },
  mixins: [
    ColumnUtilsMixin,
  ],
  props: {
    view: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    route: {
      type: String,
      default: '',
    },
  },
  emits: [
    'activate',
    'delete',
    'dragover',
    'dragenter',
    'dragleave',
    'dragstart',
    'dragend',
    'drop',
  ],
  data() {
    return {
      viewDropArea: 'table',
    };
  },
  methods: {
    onDragOver(event) {
      this.$emit('dragover', event);
    },

    onDragEnter(event) {
      this.$emit('dragenter', event);
    },

    onDragLeave(event) {
      this.$emit('dragleave', event);
    },

    onDrop(event) {
      this.$emit('drop', event);
    },

    onDragStart(event) {
      this.$emit('dragstart', event);
    },

    onDragEnd(event) {
      this.$emit('dragend', event);
    },

    onActivate() {
      this.$emit('activate', this.index);
    },

    onDelete() {
      this.$emit('delete', this.index);
    },
  },
};
</script>
