<template>
  <div class="breadcrumbs">
    <template
      v-for="(crumb, index) in breadcrumbs"
      :key="index"
    >
      <span
        v-if="index !== 0"
        class="breadcrumbs_spacer"
      >
        •
      </span>
      <button
        type="button"
        class="breadcrumbs_crumb"
        @click="activateIndex(index)"
      >
        <Icon
          v-if="breadcrumbs.length === 1"
          type="arrow-back"
          class="breadcrumbs_return"
        />
        {{ crumb }}
      </button>
    </template>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  name: 'HelpBreadcrumbs',
  components: {
    Icon,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['selectPreviousPage'],
  methods: {
    activateIndex(index) {
      this.$emit('selectPreviousPage', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  align-items: center;
  display: flex;

  &_return {
    height: 15px;
    margin-right: $spacing-xs;
    width: 15px;

    color: #8E9293;
  }

  &_spacer {
    padding: 0 calculateEm(14px, 6px);

    color: #8E9293;
  }

  &_crumb {
    appearance: none;
    display: flex;
    align-items: center;

    margin: 0;
    padding: 0 $spacing-xxs;

    color: #8E9293;
    font-size: calculateRem(14px);
    font-weight: 500;
    line-height: 1.43;

    background: transparent;
    border: none;

    &:first-child {
      padding-left: 0;
    }
  }
}
</style>
