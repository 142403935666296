import TOC from '@/components/views/image/TOC';
import Settings from '@/components/views/image/Settings';

export default [
  {
    path: ':pageKey/views/:viewKey/image',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/image/settings',
    components: {
      toolbox: Settings,
    },
  },
];
