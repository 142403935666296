<template>
  <ViewWrapper
    style="min-width: 250px; min-height: 200px !important"
    :view="view"
    :show-title="false"
    class="is-full-width"
  >
    <div class="body-status mt-20">
      <div
        v-if="view.type === `report` && view.source && view.source.object"
        style="text-align: center;"
      >
        <div v-if="!view.dataIsLoaded()">
          <Icon type="loading-spinner" /><br>
          Generating default report data
        </div>
        <div v-else>
          <Report
            :view="view"
            :is-simple-report="true"
          />
        </div>
      </div>

      <div v-else-if="view.isRecordDriven() && view.source && view.source.object">
        <Icon type="loading-spinner" />
      </div>

      <div
        v-else-if="view.isStatic()"
        style="text-align: center;"
      >
        <p style="font-size: 1.1em;">
          Save this new view to further configure
        </p>
        <Icon
          type="empty-point-left"
          class="header-icon"
        />
      </div>

      <div
        v-else
        id="new-view-wrapper"
        style="text-align: center;"
      >
        <p
          class="text-base font-semibold"
          style="font-size: 1em;"
        >
          Use the left menu to configure this new {{ viewTypeName }}.
        </p>
      </div>
    </div>
  </ViewWrapper>
</template>

<script>
import ViewWrapper from '@/components/views/ViewWrapper';
import Icon from '@/components/ui/Icon';
import Report from '@/components/renderer/report/Report';

export default {
  components: {
    ViewWrapper,
    Icon,
    Report,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  computed: {
    viewTypeName() {
      return this.view.type === 'table' ? 'grid' : this.view.type;
    },
  },
};
</script>
