<template>
  <ViewToolbox
    id="report-properties"
    :key="`report-properties-${rowIndex}-${reportIndex}`"
    back-title="Report"
    :back-count="6"
    title="Chart Properties"
  >
    <template v-if="!!report">
      <p class="text-xs text-default mb-0">
        Use the sections below to edit this <b>{{ viewTypeLabel }}</b>.
      </p>
      <Toggle :is-open="true">
        <template #title>
          Source
        </template>
        <template #content>
          <ReportSource
            :report-source="report.source"
            :show-sort="false"
            :show-limit="false"
            :hide-label="true"
          />
        </template>
      </Toggle>

      <Toggle
        :is-open="true"
        class="mt-2.5"
      >
        <template #title>
          Data
        </template>
        <template #content>
          <div class="border border-solid border-subtle border-x-0 border-t-0 pb-2.5 mb-0">
            <div
              v-if="isChart"
              class="padding-bottom-half pb-0 mb-2 font-medium"
            >
              How will the records be <b>grouped</b>?
            </div>
            <div
              v-else
              class="padding-bottom-half pb-0 mb-2"
            >
              <b class="font-medium">{{ capitalize(report.type) }} Rows</b>
            </div>

            <!-- Groups -->
            <ActionList
              v-model:items="localReportGroups"
              :can-be-empty="isChart"
              empty-text="add group"
              :default-item="defaultGroup"
              :add-is-disabled="groupsDisabledMessage !== ``"
              :add-disabled-message="groupsDisabledMessage"
              :hide-buttons="isPieChart"
              class="small padding-bottom mb-4 pb-0"
              action-list-item-classes="bg-subtle rounded-lg p-4"
            >
              <template #default="{item, itemIndex}">
                <ReportGroupItem
                  v-if="isChart"
                  :key="`${itemIndex}-${Date.now()}`"
                  :item="item"
                  type="group"
                  :object="object"
                  :item-options="groupFields"
                />
                <ReportGroupItem
                  v-else
                  :key="`${itemIndex}-${Date.now()}`"
                  :item="item"
                  type="pivot"
                  :object="object"
                  :item-options="pivotFields"
                />
              </template>
            </ActionList>

            <!--  Pivot Columns -->
            <div v-if="isTable">
              <div
                class="padding-bottom-half pb-0 mb-2"
              >
                <b class="font-medium">Pivot Columns</b>
              </div>

              <div
                v-if="report.calculations.length !== 1"
                class="description-text"
              >
                <span>Pivot Columns will only work with one calculation.</span>
                <span>Please remove all but one Pivot Calculation to use this feature.</span>
              </div>

              <ActionList
                v-else
                ref="pivot"
                v-model:items="localReportPivotGroups"
                :can-be-empty="true"
                empty-text="add pivot column"
                :default-item="defaultPivotGroup"
                class="small padding-bottom mb-4 pb-0"
                action-list-item-classes="bg-subtle rounded-lg p-4"
              >
                <template #default="{item, itemIndex}">
                  <ReportGroupItem
                    :key="`${itemIndex}-${Date.now()}`"
                    :item="item"
                    type="pivot"
                    :object="object"
                    :item-options="pivotFields"
                  />
                </template>
              </ActionList>
            </div>
  
            <!-- Group Calculations -->
            <template v-if="showCalculations">
              <div
                v-if="isChart"
                class="padding-bottom-half p-0 mb-2 font-medium"
              >
                What do you want to <b>calculate</b> for each group?
              </div>
              <div
                v-else
                class="padding-bottom-half p-0 mb-2"
              >
                <b class="font-medium">Pivot Calculations</b>
              </div>

              <ActionList
                v-model:items="report.calculations"
                :can-be-empty="isChart"
                empty-text="add calculation"
                :default-item="defaultCalculation"
                :hide-buttons="isPieChart"
                :add-is-disabled="calculationsDisabledMessage !== `` && report.calculations.length > 0"
                :add-disabled-message="calculationsDisabledMessage"
                class="small padding-bottom mb-4 pb-0"
                action-list-item-classes="bg-subtle rounded-lg p-4"
              >
                <template #default="{item, itemIndex}">
                  <ReportGroupItem
                    :key="`${itemIndex}-${Date.now()}`"
                    :item="item"
                    type="calculation"
                    :object="object"
                    :item-options="calculateFields"
                  />
                </template>
              </ActionList>
            </template>

            <!-- Group fields for total/average -->
            <template v-if="showFields">
              <div
                class="padding-bottom-half pb-0 mb-2 font-medium"
              >
                Which fields do you want to display the <b>{{ fieldCalculationType }}</b> of?
              </div>
              <ActionList
                v-model:items="report.calculations"
                :can-be-empty="false"
                :default-item="defaultCalculation"
                class="small padding-bottom"
                action-list-item-classes="bg-subtle rounded-lg p-4"
              >
                <template #default="{item, itemIndex}">
                  <ReportGroupItem
                    :key="`${itemIndex}-${Date.now()}`"
                    :item="item"
                    type="field"
                    :object="object"
                    :item-options="calculateFields"
                  />
                </template>
              </ActionList>
            </template>
          </div>
        </template>
      </Toggle>

      <Toggle
        v-if="isTable"
        :is-open="true"
        class="mt-2"
      >
        <template #title>
          Summaries
        </template>

        <template #content>
          <!--  Column Summaries -->
          <div class="mb-0.5">
            <div
              class="padding-bottom-half pb-0 mb-2"
            >
              <b class="font-medium">Column Summaries</b>
            </div>
            <ActionList
              v-model:items="report.summaries"
              :can-be-empty="true"
              empty-text="add column summary"
              :default-item="defaultSummary"
              class="small padding-bottom"
              action-list-item-classes="bg-subtle rounded-lg p-4"
            >
              <template #default="{item, itemIndex}">
                <ReportGroupItem
                  :key="`${itemIndex}-${Date.now()}`"
                  :item="item"
                  type="summary"
                  :object="object"
                />
              </template>
            </ActionList>
          </div>

          <!--  Row Summaries -->
          <div class="border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
            <div
              class="padding-bottom-half pb-0 mb-2"
            >
              <b class="font-medium">Row Summaries</b>
            </div>

            <ActionList
              v-if="computationsCount > 1"
              v-model:items="report.row_summaries"
              :can-be-empty="true"
              empty-text="add row summary"
              :default-item="defaultSummary"
              class="small"
              action-list-item-classes="bg-subtle rounded-lg p-4"
            >
              <template #default="{item, itemIndex}">
                <ReportGroupItem
                  :key="`${itemIndex}-${Date.now()}`"
                  :item="item"
                  type="summary"
                  :object="object"
                />
              </template>
            </ActionList>

            <div
              v-else
              class="description-text mb-0 not-italic"
            >
              <span class="mb-1 text-xs">Row Summaries only work with two or more calculations.</span>
              <span class="mb-0 text-xs">Please add another Pivot Calculation to use this feature.</span>
            </div>
          </div>
        </template>
      </Toggle>

      <Toggle
        :is-open="true"
        class="mt-2.5"
      >
        <template #title>
          Options
        </template>
        <template #content>
          <div class="option-list tw-input-labels border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
            <label>
              <input
                v-model="report.options.exclude_empties"
                type="checkbox"
              >
              Exclude empty groups
            </label>
            <label v-if="isLineChart || isAreaChart || isBarChart">
              <input
                v-model="report.options.hide_negatives"
                type="checkbox"
              >
              Hide negative ranges
            </label>
            <label>
              <input
                v-model="report.options.child_records"
                type="checkbox"
              >
              Click to expand group records <HelpIcon
                :copy="`This will create a child page that will open in a popup. Any views<br />on that page will show the records of the expanded group.`"
                style="position: relative; display: inline-block;"
              />
              <div
                v-if="report.options.child_records"
                class="option-list-child-page flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle my-2 ml-[22px]"
              >
                <Icon
                  type="page"
                  class="small text-subtle h-4 w-4"
                />&nbsp;
                <RouterLink
                  :to="childPageLink"
                  class="text-emphasis"
                >{{ childPageName }}</RouterLink>
              </div>
            </label>
            <label v-if="isChart">
              <input
                v-model="report.options.export_links"
                type="checkbox"
              >
              Allow print &amp; export options
            </label>
            <label
              v-if="isChart"
              data-cy="report-data-table-option"
            >
              <input
                v-model="report.options.shouldShowDataTable"
                type="checkbox"
              >
              Show/hide data table
            </label>
          </div>
        </template>
      </Toggle>

      <Toggle
        :is-open="true"
        class="mt-2.5"
      >
        <template #title>
          Filters
        </template>
        <template #content>
          <div class="border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
            <Filters
              v-model:filter-type="report.filters.filter_type"
              v-model:filter-fields="report.filters.filter_fields"
              v-model:preset-filters="report.filters.preset_filters"
              v-model:allow-preset-filters="report.filters.allow_preset_filters"
              v-model:menu-filters="report.filters.menu_filters"
              v-model:connection-sources="report.filters.filter_connection_sources"
              :show-label="false"
              :show-fields-choice="true"
              :object="object"
            />
          </div>
        </template>
      </Toggle>

      <Toggle
        :is-open="true"
        class="mt-2.5"
      >
        <template #title>
          Layout & Design
        </template>
        <template #content>
          <div class="grid-verticalGaps gap-4">
            <div>
              <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Title</label>
              <input
                v-model="report.title"
                type="text"
              >
            </div>
            <div>
              <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Description</label>
              <textarea v-model="report.description" />
            </div>
            <div v-if="isBarChart">
              <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Bar Type</label>
              <ChoicePicker
                v-model="report.layout.bar_type"
                capitalize="true"
                :choices="['bar', 'column']"
                class="small"
              />
            </div>
            <!-- TODO: See if Pivot Table actually needs to set Legend property -->
            <div v-if="isChart">
              <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Legend</label>
              <ChoicePicker
                v-model="report.layout.legend"
                capitalize="true"
                :choices="['bottom', 'right', 'none']"
                class="small"
              />
            </div>
            <div v-if="isAreaChart">
              <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Stacking</label>
              <ChoicePicker
                v-model="report.layout.stacking"
                capitalize="true"
                :choices="['none', 'normal', 'percent']"
                class="small"
              />
            </div>
            <div v-if="isLineChart || isAreaChart || isBarChart">
              <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Labels</label>
              <div class="input-labels tw-input-labels">
                <label><input
                  v-model="report.layout.data_labels"
                  type="checkbox"
                > Show Data Labels in Chart</label>
                <label><input
                  v-model="report.layout.tilt_labels"
                  type="checkbox"
                > Tilt Group Labels</label>
              </div>
            </div>
            <div v-if="isChart">
              <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Dimensions</label>
              <ChoicePicker
                v-model="report.layout.dimensions"
                capitalize="true"
                :choices="['auto', 'custom']"
                class="small"
              />
            </div>
            <template v-if="report.layout && report.layout.dimensions === 'custom' && (isChart)">
              <div>
                <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Chart Width</label>
                <input
                  v-model="report.layout.chart_width"
                  type="text"
                >
              </div>
              <div>
                <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Chart Height</label>
                <input
                  v-model="report.layout.chart_height"
                  type="text"
                >
              </div>
              <div v-if="report.layout.legend !== 'none'">
                <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Legend Width</label>
                <input
                  v-model="report.layout.legend_width"
                  type="text"
                >
              </div>
            </template>
          </div>
        </template>
      </Toggle>
    </template>
  </ViewToolbox>
</template>

<script>
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';

import ActionList from '@/components/ui/lists/ActionList';
import ChoicePicker from '@/components/ui/inputs/ChoicePicker';
import Filters from '@/components/views/shared/settings/Filters';
import HelpIcon from '@/components/ui/HelpIcon';
import Icon from '@/components/ui/Icon';
import PageAddUtils from '@/components/pages/PageAddUtils';
import ReportGroupItem from '@/components/views/report/ReportGroupItem';
import ReportUtils from '@/components/util/ReportUtils';
import RequestUtils from '@/components/util/RequestUtils';
import ReportSource from '@/components/views/shared/Source';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import { buildPageSchema } from '@/lib/schema-helper';
import { eventBus } from '@/store/bus';

const refetchRecordThrottleInterval = 2000;

const refetchRecordDataWatcher = {

  handler(newVal, oldVal) {
    if (!this.report) {
      return;
    }

    if (typeof oldVal === 'undefined' || isEqual(newVal, oldVal)) {
      return;
    }

    this.refetchRecordData();
  },
  deep: true,
};

export default {
  name: 'ReportProperties',
  components: {
    ActionList,
    ChoicePicker,
    Filters,
    HelpIcon,
    Icon,
    ReportGroupItem,
    ReportSource,
    Toggle,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
    PageAddUtils,
    RequestUtils,
    ReportUtils,
  ],
  data() {
    return {
      isDataStale: false,
      restorableChildPage: null, // used to restore the child page when toggled
    };
  },
  computed: {
    ...mapGetters([
      'getPageBySlug',
    ]),

    groupsDisabledMessage() {
      // If all groups have been removed then we can always add a new one
      if (!this.report.groups.length) {
        return '';
      }

      if (this.isChart && this.report.calculations.length > 1) {
        return 'A report can have multiple <b>groups</b> only when there is one calculation. Remove a <b>calculation</b> in order to add a new group.';
      }

      if (this.isChart && this.report.groups.length > 1) {
        return 'A report can have a maximum of <b>2 groups</b>. Edit an existing group instead, or remove a group to add a new one.';
      }

      return '';
    },

    calculationsDisabledMessage() {
      if (this.isChart && this.report.groups.length > 1) {
        return 'A report can have multiple <b>calculations</b> only when there is one group. Remove a <b>group</b> in order to add a new calculation.';
      }

      if (!isEmpty(this.localReportPivotGroups)) {
        return 'A report can have multiple <b>calculations</b> only when there are no pivot columns. Remove the <b>Pivot Columns</b> in order to add a new calculation.';
      }

      return '';
    },

    viewTypeLabel() {
      const labels = {
        pivot: 'Pivot Table',
        table: 'Pivot Table',
        bar: 'Bar Chart',
        area: 'Area Chart',
        line: 'Line Chart',
        pie: 'Pie Chart',
      };

      return labels[this.report.type];
    },

    childPageLink() {
      if (isNil(this.report.child_page)) {
        return `/pages/${this.page.key}/`;
      }

      const childPage = this.getPageBySlug(this.report.child_page.scene);

      if (isNil(childPage) || childPage === false) {
        return `/pages/${this.page.key}/`;
      }

      return `/pages/${childPage.key}/`;
    },

    childPageName() {
      if (!this.report.child_page) {
        return this.defaultChildPageName;
      }

      const childPage = this.getPageBySlug(this.report.child_page.scene);

      if (childPage) {
        return childPage.name;
      }

      return this.defaultChildPageName;
    },

    defaultChildPageName() {
      const reportName = this.report.title || `${this.report.type.substr(0, 1).toUpperCase() + this.report.type.substr(1)} Report`;

      return `${reportName} Records`;
    },

    showCalculations() {
      if (isEmpty(this.report.groups)) {
        return false;
      }

      return !this.showFields;
    },

    showFields() {
      if (isEmpty(this.report.groups)) {
        return false;
      }

      if ([
        'totals',
        'averages',
      ].includes(this.report.groups[0].group)) {
        return true;
      }

      return false;
    },

    fieldCalculationType() {
      return this.report.groups[0].group;
    },

    isTable() {
      return this.report.type === 'table';
    },
    isChart() {
      return this.isPieChart || this.isLineChart || this.isAreaChart || this.isBarChart;
    },
    isPieChart() {
      return this.report.type === 'pie';
    },
    isBarChart() {
      return this.report.type === 'bar';
    },
    isLineChart() {
      return this.report.type === 'line';
    },
    isAreaChart() {
      return this.report.type === 'area';
    },

    groupFields(isChart = true) {
      // get fields with type as the prefix e.g. boolean-field_1
      return this.object.getFieldsForReports(true);
    },

    pivotFields() {
      // get fields with no prefix e.g. field_1
      return this.object.getFieldsForReports(false);
    },

    calculateFields() {
      const isGroupedByTotalsOrAverages = this.report.groups.some(({ group }) => [
        'totals',
        'averages',
      ].includes(group));

      // only show numeric field if grouped by totals or averages
      const additionalFields = isGroupedByTotalsOrAverages ? [] : [
        {
          value: 'count',
          label: 'Count',
          displayLabel: 'The <b>total record count</b>',
        },
      ];

      return this.object.fields
        .filter((field) => field.storesNumericValues())
        .map(({ key, name }) => ({
          value: key,
          label: this.showCalculations ? `The <b>${name}</b>` : name,
        }))
        .concat(additionalFields);
    },

    computationsCount() {
      const calculations = get(this, 'report.calculations', []);

      return calculations.length;
    },

    rowIndex() {
      return this.$route.params.rowIndex || 0;
    },

    reportIndex() {
      return this.$route.params.reportIndex || 0;
    },

    report() {
      if (!this.hasActiveView) {
        return undefined;
      }

      return this.view?.rows?.[this.rowIndex]?.reports[this.reportIndex];
    },

    localReportGroups: {
      get() {
        return this.report?.groups?.filter((group) => !group.pivot) ?? [];
      },
      set(newGroups) {
        if (!this.report) {
          return;
        }

        // Combine non-pivot groups with pivot groups (in that order).
        this.report.groups = newGroups.concat(this.localReportPivotGroups);
      },
    },

    localReportPivotGroups: {
      get() {
        return this.report?.groups?.filter((group) => group.pivot) ?? [];
      },
      set(newPivotGroups) {
        if (!this.report) {
          return;
        }

        // Combine non-pivot groups with pivot groups (in that order).
        this.report.groups = this.localReportGroups.concat(newPivotGroups);
      },
    },

    computedReportCalculations() {
      return this.report?.calculations ? cloneDeep(this.report.calculations) : '';
    },

    computedReportGroups() {
      return this.report?.groups ? cloneDeep(this.report.groups) : '';
    },
    isGroupedByTotalsOrAverages() {
      return ['totals', 'averages'].includes(this.report.groups[0].group);
    },
  },
  watch: {
    computedReportCalculations: [
      refetchRecordDataWatcher,
      function (newVal) {
        if (!this.report) {
          return;
        }

        const newLength = newVal ? newVal.length : 0;
        const rowSummaryLength = this.report.row_summaries ? this.report.row_summaries.length : 0;
        const pivotGroupsLength = this.localReportPivotGroups ? this.localReportPivotGroups.length : 0;

        if (newLength < 2 && rowSummaryLength) {
          // Make sure if there is less than 2 calculations that all row summaries are removed.
          this.report.row_summaries.splice(0, rowSummaryLength);
        }

        if (newLength > 1 && pivotGroupsLength) {
          // Make sure if there is more than 1 calculation that all pivot columns are removed.
          this.report.groups.forEach((group, iter) => {
            if (group.pivot) {
              delete this.report.groups[iter];
            }
          });
        }
      },
    ],
    'report.summaries': refetchRecordDataWatcher,
    'report.source': refetchRecordDataWatcher,
    'report.options.exclude_empties': refetchRecordDataWatcher,
    'report.options.hide_negatives': refetchRecordDataWatcher,
    computedReportGroups: {
      handler(newVal, oldVal) {
        if (!this.report || typeof oldVal === 'undefined' || isEqual(newVal, oldVal)) {
          return;
        }

        // Pie charts ensure just one calculation if it's not doing a total or average grouping
        if (this.isPieChart && ![
          'totals',
          'averages',
        ].includes(newVal[0].group) && this.report.calculations.length > 1) {
          this.report.calculations = [
            this.report.calculations[0],
          ];
        }

        // If grouped by Totals/averages we clean any calculations
        if (this.isGroupedByTotalsOrAverages) {
          this.report.calculations = this.report.calculations.map((calculation) => ({ ...calculation, calculation: '' }));
        }

        this.refetchRecordData();
      },
    },
    'report.options.child_records': function (newVal, oldVal) {
      if (!this.report) {
        return;
      }

      if (typeof oldVal === 'undefined') {
        return;
      }

      // if disabled, delete the child view
      if (!newVal) {
        if (this.report.child_page) {
          // save for later in case they toggle it back
          this.restorableChildPage = this.report.child_page;

          delete this.report.child_page;
        }

        return;
      }

      // no need to create a child view one already existed
      if (oldVal === false && this.restorableChildPage) {
        this.report.child_page = this.restorableChildPage;

        return;
      }

      // if we already have an existing child_page, use that
      if (this.report.child_page && this.report.child_page.scene && !this.report.child_page.scene.name) {
        return;
      }

      const childPage = this.createChildPage();

      this.report.child_page = childPage;
    },
  },
  mounted() {
    // TODO: this event is way too generic
    // check to see if we need to re-fetch report (runs after view update)
    eventBus.$on('updatesSaved', () => {
      this.reloadStaleReportData();
    });
  },
  methods: {
    capitalize,
    createChildPage() {
      // build view
      const obj = this.object;
      const view = {
        name: this.childPageName,
        type: 'table',
        source: {
          object: obj.key,
        },
      };

      // build page
      const page = {
        name: this.childPageName,
        object: this.page.object,
        parent: this.page.slug,
        modal: true,
      };

      // when adding from a wizard the view isn't created yet so no key is available
      const { key } = this.view;

      if (key && key.indexOf('view_') > -1) {
        page.source = {
          view: key,
        };
      }

      const childPage = {
        scene: buildPageSchema(page, [
          view,
        ], {
          object: obj.key,
        }),
      };

      // clear out title
      childPage.scene.views[0].title = '';

      // if our report is dependent on an authenticated user, ensure that the newly created child view is as well.
      // otherwise, the logged in user will be able to see other users' records by manipulating the child view's filters
      if (this.report.source.authenticated_user) {
        childPage.scene.views[0].source.authenticated_user = this.report.source.authenticated_user;
        childPage.scene.views[0].source.connection_key = this.report.source.connection_key;
        childPage.scene.views[0].source.relationship_type = this.report.source.relationship_type;
        childPage.scene.views[0].source.parent_source = this.report.source.parent_source;
      }

      return childPage;
    },

    hasFilters(group) {
      return !isEmpty(group.filters);
    },
    defaultGroup() {
      const field = this.object.fields[0];

      return {
        field: field.key,
        label: field.name,
        type: field.type,
      };
    },
    defaultPivotGroup() {
      const group = this.defaultGroup();

      group.pivot = true;

      return group;
    },
    defaultSummary() {
      return {
        calc: 'sum',
        label: 'Sum',
      };
    },
    defaultCalculation() {
      const calculateOption = this.calculateFields[0];
      let calculateLabel = calculateOption.label;

      if (calculateOption.value.includes('field')) {
        calculateLabel = this.object.getField(calculateOption.value).name;
      }

      return {
        field: calculateOption.value,
        label: calculateLabel,
        displayLabel: this.isGroupedByTotalsOrAverages ? '' : 'The <b>total record count</b>',
        calculation: this.isGroupedByTotalsOrAverages ? '' : 'count',
      };
    },
    objectField(fieldKey) {
      return this.object.getField(fieldKey);
    },
    refetchRecordData: debounce(function () { // need function () for proper this binding
      eventBus.$emit(`reloadReportData.${this.view.key}`, {
        rowIndex: this.rowIndex,
        reportIndex: this.reportIndex,
      });
    }, refetchRecordThrottleInterval, {
      leading: true,
      trailing: false,
    }),
  },
};
</script>

<style lang="scss">
#report-properties {

  label.underline {

    border: 0;
    margin-bottom: 0;
  }

  .option-list > label {
    display:block;
  }

  .option-list-child-page {
    padding-left:24px;
    max-height: 100px;
    overflow-y: auto;
  }

  .filter-icon {
    color:#888
  }

  .description-text {
    font-size: .7rem;
    font-style: italic;

    & span {
      display:block;
      margin-bottom: .4rem;
    }
  }

  .toggle-wrapper {
    font-size: .875rem;
  }
}
</style>
