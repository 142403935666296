<template>
  <div class="control kn-inputWrapper ">
    <select
      :id="input.id"
      v-model="localValue"
      data-cy="dropdown-select"
      class="group-[&:not(.kn-view)]/shared:text-base group-[&:not(.kn-view)]/shared:py-2 group-[&:not(.kn-view)]/shared:pl-3 group-[&:not(.kn-view)]/shared:leading-5"
      :name="input.id"
    >
      <option
        v-if="showBlankOption"
        selected
        value=""
      >
        {{ input.format.blank }}
      </option>
      <template
        v-for="option in options"
        :key="option.label"
      >
        <option
          v-if="option"
          :value="optionValue(option)"
        >
          {{ option.label }}
        </option>
      </template>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      default: () => {},
    },
    input: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    modelValue: {
      type: [
        Object,
        String,
        Array,
        Boolean,
      ],
      required: true,
      default: '',
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        if (newValue === 'true' || newValue === 'false') {
          this.$emit('update:modelValue', newValue === 'true');

          return;
        }

        this.$emit('update:modelValue', newValue);
      },
    },

    /**
       * Whether or not the select is allowed to be the 'blank' option.
       *
       * @returns {boolean}
       */
    blanksAreAllowed() {
      const blankValue = 'kn-blank';
      const blankDefault = this.input.format.default;

      return (!blankDefault || blankDefault === blankValue);
    },

    /**
       * Whether or not the blank option should be shown based on the field blank options
       * and whether or not the blank option already exists.
       *
       * @returns {boolean}
       */
    showBlankOption() {
      if (!this.blanksAreAllowed) {
        return false;
      }

      // The blank option value should always be an empty string. It should not be `kn-blank` like the default value.
      const blankOption = this.options.find((option) => option.value === '');

      // Do not show if the blank option already exists.
      return !blankOption;
    },
  },
  mounted() {
    // The local value here should never be `kn-blank` unless something went wrong with the code.
    // Nevertheless we are looking for it here in case the value was incorrectly stored in mongo.
    const valueIsBlank = (this.localValue === '' || this.localValue === 'kn-blank');

    // ensure default option is selected if value is blank
    if (!this.blanksAreAllowed && valueIsBlank) {
      let defaultOption = null;

      if (this.field.format.default) {
        defaultOption = this.options.find((option) => option.value === this.field.format.default);
      }

      // Set the item value to the default option value or just choose the first option's value.
      this.localValue = (defaultOption) ? defaultOption.value : this.options[0].value;
    } else if (this.localValue === 'kn-blank') {
      this.localValue = '';
    }
  },
  methods: {
    optionValue(option) {
      return option.value;
    },
  },
};
</script>
