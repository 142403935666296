<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Format</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="currencyFormat"
          type="radio"
          value="$"
        >$</label>
        <label><input
          v-model="currencyFormat"
          type="radio"
          value="£"
        >£</label>
        <label><input
          v-model="currencyFormat"
          type="radio"
          value="€_after"
        >€ (after, 99 = 99 €)</label>
        <label><input
          v-model="currencyFormat"
          type="radio"
          value="€"
        >€ (before, 99 = €99)</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Currency',
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    format() {
      return this.fieldLocal.format || {};
    },
    currencyFormat: {
      get() {
        if (typeof this.format.format === 'undefined') {
          return '$';
        }

        return this.format.format;
      },
      set(newVal) {
        this.fieldLocal.format.format = newVal;
      },
    },
  },
};
</script>
