<template>
  <form class="kn-form">
    <!-- EMPTY -->
    <template v-if="fieldsAreEmpty">
      <EmptyFieldsDropZone
        class="drop-target"
        :class="{'is-dragging-over': isDraggingInput}"
        buildable="form"
        :build-path="addFormInputsPath"
        :is-dragging-field="isDraggingInput"
        @dragover="onDragOver"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @drop="onDrop"
      />
    </template>

    <template v-else>
      <ViewUtilityDropdown
        fields-location="inputs"
        class="static block ml-[7px]"
        style="position: absolute; top: 0; right: 8px;"
      />

      <template
        v-for="(group, g_index) in groups"
        :key="g_index"
      >
        <!-- NEW GROUP SLOT -->
        <template v-if="group.columns.length > 1 && (g_index === 0 || (g_index > 0 && groups[g_index-1].columns.length > 1))">
          <div
            class="kn-form-group slot"
            data-type="slot"
          >
            <div class="drop-wrapper horizontal">
              <div
                class="drop-target horizontal"
                :data-group="g_index"
                :data-column="0"
                :data-item="0"
                data-target-type="group-new"
                @dragover="onDragOver"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @drop="onDrop"
              >
                <div class="line" />
              </div>
            </div>
          </div>
        </template>

        <div
          class="kn-form-group"
          data-type="items"
          @dragover="onDragOverGroup"
          @dragleave="onDragLeaveGroup"
          @dragend="onDragEndGroup"
        >
          <template
            v-for="(column, c_index) in group.columns"
            :key="`column-${g_index}-${c_index}`"
          >
            <TransitionGroup
              name="column-list"
              tag="div"
              class="kn-form-group-column draggable-inputs"
              data-type="items"
            >
              <div
                v-if="c_index === 0"
                key="left-slot"
                v-tippy
                class="drop-wrapper vertical left"
                content="Three is the highest number of columns allowed"
              >
                <div
                  :data-group="g_index"
                  :data-column="c_index"
                  :data-item="0"
                  data-target-type="column-new"
                  class="drop-target vertical"
                  :data-is-disabled="(group.columns.length > 2)"
                  @dragover="onDragOver"
                  @dragenter="onDragEnter"
                  @dragleave="onDragLeave"
                  @drop="onDrop"
                >
                  <div class="line" />
                </div>
              </div>
              <div
                key="right-slot"
                class="drop-wrapper vertical right"
                :class="{'last': c_index + 1 === group.columns.length}"
              >
                <div
                  :data-group="g_index"
                  :data-column="c_index + 1"
                  :data-item="0"
                  data-target-type="column-new"
                  class="drop-target vertical"
                  :data-is-disabled="(group.columns.length > 2)"
                  @dragover="onDragOver"
                  @dragenter="onDragEnter"
                  @dragleave="onDragLeave"
                  @drop="onDrop"
                >
                  <div class="line" />
                </div>
              </div>

              <!-- Form Input -->
              <div
                v-for="(input, i_index) in column.inputs"
                :key="i_index"
              >
                <RouterLink
                  v-slot="{ isActive, navigate }"
                  :to="inputLink(routePrefix, g_index, c_index, i_index)"
                  custom
                >
                  <div
                    class="item-wrapper"
                    :class="{'router-link-active': isActive}"
                    :data-group="g_index"
                    :data-column="c_index"
                    :data-item="i_index"
                    draggable="true"
                    @click="navigate"
                    @dragstart="onDragStart"
                    @dragend="onDragEnd"
                  >
                    <div
                      :data-group="g_index"
                      :data-column="c_index"
                      :data-item="i_index"
                      class="drop-target top"
                      @dragover="onDragOver"
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    />
                    <div
                      :data-group="g_index"
                      :data-column="c_index"
                      :data-item="i_index + 1"
                      class="drop-target bottom"
                      @dragover="onDragOver"
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    />

                    <FormItem
                      :input="setInput(input)"
                      :field="inputField(input)"
                      :value="getFormItemValue(input)"
                      view-key="viewKey"
                      @deleteInput="onDeleteInput($event, g_index, c_index, i_index)"
                    />
                  </div>
                </RouterLink>
              </div>
            </TransitionGroup>
          </template>
        </div>

        <!-- LAST NEW GROUP SLOT -->
        <template v-if="g_index === groups.length-1 && group.columns.length > 1">
          <div
            :key="`final-group-slot-${g_index}`"
            class="kn-form-group slot"
            data-type="slot"
          >
            <div class="drop-wrapper horizontal">
              <div
                class="drop-target horizontal"
                :data-group="g_index + 1"
                :data-column="0"
                :data-item="0"
                data-target-type="group-new"
                @dragover="onDragOver"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @drop="onDrop"
              >
                <div class="line" />
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>

    <!-- SUBMIT -->
    <div
      v-if="canBeEmpty || !fieldsAreEmpty"
      class="kn-submit"
    >
      <button
        class="kn-button is-primary"
        type="submit"
        style="pointer-events: none;"
      >
        {{ submitButtonText }}
      </button>
    </div>
  </form>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import FormItem from '@/components/renderer/form/FormItem';
import EmptyFieldsDropZone from '@/components/renderer/shared/EmptyFieldsDropZone';
import StringUtils from '@/components/util/StringUtils';
import DragDropUtils from '@/components/renderer/mixins/DragDropUtils';
import ViewUtilityDropdown from '@/components/renderer/shared/ViewUtilityDropdown';

export default {
  components: {
    FormItem,
    EmptyFieldsDropZone,
    ViewUtilityDropdown,
  },
  mixins: [
    StringUtils,
    DragDropUtils,
  ],
  props: {
    view: {
      type: Object,
      default: () => ({}),
    },
    schema: { // the schema of the detail groups array
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    design: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    routePrefix: {
      type: String,
      default: '',
    },
    canBeEmpty: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete', 'drop'],
  data() {
    return {
      activeItemSlot: null,
    };
  },
  computed: {
    groups() {
      return this.schema;
    },
    object() {
      return this.$store.getters.getObject(this.view.get('source').object);
    },
    viewKey() {
      return this.view.key;
    },
    formValues() {
      return this.object ? this.object.getFieldDefaults() : {};
    },
    submitButtonText() {
      return this.view.get('submit_button_text') || 'Submit';
    },

    fieldsAreEmpty() {
      // return true if there are no populated columns
      return !this.groups.some((group) => group.columns.some((column) => !isEmpty(column.inputs)));
    },

    isDraggingInput() {
      return this.$store.getters.isDraggingViewItem(this.view.key);
    },

    addFormInputsPath() {
      if (this.routePrefix) {
        return this.routePrefix;
      }

      return `/pages/${this.$route.params.pageKey}/views/${this.view.key}/form/inputs`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initDragDrop();
    });
  },
  methods: {
    getFormItemValue(input) {
      if (!input.field) {
        return undefined;
      }

      const value = this.formValues[`${input.field.key}_raw`] || this.formValues[input.field.key];

      // Filter out symbols.
      // The symbol comes from the default field value where it should select the first option.
      // Not sure how we would do that here, so just return empty string instead.
      if (Array.isArray(value)) {
        return value.map((subValue) => {
          if (typeof subValue === 'symbol') {
            return '';
          }
          return subValue;
        });
      }

      if (typeof value === 'symbol') {
        return '';
      }

      return value;
    },
    inputLink(routePrefix, index, cIndex, iIndex) {
      return `${routePrefix}/rows/${index}/columns/${cIndex}/inputs/${iIndex}`;
    },
    inputField(input) {
      if (input.field && this.object) {
        const field = this.object.getField(input.field.key);

        if (!field) {
          throw new Error(
            `Field ${input.field.key} was expected on object ${this.object.key} but was not found. This likely means an input exists for a removed field.`,
          );
        }

        return field;
      }

      return null;
    },
    setInput(input) {
      const field = this.inputField(input);

      if (field && field.format) {
        input.format = field.format;
      }

      if (field && field.type) {
        input.type = field.type;
      }

      return input;
    },
    hasLabel(inputType) {
      const inputsWithNoLabels = [
        'copy',
        'section_break',
        'divider',
        'image',
      ];

      return !inputsWithNoLabels.includes(inputType);
    },
    onDropViewItem(event, newItem) {
      event.preventDefault();
      event.stopPropagation();

      this.$emit('drop', {
        event, newItem,
      });
    },
    onDeleteInput(event, groupIndex, columnIndex, itemIndex) {
      this.$emit('delete', {
        groupIndex, columnIndex, itemIndex,
      });
    },
  },
};
</script>

<style lang="scss">
.kn-form-group {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin: 0;
  position: relative;

  &.is-dragging-over {
    background-color: #f9f9f9;
  }

  .kn-form-group-column {
    //padding: 4px;
    flex-grow: 1;
    position: relative;
    padding: 10px;
    min-width: 370px;
    max-width: 600px;

    &.slot {
      flex-grow: 0;
    }
    &.slot.closed {
      width: 17px;
    }

    .item-wrapper {

      &:last-child {
        margin-bottom: 0;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #444d75;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
        border-radius: .3em;
        border-top-left-radius: 0;
        transition: all 200ms ease-out;
        cursor: move;
      }
    }
  }
}

.kn-form-group.slot .kn-form-group-column {
  flex-grow: 1;
}
.kn-form-group.slot {
  width: 100%;
  height: 12px;
}

.kn-form {
  min-width: 600px;
}

.kn-content button, .kn-content input, .kn-content select, .kn-content textarea, .kn-form button, .kn-form input, .kn-form select, .kn-form textarea {
  margin: 0;
}

.kn-button {
  min-width: 140px;
  align-items: center;
  border-radius: .35em;
  display: inline-flex;
  font-size: 14px;
  height: 32px;
  justify-content: flex-start;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  vertical-align: top;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  white-space: nowrap;
  text-decoration: none strong;
  text-decoration-color: inherit;
}

.kn-form {
  position: relative;

  .kn-item {

    label {
      display: block;
      font-weight: 500;
    }

    item, textarea {
      border: 0;
      padding: 0.4em .5em;
      font-size: 1em;
      line-height: 1.25em;
      border-radius: .3em;
      color: #4b5054;
      width: 100%;
      border: 1px solid #d6d6de;
      font-weight: 300;
    }
  }

  .large-items {
    item, textarea {
      padding: 0.6em .75em;
      font-size: 1em;
      line-height: 1.45em;
      border-radius: .4em;
    }
  }

  .large-spacing {
    .kn-item {
      padding: 10px 6px;
    }
  }
  .small-spacing {
    .kn-item {
      padding: 2px 6px;
    }
  }

  .small-items {
    item, textarea {
      padding: 0.3em .4em;
      font-size: .86em;
      line-height: 1.2em;
      border-radius: .25em;
    }
  }
}
</style>
