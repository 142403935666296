<template>
  <Popover v-if="showThemeWarning">
    <template #trigger>
      <div class="themeUpgrade__trigger">
        <Icon
          v-tippy="{ allowHTML: true }"
          content="Only available with a newer theme.<br />Click for more..."
          :type="icon"
          class="themeUpgrade__icon"
        />
      </div>
    </template>
    <template #content>
      <div
        class="themeUpgrade is-padded"
        style="max-width: 325px;"
      >
        <slot name="title">
          <div class="themeUpgrade__title">
            <span v-html="title" />
            <HelpIcon
              v-if="tooltipText"
              :copy="tooltipText"
            />
          </div>
        </slot>
        <slot name="body">
          <ThemeWarning />
        </slot>
      </div>
    </template>
  </Popover>
</template>

<script>
import HelpIcon from '@/components/ui/HelpIcon';
import ThemeWarning from '@/components/ui/ThemeWarning';
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';

export default {
  components: {
    HelpIcon,
    Icon,
    Popover,
    ThemeWarning,
  },
  props: {

    /**
       * Copy text content
       * @param {String}
       */
    copy: {
      type: String,
      default: 'This section contains settings only available on a <strong>newer theme</strong>.',
    },

    /**
       * Set the icon for the tooltip. Defaults to `theme-upgrade`
       * @param {String}
       */
    icon: {
      type: String,
      default: 'theme-upgrade',
    },

    /**
       * The level of theme. 2 is legacy, 3 is current, and 4 will be the new upcoming beta
       * @param {Number}
       */
    themeMinimum: {
      type: Number,
      default: 3,
    },

    /**
       * Text content for title
       * @param {String}
       */
    title: {
      type: String,
      default: 'You are currently using a legacy theme',
    },

    /**
       * Text content for tooltip
       * @param {String}
       */
    tooltipText: {
      type: String,
      default: '',
    },

    /**
       * Pass additional config options to the tooltip
       * @param {Object}
       */
    tooltipConfig: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    appTheme() {
      return 3;
    },
    showThemeWarning() {
      return true;
    },
    tooltipSettings() {
      return {
        content: this.helpText,
        classes: this.tooltipClasses,
        ...this.tooltipConfig,
      };
    },
  },
};
</script>

<style lang="scss">
  .themeUpgrade {
    font-size: 13px;
    color: $gray700;
  }

  .themeUpgrade__title {
    margin: 4px 0 8px;
    font-size: 15px;
    font-weight: 600;
    line-height: 140%;
  }
</style>
