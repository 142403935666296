import getHelp from './api-help-get-help';
import reportBug from './api-help-report-bug';
import reportEmergency from './api-help-report-emergency';
import search from './api-help-search';
import shareFeedback from './api-help-share-feedback';

export default {
  // Full namespace: api/help
  help: {
    namespaced: true,
    modules: {
      ...getHelp,
      ...reportBug,
      ...reportEmergency,
      ...search,
      ...shareFeedback,
    },
  },
};
