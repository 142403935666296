<template>
  <EmptyStateGeneric top>
    <div
      class="mb-2"
    >
      <Icon
        type="database-records"
        class="h-auto w-[110px] fill-[url(#svg-brand-gradient)] opacity-40"
      />
    </div>

    <h2 class="empty-state__title margin-bottom-lg text-xl text-emphasis font-medium mb-2">
      {{ emptyStateContent.title }}
    </h2>

    <p class="empty-state__paragraph text-default mb-6">
      {{ emptyStateContent.message }}
    </p>

    <BuilderButton
      theme="confirm"
      size="lg"
      class="mb-6"
      data-feature="add_record_button"
      data-feature-x-offset="-24"
      data-feature-y-offset="-24"
      @click="triggerAddRecordModal"
    >
      <icon type="add" /> Add a Record
    </BuilderButton>

    <p
      class="mb-0"
    >
      <a
        :href="emptyStateContent.learnMoreUrl"
        target="_blank"
        class="flex items-center justify-center underline text-default text-base font-medium"
      >
        Learn more about {{ emptyStateContent.learnMoreAbout }}
        <Icon
          type="open-in-new"
          class="link-with-icon__icon ml-2 text-emphasis"
        />
      </a>
    </p>
  </EmptyStateGeneric>
</template>

<script>
import indefinite from 'indefinite';
import Icon from '@/components/ui/Icon';
import EmptyStateGeneric from '@/components/ui/EmptyStateGeneric';
import BuilderButton from '@/components/ui/BuilderButton';

export default {
  name: 'RecordsEmpty',
  components: {
    EmptyStateGeneric,
    BuilderButton,
    Icon,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  computed: {
    emptyStateContent() {
      const content = {
        title: `Create ${indefinite(this.objectName)} record`,
        message: 'Records can also be imported, submitted by forms, or created programmatically via the API',
        learnMoreUrl: 'https://learn.knack.com/article/vdhhuysjch-working-with-records',
        learnMoreAbout: 'records',
      };

      const objectType = this.object.attributes.type;
      if (objectType === 'EcommercePaymentObject') {
        content.message = 'Payment records are added from the Live App when a user submits a payment view';
        content.learnMoreUrl = 'https://learn.knack.com/article/jpwygdsfda-payment-views';
        content.learnMoreAbout = 'payment views';
      }

      return content;
    },
    objectName() {
      return String(this.object?.inflections?.singular) ?? '';
    },
  },
  methods: {
    triggerAddRecordModal() {
      this.$router.push(`/records/objects/${this.object.key}/add`);
    },
  },
};
</script>
