<template>
  <view-toolbox
    back-title="page"
    title="Edit Image"
  >
    <div>
      <toc-links :items="items" />
    </div>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import TocLinks from '@/components/views/TocLinks';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'ImageTOC',
  components: {
    ViewToolbox,
    TocLinks,
  },
  mixins: [
    ViewUtils,
  ],
  data() {
    return {
      items: [
        {
          title: 'Settings',
          copy: 'design and settings',
          link: 'settings',
        },
      ],
    };
  },
};
</script>
