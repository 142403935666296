<template>
  <div data-cy="page-settings-dropdown">
    <a
      v-tippy
      :content="isMenuPage ? 'Dropdown Menu Settings' : 'Page Settings'"
      class="settings transition"
      :class="classes.trigger"
      @click.prevent="onOpenPopover()"
    >
      <Icon
        type="chevron-single"
        :class="classes.triggerIcon"
      />
    </a>

    <Popover
      v-if="popoverIsOpen"
      placement="bottom-start"
      :open="true"
      :arrow-offset="18"
      @hide="onClosePopover()"
    >
      <template #content>
        <div
          data-cy="page-settings-dropdown-links"
          class="links"
        >
          <RouterLink
            v-close-popper
            class="hover:text-emphasis"
            :class="linkItemClasses"
            :to="`/pages/${pageKey}/settings`"
            data-cy="page-settings"
          >
            <Icon
              type="settings"
              class="small"
            /> Settings
          </RouterLink>
          <RouterLink
            v-if="showRules"
            v-close-popper
            class="hover:text-emphasis"
            :class="linkItemClasses"
            :to="`/pages/${pageKey}/rules`"
            data-cy="page-rules"
          >
            <Icon
              type="check-circle"
              class="small"
            /> Page Rules
          </RouterLink>
          <RouterLink
            v-if="showLoginRequire"
            v-close-popper
            class="hover:text-emphasis"
            :class="linkItemClasses"
            :to="`/pages/${pageKey}/login`"
            data-cy="page-require-login"
          >
            <Icon
              type="lock"
              class="small"
            /> Require Login
          </RouterLink>
          <a
            v-if="showLoginRemove"
            v-close-popper
            class="hover:text-emphasis"
            :class="linkItemClasses"
            data-cy="page-remove-login"
            @click="onRemoveLogin"
          >
            <Icon
              type="lock"
              class="small"
            /> Remove Login
          </a>
          <RouterLink
            v-if="embedPageKey"
            v-close-popper
            class="hover:text-emphasis"
            :class="linkItemClasses"
            :to="`/pages/${embedPageKey}/publish`"
            data-cy="page-embed"
          >
            <Icon
              type="embed"
              class="small"
            /> Embed in Webpage
          </RouterLink>
          <RouterLink
            v-if="showCopy"
            v-close-popper
            class="hover:text-emphasis"
            :class="linkItemClasses"
            :to="`/pages/${pageKey}/copy`"
            data-cy="page-copy"
          >
            <Icon
              type="copy"
              class="small"
            /> Copy
          </RouterLink>
          <RouterLink
            v-if="showDelete"
            v-close-popper="!disableDeleteLink"
            v-tippy="{ allowHTML: true }"
            :content="disableDeleteLink ? 'You need at least one page! Add a new page<br />first if you want to delete this one.' : ''"
            :to="`/pages/${pageKey}/delete`"
            :aria-disabled="disableDeleteLink"
            :event="disableDeleteLink ? '' : 'click'"
            :tag="disableDeleteLink ? `button`: `a`"
            :class="[
              {
                'link--disabled': disableDeleteLink
              },
              linkItemClasses
            ]"
            class="link text-destructive hover:text-destructive"
            data-cy="page-delete"
          >
            <Icon
              type="delete"
              class="small"
            /> Delete
          </RouterLink>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';

export default {
  components: {
    Icon,
    Popover,
  },
  props: {
    page: {
      type: Object,
      default: () => ({}),
    },
    classes: {
      type: Object,
      default: () => ({
        trigger: 'hover-link',
        triggerIcon: '',
      }),
    },
  },
  emits: ['toggle'],
  data() {
    return {
      popoverIsOpen: false,
    };
  },
  computed: {
    pageKey() {
      return this.page.key;
    },
    embedPageKey() {
      if (this.page.isGlobalLoginPage()) {
        // The global login can not be embedded.
        return null;
      }

      if (this.isLoginPage) {
        // Link to the first start page under in the login instead.
        const childStartPage = this.page.getFirstChildStartPage();

        if (!childStartPage) {
          return null;
        }

        return childStartPage.key;
      }

      if (!this.isStartPage || this.isMenuPage) {
        return null;
      }

      return this.page.key;
    },
    isChild() {
      return !this.page.isTopLevelPage();
    },
    isLoginPage() {
      return this.page.isLoginPage();
    },
    isMenuPage() {
      return !!this.page.isMenuPage?.();
    },
    isUserPage() {
      return this.page.isUserPage();
    },
    isStartPage() {
      return this.page.isStartPage();
    },
    disableDeleteLink() {
      // user pages can always be deleted
      if (this.isUserPage) {
        return false;
      }

      // menu pages can always be deleted
      if (this.isMenuPage) {
        return false;
      }

      // can't delete if only one start page exists and this is a start page (login pages can be deleted)
      if (this.isStartPage) {
        return this.$store.getters['page/startPages'].length === 1;
      }

      return false;
    },
    showLoginRequire() {
      if (this.isMenuPage || this.isUserPage || this.page.isLoginPage()) {
        return false;
      }

      const parentPage = this.page.getParentPage();

      // Children of the global login page can still have their own login page.
      if (!parentPage || parentPage.isGlobalLoginPage()) {
        return true;
      }

      // don't show if the parent is a login page, unless it is the global login page.
      if (this.page.isAuthenticated() && parentPage.isLoginPage()) {
        return false;
      }

      return true;
    },
    showLoginRemove() {
      if (this.page.isGlobalLoginPage()) {
        // The global login can not be removed from this menu.
        return false;
      }

      return this.page.isLoginPage();
    },
    showCopy() {
      return this.page.isTopLevelPage(true) && !this.page.isMenuPage();
    },
    showDelete() {
      if (this.isLoginPage && this.$store.getters.isGlobalLogin && !this.page.parent) {
        // The global login can not be deleted from this menu.
        return false;
      }

      return true;
    },
    showRules() {
      return this.page.canHavePageRules();
    },
    linkItemClasses() {
      return 'rounded hover:bg-brand-50 text-base text-default';
    },
  },

  methods: {
    onOpenPopover() {
      this.popoverIsOpen = true;
      this.$emit('toggle', true);
    },
    onClosePopover() {
      this.popoverIsOpen = false;
      this.$emit('toggle', false);
    },
    onRemoveLogin() {
      const rawViews = this.$store.getters.getViewsByPageKey(this.page.key);

      log('onRemoveLogin', rawViews);

      // Route to deleting the login view, which presents a warning
      const loginView = rawViews.find((view) => view.type === 'login');

      this.$router.push(`/pages/${this.page.key}/views/${loginView.key}/delete`);
    },
  },
};
</script>
