<template>
  <div>
    <nav
      v-if="showMenu"
      data-cy="nav-page-menu"
      data-feature="go_to_live_app_button"
      data-feature-x-offset="-26"
      data-feature-y-offset="-32"
      class="accessMenu rounded bg-transparent"
    >
      <a
        :href="getActivePageLiveAppLink"
        class="accessMenu_directLink inline-flex h-8 p-2 justify-center border border-solid border-default border-r-[0.5px] hover:border-brand-600 focus:border-brand-600 focus:bg-brand-50 rounded-l hover:bg-brand-50 text-emphasis group"
        target="_blank"
        @click="logLiveAppAccess()"
      >
        <Icon
          type="open-in-new"
          class="accessMenu_icon mr-1 text-emphasis group-hover:text-brand-400"
        />
        <span class="hidden sm:block">Go to Live App</span>
      </a>
      <Popover
        placement="bottom-end"
        allow-vertical-scroll
      >
        <template #trigger>
          <button
            v-tippy
            content="Select a page"
            class="accessMenu_trigger border border-solid border-default border-l-[0.5px] hover:border-brand-600 rounded-r hover:bg-brand-50 px-0 bg-transparent py-[3px] h-auto group"
            type="button"
          >
            <Icon
              type="arrow-drop-down"
              class="accessMenu_icon h-6 w-6 text-emphasis group-hover:text-brand-400"
            />
          </button>
        </template>
        <template #content>
          <div class="links">
            <div
              v-for="rawPage in pageList"
              :key="rawPage.slug"
              class="multiActionLink hover:bg-brand-50 rounded"
            >
              <a
                target="_blank"
                :href="getPageLiveAppLink(rawPage.slug)"
                class="multiActionLink_link truncate rounded text-base text-default hover:bg-brand-50 hover:text-emphasis"
                @click="logLiveAppAccess(rawPage.key)"
              >
                <Icon
                  v-if="rawPage.authenticated === true"
                  class="text-inherit"
                  type="lock"
                />
                <Icon
                  v-else
                  class="text-inherit"
                  type="page"
                />
                {{ rawPage.name }}
              </a>
              <button
                :key="rawPage.id"
                v-clipboard:copy="getPageLiveAppLink(rawPage.slug)"
                v-tippy
                content="Copy URL to Clipboard"
                class="multiActionLink_action bg-brand-100"
                type="button"
              >
                <Icon
                  class="text-emphasis"
                  type="link"
                />
              </button>
            </div>
          </div>
        </template>
      </Popover>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import LiveAppMixin from '@/components/builder/menus/LiveAppMixin';
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';
import { logEvent } from '@/lib/segment-helper';
import { completeChecklistStep } from '@/checklists/helpers';

export default {
  name: 'LiveAppAccessMenu',
  components: {
    Icon,
    Popover,
  },
  mixins: [
    LiveAppMixin,
  ],
  computed: {
    ...mapGetters('page', [
      'startPages',
    ]),
    showMenu() {
      return !isEmpty(this.startPages);
    },
  },
  methods: {
    logLiveAppAccess(sceneKey) {
      completeChecklistStep('open_live_app');

      if (!sceneKey) {
        return logEvent('viewed_live_app', {
          key: this.activePage?.key,
          source: 'live_app_button',
        });
      }

      logEvent('viewed_live_app', {
        key: sceneKey,
        source: 'live_app_page_list',
      });
    },
  },
};
</script>

<style lang="scss">
.accessMenu {
  align-items: center;
  display: flex;
  height: 2.286em;
  background-color: $primary800;
  border-radius: 1.143em;
  @include font-button-m;

  a,
  button {
    color: $white50;

    &:hover,
    &:focus {
      background-color: $primary900;
      color: $white75;
    }
  }
}

.accessMenu_directLink {
  align-items: center;
  display: flex;
  position: relative;

  height: 100%;
  padding-left: 1.1em;
  padding-right: .75em;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: .5em;

    height: 1.286em;
    width: 1px;
    background-color: $white25;
  }

  .accessMenu_icon {
    @include icon-16;
    margin-right: .5em;
  }
}

.accessMenu_trigger {
  align-items: center;
  appearance: none;
  display: flex;
  cursor: pointer;
  height: 2.909em;
  padding-right: .75em;
  padding-left: .5em;
  background-color: transparent;
  border: none;

  color: rgba(255, 255, 255, 0.5);

  svg {
    @include icon-18;
  }
}
</style>
