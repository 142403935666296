<template>
  <span>
    <Popover placement="bottom-start">
      <template #trigger>
        <button
          v-tippy
          content="Add or remove all fields..."
          type="button"
          class="buttonCircle-outline buttonCircle-dropshadow blue viewUtility_dropdownTrigger flex static p-2 text-sm font-medium text-emphasis rounded bg-white border-default shadow-none w-max mb-4 hover:bg-brand-50 hover:border-brand-600"
        >
          Add/Remove Fields
        </button>
      </template>

      <template #content>
        <div class="links p-0">
          <button
            v-close-popper
            class="link rounded-lg hover:bg-brand-50 text-base text-default hover:text-emphasis p-2 group"
            @click="onClickAddAllFields()"
          >
            <Icon
              class="text-subtle group-hover:text-emphasis"
              type="add"
            /> Add all <strong>{{ objectName }}</strong> fields
          </button>
          <button
            v-close-popper
            class="link rounded-lg hover:bg-brand-50 text-base text-default hover:text-emphasis p-2 group"
            @click="onClickRemoveAllFields()"
          >
            <Icon
              class="text-subtle group-hover:text-emphasis"
              type="remove"
            /> Remove all fields
          </button>
        </div>
      </template>
    </Popover>
  </span>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';

export default {
  components: {
    Icon,
    Popover,
  },
  props: {
    fieldsLocation: {
      type: String,
      required: false,
      default: 'data',
    },
  },
  computed: {
    view() {
      return this.$store.getters.activeView;
    },
    objectName() {
      return ``;
    },
  },
  methods: {
    onClickAddAllFields() {
      // KLUDGE:: the close popover directive doesn't fully close popover, probably due to redrawing
      setTimeout(() => {
        this.view.addAllFields(this.fieldsLocation);
      }, 500);
    },
    onClickRemoveAllFields() {
      // KLUDGE:: the close popover directive doesn't fully close popover, probably due to redrawing
      setTimeout(() => {
        this.view.removeAllFields(this.fieldsLocation);
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.viewUtility_dropdownTrigger {
  display: none !important;
  position: absolute;
  top: 11px;
  right: 5px;

  z-index: 5;
}

.view.is-active:not(.is-new):hover .viewUtility_dropdownTrigger {
  display: flex !important;
}
</style>
