import { Utility } from '@knack/core/dist/utility';
import cloneDeep from 'lodash/cloneDeep';
import { formatNumber, toNumber } from '@/util/FormatHelper';

class Knack {
  constructor() {
    this.domain = (!window.api_domain) ? 'knack.com' : window.api_domain;
    this.protocol = (window.location.href.indexOf('knackinspector.com') === -1 && window.knack_production_mode === 'development') ? 'http://' : 'https://';
    this.loader_url = `${this.protocol}loader.${this.domain}`;
    this.isLoaded = false;
    this.hash_vars = [];

    this.fieldCategoryMapping = Utility.initializeFieldCategoryMapping();

    this.config = {
      fields: {},
      SHORT_TEXT: 'short_text',
      PARAGRAPH_TEXT: 'paragraph_text',
      BOOLEAN: 'boolean',
      MULTIPLE_CHOICE: 'multiple_choice',
      NUMBER: 'number',
      DATE_TIME: 'date_time',
      IMAGE: 'image',
      FILE: 'file',
      CONNECTION: 'connection',
      ADDRESS: 'address',
      NAME: 'name',
      PHONE: 'phone',
      LINK: 'link',
      EMAIL: 'email',
      RICH_TEXT: 'rich_text',
      CURRENCY: 'currency',
      USER: 'user',
      SUM: 'sum',
      MIN: 'min',
      MAX: 'max',
      AVERAGE: 'average',
      EQUATION: 'equation',
      CONCATENATION: 'concatenation',
      VIDEO: 'video',
      AUTO_INCREMENT: 'auto_increment',
      COUNT: 'count',
      SECTION_BREAK: 'section_break',
      TIMER: 'timer',
      RATING: 'rating',
      SIGNATURE: 'signature',
      USER_ROLES: 'user_roles',
      PASSWORD: 'password',
    };
    this.config.fields[this.config.SHORT_TEXT] = {
      label: 'Short Text',
      input_type: 'text_field',
      group: 'basic',
      field_type: 'text',
    };
    this.config.fields[this.config.PARAGRAPH_TEXT] = {
      label: 'Paragraph Text',
      input_type: 'text_area',
      group: 'basic',
      field_type: 'text',
    };
    this.config.fields[this.config.BOOLEAN] = {
      label: 'Yes/No',
      input_type: 'boolean',
      group: 'basic',
      format: true,
    };
    this.config.fields[this.config.MULTIPLE_CHOICE] = {
      label: 'Multiple Choice',
      input_type: 'combo_box',
      group: 'basic',
      format: true,
      field_type: 'text',
    };
    this.config.fields[this.config.DATE_TIME] = {
      label: 'Date/Time',
      input_type: 'text_field',
      group: 'basic',
      format: true,
      field_type: 'date',
    };
    this.config.fields[this.config.NUMBER] = {
      label: 'Number',
      input_type: 'text_field',
      group: 'basic',
      format: true,
      numeric: true,
    };
    this.config.fields[this.config.IMAGE] = {
      label: 'Image',
      input_type: 'upload',
      group: 'basic',
      format: true,
    };
    this.config.fields[this.config.FILE] = {
      label: 'File',
      input_type: 'upload',
      group: 'basic',
    };

    this.config.fields[this.config.ADDRESS] = {
      label: 'Address',
      input_type: 'text_area',
      group: 'special',
      field_type: 'text',
    };
    this.config.fields[this.config.NAME] = {
      label: 'Person',
      input_type: 'text_field',
      group: 'special',
      format: true,
      field_type: 'text',
    };
    this.config.fields[this.config.LINK] = {
      label: 'Link',
      input_type: 'text_field',
      group: 'special',
      format: true,
      field_type: 'text',
    };
    this.config.fields[this.config.EMAIL] = {
      label: 'Email',
      input_type: 'text_field',
      group: 'special',
      format: true,
      field_type: 'text',
    };
    this.config.fields[this.config.PHONE] = {
      label: 'Phone',
      input_type: 'text_field',
      group: 'special',
      format: true,
      field_type: 'text',
    };
    this.config.fields[this.config.RICH_TEXT] = {
      label: 'Rich Text',
      input_type: 'text_area',
      group: 'special',
      field_type: 'text',
    };
    this.config.fields[this.config.CURRENCY] = {
      label: 'Currency',
      input_type: 'text_field',
      group: 'special',
      numeric: true,
    };
    this.config.fields[this.config.AUTO_INCREMENT] = {
      label: 'Auto Increment',
      input_type: 'none',
      group: 'special',
      numeric: true,
    };
    this.config.fields[this.config.TIMER] = {
      label: 'Timer',
      input_type: 'special',
      group: 'special',
      numeric: true,
    };
    this.config.fields[this.config.RATING] = {
      label: 'Rating',
      input_type: 'special',
      group: 'special',
      numeric: true,
    };
    this.config.fields[this.config.SIGNATURE] = {
      label: 'Signature',
      input_type: 'special',
      group: 'special',
      numeric: false,
    };

    this.config.fields[this.config.SUM] = {
      label: 'Sum',
      input_type: 'none',
      group: 'formula',
      numeric: true,
    };
    this.config.fields[this.config.MIN] = {
      label: 'Minimum',
      input_type: 'none',
      group: 'formula',
      numeric: true,
    };
    this.config.fields[this.config.MAX] = {
      label: 'Maximum',
      input_type: 'none',
      group: 'formula',
      numeric: true,
    };
    this.config.fields[this.config.AVERAGE] = {
      label: 'Average',
      input_type: 'none',
      group: 'formula',
      numeric: true,
    };
    this.config.fields[this.config.COUNT] = {
      label: 'Count',
      input_type: 'none',
      group: 'formula',
      numeric: true,
    };
    this.config.fields[this.config.EQUATION] = {
      label: 'Equation',
      input_type: 'display',
      group: 'formula',
      numeric: true,
    };
    this.config.fields[this.config.CONCATENATION] = {
      label: 'Text Formula',
      input_type: 'none',
      group: 'formula',
      field_type: 'text',
    };

    this.config.fields[this.config.PASSWORD] = {
      label: 'Password',
      input_type: 'password',
      group: 'misc',
    };
    this.config.fields[this.config.CONNECTION] = {
      label: 'Connection',
      input_type: 'text_field',
      group: 'connection',
    };
    this.config.fields[this.config.USER] = {
      label: 'User',
      input_type: 'none',
      group: 'user',
    };
    this.config.fields[this.config.USER_ROLES] = {
      label: 'User Roles',
      input_type: 'user_roles',
      group: 'user',
    };
    this.config.fields[this.config.SECTION_BREAK] = {
      label: 'Section Break',
      input_type: 'section_break',
      group: 'internal',
    };
  }

  getCalculatedFieldTypes() {
    return [
      'sum',
      'min',
      'max',
      'average',
      'count',
      'concatenation',
      'equation',
    ];
  }

  getFieldTypes() {
    // Make sure we're not mutating field definitions elsewhere
    return cloneDeep(Utility.fieldTypes);
  }

  getFieldByType(type) {
    const allFields = Object.keys(Utility.fieldTypes).reduce((newArray, key) => newArray.concat(key, Utility.fieldTypes[key]), []);

    return allFields.find((field) => field.type === type);
  }

  // return a var located in the hash query string
  getHashVar(which) {
    return this.hash_vars[which];
  }

  formatCurrency(value) {
    return this.formatNumberWithCommas(Number(value).toFixed(2));
  }

  formatNumber(value, format) {
    return formatNumber(value, format);
  }

  formatNumberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formatNumberForStorage(value) {
    if (value > 0) {
      const kilobytes = value / 1024;

      if (kilobytes > 1000) {
        const megabytes = kilobytes / 1000;

        if (megabytes > 1000) {
          return `${formatNumber((megabytes / 1000), {
            precision: 1,
          })} GB`;
        }

        return `${formatNumber(megabytes, {
          precision: 1,
        })} MB`;
      }

      return `${formatNumber(kilobytes, {
        precision: 1,
      })} KB`;
    }

    return '0 KB';
  }

  toNumber(value) {
    return toNumber(value);
  }

  showSpinner() {
    document.getElementById('kn-logo').classList.add('kn-loading');
  }

  hideSpinner() {
    document.getElementById('kn-logo').classList.remove('kn-loading');
  }
}

export default Knack;
