<template>
  <Ribbon>
    <div class="ribbon_mainRow border-none h-auto p-6">
      <h2 class="ribbonName p-0 mr-12">
        <Icon
          type="settings-user-logins-outline"
          class="ribbonName_icon h-8 w-8 mr-2 text-subtle"
        />
        <span class="ribbonName_title text-emphasis text-xl font-medium">User Logins</span>
      </h2>
    </div>
  </Ribbon>
</template>

<script>
import Ribbon from '@/components/layout/Ribbon';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Ribbon,
    Icon,
  },
};
</script>
