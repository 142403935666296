// couldn't unpack default export in .vue file
// eslint-disable-next-line import/prefer-default-export
export const RuleType = Object.freeze({
  Action: 'action',
  Conditional: 'conditional',
  Display: 'display',
  Filter: 'filters',
  Record: 'record',
  Submit: 'submit',
  Task: 'task',
});
