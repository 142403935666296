<template>
  <Ribbon
    v-if="objectKey"
    id="bots-ribbon"
  >
    <div
      v-if="tabStyle === 'legacy'"
      class="ribbon_mainRow h-min py-3"
    >
      <h2 class="ribbonName mr-12">
        <Icon
          :type="objectIcon"
          class="ribbonName_icon text-default h-6 w-6"
        />
        <RouterLink
          :to="``"
          class="ribbonName_link truncate text-emphasis text-xl"
          v-html="object.name"
        />
        <ObjectEditDropdown
          base-url="/tasks/objects"
          :object="object"
        />
      </h2>

      <ObjectTypeLinks
        #content
        :object="object"
        active-type="tasks"
      />
    </div>
    <ObjectTypeTabs
      v-if="tabStyle === 'connections-split'"
      :object="object"
      active-tab="tasks"
    />
    <div class="ribbon_subRow border-0 gap-2 py-3 h-auto">
      <RouterLink
        v-if="objectKey"
        v-tippy
        content="Add a new task"
        :to="`/tasks/objects/${objectKey}/add/task`"
        class="iconLink ribbonLink ribbonLink-persist p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group"
        style="margin-left: -8px;"
      >
        <Icon
          type="plus-thin"
          class="iconLink_icon iconLink_icon-add ribbonLink_icon text-default mr-0 group-hover:text-brand-400 h-4 w-4"
        />
        <RibbonLinkText
          data-cy="ribbon-add-task"
          text="Add Task"
          class="text-base font-medium"
        />
      </RouterLink>
    </div>
  </Ribbon>
</template>

<script>
import { mapGetters } from 'vuex';
import Ribbon from '@/components/layout/Ribbon';
import Icon from '@/components/ui/Icon';
import ObjectTypeLinks from '@/components/objects/ObjectTypeLinks';
import ObjectEditDropdown from '@/components/objects/ObjectEditDropdown';
import FieldUtils from '@/components/fields/FieldUtils';
import RibbonLinkText from '@/components/layout/RibbonLinkText';
import { AB_TESTING_OBJECT_TABS } from '@/constants/featureflags';
import ObjectTypeTabs from '@/components/objects/ObjectTypeTabs.vue';

export default {
  components: {
    ObjectTypeTabs,
    Ribbon,
    Icon,
    ObjectTypeLinks,
    ObjectEditDropdown,
    RibbonLinkText,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    layout: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters([
      'getObject',
      'checkedRecordIds',
      'getFeatureValue',
    ]),
    objectKey() {
      return this.$route.params.objectKey;
    },
    activeObject() {
      return this.getObject(this.objectKey);
    },
    activeObjectName() {
      return !_.isNil(this.activeObject) ? this.activeObject.name : '';
    },
    objectIcon() {
      return this.activeObject?.get('integration')?.toLowerCase() ?? 'table-cells';
    },
    tabStyle() {
      return this.getFeatureValue(AB_TESTING_OBJECT_TABS);
    },
  },
};
</script>
