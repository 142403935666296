<template>
  <HelpForm
    class="help-form-share-feedback"
    store-namespace="api/help/shareFeedback"
    @submit="submitForm"
    @clear="clearForm"
    @dismissErrors="dismissErrors"
    @dismissSuccess="dismissSuccess"
  >
    <div class="margin-bottom-md mb-4">
      <label class="block text-default font-medium mb-2 leading-4 tracking-[0.14px]">
        What type of feedback do you have to share?
      </label>
      <div class="input-labels tw-input-labels">
        <label>
          <input
            v-model="shareType"
            type="radio"
            value="builder"
          >
          Builder
        </label>
        <label>
          <input
            v-model="shareType"
            type="radio"
            value="live-app"
          >
          Live App
        </label>
        <label>
          <input
            v-model="shareType"
            type="radio"
            value="knowledge-base"
          >
          Knowledge Base
        </label>
        <label>
          <input
            v-model="shareType"
            type="radio"
            value="other"
          >
          Other
        </label>
      </div>
    </div>
    <div class="margin-bottom-md mb-4">
      <label
        for="share-feedback"
        class="text-default font-medium mb-2 leading-4 tracking-[0.14px]"
      >
        Share your feedback here. We love getting feedback, so please share as much detail as you like!
      </label>

      <p class="help-description margin-0 text-[#7B7079] leading-4 tracking-[0.14px] mb-2">
        Do not include sensitive data such as login information via this form.
      </p>

      <textarea
        id="share-feedback"
        v-model="shareFeedback"
      />
    </div>
  </HelpForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HelpForm from '@/components/builder/help/forms/HelpForm';
import { mapApiFormGetters } from '@/lib/vuex-helper';

export default {
  name: 'HelpFormShareFeedback',
  components: {
    HelpForm,
  },
  emits: ['navigateToHome'],
  computed: {
    ...mapGetters('api/help/shareFeedback', [
      'validation',
      'isInvalid',
      'isSuccess',
    ]),
    ...mapApiFormGetters('api/help/shareFeedback', [
      'shareType',
      'shareFeedback',
    ]),
  },
  methods: {
    ...mapActions('api/help/shareFeedback', {
      shareFeedbackUpdateForm: 'updateForm',
      shareFeedbackReset: 'reset',
      shareFeedbackResetForm: 'resetForm',
      shareFeedbackSubmitForm: 'submitForm',
    }),
    submitForm() {
      // Submit form request
      this.shareFeedbackSubmitForm().then(() => {
        if (this.isSuccess) {
          this.clearForm();
        }
      });
    },
    clearForm() {
      this.shareFeedbackResetForm();
    },
    dismissErrors() {
      this.shareFeedbackReset();
    },
    dismissSuccess() {
      this.shareFeedbackResetForm();
      this.shareFeedbackReset();

      this.$emit('navigateToHome');
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  display: block;
}
</style>
