<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Options</label>
      <div v-if="!quickEdit">
        <ActionList
          :key="actionListKey"
          v-model:items="items"
          :default-item="defaultItem"
          :hide-buttons="isImmutable"
          class="is-grouped small bg-transparent p-0"
          :can-sort="true"
          data-cy="multiple-choice-action-list"
          @sort="onSortChoices"
          @delete:item="updateDefaultOption"
        >
          <template #default="props">
            <Icon
              class="text-default w-6 h-6 mt-2"
              type="drag-handle"
            />
            <input
              v-model="props.items[props.itemIndex]"
              type="text"
              :disabled="isImmutable"
              ignore-drag
            >
          </template>
        </ActionList>
        <div v-if="!isImmutable">
          <button
            class="button button-link mt-2 p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 no-underline hover:text-emphasis"
            data-cy="quick-edit-button"
            @click="toggleQuickEdit()"
          >
            quick edit
          </button>
        </div>
      </div>
      <div
        v-if="quickEdit"
        class="quick-edit"
      >
        <p class="mb-2">
          Add each option on a new line:
        </p>
        <textarea
          v-model="quickEditItems"
          class="quick-edit_input"
          data-cy="quick-edit-textarea"
          @keyup.enter.stop
        />
        <span class="quick-edit_actions flex justify-end">
          <button
            class="button save order-2 px-3 py-2.5 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium mr-0"
            data-cy="quick-edit-save"
            @click="saveQuickEditItems()"
          >
            Save
          </button>
          <button
            class="button button-link order-1 px-3 py-2.5 rounded-lg bg-transparent border-0 text-base leading-4 font-medium text-emphasis no-underline"
            data-cy="quick-edit-cancel"
            @click="toggleQuickEdit()"
          >
            Cancel
          </button>
        </span>
      </div>

      <Notification
        v-if="shouldShowWarning"
        class="text-warning-emphasis"
        style="margin-top: 10px"
        icon="exclamation-triangle"
      >
        <p class="text-base my-2">
          Updating this field's <strong>options</strong> could affect other fields, rules, or filters using them.
          After updating, please review any related workflows to make sure they are working as intended.
        </p>
        <p class="text-base">
          <a
            href="https://learn.knack.com/article/13eszen598-field-types#multiple-choice"
            class="underline link-with-icon link-with-icon--right text-warning-emphasis"
            target="_blank"
          >
            Learn more
          </a> about Multiple Choice fields.
        </p>
      </Notification>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Default Option</label>
      <div>
        <select
          v-model="localField.format.default"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="kn-blank">
            Blank
          </option>
          <option
            v-for="item in items"
            :key="item"
          >
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <div
      v-if="showBlankFormat"
      key="format-blank"
    >
      <label class="text-default text-sm font-medium mb-2 leading-4">Blank Text</label>
      <div>
        <input
          v-model="localField.format.blank"
          type="text"
          placeholder="Select..."
        >
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Sort</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="sorting"
          type="radio"
          value="alphabetical"
        >Alphabetical</label>
        <label><input
          v-model="sorting"
          type="radio"
          value="custom"
        >Custom</label>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Layout</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="localField.format.type"
          type="radio"
          value="single"
        >Dropdown - One Selection</label>
        <label><input
          v-model="localField.format.type"
          type="radio"
          value="multi"
        >Dropdown - Multiple Selections</label>
        <label><input
          v-model="localField.format.type"
          type="radio"
          value="checkboxes"
        >Check Boxes</label>
        <label><input
          v-model="localField.format.type"
          type="radio"
          value="radios"
        >Radio Buttons</label>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import ActionList from '@/components/ui/lists/ActionList';
import Icon from '@/components/ui/Icon';
import FieldUtils from '@/components/fields/FieldUtils';
import Notification from '@/components/ui/notifications/Notification';

export default {
  name: 'MultipleChoice',
  components: {
    ActionList,
    Icon,
    Notification,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:fieldLocal'],
  data() {
    return {
      quickEdit: false,
      quickEditItems: [],
      actionListKey: 0,
      shouldShowWarning: false,
      initialItems: [],
    };
  },
  computed: {
    localField: {

      get() {
        return this.fieldLocal;
      },
      set(newVal) {
        this.$emit('update:fieldLocal', newVal);
      },
    },
    isImmutable() {
      return this.localField.immutable;
    },
    showBlankFormat() {
      return (this.localField.format.type === 'single' && this.localField.format.default === 'kn-blank');
    },
    sorting: {
      get() {
        return this.localField.format?.sorting || 'alphabetical';
      },
      set(newVal) {
        this.localField.format.sorting = newVal;
      },
    },
    items: {
      get() {
        if (!this.localField.format.options) {
          return [];
        }

        return this.localField.format.options;
      },
      set(newValue) {
        this.localField.format.options = newValue;
      },
    },
  },
  watch: {
    // Watch for changes to the multiple choice options
    items: {
      handler(newItems) {
        this.setShouldShowWarning(newItems);
      },
      deep: true,
    },
    // Watch for changes to the multiple choice options when editing via "quick edit"
    quickEditItems(newQuickEditItems) {
      const newItems = this.getQuickEditItemsAsArray(newQuickEditItems);
      this.setShouldShowWarning(newItems);
    },
    sorting(newVal) {
      if (!this.localField.format?.options) {
        return;
      }

      if (newVal === 'alphabetical') {
        this.localField.format.options = this.localField.format.options.sort((a, b) => a.localeCompare(b));
      }
    },
  },
  mounted() {
    // Save the initial multiple choice option values when the component mounts
    this.initialItems = cloneDeep(this.items);
  },
  methods: {
    defaultItem() {
      return `Another Choice`;
    },
    toggleQuickEdit() {
      this.quickEdit = !this.quickEdit;

      if (this.quickEdit) {
        this.quickEditItems = this.items.join('\n');
      } else {
        this.setShouldShowWarning(this.quickEditItems);
      }
    },
    saveQuickEditItems() {
      this.items = this.getQuickEditItemsAsArray(this.quickEditItems);

      this.updateDefaultOption();

      this.quickEdit = false;

      this.sorting = 'custom';
    },
    getQuickEditItemsAsArray(quickEditItems) {
      return quickEditItems.split('\n').filter((item) => item);
    },
    setShouldShowWarning(newItems) {
      // This will evaluate to true if all the initialValues are included in the newItems array
      const containsAll = this.initialItems.every((item) => newItems.includes(item));

      // Show a warning message only when the field is being edited, and if newItems don't include all the
      // initialItems (this would mean one or more initial items have been modified)
      this.shouldShowWarning = !this.isNew && !containsAll;
    },
    updateDefaultOption() {
      if (!this.items.includes(this.localField.format.default)) {
        this.localField.format.default = 'kn-blank';
      }
    },
    onSortChoices(items) {
      this.items = items;

      this.sorting = 'custom';

      // Manually the key for the ActionList component to force a re-render.
      // This is generally an anti-pattern, but was added here to avoid potentially breaking changes in ActionList.
      this.actionListKey++;
    },
  },
};
</script>

<style lang="scss">
.quick-edit_input {
  width: 100%;
  min-height: 100px;
}

.quick-edit_actions {
  display: flex;
  justify-content: flex-start;

  margin-top: $spacing-xs;
}

.quick-edit_actions button {
  margin-right: $spacing-xs;
}

.icon.icon-drag-handle {
  color: $gray400;

  width: 24px;
  height: 24px;
  margin: 4px 4px 4px 0;
  padding: 0;

  &:hover {
    color: $gray900;
    cursor: grab;
  }
}

.action-list input {
  margin-right: $spacing-xs;
}
</style>
