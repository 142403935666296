import Joi from '@hapi/joi';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { getGroupsFromViews } from '@/lib/page/page-helper';
import { getViewSourceOptions } from '@/lib/page/view-source-helper';
import store from '@/store';
import View from '@/store/models/View';
import BasePage from '@/store/models/page/BasePage';

class Page extends BasePage {
  /**
   * The raw page data.
   * This comes directly from the app object.
   * @type {object}
   */
  attributes;

  /**
   * The parent object.
   * @type {{key: string, slug: string} | undefined}
   */
  parent;

  /**
   * The children objects.
   * @type {Array<{key: string, slug: string}>}
   */
  children = [];

  /**
   * The depth of this page (no parent = 0, 1 parent = 1, etc).
   * @type {number}
   */
  depth = 0;

  /**
   * The menu parent object.
   * @type {{key: string, slug: string} | undefined}
   */
  menuParent;

  /**
   * The menu children objects.
   * @type {Array<{key: string, slug: string}>}
   */
  menuChildren = [];

  /**
   * Whether or not the page is authenticated.
   * @type {boolean}
   */
  authenticated = false;

  /**
   * The authentication profiles.
   * @type {[]}
   */
  authentication_profiles = []; /* eslint-disable-line camelcase */

  /**
   * Source options.
   * Lazy loaded through the 'sourceOptions' getter.
   * @type {[] | undefined}
   */
  _sourceOptions;

  /**
   * The page view objects.
   * Lazy loaded through the `views` getter.
   * @type {View[] | undefined}
   */
  _views;

  /**
   * Whether or not the groups need to be lazy loaded.
   * @type {boolean}
   */
  _groupsAreSet = false;

  /**
   * @constructor
   * @param {RawPage} rawPage
   * @param {object[]} rawViews
   */
  constructor(rawPage, rawViews) {
    super();

    // Set the page attributes
    this.updatePageSchema(rawPage.raw, rawViews || []);

    this.loadPropertiesFromRawPage(rawPage);
  }

  /**
   * Loads the meta properties from the rawPage object.
   *
   * @param {RawPage} rawPage
   */
  loadPropertiesFromRawPage(rawPage) {
    const metaProperties = ['authenticated', 'authentication_profiles', 'depth', '_sourceOptions'];

    metaProperties.forEach((metaProperty) => {
      this[metaProperty] = rawPage[metaProperty];
    });

    if (rawPage.parent) {
      this.parent = pick(rawPage.parent, ['key', 'slug']);
    }

    if (rawPage.menuParent) {
      this.menuParent = pick(rawPage.menuParent, ['key', 'slug']);
    }

    if (!isEmpty(rawPage.children)) {
      this.children = rawPage.children.map(
        (childPage) => pick(childPage, ['key', 'slug']),
      );
    }

    if (!isEmpty(rawPage.menuChildren)) {
      this.menuChildren = rawPage.menuChildren.map(
        (menuChildPage) => pick(menuChildPage, ['key', 'slug']),
      );
    }
  }

  /**
   * Updates the raw schema data for the page.
   *
   * @param {object} pageSchema
   * @param {object[]} [newViewsSchema]
   */
  updatePageSchema(pageSchema, newViewsSchema) {
    const previousObject = this.attributes?.object;

    // Clone the page schema data so that adding views (or other mutations) will not mutate the
    // raw page schema data in the RawPage object.
    const safePageSchema = cloneDeep(pageSchema);

    if (newViewsSchema) {
      safePageSchema.views = cloneDeep(newViewsSchema);
    }

    this.attributes = {
      ...this.getDefaults(safePageSchema),
      ...this.attributes,
      ...safePageSchema,
    };

    if (safePageSchema.views) {
      // Reload the views the next time they are referenced.
      this._views = undefined;
      this._groupsAreSet = false;
    }

    if (this.object !== previousObject) {
      // This will make sure the source options are rebuilt the next time they are referenced.
      this._sourceOptions = undefined;
    }

    if (safePageSchema.groups) {
      this._groupsAreSet = false;
    }
  }

  setViews(views) {
    if (isEmpty(views)) {
      this.views = [];

      return;
    }

    this.views = views.map((viewData) => new View(viewData, {
      key: this.key,
      sourceOptions: this.sourceOptions,
    }));
  }

  hasView(viewKey) {
    return this.views.map((view) => view.key).includes(viewKey);
  }

  getDefaults(page) {
    const defaults = {};

    if (!page.parent) {
      defaults.page_menu_display = true;
      defaults.ignore_entry_scene_menu = false;
    }

    if (page.type && page.type === 'menu') {
      defaults.menu_pages = [];
    }

    defaults.allowed_profiles = [];

    return defaults;
  }

  get raw() {
    return this.attributes;
  }

  // The minimum the server needs to create a page
  rawBasic() {
    const raw = JSON.parse(JSON.stringify(this.raw));

    if (this.isNewPage()) {
      delete raw.key;
    }

    return raw;
  }

  get(property) {
    return this.attributes[property];
  }

  set(property, newVal) {
    this.attributes[property] = newVal;
  }

  get name() {
    return this.attributes.name;
  }

  set name(name) {
    this.attributes.name = name;
  }

  get key() {
    return this.attributes.key;
  }

  get slug() {
    return this.attributes.slug;
  }

  get type() {
    return this.attributes.type;
  }

  set type(newType) {
    this.attributes.type = newType;
  }

  // get parent () {
  //
  //   return this.attributes.parent
  // }
  //
  // set parent (newParent) {
  //
  //   this.attributes.parent = newParent
  // }

  get object() {
    return this.attributes.object;
  }

  set object(newObject) {
    this.attributes.object = newObject;
  }

  get menu() {
    return this.attributes.menu;
  }

  /*
  // this.authenticated as a meta property
  get authenticated () {

    return this.attributes.authenticated
  }

  set authenticated (authenticated) {

    this.attributes.authenticated = authenticated
  }
  */

  /* eslint-disable camelcase */
  get menu_pages() {
    return this.attributes.menu_pages || [];
  }

  /* eslint-disable camelcase */
  set menu_pages(menuPages) {
    this.attributes.menu_pages = menuPages;
  }

  // allowed_profiles is the user page property for storing which roles can access
  /* eslint-disable camelcase */
  get allowed_profiles() {
    return this.attributes.allowed_profiles;
  }

  /* eslint-disable camelcase */
  set allowed_profiles(allowed_profiles) {
    this.attributes.allowed_profiles = allowed_profiles;
  }

  get views() {
    if (this._views) {
      return this._views;
    }

    this.setViews(this.raw.views);

    return this._views;
  }

  set views(newViews) {
    this._views = newViews;
  }

  /**
   * Gets the view source options and lazy loads them if they are not already set.
   *
   * @returns {object[]}
   */
  get sourceOptions() {
    if (this._sourceOptions) {
      return this._sourceOptions;
    }

    this.buildViewSourceOptions();

    return this._sourceOptions;
  }

  set sourceOptions(newSourceOptions) {
    this._sourceOptions = newSourceOptions;
  }

  /**
   * Gets the groups and lazy loads them if they are not already set.
   *
   * @returns {Array<{ columns: Array<{ width: number, keys: string[] }> }>}
   */
  get groups() {
    if (this._groupsAreSet) {
      return this.raw.groups;
    }

    if (this.raw.groups && !isEmpty(this.raw.groups)) {
      this._groupsAreSet = true;

      return this.raw.groups;
    }

    this.buildGroups();

    this._groupsAreSet = true;

    return this.raw.groups;
  }

  set groups(newGroups) {
    this.raw.groups = newGroups;
  }

  /**
   * Builds the view source options from the object.
   */
  buildViewSourceOptions() {
    this.sourceOptions = getViewSourceOptions(this.object, this);
  }

  /**
   * Builds the groups from the views.
   */
  buildGroups() {
    this.groups = getGroupsFromViews(
      this.attributes.views || [],
    );
  }

  /**
   * Safely sets or adds a view to the page.
   *
   * @param {View} newView
   * @returns {View} - The new view.
   */
  setView(newView) {
    if (!(newView instanceof View)) {
      throw new Error('Page.setView() must be given a View instance.');
    }

    if (!this.attributes.views) {
      this.attributes.views = [];
    }

    let viewFound = false;

    this.attributes.views = this.attributes.views.map((viewData) => {
      if (viewData.key === newView.key) {
        viewFound = true;

        return newView.raw();
      }

      return viewData;
    });

    if (viewFound) {
      // The view already exists, so update the views array as well.
      this.views = this.views.map((view) => {
        if (view.key === newView.key) {
          return newView;
        }

        return view;
      });
    } else {
      // This is a new view, so add it.
      this.attributes.views.push(newView.raw());
      this.views.push(newView);
    }

    return this.getView(newView.key);
  }

  /**
   * Safely removes a view from the page.
   *
   * @param {string} viewKey
   */
  removeView(viewKey) {
    if (!this.attributes.views) {
      this.attributes.views = [];
    }

    this.attributes.views = this.attributes.views.filter((viewData) => viewData.key !== viewKey);

    this.views = this.views.filter((view) => view.key !== viewKey);
  }

  // Any page protected by top-level login
  isStartPageWithLogin() {
    if (this.parent) {
      const parentPage = this.getParentPage();

      if (!parentPage) {
        return false;
      }

      if (!parentPage.parent && parentPage.isLoginPage()) {
        return true;
      }
    }

    return false;
  }

  isAuthenticated() {
    if (!isEmpty(this.menu_pages)) {
      return this.menu_pages.some((pageKey) => {
        const matchedPage = store.getters.getPageByKey(pageKey);

        if (!isNil(matchedPage)) {
          return matchedPage.isAuthenticated();
        }
      });
    }
    return this.authenticated;
  }

  get pageObject() {
    return this.getPageObject();
  }

  hasUserRoleOptions() {
    return this.authentication_profiles.length > 0;
  }

  getRoleObjects() {
    const objects = [];

    const profiles = this.authentication_profiles || [];

    profiles.forEach((role) => {
      const roleObject = store.getters.getObjectByRole(role);

      if (roleObject) {
        objects.push(roleObject);
      } else {
        console.error( // eslint-disable-line no-console
          `Invalid profile '${role}' found on page '${this.key}' - no matching object.`,
        );
      }
    });

    return objects;
  }

  getAccountPlusRoleObjects() {
    const roleObjects = this.getRoleObjects();

    const accountsObject = store.getters.getObjectByRole('all_users');
    if (!accountsObject) {
      return roleObjects;
    }

    return roleObjects.concat([accountsObject]);
  }

  /**
   * Gets the parent page object for this page.
   * This will return the menu parent if one is available and `excludeMenuParent` is false.
   *
   * @param {boolean} [excludeMenuParent] - If true, the menu parent will be skipped.
   * @returns {RawPage | undefined}
   */
  getParentPage(excludeMenuParent = false) {
    if (!excludeMenuParent && this.menuParent?.key) {
      return store.getters.getPageByKey(this.menuParent.key);
    }

    if (this.parent?.key) {
      return store.getters.getPageByKey(this.parent.key);
    }

    return undefined;
  }

  /**
   * Gets the first child of this page.
   *
   * @returns {RawPage | undefined}
   */
  getFirstChild() {
    let childKey;

    if (!isEmpty(this.menuChildren)) {
      childKey = this.menuChildren[0].key;
    } else {
      childKey = this.children[0]?.key || {};
    }

    if (!childKey) {
      return;
    }

    return store.getters.getPageByKey(childKey);
  }

  getChildrenForPagesNav() {
    if (this.isMenuPage()) {
      return this.menu_pages.reduce((pages, key) => {
        const page = store.getters.getPageByKey(key);

        if (!isNil(page)) {
          pages.push(page);
        }

        return pages;
      }, []);
    }

    return this.children;
  }

  clearLocalViews() {
    this.views = [];
    this.groups = [];
  }

  updateLayout() {
    return window.Knack.Api.updateViewOrder(this.key, this.getViewsOrder(), this.groups);
  }

  requiresAuthentication() {
    return this.authenticated === true;
  }

  hasRecordDrivenViews() {
    return this.views.find((view) => view.isRecordDriven());
  }

  copy(newPageName) {
    return window.Knack.Api.copyPage(this.key, newPageName);
  }

  getPageRules() {
    return this.get('rules') || [];
  }

  getPageRulesCount() {
    if (!this.canHavePageRules()) {
      return 0;
    }

    return this.getPageRules().length;
  }

  validate(validateAttributes = {}) {
    // Find all the existing page slugs except this page's current page slug.
    // These will be considered invalid page slugs.
    const pageSlugs = store.getters['page/pages']
      .map((page) => page.slug)
      .filter((pageSlug) => pageSlug !== this.slug);

    const validationSchema = Joi.object({
      name: Joi.string().empty('').required(),
      slug: Joi.string().empty('').invalid(pageSlugs).required(),
    });

    const validationOptions = {
      abortEarly: false,
      allowUnknown: true,
    };

    const toValidate = { ...this.attributes, ...validateAttributes };

    const validationResult = Joi.validate(toValidate, validationSchema, validationOptions);

    return {
      error: validationResult.error,
      errorMap: {
        'error.name.any.required': 'Please give your page a name.',
        'error.slug.any.required': 'Please give your page a URL.',
        'error.slug.any.invalid': 'This Page URL is already in use.',
      },
    };
  }

  accountRoleFilters() {
    const filters = [];

    // When the authenticated page is role-specific, we need to filter
    // the user list down to only those roles
    if (this.authentication_profiles.length > 0) {
      const requiredRole = this.authentication_profiles[0];

      const { key: rolesFieldKey } = store.getters.accountObject.getUserRoleField();

      filters.push({
        field: rolesFieldKey,
        operator: 'is',
        value: requiredRole,
      });
    }

    return filters;
  }

  // ensure preview data exists for this page
  async loadPagePreviewData() {
    if (!this.canPreviewRecords()) {
      return false;
    }

    const objectKey = this.getPagePreviewObjectKey();

    // details page, so we're loading in records for the object the page knows about
    if (this.object) {
      await store.dispatch('loadPagePreviewRecords', objectKey);

      return;
    }

    // otherwise it's a login page, so we're loading in the first user role
    const userRole = this.authorizedUserRoles[0];

    await store.dispatch('loadPagePreviewUsers', {
      objectKey, userRole,
    });

    // Does this page have any multiple-record views connecting to the logged-in User?
    const hasUserAuthenticatedViews = this.views.find((view) => {
      if (view.isSingleRecordView() || view.isStatic()) {
        return false;
      }

      if (view.isUserAuthenticated()) {
        return true;
      }

      return false;
    });

    // If so, we need to set the preview user so the Server knows to simulate this record as the logged-in user
    if (hasUserAuthenticatedViews) {
      const previewId = store.getters.pagePreviewId;

      if (previewId) {
        await window.Knack.Api.setPreviewUser(this.key, previewId);
      }
    }
  }

  getPagePreviewObjectKey() {
    // details page
    if (this.object) {
      return this.object;
    }

    // user accounnt
    if (store.getters.accountObject) {
      return store.getters.accountObject.key;
    }

    return null;
  }

  getPagePreviewObject() {
    return store.getters.getObject(this.getPagePreviewObjectKey());
  }

  // Check if pages can choose from multiple records to use with data previews
  canPreviewRecords() {
    if (this.object) {
      return true;
    }

    if (this.isStartPageWithLogin()) {
      // do any views connect with the logged-in user?
      if (this.views.find((view) => view.isUserAuthenticated())) {
        return true;
      }
    }

    return false;
  }

  async loadViews(onlyLoadViewsThatHaveNotBeenLoaded = false) {
    const useLiveData = (store.getters.pagePreviewType === 'live');

    // First, if live data is being used, ensure preview records exist
    if (useLiveData) {
      await this.loadPagePreviewData();
    }

    // Figure out which views to load
    let { views } = this;

    if (onlyLoadViewsThatHaveNotBeenLoaded === true) {
      views = views.filter((view) => view.data.isLoaded === false);
    }

    // Load away!
    await this.loadViewData(views);
  }

  async loadViewData(views = null) {
    const _views = views || this.views || [];

    const loadDataPromises = _views.map((view) => view.loadData());

    await Promise.all(loadDataPromises);
  }

  getView(viewKey) {
    return this.views.find((view) => view.key === viewKey);
  }

  // TODO: extend logic to be compatible w/ v2
  // flatten this.groups for view key
  getViewsOrder() {
    return this.views.map((view) => view.key);
  }

  getViewsCount() {
    return this.views.length;
  }

  getMenuViews() {
    return this.getViewsByType('menu');
  }

  getViewsByType(viewType) {
    log('getViewsByType!', this.views);

    return this.views.filter((view) => view.type === viewType);
  }

  getPageObject() {
    if (!this.object) {
      return false;
    }

    return store.getters.getObject(this.object);
  }

  /**
   * Gets all the different object options that can be view sources including connections
   * and user logins.
   *
   * @param {string} [inflection]
   * @returns {Object[]}
   */
  getViewSourceOptions(inflection = 'plural') {
    return getViewSourceOptions(this.object, this, inflection);
  }

  // View types uses these to determine which types to display
  getConnectionsToMany() {
    const pageObject = this.getPageObject();

    if (!pageObject) {
      return [];
    }

    return pageObject.conns.filter((conn) => conn.has === 'many');
  }

  hasConnectionsToMany() {
    return (this.getConnectionsToMany().length > 0);
  }

  getFurtherConnectionsToMany() {
    const conns = [];

    const pageObject = this.getPageObject();

    // if no object return empty
    if (!pageObject) {
      return conns;
    }

    return pageObject.conns.filter((conn) => {
      const connObject = store.getters.getObject(conn.object);

      return connObject.conns.filter((conn) => conn.has === 'many');
    });
  }

  hasFurtherConnectionsToMany() {
    return (this.getFurtherConnectionsToMany().length > 0);
  }

  hasAnyConnectionsToMany() {
    if (this.hasConnectionsToMany()) {
      return true;
    }

    if (this.hasFurtherConnectionsToMany()) {
      return true;
    }

    return false;
  }

  getUserConnectionsToMany() {
    let conns = [];

    if (!this.authenticated) {
      return conns;
    }

    this.getAccountPlusRoleObjects().forEach((userObject) => {
      conns = conns.concat(userObject.conns.filter((conn) => conn.has === 'many'));
    });

    return conns;
  }

  hasUserConnectionsToMany() {
    if (this.getUserConnectionsToMany().length > 1) {
      return true;
    }

    return false;
  }

  getFurtherUserConnectionsToMany() {
    let conns = [];

    // if not authenticated return empty
    if (!this.authenticated) {
      return conns;
    }

    this.getRoleObjects().forEach((userObject) => {
      userObject.conns.filter((conn) => {
        const connObject = store.getters.getObject(conn.object);

        conns = conns.concat(connObject.conns.filter((conn) => conn.has === 'many'));
      });
    });

    return conns;
  }

  hasFurtherUserConnectionsToMany() {
    return (this.getFurtherConnectionsToMany().length > 1);
  }

  hasAnyUserConnectionsToMany() {
    if (this.hasUserConnectionsToMany()) {
      return true;
    }

    if (this.hasFurtherUserConnectionsToMany()) {
      return true;
    }

    return false;
  }

  canAddEcommView() {
    const objectKeys = this.getViewSourceOptions().map((source) => source.objectKey);

    return [
      ...new Set(objectKeys),
    ].some((objectKey) => {
      const object = store.getters.getObject(objectKey);

      return object.hasEcommerceField();
    });
  }

  canAddPaymentMethodView() {
    const sources = this.getViewSourceOptions();

    return sources.some((source) => {
      // Payment methods only apply to individual records
      if (source.quantity === 'many') {
        return false;
      }

      // Payments can be added to any user object
      const object = store.getters.getObject(source.objectKey);
      return object.isUser();
    });
  }

  canAddMapView() {
    // get object keys for every object that this page can create a view for
    const objectKeys = this.getViewSourceOptions().map((source) => source.objectKey);

    // Use a set to ensure unique values
    return [
      ...new Set(objectKeys),
    ].some((objectKey) => {
      const object = store.getters.getObject(objectKey);

      return object.hasGeocodedAddressField();
    });
  }

  canOptInToMapView() {
    const objectKeys = this.getViewSourceOptions().map((source) => source.objectKey);

    return [
      ...new Set(objectKeys),
    ].some((objectKey) => {
      const object = store.getters.getObject(objectKey);

      return object.hasAddressField();
    });
  }

  canAddCalendarView() {
    // get object keys for every object that this page can create a view for
    const objectKeys = this.getViewSourceOptions().map((source) => source.objectKey);

    // Use a set to ensure unique values
    return [
      ...new Set(objectKeys),
    ].some((objectKey) => {
      const object = store.getters.getObject(objectKey);

      return object.hasDateField();
    });
  }
}

export default Page;
