import Field from '@/store/models/Field';

export default {
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    object: {
      get() {
        let { activeObject } = this.$store.getters;

        if (!this.$route.params.objectKey) {
          return activeObject;
        }

        if (!activeObject || activeObject.key !== this.$route.params.objectKey) {
          activeObject = this.$store.getters.getObject(this.$route.params.objectKey);

          this.$store.commit('activeObject', activeObject);
        }

        return activeObject;
      },
      set(newValue) {
        this.$store.commit('activeObject', newValue);
      },
    },
    objectRaw() {
      return this.object.raw();
    },
    field() {
      if (this.isNew && this.fieldNew) {
        return this.fieldNew;
      }

      let { activeField } = this.$store.getters;

      if (!activeField || activeField.key !== this.$route.params.fieldKey) {
        activeField = this.object.getField(this.$route.params.fieldKey);

        this.$store.commit('activeField', activeField);
      }

      return activeField;
    },
    fieldRaw() {
      if (!this.field) {
        return null;
      }

      return this.field.raw();
    },
  },
  methods: {
    _isConnection(field) {
      return field.type === window.Knack.config.CONNECTION;
    },
    _createConnectionField() {
      this.$router.push(`/schema/list/objects/${this.object.key}/fields/add/connection`);
    },
    fieldMixin_createNewField(field) {
      if (this._isConnection(field)) {
        this._createConnectionField();

        return {};
      }

      const newField = new Field({
        name: field.name || 'New Field',
        type: field.type || 'short_text',
        format: field.format || {},
      }, this.object);

      // since this is an autoname, ensure it's unique (this appends a number)
      newField.ensureUniqueName();

      return newField;
    },
  },
};
