<template>
  <notifications
    class="kn-notifications"
    group="app-notification"
    position="top right"
    :style="inlineStyles"
  >
    <template #body="props">
      <div
        :class="`kn-notification-${props.item.type}`"
        :style="{
          color: textColor,
          backgroundColor: bgColor
        }"
      >
        <a class="title">
          {{ props.item.title }}
        </a>
        <div
          class="kn-notification_content"
          v-html="props.item.text"
        />
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    bgColor: {
      type: String,
      required: true,
    },
    customStyles: {
      type: Object,
      default: () => {},
    },
    textColor: {
      type: String,
      required: true,
    },
    topOffset: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    inlineStyles() {
      return {
        top: this.topOffset ? '58px' : '12px',
        right: '12px',
        ...this.customStyles,
      };
    },
  },
};
</script>

<style lang="scss">
.kn-notification,
[class^="kn-notification-"],
[class*=" kn-notification-"] {
  position: relative;
  overflow: hidden;
  padding: 15px 15px 15px 50px;
  border-radius: 3px;
  box-shadow: 0 0 12px #999;
  opacity: .8;
  font-size: 0.875rem;
}

.kn-notification-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=")!important;
  background-repeat: no-repeat;
  background-position: 15px;
}

.kn-notification_content {
  padding: 1em 0;
}
</style>
