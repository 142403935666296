<template>
  <ViewToolbox
    back-title="Form Menu"
    title="Form Actions & Rules"
  >
    <div>
      <TocLinks :items="items" />
    </div>
  </ViewToolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import TocLinks from '@/components/views/TocLinks';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'FormRules',
  components: {
    ViewToolbox,
    TocLinks,
  },
  mixins: [
    ViewUtils,
  ],
  data() {
    return {
      items: [
        {
          title: 'Submit Actions',
          copy: 'what happens after the form submits',
          link: 'submit',
        },
        {
          title: 'Display Rules',
          copy: 'use logic to show and hide form inputs',
          link: 'display',
        },
        {
          title: 'Record Actions',
          copy: 'insert and update records',
          link: 'record',
        },
      ],
    };
  },
  created() {
    // child forms don't need submit rules, unelss they are registration forms
    if (!this.view.isRegistrationForm() && this.viewRaw.parent) {
      this.items.shift();
    }
  },
};
</script>
