import Builder from '@/components/Builder';
import Records from '@/components/records/Records';
import RecordsIntro from '@/components/records/RecordsIntro';
import ObjectsNav from '@/components/records/ObjectsNav';
import RecordsTable from '@/components/records/RecordsTable';
import RecordAdd from '@/components/records/RecordAdd';
import RecordEdit from '@/components/records/RecordEdit';
import RecordHistory from '@/components/records/RecordHistory';
import Import from '@/components/records/import/Import';
import RecordBatch from '@/components/records/RecordBatch';
import RecordClear from '@/components/records/RecordClear';
import { getSharedObjectsPaths } from '@/router/shared/SharedObjectsPaths';
import { getSharedObjectPaths } from '@/router/shared/SharedObjectPaths';
import SharedFieldPaths from '@/router/shared/SharedFieldPaths';

export default [
  {
    path: '/records',
    component: Builder,
    children: [
      {
        path: '',
        component: Records,
        children: [
          {
            path: '',
            components: {
              toolbox: ObjectsNav,
              body: RecordsIntro,
            },
          },

          // add object paths
          ...getSharedObjectsPaths(RecordsIntro),

          {
            path: 'objects/:objectKey',
            components: {
              toolbox: ObjectsNav,
              body: RecordsTable,
            },
            props: {
              body: true,
            },
            children: [

              // object settings
              ...getSharedObjectPaths(),

              {
                path: 'fields',
                redirect: (to) => `/records/objects/${to.params.objectKey}`,
              },

              // edit and delete field paths
              ...SharedFieldPaths,
            ],
          },
          {
            path: 'objects/:objectKey/import',
            components: {
              toolbox: ObjectsNav,
              body: RecordsTable,
              modal: Import,
            },
          },
          {
            path: 'objects/:objectKey/batch/update',
            components: {
              toolbox: RecordBatch,
              body: RecordsTable,
            },
            meta: {
              addQueries: true,
            },
          },
          {
            path: 'objects/:objectKey/batch/delete',
            components: {
              toolbox: RecordBatch,
              body: RecordsTable,
            },
            props: {
              toolbox: {
                action: 'delete',
              },
            },
            meta: {
              addQueries: true,
            },
          },
          {
            path: 'objects/:objectKey/add',
            components: {
              toolbox: ObjectsNav,
              body: RecordsTable,
              modal: RecordAdd,
            },
            meta: {
              addQueries: true,
            },
          },
          {
            path: 'objects/:objectKey/clear',
            components: {
              toolbox: ObjectsNav,
              body: RecordsTable,
              modal: RecordClear,
            },
            meta: {
              addQueries: true,
            },
          },
          {
            path: 'objects/:objectKey/record/:recordID/edit',
            components: {
              toolbox: ObjectsNav,
              body: RecordsTable,
              modal: RecordEdit,
            },
            meta: {
              addQueries: true,
            },
          },
          {
            path: 'objects/:objectKey/record/:recordID/history',
            components: {
              toolbox: ObjectsNav,
              body: RecordsTable,
              modal: RecordHistory,
            },
            meta: {
              addQueries: true,
            },
          },
        ],
      },
    ],
  },
];
