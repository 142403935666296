<template>
  <div v-if="wizardStep && page">
    <WizardStep
      v-if="showWizardStep('menu')"
      v-model:wizard-step="wizardStep"
      :step="getWizardStep('menu')"
    >
      <template #edit>
        <div :class="wizardEditTextClasses" v-html="menuSummary" />
      </template>
      <template #intro>
        <div class="mb-2">
          Select Menu Pages
        </div>
      </template>
      <PageDropDownMenuSelector
        :page="page"
      />
      <div
        class="flex justify-end"
        style="margin-top: 1.25em;"
      >
        <a
          class="button orange-fill small p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium"
          @click="onSubmitMenu()"
        >
          Continue
        </a>
      </div>
    </WizardStep>

    <WizardStep
      v-if="showWizardStep('option')"
      v-model:wizard-step="wizardStep"
      :step="getWizardStep('option')"
    >
      <template #edit>
        <div :class="wizardEditTextClasses">Add a <b>{{ pageType }}</b> page</div>
      </template>

      <template #intro>
        <div class="mb-2">
          Who can access this page?
        </div>
      </template>

      <PageAddOption
        :page="pageType"
        @submit="onSubmitOption"
      />
    </WizardStep>

    <WizardStep
      v-if="showWizardStep('user')"
      v-model:wizard-step="wizardStep"
      :step="getWizardStep('user')"
    >
      <template #edit>
        <div :class="wizardEditTextClasses" v-html="userEditCopy" />
      </template>

      <template #intro>
        <div class="mb-2 leading-4">
          Which logged-in users can access this page?
        </div>
      </template>

      <PageAddUsers
        :page="page"
        @submit="onSubmitUsers"
      />
    </WizardStep>

    <WizardStep
      v-if="showWizardStep('source')"
      v-model:wizard-step="wizardStep"
      :step="getWizardStep('source')"
    >
      <template #edit>
        <div :class="wizardEditTextClasses">
          working with <b>{{ sourceObjectSingularInflection }}</b> records
        </div>
      </template>

      <template #intro>
        <div class="mb-2">
          Which <b>records</b> will this page work with?
        </div>
      </template>
      <PageAddSource
        :page="page"
        :options="options"
        @submit="onSubmitSource"
      />
    </WizardStep>

    <WizardStep
      v-if="options.source.object && showWizardStep('views')"
      v-model:wizard-step="wizardStep"
      :step="getWizardStep('views')"
    >
      <template #edit>
        <div :class="wizardEditTextClasses" v-html="viewsSummary" />
      </template>

      <template #intro>
        <div class="mb-2">
          Which <b>views</b> will this page display? <HelpIcon copy="You can edit these and add more views later." />
        </div>
      </template>
      <PageAddViews
        :page="page"
        :options="options"
        @submit="onSubmitViews"
      />
    </WizardStep>

    <WizardStep
      id="add-page-name"
      v-model:wizard-step="wizardStep"
      :step="getWizardStep('name')"
    >
      <template #intro>
        <label class="leading-4 tw-toolbox-label mb-2">
          {{ isMenuPage ? 'Menu' : 'Page' }} Name
        </label>
      </template>
      <FormWrapper
        @submit="onAddPage"
      >
        <div class="margin-bottom-double mb-6">
          <div>
            <input
              v-model="page.name"
              v-focus
              type="text"
              data-cy="page-name"
            >
          </div>
        </div>
        <p v-if="showUserEnableMessage">
          Adding this login view will enable users and add new <b>Accounts</b> object to your app.
        </p>
        <div
          class="flex justify-end mb-0 pb-0"
          style="padding-bottom: .5em;"
        >
          <BuilderButton
            theme="confirm"
            class="button green-fill text-base text-white rounded-lg p-3 border-0 leading-4 h-auto m-0"
            :disabled="!canAddPage"
            data-cy="submit-add-page"
            type="submit"
          >
            Add Page
          </BuilderButton>
        </div>
      </FormWrapper>
    </WizardStep>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import WizardStep from '@/components/ui/WizardSection';
import PageAddOption from '@/components/pages/PageAddOption';
import PageAddUsers from '@/components/pages/PageAddUsers';
import PageAddSource from '@/components/pages/PageAddSource';
import PageAddViews from '@/components/pages/PageAddViews';
import PageAddUtils from '@/components/pages/PageAddUtils';
import FormWrapper from '@/components/ui/FormWrapper';
import HelpIcon from '@/components/ui/HelpIcon';
import PageDropDownMenuSelector from '@/components/pages/PageDropDownMenuSelector';
import BuilderButton from '@/components/ui/BuilderButton';
import { parseHierarchyRecursive } from '@/lib/page/page-helper';
import { NEW_PAGE_KEY } from '@/lib/page/page-constants';

export default {
  name: 'PageAdd',
  components: {
    WizardStep,
    PageAddOption,
    PageAddUsers,
    PageAddSource,
    PageAddViews,
    HelpIcon,
    PageDropDownMenuSelector,
    FormWrapper,
    BuilderButton,
  },
  mixins: [
    PageAddUtils,
  ],
  props: {
    addType: {
      type: String,
      default: 'public',
    },
    parentPage: { // parent pages come from adding new child pages from menus/forms/other views
      type: Object,
      default: null,
    },
    pageVars: {
      type: Object,
      default: () => {},
    },
    isSinglePage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['addPage', 'close'],
  data() {
    const authenticationSteps = [
      'name',
    ];

    if (this.$store.getters.roleObjects.length) {
      authenticationSteps.unshift('user');
    }

    return {
      wizardStep: 1,
      wizardStepMax: 1,
      steps: {
        menu: [
          'menu',
          'name',
        ],
        public: [
          'name',
        ],
        login: authenticationSteps,
        user: authenticationSteps,
        option: [
          'option',
          'name',
        ],
      },

      // options track source variables for proper view selection
      options: {
        recordQuantity: 'many',
        source: {},
        views: [],
        name: '',
        inflections: {},
      },

      /**
         * @type {Page | null} page
         */
      page: null,
      pageType: (this.addType === 'option') ? 'public' : this.addType,
      destroyNewPage: true,
    };
  },
  computed: {
    ...mapGetters([
      'activePage',
      'isUsersEnabled',
      'roleObjects',
      'getObject',
      'initPageByKey',
      'getPageByKey'
    ]),
    showUserEnableMessage() {
      return !this.isUsersEnabled && this.addType === 'login';
    },
    showPagePreview() {
      return (!this.parentPage);
    },
    sourceObject() {
      return this.getObject(this.options.source.object);
    },
    sourceObjectSingularInflection() {
      if (!this.options.source || !this.options.source.object) {
        return '';
      }

      return this.sourceObject.inflections.singular;
    },
    userEditCopy() {
      if (!this.page) {
        return;
      }

      if (this.page.authenticated && this.addType !== 'option') {
        const intro = (this.isUserPage) ? 'Add a user page' : 'Add a login page';

        if (hasIn(this.page, 'allowed_profiles') && this.page.allowed_profiles.length) {
          const roles = [];

          this.page.allowed_profiles.forEach((profile, index) => {
            const obj = this.$store.getters.getObjectByRole(profile);

            roles.push(`<b>${obj.inflections.plural}</b>`);
          });

          if (roles.length === 1) {
            return `${intro} limited to ${roles[0]}`;
          }

          const lastRole = roles.pop();

          return `${intro} limited to ${roles.join(', ')} & ${lastRole}`;
        }

        return `${intro} accessible to <b>all users</b>`;
      }

      return 'Add a new page available to everyone';
    },
    isUserPage() {
      return Boolean(this.page && this.page.isUserPage());
    },
    isMenuPage() {
      return Boolean(this.page && this.page.isMenuPage());
    },
    backLink() {
      log('backLink', this.$route.params);

      if (this.$route.params.pageKey && !this.$route.params.viewKey) {
        return `/pages/${this.$route.params.pageKey}`;
      }

      return false;
    },
    pageWithUsers() {
      return this.setPageSchemaFromWizardOptions(this.options);
    },
    viewsSummary() {
      if (!this.options.views.length) {
        return '';
      }

      let summary = `displaying a <b>${this.options.views[0].type}</b>`;

      if (this.options.views.length > 1) {
        summary += ` and <b>${this.options.views[1].type}</b>`;
      }

      summary += ' view';

      return summary;
    },
    menuSummary() {
      const pageKey = get(this, 'page.key');

      if (!pageKey) {
        return '';
      }

      const page = this.getPageByKey(pageKey);

      if (!page) {
        return '';
      }

      const menuChildren = page.menuChildren || [];

      if (menuChildren.length === 1) {
        const menuPageName = parseHierarchyRecursive(
          menuChildren[0],
        );

        return `Containing the <b>${menuPageName}</b> page`;
      }

      return 'Containing multiple pages';
    },
    canAddPage() {
      return !!this.page.name;
    },
    wizardEditTextClasses() {
      return 'mb-4 text-base font-semibold tracking-[.32px]';
    },
  },
  async created() {
    const { dispatch } = this.$store;

    // let's create our page
    const pageVars = {
      name: 'New Page',
      views: [],
      ...(this.pageVars || {}),
    };

    switch (this.addType) {
      case 'login':
        pageVars.type = 'authentication';
        break;

      case 'user':
        pageVars.type = 'user';
        break;

      case 'menu':
        pageVars.type = 'menu';
        pageVars.name = 'New Menu';
        break;

      default:
        // Intentionally left blank
        break;
    }

    // Apps set up with a global login are automatically authenticated and assigned a parent page.
    const globalLoginPage = this.$store.getters['page/globalLoginPage'];
    const useGlobalLogin = (this.addType !== 'user' && globalLoginPage);

    if (useGlobalLogin) {
      pageVars.parent = globalLoginPage.slug;
    }

    if (this.parentPage) {
      pageVars.parent = this.parentPage.slug;

      // any child pages inherit the object of their parent
      if (this.parentPage.object && !pageVars.object) {
        pageVars.object = this.parentPage.object;
      }
    }

    if (this.getPageByKey(NEW_PAGE_KEY)) {
      // If a new page exists in the cache, then go ahead and delete it.
      await dispatch('removePage', NEW_PAGE_KEY);
    }

    pageVars.key = NEW_PAGE_KEY;

    // By adding the page to the pageCache we get all the processing the occurs there.
    // We probably don't need all of it, but it is safest to have it all in one place.
    const rawPage = await dispatch('addPage', pageVars);

    if (useGlobalLogin) {
      // Reset the authentication parameters for global login pages.
      // This prevents a new login page parent from being created.
      rawPage.authenticated = false;
      rawPage.authentication_profiles = [];
      rawPage.updatePageData({
        allowed_profiles: [],
      });
    }

    if (this.showPagePreview) {
      await dispatch('setActivePageByKey', {
        pageKey: pageVars.key,
      });

      this.page = this.activePage;
    } else {
      this.page = this.initPageByKey(pageVars.key);
    }
  },
  async unmounted() {
    if (!this.destroyNewPage) {
      return;
    }

    // Remove the new page from the page cache if we didn't save it.
    await this.$store.dispatch('removePage', NEW_PAGE_KEY);
  },
  methods: {
    getWizardStep(whichStep) {
      return this.steps[this.addType].indexOf(whichStep) + 1;
    },
    showWizardStep(whichStep) {
      return (this.steps[this.addType].indexOf(whichStep) > -1 && this.getWizardStep(whichStep) <= this.wizardStepMax);
    },
    nextWizardStep() {
      this.wizardStep++;

      if (this.wizardStep > this.wizardStepMax) {
        this.wizardStepMax = this.wizardStep;
      }
    },
    onClose() {
      this.$emit('close');
    },
    onSubmitMenu() {
      this.nextWizardStep();
    },
    onSubmitOption(pageType) {
      log('onSubmitOption:', pageType);

      this.pageType = pageType;

      if (this.pageType === 'login') {
        this.page.updatePageSchema({
          type: 'authentication',
        });
      }

      // update the steps based on page type
      this.steps.option = [
        'option',
      ].concat(this.steps[pageType]);

      log('this.steps now', this.steps);

      this.nextWizardStep();
    },
    onSubmitUsers(vars) {
      this.nextWizardStep();
    },
    onSubmitSource(source) {
      log('onSubmitSource()', source);

      this.options.recordQuantity = source.quantity;
      this.options.source = source.source;

      this.nextWizardStep();
    },
    onSubmitViews(views) {
      log('onSubmitViews()', views);

      this.options.views = views;
      this.nextWizardStep();

      this.$nextTick(() => {
        this.$refs.name.focus();
      });
    },
    onAddPage() {
      if (!this.canAddPage) {
        return;
      }

      this.destroyNewPage = false;

      this.$emit('addPage', this.page);
    },
  },
};
</script>

<style lang="scss">
.toolbox-body .wizard-section:first-child.active {
  margin-top: 1em;
  padding-top: 0;
  border: 0;
}
</style>
