<template>
  <FormWrapper
    :errors="errors"
    class="center"
  >
    <p class="text-base font-normal mb-6">
      Update the template used to email <strong>{{ objectRaw.inflections.plural }}</strong> their account info.
    </p>
    <p class="text-sm font-semibold leading-4 tracking-[0.14px] mb-2">
      Message
    </p>
    <RichText
      v-if="isLoaded"
      v-model="infoTemplate"
      :has-fields-list="true"
      :field-list-row="2"
      :object="object"
    />
    <div class="save center-align flex justify-end pt-">
      <a
        class="button huge save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium"
        @click="onSubmit"
      >
        Save
      </a>
    </div>
  </FormWrapper>
</template>

<script>
import isNil from 'lodash/isNil';
import hasIn from 'lodash/hasIn';
import RichText from '@/components/renderer/form/inputs/RichText';
import FormWrapper from '@/components/ui/FormWrapper';
import RequestUtils from '@/components/util/RequestUtils';
import FieldUtils from '@/components/fields/FieldUtils';

export default {
  components: {
    RichText,
    FormWrapper,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  props: {
    backLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: true,
    };
  },
  computed: {
    app() {
      return this.$store.state.app;
    },
    infoTemplate: {
      get() {
        if (isNil(this.app.users.templates) || isNil(this.app.users.templates[this.objectRaw.profile_key])) {
          return {};
        }

        return this.app.users.templates[this.objectRaw.profile_key].intros;
      },
      set(value) {
        this.app.users.templates[this.objectRaw.profile_key].intros = value;
      },
    },
  },
  created() {
    if (!hasIn(this.app.users.templates, `${this.object.profile_key}.intros`)) {
      this.isLoaded = false;

      this.commitRequest({
        request: () => this.object.getAccountInfoTemplate(),
        onSuccess: () => {
          this.isLoaded = true;
        },
      });
    } else {
      this.isLoaded = true;
    }
  },
  methods: {
    onSubmit() {
      Object.assign(this.object.attributes, this.localObject);

      this.commitRequest({
        request: () => this.object.updateAccountTemplate('intros'),
        onSuccess: () => this.$router.push(this.backLink),
      });
    },
  },
};
</script>
