<template>
  <button
    :class="buttonClasses"
    role="toggle"
    @click="onClick"
    @mouseenter="setHover(true)"
    @mouseleave="setHover(false)"
  >
    <Icon
      role="icon"
      class="iconButton_icon"
      :class="iconClasses"
      :type="iconType"
    />
  </button>
</template>

<script>

import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    iconTypePrimary: {
      type: String,
      required: true,
    },
    iconTypeSecondary: {
      type: String,
      default: '',
    },
    emitEvents: {
      type: Boolean,
      default: true,
    },
    iconClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  data() {
    return {
      hover: false,
      active: false,
    };
  },
  computed: {
    buttonClasses() {
      const classes = ['iconButton'];

      if (this.useActiveStyling) {
        classes.push({ active: this.active });
      }

      return classes;
    },
    iconType() {
      if (this.iconTypeSecondary && this.hover) {
        return this.iconTypeSecondary;
      }

      return this.iconTypePrimary;
    },
    useActiveStyling() {
      return !this.iconTypeSecondary;
    },
  },
  methods: {
    onClick() {
      if (this.useActiveStyling) {
        this.active = !this.active;
      }

      if (this.emitEvents) {
        this.$emit('click');
      }
    },
    setHover(isHover) {
      this.hover = isHover;
    },
  },
};
</script>

<style lang="scss" scoped>
.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: unset;
  border-radius: 4px;
  background: $white;

  &:hover {
    background: $gray100;
  }

  &_icon {
    height: 18px;
    width: 18px;
    color: $gray700;
  }

  &.active {
    background: $gray700;
  }

  &.active &_icon {
    color: $gray100 !important;
  }
}
</style>
