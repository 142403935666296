<template>
  <Modal
    title="Add Credentials"
    size="medium"
    @close="handleCloseModal"
  >
    <p class="center">
      Add credentials from a Single Sign-On provider of your choice:
    </p>

    <form class="custom-sso-options">
      <div class="mb-4 pb-0">
        <label class="text-default text-sm font-medium mb-2 leading-4">Provider Type</label>
        <div class="pb-0">
          <select
            v-model="providerSelection"
            class="text-base py-2 pl-3 leading-5 ml-0"
            data-cy="sso-provider-type"
          >
            <option
              value=""
              disabled
            >
              Select...
            </option>
            <option value="sso_google">
              Google
            </option>

            <IfPlan
              :level-is-minimum-of="2"
              :allow-if-sso="true"
            >
              <option value="oauth1">
                OAuth 1.0a
              </option>
            </IfPlan>
            <IfPlan
              :level-is-minimum-of="2"
              :allow-if-sso="true"
            >
              <option value="oauth2">
                OAuth 2
              </option>
            </IfPlan>
            <IfPlan
              :level-is-minimum-of="2"
              :allow-if-sso="true"
            >
              <option value="saml">
                SAML 1.1 or 2.0
              </option>
            </IfPlan>
          </select>
        </div>
      </div>
      <!-- static -->
      <div
        v-if="providerSelection === 'sso_google'"
        class="sso-options mb-0 pb-2"
      >
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Client ID</label>
          <input
            v-model="sso_google.clientId"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Client Secret</label>
          <input
            v-model="sso_google.clientSecret"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Restrict by Domain</label>
          <input
            v-model="sso_google.domainRestriction"
            type="text"
          >
        </div>
      </div>
      <!-- custom -->
      <div
        v-if="providerSelection && isCustomProvider"
        class="login-section bottom-divider border-subtle pt-2"
      >
        <h3 class="text-base pb-0 mb-4 font-semibold text-default leading-4 tracking-[0.32px]">
          Login Button
        </h3>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Provider Name <HelpIcon
            copy="Name as it should appear on button. Cannot contain spaces."
            text="Text"
            class="pb-0"
          /></label>
          <input
            v-model="name"
            type="text"
          >
          <p
            v-if="getFormError(`name`)"
            class="error-msg"
          >
            {{ getFormError(`name`) }}
          </p>
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Button Color</label>
          <input
            v-model="buttonColor"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Button Font Color</label>
          <input
            v-model="fontColor"
            type="text"
          >
        </div>
        <div class="pb-0">
          <label class="text-default text-sm font-medium mb-2 leading-4">Button Icon</label>
          <ImageInput
            v-model="logo"
            @update:modelValue="onUpdateImage"
          />
        </div>
      </div>
      <div
        v-if="providerSelection === 'oauth1'"
        class="provider-section pb-0 mb-2"
      >
        <h3 class="text-base pb-0 mb-4 font-semibold text-default leading-4 tracking-[0.32px]">
          Provider Settings
        </h3>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Request URL</label>
          <input
            v-model="oauth1.requestTokenUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Access URL</label>
          <input
            v-model="oauth1.accessTokenUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">User Authorization URL</label>
          <input
            v-model="oauth1.userAuthorizationUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Consumer Key</label>
          <input
            v-model="oauth1.consumerKey"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Consumer Secret</label>
          <input
            v-model="oauth1.consumerSecret"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Profile URL</label>
          <input
            v-model="oauth1.profileUrl"
            type="text"
          >
        </div>
      </div>
      <div
        v-if="providerSelection === 'oauth2'"
        class="provider-section pb-0 mb-2"
      >
        <h3 class="text-base pb-0 mb-4 font-semibold text-default leading-4 tracking-[0.32px]">
          Provider Settings
        </h3>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Authorization URL</label>
          <input
            v-model="oauth2.authorizationUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Access Token URL</label>
          <input
            v-model="oauth2.tokenUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Client ID</label>
          <input
            v-model="oauth2.clientId"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Client Secret</label>
          <input
            v-model="oauth2.clientSecret"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Profile URL</label>
          <input
            v-model="oauth2.profileUrl"
            type="text"
          >
        </div>
      </div>
      <div
        v-if="providerSelection === 'saml'"
        class="provider-section pb-0 mb-2"
      >
        <h3 class="text-base pb-0 mb-4 font-semibold text-default leading-4 tracking-[0.32px]">Provider Settings</h3>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Provider Entry Point <HelpIcon
            copy="Identity Provider entry point, where the SAML authentication request will be sent."
            text="Text"
            class="pb-0"
          /></label>
          <input
            v-model="saml.entryPoint"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Issuer <HelpIcon
            copy="Name of this Service Provider to supply to the Identity Provider."
            text="Text"
            class="pb-0"
          /></label>
          <input
            v-model="saml.issuer"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Identity Provider's certificate <HelpIcon
            copy="Optional Identity Provider certificate, used for validating responses from the Identity Provider."
            text="Text"
            clas="pb-0"
          /></label>
          <textarea
            v-model="saml.cert"
            name=""
          />
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Private signing certificate <HelpIcon
            copy="Optional private signing certificate, used to sign requests to the Identity Provider."
            text="Text"
            class="pb-0"
          /></label>
          <textarea
            v-model="saml.privateCert"
            name=""
          />
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Decryption private certificate <HelpIcon
            copy="Optional private key that will be used to attempt to decrypt any encrypted assertions that are received from the Identity Provider."
            text="Text"
            class="pb-0"
          /></label>
          <textarea
            v-model="saml.decryptionPrivateKey"
            name=""
          />
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Decryption public certificate <HelpIcon
            copy="Public certificate matching the decryption private certificate. Required if configured with a decryption private certificate."
            text="Text"
            class="pb-0"
          /></label>
          <textarea
            v-model="saml.decryptionPublicKey"
            name=""
          />
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Logout URL: <HelpIcon
            copy="Optional URL to send a sign out request to when a user ends their Knack session."
            text="Text"
            class="pb-0"
          /></label>
          <input
            v-model="saml.logoutUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Authentication Context <HelpIcon
            copy="Optional Authentication Context class to present to the Identity Provider."
            text="Text"
            class="pb-0"
          /></label>
          <select
            id="sso-custom-saml-option-authentication-context"
            v-model="saml.authenticationContext"
            class="text-base py-2 pl-3 leading-5 ml-0"
            name="sso-custom-saml-option-authentication-context"
          >
            <option
              value=""
            >
              None
            </option>
            <option
              value="urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport"
              selected=""
            >
              urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport
            </option>
            <option value="urn:oasis:names:tc:SAML:2.0:ac:classes:Password">
              urn:oasis:names:tc:SAML:2.0:ac:classes:Password
            </option>
            <option value="urn:oasis:names:tc:SAML:2.0:ac:classes:TLSClient">
              urn:oasis:names:tc:SAML:2.0:ac:classes:TLSClient
            </option>
            <option value="urn:oasis:names:tc:SAML:2.0:ac:classes:X509">
              urn:oasis:names:tc:SAML:2.0:ac:classes:X509
            </option>
            <option value="urn:federation:authentication:windows">
              urn:federation:authentication:windows
            </option>
            <option value="urn:oasis:names:tc:SAML:2.0:ac:classes:Kerberos">
              urn:oasis:names:tc:SAML:2.0:ac:classes:Kerberos
            </option>
            <option value="urn:oasis:names:tc:SAML:2.0:ac:classes:unspecified">
              urn:oasis:names:tc:SAML:2.0:ac:classes:unspecified
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="providerSelection && isCustomProvider"
        class="pb-0"
      >
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">ID Property</label>
          <input
            v-model="idProperty"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">First Name Property*</label>
          <input
            v-model="firstnameProperty"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Last Name Property*</label>
          <input
            v-model="lastnameProperty"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Email Address Property*</label>
          <input
            v-model="emailaddressProperty"
            type="text"
          >
        </div>
        <p class="instructions mb-0 text-default not-italic text-base">
          *Note: If these fields are left blank, your users will need to enter them manually the first time they log in.
        </p>
      </div>
    </form>

    <ValidationError
      v-if="formErrors.length"
      :errors="formErrors"
    />

    <div
      v-if="providerSelection"
      class="sso-actions flex justify-end gap-4"
    >
      <a
        class="button save margin-right-xs p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium order-2 h-10 mr-0"
        @click="onClickSave"
      >
        Save
      </a>
      <a
        v-tippy="{ allowHTML: true }"
        content="Test your SSO settings to ensure<br />your configuration is correct"
        class="button fuchsia-fill p-3 rounded-lg bg-white border border-solid border-default text-emphasis font-medium m-0 leading-4 order-1 h-10"
        @click="testSSO"
      >
        Test
      </a>
    </div>
  </Modal>
</template>

<script>
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { mapGetters } from 'vuex';

import HelpIcon from '@/components/ui/HelpIcon';
import IfPlan from '@/components/util/IfPlan';
import ImageInput from '@/components/builder/inputs/Image';
import Modal from '@/components/ui/Modal';
import RequestUtils from '@/components/util/RequestUtils';
import ValidationError from '@/components/ui/ValidationError';
import {
  getGoogleValidation,
  getOauth1Validation,
  getOauth2Validation,
  getSamlValidation,
  validateSSOProviderName,
} from '@/lib/sso-helper';
import { validate } from '@/lib/validation-helper';

export default {
  name: 'SSOAdd',
  components: {
    HelpIcon,
    IfPlan,
    ImageInput,
    Modal,
    ValidationError,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      providerSelection: '',
      name: '',
      buttonColor: '#4d4d4d',
      fontColor: '#ffffff',
      logo: '',
      idProperty: '', // saml default: eduPersonTargetedID
      firstnameProperty: '', // saml default: givenName
      lastnameProperty: '', // saml default: sn
      emailaddressProperty: '', // saml default: mail
      sso_google: {
        clientId: '',
        clientSecret: '',
        domainRestriction: '',
      },
      oauth1: {
        accessTokenUrl: '',
        consumerKey: '',
        consumerSecret: '',
        enabled: false,
        profileUrl: '',
        requestTokenUrl: '',
        type: 'oauth1',
        userAuthorizationUrl: '',
      },
      oauth2: {
        authorizationUrl: '',
        clientId: '',
        clientSecret: '',
        enabled: false,
        profileUrl: '',
        tokenUrl: '',
        type: 'oauth2',
      },
      saml: {
        authenticationContext: '',
        cert: '',
        decryptionPrivateKey: '',
        decryptionPublicKey: '',
        enabled: false,
        entryPoint: '',
        issuer: '',
        logoutUrl: '',
        privateCert: '',
        type: 'saml',
      },
      formErrors: {
        type: Array,
        default: () => [],
      },
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    appSettings() {
      return this.$store.state.app.get('settings');
    },
    isCustomProvider() {
      return ([
        'oauth1',
        'oauth2',
        'saml',
      ].indexOf(this.providerSelection) > -1);
    },
  },
  methods: {
    handleCloseModal() {
      this.$router.push(`/pages/${this.$route.params.pageKey}/views/${this.$route.params.viewKey}/login/settings`);
    },
    onUpdateImage(file) {
      if (typeof file === 'string') {
        return;
      }

      this.commitRequest({
        request: () => window.Knack.Api.uploadWebFileForLogo(file.asset, this.name),
        onSuccess: (response) => this.logo = response.logo,
      });
    },
    async onClickSave() {
      if (!this.isCustomProvider) {
        return this.saveStaticSSO();
      }

      return this.saveCustomSSO();
    },
    getFormError(fieldName) {
      if (isEmpty(this.formErrors)) {
        return undefined;
      }

      const foundError = find(this.formErrors, (formError) => formError.flatPath === fieldName);

      return (foundError) ? foundError.message : undefined;
    },
    async saveCustomSSO() {
      const { errors: nameErrors } = validateSSOProviderName({
        name: this.name,
      });

      if (this.appSettings.sso && Object.keys(this.appSettings.sso).includes(this.name)) {
        nameErrors.push(
          {
            message: 'Name is already in use.',
            flatPath: 'name',
          },
        );
      }

      this.formErrors = nameErrors;

      if (!isEmpty(this.formErrors)) {
        return;
      }

      let validation;
      const update = {
        settings: {
          sso: {},
        },
      };

      switch (this.providerSelection) {
        case 'oauth1':
          update.settings.sso[this.name] = {
            name: this.name,
            button_color: this.buttonColor,
            font_color: this.fontColor,
            logo: this.logo,
            access_token_url: this.oauth1.accessTokenUrl,
            consumer_key: this.oauth1.consumerKey,
            consumer_secret: this.oauth1.consumerSecret,
            emailaddress_property: this.emailaddressProperty,
            enabled: true,
            firstname_property: this.firstnameProperty,
            id_property: this.idProperty,
            lastname_property: this.lastnameProperty,
            profile_url: this.oauth1.profileUrl,
            request_token_url: this.oauth1.requestTokenUrl,
            type: 'oauth1',
            user_authorization_url: this.oauth1.userAuthorizationUrl,
          };
          validation = getOauth1Validation();
          break;

        case 'oauth2':
          update.settings.sso[this.name] = {
            name: this.name,
            button_color: this.buttonColor,
            font_color: this.fontColor,
            logo: this.logo,
            authorization_url: this.oauth2.authorizationUrl,
            client_id: this.oauth2.clientId,
            client_secret: this.oauth2.clientSecret,
            emailaddress_property: this.emailaddressProperty,
            enabled: true,
            firstname_property: this.firstnameProperty,
            id_property: this.idProperty,
            lastname_property: this.lastnameProperty,
            profile_url: this.oauth2.profileUrl,
            token_url: this.oauth2.tokenUrl,
            type: 'oauth2',
          };
          validation = getOauth2Validation();
          break;

        case 'saml':
          update.settings.sso[this.name] = {
            name: this.name,
            button_color: this.buttonColor,
            font_color: this.fontColor,
            logo: this.logo,
            authentication_context: this.saml.authenticationContext,
            cert: this.saml.cert,
            decryption_private_key: this.saml.decryptionPrivateKey,
            decryption_public_key: this.saml.decryptionPublicKey,
            emailaddress_property: this.emailaddressProperty,
            enabled: true,
            entry_point: this.saml.entryPoint,
            firstname_property: this.firstnameProperty,
            id_property: this.idProperty,
            issuer: this.saml.issuer,
            lastname_property: this.lastnameProperty,
            logouturl: this.saml.logoutUrl,
            private_cert: this.saml.privateCert,
            type: 'saml',
          };
          validation = getSamlValidation();
          break;
      }

      const { errors: validationErrors } = validate(
        update.settings.sso[this.name],
        validation.validationSchema,
        validation.errorMap,
      );

      this.formErrors = validationErrors;

      if (!isEmpty(this.formErrors)) {
        return;
      }

      const updateMerged = {
        settings: {
          sso: { ..._.cloneDeep(this.appSettings.sso), ..._.cloneDeep(update.settings.sso) },
        },
      };
      this.appSettings.sso = _.cloneDeep(updateMerged.settings.sso);

      const data = {
        [`sso_custom_${this.name}`]: true,
      };

      await this.saveUpdateView(data);

      const response = await window.Knack.Api.updateApplication(updateMerged);

      this.handleCloseModal();

      return response;
    },
    async saveStaticSSO() {
      let validation;
      const update = {
        settings: {},
      };

      let viewSSOSetting;

      switch (this.providerSelection) {
        case 'sso_google':
          update.settings.sso_google = {
            client_id: this.sso_google.clientId,
            client_secret: this.sso_google.clientSecret,
            domain_restriction: this.sso_google.domainRestriction,
          };

          validation = getGoogleValidation();
          viewSSOSetting = 'sso_google';
          break;
      }

      const { errors: validationErrors } = validate(
        update.settings[this.providerSelection],
        validation.validationSchema,
        validation.errorMap,
      );

      this.formErrors = validationErrors;

      if (!isEmpty(this.formErrors)) {
        return;
      }

      update.settings = _.extend(update.settings, this.appSettings);

      // app.settings has nested properties, so we need to use $set to make it reactive
      this.appSettings[this.providerSelection] = update.settings[this.providerSelection];

      const data = {
        [viewSSOSetting]: true,
      };

      // update is just a settings object with an SSO property at this point
      // we need to combine it with the app's settings before proceeding, otherwise
      // we could lose things like other SSO providers or other required settings
      // properties throughout the app
      const mergedUpdate = merge(this.app.settings, update.settings);

      // A full save involves updating the view and then the app settings.
      await this.saveUpdateView(data);

      const response = await this.saveAppSettings(mergedUpdate);

      this.handleCloseModal();

      return response;
    },
    saveUpdateView(data) {
      for (const key of Object.keys(data)) {
        this.$store.state.activeView.attributes[key] = data[key];
      }

      return new Promise((resolve, reject) => {
        this.commitRequest({
          request: () => window.Knack.Api.updateView(
            this.$store.getters.activePage.key,
            this.$store.getters.activeView.key,
            this.$store.state.activeView.attributes,
          ),
          onSuccess: (response) => {
            // This saved all pending updates to the view object, so make sure we turn off the
            // active updates flag.
            this.$store.commit('setViewHasActiveUpdates', false);

            resolve(response);
          },
          onError: (saveError) => {
            reject(saveError);
          },
        });
      });
    },
    saveAppSettings(update) {
      return new Promise((resolve, reject) => {
        this.commitRequest({
          request: () => this.app.update(update),
          globalLoading: false,
          onSuccess: (response) => {
            resolve(response);
          },
          onError: (saveError) => {
            reject(saveError);
          },
        });
      });
    },
    doRedirect(event) {
      const staticProviderMap = {
        sso_google: 'sso_google',
      };
      const strategyName = this.isCustomProvider ? this.name : staticProviderMap[this.providerSelection];

      localStorage.setItem('sso-test-strategy-name', strategyName);
      localStorage.setItem('sso-test-page-key', this.$route.params.pageKey);
      localStorage.setItem('sso-test-view-key', this.$route.params.viewKey);

      const returnUrl = window.location.href.split('#')[0];

      this.commitRequest({
        request: () => window.Knack.Api.getSSORedirectURL(strategyName, returnUrl),
        onSuccess: (url) => window.location.assign(url),
      });
    },
    async testSSO() {
      const response = await this.onClickSave();

      if (response) {
        this.doRedirect();
      }
    },
  },
};
</script>

<style lang="scss">
.custom-sso-options {
  margin: 0 auto;

  h3 {
    font-weight: bold;
    padding-bottom: 1em;
  }

  textarea {
    min-height: 5.1em
  }

  .bottom-divider {
    margin-bottom: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(56, 57, 60, .125);
  }
}

.sso-add-invalid-name {
  color: red;
}
</style>
