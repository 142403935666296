<template>
  <ViewToolbox
    back-title="page"
    title="Edit Menu"
  >
    <div>
      <TocLinks :items="items" />
    </div>
  </ViewToolbox>
</template>

<script>
import TocLinks from '@/components/views/TocLinks';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'DetailsTOC',
  components: {
    ViewToolbox,
    TocLinks,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    items() {
      return [
        {
          title: 'Source',
          copy: 'which records to display',
          link: 'source',
          filters: this.activeView?.hasSourceFilters?.(),
        },
        {
          title: 'Calendar Settings',
          copy: 'options and settings',
          link: 'settings',
        },
        {
          title: 'Event Details',
          copy: 'build event details',
          link: 'details',
        },
        {
          title: 'New Event Form',
          copy: 'add inputs',
          link: 'form',
        },
      ];
    },
  },
};
</script>
