<script setup>

import ChecklistsProgress from './ChecklistsProgress.vue';
import useChecklists from '@/checklists/useChecklists';

const { totalCompleted, totalCount } = useChecklists();

</script>

<template>
  <div class="flex items-center gap-4">
    <div class="flex-1">
      <ChecklistsProgress :steps="{completed: totalCompleted, total: totalCount}" />
    </div>
  </div>
</template>
