<template>
  <Modal
    title="Table Settings"
    :back="backLink"
  >
    <nav
      v-if="objectRaw.user"
      class="tabsMenu margin-bottom-double border-0 -mt-2 mb-6"
    >
      <RouterLink
        v-slot="{ isActive }"
        to="edit"
        class="tabsMenu_tab"
        :class="routerLinkClasses"
        data-cy="object-settings-edit"
      >
        <span :class="[linkItemContentWrapperClasses, getLinkItemContentWrapperActiveClasses(isActive)]">
          <Icon
            type="cog-6-tooth"
            class="text-default h-4 w-4 mr-1"
            :class="{
              '!text-brand': isActive,
              'group-hover:text-brand-400': !isActive
            }"
          /> Settings
        </span>
      </RouterLink>
      <IfPlan :is-trial="false">
        <RouterLink
          v-slot="{ isActive }"
          to="approval"
          class="tabsMenu_tab"
          :class="routerLinkClasses"
          data-cy="object-settings-approval"
        >
          <span :class="[linkItemContentWrapperClasses, getLinkItemContentWrapperActiveClasses(isActive)]">
            <Icon
              type="newspaper"
              class="text-default w-4 h-4 mr-1"
              :class="{
                '!text-brand': isActive,
                'group-hover:text-brand-400': !isActive
              }"
            />
            Approval Template
          </span>
        </RouterLink>
      </IfPlan>
      <IfPlan :is-trial="false">
        <RouterLink
          v-slot="{ isActive }"
          to="info"
          class="tabsMenu_tab"
          :class="routerLinkClasses"
          data-cy="object-settings-info"
        >
          <span :class="[linkItemContentWrapperClasses, getLinkItemContentWrapperActiveClasses(isActive)]">
            <Icon
              type="newspaper"
              class="text-default w-4 h-4 mr-1"
              :class="{
                '!text-brand': isActive,
                'group-hover:text-brand-400': !isActive
              }"
            />
            Account Info Template
          </span>
        </RouterLink>
      </IfPlan>
    </nav>

    <RouterView
      :object="object"
      :back-link="backLink"
      :local-object-raw="localObjectRaw"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';
import FieldUtils from '@/components/fields/FieldUtils';
import Icon from '@/components/ui/Icon';
import IfPlan from '@/components/util/IfPlan';
import RequestUtils from '@/components/util/RequestUtils';
import { isTrial } from '@/lib/plan-helper';

export default {
  name: 'ObjectSettings',
  components: {
    IfPlan,
    Modal,
    Icon,
  },
  mixins: [
    RequestUtils,
    FieldUtils,
  ],
  props: {
    baseUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    localObjectRaw() {
      return JSON.parse(JSON.stringify(this.object.raw()));
    },
    backLink() {
      return this.baseUrl;
    },
    routerLinkClasses() {
      return 'border-0 flex justify-center p-0 mr-2 group';
    },
    linkItemContentWrapperClasses() {
      return 'flex items-center text-default w-full h-full rounded-lg p-3 text-base font-normal leading-4';
    },
  },
  mounted() {
    // Just a simple redirect for those on a trial plan. Ideally this will be replaced in the future
    // by some sort of UI that says "hey, you are a trial so you don't get access to this"

    // If this is a trial account and we aren't on the edit route, redirect to the edit route
    if (isTrial() && !this.$route.path.endsWith('edit')) {
      this.$router.replace('edit');
    }
  },
  methods: {
    getLinkItemContentWrapperActiveClasses(isActive) {
      return {
        'group-hover:bg-brand-50 group-hover:text-emphasis': !isActive,
        'text-emphasis bg-brand-100': isActive,
      };
    },
  },
};
</script>
