export default {
  computed: {
    routePrefix() {
      let viewType = this.view.type;

      if (viewType === 'registration') {
        viewType = 'form';
      }

      return `/pages/${this.$route.params.pageKey}/views/${this.view.key}/${viewType}`;
    },
    page() {
      return this.$store.getters.activePage;
    },
    object() {
      return this.$store.getters.getObject(this.view.get('source').object);
    },
    design() {
      return this.view.get('design');
    },

    // this is a temp replacement for Knack.js current hash
    sceneHash() {
      return ``;
    },
  },
};
