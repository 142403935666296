import Api from '@/lib/api-wrapper';

// eslint-disable-next-line import/prefer-default-export
export async function completeChecklistStep(stepId: string) {
  const options = {
    url: '/checklists',
    method: 'PUT',
    encoding: null,
    data: { stepId },
  };

  await Api.axios(options);
}

export function replaceUrlVariables(url: string, store: any) {
  let replaced = url;

  const replacements = [
    {
      id: 'object', resolver: () => store.getters.standardObjects, param: 'id', text: 'table',
    },
    {
      id: 'scene', resolver: () => (store.getters.isEmptyPages() ? [] : store.getters.allPages), param: 'key', text: 'page',
    },
    { // TODO: This can be refactored in the new builder to accept all types of views dynamically
      id: 'scene@table_view',
      resolver: () => (store.getters.isEmptyPages() ? [] : store.getters.allPages)
        .filter((p: any) => p.views.some((v: any) => v.type === 'table')),
      param: 'key',
      text: 'page with a grid',
    },
    {
      id: 'account_object', resolver: () => store.getters.userObjects, param: 'key', text: 'account table',
    },
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const {
    id, resolver, param, text,
  } of replacements) {
    if (url.includes(`{${id}}`)) {
      const data = resolver();

      if (data.length > 0) {
        replaced = replaced.replace(`{${id}}`, data[0][param]);
      } else {
        store.dispatch('toasts/addToast', { message: `You need a ${text} in this application to perform the task!`, type: 'error' });

        return null;
      }
    }
  }

  return replaced;
}
