<template>
  <form
    id="bot-action"
    data-cy="task-action"
  >
    <RuleItem
      id="bot-action-rule"
      :object-key="object.key"
      :item="action"
      :allow-email-action="true"
      :rule-type="RuleType.Task"
      :allow-location-option="false"
      :criteria-values-can-be-fields="false"
    >
      <template #no-criteria>
        <p class="text-sm text-default mb-2">
          This task will run on every record.
        </p>
      </template>
    </RuleItem>
  </form>
</template>

<script>
import FieldUtils from '@/components/fields/FieldUtils';
import RuleItem from '@/components/rules/RuleItem';
import { RuleType } from '@/constants/rules';

export default {
  components: {
    RuleItem,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:action'],
  data() {
    return {
      RuleType,
    };
  },
  computed: {
    localAction: {
      get() {
        return this.action;
      },
      set(newValue) {
        this.$emit('update:action', newValue);
      },
    },
    hasCriteria() {
      return !_.isEmpty(this.action.criteria);
    },
    defaultCriteria() {
      const field = this.object.fields[0];

      return {
        field: field.key,
        operator: field.getFirstRuleOperator(),
        value: field.getFilterDefault(),
      };
    },
  },
  methods: {
    addCriteria() {
      this.action.criteria = [
        this.defaultCriteria,
      ];
    },
  },
};
</script>

<style lang="scss">
#bot-action {

  font-size: .94em;
}

#bot-action-rule > div {
  margin-bottom: 1em;

  & > div, & > ul {
    background-color: $gray50;
    padding: .5em;
    border-radius: .32em;
  }
}
</style>
