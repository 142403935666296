<template>
  <textarea
    :id="inputKey"
    class="kn-redactor"
    :name="inputKey"
    :value="localValue"
    @input="event => handleInput(event.target.value)"
  />
</template>

<script>
import $ from 'jquery';
import hasIn from 'lodash/hasIn';
import '@/lib/redactor/redactor.min';

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    input: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      inputKey: hasIn(this.input, 'field') ? this.input.field.key : this.input.key,
      localValue: this.modelValue,
      initialValue: this.modelValue,
      redactorEditor: null,
      currentSelectedNode: null,
    };
  },
  watch: {
    modelValue() {
      // If any changes are detected on the active view ensure that the editor code is still
      // in sync with the view's value. This fixes situations where hitting the cancel view
      // update button wouldn't trigger a reset of the redactor editor.
      if (this.initialValue === this.modelValue) {
        this.localValue = this.initialValue;
        this.redactorEditor.code.set(this.initialValue);
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.redactorEditor.core.destroy();
  },
  methods: {
    updateCurrentNode() {
      if (hasIn(this, 'redactorEditor')) {
        this.currentSelectedNode = this.redactorEditor.selection.getCurrent();
      }
    },
    init() {
      const _this = this;

      // syncs with visual editor
      const syncCallback = function () {
        // `this` is the Redactor instance
        _this.handleInput(this.code.get());
      };

      const initCallback = function () {
        // store reference to this Redactor editor for syncing purposes
        _this.redactorEditor = this;
      };

      const changeCallback = function () {
        _this.updateCurrentNode();
      };

      // extend config
      this.config.syncCallback = syncCallback;
      this.config.initCallback = initCallback;
      this.config.changeCallback = changeCallback;
      this.config.replaceDivs = false;

      // need to call Redactor with jQuery
      $(this.$el).redactor(this.config);
    },
    insert(insertValue) {
      this.redactorEditor.caret.setAfter(this.currentSelectedNode);
      this.redactorEditor.insert.text(insertValue);
      this.handleInput(this.redactorEditor.code.get());
    },
    handleInput(newValue) {
      if (newValue.trim() === '') {
        this.$emit('update:modelValue', newValue.trim());
        return;
      }

      // Manually update localValue since we don't have a two-way binding with the textarea
      // We store the raw unsanitized value to avoid the editor from sanitizing the input on every keypress,
      // creating a bad user experience
      this.localValue = newValue;

      // Sanitize the code before emitting to parent to ensure our schema data never has dirty code
      const sanitizedCode = this.redactorEditor.clean.onSet(this.redactorEditor.code.get());

      // emit to parent
      this.$emit('update:modelValue', sanitizedCode);
    },
  },
};
</script>

<style lang="scss">
@import 'src/lib/redactor/redactor.scss';

.redactor-editor {
  // this is used to match the base font. otherwise <strong> / <b> may not be applied properly.
  font-family: inherit;
  max-height: 18.75em;
}
</style>
