import flagsmith from 'flagsmith';

export async function getFlagsmithEnabledFlags() {
  // This line ensures we're up-to-date with the flags value, meaning that if we previously identified the user we
  // ensure we get computed values for that identity and not control environment values.
  await flagsmith.getFlags();

  const flagsmithFlags = flagsmith.getAllFlags();

  return Object.entries(flagsmithFlags).reduce((acc, [name, { enabled, value }]) => {
    if (enabled) {
      acc[name] = value;
    }
    return acc;
  }, {});
}
