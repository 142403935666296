<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Equation Editor</label>
      <div class="font-body margin-bottom-half">
        Use the dropdowns to add functions and fields, or type an <b>open curly bracket {</b> to select a field.
      </div>
      <div class="equation-editor-buttons-wrapper rounded-t-lg">
        <FunctionsListDropdown
          :list="editorFunctions"
          trigger-text="Functions"
          trigger-text-color="#F6744D"
          display-property="label"
          @click="handleFunctionClick"
        />
        <FieldsListDropdown
          :filter-function="(field) => fieldLocal.key !== field.key && field.canBeUsedInEquations(equationType)"
          :exclude-many-connections="true"
          trigger-text="Fields"
          :uppercase="false"
          trigger-text-color="#4D91F6"
          @click="handleFieldClick"
        />
      </div>
      <div class="equation-editor-container rounded-b-lg mb-2">
        <!-- <div class="equation-editor-header" /> -->
        <div
          ref="editor"
          class="equation-editor"
        />
      </div>
    </div>
    <div class="mb-0">
      <label class="text-default text-sm font-medium mb-2 leading-4">Equation Output</label>
      <div class="font-body text-emphasis text-base">
        {{ exampleOutput }}
      </div>
    </div>
  </div>
</template>

<script>
import * as acornLoose from 'acorn-loose';
import * as acornWalk from 'acorn-walk';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

import {
  testEquation,
  buildCalc,
  normalizeEquationValue,
  equationType,
  equationObject,
  getRawEquationValue,
} from '@/util/EquationProcessing';
import equationFunctions from '@/data/EquationFunctions.json';
import FunctionsListDropdown from '@/components/ui/lists/FunctionsListDropdown';
import FieldsListDropdown from '@/components/ui/lists/FieldsListDropdown';

export default {
  components: {
    FieldsListDropdown,
    FunctionsListDropdown,
  },
  props: {
    fieldLocal: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:equation'],
  data() {
    return {
      disposables: [],
      languageName: 'knack',
      exampleOutput: '',
      editorOptions: {
        autoClosingBrackets: true,
        contextmenu: false,
        folding: false,
        fontSize: '14px',
        glyphMargin: false,
        hideCursorInOverviewRuler: true,
        iconsInSuggestions: true,
        language: 'knack',
        lineNumbers: 'off',
        matchBrackets: true,
        minimap: {
          enabled: false,
        },
        renderLineHighlight: 'none',
        scrollbar: {
          horizontal: 'Hidden',
          vertical: 'Hidden',
        },
        scrollBeyondLastLine: false,
        tabSize: 2,
        theme: 'knack',
        value: '',
        wordSuggestions: false,
        wordWrap: 'on',
      },
      editorTheme: {
        base: 'vs-dark',
        inherit: true,
        colors: {
          'editor.background': '#222B33',
          'editorSuggestWidget.highlightForeground': '#F69C81',
        },
        rules: [
          {
            foreground: '#ff9966',
            token: 'identifier',
          },
          {
            foreground: '#B8E986',
            token: 'math',
          },
          {
            token: 'curly',
            foreground: '#81B0F6',
          },
        ],
      },
      languageConfiguration: {
        brackets: [
          [
            '(',
            ')',
          ],
        ],
      },
      monarchTokensProvider: {
        tokenizer: {
          root: [
            [
              /[a-z_$][\w$]*/,
              'identifier',
            ],
            [
              /[+\-*/%\d+]/,
              'math',
            ],
            [
              /\{[^}]*\}?/,
              'curly',
            ],
          ],
        },
      },
      completionItemProvider: {
        triggerCharacters: [
          '{',
        ],
        provideCompletionItems: this.provideCompletionItems,
      },
      signatureHelpProvider: {
        signatureHelpTriggerCharacters: [
          '(',
        ],
        signatureHelpRetriggerCharacters: [
          ',',
        ],
        provideSignatureHelp: this.provideSignatureHelp,
      },
    };
  },
  computed: {
    equationObject() {
      // when a field is being added via modal on a scene,
      // the object key is not available in the route param
      // in that scenario the object key is retrieved from the activeObject
      // which is set by the call to show the modal
      const { activeObject } = this.$store.getters;
      const secondaryObjectKey = this.$route.params.objectKey || activeObject.key;
      return equationObject(this.fieldLocal.object_key, secondaryObjectKey);
    },
    equationType() {
      return equationType(this.fieldLocal);
    },
    editorFields() {
      return this.equationObject.getEligibleFieldsForEquationField(this.fieldLocal.key, this.equationType);
    },
    editorFunctions() {
      const fieldTypes = _.uniq(this.editorFields.map((field) => field.type));

      if (this.equationType === 'numeric' || this.equationType === 'math') {
        return [
          ...equationFunctions.numeric,
          ...fieldTypes.includes('date_time') ? equationFunctions.date : [],
        ];
      }

      if (this.equationType === 'date') {
        return [
          ...equationFunctions.date,
          ...equationFunctions.date_numeric,
        ];
      }

      if (this.equationType === 'text') {
        return equationFunctions.text.filter((func) => !_.hasIn(func, 'limitToType') || fieldTypes.includes(func.limitToType));
      }

      return [];
    },
  },
  watch: {
    'fieldLocal.format': {
      deep: true,
      handler(newFormat) {
        const normalizedEquationValue = normalizeEquationValue(newFormat.equation);
        const rawEquationValue = getRawEquationValue(normalizedEquationValue, this.editorFields);

        this.testEquation(rawEquationValue);
      },
    },
  },
  created() {
    // edge case on some older apps where equation is an array
    let equationValue = normalizeEquationValue(this.fieldLocal.format.equation);

    equationValue = getRawEquationValue(equationValue, this.editorFields);

    // output the preview equation. This needs to happen before the parsing below that replaces the field keys with display values
    this.testEquation(equationValue);

    equationValue = this.getParsedEquationValue(equationValue, this.editorFields);

    equationValue = this.removeDeletedFieldsFromEquationValue(equationValue);

    this.editorOptions.value = equationValue;
  },
  mounted() {
    monaco.editor.defineTheme(this.languageName, {
      ...this.editorTheme,
    });

    // separate theme so that completion text color is blue for fields instead of orange
    monaco.editor.defineTheme(`${this.languageName}-blue`, this.editorTheme);

    monaco.languages.register({
      id: this.languageName,
    });

    this.disposables.push(monaco.languages.setLanguageConfiguration(this.languageName, this.languageConfiguration));
    this.disposables.push(monaco.languages.setMonarchTokensProvider(this.languageName, this.monarchTokensProvider));
    this.disposables.push(monaco.languages.registerCompletionItemProvider(this.languageName, this.completionItemProvider));
    this.disposables.push(monaco.languages.registerSignatureHelpProvider(this.languageName, this.signatureHelpProvider));

    this.monacoEditor = monaco.editor.create(this.$refs.editor, this.editorOptions);
    this.monacoEditor._standaloneKeybindingService.addDynamicKeybinding('-actions.find', null, () => {}); // gives ctrl-f back to browser

    // TODO:: when adding a field or function, this treats each new character in the paste as a change trigger. Can that be improved?
    this.disposables.push(this.monacoEditor.onDidChangeModelContent(this.onDidChangeModelContent));
    this.disposables.push(this.monacoEditor.onDidChangeCursorPosition(this.onDidChangeCursorPosition));
  },
  beforeUnmount() {
    for (const disposable of this.disposables) {
      disposable.dispose();
    }

    if (_.hasIn(this, 'monacoEditor') && !_.isNil(this.monacoEditor)) {
      this.monacoEditor.dispose();
      this.monacoEditor = null;
    }

    delete this.disposables;
    delete this.monacoEditor;
  },
  methods: {
    handleFunctionClick({ insertText }) {
      this.monacoEditor.trigger('keyboard', 'type', {
        text: insertText,
      });
    },
    handleFieldClick(interpolatedFieldName) {
      this.monacoEditor.trigger('keyboard', 'type', {
        text: interpolatedFieldName,
      });
    },
    getParsedEquationValue(value) {
      let outputValue = value || '';

      if (value !== '') {
        for (const field of this.editorFields) {
          outputValue = outputValue.replace(new RegExp(`{${field.value}}`, 'g'), `{${field.displayText}}`);
        }
      }

      return outputValue;
    },
    removeDeletedFieldsFromEquationValue(value) {
      return value.replace(new RegExp(/{field_\d+}/, 'g'), '');
    },
    onDidChangeModelContent(event) {
      const newlineRegex = /(\r\n\t|\n|\r\t)/gm;
      const value = this.monacoEditor.getValue();

      // if this test isn't here it would be infinite recursion
      if (newlineRegex.test(value)) {
        const strippedValue = value.replace(/(\r\n\t|\n|\r\t)/gm, '');

        this.monacoEditor.setValue(strippedValue);

        return;
      }

      const rawValue = getRawEquationValue(value, this.editorFields);

      this.fieldLocal.format.equation = value;

      this.$emit('update:equation', rawValue);
    },
    onDidChangeCursorPosition(event) {
      this.monacoEditor.trigger('cursorPositionChanged', 'editor.action.triggerParameterHints', {});
    },
    testEquation(editorValue) {
      if (_.isNil(editorValue)) {
        return;
      }

      const { calc, calcExample } = buildCalc(editorValue, this.equationType, this.fieldLocal, this.editorFields);
      const { isError, output } = testEquation(calc, this.equationType, this.fieldLocal.format);

      if (isError) {
        this.exampleOutput = output;
      } else {
        this.exampleOutput = output === 'Equation is empty' ? output : `${calcExample || calc} = ${output}`;
      }
    },
    getFunctionAtCursor() {
      const modelValue = this.monacoEditor.getValue();
      const columnPosition = this.monacoEditor.getPosition().column - 1;

      const braces = [];
      let sanitizedValue = modelValue;

      for (let index = 0; index < modelValue.length; index++) {
        const char = modelValue[index];

        if (char === '{') {
          braces.push(index);
        } else if (char === '}') {
          if (braces.length === 0) {
            continue;
          }

          if (braces.length > 1) {
            braces.pop();
          }

          const openingIndex = braces.pop() + 1;

          sanitizedValue = sanitizedValue.substring(0, openingIndex) + '*'.repeat(index - openingIndex) + sanitizedValue.substring(index);
        }
      }

      const acornTree = acornLoose.parse(sanitizedValue);

      return acornWalk.findNodeAround(acornTree, columnPosition, (nodeType, node) => {
        if (nodeType !== 'CallExpression') {
          return false;
        }

        const calleeName = (node.callee.name || node.callee.raw);
        const calleeLength = calleeName?.length ?? 1;
        const finalValue = modelValue[node.end - 1];

        return node.start + calleeLength + 1 <= columnPosition && (node.end > columnPosition || finalValue !== ')');
      });
    },
    isWithinCurlies() {
      const modelValue = this.monacoEditor.getValue();
      const columnPosition = this.monacoEditor.getPosition().column - 1;

      for (let index = columnPosition; index >= 0; index--) {
        const valAtIndex = modelValue[index];

        if (valAtIndex === '{') {
          return true;
        } if (valAtIndex === '}') {
          return false;
        }
      }

      return false;
    },
    provideSignatureHelp(model, position, cancellationToken, context) {
      // find closest node e.g. `sum(1, 2)` where the cursor is inside the parens
      const functionAtCursor = this.getFunctionAtCursor();

      if (_.isNil(functionAtCursor)) {
        return null;
      }

      const signatures = _.get(this.editorFunctions.find((func) => func.label === functionAtCursor.node.callee.name), 'signatures', []);
      const activeSignature = 0; // we don't support multiple signatures... changes needed elsewhere if we ever do
      let activeParameter = 0;

      if (_.isEmpty(signatures)) {
        return null;
      }

      if (signatures.length > 0) {
        const signature = signatures[activeSignature];
        const expressionArguements = _.get(functionAtCursor, 'node.arguments');

        // if args then we need to loop on same parameter indefinitely until closing brace
        if (_.hasIn(signature, 'hasArgsParameter') && signature.hasArgsParameter) {
          activeParameter = signature.parameters.length - 1;
        } else if (!_.isNil(expressionArguements)) {
          for (const argument of expressionArguements) {
            if (position.column - 1 > argument.end) {
              activeParameter += 1;
            }
          }
        }
      }

      return {
        activeSignature,
        activeParameter,
        signatures,
      };
    },
    provideCompletionItems(model, position, context, cancellationToken) {
      if (this.isWithinCurlies()) {
        monaco.editor.setTheme(`${this.languageName}-blue`);

        const functionAtCursor = this.getFunctionAtCursor();

        let { editorFields } = this;

        if (_.hasIn(functionAtCursor, 'node.callee.name')) {
          const functionContext = this.editorFunctions.find((func) => func.label === functionAtCursor.node.callee.name);

          if (!_.isNil(functionContext) && _.hasIn(functionContext, 'limitToType')) {
            editorFields = this.editorFields.filter((field) => field.type === functionContext.limitToType);
          }
        }

        const fieldSuggestions = editorFields.map((field) => ({
          label: field.displayText,
          insertText: `${field.displayText}}`,
          kind: monaco.languages.CompletionItemKind.Variable,
          detail: field.relation,
        }));

        return {
          suggestions: fieldSuggestions,
        };
      }

      monaco.editor.setTheme(this.languageName);

      const suggestions = this.editorFunctions.map((func) => ({
        label: func.label,
        insertText: func.insertText,
        kind: monaco.languages.CompletionItemKind.Function,
        command: {
          id: 'editor.action.triggerParameterHints',
        },
      }));

      return {
        suggestions,
      };
    },
  },
};
</script>

<style lang="scss">
.font-body {
  @include font-body;
}
.margin-bottom {
  margin-bottom: 1.5rem;
}
.equation-editor-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $gray800;
  padding-top: .25em;
  padding-bottom: .5em;
  border-bottom-left-radius: .1875em;
  border-bottom-right-radius: .1875em;
  margin-bottom: 1.5em;
}

.equation-editor-buttons-wrapper {
  width: 100%;
  background-color: $gray800;
  color: $white100;
  padding: .5em;
  border-bottom:1px solid $gray500;
  display:flex;
  border-top-left-radius: 0.1875em;
  border-top-right-radius: 0.1875em;

  .equation-editor-button {
    width: 128px;
    margin: 0;
    margin-right: .5rem;
    background-color: $white100;
    border-radius: .1875rem;
    padding: .125rem .1875rem .1875rem .5rem;
    padding-left: .5rem;
    @include font-body;
    display:inline-flex;
    align-items:center;
    justify-content:space-between;

    & label {
      margin-bottom:0;
    }
  }

  .equation-editor-icon-wrapper {
    color: $gray600;
    max-height: 18px;

    svg {
      max-height: 18px;
      max-width: 18px;
    }
  }
}

.monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input {
  background-color: $gray800 !important;
}

.equation-editor-header {
  min-height: 40px;
  width: 100%;
  background-color: $gray800;
  border-bottom: 1px solid $gray500;
}

.equation-editor {
  min-height: 120px;
  width: 100%;

  .decorationsOverviewRuler {
    visibility: hidden;
  }
}

.monaco-editor .lines-content.monaco-editor-background {
  margin-left: 5px;
}

.monaco-editor textarea:focus {
  border: none !important;
}

.monaco-editor p {
  margin: 0;
  line-height: 1.25em !important;
}

.margin-view-overlays {
  margin-top: 5px;
}
</style>
