import store from '@/store';

export function mergedResponseChanges(...responses) {
  // Take any number of responses and return a single object with their changes merged, in order (last one wins)
  return responses.reduce((mergedChanges, response) => {
    if (!response.changes) {
      return mergedChanges;
    }

    for (const key in mergedChanges) {
      if (!response.changes[key]) {
        continue;
      }

      for (const changeKey in response.changes[key]) {
        if (!mergedChanges[key][changeKey]) {
          mergedChanges[key][changeKey] = [];
        }

        // For each change from the response changes, filter out any matching keys and then append
        mergedChanges[key][changeKey] = mergedChanges[key][changeKey]
          .filter((change) => !response.changes[key][changeKey].map(({ key }) => key).includes(change.key))
          .concat(response.changes[key][changeKey]);
      }
    }

    return mergedChanges;
  }, {
    deletes: {},
    inserts: {},
    updates: {},
  });
}

export async function handleChanges({ deletes, inserts, updates }) {
  log('handleChanges() deletes', deletes);
  log('handleChanges() inserts', inserts);
  log('handleChanges() updates', updates);

  // deletes
  for (const page of deletes.scenes) {
    await store.dispatch('removePage', page.key);

    store.commit('routes/resetRoutes', page.key);
  }

  for (const view of deletes.views) {
    await store.dispatch('page/view/deleteViewLocally', {
      rawPageKey: view.scene.key,
      viewKey: view.view.key,
    });
  }

  for (const object of deletes.objects) {
    store.commit('removeObject', object.key);

    store.commit('routes/resetRoutes', object.key);
  }

  for (const field of deletes.fields) {
    store.commit('removeField', {
      fieldKey: field.field.key,
      objectKey: field.object.key,
    });
  }

  for (const obj of updates.objects) {
    log('update object: ', obj);

    store.commit('updateObject', obj);
  }

  for (const { field, object } of updates.fields) {
    log('update field : ', field);

    field.object_key = object.key;

    await store.dispatch('updateField', {
      field,
    });
  }

  // Updating scenes must come before inserts because there are cases where the newly entered scene looks for updated child scenes (adding login view)
  for (const page of updates.scenes) {
    await store.dispatch('updatePageLocally', { pageKey: page.key, updates: page });
  }

  for (const view of updates.views) {
    await store.dispatch('page/view/updateViewLocally', {
      rawPageKey: view.scene.key,
      viewKey: view.view.key,
      viewUpdates: view.view,
    });
  }

  for (const page of inserts.scenes) {
    await store.dispatch('addPage', page);
  }

  for (const object of inserts.objects) {
    store.commit('addObject', object);
  }

  for (const view of inserts.views) {
    await store.dispatch('page/view/addViewLocally', {
      rawPageKey: view.scene.key,
      viewData: view.view,
      locationType: 'addType',
      groupIndex: -1, // Pushes the view onto the end of the stack.
      columnIndex: view.view.columnIndex,
      itemIndex: view.view.itemIndex,
    });
  }
}
