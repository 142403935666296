import Build from '@/components/views/menu/Build';
import LinkProperties from '@/components/views/menu/LinkProperties';
import Settings from '@/components/views/menu/Settings';
import Design from '@/components/views/menu/Design';

export default [
  {
    path: ':pageKey/views/:viewKey/menu',
    components: {
      toolbox: Build,
    },
  },
  {
    path: ':pageKey/views/:viewKey/menu/links',
    components: {
      toolbox: Build,
    },
  },
  {
    path: ':pageKey/views/:viewKey/menu/links/:linkIndex',
    name: 'menuLinkProperties',
    components: {
      toolbox: LinkProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/menu/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/menu/design',
    components: {
      toolbox: Design,
    },
  },
];
