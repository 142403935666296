<template>
  <view-toolbox
    back-title="Edit Details"
    title="Design Settings"
  >
    <form>
      <div class="mb-2">
        <label class="tw-toolbox-label">Title</label>
        <input
          id="title-input"
          v-model="viewRaw.title"
          type="text"
        >
      </div>
      <div class="mb-2">
        <label class="tw-toolbox-label">Description</label>
        <textarea v-model="viewRaw.description" />
      </div>
      <div class="mb-2">
        <label class="tw-toolbox-label">Label Format</label>
        <LabelFormat
          :model-value="viewRaw"
          @update-property:model-value="onUpdateViewProperty"
        />
      </div>
      <div class="mb-2">
        <label class="tw-toolbox-label">Hide Empty Values</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="viewRaw.hide_fields"
            type="radio"
            name="hide_fields"
            :value="false"
          > No, show all fields</label>
          <label><input
            v-model="viewRaw.hide_fields"
            type="radio"
            name="hide_fields"
            :value="true"
          > Yes, hide fields that don't have values</label>
        </div>
      </div>

      <!--
      <div>
        <label class="label-header">Customize Design</label>
        <div class="customization-options">
          <DesignPropertyPicker
            design-property="labelFormat"
            label-text="Label Format"
            input-type="dropdown"
            :options="alignmentOptions" />
        </div>
      </div>
    -->
    </form>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DesignUtils from '@/components/views/DesignUtils';
import LabelFormat from '@/components/views/shared/settings/LabelFormat';
// import DesignPropertyPicker from '@/components/settings/design/DesignPropertyPicker'

export default {
  name: 'DetailSettings',
  components: {
    // DesignPropertyPicker,
    ViewToolbox,
    LabelFormat,
  },
  mixins: [
    ViewUtils,
    DesignUtils,
  ],
  data() {
    return {

      alignmentOptions: [
        {
          value: 'left-aligned',
          label: 'Left-aligned',
        },
        {
          value: 'right-aligned',
          label: 'Right-aligned',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.customization-options {
  margin-top: .5em;

  >div {
    margin-bottom: 1em;
  }
  .default {
    margin-bottom: 5px;
  }
  .custom {
    margin-left: 16px;
  }

  b {
    font-weight: 500;
  }
}
</style>
