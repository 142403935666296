<template>
  <EquationEditor
    v-model:equation="fieldLocal.format.equation"
    :field-local="fieldLocal"
  />
</template>

<script>
import EquationEditor from '@/components/fields/settings/shared/EquationEditor';

export default {
  name: 'TextFormula',
  components: {
    EquationEditor,
  },
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
