import TOC from '@/components/views/calendar/TOC';
import Settings from '@/components/views/calendar/Settings';
import Design from '@/components/views/calendar/Design';
import Source from '@/components/views/calendar/Source';
import Details from '@/components/views/calendar/Details';
import DetailProperties from '@/components/views/calendar/DetailProperties';
import Inputs from '@/components/views/calendar/Inputs';
import InputProperties from '@/components/views/calendar/InputProperties';

export default [
  {
    path: ':pageKey/views/:viewKey/calendar/source',
    components: {
      toolbox: Source,
    },
  },
  {
    path: ':pageKey/views/:viewKey/calendar',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/calendar/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/calendar/design',
    components: {
      toolbox: Design,
    },
  },
  {
    path: ':pageKey/views/:viewKey/calendar/details',
    components: {
      toolbox: Details,
    },
  },
  {
    path: ':pageKey/views/:viewKey/calendar/details/build',
    components: {
      toolbox: Details,
    },
  },
  {
    path: ':pageKey/views/:viewKey/calendar/details/layouts/:layoutIndex/groups/:groupIndex/columns/:columnIndex/items/:itemIndex',
    components: {
      toolbox: DetailProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/calendar/form',
    components: {
      toolbox: Inputs,
    },
  },
  {
    path: ':pageKey/views/:viewKey/calendar/form/rows/:rowIndex/columns/:columnIndex/inputs/:inputIndex',
    components: {
      toolbox: InputProperties,
    },
  },
];
