<template>
  <RouterLink
    v-slot="{ href, isActive, navigate }"
    :key="page.key"
    :to="pageLink"
    custom
  >
    <li
      :id="`page-link-${page.key}`"
      class="nav-item m-0"
      :class="[
        {'router-link-active': isActive},
        draggableClass,
        transitionClass,
      ]"
      :data-key="page.key"
      data-cy="page-link-item"
      @click="open"
    >
      <a
        class="rounded-lg py-2 pr-2 mx-4 h-auto group"
        :class="[
          {
            'bg-brand-100': isActive,
            'hover:bg-brand-50': !isActive,
            // 'pl-8': !isChild && !isFolder,
            'bg-brand-50': isPopoverPresent(`page-link-${page.key}`) && !isActive,
          },
          linkClasses
        ]"
        :href="href"
        @click="navigate"
      >
        <div
          class="page-link-wrapper p-0 m-0 h-auto"
          :class="getPageNavLinkContentActiveClasses(isActive)"
          :data-cy="page.name"
        >
          <div
            v-if="isFolder"
            class="tree-expand ml-0 w-4 h-4 mr-2"
            :class="{ open: isOpen }"
            @click.stop.prevent="toggle"
          >
            <Icon
              class="w-4 h-4"
              type="arrow-drop-right"
            />
          </div>
          <div
            v-else
            class="w-6"
            :class="{'w-5': !isChild }"
            style="width: 14px; flex-shrink: 0;"
          />

          <div class="name">
            <Icon
              v-if="page.type === 'authentication'"
              v-tippy="tooltipSettings"
              data-cy="login-page"
              type="lock"
              :class="getPageNavLinkIconActiveClasses(isActive)"
            />
            <Icon
              v-else-if="page.type === 'menu'"
              v-tippy="tooltipSettings"
              type="dropdown-menu"
              :class="getPageNavLinkIconActiveClasses(isActive)"
            />
            <Icon
              v-else
              v-tippy="tooltipSettings"
              type="page"
              :class="getPageNavLinkIconActiveClasses(isActive)"
            />
            <span
              class="transition truncate text-base text-default group-hover:text-emphasis font-normal"
              :class="{ 'text-emphasis': isActive }"
              v-html="page.name"
            />
          </div>

          <PageEditDropdown
            :page="page"
            :classes="{
              trigger: 'buttonSquare-outline -size-small -color-secondary flex w-6 h-6 rounded text-default justify-center items-center mr-2 hover:bg-brand-200 border-0',
              triggerIcon: 'h-4 w-4',
            }"
            @toggle="onPopoverToggled"
          />
        </div>
      </a>
      <Transition
        name="slide"
        @before-enter="slideBeforeEnter"
        @enter="slideEnter"
        @after-enter="slideAfterEnterAndLeave"
        @before-leave="slideBeforeLeave"
        @after-leave="slideAfterEnterAndLeave"
      >
        <PageListSortable
          v-if="isFolder && isOpen"
          class="transition page-children"
          :class="{ closed: !isOpen }"
          data-cy="child-pages"
          :can-sort="childrenAreSortable"
        >
          <PageLink
            v-for="item of children"
            :key="item.key"
            :route-query="routeQuery"
            :page="item"
            :filter-matches="filterMatches"
            :is-sortable="childrenAreSortable"
            :children-are-sortable="false"
          />
        </PageListSortable>
      </Transition>
    </li>
  </RouterLink>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { findValueDeep } from 'deepdash-es/standalone';
import PageEditDropdown from '@/components/pages/PageEditDropdown';
import Icon from '@/components/ui/Icon';
import filterPages from '@/components/pages/filterPages';
import PageListSortable from '@/components/pages/PageListSortable';
import {
  getUserRolesWithPageAccessFriendly,
  getPageAccessDescriptionMarkup,
} from '@/lib/page/page-helper';

export default {
  name: 'PageLink',
  components: {
    Icon,
    PageEditDropdown,
    PageListSortable,
  },
  props: {
    childrenAreSortable: {
      type: Boolean,
      default: false,
    },
    isSortable: {
      type: Boolean,
      default: false,
    },
    filterMatches: {
      type: Array,
      required: true,
    },
    page: {
      type: Object,
      default: () => {},
    },
    routeQuery: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popoverToggled: false,
    };
  },
  computed: {
    ...mapGetters([
      'userObjectsByProfileKey',
    ]),
    ...mapGetters('page/navVisibility', [
      'getPageNavVisibility',
    ]),
    pageLink() {
      return `${this.page.getPagePath}?${this.routeQuery}`;
    },
    draggableClass() {
      return {
        draggable: this.isSortable,
      };
    },
    transitionClass() {
      return {
        transition: true,
      };
    },
    linkClasses() {
      return {
        transition: true,
        'popover-toggled': this.popoverToggled,
      };
    },

    isFolder() {
      return this.children.length > 0;
    },

    isChild() {
      return this.page.parent;
    },

    isMenu() {
      return this.page.type === 'menu';
    },

    isOpen() {
      return this.getPageNavVisibility(this.page.key);
    },

    children() {
      return filterPages({
        pages: this.page.getChildrenPages(),
        filterMatches: this.filterMatches,
      });
    },
    tooltipSettings() {
      const userRolesWithPageAccess = getUserRolesWithPageAccessFriendly({
        page: this.page,
        userObjectsByProfileKey: this.userObjectsByProfileKey,
      });
      const content = getPageAccessDescriptionMarkup({
        page: this.page,
        userRolesWithPageAccess,
      });

      return {
        content,
        allowHTML: true,
        placement: 'right',
        theme: 'kn-dark break-words',
        delay: [500, 0],
      };
    },
  },
  mounted() {
    this.openMenuPages();
  },

  methods: {
    ...mapActions('page/navVisibility', [
      'hideNavChildren',
      'showNavChildren',
    ]),

    /**
       * Opens this page's children if any menu pages match the current page.
       */
    openMenuPages() {
      if (isEmpty(this.page.menu_pages)) {
        return;
      }

      const currentPageKey = get(this, '$route.params.pageKey');

      if (!currentPageKey) {
        return;
      }

      const { children } = this.page;

      const findCallback = (page) => get(page, 'key') === currentPageKey;

      const matchingPage = findValueDeep(children, findCallback, {
        childrenPath: 'children',
        leavesOnly: false,
      });

      if (matchingPage) {
        this.open();
      }
    },

    onPopoverToggled(toggleState) {
      this.popoverToggled = toggleState;
    },

    open() {
      if (this.isOpen || !this.isFolder) {
        return;
      }

      this.showNavChildren(this.page.key);
    },

    toggle() {
      const pageKey = this.page.key;

      if (this.isFolder) {
        if (this.isOpen) {
          this.hideNavChildren(pageKey);
        } else {
          this.showNavChildren(pageKey);
        }

        return;
      }

      // if no longer a folder but open, then set to closed
      if (this.isOpen) {
        this.hideNavChildren(pageKey);
      }
    },

    slideBeforeEnter(slideElement) {
      slideElement.style.setProperty('max-height', '0px');
    },

    slideEnter(slideElement) {
      const parentEl = slideElement.parentElement;

      // Get the height of the parent's nav-item.
      const navItemHeight = parentEl.firstElementChild.getBoundingClientRect().height;

      // Find the number of nav-item classes that exists within this element at any depth.
      const childrenCount = slideElement.getElementsByClassName('nav-item').length;

      // Assume all sub nav-items will have the same height as the parent.
      const height = navItemHeight * childrenCount;

      slideElement.style.setProperty('max-height', `${height || 0}px`);
    },

    slideAfterEnterAndLeave(slideElement) {
      // Make sure if we miscalculated the max-height that every element will still be shown.
      slideElement.style.removeProperty('max-height');
    },

    slideBeforeLeave(slideElement) {
      // Set the max-height to its current height.
      const currentHeight = slideElement.getBoundingClientRect().height;

      slideElement.style.setProperty('max-height', `${currentHeight}px`);
    },
    getPageNavLinkIconActiveClasses(isActive) {
      return {
        'text-default group-hover:text-brand-400 ': !isActive,
        'fill-[url(#svg-brand-gradient)]': isActive,
      };
    },
    getPageNavLinkContentActiveClasses(isActive) {
      return {
        'text-default group-hover:text-emphasis': !isActive,
        'text-brand': isActive,
      };
    },
    isPopoverPresent(parentId) {
      const parentElement = document.getElementById(parentId);
      if (!parentElement) {
        return false;
      }

      return Array.from(parentElement.children).some((child) => child.classList.contains('popover-toggled'));
    },
  },
};
</script>

<style lang="scss">
.nav-item {

  .slide-enter-active,
  .slide-leave-active {
    transition: max-height 300ms ease-out;
  }

  .slide-enter,
  .slide-leave-to {
    // The important is required because of how Transition works.
    max-height: 0 !important;
  }

  .tree-branch {
    border-left: 1px solid $gray300;
    border-bottom: 1px solid $gray300;
    width: 8px;
    position: absolute;
    top: -16px;
    height: 32px;
    margin-left: -13px;
  }

  .tree-branch-parent {
    border-left: 1px solid $gray300;
    position: absolute;
    top: 32px;
    height: 12px;
    left: 23px;
  }

  .tree-expand {
    height: 20px;
    width: 20px;
    margin-right: 2px;
    margin-left: -8px;

    display: flex;
    align-items: center;

    svg {
      display: block;
      transition: all 200ms ease-out;
      width: 20px;
      height: 20px;
    }

    &:hover svg {
      opacity: 1;
    }
  }

  .tree-expand.open svg {
    margin-top: 0;
    margin-left: 0;
    transform: rotate(90deg);
  }

  a {
    color: $gray400;
    display: flex;

    .page-link-wrapper {

      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 0;

      height: 32px;
      margin-left: -2px;
      padding-left: .375em;
    }

    .name {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      line-height: 1.25em;
      min-width: 0;
      @include font-body;

      span {
        color: $gray800;
      }

      svg {
        width: 16px;
        height: 16px;
        display: block;
        margin: 0 .375rem 0 0;
        flex-shrink: 0;
        color: $gray400 !important;
        transition: color .2s ease;
      }

      svg:hover {
        color: $gray500 !important;
      }
    }
  }

  &>ul {
    overflow: hidden;
  }

  &.disabled > a .name * {
    color: #ccc;
  }

  &.disabled > a {
    color: #ccc;
  }

  ul.page-children {

    & > li:last-child {
      padding-bottom: .125em;
    }
  }

  span.truncate {
    overflow-y: hidden
  }
}
</style>
