/* eslint-disable object-curly-newline */

import RuleHelper from '@knack/rules-helper';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import RecordModel from '@/store/models/Record';
import store from '@/store';

/**
 * Fields that require a special component to properly render in the table
 * @type {Set<string>}
 */
const SPECIAL_RENDER_FIELD_TYPES = new Set([
  'link',
  'signature',
  'rating',
  'paragraph_text',
  'connection',
  'rich_text',
]);

//
// VUEX GETTER REFERENCES
// References to the local vuex store
//

/**
 * Gets a field from the store.
 *
 * @return {function} - Function that retrieves a field's data.
 *                      Takes 2 arguments: the field and the application schema.
 */
const getField = (fieldKey) => store.getters.getField(fieldKey);

function getValue(ruleCriteria, record) {
  const recordFieldRaw = record[`${ruleCriteria.field}_raw`];
  if (
    Array.isArray(ruleCriteria.value)
    && Array.isArray(recordFieldRaw)
    && recordFieldRaw.length > 0
    && recordFieldRaw[0].id
  ) {
    return recordFieldRaw[0].id;
  }
  return recordFieldRaw;
}

/**
 * Gets the application schema from the store.
 *
 * @returns {function} - Function that retrieves the application schema.
 */
const app = () => store.getters.app;

/**
 * Gets the application design color for links
 *
 * @return {string} - The link color style.
 */
const appDesignColor = () => store.getters.app.design.general.links.color;

/**
 * Gets the page schema from the store by scene slug.
 *
 * @param {string} scene - The scene key/slug of a page.
 * @return {object} - An application page schema.
 */
const getPageBySlug = (scene) => store.getters.getPageBySlug(scene);

//
// UTILITY FUNCTIONS
// functions used by the helper functions (These are basically computed functions for this module)
//

/**
 * Gets passed-rules from the column, records, and application context.
 *
 * @param {object} props
 * @param {object} props.column
 * @param {object} props.record
 * @return {object[]}
 */
const getPassedRules = ({ column, record }) => {
  if (isEmpty(column?.rules)) {
    return [];
  }

  const passedRules = [];

  column.rules.forEach((rule) => {
    const pass = rule.criteria.every((ruleCriteria) => RuleHelper.checkRule(
      ruleCriteria,
      getValue(ruleCriteria, record),
      getField(ruleCriteria.field),
      app(),
    ));

    if (pass) {
      passedRules.push(rule);
    }
  });

  return passedRules;
};

/**
 * Determines if the column has content to render instead of the record data.
 *
 * @param {object} props
 * @param {object} props.column
 * @return {boolean}
 */
const isFieldContent = ({ column }) => {
  if (isNil(column.field)) {
    return false;
  }

  return (column.type === 'field' || (column.type === 'link' && column.link_type === 'field'));
};

//
// HELPER FUNCTIONS
// functions exported for use in the table cell components
//

/**
 * Gets style information from the column settings.
 *
 * @param {object} props
 * @param {object} props.column
 * @param {object[]} [props.passedRules]
 * @return {object} - The styles object for use in a vue template.
 */
const getStyle = ({ column, passedRules = [] }) => {
  let width = null;

  const isWidthCustom = hasIn(column, 'width.type') && column.width.type !== 'default';
  if (isWidthCustom) {
    const unit = column.width.units === 'pt' ? '%' : 'px';
    width = `${column.width.amount}${unit}`;
  }

  const style = {
    width,
    'text-align': hasIn(column, 'align') ? column.align : 'left',
  };

  if (Array.isArray(passedRules)) {
    passedRules.forEach((rule) => {
      rule.actions.forEach((action) => {
        switch (action.action) {
          case 'text-color':
            style.color = action.color;
            break;

          case 'text-style':
            if (action.bold) {
              style['font-weight'] = 'bold';
            }

            if (action.italic) {
              style['font-style'] = 'italic';
            }

            if (action.strikethrough) {
              style['text-decoration'] = 'line-through';
            }

            break;

          case 'bg-color':
            style['background-color'] = action.color;
            break;

          default:
            break;
        }
      });
    });
  }

  return style;
};

/**
 * Determines what to show in a table cell.
 *
 * @param {object} props
 * @param {object} props.column
 * @param {object} props.record
 * @param {object} props.field
 * @param {object[]} [props.passedRules]
 * @param {boolean} [props.ignoreLink]
 * @return {object | string} - The display value for a cell.
 */
const getDisplayValue = ({ column, record, field, passedRules, ignoreLink = false }) => {
  if (!column) {
    return '';
  }

  const style = getStyle({ column, passedRules });
  const textColor = hasIn(style, 'color') ? `style="color: ${style.color};"` : '';

  if (column.type === 'action_link') {
    if (isEmpty(column.action_rules)) {
      return '';
    }

    // TODO:ActionLinks: this should be evaluated like a display rule, Eg. checkRule() in V2
    return `<a ${textColor}>${column.action_rules[0].link_text}</a>`;
  }

  // Content is either the field value in the record or link text
  let content = '';

  if (isFieldContent({ column })) {
    const objectKey = get(column, 'field.objectKey') || get(field, 'objectKey');
    const Record = new RecordModel(record, objectKey);

    let columnFieldKey = column.field.key;

    // If the column has a connection, use the key of the connected record
    if (column?.connection?.key) {
      columnFieldKey = `${column.connection.key}.${columnFieldKey}`;
    }

    content = String(Record.getDisplayValue(columnFieldKey)) || '';
  } else {
    content = column.link_text || '';
  }

  if (column.type === 'link' && !ignoreLink) {
    return `<a ${textColor}>${content}</a>`;
  }

  return content;
};

/**
 * Gets the "raw" value of the field.
 *
 * @param {object} props
 * @param {object} props.column
 * @param {object} props.field
 * @param {object} props.record
 * @return {object | string | null} - The field value.
 */
const getFieldValueRaw = ({ column, field, record }) => {
  if (!column?.field || !field) {
    return null;
  }

  const objectKey = get(column, 'field.objectKey') || field.objectKey;
  const Record = new RecordModel(record, objectKey);

  let columnFieldKey = column.field.key;

  // If the column has a connection, use the key of the connected record
  if (column?.connection?.key) {
    columnFieldKey = `${column.connection.key}.${columnFieldKey}`;
  }

  return cloneDeep(Record.getRawValue(columnFieldKey));
};

/**
 * Gets icon information from column settings.
 *
 * @param {object} props
 * @param {object} props.column
 * @param {object} props.passedRules
 * @return {object | undefined} - The icon data.
 */
const getIcon = ({ column, passedRules }) => {
  if (!column) {
    return undefined;
  }

  const getAlignmentProps = (align) => {
    const safeAlign = align || 'left';
    const margin = (safeAlign === 'left') ? 'right' : 'left';

    return {
      align: safeAlign,
      class: `fa-margin-${margin}`,
      [safeAlign]: true,
    };
  };

  const getStyleProps = (color) => ({
    style: {
      color,
    },
  });

  // first check if any passed display rules are providing an icon
  if (!isEmpty(passedRules)) {
    const firstIconAction = passedRules
      .flatMap((rule) => rule.actions)
      .find((action) => action.action === 'icon');

    if (!isNil(firstIconAction)) {
      const { icon: { icon, align }, color } = firstIconAction;

      return {
        icon: icon.includes('fa-') ? icon.split('fa-')[1] : icon,
        ...getStyleProps(color),
        ...getAlignmentProps(align),
      };
    }
  }

  // then check for a column property
  if (column.icon) {
    // set color to use default design settings link color if it exists
    // otherwise set it to an empty string so that display rules can override it
    const textColorRuleExists = passedRules
      .flatMap((rule) => rule.actions)
      .find((action) => action.action === 'text-color');

    const color = isNil(textColorRuleExists) ? appDesignColor() : '';

    return {
      icon: column.icon.icon.includes('fa-') ? column.icon.icon.split('fa-')[1] : column.icon.icon,
      ...getStyleProps(color),
      ...getAlignmentProps(column.icon.align),
    };
  }

  return undefined;
};

/**
 * Gets the key to a page by its scene slug.
 *
 * @param {string} sceneSlug
 * @return {string}
 */
const getLinkPageKey = (sceneSlug) => {
  const page = getPageBySlug(sceneSlug);
  if (!page) {
    return '';
  }

  return page.key;
};

/**
 * Determines if cell data should be hidden based on column display rules.
 *
 * @param {object} props
 * @param {object[]} props.passedRules
 * @return {boolean}
 */
const isHiddenByDisplayRules = ({ passedRules }) => (
  // If any rule has any hidden action, then hide the field.
  passedRules.some(
    (rule) => rule.actions.some(
      (action) => action.action === 'hide',
    ),
  )
);

/**
 * Determines if a link should be displayed to link to a child page.
 *
 * @param {object} props
 * @param {object} props.column
 * @return {boolean}
 */
const isLinkingToChildPage = ({ column }) => column?.type === 'link' || column?.type === 'scene';

/**
 * Determines whether to render a special value or to just render html for the cell data.
 *
 * @param {object} props
 * @param {object} props.column
 * @param {object} props.field
 * @return {boolean}
 */
const isRenderSpecialValue = ({ column, field }) => {
  // links and action links
  if (!field) {
    return true;
  }

  if (SPECIAL_RENDER_FIELD_TYPES.has(String(field.type).toLowerCase())) {
    return true;
  }

  // if this column displays fields from a connected object
  if (column.connection) {
    return true;
  }

  return false;
};

const tableCellIsEditable = ({ column, field }) => {
  if (!field || !field.type) {
    return false;
  }

  if (column.ignore_edit) {
    return false;
  }

  if (column.connection) {
    return false;
  }

  if (field.conditional) {
    return false;
  }

  if (column.read_only) {
    return false;
  }

  const inputType = window.Knack.config.fields?.[field.type]?.input_type;
  if (inputType === 'none' || inputType === 'display') {
    return false;
  }

  return true;
};

/**
 * A connection property exists when the column is displaying fields from connected records.
 * `connection` stores the field key of the connection being used.
 *
 * @param {object} props
 * @param {object} props.column
 * @return {boolean}
 */
const isShowingConnectedValues = ({ column }) => Boolean(column?.connection);

export {
  getDisplayValue,
  getFieldValueRaw,
  getIcon,
  getLinkPageKey,
  getPassedRules,
  getStyle,
  isHiddenByDisplayRules,
  isShowingConnectedValues,
  isLinkingToChildPage,
  isRenderSpecialValue,
  tableCellIsEditable,
};
