<template>
  <section class="emptyState">
    <Icon
      class="emptyState_image"
      :type="image"
    />
    <h2 class="emptyState_title">
      {{ title }}
    </h2>
    <p class="emptyState_description">
      {{ description }}
    </p>
    <!-- Action -->
    <BuilderButton
      class="emptyState_action"
      :theme="actionTheme"
      size="lg"
      @click="triggerAction"
    >
      {{ action }}
    </BuilderButton>
    <!-- Secondary Action -->
    <BuilderButton
      v-if="secondaryAction"
      class="emptyState_secondary"
      theme="link"
      @click="triggerSecondaryAction"
    >
      {{ secondaryAction }}
    </BuilderButton>
  </section>
</template>

<script>
import BuilderButton from '@/components/ui/BuilderButton';
import Icon from '@/components/ui/Icon';

const VALID_ACTION_THEMES = [
  'add',
  'confirm',
  'info',
];

export default {
  name: 'EmptyState',
  components: {
    BuilderButton,
    Icon,
  },
  props: {

    /**
       * A heading for the empty state
       * @property {String}
       */
    title: {
      type: String,
      required: true,
    },

    /**
       * Explain what this empty state means to the customer
       * @property {String}
       */
    description: {
      type: String,
      default: '',
    },

    /**
       * Image to be shown
       * @property {String}
       */
    image: {
      type: String,
      default: '',
    },

    /**
       * Label for the action
       * @property {String}
       */
    action: {
      type: String,
      default: () => {},
    },

    /**
       * Which button theme to use. This is a subset of normal builder button themes
       * @property {String}
       */
    actionTheme: {
      type: String,
      default: 'confirm',
      validator: (value) => VALID_ACTION_THEMES.includes(value),
    },

    /**
      //  * Function that triggers when the action is clicked
      //  * @property {Function}
      //  */
    // actionEvent: {
    //   type: Function,
    //   required: true
    // },

    /**
       * Label for the optional secondary action
       * @property {String}
       */
    secondaryAction: {
      type: String,
      default: '',
    },

    // /**
    //  * Function that triggers when the optional secondary action is clicked
    //  * @property {Function}
    //  */
    // secondaryActionEvent: {
    //   type: Function,
    //   default: () => {}
    // }
  },
  emits: ['action', 'secondary-action'],
  methods: {
    triggerAction() {
      this.$emit('action');
    },
    triggerSecondaryAction() {
      this.$emit('secondary-action');
    },
  },
};
</script>
