<template>
  <div class="inputGroup">
    <ConfirmModal
      v-if="showConfirmModal"
      key="secure-field-modal"
      title="Secure this file field"
      confirm-text="Secure Field"
      :z-index="2"
      @close="cancelSecureFile()"
      @confirm="fileWasSecure = true"
    >
      <p>
        New secured links for downloading files will be generated in your app.
        Previous links accessed in the URL will no longer be accessible.
      </p>

      <p class="mb-0">
        <a
          href="https://learn.knack.com/article/13eszen598-field-types#file_fields"
          class="underline link-with-icon link-with-icon--right text-default"
          target="_blank"
        >Learn more
          <Icon
            type="open-in-new"
            class="link-with-icon__icon ml-2"
          />
        </a>
      </p>
    </ConfirmModal>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Secure</label>
      <div>
        <select
          v-model="fieldLocal.format.secure"
          class="text-base py-2 pl-3 leading-5"
        >
          <option :value="false">
            No
          </option>
          <option :value="true">
            Yes
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmModal from '@/components/ui/ConfirmModal';
import Icon from '@/components/ui/Icon';

export default {
  name: 'File',
  components: {
    ConfirmModal,
    Icon,
  },
  props: {
    fieldLocal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fileWasSecure: this.fieldLocal.format.secure,
    };
  },
  computed: {
    showConfirmModal() {
      return this.fieldLocal.format.secure && !this.fileWasSecure;
    },
  },
  methods: {
    cancelSecureFile() {
      this.fieldLocal.format.secure = false;
    },
  },
};
</script>
