<template>
  <Modal
    title="Change Action"
    :back="backLink"
  >
    <template
      v-for="(step, index) in wizardSteps"
      :key="index"
    >
      <component
        :is="step"
        v-model:wizard-step="currentWizardStep"
        :step="index + 1"
        :view="view"
        :page="page"
        @next="nextWizardStep"
        @submit="updateView"
      />
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';
import ViewAddPaymentTotalField from '@/components/pages/page/ViewAddPaymentTotalField';
import ViewAddPaymentProcessors from '@/components/pages/page/ViewAddPaymentProcessors';
import ViewAddPaymentMethod from '@/components/pages/page/ViewAddPaymentMethod';
import ViewAddPaymentConfirm from '@/components/pages/page/ViewAddPaymentConfirm';
import ViewUtils from '@/components/views/ViewUtils';

const DEFAULT_WIZARD_STEPS = [
  ViewAddPaymentTotalField,
  ViewAddPaymentProcessors,
  ViewAddPaymentMethod,
  ViewAddPaymentConfirm,
];

const CUSTOMER_WIZARD_STEPS = [
  ViewAddPaymentProcessors,
  ViewAddPaymentMethod,
  ViewAddPaymentConfirm,
];

export default {
  name: 'ChangeAction',
  components: {
    Modal,
    ViewAddPaymentTotalField,
    ViewAddPaymentProcessors,
    ViewAddPaymentMethod,
    ViewAddPaymentConfirm,
  },
  mixins: [
    ViewUtils,
  ],
  props: {
    backLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentWizardStep: 1,
    };
  },
  computed: {
    wizardSteps() {
      return this.view.type === 'customer' ? CUSTOMER_WIZARD_STEPS : DEFAULT_WIZARD_STEPS;
    },
  },
  methods: {
    nextWizardStep() {
      this.currentWizardStep++;
    },
    updateView() {
      this.$router.push(this.backLink);
    },
  },
};
</script>
