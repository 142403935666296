<template>
  <div>
    <!-- Login Views -->
    <ConfirmModal
      v-if="isLoginView"
      title="Remove this login?"
      confirm-type="alert"
      :back="backLink"
      confirm-text="Remove Login"
      @confirm="onClickRemoveLogin"
      @close="onClose"
    >
      <p>
        This page and all child pages will no longer be protected by a login.
      </p>

      <p>
        Any views connected to the logged-in user will also be removed.
      </p>

      <p class="mb-0">
        <strong>This can't be undone!</strong>
      </p>
    </ConfirmModal>

    <ConfirmModal
      v-else
      confirm-type="alert"
      confirm-text="Delete View"
      :title="`Delete this ${viewTypeName} view?`"
      :back="backLink"
      @confirm="onClickDeleteView"
      @close="onClose"
    >
      <!-- Menu Views with Links and Child pages -->
      <template v-if="isMenuViewWithChildPages">
        <p>
          You'll also be deleting any Pages and Views this links to.
        </p>
        <p class="mb-0">
          <strong>Once the view changes are saved, this can't be undone!</strong>
        </p>
      </template>

      <!-- All Other Views -->
      <template v-else>
        <p>
          This view will be permanently removed.
        </p>

        <p class="mb-0">
          <strong>This can't be undone!</strong>
        </p>
      </template>
    </ConfirmModal>
  </div>
</template>
<script>
import PageUtils from '@/components/pages/PageUtils';
import ConfirmModal from '@/components/ui/ConfirmModal';
import RequestUtils from '@/components/util/RequestUtils';
import ViewUtils from '@/components/views/ViewUtils';
import { logEvent } from '@/lib/segment-helper';

export default {
  components: {
    ConfirmModal,
  },
  mixins: [
    RequestUtils,
    ViewUtils,
    PageUtils,
  ],
  props: {
    backLink: {
      type: String,
      default: '',
    },
  },
  computed: {
    isLoginView() {
      return this.view.type === 'login';
    },
    viewTypeName() {
      let viewName;

      switch (this.view.type) {
        case 'rich_text':
          viewName = 'Rich Text';
          break;
        case 'checkout':
          viewName = 'Payment';
          break;
        case 'customer':
          viewName = 'Payment Method';
          break;
        case 'table':
          viewName = 'Grid';
          break;
        default:
          viewName = this.view.type;
      }

      const capitalized = viewName.charAt(0).toUpperCase() + viewName.slice(1);
      return capitalized;
    },
    isMenuViewWithChildPages() {
      return this.view.type === 'menu' && this.page.children.length > 0 && this.hasLinkedChildPage(this.view.links, this.page.children);
    },
  },
  methods: {
    onClose() {
      return this.$router.push(this.backLink);
    },
    onClickRemoveLogin() {
      /** @type {Page} page */
      const { page } = this;
      const childPage = page.children[0] || { key: '' };
      const pageViews = page.views.map((view) => view.raw());

      this.commitRequest({
        request: () => window.Knack.Api.updatePageLoginSettings(page.key, pageViews, false),
        onSuccess: async () => {
          await logEvent('remove_login_from_page', {
            page_name: page.name,
            page_id: page.key,
          });

          this.$router.push(`/pages/${childPage.key}`);
        },
      });
    },
    onClickDeleteView() {
      const { view } = this;

      this.commitRequest({
        request: () => window.Knack.Api.deleteView(view.pageKey, view.key),
        onSuccess: () => {
          this.refreshActivePage(view.pageKey);
          this.$router.push(`/pages/${this.page.key}`);
        },
      });
    },
    hasLinkedChildPage(menuViewLinks, pageChildren) {
      const sceneSet = new Set(menuViewLinks.map((link) => link.scene));

      return pageChildren.some((childPage) => sceneSet.has(childPage.slug));
    },
  },
};
</script>
