<template>
  <ViewToolbox
    back-title="Search Menu"
    :back-link="`/pages/${page.key}/views/${view.key}/search`"
    title="Search Inputs"
  >
    <Toggle
      :is-open="true"
      class="no-top-styles"
    >
      <template #title>
        <span>Label Format</span>
      </template>
      <template #content>
        <div class="mb-0 pb-6 border border-solid border-subtle border-x-0 border-t-0">
          <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label">Label Format</label>
          <LabelFormat
            :ignore-hide-option="true"
            :model-value="viewRaw"
            @update-property:model-value="onUpdateViewProperty"
          />
        </div>
      </template>
    </Toggle>
    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Search Inputs</span>
      </template>
      <template #content>
        <AddItems
          view-type="form"
          :view="view"
          :object="object"
        >
          <p class="text-sm leading-5">
            Drag or click options below to add search inputs.
          </p>
        </AddItems>
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import AddItems from '@/components/views/AddItems';
import LabelFormat from '@/components/views/shared/settings/LabelFormat';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'SearchResultsListBuild',
  components: {
    AddItems,
    LabelFormat,
    Toggle,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
};
</script>
