<template>
  <Modal
    title="Require User Logins"
    :back="backLink"
    size="small"
  >
    <div class="body">
      <template v-if="!isUsersEnabled">
        <p>User logins are currently <b>not active</b>. Activate to:</p>
        <ul class="ul-basic">
          <li>require <strong>logins</strong> to view your app</li>
          <li><strong>connect</strong> records to specific users</li>
          <li>create different permissions with <strong>user roles</strong></li>
        </ul>
        <p>
          <router-link
            id="close-user-settings"
            to="/settings/users"
            class="underline"
          >
            Click to activate user logins
          </router-link>
        </p>
      </template>

      <template v-else>
        <p>A new parent page with a login view will be added.</p>

        <form v-if="showRoleAccess">
          <div>
            <label>
              Which logged-in users can access this page?
            </label>
            <div class="input-labels tw-input-labels">
              <label><input
                v-model="limit_profile_access"
                type="radio"
                :value="false"
              >Give permission to all users</label>
              <label><input
                v-model="limit_profile_access"
                type="radio"
                :value="true"
              >Limit permissions to specific user roles</label>
            </div>

            <div
              v-if="limit_profile_access"
              id="quickstart-permissions"
              data-cy="quickstart-permissions-picker"
              class="input-labels"
              style="margin-top: .5em;"
            >
              <MultipleChoice
                v-model="allowed_profiles"
                :input="{format: {type: `multi`, options: roleObjectOptions}}"
              />
            </div>
          </div>
        </form>

        <div class="submit-buttons flex justify-end pt-0">
          <SubmitButton
            classes="button save rounded-lg"
            button-text="Add a login"
            :is-loading="submitIsLoading"
            @submit="onSubmit"
          />
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RequestUtils from '@/components/util/RequestUtils';
import Modal from '@/components/ui/Modal';
import SubmitButton from '@/components/renderer/shared/SubmitButton';
import MultipleChoice from '@/components/renderer/form/inputs/MultipleChoice';
import { logEvent } from '@/lib/segment-helper';

export default {
  components: {
    Modal,
    SubmitButton,
    MultipleChoice,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    backLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      submitIsLoading: false,
      limit_profile_access: false,
      allowed_profiles: [],
    };
  },
  computed: {
    ...mapGetters([
      'isUsersEnabled',
      'roleObjects',
    ]),
    showRoleAccess() {
      return this.roleObjects.length > 0;
    },

    roleObjectOptions() {
      return this.roleObjects.map((roleObject) => ({
        label: roleObject.name,
        value: roleObject.get('profile_key'),
      }));
    },
  },
  methods: {
    ...mapActions('page/navVisibility', [
      'showNavChildren',
    ]),
    onSubmit() {
      const { activePage } = this.$store.getters;
      const pageKey = activePage.key;

      const pageViews = activePage.views.map((view) => view.raw());

      this.submitIsLoading = true;

      this.commitRequest({
        request: () => window.Knack.Api.updatePageLoginSettings(pageKey, pageViews, true, this.limit_profile_access, this.allowed_profiles),
        globalLoading: false,
        onSuccess: async () => {
          await logEvent('add_login_to_page', {
            page_name: activePage.name,
            page_id: activePage.key,
          });

          this.submitIsLoading = false;

          if (activePage.parent) {
            await this.showNavChildren(activePage.parent.key);
          }

          // Reload the active page.
          // This fixes a reactivity issue when from the Page Access Info Card add login flow.
          await this.$store.dispatch('setActivePageByKey', { pageKey });

          this.$router.push(`/pages/${pageKey}`);
        },
      });
    },
  },
};
</script>
