<template>
  <Ribbon>
    <div class="ribbon_mainRow border-none h-auto p-6">
      <h2 class="ribbonName p-0 mr-12">
        <Icon
          type="settings-api-and-code-outline"
          class="ribbonName_icon h-8 w-8 mr-2 text-subtle"
        />
        <span class="ribbonName_title text-emphasis text-xl font-medium">API & Code</span>
      </h2>

      <div class="tabMenu">
        <RouterLink
          v-for="link in links"
          :key="link.path"
          :to="`/settings/api/${link.path}`"
          class="tabLink flex justify-center items-center rounded-lg m-0 mr-2 p-2 h-8"
          :class="[
            {'is-active bg-brand-100': link.path === $route.name},
            {'hover:bg-brand-50': link.path !== $route.name},
          ]"
        >
          <span class="tabLink_content m-0">
            <span
              class="tabLink_textLabel capitalize text-base text-default font-normal"
              :class="{ 'text-emphasis': link.path === $route.name }"
            >
              {{ link.label }}
            </span>
          </span>
        </RouterLink>
      </div>
    </div>
  </Ribbon>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Ribbon from '@/components/layout/Ribbon';

export default {
  components: {
    Ribbon,
    Icon,
  },
  data() {
    return {
      links: [
        {
          path: 'api',
          label: 'API',
        },
        {
          path: 'javascript',
          label: 'JavaScript',
        },
        {
          path: 'css',
          label: 'CSS',
        },
      ],
      isReskin: false,
    };
  },
};
</script>
