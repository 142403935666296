import Field from '@/components/fields/Field';
import FieldAddSettings from '@/components/fields/FieldAddSettings';
import FieldDelete from '@/components/fields/FieldDelete';
import FieldPerformance from '@/components/fields/FieldPerformance';
import FieldRulesValidation from '@/components/fields/FieldRulesValidation';
import FieldRulesConditional from '@/components/fields/FieldRulesConditional';
import FieldSettings from '@/components/fields/FieldSettings';
import FieldTypeEdit from '@/components/fields/FieldTypeEdit/FieldTypeEdit';

export default [
  {
    path: 'fields/:fieldKey',
    components: {
      modal: Field,
    },
    children: [
      {
        path: 'settings',
        component: FieldSettings,
        meta: {
          addQueries: true,
        },
      },
      {
        path: 'rules/validation',
        component: FieldRulesValidation,
        meta: {
          addQueries: true,
        },
      },
      {
        path: 'rules/conditional',
        component: FieldRulesConditional,
        meta: {
          addQueries: true,
        },
      },
    ],
  },
  {
    path: 'fields/:fieldKey/type',
    components: {
      modal: FieldTypeEdit,
    },
    meta: {
      addQueries: true,
    },
  },
  {
    path: 'fields/:fieldKey/performance',
    components: {
      modal: FieldPerformance,
    },
    meta: {
      addQueries: true,
    },
  },
  {
    path: 'fields/:fieldKey/delete',
    components: {
      modal: FieldDelete,
    },
  },
  {
    path: 'fields/add/index/:insertIndex',
    components: {
      modal: FieldAddSettings,
    },
    props: {
      modal: true,
    },
  },
];
