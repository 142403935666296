<script setup>
import { computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import indefinite from 'indefinite';

import Icon from '@/components/ui/Icon';
import EmptyStateGeneric from '@/components/ui/EmptyStateGeneric';
import BuilderButton from '@/components/ui/BuilderButton';

const router = useRouter();

const props = defineProps({
  object: {
    type: Object,
    required: true,
  },
});

const objectName = computed(() => String(props.object.inflections.singular));

const triggerAddTaskModal = () => router.push(`/tasks/objects/${props.object.key}/add/task`);
</script>

<template>
  <EmptyStateGeneric
    id="empty-task-instructions"
    top
  >
    <div
      class="mb-5"
    >
      <Icon
        type="action-task"
        class="h-auto w-[90px] fill-[url(#svg-brand-gradient)] opacity-40"
      />
    </div>

    <h2 class="empty-state__title margin-bottom-lg text-xl text-emphasis font-medium mb-2">
      {{ `Create ${indefinite(objectName)} task` }}
    </h2>

    <p class="empty-state__paragraph text-default mb-6">
      Boost your app with automated tasks to send customized emails and update records dynamically
    </p>

    <BuilderButton
      theme="confirm"
      size="lg"
      class="mb-6"
      @click="triggerAddTaskModal"
    >
      <icon type="add" /> Add a Task
    </BuilderButton>

    <p
      class="mb-0"
    >
      <a
        href="https://learn.knack.com/article/hj38ebv4di-scheduled-tasks"
        target="_blank"
        class="flex items-center justify-center underline text-default text-base font-medium"
      >
        Learn more about tasks
        <Icon
          type="open-in-new"
          class="link-with-icon__icon ml-2 text-emphasis"
        />
      </a>
    </p>
  </EmptyStateGeneric>
</template>
