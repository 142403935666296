<template>
  <template v-if="hasConnections">
    <label
      class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label"
      style="font-weight: 500;"
    >
      Restrict Connections
    </label>
    <div>
      <div v-if="!showSourceOptions">
        <a
          class="button fuchsia-gray tiny p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium capitalize truncate mb-2"
          @click="onShowSourceOptionsClick"
        >
          <Icon
            class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
            type="plus-thin"
          />
          Choose which records to show
        </a>
      </div>
      <div v-else>
        <template
          v-for="connectionField in connectionFields"
          :key="connectionField.key"
        >
          <FiltersConnectionSource
            :connection-field="connectionField"
            :connection-source="localConnectionSources[connectionField.key]"
            @update:connection-source="onUpdateConnectionSource(connectionField.key, $event)"
          />
        </template>
        <a
          class="button fuchsia-gray tiny p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium capitalize mb-2"
          @click="onClickResetSources"
        >
          Clear restrictions
        </a>
      </div>
    </div>
  </template>
</template>

<script>
import { isEmpty, omit, size } from 'lodash';
import ViewUtils from '@/components/views/ViewUtils';
import Icon from '@/components/ui/Icon';
import FiltersConnectionSource from '@/components/views/shared/settings/FiltersConnectionSource';

export default {
  components: {
    FiltersConnectionSource,
    Icon,
  },
  mixins: [
    ViewUtils,
  ],
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    filterFieldsType: {
      type: String,
      default: 'view',
      validator(value) {
        return ['view', 'object'].includes(value);
      },
    },
    connectionSources: {
      type: Object,
      default: () => undefined,
    },
  },
  emits: [
    'update:connectionSources',
  ],
  data() {
    return {
      showSourceOptions: Boolean(this.connectionSources),
    };
  },

  computed: {
    // Fields available for filters can be every field in the object
    // or just fields that have been added to the view
    filterFields() {
      // Every field in the object
      if (this.filterFieldsType === 'object') {
        return this.object?.fields || [];
      }

      // Every field that has been added to the view
      const metaCollection = 'data'; // data is an alias for fields that manage data
      return this.view.getFields(metaCollection).map((field) => this.getField(field.key));
    },
    localConnectionSources() {
      return this.connectionSources || {};
    },
    connectionFields() {
      return this.filterFields.filter((field) => field.isConnection());
    },
    hasConnections() {
      return this.connectionFields.length > 0;
    },
  },
  watch: {
    // Watch if the filterFieldsType prop has changed, so we can trigger a connection source update
    filterFieldsType: function (newFilterFieldsType) {
      if (this.localConnectionSources && !isEmpty(this.localConnectionSources)) {
        let newConnectionSources = {};

        this.connectionFields.forEach((connectionField) => {
          // If the field already exists in the connection sources object, keep it
          if (connectionField.attributes.key in this.localConnectionSources) {
            newConnectionSources = {
              ...newConnectionSources,
              [connectionField.attributes.key]: {
                ...this.localConnectionSources[connectionField.attributes.key],
              },
            };

          // Otherwise, if the filterFieldsType is 'object' it means all the object's fields can be filters,
          // so we add them to the connection fields object with default values
          } else if (newFilterFieldsType === 'object') {
            newConnectionSources = {
              ...newConnectionSources,
              [connectionField.attributes.key]: this.createConnectionSource({ connection_key: '' }, []),
            };
          }
        });

        this.triggerUpdateConnectionSources(newConnectionSources);
      }
    },
  },
  methods: {
    onClickResetSources() {
      this.triggerUpdateConnectionSources(null);

      // Hide modal
      this.showSourceOptions = false;
    },

    onShowSourceOptionsClick() {
      // If choosing to restrict connections and there are no existing connection sources,
      // generate default connection sources from existing connection fields
      if ((!this.localConnectionSources || isEmpty(this.localConnectionSources)) && this.hasConnections) {
        let newConnectionSources = {};

        this.connectionFields.forEach((connectionField) => {
          newConnectionSources = {
            ...newConnectionSources,
            [connectionField.attributes.key]: this.createConnectionSource({ connection_key: '' }, []),
          };
        });

        this.triggerUpdateConnectionSources(newConnectionSources);
      }

      this.showSourceOptions = true;
    },

    onUpdateConnectionSource(connectionFieldKey, { source, filters }) {
      let newConnectionSources;

      if (!source && !filters) {
        // If the source and filters are undefined, then remove the key altogether.
        newConnectionSources = omit(this.localConnectionSources, connectionFieldKey);
      } else {
        newConnectionSources = {
          ...this.localConnectionSources,
          [connectionFieldKey]: this.createConnectionSource(source, filters),
        };
      }

      this.triggerUpdateConnectionSources(newConnectionSources);
    },

    /**
     * Defines the connection source schema structure.
     *
     * @param {object} source
     * @param {array} filters
     * @returns {object}
     */
    createConnectionSource(source, filters) {
      return {
        source,
        filters,
      };
    },

    /**
     * Triggers the update connection sources event.
     *
     * @param {object | undefined} newConnectionSources
     */
    triggerUpdateConnectionSources(newConnectionSources) {
      // If the connection sources object is empty, then change it to null.
      if (!size(newConnectionSources)) {
        this.$emit('update:connectionSources', null);
        return;
      }

      this.$emit('update:connectionSources', newConnectionSources);
    },
  },
};
</script>
