<template>
  <div class="level-left">
    <Popover placement="bottom-start">
      <template #trigger>
        <a
          v-tippy
          content="Choose which data this page will preview"
          :class="classes.trigger"
          data-cy="page-data-source-trigger"
        >
          <Icon
            type="database-records"
            :class="classes.triggerIcon"
          />
          <span :class="classes.triggerText">{{ selectedPreviewValue }}</span>
          <Icon
            class="expand"
            :class="classes.triggerArrow"
            type="arrow-drop-down"
            style="margin-right: -2px;"
          />
        </a>
      </template>

      <template #content>
        <div
          id="page-data-source-dropdown"
          class="is-padded"
        >
          <h3 class="text-base text-emphasis">Page Preview</h3>
          <p
            id="page-preview-source-copy"
            class="text-default text-base border-b-gray-50"
          >
            What data should be used to preview your page views?
          </p>
          <form>
            <div class="input-labels tw-input-labels mb-0">
              <label
                v-close-popper
                class="text-emphasis text-base leading-4 items-center mb-1 font-normal"
              >
                <input
                  v-model="pagePreviewType"
                  type="radio"
                  name="data_source"
                  value="live"
                  data-cy="page-data-source-live"
                >
                <b>Live data</b>
              </label>
              <div class="margin-bottom">
                <em class="text-default">From your database.</em>
              </div>
              <label
                v-close-popper
                class="text-emphasis text-base leading-4 items-center mb-1 font-normal"
              >
                <input
                  v-model="pagePreviewType"
                  type="radio"
                  name="data_source"
                  value="sample"
                  data-cy="page-data-source-sample"
                >
                <b>Sample data</b>
              </label>
              <div class="margin-bottom-half">
                <em class="text-default">This will be faster since no live data needs to load, but a less accurate preview.</em>
              </div>
            </div>
          </form>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Popover from '@/components/ui/Popover';
import Icon from '@/components/ui/Icon';
import RequestUtils from '@/components/util/RequestUtils';
import { mapApiFormGetters } from '@/lib/vuex-helper';

export default {
  name: 'PageDataSourcePopover',
  components: {
    Popover,
    Icon,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    classes: {
      type: Object,
      default: () => ({
        trigger: 'iconLink',
        triggerIcon: 'iconLink_icon',
        triggerText: '',
      }),
    },
  },
  data() {
    return {
      connections: {},
    };
  },
  computed: {
    ...mapGetters([
      'activePage',
      'pages',
      'getObject',
    ]),
    pagePreviewType: {
      get() {
        return this.$store.getters.pagePreviewType;
      },
      set(newVal) {
        this.$store.commit('setPagePreviewType', newVal);
      },
    },
    selectedPreviewValue() {
      const previewValues = {
        sample: 'Using sample data',
        live: 'Using live data',
        none: 'Not showing data',
      };

      return previewValues[this.pagePreviewType];
    },
  },
};
</script>

<style lang="scss">
#page-data-source-dropdown {
  max-width: 400px;

  em {
    font-size: .95em;
  }

  h3 {
    margin-bottom: .25em;
  }
}

#page-preview-source-copy {
  border-bottom: 1px solid $gray100;
  margin-bottom: 1em;
  font-size: 1em;
  padding-bottom: 1em;

}
</style>
