import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'getObject',
    ]),
    sourceObject() {
      return this.getObject(this.view.source.object);
    },
    fields() {
      if (_.isNil(this.sourceObject)) {
        return [];
      }

      return this.sourceObject.fields;
    },
    numericFields() {
      if (_.isNil(this.sourceObject)) {
        return [];
      }

      return this.sourceObject.getEcommerceFields();
    },
    paymentProcessors() {
      return this.$store.state.app.get('payment_processors');
    },
    hasPayPalPaymentMethod() {
      if (_.isEmpty(this.view.attributes.payment_processors)) {
        return false;
      }

      return this.view.attributes.payment_processors.some((viewPaymentProcessor) => {
        viewPaymentProcessor = this.getPaymentProcessor(viewPaymentProcessor.payment_processor_key || viewPaymentProcessor.key);

        return !_.isNil(viewPaymentProcessor) ? viewPaymentProcessor.processor === 'paypal' : false;
      });
    },
    hasStripePaymentMethod() {
      if (_.isEmpty(this.view.attributes.payment_processors)) {
        return false;
      }

      return this.view.attributes.payment_processors.some((viewPaymentProcessor) => {
        viewPaymentProcessor = this.getPaymentProcessor(viewPaymentProcessor.payment_processor_key || viewPaymentProcessor.key);

        return !_.isNil(viewPaymentProcessor) ? viewPaymentProcessor.processor === 'stripe' : false;
      });
    },
    chargeName() {
      if (_.isNil(this.sourceObject)) {
        return '';
      }

      // the source object can be charges
      if (this.sourceObject.get('ecommerce') === true) {
        return this.sourceObject.name;
      }

      const paymentConnection = _.find(this.sourceObject.conns, (connection) => this.getObject(connection.object).get('ecommerce') === true);

      return paymentConnection.name;
    },
  },
  methods: {
    getPaymentProcessor(key) {
      return _.find(this.paymentProcessors, {
        key,
      });
    },
    onUpdateProcessor(key) {
      // Don't allow multiple processors of the same type to be selected

      // Deselected
      if (!this.selectedPaymentProcessors.includes(key)) {
        return;
      }

      const selectedPaymentProcessors = _.without(this.selectedPaymentProcessors, key).map(this.getPaymentProcessor);

      // No other processors selected
      if (_.isEmpty(selectedPaymentProcessors)) {
        return;
      }

      const matchingProcessor = _.find(selectedPaymentProcessors, {
        processor: this.getPaymentProcessor(key).processor,
      });

      // No duplicate processor selected
      if (_.isNil(matchingProcessor)) {
        return;
      }

      // Deselect duplicate processor
      this.selectedPaymentProcessors = _.without(this.selectedPaymentProcessors, matchingProcessor.key);
    },
  },
};
