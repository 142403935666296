<template>
  <div class="kn-section-break">
    <h3
      class="kn-title"
      v-html="input.label"
    />
    <div
      class="kn-description"
      v-html="input.copy"
    />
  </div>
</template>

<script>
export default {
  name: 'CopyStatic',
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
