import { mapGetters } from 'vuex';

export default {
  watch: {
    'view.design': {
      handler() {
        this.app.buildDesignStyleSheet();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
  },
};
