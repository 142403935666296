import Joi from '@hapi/joi';
import merge from 'lodash/merge';
import reject from 'lodash/reject';

import {
  generateDesignStylesFromApplicationSchema,
  convertV2Schema,
  v3DesignSchemaDefault,
  getDesignSchemaVersion,
  sanitizeV3DesignSettings,
} from '@knack/style-engine';

import Api from '@/lib/api-wrapper';
import { validate } from '@/lib/validation-helper';
import store from '@/store/index';
import Embed from '@/store/models/Embed';

class Application {
  constructor(app) {
    if (app instanceof Application) {
      this.attributes = app.attributes;

      return;
    }

    this.attributes = {};

    // If this is a legacy design we need to convert to V3-compatible
    const hasDesign = app.hasOwnProperty('design');
    const hasV3Design = hasDesign && getDesignSchemaVersion(app) === 'v3';

    if (!hasV3Design) {
      app = convertV2Schema(app);
    }

    this.setApplication(app);

    // If we have a v2 design or don't have any design at all save a v3 design schema to the application
    if (!hasV3Design || !hasDesign) {
      this.update(app);
    }
  }

  setApplication(app) {
    // Make sure objects and scenes are not stored in this object to prevent Vue observing overhead.
    app = {
      ...this.attributes,
      ...app,
      objects: undefined,
      scenes: undefined,
    };

    app.settings = { ...this.getSettingsDefaults(), ...app.settings };
    app.users = { ...this.getUserDefaults(), ...app.users };
    // The design object in the schema is set in the Server when the app is created,
    // that means that for new applications the default design and app.design will be equal.
    app.design = merge({}, v3DesignSchemaDefault, app.design);

    // Sanitize the design settings
    app.design = sanitizeV3DesignSettings(app.design);

    // In order for this property to be reactive it needs to exist on init
    app.logo_url = app.logo_url || '';

    app.distributions = app.distributions.map((distribution) => new Embed(distribution));

    this.attributes = app;
  }

  get name() {
    return this.attributes.name;
  }

  set name(newValue) {
    this.attributes.name = newValue;
  }

  get slug() {
    return this.attributes.slug;
  }

  set slug(newValue) {
    this.attributes.slug = newValue;
  }

  get fromEmail() {
    return this.attributes.settings.from_email;
  }

  set fromEmail(newValue) {
    this.attributes.settings.from_email = newValue;
  }

  get timezone() {
    return this.attributes.settings.timezone;
  }

  set timezone(newValue) {
    this.attributes.settings.timezone = newValue;
  }

  get timezoneDst() {
    return this.attributes.settings.timezone_dst;
  }

  set timezoneDst(newValue) {
    this.attributes.settings.timezone_dst = newValue;
  }

  get language() {
    return this.attributes.settings.language;
  }

  set language(newValue) {
    this.attributes.settings.language = newValue;
  }

  get settings() {
    return this.attributes.settings;
  }

  get logo() {
    return this.attributes.logo_url;
  }

  set logo(newValue) {
    this.attributes.logo_url = newValue;
  }

  get users() {
    return this.attributes.users;
  }

  set users(newValue) {
    this.attributes.users = newValue;
  }

  get design() {
    return this.attributes.design;
  }

  set design(newValue) {
    this.attributes.design = newValue;
  }

  get layout() {
    return this.attributes.layout;
  }

  set layout(newValue) {
    this.attributes.layout = newValue;
  }

  get distributions() {
    return this.attributes.distributions;
  }

  get account() {
    return this.attributes.account;
  }

  /**
   * Gets the level of the theme that maps to certain features
   * Level 1: Original legacy themes of flat or basic
   * Level 2: kn-beta
   * Level 3: upcoming new v3 theme
   *
   * @returns {number}
   */
  getThemeLevel() {
    if (['flat', 'basic'].includes(this.design.general.theme)) {
      return 1;
    }

    return 2;
  }

  getAccountStatus() {
    return this.account.status || 'beta';
  }

  getBetaDeadline() {
    return this.account.beta_deadline;
  }

  isSharedBuilder() {
    return this.account.user_id !== window.Knack.Api.user_id;
  }

  isFrozen() {
    return this.account.status === 'frozen';
  }

  isSuspended() {
    return this.account.status === 'pending' && this.account.billing.status === 'delinquent' && this.account.billing.notice_count > 29;
  }

  isTrialExpired() {
    return this.account.status === 'pending' && !this.account.billing.has_paid && this.getDaysLeftInTrial() < 0;
  }

  isTrialing() {
    if (!this.getAccountStatus()) {
      return false;
    }

    if (this.getAccountStatus() !== 'beta') {
      return false;
    }

    if (!this.getBetaDeadline()) {
      return false;
    }

    return true;
  }

  isSQL() {
    return Boolean(this.settings?.sql);
  }

  getDaysLeftInTrial() {
    const deadline = new Date(this.getBetaDeadline());
    const today = new Date();
    const timeLeft = (deadline.getTime() - today.getTime()) / (24 * 60 * 60 * 1000);
    let daysLeft = Math.floor(timeLeft);

    if (today.getHours() / 24 + (timeLeft - daysLeft) > 1) {
      daysLeft++;
    }

    return daysLeft;
  }

  usersAreEnabled() {
    return this.users.enabled;
  }

  get pagesAreProtectedWithSingleLogin() {
    if (!this.usersAreEnabled()) {
      return false;
    }

    if (!this.users.scope || this.users.scope !== 'application') {
      return false;
    }

    return true;
  }

  paymentsAreEnabled() {
    // TODO: figure this out
    return false;
  }

  get paymentProcessors() {
    return this.attributes.payment_processors;
  }

  set paymentProcessors(newValue) {
    this.attributes.payment_processors = newValue;
  }

  resetDesignDefaults(app) {
    log('v3DesignSchemaDefault', v3DesignSchemaDefault, app.layout, app.design);

    return { ...v3DesignSchemaDefault };
  }

  getSettingsDefaults() {
    return {
      password_options: {
        password_require_expiration: false,
        password_require_no_reuse: false,
        password_require_no_reuse_message: '',
      },
      inactivity_timeout: '1',
      ip_whitelist: '',
      lockout_options: {
        lockout_enforced: false,
        lockout_failed_attempts: 3,
        lockout_attempt_window: 15,
        lockout_length: 15,
        lockout_message: '',
        lockout_password_reset: false,
        lockout_user_email: false,
        lockout_user_email_message: '',
      },
      embed_login_method: 'cookies',
    };
  }

  getUserDefaults() {
    return {
      enabled: false,
      scope: '',
      registration: '',
    };
  }

  buildDesignStyleSheet() {
    const views = store.state.pages.all.map((page) => page.views).flat();

    generateDesignStylesFromApplicationSchema(this.raw(), views, 'v3', true);
  }

  async resetApplicationDesignSettings() {
    this.design = { ...this.resetDesignDefaults(this) };

    const response = await this.updateApplication();

    return response;
  }

  // TODO: add endpoint to handle updating all views on the serverside
  async resetAllViewsDesignSettings() {
    const views = store.state.pages.all.map((page) => page.views).flat();

    const responses = await Promise.all(views.map(async (view) => {
      view.design = {};

      await view.save();
    }));

    // return response of last view update (would be better to merge all responses)
    return responses.pop();
  }

  getDesignDefaults(viewType) {
    return this.get('design')[viewType] || {};
  }

  raw() {
    return this.attributes;
  }

  get(property) {
    return this.attributes[property];
  }

  hasFeatureFlagGeo() {
    const appSettings = this.settings || {};

    const plan = this.get('account').product_plan || {
      level: 1,
      id: 'trial',
    };

    return (appSettings.geo || plan.level > 1 || plan.id === 'trial');
  }

  validate(validateAttributes = {}) {
    const toValidate = { ...this.attributes, ...validateAttributes };

    const validationSchema = Joi.object({
      name: Joi.string().empty('').required(),
      slug: Joi.string().empty('').required(),
    }).unknown();

    const validationOptions = {
      abortEarly: false,
      allowUnknown: true,
    };

    const { error } = Joi.validate(toValidate, validationSchema, validationOptions);

    return {
      error,
      errorMap: {
        'error.name.any.required': 'Give your app a name',
        'error.slug.any.required': 'Give your app a URL',
      },
    };
  }

  validatePageCount() {
    const validationOptions = {
      abortEarly: false,
      allowUnknown: true,
    };

    // Count all the non-user start pages.
    const pageCount = store.getters['page/mainStartPages'].length;

    const { error } = Joi.validate({
      pageCount,
    }, {
      pageCount: Joi.number().greater(0),
    }, validationOptions);

    return {
      error,
      errorMap: {
        'error.pageCount.number.greater': 'You need at least one page! Add a new page first if you want to delete this one.',
      },
    };
  }

  /**
   * Validates new or updated payment processor settings against a validation schema.
   *
   * @param {object} processor
   * @param {object} options
   * @param {boolean} options.isNewProcessor - set if adding a new processor
   * @returns {{errors: object[]}}
   */
  validatePaymentProcessor(processor, options = {
    isNewProcessor: false,
  }) {
    // prevents creation of multiple processors with the same name
    const invalidProcessorNames = options.isNewProcessor
      ? this.paymentProcessors.map((paymentProcessor) => paymentProcessor.name)
      : [];

    const validationSchema = Joi.object({
      name: Joi.string()
        .invalid(invalidProcessorNames)
        .empty('')
        .required(),
    }).unknown();

    const errorMap = {
      name: {
        types: {
          'any.invalid': 'There is already a payment processor with this name.',
        },
      },
    };

    return validate(processor, validationSchema, errorMap);
  }

  async reorderObjects(objects) {
    const response = await window.Knack.Api.updateObjectsSort(objects);

    return response;
  }

  // TODO: convert all async calls that call window.Knack.Api.updateApplication to simply use this.save()
  async enableUsers() {
    const settings = {
      users: {
        enabled: true,
        scope: 'scene',
        registration: 'closed',
      },
    };

    const response = await window.Knack.Api.updateApplication(settings);

    if (response.changes && response.changes.inserts && response.changes.inserts.objects.length > 0) {
      // can't pass in response.application because it only returns part of it
      this.users = response.application.users;
      store.commit('updateApplication', this);
    }

    this.setApplication(response.application);

    return response;
  }

  async disableUsers() {
    const settings = {
      users: {
        enabled: false,
      },
    };

    const response = await window.Knack.Api.updateApplication(settings);

    if (response.changes && response.changes.deletes && response.changes.deletes.objects.length > 0) {
      for (const user of response.changes.deletes.objects) {
        store.commit('routes/resetUserObjectRoutes', user.key);
      }

      this.users = response.application.users;
      store.commit('updateApplication', this);
    }

    this.setApplication(response.application);

    return response;
  }

  async enableEcommerce() {
    const settings = {
      ecommerce: {
        enabled: true,
      },
    };

    const response = await window.Knack.Api.updateApplication(settings);

    this.attributes.ecommerce = {
      enabled: true,
    };
    store.commit('updateApplication', this.attributes);

    return response;
  }

  async enablePaymentMethods() {
    const response = await window.Knack.Api.createPaymentMethodsObject();

    return response;
  }

  async createPaymentField(objectKey, paymentObjectKey) {
    const response = await window.Knack.Api.createPaymentField(objectKey, paymentObjectKey);

    return response;
  }

  async disableEcommerce() {
    const settings = {
      ecommerce: {
        enabled: false,
      },
    };

    const response = await window.Knack.Api.updateApplication(settings);

    this.attributes.ecommerce = {
      enabled: false,
    };
    store.commit('updateApplication', this.attributes);

    return response;
  }

  async createPaymentProcessor(processor) {
    const response = await Api.createPaymentProcessor(processor);

    this.paymentProcessors.push(response.payment_processor);
    store.commit('updateApplication', this.attributes);

    return response;
  }

  /**
   * Updates payment processor state (API + Vuex)
   *
   * @param {object} processor
   */
  async updatePaymentProcessor(processor) {
    const { key: processorKey } = processor;

    const response = await Api.updatePaymentProcessor(processorKey, processor);

    this.paymentProcessors = this.paymentProcessors.map((proc) => {
      if (proc.key !== processorKey) {
        return proc;
      }

      return Object.assign(processor, response.payment_processor);
    });

    store.commit('updateApplication', this.attributes);

    return response;
  }

  async deletePaymentProcessor(processorKey) {
    const response = await Api.deletePaymentProcessor(processorKey);

    this.paymentProcessors = reject(this.paymentProcessors, {
      key: processorKey,
    });

    store.commit('updateApplication', this.attributes);

    return response;
  }

  async update(updateAttributes) {
    const response = await window.Knack.Api.updateApplication(updateAttributes);

    this.setApplication(response.application);

    return response;
  }

  // TODO: this belongs on User model, not application for Knack Account/Builder usage
  async logout() {
    localStorage.removeItem(`${this.account.id}-user-last_event`);

    const response = await window.Knack.Api.logout();

    store.commit('unauthenticateBuilder');

    return response;
  }
}

export default Application;
