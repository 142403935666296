<template>
  <HelpForm
    class="help-form-report-emergency"
    store-namespace="api/help/reportEmergency"
    @submit="submitForm"
    @clear="clearForm"
    @dismissErrors="dismissErrors"
    @dismissSuccess="dismissSuccess"
  >
    <p class="top-description mb-4 text-default text-base">
      Emergency Support will ping someone in our team immediately. Please use this form <strong>only</strong> in cases of catastrophic data loss or application downtime.
    </p>

    <div class="margin-bottom-md mb-4">
      <label
        for="emergency-issue"
        class="text-default font-medium mb-2 leading-4 tracking-[0.14px]"
      >
        Please describe the issue in as much detail as possible.
      </label>
      <p class="help-description margin-0 text-[#7B7079] leading-4 tracking-[0.14px] mb-2">
        Our goal is to see the issue in action, so please describe any steps necessary to view the issue.
        <br>
        Do not include sensitive data such as login information via this form.
      </p>
      <textarea
        id="emergency-issue"
        v-model="emergencyIssue"
      />

      <div
        v-if="isInvalid && validation.errorsByKey.emergencyIssue"
        class="validation-error"
      >
        {{ validation.errorsByKey.emergencyIssue }}
      </div>
    </div>

    <div class="margin-bottom-md mb-4">
      <label
        for="emergency-url"
        class="text-default font-medium mb-2 leading-4 tracking-[0.14px]"
      >
        What is the URL in your browser when you see this issue?
      </label>
      <input
        id="emergency-url"
        v-model="emergencyUrl"
        type="text"
        placeholder="URL"
      >

      <div
        v-if="isInvalid && validation.errorsByKey.emergencyUrl"
        class="validation-error"
      >
        {{ validation.errorsByKey.emergencyUrl }}
      </div>
    </div>

    <div class="margin-bottom-md mb-4">
      <label
        for="emergency-when"
        class="text-default font-medium mb-2 leading-4 tracking-[0.14px]"
      >
        When did you first notice this issue occurring?
      </label>
      <textarea
        id="emergency-when"
        v-model="emergencyWhen"
      />

      <div
        v-if="isInvalid && validation.errorsByKey.emergencyWhen"
        class="validation-error"
      >
        {{ validation.errorsByKey.emergencyWhen }}
      </div>
    </div>

    <div class="margin-bottom-md mb-4">
      <label
        for="emergency-changes"
        class="text-default font-medium mb-2 leading-4 tracking-[0.14px]"
      >
        Did you make any changes to the app prior to the issue occurring?
      </label>
      <textarea
        id="emergency-changes"
        v-model="emergencyChanges"
      />

      <div
        v-if="isInvalid && validation.errorsByKey.emergencyChanges"
        class="validation-error"
      >
        {{ validation.errorsByKey.emergencyChanges }}
      </div>
    </div>
  </HelpForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HelpForm from '@/components/builder/help/forms/HelpForm';
// import RequestUtils from '@/components/util/RequestUtils'
import { mapApiFormGetters } from '@/lib/vuex-helper';

export default {
  name: 'HelpFormReportEmergency',
  components: {
    HelpForm,
  },
  emits: ['navigateToHome'],
  computed: {
    ...mapGetters('api/help/reportEmergency', [
      'validation',
      'isInvalid',
      'isSuccess',
    ]),
    ...mapApiFormGetters('api/help/reportEmergency', [
      'emergencyIssue',
      'emergencyUrl',
      'emergencyWhen',
      'emergencyChanges',
    ]),
  },
  methods: {
    ...mapActions('api/help/reportEmergency', {
      reportEmergencyUpdateForm: 'updateForm',
      reportEmergencyReset: 'reset',
      reportEmergencyResetForm: 'resetForm',
      reportEmergencySubmitForm: 'submitForm',
    }),
    submitForm() {
      // Submit form request
      this.reportEmergencySubmitForm().then(() => {
        if (this.isSuccess) {
          this.clearForm();
        }
      });
    },
    clearForm() {
      this.reportEmergencyResetForm();
    },
    dismissErrors() {
      this.reportEmergencyReset();
    },
    dismissSuccess() {
      this.reportEmergencyResetForm();
      this.reportEmergencyReset();

      this.$emit('navigateToHome');
    },
  },
};
</script>

<style lang="scss">
.help-form-report-emergency {
  .top-description {
    color: #222B33;
    font-size: .875rem;
    line-height: 1.43;
    text-align: left;
  }

  .bottom-note {
    background-color: #E6E9EC;
    margin-bottom: 1rem;
    padding: 8px;

    .bottom-note-description {
      color: black;
      font-size: .875rem;
      line-height: 1.43;
    }
  }

  .help-form-submit {
    background-color: #AB1A09;
    border-color: #AB1A09;

    &:hover {
      background-color: darken(#AB1A09, 5%);
      border-color: darken(#AB1A09, 5%);
    }

    &::after {
      content: 'Submit Request'
    }

    .knButton__content {
      display: none;
    }
  }

  .block {
    display: block;
  }

  .validation-error {
    color: #AB1A09;
  }
}
</style>
