<template>
  <ViewToolbox
    back-title="Map Menu"
    title="Settings"
  >
    <div class="mb-4">
      <label class="tw-toolbox-label">Address Field</label>
      <select
        v-model="viewRaw.address_field.key"
        class="text-base py-2 pl-3 leading-5"
      >
        <option
          v-for="field in addressFields"
          :key="field.key"
          :value="field.attributes.key"
        >
          {{ field.attributes.name }}
        </option>
      </select>
    </div>

    <div class="mb-4">
      <label class="tw-toolbox-label">Title Field</label>
      <select
        class="text-base py-2 pl-3 leading-5 mb-0"
        v-model="viewRaw.title_field.key"
      >
        <option
          v-for="field in fields"
          :key="field.key"
          :value="field.attributes.key"
        >
          {{ field.attributes.name }}
        </option>
      </select>
    </div>

    <div class="mb-4">
      <label class="tw-toolbox-label">Map Size</label>
      <div class="level">
        <input
          v-model="viewRaw.map_width"
          type="number"
        >
        <span class="map-size-divider">
          x
        </span>
        <input
          v-model="viewRaw.map_height"
          type="number"
        >
      </div>
      <p class="instructions not-italic text-sm text-default">
        dimensions in pixels, width x height
      </p>
    </div>

    <div class="mb-4">
      <label class="tw-toolbox-label">List Width</label>
      <input
        v-model="viewRaw.list_width"
        type="number"
      >
    </div>

    <div class="mb-4">
      <label class="tw-toolbox-label">Units</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="viewRaw.units"
          type="radio"
          value="miles"
        >Miles</label>
        <label><input
          v-model="viewRaw.units"
          type="radio"
          value="kilometers"
        >Kilometers</label>
      </div>
    </div>

    <div class="mb-4">
      <label class="tw-toolbox-label">Starting Range</label>
      <select
        v-model="viewRaw.default_range"
        class="text-base py-2 pl-3 leading-5 mb-0"
      >
        <option value="5">
          5 {{ viewRaw.units }}
        </option>
        <option value="10">
          10 {{ viewRaw.units }}
        </option>
        <option value="25">
          25 {{ viewRaw.units }}
        </option>
        <option value="50">
          50 {{ viewRaw.units }}
        </option>
        <option value="100">
          100 {{ viewRaw.units }}
        </option>
        <option value="100000">
          Any
        </option>
      </select>
    </div>

    <div class="mb-4">
      <label class="tw-toolbox-label">Starting Point</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="viewRaw.starting_point"
          type="radio"
          value="blank"
        >Blank</label>
        <label><input
          v-model="viewRaw.starting_point"
          type="radio"
          value="location"
        >User's Current Location</label>
        <label><input
          v-model="viewRaw.starting_point"
          type="radio"
          value="address"
        >Specific Address</label>
        <div style="margin-left: 18px;">
          <input
            v-if="viewRaw.starting_point === 'address'"
            v-model="viewRaw.starting_address"
          >
        </div>
      </div>
    </div>

    <div class="border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
      <div class="flex mb-0 gap-2">
        <label class="tw-toolbox-label whitespace-nowrap leading-6 items-start">Pin Colors</label>
        <p
          v-if="app.settings.mapsAndGeocoderProvider === 'google'"
          class="mb-2"
        >
          The Live App will reflect the colors saved below
        </p>
      </div>
      <div class="level-left margin-bottom-half">
        <ColorPicker v-model="viewRaw.pin_color_default" />&nbsp;Default pin color
      </div>
      <ActionList
        id="map-colors"
        v-model:items="viewRaw.pin_colors"
        :default-item="defaultEventColor"
        :can-be-empty="true"
        class="hide-add margin-bottom-half"
      >
        <template #default="{ item, itemIndex }">
          <div>
            <ColorPicker v-model="item.color" />
          </div>
          <FieldListItem
            :object-key="object.key"
            :item="item"
            @update:item="onUpdateEventColor(itemIndex, $event)"
          />
        </template>
      </ActionList>
      <a
        class="button small green-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base capitalize font-medium mt-4"
        @click="addCustomColor"
      >
        <Icon
          class="text-default group-hover:text-brand-400 h-4 w-4 mr-2 fill-current"
          type="plus-thin"
        /> add custom color
      </a>
    </div>

    <Toggle
      :is-open="true"
      class="margin-top-double mt-2.5"
    >
      <template #title>
        <span>Pagination</span>
      </template>

      <template #content>
        <div class="border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
          <Pagination
            v-model:rows-per-page="viewRaw.rows_per_page"
            v-model:allow-limit="viewRaw.allow_limit"
            v-model:display-below="viewRaw.display_pagination_below"
          />
        </div>
      </template>
    </Toggle>

    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Filter Options</span>
      </template>

      <template #content>
        <div class="border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
          <Filters
            v-model:filter-type="viewRaw.details.filter_type"
            v-model:filter-fields="viewRaw.details.filter_fields"
            v-model:preset-filters="viewRaw.details.preset_filters"
            v-model:allow-preset-filters="viewRaw.details.allow_preset_filters"
            v-model:menu-filters="viewRaw.details.menu_filters"
            v-model:connection-sources="viewRaw.details.filter_connection_sources"
            :object="object"
          />
        </div>
      </template>
    </Toggle>

    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Title & Description</span>
      </template>

      <template #content>
        <div class="grid-verticalGaps gap-4">
          <div>
            <label class="tw-toolbox-label">Title</label>
            <input
              v-model="viewRaw.title"
              type="text"
            >
          </div>
          <div class="mb-0">
            <label class="tw-toolbox-label">Description</label>
            <textarea v-model="viewRaw.description" />
          </div>
        </div>
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import { mapGetters } from 'vuex';
import isNil from 'lodash/isNil';
import ActionList from '@/components/ui/lists/ActionList';
import ColorPicker from '@/components/ui/inputs/ColorPicker';
import Icon from '@/components/ui/Icon';
import FieldListItem from '@/components/ui/lists/FieldListItem';
import Filters from '@/components/views/shared/settings/Filters';
import Pagination from '@/components/views/shared/settings/Pagination';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'MapSettings',
  components: {
    ActionList,
    ColorPicker,
    FieldListItem,
    Filters,
    Icon,
    Pagination,
    Toggle,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    ...mapGetters([
      'app',
    ]),
    addressFields() {
      // TODO:: why is this here?
      if (isNil(this.$store.state.activeView.attributes.address_field)) {
        return '';
      }

      const objectKey = this.$store.state.activeView.attributes.source.object;

      return this.$store.getters.getAddressFields(objectKey);
    },

    fields() {
      if (isNil(this.$store.state.activeView.attributes.address_field)) {
        return '';
      }

      const objectKey = this.$store.state.activeView.attributes.source.object;

      return this.$store.getters.getObject(objectKey).fields;
    },
  },

  methods: {

    inputClass(activeFilterType, type) {
      return {
        'active-input': activeFilterType === type,
      };
    },

    defaultEventColor() {
      const field = this.object.fields[0];

      return {
        color: '#ff0000',
        field: field.key,
        value: '',
        operator: field.getFirstRuleOperator(),
      };
    },

    addCustomColor() {
      this.viewRaw.pin_colors.push(this.defaultEventColor());
    },

    onUpdateEventColor(colorIndex, newColor) {
      this.viewRaw.pin_colors[colorIndex] = newColor;
    },
  },
};
</script>

<style lang="scss">
.map-size-divider {
  font-weight:600;
  margin: 0 5px;
}

#map-colors {
  .field-list-item {

    &> * {
      margin-left: 4px;
    }
  }
}
</style>
