<template>
  <ConfirmModal
    title="Delete this payment processor?"
    :back="backLink"
    confirm-text="Delete Payment Processor"
    confirm-type="alert"
    @confirm="onClickDeletePaymentProcessor"
    @cancel="onClickCancelDeletePaymentProcesser"
  >
    <p>Any payment views using this payment processor will no longer work.</p>
    <p class="mb-0">
      <strong>This can't be undone!</strong>
    </p>
  </ConfirmModal>
</template>
<script>
import ConfirmModal from '@/components/ui/ConfirmModal';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  components: {
    ConfirmModal,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    processorKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      backLink: '/settings/ecommerce',
    };
  },
  computed: {
    app() {
      return this.$store.state.app;
    },
  },
  methods: {
    onClickDeletePaymentProcessor() {
      this.commitRequest({
        request: () => this.app.deletePaymentProcessor(this.processorKey),
        onSuccess: () => this.$router.push(this.backLink),
      });
    },
    onClickCancelDeletePaymentProcesser() {
      this.$router.push(this.backLink);
    },
  },
};
</script>
