<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Date Format</label>
      <div>
        <select
          v-model="fieldLocal.format.date_format"
          class="text-base py-2 pl-3 leading-5"
        >
          <option>mm/dd/yyyy</option>
          <option>M D, yyyy</option>
          <option>dd/mm/yyyy</option>
          <option>Ignore Date</option>
        </select>
      </div>
    </div>
    <div v-if="hasDate">
      <label class="text-default text-sm font-medium mb-2 leading-4">Default Date</label>
      <div>
        <select
          v-model="fieldLocal.format.default_type"
          class="text-base py-2 pl-3 leading-5"
          data-cy="date-default-type"
        >
          <option
            value="current"
            selected
          >
            Current Date
          </option>
          <option value="none">
            None
          </option>
          <option value="date">
            Specific Date
          </option>
        </select>
      </div>
      <div v-if="hasSpecificDate">
        <Datepicker
          v-model="localDefaultDate"
          data-cy="date-default-value"
          name="default_date"
          placeholder="Select Date"
          class="kn-datepicker mt-2"
          :format="getMomentDateFormatFromKnackDateFormat(fieldLocal.format.date_format)"
        />
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Time Format</label>
      <div>
        <select
          v-model="fieldLocal.format.time_format"
          class="text-base py-2 pl-3 leading-5"
        >
          <option>Ignore Time</option>
          <option>HH:MM am</option>
          <option
            value="HH MM (military)"
          >
            HH:MM (24 hour)
          </option>
        </select>
      </div>
    </div>
    <div v-if="hasTime">
      <label class="text-default text-sm font-medium mb-2 leading-4">Default Time</label>
      <div>
        <select
          v-model="fieldLocal.format.time_type"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="none">
            None
          </option>
          <option value="current">
            Current Time
          </option>
          <option value="time">
            Specific Time
          </option>
        </select>
      </div>
      <div v-if="hasSpecificTime">
        <TimePicker
          v-model="fieldLocal.format.default_time"
          class="kn-time mt-2"
          placeholder="Select Time"
          name="default_time"
          :format="getMomentTimeFormatFromKnackTimeFormat(fieldLocal.format.time_format)"
          :minute-interval="15"
        />
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Calendar Options</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="fieldLocal.format.calendar"
          type="radio"
          :value="false"
          @change="updateTimeFormat('Ignore Time')"
        >No, just use a simple date/time.</label>
        <label><input
          v-model="fieldLocal.format.calendar"
          type="radio"
          :value="true"
          @change="updateTimeFormat('HH:MM am')"
        >Yes, add options like repeat and end-time to use with a calendar.</label>
      </div>
    </div>
  </div>
</template>

<script>
import hasIn from 'lodash/hasIn';
import DateUtils from '@/components/renderer/mixins/DateUtils.js';
import Datepicker from '@/components/ui/inputs/datepicker/Datepicker';
import TimePicker from '@/components/ui/inputs/TimePicker';

export default {
  name: 'DateTime',
  components: {
    Datepicker,
    TimePicker,
  },
  mixins: [
    DateUtils,
  ],
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:fieldLocal'],
  computed: {
    hasCalendarOptions() {
      return this.fieldLocal.format.calendar;
    },
    hasSpecificDate() {
      return this.fieldLocal.format.default_type === 'date';
    },
    hasSpecificTime() {
      return this.fieldLocal.format.time_type === 'time';
    },
    hasDate() {
      return this.fieldLocal.format.date_format !== 'Ignore Date';
    },
    hasTime() {
      return this.fieldLocal.format.time_format !== 'Ignore Time';
    },
    localField: {
      get() {
        return this.fieldLocal;
      },
      set(newValue) {
        this.$emit('update:fieldLocal', newValue);
      },
    },
    localDefaultDate: {
      get() {
        if (hasIn(this.localField.format, 'default_date')) {
          return this.localField.format.default_date;
        }

        return this.getDefaultDate(this.fieldLocal);
      },
      set(newValue) {
        this.$emit('update:fieldLocal', {
          ...this.localField,
          format: {
            ...this.localField.format,
            default_date: this.parsedDate(newValue),
          },
        });
      },
    },
  },
  mounted() {
    // the Timepicker component doesn't handle the `none` text properly
    // so we need to set it to an empty string otherwise it will display `Invalid Date`
    if (hasIn(this.fieldLocal, 'format.default_time') && this.fieldLocal.format.default_time === 'none') {
      this.fieldLocal.format.default_time = '';
    }

    // copied from FieldView.js in Builder V2
    // this property doesn't actually appear as a default in the schema
    // until renderFieldProperties() is called
    if (!hasIn(this.fieldLocal, 'format.time_type')) {
      this.fieldLocal.format.time_type = 'current';
    }
  },
  methods: {
    updateTimeFormat(format) {
      this.fieldLocal.format.time_format = format;
    },
  },
};
</script>

<style lang="scss">
input.specific-value {
  margin-left: 10px;
}
</style>
