export default {
  namespaced: true,
  state: {
    all: [],
  },
  mutations: {
    addToast(state, toast) {
      state.all.push(toast);
    },
    removeToast(state, toastId) {
      state.all = state.all.filter((toast) => toast.id !== toastId);
    },
  },
  actions: {
    addToast({ commit }, toast) {
      const id = Date.now();
      commit('addToast', { ...toast, id });
      setTimeout(() => {
        commit('removeToast', id);
      }, toast.timeout ?? 5000);
    },
    removeToast({ commit }, toastId) {
      commit('removeToast', toastId);
    },
  },
  getters: {
    all: (state) => state.all,
  },
};
