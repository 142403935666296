<template>
  <RecordNavigation
    :allow-limit="allowLimit"
    :object-key="object.key"
    :records="records"
    :current-records-page="queryVars.currentPage"
    :records-per-page="queryVars.recordsPerPage"
    :show-interceptor="showInterceptor"
    :show-keyword-search="false"
    :show-export-button="showExportButton"
    :show-filters="false"
    :show-filter-menu="false"
    :current-records-count="currentRecordsCount"
    :show-pagination-range="showPaginationRange"
    :total-records="totalRecords"
    :total-records-pages="totalPages"
  />
  <div
    class="kn-search-list-wrapper"
    :class="viewClass"
  >
    <template
      v-for="(record, recordIndex) in records"
      :key="record.id"
    >
      <DetailsView
        v-if="recordIndex === 0"
        class="kn-list-item-container"
        :class="{ 'group has-util-dropdown': recordIndex === 0 }"
        :should-show-utility-dropdown="recordIndex === 0"
        :schema="detailsSchema"
        :data="record"
        :view="view"
        :route-prefix="routePrefix"
        @delete="onDeleteDetail"
        @drop="onDropDetail"
      />
      <div
        v-else
        class="list-item-wrapper"
      >
        <div
          v-tippy
          content="Use the first list item to add and edit list details"
          class="list-item-overlay"
        />
        <DetailsView
          class="kn-list-item-container"
          :schema="detailsSchema"
          :data="record"
          :view="view"
          :is-editable="false"
        />
      </div>
    </template>
  </div>
  <RecordNavigation
    v-if="showBottomPagination"
    :allow-limit="allowLimit"
    :object-key="object.key"
    :records="records"
    :show-interceptor="showInterceptor"
    :current-records-page="queryVars.currentPage"
    :records-per-page="queryVars.recordsPerPage"
    :show-keyword-search="false"
    :show-export-button="false"
    :show-pagination-range="false"
    :show-filters="false"
    :show-filter-menu="false"
    :total-records="totalRecords"
    :current-records-count="currentRecordsCount"
    :total-records-pages="totalPages"
  />
</template>

<script>
import DetailsView from '@/components/renderer/shared/Details';
import RecordNavigation from '@/components/renderer/shared/RecordNavigation';
import RendererUtils from '@/components/renderer/RenderUtils';
import DetailActionsMixin from '@/components/renderer/mixins/DetailActionsMixin';
import QueryMixin from '@/components/renderer/mixins/QueryMixin';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  components: {
    DetailsView,
    RecordNavigation,
  },
  mixins: [
    RendererUtils,
    DetailActionsMixin,
    QueryMixin,
    RequestUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    routePrefix() {
      return `/pages/${this.$route.params.pageKey}/views/${this.view.key}/search/results/list`;
    },

    viewClass() {
      const layouts = {
        'one-column': 'is-full',
        'three-column': 'is-one-third',
        'two-column': 'is-half',
      };
      const classes = [
        layouts[this.view.getDetailsLayout()],
      ];

      return classes;
    },
    showBottomPagination() {
      return this.view.attributes.display_pagination_below;
    },
    records() {
      return this.view.data.records;
    },
    detailsSchema: {
      get() {
        return this.view.getDetailsSchema();
      },
      set(newVal) {
        this.view.setDetailsSchema(newVal);
      },
    },
  },
  watch: {
    'view.data': function (newVal) {
      this.setRecords(newVal);
    },
  },
  created() {
    this.setRecords(this.view.data);

    this.initQueryVars();
  },
  methods: {
    queryRecordsRequest(queryVars, onSuccess, onError) {
      this.commitRequest({
        request: () => this.view.getData(queryVars),
        onSuccess: (response) => this.view.onGetDataSuccess(response, onSuccess),
        onError,
      });
    },
  },
};
</script>

<style lang="scss">
.kn-search-list-wrapper {
  display: grid;

  > * {
    width: 100%;
    margin-bottom: 1em;
  }

  &.is-half {
    grid-template-columns: repeat(2, 1fr);
  }
  &.is-one-third {
    grid-template-columns: repeat(3, 1fr);
  }
}

.list-item-wrapper {
  position: relative;
}

.list-item-overlay {
  background: #fff;
  display: none;
  opacity: 0;
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 22;
}

.view.is-active .list-item-overlay {
  display: block;
  opacity: .65;
}
</style>
