<template>
  <td
    :class="classes"
    :data-cy="dataTest"
    :data-test="dataTest"
    :style="style"
  >
    <FontAwesome
      v-if="icon && icon.icon && icon.left"
      :key="`${icon.icon}-${icon.align}`"
      :class="icon.class"
      :name="icon.icon"
      :style="icon.style"
    />

    <template v-if="!isHiddenByDisplayRules">
      <FieldValueWrapper
        v-if="renderSpecialValue"
        :index="`${record?.key ?? 'record'}-${index}-content`"
        :is-connected-values="showConnectedValues"
        :field="field || null"
        :view-item="column"
        :model-value="rawFieldValue"
        :display-value="displayValue"
        :truncate="forceTruncate"
        :class="`col-${index}`"
        :editable="isFieldEditable"
      />

      <span
        v-else
        :class="`col-${index}`"
        v-html="displayValue"
      />
    </template>

    <FontAwesome
      v-if="icon && icon.icon && icon.right"
      :key="`${icon.icon}-${icon.align}`"
      :class="icon.class"
      :name="icon.icon"
      :style="icon.style"
    />

    <ViewChildLink
      v-if="linkingToChildPage && index === 0 && linkPageKey"
      :tooltip="`Navigate to this page`"
      top="2px"
      :url="typeof column.scene === `string` ? `/pages/${linkPageKey}` : ``"
    />
  </td>
</template>

<script>
import { computed } from 'vue';

import FieldValueWrapper from '@/components/renderer/records/FieldValueWrapper';
import ViewChildLink from '@/components/renderer/shared/ViewChildLink';
import {
  getDisplayValue,
  getFieldValueRaw,
  getIcon,
  getLinkPageKey,
  getStyle,
  isShowingConnectedValues,
  isLinkingToChildPage,
  isRenderSpecialValue,
  isHiddenByDisplayRules as getIsHiddenByDisplayRules,
} from '@/components/renderer/table/TableCellUtils';
import FontAwesome from '@/components/ui/FontAwesome';

export default {
  name: 'TableCellNoEdit',
  components: {
    FieldValueWrapper,
    FontAwesome,
    ViewChildLink,
  },
  props: {
    column: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    },
    forceTruncate: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    highlightCell: {
      type: Boolean,
      default: false,
    },
    passedRules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const classes = computed(() => ({
      'kn-table-cell': true,
      'cell-highlight': props.highlightCell,
      editable: props.editable,
    }));

    const style = computed(() => getStyle(props));
    const dataTest = computed(() => `table-cell-${props.field?.key ?? 'link'}`);

    // Create Icon components, if they are needed
    const icon = computed(() => getIcon(props));
    const isHiddenByDisplayRules = computed(() => getIsHiddenByDisplayRules(props));
    const displayValue = computed(() => String(getDisplayValue(props)));
    const rawFieldValue = computed(() => getFieldValueRaw(props));
    const renderSpecialValue = computed(() => isRenderSpecialValue(props));
    const showConnectedValues = computed(() => isShowingConnectedValues(props));
    const linkingToChildPage = computed(() => isLinkingToChildPage(props));
    const linkPageKey = computed(() => getLinkPageKey(props.column.scene));

    const isFieldEditable = computed(() => (
      (props.field?.type === 'rating') ? false : props.editable
    ));

    return {
      classes,
      dataTest,
      displayValue,
      icon,
      isFieldEditable,
      isHiddenByDisplayRules,
      linkingToChildPage,
      linkPageKey,
      rawFieldValue,
      renderSpecialValue,
      showConnectedValues,
      style,
    };
  },
};
</script>
