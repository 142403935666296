<template>
  <div
    class="list-embed h-12 px-3 border border-solid border-default rounded-lg
      hover:bg-transparent hover:border-default"
    @click.self="$router.push(embedUrl)"
  >
    <ConfirmModal
      v-if="showConfirmDelete"
      confirm-type="alert"
      confirm-text="Delete Embed Code"
      title="Delete this embed code?"
      @close="showConfirmDelete = false"
      @confirm="onConfirmDelete"
    >
      <p>Any webpages including this embed code will no longer show this app.</p>
      <p class="mb-0">
        <strong>This can't be undone!</strong>
      </p>
    </ConfirmModal>

    <Modal
      v-if="isShowingEmbedCode"
      title="Embed Code"
      size="medium"
      @close="isShowingEmbedCode = false"
    >
      <p class="mb-3">
        Copy and paste the following code snippet into your site or blog:
      </p>
      <textarea
        ref="instructions"
        v-model="embedCode"
        class="margin-bottom w-full mb-3"
        rows="5"
        style="width: 95%; height: 100%;"
        data-cy="embed-code"
      />
      <div class="save center-align flex">
        <a
          class="button big orange-fill h-auto p-3 rounded-lg border border-solid border-default
            bg-white leading-none text-emphasis text-base font-medium ml-auto"
          data-cy="close-embed-code"
          @click="isShowingEmbedCode = false"
        >
          Close
        </a>
      </div>
    </Modal>

    <a
      :id="embed.key"
      class="name truncate text-base text-emphasis font-semibold"
      @click="$router.push(embedUrl)"
    >
      {{ embed.name }}
    </a>

    <div class="embed-code mr-2">
      <a
        class="button-square is-text hover:bg-brand-50"
        data-cy="show-embed-code"
        @click="showEmbedCode()"
      >
        <Icon type="embed" />
        <label
          v-show="showLinkLabel"
          class="embed-label text-default text-base font-medium mb-0"
        >
          Get Embed Code
        </label>
      </a>
    </div>

    <Popover>
      <template #trigger>
        <a
          class="button-square settings m-0 hover:bg-brand-50"
        >
          <Icon type="more-horiz" />
        </a>
      </template>

      <template #content>
        <div class="links p-0">
          <RouterLink
            v-close-popper
            :to="`${embedUrl}`"
            data-cy="embed-settings"
            class="hover:text-emphasis rounded hover:bg-brand-50 text-base text-default"
          >
            <Icon type="settings" /> Settings
          </RouterLink>
          <a
            v-close-popper
            data-cy="embed-delete"
            class="text-destructive hover:text-destructive rounded hover:bg-brand-50 text-base"
            @click="onClickDelete(embed.name)"
          >
            <Icon type="delete" /> Delete
          </a>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import Modal from '@/components/ui/Modal';
import ConfirmModal from '@/components/ui/ConfirmModal';
import Popover from '@/components/ui/Popover';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  // eslint-disable-next-line vue/no-reserved-component-names, vue/multi-word-component-names
  name: 'Embed',
  components: {
    Icon,
    Modal,
    ConfirmModal,
    Popover,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    embed: {
      type: Object,
      default: () => ({}),
    },
    showLinkLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showConfirmDelete: false,
      isEditingEmbed: false,
      isShowingEmbedCode: false,
      deleteEmbedName: '',
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    embedCode() {
      const dstKey = this.embed.key;
      const appId = this.$store.state.app.get('id');
      let domain = this.getLoaderApiUrl();
      let embedScript = 'knack';

      if (this.$store.state.app.get('settings') && this.$store.state.app.get('settings').whitelabel) {
        domain = 'https://loader.cloud-database.co';
        embedScript = 'database';
      }

      return `<script type="text/javascript">app_id="${appId}";distribution_key="${dstKey}";<\/script><script type="text/javascript" src="${domain}/${appId}/${dstKey}/${embedScript}.js"><\/script><div id="knack-${dstKey}">Loading...</div>` //eslint-disable-line
    },
    embedUrl() {
      return `/settings/embeds/${this.embed.key}`;
    },

  },
  methods: {
    getLoaderApiUrl() {
      // Remove first subdomain and change it to loader.
      // api.product.knack.com -> loader.product.knack.com
      // api.knack.com -> loader.knack.com
      const url = new URL(process.env.VUE_APP_API_URL);
      const urlParts = url.host.split('.');
      const domain = urlParts.slice(0).slice(1).join('.');
      return `${url.protocol}//loader.${domain}`;
    },
    deleteEmbed() {
      this.commitRequest({
        request: () => this.embed.delete(),
      });
    },
    showEmbedCode() {
      this.isShowingEmbedCode = true;
      this.$nextTick(() => this.$refs.instructions.select());
    },
    onClickDelete(embedName) {
      this.showConfirmDelete = true;
      this.deleteEmbedName = embedName;
    },
    onConfirmDelete() {
      this.deleteEmbed();
      this.showConfirmDelete = false;
    },
  },
};
</script>

<style lang="scss">
.list-embed {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: #FFFFFF;
  border: 1px solid #E7E4E6;
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    border-color: #898088;
    background-color: #F5F4F5;
  }

  .name {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 auto 0 0;
    color: #1B181B;
  }

  .embed-code {
    margin-right: .5em;
    flex-shrink: 0;
  }

  .button-square {
    display: flex;
    align-items: center;
    appearance: none;
    background-color: inherit;
    border: none;
    margin-left: 16px;
    border-radius: 4px;
    padding: 6px;

    &:hover,
    &.is-text:hover {
      background-color: #E0DCDF;
      color: inherit;

      svg {
        color: inherit;
      }
    }

    svg {
      width: 16px;
    }
  }

  .embed-label {
    margin-left: 0.25rem;
    cursor: pointer;
  }

  .delete,
  .edit {
    color: #797b81;
    line-height: 90%;
    padding-left: 15px;

    svg {
      width: 21px;
      height: 21px;
      outline-style: none;
    }
  }

  .modal {
    cursor: auto;
  }
}

.tooltip.delete-popover {
  border: 1px solid #e6e8ec;
  border-radius: .25em;

  .popover-inner {
    background-color: #fefefe;
    border-radius: .125em;
  }
}
</style>
