import Joi from '@hapi/joi';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import template from 'lodash/template';

/**
 * Validates a set of data against a joi validation schema.
 *
 * The errorMap format is:
 *   key => { types: { joiType: messageTemplate } }
 *
 * @param {object<string, *>} toValidate
 * @param {object<string, object>} validationSchema
 * @param {object<string, object>} errorMap
 * @param {object} validationOptions
 * @returns {{errors: object[]}}
 */
export function validate(toValidate, validationSchema, errorMap = {}, validationOptions = {}) {
  const fullValidationOptions = {
    abortEarly: false,
    allowUnknown: true,
    ...(validationOptions || {}),
  };

  const validationResult = Joi.validate(toValidate, validationSchema, fullValidationOptions);

  if (isEmpty(validationResult.error)) {
    return {
      errors: [],
    };
  }

  for (const error of validationResult.error.details) {
    let { type } = error;

    // The version of hapi in use does not have good support for named regexp, so add support here.
    if (type === 'string.regex.name') {
      const regexName = get(error, 'context.name', 'unknown');

      type = `regex.${regexName}`;
      error.regexName = regexName;
    }

    // Join the path items unless they are array indexes.
    const field = error.path.filter((value) => !isNumber(value)).join('.');

    error.flatPath = field;

    if (errorMap[field]) {
      const messageTemplate = errorMap[field].types?.[type];

      if (messageTemplate) {
        error.message = template(messageTemplate)({
          ...error,
          ...errorMap[field],
        });
      }
    }
  }

  return {
    errors: validationResult.error?.details ?? [],
  };
}
