<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const toasts = computed(() => store.getters['toasts/all']);

const removeToast = (toastId) => {
  store.dispatch('toasts/removeToast', toastId);
};
</script>

<template>
  <div
    class="fixed bottom-8 left-1/2 flex items-center flex-col z-[104] space-y-4"
    style="transform: translateX(-50%)"
  >
    <div
      v-for="toast in toasts"
      :key="toast.id"
      :class="[
        'animate-slideUpAndFade rounded-xl p-4 font-medium cursor-pointer text-sm',
        {
          'bg-success text-success-emphasis': toast.type === 'success',
          'bg-destructive text-destructive-emphasis': toast.type === 'error',
        }
      ]"
      @click="toast.onClick() ?? removeToast(toast.id)"
    >
      {{ toast.message }} <span
        v-if="toast.bold"
        class="underline font-bold"
      >{{ toast.bold }}</span>
    </div>
  </div>
</template>
