<template>
  <div
    id="connections"
    class="border-none"
  >
    <Ribbon class="title-ribbon has-margin level-left border-0 flex-none mb-1">
      <h3 class="text-emphasis">
        Connections
      </h3>
      <RouterLink
        v-tippy="addConnectionTooltipConfig"
        :to="addConnectionLink"
        data-cy="add-connection-button"
        class="button-icon-square add flex px-1 py-3 justify-center items-center rounded text-white bg-gradient-primary"
      >
        <Icon
          class="h-4 w-4 hover:fill-current"
          type="add"
        />
      </RouterLink>
    </Ribbon>
    <div
      v-if="!object?.conns.length"
      class="body-padding-full py-0"
    >
      <div class="flex flex-col items-center mt-12">
        <Icon
          class="h-12 w-12 text-muted mb-6"
          type="disconnect"
        />
        <p class="text-emphasis font-semibold text-center mb-4">
          Create a connection
        </p>
        <a
          href="https://learn.knack.com/article/do520qb4jz-about-connections"
          target="_blank"
          class="text-default underline text-center"
        >
          Learn more about connections<span class="relative">
            <Icon
              class="h-4 w-4 text-emphasis ml-2 absolute bottom-[1px]"
              type="arrow-top-right-on-square"
            />
          </span>
        </a>
      </div>
    </div>
    <template v-else>
      <div
        id="connections-list-wrapper"
        class="body-padding-full py-0"
      >
        <div class="intro">
          <p class="text-default">
            {{ object?.inflections.singular }} records can <span class="underline">connect</span> to records from these tables.
          </p>
        </div>

        <div
          id="connection-objects"
          class="margin-bottom"
        >
          <div
            v-for="(conns, connObject) in connectionsByObject(object?.conns)"
            :key="`${object?.key}-${connObject}`"
            class="connection-object"
          >
            <div class="expand justify-between mb-2">
              <div class="object truncate">
                <b class="text-sm font-medium text-default">{{ objectName(connObject) }}</b>
              </div>
              <Icon
                class="h-4 w-4 text-default text-right"
                type="arrow-drop-right"
              />
            </div>
            <div class="connections border-l-0 p-0 m-0">
              <div
                v-for="conn in conns"
                :key="`${object?.key}-${connObject}-${conn.key}`"
                class="connection bg-muted rounded-lg p-2 border-0"
                @mouseover="onOverConnection(conn.key)"
                @mouseout="onOutConnection(conn.key)"
              >
                <div class="conn-trigger level">
                  <div class="level-left overflow-visible">
                    <Icon
                      class="text-default"
                      type="arrow-drop-right"
                    />
                    <div class="conn-name level truncate max-w-[220px]">
                      <span class="text-emphasis text-sm font-medium">
                        {{ connObjectOwnerName(conn) }} {{ conn.name }}
                      </span>
                    </div>
                    <RouterLink
                      v-if="conn.relationship_type === `foreign`"
                      v-tippy="{ allowHTML: true }"
                      :content="`This connection field exists<br />on the ${getObject(conn.object).name} table.`"
                      :to="`/schema/list/objects/${conn.object}/fields/`"
                      class="foreignObjectLink"
                    >
                      <Icon
                        class="h-4 w-4 text-default"
                        type="arrow-top-right-on-square"
                      />
                    </RouterLink>
                  </div>
                  <RouterLink
                    v-slot="{ href, isActive, navigate }"
                    :to="getEditConnectionLink(conn)"
                    custom
                  >
                    <a
                      v-tippy
                      content="Edit this connection!"
                      :class="{'router-link-active': isActive}"
                      class="buttonSquare -size-small"
                      :href="href"
                      @click.stop="navigate"
                    >
                      <Icon
                        class="h-4 w-4 text-default"
                        type="settings"
                      />
                    </a>
                  </RouterLink>
                </div>
                <div class="conn-details">
                  <ConnectionDetails
                    class="pb-0 px-0"
                    :connection="conn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ConnectionDetails from '@/components/connections/ConnectionDetails';
import FieldUtils from '@/components/fields/FieldUtils';
import Icon from '@/components/ui/Icon';
import Ribbon from '@/components/layout/Ribbon';
import UIUtil from '@/util/UI';

export default {
  name: 'SchemaObjectListConnections',
  components: {
    ConnectionDetails,
    Icon,
    Ribbon,
  },
  mixins: [
    FieldUtils,
  ],
  computed: {
    ...mapGetters([
      'getObject',
    ]),
    addConnectionLink() {
      if (this.$route.path.indexOf('add') > -1) {
        return `/schema/list/objects/${this.object?.key}/fields/add/connection`;
      }

      return `/schema/list/objects/${this.object?.key}/connections/add`;
    },
    addConnectionTooltipConfig() {
      return {
        content: 'Add a new connection',
        placement: 'top-start',
      };
    },
  },
  watch: {

    '$route.params.objectKey': function () {
      this.setToggles();
    },

    'object.conns': function (newVal, oldVal) {
      // Reset toggles if a new connection is added
      if (oldVal && newVal && newVal.length > oldVal.length) {
        this.setToggles();
      }
    },
  },
  mounted() {
    this.setToggles();
  },
  methods: {
    onOverConnection(fieldKey) {
      const $field = document.getElementById(`field-wrapper-${fieldKey}`);

      if (!$field) {
        return;
      }

      $field.classList.add('highlight');
    },
    onOutConnection(fieldKey) {
      const $field = document.getElementById(`field-wrapper-${fieldKey}`);

      if (!$field) {
        return;
      }

      $field.classList.remove('highlight');
    },
    getEditConnectionLink(connection) {
      let connectionOwnerObjectKey = this.object.key;

      // Ff foreign then get the owning object from the connection
      if (connection.relationship_type === 'foreign') {
        connectionOwnerObjectKey = connection.object;
      }

      return `/schema/list/objects/${connectionOwnerObjectKey}/fields/${connection.key}/settings`;
    },
    async setToggles() {
      await this.$nextTick();

      // If only open with max number of connected objects, use document.querySelectorAll(`.connection-object`).length < 5
      const openToggles = true;

      document.querySelectorAll('.connection-object').forEach((group) => {
        const $trigger = group.firstElementChild;

        if ($trigger.getAttribute('data-has-toggle')) {
          return;
        }

        return new UIUtil.SlideToggle(group.lastElementChild, {
          trigger: $trigger,
          isOpen: openToggles,
        });
      });

      document.querySelectorAll('.connection').forEach((group) => {
        const $trigger = group.firstElementChild;

        if ($trigger.getAttribute('data-has-toggle')) {
          return;
        }

        return new UIUtil.SlideToggle(group.lastElementChild, {
          trigger: $trigger,
          isOpen: (!openToggles && group.parentElement.childNodes.length === 1),
        });
      });
    },
    connectionsByObject(conns) {
      return _.groupBy(conns, (conn) => conn.object);
    },
    objectName(objectKey) {
      const object = this.$store.getters.getObject(objectKey);

      return object?.name;
    },
    connObjectOwnerName(conn) {
      // if local then this object is the owner
      if (conn.relationship_type === 'local') {
        return '';
      }

      return this.objectName(conn.object);
    },
    connIcon(conns) {
      const hasLocal = conns.find((conn) => conn.relationship_type === 'local');
      const hasForeign = conns.find((conn) => conn.relationship_type === 'foreign');

      if (hasLocal && hasForeign) {
        return 'both-arrow';
      }

      return (hasLocal) ? 'right-arrow' : 'left-arrow';
    },
  },
};
</script>

<style lang="scss">
#connections-list-wrapper {
  overflow: auto;
  padding-top: 1em;

  .intro {
    @include font-caption;
    margin-top: 0;
    margin-bottom: 1em;
    color: $gray600;
  }
}

#connection-objects {

  .connection-object {
    margin-bottom: 16px;
  }

  .expand {
    display: flex;
    align-items: flex-start;
    margin-bottom: .25rem;
    cursor: pointer;
    @include font-body;
    font-weight: 600;

    svg.icon-arrow-drop-right {
      width: 20px;
      height: 20px;
      margin-right: .125rem;
      position: relative;
      transition: all 200ms ease-out;
      color: inherit;
      flex-shrink: 0;
    }

    svg.conn-direction {
      margin: 0 5px;
      position: relative;
      top: 1px;
    }
  }

  .connections {
    transition: all .2s ease-out;
    margin-left: 11px;
    padding-top: 4px;
    padding-left: 10px;
    border-left: 1px solid $gray100;

    .connection {
      justify-content: space-between;
      margin-bottom: 8px;
      background-color: $gray50;
      border: 1px solid $gray50;
      border-radius: .35em;
      padding: 0.3125em .5em;
      display: flex;
      flex-direction: column;

      &:hover {
        border: 1px solid $gray100;
      }
      &:last-child {
        margin-bottom: 0px;
      }

      .foreignObjectLink {
        display: inline-flex;
        margin-left: 4px;

        .icon-arrow-go {
          height: 16px;
          width: 16px;
        }
      }

      .button-square {
        background-color: unset;
      }

      .button-square:hover {
        background-color: $gray100;
      }

      .icon-settings {
        height: 16px;
        width: 16px;
      }

      .conn-trigger {
        cursor: pointer;
        color: $primary500;
        @include font-caption;
        font-weight: 600;

        .icon-arrow-drop-right {
          margin-left: -6px;
          margin-right: 0px;
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          transition: all 200ms ease-out;
        }
      }

      .conn-details {
        transition: all .2s ease-out;
      }

      .conn-name {
        svg {
          width: 16px;
          height: 16px;
          color: $gray400;
          margin-right: .25em;
          flex-shrink: 0;
          align-self: start;
          margin-top: .0625em;
        }
      }

      .icon {
        color: $gray400;
      }
    }
  }

  .connection-object > .open > svg.icon-arrow-drop-right, .open > svg.icon-arrow-drop-right {
    transform: rotate(90deg);
  }

  .conn-trigger.open svg.icon-arrow-drop-right {
    transform: rotate(90deg);
  }
}
</style>

<style lang="scss" scoped>
#connections {
  .button-icon-square {
    margin-left: 0.5em;
  }

  .level-left {
    flex-shrink: unset;
    overflow-x: hidden;
    white-space: nowrap;

    .conn-name.level {
      overflow-x: hidden;
      white-space: nowrap;

      span {
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
