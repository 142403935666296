<template>
  <transition name="loader">
    <div
      v-if="isLoading"
      id="kn-loader"
      data-cy="loader"
      :class="classes"
    >
      <div class="kn-loader-wrapper">
        <div id="loader-bg">
          <div class="loader-icon">
            <Icon
              type="knack"
              data-cog="knack-asterisk"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    local: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'kn-loader-local': this.local,
      };
    },
  },
};
</script>

<style>
#kn-loader.kn-loader-local {
  z-index: 90;
  position:absolute;
  display:block;
  top:0;
  /* position: sticky;
  max-height: 100vh; */
}

#kn-loader.kn-loader-local .kn-loader-wrapper {
  max-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content:center;
  position: sticky;
  top: 0;
}
</style>
