<template>
  <BodyWrapper>
    <RouterView />
  </BodyWrapper>
</template>

<script>
import BodyWrapper from '@/components/layout/BodyWrapper';

export default {
  name: 'AppSettings',
  components: {
    BodyWrapper,
  },
};
</script>

<style lang="scss">
#settings-sub-nav {
  margin: .4em 0 1.25em;
}
</style>
