<template>
  <ViewToolbox
    back-title="Report"
    title="Add Chart"
  >
    <div class="section-wrapper">
      <WizardStep
        v-if="showWizardStep('source')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('source')"
      >
        <template #edit>
          <div :class="wizardEditTextClasses" v-html="viewAction" />
        </template>
        <template #intro>
          <div class="mb-2 font-medium leading-5 text-sm tracking-[.14px]" v-html="sourceIntro" />
        </template>
        <ViewAddSourceObject
          :page="activePage"
          @submit="onSelectSource"
        />
      </WizardStep>

      <!-- Select the object path -->
      <WizardStep
        v-if="showWizardStep('path')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('path')"
      >
        <template #edit>
          <div :class="wizardEditTextClasses" v-html="sourcePathDescription" />
        </template>
        <template #intro>
          <div class="mb-2" v-html="pathIntro" />
        </template>
        <ViewAddSourcePath
          :page="activePage"
          :source="source"
          :paths="sourcePaths"
          :path-defaults="pathSelections"
          @submit="onSelectSourcePath"
        />
      </WizardStep>

      <!-- Confirm/Add view -->
      <WizardStep
        v-if="showWizardStep('confirm')"
        v-model:wizard-step="wizardStep"
        :step="getWizardStep('confirm')"
      >
        <template #intro>
          <strong class="mb-2">Confirm &amp; Add</strong>
        </template>
        <p class="text-xs text-default mb-4">
          You can further edit your chart after you add it.
        </p>
        <div class="flex justify-end">
          <a
            class="button text-white capitalize p-3 rounded-lg bg-gradient-primary border-0 text-base font-medium m-0 leading-4"
            data-cy="save-chart-add"
            @click="addReport"
          >
            Add {{ view.type }}
          </a>
        </div>
      </WizardStep>
    </div>
  </ViewToolbox>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewAddSourceObject from '@/components/pages/page/ViewAddSourceObject';
import ViewAddSourcePath from '@/components/pages/page/ViewAddSourcePath';
import WizardStep from '@/components/ui/WizardSection';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import RequestUtils from '@/components/util/RequestUtils';
import Toggle from '@/components/ui/Toggle';
import Icon from '@/components/ui/Icon';
import { eventBus } from '@/store/bus';

export default {
  name: 'ChartAdd',
  components: {
    ViewToolbox,
    ViewAddSourceObject,
    ViewAddSourcePath,
    WizardStep,
    Toggle,
    Icon,
  },
  mixins: [
    ViewUtils,
    RequestUtils,
  ],
  data() {
    return {
      wizardStep: 1,
      wizardStepMax: 1,
      wizardSteps: [
        'source',
        'path',
        'confirm',
      ],
      source: {},
      sourceDescription: '',
      sourcePathDescription: '',
      pathSelections: {}, // store the path selections to use as defaults when editing the path
    };
  },
  computed: {
    ...mapGetters([
      'activePage',
      'getChartType',
    ]),

    viewAction() {
      return `Add a chart that displays <strong>${this.sourceDescription}</strong>`;
    },

    sourceIntro() {
      return `Which <strong>records</strong> will this ${this.view.type} display?`;
    },

    pathIntro() {
      return `What <strong>type</strong> of ${this.sourceDescription}?`;
    },
    wizardEditTextClasses() {
      return 'mb-4 text-base font-semibold tracking-[.32px]';
    },
  },
  methods: {
    async addReport() {
      this.view.addChartToReport(this.getChartType(), this.source);

      // Tell the new report to load in its data
      await this.$nextTick();

      eventBus.$emit(`reloadReportData.${this.view.key}`, {
        rowIndex: this.view.rows.length - 1,
        reportIndex: 0,
      });
    },

    async onSelectSource(source) {
      this.sourceDescription = source.label;

      this.source = source.source;
      this.sourcePaths = source.paths;

      let addSourcePathStep = true;

      // Check if we should skip the path selection step
      if (Object.keys(this.sourcePaths).length === 1) {
        const pathKey = Object.keys(this.sourcePaths)[0];

        const sourceUsesLoggedInUser = this.sourcePaths[pathKey].user;

        const sourceUsesConnections = pathKey.indexOf(':') > -1;

        // Skip the path step if this source has no connections or logged-in user attribute. It's just a regular object source so nothing to confirm.
        if (!sourceUsesConnections && !sourceUsesLoggedInUser) {
          addSourcePathStep = false;

          // remove the path selection step
          if (this.wizardSteps.includes('path')) {
            this.wizardSteps.splice(this.wizardSteps.indexOf('path'), 1);
          }
        }
      }

      // multiple paths exist so ensure the path step exists to select it
      if (addSourcePathStep) {
        if (this.wizardSteps.indexOf('path') === -1) {
          // insert path step
          this.wizardSteps.splice(this.wizardSteps.indexOf('source') + 1, 0, 'path');
        }
      }

      this.nextWizardStep();
    },

    onSelectSourcePath(path) {
      this.source = path.source;
      this.sourcePathDescription = path.label;
      this.pathSelections = path.path;

      // add to the actual view
      this.view.source = this.source;

      if (this.view.type === 'report' && this.view.rows?.length) {
        this.view.rows[0].reports[0].source = this.source;
      }

      this.nextWizardStep();
    },

    getWizardStep(whichStep) {
      return this.wizardSteps.indexOf(whichStep) + 1;
    },

    showWizardStep(whichStep) {
      return (this.wizardSteps.indexOf(whichStep) > -1 && this.getWizardStep(whichStep) <= this.wizardStep);
    },

    nextWizardStep() {
      this.wizardStep++;

      if (this.wizardStep > this.wizardStepMax) {
        this.wizardStepMax = this.wizardStep;
      }
    },
  },
};
</script>
