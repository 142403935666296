<template>
  <view-toolbox
    back-title="Search Results & Actions"
    title="Edit Column"
    :back-count="2"
  >
    <ColumnProperties />
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ColumnProperties from '@/components/views/shared/ColumnProperties';

export default {
  name: 'SearchResultsColumnProperties',
  components: {
    ViewToolbox,
    ColumnProperties,
  },
};
</script>
