<template>
  <header id="kn-app-header">
    <div class="kn-container">
      <div id="kn-app-mobile-container">
        <h1 id="knack-logo">
          <template v-if="showAppLogo">
            <img
              v-if="appLogo"
              :src="appLogo"
              alt="Logo"
            >
          </template>
          <a
            v-else
            href="#"
            class="kn-title"
          >
            {{ applicationName }}
          </a>
        </h1>

        <div
          class="kn-mobile-controls"
        >
          <label
            class="kn-mobile-nav-toggle"
            for="menu-show-dropdown"
            @click.prevent="toggleMobileNav"
          >
            <i class="fa fa-bars" />
          </label>
          <input
            id="menu-show-dropdown"
            type="checkbox"
          >
        </div>
      </div>

      <section
        v-if="showPageMenu"
        id="app-menu-container"
      >
        <div class="kn-menu-arrow is-left" />
        <div id="kn-app-menu">
          <div class="kn-navigation-bar">
            <nav :class="navClasses">
              <ul id="app-menu-list">

                <!-- Use sample menu items if it's preview -->
                <template v-if="isPreview">
                  <li
                    v-for="page in entryPages"
                    :key="page.key"
                    :class="{'is-active': !!page.isActive, 'kn-dropdown-menu': !!page.dropdownPages}"
                  >
                    <LegacyDropdownMenu
                      v-if="page.dropdownPages"
                      :entry-page="page"
                      :dropdown-pages="page.dropdownPages"
                    />

                    <a
                      v-else
                      :href="page.slug"
                      :data-kn-slug="page.slug"
                    >
                      <span v-if="page.icon?.icon && page.icon?.align === 'left'">
                        <FontAwesome :name="page.icon.icon" />&nbsp;&nbsp;
                      </span>

                      <span>
                        {{ page.name }}
                      </span>

                      <span v-if="page.icon?.icon && page.icon?.align === 'right'">
                        &nbsp;&nbsp;<FontAwesome :name="page.icon.icon" />
                      </span>
                    </a>
                  </li>
                </template>

                <!-- Otherwise, use real menu items -->
                <template v-else>
                  <li
                    v-for="(pageItem, index) in mainStartPages.slice(0, maxAmountOfPages)"
                    :key="pageSlug(pageItem)"
                    :class="generateLinkClass(index, activeIndex)"
                  >
                    <a
                      href="#"
                      :data-kn-slug="pageSlug(pageItem)"
                    >
                      {{ pageItem.name }}
                    </a>
                  </li>
                </template>
              </ul>
            </nav>
          </div>
        </div>
        <div class="kn-menu-arrow is-right" />
      </section>
    </div>

    <!-- Mobile nav -->
    <LegacyMobileNav
      :is-open="isMobileNavOpen"
      :close-mobile-nav="closeMobileNav"
      :entry-pages="entryPages"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import FontAwesome from '@/components/ui/FontAwesome';
import LegacyDropdownMenu from '@/components/renderer/header/pieces/LegacyDropdownMenu';
import LegacyMobileNav from '@/components/renderer/header/pieces/LegacyMobileNav';

export default {
  components: {
    FontAwesome,
    LegacyDropdownMenu,
    LegacyMobileNav,
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
    entryPages: {
      type: Array,
      required: true,
    },
    maxAmountOfPages: {
      type: Number,
      default: 4,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    forceCloseMobileNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobileNavOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    ...mapGetters('page', [
      'mainStartPages',
    ]),
    applicationName() {
      return this.app.name;
    },
    menuType() {
      return this.app.design.regions.header.legacySettings.menu.format || 'buttons';
    },
    navClasses() {
      // buttons always set to true because it acts as the base of styles
      return {
        buttons: true,
        tabs: this.menuType === 'tabs',
        'is-nav': true,
      };
    },
    showAppLogo() {
      return !!this.app.design.regions.header.legacySettings?.title?.show_logo;
    },
    showPageMenu() {
      return this.app.design.regions.header.legacySettings.menu.show;
    },
    appLogo() {
      return this.app.logo;
    },
  },
  watch: {
    forceCloseMobileNav(value) {
      if (value) {
        this.closeMobileNav();
      }
    },
  },
  methods: {
    closeMobileNav() {
      this.isMobileNavOpen = false;
      // Set overflow on body element back to inherit
      const bodyElement = document.getElementById('knack-body');
      if (bodyElement) {
        bodyElement.style.overflow = 'inherit';
      }
    },
    openMobileNav() {
      this.isMobileNavOpen = true;
      // Prevent scrolling on body while mobile nav is open
      const bodyElement = document.getElementById('knack-body');
      if (bodyElement) {
        bodyElement.style.overflow = 'hidden';
      }
    },
    toggleMobileNav() {
      if (this.isMobileNavOpen) {
        this.closeMobileNav();
      } else {
        this.openMobileNav();
      }
    },
    pageSlug(page) {
      return page.slug;
    },
    generateLinkClass(listItemIndex, activeIndex) {
      return {
        'is-active': listItemIndex === activeIndex,
      };
    },
  },
};
</script>

<style lang="scss">
#kn-app-header {
  background-color: #023F74;
  position: relative;
}

#kn-app-header, #knack-logo .kn-title {
  color: #ffffff;
}

.kn-content .kn-navigation-bar .buttons.is-nav #app-menu-list li a {
  display: flex;
  border-radius: 3px;
  padding: 5px 10px;
  margin-bottom: 5px;
  text-decoration: none;
  align-items: center;
}

.kn-content .kn-navigation-bar .buttons.tabs.is-nav #app-menu-list li a {
  margin-bottom: 0px;
  border-bottom-left-radius:0px;
  border-bottom-right-radius:0px;
}

.kn-navigation-bar .buttons.tabs li.is-active > a {
  background-color:#fff;
}

.kn-content .kn-navigation-bar a {
  font-weight: 500;
  font-size: 1.075em;
}

.kn-mobile-controls {
  display: none;
  min-width: 60px;
  text-align: center;
  order: -1;

  #menu-show-dropdown {
    display: none;
  }
  .kn-mobile-nav-toggle {
    padding-left: 0;
  }
}

.kn-content .kn-navigation-bar .buttons.is-nav #app-menu-list {
  margin: 0;
  border-bottom: none;

  > li {
    margin: 0;
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  li {
    display: inline-block;
    i {
      font-size: inherit;
    }
  }
}

.kn-content .kn-navigation-bar li {
  margin: 0 1px;
}

#knack-logo {
  margin: 0;
  padding: .75em 0;
  flex-grow: 1;
}

.kn-navigation-bar a {
  background-color: #00396D;
}
</style>
