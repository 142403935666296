<template>
  <div class="grid-verticalGaps gap-4">
    <div v-if="isFieldType && field">
      <label
        for="field"
        class="tw-toolbox-label"
      >
        Field
      </label>
      <FieldName
        :field="field"
        :connection="fieldNameConnection"
        :thumb="fieldNameThumb"
      />
    </div>

    <div v-if="item.type === 'scene_link'">
      <label class="tw-toolbox-label">Page to link to</label>
      <div
        v-if="linkedPageKey"
        class="inline-flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis"
      >
        <router-link
          v-tippy
          content="Navigate to this page"
          :to="'/pages/' + linkedPageKey"
          class="underline no-underline text-default"
        >
          {{ linkedPage }}
        </router-link>
      </div>
      <div
        v-else
        class="inline-flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis"
      >
        <span>{{ linkedPage }}</span>
      </div>
    </div>

    <p v-if="isDividerType">
      Dividers have no options to configure. They are happy to just be dividers ;)
    </p>

    <template v-if="itemHasLabel">
      <div>
        <label class="tw-toolbox-label">Label Format</label>
        <LabelFormat
          :model-value="item.format"
          :default-label-format="view.get(`label_format`)"
          :view-label-format="viewLabelFormat"
          :show-format-toggle="true"
          @update-property:model-value="onUpdateItemFormatProperty"
        />
      </div>

      <div v-if="showLabelInput">
        <label class="tw-toolbox-label">Label</label>
        <input
          v-model="item.name"
          type="text"
        >
      </div>
    </template>

    <template v-if="isCopyType">
      <div>
        <label class="tw-toolbox-label">Title</label>
        <input
          v-model="item.value"
          type="text"
        >
      </div>
      <div>
        <label class="tw-toolbox-label">Copy</label>
        <textarea v-model="item.copy" />
      </div>
    </template>

    <template v-if="isLinkType">
      <div>
        <label class="tw-toolbox-label">Link Type</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="item.link_type"
            type="radio"
            name="input_type"
            value="text"
          >Text</label>
          <label><input
            v-model="item.link_type"
            type="radio"
            name="input_type"
            value="field"
          >Field</label>
        </div>
      </div>

      <div v-if="linkTypeIsField">
        <label class="tw-toolbox-label">Link Field</label>
        <select
          v-if="object"
          v-model="item.key"
          class="text-base py-2 pl-3 leading-5 mb-0"
        >
          <template
            v-for="objField in object.fields"
            :key="objField.key"
          >
            <option :value="objField.key">
              {{ objField.name }}
            </option>
            <template v-if="objField.hasThumbs()">
              <template
                v-for="thumb in objField.getThumbs()"
                :key="objField.key + ':' + thumb.key"
              >
                <option :value="objField.key + ':' + thumb.key">
                  {{ objField.getNameWithThumb(thumb) }}
                </option>
              </template>
            </template>
          </template>
        </select>
      </div>

      <div v-if="linkTypeIsText">
        <label
          for="link_text"
          class="tw-toolbox-label"
        >
          Link Text
        </label>
        <input
          v-model="item.link_text"
          type="text"
        >
      </div>

      <div>
        <label class="tw-toolbox-label">Icon</label>
        <p class="mb-0">
          <IconPicker v-model:icon="item.icon" />
        </p>
      </div>
    </template>

    <ActionLinks
      v-if="isActionLinkType"
      v-model:action-rules="item.action_rules"
    />

    <template v-if="isFieldType">
      <div v-if="isThumb">
        <label class="tw-toolbox-label">Thumbnail Gallery</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="item.img_gallery"
            type="checkbox"
          >Click the thumbnail to view the full-size image</label>
        </div>
      </div>

      <IfPlan
        :level-is-minimum-of="2"
        :allow-if-beta="true"
      >
        <div v-if="isAddress">
          <label class="tw-toolbox-label">Map</label>
          <div class="input-labels tw-input-labels">
            <label><input
              v-model="item.show_map"
              type="checkbox"
            >Show address in a map</label>
          </div>

          <div
            v-if="item.show_map"
            class="selectionGroup border-0"
          >
            <label class="tw-toolbox-label">Map Size</label>
            <div class="level">
              <div class="level-left">
                <input
                  v-model="item.map_width"
                  type="text"
                  style="width: 60px;"
                ><span>&nbsp;&nbsp;X&nbsp;&nbsp;</span><input
                  v-model="item.map_height"
                  type="text"
                  style="width: 60px;"
                ><span>&nbsp;&nbsp;<em>in pixels</em></span>
              </div>
            </div>
          </div>
        </div>
      </IfPlan>

      <template v-if="isConnectionField || isFieldFromConnectedObject">
        <div v-if="isConnectionToMany">
          <label class="tw-toolbox-label">Connection Separator <HelpIcon
            class="pb-0"
            :copy="`<b>${field?.name}</b> may contain multiple values<br />Choose how to separate these values.`"
          /></label>
          <div class="input-labels tw-input-labels">
            <label><input
              v-model="connectionSeparator"
              type="radio"
              name="conn_separator"
              value="comma"
            >Comma</label>
            <label><input
              v-model="connectionSeparator"
              type="radio"
              name="conn_separator"
              value="new_line"
            >New Line</label>
          </div>
        </div>

        <div v-if="connectedPages.length">
          <label class="tw-toolbox-label">Connection Link <HelpIcon
            class="pb-0"
            :copy="`Link the <b>${field?.name}</b> values<br />to <b>${connObject.name}</b> detail pages`"
          /></label>
          <div>
            <select
              v-model="item.conn_link"
              class="text-base py-2 pl-3 leading-5 mb-0"
            >
              <option value="">
                No Link
              </option>
              <option
                v-for="page in connectedPages"
                :key="page.key"
                :value="page.key"
              >
                {{ page.label }}
              </option>
            </select>
          </div>
        </div>
      </template>
    </template>

    <div v-if="isFieldType || isLinkType">
      <label class="tw-toolbox-label">Styles</label>
      <div class="details-style-options rounded-lg p-1 border border-solid border-default bg-white w-fit">
        <a
          v-for="style in styleOptions"
          :key="style.value"
          v-tippy
          :content="style.tip"
          class="text-base font-semibold tracking-[0.32px] rounded px-2 py-1 border-0 text-default inline-flex"
          :class="{'active bg-brand-100 text-emphasis': styles.indexOf(style.value) > -1}"
          @click="toggleStyle(style.value)"
        >
          <Icon :type="style.icon" />
        </a>
      </div>
      <Notification
        v-if="showStylesWarning"
        class="text-warning-emphasis"
        style="margin-top: 1em;"
      >
        <p class="text base text-warning-emphasis">
          These styles may conflict with the <b>design settings for view links</b>, which you can <RouterLink
            to="/settings/design/views/links"
            class="underline text-warning-emphasis"
          >
            update here
          </RouterLink>.
        </p>
      </Notification>
    </div>

    <!-- View Link Design -->
    <Toggle
      v-if="showViewLinkDesignAccordion"
      :is-open="true"
    >
      <template #title>
        <span>Link Design</span>
      </template>
      <template #content>
        <ToggleSwitch
          v-model:checked="showViewLinkDesignOptions"
          label="Use Custom Design Settings"
        >
          <template #offDescription>
            <span class="text-sm font-normal text-default">
              This link uses the
              <router-link
                to="/settings/design/views/links"
                class="text-default underline"
              >
                global design settings
              </router-link>
              for <strong>{{ viewLinkType }}</strong> links.
            </span>
          </template>

          <template #description>
            <span class="text-sm font-normal text-default">
              This link overrides the
              <router-link
                to="/settings/design/views/links"
                class="text-default underline"
              >
                global design settings
              </router-link>
              with the following settings:
            </span>
          </template>
        </ToggleSwitch>

        <LinksOptions
          v-if="showViewLinkDesignOptions"
          :custom-design-object="item.link_design"
          :view-link-type="viewLinkType"
          hide-icon-option
        />
      </template>
    </Toggle>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, isNil } from 'lodash';

import ActionLinks from '@/components/views/ActionLinks';
import HelpIcon from '@/components/ui/HelpIcon';
import Icon from '@/components/ui/Icon';
import IconPicker from '@/components/ui/inputs/IconPicker';
import IfPlan from '@/components/util/IfPlan';
import FieldName from '@/components/fields/FieldName';
import Notification from '@/components/ui/notifications/Notification';
import ViewUtils from '@/components/views/ViewUtils';
import LabelFormat from '@/components/views/shared/settings/LabelFormat';
import Toggle from '@/components/ui/Toggle';
import ToggleSwitch from '@/components/ui/ToggleSwitch';
import LinksOptions from '@/components/settings/design/options/LinksOptions';

export default {
  name: 'DetailsItemProperties',
  components: {
    ActionLinks,
    HelpIcon,
    FieldName,
    Icon,
    IconPicker,
    IfPlan,
    LabelFormat,
    LinksOptions,
    Notification,
    Toggle,
    ToggleSwitch,
  },
  mixins: [
    ViewUtils,
  ],
  props: {
    item: {
      type: Object,
      default: () => ({
        format: {},
      }),
    },
  },
  data() {
    return {
      styleOptions: [
        {
          value: 'h1', icon: 'format-h1', label: '<b>H1</b>', tip: 'Add a large header style',
        },
        {
          value: 'h2', icon: 'format-h2', label: '<b>H2</b>', tip: 'Add a large header style',
        },
        {
          value: 'strong', icon: 'format-bold', label: '<b>b</b>', tip: 'Add a bold style',
        },
        {
          value: 'em', icon: 'format-italic', label: '<i>i</i', tip: 'Add an italics style',
        },
      ],
      initialStyles: [],
    };
  },
  computed: {
    showViewLinkDesignAccordion() {
      return this.isLinkType || this.isActionLinkType || this.isDeleteType;
    },
    showViewLinkDesignOptions: {
      get() {
        return !!this.item?.link_design_active;
      },
      set(newValue) {
        if (!this.item) {
          return;
        }

        this.item.link_design_active = !!newValue;

        // if turning on custom View Link Design Options for the first time
        if (newValue && !this.item.link_design) {
          this.setCustomViewLinkDesign();
        }
      },
    },
    viewLinkType() {
      if (this.isActionLinkType) {
        return 'action';
      }
      if (this.isDeleteType) {
        return 'delete';
      }
      if (this.isLinkType) {
        return 'page';
      }

      return '';
    },
    field() {
      if (this.linkTypeIsField) {
        return this.getField(this.item.key);
      }

      if (this.hasField) {
        return this.getField(this.item.key);
      }

      return null;
    },
    connectionSeparator: {
      get() {
        return this.item.conn_separator || 'new_line';
      },

      set(newVal) {
        this.item.conn_separator = newVal;
      },
    },
    itemHasLabel() {
      if (this.isFieldType) {
        return true;
      }

      return false;
    },
    showLabelInput() {
      if (this.item.format?.label_custom && this.item.format?.label_format === 'none') {
        return false;
      }

      return true;
    },
    viewLabelFormat() {
      const view = this.$store.getters.activeView;
      const labelFormat = view.getDetailsLabelFormat();

      switch (labelFormat) {
        case 'right':
          return 'Right aligned';

        case 'none':
          return 'None';

        case 'top':
          return 'Top';

        case 'left':
        default:
          return 'Left aligned';
      }
    },
    isCopyType() {
      return (this.item.type === 'special_title' || this.item.type === 'copy');
    },
    isLinkType() {
      return this.item.type === 'delete' || this.item.type === 'scene_link';
    },
    isActionLinkType() {
      return this.item.type === 'action_link';
    },
    isDeleteType() {
      return this.item.type === 'delete';
    },
    isDividerType() {
      return this.item.type === 'divider';
    },
    isFieldType() {
      return (
        !this.isCopyType
        && !this.isLinkType
        && !this.isActionLinkType
        && !this.isDividerType
      );
    },
    hasField() {
      return (this.isFieldType || (this.isLinkType && this.item.link_type === 'field'));
    },
    linkTypeIsText() {
      return this.isLinkType && this.item.link_type === 'text';
    },
    linkTypeIsField() {
      return (this.isLinkType || this.isDeleteType) && this.item.link_type === 'field';
    },
    isConnectionField() {
      return this.field?.isConnection() ?? false;
    },
    isFieldFromConnectedObject() {
      return this.item.connection;
    },
    connection() {
      const connFieldKey = (this.isFieldFromConnectedObject)
        ? this.item.connection.key
        : this.field?.key;

      return this.$store.getters.getFieldWithObject(connFieldKey);
    },
    connectionField() {
      return this.connection.field;
    },
    connObject() {
      if (this.isConnectionField || this.isFieldFromConnectedObject) {
        // if this connection is on this view's object,
        // the object this field connects to is stored in the field relationship
        if (this.connectionField.objectKey === this.viewObjectKey) {
          return this.$store.getters.getObject(this.connectionField.get('relationship').object);
        }

        return this.connection.object;
      }

      return null;
    },
    fieldNameConnection() {
      if (this.isConnectionField || this.isFieldFromConnectedObject) {
        return (this.connObject) ? this.connObject.name : '';
      }

      return '';
    },
    viewObjectKey() {
      return this.$store.getters.activeView.source.object;
    },
    fieldNameThumb() {
      if (!this.isThumb || !this.field) {
        return '';
      }

      return this.field.getThumbNameFromKey(this.item.key);
    },
    isConnectionToMany() {
      const relationship = this.connectionField.get('relationship');

      // the connection is on this object, so use `has`
      if (this.connectionField.objectKey === this.object?.key) {
        return relationship.has === 'many';
      }

      // otherwise the connection field is on the connected object, so use `belongs_to`
      return relationship.belongs_to === 'many';
    },
    connectedPages() {
      return this.$store.getters.getDetailPagesByObject(this.connObject.key);
    },
    isParagraphField() {
      return this.field?.isParagraph() ?? false;
    },
    isAddress() {
      return this.field?.type === 'address';
    },
    isThumb() {
      if (!this.field) {
        return false;
      }

      const thumbKey = this.item.key.split(':')[1];

      if (thumbKey && this.item.type !== 'scene_link') {
        return true;
      }

      return false;
    },
    linkedPage() {
      if (!this.item.scene.name) {
        const page = this.$store.getters.getPageBySlug(this.item.scene);

        return page?.name || '';
      }

      return this.item.scene.name;
    },
    linkedPageKey() {
      if (this.item.scene.name) {
        return undefined;
      }

      const page = this.$store.getters.getPageBySlug(this.item.scene);

      return page?.key;
    },
    styles() {
      return this.item.format?.styles || [];
    },
    showStylesWarning() {
      // Only show this warning for links
      if (!this.isLinkType) {
        return false;
      }

      // Show if any style has been activated
      if (!isEmpty(this.initialStyles) || !isEmpty(this.item.format.styles)) {
        return true;
      }

      return false;
    },
  },
  watch: {
    'item.link_type': function (newVal) {
      if (newVal === 'field' && !this.item.key && this.object) {
        this.item.key = this.object.fields[0].key;
      }
    },
    'item.format.label_custom': function (value) {
      if (value === false) {
        delete this.item.format.label_format;
      }
    },
    '$route.path': function (value) {
      // reset initial styles
      this.setInitialStyles();
    },
  },
  created() {
    this.setInitialStyles();
  },
  methods: {
    setInitialStyles() {
      this.initialStyles = cloneDeep(this.item.format?.styles || []);
    },
    toggleStyle(style) {
      if (this.item.format.styles.indexOf(style) > -1) {
        this.item.format.styles.splice(this.item.format.styles.indexOf(style), 1);
      } else {
        this.item.format.styles.splice(0, 0, style);
      }

      // remove H1
      if (style === 'h1') {
        this.item.format.styles = this.item.format.styles.filter((style) => style !== 'h2');
      }

      if (style === 'h2') {
        this.item.format.styles = this.item.format.styles.filter((style) => style !== 'h1');
      }
    },
    onUpdateItemFormatProperty({ propertyName, newValue }) {
      if (propertyName === 'label_custom' && newValue) {
        this.item.format.label_format = 'left';
      }

      this.item.format[propertyName] = newValue;
    },
    setCustomViewLinkDesign() {
      const appDesign = this.$store.getters.app.get('design');

      // get the existing design values from app design for the specific link type
      if (this.isLinkType) {
        this.item.link_design = cloneDeep(appDesign.general.views.links.page);
      } else if (this.isActionLinkType) {
        this.item.link_design = cloneDeep(appDesign.general.views.links.action);
      } else if (this.isDeleteType) {
        this.item.link_design = cloneDeep(appDesign.general.views.links.delete);
      }
    },
  },
};
</script>

<style lang="scss">
.details-style-options {
  display: flex;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: .35em;
    border: 1px solid #d6d6de;
    height: 2em;
    line-height: 2em;
    width: 2em;
    margin-left: 5px;
    padding: 5px 0 5px 0;

    b {
      font-weight: 500;
    }

    &.active {
      border: 1px solid #771b66;
    }

    svg {
      height: 18px;
      width: 18px;
    }
  }

  a:first-child {
    margin: 0;
  }
}
</style>
