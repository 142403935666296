<template>
  <div class="toggle-wrapper">
    <h3
      ref="trigger"
      :class="['toggle-trigger border-0', toggleTriggerClasses, { 'arrow_isFirst': arrowIsFirst }]"
    >
      <span class="toggle-title text-base text-default font-semibold normal-case leading-4 tracking-[0.32px]">
        <slot name="title" />
      </span>
      <div class="toggle-expand open text-default h-4 w-4">
        <Icon
          class="h-4 w-4"
          type="arrow-drop-right"
        />
      </div>
    </h3>
    <div
      ref="content"
      class="toggle-content transition px-4"
      :class="toggleContentClasses"
      @toggle="$emit(`toggle`, $event)"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import UIUtil from '@/util/UI';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
    // Shows the arrow to the left of the title, instead of at the end to the right
    arrowIsFirst: {
      type: Boolean,
      default: false,
    },
    toggleTriggerClasses: {
      type: String,
      default: '',
    },
    toggleContentClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      /* eslint-disable no-unused-vars */
      const slideToggleOpen = new UIUtil.SlideToggle(this.$refs.content, {
        trigger: this.$refs.trigger,
        isOpen: this.isOpen,
      });
    });
  },
};
</script>

<style lang="scss">
.toggle-wrapper {
  display: flex;
  flex-direction: column;
}

.toggle-expand {
  height: 20px;
  width: 20px;
  cursor: pointer;

  svg {
    display: block;
    transition: all 200ms ease-out;
    width: 20px;
    height: 20px;
  }
}

h3.toggle-trigger {
  color: $primary500;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: .625rem 0;
  border-top: 1px solid $gray100;

  > * {
    display: flex;
    align-items: center;
  }

  > span {
    @include font-h5;
  }

  &.arrow_isFirst {
    justify-content: flex-start;

    .toggle-expand {
      order: -1;
      margin-right: 2px;
      margin-left: -6px;
    }
  }
}

.toggle-trigger.open .toggle-expand svg {
  transform: rotate(90deg);
}

.toggle-wrapper.no-top-styles {
   h3.toggle-trigger {
      border-top: 0;
      padding-top: 0;
      margin-top: 0;
   }
}

.toggle-title {
  max-width: 100%;
}
</style>
