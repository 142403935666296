import Moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { EquationHelper } from '@knack/equation-helper';
import { formatters } from '@knack/formatter';
import stringList from '@/data/Strings';
import store from '@/store/index';

export function equationObject(fieldObjectKey, routeObjectKey) {
  return store.getters.objects.find((obj) => obj.key === fieldObjectKey || obj.key === routeObjectKey);
}

export function equationType(fieldLocal) {
  return fieldLocal.type === 'concatenation' ? 'text' : fieldLocal.format.equation_type;
}

export function normalizeEquationValue(value) {
  return _.isArray(value) ? convertOldEquation(value) : value;
}

export function convertOldEquation(oldEquation) {
  let newEquation = '';

  for (const oldValue of oldEquation) {
    if (oldValue.type === 'field') {
      if (!oldValue.connection) {
        newEquation += `{${oldValue.field.key}}`;
      } else {
        newEquation += `{${oldValue.connection.field}.${oldValue.field.key}}`;
      }
    } else if (oldValue.type === 'text') {
      newEquation += oldValue.text;
    } else if (oldValue.type === 'current_time') {
      newEquation += 'currentTime()';
    } else if (oldValue.type === 'current_date') {
      newEquation += 'currentDate()';
    }
  }

  return newEquation;
}

export function getRawEquationValue(value, editorFields) {
  let outputValue = value || '';

  if (value === '') {
    return outputValue;
  }

  for (const field of editorFields) {
    let { displayText } = field;

    displayText = displayText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    outputValue = outputValue.replace(new RegExp(`\\{${displayText}\\}`, 'g'), `{${field.value}}`);
  }

  // trim the output value to remove any leading or trailing whitespace
  return trim(outputValue);
}

export function getParsedEquationValue(value, editorFields) {
  let outputValue = value || '';

  if (value !== '') {
    for (const field of editorFields) {
      outputValue = outputValue.replace(new RegExp(`\\{${field.key}\\}`, 'g'), `{${field.displayText}}`);
    }
  }

  return outputValue;
}

export function buildCalc(editorValue, equationType, fieldLocal, editorFields) {
  let calc = editorValue;
  let calcExample = editorValue;

  if (equationType === 'date') {
    calc = EquationHelper.convertEquationTime(calc, fieldLocal.format.date_type, 'client');
  }

  calc = calc.replace(/currentTime\(\)/g, new Date().valueOf());
  calc = calc.replace(/currentDate\(\)/g, new Date().setHours(0, 0, 0, 0).valueOf());
  let counter = 1;

  for (const editorField of editorFields) {
    const field = store.getters.getField(editorField.key);

    if (equationType !== 'text') {
      calcExample = '';

      calc = calc.replace(new RegExp(`{${editorField.value}}`, 'g'), counter);
    } else {
      const fieldTypeDefinition = window.Knack.config.fields[field.type];
      const baseType = fieldTypeDefinition.field_type || ((fieldTypeDefinition.numeric) ? 'numeric' : undefined);
      let exampleText = '';
      let exampleTextDisplay = '';

      switch (baseType) {
        case 'numeric':

          exampleText = counter;

          if (field.type === 'date_time') {
            exampleText = Number(new Moment().add('days', counter).toDate());
          }

          break;

        case 'date':

          exampleText = Number(new Moment().add('days', counter).toDate());
          let dateFormat = '';

          if (!field.format) {
            break;
          }

          if (field.format.date_format !== 'Ignore Date') {
            dateFormat += field.format.date_format.toUpperCase();
          }

          if (field.format.time_format !== 'Ignore Time') {
            dateFormat += ` ${(field.format.time_format === 'HH:MM am') ? 'h:m a' : 'H:m'}`;
          }

          exampleText = Moment(new Date(exampleText)).format(dateFormat);

          break;

        default:

          exampleText = stringList[counter - 1];
          break;
      }

      switch (field.get('type')) {
        case 'address':
          exampleText = 'ADDRESS';
          exampleTextDisplay = 'ADDRESS';
          break;

        case 'name':
          exampleText = 'NAME';
          exampleTextDisplay = 'NAME';
          break;

        case 'link':
          exampleText = 'LINK';
          exampleTextDisplay = 'LINK';
          break;

        case 'date_time':
          exampleText = 'DATE';
          break;
      }

      calcExample = calcExample.replace(new RegExp(`{${editorField.value}}`, 'g'), exampleTextDisplay || exampleText);

      calc = calc.replace(new RegExp(`{${editorField.value}}`, 'g'), encodeURIComponent(JSON.stringify({
        formatted_value: exampleText,
        field_type: field.type,
      })));
    }

    counter++;
  }

  return {
    calc, calcExample,
  };
}

export function testEquation(calc, equationType, equationFormat) {
  let isError = false;
  let output = '';

  try {
    let equationResult = '';

    // don't continue if calc is empty. otherwise "Equation Output: undefined" is displayed
    if (isEmpty(calc)) {
      return {
        isError: true,
        output: 'Equation is empty',
      };
    }

    if (equationType !== 'text') {
      equationResult = EquationHelper.evalNumericEquation(
        calc,
        store.getters.appTimezoneFormatted,
        equationType === 'date',
      );
    } else {
      // 3rd argument actually makes up fake data for certain addresses / etc... will need to set to false later when we have fake support
      equationResult = EquationHelper.evalStringEquation(calc, true, true, store.getters.appTimezoneFormatted);
    }

    const { date_result } = equationFormat;

    if (equationType === 'numeric' || (equationType === 'date' && date_result === 'number')) {
      output = formatters.number(equationResult, equationFormat);
    } else if (equationType === 'date') {
      output = formatters.date(equationResult, equationFormat);
    }
  } catch (error) {
    let errorText = error.toString();

    // try to clean up the error text a little bit
    if (errorText.indexOf('TypeError:') > -1) {
      errorText = errorText.split('TypeError: ')[1];
    } else if (errorText.indexOf('Error:') > -1) {
      errorText = errorText.split('Error: ')[1];
    }

    if (errorText.indexOf('(expected') > -1) {
      errorText = errorText.split('(expected')[0];
    }

    if (errorText.indexOf('(char') > -1) {
      errorText = errorText.split('(char')[0];
    }

    if (errorText.indexOf('format.trim') > -1) {
      errorText = 'formatDate function requires a date format parameter.';
    }

    isError = true;
    output = errorText;
  }

  return {
    isError, output,
  };
}
