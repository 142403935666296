<template>
  <div>
    <Popover
      placement="bottom-start"
      boundary="body"
      class="w-auto"
    >
      <template #trigger>
        <div
          v-tippy
          content="Merge the values from Knack fields into your content"
          class="dropdown-trigger"
        >
          <div v-if="triggerText">
            <div
              data-cy="fields-list-dropdown"
              class="equation-editor-button bg-white h-10 py-2 px-3 inline-flex items-center rounded-lg"
            >
              <label
                class="mb-0 text-base font-semibold"
                :style="{color: triggerTextColor}"
              >
                {{ triggerText }}
              </label>
              <div class="equation-editor-icon-wrapper">
                <Icon
                  class="arrow-icon text-default"
                  type="arrow-drop-down"
                />
              </div>
            </div>
          </div>
          <div
            v-else
            class="button-wrapper"
          >
            <Icon
              class="icon-merge-field"
              type="merge-field"
            />
            <span
              v-if="textButton"
              class="button-text"
            >
              Merge Fields
            </span>
            <Icon
              class="icon-expand"
              type="arrow-drop-down"
            />
          </div>
        </div>
      </template>
      <template #content>
        <div
          class="shadow-none p-0 w-min"
          :class="{ 'field-list-dropdown': true }"
        >
          <div class="search-wrapper p-0 w-fit">
            <TextInputSearch
              v-model="searchText"
              v-focus
              placeholder="Filter by field name"
              css-class="modal__search"
              class="mb-1 w-max"
              :autocomplete="false"
            />
            <Icon
              class="search-icon top-[5px]"
              type="filter-indicator"
            />
          </div>
          <ul
            class="field-list-dropdown-items border-t-gray-100 p-0"
            style="max-width: 400px;"
          >
            <li
              v-for="(listItems, listItemIndex) of filteredList(searchText, list)"
              :key="listItemIndex"
              class="field-list-dropdown-item"
            >
              <div
                v-for="(item, itemIndex) of listItems"
                :key="itemIndex"
              >
                <template v-if="item.fields">
                  <div
                    v-for="(subItem) of item.fields"
                    :key="`${item.conn.key}.${subItem.key}`"
                    v-close-popper
                    class="field-list-dropdown-item-cell rounded hover:bg-brand-50 text-default group"
                    @click="handleConnectionItemClick(`{${item.name}.${subItem.name}}`)"
                  >
                    <TypeIcon
                      :field="subItem"
                      class="text-default"
                      style="position: relative; top: 3px;"
                    />
                    <span class="field-list-dropdown-sublist-item text-base group-hover:text-emphasis">
                      {{ item.name }} > {{ subItem.name }}
                    </span>
                  </div>
                </template>
                <div
                  v-else
                  v-close-popper
                  class="field-list-dropdown-item-cell rounded hover:bg-brand-50 text-default group"
                  @click="handleItemClick(item.name)"
                >
                  <TypeIcon
                    :field="item"
                    class="text-default"
                    style="position: relative; top: 3px;"
                  />
                  <span class="field-list-dropdown-sublist-item text-base group-hover:text-emphasis">
                    {{ item.name }}
                  </span>
                </div>
              </div>
            </li>
            <div
              v-if="isListEmpty(filteredList(searchText, list))"
              class="p-2"
              style="padding-left: 6px;"
            >
              {{ emptyText }}
            </div>
          </ul>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script>
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import { mapGetters } from 'vuex';
import Popover from '@/components/ui/Popover';
import TypeIcon from '@/components/fields/TypeIcon';
import Icon from '@/components/ui/Icon';
import TextInputSearch from '@/components/builder/inputs/Text';

export default {
  name: 'FieldsListDropdown',
  components: {
    TextInputSearch,
    Popover,
    TypeIcon,
    Icon,
  },
  props: {
    excludeManyConnections: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: () => ({}),
    },
    uppercase: {
      type: Boolean,
      default: true,
    },
    additionalOptions: {
      type: Array,
      default: () => [],
    },
    emptyText: {
      type: String,
      default: 'No results',
    },
    showConnectionFields: {
      type: Boolean,
      default: true,
    },
    textButton: {
      type: Boolean,
      default: false,
    },
    triggerText: {
      type: String,
      default: '',
    },
    triggerTextColor: {
      type: String,
      default: 'royalblue',
    },
    filterFunction: {
      type: Function,
      default: () => true,
    },
  },
  emits: [
    'click',
  ],
  data() {
    return {
      searchText: '',
    };
  },
  computed: {
    ...mapGetters([
      'activeObject',
    ]),
    additionalOptionItems() {
      return this.additionalOptions.map((option) => ({
        name: option.name || option,
      }));
    },
    parentObjectName() {
      const listItem = flatten(this.list)[0];

      if (isArray(listItem)) {
        return listItem[0].parentObject.name;
      }

      return listItem.parentObject.name;
    },
    currentObjectName() {
      return this.fieldObject.name;
    },
    fieldObject() {
      if (this.activeObject === false || isEmpty(this.activeObject)) {
        return this.object;
      }

      return this.activeObject;
    },
    list() {
      let connectedObjectsFields = [];

      if (this.showConnectionFields) {
        // Exclude any connections that connect to many records
        const excludeOptions = {};

        if (this.excludeManyConnections) {
          excludeOptions.has = 'many';
        }

        connectedObjectsFields = this.fieldObject.getConnectedFields(excludeOptions);

        connectedObjectsFields = connectedObjectsFields.map((connectedObject) => {
          connectedObject.fields = connectedObject.fields.filter(this.filterFunction);

          return connectedObject;
        });
      }

      // TODO:: what is isConnected? This doesn't exist on the field model
      let unConnectedFields = this.fieldObject.getFields().filter((field) => !field.isConnected).filter(this.filterFunction);

      if (this.fieldObject.hasPasswordField()) {
        // Do not display "Password" as a dropdown option because
        // password field values are encrypted and are not stored in any readable form.
        // i.e. do not merge password field value into any content.
        unConnectedFields = unConnectedFields.filter((field) => !field.isPassword());
      }

      const fieldsList = [
        unConnectedFields,
        connectedObjectsFields,
        this.additionalOptionItems,
      ];

      return fieldsList;
    },
  },
  methods: {
    handleConnectionItemClick(value) {
      if (this.uppercase) {
        value = value.toUpperCase();
      }

      this.$emit('click', value);

      this.searchText = '';
    },
    handleItemClick(value) {
      if (this.uppercase) {
        value = value.toUpperCase();
      }

      this.$emit('click', `{${value}}`);

      this.searchText = '';
    },
    filteredList(searchText, listItems) {
      if (!searchText) {
        return listItems;
      }

      const filteredList = listItems.map((item) => {
        if (item.some((child) => child.fields)) {
          return item.map((subItem) => {
            subItem.fields = subItem.fields.filter(this.filterBySearch(item, searchText)).filter((item) => !isEmpty(item));

            return subItem;
          }).filter((item) => !isEmpty(item.fields));
        }

        return item.filter(this.filterBySearch(item, searchText)).filter((item) => !isEmpty(item));
      });

      return filteredList;
    },
    filterBySearch(item, searchText) {
      return (item) => this.isInSearch(item.name, searchText);
    },
    isInSearch(item, search) {
      return item.toLowerCase().includes(search.toLowerCase());
    },
    isListEmpty(list) {
      return isEmpty(list);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-trigger {
  cursor: pointer
}

.field-list-dropdown {
  padding: 4px 0 10px;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
  @include font-body;

  .field-icon {
    margin-right: 3px;
  }

  &-items {
    min-width: 176px;
    max-height: 176px;
    overflow-y: auto;
    border-top: 1px solid $gray200;
    padding-top:.25rem;
    padding-bottom:.25rem;
    display:flex;
    flex-direction:column;
  }

  &-item {
    cursor:pointer;
    display: block;
    // padding: 0px 15px;
    color: #000;
    text-decoration: none;

    &-cell {

      padding: .3125rem .5rem .375rem;

      &:hover {
        background-color: $gray100;
        transition: .2s;

        * {
          color: $fuchsia-text;
        }
      }

      svg {
        width: 18px !important;
        height: 18px !important;
        position: relative;
        top: 3px;
        margin-right: 3px;
        display: inline-flex;
        align-items: center;
        color: $gray500;
      }
    }

    &-subcell {
      padding: 0px 8px;
    }
  }

  &-sublist > div {

    &:hover {
      background-color:#ededed;
      transition: .2s;

      * {
        color: $fuchsia-text;
      }
    }
  }

  &-sublist-item {
    padding: 4px 10px;
    cursor: pointer;
    color: $text;
  }
}

.field-list-dropdown-item-cell > div > span {
  display: block;
  width: 100%;
}

.connection-item {
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:100%;
}

span .icon-connection {
  width: 16px;
  height:10px;
  padding-right:5px;
}

span .arrow-icon {
  width: 15px;
  height: 22px;
  color: #aaa;
}

span .icon-expand {
  width: 15px;
  height: 22px;
  margin-left:0px;
  color: #999;
}

.search-wrapper {
  padding: .5rem;
  position: relative;
}

.search-icon {
  position: absolute;
  right: 20px;
  width: 16px;
  color: #888;
  top: 4px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 33px;

  svg {
    max-height: 18px;
  }

  svg:nth-of-type(2) {
    margin-left: -12px;
  }
}
span .icon-merge-field {
  height: 19px;
  width: 19px;
  margin-right:1px;
  color:#777;
}

.button-text {
  margin: 0 4px;
}

.field-list-dropdown-item-cell header {
  position:relative;
}

.connection-item {

  position:relative;

  svg {
    max-height: 18px;
  }
}

.equation-editor-button {
  width: 130px;
  margin: 0 4px;
  background-color: #fff;
  border-radius: 2px;
  padding: 2px 4px;
  padding-left:6px;
  font-size: .8rem;
  display:inline-flex;
  align-items:center;
  justify-content:space-between;

  & label {
    margin-bottom:0;
  }
}

.equation-editor-icon-wrapper {
  color: #888;
  max-height: 18px;

  svg {
    max-height: 18px;
    max-width: 18px;
  }
}
</style>
