<template>
  <div
    id="schema"
    class="main-wrapper"
  >
    <RouterView name="modal" />
    <RouterView name="body" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    activeObject() {
      if (this.$route.params.objectKey) {
        return this.$store.getters.activeObject;
      }

      return false;
    },
  },
};
</script>
