/**
 * Maps apiForm fields as simple getter/setters.
 * This is for use in computed for vue components.
 *
 * @param {string} namespace
 * @param {string[]} computed
 * @returns {Object<string, Object>}
 */
export const mapApiFormGetters = (namespace, computed) => {
  const lookup = `${namespace}/getFormValue`;
  const setter = `${namespace}/updateForm`;

  const safeComputedMap = _formatComputedMap(computed);

  return safeComputedMap.reduce((final, [mapTo, mapFrom]) => {
    final[mapTo] = {
      vuex: true,
      get() {
        return this.$store.getters[lookup](mapFrom);
      },
      set(newValue) {
        return this.$store.dispatch(setter, {
          key: mapFrom,
          newValue,
        });
      },
    };

    return final;
  }, {});
};

/**
 * Formats the computed map to always be an array of mapFrom and mapTo.
 *
 * @param {string[]|Object<string, string>} computed
 * @returns {[string, string]}
 * @private
 */
function _formatComputedMap(computed) {
  if (Array.isArray(computed)) {
    return computed.map((mapFrom) => [
      mapFrom,
      mapFrom,
    ]);
  }

  return Object.keys(computed).map((mapTo) => [
    mapTo,
    computed[mapTo],
  ]);
}
