<template>
  <div class="notification border-0 rounded-lg bg-[#FEF4D7]">
    <div
      v-if="titleIcon"
      class="mb-2"
    >
      <Icon
        class="text-warning-emphasis"
        :type="titleIcon"
      />
    </div>
    <div
      v-if="title"
      class="notification__title"
    >
      <span v-html="title" />
    </div>
    <p class="notification__copy">
      <slot />
    </p>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

const CONFIRM_TYPES = new Set([
  'info', // yellow
  'warn', // red
]);

export default {
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Warning',
    },
    copy: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: CONFIRM_TYPES.warn,
    },
  },
  computed: {
    titleIcon() {
      if (this.icon) {
        return this.icon;
      }

      if (this.type === CONFIRM_TYPES.warn) {
        return 'exclamation-triangle';
      }

      return 'info-circle';
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  padding: 1em;
  background: #fff9db;
  border: 1px solid #ffdf98;
  border-radius: .25rem;

  svg {
    color: #ffc14e;
    height: 22px;
    width: 22px;
    margin-right: 4px;
  }
}

.notification__title {
  font-size: 1.0125rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.notification__copy {
  font-size: 14px;
}

.notification:deep p:last-child {
  margin-bottom: 0;
}

.notification--wrapperWhite {
  background: white;
  border-color: $gray200;
}
</style>
