<template>
  <Modal
    title="Dismiss Get Started Guide"
    size="medium"
    @close="onCancel"
  >
    <p>
      If you decide to come back, the Get Started button is always
      there for you on the top right to resume from where you left off.
    </p>
    <div class="flex justify-end gap-4">
      <button
        class="p-3 rounded-lg bg-transparent border-0 text-emphasis font-medium m-0 leading-4"
        @click="onCancel"
      >
        Cancel
      </button>
      <button
        class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base font-medium m-0 leading-4"
        @click="onClose"
      >
        Close Guide
      </button>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/components/ui/Modal';

export default {
  components: {
    Modal,
  },
  emits: ['cancel', 'close'],
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onClose() {
      this.$emit('close');
    },
  },
};

</script>
