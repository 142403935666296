<template>
  <view-toolbox
    back-title="Checkout"
    :back-count="8"
    title="Item Properties"
  >
    <DetailProperties
      :item="item"
    />
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DetailProperties from '@/components/views/shared/DetailProperties';

export default {
  name: 'DetailsItemProperties',
  components: {
    ViewToolbox,
    DetailProperties,
  },
  mixins: [
    ViewUtils,
  ],
  data() {
    return {
      styleOptions: [
        {
          value: 'h1', label: '<b>H1</b>', tip: 'Add a large header style',
        },
        {
          value: 'h2', label: '<b>H2</b>', tip: 'Add a large header style',
        },
        {
          value: 'strong', label: '<b>b</b>', tip: 'Add a bold style',
        },
        {
          value: 'em', label: '<i>i</i', tip: 'Add an italics style',
        },
      ],
    };
  },
  computed: {
    item() {
      const {
        layoutIndex,
        groupIndex,
        columnIndex,
        itemIndex,
      } = this.$route.params;

      return this
        .viewRaw
        ?.checkout_page
        ?.columns[layoutIndex]
        ?.groups[groupIndex]
        ?.columns[columnIndex][itemIndex];
    },
  },
  watch: {
    'item.link_type': function itemLinkTypeWatcher(newVar) {
      if (newVar === 'field' && !this.item.link_field) {
        this.item.link_field = this.object.fields[0].key;
      }
    },
  },
};
</script>

<style lang="scss">
</style>
