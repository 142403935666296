<template>
  <view-toolbox
    back-title="Map Menu"
    title="Design"
  >
    <div>
      <label for="title">
        Title
      </label>
      <input
        v-model="viewRaw.title"
        type="text"
        name="title"
      >
    </div>
    <div>
      <label for="title">
        Description
      </label>
      <textarea
        v-model="viewRaw.description"
        name="description"
      />
    </div>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'MapDesign',
  components: {
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
};
</script>
