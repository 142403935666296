<template>
  <ViewToolbox
    back-title="Payment Rules"
    title="Submit Rules"
  >
    <p
      class="text-sm leading-5 mb-0"
      style="margin-bottom: 1em;"
    >
      Add rules for where to direct the user after the payment is submitted. The first rule that matches will be used.
    </p>
    <SubmitRules />
  </ViewToolbox>
</template>

<script>
import SubmitRules from '@/components/views/shared/SubmitRules';
import ViewToolbox from '@/components/views/ViewToolbox';

export default {
  components: {
    SubmitRules,
    ViewToolbox,
  },
};
</script>

<style lang="scss">
</style>
