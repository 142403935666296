import dragula from 'dragula';
import get from 'lodash/get';

import store from '@/store';

// The singleton instance of dragula for pages.
let pageDrag = null;

const onDrop = async (el, target) => {
  const movedPageKey = get(el, 'attributes.data-key.value');

  const sortedPageKeys = Array.from(
    target.childNodes,
    (node) => get(node, 'attributes.data-key.value'),
  );

  await store.dispatch(
    'page/api/updatePagesOrder',
    { movedPageKey, sortedPageKeys },
  );
};

export const pageDragSetup = () => {
  // Set up page sorting
  pageDrag = dragula([], {
    accepts: (el, target, source) => target === source,
  });

  pageDrag.on('drop', onDrop);
};

export const pageDragAddContainer = ($el) => {
  const containerExists = pageDrag?.containers?.includes($el);
  if (!pageDrag || containerExists) {
    return;
  }

  pageDrag.containers.push($el);
};

export const pageDragTeardown = () => {
  pageDrag?.off('drop', onDrop);
  pageDrag = null;
};
