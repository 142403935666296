<template>
  <a
    class="add-view-slot transition"
    :class="classObject"
    @click="onClick"
  >
    <div class="line">
      <div
        v-tippy
        content="Add a new view at this location"
        class="plus"
      >
        <svg
          viewBox="0 0 512 512"
          class="icon-plus"
          style="width: 12px; height: 12px;"
        >
          <path d="m457 210l0 55c0 8-3 14-8 20-5 5-12 8-19 8l-119 0 0 118c0 8-3 15-8 20-5 5-12 8-20 8l-54 0c-8 0-15-3-20-8-5-5-8-12-8-20l0-118-119 0c-7 0-14-3-19-8-5-6-8-12-8-20l0-55c0-7 3-14 8-19 5-5 12-8 19-8l119 0 0-119c0-8 3-14 8-19 5-6 12-8 20-8l54 0c8 0 15 2 20 8 5 5 8 11 8 19l0 119 119 0c7 0 14 3 19 8 5 5 8 12 8 19z" />
        </svg>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    orientation: {
      type: String,
      default: '',
    },
    first: {
      type: String,
      default: '',
    },
    last: {
      type: String,
      default: '',
    },
  },
  emits: [
    'click',
  ],
  computed: {
    classObject() {
      return {
        horizontal: this.orientation === 'horizontal',
        vertical: this.orientation === 'vertical',
        first: this.first,
        last: this.last,
      };
    },
  },
  methods: {
    onClick(event) {
      return this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss">
.add-view-slot {
  position: absolute;
  overflow: visible;
  display: block;
  opacity: 1;

  .plus {
    opacity: 0;
    background-color: #2ab27b;
    border-radius: 5px;
    position: absolute;
    height: 18px;
    width: 18px;
    line-height: 18px;
    text-align: center;
    color: #fff;

    svg {
      vertical-align: middle;
    }
  }

  .line {
    position: absolute;
  }

  &.horizontal {
    height: 20px;
    margin: 2px 15px;
    left: 0;
    right: 0;

    .line {
      border-top: dashed 1px rgba(215, 214, 218, 0);
      top: 10px;
      width: 100%;
    }

    .plus {
      top: -9px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.vertical {
    width: 20px;
    top: 0;
    bottom: 0;
    margin: 2px 0px;

    .line {
      border-left: dotted 1px rgba(215, 214, 218, 0);
      right: 10px;
      height: 100%;
    }

    .plus {
      left: -9px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.add-view-slot:hover {

  &.horizontal .line {
    border-top: 1px solid #d7d9de;
  }
  &.vertical .line {
    border-left: 1px solid #d7d9de;
  }
  .plus {
    opacity: 1;
  }
}

.add-view-slot.active {

  &.horizontal .line {
    border-top: 1px solid #2ab27b;
  }
  &.vertical .line {
    border-left: 1px solid #2ab27b;
  }

  .plus {
    background-color: #2ab27b;
    opacity: 1;
  }
}
</style>
