<template>
  <div class="control kn-inputWrapper">
    <div
      v-if="isMultipleSelect"
      :id="`connection-picker-chosen-${key}`"
    >
      <MultiSelect
        v-if="!isAjax"
        ref="multiSelect"
        v-shortkey.once="['esc']"
        :model-value="localValue"
        data-cy="connection-picker"
        :class="{ 'kn-multiSelect-single': !localMultiple }"
        :close-on-select="true"
        :custom-label="customLabel"
        :disabled="isDisabled"
        :hide-selected="localMultiple"
        :loading="isDisabled"
        :multiple="localMultiple"
        :options="localOptions"
        :show-labels="false"
        class="kn-multiSelect"
        label="identifier"
        placeholder="Select..."
        track-by="id"
        @close="onClose"
        @open="onOpen"
        @remove="onRemoved"
        @select="onSelected"
        @shortkey="close"
      >
        <template #option="{option}">
          <div
            class="option__desc"
            data-cy="connection-picker-option"
            v-html="option.identifier"
          />
        </template>
      </MultiSelect>
      <MultiSelect
        v-else
        ref="multiSelect"
        v-shortkey.once="['esc']"
        :model-value="localValue"
        data-cy="connection-picker"
        :class="{ 'kn-multiSelect-single': !localMultiple }"
        :clear-on-select="true"
        :close-on-select="true"
        :custom-label="customLabel"
        :hide-selected="localMultiple"
        :internal-search="false"
        :loading="isDisabled"
        :multiple="localMultiple"
        :options="localOptions"
        :searchable="true"
        :show-labels="false"
        :show-no-results="false"
        class="kn-multiSelect"
        label="identifier"
        open-direction="bottom"
        placeholder="Type to filter"
        track-by="id"
        @close="onClose"
        @open="onOpen"
        @remove="onRemoved"
        @search-change="handleSearchChange"
        @select="onSelected"
        @shortkey="close"
      >
        <template #option="{option}">
          <div
            class="option__desc"
            data-cy="connection-picker-option"
            v-html="option.identifier"
          />
        </template>
        <template #noOptions>
          <div>...</div>
        </template>
      </MultiSelect>
    </div>
    <div
      v-else
      :id="`connection-picker-checkbox-${key}`"
      class="conn_inputs"
    >
      <div class="control kn-inputWrapper">
        <div
          v-if="isDisabled"
          class="control--isLoading"
        >
          loading...
        </div>

        <template v-else>
          <Checkboxes
            v-if="localInputType === `checkbox`"
            :options="optionsForCheckboxes"
            :model-value="localValueForCheckboxes"
            @update:model-value="onCheckboxChange"
          />

          <Radios
            v-else
            :name="key"
            :options="optionsForCheckboxes"
            :model-value="localValueForCheckboxes"
            @update:model-value="onCheckboxChange"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import castArray from 'lodash/castArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import MultiSelect from 'vue-multiselect';
import { mapGetters } from 'vuex';

import Checkboxes from '@/components/renderer/form/inputs/MultipleChoiceCheckboxes';
import Radios from '@/components/renderer/form/inputs/Radios';
import RequestUtils from '@/components/util/RequestUtils';
import { FIELD_DEFAULT_REPLACE_WITH_FIRST } from '@/constants/field';
import Api from '@/lib/api-wrapper';

export default {
  name: 'ConnectionInput',
  components: {
    Checkboxes,
    Radios,
    MultiSelect,
  },
  mixins: [
    RequestUtils,
  ],
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      default: () => ({}),
    },
    inputType: {
      type: String,
      default: () => '',
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: [Object, Array, String, Number],
      default: () => ([]),
    },
    multiple: {
      type: Boolean,
      default: () => undefined,
    },
    filters: {
      type: Array,
      default: () => ([]),
    },
    options: {
      type: Array,
      default: null,
    },
    useSelectInput: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['loaded', 'connection', 'update:modelValue'],
  data() {
    return {
      isLoading: null,
      isAjax: false,
      localOptions: [],
      localValue: [],
      localValueForCheckboxes: [],
    };
  },
  computed: {
    ...mapGetters([
      'getField',
    ]),
    localMultiple() {
      if (!isNil(this.multiple)) {
        return this.multiple;
      }

      return this.fieldRaw?.relationship?.has === 'many';
    },
    optionsForCheckboxes() {
      return this.localOptions.map((option) => ({
        value: option.id,
        label: option.identifier,
      }));
    },

    fieldRaw() {
      return this.field.raw();
    },
    view() {
      return {};
    },
    key() {
      return !isNil(this.field) ? this.field.key : this.input.key;
    },
    isDisabled() {
      return this.isLoading === true;
    },
    isCheckboxOrRadio() {
      const isCheckboxOrRadioInput = (this.localInputType === 'checkbox' || this.localInputType === 'radio');

      const hasAppropriateAmountOfOptions = this.localOptions.length < 30;

      return isCheckboxOrRadioInput && hasAppropriateAmountOfOptions && this.isAjax === false;
    },
    localInputType() {
      if (this.inputType) {
        return this.inputType;
      }

      return this.field?.format?.input;
    },
    isMultipleSelect() {
      return this.useSelectInput || !this.isCheckboxOrRadio;
    },
    fieldRawObject() {
      return get(this.fieldRaw, 'relationship.object', this.field.objectKey);
    },
    connectionDisplayFieldDoesNotLimit() {
      const actualDisplayField = this.getField(this.field.getConnectedDisplayFieldKey());

      return ['connection', 'number'].includes(actualDisplayField?.type);
    },
  },
  watch: {
    async modelValue(newValue) {
      this.isLoading = true;
      await this.parseValues(newValue);
      this.isLoading = false;
    },
  },
  async created() {
    // Make sure to show the spinner while the options and values are loading.
    this.isLoading = true;

    // Make sure to parse the options first in case "replace with first" exists in the values.
    // This will fetch the connection options from the server if they weren't passed in.
    const options = this.options ?? (
      await this.fetchConnectionOptions(this.filters)
    );

    // Set the options locally after scrubbing them.
    // We don't want to add the values because we haven't parsed them yet!
    this.setLocalOptions(options, { skipAddingValues: true });

    await this.parseValues(this.modelValue);

    this.addValuesToOptions();

    this.isLoading = false;

    // Fires when this component is initially loaded and ready to be used.
    // Consumed by FormInput.
    this.$emit('loaded', this.input, this.localValue);
  },
  methods: {
    /**
     * Parses the connection values into local values that we can use.
     *
     * @param {array | object | string | Symbol | undefined} propValues
     * @returns {Promise<void>}
     */
    async parseValues(propValues) {
      /** @type {Array<object | string | Symbol | undefined>} incomingValues */
      const incomingValues = castArray(propValues);

      // If any of the given values were defaults, then resolve the defaults before parsing.
      // Make sure to do this after the options have been set.
      const idValues = this.parseDefaultValues(incomingValues).filter(Boolean);

      this.localValue = await this.parseIdentifiers(idValues);

      if (!this.isMultipleSelect) {
        // For checkbox and radio connection picking, we need to map each value to just its id.
        this.localValueForCheckboxes = this.localValue.map(
          (value) => value.id,
        );
      }
    },

    /**
     * Adds local values to the options list if they don't exist there already.
     * We want to do this even if the local values don't match any search criteria or if they
     * were not returned as records in order to improve the user experience where they always
     * at least see the records they have selected.
     */
    addValuesToOptions() {
      const newOptions = this.localValue.reduce((final, localValue) => {
        const isValueInOptions = this.localOptions.find(({ id }) => id === localValue.id);

        if (!isValueInOptions) {
          final.push(localValue);
        }

        return final;
      }, []);

      const scrubbedOptions = this.scrubOptions(newOptions);

      this.localOptions = [
        ...this.localOptions,
        ...scrubbedOptions,
      ];
    },

    /**
     * Checks for the default value of 'replace with first'. If found, the first local option
     * is chosen and the value is set to that.
     *
     * @param {Array<object | string | Symbol | undefined>} values
     * @returns {Array<string | {id: string} | undefined>}
     */
    parseDefaultValues(values) {
      // A value of FIELD_DEFAULT_REPLACE_WITH_FIRST means the value should be replaced with
      // the first option after the options have loaded, so replace it here.

      // A value of 'first' is deprecated and should no longer be used. It can be removed if
      // we know it is no longer being used.

      return values.map((value) => {
        if (value !== FIELD_DEFAULT_REPLACE_WITH_FIRST && value !== 'first') {
          return value;
        }

        const newValue = this.localOptions?.[0];
        if (!newValue?.id) {
          return undefined;
        }

        return {
          id: newValue.id,
        };
      });
    },

    /**
     * Gets the record identifiers (labels/names) from a list of record ids.
     *
     * @param {string[]} recordIds
     * @returns {Promise<string[]>}
     */
    async getIdentifierFromIds(recordIds) {
      const defaultIdentifier = '';

      // localValues exist in the options so we don't need to ask the server.
      // fetchIds were not found in the options and so we will ask the server for the identifier.
      const localValues = [];
      const fetchIds = [];

      recordIds.forEach((recordId) => {
        const matchingOption = this.localOptions.find((option) => option.id === recordId);

        if (isNil(matchingOption?.identifier)) {
          fetchIds.push(recordId);
          return;
        }

        localValues.push({
          id: recordId,
          identifier: matchingOption.identifier,
        });
      });

      if (isEmpty(fetchIds)) {
        return localValues;
      }

      let records;
      try {
        const objectKey = this.field.relationship.object;
        const response = await Api.getRecordIdentifiers(objectKey, fetchIds);

        records = response.records;
      } catch (getError) {
        records = fetchIds.map((fetchId) => ({
          id: fetchId,
          identifier: defaultIdentifier,
        }));
      }

      return [
        ...localValues,
        ...records,
      ];
    },

    /**
     * Parses the identifiers for each id value.
     * The identifiers are the human readable labels while the ids are the mongo ids of the record.
     *
     * @param {Array<string | {id: string}>} values
     * @returns {Promise<Array<{id: string, identifier: string}>>}
     */
    async parseIdentifiers(values) {
      if (isEmpty(values)) {
        return [];
      }

      // The value is either an object with the id, or it is a string that is the id.
      const valueRecordIds = values
        .map((value) => value?.id || value)
        .filter(Boolean);

      const valuesWithIdentifiers = await this.getIdentifierFromIds(valueRecordIds);

      // Only return values with a populated id.
      return valuesWithIdentifiers.filter((value) => value?.id);
    },

    /**
     * Scrubs the given options before using them.
     *
     * @param {Array<{identifier: string}>} options
     * @returns {Array<{identifier: string}>}
     */
    scrubOptions(options) {
      return options.map((option) => {
        let newIdentifier = String(option.identifier);

        // If anchor tags are present, scrub them.
        if (newIdentifier.match(/<a[^>]*>/)) {
          newIdentifier = newIdentifier.replace(/<\/?a[^>]*>/g, '');
        }

        return {
          ...option,
          identifier: newIdentifier,
        };
      });
    },

    /**
     * Sets the local options including scrubbing and adding values.
     *
     * @param {Array<{id: string, identifier: string}>} newOptions
     * @param {object} settings
     * @param {boolean} settings.skipScrubbing
     * @param {boolean} settings.skipAddingValues
     */
    setLocalOptions(newOptions, { skipScrubbing = false, skipAddingValues = false } = {}) {
      if (skipScrubbing) {
        this.localOptions = newOptions;
      } else {
        this.localOptions = this.scrubOptions(newOptions);
      }

      if (!skipAddingValues) {
        this.addValuesToOptions();
      }
    },

    setRecordsAsOptions(response) {
      if (response.total_records > 500 && !this.connectionDisplayFieldDoesNotLimit) {
        this.isAjax = true;
      }

      const records = response?.records ?? [];

      return records.map((record) => ({
        id: record.id,
        identifier: record.identifier,
      }));
    },

    /**
     * Handles when a user searches for ajax record(s).
     *
     * @param {string} searchQuery
     * @returns {Promise<void>}
     */
    async handleSearchChange(searchQuery) {
      if (searchQuery.trim().length < 2) {
        return;
      }

      this.isLoading = true;

      const filters = [
        {
          field: this.field.getConnectedDisplayFieldKey(),
          operator: 'contains',
          value: searchQuery,
        },
      ];

      const newOptions = await this.fetchConnectionOptions(
        [].concat(this.filters, filters),
        2000,
        false,
      );

      this.setLocalOptions(newOptions);

      this.isLoading = false;
    },

    /**
     * Fetches the available options for the connections from the server.
     *
     * @param {array} filters
     * @param {number} [recordsPerPage]
     * @param {boolean} [limitReturn]
     * @returns {Promise<object[]>}
     */
    async fetchConnectionOptions(filters = [], recordsPerPage = 2000, limitReturn = true) {
      const safeLimitReturn = Boolean(
        this.connectionDisplayFieldDoesNotLimit ? false : limitReturn,
      );

      return new Promise((resolve, reject) => {
        this.commitRequest({
          request: () => Api.getConnections(
            this.fieldRawObject,
            filters,
            safeLimitReturn ? recordsPerPage : 'all_records',
            safeLimitReturn,
          ),
          onSuccess: (response) => {
            const newOptions = this.setRecordsAsOptions(response);

            resolve(newOptions);
          },
          onError: (getError) => {
            reject(getError);
          },
          globalLoading: false,
        });
      });
    },

    customLabel({ identifier }) {
      const safeIdentifier = String(identifier); // may be a number

      if (safeIdentifier.includes('<span class=')) {
        return safeIdentifier.replace(/<[^>]+>/g, '');
      }

      return safeIdentifier;
    },
    close(event) {
      if (this.$refs.multiSelect.isOpen) {
        this.$refs.multiSelect.deactivate();
        event.stopPropagation();
      }
    },
    onOpen() {
      this.$parent.$emit('connection', 'open');
    },
    onClose() {
      this.$parent.$emit('connection', 'close');
    },
    onRemoved(removedValue) {
      const newValues = this.localValue.filter(({ id }) => id !== removedValue.id);

      this.$emit('update:modelValue', newValues);
    },
    onSelected(selectedValue) {
      const selectedValues = castArray(selectedValue || '');

      const newValues = (this.localMultiple)
        ? this.localValue.concat(selectedValues)
        : selectedValues;

      this.$emit('update:modelValue', newValues);
    },
    async onCheckboxChange(newValue) {
      const newValues = await this.parseIdentifiers(
        castArray(newValue),
      );

      this.$emit('update:modelValue', newValues);
    },
  },
};
</script>
