<template>
  <div class="dropdown-picker flex bg-white h-10 p-0 max-w-full w-full border border-default rounded-lg text-base text-emphasis hover:border-emphasis leading-[22px]">
    <Popover
      :placement="placement"
      :popover-classes="['py-2', 'px-3', 'w-full']"
      allow-vertical-scroll
    >
      <template #trigger>
        <div class="dropdown-trigger w-full justify-between">
          <span class="inline-flex items-center truncate">
            <Icon
              v-if="selectedOption.icon"
              class="text-subtle"
              :type="selectedOption.icon"
            />
            <span
              class="dropdown-label"
              v-html="displayLabel(selectedOption)"
            />
          </span>
          &nbsp;
          <Icon
            type="arrow-drop-down"
            class="text-subtle ml-2 text-right"
          />
        </div>
      </template>
      <template #content>
        <div
          class="links max-h-[calc(90vh-224px)] p-0"
          style="max-height: 240px;"
        >
          <template
            v-for="(option, optionIndex) in options"
            :key="optionIndex"
          >
            <div
              v-if="option.type && option.type === `section`"
              class="dropdownPicker_section capitalize text-subtle font-medium text-sm p-2 border-t-subtle"
            >
              <span v-html="displayLabel(option)" />
            </div>
            <a
              v-else
              v-close-popper
              :data-cy="`dropdown-item-${option.value}`"
              :style="{'max-width': `${maxWidth}px`}"
              class="rounded hover:bg-brand-50 text-base text-default hover:text-emphasis p-2"
              @click="onClickOption(option)"
            >
              <Icon
                v-if="option.icon"
                :type="option.icon"
              />
              <span v-html="displayLabel(option)" />
              <span v-if="option.showEllipsis">...</span>
            </a>
          </template>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script>
import Popover from '@/components/ui/Popover';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Popover,
    Icon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [
        String,
        Number,
        Object,
      ],
      default: null,
    },
    automaticallySetDefault: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      required: false,
      default: 'bottom',
    },
    maxWidth: {
      type: Number,
      default: 400,
    },
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      dropdownValue: this.modelValue,
    };
  },
  computed: {
    selectedOption() {
      let option = this.options.find((option) => option.value === this.dropdownValue);

      // if no default is provided we need to set one
      if (!option) {
        option = this.options[0];

        // TODO: emitting default option triggers "save changes" updates.
        // Should we have a "Select..." style empty state
        // OR emit a separate default event that a consumer can choose to update on

        // let's emit so this default can be reactive to the consumer
        if (this.automaticallySetDefault) {
          this.$emit('update:modelValue', option.value, option);
        }
      }

      return option;
    },
  },
  methods: {
    displayLabel(option) {
      return option.displayLabel ?? option.label;
    },
    onClickOption(whichOption) {
      const oldValue = this.dropdownValue;

      this.dropdownValue = whichOption.value;

      if (oldValue !== this.dropdownValue) {
        this.$emit('update:modelValue', this.dropdownValue, whichOption, oldValue);
        this.$emit('change', this.dropdownValue, whichOption, oldValue);
      }
    },
  },
};
</script>

<style lang="scss">
.dropdown-picker {
  min-width: 0; // handle flex text truncate
  display: flex !important;

  .trigger {

    display: flex !important;
    min-width: 0;
  }

  .dropdown-trigger {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    min-width: 0;

    .icon {
      width: 13px;
      height: 13px;
      margin-right: 4px;
    }

    .icon.icon-arrow-drop-down {
      width: 15px;
      height: 15px;
      margin-left: -1px;
      margin-right: -2px;
      color: $fuchsia-text;
      flex-shrink: 0;
    }

    b {
      font-weight: 500;
    }
  }

  .dropdown-label {
    min-width: 0;
  }

  &.is-text {

    .dropdown-trigger {
      display: inline-flex;
      position: relative;
      cursor: pointer;
      line-height: 1.1em;
      padding: .25em .2em .15em;
      margin: 0px 3px;
      border-bottom: 1px solid #a8abb3;
      white-space: nowrap;

      &:hover {
        background: rgba(141, 144, 152, 0.15);
        border-bottom: 1px solid #8a337a;
      }
    }

    .dropdown-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.is-inline {
      display: inline !important;

    .trigger {
      display: inline !important;
    }

    .dropdown-trigger {
      display: inline;
      cursor: pointer;
      border-bottom: 1px solid #a8abb3;
      padding-left: 2px;

      &:hover {
        background: rgba(141, 144, 152, 0.15);
        border-bottom: 1px solid #8a337a;
      }

      .icon.icon-arrow-drop-down {
        margin-left: -2px;
        margin-right: 0px;
        position: relative;
        top: 2px;
      }
    }

    .dropdown-label {
    }
  }
}

.dropdownPicker_section {
  padding: 8px 12px 8px;
  border-top: 1px solid #E5E5E5;
  margin-top: 6px;

  color: #566C7F;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;

  cursor: default;
}
</style>
