// TODO: currently used to check if we should load logrocket
// update logic and break out into separate function
export const shouldEnableLogRocket = () => {
  if (process.env.VUE_APP_LOG_ROCKET_DISABLE) {
    return false;
  } if (process.env.VUE_APP_LOG_ROCKET_ENABLE) {
    return true;
  }

  return isProduction() || window.location.href.includes('v3.us.private.knack');
};

export const isProduction = () => process.env.NODE_ENV === 'production';

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const isCRM = () => window.location.hostname.includes('knackcrm.com');
