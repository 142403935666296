import Joi from '@hapi/joi';
import store from '@/store/index';

class Embed {
  constructor(embed) {
    this.attributes = embed;
  }

  get key() {
    return this.attributes.key;
  }

  set key(newValue) {
    this.attributes.key = newValue;
  }

  get name() {
    return this.attributes.name;
  }

  set name(newValue) {
    this.attributes.name = newValue;
  }

  get scene() {
    return this.attributes.scene;
  }

  set scene(newValue) {
    this.attributes.scene = newValue;
  }

  get design() {
    return this.attributes.design;
  }

  set design(newValue) {
    this.attributes.design = newValue;
  }

  validate() {
    const validationSchema = {
      _id: Joi.string().empty('').optional(),
      customized: Joi.boolean().optional(),
      default: Joi.boolean().optional(),
      domains: Joi.array().optional(),
      method: Joi.string().empty('').optional(),
      mode: Joi.string().empty('').optional(),
      code: Joi.string().empty('').optional(),
      key: Joi.string().empty('').optional(),
      name: Joi.string().invalid(store.state.app.get('distributions').filter((dist) => dist.name === this.name && dist.key !== this.key).map((dist) => dist.name)).empty('').required(),
      scene: Joi.string().required(),
      theme: Joi.string().empty('').optional(),
      design: Joi.object({
        header: Joi.object({
          display: Joi.string().required(),
        }),
        general: Joi.object({
          fonts: Joi.object({
            show: Joi.boolean().required(),
          }),
        }),
      }),
    };

    const validationOptions = {
      abortEarly: false,
      allowUnknown: true,
    };

    const validationResult = Joi.validate(this.attributes, validationSchema, validationOptions);

    return {
      error: validationResult.error,
    };
  }

  async create() {
    const response = await window.Knack.Api.createDistribution(this.name, this.scene, this.design);

    store.commit('createDistribution', response);

    return response;
  }

  async delete() {
    const response = await window.Knack.Api.deleteDistribution(this.key);

    store.commit('deleteDistribution', this.key);

    return response;
  }

  async update() {
    const response = await window.Knack.Api.updateDistribution(this.key, this.name, this.scene, this.design);

    store.commit('updateDistribution', {
      distKey: this.key,
      embedName: this.name,
      pageName: this.scene,
      design: this.design,
    });

    return response;
  }
}

export default Embed;
