<template>
  <form @submit.prevent="submitForm">
    <div
      v-for="(input, index) in inputs"
      :key="input.field.key"
      class="kn-item group/shared mb-4"
    >
      <FormInput
        :focus="index === 0"
        :input="input"
        :field="inputField(input)"
        :form-values="formValues"
        :error="localErrors[input.field.key]"
        :data-field-name="input.field.key"
        :model-value="getFormValue(input.field.key)"
        :show-name-field-hint-labels="true"
        @update:model-value="onFormInputUpdated(input.field.key, $event)"
        @loaded="onFormInputLoaded"
      />
    </div>
    <div class="margin-bottom-double -mt-2 mb-0" />
    <div class="kn-submit submit-buttons flex justify-end">
      <button
        class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium h-10"
        :class="{ disabled: isLoadingConnections }"
        :disabled="isLoadingConnections"
        data-cy="save"
        type="submit"
      >
        {{ trans('submit_button_text') }}
      </button>
    </div>
  </form>
</template>

<script>
import isNil from 'lodash/isNil';
import FormInput from '@/components/renderer/form/FormInput';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';

export default {
  name: 'FormFields',
  components: {
    FormInput,
  },
  mixins: [
    TranslationUtils,
  ],
  props: {
    inputs: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'submitForm',
  ],
  data() {
    return {
      formValues: this.values,
      connectionLoadingCount: 0,
      localErrors: {},
    };
  },
  computed: {
    object() {
      return this.$store.getters.getObject(this.$route.params.objectKey);
    },
    isLoadingConnections() {
      return this.connectionLoadingCount > 0;
    },
  },
  watch: {
    errors: {
      handler(newErrors) {
        this.localErrors = { ...this.localErrors, ...newErrors };
      },
    },
  },
  created() {
    const connectionInputs = this.inputs.filter((input) => {
      const field = this.object.getField(input.field.key);

      // Exclude read only connections from counts.
      // Connections set up by Payments are technically read only.
      return (!isNil(field) && field.type === 'connection' && !field.isReadOnly());
    });

    this.connectionLoadingCount = connectionInputs.length;
  },
  methods: {
    inputField(input) {
      if (input.field) {
        const objectField = this.object.getField(input.field.key);

        if (objectField) {
          return objectField;
        }

        // We sometimes send in fake fields, like "send an email intro" for user records
        return input.field;
      }

      return null;
    },
    setInput(input) {
      const field = this.inputField(input);

      if (field && field.format) {
        input.format = field.format;
      }

      return input;
    },
    getFormValue(fieldKey) {
      return this.formValues[`${fieldKey}_raw`] || this.formValues[fieldKey];
    },
    submitForm() {
      this.localErrors = {};
      const formErrors = [];

      this.inputs.forEach((input) => {
        const value = this.formValues[input.field.key];
        const fieldType = input.type;

        if (fieldType === 'number' || fieldType === 'currency') {
          const regex = /^[0-9. +,-:]+$/;
          if (!regex.test(value) && value !== '') {
            formErrors.push({
              field: this.inputField(input).key,
              message: `Values entered in ${fieldType} fields must be numeric`,
            });
          }
        }

        if (fieldType === 'image' && value?.asset?.content?.type === 'image/svg+xml') {
          formErrors.push({
            field: this.inputField(input).key,
            message: '.svg files are not supported',
          });
        }

        if (this.inputField(input).get('required') && !value) {
          formErrors.push({
            field: this.inputField(input).key,
            message: `${this.inputField(input).name} is required`,
            type: 'required',
          });
        }
      });

      if (formErrors.length) {
        const errors = formErrors.reduce((accumulator, error) => ({ ...accumulator, [error.field]: error }), {});

        this.localErrors = { ...this.localErrors, ...errors };
      }

      this.$emit('submitForm', this.formValues, formErrors.length ? formErrors : undefined);
    },
    onFormInputLoaded({ field }) {
      this.connectionLoadingCount--;
    },
    onFormInputUpdated(fieldKey, emittedValue) {
      if (this.formValues[`${fieldKey}_raw`]) {
        this.formValues[`${fieldKey}_raw`] = emittedValue;
      } else {
        this.formValues[fieldKey] = emittedValue;
      }
    },
  },
};
</script>
