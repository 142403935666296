<template>
  <ViewToolbox
    back-title="Edit Login"
    title="Settings"
  >
    <div>
      <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Can new users register to access this page?</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="viewRaw.registration_type"
          name="registration_type"
          type="radio"
          value="closed"
        >No</label>
        <label><input
          v-model="viewRaw.registration_type"
          name="registration_type"
          type="radio"
          value="open"
        >Yes</label>
        <label><input
          v-model="viewRaw.registration_type"
          name="registration_type"
          type="radio"
          value="approval"
        >Pending approval <HelpIcon
          copy="New users can register, but can only access this<br />page after you approve their account record."
          text="Text"
        /></label>
      </div>
    </div>

    <div v-if="hasUserRoles">
      <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Permissions</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="viewRaw.limit_profile_access"
          type="checkbox"
          :true-value="true"
          :false-value="false"
        >Limit permissions to specific user roles</label>
      </div>
    </div>

    <div v-if="viewRaw.limit_profile_access">
      <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Which roles have permission?</label>
      <MultipleChoice
        v-model="viewRaw.allowed_profiles"
        :input="{format: {type: `multi`, options: roleObjectOptions}}"
      />
    </div>

    <SSO :view="viewRaw" />
  </ViewToolbox>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import HelpIcon from '@/components/ui/HelpIcon';
import SSO from '@/components/settings/sso/SSO';
import MultipleChoice from '@/components/renderer/form/inputs/MultipleChoice';

export default {
  name: 'LoginSettings',
  components: {
    ViewToolbox,
    HelpIcon,
    SSO,
    MultipleChoice,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    ...mapGetters([
      'roleObjects',
    ]),
    roleObjectOptions() {
      return this.roleObjects.map((roleObject) => ({
        label: roleObject.name,
        value: roleObject.get('profile_key'),
      }));
    },
    hasUserRoles() {
      return this.roleObjects.length > 0;
    },
  },
  data() {
    return {
      currentRegistrationForms: {},
    };
  },
  watch: {
    'view.attributes.registration_type': function () {
      this.setRegistrationForms();
    },

    'view.attributes.limit_profile_access': function () {
      this.setRegistrationForms();
    },

    'view.attributes.allowed_profiles': function () {
      this.setRegistrationForms();
    },
  },
  created() {
    // we're setting on created so that any reactive updates won't overwrite view keys that are set in view.registration_forms
    this.currentRegistrationForms = {};

    // if currently exists, make a copy to use as we do not want to overwrite anything already setup
    if (this.viewRaw.registration_forms) {
      this.currentRegistrationForms = JSON.parse(JSON.stringify(this.viewRaw.registration_forms));
    }
  },
  methods: {

    setRegistrationForms() {
      if (this.viewRaw.registration_type === 'closed') {
        this.viewRaw.registration_forms = {};

        return;
      }

      if (this.viewRaw.limit_profile_access) {
        this.viewRaw.registration_forms = {};

        this.viewRaw.allowed_profiles.forEach((key) => {
          this.viewRaw.registration_forms[key] = this.currentRegistrationForms[key] || {};
        });
      } else {
        // if this is open to all profiles, then we just need a single registration form
        if (this.viewRaw.registration_forms) {
          this.viewRaw.registration_forms.all_users = this.currentRegistrationForms.all_users || {};
        }
      }

      log('setRegistrationForms', this.viewRaw.registration_forms);
    },
  },
};
</script>
