import Builder from '@/components/Builder';
import TaskModal from '@/components/tasks/TaskModal';
import Tasks from '@/components/tasks/Tasks';
import TasksIntro from '@/components/tasks/TasksIntro';
import ObjectsNav from '@/components/tasks/ObjectsNav';
import TaskList from '@/components/tasks/TaskList';
import TaskSettings from '@/components/tasks/TaskSettings';
import TaskAction from '@/components/tasks/TaskAction';
import TaskHistory from '@/components/tasks/TaskHistory';
import EmailHistory from '@/components/views/EmailHistory';
import TaskRun from '@/components/tasks/TaskRun';
import TaskDelete from '@/components/tasks/TaskDelete';
import { getSharedObjectsPaths } from '@/router/shared/SharedObjectsPaths';
import { getSharedObjectPaths } from '@/router/shared/SharedObjectPaths';

export default [
  {
    path: '/tasks',
    component: Builder,
    children: [
      {
        path: '',
        component: Tasks,
        children: [
          {
            path: '',
            components: {
              toolbox: ObjectsNav,
              body: TasksIntro,
            },
          },

          // add object paths
          ...getSharedObjectsPaths(TasksIntro),

          {
            path: 'objects/:objectKey',
            props: true,
            components: {
              toolbox: ObjectsNav,
              body: TaskList,
            },
            children: [

              // object settings
              ...getSharedObjectPaths(),

              {
                path: 'add',
                redirect: (to) => `/tasks/objects/${to.params.objectKey}/add/task`,
                components: {
                  toolbox: ObjectsNav,
                  body: TaskList,
                  modal: TaskModal,
                },
                children: [
                  {
                    path: 'task',
                    component: TaskSettings,
                  },
                  {
                    path: 'action',
                    component: TaskAction,
                  },
                ],
              },
              {
                path: ':taskKey',
                redirect: (to) => `/tasks/${to.params.taskKey}/task`,
                props: {
                  modal: true,
                },
                components: {
                  toolbox: ObjectsNav,
                  body: TaskList,
                  modal: TaskModal,
                },
                children: [
                  {
                    path: 'task',
                    component: TaskSettings,
                  },
                  {
                    path: 'action',
                    component: TaskAction,
                  },
                  {
                    path: 'history',
                    component: TaskHistory,
                  },
                  {
                    path: 'run',
                    component: TaskRun,
                  },
                ],
              },
              {
                path: ':taskKey/history/emails/history',
                components: {
                  toolbox: ObjectsNav,
                  body: TaskList,
                  modal: EmailHistory,
                },
                props: {
                  modal: {
                    backLink: './',
                  },
                },
              },
              {
                path: ':taskKey/delete',
                props: {
                  modal: true,
                },
                components: {
                  toolbox: ObjectsNav,
                  body: TaskList,
                  modal: TaskDelete,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
