import store from '@/store/index';
import { clearBuilderInactivityEvents } from '@/util/InactivityHelper';

class User {
  constructor(user) {
    this.attributes = user;
    this.isAuthenticated = false;
  }

  get(property) {
    return this.attributes[property];
  }

  async submitLogin(email, password, totpToken) {
    log('User.submitLogin() - calling api.authenticate');

    const response = await window.Knack.Api.authenticate(email, password, totpToken);

    if (response.using_2fa === true) {
      return response;
    }

    clearBuilderInactivityEvents();

    store.commit('authenticateBuilder');

    return response;
  }
}

export default User;
