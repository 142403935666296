<template>
  <DesignRibbon />

  <div class="kn-design-preview-wrapper builderLayout_bodyShadow">
    <div
      v-if="showInstructions && !disableInstructions"
      class="preview-instructions"
    >
      <div>
        <p>
          Use the options on the left to customize the design of your live apps.
          Any changes will be previewed on this sample page.
        </p>
        <p class="center">
          <a
            class="button large green-fill"
            @click="disableInstructions=true"
          >
            Got it!
          </a>
        </p>
      </div>
    </div>
    <div
      :class="[
        'rendererPane kn-design-preview',
        `${previewSize ? `kn-design-preview--${previewSize}`: ''}`
      ]"
    >
      <div
        id="knack-body"
        class="kn-content"
      >
        <Notification
          :text-color="design.components.notifications.color"
          :bg-color="design.components.notifications.bg_color"
          :top-offset="false"
          :custom-styles="notificationPreviewStyles"
        />

        <LegacyAppHeader
          v-if="isLegacyHeader"
          :entry-pages="pageMenuEntryPages"
          :force-close-mobile-nav="previewSize === 'desktop'"
          is-preview
        />

        <AppHeader
          v-else
          :entry-pages="pageMenuEntryPages"
          :force-close-mobile-nav="previewSize === 'desktop'"
        />

        <div class="kn-container">
          <p>This is a sample page with fake data to help you preview design changes.</p>

          <div class="menu-views-wrapper">
            <!-- Menu Buttons -->
            <ViewWrapper
              :view="menuView"
              :is-view-only="true"
            >
              <MenuView
                :view="menuView"
                :is-view-only="true"
              />
            </ViewWrapper>

            <!-- Menu Tabs -->
            <ViewWrapper
              :view="tabMenuView"
              :is-view-only="true"
            >
              <MenuView
                :view="tabMenuView"
                :is-view-only="true"
              />
            </ViewWrapper>
          </div>

          <ViewWrapper
            :view="tableViewSchema"
            :is-view-only="true"
          >
            <TableView
              :view="tableViewSchema"
              :is-view-only="true"
            />
          </ViewWrapper>

          <ViewWrapper
            :view="formView"
            :is-view-only="true"
          >
            <div class="click-interceptor" />
            <Form :view="formView" />
          </ViewWrapper>

          <ViewWrapper
            :view="detailsView"
            :is-view-only="true"
          >
            <div class="click-interceptor" />
            <Details
              :is-editable="false"
              :view="detailsView"
            />
          </ViewWrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useLiveAppDesignPreview from '@/composables/ui/useLiveAppDesignPreview';
import Notification from '@/components/renderer/shared/Notification';
import MenuView from '@/components/renderer/menu/Menu';
import TableView from '@/components/renderer/table/ViewTable';
import AppHeader from '@/components/renderer/header/AppHeader';
import LegacyAppHeader from '@/components/renderer/header/LegacyAppHeader';
import RequestUtils from '@/components/util/RequestUtils';
import RecordsUtils from '@/components/records/RecordsUtils';
import Form from '@/components/renderer/form/Form';
import Details from '@/components/renderer/details/Details';
import DesignRibbon from '@/components/settings/design/DesignRibbon';
import PreviewRecords from '@/data/preview-records.json';
import PreviewColumns from '@/data/preview-columns.json';
import PreviewMenuView from '@/data/preview-menu-view.json';
import PreviewMenuTabsView from '@/data/preview-menu-tabs-view.json';
import PreviewPageMenuEntryPages from '@/data/preview-page-menu-entry-pages.json';
import PreviewObject from '@/data/preview-object.json';
import PreviewFormView from '@/data/preview-form-view.json';
import PreviewDetailsView from '@/data/preview-details-view.json';
import ViewModel from '@/store/models/View';
import ViewWrapper from '@/components/views/ViewWrapper';

export default {
  components: {
    Notification,
    MenuView,
    AppHeader,
    DesignRibbon,
    LegacyAppHeader,
    TableView,
    Details,
    Form,
    ViewWrapper,
  },
  mixins: [
    RequestUtils,
    RecordsUtils,
  ],
  props: {
    showInstructions: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { previewSize, showNotificationPreview, resetLiveAppDesignPreviewState } = useLiveAppDesignPreview();
    return { previewSize, showNotificationPreview, resetLiveAppDesignPreviewState };
  },
  data() {
    return {
      disableInstructions: true,
    };
  },
  computed: {
    object() {
      return PreviewObject;
    },
    tableViewSchema() {
      const table = new ViewModel({
        columns: PreviewColumns,
        key: 'table_view',
        type: 'table',
        keyword_search: true,
        allow_limit: true,
        allow_exporting: true,
        filter_type: 'fields',
        source: {},
        title: 'Contacts Grid',
      }, {});

      table.data = {
        records: PreviewRecords,
        isLoaded: true,
      };

      return table;
    },
    formView() {
      return new ViewModel(PreviewFormView, {});
    },
    detailsView() {
      const details = new ViewModel(PreviewDetailsView, {});

      details.data = {
        records: [
          PreviewRecords[0],
        ],
        isLoaded: true,
      };

      return details;
    },
    detailsData() {
      return PreviewRecords[0];
    },
    isLegacyHeader() {
      return this.design.regions.header.isLegacy;
    },
    pageMenuEntryPages() {
      return PreviewPageMenuEntryPages;
    },
    design() {
      return this.$store.state.app.design;
    },
    appName() {
      return this.$store.state.app.name;
    },
    menuView() {
      return new ViewModel(PreviewMenuView, {});
    },
    tabMenuView() {
      return new ViewModel(PreviewMenuTabsView, {});
    },
    notificationPreviewStyles() {
      const styles = {
        top: 'auto',
        transform: 'translateY(15px)',
      };

      if (this.previewSize !== 'desktop') {
        styles.right = 'auto';
        styles.transform = this.previewSize === 'tablet' ? 'translate(455px, 15px)' : 'translate(165px, 15px)';
      }

      return styles;
    },
  },
  watch: {
    showNotificationPreview(newValue) {
      this.toggleNotificationPreview(newValue);
    },
  },
  created() {
    // Ensure we set the live app design preview state to its default values
    this.resetLiveAppDesignPreviewState();
  },
  methods: {
    toggleNotificationPreview(newValue) {
      this.$notify({
        group: 'app-notification',
        type: 'info',
        duration: -3000,
        'animation-type': 'velocity',
        text: 'Notification Preview',
        ignoreDuplicates: false,
        clear: !newValue,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.kn-design-preview-wrapper {
  height: 100%;
  position: relative;
  overflow: auto;
}

// Using Vue's :deep selector so scoped styles apply to nested/sub-components as well
.kn-design-preview:deep {
  position: relative;
  overflow: auto;
  height: 100%;
  background-color: #FFFFFF;

  .knHeader .knHeader__menu--tabs .knHeader__menu-list-item {
    display: inline-flex;
  }

  &.rendererPane {
    .kn-view,
    .kn-title,
    .kn-view-table-wrapper,
    .menu-links,
    .kn-details-group .kn-details-group-column .item-wrapper {
      padding: 0;
    }
  }

  #knack-body {
    background-color: #FFFFFF;
    height: 100%;

    > .kn-container {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  // targets notification preview parent element
  // the vue-notification default z-index is 5000
  .vue-notification-group.kn-notifications {
    z-index: 6;
  }

  .kn-table-element {
    overflow-x: scroll;
  }
  .kn-form {
    .kn-item, .kn-submit {
      padding-left: 0;
    }
  }

  .view {
    margin-bottom: 1.5em;
    z-index: initial;

    h1 {
      font-weight: 500;
      font-size: 1.2em;
      line-height: 150%;
      margin-bottom: .25em;
      color: #000;
    }
  }

  .menu-views-wrapper {
    display: flex;
    flex-wrap: wrap;
    min-height: 125px;

    .view {
      flex: 0 1 auto;
      width: auto;
      @media (min-width: 1280px) {
        flex-basis: 50%;
      }
    }
  }

  .kn-form {
    min-width: 100%;

    form {
      max-width: 600px;

      input[type=submit] {
        font-weight: 400;
      }
    }
  }
}

// Common mobile/tablet styles
.kn-design-preview--tablet:deep,
.kn-design-preview--mobile:deep {
  background-color: rgba(0,0,0, 0.5);

  #knack-body {
    max-height: 80vh;
    margin: 20px auto;
    overflow: inherit;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  }

  // Header
  .knHeader {
    padding: 10px 0;
    .knHeader__content {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    .knHeader__menu {
      display: none;
    }
    .knHeader__title-logo-wrapper {
      margin-bottom: 5px;
    }
    .knHeader__mobile-controls {
      display: block;
      .fa {
        font-size: inherit;
      }
    }
  }

  // Legacy Header
  #kn-app-header {
    .kn-mobile-controls {
      display: block;
    }
    #app-menu-container {
      display: none;
    }
  }

  .menu-views-wrapper {
    .view {
      flex-basis: auto;
    }
  }
}

// Set individual styles for mobile
.kn-design-preview--mobile:deep {
  #knack-body {
    max-width: 480px;
  }

  .knHeader {
    .knHeader__title {
      &--small {
        font-size: 18px;
      }
      &--medium {
        font-size: 22px;
      }
      &--large {
        font-size: 26px;
      }
    }
  }
}

// Set individual styles for tablet
.kn-design-preview--tablet {
  #knack-body {
    max-width: 769px;
  }
}
</style>
