<template>
  <ViewToolbox
    back-title="Details Menu"
    title="Actions & Fields"
  >
    <Toggle
      :is-open="true"
      class="no-top-styles"
    >
      <template #title>
        <span>Actions & Fields</span>
      </template>
      <template #content>
        <AddItems
          :view="view"
          :object="object"
        >
          <p class="text-sm leading-5">
            Drag or click options below to add new details.
          </p>
        </AddItems>
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import { mapGetters } from 'vuex';
import AddItems from '@/components/views/AddItems';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'DetailsBuild',
  components: {
    AddItems,
    Toggle,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
};
</script>
