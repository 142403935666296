<template>
  <div :class="inputWrapperClasses">
    <header v-if="inputType !== `checkbox`">
      <label>{{ label }}</label>
      <DesignPropertyPickerDropdown
        v-if="designPropertyValue"
        :default-value="getApplicationDesignPropertyDefault(designProperty)"
        @revert="onRevert(getApplicationDesignPropertyDefault(designProperty))"
        @replace="onReplace(designPropertyValue)"
      />
    </header>

    <span v-if="inputType === `choice`">
      <ChoicePicker
        v-model="designPropertyValue"
        capitalize="true"
        :choices="options"
        :default-choice="getApplicationDesignPropertyDefault(designProperty)"
        class="small"
      />
    </span>

    <span v-else-if="inputType === `color`">
      <ColorPicker
        v-model="designPropertyValue"
        :show-text-value="true"
        :default-value="getApplicationDesignPropertyDefault(designProperty)"
      />
    </span>

    <span v-else-if="inputType === `dropdown`">
      <Dropdown
        v-model="designPropertyValue"
        :options="options"
        class="is-text"
      />
    </span>

    <span v-else-if="inputType === `radio`">
      <Radios
        v-model="designPropertyValue"
        :default-value="getApplicationDesignPropertyDefault(designProperty)"
        :options="options"
      />
    </span>

    <span v-else-if="inputType === `checkbox`">
      <label>
        <input
          v-model="designPropertyValue"
          type="checkbox"
        >
        <span class="checkbox-label">{{ label }}</span>
        <DesignPropertyPickerDropdown
          v-if="designPropertyValue && inputType === `checkbox`"
          :default-value="getApplicationDesignPropertyDefault(designProperty)"
          @revert="onRevert(getApplicationDesignPropertyDefault(designProperty))"
          @replace="onReplace(designPropertyValue)"
        />
      </label>
    </span>

    <span v-else>{{ getApplicationDesignPropertyDefault(designProperty) }}</span>

    <span
      v-if="descriptionText"
      class="design-description-text"
    >{{ descriptionText }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Radios from '@/components/renderer/form/inputs/Radios';
import ViewUtils from '@/components/views/ViewUtils';
import ChoicePicker from '@/components/ui/inputs/ChoicePicker';
import ColorPicker from '@/components/ui/inputs/ColorPicker';
import Checkbox from '@/components/builder/inputs/Checkbox';
import Dropdown from '@/components/ui/Dropdown';
import DesignPropertyPickerDropdown from '@/components/settings/design/DesignPropertyPickerDropdown';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    DesignPropertyPickerDropdown,
    ChoicePicker,
    ColorPicker,
    Checkbox,
    Dropdown,
    Radios,
    Icon,
  },
  mixins: [
    ViewUtils,
  ],
  props: {
    designProperty: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
    },
    inputType: {
      type: String,
      default: 'choice',
    },
    descriptionText: {
      type: String,
    },
    isInline: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return [
          'small',
          'medium',
          'large',
        ];
      },
    },
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    inputWrapperClasses() {
      return {
        'design-input-wrapper': true,
        'design-input-wrapper--inline': this.isInline === true,
        inline: this.isInline === true,
      };
    },
    label() {
      if (isEmpty(this.labelText)) {
        return this.designProperty;
      }

      return this.labelText;
    },
    designPropertyValue: {
      get() {
        return get(this.view.design, this.designProperty);
      },
      set(value) {
        this.view.design[this.designProperty] = value;
      },
    },
  },
  methods: {
    onReplace(newValue) {
      this.app.design[this.view.type][this.designProperty] = newValue;
    },
    onRevert(defaultValue) {
      this.view.design[this.designProperty] = defaultValue;
    },
    getApplicationDesignPropertyDefault(designProperty) {
      return this.app.design[this.view.type][designProperty];
    },
  },
};
</script>

<style lang="scss">
.design-input-wrapper {

  margin-bottom: 1rem;

  &--inline {
    margin-bottom: 0rem;
  }

  & > input {
    margin-right: 5px;
  }

  & > header {
    display:flex;
    align-items:center;
    margin-bottom: 3px;
  }

  & > header label {
    margin-right: 2px;
    font-weight: 500;
    text-transform: capitalize;
  }

  & > span {
    display: flex;
    align-items: center;
  }

  .disabled-color-preview {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }

  .text-value {
    margin-left: 5px;
  }

  .design-description-text {
    font-size:.8em;
    color:#888;
    margin-top:4px;
  }

  .option {
    display: flex;
    align-items: center;
  }

  .checkbox-label {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 100;
    font-style: normal;
  }
}
</style>
