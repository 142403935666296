<template>
  <div>
    <div
      v-if="isLatLong"
      class="control kn-inputWrapper is-grouped"
    >
      <div class="control kn-inputWrapper">
        <input
          id="latitude"
          v-model="localLatitude"
          class="input kn-input"
          name="latitude"
          type="text"
        >
        <label
          class="help group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
          for="latitude"
        >
          {{ trans(`Latitude`) }}
        </label>
      </div>
      <div class="control kn-inputWrapper">
        <input
          v-model="localLongitude"
          class="input kn-input"
          name="longitude"
          type="text"
        >
        <label
          class="help group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
          for="longitude"
        >
          {{ trans(`Longitude`) }}
        </label>
      </div>
    </div>
    <template v-else>
      <div :class="addressClass">
        <div class="address_street control kn-inputWrapper">
          <div class="control kn-inputWrapper">
            <input
              id="street"
              ref="street"
              v-model="localStreet"
              class="input kn-input"
              name="street"
              type="text"
            >
            <label
              class="help group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
              for="street"
            >
              {{ labels.street }}
            </label>
            <p
              v-if="isGoogleMapsKeyInvalid"
              class="validation-error text-destructive-emphasis -mt-2 mb-2"
            >
              Auto-complete functionality is inactive. Please enter a valid Google Maps API key in App Settings.
            </p>
          </div>
          <div class="control kn-inputWrapper">
            <input
              id="street2"
              v-model="localStreet2"
              class="input kn-input"
              name="street2"
              type="text"
            >
            <label
              class="help group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
              for="street2"
            >
              {{ labels.street2 }}
            </label>
          </div>
        </div>
      </div>
      <div class="control kn-inputWrapper">
        <input
          id="city"
          ref="city"
          v-model="localCity"
          class="input kn-input"
          name="city"
          type="text"
        >
        <label
          class="help group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
          for="city"
        >
          {{ labels.city }}
        </label>
      </div>
      <div class="control kn-inputWrapper">
        <input
          id="state"
          ref="state"
          v-model="localState"
          class="input kn-input"
          name="state"
          size="2"
          type="text"
        >
        <label
          class="help group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
          for="state"
        >
          {{ labels.state }}
        </label>
      </div>
      <div class="control kn-inputWrapper">
        <input
          id="zip"
          ref="zip"
          v-model="localZip"
          class="input kn-input group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
          name="zip"
          size="5"
          type="text"
        >
        <label
          class="help group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
          for="zip"
        >
          {{ labels.zip }}
        </label>
      </div>
      <div
        v-if="isInternationalCountry"
        class="control kn-inputWrapper"
      >
        <input
          id="country"
          ref="country"
          v-model="localCountry"
          class="input kn-input"
          name="country"
          type="text"
        >
        <label
          class="help group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm"
          for="country"
        >
          {{ trans(`Country`) }}
        </label>
      </div>
    </template>
  </div>
</template>

<script>
import hasIn from 'lodash/hasIn';
import { mapActions, mapGetters } from 'vuex';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';

export default {
  name: 'AddressInput',
  mixins: [
    TranslationUtils,
  ],
  inheritAttrs: false,
  props: [
    'input',
    'field',
    'modelValue',
  ],
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters([
      'isGoogleMapsKeyInvalid',
    ]),
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    localStreet: {
      get() {
        return this.localValue.street;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          street: newValue,
        });
      },
    },
    localStreet2: {
      get() {
        return this.localValue.street2;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          street2: newValue,
        });
      },
    },
    localCity: {
      get() {
        return this.localValue.city;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          city: newValue,
        });
      },
    },
    localState: {
      get() {
        return this.localValue.state;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          state: newValue,
        });
      },
    },
    localZip: {
      get() {
        return this.localValue.zip;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          zip: newValue,
        });
      },
    },
    localLongitude: {
      get() {
        return this.localValue.longitude;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          longitude: newValue,
        });
      },
    },
    localLatitude: {
      get() {
        return this.localValue.latitude;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          latitude: newValue,
        });
      },
    },
    localCountry: {
      get() {
        return this.localValue.country;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          country: newValue,
        });
      },
    },
    labels() {
      return {
        street: this.trans('Street Address'),
        street2: this.trans('Street Address 2'),
        city: this.trans('City'),
        state: this.isInternational ? this.trans('Province / Region') : this.trans('State'),
        zip: this.isInternational ? this.trans('Postal Code') : this.trans('Zip'),
      };
    },
    hasAutoCompleteEnabled() {
      return this.input.format.enable_address_autocomplete;
    },
    googleMapsApiKeyHash() {
      const { googleMapsApiKey, mapsAndGeocoderProvider } = this.$store.getters.app.get('settings');
      if (mapsAndGeocoderProvider === 'here') {
        return null;
      }
      return googleMapsApiKey;
    },
    isLatLong() {
      return _.get(this, 'input.format.input') === 'lat_long';
    },
    isInternationalCountry() {
      return _.get(this.input, 'format.format') === 'international_country';
    },
    isInternational() {
      return _.get(this.input, 'format.format') === 'international' || _.get(this.input, 'format.format') === 'international_country';
    },
    addressClass() {
      return {
        'address-international': this.isInternational,
        'address-us': !this.isInternational,
      };
    },
  },
  async mounted() {
    if (this.hasAutoCompleteEnabled) {
      await this.loadGoogleMapsApi({ googleMapsApiKeyHash: this.googleMapsApiKeyHash });
      if (this.isGoogleMapsKeyInvalid) return;
      const place = new google.maps.places.Autocomplete(this.$refs.street, {
        types: [
          'geocode',
        ],
        fields: [
          'address_components',
        ],
      });
      place.addListener('place_changed', () => {
        this.handleSelectedAddressAutocomplete(place);
      });
    }
  },
  methods: {
    ...mapActions(['loadGoogleMapsApi']),
    handleSelectedAddressAutocomplete(autocomplete) {
      const componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        sublocality_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
        postal_town: 'short_name',
        neighborhood: 'short_name',
        sublocality_level_3: 'short_name',
      };

      const place = autocomplete.getPlace();

      const addressData = {};

      if (!place || !place.address_components) {
        return;
      }

        for (const [index] of place.address_components.entries()) { // eslint-disable-line

        const addressType = place.address_components[index].types[0];

        if (componentForm[addressType]) {
          addressData[addressType] = place.address_components[index][componentForm[addressType]];
        }
      }

      this.clearAddressAutocomplete();

      return this.fillInAddressAutocomplete(addressData);
    },
    clearAddressAutocomplete() {
      for (const ref of Object.keys(this.$refs)) {
        this.$refs[ref].value = '';
      }
    },
    fillInAddressAutocomplete(addressData) {
      const localValue = {};

      if (hasIn(addressData, 'street_number') && hasIn(addressData, 'route')) {
        localValue.street = `${addressData.street_number} ${addressData.route}`;
      } else if (hasIn(addressData, 'route')) {
        localValue.street = addressData.route;
      } else if (hasIn(addressData, 'sublocality_level_3')) {
        localValue.street = addressData.sublocality_level_3;
      }

      if (hasIn(addressData, 'locality')) {
        localValue.city = addressData.locality;
      } else if (hasIn(addressData, 'sublocality_level_1')) {
        localValue.city = addressData.sublocality_level_1;
      } else if (hasIn(addressData, 'postal_town')) {
        localValue.city = addressData.postal_town;
      } else if (hasIn(addressData, 'neighborhood')) {
        localValue.city = addressData.neighborhood;
      }

      if (hasIn(addressData, 'administrative_area_level_1')) {
        localValue.state = addressData.administrative_area_level_1;
      }

      if (hasIn(addressData, 'postal_code')) {
        localValue.zip = addressData.postal_code;
      }

      if (hasIn(addressData, 'country') && this.$refs.country) {
        localValue.country = addressData.country;
      }

      this.localValue = localValue;
    },
  },
};
</script>

<style lang="scss">
.pac-container {
  z-index: 10001; /* Address autocomplete dropdown needs to be above inline edit Popover */
}
</style>
