// These functions use regex to replace field keys with field names and vice versa
// Used in email templates, email subjects, etc.
export const nonFieldTemplateOptions = [
  {
    name: 'RECORD ID',
    key: 'record_id',
  },
  {
    name: 'PAGE URL',
    key: 'page_url',
  },
  {
    name: 'PARENT PAGE URL',
    key: 'parent_url',
  },
  {
    name: 'PAYMENT SUMMARY',
    key: 'charge_summary',
  },
];

export function replaceTemplateFieldKeysWithNames(content, replacements) {
  if (!content) {
    return '';
  }

  for (const replacement of replacements) {
    // Split/join instead of replacing by regex to avoid escaping
    content = content.split(`{${replacement.key}}`).join(`{${replacement.name.toUpperCase()}}`);
  }

  return content;
}

export function replaceTemplateFieldNamesWithKeys(content, replacements) {
  return replacements.reduce((updatedTemplate, replacement) => updatedTemplate
    .split(`{${replacement.name.toUpperCase()}}`)
    .join(`{${replacement.key}}`), content);
}

export function FieldTemplateConverter(object) {
  let replacements = [];
  let objectFields = object.fields;

  if (object.hasPasswordField()) {
    // Do not convert password field type keys with names and vice versa because
    // password field values are encrypted and are not stored in any readable form.
    // i.e. do not merge password field value into any content.
    objectFields = objectFields.filter((field) => !field.isPassword());
  }

  // do this once for each consumer, rather than on each replace method
  for (const field of objectFields) {
    replacements.push({
      name: field.get('name').toUpperCase(),
      key: field.get('key'),
    });

    if (field.hasThumbs()) {
      replacements = replacements.concat(field.getThumbsAsFieldOptions());
    }
  }

  // TODO:: add connections

  replacements.push(...nonFieldTemplateOptions);

  return {
    replaceFieldKeysWithNames: (content) => replaceTemplateFieldKeysWithNames(content, replacements),
    replaceFieldNamesWithKeys:
      (content, escapeFieldNames) => replaceTemplateFieldNamesWithKeys(content, replacements, escapeFieldNames),
  };
}
