<template>
  <WizardStep
    :wizard-step="wizardStep"
    :step="step"
    @update:wizardStep="$emit('update:wizardStep', step)"
  >
    <template #edit>
      <div class="mb-4 text-base font-semibold tracking-[.32px]">
        using <span v-html="paymentProcessorEditDisplay" />
      </div>
    </template>
    <template #intro>
      <div class="mb-2">
        Which payment processor should be used?
      </div>
    </template>
    <div>
      <div v-if="hasMultipleProcessorOptions">
        <div
          v-for="processor in paymentProcessors"
          :key="processor.key"
          class="input-labels tw-input-labels"
        >
          <label><input
            v-model="selectedPaymentProcessors"
            :disabled="isCustomerView && processor.processor === `paypal`"
            type="checkbox"
            :value="processor.key"
            @change="onUpdateProcessor(processor.key)"
          > {{ processor.name }}</label>
        </div>
      </div>
      <div v-else-if="firstProcessor">
        The <strong>{{ firstProcessor.name }}</strong> processor will be used.
      </div>
    </div>
    <p class="mt-2">
      <a
        class="inline-flex items-center green underline rounded-lg text-base text-emphasis border border-solid border-default bg-white p-3 h-10 hover:bg-brand-50 hover:border-brand-600 no-underline font-medium group"
        @click="showEcommerceAddProcessor = true"
      >
        <Icon
          class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
          type="plus-thin"
        />
        Add New Processor
      </a>
    </p>
    <IfPlan :level-is-maximum-of="1">
      <div
        class="input-labels tw-input-labels"
      >
        <label>
          <input
            type="checkbox"
            value="test_mode"
            checked="checked"
            disabled="disabled"
          > Use the test mode of your payment processors
        </label>
      </div>
    </IfPlan>
    <IfPlan :level-is-minimum-of="2">
      <div
        class="input-labels tw-input-labels"
      >
        <label class="text-base">
          <input
            v-model="view.attributes.ecommerce_test_mode"
            type="checkbox"
          > Use the test mode of your payment processors
        </label>
      </div>
    </IfPlan>
    <p
      v-if="showNoProcessorSelection"
      class="error-alert form-errors mt-2 rounded-lg p-4 bg-destructive mb-6 border-0 text-destructive-emphasis text-base"
    >
      Must select at least one payment processor.
    </p>
    <div class="margin-top flex justify-end">
      <button
        type="button"
        class="button orange-fill medium text-white px-3 py-2 bg-gradient-primary rounded-lg border-0 text-base font-medium h-10"
        @click="onNext"
      >
        Next
      </button>
    </div>
    <EcommerceProcessor
      v-if="showEcommerceAddProcessor"
      :back-link="null"
      @close="showEcommerceAddProcessor = false"
    />
  </WizardStep>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import WizardStep from '@/components/ui/WizardSection';
import PaymentUtils from '@/components/settings/ecommerce/PaymentUtils';
import EcommerceProcessor from '@/components/settings/ecommerce/EcommerceProcessor';
import Icon from '@/components/ui/Icon';
import IfPlan from '@/components/util/IfPlan';

export default {
  components: {
    IfPlan,
    WizardStep,
    EcommerceProcessor,
    Icon,
  },
  mixins: [
    PaymentUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    wizardStep: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:wizardStep', 'next'],
  data() {
    return {
      selectedPaymentProcessors: [],
      showEcommerceAddProcessor: false,
      showNoProcessorSelection: false,
    };
  },
  computed: {
    paymentProcessorEditDisplay() {
      const displayNames = [];

      for (const selectedProcessorKey of this.selectedPaymentProcessors) {
        const paymentProcessor = this.paymentProcessors.find((paymentProcessor) => paymentProcessor.key === selectedProcessorKey);

        if (!isNil(paymentProcessor)) {
          displayNames.push(`<strong>${paymentProcessor.name}</strong>`);
        }
      }

      return displayNames.join(' and ');
    },
    hasMultipleProcessorOptions() {
      return this.paymentProcessors.length > 1;
    },
    firstProcessor() {
      return this.paymentProcessors.length > 0 ? this.paymentProcessors[0] : null;
    },
    isCustomerView() {
      return this.view.type === 'customer';
    },
  },
  watch: {
    paymentProcessors(value) {
      if (!this.hasMultipleProcessorOptions && !isNil(this.firstProcessor)) {
        this.selectedPaymentProcessors.push(this.firstProcessor.key);
      }
    },
    selectedPaymentProcessors(newSelectedProcessors) {
      if (!isEmpty(newSelectedProcessors)) {
        this.showNoProcessorSelection = false;
      }
    },
  },
  created() {
    if (!isEmpty(this.view.paymentProcessors)) {
      this.selectedPaymentProcessors = this.view.paymentProcessors.map((processor) => processor.payment_processor_key);
    } else if (!this.hasMultipleProcessorOptions && !isNil(this.firstProcessor)) {
      this.selectedPaymentProcessors.push(this.firstProcessor.key);
    }
  },
  methods: {
    onNext() {
      if (isEmpty(this.selectedPaymentProcessors)) {
        this.showNoProcessorSelection = true;

        return;
      }

      this.showNoProcessorSelection = false;

      this.view.paymentProcessors = [];

      for (const paymentProcessorKey of this.selectedPaymentProcessors) {
        const paymentProcessor = this.paymentProcessors.find((processor) => processor.key === paymentProcessorKey);

        this.view.paymentProcessors.push({
          type: paymentProcessor.processor,
          payment_processor_key: paymentProcessorKey,
          total_field_key: this.view.totalField,
          currency: 'USD', // might be useless... never seems to get set to anything else in v2
        });
      }

      this.$emit('next');
    },
  },
};
</script>

<style lang="scss">
</style>
