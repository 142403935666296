<template>
  <ConfirmModal
    title="Delete this task?"
    confirm-text="Delete"
    confirm-type="alert"
    :back="backLink"
    @confirm="onClickDeleteTask"
  >
    <p class="mb-0">
      <strong>This can't be undone!</strong>
    </p>
  </ConfirmModal>
</template>
<script>
import ConfirmModal from '@/components/ui/ConfirmModal';
import FieldUtils from '@/components/fields/FieldUtils';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  components: {
    ConfirmModal,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  props: {
    taskKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    backLink() {
      return `/tasks/objects/${this.object.key}`;
    },
    task() {
      return this.object.tasks.find(({ key }) => key === this.taskKey);
    },
  },
  methods: {
    onClickDeleteTask() {
      this.commitRequest({
        request: () => this.task.delete(),
        onSuccess: () => this.$router.push(this.backLink),
      });
    },
  },
};
</script>
