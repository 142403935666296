import axios from 'axios';
import { Crisp } from 'crisp-sdk-web';

// eslint-disable-next-line import/prefer-default-export
export async function showCrispChat() {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, first_name, last_name } = window.Knack.Api;
  const websiteId = process.env.VUE_APP_CRISP_ID;
  if (!window.$crisp && email && websiteId) {
    Crisp.configure(websiteId);
    try {
      const signature = await axios.post(`${process.env.VUE_APP_API_URL}crisp-auth/identify`, { email });
      if (signature) {
        Crisp.user.setEmail(email, signature);
        Crisp.user.setNickname(`${first_name} ${last_name}`);
        if (window.location.href.includes('/my-app-name')) {
          Crisp.session.pushEvent('template_id', { template_id: '66fac4963bea5b3e05faf2e3' });
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  }
}

export function sendEventsForMeasuring() {
  const crisp = window.$crisp;

  try {
    if (crisp) {
      // Assign a segment programatically to track the number of users who were sent the automation
      crisp.push(['set', 'session:segments', [['new_user_agent_offline']]]);

      // Check users who started a conversation
      crisp.push(['on', 'message:received', () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'CrispInteractions',
          'event-label': 'message_received',
        });
      }]);
    }
  } catch (error) {
    console.log('error', error);
  }
}

export function hideCrispChat() {
  if (window.$crisp) {
    Crisp.chat.hide();
  }
}
