<template>
  <view-toolbox
    back-title="Edit Details"
    title="Design Settings"
  >
    <div class="mb-0 pb-6 border border-solid border-subtle border-x-0 border-t-0">
      <label class="tw-toolbox-label">Hide Empty Values</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="viewRaw.hide_fields"
          type="radio"
          name="hide_fields"
          :value="false"
        > No, show all fields</label>
        <label><input
          v-model="viewRaw.hide_fields"
          type="radio"
          name="hide_fields"
          :value="true"
        > Yes, hide fields that don't have values</label>
      </div>
    </div>

    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Details Layout</span>
      </template>
      <template #content>
        <div class="inputGroup mb-0 pb-6 border border-solid border-subtle border-x-0 border-t-0">
          <div class="mb-4">
            <label class="tw-toolbox-label">Label Format</label>
            <LabelFormat
              :model-value="viewRaw"
              @update-property:model-value="onUpdateViewProperty"
            />
          </div>
          <div class="mb-0">
            <label class="tw-toolbox-label">Layout</label>
            <DetailsLayout />
          </div>
        </div>
      </template>
    </Toggle>

    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Title & Description</span>
      </template>
      <template #content>
        <div class="inputGroup">
          <div class="mb-4">
            <label class="tw-toolbox-label">Title</label>
            <input
              id="title-input"
              v-model="viewRaw.title"
              type="text"
            >
          </div>
          <div>
            <label class="tw-toolbox-label">Description</label>
            <textarea v-model="viewRaw.description" />
          </div>
        </div>
      </template>
    </Toggle>
  </view-toolbox>
</template>

<script>
import DesignUtils from '@/components/views/DesignUtils';
import LabelFormat from '@/components/views/shared/settings/LabelFormat';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DetailsLayout from '@/components/views/shared/DetailsLayout';
import Toggle from '@/components/ui/Toggle';

export default {
  name: 'DetailSettings',
  components: {
    DetailsLayout,
    Toggle,
    ViewToolbox,
    LabelFormat,
  },
  mixins: [
    ViewUtils,
    DesignUtils,
  ],
  data() {
    return {
      alignmentOptions: [
        {
          value: 'left-aligned',
          label: 'Left-aligned',
        },
        {
          value: 'right-aligned',
          label: 'Right-aligned',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.customization-options {
  margin-top: .5em;

  >div {
    margin-bottom: 1em;
  }
  .default {
    margin-bottom: 5px;
  }
  .custom {
    margin-left: 16px;
  }

  b {
    font-weight: 500;
  }
}
</style>
