<template>
  <view-toolbox
    back-title="Details"
    title="Display Rules"
  >
    <p
      class="text-sm leading-5 mb-0"
      style="margin-bottom: 1em"
    >
      Add rules to show and hide fields based on record values.
    </p>

    <form
      id="display-rules-editor"
      class="rules-editor"
    >
      <div>
        <GroupList
          v-model:items="displayRules"
          item-title="Display Rule"
          class="display-rules mt-4"
          group-list-item-classes="bg-subtle"
        >
          <template #default="{item, itemIndex}">
            <div>
              <div class="margin-bottom-half" data-testid='display-rules-if'>
                <p class="mb-2">
                  <b>If</b> the following rules match
                </p>
                <FieldList
                  :object-key="object.key"
                  :items="item.criteria"
                  class="is-grouped is-contained bg-transparent p-0"
                  @update:items="onUpdateCriteria(item, $event)"
                />
              </div>

              <div data-testid='display-rules-then'>
                <p class="text-sm leading-5 mb-0">
                  <b>Then</b>
                </p>
                <ActionList
                  v-model:items="item.actions"
                  :default-item="defaultActionItem"
                  :can-be-empty="false"
                  scope-name="actionItem"
                  class="is-grouped is-contained bg-transparent"
                >
                  <template #default="{actionItem}">
                    <div class="display-rule-actions level w-full">
                      <select
                        v-model="actionItem.action"
                        class="text-base py-2 pl-3 leading-5 truncate"
                        data-testid='display-rules-then-action'
                      >
                        <option value="show">
                          Show
                        </option>
                        <option value="hide">
                          Hide
                        </option>
                        <option value="label">
                          Rename label
                        </option>
                      </select>

                      <span
                        v-if="actionItem.action === 'label'"
                        class="self-center"
                      >
                        of&nbsp;
                      </span>

                      <select
                        v-model="actionItem.field"
                        class="text-base py-2 pl-3 leading-5 truncate"
                        data-testid='display-rules-then-target'
                      >
                        <option
                          v-for="field in object.fields"
                          :key="field.key"
                          :value="field.key"
                        >
                          {{ field.name }}
                        </option>
                      </select>

                      <template v-if="actionItem.action === 'label'">
                        <span class="self-center">&nbsp;to&nbsp;</span>
                        <input
                          v-model="actionItem.value"
                          type="text"
                          class="flex-auto"
                        >
                      </template>
                    </div>
                  </template>
                </ActionList>
              </div>
            </div>
          </template>
        </GroupList>
        <div>
          <a
            class="button small add-fill p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base"
            @click="onAddListItem"
             data-testid="display-rules-add-rule-button"
          >
            <Icon
              class="text-subtle h-4 w-4 group-hover:text-brand-400 mr-2 fill-current"
              type="plus-thin"
            /> Add rule
          </a>
        </div>
      </div>
    </form>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import GroupList from '@/components/ui/lists/GroupList';
import FieldList from '@/components/ui/lists/FieldList';
import ActionList from '@/components/ui/lists/ActionList';
import Icon from '@/components/ui/Icon';

export default {
  name: 'DetailsDisplayRules',
  components: {
    ViewToolbox,
    GroupList,
    FieldList,
    ActionList,
    Icon,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    displayRules: {

      get() {
        return this.viewRaw && this.viewRaw.rules ? this.viewRaw.rules.fields : [];
      },

      set(newVal) {
        this.viewRaw.rules.fields = newVal;
      },
    },
  },
  methods: {
    defaultCriteria() {
      const field = this.object.fields[0];

      return {
        field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
      };
    },
    defaultItem() {
      return {
        actions: [
          this.defaultActionItem(),
        ],
        criteria: [
          this.defaultCriteria(),
        ],
      };
    },
    defaultActionItem() {
      return {
        action: 'hide',
        field: this.object.fields[0].key,
        value: '',
      };
    },
    onAddListItem(event) {
      this.viewRaw.rules.fields.push(this.defaultItem());
    },

    onUpdateCriteria(item, newCriteria) {
      item.criteria = newCriteria;
    },
  },
};
</script>

<style lang="scss">
.display-rule-actions {
  select {
    max-width: 160px;
    margin-right: 6px;
  }
}

.text-style-options {
  display: flex;

  a {
    background-color: #eee;
    border-radius: .35em;
    border: 1px solid #eee;
    height: 2em;
    line-height: 2em;
    display: block;
    width: 2em;
    text-align: center;
    margin-left: 5px;

    &.active {
      border: 1px solid #771b66;
    }
  }

  a:first-child {
    margin: 0;
  }
}
</style>
