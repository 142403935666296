<template>
  <ViewToolbox
    back-title="Form Menu"
    :back-link="`/pages/${page.key}/views/${view.key}/form`"
    title="Design & Options"
  >
    <div class="mb-4">
      <label class="tw-toolbox-label">
        Submit Button Text
      </label>
      <input
        v-model="viewRaw.submit_button_text"
        type="text"
      >
    </div>
    <div class="pb-6 mb-0 border border-solid border-subtle border-x-0 border-t-0">
      <label class="tw-toolbox-label">
        Reload Form Text
      </label>
      <input
        v-model="reloadFormText"
        type="text"
      >
    </div>
    <!--
    <label>Design</label>
    <label class="label-header">Submit Button</label>
    <div class="customization-options">
      <DesignPropertyPicker
        design-property="style"
        label-text="submit style"
        :options="['outline', 'fill']" />
      <DesignPropertyPicker
        v-if="designStyle === `fill`"
        design-property="fillColor"
        label-text="fill color"
        input-type="color"
        description-text="A darker shade is used for the hover state" />
      <DesignPropertyPicker
        v-if="designStyle === `outline`"
        design-property="outlineColor"
        label-text="border color"
        input-type="color" />
      <DesignPropertyPicker
        design-property="textColor"
        label-text="font color"
        input-type="color" />
    </div>
    -->

    <Toggle
      id="column-properties-toggle"
      :is-open="true"
      class="margin-top-double mt-0"
    >
      <template #title>
        <span class="mt-2">Title & Description</span>
      </template>

      <template #content>
        <div class="grid-verticalGaps border border-solid border-subtle border-x-0 border-t-0">
          <div>
            <label class="tw-toolbox-label">Title</label>
            <input
              v-model="viewRaw.title"
              type="text"
            >
          </div>
          <div>
            <label class="tw-toolbox-label">Description</label>
            <textarea v-model="viewRaw.description" />
          </div>
        </div>
      </template>
    </Toggle>

    <Toggle
      id="column-properties-toggle"
      :is-open="true"
    >
      <template #title>
        <span class="mt-2">E-Commerce</span>
      </template>

      <template #content>
        <div>
          <label class="tw-toolbox-label">E-Commerce</label>
          <div v-if="hasNumericFields">
            <div v-if="viewEcommerceEnabled">
              <p>This form will redirect to a checkout page to accept payment.</p>
              <button
                class="button red-gray"
                :disabled="hasPendingEcommerceUpdates"
                @click="disableFormEcommerce"
              >
                Remove Payment View
              </button>
            </div>
            <div v-else>
              <p>Connect this form to a payment view to accept payments or create a customer to charge later.</p>
              <RouterLink :to="routeAppend($route.path, 'ecommerce')">
                <button
                  class="button green-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600"
                  :disabled="hasPendingEcommerceUpdates"
                >
                  Add a Payment View
                </button>
              </RouterLink>
            </div>
          </div>
          <div v-else>
            <p>Add a numeric field if you wish to use E-Commerce features on this form.</p>
          </div>
        </div>
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import isNil from 'lodash/isNil';
import hasIn from 'lodash/hasIn';

import PaymentUtils from '@/components/settings/ecommerce/PaymentUtils';
import RequestUtils from '@/components/util/RequestUtils';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'FormSettings',
  components: {
    Toggle,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
    RequestUtils,
    PaymentUtils,
  ],
  computed: {
    designStyle() {
      if (isNil(this.view.design.style)) {
        return this.app.design.form.style;
      }

      return this.view.design.style;
    },
    reloadFormText: {
      get() {
        return this.viewRaw.reload_form_text || 'Reload form';
      },
      set(newValue) {
        this.viewRaw.reload_form_text = newValue;
      },
    },
    hasNumericFields() {
      return this.numericFields.length > 0;
    },
    viewEcommerceEnabled() {
      return this.viewRaw.ecommerce === true;
    },
    hasPendingEcommerceUpdates() { // this wouldn't be necessary with a better UX...
      if (!this.view.attributesRestore) {
        return false;
      }

      return hasIn(this.viewRaw, 'ecommerce') && this.viewRaw.ecommerce !== this.view.attributesRestore?.ecommerce;
    },
  },
  methods: {
    disableFormEcommerce() {
      this.viewRaw.ecommerce = false;
      this.viewRaw.rules.submits = this.viewRaw.rules.submits.filter((rule) => !rule.ecommerce);
    },
  },
};
</script>
