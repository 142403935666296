<template>
  <Toolbox
    back-title="back"
    :back-link="backLink"
    :title="title"
    theme="active"
    class="no-padding"
  >
    <!-- SOURCE STEP -->
    <template v-if="step === 'source'">
      <p clas="mb-2 leading-4 tracking-[0.14px]">
        <b class="font-medium">Which records do you want to {{ action }}?</b>
      </p>

      <div
        v-if="!criteria.rules.length"
        class="mt-2"
      >
        <a
          class="underline fuchsia no-underline p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium inline-flex items-center capitalize"
          @click="onAddRule"
        >
          <Icon
            class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
            type="plus-thin"
          />
          Add criteria
        </a>
        <div class="margin-top my-4 text-sm leading-5 font-normal">
          Every record will be {{ actionPastTense }} if no criteria is added.
        </div>
      </div>

      <div v-else>
        <div
          class="text-sm font-medium leading-5 tracking-[0.14px] mb-4"
          style="margin-bottom: .5em;"
        >
          Only records that match <select
            v-model="criteria.match"
            class="text-base py-2 pl-3 leading-5 truncate"
            style="display: inline; width: auto;"
          >
            <option>all</option><option>any</option>
          </select> of the following rules:
        </div>

        <!-- CRITERIA -->
        <FieldList
          ref="rules"
          class="is-grouped bg-subtle rounded-lg p-4"
          style="font-size: .9em;"
          :items="criteria.rules"
          :object-key="activeObject.key"
          :can-be-empty="true"
          @update:items="onUpdateCriteriaRules"
        />

        <div>
          <div
            v-for="(group, index) in criteria.groups"
            :key="index"
          >
            <div class="text-sm font-medium leading-5 tracking-[0.14px] my-1">{{ matchCopy }}:</div>
            <FieldList
              class="is-grouped bg-subtle rounded-lg p-4"
              style="font-size: .9em;"
              :items="group"
              :object-key="activeObject.key"
              :can-be-empty="true"
              @delete:item="onDeleteGroupItem"
              @update:items="onUpdateGroupItems(index, $event)"
            />
          </div>

          <a
            v-tippy
            content="Add groups of rules for more complex matching"
            class="button tiny fuchsia-gray mt-4 p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium capitalize"
            @click="onAddGroup"
          >
            <Icon
              class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
              type="plus-thin"
            />
            Add group
          </a>
        </div>
      </div>

      <div
        id="submit-source"
        class="margin-top-double mt-6 flex justify-end"
      >
        <a
          data-cy="match-records-next"
          class="button orange-fill p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium h-10"
          @click="onSubmitSource"
        >
          Next
        </a>
      </div>
    </template>

    <!-- VALUES STEP -->
    <template v-if="step === 'values'">
      <p class="text-sm leading-5 tracking-[0.14px] mb-4">
        <b class="font-medium">Which values do you want to update?</b>
      </p>

      <ValueList
        :object="activeObject"
        :values="values"
        :can-be-empty="false"
        @update:values="onUpdateValues"
      />

      <div
        id="submit-source"
        class="margin-top-double flex justify-end mt-6 gap-4"
      >
        <a
          data-cy="match-values-next"
          class="button orange-fill p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium h-10 order-2"
          @click="onSubmitValues"
        >
          Next
        </a>&nbsp;
        <a
          v-if="stepAction === `select`"
          data-cy="match-values-back"
          class="button gray-outline text-emphasis p-3 rounded-lg bg-white border-default text-base leading-4 font-medium h-10 order-1"
          @click="previousStep"
        >
          Back
        </a>
      </div>
    </template>

    <!-- CONFIRMATION STEP -->
    <template v-if="step === 'confirm'">
      <template v-if="recordCount === 0">
        <p
          data-cy="submit-confirm-zero-records"
          class="text-sm leading-5 tracking-[0.14px] mb-4"
        >
          <b class="font-medium">Your criteria isn't matching any records to {{ action }}. Click back to adjust your record criteria.</b>
        </p>
        <div
          id="submit-batch"
          class="margin-top-double mt-6 flex justify-end"
        >
          <a
            class="button gray-outline capitalize text-emphasis p-3 rounded-lg bg-white border-default text-base leading-4 font-medium h-10"
            @click="previousStep(2)"
          >
            Back to criteria
          </a>
        </div>
      </template>
      <template v-else>
        <p
          data-cy="submit-confirm-total-records"
          class="text-sm leading-5 tracking-[0.14px] mb-4"
        >
          <b class="font-medium">This batch will {{ action }} {{ recordCount }} total records.</b>
        </p>
        <p class="text-sm leading-5 tracking-[0.14px] mb-4 font-normal">Note: you can cancel this {{ action }} once submitted if needed.</p>

        <div
          id="submit-batch"
          class="margin-top-double mt-6 flex justify-end gap-4"
        >
          <a
            class="button orange-fill p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium h-10 order-2"
            @click="onSubmitBatch"
          >
            {{ title }}
          </a>&nbsp;
          <a
            class="button gray-outline text-emphasis p-3 rounded-lg bg-white border-default text-base leading-4 font-medium h-10 order-1"
            @click="previousStep"
          >
            Back
          </a>
        </div>
      </template>
    </template>
  </Toolbox>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Toolbox from '@/components/layout/Toolbox';
import Icon from '@/components/ui/Icon';
import FieldList from '@/components/ui/lists/FieldList';
import ValueList from '@/components/ui/lists/ValueList';
import RequestUtils from '@/components/util/RequestUtils';
import RecordsUtils from '@/components/records/RecordsUtils';
import RouteUtils from '@/components/util/RouteUtils';
import { eventBus } from '@/store/bus';

export default {
  name: 'RecordBatch',
  components: {
    Toolbox,
    FieldList,
    ValueList,
    Icon,
  },
  mixins: [
    RecordsUtils,
    RequestUtils,
    RouteUtils,
  ],
  props: {
    selectType: {
      type: String,
      default: 'select', // select | checked
    },
    action: {
      type: String,
      default: 'update', // update | delete
    },
  },
  emits: ['save'],
  data() {
    return {
      steps: {
        update: {
          checked: [
            'values',
            'confirm',
          ],
          select: [
            'source',
            'values',
            'confirm',
          ],
        },
        delete: {
          select: [
            'source',
            'confirm',
          ],
          checked: [
            'confirm',
          ],
        },
      },
      stepAction: this.selectType || 'select',
      recordAction: this.action || 'update',
      stepIndex: 0,
      criteria: {
        match: 'any',
        rules: [],
        groups: [],
      },
      groups: [],
      values: [],
      recordCount: 0,
    };
  },
  computed: {
    ...mapGetters([
      'checkedRecordIds',
    ]),
    matchCopy() {
      return (this.criteria.match === 'all') ? 'and match at least one of following' : 'or match all of the following';
    },
    title() {
      return (this.action === 'update') ? 'Update Records' : 'Delete Records';
    },
    actionPastTense() {
      return (this.action === 'update') ? 'updated' : 'deleted';
    },
    step() {
      return this.steps[this.recordAction][this.stepAction][this.stepIndex];
    },
    activeObject() {
      if (this.$route.params.objectKey) {
        return this.$store.getters.getObject(this.$route.params.objectKey);
      }

      return false;
    },

    pickedValues() {
      return _.map(this.values, (value) => _.pick(value, [
        'field',
        'value',
      ]));
    },
  },
  watch: {
    checkedRecordIds(newVal, oldVal) {
      // go back if user has unchecked all records
      if (newVal.length === 0 && oldVal.length > 0) {
        this.routerPushMaintainQuery(`/records/objects/${this.activeObject.key}`);
      }
    },
  },
  mounted() {
    if (!_.isEmpty(this.checkedRecordIds)) {
      this.stepAction = 'checked';
      this.recordCount = this.checkedRecordIds.length;
    }
  },
  methods: {
    ...mapMutations([
      'setCheckedRecordIds',
    ]),
    onDeleteGroupItem() {
      this.criteria.groups = this.criteria.groups.filter((group) => group.length);
    },
    onUpdateGroupItems(groupIndex, newItems) {
      this.criteria.groups[groupIndex] = newItems;
    },
    defaultRule() {
      const field = this.activeObject.fields[0];

      return {
        field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
      };
    },
    onUpdateCriteriaRules(newRules) {
      this.criteria.rules = newRules;
    },
    onAddRule() {
      if (!this.criteria.rules) {
        this.criteria.rules = [];
      }

      this.criteria.rules.push(this.defaultRule());

      this.$emit('save');
    },
    onAddGroup() {
      this.criteria.groups.push([
        this.defaultRule(),
      ]);

      this.$emit('save');
    },
    handleBatchRecordsCount(totalRecords) {
      this.recordCount = totalRecords;

      this.nextStep();
    },
    onSubmitSource() {
      log('Source.onSubmit()');

      if (this.action === 'delete') {
        return this.commitRequest({
          request: () => window.Knack.Api.getBatchRecordsCount(this.activeObject.key, this.criteria),
            onSuccess: ({ total_records }) => this.handleBatchRecordsCount(total_records) // eslint-disable-line
        });
      }

      return this.nextStep();
    },
    onSubmitValues() {
      if (!_.isEmpty(this.checkedRecordIds)) {
        return this.nextStep();
      }

      this.commitRequest({
        request: () => window.Knack.Api.getBatchRecordsCount(this.activeObject.key, this.criteria),
          onSuccess: ({ total_records }) => this.handleBatchRecordsCount(total_records) // eslint-disable-line
      });
    },
    onSubmitBatch() {
      // Update operations
      if (this.recordAction === 'update') {
        if (this.stepAction === 'checked') {
          return this.commitRequest({
            request: () => window.Knack.Api.updateBatchRecordsById(this.activeObject.key, this.checkedRecordIds, this.pickedValues),
            onSuccess: () => {
              this.setCheckedRecordIds([]);
              this.$store.dispatch('notifications/jobQueued', {
                jobType: 'update',
                objectKey: this.activeObject.key,
              });
              this.exitWizard();
            },
          });
        }

        return this.commitRequest({
          request: () => window.Knack.Api.updateBatchRecords(this.activeObject.key, this.criteria, this.pickedValues),
          onSuccess: () => {
            this.$store.dispatch('notifications/jobQueued', {
              jobType: 'update',
              objectKey: this.activeObject.key,
            });
            this.exitWizard();
          },
        });
      }

      // Delete operations

      if (this.stepAction === 'checked') {
        return this.commitRequest({
          request: () => window.Knack.Api.deleteBatchRecordsById(this.activeObject.key, this.checkedRecordIds),
          onSuccess: () => {
            this.setCheckedRecordIds([]);
            this.$store.dispatch('notifications/jobQueued', {
              jobType: 'delete',
              objectKey: this.activeObject.key,
            });

            eventBus.$emit('recordsDelete', this.checkedRecordIds);

            this.exitWizard();
          },
        });
      }

      return this.commitRequest({
        request: () => window.Knack.Api.deleteBatchRecords(
          this.activeObject.key,
          this.criteria.rules,
          this.criteria.groups,
          this.criteria.match,
        ),
        onSuccess: () => {
          this.$store.dispatch('notifications/jobQueued', {
            jobType: 'delete',
            objectKey: this.activeObject.key,
          });
          this.exitWizard();
        },
      });
    },
    exitWizard() {
      log('exit batch update');

      this.routerPushMaintainQuery(`/records/objects/${this.activeObject.key}`);
    },
    nextStep() {
      this.stepIndex++;
    },
    previousStep(event, stepCount = 1) {
      log('previous step', this.stepIndex, stepCount);

      this.stepIndex -= stepCount;
    },
    onUpdateValues(newValues) {
      this.values = newValues;
    },
  },
};
</script>

<style lang="scss">
</style>
