const storeState = {
  assetIsLoaded: false,
  assetMetadata: {},
  assetTokenKey: null,
};

const storeMutations = {
  resetAll(state) {
    state.assetTokenKey = null;
    state.assetIsLoaded = false;
    state.assetMetadata = {};
  },
  setAssetMetadata(state, metadata) {
    state.assetMetadata = metadata;
  },
  setAssetTokenKey(state, key) {
    state.assetTokenKey = key;
  },
  setDownloadUrl(state, url) {
    state.downloadUrl = url;
  },
  setAssetIsLoaded(state, status) {
    state.assetIsLoaded = status;
  },
  setPreviewUrl(state, url) {
    state.previewUrl = url;
  },
};

const storeActions = {
  async setAssetMetadata({ state, commit }, {
    assetId, pageKey, viewKey, fieldKey,
  }) {
    try {
      const { asset } = await window.Knack.Api.getAssetMetadata(assetId, pageKey, viewKey, fieldKey);

      asset.pageKey = pageKey;
      asset.viewKey = viewKey;
      asset.fieldKey = fieldKey;

      commit('setAssetMetadata', asset);
    } catch (err) {
      throw err;
    }
  },
  async setAssetTokenKey({ state, commit }, {
    assetId, pageKey, viewKey, fieldKey,
  }) {
    try {
      const { token_key: tokenKey } = await window.Knack.Api.getAssetTokenKey(assetId, pageKey, viewKey, fieldKey);

      commit('setAssetTokenKey', tokenKey);
    } catch (err) {
      throw err;
    }
  },
  async getAssetByTokenKey({ state, commit }, {
    assetId, pageKey, viewKey, fieldKey,
  }) {
    try {
      await window.Knack.Api.getAssetByTokenKey(assetId, state.assetTokenKey);
    } catch (err) {
      throw err;
    }
  },
  async showAssetViewer({ state, commit, dispatch }, {
    assetId, pageKey, viewKey, fieldKey,
  }) {
    // TODO: make concurrent with setAssetTokenKey
    await dispatch('setAssetMetadata', {
      assetId,
      pageKey,
      viewKey,
      fieldKey,
    });

    await dispatch('setAssetTokenKey', {
      assetId,
      pageKey,
      viewKey,
      fieldKey,
    });

    await commit('setAssetIsLoaded', true);
  },
};

const storeGetters = {
  assetIsLoaded: (state) => state.assetIsLoaded,
  assetTokenKey: (state) => state.assetTokenKey,
  assetMetadata: (state) => state.assetMetadata,
};

export default {
  namespaced: true,
  state: storeState,
  mutations: storeMutations,
  actions: storeActions,
  getters: storeGetters,
};
