<template>
  <div>
    <!-- CHILD PAGE MODAL -->
    <AddChildPage
      v-if="showChildPageModal"
      :page-vars="pageVarsForChildPage"
      @submit="onSubmitChildPage"
      @close="onClose"
    />

    <!-- ACTION RULE -->
    <GroupList
      v-model:items="actionRulesLocal"
      item-title="Action Link"
      class="action-rules small"
      scope-name="actionRule"
      :can-be-empty="false"
    >
      <template #default="{actionRule, actionRuleIndex}">
        <!-- VALIDATION ERROR MESSAGE -->
        <ValidationError
          v-if="!actionRule.record_rules.length"
          :errors="errors"
        />

        <!-- LINK -->
        <div>
          <label class="tw-toolbox-label font-medium leading-4 text-sm text-default">Link Text</label>
          <input
            v-model="actionRule.link_text"
            type="text"
          >
        </div>

        <!-- WHEN CRITERIA -->
        <div>
          <label class="tw-toolbox-label font-medium leading-4 text-sm text-default">Criteria</label>
          <div class="criteria-rules">
            <CriteriaList
              :criteria="actionRule.criteria"
              :object="object"
              :rule-type="RuleType.Action"
              @update:criteria="onUpdateActionRuleCriteria(actionRule, $event)"
            >
              <template #no-criteria>
                Display this link for every record.
              </template>
              <template #criteria-title>
                Show when the following criteria is true:
              </template>
            </CriteriaList>
          </div>
        </div>

        <!-- ACTIONS -->
        <div>
          <label class="tw-toolbox-label font-medium leading-4 text-sm text-default">Actions</label>

          <p>What actions will this link trigger?</p>

          <GroupList
            v-model:items="actionRule.record_rules"
            :item-add="true"
            :item-move="false"
            :default-item-add="defaultRecordRule()"
            item-title="Action"
            class="record-rules"
            scope-name="recordRule"
          >
            <template #default="{recordRule, recordRuleIndex}">
              <RuleItem
                :item="recordRule"
                :item-index="recordRuleIndex"
                :allow-email-action="true"
                :show-criteria="false"
              />
            </template>
          </GroupList>
          <p>
            <a
              v-if="actionRule.record_rules.length < 1"
              class="button small add-fill text-base p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group"
              data-cy="add-action"
              @click="onAddRecordAction(actionRule)"
            >
              <Icon
                class="text-default fill-current group-hover:text-brand-600"
                type="add"
              /> Add Action
            </a>
          </p>
        </div>

        <!-- SUBMIT RULES -->
        <div class="submit-rules-section margin-bottom-half">
          <label class="tw-toolbox-label font-medium leading-4 text-sm text-default">Outcome</label>
          <p>What happens after the action is complete?</p>

          <template
            v-for="(submitRule, submitRuleIndex) in actionRule.submit_rules"
            :key="`submitRule-${submitRuleIndex}`"
          >
            <div class="width-full">
              <select
                v-model="submitRule.action"
                class="margin-bottom"
                data-cy="submit-rule-outcome"
                @change="onChangeSubmitRuleAction(submitRule, submitRuleIndex, actionRuleIndex)"
              >
                <option value="message">
                  Show a confirmation message
                </option>
                <option
                  v-if="showParentPageOption"
                  value="parent_page"
                >
                  Redirect to the parent page
                </option>
                <option value="existing_page">
                  Redirect to an existing page
                </option>
                <option value="url">
                  Redirect to another website URL
                </option>
                <option value="child_page">
                  Redirect to a new child page
                </option>
              </select>

              <!-- MESSAGE -->
              <div
                v-if="submitRule.action === `message`"
                :key="`submitRule-${submitRuleIndex}-message`"
              >
                <label class="tw-toolbox-label font-medium leading-4 text-sm text-default">Message</label>
                <Redactor
                  v-model="submitRule.message"
                  :input="submitRule"
                  :config="redactorOptions"
                />
              </div>

              <div
                v-if="submitRule.action === `existing_page`"
                :key="`submitRule-${submitRuleIndex}-existing-page`"
              >
                <label class="tw-toolbox-label font-medium leading-4 text-sm text-default">Existing Page</label>
                <select
                  v-model="submitRule.existing_page"
                  data-cy="submit-rule-existing-page"
                >
                  <option
                    v-for="page in redirectPages"
                    :key="page.slug"
                    :value="page.slug"
                  >
                    {{ page.label }}
                  </option>
                </select>
              </div>

              <div
                v-if="submitRule.action === `url`"
                :key="`submitRule-${submitRuleIndex}-url`"
              >
                <label class="tw-toolbox-label font-medium leading-4 text-sm text-default">URL</label>
                <input
                  v-model="submitRule.url"
                  type="text"
                >
              </div>

              <div
                v-if="submitRule.action === `child_page`"
                :key="`submitRule-${submitRuleIndex}-child-page`"
              >
                <label class="tw-toolbox-label font-medium leading-4 text-sm text-default">Child Page</label>
                <div
                  v-if="!submitRule.scene"
                  class="level-left"
                >
                  <Icon
                    type="page"
                    class="small"
                    style="opacity: .65;"
                  />&nbsp;<a
                    class="fuchsia text-default"
                    data-cy="set-child-page"
                    @click="onEditChildPage(submitRule, submitRuleIndex, actionRuleIndex)"
                  >
                    Set the child page
                  </a>
                </div>
                <div
                  v-if="submitRule.scene"
                  class="level-left"
                >
                  <Icon
                    type="page"
                    class="small"
                    style="opacity: .65;"
                  />&nbsp;{{ submitRuleSceneName(submitRule.scene) }}&nbsp;<a
                    class="fuchsia text-defult"
                    @click="onEditChildPage(submitRule, submitRuleIndex, actionRuleIndex)"
                  >
                    [edit]
                  </a>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </GroupList>
    <div>
      <a
        class="button small add-fill text-base p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group"
        @click="onAddListItem"
      >
        <Icon
          class="text-default fill-current group-hover:text-brand-600"
          type="add"
        /> Add Action Link
      </a>
    </div>
  </div>
</template>

<script>
import isString from 'lodash/isString';
import { mapGetters } from 'vuex';
import AddChildPage from '@/components/views/form/AddChildPage';
import CriteriaList from '@/components/ui/lists/CriteriaList';
import GroupList from '@/components/ui/lists/GroupList';
import Icon from '@/components/ui/Icon';
import Redactor from '@/lib/redactor/VueRedactor';
import ViewUtils from '@/components/views/ViewUtils';
import RuleItem from '@/components/rules/RuleItem';
import ValidationError from '@/components/ui/ValidationError';
import { NEW_PAGE_KEY } from '@/lib/page/page-constants';
import { RuleType } from '@/constants/rules';

export default {
  name: 'ActionLinks',
  components: {
    AddChildPage,
    CriteriaList,
    GroupList,
    Icon,
    Redactor,
    RuleItem,
    ValidationError,
  },
  mixins: [
    ViewUtils,
  ],
  props: {
    actionRules: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:actionRules'],
  data() {
    return {
      redactorOptions: {
        autoresize: true,
        removeComments: true,
        buttons: [
          'html',
          'formatting',
          'bold',
          'italic',
          'deleted',
          'link',
          'unorderedlist',
          'orderedlist',
          'outdent',
          'indent',
        ],
        plugins: [
          'fontcolor',
          'image_web_link',
        ],
      },
      showChildPageModal: false,
      currentItem: {},
      currentSubmitAndActionRuleIndexes: {},
      RuleType,
    };
  },
  computed: {
    ...mapGetters([
      'getPageBySlug',
    ]),
    actionRulesLocal: {
      get() {
        return this.actionRules;
      },

      set(newVal) {
        this.$emit('update:actionRules', newVal);
      },
    },
    redirectPages() {
      return this.$store.getters.getStarterPlusChildPagesByObject(this.object.key);
    },
    showParentPageOption() {
      return this.$store.getters.activePage.parent;
    },
    objectConnections() {
      const conns = [];

      this.object.conns.forEach((conn) => {
        const connectionObject = this.$store.getters.getObject(conn.object);

        // you can`t insert ecommerce
        if (!connectionObject || connectionObject.get('ecommerce')) {
          return;
        }

        conns.push({
          value: `${conn.object}.${conn.key}`,
          label: `${connectionObject.inflections.singular} connected to this record (${connectionObject.inflections.singular} > ${conn.name})`,
          object: conn.object,
          name: conn.name,
          has: conn.has,
        });
      });

      return conns;
    },
    hasConnections() {
      return this.objectConnections.length > 0;
    },
    emailFieldOptions() {
      return this.object.getEmailFieldOptions();
    },
    userEmail() {
      return this.$store.state.app.get('settings').from_email || this.$store.state.app.get('account').session.user.email;
    },
    pageVarsForChildPage() {
      return {
        object: this.object.key,
      };
    },
  },
  methods: {
    submitRuleSceneName(scene) {
      // If this is an existing rule, this will be a scene slug
      if (isString(scene)) {
        const matchingScene = this.getPageBySlug(scene);

        if (matchingScene) {
          return matchingScene.name;
        }
      }

      return scene.name;
    },
    childPage() {
      return {
        object: this.object.key,
      };
    },
    getValuesObject(action, connectionKey) {
      if (action === 'record' || action === 'email') {
        return this.object;
      }

      // if not record, then it`s a connection
      const connectedObjectKey = connectionKey.split('.')[0];

      log(' - - - - -- getValuesObject', action, connectedObjectKey);

      return this.$store.getters.getObject(connectedObjectKey);
    },
    getConnectionActions(action) {
      action = action || 'connection'; // connection | insert

      const options = [];

      this.objectConnections.forEach((conn) => {
        let label;

        if (action === 'insert') {
          label = `A new ${conn.label}`;
        } else {
          label = `${(conn.has === 'one') ? 'The' : 'Each'} ${conn.label}`;
        }

        options.push({
          value: conn.value,
          label,
        });
      });

      return options;
    },
    defaultEmailRule() {
      return {
        action: 'email',
        email: {
          from_name: 'From Name',
          from_email: this.userEmail,
          subject: 'New Submission',
          message: 'New submission alert!',
          recipients: [
            this.defaultRecipient(),
          ],
        },
        criteria: [],
      };
    },
    defaultRecipient() {
      return {
        recipient_mode: 'to',
        recipient_type: 'custom',
        email: this.userEmail,
        field: this.emailFieldOptions.length ? this.emailFieldOptions[0].value : null,
      };
    },
    defaultRecordRule(item) {
      return {
        criteria: null,
        action: 'record',
        values: this.defaultRecordRuleValues(item),
      };
    },
    defaultRecordRuleValues(item) {
      return [
        {
          field: 'field_1',
          type: 'value',
          input: '',
          connection_field: null,
          value: '',
        },
      ];
    },
    defaultActionLink() {
      return {
        criteria: [],
        link_text: 'action',
        record_rules: [
          {
            criteria: null,
            action: 'record',
            values: [
              {
                field: '',
                type: 'value',
                input: '',
                connection_field: null,
                value: '',
              },
            ],
          },
        ],
        submit_rules: [
          {
            action: 'message',
            key: '',
            is_default: true,
            message: 'Action successfully completed.',
            reload_show: false,
            reload_auto: false,
          },
        ],
      };
    },
    onAddListItem(event) {
      return this.actionRulesLocal.push(this.defaultActionLink());
    },
    onAddRecordAction() {
      // action_rules index will always be 0 because this button is
      // used to add a new record action when no others exists
      return this.actionRulesLocal[0].record_rules.push(this.defaultRecordRule());
    },
    onChangeSubmitRuleAction(item, submitRuleIndex, actionRuleIndex) {
      // If this is an submit rule to create a child page, show the modal
      if (item.action === 'child_page' && !item.scene) {
        this.onEditChildPage(item, submitRuleIndex, actionRuleIndex);
      }
    },
    onUpdateActionRuleCriteria(actionRule, newCriteria) {
      actionRule.criteria = newCriteria;
    },
    onEditChildPage(item, submitRuleIndex, actionRuleIndex) {
      this.currentItem = item;

      this.currentSubmitAndActionRuleIndexes = {
        submitRuleIndex,
        actionRuleIndex,
      };

      this.showChildPageModal = true;
    },
    onSubmitChildPage(page) {
      log('onSubmitChildPage!!!', page);
      this.showChildPageModal = false;

      const { submitRuleIndex, actionRuleIndex } = this.currentSubmitAndActionRuleIndexes;

      const pageData = { ...page.raw };

      // We want to make sure a new key is generated.
      delete pageData.key;

      this.actionRulesLocal[actionRuleIndex].submit_rules[submitRuleIndex].scene = pageData;

      // Now delete the newPage floating around in the page cache.
      this.$store.dispatch('removePage', NEW_PAGE_KEY);
    },
    onSetActionDefaults(actionRuleIndex, item, itemIndex) {
      if (item.action === 'record' && !_.hasIn(item, 'values')) {
        this.actionRules[actionRuleIndex].record_rules[itemIndex].values = this.defaultRecordRule().values;
      }

      if (item.action === 'email' && !_.hasIn(item, 'email')) {
        this.actionRulesLocal[actionRuleIndex].record_rules[itemIndex].email = this.defaultEmailRule().email;
      }
    },
    onClose() {
      this.showChildPageModal = false;
    },
  },
};
</script>

<style lang="scss">

.action-rules {
  h2 {
    font-size: 1.125em;
    font-weight: bold;
    margin-bottom: .5em;
    border: 0;
  }

  .button {
    &.add-fill {
      background-color: #2ab27a;
      border-color: #2ab27a;
      color: #fff;
    }

    &.add-fill:hover {
      background-color: #2a9a6c;
      border-color: #2a9a6c;
    }
  }

  .criteria-rules {
    padding: 0 !important;
    margin: 0 !important;

    .action-list {
      padding: .5em;
      background-color: $gray200;
      border-radius: .24em;
    }

    label {
      font-weight: 500;
      color: #3a3d46;
      margin-bottom: .15em;
      display: block;
    }
  }

  .bottom-divider {
    margin-bottom: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(56, 57, 60, .125);
  }

  .form-emails {
    textarea {
      min-height: 200px !important;
    }

    .recipient-item {
      flex-grow: 1;

      > * {
        margin-right: 5px;
      }
    }
  }
}

.record-rules {
  li {
    .button {
      background-color: #2ab27a;
      border-color: #2ab27a;
      color: #fff;
    }
  }
}
</style>
