<template>
  <ConfirmModal
    confirm-type="alert"
    :back="backLink"
    title="Delete All Records?"
    confirm-text="Delete All Records"
    @confirm="onClickClearAll"
  >
    <p>This will permanently delete every record in the <strong>{{ object.inflections.plural }}</strong> object.</p>
    <p class="mb-0">
      <strong>This can't be undone!</strong>
    </p>
  </ConfirmModal>
</template>

<script>
import ConfirmModal from '@/components/ui/ConfirmModal';
import FieldUtils from '@/components/fields/FieldUtils';
import RequestUtils from '@/components/util/RequestUtils';
import RecordsUtils from '@/components/records/RecordsUtils';

export default {
  components: {
    ConfirmModal,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
    RecordsUtils,
  ],
  methods: {
    onClickClearAll() {
      this.commitRequest({
        request: () => this.object.clearAllRecords(),
        onSuccess: () => this.$router.push(this.backLink),
      });
    },
  },
};
</script>
