<template>
  <div
    class="toolbox-body p-4 pt-0"
    :class="{ 'toolbox-body--has-save-actions pb-[86px]': applicationHasPendingDesignChanges }"
  >
    <ValidationError
      v-if="errors.length"
      :errors="errors"
    />

    <slot />
  </div>
</template>

<script>

import isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';
import ValidationError from '@/components/ui/ValidationError';

export default {
  components: {
    ValidationError,
  },
  computed: {
    ...mapGetters([
      'getViewErrors',
      'applicationHasPendingDesignChanges',
    ]),
    errors() {
      if (!isEmpty(this.getViewErrors)) {
        return this.getViewErrors;
      }
      return [];
    },
  },
};
</script>

<style lang="scss">
.toolbox-body {
  padding: 1em;
  overflow: auto;
  height: 100%;
  flex: 1 0 0;

  p {
    font-size: 14px;
  }

  label {
    margin-bottom: 0.25em !important;
  }

  &--has-save-actions {
    padding-bottom: 40px;
  }
}

.toolbox.no-padding .toolbox-body {
  padding: 0;
}
</style>
