<template>
  <ul
    class="toolboxLinks"
    data-cy="toolbox-links"
  >
    <li
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      :data-cy="item.title"
      class="[&:not(:last-child)]:mb-2"
    >
      <RouterLink
        :to="routeAppend($route.path, item.link)"
        class="p-2 rounded-lg group hover:bg-brand-50 group"
        :data-feature="item.featureId"
        data-feature-x-offset="-30"
        data-feature-y-offset="4"
      >
        <div>
          <b class="block text-default group-hover:text-emphasis text-base font-semibold">
            {{ item.title }}
            <span
              v-if="item.filters"
              v-tippy
              content="Filters are limiting this view's records"
              class="toolbox__badge h-6 w-6 p-2 rounded-md text-sm leading-4 items-center ml-1 bg-subtle"
            >
              <Icon
                class="h-4 w-4 group-hover:text-default"
                type="filter-funnel"
              />
            </span>
            <ThemeIcon
              v-if="item.requiredTheme && item.requiredTheme > app.getThemeLevel()"
              class="toolbox__badge"
            />
          </b>
          <p class="text-default group-hover:text-emphasis text-xs m-0 mt-1">
            {{ item.copy }}
          </p>
        </div>
        <Icon
          type="arrow-forward"
          class="text-default group-hover:text-brand-400"
        />
      </RouterLink>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import ThemeIcon from '@/components/ui/ThemeIcon';

export default {
  components: {
    Icon,
    ThemeIcon,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
  },
};
</script>

<style lang="scss">
.toolboxLinks {
  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #8f9298;
    border-radius: .32em;
    padding: .75em;
    transition: all .1s ease-in;

    &:hover {
      background-color: #e4e6ea;

      svg {
        color: $active;
      }
    }

    p {
      font-size: .875rem;
      line-height: 1.25rem;
      margin-right: 1em;
      margin-bottom: 0;
    }
  }
  svg {
    width: 22px;
    height: 22px;
    flex-shrink: 0;

    g {
      path:first-child {
        fill: currentColor;
      }
    }
  }
  b {
    color: $active;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
  }
}

.toolbox__badge {
  margin-left: 8px;
  border-radius: 50em;
  background: #e4e5ea;
  background-color: $gray100;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  transition: background-color .1s ease-in;

  svg {
    width: 16px;
    height: 16px;
  }
}

.toolboxLinks a:hover .toolbox__badge {
  background-color: $gray200;
}

.toolbox__badge:hover {
  background-color: $gray300;
}
</style>
