<template>
  <div id="field-validation-rules">
    <p>Use rules to validate the <b>{{ fieldRaw.name }}</b> value.</p>
    <p>
      <label class="text-emphasis text-sm font-medium mb-2 leading-4"><input
        v-model="enabled"
        type="checkbox"
      >Add validation rules</label>
    </p>
    <GroupList
      v-if="enabled"
      v-model:items="validationRules"
      :item-delete="showDeleteRuleIcons"
      class="small"
    >
      <template #default="{item}">
        <div class="border border-solid border-b-subtle border-x-0 border-t-0 pb-4">
          <label class="text-default text-sm font-medium mb-2 leading-4">When the following criteria is true</label>
          <FieldList
            :object-key="objectRaw.key"
            :items="item.criteria"
            :use-validation-operators="true"
            class="naked"
            @update:items="onUpdateCriteria(item, $event)"
          />
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">In the Live App, prevent saving and show this message</label>
          <textarea v-model="item.message" />
        </div>
      </template>
    </GroupList>
    <div
      v-if="enabled"
      class="mt-6"
    >
      <a
        class="button small green-gray h-10 w-fit p-3 rounded-lg border border-solid border-default bg-white leading-4 text-emphasis text-base no-underline"
        @click="onAddRule"
      >
        <Icon
          class="text-default fill-current"
          type="add"
        /> Add Rule
      </a>
    </div>
  </div>
</template>

<script>
import FieldUtils from '@/components/fields/FieldUtils';
import GroupList from '@/components/ui/lists/GroupList';
import FieldList from '@/components/ui/lists/FieldList';
import Icon from '@/components/ui/Icon';

export default {
  name: 'FieldRulesValidation',
  components: {
    GroupList,
    FieldList,
    Icon,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    fieldLocal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      enabled: false,
    };
  },
  computed: {
    objectFields() {
      return this.object.fields;
    },
    validationRules: {
      get() {
        return this.fieldLocal.validation;
      },
      set(newValue) {
        this.fieldLocal.validation = newValue;
      },
    },
    showDeleteRuleIcons() {
      return this.validationRules.length > 1;
    },
  },
  watch: {
    enabled(newValue) {
      if (_.isEmpty(this.fieldLocal.validation)) {
        this.onAddRule();
      }

      if (!newValue) {
        this.fieldLocal.validation = [];
      }
    },
  },
  created() {
    if (!this.fieldLocal.validation) {
      this.fieldLocal.validation = [];
    }

    if (this.validationRules && this.validationRules.length) {
      this.enabled = true;
    }
  },
  methods: {
    onAddRule() {
      const newRule = {
        criteria: [
          {
            field: (this.objectFields.length > 0) ? this.objectFields[0].key : '',
            operator: 'contains',
          },
        ],
        value: '',
      };

      this.validationRules.push(newRule);
    },
    onDeleteRule(ruleIndex) {
      this.validationRules.splice(ruleIndex, 1);
    },
    onUpdateCriteria(item, newCriteria) {
      item.criteria = newCriteria;
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-rule {
  display: flex;
  justify-content: flex-end;

  svg {
    color: #af4343;
    width: 15px;
    height: 15px;
  }
}
</style>
