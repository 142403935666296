<template>
  <view-toolbox
    back-title="Edit Image"
    title="Settings"
  >
    <form />
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DesignUtils from '@/components/views/DesignUtils';
import ChoicePicker from '@/components/ui/inputs/ChoicePicker';
import ColorPicker from '@/components/ui/inputs/ColorPicker';
import Icon from '@/components/ui/Icon';

export default {
  name: 'ImageSettings',
  components: {
    ViewToolbox,
    ChoicePicker,
    ColorPicker,
    Icon,
  },
  mixins: [
    ViewUtils,
    DesignUtils,
  ],
};
</script>
