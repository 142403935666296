<template>
  <ViewToolbox
    :back-link="`/pages/${page.key}/views/${view.key}/search`"
    back-title="Search Menu"
    title="Search Results & Actions"
  >
    <Toggle
      v-if="searchResultsType === `list`"
      :is-open="true"
      class="no-top-styles"
    >
      <template #title>
        <span>Results Layout</span>
      </template>
      <template #content>
        <div class="inputGroup">
          <div>
            <label>Label Format</label>
            <LabelFormat
              :model-value="viewRaw.results"
              @update-property:model-value="onUpdateViewResultsProperty"
            />
          </div>
          <div>
            <label>Layout</label>
            <DetailsLayout />
          </div>
        </div>
      </template>
    </Toggle>
    <Toggle :is-open="true">
      <template #title>
        <span>Search Results & Actions</span>
      </template>
      <template #content>
        <AddItems
          :view-type="searchResultsType"
          :view="view"
          :object="object"
        >
          <p class="text-sm leading-5">
            Drag or click options below to add the search results.
          </p>
        </AddItems>
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import AddItems from '@/components/views/AddItems';
import DetailsLayout from '@/components/views/shared/DetailsLayout';
import LabelFormat from '@/components/views/shared/settings/LabelFormat';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'SearchResultsListBuild',
  components: {
    AddItems,
    DetailsLayout,
    LabelFormat,
    Toggle,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    searchResultsType() {
      return this.viewRaw.results_type;
    },
  },
  methods: {
    onUpdateViewResultsProperty({ propertyName, newValue }) {
      this.view.attributes.results[propertyName] = newValue;
    },
  },
};
</script>
