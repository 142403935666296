<template>
  <ViewToolbox
    back-title="Menu"
    :back-count="2"
    title="Link Properties"
  >
    <template v-if="link">
      <div
        v-if="link.type === 'scene'"
        class="mb-4"
      >
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Page</label>
        <div v-if="showScenePicker">
          <PageSelect
            :model-value="link.scene"
            :object-key="page.object"
            @update:model-value="setLinkScene($event)"
          />
        </div>
        <div v-else-if="linkPage && linkPage.key">
          <router-link
            v-tippy
            content="Navigate to this page"
            :to="'/pages/' + linkPage.key"
            class="underline flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis mt-2 no-underline"
          >
            <Icon
              class="w-4 h-4 mr-1 text-subtle"
              type="table-cells"
            />
            {{ linkPage.name }}
          </router-link>
        </div>
        <div
          v-else-if="linkPage"
          class="flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis mt-2"
        >
          <Icon
            class="w-4 h-4 mr-1 text-subtle"
            type="table-cells"
          />
          {{ linkPage.name }}
        </div>
      </div>

      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Link Text</label>
        <input
          v-model="link.name"
          v-focus
          data-field-name="name"
          type="text"
        >
      </div>

      <div
        v-if="link.type === 'url'"
        class="mb-4"
      >
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">URL</label>
        <input
          v-model="link.url"
          type="text"
        >
      </div>
      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Icon</label>
        <p class="mt-4">
          <IconPicker v-model:icon="linkIcon" />
        </p>
      </div>
      <div class="border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">New Window</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="link.new_window"
            type="checkbox"
          >Open link in a new window</label>
        </div>
      </div>
      <div v-if="menuFormat === 'tabs'">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Link Preview</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="previewTabAsActive"
            type="checkbox"
          >Preview tab as active <HelpIcon :text="previewActiveTabText" /></label>
        </div>
      </div>
    </template>

    <!-- Link Design -->
    <Toggle
      v-if="!isLegacyTheme"
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Link Design</span>
      </template>
      <template #content>
        <p
          v-if="menuIsLegacyStyle"
          class="mb-4 text-sm font-normal leading-4"
        >
          <strong>Links cannot be customized on 'Legacy' menus.</strong><br>
        </p>
        <ToggleSwitch
          v-model:checked="showLinkDesignOptions"
          :is-disabled="menuIsLegacyStyle"
          label="Use Custom Design Settings"
        >
          <template #offDescription>
            <span class="text-default">
              This link uses the
              <template v-if="isMenuDesignActive">
                view level
                <router-link
                  :to="menuViewPath"
                  class="text-default underline"
                >
                  menu design settings.
                </router-link>
              </template>
              <template v-else>
                <router-link
                  to="/settings/design/menus"
                  class="text-default underline"
                >
                  global menu design settings.
                </router-link>
              </template>
            </span>
          </template>

          <template #description>
            <span class="text-default">
              This link overrides the
              <template v-if="isMenuDesignActive">
                view level
                <router-link
                  :to="menuViewPath"
                  class="text-default underline"
                >
                  menu design settings
                </router-link>
              </template>
              <template v-else>
                <router-link
                  to="/settings/design/menus"
                  class="text-default underline"
                >
                  global menu design settings
                </router-link>
              </template>
              with the following colors:
            </span>
          </template>
        </ToggleSwitch>

        <MenusOptions
          v-if="showLinkDesignOptions"
          :custom-design-object="link.link_design[menuFormat]"
          :menu-format="menuFormat"
          :only-colors="true"
        />
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import HelpIcon from '@/components/ui/HelpIcon';
import PageSelect from '@/components/ui/inputs/PageSelect';
import IconPicker from '@/components/ui/inputs/IconPicker';
import MenusOptions from '@/components/settings/design/options/MenusOptions';
import Toggle from '@/components/ui/Toggle';
import ToggleSwitch from '@/components/ui/ToggleSwitch';
import Icon from '@/components/ui/Icon';
import useMenuPreviewActiveLinks from '@/composables/ui/useMenuPreviewActiveLinks';
import useMenuDesignOverrides from '@/composables/ui/useMenuDesignOverrides';

export default {
  name: 'MenuLinkProperties',
  components: {
    ViewToolbox,
    PageSelect,
    HelpIcon,
    IconPicker,
    MenusOptions,
    Toggle,
    ToggleSwitch,
    Icon,
  },
  mixins: [
    ViewUtils,
  ],
  setup() {
    const { isLinkActive, toggleLinkActive } = useMenuPreviewActiveLinks();
    const {
      menuDesign,
      isMenuDesignActive,
      isLinkDesignActive,
      setIsLinkDesignActive,
    } = useMenuDesignOverrides();

    return {
      isLinkActive,
      toggleLinkActive,
      menuDesign,
      isMenuDesignActive,
      isLinkDesignActive,
      setIsLinkDesignActive,
    };
  },
  data() {
    return {
      showingScenePicker: false,
    };
  },
  computed: {
    ...mapGetters('page', [
      'getPageBySlug',
      'startPages',
    ]),
    isLegacyTheme() {
      return this.$store.getters.app.getThemeLevel() < 2;
    },
    menuIsLegacyStyle() {
      if (this.isMenuDesignActive && this.menuDesign) {
        return this.menuDesign[this.menuFormat].format === 'legacy';
      }

      const appDesign = this.$store.getters.app.get('design');
      return appDesign.general.menus.links[this.menuFormat].format === 'legacy';
    },
    menuFormat() {
      if (this.viewRaw?.format === 'tabs') {
        return 'tabs';
      }
      return 'buttons';
    },
    menuViewPath() {
      const currentPath = this.$route.path;
      if (currentPath.includes('links/')) {
        return currentPath.slice(0, currentPath.indexOf('links/'));
      }
      return currentPath;
    },
    linkIndex() {
      return this.$route.params.linkIndex;
    },
    link() {
      if (!this.linkIndex || !this.hasActiveView) {
        return undefined;
      }

      const link = this.viewRaw.links[this.linkIndex];
      if (!link) {
        return undefined;
      }

      return link;
    },
    previewActiveTabText() {
      return 'A tab link would show as active on linked child pages';
    },
    previewTabAsActive: {
      get() {
        return this.isLinkActive(this.linkIndex, this.view.attributes.key);
      },
      set() {
        this.toggleLinkActive(this.linkIndex, this.view.attributes.key);
      },
    },
    showLinkDesignOptions: {
      get() {
        if (this.menuIsLegacyStyle) {
          return false;
        }
        return this.isLinkDesignActive(this.linkIndex);
      },
      set(newValue) {
        this.setIsLinkDesignActive(this.linkIndex, newValue);
      },
    },
    showScenePicker() {
      if (!this.link) {
        return false;
      }

      if (this.showingScenePicker) {
        return true;
      }

      // The scene picker should stay showing if it is ever shown once.
      // We need to refactor this component to not need a mutation here.
      this.showingScenePicker = true;

      return this.link.type === 'scene' && !this.link.scene;
    },
    linkPage() {
      // if we're after creating a new page, link.scene is the full page representation, so check for key
      if (get(this.link, 'scene.name')) {
        return this.link.scene;
      }

      return this.getPageBySlug(this.link.scene);
    },
    linkIcon: {
      get() {
        if (!this.link.icon) {
          return {
            icon: '', align: 'left',
          };
        }

        return this.link.icon;
      },
      set(newVal) {
        this.link.icon = newVal;
      },
    },
  },
  watch: {
    hasActiveView(nowHasActiveView) {
      if (nowHasActiveView && this.$refs.name) {
        this.$refs.name.focus();
      }
    },
  },
  methods: {
    setLinkScene(newScene) {
      if (!newScene) {
        return;
      }

      let oldPageName = '';
      if (this.link.scene && typeof this.link.scene === 'string') {
        oldPageName = this.getPageBySlug(this.link.scene).name;
      }

      let newPageName = this.link.name;
      if (this.link.name === 'New Link' || this.link.name === oldPageName) {
        const page = this.getPageBySlug(newScene);

        if (page) {
          newPageName = page.name;
        }
      }

      this.link.scene = newScene;
      this.link.name = newPageName;
    },
  },
};
</script>
