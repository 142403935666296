<template>
  <div class="body-scroll-wrapper builderLayout_bodyShadow">
    <div id="body-scroll">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BodyWrapper',
};
</script>

<style lang="scss">
.body-scroll-wrapper {
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
}

#body-scroll {
  position: absolute;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  padding: 0;
  overflow: scroll;
}

.body-scroll-wrapper.disabled #body-scroll {
  overflow: hidden;
}
</style>
