import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import {
  generatePagesStructure,
  injectParentIntoPage,
  parsePagesByDepth,
  parsePagesStructureByDepth,
  preparePagesForStructure,
  processPageMenuChildren,
  processPageMenuParent,
  sortPages,
} from '@/lib/page/page-helper';
import RawPage from '@/store/models/page/RawPage';
import viewCache from '@/store/utils/ViewCache';

import pageApi from './page-api';
import pageNavVisibility from './page-nav-visibility';
import pageView from './page-view';

/**
 * Gets the nav page type.
 *
 * @param {string} pageType
 * @returns {string}
 */
function getNavPageType(pageType) {
  // Group menu and other non-user pages in with `page` pages.
  return (pageType === 'user') ? 'user' : 'page';
}

const storeState = {
  isLoaded: false,
  pages: [],
  pagesByKey: {},
  pagesBySlug: {},
  pagesOrder: [],
  pagesStructure: [],
};

const storeGetters = {

  /**
   * Whether or not the pages have been loaded.
   *
   * @param {object} state
   * @returns {boolean}
   */
  isLoaded: (state) => state.isLoaded,

  /**
   * Gets all pages as an array.
   *
   * @param {object} state
   * @returns {RawPage[]}
   */
  pages: (state) => state.pages,

  /**
   * Gets a list of ordered page keys.
   *
   * @param {object} state
   * @returns {string[]}
   */
  pagesOrder: (state) => state.pagesOrder,

  /**
   * Gets the page structure.
   *
   * @param {object} state
   * @returns {object[]}
   */
  pageStructure: (state) => state.pagesStructure,

  /**
   * Gets a page with the given page key.
   *
   * @returns {function(string): RawPage | undefined}
   */
  getPageByKey: (state) => (pageKey) => state.pagesByKey[pageKey],

  /**
   * Gets a page with the given page slug.
   *
   * @returns {function(string): RawPage | undefined}
   */
  getPageBySlug: (state) => (pageSlug) => state.pagesBySlug[pageSlug],

  /**
   * Gets the global login page if the app has it turned on.
   *
   * @param {object} state
   * @param {object} getters
   * @param {object} rootState
   * @param {object} rootGetters
   * @returns {RawPage | undefined}
   */
  globalLoginPage: (state, getters, rootState, rootGetters) => {
    if (!rootGetters.isGlobalLogin) {
      return;
    }

    return state.pages.find((rawPage) => !rawPage.parent);
  },

  /**
   * Gets the start pages.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {RawPage[]}
   */
  startPages: (state, getters) => {
    // If the pages haven't finished their initial load then don't try to parse them.
    // This can prevent wasted computation cycles during initial load.
    if (!state.isLoaded) {
      return [];
    }

    return getters.topLevelPages.reduce((final, navPage) => {
      if (navPage.isMenuPage()) {
        const menuChildrenStartPages = navPage
          .menuChildren
          .map((menuChildPage) => menuChildPage.getStartPage())
          .filter(Boolean);

        // Push the start pages for the menu children.
        return final.concat(menuChildrenStartPages);
      } if (navPage.isLoginPage()) {
        // Find and push the login page's start page.
        const loginStartPage = navPage.getStartPage();

        if (loginStartPage) {
          final.push(loginStartPage);
        }

        return final;
      }

      // This page should be a start page.
      final.push(navPage);

      return final;
    }, []);
  },

  /**
   * Gets the non-user start pages.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {RawPage[]}
   */
  mainStartPages: (state, getters) => getters.startPages.filter((page) => !page.isUserPage()),

  /**
   * Gets the list of pages that can be added to a menu.
   *
   * These are pages that don't have a page parent (but can have a menu parent) and
   * are not themselves menus.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {RawPage[]}
   */
  menuListPages: (state, getters) => {
    const { globalLoginPage } = getters;

    // By using navPages and page.getChildrenPages() we are getting the correct page order.
    // state.pages order is not necessarily correct.
    const topNavPages = (globalLoginPage) ? globalLoginPage.getChildrenPages() : getters.navPages.main;

    return topNavPages.reduce((final, navPage) => {
      if (navPage.isMenuPage()) {
        return final.concat(navPage.menuChildren);
      }

      final.push(navPage);

      return final;
    }, []);
  },

  /**
   * Gets the top level pages.
   * These are all the pages that don't have a page parent or menu parent.
   * Unlike the start pages, these will include the login pages instead of their children.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {RawPage[]}
   */
  topLevelPages: (state, getters) => {
    // If the pages haven't finished their initial load then don't try to parse them.
    // This can prevent wasted computation cycles during initial load.
    if (!state.isLoaded) {
      return [];
    }

    const { globalLoginPage } = getters;

    // By using navPages and page.getChildrenPages() we are getting the correct page order.
    // state.pages order is not necessarily correct.
    const topMainNavPages = (globalLoginPage) ? globalLoginPage.getChildrenPages() : getters.navPages.main;

    return [
      ...topMainNavPages,
      ...getters.navPages.user,
    ];
  },

  /**
   * The full list of pages that should display in the left navigation.
   *
   * @param {object} state
   * @returns {{main: RawPage[], user: RawPage[]}}
   */
  navPages: (state) => {
    // If the pages haven't finished their initial load then don't try to parse them.
    // This can prevent wasted computation cycles during initial load.
    if (!state.isLoaded) {
      return [];
    }

    const mainNavPages = [];
    const userNavPages = [];

    state.pages.forEach((page) => {
      if (page.getParentPage()) {
        return;
      }

      const pageType = getNavPageType(page.type);

      if (pageType === 'user') {
        userNavPages.push(page);
      } else {
        mainNavPages.push(page);
      }
    });

    return {
      main: mainNavPages,
      user: userNavPages,
    };
  },
};

const storeMutations = {

  /**
   * Clears the pages from the stores.
   *
   * @param {object} state
   */
  clearPages(state) {
    state.pages = [];
    state.pagesByKey = {};
    state.pagesBySlug = {};
    state.pagesOrder = [];
    state.pagesStructure = [];

    viewCache.clearAllViews();
  },

  /**
   * Processes when the page order is manually changed (through drag and drop).
   *
   * @param {object} state
   * @param {object} payload
   * @param {string[]} payload.newOrder
   */
  processNewPagesOrder(state, { newOrder }) {
    state.pagesOrder = newOrder;

    state.pages = sortPages(state.pages, state.pagesOrder);
  },

  /**
   * Sets the pages order and sorts the page objects.
   *
   * @param {object} state
   * @param {object} payload
   * @param {string[]} payload.pagesOrder
   * @param {boolean} [payload.skipSort]
   */
  setPagesOrder(state, { pagesOrder, skipSort }) {
    state.pagesOrder = pagesOrder || [];

    if (skipSort) {
      return;
    }

    state.pages = sortPages(state.pages, state.pagesOrder);
  },

  /**
   * Sets the page structure.
   *
   * @param {object} state
   * @param {object[]} newPageStructure
   */
  setPagesStructure(state, newPageStructure) {
    state.pagesStructure = newPageStructure;
  },

  /**
   * Sets whether or not the pages have loaded.
   *
   * @param {object} state
   * @param {boolean} newIsLoaded
   */
  setIsLoaded(state, newIsLoaded) {
    state.isLoaded = Boolean(newIsLoaded);
  },

  /**
   * Removes a page from the store.
   *
   * @param {object} state
   * @param {object} payload
   * @param {RawPage} payload.page
   * @param {boolean} [payload.keepMapData]
   */
  unsetPage(state, { page, keepMapData = false }) {
    const pageKey = page.key;
    const pageSlug = page.slug;
    const pageIndex = state.pages.findIndex((existingPage) => existingPage.key === pageKey);

    state.pages.splice(pageIndex, 1);

    if (keepMapData) {
      return;
    }

    delete state.pagesByKey[pageKey];
    delete state.pagesBySlug[pageSlug];
  },

  /**
   * Updates the page slug in the pagesBySlug object.
   *
   * @param {object} state
   * @param {object} payload
   * @param {string} payload.newSlug
   * @param {string} payload.oldSlug
   */
  updatePageSlug(state, { newSlug, oldSlug }) {
    if (newSlug === oldSlug || !state.pagesBySlug[oldSlug]) {
      return;
    }

    state.pagesBySlug[newSlug] = state.pagesBySlug[oldSlug];

    delete state.pagesBySlug[oldSlug];
  },
};

const storeActions = {

  /**
   * Adds one or more pages to the store.
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {function(string, *)} context.dispatch
   * @param {object} context.getters
   * @param {object} context.rootGetters
   * @param {object} context.state
   * @param {object} payload
   * @param {RawPage[]} payload.pages
   * @param {number} [payload.addIndex]
   * @param {boolean} [payload.processPage]
   * @returns {Promise<RawPage>}
   */
  async addPages(
    {
      commit, dispatch, getters, rootGetters, state,
    },
    { pages, addIndex, processPage = true },
  ) {
    return Promise.all(
      pages.map(async (page) => {
        const { views } = page;

        // Make sure views are not stored in the page cache.
        delete page.views;

        const rawPage = new RawPage(page);

        // We are updating the state here instead of using a commit to increase performance
        // on apps with a lot of pages.
        if (!isNil(addIndex)) {
          state.pages.splice(addIndex, 0, rawPage);
        } else {
          state.pages.push(rawPage);
        }

        state.pagesByKey[rawPage.key] = rawPage;
        state.pagesBySlug[rawPage.slug] = rawPage;

        if (views) {
          viewCache.setViewsForPage(views, rawPage.key);
        }

        if (processPage) {
          await dispatch('processPage', { page: rawPage });
        }

        return rawPage;
      }),
    );
  },

  /**
   * Removes a page from the store.
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {function(string, *)} context.dispatch
   * @param {object} context.getters
   * @param {object} payload
   * @param {RawPage} payload.page
   * @returns {Promise<void>}
   */
  async removePage({ commit, dispatch, getters }, { page }) {
    const pageKey = page.key;

    if (!getters.getPageByKey(pageKey)) {
      return;
    }

    commit('unsetPage', { page });

    viewCache.clearViewsForPage(pageKey);

    // Regenerate the page structure and page order data.
    dispatch('_generatePageStructure');
  },

  /**
   * Sets the views for a page to the given view data.
   *
   * @param {object} unused
   * @param {object} payload
   * @param {string} pageKey
   * @param {object} views
   * @returns {Promise<void>}
   */
  async setPageViews(unused, { pageKey, views }) {
    views.forEach((view) => {
      viewCache.setView(view, pageKey);
    });
  },

  /**
   * Builds the nav menu pages that are sorted by type.
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {function(string, *)} context.dispatch
   * @param {object} context.getters
   * @param {object} context.rootGetters
   * @param {object} payload
   * @param {RawPage[]} payload.pages
   * @param {object[]} payload.pagesStructure
   * @returns {Promise<void>}
   */
  async loadPages({
    commit, dispatch, getters, rootGetters,
  }, { pages, pagesStructure }) {
    commit('clearPages');

    // Push the user pages to the bottom of the list.
    // By sorting this here, a lot of later page order logic is simplified. No need filter
    // for user and non-user pages when displaying a full list of pages.
    const preSortedPages = sortBy(pages, (page) => (page.type === 'user' ? 1 : 0));

    await dispatch('addPages', { pages: preSortedPages, processPage: false });

    if (pagesStructure) {
      // We aren't awaiting this because most things don't need pagesOrder to be set right away
      // and it speeds up the initial page load.
      dispatch('_processPageStructure', pagesStructure);
    } else {
      preparePagesForStructure();

      // We aren't awaiting this because most things don't need pagesOrder to be set right away
      // and it speeds up the initial page load.
      dispatch('_generatePageStructure');
    }

    commit('setIsLoaded', true);
  },

  /**
   * Process the extra information for the page.
   * This includes things like parent, children, authenticated, etc.
   *
   * In order for this method to function correctly, all the pages must be preloaded into
   * this cache. This method does not work on the first loading of pages (use
   * _generatePageStructure() or _processPageStructure() instead).
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {function(string, *)} context.dispatch
   * @param {object} payload
   * @param {RawPage} payload.page
   * @returns {Promise<void>}
   */
  async processPage({ commit, dispatch, rootGetters }, { page }) {
    const { pagesOrder } = injectParentIntoPage(page);

    // Page order does not work well with global login pages. We need to update page order to store
    // the full list of pages at all depths (not just the top level pages) to address this.
    if (pagesOrder && !rootGetters.isGlobalLogin) {
      commit('setPagesOrder', { pagesOrder });
    }

    processPageMenuChildren(page);

    processPageMenuParent(page);

    // Authentication changes can propagate up to chain and then back down again, so we
    // must start with the top level when parsing the depth here.
    const topLevelPage = page.getTopLevelParent();

    // Add depth and authentication properties to the pages.
    parsePagesByDepth([topLevelPage]);

    dispatch('_generatePageStructure');
  },

  /**
   * Processes all the pages after assuring that they are sorted for processing.
   *
   * Page Structure is used to populate meta information about the pages, such as:
   *  * parent-child depth
   *  * parent-child structure including menus
   *  * authenticated
   *  * authentication_profiles
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {object} context.state
   * @returns {Promise<void>}
   */
  async _generatePageStructure({ commit, state }) {
    const { pagesStructure, pagesOrder } = generatePagesStructure(state.pages);

    commit('setPagesOrder', { pagesOrder, skipSort: true });
    commit('setPagesStructure', pagesStructure);
  },

  /**
   * Processes a page structure into the pages.
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {object[]} pagesStructure
   * @returns {Promise<void>}
   */
  async _processPageStructure({ commit }, pagesStructure) {
    const { pagesOrder } = parsePagesStructureByDepth(pagesStructure, []);

    commit('setPagesOrder', { pagesOrder, skipSort: true });
    commit('setPagesStructure', pagesStructure);
  },
};

export default {
  // Full namespace: page
  page: {
    namespaced: true,
    modules: {
      ...pageApi,
      ...pageNavVisibility,
      ...pageView,
    },
    state: storeState,
    getters: storeGetters,
    mutations: storeMutations,
    actions: storeActions,
  },
};
