<template>
  <div>
    <label
      v-if="showLabel"
      class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label mt-0.5"
    >
      Filters
    </label>

    <div class="input-labels">
      <label class="text-emphasis text-base mb-3 font-normal items-baseline"><input
        v-model="localFilterType"
        type="radio"
        value="none"
      >Don't allow records to be filtered</label>
      <label class="text-emphasis text-base mb-3 font-normal items-baseline"><input
        v-model="localFilterType"
        type="radio"
        value="fields"
      >Allow users to add custom filters</label>

      <div
        v-if="localFiltersCustom"
        class="margin-bottom-onehalf selectionGroup m-0 p-0 border-0"
        style="margin-top: 6px;"
      >
        <template v-if="showFieldsChoice">
          <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label mt-4">Which fields can be filters?</label>
          <select
            v-model="localFilterFields"
            class="margin-bottom text-base py-2 pl-3 leading-5"
          >
            <option value="view">
              The fields being used by this view
            </option>
            <option value="object">
              All fields in table
            </option>
          </select>
        </template>

        <!-- Filter defaults -->
        <div class="margin-bottom">
          <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Filter defaults</label>
          <FieldList
            v-if="customFilters.length > 0"
            :object-key="object.key"
            :items="customFilters"
            :can-be-empty="true"
            @update:items="onUpdateCustomFilters"
          />
          <a
            v-if="customFilters.length === 0"
            class="button fuchsia-gray tiny p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium capitalize truncate"
            @click="onAddPresetFilter"
          >
            <Icon
              class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
              type="plus-thin"
            />
            Add defaults for initial filters
          </a>
        </div>

        <!-- Connected field restrictions -->
        <FiltersConnectionSources
          v-model:connection-sources="localConnectionSources"
          :filter-fields-type="localFilterFields"
        />
      </div>

      <!--<label><input v-model="localFiltersMenu" type="checkbox">Use a filter menu</label>-->
      <label class="text-emphasis text-base mb-3 font-normal items-baseline"><input
        v-model="localFilterType"
        type="radio"
        value="menu"
      >Use a filter menu</label>
      <div
        v-if="localFiltersMenu"
        class="margin-bottom-onehalf selectionGroup m-0 p-0 border-0 mt-2"
        style="margin-top: 6px;"
      >
        <GroupList
          v-model:items="localMenuFilters"
          item-title="Link"
          class="small"
          :can-delete-item="canDeleteItem"
          group-list-item-classes="bg-subtle"
        >
          <template #default="{ item, itemIndex }">
            <div>
              <label class="tw-toolbox-label">Filters</label>
              <FieldListItem
                :item="item"
                :default-item="defaultMenuFilterCriteria"
                :object-key="object.key"
                class="level-left width-full items-start"
                @update:item="onUpdateMenuFilter(itemIndex, $event)"
              />
            </div>
            <div>
              <label class="tw-toolbox-label">Link Text</label>
              <input
                v-model="item.text"
                type="text"
                @focus="setMenuDefault(item)"
              >
            </div>
          </template>
        </GroupList>
        <a
          class="button fuchsia-gray tiny p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium mt-4"
          @click="onAddMenuFilter"
        >
          <Icon
            class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
            type="plus-thin"
          />
          Add menu link
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupList from '@/components/ui/lists/GroupList';
import FieldList from '@/components/ui/lists/FieldList';
import FieldListItem from '@/components/ui/lists/FieldListItem';
import FiltersConnectionSources from '@/components/views/shared/settings/FiltersConnectionSources';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    GroupList,
    FieldList,
    FieldListItem,
    FiltersConnectionSources,
    Icon,
  },
  props: {
    connectionSources: {
      type: Object,
      default: undefined,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showFieldsChoice: {
      type: Boolean,
      default: true,
    },
    filterType: {
      type: String,
      default: 'none',
    },
    filtersCustom: {
      type: Boolean,
      default: false,
    },
    filterFields: {
      type: String,
      default: 'view',
    },
    presetFilters: {
      type: Array,
      default: () => ([]),
    },
    allowPresetFilters: {
      type: Boolean,
      default: false,
    },
    menuFilters: {
      type: Array,
      default: () => ([]),
    },
    filtersMenu: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'update:allowPresetFilters',
    'update:filterFields',
    'update:filterType',
    'update:menuFilters',
    'update:presetFilters',
    'update:connectionSources',
  ],
  data() {
    return {
      localFilterType: this.filterType,
      localFilterFields: this.filterFields,
      localPresetFilters: this.presetFilters,
      localAllowPresetFilters: this.allowPresetFilters,
    };
  },

  computed: {
    ...mapGetters([
      'getField',
    ]),
    customFilters() {
      return (this.localPresetFilters && this.localAllowPresetFilters)
        ? this.localPresetFilters
        : [];
    },
    localFiltersCustom() {
      return this.localFilterType === 'fields';
    },
    localFiltersMenu() {
      return this.localFilterType === 'menu';
    },
    localMenuFilters: {
      get() {
        return this.menuFilters;
      },
      set(newVal) {
        this.$emit('update:menuFilters', newVal);
      },
    },
    localConnectionSources: {
      get() {
        return this.connectionSources;
      },
      set(newVal) {
        this.$emit('update:connectionSources', newVal);
      },
    },
  },
  watch: {
    localFilterType(newVal) {
      if (newVal === 'menu') {
        if (!this.localMenuFilters.length) {
          this.onAddMenuFilter();
        }

        if (this.localMenuFilters[0] && !this.localMenuFilters[0].field) {
          this.localMenuFilters = [
            { ...this.defaultMenuFilter().criteria[0], ...this.localMenuFilters[0] },
          ];
        }
      }

      this.$emit('update:filterType', this.localFilterType);
    },
    localFilterFields() {
      this.$emit('update:filterFields', this.localFilterFields);
    },
    localPresetFilters() {
      this.$emit('update:presetFilters', this.localPresetFilters);
    },
    localAllowPresetFilters() {
      this.$emit('update:allowPresetFilters', this.localAllowPresetFilters);
    },
  },
  methods: {
    setMenuDefault(item) {
      if (item.text) {
        return;
      }

      // Prefer labels when present. Connections store labels for display values
      if (item.label) {
        item.text = item.label;

        return;
      }

      if (typeof item.value !== 'object') {
        let { value } = item;

        if (item.field) {
          const itemField = this.getField(item.field);

          if (itemField.isBoolean()) {
            value = itemField.getBooleanLabel(value);
          }
        }

        item.text = String(value);
      }
    },

    defaultPresetFilter() {
      const field = this.object.fields[0];

      return {
        field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
      };
    },

    onAddPresetFilter() {
      this.localAllowPresetFilters = true;

      if (this.localPresetFilters.length === 0) {
        this.localPresetFilters.push(this.defaultPresetFilter());
      }
    },

    defaultMenuFilter() {
      return {
        text: '',
        ...this.defaultMenuFilterCriteria(),
      };
    },

    defaultMenuFilterCriteria() {
      const field = this.object.fields[0];

      return {
        field: field.key,
        operator: field.getFirstRuleOperator(),
        value: field.getFilterDefault(),
      };
    },

    onAddMenuFilter() {
      this.localMenuFilters.push(this.defaultMenuFilter());
      this.$emit('update:menuFilters', this.localMenuFilters);
    },

    onUpdateMenuFilter(itemIndex, newFilter) {
      const filters = this.localMenuFilters.slice();
      filters[itemIndex] = newFilter;

      this.localMenuFilters = filters;
    },

    canDeleteItem() {
      return this.localMenuFilters.length > 1;
    },

    onUpdateCustomFilters(newFilters) {
      this.localPresetFilters = newFilters;
    },
  },
};
</script>

<style lang="scss" scoped>
form .input-labels label {
  font-weight: 500;
}
</style>
