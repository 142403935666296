<template>
  <div>
    <div class="map-row level-equal min-h-[51px]">
      <label class="field text-emphasis text-base font-normal leading-4">
        <input
          v-model="column.added"
          type="checkbox"
        > <span class="light text-emphasis">
          {{ column.index + 1 }}
        </span> {{ column.name }}
      </label>
      <div class="column level-right">
        <template v-if="column.added">
          <select
            v-model="column.addedFieldType"
            class="h-10 text-base font-normal leading-5"
          >
            <option
              v-for="type in fieldTypes"
              :key="type.key"
              :value="type.key"
            >
              {{ type.label }}
            </option>
          </select>
        </template>
      </div>
    </div>

    <template v-if="column.added && isConnectionType">
      <div class="map-sub-row map-connection">
        <div class="top level-equal">
          <label>Which table will this connect with?</label>
          <select
            v-model="column.connectionObjectKey"
            class="h-10 text-base font-normal leading-5"
          >
            <option
              v-for="object in objects"
              :key="object.key"
              :value="object.key"
            >
              {{ object.name }}
            </option>
          </select>
        </div>
        <div class="top level-equal">
          <label>Which <b>{{ connectedObjectName }}</b> field will match the <b>{{ column.name }}</b> column?</label>
          <select
            v-model="column.connectionMatchField"
            class="h-10 text-base font-normal leading-5"
          >
            <option
              v-for="field in importMatchableConnectedObjectFields"
              :key="field.key"
              :value="field.key"
            >
              {{ field.name }}
            </option>
          </select>
        </div>
        <div class="level-equal">
          <label>What if no match is found?</label>
          <select
            v-model="column.connectionNoMatchRule"
            class="h-10 text-base font-normal leading-5"
          >
            <option value="skip">
              Skip this {{ column.name }} value
            </option>
            <option value="insert">
              Insert a new {{ connectedObjectName }} with this {{ column.name }} value
            </option>
          </select>
        </div>
      </div>
    </template>

    <template v-if="column.added && isComplexType">
      <div
        class="map-sub-row level-equal"
        style="margin: 0;"
      >
        <p>{{ capitalize(column.addedFieldType) }} fields can have multiple parts to map:</p>
      </div>

      <div
        v-for="(part, partIndex) in column.parts"
        :key="partIndex"
        class="map-sub-row level-equal"
        :data-cy="getPartLabel(part)"
      >
        <label class="field text-emphasis text-base font-normal leading-4">
          <span
            v-if="part.warn"
            v-tippy="{
              hideOnClick: false,
              interactive: true,
              placement: 'top',
              showOnCreate: true,
              sticky: true,
              trigger: 'manual',
            }"
            content="At least one part must be mapped."
          />
          <input
            v-model="part.mapped"
            type="checkbox"
            @click="onCheckComplexPart($event, partIndex)"
            @mouseleave="part.warn = false"
          > {{ part.part.label }}
        </label>
        <div class="column">
          <select
            v-if="part.mapped"
            v-model="part.mappedColumnIndex"
            class="h-10 text-base font-normal leading-5"
          >
            <option
              v-for="(availableColumn, availableColumnIndex) in getAvailableColumnsToMap(part)"
              :key="availableColumnIndex"
              :value="availableColumn.value"
            >
              {{ availableColumn.label }}
            </option>
          </select>
        </div>
      </div>
    </template>

    <template v-if="column.added && isDateType">
      <div class="map-sub-row map-connection border-l-0 -ml-0.5">
        <div class="font-bold">
          Field date/time format
        </div>
        <div class="top level-equal">
          <div>
            <div>Select the date format</div>
            <div
              v-for="format in getDateFormatOptions()"
              :key="format.key"
            >
              <label>
                <input
                  v-model="selectedDateFormat"
                  type="radio"
                  :value="format.key"
                  :checked="matchPredictedDateFormat(format.key)"
                >
                <span class="text-emphasis text-base font-normal">{{ format.label }}</span>
              </label>
            </div>
          </div>
        </div>

        <div class="top level-equal">
          <div>
            <div>Select the time format</div>
            <div
              v-for="format in getTimeFormatOptions()"
              :key="format.key"
            >
              <label>
                <input
                  v-model="selectedTimeFormat"
                  type="radio"
                  :value="format.key"
                  :checked="matchPredictedTimeFormat(format.key)"
                >
                <span class="text-emphasis text-base font-normal">{{ format.label }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';

export default {
  props: {
    column: {
      type: Object,
      default: () => {},
    },
    fieldTypes: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    availableColumns: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    mappedColumnIndexes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedDateFormat: null,
      selectedTimeFormat: null,
    };
  },
  computed: {
    objects() {
      return this.$store.getters.objects;
    },
    isConnectionType() {
      return (this.column.addedFieldType === 'connection');
    },
    isComplexType() {
      if (this.column.addedFieldType === false) {
        return false;
      }

      return (this.addedField.complex);
    },
    addedField() {
      return this.fieldTypes.find((type) => type.key === this.column.addedFieldType);
    },
    fieldParts() {
      return this.addedField.parts;
    },
    connectedObject() {
      if (!this.column.connectionObjectKey) {
        return false;
      }

      return this.$store.getters.getObject(this.column.connectionObjectKey);
    },
    connectedObjectName() {
      if (!this.connectedObject) {
        return '';
      }

      return this.connectedObject.inflections.singular;
    },
    importMatchableConnectedObjectFields() {
      if (!this.connectedObject) {
        return [];
      }

      return this.connectedObject.fields
        .filter((field) => field.isImportMatchable());
    },
    isDateType() {
      return (this.column.addedFieldType === 'date_time');
    },
  },
  watch: {
    'column.added': function (isAdded, isAddedOld) {
      log('ImportMapAdd.watch.column.added(), column: ', this.column);

      if (isAdded === false) {
        return;
      }

      // if no index, check for prediction
      if (this.column.addedFieldType === false && !isNil(this.column.predictedType)) {
        this.column.addedFieldType = this.column.predictedType;
      }

      // make sure parts are still available
      if (this.column.parts) {
        this.column.parts = this.column.parts.map((part) => {
          log('part:');
          log(part);
          log(`this.getMappedColumnCount(part.mappedColumnIndex): ${this.getMappedColumnCount(part.mappedColumnIndex)}`);

          // if mapped and we have an index, make sure this part's mapped column is still available
          if (part.mapped && part.mappedColumnIndex && this.getMappedColumnCount(part.mappedColumnIndex) > 1) {
            part.mapped = false;
            part.mappedColumnIndex = false;
          }

          return part;
        });
      }

      log('this.column.parts now:', this.column.parts);
    },
    'column.addedFieldType': function (type, typeOld) {
      log('ImportMapAdd.watch.column.addedFieldType()', 'type: ', type, 'this.column.predictedPart: ', this.column.predictedPart);

      if (this.isDateType) {
        this.ensureDateField();
      }

      if (this.isComplexType) {
        this.column.parts = [];
        this.column.complex = true;

        this.addedField.parts.forEach((part) => {
          this.column.parts.push({
            mapped: false,
            mappedColumnIndex: false,
            warn: false,
            part,
          });
        });

        if (this.column.predictedPart) {
          this.column.parts.forEach((part) => {
            if (part.part.key === this.column.predictedPart) {
              part.mapped = true;
              part.mappedColumnIndex = this.column.index;
            }
          });
        }

        console.log('any mapped', this.column.parts.find((part) => part.mapped));

        // at minimum, populate first sub-part with this field
        if (!this.column.parts.find((part) => part.mapped)) {
          this.column.parts[0].mapped = true;
          this.column.parts[0].mappedColumnIndex = this.column.index;
        }
      }

      if (type === 'connection') {
        this.column.connectionObjectKey = this.objects[0].key;
        this.column.connectionNoMatchRule = 'skip';
      }
    },
    'column.connectionObjectKey': function (type, typeOld) {
      this.column.connectionMatchField = this.objects[0].key;
    },
    selectedDateFormat(format) {
      this.column.predictedFormat = {
        ...this.column.predictedFormat,
        date_format: format,
      };
      this.column.format = {
        ...this.column.format,
        date_format: format,
      };
    },
    selectedTimeFormat(format) {
      this.column.predictedFormat = {
        ...this.column.predictedFormat,
        time_format: format,
      };
      this.column.format = {
        ...this.column.format,
        time_format: format,
      };
    },
  },
  created() {
    if (!this.column.addedFieldType) {
      this.column.addedFieldType = this.column.predictedType || 'short_text';
    }
  },
  methods: {
    capitalize,
    getMappedColumnCount(columnIndex) {
      let count = 0;

      this.mappedColumnIndexes.forEach((index) => {
        if (index === columnIndex) {
          count++;
        }
      });

      log(`done, count is: ${count}`);

      return count;
    },
    getAvailableColumnsToMap(field) {
      const options = [];

      // nothing is mapped yet, so add a default
      // if (field.mappedColumnIndex === false) {
      options.push({
        value: false, label: 'Select a column',
      });

      // add the available columns
      this.availableColumns.forEach((col, availableIndex) => {
        // add the available column
        options.push({
          value: col.index,
          label: `${col.index + 1} ${col.name}`,
        });
      });

      // add the current column
      if (field && field.mappedColumnIndex !== false) {
        options.push({
          value: field.mappedColumnIndex,
          label: `${field.mappedColumnIndex + 1} ${this.getColumnName(field.mappedColumnIndex)}`,
        });
      }

      // sort by value, which is the index
      sortBy(options, [
        'value',
      ]);

      return options;
    },
    getColumnName(columnIndex) {
      log('ImportMapAdd.methhods.getColumnName()');
      log(`column for index ${columnIndex}:`);
      log(this.columns[columnIndex]);

      return this.columns[columnIndex].name;
    },

    onCheckComplexPart(event, partIndex) {
      const part = this.column.parts[partIndex];

      if (part.mapped && this.column.parts.filter((columnPart) => columnPart.mapped).length === 1) {
        part.warn = true;

        return event.preventDefault();
      }

      part.warn = false;
    },

    getPartLabel(part) {
      return get(part, 'part.label');
    },

    getDateFormatOptions() {
      const fieldType = this.fieldTypes.find(
        (fieldType) => fieldType.key === 'date_time',
      );
      return fieldType ? fieldType.formats.date : [];
    },

    getTimeFormatOptions() {
      const fieldType = this.fieldTypes.find(
        (fieldType) => fieldType.key === 'date_time',
      );
      return fieldType ? fieldType.formats.time : [];
    },

    matchPredictedDateFormat(format) {
      if (!this.column.format || !this.column.format.date_format) {
        return format === 'mm/dd/yyyy';
      }
      return this.column.format.date_format === format;
    },

    matchPredictedTimeFormat(format) {
      if (!this.column.format || !this.column.format.time_format) {
        return format === 'Ignore Time';
      }
      return this.column.format.time_format === format;
    },

    ensureDateField() {
      this.column.predictedType = 'date_time';
      this.column.predictedFormat = this.column.predictedFormat || {
        date_format: 'mm/dd/yyyy',
        time_format: 'Ignore Time',
      };
      this.column.format = this.column.format || this.column.predictedFormat;
    },
  },
};
</script>

<style lang="scss">
#import-toolbox .map-sub-row p {
  margin-top: .5em;
  margin-bottom: .25em;
}

#map-create div.map-connection {
    border-left: 1px solid #dfe2e8;
    margin-top: 0;
    padding-top: .75em;
    margin-left: 5px;
    margin-bottom: 1em;
    padding-left: 1em;
    font-size: .975em;

  > div {
    margin-bottom: 1em;
  }

  label {
    line-height: 1.35em;
    margin-top: .15em;
    padding-right: .25em;
    margin-right: .35em;
  }
}
</style>
