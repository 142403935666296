<template>
  <div>
    <div
      v-if="showSourceOptions || showFormOptions"
      id="select-source-options"
    >
      <div v-if="showSourceOptions">
        <div style="padding-top: .5em; border-bottom: 1px rgba(0, 0, 0, .075) solid; margin-bottom: .25em; padding-bottom: .25em;">
          Show options that:
        </div>
        <label v-for="option in userSourceOptions">
          <input
            v-model="userSourceType"
            type="radio"
            :value="option.value"
          >
          <Icon :type="option.icon" /><span v-html="option.label" />
        </label>
      </div>

      <div v-if="showFormOptions">
        <span v-if="showSourceOptions">
          to
        </span>
        <span v-else>
          Options for
        </span>
        <Dropdown
          v-model="formQuantity"
          :options="formOptions"
          class="is-text"
        />
      </div>
    </div>

    <div
      v-if="sourcesAreEmpty"
      id="select-source-empty"
    >
      <p><span v-html="selectedRoleName" /> no options to {{ viewActionSummary }}.</p>
      <p>Please try another option.</p>
    </div>

    <template v-else>
      <div
        id="select-source-objects"
        :class="{'source-links': true, 'hide-toggles': !showToggles}"
      >
        <div
          v-for="(source, key, index) in sources"
          :key="key"
          class="source-link"
          data-cy="data-source-option"
        >
          <Toggle :is-open="(index === 0 || Object.keys(sources).length < 5)">
            <template #title>
              <div class="source-link-title">
                <Icon
                  :type="`object`"
                  style="width: 18px; height: 18px; margin-right: 4px;"
                />
                {{ source.name }}
                <span
                  v-if="source.optionCount > 1"
                  style="font-size: .85em; opacity: .75;"
                >&nbsp;&nbsp;({{ source.optionCount }})</span>
              </div>
            </template>
            <template #content>
              <template v-if="source.options.length === 1">
                <!--<p>Use the following <b>{{ source.name }}</b> records:</p>-->
                <div v-html="source.options[0].label" />
                <a class="button">Select</a>
              </template>

              <div
                v-else
                class="source-object-options"
                style="margin-top: .5em;"
              >
                <a
                  v-for="(option, optionKey, optionIndex) in source.options"
                  :key="`${option.objectKey}-${optionIndex}`"
                  class="source-link"
                  data-cy="data-source-option"
                >
                  <div class="source-link-content">
                    <div class="link-label-wrapper">
                      <Icon
                        type="database-lookup"
                        style="width: 16px; height: 16px; margin-right: 4px; flex-shrink: 0;"
                      />
                      <div
                        class="link-label"
                        style="display: inline;"
                        v-html="option.label"
                      />
                    </div>
                  </div>

                  <div
                    v-if="option.connections.direct.length > 1 || option.connections.parent.length > 1"
                    class="source-connections"
                  >
                    <div v-if="option.connections.direct.length > 1">

                      <p>How will <span v-html="option.labelParts.from" /> connect to <span v-html="option.labelParts.to" />?</p>

                      <label v-for="connection in option.connections.direct">
                        <input
                          v-model="directConnectionFieldKey"
                          type="radio"
                          :value="connection.field.key"
                        >
                        <Icon type="object" />{{ connection.field.names.object }} <span>&gt;</span><Icon
                          type="database-lookup"
                          style="margin-left: 2px;"
                        />{{ connection.field.names.field }}
                      </label><br>
                    </div>
                    <div v-if="option.connections.parent.length > 1">

                      <p>How will <span v-html="option.labelParts.to" /> connect to <span v-html="option.labelParts.parent" />?</p>

                      <label v-for="connection in option.connections.parent">
                        <input
                          v-model="parentConnectionFieldKey"
                          type="radio"
                          :value="connection.field.key"
                        >
                        <Icon type="object" />{{ connection.field.names.object }} <span>&gt;</span><Icon
                          type="database-lookup"
                          style="margin-left: 2px;"
                        />{{ connection.field.names.field }}
                      </label><br>
                    </div>

                    <a class="button small green-fill">Select</a>
                  </div>
                </a>
              </div>
            </template>
          </Toggle>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import Icon from '@/components/ui/Icon';
import Toggle from '@/components/ui/Toggle';
import Dropdown from '@/components/ui/Dropdown';

export default {
  components: {
    Icon,
    Toggle,
    Dropdown,
  },
  props: {
    page: {
      type: Object,
      default() {
        return {
          source: {},
        };
      },
    },
    recordsQuantity: {
      type: String,
      default: 'all', // one | many | all
    },

    // add-view needs to account for forms: they can sometimes either insert or update records
    viewType: {
      type: String,
      default: null,
    },
  },
  emits: [
    'submit',
  ],
  data() {
    return {
      userSourceType: 'public', // what does this do?
      optionCount: 0,
      objectsWithAddressFields: () => ({}),
      objectsWithDateFields: () => ({}),
      formOptions: [
        {
          value: 'many',
          label: 'add a <b>new</b> record',
        },
        {
          value: 'one',
          label: 'update an <b>existing</b> record',
        },
      ],
      formQuantity: 'many', // relevant when viewType = form (see prop below)
      directConnectionFieldKey: '',
      parentConnectionFieldKey: '',
    };
  },
  computed: {
    ...mapGetters([
      'standardObjects',
      'getObjectByRole',
      'userObjects',
      'getObject',
      'isUsersEnabled',
    ]),

    sourcesAreEmpty() {
      return isEmpty(this.sources);
    },

    showFormOptions() {
      if (this.viewType !== 'form') {
        return false;
      }

      const hasMany = this.options.find((option) => option.quantity === 'many');
      const hasOne = this.options.find((option) => option.quantity === 'one');

      if (hasMany && hasOne) {
        return true;
      }

      this.formQuantity = (hasMany) ? 'many' : 'one';

      return false;
    },

    showSourceOptions() {
      return this.page.authenticated && this.userSourceOptions.length > 0;
    },

    showToggles() {
      if (this.showAllObjects) {
        return false;
      }

      /*
        // show toggles if lots of "further connected"
        const furtherCount = Object.keys(this.sources).filter((key) => {

          return this.sources[key].options.find((option) => {
            return option.parentObjectKey
          })
        })

        log(`showToggles(), furtherCount`, furtherCount)

        if (furtherCount.length > 2) {

          return true
        }
        */

      // show toggles if any object has multiple options
      return Object.keys(this.sources).find((key) => this.sources[key].options.length > 1);
    },

    showAllObjects() {
      return this.userSourceType === 'public' && !this.page.object && this.viewType !== 'checkout';
    },

    options() {
      return this.page.sourceOptions;
    },

    sources() {
      const sources = {};
      const { options } = this;

      // track these to save on some processing in addOption
      this.objectsWithAddressFields = {};
      this.objectsWithDateFields = {};

      this.optionCount = 0;

      log('! ! ! sources()!', this.options);

      if (this.showAllObjects) {
        options.forEach((option) => {
          if (option.type === 'all') {
            this.addOption(sources, option);
          }
        });

        return sources;
      }

      // details page for a specific object. In this case all options are available
      if (this.userSourceType === 'public') {
        options.forEach((option) => {
          this.addOption(sources, option);
        });

        return sources;
      }

      // user page for a specific role
      options.forEach((option) => {
        if (option.type === 'user' && option.roleKey && option.roleKey === this.userSourceType) {
          this.addOption(sources, option);
        }
      });

      log('!!! sources: ', sources);

      return sources;
    },

    // Used to populate the options of available user roles to pick from
    userSourceOptions() {
      const options = [];

      // we only want to show these if the page has no object
      if (this.page.object) {
        return options;
      }

      options.push({
        label: '<b>any user</b> can access',
        value: 'public',
        icon: 'user',
      });

      // initially filter out all_users to address schema bug where they may live in the login property
      this.page.authentication_profiles.filter((profile_key) => profile_key !== 'all_users').concat('all_users').forEach((profileKey) => {
        const obj = this.getObjectByRole(profileKey);

        if (!obj) {
          return;
        }

        options.push({
          label: `only <b>${obj.inflections.plural}</b> can access`,
          value: obj.key,
          icon: 'lock',
        });
      });

      if (options.length > 1) {
        this.userSourceType = options[1].value;
      }

      return options;
    },

    showUserSource() {
      return this.userSourceType !== 'public';
    },

    profileObjects() {
      return this.$store.getters.userObjects.filter((obj) => {
        const hasProfileAccess = (obj.profile_key && this.page.allowed_profiles && this.page.allowed_profiles.length && this.page.allowed_profiles.indexOf(obj.profile_key) > -1);

        return obj.profile_key === 'all_users' || hasProfileAccess;
      });
    },
    pageObject() {
      return this.getObject(this.page.object);
    },

    selectedRoleName() {
      if (this.userSourceType === 'public') {
        return 'Users that aren\'t logged-in have';
      }

      const obj = this.$store.getters.getObject(this.userSourceType);

      return `The <b>logged-in ${obj.inflections.singular}</b> has`;
    },
    viewActionSummary() {
      if (this.viewType === 'form') {
        return (this.formQuantity === 'many') ? 'add a new record with a form' : 'update a record with a form';
      }

      let type = this.viewType;

      if (this.viewType === 'details') {
        type = 'details view';
      }

      return `display records on this page with a ${type}`;
    },
  },
  methods: {
    onClickSourceTitle($event, source, key, index) {
      log('onClickSourceTitle', source, key, index);

      source.open = true;
      source.name = 'name';

      this.sources[key].name = 'test';
      this.sources[key].open = true;
    },
    onSubmit(source) {
      this.$emit('submit', source);
    },
    // index options by object
    addOption(sources, option) {
      // log(`trying option`, option)

      // if we need a specific records quantity, ignore non-matches
      if (this.viewType !== 'form' && this.recordsQuantity !== 'all' && option.quantity !== this.recordsQuantity) {
        return;
      }

      // if this is for a form, match with the form quantity (create = many, update = one)
      if (this.viewType === 'form' && option.quantity !== this.formQuantity) {
        return;
      }

      if ((this.viewType === 'checkout' || this.viewType === 'charge') && option.source.authenticated_user && (!option.source.connection_key || option.source.relationship_type !== 'local')) {
        return;
      }

      if (this.viewType === 'form') {
        const obj = this.$store.getters.getObject(option.objectKey);

        if (obj.ecommerce || obj.ecommercePaymentMethods) {
          return;
        }
      }

      // calendars can only show objects that have date fields
      if (this.viewType === 'calendar') {
        if (_.isNil(this.objectsWithDateFields[option.objectKey])) {
          this.objectsWithDateFields[option.objectKey] = this.$store.getters.getObject(option.objectKey).hasDateField();
        }

        if (!this.objectsWithDateFields[option.objectKey]) {
          return;
        }
      }

      // maps can only show objects that have address fields
      if (this.viewType === 'map') {
        if (_.isNil(this.objectsWithAddressFields[option.objectKey])) {
          this.objectsWithAddressFields[option.objectKey] = this.$store.getters.getObject(option.objectKey).hasGeocodedAddressField();
        }

        if (!this.objectsWithAddressFields[option.objectKey]) {
          return;
        }
      }

      this.optionCount++;

      if (!sources[option.objectKey]) {
        sources[option.objectKey] = {
          options: {},
          optionCount: 0,
          name: this.getObject(option.objectKey).name,
        };
      }

      // generate option key
      // if to sources have the same key we need to disambiguate by revealing the connection
      let optionKey = option.objectKey;

      if (option.connections && option.connections.direct) {
        optionKey += `:${option.connections.direct.object.key}`;

        if (option.connections.parent) {
          optionKey += `:${option.connections.parent.object.key}`;
        }
      }

      log('Adding optionKey', optionKey);

      if (!sources[option.objectKey].options[optionKey]) {
        sources[option.objectKey].optionCount++;

        sources[option.objectKey].options[optionKey] = {
          label: `Use ${option.label}`,
          labelParts: option.labelParts,
          count: 0,
          connections: {
            direct: [],
            parent: [],
          },
        };
      }

      if (!option.connections) {
        return;
      }

      if (!sources[option.objectKey].options[optionKey].connections.direct.find((conn) => conn.field.key === option.connections.direct.field.key)) {
        sources[option.objectKey].options[optionKey].connections.direct.push(option.connections.direct);
      }

      if (option.connections.parent && !sources[option.objectKey].options[optionKey].connections.parent.find((conn) => conn.field.key === option.connections.parent.field.key)) {
        sources[option.objectKey].options[optionKey].connections.parent.push(option.connections.parent);
      }
    },
  },
};
</script>

<style lang="scss">
.source-connections {

  label {
    display: block;

    .icon {
      flex-shrink: 0;
      margin-right: 2px;
      position: relative;
      top: 3px;

      opacity: .9;

      height: 15px;
      width: 15px;
    }

    span {
      display: inline-block;
      margin: 0 6px;

      opacity: .8;

      font-size: .9em;
    }
  }

  p {
    margin: .5em 0;
  }
}

#select-source-objects {

  .source-link-title {
    display: flex;

    .source-link-label {
      flex-grow: 1;
      display: flex;
      font-weight: 500;
    }
  }

  .source-link-confirm {
    margin-top: 10px;
  }

  .source-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: inherit;
    font-size: .95em;
    line-height: 1.2em;
    // border-top: 1px solid #d9dce6;
    padding: .65em .6em .55em;
    border-radius: .35em;
    margin-bottom: .5em;
    background-color: rgba(0, 0, 0, .035);

    .toggle-trigger {
      margin: 0;
      border: 0;
      padding: 0;
      color: inherit;
    }

    .toggle-wrapper {
      margin: 0;
    }

    .link-label-wrapper {
      display: flex;
      align-items: flex-start;
    }

    .link-label b.source-label {
      font-weight: 500;
      // display: none;

      svg {
        opacity: .55;
      }
    }

    .icon-arrow-forward {
      height: 18px;
      width: 18px;
      opacity: 0.5;
      flex-shrink: 0;
    }

    &:hover {

      svg.icon-arrow-forward {
        color: $fuchsia;
        opacity: 1;
      }
    }
  }
}

#select-source-empty {
  background-color: #fff;
  padding: 0.75em;
  border-radius: 0.33em;

  p:last-child {
    margin: 0;
  }
}

#select-source-options {
  // border-bottom: 1px solid #d9dce6;
  margin-bottom: 1em;
  font-size: .95em;
  line-height: 1.15em;

  > div {

    label {
      display: block;

      svg {
        width: 16px;
        height: 16px;
        opacity: 0.8;
        margin-right: 3px;
        top: 2px;
        position: relative;
      }
    }

    &:first-child {
      margin-bottom: .45em;
    }
  }
}

#select-objects {

  // overwrite toggle
  h3 {
    margin: 0;
  }

  .toggle-content > *:last-child {
    padding-bottom: 1em;
  }

  > div {
    cursor: pointer;

    .toggle-trigger .toggle-title {
      text-transform: none!important;
      font-size: 1em;
    }

    .toggle-wrapper {
      margin-bottom: .5em;
    }

    .link-label-wrapper {
      display: flex;
      align-items: flex-start;
    }

    .source-link {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      color: inherit;
      font-size: .95em;
      line-height: 1.2em;
      // border-top: 1px solid #d9dce6;
      padding: .65em .6em .55em;
      border-radius: .35em;
      margin-bottom: .5em;
      background-color: rgba(0, 0, 0, .035);

      .source-link-content svg {
        opacity: .55;
      }

      .link-label {
        display: flex;
        width: 100%;
      }

      .link-label b.source-label {
        font-weight: 500;
        // display: none;

        svg {
          opacity: .55;
        }
      }

      .icon-arrow-forward {
        height: 18px;
        width: 18px;
        opacity: 0.5;
        flex-shrink: 0;
      }

      &:hover {
        background-color: rgba(0, 0, 0, .055);

        svg.icon-arrow-forward {
          color: $fuchsia;
          opacity: 1;
        }
      }
    }

    .link-conns {
      opacity: .6;
      margin-top: .4em;
      font-size: .925em;
      line-height: 1.1em;

      svg {
        width: 12px;
        height: 12px;
      }

      .icon-connection {
        margin-right: 5px;
      }

      .icon-chevron-single {
        margin: 0;
        width: 13px;
        height: 15px;
      }
    }
  }

  &.hide-toggles {
    .toggle-trigger {
      display: none;
    }

    .toggle-content {
      visibility: visible !important;
      max-height: none !important;
    }

    .link-label .source-label {
      display: inline !important;
    }

    .toggle-wrapper {
      margin: 0;
    }
  }

  .source-connections {
    font-size: .86em;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    opacity: .95;
    padding-left: 18px;

    > div {
      margin-top: 4px;
    }

    svg {
      width: 14px; height: 14px;
      position: relative;
      top: 3px;
      color: $fuchsia;
      opacity: .8 !important;
    }
  }
}
</style>
