import isNil from 'lodash/isNil';
import store from '@/store/index';
import { Joi } from '@/lib/joi';

class Task {
  constructor(task) {
    if (!isNil(task)) {
      this.attributes = JSON.parse(JSON.stringify(task));
    }
  }

  raw() {
    return this.attributes;
  }

  get key() {
    return this.attributes.key;
  }

  get name() {
    return this.attributes.name;
  }

  set name(newValue) {
    this.attributes.name = newValue;
  }

  get type() {
    return this.attributes.type;
  }

  set type(newValue) {
    this.attributes.type = newValue;
  }

  get schedule() {
    return this.attributes.schedule;
  }

  set schedule(newValue) {
    this.attributes.schedule = newValue;
  }

  get runStatus() {
    return this.attributes.run_status;
  }

  set runStatus(newValue) {
    this.attributes.run_status = newValue;
  }

  get action() {
    return this.attributes.action;
  }

  set action(newValue) {
    this.attributes.action = newValue;
  }

  validate(attributes) {
    if (isNil(attributes)) {
      attributes = this.attributes;
    }

    // emails don't necessarily need a field
    const field = attributes.action.action === 'email' ? Joi.allow(null).optional()
      : Joi.string();

    const actionValuesValidationSchema = Joi.object().keys({
      connection_field: Joi.string().empty('').allow(null).optional(),
      field,
      input: Joi.string().empty('').optional(),
      type: Joi.string(),
      // Used for "add to" and "remove from"
      action: Joi.string().empty('').optional(),
      values: [
        Joi.string().empty('').optional(),
        Joi.boolean().optional(),
        Joi.object().optional(),
        Joi.array().sparse().items(
          Joi.boolean().optional(),
          Joi.string().empty('').optional(),
          // Unsure if this is correct but tasks that update complex dates pass
          // values as objects
          Joi.object().optional(),
          // Allow connection values, which are passed as an object in an array
          Joi.array().items(Joi.object().optional()).optional(),
          Joi.array().items(Joi.string().empty('').optional()).optional(),
        ).optional(),
      ],
    });

    const actionValidationSchema = Joi.object().keys({
      action: Joi.string(),
      criteria: Joi.array(),
      email: [
        Joi.array(), // default value is an empty array
        Joi.object(), // when populated it's an object
      ],
      values: Joi.array().items(actionValuesValidationSchema),
    }).required();

    const validationSchema = {
      key: Joi.string().empty('').optional(),
      action: actionValidationSchema,
      name: Joi.string().empty('').required(),
      object_key: Joi.string().empty('').required(),
      run_status: Joi.string().empty('').required(),
      schedule: Joi.object({
        date: Joi.string().notBeforeToday(),
      }).required(),
      scheduled: Joi.boolean().optional(),
      type: Joi.string().empty('').required(),
    };

    const validationOptions = {
      abortEarly: false,
      allowUnknown: true,
    };

    const validationResult = Joi.validate(attributes, validationSchema, validationOptions);

    if (validationResult.error) {
      return {
        error: validationResult.error,
        errorMap: {
          'error.name.any.required': 'Give your Task a name.',
          'error.action_values_0_value.any.required': 'Enter a custom value.',
          'error.schedule_date.string.notBeforeToday': 'Run date cannot be before today.',
        },
      };
    }
  }

  async create() {
    const response = await window.Knack.Api.createTask(this.attributes.object_key, this.attributes);

    store.commit('createTask', response);

    return response;
  }

  async update() {
    const response = await window.Knack.Api.updateTask(this.attributes.object_key, this.attributes.key, this.attributes);

    store.commit('updateTask', response);

    return response;
  }

  async delete() {
    const response = await window.Knack.Api.deleteTask(this.attributes.object_key, this.attributes.key);

    store.commit('deleteTask', this.attributes.key);

    return response;
  }
}

export default Task;
