<template>
  <Modal
    title="Record History"
    :back="backLink"
    size="full-screen"
    prevent-background-click-to-close
  >
    <Modal
      v-if="showVersionModal"
      title="Version Detail"
      :z-index="2"
      @close="onCloseVersionModal"
    >
      <FormWrapper :show-submit="false">
        <form>
          <div>
            <label>Version ID</label>
            <input
              :value="version.id"
              type="text"
              disabled
            >
          </div>

          <div>
            <label>Transaction ID</label>
            <input
              :value="version.transaction_id"
              type="text"
              disabled
            >
          </div>

          <div>
            <label>UTC</label>
            <input
              :value="version.timestamp"
              type="text"
              disabled
            >
          </div>

          <div>
            <label>Source</label>
            <input
              :value="version.source.indexOf('<') > -1 ? version.source.split('>')[1].split('<')[0] : version.source"
              type="text"
              disabled
            >
            <p
              v-if="sourceDescription"
              class="kn-instructions"
            >
              {{ sourceDescription }}
            </p>
          </div>

          <div>
            <label>Action</label>
            <input
              :value="version.action.split('>')[1].split('<')[0]"
              type="text"
              disabled
            >
          </div>

          <div v-if="version.job_id">
            <label>Job ID</label>
            <input
              :value="version.job_id"
              type="text"
              disabled
            >
          </div>

          <div v-if="version.user">
            <label>Updated By</label>
            <input
              :value="version.user"
              type="text"
              disabled
            >
          </div>
        </form>
      </FormWrapper>
    </Modal>

    <div
      id="records-history"
      ref="recordsHistoryTableWrapper"
    >
      <TableWrapper>
        <template #navigation>
          <RecordsTableNavigation
            :object-key="objectKey"
            :allow-limit="false"
            :current-records-page="currentPage"
            :records-per-page="25"
            :total-records="totalRecords"
            :total-records-pages="totalPages"
            :current-records-count="records.length"
            :show-filters="false"
            :show-keyword-search="false"
            :show-export-button="false"
            class="px-6 py-4 mb-0"
            @update="onTableUpdate"
          />
        </template>
        <template #table>
          <VTable
            :columns="columns"
            :records="records"
            :show-inline-edit="false"
            :allow-sorting="false"
            :should-highlight-cell="highlightRecordChanges"
            :draggable="false"
          />
        </template>
      </TableWrapper>
    </div>
  </Modal>
</template>

<script>
import get from 'lodash/get';
import FormWrapper from '@/components/ui/FormWrapper';
import Modal from '@/components/ui/Modal';
import RequestUtils from '@/components/util/RequestUtils';
import RecordsUtils from '@/components/records/RecordsUtils';
import TableWrapper from '@/components/renderer/table/TableWrapper';
import RecordsTableNavigation from '@/components/records/RecordsTableNavigation';
import VTable from '@/components/renderer/table/Table';
import SchemaUtils from '@/store/utils/SchemaUtils';
import Field from '@/store/models/Field';

export default {
  name: 'RecordHistory',
  components: {
    FormWrapper,
    Modal,
    TableWrapper,
    RecordsTableNavigation,
    VTable,
  },
  mixins: [
    RequestUtils,
    RecordsUtils,
  ],

  // set the backlink to the previous route
  data: () => ({
    records: [],
    totalRecords: 1,
    totalPages: 1,
    currentPage: 1,
    version: {},
    showVersionModal: false,
  }),
  computed: {
    historyColumns() {
      const object = {
        key: this.object.key,
      };

      const historyColumnsDefaults = [
        {
          type: 'field',
          field: new Field({
            key: 'date', type: 'short_text',
          }, object),
          header: 'Date Updated',
          sortable: false,
        },
        {
          type: 'field',
          field: new Field({
            key: 'source', type: 'link',
          }, object),
          header: 'Source',
          sortable: false,
        },
        {
          type: 'field',
          field: new Field({
            key: 'action', type: 'short_text',
          }, object),
          header: 'Action',
          sortable: false,
        },
        {
          type: 'field',
          field: new Field({
            key: 'user', type: 'short_text',
          }, object),
          header: 'Updated By',
          sortable: false,
        },
        {
          type: 'field',
          field: new Field({
            key: 'ip', type: 'short_text',
          }, object),
          header: 'IP Address',
          sortable: false,
        },
      ];

      return historyColumnsDefaults.map((column) => ({ ...SchemaUtils.columnDefaults(), ...column }));
    },
    objectColumns() {
      return this.object.getFieldColumns();
    },
    columns() {
      return this.historyColumns.concat(this.objectColumns);
    },
    object() {
      return this.$store.getters.activeObject;
    },
    sourceDescription() {
      return this.version.source.toLowerCase() === 'api' && this.version.origin ? `${this.version.origin.method}: ${this.version.origin.url}` : '';
    },
  },
  async mounted() {
    await this.getRecordData();
  },
  methods: {
    onClickVersionAction(event) {
      const versionId = event.currentTarget.getAttribute('version-id');

      this.version = this.records.filter((record) => record.id === versionId)[0];
      this.showVersionModal = true;
    },
    onTableUpdate({ currentPage }) {
      this.currentPage = currentPage;

      this.getRecordData();
    },
    getRecordData() {
      this.commitRequest({
        request: () => {
          if (this.app.attributes.settings.sql && this.app.attributes.settings.hasSabu !== true) {
            return window.Knack.Api.getSQLRecordHistory(this.objectKey, this.recordId, this.currentPage);
          }
          // Old Babu endpoint
          return window.Knack.Api.getRecordHistory(this.objectKey, this.recordId, this.currentPage);
        },
        onSuccess: ({ records, total_records: responseTotalRecords, current_page: responseCurrentPage }) => {
          this.records = records || [];
          this.totalRecords = responseTotalRecords;
          this.currentPage = responseCurrentPage;

          // server sends back incorrect info so calculating this instead (assuming pagination of 25)
          this.totalPages = Math.ceil(responseTotalRecords / 25);

          // setup listeners for Version Detail modals
          this.setupVersionModalListeners();
        },
      });
    },
    onCloseVersionModal() {
      this.showVersionModal = false;
    },
    async setupVersionModalListeners() {
      await this.$nextTick();

      const { recordsHistoryTableWrapper } = this.$refs;

      if (recordsHistoryTableWrapper) {
        const versionModals = this.$refs.recordsHistoryTableWrapper.querySelectorAll('.version-modal');
        for (const versionModal of versionModals) {
          versionModal.addEventListener('click', this.onClickVersionAction);
        }
      }
    },
    highlightRecordChanges(table, currentRecordIndex, columnIndex) {
      if (!table) {
        return false;
      }

      const allRecords = table.records;
      const fieldKey = get(table, `columnsWithFields[${columnIndex}].field.attributes.key`);

      if (!fieldKey) {
        return false;
      }

      const maxIndex = allRecords.length - 1;

      if (currentRecordIndex === maxIndex) {
        return false;
      }

      const currentRecord = allRecords[currentRecordIndex];
      const previousRecord = allRecords[currentRecordIndex + 1];

      return (previousRecord[fieldKey] !== currentRecord[fieldKey]);
    },
  },
};
</script>
