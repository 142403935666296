<template>
  <view-toolbox
    back-title="Edit Calendar"
    title="Source"
  >
    <div>
      <Source :show-sort="false" />
    </div>
  </view-toolbox>
</template>
<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import Source from '@/components/views/shared/Source';

export default {
  components: {
    ViewToolbox,
    Source,
  },
};
</script>
