<template>
  <div>
    <RouterView
      v-slot="{ Component }"
      name="toolbox"
    >
      <Transition :name="transitionName">
        <component :is="Component" />
      </Transition>
    </RouterView>
  </div>
</template>

<script>
export default {
  name: 'ToolboxRouter',
  data() {
    return {
      transitionName: 'slide-left',
    };
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;

      // more pronounced transition when first activating a view
      if (from.name === 'page' && to.path.indexOf('/views/') > -1) {
        this.transitionName = 'replace';
      } else {
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }
    },
  },
};
</script>
