<template>
  <BodyWrapper>
    <div
      id="user-settings"
      class="body-wrapper m-0 mb-10 px-6 py-2"
    >
      <form
        v-if="!app.users.enabled"
        class="max-w-xl"
      >
        <p class="text-default text-base mb-2">
          Users currently are <strong>not active</strong>. Click below to activate.
        </p>
        <p class="text-sm text-default mb-0">
          Questions? <a
            class="text-default underline"
            href="https://learn.knack.com/article/o7zqrvduj7-about-users"
            target="_blank"
          >View a summary</a> of user features.
        </p>
        <a
          class="button medium save mt-4 bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium"
          data-cy="activate-users-button"
          @click="onActivateUsers"
        >
          Activate Users
        </a>
      </form>

      <div v-else>
        <form class="horizontal-labels margin-bottom-double max-w-xl mb-0">
          <p class="text-default text-base mb-4">
            Users currently <strong>are active</strong>. This means you can require logins to view your
            pages and connect records to specific user accounts.
          </p>
          <a
            v-close-popper
            class="button small delete bg-white rounded-lg
              border border-destructive p-3 text-base text-destructive leading-4 font-medium"
            @click="toggleRemoveModal"
          >
            Remove User Roles From My App
          </a>
        </form>

        <ConfirmModal
          v-if="showRemoveModal"
          title="Removing users can have destructive consequences"
          confirm-type="alert"
          confirm-text="Remove"
          confirm-button-test-id="remove-users-button"
          :confirm-button-disabled="!hasAcknowledgedUserDeletionWarning"
          @close="toggleRemoveModal"
          @confirm="onRemoveUsers"
        >
          <p class="mb-6">
            If you proceed, any user login requirements will be deleted. Anyone can access the Live App without a login.
          </p>
          <p class="mb-6">
            All <strong>user tables</strong> and <strong>user records</strong> will be deleted and cannot be recovered.
          </p>
          <p class="mb-6">
            Significant time may be required to rebuild or repair this app. This action cannot be reversed.
          </p>

          <div class="user-deletion-acknowledgement">
            <label>
              <input
                v-model="hasAcknowledgedUserDeletionWarning"
                type="checkbox"
              >
              <span class="text-emphasis text-base font-normal not-italic">
                I understand that this may result in permanent breaking changes to this app.
              </span>
            </label>
          </div>
        </ConfirmModal>

        <hr
          class="my-8 border-0 border-t border-solid border-subtle"
        >

        <Toggle toggle-trigger-classes="p-0">
          <template #title>
            Login Settings
          </template>
          <template #content>
            <div class="mt-2">
              <form class="horizontal-labels">
                <p class="text-xs text-default mb-6">
                  Login settings are foundational to your app's structure. Read more about
                  <a
                    class="underline text-default"
                    href="https://learn.knack.com/article/o7zqrvduj7-about-users#activate-users"
                    target="_blank"
                  >managing login settings here</a>
                  before making a change.
                </p>
                <div class="is-vertical mb-0">
                  <div class="input-labels">
                    <label
                      data-cy="users-individual-logins"
                      class="flex items-baseline mb-3"
                    >
                      <input
                        v-model="app.users.scope"
                        class="m-0 mr-2"
                        type="radio"
                        value="scene"
                        name="scope"
                      >
                      <div class="margin-bottom mb-0 text-emphasis text-base font-normal">
                        <span class="block text-base">Secure individual pages with a login</span><em
                          v-if="app.users.scope === 'scene'"
                          class="block"
                        >
                          <p class="instructions m-0 mt-1 radio not-italic text-subtle text-xs mb-0">
                            Preferred. Each page can have different access rights and settings
                          </p>
                        </em>
                      </div>
                    </label>
                    <label
                      data-cy="users-single-login"
                      class="flex items-baseline mb-3"
                    >
                      <input
                        v-model="app.users.scope"
                        class="m-0 mr-2"
                        type="radio"
                        value="application"
                        name="scope"
                      >
                      <div class="margin-bottom mb-0 text-emphasis text-base font-normal">
                        <span class="block text-base">Secure every page with the same login</span>
                        <p
                          v-if="app.users.scope === 'application'"
                          class="instructions m-0 mt-1 radio not-italic text-subtle text-xs mb-0"
                        >
                          Every page will have the same access rights and settings. Less flexible
                        </p>
                      </div>
                    </label>
                    <label
                      data-cy="users-no-logins"
                      class="flex items-baseline m-0"
                    >
                      <input
                        v-model="app.users.scope"
                        class="m-0 mr-2"
                        type="radio"
                        value="none"
                        name="scope"
                      >
                      <div class="text-emphasis text-base font-normal">
                        <span class="block text-base">Do not require a login to access your application</span>
                        <p
                          v-if="app.users.scope === 'none'"
                          class="instructions radio m-0 mt-1 not-italic text-subtle text-xs mb-0"
                        >
                          Anyone can access your app with the URL
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </template>
        </Toggle>

        <hr
          class="my-8 border-0 border-t border-solid border-subtle"
        >

        <Toggle toggle-trigger-classes="p-0">
          <template #title>
            Security Settings
          </template>
          <template #content>
            <div class="mt-6">
              <form>
                <div class="mb-6">
                  <label class="text-default mb-2">Inactivity</label>
                  <div class="is-vertical">
                    <div class="input-labels">
                      <label class="text-emphasis text-base font-normal items-baseline mb-2">
                        <input
                          v-model="app.settings.inactivity_timeout_enabled"
                          class="m-0 mr-2"
                          type="checkbox"
                        >
                        Automatic inactivity log out
                      </label>
                      <p class="instructions margin-none not-italic text-default text-xs m-0">
                        This setting applies to users in the Builder and the Live App
                      </p>
                    </div>
                    <template v-if="app.settings.inactivity_timeout_enabled">
                      <div class="margin-top my-3">
                        <label class="text-default text-sm">Logout after</label>
                        <div class="level-left margin-bottom m-0">
                          <select
                            v-model="app.settings.inactivity_timeout"
                            style="max-width: max-content;"
                          >
                            <option value="1">
                              1 minute of inactivity
                            </option>
                            <option value="5">
                              5 minutes of inactivity
                            </option>
                            <option value="10">
                              10 minutes of inactivity
                            </option>
                            <option value="15">
                              15 minutes of inactivity
                            </option>
                            <option value="30">
                              30 minutes of inactivity
                            </option>
                            <option value="60">
                              60 minutes of inactivity
                            </option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <label class="text-default text-sm">Inactivity Message</label>
                        <textarea v-model="app.settings.inactivity_message" />
                      </div>
                    </template>
                  </div>
                </div>

                <div class="mb-6">
                  <label class="text-default mb-2">Passwords must include</label>
                  <div class="is-vertical">
                    <div class="input-labels">
                      <label class="text-emphasis text-base font-normal items-baseline m-0 mb-2"><input
                        v-model="app.settings.password_options.password_minimum_character"
                        class="m-0 mr-2"
                        type="checkbox"
                      >A minimum of 8 characters</label>
                      <label class="text-emphasis text-base font-normal items-baseline m-0 mb-2"><input
                        v-model="app.settings.password_options.password_require_no_common"
                        class="m-0 mr-2"
                        type="checkbox"
                      >No common words</label>
                      <label class="text-emphasis text-base font-normal items-baseline m-0 mb-2"><input
                        v-model="app.settings.password_options.password_require_number"
                        class="m-0 mr-2"
                        type="checkbox"
                      >At least 1 number</label>
                      <label class="text-emphasis text-base font-normal items-baseline m-0 mb-2"><input
                        v-model="app.settings.password_options.password_special_character"
                        class="m-0 mr-2"
                        type="checkbox"
                      >At least 1 special character</label>
                      <label class="text-emphasis text-base font-normal items-baseline m-0 mb-2"><input
                        v-model="app.settings.password_options.password_require_lowercase"
                        class="m-0 mr-2"
                        type="checkbox"
                      >At least 1 lowercase character</label>
                      <label class="text-emphasis text-base font-normal items-baseline m-0"><input
                        v-model="app.settings.password_options.password_require_uppercase"
                        class="m-0 mr-2"
                        type="checkbox"
                      >At least 1 uppercase character</label>
                    </div>
                  </div>
                </div>

                <div class="mb-6">
                  <label class="text-default mb-2">Password Settings</label>
                  <div class="is-vertical">
                    <div class="input-labels">
                      <label class="text-emphasis text-base font-normal items-baseline m-0"><input
                        v-model="app.settings.password_options.password_require_expiration"
                        class="m-0 mr-2"
                        type="checkbox"
                      >Expire every 60 days</label>
                    </div>
                    <div
                      v-if="app.settings.password_options.password_require_expiration"
                      class="margin-bottom m-0 mt-2"
                    >
                      <label class="text-default text-sm m-0 mb-2">Expiration Message</label>
                      <textarea v-model="app.settings.password_options.password_require_expiration_message" />
                    </div>
                    <div class="input-labels mt-2">
                      <label class="text-emphasis text-base font-normal items-baseline m-0"><input
                        v-model="app.settings.password_options.password_require_no_reuse"
                        class="m-0 mr-2"
                        type="checkbox"
                      >Can't use last 3 passwords</label>
                    </div>
                    <div
                      v-if="app.settings.password_options.password_require_no_reuse"
                      class="mt-2"
                    >
                      <label class="text-default text-sm m-0 mb-2">
                        Warning when one of last three passwords is used
                      </label>
                      <textarea v-model="app.settings.password_options.password_require_no_reuse_message" />
                    </div>
                  </div>
                </div>

                <div class="mb-6">
                  <label class="text-default mb-2">Failed Login Settings</label>
                  <div class="is-vertical">
                    <div class="input-labels">
                      <label class="text-emphasis text-base font-normal items-baseline mb-2"><input
                        v-model="app.settings.lockout_options.lockout_enforced"
                        class="m-0 mr-2"
                        type="checkbox"
                      >Lock out users after too many failed logins</label>
                      <p class="instructions margin-none not-italic text-default text-xs m-0">
                        This can prevent "guessing"
                      </p>
                    </div>
                    <template v-if="app.settings.lockout_options.lockout_enforced">
                      <div class="margin-top selects-maxContentWidth my-3">
                        <p class="m-0">
                          Lockout after:
                          <select v-model="app.settings.lockout_options.lockout_failed_attempts">
                            <option value="3">
                              3 failed logins
                            </option>
                            <option value="5">
                              5 failed logins
                            </option>
                            <option value="10">
                              10 failed logins
                            </option>
                          </select>
                          Within a:
                          <select v-model="app.settings.lockout_options.lockout_attempt_window">
                            <option value="1">
                              1 minute time period
                            </option>
                            <option value="5">
                              5 minute time period
                            </option>
                            <option value="15">
                              15 minute time period
                            </option>
                            <option value="60">
                              60 minute time period
                            </option>
                          </select>
                          Users are locked out for:
                          <select v-model="app.settings.lockout_options.lockout_length">
                            <option value="5">
                              5 minutes
                            </option>
                            <option value="15">
                              15 minutes
                            </option>
                            <option value="60">
                              60 minutes
                            </option>
                            <option value="1440">
                              1 day
                            </option>
                            <option value="forever">
                              Forever
                            </option>
                          </select>
                        </p>
                      </div>
                      <label class="text-default mb-2">Lockout message</label>
                      <textarea
                        v-model="app.settings.lockout_options.lockout_message"
                        class="mb-3"
                      />
                      <label class="margin-top text-default m-0 mb-2">
                        Lockout Email Options
                      </label>
                      <div class="input-labels">
                        <label class="text-emphasis text-base font-normal items-baseline m-0"><input
                          v-model="app.settings.lockout_options.lockout_password_reset"
                          class="m-0 mr-2"
                          type="checkbox"
                        >Allow user to request password reset to unlock account</label>
                        <div
                          v-if="app.settings.lockout_options.lockout_password_reset"
                          class="input-labels mt-2"
                        >
                          <label class="text-default text-sm mb-2">
                            Password Reset Message
                          </label>
                          <textarea v-model="app.settings.lockout_options.password_reset_message" />
                        </div>
                        <label class="margin-top-half text-emphasis text-base font-normal items-baseline m-0 mt-2">
                          <input
                            v-model="app.settings.lockout_options.lockout_user_email"
                            class="m-0 mr-2"
                            type="checkbox"
                          >Send user an email when locked out
                        </label>
                        <div
                          v-if="app.settings.lockout_options.lockout_user_email"
                          class="mt-2"
                        >
                          <label class="text-default text-sm mb-2">Email Message</label>
                          <textarea v-model="app.settings.lockout_options.lockout_user_email_message" />
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <div class="embedded-login-security mb-0">
                  <label class="text-default mb-2">Embedded Login Security</label>
                  <p class="text-xs text-subtle mb-3">
                    Choose how browsers will store information about logged-in users in
                    <a
                      href="https://learn.knack.com/article/jyqtajcj52-embedding-your-app"
                      class="underline text-subtle"
                      target="_blank"
                    >embedded apps.</a>
                    This choice may require trade-offs as browsers
                    continue to restrict how user sessions are stored.
                    <a
                      href="https://learn.knack.com/article/f52gk9tsdp-embedded-login-security-settings"
                      class="underline text-subtle"
                      target="_blank"
                    >See this article for more details.</a>
                  </p>
                  <div class="horizontal-labels is-vertical">
                    <div class="input-labels">
                      <label class="flex items-baseline m-0 mb-3">
                        <input
                          v-model="app.settings.embed_login_method"
                          class="m-0 mr-2"
                          type="radio"
                          value="cookies"
                          name="scope"
                        >
                        <div class="margin-bottom mb-0 text-emphasis text-base font-normal">
                          <span class="block text-base">Cookies</span>
                          <p class="instructions radio m-0 mt-1 radio not-italic text-subtle text-xs">
                            Sets a secure cookie by logging in with a popup,
                            following security best practices
                          </p>
                        </div>
                      </label>
                      <label class="flex items-baseline m-0">
                        <input
                          v-model="app.settings.embed_login_method"
                          class="m-0 mr-2"
                          type="radio"
                          value="refresh"
                          name="scope"
                        >
                        <div class="margin-bottom mb-0 text-emphasis text-base font-normal">
                          <span class="block text-base">Tokens</span>
                          <p class="instructions radio m-0 mt-1 radio not-italic text-subtle text-xs">
                            Uses refresh tokens to act like a normal login, but has some
                            <a
                              href="https://learn.knack.com/article/f52gk9tsdp-embedded-login-security-settings"
                              class="underline text-subtle"
                              target="_"
                            >security vulnerabilities</a>
                            to be aware of
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </template>
        </Toggle>

        <div class="flex">
          <a
            class="button medium save
              bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium ml-auto mt-6"
            data-cy="save-user-settings-button"
            @click="onSaveUserSettings"
          >
            Save Settings
          </a>
        </div>
      </div>
    </div>
  </BodyWrapper>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import BodyWrapper from '@/components/layout/BodyWrapper';
import ConfirmModal from '@/components/ui/ConfirmModal';
import RequestUtils from '@/components/util/RequestUtils';
import Toggle from '@/components/ui/Toggle';
import { logEvent } from '@/lib/segment-helper';

export default {
  components: {
    BodyWrapper,
    ConfirmModal,
    Toggle,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      localAppSchema: {},
      showRemoveModal: false,
      hasAcknowledgedUserDeletionWarning: false,
    };
  },
  computed: {
    app() {
      return this.localAppSchema;
    },
  },
  created() {
    this.setLocalAppSchema();
  },
  methods: {
    setLocalAppSchema() {
      this.localAppSchema = cloneDeep(this.$store.getters.app.raw());
    },
    onActivateUsers() {
      this.commitRequest({
        request: () => this.$store.getters.app.enableUsers(),
        onSuccess: () => {
          this.setLocalAppSchema();
          logEvent('users_activate');
        },
      });
    },
    onRemoveUsers() {
      this.commitRequest({
        request: () => this.$store.getters.app.disableUsers(),
        onSuccess: () => {
          this.toggleRemoveModal();
          this.setLocalAppSchema();
          logEvent('users_remove');
        },
      });
    },
    onSaveUserSettings() {
      this.commitRequest({
        request: () => this.$store.getters.app.update({
          settings: this.localAppSchema.settings,
          users: this.localAppSchema.users,
        }),
        onSuccess: () => {
          this.setLocalAppSchema();
        },
      });
    },
    toggleRemoveModal() {
      this.showRemoveModal = !this.showRemoveModal;
    },
  },
};
</script>

<style lang="scss" scoped>
#user-settings {
  max-width: 600px;

  form {
    max-width: 600px;
    font-size: .875rem;
  }

  h3 {
    font-size: 1.25em;
  }

  .selects-maxContentWidth select {
    max-width: fit-content;
    margin-bottom: 4px;
  }
}

.user-deletion-acknowledgement {
  font-style: italic;

  label {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
  }
  input {
    margin-right: 0.75em !important;
    &:checked ~ span {
      font-weight: bold;
    }
  }
}

.embedded-login-security {
  margin-bottom: 1em;

  p {
    font-weight: 400;
    color: $text;

    &.instructions {
      margin-top: 0;
    }
  }

  .is-vertical {
    margin-bottom: 0;
  }
}
</style>
