import cloneDeep from 'lodash/cloneDeep';
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import store from '@/store';
import View from '@/store/models/View';

/**
 * Adds a new view to the page.
 *
 * @param {RawPage | Page} page
 * @param {object} viewData
 * @param {string} [locationType]
 * @param {number} [groupIndex] - Use -1 to push.
 * @param {number} [columnIndex]
 * @param {number} [itemIndex]
 * @returns {View}
 */
export function addLocalView(page, viewData, locationType = 'group', groupIndex = 0, columnIndex = 0, itemIndex = 0) {
  // Create view class and set
  const newView = new View(viewData, {
    key: page.key,
    sourceOptions: page.sourceOptions,
  });

  // We want to make sure the page groups are lazy loaded before we begin. Otherwise they
  // will be populated after the view is set and addViewToGroup() will cause duplicates.
  page.groups; // eslint-disable-line no-unused-expressions

  // See if this view already exists.
  const existingViewIndex = page.views.findIndex((view) => view.key === newView.key);

  if (existingViewIndex !== -1) {
    const existingView = page.views[existingViewIndex];

    if (existingView.data) {
      newView.setData(existingView.data);
    }

    // If page is reactive, then the view setView() returns will be reactive.
    return page.setView(newView);
  }

  const locationIndex = (groupIndex === -1) ? page.groups.length : groupIndex;

  // Save this location to the view for saving to the server
  newView.setCreateLocation(locationType, locationIndex, columnIndex, itemIndex);

  // add to views and groups for inline preview
  // If page is reactive, then the view setView() returns will be reactive.
  const pageNewView = page.setView(newView);

  addViewToGroup(page, pageNewView.key, locationType, groupIndex, columnIndex, itemIndex);

  return pageNewView;
}

/**
 * Adds a View to the page groups.
 *
 * @param {RawPage} page
 * @param {string} viewKey
 * @param {string} addLocationType
 * @param {number} groupIndex - Use -1 to push.
 * @param {number} columnIndex
 * @param {number} itemIndex
 */
function addViewToGroup(page, viewKey, addLocationType, groupIndex, columnIndex, itemIndex) {
  const newColumn = {
    keys: [
      viewKey,
    ],
    width: 100,
  };

  const { groups } = page;

  if (groupIndex === -1) {
    groups.push({
      columns: [newColumn],
    });
  } else if (isNil(groups[groupIndex])) {
    groups[groupIndex] = {
      columns: [newColumn],
    };
  } else if (addLocationType === 'group') {
    // new group
    groups.splice(groupIndex, 0, {
      columns: [newColumn],
    });
  } else if (addLocationType === 'column') {
    // new column
    groups[groupIndex].columns.splice(columnIndex, 0, newColumn);
  } else {
    // add in existing column
    groups[groupIndex].columns[columnIndex].keys.splice(itemIndex, 0, viewKey);
  }

  page.groups = groups;
}

/**
 * Updates a view on the page.
 *
 * @param {RawPage} page
 * @param {string} viewKey
 * @param {object} viewUpdates
 */
export function updateLocalView(page, viewKey, viewUpdates) {
  const viewData = store.getters.getViewByKey(viewKey);

  if (!viewData) {
    return;
  }

  const newView = new View(viewData, {
    key: page.key,
    sourceOptions: page.sourceOptions,
  });

  newView.setView(viewUpdates);

  store.commit('setView', {
    pageKey: page.key,
    viewData: newView.raw(),
  });
}

/**
 * Replaces a view on a page with a new view.
 *
 * @param {Page} page
 * @param {object} viewData
 * @returns {View}
 */
export function replaceLocalView(page, viewData) {
  // If we are replacing a view then we might want to use the old view's data.
  const oldView = page.getView(viewData.key);

  // Create the new view and send it to the page.
  // If page is reactive then what setView() returns will be reactive.
  const newView = page.setView(
    new View(viewData, {
      key: page.key,
      sourceOptions: page.sourceOptions,
    }),
  );

  let loadData = false;

  if (newView.isRecordDriven() && newView.source && newView.source.object) {
    // If the old view data is valid, then use it instead of reloading the data.
    const oldViewData = oldView?.data;

    if (!isEmpty(oldViewData?.records) && oldView.source.object === newView.source.object) {
      newView.setData(cloneDeep(oldViewData));
    } else {
      loadData = true;
    }
  }

  page.groups = page.groups.map((group) => {
    group.columns = group.columns.map((column) => {
      column.keys = column.keys.map((view) => {
        // Potentially a bug? aren't we mapping the keys themsevles?
        if (view.key === newView.key) {
          // Jon: Like the comment above says, isn't this supposed to be a string?
          return newView;
        }

        // Jon Note: Do we need to consider if these keys are string too?

        return view;
      });

      return column;
    });

    return group;
  });

  if (loadData) {
    log('page-views.replaceLocalView() - Loading view data');

    newView.loadData();
  }

  return newView;
}

/**
 * Deletes a view on a page.
 *
 * @param {RawPage | Page} page
 * @param {string} viewKey
 */
export function deleteLocalView(page, viewKey) {
  store.commit('removeView', {
    pageKey: page.key,
    viewKey,
  });

  page.groups = page.groups.map((group) => {
    group.columns = group.columns.map((column) => {
      column.keys = column.keys.filter((localViewKey) => localViewKey !== viewKey);

      return column;
    });

    return group;
  }).filter((group) => {
    group.columns = group.columns.filter((column) => !isEmpty(column.keys));

    return !isEmpty(group.columns);
  });
}
