<template>
  <view-toolbox
    back-title="Edit Divider"
    title="Settings"
  >
    <form>
      <h2>Design</h2>
      <p>
        <em>
          Override <a class="green underline">
            theme defaults
          </a> with custom design!
        </em>
      </p>
    </form>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DesignUtils from '@/components/views/DesignUtils';
import ChoicePicker from '@/components/ui/inputs/ChoicePicker';
import ColorPicker from '@/components/ui/inputs/ColorPicker';
import Icon from '@/components/ui/Icon';

export default {
  name: 'DividerSettings',
  components: {
    ViewToolbox,
    ChoicePicker,
    ColorPicker,
    Icon,
  },
  mixins: [
    ViewUtils,
    DesignUtils,
  ],
};
</script>
