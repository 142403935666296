<template>
  <div
    class="paste-input-container"
    @click="focusTextArea()"
  >
    <ul>
      <li :class="{ active: hasFocus }">
        Click to activate
      </li>
      <li :class="{ active: hasData, disabled: !hasFocus && !hasData }">
        Paste data
      </li>
    </ul>
    <div v-if="hasData">
      <ul>
        <li>
          Size: {{ dataLength }} bytes
        </li>
      </ul>
      <a
        class="button save"
        @click="handleImport()"
      >
        Import
      </a>
    </div>
    <div class="data-container">
      <textarea
        v-model="pastedData"
        class="data"
      />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      hasFocus: false,
      pastedData: '',
    };
  },
  computed: {
    hasData() {
      return this.pastedData.length > 0;
    },
    dataLength() {
      const encoder = new TextEncoder();

      return encoder.encode(this.pastedData).length;
    },
  },
  mounted() {
    const textarea = this.$el.querySelector('.data');

    log('adding event listeners');

    textarea.addEventListener('focus', () => {
      log('textarea focused');

      textarea.select();

      this.hasFocus = true;
    });

    textarea.addEventListener('blur', () => {
      log('textarea blurred');

      this.hasFocus = false;
    });
  },
  methods: {
    focusTextArea() {
      log('focusing textarea');

      this.$el.querySelector('.data').focus();
    },

    handleImport() {
      // So the paste input can be used for different cases, handle this in the extended component

      log('calling from parent component!');
      log(this.pastedData);
    },
  },
};
</script>

<style lang="scss">
.paste-input-container {
  text-align: center;
  border: 1px solid rgb(119, 27, 102);
  margin: 24px;
  padding: 24px;

  .disabled {
    opacity: 0.75
  }

  .active {
    color: rgb(119, 27, 102);
  }

  .data-container {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
</style>
