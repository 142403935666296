<template>
  <div class="filtersConnectionSource">
    <FiltersEdit
      v-if="editingFilters"
      :filters="localConnectionFiltersForFieldEdit"
      modal-size="medium"
      :object-key="objectKey"
      :show-boolean-options="false"
      @close="editingFilters = false"
      @update="onUpdateFilters"
    />
    <select
      v-if="sourceOptions && sourceOptions.length"
      class="text-base py-2 pl-3 leading-5"
      v-model="localConnectionSource"
    >
      <option
        v-for="showOption in sourceOptions"
        :key="showOption.value"
        :value="showOption.value"
      >
        Show {{ showOption.label }}
      </option>
    </select>

    <select
      v-else
      class="text-base py-2 pl-3 leading-5"
    >
      <option value="">
        Show all {{ connectedObject?.inflections?.singular }} records
      </option>
    </select>
    <div
      v-tippy
      content="Add criteria to define which records to show"
      class="filtersConnectionSource__filterWrapper"
      @click="editingFilters = true;"
    >
      <div class="filtersConnectionSource__filterButton button-square bg-subtle rounded-lg group">
        <Icon
          class="filtersConnectionSource__filterIcon group-hover:text-emphasis"
          type="filter-indicator"
        />
        <span
          v-if="localConnectionFilters.length > 0"
          class="filtersConnectionSource__filterCount"
        >
          {{ localConnectionFilters.length }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import FiltersEdit from '@/components/renderer/shared/FiltersEdit';
import Icon from '@/components/ui/Icon';
import ViewUtils from '@/components/views/ViewUtils';
import ParentConnectionsMixin from '@/components/views/form/ParentConnectionsMixin';

export default {
  components: {
    FiltersEdit,
    Icon,
  },
  mixins: [
    ViewUtils,
    ParentConnectionsMixin,
  ],
  props: {
    connectionSource: {
      type: Object,
      default: () => undefined,
    },
    connectionField: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'update:connectionSource',
  ],
  data() {
    return {
      editingFilters: false,
      ignoreViewInConnectionParentOptions: true,
    };
  },
  computed: {
    // ParentConnectionsMixin uses this
    field() {
      return this.connectionField;
    },
    objectKey() {
      return this.field.get('relationship').object;
    },
    connectedObject() {
      return this.$store.getters.getObject(this.objectKey);
    },
    localConnectionFilters() {
      return this.connectionSource?.filters || [];
    },
    localConnectionFiltersForFieldEdit() {
      // FiltersEdit.vue expects the rules property
      return {
        rules: this.localConnectionFilters,
      };
    },
    localConnectionSource: {
      get() {
        if (!this.connectionSource?.source) {
          return '';
        }

        const { source } = this.connectionSource;

        if (source.remote_key) {
          return `${source.connection_key}-${source.remote_key}-${source.type}`;
        }

        if (source?.connections?.[0]) {
          const firstConnection = source.connections[0];

          return `${firstConnection.field.key}-${firstConnection.source.field.key}-input`;
        }

        return '';
      },
      set(newValue) {
        if (!newValue) {
          this.triggerUpdateConnectionSource(
            undefined,
            this.connectionSource?.filters,
          );
        }

        const {
          connectionKey,
          remoteKey,
          type,
        } = this.mapConnectionParentOptionsFromSelectable(newValue);

        const source = {
          ...(this.connectionSource?.source || {}),
          connection_key: connectionKey,
          remote_key: remoteKey,
          type,
          connections: undefined,
        };

        this.triggerUpdateConnectionSource(
          source,
          this.localConnectionFilters,
        );
      },
    },
    sourceOptions() {
      return this.connectionParentOptions;
    },
  },
  methods: {
    triggerUpdateConnectionSource(source, filters) {
      this.$emit('update:connectionSource', {
        source,
        filters,
      });
    },
    onUpdateFilters({ rules } = {}) {
      const newFilters = (!isEmpty(rules)) ? rules : undefined;

      this.triggerUpdateConnectionSource(
        this.connectionSource?.source,
        newFilters,
      );

      this.editingFilters = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.filtersConnectionSource {
  display: flex;
  margin-bottom: .5rem;
}

.filtersConnectionSource__filterIcon {
  margin-left: 6px;
  margin-right: 6px;
}

.filtersConnectionSource__filterCount {
  height: 18px;
  width: 18px;
  color: $darkerGray;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  margin-left: -0.5rem;
  margin-right: 0.125rem;
  min-width: 1.125rem;
  text-align: center;
  flex-shrink: 0;
}

.filtersConnectionSource__filterWrapper {
  margin-top: 2px;
  margin-left: .5rem;
  cursor: pointer;
  min-width: 40px;
}

.filtersConnectionSource__filterButton {
  display: inline-flex;
}
</style>
