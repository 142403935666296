<template>
  <ImportWizard
    action="create"
    :is-user="isUser"
    @import-started="onClose"
  />
</template>

<script>
import ImportWizard from '@/components/records/import/ImportWizard';

export default {
  components: {
    ImportWizard,
  },
  data() {
    return {};
  },
  computed: {
    isUser() {
      return this.$attrs['is-user'];
    },
  },
  created() {
    // Resets active object set by `beforeEach` routing method
    // Necessary in case there was an object id in the URL params before navigating
    this.$store.commit('activeObject', false);
  },
  methods: {
    onClose() {
      const newObjectKey = this.$store.getters.objects.lastItem.get('key');

      this.$router.push(`/records/objects/${newObjectKey}`);
    },
  },
};
</script>

<style lang="scss">
</style>
