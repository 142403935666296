<template>
  <div
    id="kn-task-history-table"
    class="pb-4"
  >
    <p
      v-if="!hasHistory"
      class="text-default text-base"
    >
      No history found.
    </p>
    <TableWrapper v-else>
      <template #navigation>
        <RecordsTableNavigation
          :object-key="object.key"
          :allow-limit="false"
          :current-records-page="currentPage"
          :records-per-page="25"
          :total-records="totalHistory.length"
          :total-records-pages="totalPages"
          :current-records-count="history.length"
          :show-filters="false"
          :show-keyword-search="false"
          :show-export-button="false"
          @update="onTableUpdate"
        />
      </template>
      <template #table>
        <RouterLink
          id="view-mail-delivery-history"
          :to="`${routePrefix}/emails/history`"
          class="fuchsia underline margin-bottom text-default underline-offset-4 text-base"
        >
          View email delivery history
        </RouterLink>
        <VTable
          :columns="columns"
          :records="history"
          :show-inline-edit="false"
          :allow-sorting="false"
          :draggable="false"
          class="mt-6"
          data-cy="task-history-table"
        />
      </template>
    </TableWrapper>
  </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import FieldUtils from '@/components/fields/FieldUtils';
import TableWrapper from '@/components/renderer/table/TableWrapper';
import RecordsTableNavigation from '@/components/records/RecordsTableNavigation';
import VTable from '@/components/renderer/table/Table';
import RequestUtils from '@/components/util/RequestUtils';
import SchemaUtils from '@/store/utils/SchemaUtils';
import Field from '@/store/models/Field';

const RECORDS_PER_PAGE = 25;

export default {
  components: {
    TableWrapper,
    RecordsTableNavigation,
    VTable,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  data() {
    return {
      history: [],
      totalHistory: [],
      currentPage: 1,
    };
  },
  computed: {
    backLink() {
      return `/tasks/objects/${this.object.key}`;
    },
    routePrefix() {
      return `/tasks/objects/${this.object.key}/${this.$route.params.taskKey}/history`;
    },
    hasHistory() {
      return !isEmpty(this.totalHistory);
    },
    columns() {
      // the Field model constructor expects an object to be passed in
      const objectModel = {
        key: null,
      };

      const historyColumnsDefaults = [
        {
          type: 'field',
          field: new Field({
            key: 'started_at', type: 'date_time',
          }, objectModel),
          header: 'Started At',
          sortable: false,
        },
        {
          type: 'field',
          field: new Field({
            key: 'ended_at', type: 'date_time',
          }, objectModel),
          header: 'Ended At',
          sortable: false,
        },
        {
          type: 'field',
          field: new Field({
            key: 'status', type: 'short_text',
          }, objectModel),
          header: 'Status',
          sortable: false,
        },
        {
          type: 'field',
          field: new Field({
            key: 'processed_count', type: 'count',
          }, objectModel),
          header: 'Records Processed',
          sortable: false,
        },
      ];

      return historyColumnsDefaults.map((column) => ({ ...SchemaUtils.columnDefaults(), ...column }));
    },
    totalPages() {
      return Math.ceil(this.totalHistory.length / RECORDS_PER_PAGE);
    },
  },
  mounted() {
    this.getTaskData();
  },
  methods: {
    onTableUpdate({ currentPage }) {
      this.currentPage = currentPage;
      this.history = this.getVisibleHistory(this.totalHistory, currentPage);
    },
    getTaskData() {
      this.commitRequest({
        request: () => this.object.getTaskHistory(this.$route.params.taskKey),
        onSuccess: (history) => {
          const totalHistory = history.map((record) => ({
            ...record,
            ended_at: moment(record.ended_at).format('MM/DD/YYYY h:mma'),
            started_at: moment(record.started_at).format('MM/DD/YYYY h:mma'),
          }));

          this.totalHistory = totalHistory;
          this.history = this.getVisibleHistory(totalHistory);
        },
      });
    },
    getVisibleHistory(history, visiblePage = 1) {
      if (visiblePage === 1) {
        return history.slice(0, RECORDS_PER_PAGE);
      }

      const startIndex = RECORDS_PER_PAGE * (visiblePage - 1);
      const endIndex = RECORDS_PER_PAGE * visiblePage;

      return history.slice(startIndex, endIndex);
    },
  },
};
</script>
