export const ColumnUtilsMixin = {
  computed: {
    isGrouping() {
      return this.column.grouping === true;
    },
    columnHeader() {
      return this.column.header;
    },
    columnStyle() {
      const styles = {};

      if (this.column.width.type !== 'default') {
        const unit = this.column.width.units === 'px' ? this.column.width.units : '%';

        styles.width = `${this.column.width.amount}${unit}`;
      }

      return styles;
    },
    columnClass() {
      return [];
    },
  },
};
