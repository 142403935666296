<template>
  <div class="value-list-item grid grid-cols-2 gap-2">
    <select
      v-if="showFieldOptions"
      :value="item.field"
      data-cy="value-list-field"
      class="kn-input text-base py-2 pl-3 leading-5 truncate"
      :class="{ 'col-span-2': !showValueTypeOptions }"
      @change="onItemFieldChange($event.target.value)"
    >
      <option
        v-for="objField in objectFields"
        :key="objField.key"
        :value="objField.key"
      >
        {{ objField.name }}
      </option>
    </select>

    <span
      v-else
      class="kn-content-width text-default text-sm font-medium leading-4 self-center"
    >
      Set
    </span>

    <select
      v-if="showValueTypeOptions"
      v-model="item.type"
      class="value-item-type-options kn-input text-base py-2 pl-3 leading-5 truncate"
      :class="{ 'col-span-2': !showFieldOptions }"
      data-cy="value-list-type"
    >
      <option
        v-for="(typeOption) in typeOptions"
        :key="typeOption.value"
        :value="typeOption.value"
        :disabled="typeOption.disabled"
        :title="typeOption.title"
      >
        {{ typeOption.label }}
      </option>
    </select>
    <span
      v-else
      class="kn-content-width text-default text-sm font-medium leading-4 self-center col-span-2"
    >
      to
    </span>

    <select
      v-if="showRuleActionOptions"
      v-model="item.action"
      class="kn-input text-base py-2 pl-3 leading-5 truncate col-span-2"
    >
      <option
        v-for="(actionOption) in actionOptions"
        :key="actionOption.value"
        :value="actionOption.value"
      >
        {{ actionOption.label }}
      </option>
    </select>

    <template v-if="itemType === 'record'">
      <select
        v-if="inputFields.length"
        v-model="item.input"
        class="text-base py-2 pl-3 leading-5 truncate col-span-2"
      >
        <option
          v-for="inputField in inputFields"
          :key="inputField.value"
          :value="inputField.value"
        >
          {{ inputField.label }}
        </option>
      </select>

      <div
        v-else
        class="kn-content-width"
      >
        No available fields
      </div>
    </template>

    <select
      v-if="itemType === 'connection'"
      v-model="item.connection_field"
      class="text-base py-2 pl-3 leading-5 truncate col-span-2"
    >
      <option
        v-for="connectedField in connectedFields"
        :key="connectedField.key"
        :value="connectedField.value"
      >
        {{ connectedField.label }}
      </option>
    </select>

    <div
      v-if="itemType === 'value'"
      :key="`${field.key}-value-input`"
      class="kn-form kn-form-constrained w-full group/shared col-span-2"
    >
      <FormInput
        :input="fieldInput"
        :field="field"
        :model-value="item.value"
        :show-label="false"
        :should-upload-asset-on-add="true"
        @update="onFormInputUpdated"
      />
    </div>
  </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import FormInput from '@/components/renderer/form/FormInput';

export default {
  components: {
    FormInput,
  },
  props: {

    // An array of keys that should be excluded from this.objectFields
    // E.g. RecordAction uses this to eclude the connection field that
    // the "Insert a connected record" action is setting automatically
    excludedObjectFieldKeys: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    object: {
      type: Object,
      default: () => ({}),
    },
    connectedFieldOptions: {
      type: Array,
      default: () => [],
    },
    inputFieldOptions: {
      type: Array,
      default: () => [],
    },
    valueCanBeField: {
      type: Boolean,
      default: false,
    },
    allowConnectionOption: {
      type: Boolean,
      default: true,
    },
    allowLocationOption: {
      type: Boolean,
      default: true,
    },
    showValueTypeOptions: {
      type: Boolean,
      default: false,
    },
    showFieldOptions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      action: '',
      actionOptions: [
        {
          label: 'by replacing with',
          value: '',
        },
        {
          label: 'by adding',
          value: 'append',
        },
        {
          label: 'by removing',
          value: 'remove',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'activePage',
    ]),
    typeOptions() {
      const typeOptions = [
        {
          label: 'to a custom value',
          value: 'value',
        },
        {
          label: this.recordValueLabel,
          value: 'record',
          title: (!this.inputFields.length) ? 'No available fields' : '',
          disabled: !this.inputFields.length,
        },
      ];

      if (this.showUserOption) {
        typeOptions.push({
          label: `to the logged-in ${this.userLabel}`,
          value: 'user',
        });
      }

      if (this.showConnectionOption) {
        typeOptions.push({
          label: 'to a connected value',
          value: 'connection',
        });
      }

      if (this.showDateOption) {
        typeOptions.push({
          label: 'to the current date',
          value: 'current_date',
        });
      }

      if (this.showLocationOption) {
        typeOptions.push({
          label: 'to the user\'s current location',
          value: 'current_location',
        });
      }

      return typeOptions;
    },
    itemType() {
      return this.item.type || 'record';
    },
    objectFields() {
      let objectFields = this.object.getMutableFields();

      if (this.excludedObjectFieldKeys.length) {
        objectFields = objectFields.filter((field) => !this.excludedObjectFieldKeys.includes(field.key));
      }

      return objectFields;
    },
    fieldInput() {
      return this.field.getAsFormInput?.() ?? {};
    },
    connectedFields() {
      return this.field.getCompatibleFieldOptions?.(this.connectedFieldOptions) ?? [];
    },
    inputFields() {
      // check if no field options: there will be a single array with a null value
      if (this.inputFieldOptions.length === 1 && !this.inputFieldOptions[0].value) {
        return this.inputFieldOptions;
      }

      return this.field.getCompatibleFieldOptions?.(this.inputFieldOptions) ?? [];
    },
    field() {
      if (!this.object || !this.item?.field) {
        return {};
      }

      return this.object.getField(this.item.field);
    },
    userLabel() {
      if (!this.showUserOption) {
        return '';
      }

      return this.$store.getters.getObject(this.field.get('relationship').object).name;
    },
    showUserOption() {
      if (!this.field.isConnection?.()) {
        return false;
      }

      // are we dealing with an active page?
      if (!this.$store.getters.activePage) {
        return false;
      }

      // is the connected object pointing to a user role?
      const connectedObject = this.$store.getters.getObject(this.field.get('relationship').object);

      if (!connectedObject.isUser()) {
        return false;
      }

      // if pointing to "all_users", it's good
      if (connectedObject.isAccount()) {
        return true;
      }

      // otherwise make sure the active page allows access for this user role
      return this.activePage.roleHasAccess(connectedObject.profile_key);
    },
    showRuleActionOptions() {
      const isCustomValue = this.itemType === 'value';
      const hasField = this.field;
      const isSupportedMultipleChoice = hasField && this.field.type === 'multiple_choice' && (this.field.format.type === 'multi' || this.field.format.type === 'checkboxes');
      const isConnection = hasField && this.field.type === 'connection';
      const isMany = hasField && isConnection && get(this.field, 'relationship.has', 'one') === 'many';

      return this.showValueTypeOptions
        && isCustomValue
        && (isSupportedMultipleChoice || (isConnection && isMany));
    },
    showConnectionOption() {
      return this.allowConnectionOption && this.connectedFields.length > 0;
    },

    showDateOption() {
      return this.field.isDate?.();
    },

    showLocationOption() {
      return this.allowLocationOption && this.field.isAddress?.();
    },

    recordValueLabel() {
      // TODO:: is this necessary to label "to a form value"?
      // The use case is insert forms where your only available options
      // are fields that are inputs in the form
      /*
        if (this.$store.getters.activeView && this.$store.getters.activeView.type === `form`) {
          return `to a form value`
        }
        */

      return `to a field value`;
    },
  },
  watch: {
    showRuleActionOptions(newVar) {
      if (!newVar) {
        // Reset the item action if the new item does not support actions.
        this.item.action = '';
      }
    },
    'item.field': function (newVar, oldVar) {
      // clear values
      // TODO:: do smart conversion based on previous field/value
      if (oldVar) {
        this.item.value = '';
      }

      this.setDefaultValue();

      // if this field doesn't have the current type option, default to first available
      if (!this.typeOptions.find((option) => option.value === this.item.type)) {
        this.item.type = this.typeOptions[0].value;
      }
    },
    'item.type': function (newVar, oldVar) {
      this.setDefaultValue();
    },
    'item.action': function (newValue, oldValue) {
      this.item.action = newValue;
    },
  },
  created() {
    // it's possible the item field is not in the available
    // list of fields (e.g. toggling between connected objects)
    if (!this.item.field || !this.objectFields.find((field) => this.item.field === field.key)) {
      this.onItemFieldChange(this.objectFields?.[0]?.key || null);
    }

    this.setDefaultActionForConnectionAndMultipleChoiceRules();
  },
  methods: {
    setDefaultActionForConnectionAndMultipleChoiceRules() {
      if (!this.showRuleActionOptions) {
        return;
      }

      this.item.action = this.item.action || '';
    },
    setDefaultValue() {
      if (this.itemType === 'record') {
        if (!this.inputFields.find((field) => field.value === this.item.input)) {
          this.item.input = (this.inputFields[0]) ? this.inputFields[0].value : '';
        }

        // if no available fields to set to, default to a custom value
        if (isEmpty(this.inputFields)) {
          this.item.type = 'value';
        }
      }

      if (this.itemType === 'connection') {
        if (!this.connectedFields.find((field) => field.value === this.item.connection_field)) {
          this.item.connection_field = (this.connectedFields[0]) ? this.connectedFields[0].value : '';
        }
      }

      if (this.item.type === 'value' && this.field && this.field.type === 'multiple_choice') {
        if (!this.item.value || !this.field.format.options.includes(this.item.value)) {
          // Default value means this can sometimes be blank, depending on the field settings.
          this.item.value = this.field.getDefaultValue();
        }
      }

      this.setDefaultActionForConnectionAndMultipleChoiceRules();
    },
    onFormInputUpdated(newValue) {
      this.item.value = newValue;
    },
    onItemFieldChange(newFieldKey) {
      this.item.field = newFieldKey;
    },
  },
};
</script>

<style lang="scss">
.value-list-item {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: top;

  & > span {
    font-size: 1em;
    line-height: 2.2em;
  }

  & > * {
    margin-right: $spacing-xs;
    min-width: 70px;
    max-width: fit-content;
    flex: 1;
  }

  & > *:last-child {
    margin-right: 0;
  }

  .kn-content-width {
    max-width: max-content;
    min-width: min-content;
  }

  .kn-inputWrapper {
    width: 100%;

    p {
      margin: 0;
    }
  }

  .kn-form {
    max-width: 100%;
    min-width: 0px;
  }

  .kn-input,
  .kn-select input {
    width: 100%;
  }
}
</style>
