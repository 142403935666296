<template>
  <textarea
    :id="input.id"
    v-model="localValue"
    class="kn-textarea"
    :name="input.id"
  />
</template>

<script>
export default {
  name: 'TextAreaInput',
  inheritAttrs: false,
  props: [
    'input',
    'field',
    'modelValue',
  ],
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
};
</script>

<style lang="scss">
.kn-textarea {
  height: 80px;
  width: 566px;
}
</style>
