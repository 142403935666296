<template>
  <Modal
    :size="modalSize"
    :title="title"
    :description="description"
    :show-title="hasTitle || hasTitleSlotContent"
    :show-close="hasCancel"
    :is-centered="isCentered"
    :classes="[`confirmModal-${confirmType}`, `confirmModal`, 'rounded-xl', 'border-0', ...classes]"
    :z-index="zIndex"
    data-cy="confirm-modal"
    @close="onClose"
  >
    <template
      v-if="overrideTitleSlot"
      #title
    >
      <slot name="titleContent">
        <h1>{{ title }}</h1>
      </slot>
    </template>

    <div
      class="m-0"
      :class="{ 'confirmModal_content': withContentMargin }"
    >
      <slot />
    </div>
    <template #footer>
      <div
        class="flex justify-end gap-2"
        :class="{'is-centered': isCentered}"
      >
        <BuilderButton
          :theme="confirmButtonTheme"
          class="confirmModal_button text-base text-white rounded-lg p-3 border-0 order-2 h-auto"
          :data-cy="confirmButtonTestId"
          :disabled="confirmButtonDisabled"
          @click="onClickConfirm"
        >
          {{ confirmText }}
        </BuilderButton>
        <BuilderButton
          v-if="hasCancel"
          class="confirmModal_button text-emphasis rounded-lg p-3 border-0 order-1 h-auto"
          theme="cancel"
          data-cy="cancel"
          @click="onClickCancel"
        >
          {{ cancelText }}
        </BuilderButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Modal from '@/components/ui/Modal';
import Icon from '@/components/ui/Icon';
import BuilderButton from '@/components/ui/BuilderButton';

const CONFIRM_TYPES = new Set([
  'alert',
  'info',
  'confirm',
  'save',
]);

const BUTTON_TYPE_THEMES = {
  alert: 'delete',
  confirm: 'confirm',
  info: 'confirm',
};

export default {
  components: {
    Modal,
    Icon,
    BuilderButton,
  },
  props: {
    title: {
      type: String,
      default: 'Confirm',
    },
    description: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'Delete',
    },
    confirmButtonType: {
      type: String,
      default: '',
    },
    confirmButtonTestId: {
      type: String,
      default: 'confirm',
    },
    confirmButtonDisabled: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    modalSize: {
      type: String,
      default: 'smallish',
    },
    classes: {
      type: Array,
      default: () => [],
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    back: {
      type: [
        String,
        undefined,
      ],
      default: undefined,
    },
    confirmType: {
      type: String,
      default: 'confirm',
      validator: (value) => CONFIRM_TYPES.has(value),
    },
    hasCancel: {
      type: Boolean,
      default: true,
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 1,
    },
    withContentMargin: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['cancel', 'close', 'confirm'],
  computed: {
    hasTitle() {
      return !isEmpty(this.title);
    },
    hasTitleSlotContent() {
      return !isNil(this.$slots.titleContent);
    },
    overrideTitleSlot() {
      return this.hasTitleSlotContent;
    },
    confirmButtonTheme() {
      const type = this.confirmButtonType || this.confirmType;

      return BUTTON_TYPE_THEMES[type];
    },
  },
  methods: {
    onClose() {
      log('confirmModal.onClose', this.back);

      this.routeBack();

      this.$emit('close');
    },

    routeBack() {
      // we can't just use router.back because the modal itself could have many child routes
      if (!isNil(this.back)) {
        this.$router.push(this.back);
      }
    },

    onClickCancel() {
      this.routeBack();

      this.$emit('cancel');
      this.$emit('close');
    },
    onClickConfirm() {
      if (this.confirmButtonDisabled) {
        return;
      }

      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss">
.confirmModal {

  p {
    margin-bottom: .75em;
  }

  &.center {

    p {
      text-align: center;
    }
  }

  .icon-warning {
    width: 40px;
    height: 40px;
    color: $error500;
  }

  h2 {
    font-size: 1.15em;
    margin-top: .5em;
    margin-bottom: 1.25em;
    text-align: center;
    font-weight: 500;
  }

  // TODO:: remove when this is fixed in the design library
  .knButton {
    font-size: .875em !important;
  }

  &_content {
    margin: 2rem;
  }

  &_button + .confirmModal_button {
    margin-left: .5rem;
  }

  &-alert {
    .modal_title {
      color: $error500;
    }
  }

  &-info {
    .modal_title {
      color: $secondary500;
    }
  }

  .modal_footer .is-centered {
    text-align: center;
    justify-content: center;
  }
}
</style>
