import isEmpty from 'lodash/isEmpty';

export const replaceNewItemKey = (itemKey, groups) => {
  groups = groups.map((group) => {
    group.columns = group.columns.map((column) => {
      column.keys = column.keys.map((key) => (key === 'new' ? itemKey : key));

      return column;
    });

    return group;
  });

  return groups;
};

// TODO use https://github.com/immutable-js/immutable-js/#nested-structures
export const addItemKey = (itemKey, groups, addLocationType = 'group', groupIndex = 0, columnIndex = 0, itemIndex = 0) => {
  // new group
  if (addLocationType === 'group') {
    return [
      ...groups.slice(0, groupIndex),
      {
        columns: [
          {
            keys: [
              itemKey,
            ],
          },
        ],
      },
      ...groups.slice(groupIndex),
    ];
  }

  // new column
  if (addLocationType === 'column') {
    return [
      ...groups.slice(0, groupIndex),
      {
        columns: [
          ...groups[groupIndex].columns.slice(0, columnIndex),
          {
            keys: [
              itemKey,
            ],
          },
          ...groups[groupIndex].columns.slice(columnIndex),
        ],
      },
      ...groups.slice(groupIndex + 1),
    ];
  }

  if (addLocationType === 'item') {
    groups = [
      ...groups.slice(0, groupIndex),
      {
        columns: [
          ...groups[groupIndex].columns.slice(0, columnIndex),
          {
            keys: [
              ...groups[groupIndex].columns[columnIndex].keys.slice(0, itemIndex),
              itemKey,
              ...groups[groupIndex].columns[columnIndex].keys.slice(itemIndex),
            ],
          },
          ...groups[groupIndex].columns.slice(columnIndex + 1),
        ],
      },
      ...groups.slice(groupIndex + 1),
    ];

    return groups;
  }
};

export const removeItemKey = (itemKey, groups) => {
  groups = groups.map((group) => {
    group.columns = group.columns.map((column) => {
      column.keys = column.keys.filter((key) => key !== itemKey);

      return column;
    });

    return group;
  });

  return clearEmptyColumns(groups);
};

export const updateItemKey = (itemKey, groups, addLocationType, groupIndex, columnIndex, itemIndex) => {
  groups = removeItemKey(itemKey, groups);

  groups = addItemKey(itemKey, groups, addLocationType, groupIndex, columnIndex, itemIndex);

  return groups;
};

export const clearEmptyColumns = (groups) => groups.filter((group) => {
  group.columns = group.columns.filter((column) => !isEmpty(column.keys));

  return !isEmpty(group.columns);
});
