<template>
  <Overlay
    id="object-add-wrapper"
    class="full-width full-body gray-bg left-title"
    :back="backLink || URLPrefix"
  >
    <template #title>
      <h1 class="text-base font-semibold items-center">
        Add {{ objectContext }}
        <div
          id="addobject-nav"
          class="gap-2 ml-12"
          data-cy="add-from-scratch"
        >
          <router-link
            v-if="!isUser"
            v-slot="{ isActive }"
            :to="routerLink('import')"
            class="border-0 m-0"
            data-cy="add-from-import"
          >
            <span :class="getRouterLinkContentWrapperClasses(isActive)">
              By Import
            </span>
          </router-link>
          <router-link
            v-if="!isUser"
            v-slot="{ isActive }"
            class="border-0 m-0"
            :to="routerLink('scratch')"
          >
            <span :class="getRouterLinkContentWrapperClasses(isActive)">
              From Scratch
            </span>
          </router-link>
          <router-link
            v-if="hasIntegrationsEnabled && !isUser"
            v-slot="{ isActive }"
            :to="routerLink('integration')"
            class="border-0 m-0"
          >
            <span :class="getRouterLinkContentWrapperClasses(isActive)">
              From an Integration
              <div class="badge-wrapper">
                <Badge intent="brand">
                  Preview
                </Badge>
              </div>
            </span>
          </router-link>
          <router-link
            v-if="!isUser"
            v-slot="{ isActive }"
            :to="routerLink('template')"
            class="border-0 m-0"
            data-cy="add-from-template"
          >
            <span :class="getRouterLinkContentWrapperClasses(isActive)">
              Table Catalog
            </span>
          </router-link>
        </div>
      </h1>
    </template>

    <RouterView :is-user="isUser" />
  </Overlay>
</template>

<script>
import Overlay from '@/components/ui/Overlay';
import Badge from '@/components/ui/Badge';
import FieldUtils from '@/components/fields/FieldUtils';
import { INTEGRATIONS } from '@/constants/featureflags';

export default {
  components: {
    Overlay,
    Badge,
  },
  mixins: [
    FieldUtils,
  ],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.backLink && from.path && from.path !== '/') {
        vm.backLink = from.path;
      }
    });
  },
  data() {
    return {
      title: 'Add a Table',
      existingQueryString: '',
      rootURL: '',
      backLink: '',
    };
  },
  computed: {
    isUser() {
      return this.$route.query.user === 'true';
    },
    objectContext() {
      return this.isUser ? 'a User Role' : 'a Table';
    },
    URLPrefix() {
      if (this.$route.path.includes('cards')) {
        return '/schema/cards/objects';
      }

      if (this.$route.path.includes('records')) {
        return '/records/objects';
      }

      return '/schema/list/objects';
    },
    hasIntegrationsEnabled() {
      return this.$store.getters.hasFeature(INTEGRATIONS);
    },
  },

  // set the backlink to the previous route
  created() {
    if (this.$route.fullPath.indexOf('?') > -1) {
      this.existingQueryString = `?${this.$route.fullPath.split('?')[1]}`;
    }

    if (this.isUser) {
      this.title = 'Add a new User Role';
    }
  },
  methods: {

    // KLUDGE: ensure the links can be active; has to be a better way here
    routerLink(which) {
      return `${this.URLPrefix}/add/${which}${this.existingQueryString}`;
    },
    getRouterLinkContentWrapperClasses(isActive) {
      return [
        'text-default',
        'text-base',
        'font-normal',
        'p-2',
        'rounded-lg',
        'flex',
        'items-center',
        {
          'text-emphasis bg-brand-100': isActive,
          'hover:text-emphasis': !isActive,
          'hover:bg-brand-50': !isActive,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
#object-add-wrapper {
  > .title h1 svg {
    color: $green-text;
  }
}
#field-current {
  font-size: 1.05em;
  strong {
    margin-right: 6px;
    vertical-align: middle;
  }
}

#addobject-nav {
  font-size: 1.05rem;
  line-height: 1.7em;
  margin-left: 3em;
  display: flex;

  > a {
    display: block;
    margin-right: 2em;
    color: $gray-text;

    &.router-link-active {
      color: $fuchsia-text;
      border-bottom: 3px solid $fuchsia;
    }

    &:not(.router-link-active):hover {
      color: $gray-text-hover;
    }
  }
}

.badge-wrapper {
  display: inline-flex;
  align-items: center;
  margin-left: $spacing-xxs;
}
</style>
