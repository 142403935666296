<template>
  <div class="control kn-inputWrapper is-grouped">
    <p
      v-if="useTitle"
      class="control kn-inputWrapper select"
    >
      <label
        v-if="showHintLabels"
        class="hint group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:mb-2 group-[&:not(.kn-view)]/shared:text-sm"
        for="title"
      >
        {{ trans('Title') }}
      </label>
      <select
        id="title"
        v-model="localTitle"
        name="title"
        class="select"
      >
        <option value="">
          None
        </option>
        <option>Ms.</option>
        <option>Miss</option>
        <option>Mr.</option>
        <option>Mrs.</option>
        <option>Dr.</option>
        <option>Prof.</option>
      </select>
    </p>
    <p class="control kn-inputWrapper is-expanded">
      <label
        v-if="showHintLabels"
        class="hint group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:mb-2 group-[&:not(.kn-view)]/shared:text-sm"
        for="first"
      >
        {{ trans('First') }}
      </label>
      <input
        id="first"
        v-model="localFirst"
        class="input kn-input"
        name="first"
        type="text"
        :placeholder="trans('First')"
      >
    </p>
    <p
      v-if="useMiddle"
      class="control kn-inputWrapper is-expanded"
    >
      <label
        v-if="showHintLabels"
        class="hint group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:mb-2 group-[&:not(.kn-view)]/shared:text-sm"
        for="middle"
      >
        {{ trans('Middle') }}
      </label>
      <input
        id="middle"
        v-model="localMiddle"
        class="input kn-input"
        name="middle"
        type="text"
        :placeholder="trans('Middle')"
      >
    </p>
    <p class="control kn-inputWrapper is-expanded">
      <label
        v-if="showHintLabels"
        class="hint group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:mb-2 group-[&:not(.kn-view)]/shared:text-sm"
        for="last"
      >
        {{ trans('Last') }}
      </label>
      <input
        id="last"
        v-model="localLast"
        class="input kn-input"
        name="last"
        type="text"
        :placeholder="trans('Last')"
      >
    </p>
    <input
      name="key"
      type="hidden"
      :value="field.key"
    >
  </div>
</template>

<script>
import hasIn from 'lodash/hasIn';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';

export default {
  name: 'NameInput',
  mixins: [
    TranslationUtils,
  ],
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [Object, String],
      required: true,
    },
    input: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    showHintLabels: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    localTitle: {
      get() {
        return this.localValue.title;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          title: newValue,
        });
      },
    },
    localFirst: {
      get() {
        return this.localValue.first;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          first: newValue,
        });
      },
    },
    localMiddle: {
      get() {
        return this.localValue.middle;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          middle: newValue,
        });
      },
    },
    localLast: {
      get() {
        return this.localValue.last;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          last: newValue,
        });
      },
    },
    fieldKey() {
      return this.field.key;
    },
    useTitle() {
      return hasIn(this.input, 'format.format') && this.input.format.format.includes('Title');
    },
    useMiddle() {
      return hasIn(this.input, 'format.format') && this.input.format.format.includes('Middle');
    },
  },
};
</script>

<style scoped>
.kn-inputWrapper .hint {
  margin-bottom: 0.25rem;
  padding: 0;
}
</style>
