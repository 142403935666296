<template>
  <div :class="{ 'pb-10': isToolboxSaveRendered }">
    <AddChildPage
      v-if="showChildPageModal"
      :page-vars="currentItem.scene"
      @submit="onSubmitChildPage"
      @close="onClose"
    />

    <GroupList
      v-model:items="listItems"
      class="form-emails small mt-4"
      group-list-item-classes="bg-subtle"
      item-title="Submit Rule"
      key-prefix="submit"
      :page="childPage()"
      :pre-copy-item="preCopyItem"
      :can-delete-item="canDeleteItem"
    >
      <template #default="{item}">
        <div>
          <div>
            <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Submit Action</label>
            <select
              v-model="item.action"
              data-cy="submit-rule-submit-action"
              @change="onChangeAction(item)"
            >
              <option value="message">
                Show a confirmation message
              </option>
              <option
                v-if="showParentPageOption"
                value="parent_page"
              >
                Redirect to the parent page
              </option>
              <option value="existing_page">
                Redirect to an existing page
              </option>
              <option value="url">
                Redirect to another website URL
              </option>
              <option value="child_page">
                Redirect to a new child page
              </option>
            </select>
          </div>
        </div>

        <!-- WHEN CRITERIA -->
        <div v-if="item.is_default">
          <div>
            <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">When</label>
            <p><em>Default submit rule</em>.</p>
          </div>
        </div>
        <div v-else>
          <div>
            <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">When</label>
            <CriteriaList
              :criteria="item.criteria"
              :object="object"
              :values-can-be-fields="valuesCanBeFields"
              :can-be-empty="false"
              @update:criteria="onUpdateCriteria(item, $event)"
            >
              <template #no-criteria>
                Currently applies to every form.
              </template>
            </CriteriaList>
          </div>
        </div>

        <!-- ACTION -->
        <div>
          <div
            v-if="item.action === `message`"
            key="message"
          >
            <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Show the following message</label>
            <Redactor
              v-model="item.message"
              :input="item"
              :config="redactorOptions"
            />
            <div class="input-labels tw-input-labels">
              <label class="text-emphasis"><input
                v-model="item.reload_show"
                type="checkbox"
                @click="item.reload_auto = false"
              > Show a link to reload the form</label>
              <label class="text-emphasis"><input
                v-model="item.reload_auto"
                type="checkbox"
                @click="item.reload_show = false"
              > Automatically reload the form</label>
            </div>
          </div>

          <div
            v-if="item.action === `existing_page`"
            key="existing_page"
          >
            <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Redirect to the following page</label>
            <select
              v-model="item.existing_page"
              data-cy="submit-rule-existing-page"
              style="max-width: 100%;"
            >
              <template v-for="page in redirectPages">
                <option
                  v-if="pageIsNotAFormChildPage(item, page)"
                  :key="page.key"
                  :value="page.slug"
                >
                  {{ page.label }}
                </option>
              </template>
            </select>
          </div>

          <div
            v-if="item.action === `url`"
            key="url"
          >
            <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Redirect to the following URL</label>
            <input
              v-model="item.url"
              type="text"
            >
          </div>

          <div
            v-if="item.action === `child_page`"
            key="child_page"
          >
            <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Child Page</label>
            <div
              v-if="!item.scene"
              class="level-left"
            >
              <Icon
                type="page"
                class="small text-subtle h-4 w-4 opacity-100"
                style="opacity: .65;"
              />&nbsp;<a
                class="fuchsia text-default underline ml-auto"
                @click="onEditChildPage(item)"
              >
                Set the child page
              </a>
            </div>
            <div
              v-if="item.scene && item.scene.object"
              class="level-left"
            >
              <Icon
                type="page"
                class="small text-subtle h-4 w-4 opacity-100"
                style="opacity: .65;"
              />&nbsp;{{ item.scene.name }}&nbsp;<a
                class="fuchsia text-default underline ml-auto"
                @click="onEditChildPage(item)"
              >
                edit
              </a>
            </div>
            <div
              v-if="item.scene && !item.scene.object && $store.getters.getPageBySlug(item.scene)"
              class="level-left text-default"
            >
              <Icon
                type="page"
                class="small text-subtle h-4 w-4 opacity-100"
                style="opacity: .65;"
              />&nbsp;{{ $store.getters.getPageBySlug(item.scene).name }}
            </div>
          </div>
        </div>
      </template>
    </GroupList>

    <!-- ends here -->
    <div>
      <a
        class="button small green-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base"
        @click="onAddListItem"
      >
        <Icon
          class="text-subtle h-4 w-4 group-hover:text-brand-400 mr-2 fill-current"
          type="plus-thin"
        /> Add Rule
      </a>
    </div>
  </div>
</template>

<script>
import AddChildPage from '@/components/views/form/AddChildPage';
import CriteriaList from '@/components/ui/lists/CriteriaList';
import GroupList from '@/components/ui/lists/GroupList';
import Icon from '@/components/ui/Icon';
import Redactor from '@/lib/redactor/VueRedactor';
import ViewUtils from '@/components/views/ViewUtils';
import { NEW_PAGE_KEY } from '@/lib/page/page-constants';

export default {
  name: 'FormSubmitRules',
  components: {
    AddChildPage,
    CriteriaList,
    GroupList,
    Icon,
    Redactor,
  },
  mixins: [
    ViewUtils,
  ],
  data() {
    return {
      currentItem: {
        type: Object,
        default: () => ({}),
      },
      redactorOptions: {
        autoresize: true,
        removeComments: true,
        buttons: [
          'html',
          'formatting',
          'bold',
          'italic',
          'deleted',
          'link',
          'unorderedlist',
          'orderedlist',
          'outdent',
          'indent',
        ],
        plugins: [
          'fontcolor',
          'image_web_link',
        ],
      },
      showChildPageModal: false,
    };
  },
  computed: {
    valuesCanBeFields() {
      return this.viewRaw.action === 'update';
    },
    listItems: {

      get() {
        return this.viewRaw.rules?.submits;
      },
      set(newVal) {
        this.viewRaw.rules.submits = newVal;
      },
    },
    redirectPages() {
      const includeChildrenOfChildren = true;

      return this.$store.getters
        .getStarterPlusChildPagesByObject(this.object.key, includeChildrenOfChildren);
    },
    showParentPageOption() {
      return this.$store.getters.activePage.parent;
    },
    isToolboxSaveRendered() {
      const { getters } = this.$store;

      return getters.viewHasActiveUpdates || getters.pageHasActiveUpdates;
    },
  },
  methods: {
    pageIsNotAFormChildPage(item, page) {
      return !item.scene || page.slug !== item.scene;
    },
    onChangeAction(item) {
      if (item.action === 'existing_page') {
        if (!item.existing_page) {
          item.existing_page = this.redirectPages[0].slug;
        }
      }

      if (item.action === 'child_page') {
        // if a scene, confirm it exists
        if (item.scene) {
          // delete the scene if it doesn't
          if (!this.$store.getters.getPageBySlug(item.scene)) {
            delete item.scene;

            this.setChildSceneDefault(item);
          }
        } else {
          this.setChildSceneDefault(item);

          this.onEditChildPage(item);
        }
      }
    },
    onClose() {
      this.showChildPageModal = false;
    },
    // passed in to GroupList to modify the copied item
    preCopyItem(newItem) {
      if (newItem.is_default) {
        delete newItem.is_default;
        newItem.criteria = [];
      }

      return newItem;
    },
    canDeleteItem(item, index) {
      if (item.is_default) {
        return false;
      }

      return true;
    },
    defaultItem() {
      return {
        action: 'message',
        criteria: [
          this.object.fields[0].getDefaultRule(),
        ],
        message: 'Form successfully submitted.',
        reload_show: false,
        reload_auto: false,
        scene: null,
        existing_page: this.redirectPages[0].slug,
      };
    },
    onAddListItem() {
      if (!this.viewRaw.rules || !this.viewRaw.rules.submits) {
        this.addViewProperties({
          submits: [],
        }, 'rules');
      }

      const newListItems = [...this.listItems];
      newListItems.splice(newListItems.length - 1, 0, this.defaultItem());

      // ensure computed set is triggered
      this.listItems = newListItems;
    },
    onAddCriteria(item) {
      if (!item.criteria) {
        item.criteria = [];
      }

      item.criteria.push({
        field: this.object.fields[0].key,
        operator: 'contains',
        value: '',
      });
    },
    onUpdateCriteria(item, newCriteria) {
      item.criteria = newCriteria;
    },
    onEditChildPage(item) {
      this.currentItem = item;

      if (!this.currentItem.scene) {
        this.setChildSceneDefault(this.currentItem);
      }

      this.showChildPageModal = true;
    },
    setChildSceneDefault(item) {
      item.scene = {
        name: 'New Page',
        parent: this.$store.getters.activePage.slug,
        object: this.object.key,
      };
    },
    async onSubmitChildPage(page) {
      this.showChildPageModal = false;

      await this.$store.dispatch('removePage', NEW_PAGE_KEY);

      this.currentItem.scene = page.rawBasic();
    },
    childPage() {
      return {
        object: this.object?.key,
      };
    },
  },
};
</script>

<style lang="scss">
.form-emails textarea {
  min-height: 200px !important;
}
</style>
