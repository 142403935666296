<template>
  <Modal
    title="Mail Delivery History"
    size="large"
    :back="backLink"
  >
    <Modal
      v-if="showMessageModal"
      title="Message"
      size="medium"
      :z-index="2"
      @close="showMessageModal = false"
    >
      <div
        style="background-color: #eee; padding: .5em;"
        v-html="message"
      />
    </Modal>

    <p>Please allow a few minutes for recent deliveries to appear in the history.</p>

    <div id="kn-email-history-table">
      <TableWrapper>
        <template #table>
          <VTable
            ref="emailHistory"
            :columns="columns"
            :records="records"
            :show-inline-edit="false"
            :allow-sorting="false"
            :draggable="false"
          />
        </template>
      </TableWrapper>
    </div>

    <p class="instructions margin-top">
      Note: Message content not always available.
    </p>
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';
import RequestUtils from '@/components/util/RequestUtils';
import SchemaUtils from '@/store/utils/SchemaUtils';
import Field from '@/store/models/Field';
import TableWrapper from '@/components/renderer/table/TableWrapper';
import VTable from '@/components/renderer/table/Table';

export default {
  name: 'EmailHistory',
  components: {
    Modal,
    TableWrapper,
    VTable,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    backLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      records: [],
      totalRecords: 1,
      totalPages: 1,
      currentPage: 1,
      message: {},
      showMessageModal: false,
    };
  },
  computed: {
    columns() {
      // the Field model constructor expects an object to be passed in
      const objectModel = {
        key: null,
      };

      const historyColumnsDefaults = [
        {
          type: 'field',
          field: new Field({
            key: 'timestamp', type: 'short_text',
          }, objectModel),
          header: 'Date',
          sortable: false,
        },
        {
          type: 'field',
          field: new Field({
            key: 'recipients', type: 'short_text',
          }, objectModel),
          header: 'Recipients',
          sortable: false,
        },
        {
          type: 'field',
          field: new Field({
            key: 'subject', type: 'link',
          }, objectModel),
          header: 'Subject',
          sortable: false,
        },
        {
          type: 'field',
          field: new Field({
            key: 'state', type: 'short_text',
          }, objectModel),
          header: 'Status',
          sortable: false,
        },
      ];

      return historyColumnsDefaults.map((column) => ({ ...SchemaUtils.columnDefaults(), ...column }));
    },
  },
  mounted() {
    // taskKey is for email task history, viewKey is for email rule history
    const key = this.$route.params.taskKey || this.$route.params.viewKey;

    this.commitRequest({
      request: () => window.Knack.Api.getEmailDeliveryHistory(key),
      onSuccess: (response) => {
        this.records = response.results;
        this.totalRecords = response.results.length;

        // update Subject column to be links
        this.records = this.records.map((record) => ({
          ...record,
          subject: `<a class="message-modal" data-message-id="${record.id}">${record.subject}</a>`,
        }));

        // setup listeners for Email message modals
        this.setupMessageModalListeners();
      },
    });
  },
  methods: {
    onClickEmailSubject(event) {
      const messageId = event.currentTarget.getAttribute('data-message-id');

      this.commitRequest({
        request: () => window.Knack.Api.getEmailMessage(messageId),
        onSuccess: (response) => {
          this.message = response.results;
        },
      });

      this.showMessageModal = true;
    },
    async setupMessageModalListeners() {
      await this.$nextTick();

      const $messageModals = this.$refs.emailHistory.$el.querySelectorAll('.message-modal');

      for (const $modal of $messageModals) {
        $modal.addEventListener('click', this.onClickEmailSubject);
      }
    },
  },
};
</script>

<style lang="scss">
</style>
