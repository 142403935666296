<template>
  <div :data-test-id="`form-input-${localInput.label || localInput.name}`">
    <label
      v-if="showLabel && hasLabel(localInput.type)"
      class="kn-input-label group-[&:not(.kn-view)]/shared:mb-0"
    >
      <span
        class="kn-input-label group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:font-medium group-[&:not(.kn-view)]/shared:mb-2"
        v-html="localInput.label || localInput.name"
      />
      <span
        v-if="showRequired"
        class="kn-required group-[&:not(.kn-view)]/shared:text-brand inline-flex self-baseline"
      >&nbsp;*</span>
    </label>
    <FieldValueWrapper
      v-if="isReadOnly"
      :input="localInput"
      :field="field"
      :model-value="localValue"
      :display-value="displayValue"
    />
    <Component
      :is="componentType"
      v-else
      v-model="localValue"
      :input="localInput"
      :field="field"
      :form-values="formValues"
      :should-upload-on-add="shouldUploadAssetOnAdd"
      :numeric-time-format="numericTimeFormat"
      :is-in-popover="isInPopover"
      :show-hint-labels="showNameFieldHintLabels"
      @loaded="onFormInputLoaded"
    />
    <p
      v-if="error"
      class="error-msg"
    >
      {{ error.message }}
    </p>
    <p
      v-if="showInstructions"
      class="kn-instructions"
      v-html="localInput.instructions"
    />
  </div>
</template>

<script>
import AddressInput from '@/components/renderer/form/inputs/Address';
import BooleanInput from '@/components/renderer/form/inputs/Boolean';
import ConnectionInput from '@/components/renderer/form/inputs/Connection';
import DateInput from '@/components/renderer/form/inputs/Date';
import EmailInput from '@/components/renderer/form/inputs/Email';
import EquationInput from '@/components/renderer/form/inputs/Equation';
import FileInput from '@/components/renderer/form/inputs/File';
import ImageInput from '@/components/renderer/form/inputs/Image';
import LinkInput from '@/components/renderer/form/inputs/Link';
import MultipleChoiceInput from '@/components/renderer/form/inputs/MultipleChoice';
import NameInput from '@/components/renderer/form/inputs/Name';
import PasswordInput from '@/components/renderer/form/inputs/Password';
import PhoneInput from '@/components/renderer/form/inputs/Phone';
import RatingInput from '@/components/renderer/form/inputs/Rating';
import RichTextInput from '@/components/renderer/form/inputs/RichText';
import SignatureInput from '@/components/renderer/form/inputs/Signature';
import TimerInput from '@/components/renderer/form/inputs/Timer';
import TextAreaInput from '@/components/renderer/form/inputs/TextArea';
import TextInput from '@/components/renderer/form/inputs/Text';
import UserRoles from '@/components/renderer/form/inputs/UserRoles';
import CopyStatic from '@/components/renderer/form/statics/Copy';
import DividerStatic from '@/components/renderer/form/statics/Divider';
import FieldValueWrapper from '@/components/renderer/records/FieldValueWrapper';
import DateUtils from '@/components/renderer/mixins/DateUtils';

export default {
  components: {
    FieldValueWrapper,
  },
  mixins: [
    DateUtils,
  ],
  inheritAttrs: false,
  props: {
    field: {
      type: Object,
      // The divider 'input' does not have a field. Possibly others (see inputsWithNoLabels).
      default: () => null,
    },
    input: {
      type: Object,
      default: () => {},
    },
    formValues: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      default: () => '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showNameFieldHintLabels: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: null,
    },
    shouldUploadAssetOnAdd: {
      type: Boolean,
      default: false,
    },
    numericTimeFormat: {
      type: Boolean,
      default: true,
    },
    // In case we need to model any different behavior if it's in a popover
    // E.g. timepicker renders as static so if you click in it, it doesn't close the popover
    isInPopover: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'connection', // from ConnectionInput component
    'loaded',
    'update',
    'update:modelValue',
  ],
  computed: {
    componentType() {
      const componentMap = {
        address: AddressInput,
        boolean: BooleanInput,
        combo_box: MultipleChoiceInput,
        connection: ConnectionInput,
        currency: TextInput,
        copy: CopyStatic,
        date_time: DateInput,
        display: EquationInput,
        divider: DividerStatic,
        email: EmailInput,
        equation: EquationInput,
        file: FileInput,
        image: ImageInput,
        link: LinkInput,
        multiple_choice: MultipleChoiceInput,
        name: NameInput,
        password: PasswordInput,
        number: TextInput,
        paragraph_text: TextAreaInput,
        phone: PhoneInput,
        rich_text: RichTextInput,
        short_text: TextInput,
        signature: SignatureInput,
        text_field: TextInput,
        text_area: TextAreaInput,
        timer: TimerInput,
        section_break: CopyStatic,
        rating: RatingInput,
        user_roles: UserRoles,
      };

      return componentMap[this.localInput.type];
    },
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update', newValue);
        this.$emit('update:modelValue', newValue);
      },
    },

    displayValue() {
      return this.isBuilder ? '<i>read-only value</i>' : '<i>renderer value</i>';
    },
    isBuilder() {
      // TODO: update this when mode type is added
      // Eg. return (Knack.mode === `builder`)
      return true;
    },
    isReadOnly() {
      return this.localInput.read_only;
    },
    showInstructions() {
      return this.localInput.instructions;
    },
    showRequired() {
      if (this.field && this.field.get('required') && !this.localInput.read_only) {
        return true;
      }

      return false;
    },
    localInput() {
      const { type } = this.input;

      // handle `special` (Rating, Signature, Timer)
      // and `upload` (File, Image (Url)) input type previews
      if (['special', 'upload'].includes(type)) {
        return {
          ...this.input,
          type: this.field.get('type'),
        };
      }

      return {
        ...this.input,
      };
    },
  },
  mounted() {
    if (!this.focus) {
      return;
    }

    setTimeout(() => {
      // TODO: rich text needs focus
      if (this.$el.querySelector('textarea')) {
        this.$el.querySelector('textarea').focus();
      } else if (this.$el.querySelector('input')) {
        this.$el.querySelector('input').focus();
      }
    }, 200);
  },
  methods: {
    hasLabel(inputType) {
      const inputsWithNoLabels = [
        'copy',
        'section_break',
        'divider',
        'password',
      ];

      return !inputsWithNoLabels.includes(inputType);
    },
    onFormInputLoaded(event, value) {
      // Necessary to update `this.localValue` here to ensure that Connection type fields with field
      // settings indicating that the default value should be the 'First Option' work as expected.
      // `Connection.vue` emits a `loaded` event which is listened for here which we can leverage to set the updated
      // localValue after `Connection.vue` handles fetching the connection field options and
      // setting its own localValue.
      this.localValue = value;
      this.$emit('loaded', event);
    },
  },
};
</script>

<style lang="scss">
.kn-instructions {
  font-size: .96em;
  opacity: .85;
  font-style: italic;
  margin-bottom: 0;
  margin-top: .25em;
}
</style>
