<template>
  <div
    id="object-list"
  >
    <RouterView
      name="modal"
      :base-url="`/schema/list/objects/${object?.key}/fields`"
    />
    <FieldAddSettings
      v-if="showAddFieldSettings"
      :field-new="fieldNew"
      :close-on-event="true"
      @close="showAddFieldSettings = false"
      @save="onSaveNewField"
    />

    <div
      id="object-list-fields-wrapper"
      class="-mt-3"
    >
      <RecyclableSortableItems
        v-if="!noResults"
        id="object-list-fields"
        ref="fields"
        :items="filteredFields"
        parent-element="div"
        :item-size="sortableItemSize"
        class="body-padding-full"
        data-cy="object-list-fields"
        @sort="onSort"
        @sortDrag="onSortDrag"
      >
        <template #default="{item, index}">
          <div
            :id="`field-wrapper-${item.id}`"
            :key="item.key"
            data-cy="object-field"
            class="item-wrapper"
          >
            <div
              class="drop-target top"
              :data-item="index"
              @dragover="onDragOver"
              @dragenter="onDragEnter"
              @dragleave="onDragLeave"
              @drop="onDrop($event, index)"
            />
            <div
              class="drop-target bottom"
              :data-item="index + 1"
              @dragover="onDragOver"
              @dragenter="onDragEnter"
              @dragleave="onDragLeave"
              @drop="onDrop($event, index + 1)"
            />
            <ObjectListField
              :field="item"
              :object="object"
              :can-delete="canDeleteFields"
            />
          </div>
        </template>
      </RecyclableSortableItems>

      <EmptyStateGeneric
        v-else
        top
      >
        <div
          class="mb-2"
        >
          <Icon
            type="search"
            class="h-auto w-[110px] fill-[url(#svg-brand-gradient)] opacity-40"
          />
        </div>

        <h2 class="empty-state__title margin-bottom-lg text-xl text-emphasis font-medium mb-2">
          No results found
        </h2>

        <p class="empty-state__paragraph text-default mb-6">
          Try different keywords or clear the filter
        </p>
      </EmptyStateGeneric>
    </div>

    <ObjectListConnections v-if="tabStyle !== 'connections-split'" />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import {
  mapGetters,
  mapActions,
} from 'vuex';

import FieldAddSettings from '@/components/fields/FieldAddSettings';
import FieldUtils from '@/components/fields/FieldUtils';
import ObjectListField from '@/components/schema/ObjectListField';
import ObjectListConnections from '@/components/schema/ObjectListConnections';
import RecyclableSortableItems from '@/components/ui/lists/RecyclableSortableItems';
import UIUtil from '@/util/UI';
import RequestUtils from '@/components/util/RequestUtils';
import EmptyStateGeneric from '@/components/ui/EmptyStateGeneric';
import Icon from '@/components/ui/Icon';
import { eventBus } from '@/store/bus';
import { AB_TESTING_OBJECT_TABS } from '@/constants/featureflags';

// Height of list item card + 8px of spacing between elements
const SORTABLE_ITEM_SIZE = 48;
const SORTABLE_ITEM_SIZE_WITH_FIELD_KEYS = 56;

export default {
  name: 'SchemaObjectListFields',
  components: {
    FieldAddSettings,
    ObjectListField,
    ObjectListConnections,
    RecyclableSortableItems,
    EmptyStateGeneric,
    Icon,
  },
  mixins: [
    FieldUtils,
    UIUtil,
    RequestUtils,
  ],
  beforeRouteUpdate(to) {
    // prevents filters from being cleared if a new route relies on the same object fields
    // as the current route (e.g. when editing a field)
    if (this.object?.key !== to.params?.objectKey) {
      this.clearSchemaFieldFilters();
    }
  },
  props: {
    connectionType: {
      type: String,
      default: 'list',
    },
  },
  data() {
    return {
      fieldNew: {}, // used to temporarily store a new field on drag/drop
      fieldNewIndex: 0,
      showAddFieldSettings: false,
      sortableItemSize: SORTABLE_ITEM_SIZE,
    };
  },
  computed: {
    ...mapGetters('ui/filterSchemaFields', [
      'getSchemaFieldFilters',
      'getFilteredSchemaFields',
      'getSortedListFromFilters',
    ]),
    ...mapGetters('notifications', [
      'activeSocketNotification',
    ]),
    ...mapGetters('ui/filterSchemaFields', {
      fieldKeyFilteringEnabled: 'getFilteringByKeyEnabled',
    }),
    ...mapGetters([
      'getFeatureValue',
    ]),
    canDeleteFields() {
      return this.object?.fields.length > 1;
    },
    noResults() {
      return this.filteredFields.length === 0;
    },
    filteredFields() {
      if (!this.object) {
        return [];
      }

      return this.getFilteredSchemaFields(this.object.fields);
    },
    tabStyle() {
      return this.getFeatureValue(AB_TESTING_OBJECT_TABS);
    },
  },
  watch: {
    fieldKeyFilteringEnabled: {
      handler(enabled) {
        this.sortableItemSize = enabled ? SORTABLE_ITEM_SIZE_WITH_FIELD_KEYS : SORTABLE_ITEM_SIZE;
      },
      immediate: true,
    },
  },
  mounted() {
    // Scroll down to the added field on create
    eventBus.$on('field.create', this.onFieldCreate);

    // drag from available items
    eventBus.$on('dragStartFieldNew', (event) => {
      this.$el.classList.add('is-dragging-over');
    });

    eventBus.$on('dragEndFieldNew', () => {
      this.$el.classList.remove('is-dragging-over');
    });
  },
  beforeUnmount() {
    eventBus.$off('field.create', this.onFieldCreate);
  },
  methods: {
    ...mapActions('ui/filterSchemaFields', [
      'clearSchemaFieldFilters',
    ]),
    async scrollToField(fieldKey) {
      await this.$nextTick();

      const $fields = document.querySelector('#object-list-fields');

      const $field = document.querySelector(`#field-wrapper-${fieldKey}`);

      const fieldOffset = $field.getBoundingClientRect().top - $fields.getBoundingClientRect().top;

      const scrollDestination = $fields.scrollTop + fieldOffset - 40;

      if (fieldOffset < 0) {
        return;
      }

      // set up a scrollable instance
      const scrollable = new UIUtil.ScrollTo($fields);

      return scrollable.go(scrollDestination);
    },
    onSortDrag(event, sortedFields) {
      // handling sort drag when filters are applied
      if (this.filteredFields.length < this.object.fields.length) {
        sortedFields = this.getSortedListFromFilters({
          allFields: this.object.fields,
          filteredFields: sortedFields,
        });
      }

      this.object.fields = sortedFields;
    },
    onSort(event) {
      this.commitRequest({
        request: () => window.Knack.Api.updateFieldsSort(
          this.object.key,
          this.object.fields.map((field) => field.key),
        ),
        onSuccess: () => this.$store.commit('updateFieldOrder', {
          objectKey: this.object.key,
          fields: this.object.fields,
        }),
        globalLoading: false,
      });
    },
    onDragOver(event) {
      return event.preventDefault();
    },
    onDragEnter(event) {
      return event.target.classList.add('over');
    },
    onDragLeave(event) {
      return event.target.classList.remove('over');
    },
    onDrop(event, fieldIndex) {
      event.preventDefault();
      event.stopPropagation();

      const field = JSON.parse(event.dataTransfer.getData('field'), this.object.name);
      const fieldNew = this.fieldMixin_createNewField(field);

      // returns empty when field needs to go through it's own setup wizard (ie connections)
      if (isEmpty(fieldNew)) {
        return;
      }

      if (fieldNew.hasRequiredSettings) {
        this.fieldNew = fieldNew;
        this.fieldNewIndex = fieldIndex;
        this.showAddFieldSettings = true;

        return;
      }

      return this.commitFieldSave(fieldNew, fieldIndex);
    },
    onSaveNewField() {
      this.showAddFieldSettings = false;

      this.commitFieldSort(this.fieldNewIndex);
    },
    commitFieldSave(fieldNew, fieldIndex) {
      this.commitRequest({
        validate: () => fieldNew.validate(),
        request: () => fieldNew.create({}, fieldIndex),
        onSuccess: () => {

          // this.object.fields.splice(fieldIndex, 0, this.object.fields.pop())
        },
      });
    },
    commitFieldSort(fieldIndex) {
      this.object.fields.splice(fieldIndex, 0, this.object.fields.pop());

      // resort
      this.commitRequest({
        request: () => window.Knack.Api.updateFieldsSort(this.object?.key, this.object.fields.map((field) => field.key)),
        onSuccess: ({ fields }) => this.$store.commit('updateFieldOrder', {
          objectKey: this.object?.key,
          fields: this.object.fields,
        }),
        globalLoading: false,
      });
    },
    onFieldCreate(field) {
      // allow for any potential resorting to happen
      this.scrollToField(field.key);
    },
  },
};
</script>

<style lang="scss">
#object-list-fields > .vue-recycle-scroller__item-wrapper {
  display: flex;
  height: 100%;

  #object-list-add-field {
    padding-top: .75em;
  }

  .vue-recycle-scroller__item-view {
    flex: 2 0;
    //padding: 1.25em 0 1em 1.75em;
    padding-right: 0;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    max-width: 800px;

    &.draggable-mirror {
      background-color: #eaecf0;
      opacity: .7;
    }
  }
}

.vue-recycle-scroller__item-view {

  > .item-wrapper {
    padding: 0;

    &.is-dragging .field-item {
      border: 1px dashed $gray300;
    }
  }
}

#object-list-fields-wrapper .highlight .field-item {
  border: 1px solid $fuchsia;
}
</style>

<style lang="scss" scoped>
  :deep(.empty-object-list) {
    width: 400px;
    margin-top: 48px;

    button {
      display: none;
    }

    svg {
      width: 128px;
      height: auto;
    }
  }
</style>
