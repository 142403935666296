<template>
  <div id="step-match">
    <h1 class="bg-transparent text-emphasis text-xl font-medium p-6 pb-4">
      Update Records
    </h1>
    <div class="toolbox-body">
      <div class="overflow">
        <p class="is-padded padding-top px-6 pt-0 font-medium">
          Do you want to update existing <b>{{ objectName }}</b> records with this import?
        </p>
        <form
          class="is-padded px-6"
          @submit.prevent="onSubmit"
        >
          <div class="input-labels tw-input-labels">
            <label class="items-center"><input
              :checked="!updateRecords"
              type="radio"
              name="updateRecords"
              class="mt-0 mr-2"
              @input="$emit('update:updateRecords', false)"
            > No, insert each row as a new record</label>
            <label class="items-center"><input
              :checked="updateRecords"
              type="radio"
              name="updateRecords"
              class="mt-0 mr-2"
              @input="$emit('update:updateRecords', true)"
            > Yes</label>
          </div>
          <template v-if="updateRecords">
            <template v-if="matchableFields.length">
              <p class="top-border border-0 p-0 mb-2">
                Update records where the following rules match:
              </p>
              <div id="match-labels">
                <span class="p-0">Column</span><span>Knack Field</span>
              </div>
              <ActionList
                id="match-rules"
                :items="rules"
                :default-item="defaultMatchRule"
                class="is-grouped bg-transparent p-0"
                :can-be-empty="false"
                @update:items="$emit('update:rules', $event)"
              >
                <template #default="{item}">
                  <select
                    v-model="item.columnIndex"
                    class="h-10 w-40 grow-0 text-base py-2 pl-3 leading-5"
                  >
                    <option
                      v-for="(column, columnIndex) in columns"
                      :key="columnIndex"
                      :value="column.index"
                    >
                      {{ column.index + 1 }} {{ column.name }}
                    </option>
                  </select>
                  <div class="justify-center px-1.5">
                    &nbsp;<strong>=</strong>&nbsp;
                  </div>
                  <select
                    v-model="item.fieldKey"
                    class="h-10 w-auto text-base py-2 pl-3 leading-5"
                  >
                    <option
                      v-for="field in matchableFields"
                      :key="field.key"
                      :value="field.key"
                    >
                      {{ field.name }}
                    </option>
                  </select>
                </template>
              </ActionList>
              <p class="top-border font-medium mb-2 border border-solid border-x-0 border-b-0 border-t-subtle">
                What if no matching record is found to update?
              </p>
              <div class="input-labels margin-bottom-double tw-input-labels">
                <label class="items-center"><input
                  :checked="insertUnmatched"
                  type="radio"
                  name="insertUnmatched"
                  class="mt-0 mr-2"
                  @input="$emit('update:insertUnmatched', true)"
                > Insert the row as a new record</label>
                <label class="items-center"><input
                  :checked="!insertUnmatched"
                  type="radio"
                  name="insertUnmatched"
                  class="mt-0 mr-2"
                  @input="$emit('update:insertUnmatched', false)"
                > Skip the row and do nothing</label>
              </div>
            </template>
            <template v-else>
              <p>There are no matchable fields in this schema object.</p>
            </template>


          </template>
        </form>
      </div>
    </div>
    <div
      id="submit-mapping"
      class="flex justify-end border-0 px-6"
    >
      <a
        class="button gray-outline button gray-outline px-3 py-2.5 rounded-lg bg-transparent border border-solid border-default text-base leading-4 font-medium text-emphasis"
        @click="$emit('back')"
      >
        Back
      </a>
      <a
        class="button orange-fill px-3 py-2.5 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium mr-0"
        data-cy="submit-mapping"
        @click="onSubmit"
      >
        Next
      </a>
    </div>
  </div>
</template>

<script>
import ActionList from '@/components/ui/lists/ActionList';

export default {
  components: {
    ActionList,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    insertUnmatched: {
      type: Boolean,
      default: false,
    },
    updateRecords: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'back',
    'submit',
    'update:updateRecords',
    'update:insertUnmatched',
    'update:rules',
  ],
  computed: {
    objectName() {
      return this.$store.getters.activeObject.name;
    },
    matchableFields() {
      return this.$store.getters.activeObject.fields.filter((field) => field.isImportMatchable());
    },
  },
  created() {
    if (this.matchableFields.length && !this.rules.length) {
      this.$emit('update:rules', [
        {
          columnIndex: 0,
          fieldKey: this.matchableFields[0].key,
        },
      ]);
    }
  },
  methods: {
    defaultMatchRule() {
      return {
        columnIndex: 0,
        fieldKey: this.matchableFields[0].key,
      };
    },
    onSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss">
#step-match {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#match-labels {
  margin-bottom: .25em;
  display: flex;

  span {
    display: block;
    font-weight: 500;
    font-size: 1em;
    width: 50%;

    &:first-child {
      padding-left: .75em;
    }
  }
}
#match-rules {
  margin-bottom: 1.5em;

  strong {
    font-weight: 500;
    font-size: 1.23rem;
  }
}
</style>
