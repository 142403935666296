<script setup>
/* eslint-disable no-param-reassign */
import {
  onMounted, onBeforeUnmount, computed, watch, ref, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import Api from '@/lib/api-wrapper';
import checklistStrings from '@/components/checklists/checklist-strings';
import useChecklists from '@/checklists/useChecklists';

const store = useStore();
const highlightedFeatures = computed(() => store.getters['checklists/highlightedFeatures']);

const HIGHLIGHT_SHADOW_CLASS = 'shadow-[0px_0px_1px_5px_rgba(121,_192,_123,_1),0px_0px_2px_8px_rgba(206,231,200,_1)]';

const HIGHLIGHTED_ELEMENT_CLASSES = [
  HIGHLIGHT_SHADOW_CLASS,
  '[animation-duration:2s]',
  'animate-pulse',
  'relative',
  'visible',
];

const onFeatureClick = (e) => {
  const clickedElement = e.target;

  const featureElement = clickedElement.dataset.feature
    ? clickedElement
    : clickedElement.closest('[data-feature]');

  if (!featureElement) return;

  store.commit(
    'checklists/setHighlightedFeatures',
    highlightedFeatures.value.filter((f) => f !== featureElement.dataset.feature),
  );
};

const restoreFeature = (featureElement) => {
  const { feature } = featureElement.dataset;
  if (feature && !highlightedFeatures.value.includes(feature)) {
    featureElement.classList.remove(...HIGHLIGHTED_ELEMENT_CLASSES);
    featureElement.removeEventListener('click', onFeatureClick);
  }
};

const highlight = (observer) => {
  observer.disconnect(); // Disconnect the observer before making changes

  // Get all current beacons
  const currentBeacons = document.querySelectorAll('[data-type="beacon"]');

  // Remove beacons that are not in the highlightedFeatures array
  currentBeacons.forEach((beacon) => {
    const featureElement = beacon.closest('[data-feature]');

    beacon.remove();

    if (featureElement) {
      restoreFeature(featureElement);
    }
  });

  // Add new beacons for each feature in the highlightedFeatures array
  highlightedFeatures.value.forEach((feature) => {
    const elements = document.querySelectorAll(`[data-feature="${feature}"]`);
    elements.forEach((div) => {
      if (!div.querySelector('[data-type="beacon"]')) {
        const xOffset = parseInt(div.dataset.featureXOffset ?? '0', 10);
        const yOffset = parseInt(div.dataset.featureYOffset ?? '0', 10);

        const beacon = document.createElement('div');
        beacon.dataset.type = 'beacon';
        beacon.style.top = `${yOffset + 2}px`;
        beacon.style.right = `${xOffset + 2}px`;
        beacon.className = 'absolute flex items-center justify-center translate-x-1/2 -translate-y-1/2 w-12 h-12 overflow-hidden';
        beacon.innerHTML = `
              <div class="animate-ping absolute inline-flex w-6 h-6 rounded-full bg-green-400"></div>
              <div class="relative inline-flex rounded-full w-6 h-6 bg-green-400 items-center justify-center shadow-[0px_0px_1px_3px_rgba(213,231,200,1)] z-10">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" class="icon icon-bulb text-inherit w-4 h-4 mr-0">
                  <path d="M8 0.5C4.96243 0.5 2.5 2.96243 2.5 6C2.5 8.03384 3.60421 9.80921 5.24344 10.7603C5.70041 11.0254 5.98657 11.4323 5.99952 11.8417C6.00666 12.0675 6.16438 12.2605 6.38427 12.3125C6.61852 12.3679 6.85723 12.4114 7.09973 12.4426C7.31546 12.4703 7.50012 12.2975 7.50012 12.08V8.97263C7.28727 8.94908 7.07869 8.91066 6.87549 8.85836C6.60807 8.78953 6.44707 8.51694 6.5159 8.24952C6.58473 7.98209 6.85732 7.8211 7.12475 7.88993C7.40396 7.96179 7.69717 8.00012 8.00012 8.00012C8.30307 8.00012 8.59628 7.96179 8.87549 7.88993C9.14292 7.8211 9.41551 7.98209 9.48434 8.24952C9.55317 8.51694 9.39218 8.78953 9.12475 8.85836C8.92156 8.91066 8.71297 8.94908 8.50012 8.97263V12.08C8.50012 12.2975 8.68479 12.4703 8.90053 12.4426C9.14293 12.4114 9.38156 12.3678 9.61573 12.3125C9.83563 12.2605 9.99334 12.0675 10.0005 11.8417C10.0134 11.4323 10.2996 11.0254 10.7566 10.7603C12.3958 9.80921 13.5 8.03384 13.5 6C13.5 2.96243 11.0376 0.5 8 0.5Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00876 13.2664C6.06022 12.9951 6.32188 12.8169 6.59318 12.8684C7.0485 12.9548 7.51873 13 8 13C8.48127 13 8.95151 12.9548 9.40682 12.8684C9.67812 12.8169 9.93978 12.9951 9.99124 13.2664C10.0427 13.5377 9.86449 13.7994 9.59319 13.8509C9.07675 13.9488 8.54412 14 8 14C7.45588 14 6.92326 13.9488 6.40682 13.8509C6.13551 13.7994 5.9573 13.5377 6.00876 13.2664Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.50271 14.896C6.53144 14.6213 6.77738 14.422 7.05202 14.4507C7.36341 14.4833 7.67967 14.5 8 14.5C8.32033 14.5 8.63659 14.4833 8.94798 14.4507C9.22262 14.422 9.46856 14.6213 9.49729 14.896C9.52602 15.1706 9.32667 15.4166 9.05202 15.4453C8.70613 15.4815 8.35515 15.5 8 15.5C7.64485 15.5 7.29387 15.4815 6.94798 15.4453C6.67333 15.4166 6.47398 15.1706 6.50271 14.896Z" fill="white"/>
                </svg>
              </div>
            `;
        div.appendChild(beacon);
        div.classList.add(...HIGHLIGHTED_ELEMENT_CLASSES);
        div.addEventListener('click', onFeatureClick);
      }
    });
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  }); // Reconnect the observer after making changes
};

// We do this because the api-wrapper interceptors return directly
// the data property and we have to watch the headers.
function addFirstInterceptor(instance, interceptor) {
  const interceptors = instance.interceptors.response.handlers.slice();

  instance.interceptors.response.handlers = [];
  const id = instance.interceptors.response.use(interceptor);

  interceptors.filter((int) => !!int).forEach(({ fulfilled, rejected }) => {
    instance.interceptors.response.use(fulfilled, rejected);
  });

  return id;
}

const observer = ref(new MutationObserver(() => highlight(observer.value)));

const { totalCompleted, totalCount } = useChecklists();

onMounted(() => {
  // Observe for changes in the DOM
  observer.value.observe(document.body, {
    childList: true,
    subtree: true,
  });

  // Initial addition
  highlight(observer.value);

  const interceptorId = addFirstInterceptor(Api.axios, (response) => {
    if (response.headers?.['knack-checklist-step']) {
      store.dispatch('checklists/fetchSteps');

      response.headers?.['knack-checklist-step'].split(',').forEach((step) => {
        let message = `🎉 You've completed the onboarding task ${checklistStrings.steps[step]}.`;
        let bold = 'See your next task';

        if (step === 'create_account') {
          message = "🎉 Congratulations, you've created an account with Knack!";
          bold = 'See what\'s next in your onboarding guide';
        } else if (totalCompleted.value === totalCount.value - 1) {
          message = `🎉 You've completed the onboarding task ${checklistStrings.steps[step]}`;
          bold = undefined;
        }

        store.dispatch('toasts/addToast', {
          message,
          bold,
          type: 'success',
          onClick: () => {
            store.commit('checklists/setPopoverOpen', true);
          },
          timeout: 8000,
        });
      });
    }

    return response;
  });

  onBeforeUnmount(() => {
    observer.value.disconnect();

    Api.axios.interceptors.response.eject(interceptorId);
  });
});

watch(highlightedFeatures, async () => {
  highlight(observer.value);
});

</script>

<template>
  <div />
</template>
