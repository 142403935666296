<template>
  <Modal
    title="Edit Record"
    :back="backLink"
    prevent-background-click-to-close
  >
    <Form
      v-if="isLoaded"
      :inputs="inputs"
      :values="formValues"
      :errors="fieldErrors"
      @submit-form="onSubmitForm"
    />
  </Modal>
</template>

<script>
import get from 'lodash/get';
import FieldUtils from '@/components/fields/FieldUtils';
import RecordModel from '@/store/models/Record';
import RecordsUtils from '@/components/records/RecordsUtils';
import RequestUtils from '@/components/util/RequestUtils';
import Form from '@/components/renderer/form/FormFields';
import Modal from '@/components/ui/Modal';
import { getAssetsFromValues } from '@/util/AssetsHelper';
import { eventBus } from '@/store/bus';

export default {
  name: 'RecordEdit',
  components: {
    Form,
    Modal,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
    RecordsUtils,
  ],
  data() {
    return {
      formValues: {},
      isLoaded: false,
    };
  },
  created() {
    // Fetch the active record if we don't have it in the loaded records
    this.commitRequest({
      request: () => window.Knack.Api.getRecord(this.objectKey, this.recordId, 'raw'),
      onSuccess: (values) => {
        const Record = new RecordModel(values, this.objectKey);

        this.formValues = Record.getValuesForInputs();

        this.isLoaded = true;
      },
    });
  },
  methods: {
    onSubmitForm(recordValues, formErrors) {
      if (formErrors) return;

      // Grab the assets for any newly uploaded images or files
      const assets = getAssetsFromValues(this.inputs, this.formValues);

      // If a password input exists, only send its value if it has changed
      const passwordInput = this.inputs.find(({ type }) => type === 'password');

      if (passwordInput) {
        // Only send a new password value if it has changed
        if (get(this, `formValues.${passwordInput.field.key}.hasChanged`) !== true) {
          delete recordValues[passwordInput.field.key];

          // However, we still end up with the property on the record values
          // for some reason. Convert it to a plain object to be sure.
          recordValues = JSON.parse(JSON.stringify(recordValues));
        }
      }

      log('onSubmitForm', recordValues);

      this.commitRequest({
        request: () => window.Knack.Api.updateRecord(this.objectKey, this.recordId, recordValues, assets),
        onSuccess: (record) => {
          eventBus.$emit('recordUpdate', record);

          this.$router.push(this.backLink);
        },
      });
    },
  },
};
</script>
