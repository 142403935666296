<template>
  <div
    v-if="filterMenuLinks.length"
    class="js-filter-menu tabs is-toggle is-flush"
  >
    <ul>
      <li
        v-for="(filter, filterIndex) in filterMenuLinks"
        :key="filterIndex"
        :class="(filterIndex === 0) ? `is-active` : ``"
      >
        <a>{{ filter.text || `&nbsp;` }}</a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    filterMenuLinks: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.js-filter-menu.tabs {

  margin-bottom: .75em;

  ul {
    display: flex;
  }

  &.is-toggle {
    li:last-child a {
      border-radius: 0 .35em .35em 0;
    }

    li.is-active a {
      z-index: 1;
      background-color: #ddd;
      box-shadow: inset 0 -2.5px hsla(0,0%,100%,.5);
    }

    li:first-child a {
      border-radius: .35em 0 0 .35em;
    }

    li:only-child a {
      border-radius: .35em;
    }

    li+li {
      margin-left: -1px;
    }
  }

  li:not(.is-active) a {
      box-shadow: inset 0 -2.5px rgba(0,0,0,.1);
  }
  a {
    align-items: center;
    border-bottom: 1px solid #dbdbdb;
    color: #4a4a4a;
    display: flex;
    justify-content: center;
    padding: 6px 12px;
    vertical-align: top;
    text-decoration: none;
  }
  &.is-toggle a {
      border: 1px solid #dbdbdb;
      margin-bottom: 0;
      padding-bottom: 5px;
      padding-top: 5px;
      position: relative;
  }
}
</style>
