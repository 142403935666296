<template>
  <div>
    <VueFlatPicker
      :key="pickerKey"
      v-model="localTime"
      :config="compiledConfig"
      class="flatpickr-input h-10 rounded-lg py-2 px-3 placeholder:text-subtle focus:shadow-none focus:border-emphasis focus:border-opacity-100 focus:border border-solid border-default w-full"
      :class="[
        {'flatpickr-input--is-disabled': isDisabled},
        inputClasses,
      ]"
      :placeholder="placeholder"
      :disabled="isDisabled"
    />
  </div>
</template>

<script>
import isNil from 'lodash/isNil';
import VueFlatPicker from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

const convertTime12to24 = (time12h) => {
  const time = time12h.slice(0, -2).split(':');
  const modifier = time12h.slice(-2);
  const minutes = time[1];
  let hours = time[0];

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

export default {
  name: 'TimePicker',
  components: {
    VueFlatPicker,
  },
  props: {
    format: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    modelValue: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    minuteInterval: {
      default: 5,
      validator: (prop) => !Number.isNaN(prop),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // Renders as static so if you click in it, it doesn't close the popover
    isInPopover: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    inputClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      pickerKey: 0,
      localTime: '',
    };
  },
  computed: {
    militaryTime() {
      return this.format === 'HH:mm';
    },
    compiledConfig() {
      return {
        allowInput: true,
        noCalendar: true,
        enableTime: true,
        clickOpens: true,
        static: this.isInPopover, // this prevents timePicker from closing the popover
        dateFormat: this.format === 'HH:mm' ? 'H:i' : 'h:iK',
        time_24hr: this.militaryTime,
        onClose: this.onPickerClose,
        onChange: this.onChange,
        minuteIncrement: this.minuteInterval,
      };
    },
  },
  watch: {
    militaryTime(newVal, oldVal) {
      // If moving from 12 to 24 hour convert time manually
      // library fails to convert 2:00pm correctly
      if (!oldVal && newVal) {
        this.localTime = convertTime12to24(this.localTime);
      }

      // Increase key to force re-render of picker
      this.pickerKey += 1;
    },
    value(newVal) {
      if (newVal !== this.localTime) {
        this.localTime = newVal;
      }
    },
    isDisabled(newVal) {
      // VueFlatPicker creates a copy of itself when in mobile view which causes a de-sync with the 'any time' button
      // We're using this to sync the 'disabled' property of the mobile and desktop timepickers
      const $mobileTimePicker = this.$el.querySelector('.flatpickr-mobile');

      if ($mobileTimePicker) {
        if (newVal === true) {
          $mobileTimePicker.disabled = true;
          $mobileTimePicker.classList.add('flatpickr-input--is-disabled');
        } else {
          $mobileTimePicker.disabled = false;
          $mobileTimePicker.classList.remove('flatpickr-input--is-disabled');
        }
      }
    }
  },
  created() {
    this.localTime = this.modelValue;
  },
  methods: {
    normalizeTimeString(timeString) {
      // Trim spaces if any exist between time and am/pm, ensure am/pm are uppercase
      return timeString.split(' ').join('').toUpperCase();
    },
    syncTimeToParent(time) {
      // Emit value on events intentionally fired so only complete entries are evaluated
      // emitting as you type causes the library to evaluate incomplete times as invalid
      // and reset the value to 12:00pm
      if (isNil(this.modelValue) || time !== this.normalizeTimeString(this.modelValue)) {
        this.$emit('update:modelValue', time);
      }
    },
    onPickerClose(selectedDates, dateStr, { input: { value }, parseDate, setDate }) {
      // During Flatpickr's onClose event, we want to ensure that if the user typed the time into the field,
      // the picker is updated to reflect this value if they click back into the field to edit
      setDate(parseDate(value));

      // We also want to sync the value to the parent
      this.syncTimeToParent(dateStr);
    },
    onChange(selectedDates, timeString) {
      log('onChange!!');

      this.syncTimeToParent(timeString);
    },
    onBlur(timeString) {
      log('on BLUR~!!!!!');

      const normalizedTime = this.normalizeTimeString(timeString);

      this.syncTimeToParent(normalizedTime);
    },
  },
};
</script>

<style lang="scss">
#kn-table-cell-popover .flatpickr-time .numInputWrapper {
  margin-top: 0 !important;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: solid white 1px !important;
}

.flatpickr-input--is-disabled {
  opacity: 0.35;
}
</style>
