<template>
  <VDropdown
    ref="popper"
    container="body"
    :shown="shown"
    :auto-hide="autoHide"
    :boundary="boundary"
    :delay="delay"
    :handle-resize="handleResize"
    :placement="placement"
    :triggers="triggers"
    :offset="offset"
    :class="popoverClasses"
    @show="onShow"
    @apply-show="onApplyShow"
    @hide="onHide"
    @update:shown="onUpdateShown"
  >
    <slot name="trigger" />
    <template #popper>
      <div
        class="kn-popover p-2 rounded shadow-md"
        :class="[{ 'kn-popover--vertical-scroll': allowVerticalScroll }, ...contentWrapperClasses]"
      >
        <slot
          v-if="isShowing"
          name="content"
        />
      </div>
    </template>
  </VDropdown>
</template>

<script>
import get from 'lodash/get';

export default {
  inheritAttrs: false,
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    autoHide: {
      type: Boolean,
      default: true,
    },
    manualAutoHide: {
      type: Boolean,
      default: false,
    },
    boundary: {
      type: null,
      default: 'body',
    },
    popoverClass: {
      type: String,
      default: 'kn-popover',
    },
    popoverBaseClass: {
      type: String,
      default: 'kn-popover',
    },
    popoverArrowClass: {
      type: String,
      default: 'tooltip-arrow popover-arrow',
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    // allow popper.js to flip placement of popover over its axis
    // on detecting overflow (e.g. from left to right, from top to bottom, etc.)
    flipPlacement: {
      type: Boolean,
      default: true,
    },
    trigger: {
      type: String,
      default: 'click enter',
    },
    // In Popper this is called the Distance value.
    distanceOffset: {
      type: Number,
      default: 0,
    },
    // In Popper this is called the Skidding value.
    windowOffset: {
      type: Number,
      default: 0,
    },
    // The left offset to set for the Popper arrow.
    arrowOffset: {
      type: Number,
      default: 0,
    },
    allowVerticalScroll: {
      type: Boolean,
      default: false,
    },
    popoverClasses: {
      type: Array,
      default: () => [],
    },
    contentWrapperClasses: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'hide',
    'show',
  ],
  data() {
    return {
      handleResize: true,
      // Delays on hide are not supported right now, don't set hide above 0.
      // This is because the apply-hide event is not functioning correctly.
      delay: {
        show: 0,
        hide: 0,
      },
      // Whether or not to show the popover.
      shown: false,
      // Whether or not the popover is currently showing.
      isShowing: false,
    };
  },
  computed: {
    triggers() {
      return this.trigger.split(' ');
    },
    offset() {
      // Generates the offset.
      // @see https://v-tooltip.netlify.app/guide/component.html#offset.
      return [this.windowOffset, this.distanceOffset];
    },
  },
  created() {
    if (this.open) {
      this.show();
    }
  },
  methods: {
    /**
     * Closes/hides the popover.
     */
    hide() {
      // This will force the popover the close.
      this.shown = false;

      // Fire the hide even when we intend to hide the popover, not when it is actually hidden.
      this.$emit('hide');
    },

    /**
     * Opens/shows the popover.
     */
    show() {
      // This will force the popover the open.
      this.shown = true;

      // Fire the hide even when we intend to show the popover, not when it is actually hidden.
      this.$emit('show');
    },

    /**
     * If the popover's trigger is pressed, it will internally change its shown state
     * and fire this event.
     *
     * This usually fires twice as the popover shown and our shown props sync.
     *
     * @param {boolean} newShown
     */
    onUpdateShown(newShown) {
      // Make sure we don't duplicate the show and hide calls if we are already in a good state.
      if (this.shown === newShown) {
        return;
      }

      if (newShown) {
        this.show();
      } else {
        this.hide();
      }
    },

    /**
     * Fires after the popover finishes showing and is visible and ready.
     *
     * @returns {Promise<void>}
     */
    async onApplyShow() {
      // Give the resizing flow a bit to finish so it won't override the arrow offset.
      await this.$nextTick();

      const popperContent = get(this, '$refs.popper.$refs.popperContent');
      if (!popperContent) {
        return;
      }

      if (this.arrowOffset) {
        const arrow = get(popperContent, '$refs.arrow');
        if (arrow) {
          arrow.style.setProperty('left', `${this.arrowOffset}px`);
        }
      }

      const popoverId = get(popperContent, 'popperId');
      const firstSelectable = document.querySelector(`#${popoverId} a, #${popoverId} button`);
      if (firstSelectable) {
        firstSelectable.focus();
      }
    },

    /**
     * Fires when the popover is in the process of showing itself.
     */
    onShow() {
      this.isShowing = true;

      this.hideArrow();

      const foundClickPreventer = document.querySelector('.click-preventer');
      if (foundClickPreventer) {
        return;
      }

      const div = document.createElement('div');
      div.className = 'click-preventer';

      const clickPreventer = document.body.appendChild(div);

      // If we're turning off auto-hide it's due to the content
      // inside the popover erroneously triggering a 'close'
      // event on the popover, this prevents the plugin from
      // closing itself on outside clicks so we need to manually
      // wire it up ourselves
      if (this.manualAutoHide && !this.autoHide) {
        clickPreventer.addEventListener('click', this.hide);
      }
    },

    /**
     * Fires when the popover is in the process of hiding itself.
     */
    onHide() {
      this.isShowing = false;

      let clickPreventer = document.querySelector('.click-preventer');

      if (clickPreventer) {
        // clean up after ourselves, has no effect if the
        // listener has not already been registered
        clickPreventer.removeEventListener('click', this.hide);

        document.body.removeChild(clickPreventer);

        clickPreventer = null;
      }
    },

    /**
     * Hides the arrow on initial render.
     */
    hideArrow() {
      const popperContent = get(this, '$refs.popper.$refs.popperContent');
      if (!popperContent) {
        return;
      }

      const arrow = get(popperContent, '$refs.arrow');
      if (arrow) {
        arrow.style.setProperty('display', 'none');
      }
    },
  },
};
</script>

<style lang="scss">
.v-popper--theme-dropdown .v-popper__inner {
  padding: 0;
  box-shadow: 0 0 0 1px rgba(136, 152, 170, 0.2),
    0 2px 7px 0 rgba(49, 49, 93, 0.1),
    0 1px 9px 0 rgba(0, 0, 0, 0.08);
  max-width: 600px;
}

.v-popper--theme-dropdown .v-popper__arrow {
  filter: drop-shadow(1px 0 0 rgba(136,152,170,.4)) drop-shadow(-1px -1px 0 rgba(136,152,170,.4));
}
</style>
