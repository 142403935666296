import TOC from '@/components/views/list/TOC';
import Build from '@/components/views/list/Build';
import ItemProperties from '@/components/views/list/ItemProperties';
import Settings from '@/components/views/list/Settings';
import Design from '@/components/views/list/Design';
import Source from '@/components/views/list/Source';

export default [
  {
    path: ':pageKey/views/:viewKey/list',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/list/items',
    components: {
      toolbox: Build,
    },
  },
  {
    path: ':pageKey/views/:viewKey/list/items/layouts/:layoutIndex/groups/:groupIndex/columns/:columnIndex/items/:itemIndex',
    name: 'listInputProperties',
    components: {
      toolbox: ItemProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/list/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/list/design',
    components: {
      toolbox: Design,
    },
  },
  {
    path: ':pageKey/views/:viewKey/list/source',
    components: {
      toolbox: Source,
    },
  },
];
