<template>
  <div class="kn-search-wrapper">
    <form
      :class="formClass"
      class="kn-searchInputs-wrapper margin-bottom-double"
    >
      <!-- EMPTY -->
      <template v-if="searchFieldsAreEmpty">
        <div class="margin-bottom-double">
          <EmptyFieldsDropZone
            class="drop-target"
            :class="{'is-dragging-over': isDraggingSearchField}"
            buildable="search"
            :build-path="addSearchFieldPath"
            :is-dragging-field="isDraggingSearchField"
            @dragover="onDragOver"
            @dragenter="onDragEnter"
            @dragleave="onDragLeave"
            @drop="onDrop"
          />
        </div>
      </template>

      <template v-else>
        <ViewUtilityDropdown
          fields-location="search"
          class="static block"
          style="position: absolute; top: 0; right: 0;"
        />

        <template
          v-for="(group, index) in view.get('groups')"
          :key="index"
        >
          <!-- NEW GROUP SLOT -->
          <template v-if="group.columns.length > 1 && (index === 0 || (index > 0 && view.get('groups')[index-1].columns.length > 1))">
            <div
              class="kn-form-group slot mt-2.5"
              data-type="slot"
            >
              <div
                class="kn-form-group-column slot draggable-inputs"
                data-type="slot"
              >
                <div class="drop-wrapper horizontal">
                  <div
                    class="drop-target horizontal"
                    @dragover="onDragOver"
                    @dragenter="onDragEnter"
                    @dragleave="onDragLeave"
                    @drop="onDrop"
                  >
                    <div class="line" />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div
            class="kn-form-group mt-2.5"
            data-type="items"
            :data-group="index"
          >
            <template
              v-for="(column, c_index) in group.columns"
              :key="`search-columns-${index}-${c_index}`"
            >
              <TransitionGroup
                name="column-list"
                tag="div"
                class="kn-form-group-column draggable-inputs"
                data-type="items"
                :data-column="c_index"
              >
                <div
                  v-if="group.columns.length < 3"
                  key="left-slot"
                  class="drop-wrapper vertical left"
                >
                  <div
                    class="drop-target vertical"
                    @dragover="onDragOver"
                    @dragenter="onDragEnter"
                    @dragleave="onDragLeave"
                    @drop="onDrop"
                  >
                    <div class="line" />
                  </div>
                </div>
                <div
                  v-if="group.columns.length < 3 && group.columns.length-1 === c_index"
                  key="right-slot"
                  class="drop-wrapper vertical right"
                >
                  <div
                    class="drop-target vertical"
                    @dragover="onDragOver"
                    @dragenter="onDragEnter"
                    @dragleave="onDragLeave"
                    @drop="onDrop"
                  >
                    <div class="line" />
                  </div>
                </div>

                <!-- Form Input -->
                <div
                  v-for="(input, i_index) in column.fields"
                  :key="i_index"
                >
                  <RouterLink
                    v-slot="{ isActive, navigate }"
                    :to="`/${routePrefix}/rows/${index}/columns/${c_index}/inputs/${i_index}`"
                    custom
                  >
                    <div
                      class="item-wrapper"
                      :class="{'router-link-active': isActive}"
                      :data-group="index"
                      :data-column="c_index"
                      :data-item="i_index"
                      draggable="true"
                      @click="navigate"
                      @dragstart="onDragStart"
                      @dragend="onDragEnd"
                    >
                      <div
                        class="drop-target top"
                        @dragover="onDragOver"
                        @dragenter="onDragEnter"
                        @dragleave="onDragLeave"
                        @drop="onDrop"
                      />
                      <div class="kn-item">
                        <label :class="labelFormat">
                          {{ input.name }}&nbsp;<span
                            v-if="input.required"
                            class="required"
                          >
                            *
                          </span>
                        </label>

                        <Copy
                          v-if="input.type && input.type === 'copy'"
                          :input="input"
                        />

                        <Divider
                          v-else-if="input.type && input.type === 'divider'"
                          :input="input"
                        />

                        <p
                          v-else-if="input.field && input.field === 'section_break'"
                          class="section-break"
                        >
                          {{ input.copy }}
                        </p>

                        <input
                          v-else-if="input.field && input.field === 'keyword_search'"
                          class="preview-input"
                        >

                        <FieldListItem
                          v-else
                          :item="input"
                          :set-input-format="setFieldListItemFormat"
                          :object-key="objectKey"
                          :show-field="false"
                          :show-operator="(input.ignore_operators === false)"
                          :show-is-any="true"
                          :use-simplified-inputs="false"
                          @update:item="onUpdateFieldListItem($event, index, c_index, i_index)"
                        />

                        <p
                          v-if="input.instructions"
                          class="kn-instructions"
                          v-html="input.instructions"
                        />

                        <div
                          v-tippy
                          content="Click to edit, drag to move"
                          class="overlay"
                          :data-cy="`Activate Search Field ${input.name}`"
                          @click="onActivateInput"
                        />
                        <div class="item-links gap-1 bg-subtle rounded-t-lg border-b-0 p-1">
                          <a
                            v-tippy
                            content="Edit this form input"
                            class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
                          >
                            <Icon
                              class="text-default"
                              type="edit"
                            />
                          </a>
                          <a
                            v-tippy
                            content="Delete this form input"
                            class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
                            @click="onDeleteInput($event, index, c_index, i_index)"
                          >
                            <Icon
                              class="text-default"
                              type="delete"
                            />
                          </a>
                        </div>
                      </div>

                      <div
                        class="drop-target bottom"
                        @dragover="onDragOver"
                        @dragenter="onDragEnter"
                        @dragleave="onDragLeave"
                        @drop="onDrop"
                      />
                    </div>
                  </RouterLink>
                </div>
              </TransitionGroup>
            </template>
          </div>

          <!-- LAST NEW GROUP SLOT -->
          <template v-if="index === view.get('groups').length-1 && group.columns.length > 1">
            <div
              :key="`new-group-slot-last-${index}`"
              class="kn-form-group slot mt-2.5"
              data-type="slot"
            >
              <div
                class="kn-form-group-column slot draggable-inputs"
                data-type="slot"
              >
                <div class="drop-wrapper">
                  <div
                    class="drop-target horizontal"
                    @dragover="onDragOver"
                    @dragenter="onDragEnter"
                    @dragleave="onDragLeave"
                    @drop="onDrop"
                  >
                    <div class="line" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </template>

      <!-- SUBMIT -->
      <div
        v-if="!searchFieldsAreEmpty"
        class="kn-submit"
      >
        <button
          class="kn-button is-primary"
          type="submit"
          style="pointer-events: none;"
        >
          {{ trans('Search') }}
        </button>
      </div>
    </form>

    <SearchResultsTable
      v-if="view.data && isTableResults"
      :view="view"
    />

    <SearchResultsList
      v-else
      :view="view"
    />
  </div>
</template>

<script>
import Copy from '@/components/renderer/form/statics/Copy';
import Divider from '@/components/renderer/form/statics/Divider';
import DragDropUtils from '@/components/renderer/mixins/DragDropUtils';
import EmptyFieldsDropZone from '@/components/renderer/shared/EmptyFieldsDropZone';
import FieldListItem from '@/components/ui/lists/FieldListItem';
import Icon from '@/components/ui/Icon';
import SearchResultsList from '@/components/renderer/search/SearchResultsList';
import SearchResultsTable from '@/components/renderer/search/SearchResultsTable';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';
import ViewUtilityDropdown from '@/components/renderer/shared/ViewUtilityDropdown';
import { eventBus } from '@/store/bus';
import SchemaUtils from '@/store/utils/SchemaUtils';

export default {
  components: {
    Copy,
    Divider,
    EmptyFieldsDropZone,
    FieldListItem,
    Icon,
    SearchResultsList,
    SearchResultsTable,
    ViewUtilityDropdown,
  },
  mixins: [
    DragDropUtils,
    TranslationUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeItemSlot: null,
      viewDropArea: 'form', // used to define eligible drop areas for adding items (as opposed to search results)
    };
  },
  computed: {
    routePrefix() {
      return `pages/${this.$route.params.pageKey}/views/${this.view.key}/${this.view.type}`;
    },

    formClass() {
      const classes = {};

      // classes[this.view.get('options').size.value + '-inputs'] = true
      // classes[this.view.get('options').spacing.value + '-spacing'] = true

      return classes;
    },

    objectKey() {
      return this.view.source.object;
    },

    isTableResults() {
      const tableColumn = this.view.get('results').columns[0] || {}; // possible table could be empty

      // Kludge?: this second conditional ensures that changing settings has time to update the result schema between list and table. This will then return a different value when those result changes are commited.
      return this.view.get('results_type') === 'table' && !tableColumn.groups;
    },

    isSearchResults() {
      const listLayout = this.view.get('results').columns[0] || {}; // possible table could be empty

      // Kludge?: this second conditional ensures that changing settings has time to update the result schema between list and table. This will then return a different value when those result changes are commited.
      return this.view.get('results_type') === 'list' && listLayout.groups;
    },

    labelFormat() {
      return this.view.get('label_format');
    },

    searchFieldsAreEmpty() {
      if (this.view.get('groups').length === 0) {
        return true;
      }

      if (this.view.get('groups')[0].columns.length === 0) {
        return true;
      }

      if (this.view.get('groups')[0].columns[0].fields.length === 0) {
        return true;
      }

      return false;
    },

    isDraggingSearchField() {
      return this.$store.getters.isDraggingViewItem(this.view.key);
    },

    addSearchFieldPath() {
      return `${this.routePrefix}/inputs`;
    },
  },
  mounted() {
    // Bind to add new item
    eventBus.$on(`addViewItem.${this.view.key}.form`, ({ item }) => {
      const input = { ...SchemaUtils.searchDefaults(), ...item };

      const searchGroups = this.view.get('groups');

      if (!searchGroups.length) {
        searchGroups.push({
          columns: [{
            fields: [input],
          }],
        });
        return;
      }

      // Add to last group
      const lastGroupIndex = searchGroups.length - 1;
      const lastColumnIndex = searchGroups[lastGroupIndex].columns.length - 1;

      searchGroups[lastGroupIndex].columns[lastColumnIndex].fields.push(input);
    });
  },
  beforeUnmount() {
    eventBus.$off(`addViewItem.${this.view.key}.form`);
  },
  methods: {
    setFieldListItemFormat(item, field) {
      return field.setFormatForSearchInput(item);
    },
    onDropViewItem(dropEvent) {
      log('onDropSearchInput');

      dropEvent.preventDefault();
      dropEvent.stopPropagation();

      const oldGroupIndex = Number(dropEvent.dataTransfer.getData('group'));
      const oldColumnIndex = Number(dropEvent.dataTransfer.getData('column'));
      const oldItemIndex = Number(dropEvent.dataTransfer.getData('item'));
      const newInput = dropEvent.dataTransfer.getData('new');
      const $parent = dropEvent.target.parentElement;
      let itemIndex = Number($parent.getAttribute('data-item'));

      if (dropEvent.target.classList.contains('bottom')) {
        itemIndex++;
      }

      const $col = $parent.closest('.kn-form-group-column');
      const $group = $col?.closest('.kn-form-group');

      log('$col', $col, '$group', $group);

      let groupItemIndex = 0;
      let colIndex = 0;
      let colItemIndex = 0;

      // If there is no column or group (view.groups is empty) then don't try to find their
      // elements, we know the indexes will all be zero.
      if ($col) {
        groupItemIndex = ($group) ? Number($group.getAttribute('data-group')) : 0;
        colIndex = Number($col.getAttribute('data-column'));
        colItemIndex = this.getElementIndex($col, 'items');
      }

      let oldInputs;
      let input;

      // set the input to add (could be from an insert or drag)
      if (!newInput) {
        input = this.view.attributes.groups[oldGroupIndex].columns[oldColumnIndex].fields.splice(oldItemIndex, 1)[0];

        // extract inputs from old column
        oldInputs = this.view.attributes.groups[oldGroupIndex].columns[oldColumnIndex].fields.splice(0);
      } else {
        // input was stringified from add menu, so parse and combine with defaults
        input = { ...SchemaUtils.searchDefaults(), ...JSON.parse(newInput) };
      }

      log('groupItemIndex', groupItemIndex, 'colIndex', colIndex, 'colItemIndex', colItemIndex, 'itemIndex', itemIndex, 'oldGroupIndex', oldGroupIndex, 'oldColumnIndex', oldColumnIndex, 'oldItemIndex', oldItemIndex);

      // empty
      if (this.view.get('groups').length === 0) {
        // add new group
        this.view.attributes.groups.splice(groupItemIndex, 0, {
          columns: [
            {
              fields: [
                input,
              ],
            },
          ],
        });

        return this.resetDragDrop();
      }

      // insert a new group
      if ($group?.getAttribute('data-type') === 'slot') {
        // add old inputs back
        if (oldInputs) {
          this.view.get('groups')[oldGroupIndex].columns[oldColumnIndex].fields = oldInputs;
        }

        // add new group
        this.view.attributes.groups.splice(groupItemIndex, 0, {
          columns: [
            {
              fields: [
                input,
              ],
            },
          ],
        });

        return this.resetDragDrop();
      }

      // new column
      if (event.target.classList.contains('vertical')) {
        if ($parent.classList.contains('right')) {
          colItemIndex++;
        }

        // add old inputs back
        if (oldInputs) {
          this.view.attributes.groups[oldGroupIndex].columns[oldColumnIndex].fields = oldInputs;
        }

        // insert new column at beginning
        if (colItemIndex === 0) {
          this.view.attributes.groups[groupItemIndex].columns.unshift({
            fields: [
              input,
            ],
          });

          // insert new column at end
        } else if (colItemIndex + 1 > this.view.attributes.groups[groupItemIndex].columns.length) {
          this.view.attributes.groups[groupItemIndex].columns.push({
            fields: [
              input,
            ],
          });

          // insert new middle column
        } else {
          this.view.attributes.groups[groupItemIndex].columns.splice(1, 0, {
            fields: [
              input,
            ],
          });
        }

        return this.resetDragDrop();
      }

      // moving within the same column
      if (!newInput && oldGroupIndex === groupItemIndex && oldColumnIndex === colItemIndex) {
        if (oldItemIndex < itemIndex) {
          itemIndex--;
        }

        // extract inputs from column and insert active input
        if (oldInputs) {
          oldInputs.splice(itemIndex, 0, input);
          this.view.attributes.groups[oldGroupIndex].columns[oldColumnIndex].fields = oldInputs;
        }

        return this.resetDragDrop();
      }

      // existing column

      // extract inputs from new column and insert active input
      const newInputs = this.view.attributes.groups[groupItemIndex].columns[colItemIndex].fields.splice(0);

      newInputs.splice(itemIndex, 0, input);

      // add old inputs back
      if (oldInputs) {
        this.view.attributes.groups[oldGroupIndex].columns[oldColumnIndex].fields = oldInputs;
      }

      this.view.attributes.groups[groupItemIndex].columns[colItemIndex].fields = newInputs;

      return this.resetDragDrop();
    },

    routeToInputs() {
      return this.$router.push(`/pages/${this.$route.params.pageKey}/views/${this.$route.params.viewKey}/search/inputs`);
    },

    onActivateInput(activateInputEvent) {
      return activateInputEvent.target.nextElementSibling.querySelector('a').click();
    },

    onDeleteInput(event, groupIndex, columnIndex, itemIndex) {
      log(`onDeleteInput, groupIndex: ${groupIndex} columnIndex: ${columnIndex} itemIndex: ${itemIndex}`);
      log(this.view.get('groups'));

      this.view.get('groups')[groupIndex].columns[columnIndex].fields.splice(itemIndex, 1);

      if (!this.view.get('groups')[groupIndex].columns[columnIndex].fields.length) {
        this.view.get('groups')[groupIndex].columns.splice(columnIndex, 1);
      }

      if (!this.view.get('groups')[groupIndex].columns.length) {
        this.view.get('groups').splice(groupIndex, 1);
      }

      return this.routeToInputs();
    },

    resetDragDrop() {
      // clear any empty groups and columns
      this.view.clearEmptyFieldSchemas();

      this.routeToInputs();

      return this.view.attributes.groups;
    },

    onUpdateFieldListItem(newItem, groupIndex, columnIndex, itemIndex) {
      this.view.get('groups')[groupIndex].columns[columnIndex].fields[itemIndex] = newItem;
    },
  },
};
</script>

<style lang="scss">
.kn-searchInputs-wrapper {
  margin-left: 6px;
}

.kn-search-wrapper {
  min-width: 600px;
  position: relative;

  .kn-search-results-table-wrapper {
    margin-left: 7px;
    margin-top: 12px;
  }

  .kn-item > label {
    display: block;
    font-weight: 700;
    line-height: 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 35%;
     &.none {
      display: none;
    }
     &.left {
      float: left;
    }
     &.right {
      float: left;
      text-align: right;
      padding-right: 8px;
    }
     &.top {
      float: none;
    }
  }

  .field-list-item {
    display: flex;
  }

  select {
    margin-right: 8px !important;
  }

  .large-inputs {
    input, textarea {
      padding: 0.6em .75em;
      @include font-h6;
      border-radius: .4em;
    }
  }

  .large-spacing {
    .kn-input {
      padding: 10px 6px;
    }
  }
  .small-spacing {
    .kn-input {
      padding: 2px 6px;
    }
  }

  .small-inputs {
    input, textarea {
      padding: 0.3em .4em;
      font-size: .86em;
      line-height: 1.2em;
      border-radius: .25em;
    }
  }

  .kn-submit {
    margin-top: 10px;
    padding: 0 2px;
  }

  .kn-button.is-primary, .kn-content .is-primary.fc-button {
    background-color: #52fd96;
    border-width: 0;
    color: rgba(0,0,0,.7);
  }

  .kn-button[type=submit], .kn-content [type=submit].fc-button {
    min-width: 140px;
  }

  .kn-content button, .kn-content input, .kn-content select, .kn-content textarea, .kn-form button, .kn-form input, .kn-form select, .kn-form textarea {
    margin: 0;
  }
  .is-primary {
    color: #ffffff !important;
  }

  .is-primary {
    background-color: #0980e4 !important;
  }

  .kn-button {
    background-color: rgba(0,0,0,.08);
    align-items: center;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: .35em;
    display: inline-flex;
    font-size: 14px;
    height: 32px;
    justify-content: flex-start;
    line-height: 24px;
    padding-left: 6px;
    padding-right: 8px;
    position: relative;
    vertical-align: top;
    user-select: none;
    cursor: pointer;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    white-space: nowrap;
    text-decoration: none strong;
    text-decoration-color: inherit;
  }

  .required {
    color: $danger;
  }

  p.section-break {
    margin-bottom: 0;
  }

  input.preview-input, .kn-keyword-search input.input {
    max-width: 221px;
  }
}
</style>
