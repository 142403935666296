<template>
  <ActionList
    ref="list"
    :default-item="defaultItem"
    :can-be-empty="true"
    :items="items"
    @update:items="emitUpdate"
  >
    <template #default="{item}">
      <input
        v-model="item.value"
        v-focus
        data-cy="quick-list-input"
        class="h-10 rounded-lg py-2 px-3 placeholder:text-subtle focus:shadow-none focus:border-emphasis focus:border-opacity-100 focus:border border-solid border-default w-full"
        placeholder="Admin"
        @keyup.enter="addItem"
      >
    </template>
  </ActionList>
</template>

<script>
// USE: simple text input with enter for adding new.
// E.g. multiple choice field options, enable users user roles

import ActionList from '@/components/ui/lists/ActionList';

export default {
  components: {
    ActionList,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:items'],
  methods: {
    /**
     * Ask ActionList to add a new item.
     */
    addItem() {
      this.$refs.list.onAddItem();
    },
    /**
     * Inform the parent of the new items.
     * This fires whenever ActionList adds a new item.
     */
    emitUpdate(items) {
      this.$emit('update:items', items);
    },
    /**
     * This is the default item sent into and
     * used by ActionList.
     */
    defaultItem() {
      return {
        value: '',
      };
    },
  },
};
</script>

<style lang="scss">
.field-list {
  .field-list-item {
    display: flex;
    flex-grow: 1;

    > * {
      margin-right: .25rem;
    }
    > *:last-child {
      margin-right: 0;
    }
  }

}
</style>
