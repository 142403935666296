import Joi from '@hapi/joi';
import get from 'lodash/get';
import LogRocket from 'logrocket';

import api from '@/lib/api-wrapper';
import { shouldEnableLogRocket } from '@/lib/env-helper';
import { getDevUrl } from '@/lib/url-helper';
import FormBase from '@/store/forms/FormBase';

/**
 * The form for reporting an emergency.
 */
export default class FormHelpReportEmergency extends FormBase {
  /**
   * The description of the emergency.
   * @type {string}
   */
  emergencyIssue = '';

  /**
   * The URL of where the issue was noticed.
   * @type {string}
   */
  emergencyUrl = '';

  /**
   * When the user first noticed the issue.
   * @type {string}
   */
  emergencyWhen = '';

  /**
   * Any changes that were made to the app prior to the issue occurring.
   * @type {string}
   */
  emergencyChanges = '';

  /**
   * Validates the form.
   *
   * @returns {{passed: boolean, errors: Error[]}}
   */
  async validate() {
    const validationSchema = {
      emergencyIssue: Joi.string().allow('').optional().label('The issue description'),
      emergencyUrl: Joi.string().allow('').optional().label('The URL when you see the issue'),
      emergencyWhen: Joi.string().allow('').optional().label('When the issue was noticed'),
      emergencyChanges: Joi.string().allow('').optional().label('Prior changes'),
    };

    return this._validateForm(validationSchema);
  }

  /**
   * Submits the form to the server.
   *
   * @param {Object} accountObject
   * @returns {Promise<Object>}
   */
  async submit(accountObject) {
    console.log('account', accountObject);

    const userId = api.user_id;
    const userEmail = api.email;

    const logRocketSessionUrl = await this._getLogRocketSession({
      userId,
      userEmail,
      planId: get(accountObject, 'product_plan.id'),
    });

    const ticketData = {
      builderLink: window.location.href,
      devLink: getDevUrl(),
      emergencyChanges: this.emergencyChanges,
      emergencyIssue: this.emergencyIssue,
      emergencyUrl: this.emergencyUrl,
      emergencyWhen: this.emergencyWhen,
      logRocketSessionUrl,
    };

    return api.helpReportEmergency(ticketData);
  }

  /**
   * Gets the log rocket session url.
   *
   * @param {Object} setup
   * @param {string|number} setup.userId
   * @param {string} setup.userEmail
   * @param {string} setup.planId
   * @returns {Promise<string|null>}
   * @private
   */
  async _getLogRocketSession({ userId, userEmail, planId }) {
    if (!shouldEnableLogRocket()) {
      return null;
    }

    LogRocket.identify(userId, {
      email: userEmail,
      plan_id: planId,
    });

    return new Promise((resolve) => {
      LogRocket.getSessionURL(resolve);
    });
  }
}
