<template>
  <div class="monaco-code-editor" />
</template>

<script>
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { eventBus } from '@/store/bus';

export default {
  props: {
    language: {
      type: String,
      required: true,
    },
    editorOptions: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['save'],
  data() {
    return {
      code: '',
    };
  },
  computed: {
    ...mapGetters([
      'getCursorPosition',
      'getTempCode',
      'app',
      's3ApplicationCdnDomain',
    ]),
  },
  async mounted() {
    await this.$nextTick();

    const fileExtension = this.language === 'javascript' ? 'js' : 'css';

    let data = '';
    try {
      const response = await axios.get(`https://${this.s3ApplicationCdnDomain}/${this.app.get('id')}/custom/main.${fileExtension}?${Date.now()}`);
      data = response.data;
    } catch (fetchError) {
      // Prevent an error here and just show an empty editor.
    }

    this.code = `${data}`;

    eventBus.$emit(`${this.language}CodeEditor:update`, this.code);

    const monacoOptions = {
      value: this.code,
      language: this.language,
      wordBasedSuggestions: false,
      ...this.editorOptions,
    };

    this.monacoEditor = monaco.editor.create(this.$el, monacoOptions);

    this.monacoEditor.onDidChangeModelContent(this.codeChange);

    this.monacoEditor.addAction({
      id: 'save-event',
      label: 'Save Changes',
      keybindings: [
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S,
      ],
      run: () => this.$emit('save'),
    });

    window.onresize = () => {
      this.monacoEditor.layout();
    };

    const tempCode = this.getTempCode(this.language);

    if (tempCode) {
      this.monacoEditor.setValue(tempCode);

      eventBus.$emit(`${this.language}CodeEditor:update`, tempCode);
    }

    const cursorPosition = this.getCursorPosition(this.language);

    if (cursorPosition) {
      this.monacoEditor.revealPositionInCenter(cursorPosition);
    }
  },
  beforeUnmount() {
    if (this.monacoEditor) {
      const { language } = this;

      this.$store.commit('setTempCode', {
        language,
        tempCode: this.monacoEditor.getValue(),
      });

      this.$store.commit('setCursorPosition', {
        language,
        cursorPosition: this.monacoEditor.getPosition(),
      });

      this.monacoEditor.dispose();
    }
  },
  methods: {
    codeChange() {
      const value = this.monacoEditor.getValue();

      if (this.code !== value) {
        eventBus.$emit(`${this.language}CodeEditor:update`, value);
      }
    },
  },
};
</script>

<style lang="scss">
.monaco-code-editor {
  height: calc(100vh - 330px);
  width: 100%;
  max-width: 100% !important;
}
</style>
