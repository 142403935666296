<template>
  <view-toolbox
    back-title="Form Menu"
    title="Design & Options"
  >
    <form>
      <div>
        <label>Title</label>
        <input
          v-model="viewRaw.title"
          type="text"
        >
      </div>
      <div>
        <label>Description</label>
        <textarea v-model="viewRaw.description" />
      </div>
      <div>
        <label>Submit Button Text</label>
        <input
          v-model="viewRaw.submit_button_text"
          type="text"
        >
      </div>
      <div>
        <label>Reload Form Text</label>
        <input
          v-model="reloadFormText"
          type="text"
        >
      </div>
      <!--
      <label>Design</label>
      <label class="label-header">Submit Button</label>
      <div class="customization-options">
        <DesignPropertyPicker
          design-property="style"
          label-text="submit style"
          :options="['outline', 'fill']" />
        <DesignPropertyPicker
          v-if="designStyle === `fill`"
          design-property="fillColor"
          label-text="fill color"
          input-type="color"
          description-text="A darker shade is used for the hover state" />
        <DesignPropertyPicker
          v-if="designStyle === `outline`"
          design-property="outlineColor"
          label-text="border color"
          input-type="color" />
        <DesignPropertyPicker
          design-property="textColor"
          label-text="font color"
          input-type="color" />
      </div>
      -->
    </form>
  </view-toolbox>
</template>

<script>
import isNil from 'lodash/isNil';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DesignUtils from '@/components/views/DesignUtils';
// import DesignPropertyPicker from '@/components/settings/design/DesignPropertyPicker'

export default {
  name: 'FormSettings',
  components: {
    ViewToolbox,
    // DesignPropertyPicker
  },
  mixins: [
    ViewUtils,
    DesignUtils,
  ],
  computed: {
    designStyle() {
      if (isNil(this.view.design.style)) {
        return this.app.design.form.style;
      }

      return this.view.design.style;
    },
    reloadFormText: {
      get() {
        return this.viewRaw.reload_form_text || 'Reload form';
      },
      set(newValue) {
        this.viewRaw.reload_form_text = newValue;
      },
    },
  },
};
</script>
