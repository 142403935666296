<template>
  <div
    :id="shouldShowEmptyState ? undefined : 'tasks-list-wrapper'"
    class="-mt-3"
  >
    <RouterView
      name="modal"
      :base-url="`/tasks/objects/${object.key}`"
    />

    <TasksEmpty
      v-if="shouldShowEmptyState"
      :object="object"
    />

    <RecyclableSortableItems
      v-else
      id="tasks-list"
      class="body-padding-full"
      :items="object.tasks"
      :item-size="sortableItemSize"
      @sort="onSort"
    >
      <template #default="{item}">
        <TaskListItem
          :id="`task-list-item-${item.id}`"
          :key="item.key"
          :task="item"
        />
      </template>
    </RecyclableSortableItems>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TaskListItem from '@/components/tasks/TaskListItem';
import TasksEmpty from '@/components/tasks/TasksEmpty';
import RecyclableSortableItems from '@/components/ui/lists/RecyclableSortableItems';
import FieldUtils from '@/components/fields/FieldUtils';
import RequestUtils from '@/components/util/RequestUtils';

// Height of list item card + 8px of spacing between elements
const SORTABLE_ITEM_SIZE = 48;

export default {
  components: {
    TaskListItem,
    TasksEmpty,
    RecyclableSortableItems,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  data() {
    return {
      tasks: [],
      sortableItemSize: SORTABLE_ITEM_SIZE,
    };
  },
  computed: {
    ...mapGetters([
      'activeObject',
    ]),
    shouldShowEmptyState() {
      return !this.object.tasks || this.object.tasks.length === 0;
    },
  },
  methods: {
    onSort(event, tasks) {
      this.commitRequest({
        request: () => window.Knack.Api.updateTasksSort(this.object.key, tasks.map((task) => task.key)),
        onSuccess: () => {
          this.$store.commit('updateTaskOrder', {
            objectKey: this.object.key,
            tasks,
          });
        },
        globalLoading: false,
      });
    },
  },
};
</script>

<style lang="scss">
#tasks-list-wrapper {
  flex: 2 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 800px;
}
</style>
