<template>
  <div
    class="view rounded-lg rounded-tl-none"
    :class="viewWrapperClasses"
  >
    <!-- view only -->
    <template v-if="!showEdit">
      <div
        ref="wrapper"
        class="wrapper"
        :class="viewClasses"
      >
        <h2
          v-if="viewTitle"
          class="kn-title"
          data-cy="view-title"
          v-html="viewTitle"
        />

        <div
          v-if="!viewTitle && !viewDescription"
          class="view-top-spacer"
          style="height: 1em;"
        />

        <p
          v-if="viewDescription"
          class="view-description"
          data-cy="view-description"
          v-html="viewDescription"
        />
        <slot />
      </div>
    </template>

    <template v-else>
      <slot name="childLinks" />

      <div class="viewName_wrapper cursor-default">
        <div
          v-if="!isEditingViewName"
          class="viewName_name gap-3 bg-transparent"
        >
          <p class="viewName_label truncate mb-0 text-sm text-emphasis font-semibold tracking-[0.28px]">
            {{ view.get(`name`) }}
          </p>
          <button
            class="bg-transparent border-0 text-default text-sm font-medium py-1 px-0"
            @click="onClickEditName"
          >
            Change View Name
          </button>
        </div>
        <input
          v-else
          v-model="view.attributes.name"
          v-focus
          class="viewName_input h-10"
          style="margin-right: 8px;"
          type="text"
          @keyup.esc="onCancelViewName"
          @keyup.enter="onSubmitViewName"
          @blur="onCancelViewName()"
        >
      </div>

      <div
        class="view-edit-links rounded-t-lg bg-subtle p-0"
        :class="{ 'px-1': isNew }"
      >
        <div class="view-edit-links-wrapper gap-1">
          <template v-if="showDrag">
            <a
              v-if="isActive"
              key="view-move-active"
              v-tippy="{ allowHTML: true }"
              content="Click to exit edit mode<br />to move this view"
              class="handle-is-disabled h-6 w-6 m-0 ml-1 mr-0"
              @click="$router.push(`/pages/${$route.params.pageKey}`)"
            >
              <Icon
                class="text-default m-0"
                type="drag-handle"
              />
            </a>
            <a
              v-else
              key="view-move"
              v-tippy
              content="Drag to move"
              class="handle hover:bg-emphasis rounded-md h-6 w-6 ml-1 mr-0"
            >
              <Icon
                class="text-default m-0"
                type="drag-handle"
              />
            </a>
          </template>

          <RouterLink
            v-slot="{ isActive: linkIsActive, navigate }"
            :to="`${baseLink}/${viewType}`"
            custom
          >
            <div
              class="view-edit-label hover:bg-emphasis rounded-md border-x-0 p-1 m-0"
              :class="{'router-link-active': linkIsActive}"
              @click="navigate"
            >
              <Icon
                v-if="isUserView"
                v-tippy
                content="This view works with the logged-in user"
                type="key"
                class="view-is-user text-default m-0 mr-2"
              />

              <div
                v-tippy
                :content="!isActive ? 'Edit this view' : ''"
                class="view-edit-type text-default capitalize text-base leading-4"
                :data-cy="viewTypeTitle"
              >
                {{ viewTypeTitle }}
              </div>
            </div>
          </RouterLink>

          <template v-if="showSettings">
            <RouterLink
              v-tippy
              content="Edit this view"
              data-cy="view-edit-settings-link"
              class="settings hover:bg-emphasis rounded-md h-6 w-6 m-0"
              :to="`${baseLink}/${viewType}`"
            >
              <Icon
                type="edit"
                class="small text-default m-0"
              />
            </RouterLink>
            <RouterLink
              v-tippy
              content="Delete this view..."
              data-cy="view-delete-link"
              class="settings hover:bg-emphasis rounded-md h-6 w-6 m-0"
              :to="`${baseLink}/delete`"
            >
              <Icon
                type="delete"
                class="small text-default m-0"
              />
            </RouterLink>

            <Popover
              @show="onPopoverShow"
              @hide="onPopoverHide"
              placement="bottom-end"
            >
              <template #trigger>
                <a
                  v-tippy
                  content="Copy &amp; move"
                  class="settings hover:bg-emphasis rounded-md h-6 w-6 mr-1"
                  data-cy="view-open-popover"
                >
                  <Icon
                    class="text-default m-0"
                    type="more-horiz"
                  />
                </a>
              </template>

              <template #content>
                <div class="links p-0">
                  <RouterLink
                    v-close-popper
                    data-cy="view-copy-link"
                    class="rounded-lg hover:bg-brand-50 text-base text-default hover:text-emphasis p-2 group"
                    :to="`${baseLink}/copy`"
                  >
                    <Icon
                      type="copy"
                      class="small text-subtle group-hover:text-emphasis"
                    /> Copy
                  </RouterLink>
                  <RouterLink
                    v-close-popper
                    data-cy="view-move-link"
                    class="rounded-lg hover:bg-brand-50 text-base text-default hover:text-emphasis p-2 group"
                    :to="`${baseLink}/move`"
                  >
                    <Icon
                      type="arrow-top-right-thick"
                      class="small text-subtle group-hover:text-emphasis"
                    /> Move To
                  </RouterLink>
                </div>
              </template>
            </Popover>
          </template>
        </div>

        <!--
        <div class="view-title-message"><b>View added!</b>&nbsp;Configure this table with the left menu.</div>
        -->
      </div>

      <div class="view-expander">
        <div
          class="expand-bg"
          @click="onClickBlur"
        />
        <div
          v-tippy
          :content="`Click to ${isExpanded ? 'shrink' : 'expand'}`"
          class="expand buttonSquare-outline buttonSquare-dropshadow blue"
          style="background: white;"
          @click.prevent="onClickExpand"
        >
          {{ `${isExpanded ? 'shrink' : 'expand'}` }} <Icon type="arrow-drop-down" />
        </div>
      </div>

      <div
        v-tippy="{ followCursor: `initial`, arrow: false, allowHTML: true }"
        :content="blurTitle"
        class="blur"
        @click="onClickBlur"
      />

      <div
        ref="wrapper"
        :class="viewClasses"
        :style="wrapperStyle"
      >
        <!--
        <div class="view-message"><Icon type="check-circle" class="small inline" />&nbsp;&nbsp;<b>View added!</b>&nbsp;Configure this table with the left menu.&nbsp;&nbsp;<a class="message-close"><Icon type="close" class="small inline" /></a></div>
        -->

        <h2
          v-if="showTitle"
          class="kn-title"
          :class="{ 'kn-title no-title': !viewTitle }"
          data-cy="view-title"
          v-html="viewTitle"
        />
        <p
          v-if="viewDescription"
          class="view-description"
          data-cy="view-description"
          v-html="viewDescription"
        />

        <!-- View Component -->
        <slot :onViewHeightChange="onViewHeightChange" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';
import PageNavUtils from '@/components/pages/PageNavUtils';
import UIUtil from '@/util/UI';

export default {
  components: {
    Icon,
    Popover,
  },
  mixins: [
    PageNavUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },

    // don't show any edit options, used by app settings for design preview
    isViewOnly: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'toggleCollapse',
  ],
  data() {
    return {
      isEditingViewName: false,
      isExpanded: true,
      expandable: false,
      expandMaxHeight: 200,
      expandableMinHeight: 300, // a view is considered expandable if its height is larger than 300px
      popoverToggled: false, // edit menu, ensures hover style persists when toggled
      ignoreExpanding: [
        'menu',
        'login',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showViewNames',
      'collapseViews',
      'menuKeyForAddingNewView',
    ]),
    isActiveMenuFromAddView() {
      return this.view.key === this.menuKeyForAddingNewView;
    },
    isNew() {
      return this.view.key === 'new';
    },
    isUserView() {
      if (!this.view.source) {
        return false;
      }

      return this.view.source.authenticated_user;
    },
    blurTitle() {
      if (this.isNew) {
        return 'Continue using the left panel to add this view.<br />Once added you can configure additional options.';
      }

      if (this.isNewPage) {
        return '';
      }

      return 'Click to edit this view';
    },
    showDrag() {
      // This could be a menu view that a new view being added is linked from
      if (this.isActiveMenuFromAddView) {
        return false;
      }

      return !this.isNew;
    },
    showSettings() {
      // This could be a menu view that a new view being added is linked from
      if (this.isActiveMenuFromAddView) {
        return false;
      }

      return !this.isNew;
    },
    showEdit() {
      return !this.isViewOnly;
    },
    routePrefix() {
      return `pages/${this.$route.params.pageKey}/views/${this.view.key}/${this.view.type}`;
    },
    viewTitle() {
      if (this.view.isCheckoutType()) {
        return this.view.get('checkout_page') ? this.view.get('checkout_page').title : this.view.get(
          'title',
        );
      }

      return this.view.get('title');
    },
    viewDescription() {
      if (this.view.isCheckoutType()) {
        if (this.isNew) {
          return '';
        }

        return this.view.attributes.checkout_page ? this.view.attributes.checkout_page.description : this.view.attributes.description;
      }

      return this.view.attributes.description;
    },
    viewType() {
      if (this.view.isCheckoutType()) {
        return 'checkout';
      }

      return this.view.type;
    },
    viewTypeTitle() {
      let name = '';

      if (this.view.key === 'new') {
        name = 'New ';
      }

      if (this.view.type === 'rich_text') {
        return `${name}Rich Text`;
      }

      if (this.view.type === 'form' && this.view.get('parent')) {
        return `${name}Registration Form`;
      }

      if (this.view.type === 'customer') {
        return `${name}Payment Method`;
      }

      if (this.view.type === 'charge' || this.view.type === 'checkout') {
        return `${name}Payment`;
      }

      if (this.view.type === 'table') {
        return `${name}Grid`;
      }

      return `${name}${this.view.type}`;
    },
    viewWrapperClasses() {
      let isFullWidth = (this.view.type === 'table' || this.view.type === 'search');

      // Detail views that use more than one column should take up the whole width
      if (this.view.type === 'details' && this.view.getDetailsLayout() !== 'full') {
        isFullWidth = true;
      }

      if (this.isNew) {
        return {
          [this.view.key]: true,
          'is-viewOnly': true,
          'is-active border-default': this.isActive,
          'is-new': true,
          'is-expanded': true,
          'kn-view-new': true,
          'is-full-width': isFullWidth,
        };
      }

      return {
        [this.view.key]: true,
        'is-active border-default': this.isActive,
        'is-expanded': this.isExpanded,
        js_isExpanded: this.isExpanded, // javascript reference
        'popover-toggled': this.popoverToggled,
        expandable: this.expandable,
        'is-full-width': isFullWidth,
        'show-name': this.$store.getters.showViewNames,
        'is-viewOnly': this.isActiveMenuFromAddView,
        'pt-16': this.isEditingViewName,
      };
    },
    viewClasses() {
      return [
        `kn-${this.viewType}`,
        'group/shared',
        'kn-view',
        'transition',
      ];
    },
    isActive() {
      // views added during add-page-preview do not have a key
      if (!this.view.key || !this.$store.state.activeView) {
        return false;
      }

      return this.$store.state.activeView.key === this.view.key;
    },
    wrapperStyle() {
      if (this.ignoreExpanding.indexOf(this.viewType) === -1) {
        return '';// `max-height: ${this.expandMaxHeight}px;`
      }

      return '';
    },
    page() {
      return this.$store.getters.activePage;
    },

    isNewPage() {
      return Boolean(this.page && this.page.isNewPage());
    },

    baseLink() {
      return `/pages/${this.page.key}/views/${this.view.key}`;
    },
  },
  watch: {
    isActive(newVal) {
      if (!newVal) {
        return;
      }

      this.scrollToTop();
    },
    collapseViews(newVal, oldVal) {
      // collapse
      if (newVal && this.isExpanded) {
        this.$refs.wrapper.style.maxHeight = `${this.expandMaxHeight}px`;
      }

      // expand
      if (!newVal && !this.isExpanded) {
        this.$refs.wrapper.style.maxHeight = '';
      }

      this.isExpanded = !newVal;
    },
  },
  async mounted() {
    // some rule for view types which can be expanded?
    if (this.ignoreExpanding.indexOf(this.viewType) === -1 && this.showEdit) {
      await this.$nextTick();

      if (this.$refs.wrapper.offsetHeight > this.expandableMinHeight) {
        this.expandable = true;
      }
    }

    if (this.collapseViews && this.isExpanded && this.expandable) {
      await this.$nextTick();

      this.isExpanded = false;

      this.$refs.wrapper.style.maxHeight = `${this.expandMaxHeight}px`;
    }

    if (this.isActive) {
      await this.$nextTick();

      this.onClickActivate();
      this.scrollToTop();
    }

    if (this.isNew) {
      await this.$nextTick();

      this.scrollToTop();
    }
  },
  methods: {
    onViewHeightChange() {
      // Re-evaluate if the view is expandable after a height change.
      // Context: Most views have auto heights based on their content, but other views like Rich Text (which renders
      // their content inside an iFrame) need their height manually adjusted after ViewWrapper has mounted.
      this.expandable = this.$refs.wrapper.offsetHeight > this.expandableMinHeight;
    },
    onClickEditName() {
      this.nameRestore = this.view.name;

      this.isEditingViewName = true;
    },
    onCancelViewName() {
      setTimeout(() => {
        // clicking the submit button will also trigger blur but this delay ensures we're still editing
        if (!this.isEditingViewName) {
          return;
        }

        this.view.name = this.nameRestore;

        this.isEditingViewName = false;
      }, 250);
    },
    onSubmitViewName() {
      // Trim whitespace and don't save if empty
      if (!this.view.name.trim()) {
        return;
      }

      // Optimistic save, no point in awaiting this
      this.view.save();

      this.isEditingViewName = false;
    },
    async scrollToTop() {
      await this.$nextTick();

      const $bodyScroll = document.getElementById('body-scroll');

      const viewOffset = this.$el.getBoundingClientRect().top - $bodyScroll.getBoundingClientRect().top;

      const scrollDestination = $bodyScroll.scrollTop + viewOffset - 40;

      log('ViewWrapper.scrollToTop() viewOffset', viewOffset, '$bodyScroll.scrollTop', $bodyScroll.scrollTop, 'destination', scrollDestination, $bodyScroll);

      if (viewOffset < 0) {
        return;
      }

      // set up a scrollable instance
      const scrollable = new UIUtil.ScrollTo($bodyScroll);

      return scrollable.go(scrollDestination);
    },
    onPopoverShow() {
      this.popoverToggled = true;
    },
    onPopoverHide() {
      this.popoverToggled = false;
    },
    onClickBlur(event) {
      if (this.isNewPage || this.isNew) {
        return;
      }

      // does this page have an active view?
      const route = `/pages/${this.$route.params.pageKey}`;

      if (this.$store.getters.activeView) {
        this.$router.push(route);
        return;
      }

      this.$router.push(`${route}/views/${this.view.key}/${this.viewType}`);

      if (!this.isExpanded) {
        this.onClickExpand(event);
      }
    },
    onClickActivate(event) {
      if (this.isExpanded) {
        return;
      }

      return this.onClickExpand(event);
    },
    onClickExpand(event) {
      this.isExpanded = !this.isExpanded;

      this.$nextTick(() => {
        this.$emit('toggleCollapse');
      });

      const element = this.$refs.wrapper;

      let height = 0;

      if (this.isExpanded) {
        Array.from(element.children).forEach((child) => {
          height += Number(child.getBoundingClientRect().height);

          return height;
        });
      }

      height = `${height}px`;

      const transitionEnd = function (event) {
        if (event.propertyName !== 'max-height') {
          return;
        }

        element.style.maxHeight = '';
        element.removeEventListener('transitionend', transitionEnd);
      };

      function nextFrame(callback) {
        return window.requestAnimationFrame(() => window.requestAnimationFrame(callback));
      }

      if (this.isExpanded) {
        element.addEventListener('transitionend', transitionEnd, false);
        element.style.maxHeight = height;

        return;
      }

      height = 0;

      Array.from(element.childNodes).forEach((child) => {
        if (!child.getBoundingClientRect) {
          return;
        }

        height += Number(child.getBoundingClientRect().height);

        return height;
      });

      element.style.maxHeight = `${height}px`;

      return nextFrame(() => {
        element.style.maxHeight = `${this.expandMaxHeight}px`;

        return element.style.maxHeight;
      });
    },
    // TODO: fix hacky implementation. handle title focus in new component lifecycle
    onClickTitle() {
      if (this.view.type === 'rich_text') {
        return;
      }

      let viewSettingsPath = 'design';

      if (this.routePrefix.includes('/report')) {
        viewSettingsPath = 'settings';
      } else if (this.routePrefix.includes('/checkout') || this.routePrefix.includes('/charge')) {
        viewSettingsPath = 'details';
      }

      const titleRoute = `/${this.routePrefix}/${viewSettingsPath}`;

      return this.$router.push(titleRoute, () => {
        setTimeout(() => {
          if (document.querySelector('#title-input')) {
            document.querySelector('#title-input').focus();
          }
        }, 200);
      });
    },
  },
};
</script>

<style lang="scss">
.viewName_wrapper {
  position: absolute;
  cursor: pointer;
  z-index: 15;

  left: 15px;
  max-width: calc(100% - 30px);
  top: 12px;
}
.viewName_name {
  display: flex;
  align-items: center;
  justify-content: center;

  background: white;
  max-width: 100%;
  height: 31px;
}
.viewName_wrapper.is-editing {
    max-width: calc(100% - 15px);
  .viewName_name {
    // max-width: 800;
  }
}
.viewName_name.buttonSquare-outline {
  box-shadow: 1px 1px 3px rgba(32, 36, 38, 0.2);
  padding: 0 10px;
}

.viewName_name.-color-fuchsia {
  border-color: $fuchsia !important;
}

.viewName_submitButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewName_label {
  margin-left: 1px;

  font-size: 14px;
}

.viewName_icon {
  flex-shrink: 0;

  height: 15px !important;
  width: 15px !important;
  margin-right: 6px;

  color: $darkerGray;
}

.viewName_input {
  background-color: transparent;
  border-color: transparent !important;
  border-radius: 2;
  padding: 0 4px;
  height: 22px;
  width: 100%;
  max-width: 250px;
  margin-left: -4px !important; // we need to remove .rendererPane
}

.viewName_input:focus {
  border-color: transparent !important;
}

.view:not(.show-name) .viewName_wrapper {
  display: none !important;
}

.view.show-name .viewName_wrapper {
  display: flex;
}

.view.show-name {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding-top: 44px;
}

.is-viewOnly .viewName_label {
  cursor: default;
  pointer-events: none;
}

.view {
  display: flex;
  flex: 0 1;
  position: relative;

  min-width: 240px;
  width: 100%;

  border: solid 1px rgba(255, 255, 255, 0);
  border-top-left-radius: 0;
  z-index: 1;

  .kn-view {
    overflow: hidden;

    .no-title {
      margin-top: 1.5em;
    }
  }

  .blur {
    position: absolute;
    left: 0; top: 0; right: 0; bottom: 0;
    background-color: #fff;
    z-index: 13;
    opacity: .01;
    cursor: pointer;
  }

  &.is-active .blur {
    display: none;
  }

  &.is-new .blur {
    display: inherit;
    cursor: default;
  }

  &.is-full-width {
    display: block;
    max-width: none;
  }
}

#pages.is-new-view, #pages.is-new-page {
  .blur {
    cursor: default;
  }

  .view-expander {
    display: none;
  }

  .view:not(.is-active) {
    border-bottom: white;
  }
}

#pages:not(.active-view):not(.is-new-page):not(.is-new-view) #page-views:not(.is-dragging-over) .view:hover, .view.popover-toggled {
  border: solid 1px #f6f6f7;
  border-top-color: #fff;

  .blur {
    opacity: .075;
    background-color: #8a8c92;
  }
}
#pages:not(.active-view, .is-new-page, .is-new-view) #page-views:not(.is-dragging-over) .view .blur {
  cursor: pointer;
}

#pages.active-view .view:not(.is-active) .blur {
  opacity: 0;
}

/* EXPANDED */
.view-expander {
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  z-index: 14;
  cursor: pointer;
}

.view .expand-bg {
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.view .expand {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 6px;
  padding: 4px 6px 4px 10px;
  left: 50%;
  margin-left: -16px;
  z-index: 15;
  line-height: 1.3em;
  cursor: pointer;
  visibility: hidden;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 18px;
    height: 18px;
    display: block;

    g {
      path:first-child {
        fill: #fff;
      }
    }
  }
}

.view:hover .expand {
  visibility: visible;
}

.view.is-expanded .expand svg {
  transform: rotate(180deg);
}
.view.is-expanded .expand svg {
  transform: rotate(180deg);
}

#pages:not(.is-dragging-over, .is-new-view) .view:hover .expand {
  background-color: #771b66;
}

.view {
  .view-expander {
    display: none;
  }
}

.view.expandable {

  .view-expander {
    display: block;
  }
}

.view.expandable:not(.show-name) {

  .view-expander {
    border-bottom: 1px dashed #d9d9e2;
  }
}

.view.is-expanded .view-expander {
  border-bottom-color: white;
}

#page-views.has-active-view .view-expander {
  display: none;
}

.view.is-active {
  .view-expander {
    display: none;
  }
}

.view.is-expanded .expand-bg {
  opacity: 0;
}

/* EDIT LINKS */

.view-message {
  background: #222124;
  // height: 100%;
  padding: .75em 1em;
  border-radius: .35em;
  margin: .25em;
  margin-bottom: 1em;
  color: #eff0f4;
  display: flex;

  .message-wrapper {
    flex-grow: 1;
    margin-right: .5em;
  }

  .message-close {
    float: right;
    color: #eff0f4;

    svg {
      opacity: .7;
    }

    &:hover svg {
      opacity: 1;
    }
  }
}

.view-title-message {
  background: #222124;
  border-left: 1px solid #38393c;
  height: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .94em;
}

.view-edit-links {
  position: absolute;
  top: -32px;
  left: -1px;
  // border-top-left-radius: 3px;
  // border-top-right-radius: 3px;
  height: 31px;
  background-color: #e5e6ea;
  border-top-left-radius: .2em;
  border-top-right-radius: .2em;
  opacity: 0;
  z-index: 15;
  display: none;
  align-items: center;
  justify-content: center;
  padding-right: 3px;

  .view-edit-links-wrapper {
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-drag-handle {
      width: 16px;
      height: 16px;
    }
  }

  .view-edit-type {
    font-size: 13.5px;
    font-weight: 500;
    color: #58585a;
    height: 100%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  a {
    color: #761c66 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  .handle, .handle-is-disabled {
    margin: 0 1px 0 8px;
  }

  .handle {
    cursor: move;
  }

  .handle-is-disabled {
    opacity: .5;
  }

  .settings {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 21px;
    border-radius: .2em;
    margin-right: 4px;

    &:hover {
      background: #d5d7dc;
    }
  }
}

.view-edit-label {
  display: flex;
  align-items: center;
  border-right: 1px solid #c7c9d0;
  border-left: 1px solid #c7c9d0;
  margin: 2px 9px 2px 6px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-left: -4px;
    opacity: .9;
  }
}

.view.is-active .view-edit-label {
  border-left: 1px solid #5297c1;
  border-right: 1px solid #5297c1;
}

.view.is-viewOnly .view-edit-label {
  border: 0;
  pointer-events: none;
  cursor: default;
}

.view.is-active .view-edit-links {
  background-color: $active;
  color: #e8eef1;

  svg, .view-edit-type {
    color: #e8eef1;
  }
  .settings {

    &:hover {
      background: #0e6192;
    }
  }
}

.view.is-active.is-new {
  .view-edit-links {
    background-color: $new;
  }
}

#pages:not(.is-new-page):not(.is-new-view):not(.active-view) #page-views:not(.is-dragging-over) .view:hover .view-edit-links, .view.is-active .view-edit-links, .draggable-mirror .view-edit-links, .view.popover-toggled .view-edit-links {
  opacity: 1;
  display: flex;
}

.view-links-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.view-links-leave-active {
  transition: all .4s ease-out;
}
.view-links-enter {
  transform: translateX(30px);
  opacity: 0;
}
.view-links-enter-active {
  transition: all .3s ease-in .2s;
  opacity: 0;
}
.view-links-enter-to {
  opacity: 1;
}

.kn-view {
  & > h1 {
    font-size: 1.75em;
    line-height: 1.35em;
    margin-top: .5em;
    font-weight: 500;
  }

  & > h1, & > p {
    padding-left: 15px;
  }
}

.item-wrapper {
  position: relative;
}
.item-wrapper.is-dragging {
  cursor: move;

  .kn-item {
    opacity: 0.6;
  }

  .item-links {
    display: none;
  }
}

.view.is-active {
  border: solid 1px $active;
  border-radius: .32em;
  border-top-left-radius: 0;
  background-color: #fff;
  z-index: 11;

  &.is-new {
    border: solid 1px $new;
  }
}

.child-links {
  z-index: 15;
}

// disactivate views like maps and calendars from being interactive
.click-interceptor {
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  z-index: 3;
}
</style>
