<template>
  <div>
    <p class="text-default text-base mb-0">Click below to run this task immediately. The task will continue to run on the schedule you've defined.</p>
    <div class="submit-buttons flex justify-end">
      <a
        class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium"
        data-cy="task-run"
        @click="onClickRun"
      >
        Run this task
      </a>
    </div>
  </div>
</template>
<script>
import FieldUtils from '@/components/fields/FieldUtils';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  computed: {
    backLink() {
      return `/tasks/objects/${this.object.key}`;
    },
  },
  methods: {
    onClickRun() {
      this.commitRequest({
        request: () => window.Knack.Api.runTask(this.$route.params.taskKey),
        onSuccess: () => this.$router.push(this.backLink),
      });
    },
  },
};
</script>
