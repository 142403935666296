<template>
  <div class="control kn-inputWrapper">
    <input
      class="file"
      :name="field.key"
      type="hidden"
      :value="ids"
    >
    <div class="kn-asset-current">
      <div style="margin-bottom: 6px;">
        <div
          v-if="hasFilename"
          class="kn-asset-current"
        >
          <a
            class="kn-view-asset"
            :data-field-key="field.key"
            :data-asset-id="localValue.id"
            :data-file-name="localValue.filename"
            @click="onClickViewAsset"
          >
            {{ localValue.filename }}
          </a>&nbsp;&nbsp;<a
            class="kn-file-remove"
            @click="onClickRemove"
          >
            {{ trans("remove") }}
          </a>
        </div>
      </div>
    </div>

    <div
      class="fileUpload"
      :style="fileUploadStyle"
      @click="previewFileNewTab"
    >
      <FileUpload
        ref="upload"
        :name="uploadName"
        label-idle="Drop files here or click to upload"
        :allow-multiple="false"
        :allow-file-type-validation="false"
        :files="localFiles"
        :required="isRequired"
        @updatefiles="updateLocalFile"
      />
    </div>
    <!-- TODO: Flash Upload -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import hasIn from 'lodash/hasIn';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';
import RequestUtils from '@/components/util/RequestUtils';
import { getInitialLocalFilesArray, getKnackFormatFromFilepondObject, uploadFilepondFiles } from '@/util/AssetsHelper';

const FileUpload = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  name: 'FileInput',
  components: {
    FileUpload,
  },
  mixins: [
    RequestUtils,
    TranslationUtils,
  ],
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      type: [
        Array, // filepond file array
        Object, // upload
        String, // url
      ],
      required: true,
    },
    shouldUploadOnAdd: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      localFiles: [],
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    localFilesValue: {
      get() {
        if (isEmpty(this.modelValue)) {
          return [];
        }

        // check if initial knack field value
        // TODO: actually upload file to work with
        if (hasIn(this.modelValue, 'field_key')) {
          return [
            {
              source: this.modelValue.url,
              options: {
                type: 'local',
                file: {
                  name: this.modelValue.filename,
                  type: this.modelValue.type,
                  size: this.modelValue.size,
                },
              },
            },
          ];
        }

        return isArray(this.modelValue) ? this.modelValue : [
          this.modelValue,
        ];
      },
      set() {
        // for some reason the value passed in to the hooks is not the files array
        // but the instance so we need to manually get the files from the ref (filepond)

        const files = this.$refs.upload.getFiles();

        this.$emit('update:modelValue', files);
      },
    },
    uploadName() {
      return `file-${this.field.key}`;
    },
    hasFiles() {
      return !isEmpty(this.localFilesValue);
    },
    ids() {
      return isNil(this.localValue.id) ? '' : this.localValue.id;
    },
    hasFilename() {
      return !isEmpty(this.localValue.filename);
    },
    isBuilder() {
      // TODO: update this when mode type is added
      // Eg. return (Knack.mode === `builder`)
      return true;
    },
    fileUploadStyle() {
      return isString(this.localValue) ? 'cursor: pointer;' : '';
    },
    isRequired() {
      return Boolean(this.field?.get?.('required'));
    },
  },
  created() {
    getInitialLocalFilesArray(this.modelValue).then((result) => {
      this.localFiles = result;
    });
  },
  methods: {
    ...mapActions('assets', [
      'showAssetViewer',
    ]),
    previewFileNewTab() {
      this.commitRequest({
        request: () => this.showAssetViewer({
          assetId: this.localValue,
          pageKey: this.isBuilder ? 'builder' : this.$route.params.pageKey,
          viewKey: this.isBuilder ? 'builder' : this.$route.params.viewKey,
          fieldKey: this.isBuilder ? 'builder' : this.field.key,
        }),
      });
    },
    onClickRemove() {
      this.localValue = null;
    },
    onClickViewAsset() {
      this.commitRequest({
        request: () => this.showAssetViewer({
          assetId: this.localValue.id,
          pageKey: this.isBuilder ? 'builder' : this.$route.params.pageKey,
          viewKey: this.isBuilder ? 'builder' : this.$route.params.viewKey,
          fieldKey: this.isBuilder ? 'builder' : this.field.key,
        }),
      });
    },
    async updateLocalFile(files) {
      const hasFiles = !isEmpty(files);
      // This method runs on initializing async files as well
      // so evaluate if we're moving from the filepond config (built in getInitialLocalFilesArray)
      const isInitialUpdate = !isEmpty(this.localFiles) && hasIn(this.localFiles[0], 'options');

      // This prevents the uploading from happening more than once because this method can fire multiple times after a file is selected.
      const isAlreadyUploading = !isEmpty(this.localFiles) && hasIn(this.localFiles[0], 'id');

      this.localFiles = files;

      if (this.shouldUploadOnAdd && hasFiles && !isInitialUpdate && !isAlreadyUploading) {
        const response = await uploadFilepondFiles(this.field.key, files, 'file');

        this.localValue = response[this.field.key];

        return;
      }

      this.localValue = getKnackFormatFromFilepondObject(this.input, files[0]);
    },
  },
};
</script>
