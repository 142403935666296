import SchemaUtils from '@/store/utils/SchemaUtils';
import { eventBus } from '@/store/bus';

/*
  Requirements for consumers:
  * this.routePrefix: the hash prefix to route different actions to
  * this.groups: the array of groups to act on
*/
export default {
  data() {
    return {
      showConfirmDeleteLink: false,
      detailsDeleteLocation: null,
    };
  },
  mounted() {
    this.addDetailEventListener();
  },
  updated() {
    this.addDetailEventListener();
  },
  beforeUnmount() {
    eventBus.$off(`addViewItem.${this.view.key}.details`);
  },
  methods: {
    addDetailEventListener() {
      eventBus.$off(`addViewItem.${this.view.key}.details`);
      eventBus.$on(`addViewItem.${this.view.key}.details`, ({ item, event }) => {
        const detailsItem = { ...SchemaUtils.detailDefaults(), ...item };

        this.addDetailsItem(detailsItem, event);
      });
    },
    deleteDetail({
      layoutIndex, groupIndex, columnIndex, itemIndex,
    }) {
      this.detailsSchema[layoutIndex].groups[groupIndex].columns[columnIndex].splice(itemIndex, 1);

      // delete the entire column if multiple columns exist in this group
      if (!this.detailsSchema[layoutIndex].groups[groupIndex].columns[columnIndex].length && this.detailsSchema[layoutIndex].groups[groupIndex].columns.length > 1) {
        this.detailsSchema[layoutIndex].groups[groupIndex].columns.splice(columnIndex, 1);
      }

      // delete the entire group if it's empty and more than group exists
      const groupIsEmpty = (this.detailsSchema[layoutIndex].groups[groupIndex].columns.flatMap((column) => column).length === 0);

      if (groupIsEmpty && this.detailsSchema[layoutIndex].groups.length > 1) {
        this.detailsSchema[layoutIndex].groups.splice(groupIndex, 1);
      }
    },
    onDeleteDetail({
      layoutIndex, groupIndex, columnIndex, itemIndex,
    }) {
      log('onDeleteDetail() ! ! ! !', layoutIndex, groupIndex, columnIndex, itemIndex);

      this.deleteDetail({
        layoutIndex, groupIndex, columnIndex, itemIndex,
      });

      this.routeToDetails();
    },
    routeToDetails() {
      const prefix = this.routePrefixDetails || this.routePrefix;

      this.$router.push(`${prefix}`);
    },
    onDropDetail({ event, newItem }) {
      const maxColumnCount = 3;

      // where the item was dragged from
      const oldLayoutIndex = Number(event.dataTransfer.getData('layout')) || 0;
      const oldGroupIndex = Number(event.dataTransfer.getData('group')) || 0;
      const oldColumnIndex = Number(event.dataTransfer.getData('column')) || 0;
      const oldItemIndex = Number(event.dataTransfer.getData('item')) || 0;

      // where the item was dragged to
      const $dropTarget = event.target;
      const dropTargetType = $dropTarget.dataset.targetType || 'column-same';

      const layoutIndex = Number($dropTarget.getAttribute('data-layout'));
      const groupIndex = Number($dropTarget.getAttribute('data-group'));
      const columnIndex = Number($dropTarget.getAttribute('data-column'));
      let itemIndex = Number($dropTarget.getAttribute('data-item'));

      log('onDropDetail', event.dataTransfer, $dropTarget, newItem, event);

      let oldItems;
      let item;

      // do we have a new item?
      if (!newItem) {
        newItem = event.dataTransfer.getData('new');
      }

      // Validate that we can move into a new column
      if (!newItem && dropTargetType === 'column-new') {
        const columnCount = this.detailsSchema[layoutIndex].groups[groupIndex].columns.length;

        // If moving between groups, then reject if max columns already exist
        if (oldGroupIndex !== groupIndex && columnCount === maxColumnCount) {
          return;
        }

        // If moving in the same group, and there are already max columns, we can allow if the old column input count will be zero (so reject if its greater than 1)
        const oldInputCount = this.detailsSchema[oldLayoutIndex].groups[oldGroupIndex].columns[oldColumnIndex].length;

        if (oldGroupIndex === groupIndex && columnCount === 3 && oldInputCount > 1) {
          return;
        }
      }

      log('newItem', newItem);

      // set the item to add (could be from an insert or drag)
      if (newItem) {
        // Validate that we can add a new column
        const columnCount = this.detailsSchema[layoutIndex].groups[groupIndex].columns.length;

        if (dropTargetType === 'column-new' && columnCount === maxColumnCount) {
          return false;
        }

        // item maybe stringified from add menu
        item = (typeof newItem === 'string') ? JSON.parse(newItem) : newItem;

        // item was stringified from add menu
        item = { ...SchemaUtils.detailDefaults(), ...item };
      } else {
        // extract item from old column
        item = this.detailsSchema[oldLayoutIndex].groups[oldGroupIndex].columns[oldColumnIndex].splice(oldItemIndex, 1)[0];
        oldItems = this.detailsSchema[oldLayoutIndex].groups[oldGroupIndex].columns[oldColumnIndex].splice(0);
      }

      // dragging down in the same column needs an item index adjustment
      if (!newItem && oldLayoutIndex === layoutIndex && oldGroupIndex === groupIndex && oldColumnIndex === columnIndex && oldItemIndex < itemIndex) {
        itemIndex--;
      }

      // add a new property for tracking later
      item.isNew = true;

      // if details is empty, we'll set some defaults to this item and be done
      if (!this.detailsSchema[layoutIndex] || this.detailsSchema[layoutIndex].groups.length === 0) {
        this.buildDefaultDetailsSchema(layoutIndex);

        this.detailsSchema[layoutIndex].groups[0].columns[0].push(item);

        return this.finishDetailsDragDrop(item);
      }

      log('layoutIndex', layoutIndex, 'groupIndex', groupIndex, 'columnIndex', columnIndex, 'itemIndex', itemIndex, 'oldGroupIndex', oldGroupIndex, 'oldColumnIndex', oldColumnIndex, 'oldItemIndex', oldItemIndex, 'dropTargetType', dropTargetType, 'classList:', $dropTarget.classList, 'item', item);

      // insert a new group
      if (dropTargetType === 'group-new') {
        log('TYPE::: group-new');

        // add old items back
        if (oldItems) {
          this.detailsSchema[oldLayoutIndex].groups[oldGroupIndex].columns[oldColumnIndex] = oldItems;
        }

        // add new group
        this.detailsSchema[layoutIndex].groups.splice(groupIndex, 0, {
          columns: [
            [
              item,
            ],
          ],
        });

        return this.finishDetailsDragDrop(item);
      }

      // split a column
      if (dropTargetType === 'column-split') {
        // add old items back
        if (oldItems) {
          this.detailsSchema[oldLayoutIndex].groups[oldGroupIndex].columns[oldColumnIndex] = oldItems;
        }

        // get all the items in this group
        const colItems = this.detailsSchema[layoutIndex].groups[groupIndex].columns[columnIndex].splice(0);

        // need to shift the index if the source item was above the target in the same column

        // get the target item out of the current group for the new split group
        const pairedItem = colItems.splice(itemIndex, 1)[0];

        // get all the items after this one for a new group
        const afterItems = colItems.splice(itemIndex);

        log('colItems', colItems, 'item', item, 'paired with', pairedItem, 'afterItems', afterItems);

        // add items back to the original group
        this.detailsSchema[layoutIndex].groups[groupIndex].columns[columnIndex] = colItems;

        // splice in a new group with the new split column
        const splitColumns = [
          [
            item,
          ],
        ];

        if ($dropTarget.classList.contains('right')) {
          splitColumns.unshift([
            pairedItem,
          ]);
        } else {
          splitColumns.push([
            pairedItem,
          ]);
        }

        this.detailsSchema[layoutIndex].groups.splice(groupIndex + 1, 0, {
          columns: splitColumns,
        });

        // splice in a new group with any remaining items
        if (afterItems.length) {
          this.detailsSchema[layoutIndex].groups.splice(groupIndex + 2, 0, {
            columns: [
              afterItems,
            ],
          });
        }

        return this.finishDetailsDragDrop(item);
      }

      // new column
      if (dropTargetType === 'column-new') {
        log('TYPE::: ADDING NEW COLUMN!', this.detailsSchema[layoutIndex].groups[groupIndex].columns.length);

        // add old items back
        if (oldItems) {
          this.detailsSchema[oldLayoutIndex].groups[oldGroupIndex].columns[oldColumnIndex] = oldItems;
        }

        // insert new column at beginning
        if (columnIndex === 0) {
          this.detailsSchema[layoutIndex].groups[groupIndex].columns.unshift([
            item,
          ]);

        // insert new column at end
        } else if (columnIndex + 1 > this.detailsSchema[layoutIndex].groups[groupIndex].columns.length) {
          this.detailsSchema[layoutIndex].groups[groupIndex].columns.push([
            item,
          ]);

        // insert new middle column
        } else {
          this.detailsSchema[layoutIndex].groups[groupIndex].columns.splice(1, 0, [
            item,
          ]);
        }

        return this.finishDetailsDragDrop(item);
      }

      // moving within the same column
      if (!newItem && oldLayoutIndex === layoutIndex && oldGroupIndex === groupIndex && oldColumnIndex === columnIndex) {
        log('TYPE::: same column!');

        // extract items from column and insert active item
        if (oldItems) {
          oldItems.splice(itemIndex, 0, item);
          this.detailsSchema[oldLayoutIndex].groups[oldGroupIndex].columns[oldColumnIndex] = oldItems;
        }

        return this.finishDetailsDragDrop(item);
      }

      // move into another existing column
      log('TYPE::: existing');

      // extract items from new column and insert active item
      const newItems = this.detailsSchema[layoutIndex].groups[groupIndex].columns[columnIndex].splice(0);

      newItems.splice(itemIndex, 0, item);

      // add old items back
      if (oldItems) {
        this.detailsSchema[oldLayoutIndex].groups[oldGroupIndex].columns[oldColumnIndex] = oldItems;
      }

      this.detailsSchema[layoutIndex].groups[groupIndex].columns[columnIndex] = newItems;

      return this.finishDetailsDragDrop(item);
    },
    finishDetailsDragDrop(item) {
      // clear any empty groups and columns
      this.detailsSchema.filter((layout) => {
        layout.groups = layout.groups.filter((group) => {
          group.columns = group.columns.filter((column) => column.length);

          return group.columns.length;
        });

        // Keep empty
        if (layout.groups.length === 0) {
          layout.groups.push({
            columns: [
              [],
            ],
          });
        }

        return layout.groups.length;
      });

      log('finishDetailsDragDrop()', this.detailSchema);

      // if this item is new, let's find it and broadcast
      if (item.isNew) {
        this.detailsSchema.find((layout, layoutIndex) => layout.groups.find((group, groupIndex) => group.columns.find((column, columnIndex) => column.find((item, itemIndex) => {
          if (item.isNew) {
            // broadcast complete
            eventBus.$emit(`addViewItemEnd.${this.view.key}.details`, {
              item, layoutIndex, groupIndex, columnIndex, itemIndex,
            });

            return true;
          }
        }))));

        // delete our tracking property
        delete item.isNew;
      }

      if (!item.isNew) {
        this.routeToDetails();
      }
    },
    addDetailsItem(newItem, dragEvent) {
      // drag event means drag/drop
      if (dragEvent) {
        return this.onDropDetail({
          event: dragEvent,
          newItem,
        });
      }

      // otherwise add to last group
      newItem.isNew = true;

      this.view.addItemToDetailsCollection(this.detailsSchema, newItem);

      this.finishDetailsDragDrop(newItem);
    },
    buildDefaultDetailsSchema(layoutIndex = 0) {
      if (!this.detailsSchema[layoutIndex]) {
        this.detailsSchema.splice(layoutIndex, 0, {
          groups: [],
        });
      }

      this.detailsSchema[layoutIndex].groups.splice(0, 0, {
        columns: [
          [],
        ],
      });
    },

  },
};
