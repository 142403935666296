<template>
  <div class="kn-entries-summary group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-base">
    <span class="light">
      {{ trans('Showing') }}
    </span> {{ range }}
    <span class="light">
      {{ trans('of') }}
    </span> {{ totalRecords }}
  </div>
</template>

<script>
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';

export default {
  mixins: [
    TranslationUtils,
  ],
  props: [
    'currentPage',
    'recordsPerPage',
    'totalRecords',
  ],
  computed: {
    startOfRange() {
      return (this.currentPage - 1) * this.recordsPerPage + 1;
    },
    endOfRange() {
      if (this.totalRecords < (this.recordsPerPage * this.currentPage)) {
        return this.totalRecords;
      }

      return ((this.totalRecords * this.currentPage) !== this.totalRecords) ? (this.startOfRange - 1 + this.recordsPerPage) : (this.recordsPerPage * this.currentPage);
    },
    range() {
      return `${this.startOfRange}-${this.endOfRange}`;
    },
  },
};
</script>
<style scoped lang="scss">
.kn-entries-summary {
  margin-right:10px;
}
</style>
