<template>
  <ConfirmModal
    confirm-type="alert"
    :back="backLink"
    @confirm="onDelete"
  >
    <template #titleContent>
      <h1 v-if="isIntegrationTable">
        Deleting the <em>{{ objectNameToDelete }}</em> table may cause critical issues
      </h1>
      <h1 v-else>
        <span class="text-xl text-emphasis font-medium">
          Delete the {{ objectNameToDelete }} table?
        </span>
      </h1>
    </template>
    <div v-if="isIntegrationTable">
      <p class="text-left">
        <strong>Table created from external Integration</strong>
      </p>
      <p class="text-left">
        Data will not be re-imported with a data refresh.
      </p>
      <p class="text-left">
        <strong>
          This may have critical consequences or cause breaking changes on other tables created from the Integration
        </strong>
      </p>
    </div>
    <p class="text-left">
      This will permanently delete the {{ objectNameToDelete }} table <strong>everywhere</strong> it
      is used in the app.
    </p>

    <p class="text-left font-bold text-[#B11F33]">
      All records in the table and all views displaying this table will be deleted.
      {{ isRole ? 'The login restriction for pages linked to this role will be removed.' : '' }}
    </p>

    <p class="text-left mb-0">
      <strong>This can't be undone!</strong>
    </p>
  </ConfirmModal>
</template>

<script>
import FieldUtils from '@/components/fields/FieldUtils';
import ConfirmModal from '@/components/ui/ConfirmModal';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  components: {
    ConfirmModal,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  props: {
    baseUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    backLink() {
      return `${this.baseUrl}/${this.object?.key}`;
    },
    objectNameToDelete() {
      return this.object ? this.object.get('name') : '';
    },
    isIntegrationTable() {
      return this.object?.get('integration') === 'QuickBooks';
    },
    isRole() {
      return this.object?.isRole();
    },
  },
  methods: {
    onDelete() {
      this.commitRequest({
        request: () => this.object.deleteObject(),
        onSuccess: () => this.$router.push('/schema/list'),
      });
    },
  },
};
</script>
