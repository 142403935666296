<template>
  <div class="help-search-result">
    <h1 class="search-results-title">
      <Icon type="kb" />
      <span class="title-text">Knowledge Base Results</span>
    </h1>

    <div v-if="isPending">
      <IconLoading />
    </div>

    <p
      v-else-if="isFailure && isInvalid"
      class="error-alert form-errors search-validation-error"
    >
      {{ validation.errorsByKey.query }}.
    </p>

    <div v-else-if="isFailure">
      <section class="search-result search-result-error">
        <h2 class="search-result-title">
          An error occurred while searching.
        </h2>

        <div
          v-for="error in errors"
          :key="error.message"
          class="search-result-description"
        >
          {{ error.message }}
        </div>
      </section>
    </div>

    <div v-else-if="isSuccess">
      <section
        v-for="result in results"
        :key="result.cacheId"
        class="search-result"
      >
        <h2 class="search-result-title">
          <a
            class="title-anchor"
            :href="result.link"
            target="_blank"
          >
            <span class="title-text">{{ result.title }}</span>
            <Icon
              type="open-in-new"
              class="drawerItem_headerIcon icon icon-open-in-new title-link"
            />
          </a>
        </h2>
        <div class="search-result-description">
          {{ result.text }}
        </div>
      </section>
      <section
        v-if="!results.length"
        class="search-result"
      >
        <div class="search-result-description">
          No results found.
        </div>
      </section>
    </div>

    <div v-else>
      Hit Enter to Start Search
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import IconLoading from '@/components/builder/icons/IconLoading';

export default {
  name: 'HelpSearchResult',
  components: {
    Icon,
    IconLoading,
  },
  computed: {
    ...mapGetters('api/help/search', [
      'results',
      'errors',
      'validation',
      'isInvalid',
      'isPending',
      'isSuccess',
      'isFailure',
    ]),
  },
};
</script>

<style lang="scss" scoped>
$linkColor: #8E9293;
$textColor: #616668;
$titleColor: #202426;

.help-search-result {
  font-size: 16px;
  padding: 1rem 1.5rem;
}

.search-results-title {
  align-items: center;
  color: $titleColor;
  display: flex;
  line-height: 1rem;
  font-weight: 500;
  margin-top: .5rem;
  margin-bottom: 1.5rem;

  .icon-kb {
    height: 22px;
    width: 22px;
  }

  .title-text {
    font-size: 1rem;
    margin-left: .5rem;
  }
}

.search-result {
  color: $textColor;
  font-size: .875rem; //14 px
  margin-bottom: 1.25rem; // 20px
}

.search-result-title {
  color: $titleColor;
  font-weight: 500;
  line-height: 1.125rem; // 18px
  margin-bottom: .25rem;

  .title-anchor {
    align-items: center;
    color: inherit;
    display: flex;
    text-decoration: none;
  }

  .title-text {
    max-width: 92%
  }

  .title-link {
    color: $linkColor;
    height: 16px;
    margin-left: .375rem;
    width: 16px;
  }
}

.search-result-description {
  line-height: 1.25rem; // 20px
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}
</style>

<style lang="scss">
  .kn-icon-loading {
    display: flex;
    justify-content: center;

    .icon {
      width: 100px;
      height: 90px;
    }
  }
</style>
