import isEmpty from 'lodash/isEmpty';
import store from '@/store';

import { objectsByFieldKey as globalObjectsByFieldKey} from '@/store/modules/object/objectsByFieldKey';

function getConnectionNameParts(objectsByFieldKey, fieldKey) {
  const object = objectsByFieldKey[fieldKey];
  if (!object) {
    return {
      object: undefined,
      field: undefined,
    };
  }

  const field = object.fieldsByKey?.[fieldKey];

  return {
    object: object.inflections?.singular,
    field: field?.name,
  };
}

/**
 * Gets the view source options.
 *
 * @param {string} [objectKey]
 * @param {Page | RawPage} [page]
 * @param {string} [inflection]
 * @returns {object[]}
 */
export function getViewSourceOptions(objectKey, page, inflection = 'plural') {
  const pageObject = store.getters.getObject(objectKey);

  // Passing this object around to avoid weird vue slowdown from accessing
  // this getter thousands of times (was causing massive delay on very complex apps).
  // const { objectsByFieldKey } = store.getters;
  const objectsByFieldKey = globalObjectsByFieldKey.value;

  // this page knows about an object, so we want options to show/edit that object record,
  // along with connected records
  if (pageObject) {
    // add single options for this object
    // if (this.quantity === 'one') {
    const singleOption = getSingleObjectViewSourceOption(pageObject);

    // }

    // check objects connected to this object
    const connectedOptions = getPageConnectedViewSourceOptions(
      objectsByFieldKey,
      pageObject,
      inflection,
    );

    return [
      singleOption,
      ...connectedOptions,
    ];
  }

  // no object, so this is likely an entry page, so we want options to show
  // 'records in your database' for EVERY object.
  // if (this.quantity === 'many') {
  const objectOptions = getEveryObjectViewSourceOptions(inflection);

  // }

  let userOptions = [];

  // if this is a user login page, we want to add single options for the logged-in user record,
  // along with connected records
  if (page && page.authenticated === true) {
    userOptions = getUserViewSourceOptions(
      objectsByFieldKey,
      page.authentication_profiles,
      inflection,
    );
  }

  return [
    ...objectOptions,
    ...userOptions,
  ];
}

/**
 * Gets the view source option icon for the given Object.
 *
 * @param {Obj} object
 * @returns {string}
 */
function getViewSourceOptionIcon(object) {
  if (object.isUser()) {
    return 'user';
  } if (object.ecommerce) {
    return 'credit-card';
  }

  return 'object';
}

/**
 * Gets a single object view source option.
 *
 * @param {Obj} pageObject
 * @returns {object} - The option object.
 */
function getSingleObjectViewSourceOption(pageObject) {
  return {
    objectKey: pageObject.key,
    type: 'page',
    quantity: 'one',
    label: `this page's <b class="source-label">${pageObject.inflections.singular}</strong> record`,
    source: {
      object: pageObject.key,
    },
    labelParts: {
      from: `this page's <strong>${pageObject.inflections.singular}</strong>`,
    },
    icon: getViewSourceOptionIcon(pageObject),
  };
}

/**
 * Gets page connected view source options.
 *
 * @param {object} objectsByFieldKey
 * @param {object} pageObject
 * @param {string} inflection
 * @returns {object[]} - The option objects.
 */
function getPageConnectedViewSourceOptions(objectsByFieldKey, pageObject, inflection) {
  if (!pageObject || !pageObject.conns) {
    return [];
  }

  const options = [];

  // direct connections
  pageObject.conns.forEach((conn) => {
    const connObject = store.getters.getObject(conn.object);

    if (!connObject) {
      return;
    } if (connObject.ecommerce || connObject.ecommercePaymentMethods) {
      return;
    }

    const labelArticle = (conn.has === 'one') ? 'the ' : '';

    const labelFrom = connObject.inflections[inflection];

    const labelTo = `this page's <strong>${pageObject.inflections.singular}</strong>`;

    const option = {
      objectKey: conn.object,
      type: 'page',
      quantity: conn.has,
      label: `${labelArticle}<b class="source-label">${labelFrom}</strong> connected to ${labelTo}`,
      labelParts: {
        from: labelFrom,
        to: labelTo,
      },
      conns: [
        getConnectionNameParts(objectsByFieldKey, conn.key),
      ],
      connections: {
        direct: {
          object: {
            key: pageObject.key,
            name: pageObject.name,
            inflections: pageObject.inflections,
          },
          field: {
            key: conn.key,
            names: getConnectionNameParts(objectsByFieldKey, conn.key),
          },
          relationship_type: conn.relationship_type,
        },
      },
      source: {
        object: conn.object,
        connection_key: conn.key,
        relationship_type: conn.relationship_type,
      },
      icon: getViewSourceOptionIcon(connObject),
    };

    options.push(option);

    // further connections
    getFurtherConnectedViewSourceOptions(options, conn, connObject, false, pageObject, inflection, objectsByFieldKey);
  });

  return options;
}

function getFurtherConnectedViewSourceOptions(options, conn, connObject, authenticated, originObject, inflection = 'plural', objectsByFieldKey) {
  connObject.conns.forEach((subConn) => {
    // don't reconnect single connections back to original object
    if (subConn.has === 'one' && subConn.key === conn.key) {
      return;
    }

    // we can't connect to a further single record from many connected records
    if (subConn.has === 'one' && conn.has === 'many') {
      return;
    }

    const subConnObject = store.getters.getObject(subConn.object);

    if (!subConnObject) {
      return;
    } if (subConnObject.ecommerce || subConnObject.ecommercePaymentMethods) {
      return;
    }

    const source = {
      object: subConn.object,
      connection_key: subConn.key,
      relationship_type: subConn.relationship_type,
      parent_source: {
        connection: conn.key,
        object: connObject.key,
      },
    };

    if (authenticated) {
      source.authenticated_user = true;
    }

    const labelArticle = (subConn.has === 'one') ? 'the ' : '';
    const labelFrom = `<b class="source-label">${subConnObject.inflections[inflection]}</strong>`;
    let label = `${labelArticle}${labelFrom} connected to `;
    let labelToParent;

    if (authenticated) {
      labelToParent = `the logged-in <strong>${originObject.inflections.singular}</strong>`;
    } else {
      labelToParent = `this page's <strong>${originObject.inflections.singular}</strong>`;
    }

    label += labelToParent;

    const labelTo = `<strong>${connObject.inflections[`${conn.has === 'one' ? 'singular' : 'plural'}`]}</strong>`;

    label += ` <span style="font-size:.85em;">&gt;</span> ${labelTo}`;

    const option = {
      objectKey: subConn.object,
      parentObjectKey: connObject.key,
      type: (authenticated) ? 'user' : 'page',
      roleKey: (authenticated && originObject) ? originObject.key : null,
      quantity: (conn.has === 'many') ? 'many' : subConn.has,
      label,
      labelParts: {
        from: labelFrom,
        to: labelTo,
        parent: labelToParent,
      },
      conns: [
        getConnectionNameParts(objectsByFieldKey, conn.key),
        getConnectionNameParts(objectsByFieldKey, subConn.key),
      ],
      connections: {
        direct: {
          object: {
            key: connObject.key,
            name: connObject.name,
            inflections: connObject.inflections,
          },
          field: {
            key: subConn.key,
            names: getConnectionNameParts(objectsByFieldKey, subConn.key),
          },
          relationship_type: subConn.relationship_type,
        },
        parent: {
          object: {
            key: originObject.key,
            name: originObject.name,
            inflections: originObject.inflections,
          },
          field: {
            key: conn.key,
            names: getConnectionNameParts(objectsByFieldKey, conn.key),
          },
        },
      },
      source,
      icon: getViewSourceOptionIcon(subConnObject),
    };

    options.push(option);
  });
}

/**
 * Gets every object view source option.
 *
 * @returns {object[]} - The array of options.
 */
function getEveryObjectViewSourceOptions(inflection = 'plural') {
  const objects = [
    ...store.getters.standardObjects,
    store.getters.accountObject,
    ...store.getters.roleObjects,
    ...store.getters.ecommerceObjects,
  ].filter((obj) => !isEmpty(obj));

  return objects.map((obj) => ({
    objectKey: obj.key,
    type: 'all',
    quantity: 'many',
    label: `<b class="source-label">${obj.inflections[inflection]}</strong>`,
    labelParts: {
      from: obj.inflections[inflection],
    },
    source: {
      object: obj.key,
    },
    icon: getViewSourceOptionIcon(obj),
  }));
}

function getUserViewSourceOptions(
  objectsByFieldKey,
  authenticationProfiles,
  inflection = 'plural',
) {
  const profiles = [
    ...(authenticationProfiles || []),
    'all_users',
  ];

  const options = [];

  profiles.forEach((role) => {
    const roleObject = store.getters.getObjectByRole(role);

    if (!roleObject) {
      return;
    }

    const singularInflection = roleObject.inflections.singular;

    const option = {
      objectKey: roleObject.key,
      type: 'user',
      roleKey: roleObject.key,
      quantity: 'one',
      label: `the <b class="source-label">logged-in ${singularInflection}</strong> record`,
      source: {
        object: roleObject.key,
        authenticated_user: true,
      },
      labelParts: {
        from: `the logged-in <strong>${singularInflection}</strong>`,
      },
      icon: getViewSourceOptionIcon(roleObject),
    };

    options.push(option);

    // connections
    getUserConnectedViewSourceOptions(objectsByFieldKey, roleObject, options, inflection);
  });

  return options;
}

function getUserConnectedViewSourceOptions(
  objectsByFieldKey,
  roleObject,
  options,
  inflection = 'plural',
) {
  if (!roleObject || !roleObject.conns) {
    return;
  }

  roleObject.conns.forEach((conn) => {
    const connObject = store.getters.getObject(conn.object);
    let labelFrom = connObject.inflections[inflection];

    // the local connection name will be more useful
    if (conn.relationship_type === 'local' && conn.name.indexOf(connObject.inflections.singular) === -1 && conn.name !== connObject.inflections.plural) {
      labelFrom = conn.name;
    }

    const labelTo = `the logged-in <strong>${roleObject.inflections.singular}</strong>`;

    const option = {
      objectKey: conn.object,
      type: 'user',
      roleKey: roleObject.key,
      quantity: conn.has,
      label: `<b class="source-label">${labelFrom}</strong> connected to ${labelTo}`,
      labelParts: {
        from: labelFrom,
        to: labelTo,
      },
      conns: [
        getConnectionNameParts(objectsByFieldKey, conn.key),
      ],
      connections: {
        direct: {
          object: {
            key: roleObject.key,
            name: roleObject.name,
            inflections: roleObject.inflections,
          },
          field: {
            key: conn.key,
            names: getConnectionNameParts(objectsByFieldKey, conn.key),
          },
          relationship_type: conn.relationship_type,
        },
      },
      source: {
        object: conn.object,
        connection_key: conn.key,
        relationship_type: conn.relationship_type,
        authenticated_user: true,
      },
      icon: getViewSourceOptionIcon(connObject),
    };

    options.push(option);

    // further connections
    getFurtherConnectedViewSourceOptions(options, conn, connObject, true, roleObject, inflection, objectsByFieldKey);
  });
}

export function getSourcesForView(viewType, objectsWithDateFields, objectsWithAddressFields, recordsQuantity, formQuantity, sources, pageViewSourceOption) {
  const obj = store.getters.getObject(pageViewSourceOption.objectKey);

  // if we need a specific records quantity, ignore non-matches
  if (viewType !== 'form' && recordsQuantity !== 'all' && pageViewSourceOption.quantity !== recordsQuantity) {
    return;
  }

  // if this is for a form, match with the form quantity (create = many, update = one)
  if (viewType === 'form' && pageViewSourceOption.quantity !== formQuantity) {
    return;
  }

  // E-Commerce
  const viewDoesCreateCharge = viewType === 'checkout' || viewType === 'charge';

  const doesNotHaveNumericFields = isEmpty(obj.getEcommerceFields());

  const isNotConnectedOrHasNonLocalRelationship = !pageViewSourceOption.source.connection_key || pageViewSourceOption.source.relationship_type !== 'local';

  if (viewDoesCreateCharge && doesNotHaveNumericFields && isNotConnectedOrHasNonLocalRelationship) {
    return;
  }

  if (viewType === 'form') {
    if (obj.ecommerce || obj.ecommercePaymentMethods) {
      return;
    }
  }

  // calendars can only show objects that have date fields
  if (viewType === 'calendar') {
    if (_.isNil(objectsWithDateFields[pageViewSourceOption.objectKey])) {
      objectsWithDateFields[pageViewSourceOption.objectKey] = store.getters.getObject(pageViewSourceOption.objectKey).hasDateField();
    }

    if (!objectsWithDateFields[pageViewSourceOption.objectKey]) {
      return;
    }
  }

  // maps can only show objects that have address fields
  if (viewType === 'map') {
    if (_.isNil(objectsWithAddressFields[pageViewSourceOption.objectKey])) {
      objectsWithAddressFields[pageViewSourceOption.objectKey] = store.getters.getObject(pageViewSourceOption.objectKey).hasAddressField();
    }

    if (!objectsWithAddressFields[pageViewSourceOption.objectKey]) {
      return;
    }
  }

  addViewSourcePath(sources, pageViewSourceOption, recordsQuantity);

  return sources;
}

export function addViewSourcePath(sources, pageViewSourceOption, recordsQuantity) {
  const inflectionsType = (recordsQuantity === 'one') ? 'singular' : 'plural';

  if (!sources[pageViewSourceOption.objectKey]) {
    sources[pageViewSourceOption.objectKey] = {
      options: {},
      optionCount: 0,
      objectKey: pageViewSourceOption.objectKey,
      name: store.getters.getObject(pageViewSourceOption.objectKey).inflections[inflectionsType],
      icon: pageViewSourceOption.icon,
      quantity: pageViewSourceOption.quantity,
    };
  }

  // generate pageViewSourceOption key
  // if to sources have the same key we need to disambiguate by revealing the connection
  let pageViewSourceOptionKey = pageViewSourceOption.objectKey;

  if (pageViewSourceOption.connections && pageViewSourceOption.connections.direct) {
    pageViewSourceOptionKey += `:${pageViewSourceOption.connections.direct.object.key}`;

    if (pageViewSourceOption.connections.parent) {
      pageViewSourceOptionKey += `:${pageViewSourceOption.connections.parent.object.key}`;
    }
  }

  if (!sources[pageViewSourceOption.objectKey].options) {
    sources[pageViewSourceOption.objectKey].options = {};
  }

  if (!sources[pageViewSourceOption.objectKey].options[pageViewSourceOptionKey]) {
    sources[pageViewSourceOption.objectKey].pageViewSourceOptionCount++;

    sources[pageViewSourceOption.objectKey].options[pageViewSourceOptionKey] = {
      object: pageViewSourceOptionKey,
      quantity: pageViewSourceOption.quantity,
      label: `Use ${pageViewSourceOption.label}`,
      labelParts: pageViewSourceOption.labelParts,
      count: 0,
      user: (pageViewSourceOption.type === 'user'),
      relationship_type: pageViewSourceOption.source.relationship_type,
      connections: {
        direct: [],
        parent: [],
      },
    };
  }

  if (!pageViewSourceOption.connections) {
    return;
  }

  if (!sources[pageViewSourceOption.objectKey].options[pageViewSourceOptionKey].connections.direct.find((conn) => conn.field.key === pageViewSourceOption.connections.direct.field.key)) {
    sources[pageViewSourceOption.objectKey].options[pageViewSourceOptionKey].connections.direct.push(pageViewSourceOption.connections.direct);
  }

  if (pageViewSourceOption.connections.parent && !sources[pageViewSourceOption.objectKey].options[pageViewSourceOptionKey].connections.parent.find((conn) => conn.field.key === pageViewSourceOption.connections.parent.field.key)) {
    sources[pageViewSourceOption.objectKey].options[pageViewSourceOptionKey].connections.parent.push(pageViewSourceOption.connections.parent);
  }
}
