<script setup>
import { computed, defineProps } from 'vue';

import { FontAwesomeIcons } from '@/constants/fontAwesome';

const props = defineProps({
  name: {
    type: String,
    required: true,
    validator(val) {
      if (val) {
        const name = val.replace(/^fa-/, '');

        if (!FontAwesomeIcons.includes(name)) {
          console.warn( // eslint-disable-line no-console
            `Invalid prop: prop "name" is referring to an unregistered icon "${name}".\n`
            + 'Please make sure you have imported this icon before using it.',
          );
          return false;
        }

        return true;
      }
      return true;
    },
  },
});

const sanitizedIconName = computed(() => props.name?.replace(/^fa-/, ''));

const faClasses = computed(() => [
  'fa',
  `fa-${sanitizedIconName.value}`,
]);
</script>

<script>
export default {
  name: 'FontAwesome',
};
</script>

<template>
  <i :class="faClasses" />
</template>

<style lang="scss" scoped>
.fa {
  font-size: 1.25em;
}
</style>
