<template>
  <div
    class="reports-wrapper"
    :class="viewClass"
  >
    <GlobalLoading
      :is-loading="isLoading"
      :local="true"
    />

    <!-- EMPTY -->
    <template v-if="chartsAreEmpty">
      <div class="margin-bottom-double padding">
        <EmptyFieldsDropZone
          class="drop-target"
          :class="{'is-dragging-over': isDraggingChart}"
          buildable="report"
          items-alias="a chart"
          :build-path="addChartPath"
          :is-dragging-field="isDraggingChart"
          @dragover="onDragOver"
          @dragenter="onDragEnter"
          @dragleave="onDragLeave"
          @drop="onDrop"
        />
      </div>
    </template>

    <template
      v-for="(row, rowIndex) in view.rows"
      v-else
      :key="rowIndex"
    >
      <div
        class="kn-item-row level-left draggable-inputs"
        style="align-items: flex-start;"
      >
        <div
          class="drop-target horizontal top"
          :data-row="rowIndex"
          :data-item="0"
          @dragover="onDragOver"
          @dragenter="onDragEnter"
          @dragleave="onDragLeave"
          @drop="onDrop"
        >
          <div class="line" />
        </div>

        <div
          class="drop-target horizontal bottom"
          :data-row="rowIndex+1"
          :data-item="0"
          @dragover="onDragOver"
          @dragenter="onDragEnter"
          @dragleave="onDragLeave"
          @drop="onDrop"
        >
          <div class="line" />
        </div>

        <!-- Report -->
        <template
          v-for="(report, itemIndex) in row.reports"
          :key="itemIndex"
        >
          <RouterLink
            v-slot="{ isActive, navigate }"
            :to="routePrefix + '/rows/' + rowIndex + '/reports/' + itemIndex"
            custom
          >
            <div
              class="item-wrapper"
              :class="{'router-link-active': isActive}"
              :data-row="rowIndex"
              :data-item="itemIndex"
              draggable="true"
              @click="navigate"
              @dragstart="onDragStart"
              @dragend="onDragEnd"
            >
              <div
                v-if="row.reports.length < 3"
                class="drop-target vertical left"
                :data-row="rowIndex"
                :data-item="itemIndex"
                @dragover="onDragOver"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @drop="onDrop"
              />
              <div
                v-if="row.reports.length < 3"
                class="drop-target vertical right"
                :data-row="rowIndex"
                :data-item="itemIndex + 1"
                @dragover="onDragOver"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @drop="onDrop"
              />

              <div class="kn-item">
                <h3
                  v-if="report.title"
                  class="kn-title"
                >
                  {{ report.title }}
                </h3>
                <p
                  v-if="report.description"
                  class="kn-description"
                >
                  {{ report.description }}
                </p>

                <!-- Filters, print, download, show/hide data table -->
                <RecordNavigation
                  v-if="hasReportData(getReportData(rowIndex, itemIndex))"
                  :object-key="report.source.object"
                  :allow-limit="false"
                  :show-keyword-search="false"
                  :show-export-button="false"
                  :show-filters="report.filters.filter_type === `fields`"
                  :show-filter-menu="report.filters.filter_type === `menu`"
                  :filter-menu-links="report.filters.menu_filters"
                  :show-pagination="false"
                />

                <div v-if="shouldRenderOptionsPreviews(report, getReportData(rowIndex, itemIndex))">
                  <a
                    v-if="report.options.export_links"
                    href="#"
                    class="underline"
                    style="margin-right: .5em;"
                  >print</a>
                  <a
                    v-if="report.options.export_links"
                    href="#"
                    class="underline"
                    style="margin-right: .5em;"
                  >download</a>
                  <a
                    v-if="report.options.shouldShowDataTable"
                    href="#"
                    class="underline"
                  >show/hide data table</a>
                </div>

                <PivotTable
                  v-if="report.type === 'table'"
                  :report="report"
                  :report-data="getReportData(rowIndex, itemIndex)"
                  :view="view"
                  :row-index="rowIndex"
                  :report-index="itemIndex"
                />
                <Chart
                  v-else
                  :report="report"
                  :report-data="getReportData(rowIndex, itemIndex)"
                  :is-loading="isLoading"
                  :view="view"
                  :row-index="rowIndex"
                  :report-index="itemIndex"
                  :should-show-data-table="report.options.shouldShowDataTable"
                />

                <div
                  class="overlay"
                  @click="activateItem(rowIndex, itemIndex)"
                />
                <div class="item-links gap-1 bg-subtle rounded-t-lg border-b-0 p-1">
                  <RouterLink
                    v-tippy
                    content="Edit this report"
                    class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
                    :to="`${routePrefix}/rows/${rowIndex}/reports/${itemIndex}`"
                  >
                    <Icon
                      class="text-default"
                      type="edit"
                    />
                  </RouterLink>
                  <a
                    v-tippy
                    content="Delete this report"
                    class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
                    @click.prevent="onDeleteItem(rowIndex, itemIndex)"
                  >
                    <Icon
                      class="text-default"
                      type="delete"
                    />
                  </a>
                </div>
              </div>
            </div>
          </RouterLink>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import Chart from '@/components/renderer/report/Chart';
import DragDropUtils from '@/components/renderer/mixins/DragDropUtils';
import EmptyFieldsDropZone from '@/components/renderer/shared/EmptyFieldsDropZone';
import GlobalLoading from '@/components/ui/GlobalLoading';
import Icon from '@/components/ui/Icon';
import PivotTable from '@/components/renderer/report/PivotTable';
import RecordNavigation from '@/components/renderer/shared/RecordNavigation';
import RenderUtils from '@/components/renderer/RenderUtils';
import RequestUtils from '@/components/util/RequestUtils';
import StringUtils from '@/components/util/StringUtils';

import { defaultReport } from '@/lib/schema-helper';
import { eventBus } from '@/store/bus';

export default {
  name: 'Report',
  components: {
    EmptyFieldsDropZone,
    Chart,
    GlobalLoading,
    Icon,
    PivotTable,
    RecordNavigation,
  },
  mixins: [
    StringUtils,
    DragDropUtils,
    RenderUtils,
    RequestUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeDragSlot: null,
      isLoading: false,
    };
  },
  computed: {

    groups() {
      return this.view.groups;
    },

    chartsAreEmpty() {
      // return true if there are no rows that have at least one report
      const noRows = !this.view?.rows;
      return noRows || this.view.rows.every((row) => isEmpty(row.reports));
    },

    isDraggingChart() {
      return this.$store.getters.isDraggingViewItem(this.view.key);
    },

    addChartPath() {
      return this.routePrefix;
    },

    viewClass() {
      const classes = {};

      // classes[this.view.get('design').size.value + '-inputs'] = true

      return classes;
    },
    object() {
      return this.$store.getters.getObject(this.view.get('source').object);
    },
  },
  mounted() {
    return this.$nextTick(() => this.initDragDrop());
  },
  methods: {
    isNewGroupSlot(groupReportsLength, index) {
      return groupReportsLength > 1 && (index === 0 || (index > 0 && this.view.groups[index - 1].columns.length > 1));
    },
    isLastGroupSlot(groupReportsLength, index) {
      return index === this.view.groups.length - 1 && groupReportsLength > 1;
    },

    getReportData(rowIndex, itemIndex) {
      if (!hasIn(this.view, 'data.reports')) {
        return {
          filters: [],
          records: [],
          summaries: [],
        };
      }

      const reportIndex = this.view.getReportIndex(rowIndex, itemIndex);

      return this.view.data.reports[reportIndex];
    },

    onDropViewItem(event) {
      event.preventDefault();

      event.stopPropagation();

      const rowIndex = parseInt(event.target.getAttribute('data-row'), 10);
      const itemIndex = parseInt(event.target.getAttribute('data-item'), 10);

      let newItem = event.dataTransfer.getData('new');

      const oldRowIndex = Number(event.dataTransfer.getData('row'));
      const oldItemIndex = Number(event.dataTransfer.getData('item'));

      log('onDrop! from row/item', oldRowIndex, oldItemIndex, 'to new row/item', rowIndex, itemIndex, 'event', event, event.target.getAttribute('data-row'));

      let oldItems; let
        item;

      // set the item to add (could be from an insert or drag)
      if (newItem) {
        log('newItem ! ! ! ', newItem);

        // New reports are dragged here as types, so let's add it
        if (typeof newItem === 'string') {
          const reportType = newItem;

          newItem = defaultReport(reportType, this.view.source);
        }

        item = newItem;
      } else {
        // extract item from old column
        item = this.view.rows[oldRowIndex].reports.splice(oldItemIndex, 1)[0];
        oldItems = this.view.rows[oldRowIndex].reports.splice(0);
      }

      // reset data
      // remove from old?
      let reportData = {};

      if (!newItem) {
        const oldReportIndex = this.view.getReportIndex(oldRowIndex, oldItemIndex);

        reportData = this.view.data.reports.splice(oldReportIndex, 1)[0];
      }

      // insert new data
      const reportIndex = this.view.getReportIndex(rowIndex, itemIndex);

      // splice in
      this.view.data.reports.splice(parseInt(reportIndex, 10), 0, reportData);

      // if reports is empty, we'll set some defaults to this item and be done
      if (this.view.rows.length === 0) {
        this.view.rows.splice(0, 0, {
          reports: [
            item,
          ],
        });

        return this.cleanupReportItems(0, 0, newItem);
      }

      // insert a new row
      if (event.target.classList.contains('horizontal')) {
        log('TYPE::: row-new', item);

        // add old items back
        if (oldItems) {
          this.view.rows[oldRowIndex].reports = oldItems;
        }

        // add new group
        this.view.rows.splice(rowIndex, 0, {
          reports: [
            item,
          ],
        });

        return this.cleanupReportItems(rowIndex, itemIndex, newItem);
      }

      // moving within the same row
      if (!newItem && oldRowIndex === rowIndex) {
        log('TYPE::: same row!');

        if (oldItemIndex < itemIndex) {
          // itemIndex--
        }

        // extract items from column and insert active item
        if (oldItems) {
          oldItems.splice(itemIndex, 0, item);
          this.view.rows[oldRowIndex].reports = oldItems;
        }

        return this.cleanupReportItems(rowIndex, itemIndex, newItem);
      }

      // move into another existing column
      log('TYPE::: existing');

      // extract items from new column and insert active item
      const newItems = this.view.rows[rowIndex].reports.splice(0);

      newItems.splice(itemIndex, 0, item);

      // add old items back
      if (oldItems) {
        this.view.rows[oldRowIndex].reports = oldItems;
      }

      this.view.rows[rowIndex].reports = newItems;

      this.setColumnLayout(rowIndex);

      return this.cleanupReportItems(rowIndex, itemIndex, newItem);
    },

    async cleanupReportItems(rowIndex, itemIndex, newItem) {
      // this.clearEmptyReportRows()

      log(`cleanupReportItems: rowIndex ${rowIndex}, itemIndex ${itemIndex}`, newItem, this.view.rows);

      // if new, tell new report to load in data
      if (newItem) {
        await this.$nextTick();

        eventBus.$emit(`reloadReportData.${this.view.key}`, {
          rowIndex,
          reportIndex: itemIndex,
        });
      }

      // if we moved an active item, we need reactive it from its new index
      this.$router.push(`${this.routePrefix}/rows/${rowIndex}/reports/${itemIndex}`);

      return this.view.rows;
    },

    clearEmptyReportRows() {
      // clear any empty groups and columns
      this.view.rows = this.view.attributes.rows.filter((row) => row.reports && row.reports.length);
    },

    activateItem(rowIndex, columnIndex) {
      this.$router.push(`${this.routePrefix}/rows/${rowIndex}/reports/${columnIndex}`);
    },

    onDeleteItem(rowIndex, itemIndex) {
      this.view.rows[rowIndex].reports.splice(itemIndex, 1);

      // remove from report data
      const reportIndex = this.view.getReportIndex(rowIndex, itemIndex);

      this.view.data.reports.splice(reportIndex, 1);

      this.clearEmptyReportRows();

      this.setColumnLayout(rowIndex);

      this.$router.push(this.routePrefix);
    },

    calculateColumnLayout(numberOfColumns) {
      if (numberOfColumns > 3) {
        numberOfColumns = 3;
      }

      const columnLayout = {
        1: 'one-col',
        2: 'half',
        3: 'thirds',
      };

      return columnLayout[numberOfColumns];
    },

    setColumnLayoutView(index, columnLayout) {
      this.view.rows[index].layout = columnLayout;
    },

    setColumnLayout(rowIndex) {
      // Ignore if this row was deleted
      if (!this.view.rows[rowIndex]) {
        return;
      }

      const reportsLength = this.view.rows[rowIndex].reports.length;
      const columnLayout = this.calculateColumnLayout(reportsLength);
      this.setColumnLayoutView(rowIndex, columnLayout);
    },

    hasReportData(reportData) {
      return !isEmpty(reportData) && !isEmpty(reportData.records);
    },

    shouldRenderOptionsPreviews(report, reportData) {
      const shouldRenderOptionsLinks = report.options.export_links || report.options.shouldShowDataTable;
      const isReportDataRenderable = this.hasReportData(reportData) && hasIn(this.view, 'data.reports') && !hasIn(reportData, 'max_data');

      return shouldRenderOptionsLinks && isReportDataRenderable;
    },
  },
};
</script>

<style lang="scss">
.kn-report {

  .reports-wrapper {
    position: relative;
    min-width: 400px;
  }

  .kn-item-row {
    position: relative;
    padding: 10px;
  }

  .kn-item-row .item-wrapper:first-child .kn-item {
    padding-left: 5px;
    margin-left: 10px;
  }

  .drop-target.horizontal {
    padding: 0;
    bottom: inherit;
  }

  .drop-target.horizontal.top {
    width: 100%;
    height: 25px;
    z-index: 1001;
  }

  .drop-target.horizontal.bottom {
    width: 100%;
    height: 25px;
    z-index: 1001;
    bottom: 0;
    top: inherit;
  }

  .drop-target.vertical.left {
    width: 50%;
    height: 100%;
    z-index: 1000;
  }

  .drop-target.vertical.right {
    width: 50%;
    height: 100%;
    z-index: 1000;
    right: 0;
  }
}
</style>
