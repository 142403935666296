<template>
  <div>
    <p>Updating a user's status can affect how they can access the app.</p>
    <form>
      <div class="mb-0">
        <label class="text-default text-sm text-medium mb-2">New Status</label>
        <div class="input-labels tw-input-labels">
          <label class="flex items-baseline text-emphasis text-base">
            <input
              type="radio"
              value="active"
              name="status"
              :checked="accountStatus === 'active'"
              @change="$emit('update:accountStatus', $event.target.value)"
            >
            Active
          </label>
          <label class="flex items-baseline text-emphasis text-base">
            <input
              type="radio"
              value="inactive"
              name="status"
              :checked="accountStatus === 'inactive'"
              @change="$emit('update:accountStatus', $event.target.value)"
            >
            Inactive
          </label>
          <label class="flex items-baseline text-emphasis text-base">
            <input
              type="radio"
              value="pending"
              name="status"
              :checked="accountStatus === 'pending'"
              @change="$emit('update:accountStatus', $event.target.value)"
            >
            Pending Approval
          </label>
        </div>
      </div>
      <div
        v-if="accountStatus === `active`"
        class="mb-0 mt-4"
      >
        <div class="input-labels">
          <label class="flex items-baseline text-emphasis text-base">
            <input
              type="checkbox"
              :value="sendApprovalEmail"
              :checked="sendApprovalEmail"
              @change="$emit('update:sendApprovalEmail', $event.target.checked)"
            >
            Send approval emails to selected users
          </label>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    accountStatus: {
      type: String,
      required: true,
    },
    sendApprovalEmail: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:accountStatus', 'update:sendApprovalEmail'],
};
</script>
