<template>
  <Modal
    title="Copy Table Data"
    :back="backLink"
  >
    <div
      ref="copy-object"
      class="section-wrapper"
    >
      <div
        v-if="errors.length"
        class="error-alert form-errors rounded-lg p-4 bg-destructive mb-6 border-0"
      >
        <div class="mb-1">
          <Icon
            class="block text-destructive-emphasis h-4 w-4"
            type="exclamation-triangle"
          />
        </div>
        <b class="text-destructive-emphasis text-base">Please correct the following errors:</b>
        <ul class="pl-[22px] text-destructive-emphasis text-base">
          <li
            v-for="(error, errorIndex) in errors"
            :key="errorIndex"
            v-html="error.message || error"
          />
        </ul>
      </div>

      <WizardStep
        id="choose-object-target"
        v-model:wizard-step="wizardStep"
        :step="1"
        class="first"
      >
        <template #intro>
          <div class="mb-2">
            Copy Fields from {{ object.name }} to?
          </div>
        </template>

        <template
          v-if="isNewObject"
          #edit
        >
          <div class="mb-6">
            Copying <strong>{{ object.name }}</strong> fields into new table <strong>{{ newObjectName }}</strong>
          </div>
        </template>
        <template
          v-else
          #edit
        >
          <div class="mb-6">
            Copying <strong>{{ object.name }}</strong> fields into existing table <strong>{{ existingObject.name }}</strong>
          </div>
        </template>

        <ObjectCopyTarget
          v-model:is-new-object="isNewObject"
          :new-object-name="newObjectName"
          :existing-object-key="existingObject.key"
          @submit="onSetTargetObject"
        />
      </WizardStep>

      <WizardStep
        v-if="wizardStep > 1"
        id="choose-fields"
        v-model:wizard-step="wizardStep"
        :step="2"
      >
        <template #intro>
          <div class="mb-2">
            Select Fields to Copy
          </div>
        </template>

        <ObjectCopyFields
          v-model:is-new-object="isNewObject"
          :existing-object="existingObject"
          @submit="onSelectFieldsToCopy"
        />
      </WizardStep>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { sanitizeObjectName } from '@/util/FormatHelper';
import Modal from '@/components/ui/Modal';
import FieldUtils from '@/components/fields/FieldUtils';
import WizardStep from '@/components/ui/WizardSection';
import ObjectCopyTarget from '@/components/objects/ObjectCopyTarget';
import ObjectCopyFields from '@/components/objects/ObjectCopyFields';
import Icon from '@/components/ui/Icon';
import RequestUtils from '@/components/util/RequestUtils';
import Obj from '@/store/models/Object';

export default {
  name: 'ObjectCopy',
  components: {
    Modal,
    WizardStep,
    ObjectCopyTarget,
    ObjectCopyFields,
    Icon,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  props: {
    baseUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isNewObject: true,
      newObjectName: '',
      existingObject: {},
      fieldsToCopy: [],
      wizardStep: 1,
    };
  },
  computed: {
    ...mapGetters([
      'objects',
      'app',
    ]),
    backLink() {
      return `${this.baseUrl}`;
    },
  },
  created() {
    this.newObjectName = `${this.object.get('name')} Copy`;

    let defaultObject = this.$store.getters.objects[0];

    if ((defaultObject.key === this.object.key) && !isEmpty(this.$store.getters.objects[1])) {
      defaultObject = this.$store.getters.objects[1];
    }

    this.existingObject = defaultObject;
  },
  methods: {
    onSetTargetObject({ isNewObject, newObjectName, existingObjectKey }) {
      log('onSetTargetObject', isNewObject, newObjectName, existingObjectKey);

      this.isNewObject = isNewObject;
      this.newObjectName = newObjectName;

      if (!this.isNewObject) {
        this.existingObject = this.objects.find((object) => object.key === existingObjectKey);

        // the main account object needs to copy any user role fields automatically
        // TODO: this needs testing, not sure if profile objects also need this
        if (this.object.profile_key === 'all_users') {
          this.fieldsToCopy = this.object.getUserRoleFields();
        }
      }

      return this.wizardStep++;
    },
    onSelectFieldsToCopy(selectedFields) {
      if (this.isNewObject) {
        const newObject = new Obj({
          name: sanitizeObjectName(this.newObjectName),
        });

        this.commitRequest({
          validate: () => newObject.validate(),
          request: () => this.object.copy(newObject.name, selectedFields),
          onSuccess: ({ object }) => {
            const redirectUrl = this.baseUrl.replace(this.object.key, object.key);

            this.$router.push(`${redirectUrl}`);
          },
        });
      } else {
        const { existingObject } = this;

        this.commitRequest({
          request: () => this.object.copyFields(existingObject.key, existingObject.name, selectedFields),
          onSuccess: ({ object }) => {
            const redirectUrl = this.baseUrl.replace(this.object.key, existingObject.key);

            this.$router.push(`${redirectUrl}`);
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
</style>
