<template>
  <Modal
    title="Add a Link"
    size="small"
    @close="onClose"
  >
    <form>
      <div>
        <label class="text-default text-sm font-medium mb-2 leading-4">Page to link to</label>
        <select
          v-model="linkedPage"
          class="text-base py-2 pl-3 leading-5 mb-2"
        >
          <option
            v-for="page in linkedPages"
            :key="page.key"
            :value="page.page"
          >
            {{ page.label }}
          </option>
        </select>
      </div>
      <div class="save flex justify-end">
        <a
          class="button save text-white px-3 py-2 bg-gradient-primary rounded-lg border-0 text-base font-medium"
          @click="onSelectLinkedPage"
        >
          Save
        </a>
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';

export default {
  components: {
    Modal,
  },
  props: {
    objectKey: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'onSelectLinkedPage'],
  data() {
    return {
      linkedPage: null,
    };
  },
  computed: {

    // used by modal for adding a new column to link to an existing page
    linkedPages() {
      return this.$store.getters.getDetailPagesByObject(this.objectKey);
    },
  },
  watch: {
    linkedPages(newValue) {
      this.linkedPage = newValue[0].page;
    },
  },
  mounted() {
    this.linkedPage = this.linkedPages[0].page;
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSelectLinkedPage() {
      const linkedPage = this.$store.getters.getPageBySlug(this.linkedPage);

      this.$emit('onSelectLinkedPage', linkedPage);
    },
  },
};
</script>
