<template>
  <div
    v-if="isLoading"
    class="loading__wrapper"
  >
    <div class="loading__spinner" />
  </div>
</template>

<script>
export default {
  props: [
    'isLoading',
  ],
};
</script>

<style scoped lang="scss">
.loading {
  &__wrapper {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:10;
    background-color:rgba(255, 255, 255, .5);
    display:flex;
    align-items:center;
    justify-content:center;
  }
  &__spinner {
    background: #000 url(../../../assets/spinner-light.gif) no-repeat 50%;
    width:50px;
    height:50px;
    box-shadow: 0 2px 4px #333;
    opacity: .85;
    border-radius: 10px;
  }
}
</style>
