<template>
  <ViewToolbox
    back-title="Edit Grid"
    title="Settings"
  >
    <div>
      <label class="text-default text-base font-semibold leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Options</label>
      <div class="input-labels">
        <label class="text-emphasis text-base font-normal items-baseline"><input
          v-model="viewRaw.keyword_search"
          type="checkbox"
        >Keyword search</label>
        <template v-if="viewRaw.keyword_search && isSQL">
          <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label mt-4">Which fields can be searched?</label>
          <select
            v-model="viewRaw.keyword_search_fields"
            class="margin-bottom text-base py-2 pl-3 leading-5"
          >
            <option value="view">
              The fields being used by this view
            </option>
            <option value="all">
              All fields in table
            </option>
          </select>
        </template>
        <div class="tw-input-labels">
          <label><input
            v-model="viewRaw.allow_exporting"
            type="checkbox"
          >Record exporting</label>
          <label><input
            v-model="cellEditor"
            type="checkbox"
          >Inline editing</label>
          <label><input
            v-model="viewRaw.hide_empty"
            type="checkbox"
          >Hide empty columns</label>
        </div>
      </div>
    </div>

    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Empty Grid Text <HelpIcon copy="Text to show when the grid<br />has no records to display" />
      </label>
      <input
        v-model="viewRaw.no_data_text"
        type="text"
      >
    </div>

    <div class="mb-0 pb-6 border border-solid border-subtle border-x-0 border-t-0">
      <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Column Summaries</label>

      <ActionList
        v-if="totals.length > 0"
        :items="totals"
        class="is-grouped bg-subtle rounded-lg p-4"
        :default-item="defaultColumnSummary"
        :can-be-empty="true"
        @update:items="onUpdateColumnSummaries"
      >
        <template #default="props">
          <input
            v-model="props.item.label"
            type="text"
          >
          <select
            v-model="props.item.calc"
            class="text-base py-2 pl-3 leading-5 ml-0"
            style="margin-left: 5px;"
          >
            <option value="sum">
              Sum
            </option>
            <option value="average">
              Average
            </option>
            <option value="min">
              Minimum
            </option>
            <option value="max">
              Maximum
            </option>
          </select>
        </template>
      </ActionList>
      <div v-if="totals.length === 0">
        <p class="margin-bottom-half">
          Show summaries of each column at the bottom of the grid.
        </p>
        <a
          class="button tiny fuchsia-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium"
          @click="onAddColumnSummary"
        >
          <Icon
            class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
            type="plus-thin"
          />
          Add Summary
        </a>
      </div>
    </div>

    <Toggle
      :is-open="true"
      class="margin-top-double mt-2.5"
    >
      <template #title>
        <span>Pagination</span>
      </template>

      <template #content>
        <Pagination
          v-model:rows-per-page="viewRaw.rows_per_page"
          v-model:allow-limit="viewRaw.allow_limit"
          v-model:display-below="viewRaw.display_pagination_below"
        />
      </template>
    </Toggle>

    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Filter Options</span>
      </template>

      <template #content>
        <Filters
          v-model:filter-type="viewRaw.filter_type"
          v-model:filter-fields="viewRaw.filter_fields"
          v-model:preset-filters="viewRaw.preset_filters"
          v-model:allow-preset-filters="viewRaw.allow_preset_filters"
          v-model:menu-filters="viewRaw.menu_filters"
          v-model:connection-sources="viewRaw.filter_connection_sources"
          :object="object"
        />
      </template>
    </Toggle>

    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Title & Description</span>
      </template>

      <template #content>
        <div class="grid-verticalGaps border border-solid border-subtle border-x-0 border-t-0">
          <div>
            <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Title</label>
            <input
              v-model="viewRaw.title"
              type="text"
            >
          </div>
          <div>
            <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Description</label>
            <textarea v-model="viewRaw.description" />
          </div>
        </div>
      </template>
    </Toggle>

    <!-- Grid Design -->
    <template v-if="!isLegacyTheme">
      <Toggle
        :is-open="true"
        class="mt-2.5"
      >
        <template #title>
          <span>Grid Design</span>
        </template>
        <template #content>
          <ToggleSwitch
            v-model:checked="showTableDesignOptions"
            label="Use Custom Design Settings"
          >
            <template #offDescription>
              <span class="text-default">
                This grid uses the
                <router-link
                  to="/settings/design/tables"
                  class="text-default underline"
                >
                  global grid design settings.
                </router-link>
              </span>
            </template>

            <template #description>
              <span class="text-default">
                This grid overrides the
                <router-link
                  to="/settings/design/tables"
                  class="text-default underline"
                >
                  global grid design settings
                </router-link>
                with the following settings:
              </span>
            </template>
          </ToggleSwitch>

          <TablesOptions
            v-if="showTableDesignOptions"
            :custom-design-object="viewRaw.table_design"
          />
        </template>
      </Toggle>
    </template>
  </ViewToolbox>
</template>

<script>
import { cloneDeep } from 'lodash';
import ActionList from '@/components/ui/lists/ActionList';
import Filters from '@/components/views/shared/settings/Filters';
import HelpIcon from '@/components/ui/HelpIcon';
import Pagination from '@/components/views/shared/settings/Pagination';
import TablesOptions from '@/components/settings/design/options/TablesOptions';
import Toggle from '@/components/ui/Toggle';
import ToggleSwitch from '@/components/ui/ToggleSwitch';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import Icon from '@/components/ui/Icon';

export default {
  name: 'TableSettings',
  components: {
    ActionList,
    Filters,
    HelpIcon,
    Pagination,
    TablesOptions,
    Toggle,
    ToggleSwitch,
    ViewToolbox,
    Icon,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    isLegacyTheme() {
      return this.$store.getters.app.getThemeLevel() < 2;
    },
    isSQL() {
      return this.$store.getters.app.isSQL();
    },
    showTableDesignOptions: {
      get() {
        return !!this.viewRaw?.table_design_active;
      },
      set(newValue) {
        this.viewRaw.table_design_active = newValue;

        // if turning on custom Grid design settings for the first time,
        // clone settings from the existing global grid design
        if (newValue && !this.viewRaw.table_design) {
          const appDesign = this.$store.getters.app.get('design');
          this.viewRaw.table_design = cloneDeep(appDesign.general.tables);
        }
      },
    },
    totals: {
      get() {
        return this.viewRaw?.totals ?? [];
      },
      set(newTotals) {
        this.view.attributes.totals = newTotals;
      },
    },
    customFilters() {
      return (
        this.viewRaw.preset_filters
        && this.viewRaw.allow_preset_filters
      ) ? this.viewRaw.preset_filters : [];
    },
    cellEditor: {
      get() {
        return this.view?.get('options.cell_editor') || false;
      },
      set(newVal) {
        if (!this.viewRaw.options) {
          this.viewRaw.options = {};
        }

        this.viewRaw.options.cell_editor = newVal;
      },
    },
  },
  methods: {
    defaultColumnSummary() {
      return {
        label: 'Total',
        calc: 'sum',
      };
    },
    onAddColumnSummary() {
      this.viewRaw.totals.push(this.defaultColumnSummary());
    },
    onUpdateColumnSummaries(newValue) {
      this.onUpdateViewProperty({
        propertyName: 'totals',
        newValue,
      });
    },
    defaultPresetFilter() {
      const field = this.object.fields[0];

      return {
        field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
      };
    },
    onAddPresetFilter() {
      log('onAddPresetFilter');
      this.viewRaw.allow_preset_filters = true;
      this.viewRaw.preset_filters.push(this.defaultPresetFilter());
    },
    defaultMenuFilter() {
      const field = this.object.fields[0];

      return {
        text: 'Link',
        criteria: [
          {
            field: field.key,
            operator: field.getFirstRuleOperator(),
            value: field.getFilterDefault(),
          },
        ],
      };
    },
    onAddMenuFilter() {
      this.viewRaw.menu_filters.push(this.defaultMenuFilter());
    },
  },
};
</script>
