import { v4 as uuid } from 'uuid';

export default class FrameListener {
  constructor(frameRef) {
    this.ref = frameRef;
    this.listeners = [];

    window.addEventListener('message', this.handleMessage);
  }

  postMessage = (key, data, respCallback) => {
    const respKey = respCallback
      ? uuid
      : undefined;

    if (this.ref?.contentWindow?.postMessage) {
      if (respKey && respCallback) {
        this.addListener(respKey, respCallback, true);
      }
      this.ref?.contentWindow?.postMessage({ key, resp_key: respKey, data }, '*');
    } else {
      throw new Error('target ref not set or has no postmessage function');
    }
  };

  addListener = (key, callBack, once = false) => {
    this.listeners = this.listeners.filter((l) => l.key !== key);
    this.listeners.push({
      key,
      callBack,
      id: uuid,
      once,
    });
  };

  removeListener = ({ key, id }) => {
    this.listeners = this.listeners.filter(
      (l) => (id === undefined || l.id !== id)
        && (key === undefined || l.key !== key),
    );
  };

  handleMessage = (e) => {
    const key = e?.data?.key;
    const respKey = e?.data?.respKey;
    const data = e?.data?.data;

    this.listeners = this.listeners.filter(async (listener) => {
      if (key === listener.key) {
        const replyData = await listener.callBack(data);
        if (respKey) {
          this.postMessage(respKey, replyData);
        }
        return !listener.once;
      }
      return true;
    });
  };
}
