<template>
  <div>
    <div v-if="isUnique">
      <div class="link_label">
        <input
          id="link_label"
          v-model="localLabel"
          class="input kn-input"
          name="label"
          type="text"
        >
        <label
          class="help group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm group-[&:not(.kn-view)]/shared:font-normal group-[&:not(.kn-view)]/shared:mt-0.5 group-[&:not(.kn-view)]/shared:mb-2"
          for="link_label"
        >
          Link Text
        </label>
      </div>
      <div class="link_url">
        <input
          :id="input.id"
          v-model="localUrl"
          class="input kn-input"
          name="url"
          type="text"
          :pattern="urlPatternRegex"
        >
        <label
          class="hint group-[&:not(.kn-view)]/shared:text-default group-[&:not(.kn-view)]/shared:text-sm group-[&:not(.kn-view)]/shared:font-normal group-[&:not(.kn-view)]/shared:mt-0.5 group-[&:not(.kn-view)]/shared:mb-2"
          :for="input.id"
        >
          Link URL
        </label>
      </div>
    </div>
    <input
      v-else
      :id="input.id"
      v-model="localUrl"
      class="input kn-input"
      name="url"
      type="text"
      :pattern="urlPatternRegex"
    >
    <input
      name="key"
      type="hidden"
      :value="input.id"
    >
  </div>
</template>

<script>
import isNil from 'lodash/isNil';

export default {
  name: 'LinkInput',
  inheritAttrs: false,
  props: [
    'input',
    'field',
    'modelValue',
  ],
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    localLabel: {
      get() {
        return this.localValue.label;
      },
      set(newValue) {
        // In the V2 builder, the link value always includes URL value
        // as at least an empty string, or the value is not formatted correctly.
        if (!this.localUrl) {
          this.$emit('update:modelValue', {
            label: newValue,
            url: '',
          });

          return;
        }

        this.$emit('update:modelValue', {
          ...this.localValue,
          label: newValue,
        });
      },
    },
    localUrl: {
      get() {
        return this.localValue.url;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          url: newValue,
        });
      },
    },
    isUnique() {
      return !isNil(this.input.format) && this.input.format.text_format === 'unique';
    },
    urlPatternRegex() {
      // TODO: make pattern regex work and change input types back to url
      // https://rodneyrehm.de/t/url-regex.html#imme_emosol
        return `^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$` // eslint-disable-line
    },
  },
};
</script>
