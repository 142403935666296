<template>
  <div :class="['input-box', { active }]">
    <div class="left-icon-wrapper flex">
      <Icon
        :type="iconType"
        :class="['left-icon', { focused }, iconClasses]"
      />
    </div>
    <input
      v-model="inputText"
      :class="['text-input', { active }, inputClasses]"
      :type="inputType"
      :placeholder="placeholder"
      @input="$emit('filter-input', inputText)"
      @focus="focused = true"
      @blur="focused = false"
    >
    <div class="close-button flex">
      <Icon
        v-show="inputText"
        type="close"
        :class="['close-icon hover:text-subtle', { active }]"
        @click="reset"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {

    /**
       * Input element type
       */
    inputType: {
      type: String,
      default: 'search',
    },

    /**
       * Hint text for the input box
       */
    placeholder: {
      type: String,
      default: '',
    },

    /**
       * Name of the icon to display in the left-hand side of the input box.
       */
    iconType: {
      type: String,
      default: 'filter-indicator',
    },
    inputClasses: {
      type: String,
      default: '',
    },
    iconClasses: {
      type: String,
      default: '',
    },
  },
  emits: [
    'clear',
    'filter-input',
  ],
  data() {
    return {
      focused: false,
      inputText: '',
    };
  },
  computed: {
    active() {
      return this.focused || this.inputText;
    },
    closeIconVisible() {
      return this.active && this.inputText;
    },
  },
  methods: {
    reset() {
      this.inputText = '';
      this.$emit('clear');
    },
  },
};
</script>

<style lang="scss" scoped>
.input-box {
  display: flex;
  align-items: center;
  width: 240px;
  height: 32px;

  .text-input {
    flex: 1;
    padding: 0 28px;

    &::placeholder {
      color: $gray400;
      display: none;
    }

    &:hover {
      border-color: $gray400;
    }

    &:focus {
      &::placeholder {
        visibility: hidden;
      }
    }

    // excludes native search button on webkit browsers
    &::-webkit-search-cancel-button {
      display: none !important;
    }
  }

  .left-icon-wrapper {
    position: relative;
    align-items: center;

    .left-icon {
      position: absolute;
      padding: 8px 0px;
      color: $gray400;

      &.focused {
        color: $primary500;
      }
    }
  }

  .close-button {
    position: relative;
    align-items: center;

    .close-icon {
      position: absolute;
      cursor: pointer;
      padding: 8px 0px;
      right: 0;
      color: $gray900;

      &:hover {
        color: $gray400;
      }
    }
  }
}
</style>
