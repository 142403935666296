<template>
  <div class="recordsTruncate_wrapper">
    <span v-html="truncatedText" />
    <span
      v-if="shouldDisplayTruncate"
      type="button"
      :class="{'recordsTruncate': truncated}"
    >
      <button
        type="button"
        class="recordsTruncate_trigger"
        @click="toggleTruncation"
      >
        {{ truncationToggleText }} <Icon :type="getIcon" />
      </button>
    </span>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';
import Icon from '@/components/ui/Icon';
import { defaultCharacterLimit } from '@/lib/schema-helper';

export default {
  components: {
    Icon,
  },
  props: {
    displayValue: {
      type: String,
      default: '',
    },
    viewItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      truncated: true,
    };
  },
  computed: {
    characterLimit() {
      if (isNil(this.viewItem.character_limit)) {
        return defaultCharacterLimit;
      }

      return parseInt(this.viewItem.character_limit, 10);
    },
    getIcon() {
      return this.truncated ? 'arrow-drop-down' : 'arrow-drop-up';
    },
    shouldDisplayTruncate() {
      return this.viewItem.truncate === true && this.displayValue.length > this.characterLimit;
    },
    truncationToggleText() {
      return this.truncated ? 'expand' : 'collapse';
    },
    truncatedText() {
      return this.shouldDisplayTruncate && this.truncated ? this.displayValue.substring(0, this.characterLimit - 1).trim() : this.displayValue;
    },
  },
  methods: {
    toggleTruncation(event) {
      event.preventDefault();

      this.truncated = !this.truncated;
    },
  },
};
</script>

<style lang="scss">
.recordsTruncate {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  pointer-events: none;

  &_wrapper {
    overflow: hidden;
  }

  &_trigger {
    appearance: none;
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 6px;
    background-color: #a8a8ad;
    opacity: 0.75;
    padding: 4px 6px 4px 10px;
    color: #fff;
    left: calc(50% - 40px);
    z-index: 15;
    line-height: 1.3em;
    border-radius: .33em;
    border: none;
    cursor: pointer;
    visibility: hidden;
    pointer-events: all;

    svg {
      width: 18px;
      height: 18px;
      display: block;

      g {
        path:first-child {
          fill: #fff;
        }
      }
    }
  }
}

.cell-edit:hover .recordsTruncate_trigger {
  visibility: visible;
}

table.is-striped tbody tr:nth-child(even) .recordsTruncate {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fafafa 100%);
}

td.kn-table-cell.cell-edit:hover .recordsTruncate {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #c7eefe 100%);
}
</style>
