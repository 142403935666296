/**
 * Maps to FlagSmith ID
 * @const {string}
 */
// eslint-disable-next-line import/prefer-default-export
export const ENCODED_RECORD_IDS = 'encoded_record_ids';
export const INTEGRATIONS = 'integrations';
export const DATA_TABLE_V1 = 'data_table_v1';
export const FLOWS = 'albato_flows';
export const AB_TESTING_OBJECT_TABS = 'ab_testing_object_tabs';
