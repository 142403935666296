import axios from 'axios';

const getRequestHeaders = (appId) => ({
  'X-Knack-Application-Id': appId,
  'X-Knack-New-Builder': true,
  'X-Knack-Rest-Api-Key': 'builder',
  'X-Knack-Builder-Version': 'v3',
});

const storeState = {
  jwtToken: '',
  connectedServices: [],
  availableServices: [],
  hasLoaded: false,
  isIntegrationImportInProgress: false,
};

const storeGetters = {
  jwtToken: (state) => state.jwtToken,
  connectedServices: (state) => state.connectedServices,
  availableServices: (state) => state.availableServices,
  hasLoaded: (state) => state.hasLoaded,
  isIntegrationImportInProgress: (state) => state.isIntegrationImportInProgress,
};

const storeMutations = {
  setJwtToken(state, jwtToken) {
    state.jwtToken = jwtToken;
  },
  setConnectedServices(state, connectedServices) {
    state.connectedServices = connectedServices;
  },
  setAvailableServices(state, availableServices) {
    state.availableServices = availableServices;
  },
  setIsIntegrationImportInProgress(state, isIntegrationImportInProgress) {
    state.isIntegrationImportInProgress = isIntegrationImportInProgress;
  },
};

const storeActions = {
  async init({ dispatch, state }) {
    await dispatch('createUserSession');
    await dispatch('fetchConnectedServices');
    await dispatch('fetchAvailableServices');
    state.hasLoaded = true;
  },

  async createUserSession({ commit, rootState }) {
    const url = `${process.env.VUE_APP_API_URL}integrations/sessions`;
    const { data } = await axios.post(url, {}, {
      withCredentials: true,
      headers: getRequestHeaders(rootState.appId),
    });
    commit('setJwtToken', data.session_token);
  },

  async fetchConnectedServices({ commit, rootState }) {
    const url = `${process.env.VUE_APP_API_URL}integrations/accounting/connections`;
    const { data } = await axios.get(url, {
      withCredentials: true,
      headers: getRequestHeaders(rootState.appId),
    }) || [];
    commit('setConnectedServices', data || []);
  },

  async fetchAvailableServices({ commit, rootState }) {
    const url = `${process.env.VUE_APP_API_URL}integrations/accounting/connectors`;
    const { data } = await axios.get(url, {
      withCredentials: true,
      headers: getRequestHeaders(rootState.appId),
    }) || [];
    commit('setAvailableServices', data || []);
  },

  async deleteConnection({ commit, state, rootState }, serviceId) {
    const url = `${process.env.VUE_APP_API_URL}integrations/accounting/connections/quickbooks`;
    const response = await axios.delete(url, {
      withCredentials: true,
      headers: getRequestHeaders(rootState.appId),
    });
    if (response.status === 200) {
      commit('setConnectedServices', state.connectedServices.filter((service) => service.id !== serviceId));
    }
  },

  async requestImport({ rootState }) {
    const url = `${process.env.VUE_APP_API_URL}integrations/accounting/import`;
    const response = await axios.post(
      url,
      {},
      {
        withCredentials: true,
        headers: getRequestHeaders(rootState.appId),
      },
    );
    return response?.data;
  },

  async refetchData({ rootState }) {
    const url = `${process.env.VUE_APP_API_URL}integrations/accounting/import`;
    const response = await axios.post(
      url,
      {
        isDataRefresh: true,
      },
      {
        withCredentials: true,
        headers: getRequestHeaders(rootState.appId),
      },
    );
    return response?.data;
  },

  updateIsIntegrationImportInProgress({ commit }, { isIntegrationImportInProgress }) {
    commit('setIsIntegrationImportInProgress', isIntegrationImportInProgress);
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions,
};
