<script>
import Papa from 'papaparse';

import PasteInput from '@/components/ui/inputs/PasteInput';

export default {
  extends: PasteInput,
  emits: [
    'submit',
  ],
  methods: {
    handleImport() {
      const data = Papa.parse(this.pastedData, {
        skipEmptyLines: true,
      });

      this.$emit('submit', data.data);
    },
  },
};
</script>
