<template>
  <div class="toolbox-wrapper">
    <Toolbox
      back-title="design"
      title="View Links"
    >
      <p>Customize how links will appear in views</p>

      <Notification
        v-if="showThemeWarning"
        class="notification--wrapperWhite margin-bottom"
      >
        <ThemeWarning />
      </Notification>

      <Toggle
        toggle-content-classes="px-4"
        class="margin-bottom"
      >
        <template #title>
          <span class="text-base text-emphasis font-semibold">
            Page Links
          </span>
        </template>
        <template #content>
          <form
            style="position: relative;"
            :class="{isDisabled: showThemeWarning}"
          >
            <p>Customize links to other pages that edit records or view record details</p>
            <LinksOptions view-link-type="page" />
          </form>
        </template>
      </Toggle>

      <hr class="border-0 border-t border-solid border-subtle">

      <Toggle
        toggle-content-classes="px-4"
        class="margin-bottom"
      >
        <template #title>
          <span class="text-base text-emphasis font-semibold">
            Action Links
          </span>
        </template>
        <template #content>
          <form
            style="position: relative;"
            :class="{isDisabled: showThemeWarning}"
          >
            <p>Customize action links</p>
            <LinksOptions view-link-type="action" />
          </form>
        </template>
      </Toggle>

      <hr class="border-0 border-t border-solid border-subtle">

      <Toggle
        toggle-content-classes="px-4"
        class="margin-bottom"
      >
        <template #title>
          <span class="text-base text-emphasis font-semibold">
            Delete Links
          </span>
        </template>
        <template #content>
          <form
            style="position: relative;"
            :class="{isDisabled: showThemeWarning}"
          >
            <p>Customize links to delete records</p>
            <LinksOptions view-link-type="delete" />
          </form>
        </template>
      </Toggle>
    </Toolbox>
  </div>
</template>

<script>
import { designSettingsAliases, getStylesFromDesignSettings } from '@knack/style-engine';
import LinksOptions from '@/components/settings/design/options/LinksOptions';
import ApplicationDesignUtils from '@/components/util/ApplicationDesignUtils';
import Notification from '@/components/ui/notifications/Notification';
import ThemeWarning from '@/components/ui/ThemeWarning';
import Toggle from '@/components/ui/Toggle';
import Toolbox from '@/components/layout/Toolbox';

export default {
  name: 'TableDesignSettings',
  components: {
    Notification,
    LinksOptions,
    ThemeWarning,
    Toolbox,
    Toggle,
  },
  mixins: [
    ApplicationDesignUtils,
  ],
  computed: {
    showThemeWarning() {
      return this.app.getThemeLevel() < 2;
    },
  },
  // These watchers make sure that the link text is always visible
  // after changing the format
  watch: {
    'app.design.general.views.links.page.format': function (newValue) {
      if (newValue !== 'text') {
        return;
      }

      this.changeTextColorIfInvisible(
        designSettingsAliases.VIEW_LINKS,
        'page',
      );
    },
    'app.design.general.views.links.action.format': function (newValue) {
      if (newValue !== 'text') {
        return;
      }

      this.changeTextColorIfInvisible(
        designSettingsAliases.VIEW_LINKS_ACTION,
        'action',
      );
    },
    'app.design.general.views.links.delete.format': function (newValue) {
      if (newValue !== 'text') {
        return;
      }

      this.changeTextColorIfInvisible(
        designSettingsAliases.VIEW_LINKS_DELETE,
        'delete',
      );
    },
  },
  methods: {
    changeTextColorIfInvisible(alias, which) {
      // We're switching to text, so confirm color is visible
      const styles = getStylesFromDesignSettings(
        alias,
        this.app.design,
      );

      // If white change to custom
      if (styles.color === '#ffffff') {
        // This will use the default link color for the text
        this.app.design.general.views.links[which].colors.text.custom = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.isDisabled div * {
  opacity: .5;
  pointer-events: none;
}

.tableSettings_divider {
  border-top: 1px solid #cdcdcd;
  margin-bottom: 1em;
}

label.radio {
  margin-bottom: 0 !important;
}
</style>
