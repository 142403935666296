<template>
  <button
    v-if="showIf"
    type="button"
    class="drawerItem"
    @click="activate"
  >
    <div class="drawerItem_iconWrapper">
      <Icon
        class="drawerItem_icon"
        :type="icon"
      />
    </div>
    <div class="drawerItem_content">
      <h2 class="drawerItem_header">
        {{ config.title }}
      </h2>
      <p class="drawerItem_caption">
        {{ config.caption }}
      </p>
    </div>
  </button>
</template>

<script>
import Icon from '@/components/ui/Icon';
import { isEmergencyAlertAllowed } from '@/lib/plan-helper';

export default {
  name: 'HelpCategory',
  components: {
    Icon,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['changePage'],
  computed: {
    icon() {
      return this.config.icon;
    },
    showIf() {
      if (!this.config.showIf || !Array.isArray(this.config.showIf)) {
        return true;
      }

      return this.config.showIf.reduce((final, flag) => {
        if (flag === 'isEmergencyAlertAllowed') {
          return isEmergencyAlertAllowed();
        }

        return final;
      }, true);
    },
  },
  methods: {
    activate() {
      this.$emit('changePage');
    },
  },
};
</script>
