<template>
  <Modal
    title="E-Commerce Payment Processor"
    :back="backLink"
    :z-index="2"
    @close="onClose"
  >
    <form class="margin-bottom-sm mb-0">
      <div
        v-if="errors.length"
        class="error-alert form-errors rounded-lg p-4 bg-destructive mb-6 border-0"
      >
        <div class="mb-1">
          <Icon
            class="block text-destructive-emphasis h-4 w-4"
            type="exclamation-triangle"
          />
        </div>
        <b class="text-destructive-emphasis text-base">Please correct the following errors:</b>
        <ul class="pl-[22px] text-destructive-emphasis text-base">
          <li
            v-for="(error, errorIndex) in errors"
            :key="errorIndex"
            v-html="error.message || error"
          />
        </ul>
      </div>

      <label class="block text-sm text-default font-medium mb-2 leading-4">Processor Type</label>
      <div class="input-labels mb-0">
        <label class="flex items-baseline text-emphasis text-base font-normal m-0 mb-1"><input
          v-model="localProcessor.processor"
          class="m-0 mr-2"
          type="radio"
          value="stripe"
        >Stripe</label>
        <label class="flex items-baseline text-emphasis text-base font-normal m-0"><input
          v-model="localProcessor.processor"
          class="m-0 mr-2"
          type="radio"
          value="paypal"
        >PayPal</label>
      </div>
    </form>

    <hr
      v-if="isStripe || isPayPal"
      class="my-8 border-0 border-t border-solid border-subtle"
    >

    <div
      v-if="isStripe"
      class="mt-6"
    >
      <p class="text-base text-default font-normal mb-6">
        Add credentials from your Stripe account below.
        You can retrieve your Stripe API keys from your <a
          class="text-base text-default underline font-medium"
          href="https://dashboard.stripe.com/account/apikeys"
          target="_blank"
        >Stripe API Keys page</a>.
      </p>
      <form class="horizontal-labels">
        <div
          data-field-name="name"
          class="block mb-5"
        >
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Processor Name
          </label>
          <input
            v-model="localProcessor.name"
            type="text"
          >
        </div>
        <div class="block mb-5">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Test Publishable Key
          </label>
          <input
            v-model="localProcessor.test_public_auth_key"
            type="text"
          >
        </div>
        <div class="block mb-5">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Test Secret Key
          </label>
          <input
            v-model="localProcessor.test_private_auth_key"
            type="text"
          >
        </div>
        <div class="block mb-5">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Live Publishable Key
          </label>
          <input
            v-model="localProcessor.public_auth_key"
            type="text"
          >
        </div>
        <div class="block mb-5">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Live Secret Key
          </label>
          <input
            v-model="localProcessor.private_auth_key"
            type="text"
          >
        </div>
        <div class="block">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Default Currency
          </label>
          <Currencies
            v-model="localProcessor.currency"
            :processor="localProcessor.processor"
          />
        </div>
      </form>
    </div>

    <div
      v-if="isPayPal"
      class="mt-6"
    >
      <p class="text-base text-default font-normal mb-6">
        Add credentials from your PayPal account below.
        You can retrieve your PayPal account information from your <a
          class="text-base text-default underline font-medium"
          href="https://developer.paypal.com/developer/applications"
          target="_blank"
        >PayPal dashboard page</a>.
      </p>
      <form class="horizontal-labels">
        <div
          data-field-name="name"
          class="block mb-5"
        >
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Processor Name
          </label>
          <input
            v-model="localProcessor.name"
            type="text"
          >
        </div>
        <div class="block mb-5">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Username
          </label>
          <input
            v-model="localProcessor.username"
            type="text"
          >
        </div>
        <div class="block mb-5">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Password
          </label>
          <input
            v-model="localProcessor.password"
            type="password"
          >
        </div>
        <div class="block mb-5">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Signature
          </label>
          <input
            v-model="localProcessor.signature"
            type="text"
          >
        </div>
        <div class="block mb-5">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Test Username
          </label>
          <input
            v-model="localProcessor.test_username"
            type="text"
          >
        </div>
        <div class="block mb-5">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Test Password
          </label>
          <input
            v-model="localProcessor.test_password"
            type="password"
          >
        </div>
        <div class="block mb-5">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Test Signature
          </label>
          <input
            v-model="localProcessor.test_signature"
            type="text"
          >
        </div>
        <div class="block">
          <label class="block text-left text-default font-medium p-0 mb-2 leading-4">
            Default Currency
          </label>
          <Currencies
            v-model="localProcessor.currency"
            :processor="localProcessor.processor"
          />
        </div>
      </form>
    </div>

    <div class="submit-buttons p-0 flex justify-end m-0 mt-6">
      <button
        class="button cancel bg-transparent rounded-lg border-0 p-3 text-base leading-4 font-medium mr-2"
        @click="onClose"
      >
        Cancel
      </button>
      <button
        class="button save bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium"
        @click="submitClickHandler"
      >
        {{ submitVerbiage }} Payment Processor
      </button>
    </div>
  </Modal>
</template>
<script>
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Modal from '@/components/ui/Modal';
import Icon from '@/components/ui/Icon';
import Currencies from '@/components/settings/ecommerce/Currencies';
import RequestUtils from '@/components/util/RequestUtils';
import { defaultEmptyPaymentProcessor } from '@/lib/schema-helper';

export default {
  components: {
    Modal,
    Currencies,
    Icon,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    backLink: {
      type: [
        String,
        null,
      ],
      default: '/settings/ecommerce',
    },
    processorKey: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data() {
    return {
      localProcessor: () => ({}),
    };
  },
  computed: {
    isStripe() {
      return this.localProcessor.processor === 'stripe';
    },
    isPayPal() {
      return this.localProcessor.processor === 'paypal';
    },
    isEditMode() {
      return !isEmpty(this.processorKey);
    },
    submitVerbiage() {
      return this.isEditMode ? 'Update' : 'Add';
    },
    submitClickHandler() {
      return this.isEditMode ? this.onClickUpdatePaymentProcessor : this.onClickAddPaymentProcessor;
    },
  },
  watch: {
    'localProcessor.processor': function (newVal, oldVal) {
      // on processor change the form should be wiped
      // if oldVal is undefined our form is already blank
      if (oldVal === undefined) {
        return;
      }

      if (newVal !== oldVal) {
        this.localProcessor = { processor: newVal, ...defaultEmptyPaymentProcessor() };
      }
    },
  },
  created() {
    this.localProcessor = this.setLocalProcessor();
  },
  methods: {
    setLocalProcessor() {
      // if we have no processor key, this is a new payment processor
      const processorValues = isEmpty(this.processorKey) ? defaultEmptyPaymentProcessor() : this.app.paymentProcessors.find((processor) => processor.key === this.processorKey);

      return { ...processorValues };
    },
    onClickAddPaymentProcessor() {
      this.commitRequest({
        validate: () => this.app.validatePaymentProcessor(this.localProcessor, {
          isNewProcessor: true,
        }),
        request: () => this.app.createPaymentProcessor(this.localProcessor),
        onSuccess: () => {
          if (!isNil(this.backLink)) {
            this.$router.push(this.backLink);
          }

          this.$emit('close');
        },
      });
    },
    onClickUpdatePaymentProcessor() {
      this.commitRequest({
        validate: () => this.app.validatePaymentProcessor(this.localProcessor),
        request: () => this.app.updatePaymentProcessor(this.localProcessor),
        onSuccess: () => {
          this.$router.push(this.backLink);
        },
      });
    },
    onClose() {
      if (!isNil(this.backLink)) {
        this.$router.push(this.backLink);
      }

      this.$emit('close');
    },
  },
};
</script>
