<template>
  <div
    class="overlay-bg"
    :class="classes"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    center: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'overlay-bg': true,
        'overlay-bg--center': this.center,
      };
    },
  },
};
</script>

<style lang="scss">
.overlay-bg {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,0.7);
  overflow-y: auto;

  &--center {
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
