<template>
  <div v-if="hasActiveView && column">
    <ValidationError :errors="errors" />

    <Toggle
      id="column-properties-toggle"
      :is-open="true"
      class="no-top-styles"
    >
      <template #title>
        <span>Column Properties</span>
      </template>

      <template #content>
        <div class="grid-verticalGaps gap-4 border border-solid border-subtle border-x-0 border-t-0">
          <div v-if="hasField && field">
            <label
              for="field"
              class="tw-toolbox-label"
            >
              Field
            </label>
            <FieldName
              :field="field"
              :connection="fieldConnection"
            />
          </div>
          <div v-if="isLinkColumn">
            <label class="tw-toolbox-label">Page to link to</label>
            <div v-if="linkedPageKey">
              <RouterLink
                v-tippy
                content="Navigate to this page"
                :to="'/pages/' + linkedPageKey"
                class="underline flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis mt-2 no-underline"
              >
                {{ linkedPage }}
              </RouterLink>
            </div>
            <div v-else>
              <span>{{ linkedPage }}</span>
            </div>
          </div>
          <div>
            <label
              for="header"
              class="tw-toolbox-label"
            >
              Header
            </label>
            <input
              v-model="column.header"
              type="text"
            >
          </div>
          <template v-if="isLinkColumn || isDeleteColumn">
            <div v-if="isLinkColumn">
              <label class="tw-toolbox-label">Link Type</label>
              <div class="input-labels tw-input-labels">
                <label><input
                  v-model="column.link_type"
                  type="radio"
                  value="text"
                >Text</label>
                <label><input
                  v-model="column.link_type"
                  type="radio"
                  value="field"
                >Field</label>
              </div>
            </div>
            <div v-if="isLinkField">
              <label class="tw-toolbox-label">Link Field</label>
              <select
                v-if="object"
                v-model="linkField"
                class="text-base py-2 pl-3 leading-5"
              >
                <template
                  v-for="objField in object.fields"
                  :key="objField.key"
                >
                  <option :value="objField.key">
                    {{ objField.name }}
                  </option>
                  <template
                    v-for="thumb in objField.getThumbs()"
                    :key="objField.key + ':' + thumb.key"
                  >
                    <option
                      v-if="objField.hasThumbs()"
                      :value="objField.key + ':' + thumb.key"
                    >
                      {{ objField.getNameWithThumb(thumb) }}
                    </option>
                  </template>
                </template>
              </select>
            </div>
            <div v-if="isLinkText || isDeleteColumn">
              <label
                for="link_text"
                class="tw-toolbox-label"
              >
                Link Text
              </label>
              <input
                v-model="column.link_text"
                type="text"
              >
            </div>
            <div>
              <label class="tw-toolbox-label">Icon</label>
              <p class="mb-0"><IconPicker v-model:icon="column.icon" /></p>
            </div>
          </template>
          <ActionLinks
            v-if="isActionLinkColumn"
            v-model:action-rules="column.action_rules"
            :errors="errors"
          />
          <template v-if="hasField">
            <div v-if="showSummary">
              <label class="tw-toolbox-label">Allow Summary</label>
              <select
                v-model="column.ignore_summary"
                class="text-base py-2 pl-3 leading-5"
              >
                <option :value="false">
                  Yes
                </option>
                <option :value="true">
                  No
                </option>
              </select>
            </div>
            <template v-if="isParagraphField">
              <div>
                <label class="tw-toolbox-label">Truncate Text</label>
                <div class="input-labels tw-input-labels">
                  <label><input
                    v-model="column.truncate"
                    type="checkbox"
                  >Shorten text after a set character limit</label>
                </div>
              </div>
              <div v-if="column.truncate">
                <label class="tw-toolbox-label">Character Limit <HelpIcon copy="Must be a value greater than 0" class="pb-0" /></label>
                <input
                  v-model="column.character_limit"
                  type="number"
                  min="1"
                >
              </div>
            </template>
            <div v-if="isThumb">
              <label class="tw-toolbox-label">Thumbnail Gallery</label>
              <div class="input-labels tw-input-labels">
                <label><input
                  v-model="column.img_gallery"
                  type="checkbox"
                >Click the thumbnail to view the full-size image</label>
              </div>
            </div>
            <template v-if="isConnectionField || isFieldFromConnectedObject">
              <div v-if="isConnectionToMany">
                <label class="tw-toolbox-label">
                  Connection Separator <HelpIcon class="pb-0" :copy="`<b>${field?.name}</b> may contain multiple values<br />Choose how to separate these values.`" />
                </label>
                <div class="input-labels tw-input-labels">
                  <label><input
                    v-model="connSeparator"
                    type="radio"
                    value="comma"
                  >Comma</label>
                  <label><input
                    v-model="connSeparator"
                    type="radio"
                    value="new_line"
                  >New Line</label>
                </div>
              </div>
              <div v-if="showConnectionLink()">
                <label class="tw-toolbox-label">
                  Connection Link <HelpIcon class="pb-0" :copy="`Link the <b>${field?.name}</b> values<br />to <b>${connObject.name}</b> detail pages`" />
                </label>
                <div>
                  <select
                    v-model="column.conn_link"
                    class="text-base py-2 pl-3 leading-5"
                  >
                    <option value="">
                      No Link
                    </option>
                    <option
                      v-for="page in connectedPages()"
                      :key="page.key"
                      :value="page.key"
                    >
                      {{ page.label }}
                    </option>
                  </select>
                </div>
              </div>
            </template>
          </template>
          <template v-if="isFieldColumn">
            <div v-if="hasField">
              <label class="tw-toolbox-label">Grouping</label>
              <div class="input-labels tw-input-labels">
                <label><input
                  v-model="column.grouping"
                  type="checkbox"
                  @change="onChangeColumnGrouping(column)"
                >Group records based on this field's value</label>
              </div>
            </div>
            <template v-if="column.grouping">
              <div v-if="field">
                <label class="tw-toolbox-label">Group Order</label>
                <select
                  v-model="column.group_sort"
                  class="text-base py-2 pl-3 leading-5"
                >
                  <option value="asc">
                    {{ field.getSortAscending() }}
                  </option>
                  <option value="desc">
                    {{ field.getSortDescending() }}
                  </option>
                </select>
              </div>
              <div>
                <label class="tw-toolbox-label">Group Position</label>
                <select
                  :value="groupPosition"
                  :disabled="groupPositionIsDisabled"
                  class="text-base py-2 pl-3 leading-5"
                  style="width: 50px;"
                  @change="onUpdateGroupPosition($event.target.value)"
                >
                  <option
                    v-for="(group, index) in columnGroups"
                    :key="index"
                    :value="index"
                  >
                    {{ index + 1 }}
                  </option>
                </select>
              </div>
            </template>
            <div v-if="showInlineEditing">
              <label class="tw-toolbox-label">Allow Inline Editing</label>
              <div class="input-labels tw-input-labels">
                <label><input
                  v-model="column.ignore_edit"
                  type="radio"
                  :value="false"
                >Yes</label>
                <label><input
                  v-model="column.ignore_edit"
                  type="radio"
                  :value="true"
                >No</label>
              </div>
            </div>
          </template>
        </div>
      </template>
    </Toggle>

    <Toggle
      v-if="isConnectionField && showInlineEditing && !column.ignore_edit"
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Inline Edit Options</span>
      </template>
      <template #content>
        <div class="grid-verticalGaps gap-4 border border-solid border-subtle border-x-0 border-t-0">
          <div>
            <label class="tw-toolbox-label">Filters</label>
            <p class="margin-bottom-half">
              Filter which records are available to choose.
            </p>
            <FieldList
              v-if="hasFilters"
              :items="connectionFilters"
              :object-key="connectionObjectKey"
              :can-be-empty="true"
              class="is-grouped hide-add"
              style="font-size: .9em;"
              @update:items="onUpdateConnectionFilters"
            />
            <a
              class="button tiny fuchsia-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base capitalize font-medium"
              @click="onAddFilter"
            >
              <Icon
                class="text-default group-hover:text-brand-400 h-4 w-4 mr-2 fill-current"
                type="plus-thin"
              />
              add filter
            </a>
          </div>
          <div v-if="hasConnectionParentOptions">
            <label class="tw-toolbox-label">Show</label>
            <select
              v-model="connectedParentOption"
              class="text-base py-2 pl-3 leading-5"
            >
              <option
                v-for="option in connectionParentOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
      </template>
    </Toggle>

    <Toggle
      v-if="!column.grouping"
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Display Rules</span>
      </template>

      <template #content>
        <p class="mb-0">
          Add rules to update the column's display.
        </p>
        <div
          id="display-rules-editor"
          class="rules-editor border border-solid border-subtle border-x-0 border-t-0"
        >
          <div>
            <GroupList
              v-model:items="column.rules"
              item-title="Display Rule"
              class="display-rules small"
              group-list-item-classes="bg-subtle rounded-lg p4 mt-4"
            >
              <template #default="{item}">
                <div>
                  <div class="margin-bottom-half">
                    <p><b>If</b> the following rules match</p>
                    <FieldList
                      v-if="object"
                      :object-key="object.key"
                      :items="item.criteria"
                      class="is-grouped is-contained bg-transparent p-0"
                      @update:items="onUpdateCriteria(item, $event)"
                    />
                  </div>

                  <div class="mt-0 pt-0">
                    <p><b>Then</b></p>
                    <ActionList
                      v-model:items="item.actions"
                      :default-item="defaultItemAction"
                      class="is-grouped is-contained bg-transparent p-0"
                    >
                      <template #default="props">
                        <div class="display-rule-actions items-center">
                          <select
                            v-model="props.item.action"
                            class="text-base py-2 pl-3 leading-5"
                          >
                            <option value="text-color">
                              Set Text Color
                            </option>
                            <option value="text-style">
                              Set Text Style
                            </option>
                            <option value="bg-color">
                              Set Background Color
                            </option>
                            <option value="icon">
                              Display Icon
                            </option>
                            <option value="hide">
                              Hide Value
                            </option>
                          </select>

                          <ColorPicker
                            v-if="props.item.action === 'text-color' || props.item.action === 'bg-color'"
                            v-model="props.item.color"
                          />

                          <div
                            v-if="props.item.action === 'text-style'"
                            class="text-style-options"
                          >
                            <a
                              :class="{active: props.item.bold}"
                              @click="props.item.bold = !props.item.bold"
                            >
                              <b>B</b>
                            </a>
                            <a
                              :class="{active: props.item.italic}"
                              @click="props.item.italic = !props.item.italic"
                            >
                              <i>I</i>
                            </a>
                            <a
                              :class="{active: props.item.strikethrough}"
                              @click="props.item.strikethrough = !props.item.strikethrough"
                            >
                              <s>S</s>
                            </a>
                          </div>

                          <template v-if="props.item.action === 'icon'">
                            <IconPicker
                              v-model:icon="props.item.icon"
                              :ignore-delete="true"
                              :ignore-edit-button="true"
                            />
                            <ColorPicker
                              v-if="props.item.icon.icon"
                              v-model="props.item.color"
                            />
                          </template>
                        </div>
                      </template>
                    </ActionList>
                  </div>
                </div>
              </template>
            </GroupList>
            <div class="mt-4">
              <a
                class="button small green-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base capitalize font-medium"
                @click="onAddListItem"
              >
                <Icon
                  class="text-default group-hover:text-brand-400 h-4 w-4 mr-2 fill-current"
                  type="plus-thin"
                /> Add Rule
              </a>
            </div>
          </div>
        </div>
      </template>
    </Toggle>

    <Toggle
      v-if="showInlineEditing && !column.ignore_edit"
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Record Rules</span>
      </template>
      <template #content>
        <div class="border border-solid border-subtle border-x-0 border-t-0">
          <p class="mb-0">Add rules to update/insert records after inline editing.</p>
          <RulesList
            v-model:rules="editRules"
            :allow-email-action="true"
          >
            <template #no-criteria>
              <p>
                This rule runs on every inline edit for this column
              </p>
            </template>
          </RulesList>
        </div>
      </template>
    </Toggle>

    <!-- Design -->
    <Toggle
      v-if="!column.grouping"
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Design</span>
      </template>
      <template #content>
        <div class="grid-verticalGaps gap-4 border border-solid border-subtle border-x-0 border-t-0">
          <div id="column-width">
            <label class="tw-toolbox-label">Column Width</label>
            <div class="multiple-inputs">
              <ChoicePicker
                v-model="column.width.type"
                capitalize="true"
                :choices="[`default`, `custom`]"
                default-choice="default"
                class="small"
              />
              <template v-if="column.width.type ===`custom`">
                <input
                  v-model="column.width.amount"
                  type="text"
                  style="width: 80px;"
                >
                <select
                  v-model="column.width.units"
                  class="text-base py-2 pl-3 leading-5"
                >
                  <option value="px">
                    pixels
                  </option>
                  <option value="pt">
                    percent
                  </option>
                </select>
              </template>
            </div>
          </div>
          <div>
            <label class="tw-toolbox-label">Align</label>
            <ChoicePicker
              v-model="column.align"
              capitalize="true"
              :choices="[`left`, `center`, `right`]"
              default-choice="left"
              class="small"
            />
          </div>
        </div>
      </template>
    </Toggle>

    <!-- View Link Design -->
    <Toggle
      v-if="!column.grouping && showViewLinkDesignAccordion"
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Link Design</span>
      </template>
      <template #content>
        <div class="border border-solid border-subtle border-x-0 border-t-0">
          <ToggleSwitch
            v-model:checked="showViewLinkDesignOptions"
            label="Use Custom Design Settings"
          >
            <template #offDescription>
              <span class="text-sm font-normal text-default">
                This link uses the
                <router-link
                  to="/settings/design/views/links"
                  class="text-default underline"
                >
                  global design settings
                </router-link>
                for <strong>{{ columnViewLinkType }}</strong> links.
              </span>
            </template>
  
            <template #description>
              <span class="text-sm font-normal text-default">
                This link overrides the
                <router-link
                  to="/settings/design/views/links"
                  class="text-default underline"
                >
                  global design settings
                </router-link>
                with the following settings:
              </span>
            </template>
          </ToggleSwitch>
  
          <LinksOptions
            v-if="showViewLinkDesignOptions"
            :custom-design-object="column.link_design"
            :view-link-type="columnViewLinkType"
            hide-icon-option
          />
        </div>
      </template>
    </Toggle>
  </div>
</template>

<script>
import { hasIn, isEmpty, isNil, cloneDeep } from 'lodash';
import ActionLinks from '@/components/views/ActionLinks';
import ActionList from '@/components/ui/lists/ActionList';
import ChoicePicker from '@/components/ui/inputs/ChoicePicker';
import ColorPicker from '@/components/ui/inputs/ColorPicker';
import GroupList from '@/components/ui/lists/GroupList';
import HelpIcon from '@/components/ui/HelpIcon';
import ToggleSwitch from '@/components/ui/ToggleSwitch';
import LinksOptions from '@/components/settings/design/options/LinksOptions';
import FieldList from '@/components/ui/lists/FieldList';
import FieldName from '@/components/fields/FieldName';
import IconPicker from '@/components/ui/inputs/IconPicker';
import Icon from '@/components/ui/Icon';
import ParentConnectionsMixin from '@/components/views/form/ParentConnectionsMixin';
import RulesList from '@/components/rules/RulesList';
import Toggle from '@/components/ui/Toggle';
import ViewUtils from '@/components/views/ViewUtils';
import ValidationError from '@/components/ui/ValidationError';
import { defaultCharacterLimit, isCharacterLimitValid } from '@/lib/schema-helper';

export default {
  name: 'ColumnProperties',
  components: {
    ActionLinks,
    ActionList,
    ChoicePicker,
    ColorPicker,
    FieldList,
    FieldName,
    GroupList,
    HelpIcon,
    Icon,
    IconPicker,
    LinksOptions,
    RulesList,
    Toggle,
    ToggleSwitch,
    ValidationError,
  },
  mixins: [
    ParentConnectionsMixin,
    ViewUtils,
  ],
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showViewLinkDesignAccordion() {
      return this.isLinkColumn || this.isActionLinkColumn || this.isDeleteColumn;
    },
    showViewLinkDesignOptions: {
      get() {
        return !!this.column?.link_design_active;
      },
      set(newValue) {
        if (!this.column) {
          return;
        }

        this.column.link_design_active = !!newValue;

        // if turning on custom View Link Design Options for the first time
        if (newValue && !this.column.link_design) {
          this.setCustomViewLinkDesign();
        }
      },
    },
    field() {
      if (this.hasField && this.column.field) {
        const fieldKey = this.column.field.key;

        return this.getField(fieldKey);
      }

      return null;
    },
    connectionObjectKey() {
      return this.field?.getConnectedObject().key;
    },
    hasFilters() {
      return this.connectionFilters.length > 0;
    },
    connectionFilters: {
      get() {
        if (this.column.source) {
          return this.column.source.filters || [];
        }

        return [];
      },
      set(newVal) {
        if (!this.column.source) {
          this.column.source = {};
        }

        this.column.source.filters = newVal;
      },
    },
    connectedParentOption: {

      get() {
        const { column } = this;

        if (column.source) {
          if (column.source.remote_key) {
            return `${column.source.connection_key}-${column.source.remote_key}-${column.source.type}`;
          }

          if (column.source.connections && column.source.connections[0]) {
            return `${column.source.connections[0].field.key}-${column.source.connections[0].source.field.key}-input`;
          }
        }

        return '';
      },
      set(newValue) {
        const conn = newValue.split('-');
        const source = this.column.source || {};

        if (conn[2] === 'input') {
          source.connections = [
            {
              field: {
                key: conn[0],
              },
              source: {
                type: 'input',
                field: {
                  key: conn[1],
                },
              },
            },
          ];
          delete source.type;
          delete source.remote_key;
          delete source.connection_key;
        } else {
          source.type = conn[2];
          source.connection_key = conn[0];
          source.remote_key = conn[1];

          delete source.connections;
        }

        this.column.source = source;
      },
    },
    hasConnectionParentOptions() {
      // see ParentConnectionsMixin
      return this.connectionParentOptions.length > 0;
    },
    connSeparator: {
      get() {
        return this.column.conn_separator || 'new_line';
      },

      set(newVal) {
        this.column.conn_separator = newVal;
      },
    },
    hasField() {
      return (this.column.type === 'field' || this.isLinkField);
    },
    isFieldColumn() {
      return this.column.type === 'field';
    },
    isLinkColumn() {
      return this.column.type === 'link' || this.column.type === 'scene';
    },
    isActionLinkColumn() {
      return this.column.type === 'action_link';
    },
    isLinkText() {
      return this.isLinkColumn && this.column.link_type === 'text';
    },
    isLinkField() {
      return (this.isLinkColumn || this.isDeleteColumn) && this.column.link_type === 'field';
    },
    isDeleteColumn() {
      return this.column.type === 'delete';
    },
    isConnectionField() {
      return this.field?.isConnection() ?? false;
    },
    isFieldFromConnectedObject() {
      return this.column.connection;
    },
    connection() {
      const connFieldKey = (this.column.connection) ? this.column.connection.key : this.field?.key;

      return this.$store.getters.getFieldWithObject(connFieldKey);
    },
    connectionField() {
      return this.connection.field;
    },
    connObject() {
      if (this.isConnectionField || this.isFieldFromConnectedObject) {
        // if this connection is on this view's object, the object this field connects to is stored in the field relationship
        if (this.connectionField.objectKey === this.viewObjectKey) {
          return this.$store.getters.getObject(this.connectionField.get('relationship').object);
        }

        return this.connection.object;
      }

      return null;
    },
    isConnectionToMany() {
      const relationship = this.connectionField.get('relationship');

      // the connection is on this object, so use `has`
      if (this.connectionField.objectKey === this.object?.key) {
        return relationship.has === 'many';
      }

      // otherwise the connection field is on the connected object, so use `belongs_to`
      return relationship.belongs_to === 'many';
    },
    fieldConnection() {
      if (this.isConnectionField || this.isFieldFromConnectedObject) {
        return (this.connObject) ? this.connObject.name : '';
      }

      return '';
    },
    viewObjectKey() {
      return this.$store.getters.activeView.source.object;
    },
    isParagraphField() {
      return this.field?.isParagraph() ?? false;
    },
    isThumb() {
      if (!this.field) {
        return false;
      }

      const thumbKey = this.column.field.key.split(':')[1];

      if (thumbKey && this.column.type !== 'scene_link') {
        return true;
      }

      return false;
    },
    linkedPage() {
      if (!this.column.scene.name) {
        const page = this.$store.getters.getPageBySlug(this.column.scene);

        return page?.name || '';
      }

      return this.column.scene.name;
    },
    linkedPageKey() {
      if (this.column.scene.name) {
        return undefined;
      }

      const page = this.$store.getters.getPageBySlug(this.column.scene);

      return page?.key;
    },
    linkField: {
      get() {
        return this.column.field.key;
      },
      set(newVar) {
        this.column.field = {
          key: newVar,
        };
      },
    },
    showInlineEditing() {
      if (!this.field?.isFormInput()) {
        return false;
      }

      if (!this.view.hasInlineEditingEnabled()) {
        return false;
      }

      // Don't show if field is from a connected object
      if (this.isFieldFromConnectedObject) {
        return false;
      }

      if (this.column.type === 'action_link') {
        return false;
      }

      if (this.column.grouping) {
        return false;
      }

      return true;
    },
    showSummary() {
      // don't show if table property is not enabled
      if (isEmpty(this.viewRaw.totals) || !this.viewRaw.totals.length) {
        return false;
      }

      if (!this.field?.isNumeric()) {
        return false;
      }

      return true;
    },
    columns: {
      get() {
        if (!this.view?.get) {
          return [];
        }

        if (this.view.type === 'search') {
          return this.view.get('results').columns || [];
        }

        return this.view.get('columns') || this.view.get('results').columns || [];
      },
      set(newValue) {
        if (this.view.attributes.columns) {
          this.view.attributes.columns = newValue;
        } else if (hasIn(this.view, 'attributes.results.columns')) {
          this.view.attributes.results.columns = newValue;
        }
      },
    },
    editRules: {
      get() {
        return this.column.edit_rules;
      },
      set(newVal) {
        this.column.edit_rules = newVal;
      },
    },

    columnIndex() {
      return Number(this.$route.params.columnIndex || 0);
    },

    column() {
      return this.columns?.[this.columnIndex];
    },

    columnGroups() {
      return this.columns.filter((column) => column.grouping);
    },

    groupPosition() {
      return this.columnIndex;
    },

    groupPositionIsDisabled() {
      return this.columnGroups.length < 2;
    },

    columnViewLinkType() {
      if (this.isLinkColumn) {
        return 'page';
      }
      if (this.isActionLinkColumn) {
        return 'action';
      }
      if (this.isDeleteColumn) {
        return 'delete';
      }

      return '';
    },
  },
  watch: {
    'column.truncate': function () {
      if (!isCharacterLimitValid(this.column.character_limit)) {
        this.column.character_limit = defaultCharacterLimit;
      }
    },
    'column.link_type': function (newVal) {
      if (newVal === 'field' && !this.field && this.object) {
        this.column.field = {
          key: this.object.fields[0].key,
        };
      }
    },
    'column.width.type': function (newVal) {
      if (newVal === 'custom' && !this.column.width.units) {
        this.column.width.units = 'px';
        this.column.width.amount = 100;
      }
    },
  },
  methods: {
    connectedPages() {
      return this.$store.getters.getDetailPagesByObject(this.connObject.key);
    },
    showConnectionLink() {
      return !isEmpty(this.connectedPages()) && !this.column.grouping && this.isFieldColumn;
    },
    defaultFilter() {
      if (!this.field) {
        return {};
      }

      const field = this.field.getConnectedObject().fields[0];

      return {
        field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
      };
    },
    onAddFilter() {
      this.connectionFilters = [
        ...this.connectionFilters,
        this.defaultFilter(),
      ];
    },
    onUpdateConnectionFilters(newFilters) {
      this.connectionFilters = newFilters;
    },
    onAddListItem(event) {
      this.column.rules.push(this.defaultItem());
    },
    defaultCriteria() {
      if (!this.object) {
        return {};
      }

      const field = this.object.fields[0];

      return {
        field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
      };
    },
    onUpdateCriteria(item, itemCriteria) {
      item.criteria = itemCriteria;
    },
    defaultItem() {
      return {
        actions: [
          this.defaultItemAction(),
        ],
        criteria: [
          this.defaultCriteria(),
        ],
      };
    },
    defaultItemAction() {
      return {
        action: 'text-color',
        color: '#2ab27b',
        icon: {
          icon: '', align: 'left',
        },
        bold: true,
        italic: false,
        strikethrough: false,
      };
    },
    onChangeColumnGrouping(column) {
      let newIndex = 0;

      if (!column.grouping) {
        // Move the un-grouped column as the first column after the grouped ones.
        this.columns = this.reorderColumnsByGrouping(this.columns);

        newIndex = this.columns.findIndex(({ field }) => field?.key === column.field?.key);
      } else {
        if (!column.field?.key) {
          return;
        }

        // Move the newly grouped column to be the first column, but only if it has a field key.
        // The live app expects grouped columns to be the first array elements
        // (see: TableView.renderGroups)
        this.columns = this.moveGroupingColumnToFront(this.columns, column);
      }

      if (newIndex < 0) {
        return;
      }

      // Change the URL to reflect the new column Index.
      // This ensures that when toggling grouping the correct column will be selected for editing.
      const newURL = this.$route.path.replace(`columns/${this.columnIndex}`, `columns/${newIndex}`);

      this.$router.push(newURL);
    },
    moveGroupingColumnToFront(columns, columnWithGrouping) {
      const columnKey = columnWithGrouping.field.key;

      const columnsToReorder = columns.filter((column) => column.field?.key !== columnKey);
      columnsToReorder.unshift(columnWithGrouping);

      return this.reorderColumnsByGrouping(columnsToReorder);
    },
    reorderColumnsByGrouping(columns) {
      return columns.slice().sort((aColumn, bColumn) => {
        // Make sure that only columns with field keys are re-ordered.
        const aGrouped = aColumn.grouping && aColumn.field?.key;
        const bGrouped = bColumn.grouping && bColumn.field?.key;

        if (aGrouped && bGrouped) {
          return 0;
        }
        if (!aGrouped && !bGrouped) {
          return 0;
        }

        return (aGrouped && !bGrouped) ? -1 : 1;
      });
    },
    onUpdateGroupPosition(newPosition) {
      let newColumnIndex;
      let startingPosition = newPosition;

      // get new column position
      this.columns.some((column, index) => {
        if (column.grouping) {
          startingPosition -= 1;
        }

        if (startingPosition === -1) {
          newColumnIndex = index;

          return true;
        }

        return false;
      });

      // move this column from current column Index to the new one
      this.columns.splice(newColumnIndex, 0, this.columns.splice(this.columnIndex, 1)[0]);

      // change the URL to reflect the new column Index
      const newURL = this.$route.path.replace(
        `columns/${this.columnIndex}`,
        `columns/${newColumnIndex}`,
      );

      this.$router.replace(newURL);
    },
    setCustomViewLinkDesign() {
      const appDesign = this.$store.getters.app.get('design');

      // get the existing design values from app design for the specific link type
      if (this.isLinkColumn) {
        this.column.link_design = cloneDeep(appDesign.general.views.links.page);
      } else if (this.isActionLinkColumn) {
        this.column.link_design = cloneDeep(appDesign.general.views.links.action);
      } else if (this.isDeleteColumn) {
        this.column.link_design = cloneDeep(appDesign.general.views.links.delete);
      }
    },
  },
};
</script>

<style lang="scss">
#column-properties-toggle h3 {
  margin-top: .5em;
}

.width-options {
  margin-top: .5em;
  margin-bottom: 1.5em;
}

#display-rules-editor {

  .display-rule-actions {
    select {
      flex: 1 1 160px;
      margin-right: 6px;
    }
  }

  .text-style-options {
    display: flex;

    a {
      background-color: #eee;
      border-radius: .35em;
      border: 1px solid #eee;
      height: 2em;
      line-height: 2em;
      display: block;
      width: 2em;
      text-align: center;
      margin-left: 5px;

      &.active {
        border: 1px solid #771b66;
      }
    }

    a:first-child {
      margin: 0;
    }
  }
}
</style>
