<template>
  <div class="report-property-item">
    <Modal
      v-if="showSettingsModal"
      title="Edit Report Group"
      size="medium"
      @close="showSettingsModal = false"
    >
      <ReportGroupForm
        :field="field"
        :group="itemLocal"
        :object-key="object.key"
        :show-filters="showFilters"
        :show-sort="showSort"
        :show-date="showDate"
        @submit="onUpdateGroupSettings"
      />
    </Modal>

    <Dropdown
      v-if="showGroupOptions"
      v-model="item.group"
      :options="itemOptions"
      :placement="`bottom-start`"
      class="is-text"
      @update:modelValue="onChangeGroup"
    />

    <Dropdown
      v-if="type === `pivot`"
      v-model="item.field"
      :options="itemOptions"
      :placement="`bottom-start`"
      class="is-text"
      @update:modelValue="onChangePivot"
    />
    <Dropdown
      v-if="showFieldOptions"
      v-model="item.field"
      :placement="`bottom-start`"
      :options="itemOptions"
      class="is-text"
    />

    <Dropdown
      v-if="showCalculationOptions"
      v-model="localItemCalculation"
      :placement="`bottom-start`"
      :options="calculationOptions"
      class="is-text"
    />

    <a
      v-tippy
      content="Edit this group's label and other settings"
      class="button button-square btn-square square bg-transparent hover:bg-emphasis"
      @click="onClickSettings"
    >
      <Icon
        type="settings"
        class="text-default w-4 h-4"
      />
    </a>

    <Icon
      v-if="hasFilters"
      v-tippy
      content="These records are being filtered"
      type="filter-funnel"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import Dropdown from '@/components/ui/Dropdown';
import Icon from '@/components/ui/Icon';
import Modal from '@/components/ui/Modal';
import ReportGroupForm from '@/components/views/report/ReportGroupForm';

export default {
  components: {
    Dropdown,
    Icon,
    Modal,
    ReportGroupForm,
  },
  props: {
    type: {
      type: String,
      default: 'calculation',
      // group: select a group
      // calculation: select a field and calculation for a specific (total or average)
      // field: select a field for a group that is specificying a calculation (total or average)
      // summary: just show the calculation dropdown and store it in item.calc
    },
    item: {
      type: Object,
      default: () => {},
    },
    object: {
      type: Object,
      default: () => {},
    },
    // these options populate the primary dropdown
    itemOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      itemLocal: {}, // used for modal so not reactive until modal is submitted
      showSettingsModal: false,
      calculationOptions: [
        {
          value: 'sum',
          label: 'Sum',
        },
        {
          value: 'average',
          label: 'Average',
        },
        {
          value: 'min',
          label: 'Minimum',
        },
        {
          value: 'max',
          label: 'Maximum',
        },
      ],
    };
  },
  computed: {

    localItemCalculation: {

      get() {
        return this.item.calculation || this.item.calc;
      },

      set(newVal) {
        if (this.item.calc) {
          return this.item.calc = newVal;
        }

        this.item.calculation = newVal;
      },
    },

    hasFilters() {
      return !isEmpty(this.item.filters);
    },

    field() {
      if (!this.item.field) {
        return null;
      }

      return this.object.getField(this.item.field);
    },

    showGroupOptions() {
      return this.type === 'group';
    },

    showFieldOptions() {
      return this.type === 'field' || this.type === 'calculation';
    },

    showFilters() {
      if (![
        'pivot',
        'group',
        'calculation',
      ].includes(this.type)) {
        return false;
      }

      // any calculation that is using a field gets filters
      if (this.type === 'calculation') {
        return true;
      }

      // only filter groups of connection fields
      if (!this.field || this.field.type !== 'connection') {
        return false;
      }

      return true;
    },

    showSort() {
      // only filter groups of connection fields
      if (this.type === 'group' || this.type === 'pivot') {
        return true;
      }

      return false;
    },

    showDate() {
      if (this.item.type === 'date' || this.item.type === 'date_time') {
        return true;
      }

      return false;
    },

    showCalculationOptions() {
      // this item is already selecting a field for a specific calculation (total or average)
      if (![
        'calculation',
        'summary',
      ].includes(this.type)) {
        return false;
      }

      if (this.item.field && this.item.field === 'count') {
        return false;
      }

      return true;
    },
  },
  watch: {

    'item.field': function (newVal, oldVal) {
      let changeLabel = false;

      // update the label for the new field if old label was the default for the old value
      // this is annoying
      if (oldVal) {
        if ([
          'count',
          'average',
        ].includes(oldVal)) {
          if ([
            'Count',
            'Average',
          ].includes(this.item.label)) {
            changeLabel = true;
          }
        } else if (this.item.label === this.object.getField(oldVal)?.name) {
          changeLabel = true;
        }
      }

      if (changeLabel) {
        if ([
          'count',
          'average',
        ].includes(newVal)) {
          this.item.label = (newVal === 'count') ? 'Count' : 'Average';
        } else {
          this.item.label = this.object.getField(newVal)?.name ?? '';
        }
      }

      // if we're tracking a calculation ensure we're resetting that if they choose record count or record average
      if ([
        'count',
        'average',
      ].includes(newVal) && this.item.calculation) {
        this.item.calculation = newVal;
      }
    },

    'item.calc': function (newVal, oldVal) {
      const oldCalcOption = this.calculationOptions.find((option) => option.value === oldVal);
      const labelFromOldCalc = oldCalcOption ? oldCalcOption.label : '';

      log('WATCH itemLocal.calc', newVal, oldVal, this.item.label, labelFromOldCalc);

      // update the label for the new field if old label was the old field
      if (this.item.label === labelFromOldCalc) {
        this.item.label = this.calculationOptions.find((option) => option.value === newVal).label;
      }
    },
  },
  methods: {

    onClickSettings() {
      this.itemLocal = JSON.parse(JSON.stringify(this.item));

      if (this.showDate) {
        const currentDate = moment(new Date()).format('MM/DD/YYYY');

        log('currentDate', currentDate);

        const dateDefaults = {
          limit: 'all',
          range: 'day',
          sort: 'asc',
          number: 10,
          date_from: currentDate,
          date_to: currentDate,
        };

        this.itemLocal = { ...dateDefaults, ...this.itemLocal };
      }

      this.showSettingsModal = true;
    },

    onChangeGroup() {
      log('onChangeGroup, group');

      if (this.item.group.indexOf('-') === -1) {
        // by totals/averages; not really grouping these
        this.item.type = this.item.group;
        this.item.label = this.item.label || this.item.group;
      } else {
        const type = this.item.group.split('-');

        this.item.type = type[0];
        this.item.field = type[1];
        this.item.label = this.item.label || this.object.getField(this.item.field).get('name');
      }
    },

    onChangePivot() {
      log('onChangePivot, group');

      const field = this.object.getField(this.item.field);

      this.item.type = field.type;
      this.item.label = this.item.label || field.get('name');

      // on changing pivot type set the default sort to 'asc'
      // this is done to ensure what is displayed in the preview
      // matches the default shown on the Edit Report Group modal
      if (this.showSort) {
        this.item.sort = 'asc';
      }
    },

    onUpdateGroupSettings(group, field) {
      log('onUpdateGroup', this.itemLocal, group, field);

      this.showSettingsModal = false;

      // KLUDGE: Ensure reactivity on this report item. Should explore local state so no need for this
      this.item.label = this.itemLocal.label;

      if (!isEmpty(this.itemLocal.filters)) {
        this.item.filters = this.itemLocal.filters;
      } else {
        delete this.item.filters;
      }

      if (this.showSort) {
        this.item.sort = this.itemLocal.sort;
      }

      if (this.showDate) {
        log('setting date_from', this.itemLocal.date_from, moment(this.itemLocal.date_from).format('MM/DD/YYYY'));

        this.item.limit = this.itemLocal.limit;
        this.item.range = this.itemLocal.range;
        this.item.number = this.itemLocal.number;
        this.item.date_from = moment(this.itemLocal.date_from).format('MM/DD/YYYY');
        this.item.date_to = moment(this.itemLocal.date_to).format('MM/DD/YYYY');
      }
    },
  },
};
</script>

<style lang="scss">
.report-property-item {
  flex-grow: 1;
  max-width: calc(100% - 30px);
  display: flex;
  align-items: center !important;

  > * {
    margin-right: 6px;
  }

  svg.icon-settings {
    color: #73757b;
  }

  .button:hover svg.icon-settings {
    color: inherit;
  }

  .icon-filter-funnel {
    width: 15px;
    height: 15px;
  }

  .trigger b {
    display: inline;
  }
}
</style>
