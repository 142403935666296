<template>
  <Modal
    :title="`${moveCopyTitle} View`"
    :back="backLink"
    size="medium"
    data-cy="movecopy-view-modal"
  >
    <div
      v-if="isInvalidView"
      class="text-centered text-left"
    >
      <div class="bg-[#FEF4D7] p-4 rounded-lg">
        <Icon
          type="exclamation-triangle"
          class="text-warning-emphasis h-4 w-4 m-0 mb-4"
          style="color: #f9db42; height: 60px; width: 60px; margin: 1rem"
        />
        <p class="text-warning-emphasis">
          Unfortunately this <strong>{{ view.type }}</strong> cannot be {{ moveCopyVerbed }}.<br>
        </p>
        <p
          v-if="view.type === `login`"
          class="text-warning-emphasis"
        >
          <a
            href="https://learn.knack.com/article/iqyr40dmbz-logins-and-registrations"
            _target="blank"
            class="underline text-warning-emphasis"
          >See this article on logins</a> for more information.
        </p>
        <p
          v-if="view.type === `report`"
          class="text-warning-emphasis"
        >
          Reports can only be {{ moveCopyVerbed }} when every chart is using<br>records from the <strong>same</strong> object.
        </p>
      </div>
      <div class="submit-buttons flex justify-end">
        <a
          data-cy="movecopy-close"
          class="button save p-3 rounded-lg border border-solid border-default bg-transparent text-emphasis h-10 m-0 text-base"
          @click="onClose"
        >
          Close
        </a>
      </div>
    </div>
    <div
      v-else-if="isInvalidTargetPageError"
      class="text-centered"
    >
      <Icon
        type="warning"
        style="color: #f9db42; height: 60px; width: 60px; margin: 1rem"
      />
      <p>
        Unfortunately this View cannot be {{ moveCopyVerbed }} to <strong>{{ targetPageModel.name }}</strong>.
      </p>
      <p>
        {{ isInvalidTargetPageError }}
      </p>
      <div class="submit-buttons">
        <a
          data-cy="movecopy-close"
          class="button save"
          @click="onClose"
        >
          Close
        </a>
      </div>
    </div>
    <div
      v-else-if="showSuccessMessage"
      class="text-centered"
    >
      <Icon
        type="check-circle"
        style="color: green; height: 60px; width: 60px; margin: 1rem"
      />
      <p>The view has been successfully {{ moveCopyVerbed }} to the<br><strong>{{ targetPageModel.name }}</strong> page.</p>
      <div class="submit-buttons flex justify-end gap-2">
        <a
          data-cy="movecopy-go-to-new-page"
          class="button save order-2 p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium m-0"
          @click="onGoToNewPage"
        >
          Go to new page
        </a>
        <a
          data-cy="movecopy-close"
          class="button gray-outline text-emphasis order-1 p-3 rounded-lg bg-transparent border-0 text-base leading-4 font-medium m-0"
          @click="onClose"
        >
          Close
        </a>
      </div>
    </div>
    <div
      v-else-if="wizardStep"
      style="font-size: .875rem;"
    >
      <WizardStep
        v-model:wizard-step="wizardStep"
        class="first"
        :step="1"
      >
        <template #edit>
          <div :class="wizardEditTextClasses">
            <div>
              <label>
                {{ moveCopyVerbing }} to
              </label>
            </div>
            <div class="flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis mt-2">
              <Icon
                class="w-4 h-4 mr-1 text-subtle"
                type="page"
              />
              <span>
                {{ targetPageModel.name }}
              </span>
            </div>
          </div>
        </template>
        <template #intro>
          <div>
            Select a page to <strong>{{ action }}</strong> this view to.
          </div>
        </template>
        <form>
          <div class="flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis mt-2">
            <Icon
              class="w-4 h-4 mr-1 text-subtle"
              type="page"
            />
            <span>
              {{ view.name }}
            </span>
          </div>
          <div>
            <label class="text-default text-sm font-medium mb-2 leading-4">Page</label>
            <select
              v-model="targetPageKey"
              class="text-base py-2 pl-3 leading-5"
              data-cy="movecopy-select"
            >
              <option
                v-for="option in targetPageOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="submit-buttons flex justify-end ">
            <a
              data-cy="movecopy-save"
              class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium m-0"
              @click="onSelectTargetPage"
            >
              {{ viewIsStatic ? moveCopyTitle : `Next` }}
            </a>
          </div>
        </form>
      </WizardStep>
      <WizardStep
        v-if="wizardStep > 1"
        v-model:wizard-step="wizardStep"
        :step="2"
      >
        <template #edit>
          <div :class="wizardEditTextClasses">
            Confirm records
          </div>
        </template>
        <template #intro>
          <div class="mb-2">
            Confirm records
          </div>
        </template>
        <div>
          <p>Confirm the records this view will work with. They may change to work with the new page.</p>
          <ViewAddSourcePath
            :page="targetPageModel"
            :source="source"
            :paths="sourcePaths"
            :path-defaults="pathDefaults"
            :records-quantity="recordsQuantity"
            :view-type="view.type"
            @submit="onSelectSourcePath"
          />
        </div>
      </WizardStep>
      <WizardStep
        v-if="wizardStep > 2"
        v-model:wizard-step="wizardStep"
        :step="3"
      >
        <template #edit>
          <div :class="wizardEditTextClasses">
            Warning
          </div>
        </template>
        <template #intro>
          <div class="mb-2">
            Warning! Please confirm these changes.
          </div>
        </template>
        <div v-html="confirmationMessage" />
        <div class="submit-buttons">
          <a
            data-cy="movecopy-confirm"
            class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium m-0"
            @click="onConfirmViewChanges"
          >
            Confirm and Save
          </a>
        </div>
      </WizardStep>
    </div>
  </Modal>
</template>
<script>
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { mapActions, mapGetters } from 'vuex';

import Icon from '@/components/ui/Icon';
import Modal from '@/components/ui/Modal';
import PageNavUtils from '@/components/pages/PageNavUtils';
import RequestUtils from '@/components/util/RequestUtils';
import ViewAddSourcePath from '@/components/pages/page/ViewAddSourcePath';
import ViewCopyUtils from '@/components/views/ViewCopyUtils';
import ViewUtils from '@/components/views/ViewUtils';
import WizardStep from '@/components/ui/WizardSection';
import { addViewSourcePath } from '@/lib/page/view-source-helper';
import { getViewChildPageKeys } from '@/lib/page/view-child-page-helper';
import View from '@/store/models/View';

export default {
  name: 'ViewCopy',
  components: {
    Icon,
    Modal,
    ViewAddSourcePath,
    WizardStep,
  },
  mixins: [
    PageNavUtils,
    RequestUtils,
    ViewUtils,
    ViewCopyUtils,
  ],
  props: {
    action: {
      type: String,
      default: 'copy', // copy | move
    },
    backLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      confirmationMessage: '',
      isInvalidTargetPageError: null,
      pathDefaults: {},
      recordsQuantity: 'many',
      showSuccessMessage: false,
      source: null,
      sourcePaths: {},
      targetPageKey: '',
      targetPageModel: {},
      wizardStep: 1,
    };
  },
  computed: {
    ...mapGetters([
      'initPageByKey',
      'getStarterPlusChildPages',
    ]),
    isInvalidView() {
      if (!this.hasActiveView) {
        return true;
      }

      if (this.view.type === 'login') {
        return true;
      }

      if (this.view.type === 'report') {
        const reports = this.view.getReports();

        const mismatchedSource = reports.some((report) => report.source.object !== this.view.source.object);

        // Only reports that all use the same source can be copy or moved
        if (mismatchedSource) {
          return true;
        }
      }

      return false;
    },
    moveCopyTitle() {
      return capitalize(this.action);
    },
    moveCopyVerbing() {
      if (this.action === 'move') {
        return 'Moving';
      }

      return `${this.moveCopyTitle}ing`;
    },
    moveCopyVerbed() {
      if (this.action === 'move') {
        return 'moved';
      }

      return 'copied';
    },
    targetPageOptions() {
      if (this.isInvalidView) {
        return [];
      }

      // Static views like menus or rich text can move to any page
      const targetPages = (this.viewIsStatic) ? this.getStarterPlusChildPages() : this.getAllowedTargets(this.view);

      const options = [];

      targetPages.forEach((targetPage) => {
        options.push({
          value: targetPage.key,
          label: targetPage.label,
        });
      });

      return options;
    },
    viewIsStatic() {
      if (!this.hasActiveView) {
        return false;
      }

      return this.view?.isStatic();
    },
    wizardEditTextClasses() {
      return 'mb-4 text-base font-semibold tracking-[.32px]';
    },
  },
  created() {
    this.recordsQuantity = this.view.worksWithSpecificRecord() ? 'one' : 'many';

    if (isEmpty(this.targetPageOptions)) {
      return;
    }

    this.targetPageKey = get(this, 'targetPageOptions[0].value');
  },
  methods: {
    ...mapActions([
      'copyView',
    ]),
    onSelectTargetPage() {
      if (!this.targetPageKey) {
        return this.$notify({
          group: 'top-right',
          type: 'error',
          duration: 5000,
          'animation-type': 'velocity',
          title: 'Selected Page Does Not Exist',
          text: 'Try refreshing this page and attempting the action again, or contact Knack support.',
          data: {
            titleIcon: 'error',
          },
        });
      }

      // Reset a starting source
      this.source = {};

      // Get the full page model of our target page
      this.targetPageModel = this.initPageByKey(this.targetPageKey);

      log(':: :: onSelectTargetPage', this.targetPageKey, this.targetPageModel);

      // Static views can submit now
      if (this.viewIsStatic) {
        this.submitMoveCopy();

        return;
      }

      // Set the source object for non-static views
      this.source.object = this.view.source.object;

      // Make sure that the destination page is not an ancestor of the view.
      const childPageKeys = getViewChildPageKeys(this.view);
      const pageHierarchy = this.targetPageModel.getPageHierarchy().map((page) => page.key);
      const targetPageIsAViewAncestor = !isEmpty(intersection(childPageKeys, pageHierarchy));

      if (targetPageIsAViewAncestor) {
        this.isInvalidTargetPageError = `'${this.targetPageModel.name}' is invalid because it is a child of this View.`;

        return;
      }

      // Set view meta props
      const inflectionsType = this.view.isSingleRecordView() ? 'singular' : 'plural';

      // Get our source options for this page
      const sourceOptions = this.targetPageModel.getViewSourceOptions(inflectionsType);

      const pageSourcePaths = {};

      // Check each source option to add as a valid path
      sourceOptions.forEach((sourceOption) => {
        // Filter source options by this view's object
        if (sourceOption.objectKey !== this.source.object) {
          return;
        }

        if (sourceOption.quantity === this.recordsQuantity) {
          addViewSourcePath(pageSourcePaths, sourceOption, this.recordsQuantity);
        }
      });

      // addViewSourcePath() indexes for an entire page, so shortcut the paths for this specific object
      this.sourcePaths = pageSourcePaths[this.source.object]?.options;

      this.wizardStep++;
    },
    onSelectSourcePath(path) {
      // Save our source to local state
      this.source = path.source;

      // Check if we need to show any confirmation messages
      this.setConfirmationMessages();

      // Show any confirmation messages before submiting
      if (this.confirmationMessage) {
        this.wizardStep++;

        return;
      }

      // No confirmation, so we can submit
      this.submitMoveCopy();
    },
    setConfirmationMessages() {
      // Reset
      this.confirmationMessage = '';

      const viewCopy = (this.action === 'copy') ? 'new view' : 'view';

      // Warn about a view going to an unprotected page
      if (this.page.requiresAuthentication() && !this.targetPageModel.requiresAuthentication()) {
        this.confirmationMessage = `<p><strong>${this.targetPageModel.name}</strong> is not protected by a login. This ${viewCopy} will be accessible by anyone with the URL if you submit this request.</p>`;

        // Warn about moving a view going to an unprotected page
      } else if (this.view?.source?.authenticated_user && !this.source.authenticated_user) {
        this.confirmationMessage = `<p>This ${viewCopy} will not work with the logged-in user if you submit this request.</p>`;
      }

      // Warn against losing rules referencing the logged-in user
      if (this.page.requiresAuthentication()) {
        const { targetPageUserRoles, currentPageUserRoles, removedRoles } = this.getTransferRoles();

        log(`targetPageUserRoles: ${targetPageUserRoles}, currentPageUserRoles ${currentPageUserRoles}`);

        // Return if profiles are the same. All logged-in user rules will transfer fine
        if (isEqual(targetPageUserRoles, currentPageUserRoles)) {
          return;
        }

        const ruleMessages = [];

        const userRules = this.view.getWhichRuleTypesReferenceTheLoggedInUser(removedRoles);

        Object.keys(userRules).forEach((key) => {
          if (userRules[key]) {
            ruleMessages.push(`one <strong>${key} rule</strong>`);
          }
        });

        if (ruleMessages.length) {
          this.confirmationMessage += `<p>This ${viewCopy} contains at least ${ruleMessages.join(' and ')} that references the logged-in user. ${ruleMessages.length > 1 ? 'These rules' : 'This rule'} will be removed ${(this.action === 'copy') ? 'from the newly copied view' : ''}.</p>`;
        }
      }
    },
    onConfirmViewChanges() {
      this.submitMoveCopy();
    },
    getTransferRoles() {
      let targetPageUserRoles = [];

      if (this.targetPageModel.requiresAuthentication()) {
        targetPageUserRoles = [
          ...this.targetPageModel.authentication_profiles,
          'all_users',
        ];
      }

      const currentPageUserRoles = [
        ...this.page.authentication_profiles,
        'all_users',
      ];

      log(`targetPageUserRoles: ${targetPageUserRoles}, currentPageUserRoles ${currentPageUserRoles}`);

      const removedRoles = difference(currentPageUserRoles, targetPageUserRoles);

      return {
        targetPageUserRoles,
        currentPageUserRoles,
        removedRoles,
      };
    },
    submitMoveCopy() {
      // Create a new view using the source view's attributes
      const viewProps = cloneDeep(this.view.raw());

      // Not sure if this is needed
      if (this.action === 'copy') {
        viewProps.key = 'new';
      }

      const viewPage = {
        key: this.targetPageKey,
      };

      const newView = new View(viewProps, viewPage);

      // If this is a view with a source, update it with the new source
      if (newView.source) {
        // Set the new source, but keep custom settings from the original view
        // Check for each individually as not all properties should always be present
        if (newView.source.criteria) {
          this.source.criteria = newView.source.criteria;
        }

        if (newView.source.sort) {
          this.source.sort = newView.source.sort;
        }

        if (newView.source.limit) {
          this.source.limit = newView.source.limit;
        }

        // Set the source
        newView.source = this.source;

        // Report views might need to update the source of each report
        if (newView.type === 'report') {
          newView.updateReportsSource(this.source);
        }
      }

      // Remove any user rules from record views
      if (this.page.requiresAuthentication()) {
        const { targetPageUserRoles, currentPageUserRoles, removedRoles } = this.getTransferRoles();

        log(`targetPageUserRoles: ${targetPageUserRoles}, currentPageUserRoles ${currentPageUserRoles}`);

        if (!isEqual(targetPageUserRoles, currentPageUserRoles)) {
          // Reduce any rules that contain values that reference the logged-in user
          newView.reduceRulesThatReferenceLoggedInUser(removedRoles);
        }
      }

      const submitFunction = (this.action === 'copy') ? 'copyView' : 'moveView';

      // Ensure this view doesn't trigger the "Save Changes" from the active view watcher
      this.$store.dispatch('unwatchActiveView');

      this.commitRequest({
        request: () => window.Knack.Api[submitFunction](this.page.key, this.targetPageKey, this.view.key, newView.raw()),
        onError: () => {
          const action = this.moveCopyVerbing;
          const notificationTitle = `Error ${action} View`;
          this.$notify({
            group: 'top-right',
            type: 'error',
            duration: 5000,
            'animation-type': 'velocity',
            title: notificationTitle,
            text: 'Try refreshing this page and attempting the action again, or contact Knack support.',
            data: {
              titleIcon: 'error',
            },
          });
        },
        onSuccess: async (success) => {
          // Show success message
          this.showSuccessMessage = true;

          // Make sure the new page groups is propagated back to the server.
          await this.$store.dispatch(
            'page/api/updateLayout',
            { pageKey: this.targetPageKey },
            { root: true },
          );

          // `true` for `onlyLoadViewsThatHaveNotBeenLoaded`
          // This parameter only requests data from views whose data
          // has not been loaded yet
          // See store/models/Page
          if (this.action === 'copy') {
            await this.page.loadViews(true);
          }
        },
      });
    },
    onClose() {
      this.$router.push(this.backLink);
    },
    onGoToNewPage() {
      this.$router.push(`/pages/${this.targetPageKey}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 18px;
  height: 18px;
  display: inline-flex;
  vertical-align: sub;
  margin-right: 2px;
  opacity: .8;
}
</style>
