<template>
  <div class="drawer_page">
    <Transition :name="transition">
      <slot />
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    transition: {
      type: String,
      default: 'slide-left',
    },
  },
};
</script>
