<template>
  <div class="app-error">
    <EmptyState
      class="app-error-message"
      title="This app is currently unavailable"
      image="not-found"
      action="Go to App Dashboard"
      description="We are performing some maintenance at the moment. Please try again later."
      @action="goToDashboard"
    />
  </div>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState';

export default {
  name: 'ApplicationMaintenance',
  components: {
    EmptyState,
  },
  methods: {
    goToDashboard() {
      this.$router.push(process.env.VUE_APP_DASHBOARD_URL || '/');
    },
  },
};
</script>

<style lang="scss" scoped>
.app-error {
  height: 100vh;

  .emptyState {
    max-width: 450px;
  }
}

.app-error-message {
  position: relative;
  top: 45%;
  transform: translateY(-50%);
}
</style>
