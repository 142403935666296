<script setup>
import { computed, defineProps, ref, onUpdated } from 'vue';
import { useStore } from 'vuex';

import FontAwesome from '@/components/ui/FontAwesome';
import LegacyDropdownMenu from '@/components/renderer/header/pieces/LegacyDropdownMenu';

const store = useStore();

// Props
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  closeMobileNav: {
    type: Function,
    required: true,
  },
  entryPages: {
    type: Array,
    required: true,
  },
});

// Refs to hold the references to elements
const mobileNavElement = ref(null);

// Computed
const linkActiveDesign = computed(() => {
  const backgroundColor = store.getters.app.attributes.design.regions.header.legacySettings.menu.outline_or_fill_color;
  return {
    backgroundColor,
  };
});

// Methods
const onClickOutsideMobileNav = (event) => {
  // This is a bit hacky, but we needed a way to mimic the behavior of closing the mobile nav when
  // a click occurs outside the nav but the target is still inside the body of the preview element
  const previewBodyContainerElement = document.querySelector('.kn-design-preview > #knack-body > .kn-container');
  const isClickOutsideMobileNav = !mobileNavElement.value?.contains(event.target);
  const isClickInsidePreviewBody = previewBodyContainerElement?.contains(event.target);

  if (isClickOutsideMobileNav && isClickInsidePreviewBody) {
    props.closeMobileNav();
  }
};

// Lifecycle hooks
onUpdated(() => {
  if (props.isOpen) {
    document.addEventListener('click', onClickOutsideMobileNav);
  } else {
    document.removeEventListener('click', onClickOutsideMobileNav);
  }
});
</script>

<template>
  <transition name="kn-mobile-menu-transition">
    <section
      v-if="isOpen"
      id="kn-mobile-menu"
      ref="mobileNavElement"
    >
      <ul
        id="app-menu-list-mobile"
        class="kn-menu-list"
      >
        <li
          v-for="page in entryPages"
          :key="page.key"
          :class="{'is-active': page.isActive}"
          :style="page.isActive ? linkActiveDesign : undefined"
        >
          <!-- If page has dropdown menu, render dropdown page links -->
          <LegacyDropdownMenu
            v-if="page.dropdownPages"
            :entry-page="page"
            is-mobile-dropdown
            :dropdown-pages="page.dropdownPages"
            :on-dropdown-link-click="closeMobileNav"
          />

          <a
            v-else
            :href="page.slug"
            @click="closeMobileNav"
          >
            <span v-if="page.icon?.icon && page.icon?.align === 'left'">
              <FontAwesome :name="page.icon.icon" />
            </span>

            {{ page.name }}

            <span v-if="page.icon?.icon && page.icon?.align === 'right'">
              <FontAwesome :name="page.icon.icon" />
            </span>
          </a>
        </li>
      </ul>
    </section>
  </transition>
</template>

<style lang="scss">
#kn-mobile-menu {
  position: absolute;
  top: 0;
  width: 70%;
  height: 100vh;
  font-size: 1.2rem;
  background-color: #ffffff;
  box-shadow: 0 0 40px 0 rgb(0 0 0 / 10%);
  transition: all .2s ease-in-out;
  overflow-y: auto;
  z-index: 1;

  #app-menu-list-mobile {
    margin: 0.75em;
    list-style: none;

    .kn-root-dropdown {
      display: inline-block;
    }

    li {
      margin-bottom: 4px;
      &.is-active a {
        color: #fff;
      }

      a {
        display: inline-block;
        border-radius: 0;
        text-decoration: none;
        border-color: #2f96b4;
        padding: 5px 10px;
      }

      ul {
        border-left: 1px solid #dbdbdb;
        margin: 0 10px !important;
        padding-left: 10px;
      }
    }
  }
}

/* Mobile nav opening/closing Vue transitions */
.kn-mobile-menu-transition-enter-active,
.kn-mobile-menu-transition-leave-active {
  transition: transform .2s ease-in-out;
  transform: translateX(0);
}
.kn-mobile-menu-transition-enter,
.kn-mobile-menu-transition-enter-from,
.kn-mobile-menu-transition-leave-to {
  transform: translateX(-100%);
}
</style>
