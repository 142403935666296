<template>
  <Popover
    class="design-property-picker-dropdown"
    @show="$emit(`toggle`, true)"
    @hide="$emit(`toggle`, false)"
  >
    <template #trigger>
      <a
        v-tippy
        content="Override Options"
        class="settings transition hover-link target-wrapper"
      >
        <Icon
          class="target-icon"
          type="more-horiz"
        />
      </a>
    </template>

    <template #content>
      <div class="links design-settings-popover">
        <header v-if="hasHeader">
          <span>
            These options appear when the <router-link to="/"><span>app default settings</span></router-link> are overidden
            <a
              target="_blank"
              href=""
            >
              <Icon
                class="help-icon"
                type="help-circle"
              />
            </a>
          </span>
        </header>
        <div
          v-if="!isAppLevelDesign"
          v-close-popper
          class="kn-popover-item"
          @click="$emit(`revert`)"
        >
          <Icon
            class="kn-popover-item-icon"
            type="revert"
          />
          <div class="kn-popover-item-text">
            <span class="kn-popover-item-header">Revert to Default</span>
            <span class="kn-popover-item-description">{{ defaultLabel }}</span>
          </div>
        </div>
        <div
          v-if="!isAppLevelDesign"
          v-close-popper
          class="kn-popover-item"
          @click="$emit(`replace`)"
        >
          <Icon
            class="kn-popover-item-icon"
            type="change-replace"
          />
          <div class="kn-popover-item-text">
            <span class="kn-popover-item-header">Replace Current Default</span>
            <span class="kn-popover-item-description">Make this the new default setting</span>
          </div>
        </div>
        <div
          v-if="isAppLevelDesign"
          v-close-popper
          class="kn-popover-item"
          @click="$emit(`revert`)"
        >
          <Icon
            class="kn-popover-item-icon"
            type="change-replace"
          />
          <div class="kn-popover-item-text">
            <span class="kn-popover-item-header">Revert to Knack Default</span>
            <span class="kn-popover-item-description">{{ defaultLabel }}</span>
          </div>
        </div>
      </div>
    </template>
  </Popover>
</template>

<script>
import isNil from 'lodash/isNil';
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';

export default {
  components: {
    Icon,
    Popover,
  },
  props: {
    defaultValue: {},
    isAppLevelDesign: {
      type: Boolean,
      default: false,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    defaultLabel() {
      if (!isNil(this.defaultValue) && !isNil(this.defaultValue.label)) {
        return this.defaultValue.label;
      }

      return this.defaultValue;
    },
  },
};
</script>

<style lang="scss">
.design-property-picker-dropdown {
  height: 20px;

  & > .trigger {
    height: 20px;
    display: inline-flex;
  }

  .target-icon {
    width: 19px;
    height: 19px;
    color: #888;
    margin-left: 4px;
    margin-top: 1px;
  }
}

.design-settings-popover {

  .kn-popover-item {
    display:flex;
    align-items: flex-start;
    padding: 6px;

    &:hover {
      background-color: $gray50;
      cursor: pointer;
    }

    &-icon {
      @include icon-16;
      color:$gray800;
    }

    &-text {
      display:flex;
      flex-direction:column;
      margin-left: 8px;
      color:$gray800;
    }

    &-header {
      font-size: .8em;
      font-weight: 600;
    }

    &-description {
      font-size: .7em;
      font-style: italic;
    }
  }

  header {
    background-color:#efefef;
    border-bottom:1px #ddd;
    font-size:.8em;
  }

  .help-icon {
    width: 14px;
    height: 14px;
    fill: gray;
    position:relative;
    top: 2px;
  }

  .kn-popover .links {
    padding: 0px;
  }

  .kn-popover .links a {
    background-color: inherit;
    padding: 0px;

    & > span {
      color: #761c66;
      border-bottom: 1px solid #761c66;
    }
  }

  header, .kn-popover-item {
    max-width: 250px;
    padding: 8px;
  }

}
</style>
