export default {
  methods: {
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    /**
     * Returns the gramatically correct indefinite article for a word (ex. 'a word' or 'an answer')
     *
     * @param {string} text
     * @returns {string} indefiniteArticle - 'a' or 'an'
     */
    indefiniteArticle(text) {
      const vowels = ['A', 'E', 'I', 'O', 'U'];
      const indefiniteArticle = vowels.includes(text.charAt(0).toUpperCase()) ? 'an' : 'a';
      return indefiniteArticle;
    },
  },
};
