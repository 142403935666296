<template>
  <div class="input-wrapper">
    <input
      v-model="localValue"
      :class="localInputClasses"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :autocomplete="isAutocomplete"
      type="text"
    >
    <button
      v-if="showReset"
      class="reset-button top-3"
      type="button"
      @click="onResetButtonClick"
    >
      <Icon
        class="button-icon text-default"
        type="close"
      />
    </button>
    <button
      v-if="hasButton"
      class="kn-input kn-input-button bg-white rounded-lg rounded-s-none border-l-[0.5px] hover:border-l hover:-ml-[0.5px] left-0 hover:bg-brand-50 hover:border-brand-600 group"
      type="submit"
    >
      <span v-if="buttonText">{{ buttonText }}</span>
      <Icon
        v-else-if="buttonIconType"
        class="button-icon text-default group-hover:text-brand-400"
        :type="buttonIconType"
      />
    </button>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  name: 'TextInput',
  components: {
    Icon,
  },
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    cssClass: {
      type: String,
      default: 'kn-input',
    },
    name: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    hasFieldsList: {
      type: Boolean,
      default: false,
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonIconType: {
      type: String,
      default: 'search',
    },
    showReset: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  computed: {
    localInputClasses() {
      return [
        {
          input: true,
          'input-rightSquare': this.hasFieldsList,
          'input-resetButton': this.showReset,
          'kn-input': true,
          'kn-input--full': this.full,
          'rounded-lg': true,
          'rounded-e-none': this.showReset || this.hasButton,
          '!h-10': true,
        },
        this.inputClasses,
      ];
    },
    isAutocomplete() {
      return this.autocomplete ? 'on' : 'off';
    },
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  methods: {
    onResetButtonClick() {
      this.$emit('reset');
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="text"] {
  background-color: #fff;
  border-radius: .1875rem;

  &.input-rightSquare {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.input-resetButton {
    padding-right: 1.75rem;
  }
}

.input-wrapper {
  position:relative;
  display:flex;
}

.kn-input-button {
  display: flex;
  align-items: center;
  appearance: none;
  position:relative;
  left: -2px;
  padding: 0 10px;
  border: 1px solid $gray200;
  border-radius: 0 4px 4px 0;
  background-color: $gray50;
  @include font-body;
}

.kn-input-button:hover {
  background-color: $gray100;
}

input[type="text"].kn-input:hover {
  border-color: $gray300;
}

button.kn-input.kn-input-button.kn-input-reset {
  margin-left: .5em;
  left: 0px;
  border-radius: 4px;
  background: initial;
  border-color: none;

  &:hover {
    border: 1px solid #b5b5b5;
    background-color: #efefef;
  }
}

.visible {
  display: block;
}

.invisible {
  display: none;
}

.kn-input--full {
  width: 100%;
}

.button-icon {
  width: 16px;
  height: 16px;
}

.reset-button {
  position: absolute;
  right: 46px;
  top: 8px;
  border: unset;
  padding: unset;
  background: unset;
  color: $gray900;

  &:hover {
    color: $gray400;
  }
}
</style>
