<template>
  <ViewToolbox
    back-title="Search Menu"
    title="Settings"
  >
    <div>
      <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] border-0 tw-toolbox-label">Search Results</label>
      <div class="input-labels tw-input-labels">
        <label>
          <input
            :checked="viewRaw.results_type === `table`"
            type="radio"
            value="table"
            @change="handleResultsTypeChange"
          >
          Display results in a grid
        </label>
        <label>
          <input
            :checked="viewRaw.results_type === `list`"
            type="radio"
            value="list"
            @change="handleResultsTypeChange"
          >
          Display results in a list
        </label>
      </div>
    </div>

    <div v-if="isListResults">
      <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label">List Layout</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="viewRaw.list_layout"
          type="radio"
          value="one-column"
        >1 Column</label>
        <label><input
          v-model="viewRaw.list_layout"
          type="radio"
          value="two-column"
        >2 Columns</label>
        <label><input
          v-model="viewRaw.list_layout"
          type="radio"
          value="three-column"
        >3 Columns</label>
      </div>
    </div>

    <div>
      <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label">Options</label>
      <div class="input-labels tw-input-labels">
        <label>
          <input
            v-model="viewRaw.initial_results"
            type="checkbox"
          >Show results to start
        </label>
        <label v-if="viewRaw.results_type === `table`">
          <input
            v-model="viewRaw.hide_empty"
            type="checkbox"
          >Hide columns that don't have values
        </label>
        <label v-if="viewRaw.results_type === `list`">
          <input
            v-model="viewRaw.hide_fields"
            type="checkbox"
          >Hide fields that don't have values
        </label>
        <label>
          <input
            v-model="viewRaw.allow_exporting"
            type="checkbox"
          >Allow record exporting
        </label>
        <label v-if="viewRaw.results_type === `table`">
          <input
            v-model="viewRaw.cell_editor"
            type="checkbox"
          >Inline editing
        </label>
      </div>
    </div>

    <div>
      <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] border-0 tw-toolbox-label">Empty Results Text <HelpIcon copy="Text to show when the search<br />has no results" /></label>
      <input
        v-model="viewRaw.no_data_text"
        type="text"
      >
    </div>

    <div v-if="viewRaw.results_type === `table`">
      <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] border-0 tw-toolbox-label">Column Summaries</label>

      <ActionList
        v-if="totals.length > 0"
        v-model:items="totals"
        class="is-grouped bg-subtle"
        :default-item="defaultColumnSummary"
        :can-be-empty="true"
      >
        <template #default="props">
          <input
            v-model="props.item.label"
            type="text"
          >
          <select
            v-model="props.item.calc"
            class="text-base py-2 pl-3 leading-5"
            style="margin-left: 5px;"
          >
            <option value="sum">
              Sum
            </option>
            <option value="average">
              Average
            </option>
            <option value="min">
              Minimum
            </option>
            <option value="max">
              Maximum
            </option>
          </select>
        </template>
      </ActionList>
      <div v-if="totals.length === 0">
        <p class="margin-bottom-half">
          Show summaries of each column at the bottom of the grid.
        </p>
        <a
          class="button tiny fuchsia-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium"
          @click="onAddColumnSummary"
        >
          <Icon
            class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
            type="plus-thin"
          />
          Add Summary
        </a>
      </div>
    </div>

    <Toggle
      :is-open="true"
      class="margin-top-double mt-2.5"
    >
      <template #title>
        <span>Pagination</span>
      </template>

      <template #content>
        <Pagination
          v-model:rows-per-page="viewRaw.rows_per_page"
          v-model:allow-limit="viewRaw.allow_limit"
          v-model:display-below="viewRaw.display_pagination_below"
        />
      </template>
    </Toggle>

    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Title & Description</span>
      </template>

      <template #content>
        <div class="grid-verticalGaps border border-solid border-subtle border-x-0 border-t-0">
          <div>
            <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] border-0 tw-toolbox-label">Title</label>
            <input
              v-model="viewRaw.title"
              type="text"
            >
          </div>
          <div>
            <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] border-0 tw-toolbox-label">Description</label>
            <textarea v-model="viewRaw.description" />
          </div>
        </div>
      </template>
    </Toggle>

    <!-- Grid Design -->
    <template v-if="isTableResults && !isLegacyTheme">
      <Toggle
        :is-open="true"
        class="mt-2.5"
      >
        <template #title>
          <span>Grid Design</span>
        </template>
        <template #content>
          <ToggleSwitch
            v-model:checked="showTableDesignOptions"
            label="Use Custom Design Settings"
          >
            <template #offDescription>
              <span class="text-default">
                This grid uses the
                <router-link
                  to="/settings/design/tables"
                  class="text-default underline"
                >
                  global grid design settings.
                </router-link>
              </span>
            </template>

            <template #description>
              <span class="text-default">
                This grid overrides the
                <router-link
                  to="/settings/design/tables"
                  class="text-default underline"
                >
                  global grid design settings
                </router-link>
                with the following settings:
              </span>
            </template>
          </ToggleSwitch>

          <TablesOptions
            v-if="showTableDesignOptions"
            :custom-design-object="viewRaw.table_design"
          />
        </template>
      </Toggle>
    </template>
  </ViewToolbox>
</template>

<script>
import { cloneDeep } from 'lodash';
import ActionList from '@/components/ui/lists/ActionList';
import HelpIcon from '@/components/ui/HelpIcon';
import Toggle from '@/components/ui/Toggle';
import ToggleSwitch from '@/components/ui/ToggleSwitch';
import Pagination from '@/components/views/shared/settings/Pagination';
import TablesOptions from '@/components/settings/design/options/TablesOptions';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import Icon from '@/components/ui/Icon';

export default {
  name: 'TableSettings',
  components: {
    ActionList,
    HelpIcon,
    Pagination,
    TablesOptions,
    Toggle,
    ToggleSwitch,
    ViewToolbox,
    Icon,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    totals: {
      get() {
        return this.viewRaw.totals ? this.viewRaw.totals : [];
      },
      set(newTotals) {
        this.view.attributes.totals = newTotals;
      },
    },
    isLegacyTheme() {
      return this.$store.getters.app.getThemeLevel() < 2;
    },
    showTableDesignOptions: {
      get() {
        return !!this.viewRaw?.table_design_active;
      },
      set(newValue) {
        this.viewRaw.table_design_active = newValue;

        // if turning on custom Grid design settings for the first time,
        // clone settings from the existing global grid design
        if (newValue && !this.viewRaw.table_design) {
          const appDesign = this.$store.getters.app.get('design');
          this.viewRaw.table_design = cloneDeep(appDesign.general.tables);
        }
      },
    },
    customFilters() {
      return (this.viewRaw.preset_filters && this.viewRaw.allow_preset_filters) ? this.viewRaw.preset_filters : [];
    },
    isListResults() {
      return this.viewRaw.results_type === 'list';
    },
    isTableResults() {
      return this.viewRaw.results_type === 'table';
    },
  },
  methods: {
    defaultColumnSummary() {
      return {
        label: 'Total',
        calc: 'sum',
      };
    },

    onAddColumnSummary() {
      this.viewRaw.totals.push(this.defaultColumnSummary());
    },

    defaultPresetFilter() {
      const field = this.object.fields[0];

      return {
        field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
      };
    },

    onAddPresetFilter() {
      log('onAddPresetFilter');
      this.viewRaw.allow_preset_filters = true;
      this.viewRaw.preset_filters.push(this.defaultPresetFilter());
    },

    defaultMenuFilter() {
      const field = this.object.fields[0];

      return {
        text: 'Link',
        criteria: [
          {
            field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
          },
        ],
      };
    },

    onAddMenuFilter() {
      this.viewRaw.menu_filters.push(this.defaultMenuFilter());
    },

    handleResultsTypeChange(event) {
      const { value } = event.target;

      this.convertDetailSchema(value);

      this.viewRaw.results_type = value;
    },

    convertDetailSchema(toValue) {
      if (toValue === 'list') {
        const cols = this.viewRaw.results.columns.map((col) => {
          if (col.field && col.field.key) {
            col.key = col.field.key;
          } else if (col.type === 'link') {
            col.type = 'scene_link';
          }

          col.name = col.header;
          delete col.header;

          return col;
        });

        this.viewRaw.results.columns = this.view.setDetailsItemSchema('left', [
          {
            groups: [
              {
                columns: [
                  cols,
                ],
              },
            ],
          },
        ]);

        if (!this.viewRaw.results.label_format) {
          this.viewRaw.results.label_format = (this.viewRaw.results.columns[0]) ? this.viewRaw.results.columns[0].groups[0].label_format : 'left';
        }

        if (!this.viewRaw.list_layout) {
          this.viewRaw.list_layout = 'one-column';
        }
      } else {
        const nonFieldTypes = [
          'delete',
          'text',
          'link',
          'scene_link',
          'copy',
          'image',
          'divider',
        ];
        const staticTypes = [
          'copy',
          'image',
          'divider',
        ];

        this.viewRaw.results.columns = this.viewRaw.results.columns[0].groups[0].columns[0].map((col) => {
          col.header = col.name;

          if (col.type === 'scene_link') {
            col.type = 'link';
          }

          // convert any field type to field, unfortunately a lot of views are storing the field type here
          // TODO: schema update to convert all of these?
          if (nonFieldTypes.indexOf(col.type) === -1 && col.key) {
            col.type = 'field';
          }

          if (col.key) {
            col.field = {
              key: col.key,
            };
            delete col.key;
          }

          delete col.name;

          return col;
        });

        // table can't display static types, so filter out
        this.viewRaw.results.columns = this.viewRaw.results.columns.filter((col) => (staticTypes.indexOf(col.type) === -1));
      }
    },
  },
};
</script>
