import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import Obj from '@/store/models/Object';
import { objectsByFieldKey } from '@/store/modules/object/objectsByFieldKey';

const storeState = {
  all: [],
  objectsByKey: {},
};

const storeGetters = {
  objects: (state) => state.all,
  objectsByMenuOrder: (state, getters) => {
    const objects = getters.standardObjects;

    if (getters.accountObject) {
      objects.push(getters.accountObject);
    }

    return [
      ...objects,
      ...getters.roleObjects,
      ...getters.ecommerceObjects,
    ];
  },

  /**
   * Gets an object by its key.
   *
   * @param {object} state
   * @returns {function(string): (Obj | undefined)}
   */
  getObject: (state) => (key) => {
    // Objects used for previews don't exist in the store
    if (!key || key === 'object_preview') {
      return undefined;
    }

    const foundObject = state.objectsByKey[key];
    if (!foundObject) {
      console.error(`Object '${key}' was expected but was not found.`);
    }

    return foundObject;
  },
  getObjectFields: (state) => (key) => get(state, `objectsByKey.${key}.fields`, []),
  getObjectByRole: (state) => (role) => state.all.find((obj) => obj.get('profile_key') && obj.get('profile_key') === role),
  getObjectByName: (state) => (name) => state.all.find((obj) => obj.get('name').toLowerCase() === name.toLowerCase()),
  standardObjects: (state) => state.all.filter((obj) => obj.get('type') === 'StandardObject'),
  accountObject: (state) => state.all.find((obj) => obj.get('profile_key') && obj.get('profile_key') === 'all_users'),
  roleObjects: (state) => state.all.filter((obj) => obj.get('profile_key') && obj.get('profile_key') !== 'all_users'),
  allUserObjects: (state) => state.all.filter((obj) => obj.get('profile_key')),
  hasRoleObjects: (state, getters) => (getters.roleObjects.length > 0),
  userObjects: (state) => state.all.filter((obj) => obj.get('profile_key')),
  userObjectsByProfileKey: (state, getters) => getters.userObjects.reduce((final, userObject) => {
    final[userObject.profile_key] = userObject;

    return final;
  }, {}),
  ecommerceObjects: (state) => state.all.filter((obj) => obj.get('ecommerce') || obj.get('ecommerce_payment_methods')),
  objectHasMapField: (state, getters) => (key) => getters.getObject(key).hasAddressField(),
  objectHasDateField: (state, getters) => (key) => getters.getObject(key).hasDateField(),
  getFieldByObject: (state, getters) => (objectKey, fieldKey) => {
    // Thumbs and other keys stored in views may be compound keys like field_1:thumb_2.
    const safeFieldKey = fieldKey.split(':')[0];

    const object = getters.getObject(objectKey);

    return object?.fieldsByKey?.[safeFieldKey];
  },
  getField: (state, getters) => (fieldKey) => {
    if (!fieldKey) {
      return null;
    }

    // thumbs and other keys stored in views may be compound keys like field_1:thumb_2
    fieldKey = fieldKey.split(':')[0];

    return getters.getFieldWithObject(fieldKey).field;
  },
  getFieldWithObject: () => (fieldKey) => {
    const object = objectsByFieldKey.value[fieldKey];

    return {
      object,
      field: get(object, `fieldsByKey.${fieldKey}`),
    };
  },
  getAddressFields: (state) => (objectKey) => {
    const object = state.objectsByKey[objectKey];

    if (!object) {
      return [];
    }

    return object.getAddressFields();
  },

  /**
   * Gets a map of each object based on its field key.
   * This has been disabled temporarily because of a bug in vuex 4. It has been replaced by the
   * objectsByFieldKey computed in '@/store/modules/object/objectByFieldKey'.
   * @see https://github.com/vuejs/vuex/issues/1878
   *
   * @param {object} state
   * @returns {object<string, object>}
   */
  // objectsByFieldKey: (state) => state.all.reduce((final, object) => {
  //   object.fields.forEach((field) => {
  //     final[field.key] = object;
  //   });
  //
  //   return final;
  // }, {}),
};

const storeMutations = {
  loadObjects(state, objects) {
    return objects.forEach((obj) => {
      const initializedObject = new Obj(obj);

      state.all.push(initializedObject);

      state.objectsByKey[initializedObject.key] = initializedObject;
    });
  },
  updateObjectOrder(state, order) {
    state.all = sortBy(state.all, (object) => order.indexOf(object.key));
  },
  removeField(state, { objectKey, fieldKey }) {
    const object = state.objectsByKey[objectKey];

    if (isNil(object)) {
      return;
    }

    object.fields = object.fields.filter(({ key }) => key !== fieldKey);

    // update connections
  },
  updateFieldOrder(state, { objectKey, fields }) {
    const object = state.objectsByKey[objectKey];

    if (isNil(object)) {
      return;
    }

    const fieldKeys = fields.map((field) => field.key);

    object.fields = sortBy(object.fields, (field) => fieldKeys.indexOf(field.key));
  },

  addObject(state, newObject) {
    const existingObject = state.objectsByKey[newObject.key];

    // ensure we're not adding an object with a duplicate key
    if (existingObject) {
      return;
    }

    const initializedObject = new Obj(newObject);

    state.all.push(initializedObject);
    state.objectsByKey[initializedObject.key] = initializedObject;
  },

  removeObject(state, objectKey) {
    const existingObject = state.objectsByKey[objectKey];

    if (!existingObject) {
      return;
    }

    state.all = state.all.filter((object) => object.key !== objectKey);

    delete state.objectsByKey[objectKey];
  },

  updateObject(state, object) {
    const existingObject = state.objectsByKey[object.key];

    if (existingObject) {
      existingObject.setObject(object);
    }
  },

  updateTaskOrder(state, { objectKey, tasks }) {
    const existingObject = state.objectsByKey[objectKey];

    if (!existingObject) {
      return;
    }

    const taskKeys = tasks.map((task) => task.key);

    existingObject.tasks = sortBy(existingObject.tasks, (task) => taskKeys.indexOf(task.key));
  },
};

const storeActions = {

  async updateField({ state, getters }, { field }) {
    const fieldKey = field.key;
    const fieldObject = objectsByFieldKey.value[fieldKey];

    if (!fieldObject) {
      return;
    }

    const existingField = fieldObject.fieldsByKey[fieldKey];

    if (existingField) {
      Object.assign(existingField.attributes, field);
    }
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions,
};
