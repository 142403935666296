<script setup>
import { defineProps, ref } from 'vue';

// Props
const props = defineProps({
  entryPage: {
    type: Object,
    required: true,
  },
  dropdownPages: {
    type: Array,
    required: true,
  },
  isMobileDropdown: {
    type: Boolean,
    default: false,
  },
  onDropdownLinkClick: {
    type: Function,
    default: undefined,
  },
});

// State
const isDropdownVisible = ref(false);

// Refs to hold the references to elements
const dropdownListElement = ref(null);
const dropdownToggleElement = ref(null);

// Methods
const handleMouseOver = () => {
  isDropdownVisible.value = true;
};
const handleMouseLeave = (event) => {
  const isHoverOutsideDropdown = !dropdownListElement.value.contains(event.toElement);
  const isHoverOutsideToggle = !dropdownToggleElement.value.contains(event.toElement);
  isDropdownVisible.value = !(isHoverOutsideDropdown && isHoverOutsideToggle);
};
</script>

<template>
  <template v-if="isMobileDropdown">
    <a
      class="kn-root-dropdown"
      @click.prevent="onDropdownLinkClick"
    >
      <span class="nav-dropdown-link">
        {{ entryPage.name }}
      </span>
      <span class="kn-dropdown-icon fa fa-caret-down" />
    </a>

    <ul class="kn-dropdown-menu-list kn-dropdown-menu-list--mobile">
      <li
        v-for="dropdownPage in dropdownPages"
        :key="dropdownPage.key"
      >
        <a
          :href="dropdownPage.slug"
          :data-kn-slug="dropdownPage.slug"
          @click.prevent="onDropdownLinkClick"
        >
          <span>{{ dropdownPage.name }}</span>
        </a>
      </li>
    </ul>
  </template>

  <template v-else>
    <a
      ref="dropdownToggleElement"
      class="kn-root-dropdown"
      @mouseover="handleMouseOver"
      @mouseleave="handleMouseLeave"
    >
      <span class="nav-dropdown-link">
        {{ entryPage.name }}
      </span>
      <span class="kn-dropdown-icon fa fa-caret-down" />
    </a>

    <ul
      v-if="isDropdownVisible"
      ref="dropdownListElement"
      class="kn-dropdown-menu-list"
      @mouseleave="handleMouseLeave"
    >
      <li
        v-for="dropdownPage in dropdownPages"
        :key="dropdownPage.key"
      >
        <a
          :href="dropdownPage.slug"
          :data-kn-slug="dropdownPage.slug"
        >
          <span>{{ dropdownPage.name }}</span>
        </a>
      </li>
    </ul>
  </template>
</template>

<style lang="scss">
.kn-root-dropdown {
  margin-bottom: 0 !important;

  .kn-dropdown-icon {
    margin-left: 7px;
  }
}
.kn-dropdown-menu {
  position: relative;
}
.kn-dropdown-menu-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 !important;
  min-width: 100%;
  z-index: 2;

  a {
    border-radius: 0 !important;
    margin-bottom: 0 !important;
  }

  &--mobile {
    position: static;
  }
}
</style>
