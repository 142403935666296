<template>
  <div :class="`tag kn-tag-filter kn-filter-${field.key} group-[&:not(.kn-view)]/shared:mr-2 group-[&:not(.kn-view)]/shared:border-0`">
    <a
      v-tippy
      :content="trans('Edit this filter')"
      class="kn-edit-filter link-hover-color group-[&:not(.kn-view)]/shared:text-emphasis group-[&:not(.kn-view)]/shared:text-sm"
      @click="onEdit"
    >
      <span class="field">
        {{ field.name }}
      </span>
      {{ ' ' }}
      <span class="operator">
        {{ trans(filter.operator) }}
      </span>
      {{ ' ' }}
      <span
        v-if="showValue"
        class="value"
        v-html="value"
      />
    </a>
    <a
      v-tippy
      :content="trans('Remove this filter')"
      class="kn-remove-filter"
      @click="onDelete"
    >
      <Icon
        class="text-default hover:text-default"
        type="close"
      />
    </a>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import Api from '@/lib/api-wrapper';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';
import Icon from '@/components/ui/Icon';
import FieldOperators from '@/util/FieldOperators';

export default {
  components: {
    Icon,
  },
  mixins: [
    TranslationUtils,
  ],
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    object: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete', 'edit'],
  data() {
    return {
      daysOfWeek: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      filterConnectionLabel: this.filter.label,
    };
  },
  computed: {
    field() {
      log('FilterLink, field!!!', this.filter);

      return this.$store.getters.getField(this.filter.field);
    },
    showValue() {
      if (FieldOperators.hideValueBasedOnOperator(this.filter.operator)) {
        return false;
      }

      return true;
    },
    value() {
      const { value } = this.filter;

      if (this.field.type === window.Knack.config.USER_ROLES) {
        const objectByRole = this.$store.getters.getObjectByRole(value);

        if (isEmpty(objectByRole)) {
          return value;
        }

        return objectByRole.name;
      }

      if (this.field.type === window.Knack.config.BOOLEAN) {
        return this.field.getBooleanLabel(value);
      }

      if (this.field.type === window.Knack.config.IMAGE || this.field.type === window.Knack.config.FILE) {
        switch (this.filter.operator) {
          case 'size is less than':
          case 'size is greater than':

            return `${value} ${this.filter.unit}`;

          default:

            return value;
        }
      }

      if (this.field.type === window.Knack.config.CONNECTION) {
        return this.filterConnectionLabel || 'loading...';
      }

      if (this.field.type === window.Knack.config.ADDRESS) {
        if (this.filter.operator === 'near') {
          return `${value} (${this.filter.range})`;
        }
      }

      if (this.field.type === window.Knack.config.DATE_TIME) {
        switch (this.filter.operator) {
          case 'is during the current':

            return this.filter.type;

          case 'is during the previous':
          case 'is during the last':
          case 'is during the next':
          case 'is before the previous':
          case 'is after the next':

            return `${this.filter.range} ${this.filter.type}`;

          case 'is between days of the week':

            return `: ${this.daysOfWeek[Number(this.filter.from_date)]} to ${this.daysOfWeek[Number(this.filter.to_date)]}`;

          case 'is a day of the week':

            return `: ${this.daysOfWeek[Number(this.filter.value)]}`;

          case 'is between dates':

            return `: ${value.date} to ${value.to.date}`;

          default:
            // We either want to return date, time, or both depending on what we have
            return `${value?.date || ''} ${value?.time || ''}`.trim();
        }
      }

      return value;
    },
  },
  watch: {
    filter(newValue) {
      this.getFilterConnectionLabel(newValue.value);
    },
  },
  created() {
    if (!this.filterConnectionLabel) {
      this.getFilterConnectionLabel(this.filter.value);
    }
  },
  methods: {
    onEdit() {
      log('onEdit!');

      this.$emit('edit', this.index);
    },
    onDelete() {
      this.$emit('delete', this.index);
    },
    async getFilterConnectionLabel(connectionIds) {
      if (this.field.type === window.Knack.config.CONNECTION) {
        try {
          const objectKey = this.field.relationship.object;
          const response = await Api.getRecordIdentifiers(objectKey, connectionIds);
          this.filterConnectionLabel = response.records[0].identifier;
        } catch (error) {
          log(error);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.kn-tag-filter{
  background: $primary50;
  border: 1px solid $primary50;
  display: inline-flex;
  align-items: center;
  @include font-small-ui;
  margin-right: 4px;
  border-radius: 100em;
  padding: .125rem .5rem;
  text-decoration: none;

  .field {
    font-weight: bold;
  }

  svg {
    width: 14px;
    height: 14px;
    opacity: .5;
    display: block;
    margin: auto;
    margin-left: 4px;
  }

  a:hover svg {
    opacity: 1;
  }
}
</style>
