<template>
  <view-toolbox
    back-title="Form Menu"
    title="Form Action"
  >
    <p v-html="source" />
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'FormSource',
  components: {
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    source() {
      let source = `This form ${this.action} a ${(this.viewRaw.action === 'create') ? 'new ' : ''}<b>${this.object.get('inflections').singular}</b> record`;

      // two connections
      if (this.viewRaw.source.connection_key) {
        if (this.viewRaw.source.parent_source) {
          const grandparentObject = this.$store.getters.getObject(this.viewRaw.source.parent_source.object);

          source += ` connected to the ${grandparentObject.inflections.singular} records`;
        }

        const connectionField = this.$store.getters.getField(this.viewRaw.source.connection_key);
        const connectedObjectKey = (connectionField.objectKey === this.viewRaw.source.object) ? connectionField.get('relationship').object : connectionField.objectKey;

        const connectedObject = this.$store.getters.getObject(connectedObjectKey);
        const connector = (this.viewRaw.source.authenticated_user) ? 'the logged-in' : 'this page\'s';

        source += ` connected to ${connector} <b>${connectedObject.inflections.singular}</b>`;
      }

      return `${source}.`;
    },
    action() {
      return (this.viewRaw.action === 'update') ? 'updates' : 'inserts';
    },
  },
};
</script>
