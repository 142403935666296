<template>
  <div
    v-if="richTextContent"
    class="content"
  >
    <iframe
      ref="iframe"
      :srcdoc="htmlSrc"
      @load="onIframeLoad"
    />
  </div>

  <p
    v-else
    class="content content--empty"
  >
    This <strong>Rich Text</strong> view is empty. Edit this view to add content.
  </p>
</template>

<script>
export default {
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  emits: ['viewHeightChange'],
  computed: {
    richTextContent() {
      return this.view.get('content');
    },
    htmlSrc() {
      return `
        <html>
          <head>
            ${[...document.querySelectorAll('[rel="stylesheet"]')].map((elem) => elem.outerHTML).join('')}
            ${[...document.querySelectorAll('style[id="kn-dynamic-styles"]')].map((elem) => elem.outerHTML).join('')}
          </head>
          <body>
            <style>
              /* Bring over some of the app's styles that are not part of the kn-dynamic-styles style tag */
              body {
                font-size: 16px;
                font-weight: normal;
                line-height: 1.4;
                margin: 0;
                color: #222B33;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
              }
              th, td {
                border: 1px solid #e6e9ec;
                padding: 5px;
                text-align: left;
              }
            </style>
            <div id="kn-builder">
              <div class="kn-content">
                <div class="kn-view">
                  ${this.richTextContent}
                </div>
              </div>
            </div>
          </body>
        </html>
      `;
    },
  },
  methods: {
    onIframeLoad() {
      const { iframe } = this.$refs;
      const iframeBodyHeight = iframe?.contentWindow?.document?.body?.scrollHeight;

      if (iframeBodyHeight) {
        // After the iframe loads, manually set its height based on its content (plus some extra pixels to prevent
        // potential scroll bars)
        iframe.style.height = `${iframeBodyHeight + 50}px`;

        // Emit event so ViewWrapper can re-determine if the view can be expandable or not
        this.$emit('viewHeightChange');
      }
    },
  },
};
</script>

<style lang="scss">
.kn-rich_text {
  width: 100%;

  table {
    width: 100%;

    td, th {
      text-align: left;
      vertical-align: top;
    }
  }

  .content {
    padding: 0 7px;

    &--empty {
      color: #222B33;
      font-size: 16px;
      min-height: 150px;
      padding-top: 16px;
    }

    iframe {
      width: 100%;
      min-height: 150px;
    }
  }
}
</style>
