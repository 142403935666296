<template>
  <div class="expandableList">
    <slot
      name="visible"
      :items="visibleItems"
    />
    <template v-if="shouldTruncateList">
      <div
        ref="expandable"
        class="expandableList_items transition"
        @toggle="localExpanded = $event.detail.open"
      >
        <slot
          name="expandable"
          :items="expandableItems"
        />
      </div>
      <button
        v-if="showExpandTrigger"
        ref="trigger"
        type="button"
        class="expandableList_trigger text-default"
      >
        {{ trigger }}
        <Icon
          class="expandableList_arrow text-default w-4 h-4"
          :class="{ 'is-expanded': localExpanded }"
          type="chevron-double"
        />
        <span />
      </button>
    </template>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';
import UIUtil from '@/util/UI';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemsToShowWhenCollapsed: {
      type: Number,
      default: 5,
    },
    thresholdToShowCollapsed: {
      type: Number,
      default: 7,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    showExpandTrigger: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:expanded'],
  data() {
    return {
      slideToggle: null,
    };
  },
  computed: {
    localExpanded: {
      get() {
        return this.expanded;
      },
      set(newValue) {
        return this.$emit('update:expanded', newValue);
      },
    },
    trigger() {
      return this.expanded ? 'View less' : `View ${this.expandableItems.length} more`;
    },
    shouldTruncateList() {
      return this.items.length >= this.thresholdToShowCollapsed && this.showExpandTrigger;
    },
    visibleItems() {
      if (this.shouldTruncateList) {
        return this.items.slice(0, this.itemsToShowWhenCollapsed);
      }

      return this.items;
    },
    expandableItems() {
      if (this.shouldTruncateList) {
        return this.items.slice(this.itemsToShowWhenCollapsed);
      }

      return [];
    },
  },
  watch: {
    expanded(newValue, oldValue) {
      // Watch prop from parent and if value is different and list is truncated
      // trigger SlideToggle to reflect that state
      if (!newValue === oldValue && this.shouldTruncateList) {
        if (isNil(this.slideToggle)) {
          this.initSlideToggle(newValue);
        } else {
          this.slideToggle.toggle(newValue);
        }
      }
    },
  },
  mounted() {
    if (this.shouldTruncateList) {
      this.initSlideToggle(this.localExpanded);
    }
  },
  methods: {
    initSlideToggle(openState) {
      this.$nextTick(() => {
        /* eslint-disable no-unused-vars */
        this.slideToggle = new UIUtil.SlideToggle(this.$refs.expandable, {
          trigger: this.$refs.trigger,
          isOpen: openState,
        });
      });
    },
  },
};
</script>
