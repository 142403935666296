<template>
  <HelpForm
    class="help-form-report-a-bug"
    store-namespace="api/help/reportBug"
    @submit="submitZendeskBug"
    @clear="clearForm"
    @dismissErrors="dismissErrors"
    @dismissSuccess="dismissSuccess"
  >
    <div class="margin-bottom-md mb-4">
      <label class="block text-default font-medium mb-2 leading-4 tracking-[0.14px]">
        Are you seeing this bug in your Builder or Live App?
      </label>
      <div class="input-labels tw-input-labels">
        <label>
          <input
            v-model="bugLocation"
            type="radio"
            value="Builder"
          >
          Builder
        </label>
        <label>
          <input
            v-model="bugLocation"
            type="radio"
            value="Interface"
          >
          Live App
        </label>
      </div>

      <div
        v-if="isInvalid && validation.errorsByKey.bugLocation"
        class="validation-error bg-destructive text-destructive-emphasis rounded-lg p-4"
      >
        <div class="mb-1">
          <Icon
            class="block text-destructive-emphasis h-4 w-4"
            type="exclamation-triangle"
          />
        </div>
        <p class="mb-0">
          {{ validation.errorsByKey.bugLocation }}
        </p>
      </div>
    </div>
    <div class="margin-bottom-md mb-4">
      <label class="text-default font-medium mb-2 leading-4 tracking-[0.14px]">What is the URL in your browser when you see this bug?</label>
      <input
        v-model="bugUrl"
        type="text"
        placeholder="URL"
      >

      <div
        v-if="isInvalid && validation.errorsByKey.bugUrl"
        class="validation-error bg-destructive text-destructive-emphasis rounded-lg p-4"
      >
        <div class="mb-1">
          <Icon
            class="block text-destructive-emphasis h-4 w-4"
            type="exclamation-triangle"
          />
        </div>
        <p class="mb-0">
          {{ validation.errorsByKey.bugUrl }}
        </p>
      </div>
    </div>
    <div class="margin-bottom-md mb-4">
      <label class="text-default font-medium mb-2 leading-4 tracking-[0.14px]">Describe the bug with as much detail as possible.</label>

      <p class="help-description margin-0 text-[#7B7079] leading-4 tracking-[0.14px] mb-2">
        Our goal is to see the bug in action, so please describe any steps necessary to view the issue. Do not include sensitive data such as login information via the form.
      </p>

      <textarea v-model="bugDescription" />

      <div
        v-if="isInvalid && validation.errorsByKey.bugDescription"
        class="validation-error bg-destructive text-destructive-emphasis rounded-lg p-4"
      >
        <div class="mb-1">
          <Icon
            class="block text-destructive-emphasis h-4 w-4"
            type="exclamation-triangle"
          />
        </div>
        <p class="mb-0">
          {{ validation.errorsByKey.bugDescription }}
        </p>
      </div>
    </div>
  </HelpForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HelpForm from '@/components/builder/help/forms/HelpForm';
import Icon from '@/components/ui/Icon.vue';
import { mapApiFormGetters } from '@/lib/vuex-helper';

export default {
  name: 'HelpFormReportABug',
  components: {
    HelpForm,
    Icon,
  },
  emits: ['navigateToHome'],
  computed: {
    ...mapGetters('api/help/reportBug', [
      'validation',
      'isInvalid',
      'isSuccess',
    ]),
    ...mapApiFormGetters('api/help/reportBug', [
      'bugLocation',
      'bugUrl',
      'bugDescription',
    ]),
  },
  methods: {
    ...mapActions('api/help/reportBug', {
      reportBugUpdateForm: 'updateForm',
      reportBugReset: 'reset',
      reportBugResetForm: 'resetForm',
      reportBugSubmitForm: 'submitForm',
    }),
    submitZendeskBug() {
      // Submit form request
      this.reportBugSubmitForm().then(() => {
        if (this.isSuccess) {
          this.clearForm();
        }
      });
    },
    clearForm() {
      this.reportBugResetForm();
    },
    dismissErrors() {
      this.reportBugReset();
    },
    dismissSuccess() {
      this.reportBugResetForm();
      this.reportBugReset();

      this.$emit('navigateToHome');
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  display: block;
}

.validation-error {
  color: darkred;
}
</style>
