<template>
  <ViewToolbox
    back-title="page"
    title="Form Menu"
  >
    <div>
      <div
        class="margin-bottom rounded-lg bg-subtle p-2"
        style="border-radius: .34em; padding: .55em .75em; background-color: rgba(56, 57, 60, 0.08);"
      >
        <div
          class="level-left"
          style="align-items: flex-start;"
        >
          <Icon
            type="records"
            class="text-subtle mr-2 h-4 w-4"
            style="flex-shrink: 0; width: 15px; height: 15px; margin-right: 5px; margin-top: 2px;"
          />
          <div
            style="line-height: 1.35em; font-size: .96em;"
            v-html="source"
          />
        </div>
      </div>
      <TocLinks
        :items="items"
      />
    </div>
  </ViewToolbox>
</template>

<script>
import isNil from 'lodash/isNil';
import Icon from '@/components/ui/Icon';
import TocLinks from '@/components/views/TocLinks';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'FormTOC',
  components: {
    Icon,
    TocLinks,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  data() {
    return {
      items: [
        {
          title: 'Settings',
          copy: 'customization & design options ',
          link: 'settings',
        },
        {
          title: 'Add Inputs',
          copy: 'using fields',
          link: 'inputs',
        },
        {
          title: 'Actions & Rules',
          copy: 'workflow and display rules',
          link: 'rules',
        },
        {
          title: 'Emails',
          copy: 'send emails on form submissions',
          link: 'emails',
        },
      ],
    };
  },
  computed: {
    source() {
      if (!this.hasActiveView) {
        return '';
      }

      // a parent means this form is adding a new record to a parent form's connection input, or a registration form
      if (this.viewRaw.parent) {
        const parentView = this.page.getView(this.viewRaw.parent);

        if (parentView.type === 'login') {
          return `This form registers a new <b>${this.view.getSourceObject().inflections.singular}</b> from the ${this.page.name}.`;
        }

        const parentInput = parentView.getInputs().find((input) => (input.view && input.view === this.viewRaw.key));

        return `This form adds a new option to the <b>${parentInput.label || parentInput.name}</b> input in the <b>${parentView.name}</b> form`;
      }

      let source = `This form ${this.action} a ${(this.viewRaw.action === 'create') ? 'new ' : ''}<b>${this.object.get('inflections').singular}</b> record`;

      // two connections
      if (this.viewRaw.source.connection_key) {
        if (this.viewRaw.source.parent_source) {
          const grandparentObject = this.$store.getters.getObject(this.viewRaw.source.parent_source.object);

          if (!isNil(grandparentObject)) {
            source += ` connected to the ${grandparentObject.inflections.singular} records`;
          }
        }

        const connectionField = this.$store.getters.getField(this.viewRaw.source.connection_key);
        const connectedObjectKey = (connectionField.objectKey === this.viewRaw.source.object) ? connectionField.get('relationship').object : connectionField.objectKey;

        const connectedObject = this.$store.getters.getObject(connectedObjectKey);
        const connector = (this.viewRaw.source.authenticated_user) ? 'the logged-in' : 'this page\'s';

        source += ` connected to ${connector} <b>${connectedObject.inflections.singular}</b>`;
      }

      return `${source}.`;
    },
    action() {
      return (this.viewRaw.action === 'update') ? 'updates' : 'inserts';
    },
  },
};
</script>
