<template>
  <div class="kn-select group-[&:not(.kn-view)]/shared:mr-0">
    <select
      name="limit"
      :value="recordsPerPage"
      class="group-[&:not(.kn-view)]/shared:text-base group-[&:not(.kn-view)]/shared:py-2 group-[&:not(.kn-view)]/shared:pl-3 group-[&:not(.kn-view)]/shared:leading-5"
      @change="$emit('changeRecordsPerPage', $event.target.value)"
    >
      <option :value="10">
        10 {{ perPageTranslation }}
      </option>
      <option :value="25">
        25 {{ perPageTranslation }}
      </option>
      <option :value="50">
        50 {{ perPageTranslation }}
      </option>
      <option :value="100">
        100 {{ perPageTranslation }}
      </option>
      <option :value="500">
        500 {{ perPageTranslation }}
      </option>
      <option :value="1000">
        1000 {{ perPageTranslation }}
      </option>
    </select>
  </div>
</template>

<script>
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';

export default {
  mixins: [
    TranslationUtils,
  ],
  props: [
    'recordsPerPage',
  ],
  emits: ['changeRecordsPerPage'],
  computed: {
    perPageTranslation() {
      return this.trans('per_page');
    },
  },
};
</script>
<style scoped lang="scss">
.kn-select {
  margin-right:10px;
}
</style>
