<template>
  <BodyWrapper>
    <div
      id="settings-embeds-wrapper"
      class="body-wrapper m-0 mb-10 px-6 py-2 max-w-xl"
    >
      <RouterView name="modal" />

      <RecyclableSortableItems
        id="embeds-list"
        :items="app.distributions"
        :item-size="listItemHeight"
        @sort="onSort"
      >
        <template #default="{item}">
          <EmbedListItem
            :id="`embed-list-item-${item.id}`"
            :key="item.key"
            :embed="item"
            :pages="pages"
            :style="embedTileStyle"
            :show-link-label="showTileLinkLabel"
          />
        </template>
      </RecyclableSortableItems>
    </div>
  </BodyWrapper>
</template>

<script>
import sortBy from 'lodash/sortBy';
import { mapGetters } from 'vuex';
import EmbedListItem from '@/components/settings/embeds/EmbedListItem';
import BodyWrapper from '@/components/layout/BodyWrapper';
import RecyclableSortableItems from '@/components/ui/lists/RecyclableSortableItems';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  components: {
    EmbedListItem,
    BodyWrapper,
    RecyclableSortableItems,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      embeds: [],
      tileMaxWidth: 480,
      listItemHeight: 56,
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    ...mapGetters('page', [
      'startPages',
    ]),
    ...mapGetters('ui', [
      'rightBodyWidth',
    ]),
    pages() {
      return sortBy(this.startPages, 'name');
    },
    embedTileStyle() {
      return {
        'max-width': `${this.tileMaxWidth}px`,
      };
    },
    showTileLinkLabel() {
      return this.rightBodyWidth > this.tileMaxWidth + 20;
    },
  },
  methods: {
    onSort(event, embeds) {
      this.commitRequest({
        request: () => window.Knack.Api.updateDistributionsSort(embeds.map((embed) => embed.key)),
        globalLoading: false,
      });
    },
    renderAddEmbedModal() {
      this.$router.push('/settings/embeds/add');
    },
  },
};
</script>

<style lang="scss">
#settings-embeds-wrapper {
  #embeds-list {
    flex-grow: 1;
    overflow: auto;
  }

  .instructions {
    color: #999;
    font-size: .95em;
    font-style: italic;
    line-height: 1.8em !important;
    margin: 0;
    margin-bottom: 1em;
    padding: 0;
  }

  form .is-horizontal select {
    width: 100%;
  }
}
</style>
