<template>
  <ViewToolbox
    back-title="Build Menu"
    title="Edit/Add a Link"
  >
    <p class="mb-0">
      Click on a link to the right to edit or add a new link below.
    </p>
    <Modal
      v-if="isAddingPage"
      title="Link to a New Page"
      size="medium"
      @close="isAddingPage = false"
    >
      <PageAddWizard
        :parent-page="page"
        @addPage="onAddPage"
      />
    </Modal>

    <Toggle
      id="menu-add-links-toggle"
      :is-open="true"
    >
      <template #title>
        <span class="text-base font-semibold">Add a New Link</span>
      </template>

      <template #content>
        <div
          id="menu-links-toolbox"
          class="border border-x-0 border-t-0 border-solid border-subtle mb-1"
        >
          <p class="text-xs">
            Add a new link by clicking an option below.
          </p>
          <div id="menu-link-options">
            <div>
              <a
                data-cy="link-new"
                class="p-2 bg-white rounded-lg border border-solid border-default group"
                @click="onClickAddNewPage"
              >
                <span class="text-base group-hover:text-emphasis"><Icon
                  type="add-box"
                  class="small text-subtle group-hover:text-emphasis"
                /> Link to a New Page</span>
              </a>
            </div>
            <div>
              <a
                data-cy="link-existing"
                class="p-2 bg-white rounded-lg border border-solid border-default group"
                @click="onAddExistingPage"
              >
                <span class="text-base group-hover:text-emphasis"><Icon
                  type="link-to-page"
                  class="small text-subtle group-hover:text-emphasis"
                /> Link to an Existing Page</span>
              </a>
            </div>
            <div>
              <a
                data-cy="link-url"
                class="p-2 bg-white rounded-lg border border-solid border-default group mb-0"
                @click="onAddURL"
              >
                <span class="text-base group-hover:text-emphasis"><Icon
                  type="link"
                  class="small text-subtle group-hover:text-emphasis"
                /> Link to a URL</span>
              </a>
            </div>
          </div>
        </div>
      </template>
    </Toggle>

    <Toggle
      id="menu-properties-toggle"
      :is-open="true"
    >
      <template #title>
        <span class="text-default font-semibold text-base">
          Menu Settings
        </span>
      </template>

      <template #content>
        <div class="grid-verticalGaps gap-4 pb-4 border border-x-0 border-t-0 border-solid border-subtle mb-1">
          <div>
            <label
              for="title"
              class="tw-toolbox-label"
            >
              Title
            </label>
            <input
              v-model="viewRaw.title"
              type="text"
              name="title"
              data-cy="menu-title"
            >
          </div>
          <div>
            <label
              for="title"
              class="tw-toolbox-label"
            >
              Description
            </label>
            <textarea
              v-model="viewRaw.description"
              name="description"
              data-cy="menu-description"
            />
          </div>
          <div>
            <label class="tw-toolbox-label">Tabbed Menu</label>
            <div class="input-labels tw-input-labels">
              <label class="text-emphasis text-base font-normal leading-5"><input
                v-model="viewRaw.format"
                type="checkbox"
                true-value="tabs"
              > Simulate tabs by showing the menu on any linked child pages</label>
            </div>
          </div>
          <div v-if="viewRaw.format === 'tabs'">
            <label class="tw-toolbox-label">Auto Link</label>
            <div class="input-labels tw-input-labels">
              <label class="text-emphasis text-base font-normal leading-5"><input
                v-model="viewRaw.auto_link"
                type="checkbox"
              > Navigate to the first link automatically</label>
            </div>
          </div>
        </div>
      </template>
    </Toggle>

    <!-- Menu Design -->
    <Toggle
      v-if="!isLegacyTheme"
      :is-open="true"
    >
      <template #title>
        <span class="text-default font-semibold text-base">Menu Design</span>
      </template>
      <template #content>
        <ToggleSwitch
          v-model:checked="showMenuDesignOptions"
          label="Use Custom Design Settings"
        >
          <template #offDescription>
            <span class="text-sm font-normal text-default">
              This menu uses the
              <router-link
                class="text-default underline"
                to="/settings/design/menus"
              >
                global menu design settings.
              </router-link>
            </span>
          </template>

          <template #description>
            <span class="text-sm font-normal text-default">
              This menu overrides the
              <router-link
                class="text-default underline"
                to="/settings/design/menus"
              >
                global menu design settings
              </router-link>
              with the following settings:
            </span>
          </template>
        </ToggleSwitch>

        <MenusOptions
          v-if="showMenuDesignOptions"
          :custom-design-object="menuOptionsDesignObject"
          :menu-format="menuFormat"
        />
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Modal from '@/components/ui/Modal';
import MenusOptions from '@/components/settings/design/options/MenusOptions';
import PageAddWizard from '@/components/pages/PageAddWizard';
import Toggle from '@/components/ui/Toggle';
import ToggleSwitch from '@/components/ui/ToggleSwitch';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import useMenuDesignOverrides from '@/composables/ui/useMenuDesignOverrides';
import { NEW_PAGE_KEY } from '@/lib/page/page-constants';

export default {
  name: 'FormInputs',
  components: {
    Icon,
    Modal,
    MenusOptions,
    PageAddWizard,
    Toggle,
    ToggleSwitch,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  setup() {
    const { isMenuDesignActive, setIsMenuDesignActive } = useMenuDesignOverrides();
    return { isMenuDesignActive, setIsMenuDesignActive };
  },
  data() {
    return {
      isAddingPage: false,
    };
  },
  computed: {
    isLegacyTheme() {
      return this.$store.getters.app.getThemeLevel() < 2;
    },
    menuFormat() {
      if (this.viewRaw?.format === 'tabs') {
        return 'tabs';
      }
      return 'buttons';
    },
    menuOptionsDesignObject() {
      if (this.viewRaw.menu_links_design) {
        return this.viewRaw.menu_links_design[this.menuFormat];
      }
      return undefined;
    },
    showMenuDesignOptions: {
      get() {
        return this.isMenuDesignActive;
      },
      set(newValue) {
        this.setIsMenuDesignActive(newValue);
      },
    },
  },
  methods: {
    onClickAddNewPage() {
      this.isAddingPage = true;
    },
    async onAddPage(page) {
      const pageData = { ...page.raw };

      log('onAddPage', pageData);

      const newPage = {
        name: pageData.name,
        parent: pageData.parent,
      };

      if (pageData.object) {
        newPage.object = pageData.object;
      }

      if (pageData.views) {
        newPage.views = pageData.views;
      }

      this.isAddingPage = false;

      this.viewRaw.links.push({
        name: pageData.name,
        type: 'scene',
        scene: newPage,
        link_design: undefined,
        link_design_active: false,
      });

      // Now delete the newPage floating around in the page cache.
      await this.$store.dispatch('removePage', NEW_PAGE_KEY);

      this.$router.push(`${this.$route.path}/links/${String(this.viewRaw.links.length - 1)}`);
    },
    onAddExistingPage() {
      this.viewRaw.links.push({
        name: 'New Link',
        type: 'scene',
        scene: null,
        icon: {
          icon: '', align: 'left',
        },
        link_design: undefined,
        link_design_active: false,
      });

      this.$router.push(`${this.$route.path}/links/${String(this.viewRaw.links.length - 1)}`);
    },
    onAddURL() {
      this.viewRaw.links.push({
        name: 'New Link',
        type: 'url',
        icon: {
          icon: '', align: 'left',
        },
        link_design: undefined,
        link_design_active: false,
      });

      this.$router.push(`${this.$route.path}/links/${String(this.viewRaw.links.length - 1)}`);
    },
  },
};
</script>

<style lang="scss">

#menu-links-options {
  transition: all 300ms ease-in;
  overflow: hidden;
}

#menu-links-toolbox {

  a {
    background-color: #e2e4ea;
    padding: 7px 12px;
    margin-bottom: 7px;
    border-radius: 4px;
    display: block;
    color: #5d5d63;
    font-size: .96em;

    span {
      display: inline-block;
      margin-top: 1px;
      margin-left: 2px;
    }

    svg {

      position: relative;
      top: 3px;
      margin-right: 4px;
    }
  }
  a:hover {
    color: #219667;
  }

  .is-dragging a {
    opacity: .5;
  }
  .expanded-items {
    overflow: hidden;
  }
}
</style>
