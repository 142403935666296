<template>
  <div
    class="empty-view-message"
    @dragover="onDragOver"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <template v-if="isDraggingField">
      <h1>Drop it here!</h1>
      <Icon type="add-circle-outline" />
    </template>
    <template v-else>
      <h1 class="transition">
        This {{ buildable }} is empty
      </h1>
      <template v-if="!isDraggingField && isAddingField">
        <h2>{{ capitalize(itemsAction) }} {{ itemsAlias }} to build your {{ buildable }}</h2>
        <Icon type="empty-point-left" />
      </template>
      <template v-else>
        <router-link
          class="button edit-fill"
          :to="`${buildPath}`"
        >
          Add {{ capitalize(itemsAlias) }}
        </router-link>
      </template>
    </template>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    buildable: { // the thing these fields are being added to
      type: String,
      default: 'form',
    },
    buildPath: {
      type: String,
      default: '',
    },
    isDraggingField: {
      type: Boolean,
      default: false,
    },
    itemsAlias: {
      type: String,
      default: 'fields',
    },
    itemsAction: {
      type: String,
      default: 'drag',
    },
  },
  emits: ['dragenter', 'dragleave', 'dragover', 'drop'],
  computed: {
    isAddingField() {
      return this.$route.path === this.buildPath;
    },
  },
  methods: {
    capitalize,
    onDragOver(event) {
      this.$emit('dragover', event);
    },
    onDragEnter(event) {
      this.$emit('dragenter', event);
    },
    onDragLeave(event) {
      this.$emit('dragleave', event);
    },
    onDrop(event) {
      this.$emit('drop', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.view.is-active .empty-view-message {
  background: #f3f4f7;
  border: 3px dashed #f3f4f7;

  a {
    visibility: visible;
  }

  h1 {
    margin-top: 0;
    opacity: 1;
  }
}

.empty-view-message {
  background: #f6f6f7;
  position: relative;
  width: 100%;
  text-align: center;
  border: 3px solid rbga(0, 0, 0, 0);
  display: block;
  visibility: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-height: 190px;
  padding: 0 1em;

  a {
    visibility: hidden;
  }

  h1 {
    margin-top: 1em;
    margin-bottom: .5em;
    opacity: .4;
    font-size: 1.75em !important;
  }

  h2 {
    font-weight: 400;
    border: 0;
    margin: 0 !important;
    margin-bottom: .25em !important;
    padding: 0;
    font-size: 1.1em !important;
    color: inherit;
  }

  &.is-dragging-over {
    border: 3px dashed $active;

    h1 {
      pointer-events: none;
    }
  }

  .icon-plus-button {
    color: $active;
  }

  .pointer {
    font-size: 4em;
    line-height: 1em;
  }

  &.over {
    border: 3px dashed $green-fill !important;
    background: #f7fbfc !important;
  }
}

.kn-menu .empty-view-message {
  min-height: 150px;
}
</style>
