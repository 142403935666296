<template>
  <section class="drawer">
    <HelpPage :transition="slideTransition">
      <div
        v-if="currentPath.length > 0"
        :key="`${currentPath[currentPath.length - 1].slug}-page`"
      >
        <HelpHeader
          :breadcrumbs="breadcrumbs"
          :title="currentPageConfig.title"
          :caption="currentPageConfig.caption"
          @selectPreviousPage="selectPreviousPage"
          @close="closeHelpDrawer"
        />
        <HelpSearchResult
          v-if="showSearchBody"
          class="search_body"
        />
        <ul
          v-else
          class="drawer_body"
        >
          <li
            v-for="(item, key) in currentPageConfig.children"
            :key="key"
          >
            <Component
              :is="getItemComponent(item)"
              :config="item"
              @changePage="onItemClick(key)"
              @navigateToHome="navigateToHome()"
            />
          </li>
        </ul>
      </div>
    </HelpPage>
  </section>
</template>

<script>
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import capitalize from 'lodash/capitalize';
import { mapActions, mapGetters } from 'vuex';
import HelpDrawerConfig from '@/data/HelpDrawerConfig';
import HelpPage from '@/components/builder/help/HelpPage';
import HelpHeader from '@/components/builder/help/HelpHeader';
import HelpSearchResult from '@/components/builder/help/HelpSearchResult';
import HelpCategory from '@/components/builder/help/HelpCategory';
import HelpLink from '@/components/builder/help/HelpLink';
import HelpAccordion from '@/components/builder/help/HelpAccordion';
import HelpFormGetHelp from '@/components/builder/help/forms/HelpFormGetHelp';
import HelpFormReportABug from '@/components/builder/help/forms/HelpFormReportABug';
import HelpFormReportEmergency from '@/components/builder/help/forms/HelpFormReportEmergency';
import HelpFormShareFeedback from '@/components/builder/help/forms/HelpFormShareFeedback';
import { logEvent } from '@/lib/segment-helper';

export default {
  name: 'Help',
  components: {
    HelpPage,
    HelpHeader,
    HelpSearchResult,
    HelpCategory,
    HelpLink,
    HelpAccordion,
    HelpFormGetHelp,
    HelpFormReportABug,
    HelpFormReportEmergency,
    HelpFormShareFeedback,
  },
  data() {
    return {
      currentPath: [],
      slideTransition: 'slide-left',
    };
  },
  computed: {
    ...mapGetters('api/help/search', {
      searchIsReady: 'isReady',
    }),
    showSearchBody() {
      return (!this.searchIsReady);
    },
    currentPageConfig() {
      // Dynamically grabs the current page config based on the current path
      // updates page when route changes
      return get(HelpDrawerConfig, this.getConfigQueryPath(this.currentPath));
    },
    breadcrumbs() {
      return this.currentPath.reduce((breadcrumbs, path, index) => {
        // breadcrumbs shouldn't reflect the current page, only previous ones
        // don't push if we're at the last index
        if (index === this.currentPath.length - 1) {
          return breadcrumbs;
        }

        // If there is no title fall back to capitalized slug (useful for home)
        const crumb = isNil(path.title) ? capitalize(path.slug) : path.title;

        breadcrumbs.push(crumb);

        return breadcrumbs;
      }, []);
    },
  },
  mounted() {
    // if we have a help query and it has a path value parse it
    // otherwise set the home page as the active page
    if (hasIn(this.$route.query, 'help') && !isEmpty(this.$route.query.help)) {
      this.readUrlQuery(this.$route.query.help);
    } else {
      this.setHomePage();
    }
  },
  methods: {
    ...mapActions('api/help/search', {
      searchReset: 'reset',
      searchResetForm: 'resetForm',
    }),
    getItemComponent(config) {
      switch (config.type) {
        case 'category':
          return HelpCategory;

        case 'component':
          return config.component;

        case 'accordion':
          return HelpAccordion;

        default:
          return HelpLink;
      }
    },
    setHomePage() {
      // Choose top level page as 'home' page in config
      const pageKey = Object.keys(HelpDrawerConfig)[0];
      const pageTitle = HelpDrawerConfig[pageKey].title;

      this.currentPath.push(this.getPathObject(pageKey, pageTitle));

      this.updateUrlQuery(this.currentPath);
    },
    selectPreviousPage(index) {
      // trim currentPath to selected index
      this.slideTransition = 'slide-right';
      this.currentPath = this.currentPath.slice(0, index + 1);

      this.updateUrlQuery(this.currentPath);
    },
    navigateToHome() {
      this.currentPath = this.currentPath.slice(0, 1);

      this.updateUrlQuery(this.currentPath);
    },
    onItemClick(itemKey) {
      this.slideTransition = 'slide-left';
      const { title } = this.currentPageConfig.children[itemKey];

      this.currentPath.push(this.getPathObject(itemKey, title));

      this.updateUrlQuery(this.currentPath);

      logEvent('interacted_with_help_drawer', {
        item: itemKey,
      });
    },
    getPathObject(key, title) {
      return {
        title,
        slug: key,
      };
    },
    updateUrlQuery(currentPath) {
      const query = {
        ...this.$route.query,
        help: currentPath.map((path) => path.slug).join('/'),
      };

      this.$router.replace({
        path: this.$route.path,
        query,
      });
    },
    readUrlQuery(query) {
      const pathParts = query.split('/').reduce((accumulator, pathPart, pathIndex, allParts) => {
        const getPath = allParts.slice(0, pathIndex + 1).join('.children.');
        const { title } = get(HelpDrawerConfig, getPath);

        accumulator.push(this.getPathObject(pathPart, title));

        return accumulator;
      }, []);

      this.currentPath = pathParts;
    },
    getConfigQueryPath(currentPath) {
      return currentPath.map((path) => path.slug).join('.children.');
    },
    closeHelpDrawer() {
      const router = this.$router;

      this.searchReset();
      this.searchResetForm();

      // Closes the help drawer by removing the help query param from the url.
      router.replace({
        ...router.currentRoute,
        query: omit(router.currentRoute.query, ['help']),
      });
    },
  },
};
</script>
