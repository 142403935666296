import Builder from '@/components/Builder';
import Schema from '@/components/schema/Schema';
import ObjectList from '@/components/schema/ObjectList';
import ObjectListIntro from '@/components/schema/ObjectListIntro';
import ObjectListFields from '@/components/schema/ObjectListFields';
import ObjectsNav from '@/components/schema/ObjectsNav';
import { getSharedObjectsPaths } from '@/router/shared/SharedObjectsPaths';
import { getSharedObjectPaths } from '@/router/shared/SharedObjectPaths';
import SharedFieldPaths from '@/router/shared/SharedFieldPaths';

// import ObjectListConnections from '@/components/schema/ObjectListConnections'
import ConnectionAdd from '@/components/connections/ConnectionAdd';

// import ConnectionDetails from '@/components/connections/ConnectionDetails'
import Field from '@/components/fields/Field';
import FieldAdd from '@/components/fields/FieldAdd';
import FieldSettings from '@/components/fields/FieldSettings';
import FieldRulesValidation from '@/components/fields/FieldRulesValidation';
import FieldRulesConditional from '@/components/fields/FieldRulesConditional';
import FieldDelete from '@/components/fields/FieldDelete';
import FieldTypeEdit from '@/components/fields/FieldTypeEdit/FieldTypeEdit';

// import FirstObjectWizard from '@/components/schema/wizard/FirstObjectWizard'

export default [
  {
    path: '/schema/',
    redirect: () => '/schema/list',
  },
  {
    path: '/schema',
    redirect: () => '/schema/list',
    component: Builder,
    children: [
      {
        path: '',
        component: Schema,
        children: [
          {
            path: 'list/objects',
            redirect: () => '/schema/list',
          },
          {
            path: 'list',
            components: {
              body: ObjectList,
            },
            children: [
              {
                path: '',
                components: {
                  toolbox: ObjectsNav,
                  body: ObjectListIntro,
                },
              },

              // add object paths
              ...getSharedObjectsPaths(ObjectListIntro),

              {
                path: 'objects/:objectKey/fields/add',
                components: {
                  toolbox: FieldAdd,
                  body: ObjectListFields,
                },
                children: [
                  {
                    path: 'connection',
                    components: {
                      addFieldModal: ConnectionAdd,
                    },
                  },
                  {
                    path: ':fieldKey',
                    components: {
                      modal: Field,
                    },
                    children: [
                      {
                        path: 'settings',
                        component: FieldSettings,
                      },
                      {
                        path: 'rules/validation',
                        component: FieldRulesValidation,
                      },
                      {
                        path: 'rules/conditional',
                        component: FieldRulesConditional,
                      },
                    ],
                  },
                  {
                    path: ':fieldKey/type',
                    components: {
                      modal: FieldTypeEdit,
                    },
                  },
                  {
                    path: ':fieldKey/delete',
                    components: {
                      modal: FieldDelete,
                    },
                  },
                  {
                    path: 'connections/add',
                    components: {
                      modal: ConnectionAdd,
                    },
                  },
                ],
              },
              {
                path: 'objects/:objectKey/fields/connections/:connectionKey',
                components: {
                  toolbox: ObjectsNav,
                  body: ObjectListFields,
                },
                props: {
                  body: {
                    connectionType: 'details',
                  },
                },
              },
              {
                path: 'objects/:objectKey',
                components: {
                  toolbox: ObjectsNav,
                  body: ObjectListFields,
                },
                redirect: (to) => `/schema/list/objects/${to.params.objectKey}/fields`,
                children: [

                  // object settings
                  ...getSharedObjectPaths(),

                  {
                    path: 'fields',
                    component: {}, // An empty object is required or the route won't load.
                  },

                  // paths for field routes: edit, rules, type, delete
                  ...SharedFieldPaths,

                  {
                    path: 'connections/add',
                    components: {
                      modal: ConnectionAdd,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
