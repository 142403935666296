<template>
  <div
    class="field-indicator-wrapper"
    :class="[
      { 'field-indicator-wrapper--button': isInteractive },
      ...wrapperClasses,
    ]"
  >
    <Icon
      v-if="icon"
      class="tabsMenu_icon field-indicator-icon text-default fill-current"
      :class="iconClasses"
      :type="icon"
    />
    <span
      v-if="title"
      class="field-indicator-title"
    >{{ title }}</span>
    <CountIndicator
      v-if="shouldShowCount"
      class="field-indicator-count text-default"
      :count="count"
    />
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import CountIndicator from '@/components/ui/CountIndicator';

export default {
  name: 'FieldIndicator',
  components: {
    Icon,
    CountIndicator,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    isInteractive: {
      type: Boolean,
      default: false,
    },
    wrapperClasses: {
      type: Array,
      default: () => [],
    },
    iconClasses: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    shouldShowCount() {
      return this.count > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.field-indicator-wrapper {
  display: flex;
}

// Interactive version applies button-esque styling most noticeable on hover
.field-indicator-wrapper--button {
  border-radius: 4px;
  padding: .3125em .3125em;
  transition: background-color .2s ease-in;
  margin-left: .5em;
  background-color: $gray100;
  min-width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $gray200;
  }
}

.field-indicator-icon {
  fill: $gray500;
  height: 1em;
  width: 1em;
  margin-right: 0;
}

// icons can stand alone, assign the margin to whatever is next to them
.filter-indicator-icon + .field-indicator-title,
.filter-indicator-icon + .field-indicator-count {
  margin-left: 0.333em;
}

.field-indicator-title + .field-indicator-count {
  margin-left: .5em;
}

.tabsMenu_tab:hover,
.router-link-active {
  .field-indicator-icon {
    fill: $fuchsia;
  }

  .field-indicator-count {
    color: $primary500;
    background-color: $primary100;
  }
}
.field-indicator-wrapper--button .field-indicator-count {
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  min-width: 0 !important;
  width: auto;
  margin: 0 .25rem;
}
</style>
