<template>
  <ActionList
    :items="values"
    :default-item="defaultItem"
    :can-be-empty="canBeEmpty"
    :empty-text="emptyText"
    @update:items="onUpdateItems"
  >
    <template #default="{item, itemKey}">
      <ValueListItem
        :key="itemKey"
        :excluded-object-field-keys="excludedObjectFieldKeys"
        :item="item"
        :object="object"
        :connected-field-options="connectedFieldOptions"
        :input-field-options="inputFieldOptions"
        :allow-connection-option="allowConnectionOption"
        :allow-location-option="allowLocationOption"
        :show-value-type-options="showValueTypeOptions"
        :show-field-options="showFieldOptions"
      />
    </template>
  </ActionList>
</template>
<script>
import ActionList from '@/components/ui/lists/ActionList';
import ValueListItem from '@/components/ui/lists/ValueListItem';

export default {
  components: {
    ActionList,
    ValueListItem,
  },
  props: {
    excludedObjectFieldKeys: {
      type: Array,
      default: () => [],
    },
    object: {
      type: Object,
      default: () => ({}),
    },
    connectionOptions: {
      type: Array,
      default: () => [],
    },
    inputFieldOptions: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
    canBeEmpty: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: '',
    },
    allowConnectionOption: {
      type: Boolean,
      default: true,
    },
    allowLocationOption: {
      type: Boolean,
      default: true,
    },
    showValueTypeOptions: {
      type: Boolean,
      default: false,
    },
    showFieldOptions: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:values',
  ],
  computed: {
    connectedFieldOptions() {
      const options = [];

      this.connectionOptions.forEach((conn) => {
        if (conn.has === 'one') {
          const connObject = this.$store.getters.getObject(conn.object);

          connObject.fields.forEach((field) => {
            let label = connObject.name;

            if (!(conn.name === connObject.inflections.singular || conn.name === connObject.inflections.plural)) {
              label += ` (${conn.name})`;
            }

            label += ` > ${field.name}`;

            options.push({
              value: `${conn.key}-${field.key}`,
              label,
            });
          });
        }
      });

      return options;
    },
  },
  created() {
    if (!this.canBeEmpty && !this.values.length) {
      this.values.push(this.defaultItem());
    }
  },
  methods: {
    defaultItem() {
      return {
        field: this.object.fields.find((field) => !field.get('read_only')).key, // this.field.key,
        type: 'value',
        input: '',
        connection_field: '',
        value: '',
      };
    },
    onUpdateItems(newItems) {
      this.$emit('update:values', newItems);
    },
  },
};
</script>

<style lang="scss">
.field-list {
  .field-list-item {
    display: flex;
    flex-grow: 1;

    > * {
      margin-right: .25rem;
    }
    > *:last-child {
      margin-right: 0;
    }
  }

}
</style>
