<template>
  <div id="object-copy-fields">
    <form class="kn-builder-form">
      <div
        id="field-select-all-none"
        class="relative"
      >
        <div class="flex justify-end absolute right-0 -top-9 gap-6">
          <button
            class="underline bg-transparent border-none text-default text-sm font-medium p-0"
            type="button"
            @click="clearSelectedFields"
          >
            Select None
          </button>
          <button
            class="underline bg-transparent border-none text-default text-sm font-medium p-0"
            type="button"
            @click="selectAllFields"
          >
            Select All
          </button>
        </div>
      </div>

      <div class="input-labels tw-input-labels">
        <label
          v-for="field in object.fields"
          :key="field.key"
        >
          <input
            v-model="fieldsToCopyLocal"
            type="checkbox"
            class="checkbox m-0"
            :value="field.key"
            :disabled="shouldDisableFieldKey(field.key)"
            :data-test-id="field.name"
            @change="parseAndSelectRequiredFields"
          >
          <Icon
            v-if="field.immutable && object.profile_key && object.profile_key !== `all_users`"
            type="user"
            class="text-default h-6 w-6"
            style="width: 24px; height: 16px;"
          />
          <span>
            {{ field.name }}
            <HelpIcon
              v-if="isRequiredField(field) && !isUserRoleRequiredField(field)"
              :text="requiredText"
            />
            <HelpIcon
              v-if="isUserRoleRequiredField(field)"
              :text="requiredUserRoleText"
            />
          </span>
        </label>
      </div>
      <div class="submit-buttons flex justify-end">
        <button
          class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium m-0"
          :class="[submitDisabled ? `disabled` : ``]"
          :disabled="submitDisabled"
          data-cy="object-copy-submit"
          @click.prevent="onSubmit"
        >
          Copy
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import FieldUtils from '@/components/fields/FieldUtils';
import HelpIcon from '@/components/ui/HelpIcon';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    HelpIcon,
    Icon,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    isNewObject: {
      type: Boolean,
      default: true,
    },
    fieldsToCopy: {
      type: Array,
      default: () => [],
    },
    existingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'submit',
  ],
  data() {
    return {
      fieldsToCopyLocal: this.fieldsToCopy,
      requiredFields: [],
    };
  },
  computed: {
    eligibleFieldKeys() {
      let eligibleFieldKeys = [];

      if (this.isNewObject) {
        eligibleFieldKeys = this.object.getFieldKeysEligibleForCopyNew();
      } else {
        eligibleFieldKeys = this.object.getFieldKeysEligibleForCopyExisting();
      }

      return eligibleFieldKeys;
    },
    submitDisabled() {
      return isEmpty(this.fieldsToCopyLocal);
    },
    requiredText() {
      return 'This field is automatically selected because it is required by another selected field.';
    },
    requiredUserRoleText() {
      return 'This field is automatically selected because it is required to create a new user role table.';
    },
  },
  watch: {
    existingObject() {
      this.parseAndSelectRequiredFields();
    },
    fieldsToCopy(value) {
      this.fieldsToCopyLocal = value;
    },
    isNewObject() {
      this.parseAndSelectRequiredFields();
    },
  },
  created() {
    this.requiredFields = [];
  },
  mounted() {
    this.parseAndSelectRequiredFields();
  },
  methods: {
    isRequiredField(field) {
      return this.requiredFields.includes(field.key);
    },
    isUserRoleRequiredField(field) {
      return this.isNewObject
        && field.immutable
        && this.object.profile_key
        && this.object.profile_key !== 'all_users';
    },
    getUserRoleRequiredFields() {
      if (!this.object.profile_key || this.object.profile_key === 'all_users') {
        return [];
      }

      return this.object.getUserRoleFields();
    },
    setOrRemoveUserRoleRequiredFields(selectedFieldKeys) {
      const userRoleRequiredFieldKeys = this.getUserRoleRequiredFields();

      if (!this.isNewObject) {
        // For existing objects, remove all these fields from the select field keys.
        return difference(selectedFieldKeys, userRoleRequiredFieldKeys);
      }

      return union(selectedFieldKeys, userRoleRequiredFieldKeys);
    },
    getRequiredFieldsRecursive(fieldKeys) {
      const newRequiredFieldKeys = fieldKeys.reduce((final, selectedFieldKey) => {
        const selectedField = this.object.getField(selectedFieldKey);
        if (!selectedField) {
          return final;
        }

        return union(
          final,
          this.object.getRequiredFieldsForField(selectedField),
        );
      }, []);

      if (isEmpty(newRequiredFieldKeys)) {
        return [];
      }

      const subRequiredFields = this.getRequiredFieldsRecursive(newRequiredFieldKeys);

      return union(
        newRequiredFieldKeys,
        subRequiredFields,
      );
    },
    parseAndSelectRequiredFields() {
      const startingSelectedFieldKeys = [...this.fieldsToCopyLocal];

      const newRequiredFieldKeys = this.getRequiredFieldsRecursive(startingSelectedFieldKeys);

      const newSelectedFieldKeys = union(
        startingSelectedFieldKeys,
        newRequiredFieldKeys,
      );

      this.fieldsToCopyLocal = this.setOrRemoveUserRoleRequiredFields(newSelectedFieldKeys);
      this.requiredFields = newRequiredFieldKeys;
    },
    shouldDisableFieldKey(fieldKey) {
      // Don't copy ineligible field keys, even to an existing object
      if (!this.eligibleFieldKeys.includes(fieldKey)) {
        return true;
      }

      // when copying to an existing object enable field selection
      if (!this.isNewObject) {
        return false;
      }

      if (this.getUserRoleRequiredFields().includes(fieldKey)) {
        return true;
      }

      return this.requiredFields.includes(fieldKey);
    },
    onSubmit() {
      return this.$emit('submit', this.fieldsToCopyLocal);
    },
    selectAllFields() {
      this.fieldsToCopyLocal = this.object.fields.reduce((memo, { key }) => {
        if (this.eligibleFieldKeys.includes(key)) {
          memo.push(key);
        }

        return memo;
      }, []);

      this.parseAndSelectRequiredFields();
    },
    clearSelectedFields() {
      this.fieldsToCopyLocal = [];
      this.parseAndSelectRequiredFields();
    },
  },
};
</script>

<style lang="scss">
#field-select-all-none {
  @include font-caption;
  margin: 0;
  margin-bottom: .5rem !important;

  .small {
    padding: 0 .25rem;
  }
}

#object-copy-fields {
  label {
    align-items: center;
    margin-bottom: .5em;
  }

  input {
    margin: 0;
  }

  span {
    display: inline-flex;
  }

  .help-icon {
    opacity: .75;
  }
}
</style>
