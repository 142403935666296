import TOC from '@/components/views/login/TOC';
import Settings from '@/components/views/login/Settings';
import Design from '@/components/views/login/Design';
import SSOAdd from '@/components/settings/sso/SSOAdd';
import SSOEdit from '@/components/settings/sso/SSOEdit';

export default [
  {
    path: ':pageKey/views/:viewKey/login',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/login/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/login/settings/sso/add',
    components: {
      modal: SSOAdd,
      toolbox: Settings,
    },
    meta: {
      widthClass: 'builderLayout_toolbox-large',
    },
  },
  {
    path: ':pageKey/views/:viewKey/login/settings/sso/edit/:provider',
    components: {
      modal: SSOEdit,
      toolbox: Settings,
    },
    props: {
      modal: true,
    },
    meta: {
      widthClass: 'builderLayout_toolbox-large',
    },
  },
  {
    path: ':pageKey/views/:viewKey/login/design',
    components: {
      toolbox: Design,
    },
  },
];
