<template>
  <div class="toolbox-wrapper">
    <Toolbox
      back-title="design"
      title="Look and Feel"
    >
      <p class="mb-0">
        Customize the app design
      </p>

      <Toggle
        v-if="showThemeToggle"
        toggle-content-classes="px-4"
      >
        <template #title>
          <span class="text-base text-emphasis font-semibold">
            Theme
          </span>
        </template>
        <template #content>
          <div class="p-0 pb-6">
            <div class="customization-options m-0">
              <AppDesignPropertyPicker
                design-property="general.theme"
                label-text="Theme"
                input-type="dropdown"
                :options="themeOptions"
              />
            </div>
            <Notification
              v-if="showLegacyWarning"
              class="mt-2"
            >
              <p>Your app is on a legacy theme that is no longer supported. Update to "Knack Standard" to access new design settings.</p>
            </Notification>
            <Notification
              v-if="showUpgradeWarning"
              class="mt-2"
            >
              <p>Congrats, you're upgrading to the latest theme. Once saved you will no longer be able to choose a legacy theme.</p>
            </Notification>
          </div>
        </template>
      </Toggle>

      <hr
        v-if="showThemeToggle"
        class="border-0 border-t border-solid border-subtle"
      >

      <Toggle toggle-content-classes="px-4">
        <template #title>
          <span class="text-base text-emphasis font-semibold">
            Page Layout
          </span>
        </template>
        <template #content>
          <div class="p-0 pb-6">
            <div class="customization-options m-0">
              <AppDesignPropertyPicker
                design-property="regions.body.full_width"
                label-text="page width"
                :options="pageWidthOptions"
                description-text="Full Width will always fill the entire screen.
                  Max Width prevents big screens from stretching the page too wide."
              />
            </div>
          </div>
        </template>
      </Toggle>

      <hr class="border-0 border-t border-solid border-subtle">

      <Toggle toggle-content-classes="px-4">
        <template #title>
          <span class="text-base text-emphasis font-semibold">
            Text
          </span>
        </template>
        <template #content>
          <div class="p-0 pb-6">
            <div class="customization-options m-0">
              <AppDesignPropertyPicker
                design-property="general.font"
                label-text="font"
                input-type="dropdown"
                :options="fontOptions"
              />
              <AppDesignPropertyPicker
                design-property="general.links.color"
                label-text="link color"
                input-type="color"
                description-text="This will affect links, button text, and grid headers."
                data-feature="link_color_picker"
                @input="onColorHasChanged"
              />
            </div>
          </div>
        </template>
      </Toggle>

      <hr class="border-0 border-t border-solid border-subtle">

      <Toggle toggle-content-classes="px-4">
        <template #title>
          <span class="text-base text-emphasis font-semibold">
            Buttons
          </span>
        </template>
        <template #content>
          <div class="p-0 pb-6">
            <div class="customization-options m-0">
              <AppDesignPropertyPicker
                design-property="general.buttons.bg_color"
                label-text="fill color"
                input-type="color"
              />
              <AppDesignPropertyPicker
                design-property="general.buttons.color"
                label-text="text color"
                input-type="color"
              />
            </div>
          </div>
        </template>
      </Toggle>

      <hr class="border-0 border-t border-solid border-subtle">

      <Toggle toggle-content-classes="px-4">
        <template #title>
          <span class="text-base text-emphasis font-semibold">
            Notifications
          </span>
        </template>
        <template #content>
          <div class="p-0 pb-6">
            <div class="customization-options m-0">
              <AppDesignPropertyPicker
                design-property="components.notifications.color"
                label-text="text color"
                input-type="color"
                @input="onColorHasChanged"
              />
              <AppDesignPropertyPicker
                design-property="components.notifications.bg_color"
                label-text="background color"
                input-type="color"
                @input="onColorHasChanged"
              />
            </div>
          </div>
        </template>
      </Toggle>
    </Toolbox>
  </div>
</template>

<script>
import { fontOptions } from '@knack/style-engine';

import useLiveAppDesignPreview from '@/composables/ui/useLiveAppDesignPreview';
import AppDesignPropertyPicker from '@/components/settings/design/AppDesignPropertyPicker';
import ApplicationDesignUtils from '@/components/util/ApplicationDesignUtils';
import Notification from '@/components/ui/notifications/Notification';
import Toolbox from '@/components/layout/Toolbox';
import Toggle from '@/components/ui/Toggle';

export default {
  name: 'PageDesignSettings',
  components: {
    AppDesignPropertyPicker,
    Notification,
    Toggle,
    Toolbox,
  },
  mixins: [
    ApplicationDesignUtils,
  ],
  setup() {
    const { setShowNotificationPreview } = useLiveAppDesignPreview();
    return { setShowNotificationPreview };
  },
  data() {
    return {
      themeOptions: [
        {
          label: 'Knack Standard',
          value: 'kn-beta',
        },
        {
          label: 'Legacy: Classic',
          value: 'basic',
        },
        {
          label: 'Legacy: Flat',
          value: 'flat',
        },
      ],
      initialTheme: 'kn-beta',
    };
  },
  computed: {
    fontOptions() {
      return fontOptions.map((option) => ({
        label: option,
        value: option,
      }));
    },
    pageWidthOptions() {
      return [
        {
          label: 'Full Width',
          value: true,
        },
        {
          label: 'Max Width',
          value: false,
        },
      ];
    },
    showThemeToggle() {
      return this.initialTheme !== 'kn-beta';
    },
    showLegacyWarning() {
      return this.app?.design?.general?.theme !== 'kn-beta';
    },
    showUpgradeWarning() {
      return this.initialTheme !== 'kn-beta' && this.app?.design?.general?.theme === 'kn-beta';
    },
  },
  created() {
    this.initialTheme = this.app?.design?.general?.theme || 'kn-beta';

    // Show the notification preview since this is where the notification configuration is
    this.setShowNotificationPreview(true);
  },
};
</script>
