<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Format</label>
      <div>
        <select
          v-model="fieldLocal.format.format"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="(999) 999-9999">
            (999) 999-9999
          </option>
          <option value="999.999.9999">
            999.999.9999
          </option>
          <option value="+99 999 999 99?99">
            +99 999 999 9999
          </option>
          <option value="+99 (0)999 999 99?99">
            +99 (0)999 999 9999
          </option>
          <option value="99 9999 9999">
            99 9999 9999
          </option>
          <option value="9999 999 999">
            9999 999 999
          </option>
          <option value="any">
            Any
          </option>
        </select>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4"><input
        v-model="fieldLocal.format.extension"
        type="checkbox"
      > Include Extension</label>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Default Value</label>
      <PhoneInput
        v-model="fieldLocal.default"
        :input="fieldLocal"
      />
    </div>
  </div>
</template>

<script>
import PhoneInput from '@/components/renderer/form/inputs/Phone';

export default {
  name: 'Phone',
  components: {
    PhoneInput,
  },
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
