/**
 * The READY status for async requests.
 * Indicates no request has yet been made.
 * @const {string}
 */
export const STATUS_READY = 'READY';

/**
  * The PENDING status for async requests.
  * Indicates the request is waiting for a response.
  * @const {string}
  */
export const STATUS_PENDING = 'PENDING';

/**
  * The SUCCESS status for async requests.
  * Indicates the request was successful.
  * @const {string}
  */
export const STATUS_SUCCESS = 'SUCCESS';

/**
  * The FAILURE status for async requests.
  * Indicates the request failed with an error.
  * @const {string}
  */
export const STATUS_FAILURE = 'FAILURE';
