<template>
  <ViewToolbox
    :back-link="`/pages/${pageKey}/views/${viewKey}/list`"
    back-title="List Menu"
    title="Add Actions & Fields"
  >
    <Toggle
      :is-open="true"
      class="no-top-styles"
    >
      <template #title>
        <span>Details Layout</span>
      </template>
      <template #content>
        <div class="inputGroup mb-0 pb-6 border border-solid border-subtle border-x-0 border-t-0">
          <div>
            <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label">Label Format</label>
            <LabelFormat
              :model-value="viewRaw"
              @update-property:model-value="onUpdateViewProperty"
            />
          </div>
          <div class="mb-0">
            <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] tw-toolbox-label">Layout</label>
            <DetailsLayout />
          </div>
        </div>
      </template>
    </Toggle>
    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Add Details</span>
      </template>
      <template #content>
        <AddItems
          :view="view"
          :object="object"
        >
          <p class="text-sm leading-5">
            Drag or click options below to add new details.
          </p>
        </AddItems>
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import get from 'lodash/get';

import DetailsLayout from '@/components/views/shared/DetailsLayout';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import AddItems from '@/components/views/AddItems';
import LabelFormat from '@/components/views/shared/settings/LabelFormat.vue';

export default {
  name: 'ListDetailsBuild',
  components: {
    LabelFormat,
    AddItems,
    DetailsLayout,
    Toggle,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    pageKey() {
      return get(this.page, 'key', '');
    },
    viewKey() {
      return get(this.view, 'key', '');
    },
  },
};
</script>
