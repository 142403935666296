<template>
  <div class="kn-icon-loading">
    <div class="loader-wrapper">
      <Icon
        type="knack"
        data-cog="knack-asterisk"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  name: 'IconLoading',
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
@keyframes spinning-cog {
  0% {
    transform: rotate(0deg)
  }
  20% {
    transform: rotate(-45deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.kn-icon-loading {
  svg {
    animation: spinning-cog 1.5s infinite ease;
    fill: rgba(152, 42, 134, 0.75);
  }
}
</style>
