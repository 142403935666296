import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';

import { getGroupsFromViews } from '@/lib/page/page-helper';
import { getViewSourceOptions } from '@/lib/page/view-source-helper';
import store from '@/store';
import BasePage from '@/store/models/page/BasePage';
import View from '@/store/models/View';

/**
 * The raw page data from the app.
 */
export class RawPage extends BasePage {
  /**
   * The raw page data.
   * This comes directly from the app object.
   * @type {object}
   */
  raw;

  /**
   * The parent object.
   * @type {RawPage | undefined}
   */
  parent;

  /**
   * The children objects.
   * @type {RawPage[]}
   */
  children = [];

  /**
   * The depth of this page (no parent = 0, 1 parent = 1, etc).
   * @type {number}
   */
  depth = 0;

  /**
   * The menu parent object.
   * @type {RawPage | undefined}
   */
  menuParent;

  /**
   * The menu children objects.
   * @type {RawPage[]}
   */
  menuChildren = [];

  /**
   * Whether or not the page is authenticated.
   * @type {boolean}
   */
  authenticated = false;

  /**
   * The authentication profiles.
   * @type {[]}
   */
  authentication_profiles = []; /* eslint-disable-line camelcase */

  /**
   * Source options.
   * Lazy loaded through the 'sourceOptions' getter.
   * @type {[] | undefined}
   */
  _sourceOptions;

  /**
   * Whether or not the groups need to be lazy loaded.
   * @type {boolean}
   */
  _groupsAreSet = false;

  /**
   * Whether or not this page is supposed to have a parent but the
   * parent page (or grand*parent page) does not exist.
   *
   * @type {boolean}
   */
  isOrphan = false;

  /**
   * @constructor
   * @param {object} pageData
   */
  constructor(pageData) {
    if (!pageData) {
      log('RawPage constructor: ', pageData);

      throw new Error('You must provide page data to RawPage.');
    } else if (!isPlainObject(pageData)) {
      log('RawPage constructor: ', {
        pageData,
        name: get(pageData, 'constructor.name', 'Not an instance'),
      });

      throw new Error('You can not instantiate a RawPage with an instance.');
    }

    super(pageData);

    this.raw = pageData;
  }

  get key() {
    return this.raw.key;
  }

  get slug() {
    return this.raw.slug;
  }

  get type() {
    return this.raw.type;
  }

  get views() {
    return store.getters.getViewsByPageKey(this.key);
  }

  set views(newViews) {
    throw new Error('Setting views through the RawPage object is not supported');
  }

  get name() {
    return this.raw.name;
  }

  set name(newName) {
    this.updatePageData({ name: newName });
  }

  get object() {
    return this.raw.object;
  }

  set object(newObject) {
    this.updatePageData({ object: newObject });
  }

  /**
   * Gets the view source options and lazy loads them if they are not already set.
   *
   * @returns {object[]}
   */
  get sourceOptions() {
    if (this._sourceOptions) {
      return this._sourceOptions;
    }

    this.buildViewSourceOptions();

    return this._sourceOptions;
  }

  set sourceOptions(newSourceOptions) {
    this._sourceOptions = newSourceOptions;
  }

  /**
   * Gets the groups and lazy loads them if they are not already set.
   *
   * @returns {Array<{ columns: Array<{ width: number, keys: string[] }> }>}
   */
  get groups() {
    if (this._groupsAreSet) {
      return this.raw.groups;
    }

    if (this.raw.groups && !isEmpty(this.raw.groups)) {
      this._groupsAreSet = true;

      return this.raw.groups;
    }

    this.buildGroups();

    this._groupsAreSet = true;

    return this.raw.groups;
  }

  set groups(newGroups) {
    this.raw.groups = newGroups;
  }

  /**
   * Gets any children page objects for this page.
   * This will get menu children for menu pages.
   *
   * @returns {RawPage[]}
   */
  getChildrenPages() {
    // For global login page apps, menu children still have the global login page as their parent
    // in order for the renderer to know a login flow is needed when hitting those pages.
    // This makes sure to skip any such children. Needed for the nav and possibly other places.
    if (this.isGlobalLoginPage()) {
      return this.children.filter((childPage) => !childPage.menuParent);
    }

    if (!isEmpty(this.menuChildren)) {
      return this.menuChildren;
    }

    return this.children;
  }

  /**
   * Gets the parent page object for this page.
   * This will return the menu parent if one is available and `excludeMenuParent` is false.
   *
   * @param {boolean} [excludeMenuParent] - If true, the menu parent will be skipped.
   * @returns {RawPage | undefined}
   */
  getParentPage(excludeMenuParent = false) {
    if (!excludeMenuParent && this.menuParent) {
      return this.menuParent;
    }

    return this.parent;
  }

  /**
   * Gets the first child of this page.
   *
   * @returns {RawPage | undefined}
   */
  getFirstChild() {
    if (!isEmpty(this.menuChildren)) {
      return this.menuChildren[0];
    }

    return this.children[0];
  }

  /**
   * Builds the view source options from the object.
   */
  buildViewSourceOptions() {
    const options = getViewSourceOptions(this.raw.object, this);

    // We do not want vue to make these observable.
    // In large apps, making this observable will freeze/crash the browser.
    // These should always be regenerated, not updated, on changes, so freezing should be safe.
    // If any mutations cause an error, then clear _sourceOptions in updatePageData().
    Object.freeze(options);

    this.sourceOptions = options;
  }

  /**
   * Builds the groups from the views.
   */
  buildGroups() {
    this.groups = getGroupsFromViews(
      this.views || [],
    );
  }

  /**
   * Updates the page with new object data.
   *
   * @param {object} newPageData
   */
  updatePageData(newPageData) {
    const previousObject = this.raw.object;

    this.raw = {
      ...this.raw,
      ...newPageData,
      views: undefined, // Make sure views are not stored in this object.
    };

    // Now make sure views isn't even set to undefined.
    // The previous `views: undefined` makes sure Vue doesn't make any view object observable.
    // This line makes sure views don't default correctly elsewhere.
    delete this.raw.views;

    if (newPageData.groups) {
      // Reload the groups the next time they are referenced.
      this._groupsAreSet = false;
    }

    if (newPageData.object !== previousObject) {
      // This will make sure the source options are rebuilt the next time they are referenced.
      this._sourceOptions = undefined;
    }
  }

  /**
   * Safely sets or adds a view to the page.
   *
   * @param {View} newView
   * @returns {View} - The new view.
   */
  setView(newView) {
    if (!(newView instanceof View)) {
      throw new Error('RawPage.setView() must be given a View instance.');
    }

    store.commit('setView', {
      viewData: newView.raw(),
      pageKey: this.key,
    });

    return newView;
  }

  /**
   * Removes a child page from the children array.
   *
   * @param {string} childPageKey
   */
  removeChild(childPageKey) {
    if (isEmpty(this.children)) {
      return;
    }

    this.children = this.children.filter((childPage) => childPage.key !== childPageKey);
  }

  /**
   * Removes a menu child.
   *
   * @param {string} childPageKey
   * @param {string} childPageSlug
   */
  removeMenuChild(childPageKey, childPageSlug) {
    if (isEmpty(this.menuChildren)) {
      return;
    }

    this.menuChildren = this.menuChildren.filter((childPage) => childPage.key !== childPageKey);

    // Also remove the child from the menu_pages in the raw page data.
    const childIndex = findIndex(this.raw.menu_pages, childPageSlug);

    if (childIndex !== -1) {
      const newRaw = {
        ...this.raw,
      };

      // Delete the view at the index.
      newRaw.menu_pages.splice(childIndex + 1, 1);

      this.raw = newRaw;
    }
  }

  /**
   * Finds the top level parent page starting from this page.
   *
   * @returns {RawPage}
   */
  getTopLevelParent() {
    let topLevelPage = this;

    while (topLevelPage.parent || topLevelPage.menuParent) {
      topLevelPage = topLevelPage.parent || topLevelPage.menuParent;
    }

    return topLevelPage;
  }

  /**
   * Gets the first child page that is a start page (if one exists).
   *
   * @returns {RawPage | undefined}
   */
  getFirstChildStartPage() {
    if (isEmpty(this.children)) {
      return;
    }

    return this.children.find((rawPage) => (rawPage.isStartPage() && !rawPage.isMenuPage()));
  }
}

export default RawPage;
