<template>
  <view-toolbox
    back-title="Search"
    :back-count="6"
    title="Input Properties"
  >
    <form>
      <template v-if="isStaticInput">
        <template v-if="isCopy">
          <div class="mb-4">
            <label class="tw-toolbox-label">Title</label>
            <input
              v-model="input.name"
              type="text"
            >
          </div>
          <div class="mb-0">
            <label class="tw-toolbox-label">Copy</label>
            <textarea v-model="input.copy" />
          </div>
        </template>
        <p v-else>
          Dividers have nothing to configure! Moving along.
        </p>
      </template>

      <template v-else>
        <div
          v-if="field"
          class="mb-4"
        >
          <label
            for="field"
            class="tw-toolbox-label"
          >
            Field
          </label>
          <div class="mb-0 inline-flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis mt-0">
            <TypeIcon
              :field="field"
              class="text-subtle h-4 w-4 mr-1"
              style="width: 15px; height: 15px;"
            />
            <span style="line-height: 12px;">
              {{ field.name }}
            </span>
          </div>
        </div>

        <div class="mb-4">
          <label
            for="label"
            class="tw-toolbox-label"
          >
            Label
          </label>
          <input
            v-model="input.name"
            type="text"
            name="label"
          >
        </div>

        <template v-if="field">
          <div class="mb-4">
            <label class="tw-toolbox-label">Advanced Filters</label>
            <div class="input-labels tw-input-labels">
              <label>
                <input
                  v-model="input.ignore_operators"
                  type="checkbox"
                  data-cy="input-ignore-operators"
                  :true-value="false"
                  :false-value="true"
                > Users can choose from different filter options
              </label>
            </div>
          </div>

          <template v-if="input.ignore_operators">
            <div
              v-if="!showMultiOptions && !field.storesNumericValues()"
              class="mb-4"
            >
              <label class="tw-toolbox-label">Match Type</label>
              <div class="input-labels tw-input-labels">
                <label><input
                  v-model="input.operator_default"
                  type="radio"
                  name="operator_default"
                  value="is"
                >Exact Match</label>
                <label><input
                  v-model="input.operator_default"
                  type="radio"
                  name="operator_default"
                  value="contains"
                >Contains Any</label>
              </div>
            </div>

            <template v-if="showMultiOptions">
              <div class="mb-4">
                <label class="tw-toolbox-label">Search By</label>
                <div class="input-labels tw-input-labels">
                  <label><input
                    v-model="input.multi_type"
                    type="radio"
                    name="multi_type"
                    value="one"
                    data-cy="input-multi-type-one"
                  >Just One Option</label>
                  <label><input
                    v-model="input.multi_type"
                    type="radio"
                    name="multi_type"
                    value="many"
                    data-cy="input-multi-type-many"
                  >Multiple Options</label>
                </div>
              </div>

              <div class="mb-4">
                <label class="tw-toolbox-label">Input Format</label>
                <div class="input-labels tw-input-labels">
                  <label><input
                    v-model="input.multi_input"
                    type="radio"
                    name="multi_input"
                    value="chosen"
                    data-cy="input-multi-input-dropdown"
                  >Searchable Dropdown</label>
                  <label v-if="input.multi_type === 'many'">
                    <input
                      v-model="input.multi_input"
                      type="radio"
                      name="multi_input"
                      value="checkbox"
                      data-cy="input-multi-input-box"
                    >Checkboxes
                  </label>
                  <label v-if="input.multi_type === 'one'">
                    <input
                      v-model="input.multi_input"
                      type="radio"
                      name="multi_input"
                      value="radio"
                      data-cy="input-multi-input-box"
                    >Radio Buttons
                  </label>
                </div>
              </div>

              <div
                v-if="input.multi_type === 'many'"
                class="mb-4"
              >
                <label class="tw-toolbox-label">Multiple Match</label>
                <div class="input-labels tw-input-labels">
                  <label><input
                    v-model="input.multi_match"
                    type="radio"
                    name="multi_match"
                    value="or"
                  ><span>Records can match <strong>any</strong> option</span></label>
                  <label><input
                    v-model="input.multi_match"
                    type="radio"
                    name="multi_match"
                    value="and"
                  ><span>Records must match <strong>all</strong> options</span></label>
                </div>
              </div>
            </template>
          </template>
        </template>

        <template v-if="isKeywordSearch && isSQL">
          <div class="mb-4">
            <label class="tw-toolbox-label">Which fields can be searched?</label>
            <select
              v-model="viewRaw.keyword_search_fields"
              class="margin-bottom text-base py-2 pl-3 leading-5 mb-0"
            >
              <option value="view">
                The fields being used by this view
              </option>
              <option value="all">
                All fields in table
              </option>
            </select>
          </div>
        </template>

        <div class="mb-4">
          <label class="tw-toolbox-label">Required</label>
          <div class="input-labels tw-input-labels">
            <label><input
              v-model="input.required"
              type="checkbox"
            > A value must be entered to submit the search</label>
          </div>
        </div>

        <template v-if="isConnectionField">
          <div class="mb-4">
            <label class="tw-toolbox-label">Filters</label>
            <p class="margin-bottom-half font-normal text-default">
              Filter which records are available to choose.
            </p>
            <FieldList
              v-if="hasFilters"
              :items="connectionFilters"
              :object-key="connectionObjectKey"
              :can-be-empty="true"
              class="is-grouped hide-add p-0 bg-transparent"
              style="font-size: .9em;"
              @update:items="onUpdateConnectionFilters"
            />
            <a
              class="button tiny fuchsia-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base capitalize font-medium"
              @click="onAddFilter"
            >
              <Icon
                class="text-default group-hover:text-brand-400 h-4 w-4 mr-2 fill-current"
                type="plus-thin"
              />
              add filter
            </a>
          </div>
          <div
            v-if="hasConnectionParentOptions"
            class="mb-4"
          >
            <label class="tw-toolbox-label">Show</label>
            <select
              v-model="connectedParentOption"
              class="text-base py-2 pl-3 leading-5 mb-0"
            >
              <option
                v-for="option in connectionParentOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </template>

        <div
          v-if="field"
          class="mb-4"
          style="max-width: 100%;"
        >
          <label class="tw-toolbox-label">Default</label>
          <FieldListItem
            id="search-default"
            data-cy="search-default"
            :item="input"
            :item-index="0"
            :object-key="objectKey"
            :set-input-format="setFieldListItemFormat"
            :show-field="false"
            :show-operator="showDefaultOperator"
            :show-is-any="true"
            :use-simplified-inputs="false"
            @update:item="onUpdateSearchDefault"
          />
        </div>

        <div class="mb-0">
          <label
            for="instructions"
            class="tw-toolbox-label"
          >
            Instructions
          </label>
          <textarea
            v-model="input.instructions"
            name="instructions"
          />
        </div>
      </template>
    </form>
  </view-toolbox>
</template>

<script>
import isNil from 'lodash/isNil';
import FieldList from '@/components/ui/lists/FieldList';
import FieldListItem from '@/components/ui/lists/FieldListItem';
import ParentConnectionsMixin from '@/components/views/form/ParentConnectionsMixin';
import TypeIcon from '@/components/fields/TypeIcon';
import Icon from '@/components/ui/Icon';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'SearchInputProperties',
  components: {
    ViewToolbox,
    FieldList,
    FieldListItem,
    TypeIcon,
    Icon,
  },
  mixins: [
    ParentConnectionsMixin,
    ViewUtils,
  ],
  data() {
    return {
      rowIndex: null,
      columnIndex: null,
      inputIndex: null,
      field: null,
    };
  },
  computed: {
    isSQL() {
      return this.$store.getters.app.isSQL();
    },
    input() {
      if (!this.viewRaw.key) {
        return {};
      }

      this.setInput();

      if (isNil(this.rowIndex)) {
        return {};
      }

      const input = this.viewRaw.groups[this.rowIndex]?.columns[this.columnIndex]?.fields[this.inputIndex];

      if (isNil(input)) {
        return {};
      }

      if (!input.multi_match) {
        input.multi_match = 'any';
      }

      return input;
    },
    connectionObjectKey() {
      return this.field?.getConnectedObject().key;
    },
    hasFilters() {
      return this.connectionFilters.length > 0;
    },
    isConnectionField() {
      return this.field?.isConnection() ?? false;
    },
    connectionFilters: {
      get() {
        if (this.input.source) {
          return this.input.source.filters || [];
        }

        return [];
      },
      set(newVal) {
        if (!this.input.source) {
          this.input.source = {};
        }

        this.input.source.filters = newVal;
      },
    },
    connectedParentOption: {
      get() {
        const { input } = this;

        if (input.source) {
          if (input.source.remote_key) {
            return `${input.source.connection_key}-${input.source.remote_key}-${input.source.type}`;
          }

          if (input.source.connections && input.source.connections[0]) {
            return `${input.source.connections[0].field.key}-${input.source.connections[0].source.field.key}-input`;
          }
        }

        return '';
      },
      set(newValue) {
        const conn = newValue.split('-');
        const source = this.input.source || {};

        if (conn[2] === 'input') {
          source.connections = [
            {
              field: {
                key: conn[0],
              },
              source: {
                type: 'input',
                field: {
                  key: conn[1],
                },
              },
            },
          ];
          delete source.type;
          delete source.remote_key;
          delete source.connection_key;
        } else {
          source.type = conn[2];
          source.connection_key = conn[0];
          source.remote_key = conn[1];

          delete source.connections;
        }

        this.input.source = source;
      },
    },
    hasConnectionParentOptions() {
      // see ParentConnectionsMixin
      return this.connectionParentOptions.length > 0;
    },
    objectKey() {
      return this.viewRaw.source.object;
    },

    isCopy() {
      // For some reason v2 schemas stored this as field = `section_break`
      return (this.input?.type === 'copy' || this.input?.type === 'section_break' || this.input?.field === 'section_break');
    },

    isKeywordSearch() {
      return this.input?.field === 'keyword_search';
    },

    isStaticInput() {
      return this.input?.type === 'divider' || this.isCopy;
    },

    showMultiOptions() {
      if (!this.field) {
        return false;
      }

      const multiTypes = [
        window.Knack.config.MULTIPLE_CHOICE,
        window.Knack.config.CONNECTION,
      ];

      return multiTypes.includes(this.field.type);
    },

    showDefaultOperator() {
      log(`showDefaultOperator() this.input.ignore_operators: ${this.input.ignore_operators}`);

      return (this.input.ignore_operators === false);
    },
  },
  watch: {
    'input.multi_type': function (newVal) {
      if (this.input.multi_input !== 'chosen') {
        this.input.multi_input = (newVal === 'one') ? 'radio' : 'checkbox';
      }

      if (newVal === 'one' && Array.isArray(this.input.value)) {
        // This must default to empty string or the FieldListItem will not display.
        this.input.value = this.input.value[0] || '';
      }

      if (newVal === 'many' && !Array.isArray(this.input.value)) {
        this.input.value = (!isNil(this.input.value)) ? Array(this.input.value) : [];
      }
    },
    'input.ignore_operators': function (newVal) {
      if (newVal && !this.input.operator) {
        this.input.operator = this.input.operator_default;

        // ensure this operator exists
        if (this.field) {
          const fieldOperators = this.field.getRuleOperators();

          if (!fieldOperators.find((operator) => operator === this.input.operator)) {
            this.input.operator = fieldOperators[0];
          }
        }
      }

      if (!newVal) {
        // Clear out the auto filled values when ignore_operators is turned off
        // otherwise the renderer will still use this (default) value with no way for the user
        // to see it or turn it off.
        if (Array.isArray(this.input.value)) {
          this.input.value = [];
        } else {
          this.input.value = '';
        }
      }
    },
  },
  methods: {
    defaultFilter() {
      if (!this.field) {
        return {};
      }

      const field = this.field.getConnectedObject().fields[0];

      return {
        field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
      };
    },
    setFieldListItemFormat(item, field) {
      return field.setFormatForSearchInput(item);
    },
    setInput() {
      this.rowIndex = this.$route.params.rowIndex;
      this.columnIndex = this.$route.params.columnIndex;
      this.inputIndex = this.$route.params.inputIndex;

      if (isNil(this.rowIndex)) {
        return;
      }

      const fieldKey = this.viewRaw.groups[this.rowIndex]?.columns[this.columnIndex]?.fields[this.inputIndex]?.field;

      this.field = this.getField(fieldKey);
    },
    onAddFilter() {
      this.connectionFilters = [
        ...this.connectionFilters,
        this.defaultFilter(),
      ];
    },
    onUpdateConnectionFilters(newFilters) {
      this.connectionFilters = newFilters;
    },
    onUpdateSearchDefault(newDefault) {
      if (!this.input) {
        return;
      }

      this.viewRaw.groups[this.rowIndex].columns[this.columnIndex].fields[this.inputIndex] = newDefault;
    },
  },
};
</script>

<style>
#search-default {
  display: flex;
  font-size: .9em;
}

#search-default > * {
  margin-right: 4px;
  max-width: 100%;
}
#search-default > *:last-child {
  margin-right: 0;
}
</style>
