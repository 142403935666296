import Joi from '@hapi/joi';
import api from '@/lib/api-wrapper';
import FormBase from '@/store/forms/FormBase';

/**
 * The maximum size of result text.
 * @const {number}
 */
const RESULT_TRUNCATE_LENGTH = 140;

/**
 * The maximum number of results to show.
 * @const {number}
 */
const RESULTS_MAX = 5;

/**
 * The form for searching help.
 */
export default class FormHelpSearch extends FormBase {
  /**
   * The search query.
   * @type {string}
   */
  query = '';

  /**
   * Validates the form.
   *
   * @returns {{passed: boolean, errors: Error[]}}
   */
  async validate() {
    const validationSchema = {
      query: Joi.string().min(2).required().label('The search'),
    };

    return this._validateForm(validationSchema);
  }

  /**
   * Submits the form to the server.
   *
   * @returns {Promise<{text: string, title: string}>}
   */
  async submit() {
    const searchResponse = await api.helpSearch(this.query, RESULTS_MAX);

    return (searchResponse.items || []).map((searchResult) => {
      const text = String(searchResult.snippet || '');

      return {
        title: searchResult.title,
        link: searchResult.link,
        text: (text.length > RESULT_TRUNCATE_LENGTH) ? `${text.slice(0, RESULT_TRUNCATE_LENGTH)}...` : text,
      };
    });
  }
}
