<template>
  <div class="item-wrapper">
    <div v-if="showActionSelection">
      <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Action</label>
      <div class="flex p-0 flex-col gap-2">
        <select
          v-model="item.action"
          style="max-width: max-content;"
          class="text-base py-2 pl-3 leading-5"
          data-cy="task-action-type"
        >
          <option
            v-if="allowRecordActions"
            value="record"
          >
            Update this record
          </option>
          <option
            v-if="allowRecordActions && hasConnections"
            value="connection"
          >
            Update connected records
          </option>
          <option
            v-if="allowRecordActions && hasConnections"
            value="insert"
          >
            Insert a connected record
          </option>
          <option
            v-if="allowEmailAction"
            value="email"
          >
            Send a custom email
          </option>
          <option
            v-if="allowEmailActionBasic"
            value="email_notification"
          >
            Send a basic email notification
          </option>
        </select>
        <select
          v-if="showConnectionPicker"
          v-model="item.connection"
          class="text-base py-2 pl-3 leading-5 ml-0"
          style="margin-left: 5px; min-width: 0;"
        >
          <option
            v-for="conn in getConnectionActions(item.action)"
            :key="conn.value"
            :value="conn.value"
          >
            {{ conn.label }}
          </option>
        </select>
      </div>
    </div>

    <!-- WHEN CRITERIA -->
    <!--
      TODO: this needs to be optional for action links, which have criteria set at a higher level than record rules
    -->
    <div
      v-if="showCriteria"
      class="mt-0 border-t-0 mb-4"
    >
      <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">
        <slot name="criteria-title">
          When
        </slot>
      </label>
      <CriteriaList
        :criteria="item.criteria"
        :values-restrict-calculated-fields="criteriaValuesRestrictCalculatedFields"
        :values-can-be-fields="criteriaValuesCanBeFields"
        :show-title="showCriteriaTitle"
        :object="object"
        :rule-type="ruleType"
        @update:criteria="onUpdateCriteria(item, $event)"
      >
        <template #no-criteria>
          <slot name="no-criteria" />
        </template>
      </CriteriaList>
    </div>

    <!-- ACTION: Record -->
    <BasicEmailAction
      v-if="item.action === `email_notification`"
      :item="item"
    />
    <EmailAction
      v-else-if="item.action === `email`"
      :item="item"
      :object="object"
    />
    <RecordAction
      v-else
      class="mt-0"
      :item="item"
      :object="object"
      :values-object="valuesObject"
      :input-field-options="inputFieldOptions"
      :allow-connection-option="allowConnectionOption"
      :allow-location-option="allowLocationOption"
      :show-field-options="showFieldOptions"
    />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import BasicEmailAction from '@/components/rules/BasicEmailAction';
import CriteriaList from '@/components/ui/lists/CriteriaList';
import EmailAction from '@/components/rules/EmailAction';
import RecordAction from '@/components/rules/RecordAction';

export default {
  name: 'RuleItem',
  components: {
    CriteriaList,
    RecordAction,
    BasicEmailAction,
    EmailAction,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    inputFieldOptions: {
      type: Array,
      default: () => [],
    },
    allowConnectionOption: {
      type: Boolean,
      default: true,
    },
    allowLocationOption: {
      type: Boolean,
      default: true,
    },
    allowEmailAction: {
      type: Boolean,
      default: false,
    },
    allowEmailActionBasic: {
      type: Boolean,
      default: false,
    },
    allowRecordActions: {
      type: Boolean,
      default: true,
    },
    criteriaValuesRestrictCalculatedFields: {
      type: Boolean,
      default: false,
    },
    criteriaValuesCanBeFields: {
      type: Boolean,
      default: false,
    },
    objectKey: {
      type: String,
      default: '',
    },
    showCriteria: {
      type: Boolean,
      default: true,
    },
    showCriteriaTitle: {
      type: Boolean,
      default: true,
    },
    showFieldOptions: {
      type: Boolean,
      default: true,
    },
    showActionSelection: {
      type: Boolean,
      default: true,
    },
    ruleType: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'activeObject',
      'activeView',
      'getObject',
    ]),
    object() {
      if (this.objectKey) {
        return this.getObject(this.objectKey);
      }

      if (this.activeView?.source?.object) {
        return this.getObject(this.activeView.source.object);
      }

      if (this.activeObject) {
        return this.activeObject;
      }

      return {};
    },
    showConnectionPicker() {
      return (this.item.action === 'connection' || this.item.action === 'insert');
    },
    valuesObject() {
      return this.getValuesObject(this.item.action, this.item.connection);
    },
    objectConnections() {
      const conns = [];

      this.object.conns.forEach((conn) => {
        const connectionObject = this.getObject(conn.object);

        // you can't insert ecommerce
        if (!connectionObject || connectionObject.get('ecommerce')) {
          return;
        }

        conns.push({
          key: conn.key,
          value: `${conn.object}.${conn.key}`,
          label: `${connectionObject.inflections.singular} connected to this record (${connectionObject.inflections.singular} > ${conn.name})`,
          object: conn.object,
          name: conn.name,
          has: conn.has,
        });
      });

      return conns;
    },
    hasConnections() {
      return this.objectConnections.length > 0;
    },
  },
  watch: {
    'item.action': function (newVal, oldVal) {
      if (newVal !== oldVal && (newVal === 'email_notification' || newVal === 'email')) {
        // Reset the email object. The action components will rebuild it when they are created.
        // This prevents the pollution of one email rule type from another.
        this.item.email = {};

        return;
      }

      if (newVal === 'connection') {
        this.item.connection = this.getConnectionActions(newVal)[0].value;
      }

      if (newVal === 'record') {
        const field = this.object.getMutableFields()[0];

        this.item.values = [
          field.getDefaultRule({
            isValue: true,
          }),
        ];

        delete this.item.connection;
      }

      // moving from record to connection needs new values
      const newActionIsConnection = (newVal === 'connection' || newVal === 'insert');

      if (oldVal && oldVal === 'record' && newActionIsConnection) {
        const connField = this.getValuesObject(newVal, this.item.connection).getMutableFields()[0];

        this.item.values = [
          connField.getDefaultRule({
            isValue: true,
          }),
        ];
      }
    },
    'item.connection': function (newVal, oldVal) {
      // Only reset values when we're sure the connection has changed appropriately
      if (!newVal || isEqual(newVal, oldVal) || !this.showConnectionPicker) {
        return;
      }

      this.item.connection = newVal;

      const connField = this.getValuesObject(this.item.action, newVal).getMutableFields()[0];

      this.item.values = [
        connField.getDefaultRule({
          isValue: true,
        }),
      ];
    },
  },
  methods: {
    // the object that will populate the fields that you can set values for
    getValuesObject(action, connectionKey) {
      if (action === 'record') {
        return this.object;
      }

      if (!connectionKey) {
        return this.object;
      }

      // if not record, then it's a connection
      const connectedObjectKey = connectionKey.split('.')[0];

      return this.getObject(connectedObjectKey);
    },
    getConnectionActions(action) {
      action = action || 'connection'; // connection | insert

      const options = [];

      this.objectConnections.forEach((conn) => {
        let label;

        if (action === 'insert') {
          label = `A new ${conn.label}`;
        } else {
          label = `${(conn.has === 'one') ? 'The' : 'Each'} ${conn.label}`;
        }

        options.push({
          value: conn.value,
          label,
        });
      });

      return options;
    },

    onUpdateCriteria(item, newCriteria) {
      item.criteria = newCriteria;
    },
  },
};
</script>

<style lang="scss">
.form-emails textarea {
  min-height: 200px !important
}
</style>
