<template>
  <label class="checkBox">
    <input
      v-model="localValue"
      v-bind="$attrs"
      class="checkBox_input"
      type="checkbox"
      :name="name"
      @change="$emit('update:modelValue', modelValue)"
    >
    <span v-if="!hideLabel">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [
        String,
        Boolean,
      ],
      required: true,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    const truthyValues = [
      true,
      'True',
      'Yes',
      'On',
    ];

    return {
      inputValue: truthyValues.includes(this.modelValue),
      inputValueRaw: this.valueRaw,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkBox_input {
  cursor: pointer;
}
</style>
