<template>
  <div class="kn-records-nav">
    <div
      v-if="showInterceptor"
      class="click-interceptor"
    />

    <FilterMenu
      v-if="recordsNavMixin_displaySettings.filterMenu"
      :filter-menu-links="filterMenuLinks"
    />
    <KeywordSearch
      v-if="recordsNavMixin_displaySettings.keywordSearch"
      :search="recordsNavMixin_localSearch"
      @search="onKeywordSearch"
    />
    <div
      class="level"
      style="justify-content: space-between; flex-grow: 1;"
    >
      <div class="level-left">
        <PaginationRange
          v-if="recordsNavMixin_displaySettings.paginationRange && showPaginationRange"
          :current-page="Number(recordsNavMixin_localCurrentPage)"
          :records-per-page="Number(recordsNavMixin_localRecordsPerPage)"
          :total-records="Number(totalRecords)"
        />
        <ExportButton v-if="recordsNavMixin_displaySettings.exportButton" />

        <slot />

        <Filters
          v-if="recordsNavMixin_displaySettings.filters"
          v-model:filters="recordsNavMixin_localFilters"
          :object-key="objectKey"
        />
      </div>
      <Pagination
        v-if="recordsNavMixin_displaySettings.pagination"
        v-model:records-per-page="recordsNavMixin_localRecordsPerPage"
        v-model:current-page="recordsNavMixin_localCurrentPage"
        :total-pages="totalRecordsPages"
        :allow-limit="allowLimit"
      />
    </div>
  </div>
</template>

<script>
import ExportButton from '@/components/renderer/shared/ExportButton';
import PaginationRange from '@/components/renderer/shared/PaginationRange';
import Filters from '@/components/renderer/shared/Filters';
import FilterMenu from '@/components/renderer/shared/FilterMenu';
import Pagination from '@/components/renderer/shared/Pagination';
import KeywordSearch from '@/components/renderer/shared/KeywordSearch';
import RecordsNav from '@/components/renderer/mixins/RecordsNavMixin';

export default {
  components: {
    ExportButton,
    PaginationRange,
    Filters,
    FilterMenu,
    Pagination,
    KeywordSearch,
  },
  mixins: [
    RecordsNav,
  ],
  props: {
    filterMenuLinks: {
      type: Array,
      default: () => [],
    },
    showInterceptor: {
      type: Boolean,
      default: true,
    },
    showPaginationRange: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onKeywordSearch(value) {
      this.recordsNavMixin_localSearch = value;
      this.recordsNavMixin_triggerUpdate();
    },
  },
};
</script>

<style lang="scss">
.kn-records-nav {
  position: relative;
  margin: 7px;

  .kn-keyword-search {
    margin-bottom: .5em;
  }
}
</style>
