<template>
  <RouterLink
    v-slot="{ isActive, navigate }"
    :to="`${editLink}/task`"
    custom
  >
    <div
      class="tile taskListItem bg-muted m-0 py-1 pl-3 pr-2 rounded-lg border-0 hover:bg-brand-50 h-10 shadow-none"
      :class="{'router-link-active': isActive}"
      data-cy="task-list-item"
      v-bind="$attrs"
      @click="navigate"
    >
      <div
        class="tile_type taskListItem_type"
        :class="{ 'hidden-icon': !showCalendarIcon }"
      >
        <Icon
          v-if="showCalendarIcon"
          class="text-subtle"
          type="calendar"
        />
        <div class="field-title text-default ml-2 text-xs">
          {{ task.schedule.repeat }}
        </div>
      </div>

      <div class="tile_name">
        <label
          class="truncate text-emphasis text-sm leading-4 font-medium mb-0"
          data-cy="task-list-item-name"
        >
          {{ task.name }}
        </label>
      </div>

      <div
        v-if="isPaused"
        v-tippy
        content="This task is currently not running. Click to activate..."
        class="tile_button taskListItem_paused paused button-square bg-transparent hover:bg-brand-100 px-1"
        :class="{ 'hidden-label': !showPausedLabel }"
      >
        <Icon
          class="text-subtle"
          type="pause"
        />
        <label
          v-if="showPausedLabel"
          class="text-default mb-0 ml-1"
        >
          Paused
        </label>
      </div>

      <Popover placement="bottom-end">
        <template #trigger>
          <a
            v-tippy
            content="Settings, history, run"
            class="tile_button button-square bg-transparent hover:bg-brand-100"
            data-cy="task-list-item-menu"
            @click.stop.prevent
          >
            <Icon type="more-horiz" />
          </a>
        </template>

        <template #content>
          <div class="links">
            <RouterLink
              v-close-popper
              :to="`${editLink}/task`"
              data-cy="task-settings"
              class="hover:text-emphasis"
              :class="taskOptionsListItemClasses"
            >
              <Icon
                class="text-inherit"
                type="settings"
              /> Settings
            </RouterLink>
            <RouterLink
              v-close-popper
              :to="`${editLink}/action`"
              data-cy="task-ction"
              class="hover:text-emphasis"
              :class="taskOptionsListItemClasses"
            >
              <Icon
                class="text-inherit"
                type="action-task"
              /> Action
            </RouterLink>
            <RouterLink
              v-close-popper
              :to="`${editLink}/history`"
              data-cy="task-history"
              class="hover:text-emphasis"
              :class="taskOptionsListItemClasses"
            >
              <Icon
                class="text-inherit"
                type="history"
              /> History
            </RouterLink>
            <RouterLink
              v-close-popper
              :to="`${editLink}/run`"
              data-cy="task-run"
              class="hover:text-emphasis"
              :class="taskOptionsListItemClasses"
            >
              <Icon
                class="text-inherit"
                type="play-run-arrow"
              /> Run
            </RouterLink>
            <RouterLink
              v-close-popper
              :to="`${editLink}/delete`"
              data-cy="task-delete"
              class="text-destructive hover:text-destructive"
              :class="taskOptionsListItemClasses"
            >
              <Icon
                class="text-inherit"
                type="delete"
              /> Delete
            </RouterLink>
          </div>
        </template>
      </Popover>
    </div>
  </RouterLink>
</template>
<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';
import Task from '@/store/models/Task';

const breakpoint1 = 600;

export default {
  components: {
    Icon,
    Popover,
  },
  inheritAttrs: false,
  props: {
    task: {
      type: Task,
      required: true,
    },
  },
  computed: {
    ...mapGetters('ui', [
      'rightBodyWidth',
    ]),
    backLink() {
      return `/tasks/objects/${this.$route.params.objectKey}`;
    },
    editLink() {
      return `${this.backLink}/${this.task.key}`;
    },
    dateTimeIcon() {
      return {
        type: 'date_time',
      };
    },
    taskUrl() {
      return `${this.backLink()}/${this.task.id}`;
    },
    isPaused() {
      return this.task.runStatus === 'paused';
    },
    showPausedLabel() {
      return this.isPaused && this.rightBodyWidth > breakpoint1;
    },
    showCalendarIcon() {
      return this.rightBodyWidth > breakpoint1;
    },
    taskOptionsListItemClasses() {
      return 'rounded hover:bg-brand-50 text-base text-default';
    },
  },
};
</script>

<style lang="scss">
.taskListItem {
  margin-bottom: .5em;
}

.taskListItem_type {
  svg {
    width: 16px;
    height: 16px;

    path {
      fill: $gray500;
    }
  }
}

// Need !important to not be overriden by .square-button's padding
.taskListItem_paused {
  padding: 0 .5rem !important;

  @include font-caption;
  color: $gray600;

    &:hover {
      color: $primary500 !important;
    }

  svg {
    margin-right: .25rem !important;
  }
}

.tooltip.delete-popover {
  border: 1px solid #e6e8ec;
  border-radius: .25em;

  .popover-inner {
    background-color: #fefefe;
    border-radius: .125em;
  }
}
</style>

<style lang="scss" scoped>
  .tile_type.hidden-icon {
    flex-basis: unset;
    margin-right: 1em;
  }

  .tile_name {
    white-space: nowrap;
    overflow: hidden;
    flex-grow: 1;
  }

  .taskListItem_paused {

    &.hidden-label {
      padding: 0 !important;

      svg {
        margin-right: 0 !important;
      }
    }
  }
</style>
