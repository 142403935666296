<template>
  <ViewToolbox
    back-title="page"
    title="Edit Grid"
  >
    <div>
      <ToolboxLinks :items="items" />
    </div>
  </ViewToolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ToolboxLinks from '@/components/layout/ToolboxLinks';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'TableTOC',
  components: {
    ViewToolbox,
    ToolboxLinks,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    items() {
      return [
        {
          title: 'Source',
          copy: 'which records to display',
          link: 'source',
          filters: this.activeView?.hasSourceFilters?.(),
        },
        {
          title: 'Settings',
          copy: 'filters, column summaries, and more',
          link: 'settings',
        },
        {
          title: 'Actions & Column Fields',
          copy: 'for fields and links',
          link: 'columns',
          featureId: 'add_columns_button',
        },
      ];
    },
  },
};
</script>
