class SchemaUtils {
  columnDefaults() {
    return {
      grouping: false,
      group_sort: 'asc',
      ignore_edit: false,
      ignore_summary: false,
      conn_separator: '',
      conn_link: '',
      link_type: 'text',
      link_text: '',
      link_field: '',
      link_design: undefined,
      link_design_active: false,
      icon: {
        icon: '',
        align: 'left',
      },
      img_gallery: '',
      width: {
        type: 'default',
        units: 'px',
        amount: '50',
      },
      align: 'left',
      rules: [],
    };
  }

  inputDefaults(type = 'short_text') {
    const defaults = {
      instructions: '',
    };

    if (type === 'connection') {
      defaults.source = {
        filters: [],
      };
    }

    return defaults;
  }

  detailDefaults() {
    return {
      conn_separator: '',
      conn_link: '',
      link_type: 'text',
      link_text: '',
      link_field: '',
      link_design: undefined,
      link_design_active: false,
      icon: {
        icon: '',
        align: 'left',
      },
      img_gallery: '',
      show_map: false,
      map_width: 400,
      map_height: 300,
      value: '',
      copy: '',
      type: 'field',
      format: {
        label_custom: false,
        label_format: 'left',
        styles: [],
      },
    };
  }

  searchDefaults() {
    return {
      ignore_operators: false,
      operator: 'is any',
      operator_default: 'contains',
      multi_type: 'one',
      multi_input: 'chosen',
      multi_match: 'or',
    };
  }
}

export default new SchemaUtils();
