<template>
  <ul vue:is="parentElement">
    <slot
      v-for="(item, itemIndex) in items"
      :item-index="itemIndex"
      :item="item"
    />
  </ul>
</template>

<script>
import { Sortable } from '@shopify/draggable';
import hasIn from 'lodash/hasIn';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    draggable: {
      type: String,
      default: 'li',
    },
    parentElement: {
      type: String,
      default: 'ul',
    },
    canSort: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['sortDrag', 'sort'],
  data() {
    return {
      sortable: null,
    };
  },
  mounted() {
    if (this.canSort !== true) {
      return;
    }

    this.$nextTick(() => {
      this.sortable = new Sortable(this.$el, {
        draggable: this.draggable,
        distance: 5, // prevents click conflicts
        mirror: {
          constrainDimensions: true,
        },
        classes: {
          'source:dragging': 'is-dragging',
        },
      });

      this.sortable.on('sortable:start', (event) => {
        if (hasIn(event, 'data.dragEvent.originalEvent.target.attributes.ignore-drag')) {
          return event.cancel();
        }
      });

      // Update the items on every position change due to dragging
      this.sortable.on('sortable:sorted', (event) => {
        const sortedItem = this.items[event.data.oldIndex];

        this.items.splice(event.data.oldIndex, 1);
        this.items.splice(event.data.newIndex, 0, sortedItem);

        this.$emit('sortDrag', event, this.items);
      });

      // Dragging has emit final sort
      this.sortable.on('sortable:stop', (event) => this.$emit('sort', event, this.items));
    });
  },
  beforeUnmount() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  },
};
</script>
