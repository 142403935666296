<template>
  <div class="rule-email-action mt-0">
    <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Email to send</label>

    <div class="bg-transparent rounded-lg p-0">
      <form>
        <!-- FROM -->
        <div>
          <div class="sub-form">
            <div class="block">
              <span class="label text-default text-sm font-medium mb-2 tw-toolbox-label">
                From
              </span>
              <input
                v-model="item.email.from_name"
                type="text"
              >
            </div>
            <div class="block">
              <span class="label text-default text-sm font-medium mb-2 tw-toolbox-label">
                Email
              </span>
              <input
                v-model="item.email.from_email"
                type="email"
                @keydown.space.prevent
              >
            </div>
          </div>
        </div>

        <!-- EMAIL RECIPIENTS -->
        <div class="email-recipients">
          <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Recipients</label>
          <ActionList
            v-model:items="item.email.recipients"
            :default-item="getDefaultRecipient"
            scope-name="recipient"
            class="naked"
          >
            <template #default="{recipient}">
              <div class="recipient-item">
                <select
                  v-model="recipient.recipient_mode"
                  name="recipient_mode"
                  class="text-base py-2 pl-3 leading-5"
                  style="max-width: 90px;"
                >
                  <option value="to">
                    To:
                  </option>
                  <option value="cc">
                    Cc:
                  </option>
                  <option value="bcc">
                    Bcc:
                  </option>
                  <option value="reply_to">
                    Reply-To:
                  </option>
                </select>
                <select
                  v-model="recipient.recipient_type"
                  name="recipient_type"
                  class="text-base py-2 pl-3 leading-5"
                  style="max-width: 135px;"
                >
                  <option value="custom">
                    custom address
                  </option>
                  <option
                    v-if="emailFieldOptions.length"
                    value="field"
                  >
                    email field
                  </option>
                </select>
                <input
                  v-if="recipient.recipient_type === 'custom'"
                  v-model="recipient.email"
                  type="text"
                  @keydown.space.prevent
                >
                <select
                  v-if="recipient.recipient_type === 'field'"
                  v-model="recipient.field"
                  class="text-base py-2 pl-3 leading-5"
                >
                  <option
                    v-for="emailField in emailFieldOptions"
                    :key="emailField.value"
                    :value="emailField.value"
                  >
                    {{ emailField.label }}
                  </option>
                </select>
              </div>
            </template>
          </ActionList>
        </div>

        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Subject</label>
          <div class="email-subject">
            <TextInput
              :model-value="emailSubject"
              class="input kn-input"
              :input-classes="['rounded-r-none', 'border-r-none']"
              :has-fields-list="true"
              @update:modelValue="onUpdateSubject"
            />
            <div class="email-subject__fields-list bg-transparent w-[76px]">
              <FieldsListDropdown
                class="rich_text-button h-10 rounded-r-lg bg-subtle border border-solid border-default border-l-0  w-full inline-flex justify-center items-center pl-1.5"
                :show-connection-fields="false"
                :object="object"
                :text-button="false"
                @click="handleFieldClick($event)"
              />
            </div>
          </div>
        </div>
        <div class="mb-2">
          <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Message</label>
        </div>
        <div class="width-full">
          <RichText
            v-model="item.email.message"
            :object="object"
            class="rounded-lg"
            :has-fields-list="true"
            :show-connection-fields="false"
            :field-list-row="isToolboxExpanded ? 2 : 3"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionList from '@/components/ui/lists/ActionList';
import RichText from '@/components/renderer/form/inputs/RichText';
import TextInput from '@/components/builder/inputs/Text';
import FieldsListDropdown from '@/components/ui/lists/FieldsListDropdown';
import { FieldTemplateConverter } from '@/util/FieldTemplates';
import Api from '@/lib/api-wrapper';

export default {
  name: 'EmailAction',
  components: {
    ActionList,
    FieldsListDropdown,
    TextInput,
    RichText,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      redactorOptions: {
        autoresize: true,
        removeComments: true,
        buttons: [
          'html',
          'formatting',
          'bold',
          'italic',
          'deleted',
          'link',
          'unorderedlist',
          'orderedlist',
          'outdent',
          'indent',
        ],
        plugins: [
          'fontcolor',
          'image_web_link',
        ],
      },
      // a convertor util that uses regex to replace field keys with names and vice versa in the rich text content
      fieldConvertor: null,
    };
  },
  computed: {
    ...mapGetters([
      'app',
      'isToolboxExpanded',
    ]),
    emailSubject: {
      get() {
        return this.fieldConvertor.replaceFieldKeysWithNames(this.item.email.subject);
      },
      set(newValue) {
        this.item.email.subject = this.fieldConvertor.replaceFieldNamesWithKeys(newValue);
      },
    },
    object() {
      if (this.$store.getters.activeView?.source?.object) {
        return this.$store.getters.getObject(this.$store.getters.activeView.source.object);
      }

      if (this.$store.getters.activeObject) {
        return this.$store.getters.activeObject;
      }

      return {};
    },
    emailFieldOptions() {
      return this.object.getEmailFieldOptions();
    },
  },
  watch: {
    'item.email.from_email': {
      handler: function removeSpacesHandler() {
        if (!this.item.email.from_email || this.item.email.from_email.indexOf(' ') === -1) {
          return;
        }
        this.item.email.from_email = this.item.email.from_email.replaceAll(' ', '');
      },
    },
    'item.email.recipients': {
      deep: true,
      handler: function removeSpacesHandler() {
        if (!this.item?.email?.recipients) {
          return;
        }

        this.item.email.recipients.forEach((recipient) => {
          if (!recipient.email || recipient.email.indexOf(' ') === -1) {
            return;
          }
          recipient.email = recipient.email.replaceAll(' ', '');
        });
      },
    },
  },
  created() {
    // set defaults
    this.item.email = Object.assign(this.getDefaultItem().email, this.item.email);

    this.fieldConvertor = new FieldTemplateConverter(this.object);
  },
  methods: {
    getDefaultItem() {
      return {
        action: 'email',
        email: {
          from_name: '',
          from_email: this.getDefaultSenderEmail(),
          subject: 'Your Subject',
          message: 'Your message',
          recipients: [
            this.getDefaultRecipient(),
          ],
        },
        criteria: [],
      };
    },

    getDefaultRecipient() {
      return {
        recipient_mode: 'to',
        recipient_type: 'custom',
        email: this.getDefaultSenderEmail(),
        field: this.emailFieldOptions.length ? this.emailFieldOptions[0].value : null,
      };
    },

    getDefaultSenderEmail() {
      // use app email address if set, otherwise use builder email
      return this.app.settings.from_email || Api.email || '';
    },

    onAddListItem(event) {
      return this.rules.push(this.getDefaultItem());
    },

    onAddSendCriteria(item) {
      if (!item.criteria) {
        item.criteria = [];
      }

      item.criteria.push({
        field: this.object.fields[0].key,
        operator: 'contains',
        value: '',
      });
    },

    onDeleteSendCriteria($event, itemIndex, criteriaIndex) {
      return this.rules[itemIndex].criteria.splice(criteriaIndex, 1);
    },

    onAddRecipient(event, itemIndex) {
      return this.rules[itemIndex].email.recipients.push({
        recipient_mode: 'to',
        recipient_type: 'custom',
        email: 'brandon@knack.com',
      });
    },

    onDeleteRecipient($event, itemIndex, recipientIndex) {
      return this.rules[itemIndex].email.recipients.splice(recipientIndex, 1);
    },

    onUpdateSubject(newSubject) {
      this.emailSubject = newSubject;
    },

    handleFieldClick(interpolatedFieldName) {
      this.emailSubject = `${this.emailSubject} ${interpolatedFieldName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.rule-email-action {
  textarea {
    min-height: 200px !important;
  }

  .recipient-item {
    flex-grow: 1;

    > * {
      margin-right: 5px;
    }
  }
}

.rule-email-action > div > form {
  margin-bottom: 1em;

  .sub-form > div {
    margin-bottom: .5em;
  }
}

.email-subject {
  position: relative;
  display: flex;
  align-content: stretch;
  align-items: stretch;

  .input-wrapper {
    width: 100%;
  }
}

.email-subject__fields-list {
  background-color: #ededed;
  border-top-right-radius: .3em;
  border-bottom-right-radius: .3em;
  height: 100%;
  display: flex;
  align-items: center;

  :deep(.button-wrapper) {
    height: 31px;
  }
}
</style>
