import TOC from '@/components/views/map/TOC';
import Settings from '@/components/views/map/Settings';
import Design from '@/components/views/map/Design';
import Source from '@/components/views/map/Source';
import Details from '@/components/views/map/Details';
import DetailProperties from '@/components/views/map/DetailProperties';

export default [
  {
    path: ':pageKey/views/:viewKey/map',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/map/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/map/design',
    components: {
      toolbox: Design,
    },
  },
  {
    path: ':pageKey/views/:viewKey/map/source',
    components: {
      toolbox: Source,
    },
  },
  {
    path: ':pageKey/views/:viewKey/map/list/',
    components: {
      toolbox: Details,
    },
  },
  {
    path: ':pageKey/views/:viewKey/map/list/layouts/:layoutIndex/groups/:groupIndex/columns/:columnIndex/items/:itemIndex',
    components: {
      toolbox: DetailProperties,
    },
  },
];
