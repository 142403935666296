<template>
  <form class="form-modal full-width non-filters small-labels">
    <div
      v-if="field"
      id="field"
      class="view-input mb-4"
    >
      <label class="text-default text-sm font-medium leading-4 tracking-[0.14px]">Field</label>
      <span class="field-name form-read-only flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis mt-2 text-sm">
        {{ field.name }}
      </span>
    </div>

    <div class="view-input mb-4">
      <label class="text-default text-sm font-medium leading-4 tracking-[0.14px] mb-2">Label</label>
      <input
        v-model="localGroup.label"
        name="label"
        type="text"
      >
    </div>

    <div
      v-if="showSort"
      class="mb-4"
    >
      <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] mb-2">Group Order</label>
      <select
        v-model="localSort"
        class="text-base py-2 pl-3 leading-5"
      >
        <option value="asc">
          {{ field.getSortAscending() }}
        </option>
        <option value="desc">
          {{ field.getSortDescending() }}
        </option>
      </select>
    </div>

    <template v-if="showDate">
      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] mb-2">Group dates by</label>
        <select
          v-model="localGroup.range"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="day">
            Day
          </option>
          <option value="week">
            Week
          </option>
          <option value="month">
            Month
          </option>
          <option value="year">
            Year
          </option>
        </select>
      </div>

      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] mb-2">Show</label>
        <div class="multiple-inputs level-left">
          <select
            v-model="localGroup.limit"
            class="text-base py-2 pl-3 leading-5"
          >
            <option value="all">
              All dates
            </option>
            <option value="number">
              Latest number
            </option>
            <option value="from">
              From
            </option>
            <option value="from_to">
              From-to
            </option>
          </select>

          <div v-if="localGroup.limit === `number`">
            <input
              v-model="localGroup.number"
              type="text"
            >
          </div>

          <Datepicker
            v-if="localGroup.limit === `from` || localGroup.limit === `from_to`"
            v-model="localGroup.date_from"
            :typeable="true"
            format="MM/DD/YYYY"
          />

          <template v-if="localGroup.limit === `from_to`">
            <span style="vertical-align: center;">&nbsp;to&nbsp;</span>
            <Datepicker
              v-model="localGroup.date_to"
              type="text"
              :typeable="true"
              format="MM/DD/YYYY"
            />
          </template>
        </div>
      </div>
    </template>

    <div
      v-if="showFilters"
      class="mb-0"
      :class="{ 'mb-4': localGroup.filters && localGroup.filters.length }"
    >
      <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] mb-2">Filters</label>
      <FieldList
        v-if="localGroup.filters && localGroup.filters.length"
        :object-key="filterObjectKey"
        :items="localGroup.filters"
        :can-be-empty="true"
        class="is-contained is-grouped bg-transparent"
        @update:items="onUpdateFilters"
      />
      <div v-else>
        <a
          class="text-link p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 text-base no-underline capitalize group inline-flex items-center"
          @click="onAddFilters"
        >
          <Icon
            class="text-default h-4 w-4 mr-2 group-hover:text-brand-400"
            type="plus-thin"
          />
          Add rules to filter records
        </a>
      </div>
    </div>

    <div class="submit-buttons flex justify-end">
      <a
        class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium h-10"
        @click="$emit('submit', localGroup, field)"
      >
        Submit
      </a>
    </div>
  </form>
</template>

<script>
import hasIn from 'lodash/hasIn';

import Datepicker from '@/components/ui/inputs/datepicker/Datepicker';
import FieldList from '@/components/ui/lists/FieldList';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Datepicker,
    FieldList,
    Icon,
  },
  props: {
    group: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    objectKey: {
      type: String,
      default: '',
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    showSort: {
      type: Boolean,
      default: false,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'submit',
    'update:modelValue',
  ],
  computed: {
    localGroup: {
      get() {
        return this.group;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    localSort: {
      get() {
        return this.localGroup.sort || 'asc';
      },
      set(newVal) {
        this.localGroup.sort = newVal;
      },
    },
    filterObjectKey() {
      if (hasIn(this.field, 'type') && this.field.type === 'connection') {
        return this.field.relationship.object;
      }

      return this.objectKey;
    },
  },
  methods: {
    onAddFilters() {
      const object = this.$store.getters.getObject(this.filterObjectKey);

      this.localGroup.filters = [
        object.fields[0].getDefaultRule(),
      ];
    },
    onUpdateFilters(newFilters) {
      this.localGroup.filters = newFilters;
    },
  },
};
</script>
