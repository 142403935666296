import Builder from '@/components/Builder';
import Pages from '@/components/pages/Pages';
import PagesNav from '@/components/pages/PagesNav';
import PageAdd from '@/components/pages/PageAdd';
import PageCopy from '@/components/pages/page/PageCopy';
import PageDelete from '@/components/pages/page/PageDelete';
import PageLogin from '@/components/pages/page/PageLogin';
import PageRules from '@/components/pages/page/PageRules';
import PageSettings from '@/components/pages/page/PageSettings';
import ViewAdd from '@/components/pages/page/ViewAdd';
import PageEmbed from '@/components/settings/embeds/EmbedSettings';
import ViewCopyMove from '@/components/views/ViewCopyMove';
import ViewDelete from '@/components/views/ViewDelete';
import CalendarRoutes from '@/router/views/calendar';
import DetailsRoutes from '@/router/views/details';
import DividerRoutes from '@/router/views/divider';
import FormRoutes from '@/router/views/form';
import ImageRoutes from '@/router/views/image';
import LoginRoutes from '@/router/views/login';
import ListRoutes from '@/router/views/list';
import MapRoutes from '@/router/views/map';
import MenuRoutes from '@/router/views/menu';
import ReportRoutes from '@/router/views/report';
import SearchRoutes from '@/router/views/search';
import TableRoutes from '@/router/views/table';
import CheckoutRoutes from '@/router/views/checkout';
import RichText from '@/components/views/richtext/RichText';
import PagePreview from '@/components/pages/PagePreview';

export default [
  {
    path: '/pages',
    component: Builder,
    children: [
      {
        path: '',
        component: Pages,
        children: [
          {
            path: '',
            components: {
              toolbox: PagesNav,
            },
          },
          {
            path: 'add/:addType', // public | login | menu | user | option
            components: {
              toolbox: PageAdd,
            },
            props: {
              toolbox: true,
            },
          },
          {
            path: ':pageKey',
            components: {
              toolbox: PagesNav,
            },
            meta: {
              widthClass: 'builderLayout_toolbox-large',
            },
          },
          {
            path: ':pageKey/settings',
            components: {
              toolbox: PagesNav,
              modal: PageSettings,
            },
          },
          {
            path: ':pageKey/delete',
            components: {
              toolbox: PagesNav,
              modal: PageDelete,
            },
          },
          {
            path: ':pageKey/rules',
            components: {
              toolbox: PageRules,
            },
          },
          {
            path: ':pageKey/copy',
            components: {
              toolbox: PagesNav,
              modal: PageCopy,
            },
          },
          {
            path: ':pageKey/publish',
            components: {
              toolbox: PagesNav,
              modal: PageEmbed,
            },
          },
          {
            path: ':pageKey/login',
            components: {
              toolbox: PagesNav,
              modal: PageLogin,
            },
          },
          {
            path: ':pageKey/views/add',
            components: {
              toolbox: ViewAdd,
            },
          },
          {
            path: ':pageKey/views/:viewKey/copy',
            components: {
              toolbox: PagesNav,
              modal: ViewCopyMove,
            },
            props: {
              modal: {
                action: 'copy',
              },
            },
          },
          {
            path: ':pageKey/views/:viewKey/move',
            components: {
              toolbox: PagesNav,
              modal: ViewCopyMove,
            },
            props: {
              modal: {
                action: 'move',
              },
            },
          },
          {
            path: ':pageKey/views/:viewKey/delete',
            components: {
              toolbox: PagesNav,
              modal: ViewDelete,
            },
          },
          ...CalendarRoutes,
          ...DetailsRoutes,
          ...DividerRoutes,
          ...FormRoutes,
          ...ImageRoutes,
          ...ListRoutes,
          ...LoginRoutes,
          ...MapRoutes,
          ...MenuRoutes,
          ...ReportRoutes,
          ...SearchRoutes,
          ...TableRoutes,
          ...CheckoutRoutes,
          {
            path: ':pageKey/views/:viewKey/rich_text',
            components: {
              toolbox: RichText,
            },
          },
        ],
      },
      {
        path: '/live-app-preview',
        component: PagePreview,
      },
      {
        path: ':pageKey/live-app-preview/:pageSlug',
        component: PagePreview,
      },
    ],
  },
];
