<template>
  <FormWrapper
    class="kn-keyword-search"
  >
    <p
      class="kn-layout-horizontal"
      data-cy="keyword-search"
    >
      <input
        v-model="searchLocal"
        name="keyword"
        class="input"
        type="text"
        :placeholder="trans('Search by keyword')"
      >
      <a
        class="kn-button search"
        @click="onSearch"
      >
        {{trans('Search')}}
      </a>
      <span
        v-if="showReset"
        class="reset-container"
      >
        <a
          class="reset kn-button is-link"
          @click="onReset"
        >
          reset
        </a>
      </span>
    </p>
  </FormWrapper>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';
import FormWrapper from '@/components/ui/FormWrapper';
// TODO: questionable component, why should Text have submission/reset responsibility?
import TextInput from '@/components/builder/inputs/Text';

export default {
  components: {
    FormWrapper,
    TextInput,
  },
  mixins: [
    TranslationUtils,
  ],
  props: [
    'search',
  ],
  emits: [
    'search',
  ],
  data() {
    return {
      searchLocal: this.search,
    };
  },
  computed: {
    showReset() {
      return !isEmpty(this.search);
    },
  },
  watch: {
    search(newVal) {
      this.searchLocal = newVal;
    },
  },
  methods: {
    onSearch() {
      this.$emit('search', this.searchLocal);
    },
    onReset() {
      this.searchLocal = '';

      this.onSearch();
    },
  },
};
</script>

<style lang="scss">
.kn-keyword-search {

  // TODO remove this when renderer spacing is brought in
  .kn-layout-horizontal {
    margin-bottom:0;
  }
}

span.reset-container {
  padding-left: 4px;
}
</style>
