<template>
  <div>
    <div
      class="kn-login-wrapper"
      :class="viewClass"
    >
      <div class="click-interceptor" />

      <form>
        <div class="kn-input">
          <label>Email</label>
          <input type="text">
        </div>
        <div class="kn-input">
          <label>Password</label>
          <input type="text">
        </div>
        <div class="kn-submit">
          <button
            type="submit"
            class="kn-button is-primary"
          >
            Login
          </button>
        </div>
      </form>

      <!-- Registration Links -->
      <div
        v-if="canRegister"
        class="register"
      >
        <div
          ref="childLinks"
          class="child-links"
        />

        <div
          id="need-account"
          class="control kn-inputWrapper "
        >
          <span class="kn-label">
            {{ trans('Need an account?') }}
          </span>

          <div class="kn-register-links control kn-inputWrapper ">
            <div
              v-for="(link, linkIndex) in registerLinks"
              :key="linkIndex"
              class="kn-register-link"
            >
              <a
                class="kn-button"
                :href="sceneHash + 'knack-register/' + link.url"
              >
                {{ link.label }}
              </a>
              <ViewChildLink
                :tooltip="typeof view.get(`registration_forms`)[link.url] === `string` ? `Edit this registration form` : `Save this login to access this registration form.`"
                top="5px"
                :url="typeof view.get(`registration_forms`)[link.url] === `string` ? `/pages/${page.key}/views/${view.get(`registration_forms`)[link.url]}/form` : ``"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewChildLink from '@/components/renderer/shared/ViewChildLink';
import RendererUtils from '@/components/renderer/RenderUtils.js';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';

export default {
  components: {
    ViewChildLink,
  },
  mixins: [
    RendererUtils,
    TranslationUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'roleObjects',
    ]),
    viewClass() {
      return {};
    },
    canRegister() {
      return (this.view.get('registration_type') !== 'closed');
    },
    registerLinks() {
      const pageLink = `#pages/${this.page.key}/views/`;

      if (this.isLimitedAccess === false) {
        const viewKey = this.view.get('registration_forms').all_users;

        return [
          {
            url: 'all_users',
            label: this.trans('Sign Up'),
            viewLink: pageLink + viewKey,
          },
        ];
      }

      if (this.allowedRoles.length === 1) {
        log('reg forms', this.view.get('registration_forms'), this.view);

        const viewKey = this.view.get('registration_forms')[this.view.get('allowed_profiles')[0]];

        return [
          {
            url: this.view.get('allowed_profiles')[0],
            label: this.trans('Sign Up'),
            viewLink: pageLink + viewKey,
          },
        ];
      }

      const links = [];

      this.allowedRoles.forEach((role) => {
        const viewKey = this.view.get('registration_forms')[role.get('profile_key')];

        links.push({
          url: role.get('profile_key'),
          label: `${this.trans('Sign up as ')} ${role.get('inflections').singular}`,
          viewLink: pageLink + viewKey,
        });
      });

      return links;
    },
    isLimitedAccess() {
      return (this.view.get('allowed_profiles') && this.view.get('allowed_profiles').length > 0 && this.view.get('limit_profile_access'));
    },
    allowedRoles() {
      log('allowedRoles()', this.roleObjects, this.view.get('allowed_profiles'), this.view);

      return this.roleObjects.filter((role) => (this.view.get('allowed_profiles').indexOf(role.get('profile_key')) > -1));
    },
  },
};
</script>

<style lang="scss">
.kn-login-wrapper {
  position: relative;
  min-width: 400px;
}

.kn-login-wrapper form {
  max-width: 500px;
}

.kn-register-link {
  position: relative;
  display: inline;
  padding-right: 23px; // used to offset the orange link to access the child form view
  margin-right: 12px;
}
</style>
