<template>
  <view-toolbox
    back-title="page"
    title="Edit List"
  >
    <div>
      <toc-links :items="items" />
    </div>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import TocLinks from '@/components/views/TocLinks';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'DetailsTOC',
  components: {
    ViewToolbox,
    TocLinks,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    items() {
      return [
        {
          title: 'Source',
          copy: 'which records to display',
          link: 'source',
          filters: this.activeView?.hasSourceFilters?.(),
        },
        {
          title: 'List Settings',
          copy: 'options and settings',
          link: 'settings',
        },
        {
          title: 'Actions & Fields',
          copy: 'add fields and links',
          link: 'items',
        },
      ];
    },
  },
};
</script>
