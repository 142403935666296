<template>
  <div class="iconpicker">
    <Modal
      v-if="isEditing"
      title="Edit Icon"
      :z-index="2"
      @close="isEditing = false"
    >
      <form
        class="horizontal-labels small"
        style="margin-bottom: 1em;"
      >
        <div
          v-if="icon.icon"
          style="align-items: center;"
        >
          <label style="flex: 0 1 100px;">
            Current Icon
          </label>
          <div class="level">
            <FontAwesome :name="icon.icon" />&nbsp;{{ icon.icon }}
          </div>
        </div>

        <div v-if="!ignoreAlignment">
          <label
            class="pr-0 mr-2 flex-none"
            :style="{justifyContent: (icon.icon) ? 'flex-end' : 'flex-start', flex: `0 1 ${icon.icon ? '100px' : '50px'}`}"
          >
            Align
          </label>
          <ChoicePicker
            v-model="icon.align"
            class="small"
            :capitalize="true"
            :choices="['left', 'right']"
          />
        </div>

        <div style="max-width: none;">
          <input
            v-model="filter"
            v-focus
            type="text"
            placeholder="Type to filter"
          >
        </div>
      </form>

      <div class="iconpicker-items">
        <a
          v-for="iconName in filteredIcons"
          :key="iconName"
          v-tippy="{ content: iconName }"
          class="group rounded-lg hover:bg-brand-50"
          :class="{active: `fa-${iconName}` === icon.icon}"
          @click="onClickIcon(iconName)"
        >
          <FontAwesome
            class="text-default group-hover:text-emphasis"
            :name="iconName"
          />
        </a>
      </div>
    </Modal>

    <div
      class="iconpicker-edit level-left gap-2"
      :class="{
        'flex-col items-start': !ignoreEditButton || !ignoreDelete,
        'items-center': ignoreEditButton && ignoreDelete,
      }"
    >
      <template v-if="icon && icon.icon">
        <span
          v-tippy
          :content="`Icon: ${icon.icon}`"
          class="selected-icon border-0 bg-transparent group p-0"
          @click="isEditing = true"
        >
          <FontAwesome
            class="text-2xl text-default group-hover:text-emphasis p-0 leading-none h-6 w-6"
            :name="icon.icon"
          />
        </span>
        <div class="flex gap-2">
          <button
            v-if="!ignoreEditButton"
            type="button"
            :class="buttonClasses"
            @click="isEditing = true"
          >
            Change Icon
          </button>
          <button
            v-if="!ignoreDelete"
            :class="buttonClasses"
            @click="onRemove"
          >
            Remove Icon
          </button>
        </div>
      </template>

      <a
        v-else
        v-tippy
        content="Add an icon to this link"
        style="white-space: nowrap;"
        :class="buttonClasses"
        @click="isEditing = true"
      >
        <Icon
          class="text-default h-4 w-4 ml-0 top-0 mr-2"
          type="add"
        /> Add Icon
      </a>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/ui/Modal';
import Icon from '@/components/ui/Icon';
import ChoicePicker from '@/components/ui/inputs/ChoicePicker';
import FontAwesome from '@/components/ui/FontAwesome';
import { FontAwesomeIcons } from '@/constants/fontAwesome';

export default {
  components: {
    Modal,
    Icon,
    ChoicePicker,
    FontAwesome,
  },
  props: {
    icon: {
      type: Object,
      default: () => ({}),
    },
    ignoreDelete: {
      type: Boolean,
      default: false,
    },
    ignoreEditButton: {
      type: Boolean,
      default: false,
    },
    ignoreAlignment: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:icon',
  ],
  data() {
    return {
      isEditing: false,
      filter: '',
    };
  },
  computed: {
    filteredIcons() {
      if (this.filter.length < 2) {
        return FontAwesomeIcons;
      }

      return FontAwesomeIcons.filter((icon) => icon.includes(this.filter.toLowerCase()));
    },
    buttonClasses() {
      return 'px-3 py-0 h-10 flex items-center rounded-lg border border-solid border-default bg-white text-emphasis text-base hover:bg-brand-50 hover:border-brand-600 leading-4 font-medium';
    },
  },
  methods: {
    onClickIcon(iconName) {
      this.$emit('update:icon', {
        ...this.icon,
        icon: `fa-${iconName}`,
      });

      this.isEditing = false;
    },
    onRemove() {
      this.$emit('update:icon', {
        ...this.icon,
        icon: '',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.iconpicker-items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -.3em;

  a {
    margin: .3em;
    padding: 6px;
    border-radius: .25em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    color: $text;

    svg {
      height: 20px;
      width: 20px;
    }

    &:hover {
      background-color: #eee;
      color: $fuchsia;
    }
  }
}
.iconpicker-edit {
  white-space: nowrap;

  a .icon {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    color: #761c66;
  }

  svg {
    position: relative;
    top: 2px;
  }

  .selected-icon {
    display: inline-block;
    padding: 3px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: .35em;
    width: 28px;
    height: 28px;
    text-align: center;
    color: $text;

    &:hover {
      color: $fuchsia;
    }

    svg {
      display: inline-block;
    }
  }

  .iconpicker-add.icon {
    color: green;
  }
}
</style>
