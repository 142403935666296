import { cloneDeep } from 'lodash';
import { useStore } from 'vuex';
import { computed, watchEffect } from 'vue';

let stopWatcher = null;

export default function useMenuDesignOverrides() {
  const store = useStore();
  const { activeView: view, app } = store.getters;

  // Menu
  const menuDesign = computed(() => view.attributes.menu_links_design);
  const isMenuDesignActive = computed(() => !!view.attributes.menu_links_design_active);
  const setIsMenuDesignActive = (value) => {
    view.attributes.menu_links_design_active = value;

    // if turning on Menu Design for the first time
    if (value && !view.attributes.menu_links_design) {
      view.attributes.menu_links_design = cloneDeep(app.design.general.menus.links);
    }
  };

  // Links
  const links = computed(() => view.attributes.links);
  const isLinkDesignActive = (index) => !!view.attributes.links[index]?.link_design_active;
  const setIsLinkDesignActive = (index, value) => {
    view.attributes.links[index].link_design_active = value;

    // if turning on Link Design for the first time
    if (value && !view.attributes.links[index]?.link_design) {
      if (!!view.attributes.menu_links_design_active && view.attributes.menu_links_design) {
        view.attributes.links[index].link_design = cloneDeep(view.attributes.menu_links_design);
      } else {
        view.attributes.links[index].link_design = cloneDeep(app.design.general.menus.links);
      }
    }
  };

  // Stop current watcher if there is one
  if (stopWatcher) {
    stopWatcher();
  }

  // Watch for changes to menuDesign and isMenuDesignActive and
  // update the design object for each menu link accordingly
  stopWatcher = watchEffect(() => {
    view.attributes.links.forEach((link) => {
      if (!link.link_design) {
        return;
      }

      let baseDesign;
      if (view.attributes.menu_links_design_active
        && view.attributes.menu_links_design
        && link.link_design) {
        baseDesign = view.attributes.menu_links_design;
      } else if (app.design.general.menus.links && link.link_design) {
        baseDesign = app.design.general.menus.links;
      }

      if (baseDesign) {
        link.link_design = {
          buttons: {
            ...baseDesign.buttons,
            colors: { ...link.link_design.buttons.colors },
          },
          tabs: {
            ...baseDesign.tabs,
            colors: { ...link.link_design.tabs.colors },
          },
        };
      }
    });
  });

  return {
    menuDesign,
    links,
    isMenuDesignActive,
    isLinkDesignActive,
    setIsMenuDesignActive,
    setIsLinkDesignActive,
  };
}
