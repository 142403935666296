<template>
  <span
    class="ribbon_divider"
    :class="ribbonSpacingClass"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('ui', [
      'ribbonSpacingClass',
    ]),
  },
};
</script>
