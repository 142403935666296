<template>
  <form data-cy="task-settings">
    <div class="mb-6">
      <label class="text-default text-sm font-medium mb-2 leading-4">Task Name</label>
      <input
        type="text"
        :value="taskName"
        data-cy="name"
        @input="$emit('update:taskName', $event.target.value)"
      >
    </div>
    <div class="mb-6">
      <label class="text-default text-sm font-medium mb-2 leading-4">Schedule</label>
      <div
        class="input-labels tw-input-labels"
        data-cy="repeat"
      >
        <label :class="radioClasses"><input
          v-model="localRepeat"
          class="m-0"
          type="radio"
          value="daily"
        > Daily</label>
        <label :class="radioClasses"><input
          v-model="localRepeat"
          class="m-0"
          type="radio"
          value="weekly"
        > Weekly</label>
        <label :class="radioClasses"><input
          v-model="localRepeat"
          class="m-0"
          type="radio"
          value="monthly"
        > Monthly</label>
      </div>
    </div>
    <div class="mb-6">
      <label class="text-default text-sm font-medium mb-2 leading-4">Next Run Date</label>
      <div class="level">
        <Datepicker
          v-model="localDate"
          class="kn-datepicker input"
          placeholder=""
          :typeable="true"
          :parse-typed-date="parseTypedDateForDatepicker"
          :format="getMomentDateFormatFromKnackDateFormat()"
          :disabled-dates="disablePreviousDatesConfig"
          data-cy="date"
        />
        <TimePicker
          v-model="localTime"
          class="kn-time input"
          :format="getMomentTimeFormatFromKnackTimeFormat()"
          :minute-interval="15"
          data-cy="time"
        />
      </div>
    </div>
    <div class="mb-0">
      <label class="text-default text-sm font-medium mb-2 leading-4">Status</label>
      <div
        class="input-labels tw-input-labels"
        data-cy="status"
      >
        <label :class="radioClasses"><input
          v-model="localStatus"
          class="m-0"
          type="radio"
          value="running"
        >On - this task is running</label>
        <label :class="radioClasses"><input
          v-model="localStatus"
          class="m-0"
          type="radio"
          value="paused"
        >Off - this task is paused</label>
      </div>
    </div>
  </form>
</template>

<script>
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import DateUtils from '@/components/renderer/mixins/DateUtils.js';
import Datepicker from '@/components/ui/inputs/datepicker/Datepicker';
import TimePicker from '@/components/ui/inputs/TimePicker';

export default {
  components: {
    Datepicker,
    TimePicker,
  },
  mixins: [
    DateUtils,
  ],
  props: {
    taskName: {
      type: String,
      default: '',
    },
    repeat: {
      type: String,
      default: 'daily',
    },
    date: {
      type: null,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'running',
    },
  },
  emits: ['update:date', 'update:repeat', 'update:status', 'update:taskName', 'update:time'],
  computed: {
    localRepeat: {
      get() {
        return this.repeat;
      },
      set(newValue) {
        this.$emit('update:repeat', newValue);
      },
    },
    localDate: {
      get() {
        return isEmpty(this.date) ? this.parsedDate() : this.parsedDate(this.date);
      },
      set(newValue) {
        this.$emit('update:date', moment(newValue).format('MM/DD/YYYY'));
      },
    },
    localTime: {
      get() {
        return isEmpty(this.time) ? moment().format(this.getMomentTimeFormatFromKnackTimeFormat()) : this.time;
      },
      set(newValue) {
        this.$emit('update:time', newValue);
      },
    },
    localStatus: {
      get() {
        return this.status;
      },
      set(newValue) {
        this.$emit('update:status', newValue);
      },
    },

    /**
       * Disable previous dates since tasks shouldn't allow selecting them
       * @returns {Object}
       */
    disablePreviousDatesConfig() {
      const yesterday = new Date();

      yesterday.setDate(yesterday.getDate() - 1);

      return {
        to: yesterday,
      };
    },
    radioClasses() {
      return 'text-emphasis text-base leading-4 items-center mb-4 font-normal';
    },
  },
};
</script>

<style lang=scss scoped>
.kn-datepicker {
  margin-right: 1rem;
}
.kn-time {
  flex: 1;
}
</style>
