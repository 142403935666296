import { computed } from 'vue';

import store from '@/store/index';

/**
 * This is a temporary measure because of a bug in vuex 4 where getter values were not cached.
 * @see https://github.com/vuejs/vuex/issues/1878
 */
export const objectsByFieldKey = computed(() => (
  store.getters.objects.reduce((final, object) => {
    object.fields.forEach((field) => {
      final[field.key] = object;
    });

    return final;
  }, {})
));
