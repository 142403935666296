<template>
  <ConfirmModal
    :title="title"
    :classes="[`batchUserModal`]"
    :confirm-text="confirmButtonText"
    :has-cancel="action !== 'alert' && !showConfirmMessage"
    @confirm="onConfirm"
    @close="$emit(`close`)"
  >
    <div v-if="showConfirmMessage">
      <p>{{ confirmationMessage }}</p>
    </div>
    <div v-else>
      <UpdateAccountsStatus
        v-if="action === `update`"
        v-model:accountStatus="accountStatus"
        v-model:sendApprovalEmail="sendApprovalEmail"
      />
      <template v-if="action === `intro`">
        <p>Are you sure you want to email account introductions to the selected users?</p>
        <p class="mb-0">
          An email will be sent containing links to the app and information for managing the user account.
        </p>
      </template>
      <template v-if="action === `reset`">
        <p>Are you sure you want to reset these user passwords?</p>
        <p class="mb-0">
          Their current password will be <strong>deleted</strong> and an email will be sent with a link for the
          user to reset the password.
        </p>
      </template>
      <template v-if="action === 'alert'">
        <p>
          In order for recipients to receive reset password emails, a page with a login view needs to be implemented.
        </p>
        <p class="mb-0">
          Learn more about logins and registrations <a
            href="https://learn.knack.com/article/iqyr40dmbz-logins-and-registrations"
            class="underline text-default"
            target="_blank"
          >here</a>.
        </p>
      </template>
    </div>
  </ConfirmModal>
</template>

<script>
import { mapMutations } from 'vuex';
import { eventBus } from '@/store/bus';
import ConfirmModal from '@/components/ui/ConfirmModal';
import RequestUtils from '@/components/util/RequestUtils';
import UpdateAccountsStatus from '@/components/records/UpdateAccountsStatus';

const ACTIONS = {
  update: {
    title: 'Update Status',
    confirmationMessage: 'Account Status successfully updated!',
  },
  intro: {
    title: 'Email Introductions',
    confirmationMessage: 'Emails have been sent with account intros!',
  },
  reset: {
    title: 'Reset Passwords',
    confirmationMessage: 'Emails have been sent to reset passwords!',
  },
  alert: {
    title: 'Reset Password Alert',
  },
};

/**
   * Modal to execute batch actions on account records
   */
export default {
  components: {
    ConfirmModal,
    UpdateAccountsStatus,
  },
  mixins: [
    RequestUtils,
  ],
  props: {

    /**
       * The action the modal should execute
       * Must be one of: update, intro, reset
       */
    action: {
      type: String,
      default: 'update',
      validator(val) {
        return Object.keys(ACTIONS).includes(val);
      },
    },

    /**
       * The record ids the action should impact
       */
    recordIds: {
      type: Array,
      default: () => [],
    },

    /**
       * The account object the records belong too
       */
    objectKey: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data() {
    return {
      title: ACTIONS[this.action].title,
      confirmationMessage: ACTIONS[this.action].confirmationMessage,
      showConfirmMessage: false,
      accountStatus: 'active',
      sendApprovalEmail: true,
    };
  },
  computed: {
    confirmButtonText() {
      if (this.showConfirmMessage) {
        return 'Continue';
      }
      if (this.action === 'intro') {
        return 'Send Account Info';
      }
      if (this.action === 'reset') {
        return 'Send Emails';
      }
      if (this.action === 'alert') {
        return 'Okay';
      }
      return 'Submit';
    },
  },
  methods: {
    ...mapMutations([
      'setCheckedRecordIds',
    ]),

    /**
       * Sends API request through RequestUtils mixin method `commitRequest`
       * @param {string} ApiRequestFn the string name of the knack api-wrapper method to call
       * @param {Array} data the payload to be sent to the method
       * @param {function} onSuccess the success method to be called by `commitRequest` on a success response
       */
    sendRequest(ApiRequestFn, data, onSuccess) {
      this.commitRequest({
        request: () => window.Knack.Api[ApiRequestFn](...data),
        onSuccess,
      });
    },

    /**
       * Collects selected users ids & selected object key
       * @returns {Array}
       */
    getUserData() {
      const data = [
        this.objectKey,
        [
          ...this.recordIds,
        ],
      ];

      return data;
    },

    /**
       * Shows confirmation message in modal & unchecks records in records table
       */
    showConfirmation() {
      this.showConfirmMessage = true;

      this.setCheckedRecordIds([]);
    },

    /**
       * Method to handle submitting the modal when the action is 'update'
       * Combines modal user data with status data received from UpdateAccountStatus component
       * On request success emits a `recordsUpdate` event on the global event bus to trigger
       * records query in recordsTable
       */
    onUpdateSubmit() {
      const statusData = {
        accountStatus: this.accountStatus,
        sendApprovalEmail: this.sendApprovalEmail && this.accountStatus === 'active',
      };

      const data = [
        ...this.getUserData(),
        statusData,
      ];

      this.sendRequest('updateUsersStatus', data, () => {
        eventBus.$emit('recordsUpdate', this.recordIds);

        this.showConfirmation();
      });
    },

    /**
       * Method to handle submitting the modal when the action is 'intro'
       */
    onIntroSubmit() {
      this.sendRequest('sendUserIntros', this.getUserData(), this.showConfirmation);
    },

    /**
       * Method to handle submitting the modal when the action is 'reset'
       */
    onResetSubmit() {
      this.sendRequest('resetUserPasswords', this.getUserData(), this.showConfirmation);
    },

    onConfirm() {
      if (this.showConfirmMessage) {
        this.$emit('close');
        return;
      }

      if (this.action === 'update') {
        this.onUpdateSubmit();
      } else if (this.action === 'intro') {
        this.onIntroSubmit();
      } else if (this.action === 'reset') {
        this.onResetSubmit();
      } else {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.batchUserModal {
  line-height: 1.2em;
}
</style>
