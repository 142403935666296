<template>
  <ObjectsNav
    nav-context="tasks"
    :show-task-count="true"
  />
</template>

<script>
import ObjectsNav from '@/components/objects/ObjectsNav';

export default {
  components: {
    ObjectsNav,
  },
};
</script>
