<template>
  <div id="import-wizard">
    <!-- source -->
    <ImportSource
      v-if="step ==='source'"
      @submit="onSubmitSource"
    />

    <!-- preview -->
    <div
      v-if="showPreview"
      id="import-preview"
    >
      <div
        id="import-toolbox"
        class="bg-white border-2 border-solid border-t-[#e3e3e3]/20 border-r-[#e3e3e3]/20 border-b-0 border-l-0"
      >
        <!-- headers -->
        <ImportHeaders
          v-if="step === 'headers'"
          v-model:has-header-row="hasHeaderRow"
          v-model:selected-sheet-index="selectedSheetIndex"
          :sheet-names="sheetNames"
          @submit="onSubmitHeaders"
          @back="previousStep"
          @sheet-switched="handleSheetSwitched"
        />

        <!-- match -->
        <ImportMatch
          v-if="step === 'match'"
          v-model:update-records="match.updateRecords"
          v-model:insert-unmatched="match.insertUnmatched"
          v-model:rules="match.rules"
          :columns="columns"
          @submit="onSubmitMatching"
          @back="previousStep"
        />

        <!-- map -->
        <template v-if="step === 'map'">
          <h1 class="bg-transparent text-emphasis text-xl font-medium p-6 pb-4">
            Map Columns
          </h1>
          <div class="toolbox-body">
            <template v-if="!importingNewObject">
              <h2
                class="open bg-transparent border-0 shadow-none px-6 pt-0"
              >
                <span class="text-emphasis text-base">Map columns to Knack fields</span>
              </h2>
              <div
                id="map-existing"
                class="is-padded transition open border-0 px-6"
              >
                <div class="map-labels headers level-equal">
                  <div>
                    <input
                      type="checkbox"
                      @click="onCheckAllMapFields($event)"
                    >
                    KNACK FIELD
                  </div>
                  <div>COLUMN</div>
                </div>

                <div
                  class="map-rows"
                  data-cy="map-rows"
                >
                  <template
                    v-for="(field, fieldIndex) in fields"
                    :key="fieldIndex"
                  >
                    <ImportMapField
                      :field="field"
                      :fields="fields"
                      :field-types="fieldTypes"
                      :columns="columns"
                      :records="records"
                      :available-columns="availableColumns"
                      :mapped-column-indexes="mappedColumnIndexes"
                    />
                  </template>
                </div>
              </div>
            </template>

            <h2
              id="map-create-trigger"
              ref="new"
              class="bg-transparent border-0 shadow-none px-6 pt-0"
              :class="{ open: importingNewObject }"
              @click="toggle($event, 'create')"
            >
              <span class="text-emphasis text-base">
                Map to new Knack Fields
              </span>
              <div
                v-if="!importingNewObject"
                class="tree-expand"
              >
                <Icon
                  class="h-4 w-4"
                  type="arrow-drop-right"
                />
              </div>
            </h2>

            <div
              id="map-create"
              ref="create"
              class="is-padded transition border-0 px-6"
              :class="{ open: importingNewObject }"
            >
              <div class="map-labels headers level-equal">
                <div class="capitalize text-sm">
                  <input
                    type="checkbox"
                    @click="onCheckAllAddColumns($event)"
                  >
                  COLUMN
                </div>
                <div class="capitalize text-sm">
                  NEW FIELD
                </div>
              </div>

              <div
                class="map-rows"
                data-cy="map-rows"
              >
                <template
                  v-for="(column, columnIndex) in columns"
                  :key="columnIndex"
                >
                  <ImportMapAdd
                    v-if="columnIsAvailbleToAdd(column)"
                    :column="column"
                    :field-types="fieldTypes"
                    :columns="columns"
                    :available-columns="availableColumns"
                    :mapped-column-indexes="mappedColumnIndexes"
                  />
                </template>
              </div>
            </div>

            <div
              id="submit-mapping"
              class="flex justify-end border-0 px-6"
            >
              <a
                class="button gray-outline pyx-3 py-2.5 rounded-lg bg-transparent border border-solid border-default text-base leading-4 font-medium text-emphasis"
                @click="previousStep"
              > Back </a>
              <a
                class="button orange-fill px-3 py-2.5 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium mr-0"
                :class="{ 'disabled': isNextButtonDisabled }"
                :style="{ pointerEvents: isNextButtonDisabled ? 'none' : 'auto' }"
                data-cy="submit-mapping"
                @click="onSubmitMapping"
              >
                Next
              </a>
            </div>
          </div>
        </template>
      </div>
      <div
        id="import-preview-data"
        class="bg-default p-6 border-2 border-solid border-t-[#e3e3e3]/20 border-b-0 border-x-0"
      >
        <BodyWrapper>
          <ImportTable
            :records="records"
            :table-headers="tableHeaders"
            :step="step"
            :available-columns="availableColumns"
            :mapped-field-indexes="mappedFieldIndexes"
            :added-column-indexes="addedColumnIndexes"
          />
        </BodyWrapper>
      </div>
    </div>

    <!-- confirm -->
    <ImportConfirm
      v-if="step === 'confirm'"
      v-model:object-name="objectName"
      v-model:skip-records-with-errors="skipRecordsWithErrors"
      :import-action="importingNewObject ? `create` : `update`"
      :total-records="getTotalRecords"
      @submit="onSubmitConfirm"
      @back="previousStep"
    />
  </div>
</template>

<script>
import FieldPredictor from '@knack/field-predictor';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import ImportSource from '@/components/records/import/ImportSource';
import ImportHeaders from '@/components/records/import/ImportHeaders';
import ImportMatch from '@/components/records/import/ImportMatch';
import ImportTable from '@/components/records/import/ImportTable';
import ImportMapField from '@/components/records/import/ImportMapField';
import ImportMapAdd from '@/components/records/import/ImportMapAdd';
import ImportConfirm from '@/components/records/import/ImportConfirm';
import BodyWrapper from '@/components/layout/BodyWrapper';
import Icon from '@/components/ui/Icon';
import RequestUtils from '@/components/util/RequestUtils';
import { logEvent } from '@/lib/segment-helper';

export default {
  components: {
    ImportSource,
    ImportHeaders,
    ImportMatch,
    ImportTable,
    ImportMapField,
    ImportMapAdd,
    ImportConfirm,
    BodyWrapper,
    Icon,
  },
  mixins: [RequestUtils],
  props: {
    action: {
      type: String,
      default: '',
    },
    isUser: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['import-started'],
  data() {
    return {
      steps: {
        update: [
          'source',
          'headers',
          'match',
          'map',
          'confirm',
          'action',
        ],
        create: ['source', 'headers', 'map', 'confirm', 'action'],
      },
      stepIndex: 0,
      selectedSheetIndex: 0,
      importAction: this.action || 'update',
      hasHeaderRow: true,
      match: {
        updateRecords: false,
        insertUnmatched: true,
        rules: [],
      },
      fieldTypes: [
        {
          key: 'connection',
          label: 'Connection',
        },
        {
          key: 'short_text',
          label: 'Short Text',
        },
        {
          key: 'paragraph_text',
          label: 'Paragraph Text',
        },
        {
          key: 'rich_text',
          label: 'Rich Text',
        },
        {
          key: 'number',
          label: 'Number',
        },
        {
          key: 'currency',
          label: 'Currency',
        },
        {
          key: 'multiple_choice',
          label: 'Multiple Choice',
        },
        {
          key: 'boolean',
          label: 'Yes/No',
        },
        {
          key: 'date_time',
          label: 'Date/Time',
          formats: {
            date: [
              {
                key: 'mm/dd/yyyy',
                label: 'mm/dd/yyyy',
              },
              {
                key: 'dd/mm/yyyy',
                label: 'dd/mm/yyyy',
              },
              {
                key: 'Ignore Date',
                label: 'Ignore Date',
              },
            ],
            time: [
              {
                key: 'HH:MM am',
                label: 'HH:MM am',
              },
              {
                key: 'HH MM (military)',
                label: 'HH:MM (24 hour)',
              },
              {
                key: 'Ignore Time',
                label: 'Ignore Time',
              },
            ],
          },
        },
        {
          key: 'timer',
          label: 'Timer',
        },
        {
          key: 'file',
          label: 'File',
        },
        {
          key: 'image',
          label: 'Image',
        },
        {
          key: 'name',
          label: 'Person',
          complex: true,
          parts: [
            {
              key: 'title',
              label: 'Title',
            },
            {
              key: 'first',
              label: 'First',
            },
            {
              key: 'middle',
              label: 'Middle',
            },
            {
              key: 'last',
              label: 'Last',
            },
          ],
        },
        {
          key: 'address',
          label: 'Address',
          complex: true,
          parts: [
            {
              key: 'street',
              label: 'Street Address',
            },
            {
              key: 'street2',
              label: 'Street 2',
            },
            {
              key: 'city',
              label: 'City',
            },
            {
              key: 'state',
              label: 'State',
            },
            {
              key: 'zip',
              label: 'Zip',
            },
            {
              key: 'country',
              label: 'Country',
            },
            {
              key: 'latitude',
              label: 'Latitude',
            },
            {
              key: 'longitude',
              label: 'Longitude',
            },
          ],
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'phone',
          label: 'Phone',
        },

        // {value: 'signature', label: 'Signature'},
        {
          key: 'rating',
          label: 'Rating',
        },
        {
          key: 'link',
          label: 'Link',
          complex: true,
          parts: [
            {
              key: 'url',
              label: 'URL',
            },
            {
              key: 'label',
              label: 'Link Text',
            },
          ],
        },
      ],
      records: [],
      allSheets: [],
      headers: [],
      originalFirstRow: [],
      columns: [],
      fields: [],
      file: null,
      objectName: {
        type: String,
        default: '',
      },
      token: {
        type: String,
        default: '',
      },
      skipRecordsWithErrors: false,
    };
  },
  computed: {
    step() {
      return this.steps[this.importAction][this.stepIndex];
    },
    sheetNames() {
      return this.allSheets.map((sheet) => sheet.name);
    },
    importingNewObject() {
      return !this.$store.getters.activeObject;
    },
    isNextButtonDisabled() {
      return (!this.addedColumnIndexes.length && !this.mappedFieldIndexes.length);
    },
    // columns that have beeen mapped to either an existing or new field
    mappedColumnIndexes() {
      // exclude any columns mapped to complex field parts in the add section. These also need to be excluded from the list to add new fields
      return this.mappedFieldIndexes.concat(this.addedColumnIndexes);
    },

    getTotalRecords() {
      let { rowCount } = this.allSheets[this.selectedSheetIndex];
      if (this.hasHeaderRow) {
        rowCount--;
      }
      return rowCount;
    },

    // TODO: What is this for?
    addedColumnIndexes() {
      const cols = [];

      this.columns.forEach((column) => {
        if (column.added) {
          // complex field
          if (column.parts && column.parts.length) {
            column.parts.forEach((part) => {
              // consider a part mapped if a column was mapped to a complex field and one of the parts mapped to another column
              if (part.mapped && part.mappedColumnIndex !== false) {
                cols.push(part.mappedColumnIndex);
              }
            });

            // regular field
          } else {
            cols.push(column.index);
          }
        }
      });

      return cols;
    },

    // TODO: What is this for?
    mappedFieldIndexes() {
      const cols = [];

      this.fields.forEach((field) => {
        if (!field.mapped) {
          return;
        }

        // for complex fields, map field parts
        if (field.complex) {
          return field.parts.forEach((part) => {
            if (
              part.mapped
              && part.mappedColumnIndex !== false
            ) {
              cols.push(part.mappedColumnIndex);
            }
          });
        }
        if (field.mappedColumnIndex !== false) {
          cols.push(field.mappedColumnIndex);
        }
      });

      return cols;
    },

    // both unmapped and unadded
    availableColumns() {
      return this.columns.filter((col) => {
        if (this.mappedColumnIndexes.includes(col.index)) {
          return false;
        }

        if (this.addedColumnIndexes.includes(col.index)) {
          return false;
        }

        return true;
      });
    },

    // show any unmapped or those that have been checked as added
    addColumnsList() {
      return this.columns.filter((col) => this.columnIsAvailbleToAdd(col));
    },
    showPreview() {
      return this.step !== 'source' && this.step !== 'confirm';
    },
    tableHeaders() {
      return this.headers;
    },
  },
  watch: {
    selectedSheetIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.headers = [...this.allSheets[newValue].preview[0]];
        this.records = [...this.allSheets[newValue].preview];
        if (this.hasHeaderRow) {
          this.records.shift();
        }
      }
    },

    hasHeaderRow(newValue, oldValue) {
      if (newValue === false) {
        this.records.unshift(this.headers);

        this.headers = this.records[0].map(
          (item, index) => `Column ${index + 1}`,
        );
        this.records[0] = this.originalFirstRow;
      } else {
        this.headers = this.createUniqueHeaders(this.records.shift());
      }
    },
  },
  created() {
    if (!this.headers.length) {
      this.headers = this.records.shift();
    }

    // set local fields state based on the active object's fields

    // if creating we have no existing fields to populate
    if (this.importAction === 'create') {
      return;
    }
  },
  methods: {
    createUniqueHeaders(headers) {
      const uniqueHeaders = [];
      let columnCounter = 1;

      // eslint-disable-next-line no-restricted-syntax
      for (const header of headers) {
        let effectiveHeader = header;
        if (!header?.trim()) {
          effectiveHeader = `Column ${columnCounter}`;
          columnCounter += 1;
        }
        if (uniqueHeaders.includes(effectiveHeader)) {
          let index = 1;
          let newHeader;
          do {
            index += 1;
            newHeader = `${effectiveHeader} (${index})`;
          } while (uniqueHeaders.includes(newHeader));
          uniqueHeaders.push(newHeader);
        } else {
          uniqueHeaders.push(effectiveHeader);
        }
      }
      return uniqueHeaders;
    },
    handleSheetSwitched() {
      this.hasHeaderRow = true;
    },
    getFieldParts({ type, format }) {
      // address can possibly be lat/long
      if (
        type === window.Knack.config.ADDRESS
        && format
        && format.input
        && format.input === 'lat_long'
      ) {
        return [
          {
            key: 'latitude',
            label: 'Latitude',
          },
          {
            key: 'longitude',
            label: 'Longitude',
          },
        ];
      }

      const fieldType = this.fieldTypes.find(
        (fieldType) => fieldType.key === type,
      );

      return fieldType ? _.cloneDeep(fieldType.parts) : [];
    },

    onCheckAllMapFields(event) {
      this.fields.forEach((field) => {
        field.mapped = event.target.checked;
      });
    },

    onCheckAllAddColumns(event) {
      this.columns.forEach((column) => {
        if (
          !event.target.checked
          || !this.mappedColumnIndexes.includes(column.index)
        ) {
          column.added = event.target.checked;
        }
      });
    },

    // show any unmapped or those that have been checked as added
    columnIsAvailbleToAdd(column) {
      // added is first in case the column is mapped to a complex part of a new field
      if (column.added) {
        return true;
      }

      if (this.mappedColumnIndexes.includes(column.index)) {
        return false;
      }

      return true;
    },
    onClose() {
      this.$router.push(`/records/objects/${this.$route.params.objectKey}`);
    },
    toggle(event) {
      event.currentTarget.classList.toggle('open');

      this.$refs.create.classList.toggle('open');
    },
    onSubmitSource(data) {
      log('ImportWizard.onSubmitSource()');
      log('data.file:', data.file);

      // .csv, .xls or .xlsx file upload data object from current Server import-controller
      // Google Sheets files are also sent to the import-controller
      if (data.sheets) {
        log('data.previewData:', JSON.parse(JSON.stringify(data.sheets)));
        // This initially includes the header row because we don't know
        // if the file has headers until a subsequent screen where
        // the user will tell us this
        this.token = data.token;
        this.selectedSheetIndex = 0;
        this.allSheets = data.sheets;
        this.records = [...data.sheets[0].preview];

        if (data.file) {
          this.file = data.file;
        }
        this.originalFirstRow = [...this.records[0]];
        this.headers = this.createUniqueHeaders(this.records.shift());
      }

      // set the object name, using the .csv, .xls, .xlsx or Google Sheets file if this is a new object
      const fileNameParts = data.file.name.split('.');
      if (this.importingNewObject) {
        if (fileNameParts.length > 1) {
          this.objectName = fileNameParts.slice(0, -1).join(' ');
        } else {
          this.objectName = data.file.name;
        }
      } else {
        this.objectName = this.$store.getters.activeObject.name;
      }

      this.nextStep();
    },
    onSubmitHeaders() {
      log('ImportWizard.onSubmitHeaders()');
      // Get the Field Type (key) from our hardocded list of fieldTypes (which is actually field type descriptions)
      const coreFieldTypes = this.fieldTypes.map(
        (fieldTypeDescription) => fieldTypeDescription.key,
      );

      // now that we know what the headers are let's seed columns
      this.columns = this.headers.map((header, index) => {
        const predictedTypeCount = {};

        // build up an object keyed by field type so we can increment based on the predicted type of each row we're going to analyze
        for (const type of coreFieldTypes) {
          predictedTypeCount[type] = {
            count: 0,
            format: null,
          };
        }

        const column = {
          name: header,
          index,
          added: false,
          addedFieldType: false,
          parts: [],
        };

        for (const row of this.records) {
          if (!row[index]) {
            continue;
          }
          const prediction = FieldPredictor.predict(row[index]);

          if (!prediction) {
            // default
            predictedTypeCount.short_text.count++;

            continue;
          }

          if (prediction.type === 'link:url') {
            predictedTypeCount.link.count++;
            predictedTypeCount.link.format = prediction.format;

            continue;
          }

          predictedTypeCount[prediction.type].count++;
          predictedTypeCount[prediction.type].format = prediction.format;
        }

        let predictedType;
        let predictedFormat;
        let maxPredictionCount = 0;

        for (const predictedTypeCountKey in predictedTypeCount) {
          if (
            predictedTypeCount[predictedTypeCountKey].count < maxPredictionCount
          ) {
            continue;
          }

          maxPredictionCount = predictedTypeCount[predictedTypeCountKey].count;
          predictedType = predictedTypeCountKey;
          predictedFormat = predictedTypeCount[predictedTypeCountKey].format;
        }

        if (maxPredictionCount === 0) {
          column.predictedType = "short_text";
          column.predictedFormat = null;
        } else {
          column.predictedType = predictedType;
          column.predictedFormat = predictedFormat;
        }

        if (column.predictedType === 'date_time') {
          column.format = column.predictedFormat || {
            date_format: 'dd/mm/yyyy',
            time_format: 'Ignore Time',
          };
        }

        if (this.importingNewObject) {
          column.addedFieldType = column.predictedType;
          column.added = true;
        }

        //  TODO: We do not currently predict specific parts so we need to handle this here
        //        or augment the field-predictor library to do so
        //  column.predictedPart = prediction.part

        return column;
      });

      this.fields = [];

      // If this is importing into a new object, we don't need to populate fields
      if (this.importingNewObject) {
        return this.nextStep();
      }

      this.$store.getters.activeObject.fields.forEach((field) => {
        if (!field.canBeImported()) {
          return false;
        }

        const newField = {
          key: field.key,
          type: field.type,
          name: field.name,
          complex: field.hasParts(),
          format: field.format,
          mapped: false,
          mappedColumnIndex: false,
          matchable: field.isImportMatchable(),
        };

        log(`checking if any columns match field with name ${field.name}`);

        const columnMatchingFieldName = this.columns.find(
          (column) => newField.name === column.name && !column.mapped,
        );

        log('columnMatchingFieldName');
        log(columnMatchingFieldName);
        if (columnMatchingFieldName) {
          newField.mappedColumnIndex = columnMatchingFieldName.index;
          newField.mapped = true;
        }

        // if a complex field, map all the parts
        if (newField.complex) {
          newField.parts = this.getFieldParts(field);
          newField.parts.forEach((part) => {
            part.mapped = false;
            part.mappedColumnIndex = false;
            const { key, label } = part;
            part.part = { key, label };
          });
        }
        this.fields.push(newField);
      });

      this.nextStep();
    },
    isLocalImport(fileName) {
      const lowerCasedFileName = fileName.toLowerCase();
      return lowerCasedFileName.endsWith('.csv')
           || lowerCasedFileName.endsWith('.xlsx')
           || lowerCasedFileName.endsWith('.xls');
    },
    onSubmitSheet() {
      log('ImportWizard.onSubmitSheet()');
      this.nextStep();
    },
    onSubmitMatching() {
      this.nextStep();
    },
    onSubmitMapping() {
      this.nextStep();
    },
    shouldSkipStep() {
      return this.step === 'sheet' && this.allSheets.length === 1;
    },
    nextStep() {
      this.stepIndex++;
      if (this.shouldSkipStep()) this.stepIndex++;
    },
    previousStep() {
      this.stepIndex--;
      this.hasHeaderRow = true;
      if (this.shouldSkipStep()) this.stepIndex--;
    },
    onSubmitConfirm(defaultValues = []) {
      const defaultValueFields = defaultValues.map(({ field }) => field);

      const allMappedFields = this.fields.filter(
        (field) => field.mapped || defaultValueFields.includes(field.key),
      );

      log('ImportWizard.onSubmitConfirm() final data object:');
      log('mapped fields to be submitted:');
      log(allMappedFields);
      log('new fields to be submitted:');
      log(this.addColumnsList.filter((column) => column.added));

      const data = [
        this.importAction,
        this.hasHeaderRow,
        this.match,
        allMappedFields,
        this.addColumnsList.filter((column) => column.added),
        get(this.$store, 'getters.activeObject.key'),
        this.objectName,
        this.file,
        defaultValues,
        this.isUser,
        this.token,
        this.selectedSheetIndex,
        this.skipRecordsWithErrors,
      ];

      this.commitRequest({
        request: () => {
          if (this.isLocalImport(this.file.name)) {
            return window.Knack.Api.uploadWebFileForImport(...data);
          }
          return window.Knack.Api.importGoogleSheetsFile(...data);
        },
        onSuccess: (result) => {
          log('uploadWebFileForImport result is:');
          log(result);

          this.$emit('import-started');

          this.$store.dispatch('notifications/jobQueued', {
            jobType: 'import',
            objectKey: this.activeObject.key,
          });
        },
      });
    },
  },
};
</script>

<style lang="scss">
#import-wizard {
  @include font-body;
  width: 100%;
  flex: 1;
  display: flex;
  z-index: 101;

  .is-label {
    font-weight: 500;
  }

  .top-border {
    padding-top: 1.5em;
    border-top: 1px solid #d6d9e2;
  }

  .light {
    color: $gray400;
    margin-right: 0.25rem;
    font-weight: 400;
  }

  input[type="checkbox"] {
    margin-right: 0.5em;
  }

  .tree-expand {
    // height: 18px;
    // width: 18px;
    // cursor: pointer;

    height: 24px;

    svg.icon-arrow-drop-right {
      display: block;
      margin-left: 8px;
      transition: all 200ms ease-out;
      width: 26px;
      height: 26px;
    }
  }
}

#import-preview {
  display: flex;
  flex: 1;
  width: 100%;
}

#import-toolbox {
  flex: 0 0 440px;
  display: flex;
  flex-direction: column;

  input[type="radio"] {
    height: auto;
  }

  select {
    font-size: 0.925em;
    height: 2em;
    margin: 0;
    flex-grow: 1;
    width: 100%;
  }

  & > h1,
  & > div > h1 {
    @include font-h6;
    line-height: 1;
    font-weight: 600;
    background-color: $edit500;
    color: #fff;
    padding: 0.75rem 1rem;
    margin: 0;
  }

  .toolbox-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;

    .overflow {
      overflow: auto;
      height: 100%;
    }
  }

  p {
    margin-bottom: 0.75em;
  }
  h2 {
    font-size: 1rem;
    line-height: 100%;
    font-weight: 500;
    background-color: #e7e8ea;
    // color: #771b66;
    padding: 0.5rem 1rem 0.5rem 0.9rem;
    margin-bottom: 0em;
    border-top: solid 1px #d4d6dc;
    border-bottom: solid 1px #c7c9d0;
    display: flex;
    justify-content: left;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px -1px;

    > svg {
      color: #25a593;
      height: 17px;
      width: 17px;
      margin-right: 7px;
    }

    > div {
      display: flex;
      align-items: center;
    }

    span {
      flex-grow: 1;
    }
  }

  .is-padded {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .headers {
    font-size: 0.9rem;
    font-weight: 500;
  }
}

#map-create-trigger {
  cursor: pointer;
}

h2.open {
  svg.icon-arrow-drop-right {
    transform: rotate(90deg);
  }
}

#import-preview-data {
  background: #fff;
  flex-grow: 1;

  #box-shadow-top {
    box-shadow: rgba(0, 0, 0, 0.3) 7px -6px 9px 5px;
  }
  #box-shadow-left {
    box-shadow: rgba(0, 0, 0, 0.3) -10px 0px 12px 10px;
  }
}

.map-labels {
  padding-top: 1em;
  // padding-bottom: .5em;
  // border-bottom: 1px solid #d9dce2;
}

#map-existing,
#map-create {
  overflow: auto;
  flex: 0 0 0;

  &.open {
    flex: 2 0 0;
  }

  & div.map-row {
    padding-top: 0.65em;
    border-top: 1px solid #dfe2e8;
    margin-top: 0.65em;
    line-height: 1.8em;
  }

  .map-rows > div:last-child {
    margin-bottom: 1em;
  }

  .field-icon {
    margin: 0 6px 0 2px;
  }

  div.map-sub-row {
    line-height: 1.8em;
    padding-left: 27px;
    margin-bottom: 0.6em;
  }

  .edit-map {
    color: #4c4f57;
  }
}

#map-create.open {
  border-bottom: 1px solid #d4d6dc;
}

#submit-mapping {
  padding: 1rem;
  background: white;
  border-top: 1px solid $gray100;

  a {
    font-size: 1em;
    font-weight: 500;
    margin-right: 0.5rem;

    &.gray-outline {
      font-weight: 400;
    }
  }
}
</style>
