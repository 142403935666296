<template>
  <div
    id="settings-app"
    class="body-wrapper m-0 px-6 py-2"
  >
    <form class="max-w-xl">
      <div
        data-field-name="name"
        :class="{ 'has-error': appNameError }"
      >
        <label
          for="name"
          :class="labelClasses"
        >
          App Name
        </label>
        <input
          v-model="localApp.name"
          name="name"
          type="text"
        >
        <p
          v-if="appNameError"
          class="error-msg"
        >
          {{ appNameError }}
        </p>
      </div>
      <div
        data-field-name="slug"
        :class="{ 'has-error': appURLError }"
      >
        <label
          for="slug"
          :class="labelClasses"
        >
          App URL
        </label>
        <div
          id="url-overlay"
          class="app-url-overlay m-0 h-10 border-none flex items-center
            before:content-[attr(data-before)] before:text-base before:text-muted"
          :data-before="url"
        />
        <input
          id="app-overlay"
          v-model="localApp.slug"
          class="m-0 font-normal"
          name="slug"
          type="text"
        >
        <p
          v-if="appURLError"
          class="error-msg"
        >
          {{ appURLError }}
        </p>
      </div>
      <div>
        <label
          for="description"
          :class="labelClasses"
        >
          App Description
        </label>
        <input
          v-model="localApp.description"
          name="description"
          type="text"
        >
      </div>
      <div>
        <label
          for="from_email"
          :class="labelClasses"
        >
          App Email Address
        </label>
        <input
          v-model="localApp.settings.from_email"
          name="from_email"
          type="text"
        >
        <p class="instructions not-italic text-sm text-subtle font-normal mb-0 mt-2">
          The "from" email address the app will use when sending any emails
        </p>
      </div>
      <div>
        <label
          for="technical_contact"
          :class="labelClasses"
        >App Technical Contact</label>
        <input
          v-model="localApp.settings.technical_contact"
          name="technical_contact"
          type="text"
        >
        <p class="instructions  not-italic text-sm text-subtle font-normal mb-0 mt-2">
          The email address that should receive technical notifications such as Google Maps API issues
        </p>
      </div>
      <div>
        <label
          for="user_timezone"
          :class="labelClasses"
        >
          Timezone
        </label>
        <select
          v-model="localApp.settings.timezone"
          name="user_timezone"
        >
          <option
            v-for="timezone in timezones"
            :key="timezone.region"
            :value="timezone.region"
          >
            <span v-if="timezone.region">
              (GMT{{ timezone.gmt_offset_hr }}) {{ timezone.region }}
            </span>
            <span v-else>
              -------------
            </span>
          </option>
        </select>
      </div>
      <div>
        <label
          for="timezone_dst"
          :class="labelClasses"
        >
          Include Daylight Saving?
        </label>
        <select
          v-model="localApp.settings.timezone_dst"
          name="timezone_dst"
        >
          <option value="0">
            No
          </option>
          <option value="3600">
            Yes, +1 hour during Daylight Saving Time
          </option>
        </select>
      </div>
      <div>
        <label :class="labelClasses">
          Language
        </label>
        <select
          v-model="language"
          name="language"
        >
          <option
            v-for="lang in languages"
            :key="lang.value"
            :value="lang.value"
          >
            {{ lang.name }}
          </option>
        </select>
      </div>

      <div class="flex">
        <a
          class="button medium save bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium ml-auto"
          data-cy="save-app-settings"
          @click.prevent="onSave"
        >
          Save Settings
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RequestUtils from '@/components/util/RequestUtils';
import { getLiveAppBaseUrl } from '@/lib/url-helper';

export default {
  name: 'Settings',
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      localApp: JSON.parse(JSON.stringify(this.$store.getters.app.raw())),
      errors: {
        nameTaken: undefined,
        nameMissing: undefined,
        slugTaken: undefined,
        slugMissing: undefined,
      },

      languages: [
        {
          value: 'english', name: 'English',
        },
        {
          value: 'azerbaijani', name: 'Azerbaijani',
        },
        {
          value: 'czech', name: 'Czech',
        },
        {
          value: 'danish', name: 'Danish',
        },
        {
          value: 'dutch', name: 'Dutch',
        },
        {
          value: 'finnish', name: 'Finnish',
        },
        {
          value: 'french', name: 'French',
        },
        {
          value: 'german', name: 'German',
        },
        {
          value: 'hungarian', name: 'Hungarian',
        },
        {
          value: 'italian', name: 'Italian',
        },
        {
          value: 'norwegian', name: 'Norwegian',
        },
        {
          value: 'polish', name: 'Polish',
        },
        {
          value: 'brazilian', name: 'Portuguese (Brazilian)',
        },
        {
          value: 'spanish', name: 'Spanish',
        },
        {
          value: 'swedish', name: 'Swedish',
        },
        {
          value: 'turkish', name: 'Turkish',
        },
      ],

      // timezone_selected: this.$store.state.app.get('settings').timezone,
      // timezone_dst_selected: this.$store.state.app.get('settings').timezone_dst,
      timezones: [
        {
          region: 'Hawaii', gmt_offset_hr: '-10:00', gmt_offset_s: '-36000',
        },
        {
          region: 'Alaska', gmt_offset_hr: '-09:00', gmt_offset_s: '-32400',
        },
        {
          region: 'Pacific Time (US & Canada)', gmt_offset_hr: '-08:00', gmt_offset_s: '-28800',
        },
        {
          region: 'Arizona', gmt_offset_hr: '-07:00', gmt_offset_s: '-25200',
        },
        {
          region: 'Mountain Time (US & Canada)', gmt_offset_hr: '-07:00', gmt_offset_s: '-25200',
        },
        {
          region: 'Central Time (US & Canada)', gmt_offset_hr: '-06:00', gmt_offset_s: '-21600',
        },
        {
          region: 'Eastern Time (US & Canada)', gmt_offset_hr: '-05:00', gmt_offset_s: '-18000',
        },
        {
          region: 'Indiana (East)', gmt_offset_hr: '-05:00', gmt_offset_s: '-18000',
        },
        {
          region: '', gmt_offset_hr: '', gmt_offset_s: '',
        },
        {
          region: 'Midway Island', gmt_offset_hr: '-11:00', gmt_offset_s: '-39600',
        },
        {
          region: 'Samoa', gmt_offset_hr: '-11:00', gmt_offset_s: '-39600',
        },
        {
          region: 'Tijuana', gmt_offset_hr: '-08:00', gmt_offset_s: '-28800',
        },
        {
          region: 'Chihuahua', gmt_offset_hr: '-07:00', gmt_offset_s: '-25200',
        },
        {
          region: 'Mazatlan', gmt_offset_hr: '-07:00', gmt_offset_s: '-25200',
        },
        {
          region: 'Central America', gmt_offset_hr: '-06:00', gmt_offset_s: '-21600',
        },
        {
          region: 'Guadalajara', gmt_offset_hr: '-06:00', gmt_offset_s: '-21600',
        },
        {
          region: 'Mexico City', gmt_offset_hr: '-06:00', gmt_offset_s: '-21600',
        },
        {
          region: 'Monterrey', gmt_offset_hr: '-06:00', gmt_offset_s: '-21600',
        },
        {
          region: 'Saskatchewan', gmt_offset_hr: '-06:00', gmt_offset_s: '-21600',
        },
        {
          region: 'Bogota', gmt_offset_hr: '-05:00', gmt_offset_s: '-18000',
        },
        {
          region: 'Lima', gmt_offset_hr: '-05:00', gmt_offset_s: '-18000',
        },
        {
          region: 'Quito', gmt_offset_hr: '-05:00', gmt_offset_s: '-18000',
        },
        {
          region: 'Caracas', gmt_offset_hr: '-04:30', gmt_offset_s: '-16200',
        },
        {
          region: 'Halifax', gmt_offset_hr: '-04:00', gmt_offset_s: '-14400',
        },
        {
          region: 'La Paz', gmt_offset_hr: '-04:00', gmt_offset_s: '-14400',
        },
        {
          region: 'Georgetown (Guyana)', gmt_offset_hr: '-04:00', gmt_offset_s: '-14400',
        },
        {
          region: 'Newfoundland', gmt_offset_hr: '-03:30', gmt_offset_s: '-12600',
        },
        {
          region: 'Santiago', gmt_offset_hr: '-03:00', gmt_offset_s: '-10800',
        },
        {
          region: 'Sao Paulo', gmt_offset_hr: '-03:00', gmt_offset_s: '-10800',
        },
        {
          region: 'Buenos Aires', gmt_offset_hr: '-03:00', gmt_offset_s: '-10800',
        },
        {
          region: 'Greenland', gmt_offset_hr: '-03:00', gmt_offset_s: '-10800',
        },
        {
          region: 'Mid-Atlantic', gmt_offset_hr: '-02:00', gmt_offset_s: '-7200',
        },
        {
          region: 'Azores', gmt_offset_hr: '-01:00', gmt_offset_s: '-3600',
        },
        {
          region: 'Cape Verde Is.', gmt_offset_hr: '-01:00', gmt_offset_s: '-3600',
        },
        {
          region: 'Casablanca', gmt_offset_hr: '', gmt_offset_s: '0',
        },
        {
          region: 'Dublin', gmt_offset_hr: '', gmt_offset_s: '0',
        },
        {
          region: 'Edinburgh', gmt_offset_hr: '', gmt_offset_s: '0',
        },
        {
          region: 'Lisbon', gmt_offset_hr: '', gmt_offset_s: '0',
        },
        {
          region: 'London', gmt_offset_hr: '', gmt_offset_s: '0',
        },
        {
          region: 'Monrovia', gmt_offset_hr: '', gmt_offset_s: '0',
        },
        {
          region: 'Amsterdam', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Belgrade', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Berlin', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Bern', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Bratislava', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Brussels', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Budapest', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Copenhagen', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Ljubljana', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Madrid', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Paris', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Prague', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Rome', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Sarajevo', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Skopje', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Stockholm', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Vienna', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Warsaw', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'West Central Africa', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Zagreb', gmt_offset_hr: '+01:00', gmt_offset_s: '3600',
        },
        {
          region: 'Athens', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Bucharest', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Cairo', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Harare', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Helsinki', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Istanbul', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Jerusalem', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Kyiv', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Pretoria', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Riga', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Sofia', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Tallinn', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Vilnius', gmt_offset_hr: '+02:00', gmt_offset_s: '7200',
        },
        {
          region: 'Baghdad', gmt_offset_hr: '+03:00', gmt_offset_s: '10800',
        },
        {
          region: 'Kuwait', gmt_offset_hr: '+03:00', gmt_offset_s: '10800',
        },
        {
          region: 'Minsk', gmt_offset_hr: '+03:00', gmt_offset_s: '10800',
        },
        {
          region: 'Nairobi', gmt_offset_hr: '+03:00', gmt_offset_s: '10800',
        },
        {
          region: 'Riyadh', gmt_offset_hr: '+03:00', gmt_offset_s: '10800',
        },
        {
          region: 'Tehran', gmt_offset_hr: '+03:30', gmt_offset_s: '12600',
        },
        {
          region: 'Abu Dhabi', gmt_offset_hr: '+04:00', gmt_offset_s: '14400',
        },
        {
          region: 'Baku', gmt_offset_hr: '+04:00', gmt_offset_s: '14400',
        },
        {
          region: 'Moscow', gmt_offset_hr: '+04:00', gmt_offset_s: '14400',
        },
        {
          region: 'Muscat', gmt_offset_hr: '+04:00', gmt_offset_s: '14400',
        },
        {
          region: 'St. Petersburg', gmt_offset_hr: '+04:00', gmt_offset_s: '14400',
        },
        {
          region: 'Tbilisi', gmt_offset_hr: '+04:00', gmt_offset_s: '14400',
        },
        {
          region: 'Volgograd', gmt_offset_hr: '+04:00', gmt_offset_s: '14400',
        },
        {
          region: 'Yerevan', gmt_offset_hr: '+04:00', gmt_offset_s: '14400',
        },
        {
          region: 'Kabul', gmt_offset_hr: '+04:30', gmt_offset_s: '16200',
        },
        {
          region: 'Islamabad', gmt_offset_hr: '+05:00', gmt_offset_s: '18000',
        },
        {
          region: 'Karachi', gmt_offset_hr: '+05:00', gmt_offset_s: '18000',
        },
        {
          region: 'Tashkent', gmt_offset_hr: '+05:00', gmt_offset_s: '18000',
        },
        {
          region: 'Chennai', gmt_offset_hr: '+05:30', gmt_offset_s: '19800',
        },
        {
          region: 'Kolkata', gmt_offset_hr: '+05:30', gmt_offset_s: '19800',
        },
        {
          region: 'Mumbai', gmt_offset_hr: '+05:30', gmt_offset_s: '19800',
        },
        {
          region: 'New Delhi', gmt_offset_hr: '+05:30', gmt_offset_s: '19800',
        },
        {
          region: 'Kathmandu', gmt_offset_hr: '+05:45', gmt_offset_s: '20700',
        },
        {
          region: 'Almaty', gmt_offset_hr: '+06:00', gmt_offset_s: '21600',
        },
        {
          region: 'Astana', gmt_offset_hr: '+06:00', gmt_offset_s: '21600',
        },
        {
          region: 'Dhaka', gmt_offset_hr: '+06:00', gmt_offset_s: '21600',
        },
        {
          region: 'Ekaterinburg', gmt_offset_hr: '+06:00', gmt_offset_s: '21600',
        },
        {
          region: 'Sri Jayawardenepura', gmt_offset_hr: '+06:00', gmt_offset_s: '21600',
        },
        {
          region: 'Rangoon', gmt_offset_hr: '+06:30', gmt_offset_s: '23400',
        },
        {
          region: 'Bangkok', gmt_offset_hr: '+07:00', gmt_offset_s: '25200',
        },
        {
          region: 'Hanoi', gmt_offset_hr: '+07:00', gmt_offset_s: '25200',
        },
        {
          region: 'Jakarta', gmt_offset_hr: '+07:00', gmt_offset_s: '25200',
        },
        {
          region: 'Novosibirsk', gmt_offset_hr: '+07:00', gmt_offset_s: '25200',
        },
        {
          region: 'Beijing', gmt_offset_hr: '+08:00', gmt_offset_s: '28800',
        },
        {
          region: 'Chongqing', gmt_offset_hr: '+08:00', gmt_offset_s: '28800',
        },
        {
          region: 'Hong Kong', gmt_offset_hr: '+08:00', gmt_offset_s: '28800',
        },
        {
          region: 'Krasnoyarsk', gmt_offset_hr: '+08:00', gmt_offset_s: '28800',
        },
        {
          region: 'Kuala Lumpur', gmt_offset_hr: '+08:00', gmt_offset_s: '28800',
        },
        {
          region: 'Perth', gmt_offset_hr: '+08:00', gmt_offset_s: '28800',
        },
        {
          region: 'Singapore', gmt_offset_hr: '+08:00', gmt_offset_s: '28800',
        },
        {
          region: 'Taipei', gmt_offset_hr: '+08:00', gmt_offset_s: '28800',
        },
        {
          region: 'Ulaanbataar', gmt_offset_hr: '+08:00', gmt_offset_s: '28800',
        },
        {
          region: 'Urumqi', gmt_offset_hr: '+08:00', gmt_offset_s: '28800',
        },
        {
          region: 'Irkutsk', gmt_offset_hr: '+09:00', gmt_offset_s: '32400',
        },
        {
          region: 'Osaka', gmt_offset_hr: '+09:00', gmt_offset_s: '32400',
        },
        {
          region: 'Sapporo', gmt_offset_hr: '+09:00', gmt_offset_s: '32400',
        },
        {
          region: 'Seoul', gmt_offset_hr: '+09:00', gmt_offset_s: '32400',
        },
        {
          region: 'Tokyo', gmt_offset_hr: '+09:00', gmt_offset_s: '32400',
        },
        {
          region: 'Adelaide', gmt_offset_hr: '+09:30', gmt_offset_s: '34200',
        },
        {
          region: 'Darwin', gmt_offset_hr: '+09:30', gmt_offset_s: '34200',
        },
        {
          region: 'Brisbane', gmt_offset_hr: '+10:00', gmt_offset_s: '36000',
        },
        {
          region: 'Canberra', gmt_offset_hr: '+10:00', gmt_offset_s: '36000',
        },
        {
          region: 'Guam', gmt_offset_hr: '+10:00', gmt_offset_s: '36000',
        },
        {
          region: 'Hobart', gmt_offset_hr: '+10:00', gmt_offset_s: '36000',
        },
        {
          region: 'Melbourne', gmt_offset_hr: '+10:00', gmt_offset_s: '36000',
        },
        {
          region: 'Port Moresby', gmt_offset_hr: '+10:00', gmt_offset_s: '36000',
        },
        {
          region: 'Sydney', gmt_offset_hr: '+10:00', gmt_offset_s: '36000',
        },
        {
          region: 'Yakutsk', gmt_offset_hr: '+10:00', gmt_offset_s: '36000',
        },
        {
          region: 'New Caledonia', gmt_offset_hr: '+11:00', gmt_offset_s: '39600',
        },
        {
          region: 'Solomon Is.', gmt_offset_hr: '+11:00', gmt_offset_s: '39600',
        },
        {
          region: 'Vladivostok', gmt_offset_hr: '+11:00', gmt_offset_s: '39600',
        },
        {
          region: 'Auckland', gmt_offset_hr: '+12:00', gmt_offset_s: '43200',
        },
        {
          region: 'Fiji', gmt_offset_hr: '+12:00', gmt_offset_s: '43200',
        },
        {
          region: 'Kamchatka', gmt_offset_hr: '+12:00', gmt_offset_s: '43200',
        },
        {
          region: 'Magadan', gmt_offset_hr: '+12:00', gmt_offset_s: '43200',
        },
        {
          region: 'Marshall Is.', gmt_offset_hr: '+12:00', gmt_offset_s: '43200',
        },
        {
          region: 'Wellington', gmt_offset_hr: '+12:00', gmt_offset_s: '43200',
        },
        {
          region: 'Nuku\'alofa', gmt_offset_hr: '+13:00', gmt_offset_s: '46800',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'accountSlug',
      'app',
      'domain',
    ]),
    url() {
      return `${getLiveAppBaseUrl()}/`;
    },
    language: {
      get() {
        return this.localApp.settings.language || 'english';
      },
      set(newVal) {
        this.localApp.settings.language = newVal;
      },
    },
    timezoneOffset() {
      return (this.timezones.filter((timezone) => timezone.region === this.localApp.settings.timezone))[0].gmt_offset_s;
    },
    appNameError() {
      return this.errors.nameTaken || this.errors.nameMissing;
    },
    appURLError() {
      return this.errors.slugTaken || this.errors.slugMissing;
    },
    labelClasses() {
      return 'text-default font-medium mb-2 leading-none';
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'localApp.settings.timezone': function () {
      this.localApp.settings.timezone_offset = this.timezoneOffset;
    },
    // eslint-disable-next-line func-names
    'localApp.name': function (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.errors.nameMissing = undefined;
      }
      if (this.errors.nameTaken) {
        this.errors.nameTaken = undefined;
      }
    },
    // eslint-disable-next-line func-names
    'localApp.slug': function (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.errors.slugMissing = undefined;
      }
      if (this.errors.slugTaken) {
        this.errors.slugTaken = undefined;
      }
    },
  },
  mounted() {
    const $urlOverlay = document.getElementById('url-overlay');
    const $appOverlay = document.getElementById('app-overlay');

    $urlOverlay.style['padding-right'] = 0;

    $appOverlay.classList.remove('pl-3');
    $appOverlay.style['padding-left'] = `${$urlOverlay.offsetWidth}px`;
  },
  created() {
    this.notifyErrors = true;
  },
  methods: {
    onSave() {
      // Dev Note: Don't use the appSlug getter in this function since it might be cached.
      // if the slug has changed we'll need to upate the URL on success below
      const slugHasChanged = (this.localApp.slug !== this.app.slug);
      const oldAppSlug = this.app.slug;

      this.commitRequest({
        validate: () => this.app.validate(this.localApp),
        request: () => this.app.update({
          description: this.localApp.description,
          name: this.localApp.name,
          settings: this.localApp.settings,
          slug: this.localApp.slug,
        }),
        onSuccess: () => {
          document.title = `${this.app.get('name')} - Knack Builder`;

          if (slugHasChanged) {
            const url = window.location.href.replace(
              `/${this.accountSlug}/${oldAppSlug}/`,
              `/${this.accountSlug}/${this.localApp.slug}/`,
            );

            setTimeout(() => {
              window.location.href = url;
            }, 300);
          }
        },
        onError: (errArr) => {
          const errorMessages = {
            nameTaken: 'This app name is already being used',
            slugTaken: 'This app URL is already being used',
            nameMissing: 'Give your app a name',
            slugMissing: 'Give your app a URL',
          };

          errArr.forEach((err) => {
            const errorMessage = typeof err === 'string' ? err : err.message;
            const keys = typeof err === 'string' ? ['nameTaken', 'slugTaken'] : ['nameMissing', 'slugMissing'];

            keys.forEach((key) => {
              // Check if the error message matches one of the error messages we're looking for
              if (errorMessages[key] === errorMessage) {
                this.notifyErrors = false;
                this.errors[key] = errorMessage;
              }
            });
          });
        },
      });
    },
  },
};
</script>

<style lang="scss">
#settings-app > * {
  max-width: 600px;
}

#app-overlay {
  font-weight: 600;
  margin-top: 0.063em;
}

#url-overlay {
  border: 1px solid transparent;
  border-color: transparent;
  box-sizing: border-box;
  color: transparent;
  font-size: 0.95em;
  font-weight: 600;
  line-height: 1.25em;
  overflow: hidden;
  padding: 0.5rem;
  pointer-events: none;
  position: absolute;
  transition: width 2s ease 0s;
  white-space: pre;
}

#url-overlay::before {
  color: #9E9EA6;
  content: attr(data-before);
  font-weight: 400;
}

#url-overlay::after {
  color: #9E9EA6;
  content: attr(data-after);
  font-weight: 400;
}

/* .instructions {
  color: #999;
  font-size: .95em;
  font-style: italic;
  line-height: 1.8em !important;
  margin: 0;
  padding: 0;
} */
</style>
