import Joi from '@hapi/joi';

import api from '@/lib/api-wrapper';
import { getDevUrl } from '@/lib/url-helper';
import FormBase from '@/store/forms/FormBase';

/**
 * The form for getting help with the site.
 */
export default class FormHelpGetHelp extends FormBase {
  /**
   * What the question is about.
   * @type {string}
   */
  helpSubject = '';

  /**
   * The help question.
   * @type {string}
   */
  helpQuestion = '';

  /**
   * Validates the form.
   *
   * @returns {{passed: boolean, errors: Error[]}}
   */
  async validate() {
    const validationSchema = {
      helpSubject: Joi.string().allow('').optional().label('The subject'),
      helpQuestion: Joi.string().allow('').optional().label('Your question'),
    };

    return this._validateForm(validationSchema);
  }

  /**
   * Submits the form to the server.
   *
   * @returns {Promise<Object>}
   */
  async submit() {
    const ticketData = {
      devLink: getDevUrl(),
      helpSubject: this.helpSubject,
      helpQuestion: this.helpQuestion,
    };

    return api.helpGetHelp(ticketData);
  }
}
