<template>
  <div
    class="kn-details-wrapper"
    :class="viewClass"
  >
    <ConfirmModal
      v-if="showConfirmDeleteItem"
      title="Delete this link?"
      confirm-type="alert"
      @close="showConfirmDeleteItem = false"
      @confirm="onConfirmDeleteItem"
    >
      <p>You'll also be deleting any Pages and Views this links to.</p>
      <p class="mb-0">
        <strong>Once the view changes are saved, this can't be undone!</strong>
      </p>
    </ConfirmModal>

    <!-- EMPTY -->
    <template v-if="showEmpty">
      <EmptyFieldsDropZone
        v-if="isEditable"
        :buildable="buildable"
        :build-path="addDetailsFieldsPath"
        :is-dragging-field="isDraggingField"
        :class="{'is-dragging-over': isDraggingField}"
        class="drop-target"
        @dragover="onDragOver"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @drop="onDrop"
      />
    </template>

    <template v-else>
      <ViewUtilityDropdown
        v-if="isEditable"
        :class="{
          'ml-[7px] absolute left-0': shouldShowUtilityDropdown,
          'hidden': !shouldShowUtilityDropdown
        }"
        style="position: absolute; top: 0; right: 0;"
      />
      <template
        v-for="(layout, l_index) in schema"
        :key="l_index"
      >
        <div
          :ref="setItemRef(layout)"
          :class="{'first': l_index === 0 && schema.length > 1 && schema[1].groups.length}"
          class="kn-details-column group-[.has-util-dropdown]:mt-[52px]"
        >
          <template
            v-for="(group, g_index) in layout.groups"
            :key="g_index"
          >
            <!-- NEW GROUP SLOT -->
            <template v-if="showNewGroupSlot(layout, group, g_index)">
              <div
                :class="{'-layoutColumnIsEmpty': layout.groups.length === 1 && layout.groups[0].columns[0].length === 0}"
                class="drop-wrapper horizontal"
              >
                <div
                  class="drop-target horizontal"
                  :data-layout="l_index"
                  :data-group="g_index"
                  :data-column="0"
                  :data-item="0"
                  data-target-type="group-new"
                  @dragover="onDragOver"
                  @dragenter="onDragEnter"
                  @dragleave="onDragLeave"
                  @drop="onDrop"
                >
                  <div class="line" />
                </div>
              </div>
            </template>
            <div
              v-else
              style="height: 12px;"
            />
            <div
              class="kn-details-group"
              :class="'kn-details-group-column-count-' + group.columns.length"
              @dragover="onDragOverGroup"
              @dragleave="onDragLeaveGroup"
              @dragend="onDragEndGroup"
            >
              <template
                v-for="(column, c_index) in group.columns"
                :key="c_index"
              >
                <TransitionGroup
                  name="column-list"
                  tag="div"
                  class="kn-details-group-column draggable-items"
                >
                  <div
                    v-if="c_index === 0"
                    key="left-slot"
                    class="drop-wrapper vertical left"
                  >
                    <div
                      class="drop-target vertical"
                      :data-layout="l_index"
                      :data-group="g_index"
                      :data-column="c_index"
                      :data-item="0"
                      data-target-type="column-new"
                      @dragover="onDragOver"
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    >
                      <div class="line" />
                    </div>
                  </div>
                  <div
                    key="right-slot"
                    class="drop-wrapper vertical right"
                    :class="{'last': c_index + 1 === group.columns.length}"
                  >
                    <div
                      class="drop-target vertical"
                      :data-layout="l_index"
                      :data-group="g_index"
                      :data-column="c_index + 1"
                      :data-item="0"
                      data-target-type="column-new"
                      @dragover="onDragOver"
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    >
                      <div class="line" />
                    </div>
                  </div>

                  <!-- Details Field -->
                  <div
                    v-for="(item, i_index) in column"
                    :key="i_index"
                  >
                    <RouterLink
                      v-slot="{ isActive, navigate }"
                      :to="isEditable ? `${routePrefix}/layouts/${l_index}/groups/${g_index}/columns/${c_index}/items/${i_index}` : `false`"
                      custom
                    >
                      <div
                        class="item-wrapper"
                        :class="{'router-link-active': isActive}"
                        :data-layout="l_index"
                        :data-group="g_index"
                        :data-column="c_index"
                        :data-item="i_index"
                        draggable="true"
                        @click="navigate"
                        @dragstart="onDragStart"
                        @dragend="onDragEnd"
                      >
                        <div
                          class="drop-target top"
                          :data-layout="l_index"
                          :data-group="g_index"
                          :data-column="c_index"
                          :data-item="i_index"
                          @dragover="onDragOver"
                          @dragenter="onDragEnter"
                          @dragleave="onDragLeave"
                          @drop="onDrop"
                        />
                        <div
                          class="drop-target bottom"
                          :data-layout="l_index"
                          :data-group="g_index"
                          :data-column="c_index"
                          :data-item="i_index + 1"
                          @dragover="onDragOver"
                          @dragenter="onDragEnter"
                          @dragleave="onDragLeave"
                          @drop="onDrop"
                        />
                        <template v-if="group.columns.length === 1 && group.columns[0].length > 1">
                          <div
                            class="drop-target left"
                            :data-layout="l_index"
                            :data-group="g_index"
                            :data-column="c_index"
                            :data-item="i_index"
                            data-target-type="column-split"
                            @dragover="onDragOver"
                            @dragenter="onDragEnter"
                            @dragleave="onDragLeave"
                            @drop="onDrop"
                          />
                          <div
                            class="drop-target right"
                            :data-layout="l_index"
                            :data-group="g_index"
                            :data-column="c_index"
                            :data-item="i_index"
                            data-target-type="column-split"
                            @dragover="onDragOver"
                            @dragenter="onDragEnter"
                            @dragleave="onDragLeave"
                            @drop="onDrop"
                          />
                        </template>
                        <DetailsItem
                          :item="item"
                          :is-editable="isEditable"
                          :data="data"
                          :view-label-alignment="view.getDetailsLabelFormat()"
                          :label-width="schemaLabelWidths[l_index] ? schemaLabelWidths[l_index][g_index] : ``"
                          @delete:item="onDeleteItem($event, l_index, g_index, c_index, i_index)"
                        />
                      </div>
                    </RouterLink>
                  </div>
                </TransitionGroup>
              </template>
            </div>

            <!-- LAST NEW GROUP SLOT -->
            <template v-if="g_index === layout.groups.length-1 && group.columns.length > 1">
              <div
                :key="`rightDrop-${g_index}`"
                class="kn-details-group slot"
              >
                <div class="kn-details-group-column slot draggable-items">
                  <div class="drop-wrapper">
                    <div
                      class="drop-target horizontal"
                      :data-layout="l_index"
                      :data-group="g_index + 1"
                      :data-column="0"
                      :data-item="0"
                      data-target-type="group-new"
                      @dragover="onDragOver"
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    >
                      <div class="line" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import hasIn from 'lodash/hasIn';
import { mapMutations } from 'vuex';

import ConfirmModal from '@/components/ui/ConfirmModal';
import DetailsItem from '@/components/renderer/shared/DetailsItem';
import DragDropUtils from '@/components/renderer/mixins/DragDropUtils';
import EmptyFieldsDropZone from '@/components/renderer/shared/EmptyFieldsDropZone';
import ViewUtilityDropdown from '@/components/renderer/shared/ViewUtilityDropdown';
import { eventBus } from '@/store/bus';

// TODO: at such time a UI helper/utility exists this should go there
const PREVIEW_WRAPPER_SELECTOR = '.rendererPane';

export default {
  components: {
    ConfirmModal,
    DetailsItem,
    EmptyFieldsDropZone,
    ViewUtilityDropdown,
  },
  mixins: [
    DragDropUtils,
  ],
  props: {
    emptyTitle: {
      type: String,
      default: '',
    },
    hideEmpty: {
      type: Boolean,
      default: false,
    },
    view: {
      type: Object,
      default: () => ({}),
    },
    schema: { // the schema of the detail layout (2 columns of groups)
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    design: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    routePrefix: {
      type: String,
      default: '',
    },
    shouldShowUtilityDropdown: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'delete',
    'drop',
  ],
  data() {
    return {
      activeItemSlot: null,
      showConfirmDeleteItem: false,
      schemaLabelWidths: [],
      itemRefs: [],
    };
  },
  computed: {
    buildable() {
      return this.emptyTitle || `${this.view.type} view`;
    },
    detailsLayout() {
      return this.view.getDetailsLayout();
    },
    viewClass() {
      const classes = {};

      if (this.design.spacing) {
        classes[`${this.design.spacing.value}-spacing`] = true;
      }

      if (this.detailsLayout) {
        classes[`-layout-${this.detailsLayout}`] = true;
      }

      return classes;
    },
    showEmpty() {
      if (this.hideEmpty) {
        return false;
      }

      return this.fieldsAreEmpty;
    },
    fieldsAreEmpty() {
      const fieldsHaveContent = this.schema.some((layout) => layout.groups.some((group) => group.columns.some((column) => column.length > 0)));

      return !fieldsHaveContent;
    },
    isDraggingField() {
      return this.$store.getters.isDraggingViewItem(this.view.key);
    },
    addDetailsFieldsPath() {
      return this.routePrefix;
    },
    viewLabelFormat() {
      return this.view.getDetailsLabelFormat();
    },
  },
  watch: {

    // ensure any general view updates are reactive in this component
    // TODO: make this more reactive, don't set this.schema to the schema prop in data, which removes reactivity
    schema: {
      handler() {
        // Calculate new label widths, if we're modifying the schema to move from only top or hidden labels we need new values
        this.schemaLabelWidths = this.buildEmptySchemaLabelWidths();

        this.$nextTick(() => {
          this.schemaLabelWidths = this.calculateLabelWidths();
        });
      },
      deep: true,
    },
    viewLabelFormat(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          // Calculate new label widths, if we're modifying the schema to move from only top or hidden labels we need new values
          this.schemaLabelWidths = this.calculateLabelWidths();
        });
      }
    },
  },
  created() {
    this.schemaLabelWidths = this.buildEmptySchemaLabelWidths();
  },
  mounted() {
    this.schemaLabelWidths = this.calculateLabelWidths();

    this.$nextTick(() => {
      this.initDragDrop();
    });

    // bind to add new item if this details is editable
    if (!this.isEditable) {
      return false;
    }
  },
  beforeUnmount() {
    eventBus.$off(`addViewItem.${this.view.key}.details`);
  },
  beforeUpdate() {
    this.itemRefs = [];
  },
  methods: {
    ...mapMutations([
      'addLinkedPageStart',
    ]),
    buildEmptySchemaLabelWidths() {
      return this.schema.reduce((schemaArray, schemaItem) => {
        if (hasIn(schemaItem, 'groups')) {
          schemaArray.push(new Array(schemaItem.groups.length).fill('unset'));
        }

        return schemaArray;
      }, []);
    },
    calculateLabelWidths() {
      // Exit early if there is no layout to calculate on
      if (!this.$refs.layout) {
        return [];
      }

      const newWidths = this.$refs.layout.map((layout, layoutIndex) => {
        const layoutGroups = [
          ...layout.querySelectorAll('.kn-details-group:not(.slot)'),
        ];

        if (layoutGroups.length === 0) {
          return [];
        }

        return layoutGroups.map((group, groupIndex) => {
          const existingValue = this.schemaLabelWidths[layoutIndex][groupIndex];
          const measurableLabels = group.querySelectorAll('.kn-item-labelleft .kn-item_label, .kn-item-labelright .kn-item_label');

          // If we don't have any measuable labels this details item has only top or none values for labels alignments and we don't need to calculate further
          if (measurableLabels.length === 0) {
            return 'unset';
          }

          // NodeLists are iterable but not arrays, spread into an array to utilitze map method
          const measurableLabelWidths = [];

          for (const label of measurableLabels) {
            measurableLabelWidths.push(label.offsetWidth);
          }
          let widestLabelWidth = Math.max(...measurableLabelWidths);

          const wrapperWidth = document.querySelector(PREVIEW_WRAPPER_SELECTOR).offsetWidth;
          const groupColumnCount = this.schema[layoutIndex].groups[groupIndex].columns.length;

          const fallbackWidth = groupColumnCount === 3 ? '17vw' : '30vw';
          const divisionFactor = groupColumnCount === 3 ? 5 : 3;

          if (widestLabelWidth > (wrapperWidth / divisionFactor)) {
            return fallbackWidth;
          }

          if (existingValue === 'unset') {
            widestLabelWidth += 14;
          }

          return widestLabelWidth < 114 ? '170px' : `${widestLabelWidth}px`;
        });
      });

      return newWidths;
    },
    onDeleteItem(event, layoutIndex, groupIndex, columnIndex, itemIndex) {
      // any items that are links to other pages need to be confirmed
      if (this.schema[layoutIndex].groups[groupIndex].columns[columnIndex][itemIndex].type === 'scene_link') {
        this.showConfirmDeleteItem = true;
        this.confirmDeleteItemLocation = {
          layoutIndex,
          groupIndex,
          columnIndex,
          itemIndex,
        };

        return;
      }

      this.$emit('delete', {
        layoutIndex, groupIndex, columnIndex, itemIndex,
      });
    },
    onConfirmDeleteItem() {
      this.showConfirmDeleteItem = false;

      this.$emit('delete', this.confirmDeleteItemLocation);
    },
    // called by dragDropUtils
    onDropViewItem(event, item) {
      event.preventDefault();
      event.stopPropagation();

      // if new item is type link but does not have a scene, this is linking to an existing page and we have to now select it
      if (!item) {
        let newItem = event.dataTransfer.getData('new');

        if (newItem) {
          newItem = JSON.parse(newItem);

          if (newItem.type === 'scene_link' && !newItem.scene) {
            this.addLinkedPageStart(event);

            return;
          }
        }
      }

      this.$emit('drop', {
        event,
        item,
      });
    },
    showNewGroupSlot(layout, group, groupIndex) {
      // Show a new group if this this layout has one group with new fields
      if (layout.groups.length === 1 && group.columns.length === 1 && !group.columns[0].length) {
        return true;
      }

      // Show a new group slot if this group has multiple columns
      if (group.columns.length > 1 && (groupIndex === 0 || (groupIndex > 0 && layout.groups[groupIndex - 1].columns.length > 1))) {
        return true;
      }

      return false;
    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },
  },
};
</script>

<style lang="scss">
.kn-details-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
}

.kn-details-wrapper .kn-item h2 {
  font-size: 1.25em;
}

.kn-details-column {
  flex-grow: 1;
  max-width: 100%;
}

.kn-details-column.first {
  border-right: 1px dashed #e4e6ed;
}

.kn-details-group .view-child-link {
  position: relative;
  float: right;
  margin-left: 8px;
}

.kn-details-group {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin: 0;
  position: relative;

  &.is-dragging-over {
    background-color: #f9f9f9;
  }

  label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.none {
      display: none;
    }

    &.left {
      float: left;
      margin-right: 8px;
    }

    &.right {
      float: left;
      text-align: right;
    }

    &.top {
      float: none;
      padding-left: 0;
      width: inherit;
    }
  }

  .kn-value {
    padding: .375em;
  }

  .kn-details-group-column {
    //padding: 4px;
    flex-grow: 1;
    position: relative;
    padding-right: 10px;

    &:last-child {
      padding-right: 0;
    }

    &.slot {
      flex-grow: 0;
    }
    &.slot.closed {
      width: 17px;
    }

    .item-wrapper {

      padding-top: 0;
      padding-bottom: 0;

      .kn-item {
        padding-top: 0;
        padding-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #444d75;
        opacity: 0;
        z-index: 2;
        border-radius: .3em;
        border-top-left-radius: 0;
        transition: all 200ms ease-out;
        cursor: move;
      }
    }
  }
}

.kn-details-group.slot .kn-details-group-column {
  flex-grow: 1;
}
.kn-details-group.slot {
  width: 100%;
  height: 12px;
}

.view .kn-details-group .kn-item {
  padding: 0;
  max-width: 600px;
  border-top-left-radius: 0;
  border-color: rgba(255, 255, 255, 0);
  display: flex;
  margin: 0;

  h1, h2 {
    margin-bottom: 0;
  }

  label {
    display: block;
    font-weight: 700;
    padding: .375em;
    background-color: rgba(228, 228, 228, 0.5);
    min-width: 170px;
  }

  item, textarea {
    border: 0;
    padding: 0.4em .5em;
    font-size: 1em;
    line-height: 1.25em;
    border-radius: .3em;
    color: #4b5054;
    width: 100%;
    border: 1px solid #d6d6de;
    font-weight: 300;
  }
}

.kn-details-group {
  .large-items {
    item, textarea {
      padding: 0.6em .75em;
      font-size: 1em;
      line-height: 1.45em;
      border-radius: .4em;
    }
  }

  .large-spacing {
    .kn-item {
      padding: 10px 6px;
    }
  }
  .small-spacing {
    .kn-item {
      padding: 2px 6px;
    }
  }

  .small-items {
    item, textarea {
      padding: 0.3em .4em;
      font-size: .86em;
      line-height: 1.2em;
      border-radius: .25em;
    }
  }
}

.kn-details-wrapper .drop-wrapper.horizontal.-layoutColumnIsEmpty {
  height: 100%;
  min-height: 200px;
  width: 100%;
  min-width: 200px;
  visibility: visible;

  .drop-target {
    width: 100%;
    height: 100%;
  }
}

.kn-details-group.is-dragging-over {
  .-layoutColumnIsEmpty {
    background-color: #f9f9f9;
  }
}

.kn-details-column {
  min-width: min-content;
}

.kn-details-wrapper.-layout-one_quarter {

  .kn-details-column.first {
    max-width: 25%;
    width: 25%;
  }
  .kn-details-column:not(.first) {
    max-width: 75%;
    width: 75%;
  }
}

.kn-details-wrapper.-layout-one_third {

  .kn-details-column.first {
    max-width: 33%;
    width: 33%;
  }
  .kn-details-column:not(.first) {
    max-width: 67%;
    width: 67%;
  }
}

.kn-details-wrapper.-layout-half {

  .kn-details-column.first {
    max-width: 50%;
    width: 50%;
  }
  .kn-details-column:not(.first) {
    max-width: 50%;
    width: 50%;
  }
}

.kn-details-wrapper.-layout-three_quarters {

  .kn-details-column.first {
    max-width: 75%;
    width: 75%;
  }
  .kn-details-column:not(.first) {
    max-width: 25%;
    width: 25%;
  }
}

.kn-details-wrapper.-layout-two_thirds {

  .kn-details-column.first {
    max-width: 67%;
    width: 67%;
  }
  .kn-details-column:not(.first) {
    max-width: 33%;
    width: 33%;
  }
}
</style>
