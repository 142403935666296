<template>
  <ViewToolbox
    back-title="Report Menu"
    title="Report View Settings"
  >
    <form>
      <div>
        <label>Title</label>
        <input
          v-model="view.attributes.title"
          type="text"
        >
      </div>
      <div>
        <label>Description</label>
        <textarea v-model="view.attributes.description" />
      </div>
    </form>
  </ViewToolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'ReportSettings',
  components: {
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
};
</script>

<style lang="scss">
</style>
