import { mapGetters } from 'vuex';
import { liveAppUrl } from '@/lib/url-helper';

export default {
  computed: {
    ...mapGetters([
      'activePage',
    ]),
    ...mapGetters('page', [
      'startPages',
    ]),

    /**
     * Gets the list of pages that should be shown for live app links.
     *
     * @returns {RawPage[]}
     */
    pageList() {
      return this.startPages;
    },

    /**
     * Gets the slug of the first page in the pages list.
     *
     * @returns {string | undefined}
     */
    firstPageSlug() {
      /** @type {RawPage[]} pages */
      const pages = this.pageList;
      const hasPages = pages.length > 0;

      // if we have no pages return false
      if (!hasPages) {
        return undefined;
      }

      const firstPage = pages[0];

      // if the first page is an authentication page return first child
      if (firstPage.isLoginPage()) {
        return firstPage.children[0].slug;
      }

      return firstPage.slug;
    },

    /**
     * Gets the live app link for the active page.
     *
     * @returns {string}
     */
    getActivePageLiveAppLink() {
      if (!this.activePage) {
        // No active page, so link to the first page.
        return this.getPageLiveAppLink(this.firstPageSlug);
      }

      const pageSlug = this.activePage.getSlugPathForLiveAppUrl();

      return this.getPageLiveAppLink(pageSlug || this.firstPageSlug);
    },
  },
  methods: {

    /**
     * Gets the live app url for the given page slug.
     *
     * @param {string} pageSlug
     * @returns {string}
     */
    getPageLiveAppLink(pageSlug) {
      return liveAppUrl(pageSlug);
    },
  },
};
