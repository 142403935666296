<template>
  <form
    id="page-menu-edit"
    style="margin-top: 0;"
  >
    <p class="font-medium">
      Choose the pages this drop-down menu will display:
    </p>
    <PageDropDownMenuSelector
      :page="page"
    />
    <div class="mt-4">
      <a
        class="button medium save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium"
        data-cy="save-app-settings"
        @click.prevent="updateMenuPages"
      >
        Save
      </a>
    </div>
  </form>
</template>

<script>
import PageDropDownMenuSelector from '@/components/pages/PageDropDownMenuSelector';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  components: {
    PageDropDownMenuSelector,
  },
  mixins: [
    RequestUtils,
  ],
  computed: {
    page() {
      return this.$store.getters.activePage;
    },
  },
  methods: {
    updateMenuPages() {
      const updates = {
        pageKey: this.page.key,
        menuPages: this.page.menu_pages,
      };

      // name is included in v2 request, might not be needed
      this.commitRequest({
        request: () => this.$store.dispatch('page/api/updateMenuPages', updates),
      });
    },
  },
};
</script>

<style lang="scss">
#page-menu-edit {
  & > div {
    margin-bottom: .5em;
  }
  label {
    margin-bottom: .75em;
  }
  label.disabled {
    color: #ccc;
  }
}
</style>
