<template>
  <Toolbox
    id="view-toolbox"
    :title="title"
    :back-title="backTitle"
    :back-link="backLink"
    :back-count="backCount"
    class="no-padding"
    :class="{
      'has-active-updates': hasActiveUpdates,
      'pb-10': isToolboxSaveRendered
    }"
    :theme="theme"
  >
    <FormWrapper
      :errors="errors"
      @submit="onFormSubmit"
    >
      <slot />
    </FormWrapper>
  </Toolbox>
</template>

<script>
import FormWrapper from '@/components/ui/FormWrapper';
import Toolbox from '@/components/layout/Toolbox';
import { eventBus } from '@/store/bus';

export default {
  components: {
    Toolbox,
    FormWrapper,
  },
  props: {
    backTitle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'active',
    },
    backCount: {
      type: Number,
      default: 1,
    },
    backLink: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    onSubmit: {
      type: Function,
      default: undefined,
    },
  },
  computed: {
    hasActiveUpdates() {
      return this.$store.getters.viewHasActiveUpdates;
    },
    isToolboxSaveRendered() {
      const { getters } = this.$store;

      return getters.viewHasActiveUpdates || getters.pageHasActiveUpdates;
    },
  },
  methods: {
    navigateBack() {
      const pathParts = this.$route.path.split('/');
      const pathStepsBack = this.backCount;

      log(`pathParts.length: ${pathParts.length}; pathStepsBack ${pathStepsBack}; sliced: ${pathParts.slice(0, pathParts.length - pathStepsBack).join('/')}`);

      if (pathParts.indexOf('pages') > -1 && pathParts.length <= 6) {
        return this.$router.push(`/pages/${this.$route.params.pageKey}`);
      }

      return this.$router.push(pathParts.slice(0, pathParts.length - pathStepsBack).join('/'));
    },
    onFormSubmit() {
      // Call function passed in as a prop if there is one. Otherwise, emit default event
      if (this.onSubmit) {
        this.onSubmit();
      } else {
        // Pages.vue listens for this to submit a form save
        eventBus.$emit('saveViewFromFormEnter');
      }
    },
  },
};
</script>

<style lang="scss">
.has-active-updates {
  .toolbox-body {
    margin-bottom: 40px;

    > form {
      font-size: .9rem;
    }
  }
}

#view-toolbox {
  font-size: .9rem;
  z-index: 2;
}
</style>
