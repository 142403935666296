<template>
  <div v-if="notification !== undefined">
    <JobNotificationGeneric
      :notification="notification"
      :object="object"
      @close-clicked="handleCloseClicked"
      @confirm-cancel-clicked="handlecConfirmCancelClicked"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import JobNotificationGeneric from '@/components/ui/notifications/jobs/JobNotificationGeneric';

export default {
  components: {
    JobNotificationGeneric,
  },
  props: [
    'notification',
    'object',
  ],
  emits: ['clear-fetched-notification'],
  data: () => ({
    shouldShowCancelConfirmation: false,
  }),
  computed: {
    primaryIcon() {
      switch (this.notification.jobType) {
        case 'import':

          return 'cloud-import';

        case 'update':

          return 'edit';

        case 'delete':

          return 'delete';

        case 'task':

          return 'action-task';

        default:

          return 'info-circle';
      }
    },
    timeago() {
      return moment.unix(this.notification.timestamp).fromNow();
    },
    percentageComplete() {
      return `${Math.round((this.notification.processed_count / this.notification.total_count) * 100)}%`;
    },
    shouldDisplayLoading() {
      log('shouldDisplayLoading():', this.object.status !== 'current' && isEmpty(this.notification));

      return this.object.status !== 'current' && isEmpty(this.notification);
    },
  },
  methods: {
    toggleCancelConfirmation() {
      this.shouldShowCancelConfirmation = !this.shouldShowCancelConfirmation;
    },
    handleCloseClicked() {
      this.$store.dispatch('notifications/clearSocketNotificationsForObject', this.object);
      this.$emit('clear-fetched-notification');
    },
    handlecConfirmCancelClicked(jobType) {
      const payload = {
        job_id: this.notification.job_id,
      };

      const socketEventToEmit = jobType === 'import' ? 'csv:import:cancel' : `batch:${jobType}:cancel`;

      this.$socket.emit(socketEventToEmit, payload);

      this.toggleCancelConfirmation();
    },
  },
};
</script>

<style lang="scss" scoped>
.socketNotification_icon {
  display: table-cell;
  vertical-align: top;
  padding-right: $spacing-xs;
}

</style>
