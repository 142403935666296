<script setup>
import range from 'lodash/range';
import {
  computed,
  defineEmits,
  defineProps,
  reactive,
  toRef,
  watch,
} from 'vue';

import RatingImage from '@/components/ui/rating/RatingImage';

const ICONS = Object.freeze({
  star: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjYiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyNiAyOCI+PHBhdGggZmlsbD0iI2ZkYTQwMCIgZD0iTTI2LjAwMiAxMC4xMDlxMCAwLjM0NC0wLjQwNiAwLjc1bC01LjY3MyA1LjUzMiAxLjM0NCA3LjgxNHEwLjAxNiAwLjEwOSAwLjAxNiAwLjMxMyAwIDAuMzI4LTAuMTY0IDAuNTU1dC0wLjQ3NyAwLjIyN3EtMC4yOTcgMC0wLjYyNS0wLjE4OGwtNy4wMTctMy42ODgtNy4wMTcgMy42ODhxLTAuMzQ0IDAuMTg4LTAuNjI1IDAuMTg4LTAuMzI4IDAtMC40OTItMC4yMjd0LTAuMTY0LTAuNTU1cTAtMC4wOTQgMC4wMzEtMC4zMTNsMS4zNDQtNy44MTQtNS42ODgtNS41MzJxLTAuMzkxLTAuNDIyLTAuMzkxLTAuNzUgMC0wLjU3OCAwLjg3NS0wLjcxOWw3Ljg0NS0xLjE0MSAzLjUxNi03LjExcTAuMjk3LTAuNjQxIDAuNzY2LTAuNjQxdDAuNzY2IDAuNjQxbDMuNTE2IDcuMTEgNy44NDUgMS4xNDFxMC44NzUgMC4xNDEgMC44NzUgMC43MTl6Ij48L3BhdGg+PC9zdmc+',
});

const emit = defineEmits([
  'highlight:rating',
  'update:rating',
]);

const props = defineProps({
  increment: {
    type: Number,
    default: 1,
  },
  rating: {
    type: Number,
    default: 0,
  },
  activeColor: {
    type: String,
    default: '#ffd055',
  },
  inactiveColor: {
    type: String,
    default: '#d8d8d8',
  },
  maxRating: {
    type: Number,
    default: 5,
  },
  itemSize: {
    type: Number,
    default: 50,
  },
  showRating: {
    type: Boolean,
    default: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  textClass: {
    type: String,
    default: '',
  },
  inline: {
    type: Boolean,
    default: false,
  },
  borderColor: {
    type: String,
    default: '#999',
  },
  borderWidth: {
    type: Number,
    default: 2,
  },
  spacing: {
    type: Number,
    default: 0,
  },
  fixedPoints: {
    type: Number,
    default: null,
  },
  rtl: {
    type: Boolean,
    default: false,
  },
  backgroundOpacity: {
    type: Number,
    default: 0.2,
  },
  iconType: {
    type: String,
    default: () => 'star',
    validator: (value) => ['star'].includes(value),
  },
});

/* Methods */

const round = (rating) => {
  const inv = 1.0 / props.increment;

  return Math.min(props.maxRating, Math.ceil(rating * inv) / inv);
};

const createRatings = (currentRating) => (
  range(0, props.maxRating).map((aRating) => {
    let level = 0;
    if (aRating < currentRating) {
      level = (currentRating - aRating > 1) ? 100 : (currentRating - aRating) * 100;
    }

    return Math.round(level);
  })
);

/* State */

const startRating = round(toRef(props, 'rating').value);

const data = reactive({
  step: props.increment * 100,
  fillLevel: createRatings(startRating),
  currentRating: startRating,
  selectedRating: startRating,
  customProps: {
    opacity: toRef(props, 'backgroundOpacity'),
    src: ICONS[toRef(props, 'iconType').value],
  },
});

const formattedRating = computed(() => (
  (props.fixedPoints === null) ? data.currentRating : data.currentRating.toFixed(props.fixedPoints)
));

const setRating = (newRating, persist) => {
  data.currentRating = round(newRating);

  data.fillLevel = createRatings(data.currentRating);

  if (persist) {
    data.selectedRating = data.currentRating;
    emit('update:rating', data.selectedRating);
  } else {
    emit('highlight:rating', data.currentRating);
  }
};

const onSelected = ($event, persist) => {
  if (props.readOnly) {
    return;
  }

  const position = (props.rtl) ? (100 - $event.position) / 100 : $event.position / 100;

  const current = (($event.id + position) - 1).toFixed(2);
  const newRating = (current > props.maxRating) ? props.maxRating : current;

  setRating(newRating, persist);
};

const resetRating = () => {
  if (props.readOnly) {
    return;
  }

  data.currentRating = round(data.selectedRating);
  data.fillLevel = createRatings(data.currentRating);
};

/* Watchers */

// Watch for the view props to change and update the calendar if it does.
watch(() => props.rating, (newValue) => {
  setRating(newValue, true);
});

</script>

<script>
export default {
  name: 'RateIt',
};
</script>

<template>
  <div
    :class="[
      'v-rate-it--rating',
      {'v-rate-it--rtl': rtl},
      {'v-rate-it--inline': inline},
      'v-rate-it--rating-container'
    ]"
  >
    <div
      class="v-rate-it--rating"
      @mouseleave="resetRating"
    >
      <div
        v-for="n in maxRating"
        :key="n"
        :class="[{'v-rate-it--pointer': !readOnly }, 'v-rate-it--rating-item']"
      >
        <RatingImage
          :fill="data.fillLevel[n-1]"
          :index="n"
          :custom-props="data.customProps"
          :step="data.step"
          :active-color="props.activeColor"
          :inactive-color="props.inactiveColor"
          :border-color="props.borderColor"
          :border-width="props.borderWidth"
          :rtl="props.rtl"
          :size="props.itemSize"
          :spacing="props.spacing"
          @selected="onSelected($event, true)"
          @mouse-move="onSelected"
        />
      </div>
      <span
        v-if="showRating"
        :class="['v-rate-it--rating-text', textClass]"
      > {{ formattedRating }}</span>
    </div>
  </div>
</template>

<style type="scss" scoped>
.v-rate-it--rating-item {
  display: inline-block;
}

.v-rate-it--pointer {
  cursor: pointer;
}

.v-rate-it--rating {
  display: flex;
  align-items: center;
}

.v-rate-it--inline {
  display: inline-flex;
}

.v-rate-it--rating-text {
  margin-top: 7px;
  margin-left: 7px;
}

.v-rate-it--rtl {
  direction: rtl;
}

.v-rate-it--rtl .v-rate-it--rating-text {
  margin-right: 10px;
  direction: rtl;
}
</style>
