import TOC from '@/components/views/details/TOC';
import Build from '@/components/views/details/Build';
import Rules from '@/components/views/details/DisplayRules';
import ItemProperties from '@/components/views/details/ItemProperties';
import Settings from '@/components/views/details/Settings';
import Design from '@/components/views/details/Design';

export default [
  {
    path: ':pageKey/views/:viewKey/details',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/details/items',
    components: {
      toolbox: Build,
    },
  },
  {
    path: ':pageKey/views/:viewKey/details/rules',
    components: {
      toolbox: Rules,
    },
  },
  {
    path: ':pageKey/views/:viewKey/details/items/layouts/:layoutIndex/groups/:groupIndex/columns/:columnIndex/items/:itemIndex',
    name: 'detailsInputProperties',
    components: {
      toolbox: ItemProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/details/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/details/design',
    components: {
      toolbox: Design,
    },
  },
];
