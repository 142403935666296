<template>
  <ConfirmModal
    v-if="isIdle"
    title="Inactivity Logout"
    confirm-text="Remain Logged In"
    cancel-text="Log Out Now"
    @confirm="confirmStayLoggedIn"
    @cancel="logout"
    @close="confirmStayLoggedIn"
  >
    <p class="mb-0">
      You will be logged out in {{ secondsToDisplayIdlePopup - idleSeconds }} seconds.
    </p>
  </ConfirmModal>
</template>

<script>
import { mapGetters } from 'vuex';
import RequestUtils from '@/components/util/RequestUtils';
import ConfirmModal from '@/components/ui/ConfirmModal';
import { isDevelopment } from '@/lib/env-helper';

export default {
  components: {
    ConfirmModal,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    secondsToDisplayIdleAlert: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      isIdle: false,
      idleSeconds: 0,
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    secondsToDisplayIdlePopup() {
      // In order to test inactivity within cypress, we want the popup timeout to occur faster.
      if (this.$route.query.isCypressTest) {
        return 5;
      }

      return this.secondsToDisplayIdleAlert;
    },
    accountId() {
      return this.app.get('account').id;
    },
    lastEventItemKey() {
      // this will need expanded logic if renderer ever uses it
      return `${this.accountId}-user-last_event`;
    },
    secondsUntilLogout() {
      // In order to test inactivity within cypress, we want to drastically lower the logout wait.
      if (this.$route.query.isCypressTest) {
        // Cypress will only have to wait 1 second before the popup appears.
        return this.secondsToDisplayIdlePopup + 1;
      }

      if (isDevelopment()) {
        return 3600;
      }

      // convert timeout from minutes to seconds
      const timeout = parseInt(_.get(this.app.get('settings'), 'inactivity_timeout')) * 60;

      return timeout;
    },
  },
  created() {
    // if there is no inactivity timeout set on the app schema, abort loading any further
    if (_.get(this.app.get('settings'), 'inactivity_timeout_enabled', false) === false) {
      return;
    }

    // put an initial event with the current time in to local storage. we constantly update this value as the
    // user interacts with the browser. once this value becomes stale we render a warning modal and eventually log out
    this.refreshLastEventTime();

    // finally start the interval for checking the staleness of the time set in local storage via our previous
    // call to refreshLastEventTime and other browser interactions
    this.waitToAlert();
  },
  mounted() {
    this.bindRefresh();
  },
  methods: {
    logout() {
      this.unbindRefresh();
      clearTimeout(this.alertTimeout);
      clearInterval(this.alertInterval);
      this.isIdle = false;

      this.commitRequest({
        request: () => this.app.logout(),
        onSuccess: () => this.$router.push(`/logout?redirect=${this.$route.path}`),
      });
    },
    bindRefresh() {
      document.addEventListener('mousemove', this.refreshLastEventTime);
      document.addEventListener('scroll', this.refreshLastEventTime);
      document.addEventListener('keydown', this.refreshLastEventTime);
    },
    unbindRefresh() {
      document.removeEventListener('mousemove', this.refreshLastEventTime);
      document.removeEventListener('scroll', this.refreshLastEventTime);
      document.removeEventListener('keydown', this.refreshLastEventTime);
    },
    waitToAlert() {
      const lastEventTimeMS = parseInt(localStorage.getItem(this.lastEventItemKey));

      if (!lastEventTimeMS) {
        this.refreshLastEventTime();
        this.waitToAlert();

        return;
      }

      const currentTime = new Date().getTime();
      const logoutTime = (this.secondsUntilLogout * 1000) + lastEventTimeMS;
      const alertTime = logoutTime - (this.secondsToDisplayIdlePopup * 1000);

      if (currentTime >= alertTime) {
        this.unbindRefresh();
        this.isIdle = true;
        this.idleSeconds = 0;

        this.alertInterval = setInterval(this.alertCountDown, 1000);

        return;
      }

      const msToWaitBeforeAlert = logoutTime - currentTime - (this.secondsToDisplayIdlePopup * 1000);

      this.alertTimeout = setTimeout(this.waitToAlert, msToWaitBeforeAlert);
    },
    alertCountDown() {
      this.idleSeconds += 1;

      if (this.secondsToDisplayIdlePopup - this.idleSeconds <= 0) {
        return this.logout();
      }
    },
    confirmStayLoggedIn() {
      this.isIdle = false;
      this.idleSeconds = 0;
      this.bindRefresh();
      this.refreshLastEventTime();

      clearInterval(this.alertInterval);

      this.waitToAlert();
    },
    refreshLastEventTime() {
      localStorage.setItem(this.lastEventItemKey, new Date().getTime());
    },
  },
};
</script>
