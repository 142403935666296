<template>
  <table
    id="import-preview-table"
    class="table rounded-lg"
  >
    <thead>
      <tr>
        <th
          v-for="(header, headerIndex) in tableHeaders"
          :key="headerIndex"
          :class="{ 'border-l-0': headerIndex === 0 }"
        >
          <div>
            <div class="light text-default">
              {{ headerIndex + 1 }}
            </div>
            <div>{{ header }}</div>
            <div class="is-checked">
              <Icon
                v-if="isColumnMappedToField(headerIndex)"
                v-tippy
                content="This column is mapped to a Knack field"
                type="task-check"
              />
              <Icon
                v-if="isColumnAdded(headerIndex)"
                v-tippy
                class="text-default"
                content="This column is being added as a new Knack field"
                type="add"
              />
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(record, recordIndex) in records"
        :key="recordIndex"
      >
        <td
          v-for="(cell, cellIndex) in record"
          :key="cellIndex"
          v-html="cell"
        />
      </tr>
    </tbody>
  </table>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    availableColumns: {
      type: Array,
      default: () => [],
    },
    mappedFieldIndexes: {
      type: Array,
      default: () => [],
    },
    addedColumnIndexes: {
      type: Array,
      default: () => [],
    },
    step: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    isColumnAdded(columnIndex) {
      return (this.step === 'map' && this.addedColumnIndexes.includes(columnIndex));
    },

    isColumnMappedToField(columnIndex) {
      return (this.step === 'map' && this.mappedFieldIndexes.includes(columnIndex));
    },
  },
};
</script>

<style lang="scss">
#import-preview-table {
  .light {
    margin-right: 5px;
  }
  .is-checked {
    color: #25a593;
    width: 16px;
    height: 20px;
    padding-top: 2px;

    svg {
      height: 14px;
      width: 14px;
      margin-left: 5px;
    }
  }
}
</style>
