import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { computed } from 'vue';
import { useStore } from 'vuex';

import { isCRM } from '@/lib/env-helper';

export default {
  name: 'IfPlan',
  props: {
    levelIsMinimumOf: {
      type: Number,
      default: () => undefined,
    },
    levelIsMaximumOf: {
      type: Number,
      default: () => undefined,
    },
    isTrial: {
      type: Boolean,
      default: () => undefined,
    },
    isStarter: {
      type: Boolean,
      default: () => undefined,
    },
    allowIfBeta: {
      type: Boolean,
      default: () => false,
    },
    allowIfSso: {
      type: Boolean,
      default: () => false,
    },
    disallowIfHipaa: {
      type: Boolean,
      default: () => false,
    },
    disallowIfPrivate: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const store = useStore();

    const isAllowed = computed(() => {
      const { app } = store.getters;

      const planIsLoaded = () => get(app, 'attributes.account.product_plan');
      if (!planIsLoaded) {
        return false;
      }

      const accountIsSso = Boolean(get(app, 'attributes.account.sso'));
      const accountStatus = get(app, 'attributes.account.status');
      const planLevel = get(app, 'attributes.account.product_plan.level') || 0;
      const planIsTrial = (get(app, 'attributes.account.product_plan.id') === 'trial');
      const planIsStarter = (get(app, 'attributes.account.product_plan.id').startsWith('starter'));
      const accountIsHipaa = get(app, 'attributes.account.settings.hipaa.enabled');
      const accountIsPrivate = get(app, 'attributes.settings.cluster') === 'custom';

      if (isCRM()) {
        return true;
      }

      if (props.allowIfBeta && accountStatus === 'beta') {
        return true;
      }

      if (props.allowIfSso && accountIsSso) {
        return true;
      }

      if (!isNil(props.levelIsMinimumOf) && planLevel < props.levelIsMinimumOf) {
        return false;
      }

      if (!isNil(props.levelIsMaximumOf) && planLevel > props.levelIsMaximumOf) {
        return false;
      }

      if (!isNil(props.isTrial) && planIsTrial !== props.isTrial) {
        return false;
      }

      if (!isNil(props.isStarter) && planIsStarter !== props.isStarter) {
        return false;
      }

      if (props.disallowIfHipaa && accountIsHipaa) {
        return false;
      }

      if (props.disallowIfPrivate && accountIsPrivate) {
        return false;
      }

      return true;
    });

    return {
      isAllowed,
    };
  },
  render() {
    if (!this.isAllowed) {
      return null;
    }

    return this.$slots.default();
  },
};
