<template>
  <div class="toolbox-wrapper">
    <Toolbox
      back-title="design"
      title="Header & Page Menu"
    >
      <p>Customize the app header and page menu</p>

      <ToggleSwitch
        v-model:checked="isLegacyHeader"
        label="Use Legacy Design Settings"
        toggle-switch-test-id="legacy-header-toggle"
        description-classes="mb-4"
      >
        <template #offDescription>
          This app's header uses <strong>modern</strong> design settings
        </template>
        <template #description>
          This app's header uses <strong>legacy</strong> design settings
        </template>
      </ToggleSwitch>

      <hr class="border-0 border-t border-solid border-subtle">

      <!-- Legacy design settings -->
      <template v-if="isLegacyHeader">
        <Toggle toggle-content-classes="px-4">
          <template #title>
            <span class="text-base text-emphasis font-semibold">
              Header
            </span>
          </template>
          <template #content>
            <div>
              <div class="customization-options m-0">
                <AppDesignPropertyPicker
                  design-property="regions.header.legacySettings.bg_color"
                  label-text="background color"
                  input-type="color"
                  @input="onColorHasChanged"
                />
                <AppDesignPropertyPicker
                  design-property="regions.header.legacySettings.title.color"
                  label-text="text color"
                  input-type="color"
                  @input="onColorHasChanged"
                />
                <AppDesignPropertyPicker
                  design-property="regions.header.legacySettings.title.show_logo"
                  label-text="title display"
                  :options="legacyTitleDisplayOptions"
                />
                <AppDesignPropertyPicker
                  v-if="app.design.regions.header.legacySettings.title.show_logo"
                  design-property="regions.header.legacySettings.title.logo"
                  label-text="custom logo"
                  input-type="image"
                />
              </div>
            </div>
          </template>
        </Toggle>

        <hr class="border-0 border-t border-solid border-subtle">

        <Toggle toggle-content-classes="px-4" style="margin-bottom: 2rem">
          <template #title>
            <span class="text-base text-emphasis font-semibold">
              Page Menu
            </span>
          </template>
          <template #content>
            <div>
              <div class="customization-options m-0">
                <AppDesignPropertyPicker
                  design-property="regions.header.legacySettings.menu.show"
                  description-text="Show a menu link for each start page"
                  input-type="checkbox"
                  is-inline
                />
                <template v-if="app.design.regions.header.legacySettings.menu.show">
                  <AppDesignPropertyPicker
                    design-property="regions.header.legacySettings.menu.user_auth_based"
                    description-text="Only show links the user has access to"
                    input-type="checkbox"
                    data-cy="legacy-header-page-menu-auth-based"
                  />
                  <AppDesignPropertyPicker
                    design-property="regions.header.legacySettings.menu.format"
                    label-text="format"
                    :options="[`buttons`, `tabs`]"
                  />
                  <AppDesignPropertyPicker
                    design-property="regions.header.legacySettings.menu.color"
                    label-text="text color"
                    input-type="color"
                  />
                  <AppDesignPropertyPicker
                    design-property="regions.header.legacySettings.menu.outline_or_fill_color"
                    label-text="fill color"
                    input-type="color"
                  />
                </template>
              </div>
            </div>
          </template>
        </Toggle>
      </template>

      <!-- Modern design settings -->
      <template v-else>
        <!-- Header -->
        <Toggle toggle-content-classes="px-4">
          <template #title>
            <span class="text-base text-emphasis font-semibold">
              Header
            </span>
          </template>
          <template #content>
            <AppDesignPropertyPicker
              design-property="regions.header.backgroundColor"
              label-text="Background Color"
              input-type="color"
              @input="onColorHasChanged"
            />
            <AppDesignPropertyPicker
              design-property="regions.header.options.bottomBorder.active"
              label-text="Options"
              description-text="Bottom Border"
              input-type="checkbox"
              is-inline
            />
            <div
              v-if="app.design.regions.header.options.bottomBorder.active"
              class="selectionGroup mt-0 mb-2"
              style="padding-bottom: 0"
            >
              <AppDesignPropertyPicker
                design-property="regions.header.options.bottomBorder.color"
                input-type="color"
              />
            </div>
            <AppDesignPropertyPicker
              design-property="regions.header.options.raised"
              description-text="Raised"
              input-type="checkbox"
              is-inline
            />
            <AppDesignPropertyPicker
              design-property="regions.header.options.sticky"
              description-text="Sticky"
              input-type="checkbox"
              help-text="Header remains fixed at the top when users scroll"
            />
          </template>
        </Toggle>

        <hr class="border-0 border-t border-solid border-subtle">

        <!-- Layout -->
        <Toggle
          v-if="shouldShowLayoutToggle"
          :is-open="false"
          toggle-content-classes="px-4"
        >
          <template #title>
            <span class="text-base text-emphasis font-semibold">
              Layout
            </span>
          </template>
          <template #content>
            <AppDesignPropertyPicker
              v-if="displayPageMenu && (displayHeaderTitle || displayAppLogo)"
              design-property="regions.header.layout.menuPosition"
              label-text="Page Menu Position"
              input-type="choice"
              :options="menuPositionOptions"
            />
            <AppDesignPropertyPicker
              v-if="displayPageMenu"
              design-property="regions.header.layout.menuAlignment"
              label-text="Page Menu Alignment"
              input-type="choice"
              :options="menuAlignmentOptions"
            />
            <AppDesignPropertyPicker
              v-if="shouldShowTitleLogoAlignmentOption"
              design-property="regions.header.layout.titleAlignment"
              label-text="Title & Logo Alignment"
              input-type="choice"
              :options="titleAlignmentOptions"
            />
          </template>
        </Toggle>

        <hr class="border-0 border-t border-solid border-subtle">

        <!-- Title -->
        <Toggle
          :is-open="false"
          toggle-content-classes="px-4"
        >
          <template #title>
            <span class="text-base text-emphasis font-semibold">
              Title
            </span>
          </template>
          <template #content>
            <ToggleSwitch
              v-model:checked="displayHeaderTitle"
              label="Display Header Title"
              toggle-switch-test-id="header-display-title-toggle"
            >
              <template #offDescription>
                The header title is <strong>hidden</strong>.
              </template>
              <template #description>
                The header title is <strong>visible</strong>.
              </template>
            </ToggleSwitch>
            <template v-if="displayHeaderTitle">
              <AppDesignPropertyPicker
                design-property="regions.header.title.size"
                label-text="Size"
                input-type="choice"
                :options="sizeOptions"
              />
              <AppDesignPropertyPicker
                design-property="regions.header.title.color"
                label-text="Text Color"
                input-type="color"
                @input="onColorHasChanged"
              />
              <AppDesignPropertyPicker
                design-property="regions.header.title.options.bold"
                label-text="Text Options"
                description-text="Bold"
                input-type="checkbox"
                is-inline
              />
              <AppDesignPropertyPicker
                design-property="regions.header.title.options.uppercase"
                description-text="Uppercase"
                input-type="checkbox"
              />

              <!--  Custom title -->
              <ToggleSwitch
                v-model:checked="isCustomTitle"
                label="Use Custom Title"
                toggle-switch-test-id="header-use-custom-title-toggle"
              >
                <template #offDescription>
                  The header uses the
                  <router-link
                    class="text-default underline"
                    to="/settings/app"
                  >
                    global app name
                  </router-link>
                  as the header title.
                </template>
                <template #description>
                  The header uses a custom title.
                </template>
              </ToggleSwitch>
              <AppDesignPropertyPicker
                v-if="isCustomTitle"
                design-property="regions.header.title.text"
                label-text="Custom Header Title"
                description-text="This is also used for the logo's 'title' attribute."
                input-type="text"
                input-placeholder-text="Title"
                full-width-text-input
              />
            </template>
          </template>
        </Toggle>

        <hr class="border-0 border-t border-solid border-subtle">

        <!-- Logo -->
        <Toggle
          :is-open="false"
          toggle-content-classes="px-4"
        >
          <template #title>
            <span class="text-base text-emphasis font-semibold">
              Logo
            </span>
          </template>
          <template #content>
            <ToggleSwitch
              v-model:checked="displayAppLogo"
              label="Display Logo"
              toggle-switch-test-id="header-display-logo-toggle"
            >
              <template #offDescription>
                The logo is <strong>hidden</strong>.
              </template>
              <template #description>
                The logo is <strong>visible</strong> and will
                appear to the left of the title, if there is one.
              </template>
            </ToggleSwitch>
            <template v-if="displayAppLogo">
              <AppDesignPropertyPicker
                design-property="regions.header.logo.type"
                label-text="Logo Type"
                input-type="choice"
                :options="logoTypeOptions"
              />
              <template v-if="app.design.regions.header.logo.type === 'custom'">
                <AppDesignPropertyPicker
                  design-property="regions.header.logo.image"
                  input-type="image"
                />
                <RangeSlider
                  v-model:value="app.design.regions.header.logo.imageWidthPercentage"
                  label="Width"
                  :min="1"
                  :max="100"
                  unit="%"
                />
              </template>
              <template v-else>
                <AppDesignPropertyPicker
                  design-property="regions.header.logo.icon"
                  label-text="Icon"
                  input-type="icon"
                  ignore-icon-alignment
                />
                <AppDesignPropertyPicker
                  design-property="regions.header.logo.iconSize"
                  label-text="Size"
                  input-type="choice"
                  :options="sizeOptions"
                />
                <AppDesignPropertyPicker
                  design-property="regions.header.logo.iconColor"
                  label-text="Color"
                  input-type="color"
                  @input="onColorHasChanged"
                />
              </template>
            </template>
          </template>
        </Toggle>

        <hr class="border-0 border-t border-solid border-subtle">

        <!-- Page Menu -->
        <Toggle
          :is-open="false"
          toggle-content-classes="px-4"
          data-cy="header-page-menu-accordion"
        >
          <template #title>
            <span class="text-base text-emphasis font-semibold">
              Page Menu
            </span>
          </template>
          <template #content>
            <p>The page menu contains links to every start page in your app.</p>

            <ToggleSwitch
              v-model:checked="displayPageMenu"
              label="Display Page Menu"
              toggle-switch-test-id="header-display-page-menu-toggle"
            >
              <template #offDescription>
                This app's page menu is <strong>hidden</strong>.
              </template>
              <template #description>
                This app's page menu is <strong>visible</strong>.
              </template>
            </ToggleSwitch>

            <template v-if="displayPageMenu">
              <AppDesignPropertyPicker
                design-property="regions.header.menu.userAuthBased"
                label-text="Page Menu Options"
                description-text="Only show links the user has access to"
                input-type="checkbox"
                data-cy="header-page-menu-auth-based"
              />
              <AppDesignPropertyPicker
                design-property="regions.header.menu.format"
                label-text="Format"
                input-type="choice"
                :options="menuFormatOptions"
              />

              <!-- Text Format -->
              <template v-if="app.design.regions.header.menu.format === 'text'">
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.text.size"
                  label-text="Size"
                  input-type="choice"
                  :options="sizeOptions"
                />
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.text.colors.custom"
                  label-text="Text Color"
                  input-type="radio"
                  :options="buttonCustomColorOptions"
                />
                <div
                  v-if="app.design.regions.header.menu.links.text.colors.custom"
                  class="selectionGroup -mt-2 mb-4"
                  style="margin-top: -18px;"
                >
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.text.colors.color"
                    input-type="color"
                  />
                </div>
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.text.activeStyle"
                  label-text="Active Link - Style"
                  input-type="choice"
                  :options="menuActiveLinkTextStyleOptions"
                />
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.text.colors.active.colorStyle"
                  label-text="Active Link - Text Color"
                  input-type="radio"
                  :options="menuActiveLinkTextColorOptions"
                />
                <div
                  v-if="app.design.regions.header.menu.links.text.colors.active.colorStyle === 'custom'"
                  class="selectionGroup -mt-2 mb-4"
                  style="margin-top: -18px; padding-bottom: 0"
                >
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.text.colors.active.color"
                    input-type="color"
                  />
                </div>
                <template v-if="app.design.regions.header.menu.links.text.activeStyle === 'underline'">
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.text.colors.active.underline.colorStyle"
                    label-text="Active Link - Underline Color"
                    input-type="radio"
                    :options="menuActiveLinkTextColorOptions"
                  />
                  <div
                    v-if="app.design.regions.header.menu.links.text.colors.active.underline.colorStyle === 'custom'"
                    class="selectionGroup -mt-2 mb-4"
                    style="margin-top: -18px; padding-bottom: 0"
                  >
                    <AppDesignPropertyPicker
                      design-property="regions.header.menu.links.text.colors.active.underline.color"
                      input-type="color"
                    />
                  </div>
                </template>
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.text.bold"
                  label-text="Text Options"
                  description-text="Bold"
                  input-type="checkbox"
                  is-inline
                />
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.text.uppercase"
                  description-text="Uppercase"
                  input-type="checkbox"
                />
              </template>

              <!-- Buttons Format -->
              <template v-if="app.design.regions.header.menu.format === 'buttons'">
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.buttons.format"
                  label-text="Style"
                  :options="menuButtonFormatOptions"
                />
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.buttons.size"
                  label-text="Size"
                  input-type="choice"
                  :options="sizeOptions"
                />
                <!-- Fill format -->
                <template v-if="app.design.regions.header.menu.links.buttons.format === 'fill'">
                  <!-- Text Color -->
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.buttons.colors.fill.text.custom"
                    label-text="Text color"
                    input-type="radio"
                    :options="buttonCustomColorOptions"
                  />
                  <div
                    v-if="app.design.regions.header.menu.links.buttons.colors.fill.text.custom"
                    class="selectionGroup -mt-2 mb-4"
                    style="margin-top: -18px;"
                  >
                    <AppDesignPropertyPicker
                      design-property="regions.header.menu.links.buttons.colors.fill.text.color"
                      input-type="color"
                    />
                  </div>

                  <!-- Fill Color -->
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.buttons.colors.fill.background.custom"
                    label-text="Fill color"
                    input-type="radio"
                    :options="buttonCustomColorOptions"
                  />
                  <div
                    v-if="app.design.regions.header.menu.links.buttons.colors.fill.background.custom"
                    class="selectionGroup -mt-2 mb-4"
                    style="margin-top: -18px;"
                  >
                    <AppDesignPropertyPicker
                      design-property="regions.header.menu.links.buttons.colors.fill.background.color"
                      input-type="color"
                    />
                  </div>

                  <!-- Active Link - Text Color -->
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.buttons.colors.fill.text.active.colorStyle"
                    label-text="Active Link - Text Color"
                    input-type="radio"
                    :options="menuActiveLinkTextColorOptions"
                  />
                  <div
                    v-if="app.design.regions.header.menu.links.buttons.colors.fill.text.active.colorStyle === 'custom'"
                    class="selectionGroup -mt-2 mb-4"
                    style="margin-top: -18px;"
                  >
                    <AppDesignPropertyPicker
                      design-property="regions.header.menu.links.buttons.colors.fill.text.active.color"
                      input-type="color"
                    />
                  </div>

                  <!-- Active Link - Fill Color -->
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.buttons.colors.fill.background.active.colorStyle"
                    label-text="Active Link - Fill Color"
                    input-type="radio"
                    :options="menuActiveLinkFillColorOptions"
                  />
                  <div
                    v-if="app.design.regions.header.menu.links.buttons.colors.fill.background.active.colorStyle
                      === 'custom'"
                    class="selectionGroup -mt-2 mb-4"
                    style="margin-top: -18px;"
                  >
                    <AppDesignPropertyPicker
                      design-property="regions.header.menu.links.buttons.colors.fill.background.active.color"
                      input-type="color"
                    />
                  </div>
                </template>

                <!-- Outline format -->
                <template v-if="app.design.regions.header.menu.links.buttons.format === 'outline'">
                  <!-- Text Color -->
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.buttons.colors.outline.text.custom"
                    label-text="Text color"
                    input-type="radio"
                    :options="buttonCustomColorOptions"
                  />
                  <div
                    v-if="app.design.regions.header.menu.links.buttons.colors.outline.text.custom"
                    class="selectionGroup -mt-2 mb-4"
                    style="margin-top: -18px;"
                  >
                    <AppDesignPropertyPicker
                      design-property="regions.header.menu.links.buttons.colors.outline.text.color"
                      input-type="color"
                    />
                  </div>

                  <!-- Outline Color -->
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.buttons.colors.outline.border.custom"
                    label-text="Outline color"
                    input-type="radio"
                    :options="buttonCustomBorderColorOptions"
                  />
                  <div
                    v-if="app.design.regions.header.menu.links.buttons.colors.outline.border.custom"
                    class="selectionGroup -mt-2 mb-4"
                    style="margin-top: -18px;"
                  >
                    <AppDesignPropertyPicker
                      design-property="regions.header.menu.links.buttons.colors.outline.border.color"
                      input-type="color"
                    />
                  </div>

                  <!-- Active Link - Text Color -->
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.buttons.colors.outline.text.active.colorStyle"
                    label-text="Active Link - Text Color"
                    input-type="radio"
                    :options="menuActiveLinkTextColorOptions"
                  />
                  <div
                    v-if="app.design.regions.header.menu.links.buttons.colors.outline.text.active.colorStyle
                      === 'custom'"
                    class="selectionGroup -mt-2 mb-4"
                    style="margin-top: -18px;"
                  >
                    <AppDesignPropertyPicker
                      design-property="regions.header.menu.links.buttons.colors.outline.text.active.color"
                      input-type="color"
                    />
                  </div>

                  <!-- Active Link - Outline Color -->
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.buttons.colors.outline.border.active.colorStyle"
                    label-text="Active Link - Outline Color"
                    input-type="radio"
                    :options="menuActiveLinkOutlineColorOptions"
                  />
                  <div
                    v-if="app.design.regions.header.menu.links.buttons.colors.outline.border.active.colorStyle
                      === 'custom'"
                    class="selectionGroup -mt-2 mb-4"
                    style="margin-top: -18px;"
                  >
                    <AppDesignPropertyPicker
                      design-property="regions.header.menu.links.buttons.colors.outline.border.active.color"
                      input-type="color"
                    />
                  </div>
                </template>

                <!-- Text options -->
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.buttons.uppercase"
                  label-text="Text Options"
                  description-text="Uppercase"
                  input-type="checkbox"
                />

                <!-- Options -->
                <AppDesignPropertyPicker
                  v-if="app.design.regions.header.menu.links.buttons.format === 'outline'"
                  design-property="regions.header.menu.links.buttons.borderThickness"
                  label-text="Outline Options"
                  :options="borderThicknessOptions"
                />
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.buttons.rounded"
                  :label-text="`${app.design.regions.header.menu.links.buttons.format === 'fill' ?
                    'Fill Options' : ''}`"
                  description-text="Rounded"
                  input-type="checkbox"
                  is-inline
                />
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.buttons.raised"
                  description-text="Raised"
                  input-type="checkbox"
                />
              </template>

              <!-- Tabs Format -->
              <template v-if="app.design.regions.header.menu.format === 'tabs'">
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.tabs.size"
                  label-text="Size"
                  input-type="choice"
                  :options="sizeOptions"
                />

                <!-- Text Color -->
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.tabs.colors.text.custom"
                  label-text="Text color"
                  input-type="radio"
                  :options="buttonCustomColorOptions"
                />
                <div
                  v-if="app.design.regions.header.menu.links.tabs.colors.text.custom"
                  class="selectionGroup -mt-2 mb-4"
                  style="margin-top: -18px;"
                >
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.tabs.colors.text.color"
                    input-type="color"
                  />
                </div>

                <!-- Fill Color -->
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.tabs.colors.background.custom"
                  label-text="Fill color"
                  input-type="radio"
                  :options="buttonCustomColorOptions"
                />
                <div
                  v-if="app.design.regions.header.menu.links.tabs.colors.background.custom"
                  class="selectionGroup -mt-2 mb-4"
                  style="margin-top: -18px;"
                >
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.tabs.colors.background.color"
                    input-type="color"
                  />
                </div>

                <!-- Active Link - Text Color -->
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.tabs.colors.text.active.colorStyle"
                  label-text="Active Link - Text Color"
                  input-type="radio"
                  :options="menuActiveLinkTextColorOptions"
                />
                <div
                  v-if="app.design.regions.header.menu.links.tabs.colors.text.active.colorStyle === 'custom'"
                  class="selectionGroup -mt-2 mb-4"
                  style="margin-top: -18px;"
                >
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.tabs.colors.text.active.color"
                    input-type="color"
                  />
                </div>

                <!-- Active Link - Fill Color -->
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.tabs.colors.background.active.colorStyle"
                  label-text="Active Link - Fill Color"
                  input-type="radio"
                  :options="menuActiveLinkFillColorOptions"
                />
                <div
                  v-if="app.design.regions.header.menu.links.tabs.colors.background.active.colorStyle === 'custom'"
                  class="selectionGroup -mt-2 mb-4"
                  style="margin-top: -18px;"
                >
                  <AppDesignPropertyPicker
                    design-property="regions.header.menu.links.tabs.colors.background.active.color"
                    input-type="color"
                  />
                </div>

                <!-- Text options -->
                <AppDesignPropertyPicker
                  design-property="regions.header.menu.links.tabs.uppercase"
                  label-text="Text Options"
                  description-text="Uppercase"
                  input-type="checkbox"
                />
              </template>
            </template>
          </template>
        </Toggle>

        <hr class="border-0 border-t border-solid border-subtle">

        <!-- Page Menu - Dropdown -->
        <Toggle
          :is-open="false"
          toggle-content-classes="px-4"
        >
          <template #title>
            <span class="text-base text-emphasis font-semibold">
              Page Menu - Dropdown
            </span>
          </template>
          <template #content>
            <p>Customize the look of dropdown menus.</p>
            <AppDesignPropertyPicker
              design-property="regions.header.menu.dropdownMenu.links.text.color"
              label-text="Links - Text Color"
              input-type="color"
            />
            <AppDesignPropertyPicker
              design-property="regions.header.menu.dropdownMenu.links.background.color"
              label-text="Links - Background Color"
              input-type="color"
            />
            <AppDesignPropertyPicker
              design-property="regions.header.menu.dropdownMenu.links.text.active.colorStyle"
              label-text="Active Link - Text Color"
              input-type="radio"
              :options="menuActiveLinkTextColorOptions"
            />
            <div
              v-if="app.design.regions.header.menu.dropdownMenu.links.text.active.colorStyle === 'custom'"
              class="selectionGroup -mt-2 mb-4"
              style="margin-top: -18px; padding-bottom: 0"
            >
              <AppDesignPropertyPicker
                design-property="regions.header.menu.dropdownMenu.links.text.active.color"
                input-type="color"
              />
            </div>
            <AppDesignPropertyPicker
              design-property="regions.header.menu.dropdownMenu.links.background.active.colorStyle"
              label-text="Active Link - Background Color"
              input-type="radio"
              :options="menuActiveLinkBackgroundColorOptions"
            />
            <div
              v-if="app.design.regions.header.menu.dropdownMenu.links.background.active.colorStyle === 'custom'"
              class="selectionGroup -mt-2 mb-4"
              style="margin-top: -18px; padding-bottom: 0"
            >
              <AppDesignPropertyPicker
                design-property="regions.header.menu.dropdownMenu.links.background.active.color"
                input-type="color"
              />
            </div>
          </template>
        </Toggle>

        <hr class="border-0 border-t border-solid border-subtle">

        <!-- Mobile Menu -->
        <Toggle
          :is-open="false"
          toggle-content-classes="px-4"
        >
          <template #title>
            <span class="text-base text-emphasis font-semibold">
              Mobile Menu
            </span>
          </template>
          <template #content>
            <p>Customize the look of the mobile menu.</p>
            <AppDesignPropertyPicker
              design-property="regions.header.menu.mobileMenu.toggleButtonColor"
              label-text="Mobile Menu Toggle Color"
              input-type="color"
            />
            <AppDesignPropertyPicker
              design-property="regions.header.menu.mobileMenu.links.text.color"
              label-text="Links - Text Color"
              input-type="color"
            />
            <AppDesignPropertyPicker
              design-property="regions.header.menu.mobileMenu.links.background.custom"
              label-text="Links - Background Color"
              input-type="radio"
              :options="mobileMenuLinkBackgroundColorOptions"
            />
            <div
              v-if="app.design.regions.header.menu.mobileMenu.links.background.custom"
              class="selectionGroup -mt-2 mb-4"
              style="margin-top: -18px; padding-bottom: 0"
            >
              <AppDesignPropertyPicker
                design-property="regions.header.menu.mobileMenu.links.background.color"
                input-type="color"
              />
            </div>
            <AppDesignPropertyPicker
              design-property="regions.header.menu.mobileMenu.links.text.active.colorStyle"
              label-text="Active Link - Text Color"
              input-type="radio"
              :options="menuActiveLinkTextColorOptions"
            />
            <div
              v-if="app.design.regions.header.menu.mobileMenu.links.text.active.colorStyle === 'custom'"
              class="selectionGroup -mt-2 mb-4"
              style="margin-top: -18px; padding-bottom: 0"
            >
              <AppDesignPropertyPicker
                design-property="regions.header.menu.mobileMenu.links.text.active.color"
                input-type="color"
              />
            </div>
            <AppDesignPropertyPicker
              design-property="regions.header.menu.mobileMenu.links.background.active.colorStyle"
              label-text="Active Link - Background Color"
              input-type="radio"
              :options="menuActiveLinkBackgroundColorOptions"
            />
            <div
              v-if="app.design.regions.header.menu.mobileMenu.links.background.active.colorStyle === 'custom'"
              class="selectionGroup -mt-2 mb-4"
              style="margin-top: -18px; padding-bottom: 0"
            >
              <AppDesignPropertyPicker
                design-property="regions.header.menu.mobileMenu.links.background.active.color"
                input-type="color"
              />
            </div>
            <AppDesignPropertyPicker
              design-property="regions.header.menu.mobileMenu.links.bold"
              label-text="Text Options"
              description-text="Bold"
              input-type="checkbox"
              is-inline
            />
            <AppDesignPropertyPicker
              design-property="regions.header.menu.mobileMenu.links.uppercase"
              description-text="Uppercase"
              input-type="checkbox"
            />
          </template>
        </Toggle>
      </template>
    </Toolbox>
  </div>
</template>

<script>
import { designOptionsCollection, headerDesignOptionsCollection } from '@knack/style-engine';
import useLiveAppDesignPreview from '@/composables/ui/useLiveAppDesignPreview';
import Toolbox from '@/components/layout/Toolbox';
import Toggle from '@/components/ui/Toggle';
import ToggleSwitch from '@/components/ui/ToggleSwitch';
import RangeSlider from '@/components/ui/inputs/RangeSlider';
import AppDesignPropertyPicker from '@/components/settings/design/AppDesignPropertyPicker';
import ApplicationDesignUtils from '@/components/util/ApplicationDesignUtils';

export default {
  name: 'HeaderDesignSettings',
  components: {
    Toolbox,
    Toggle,
    ToggleSwitch,
    RangeSlider,
    AppDesignPropertyPicker,
  },
  mixins: [
    ApplicationDesignUtils,
  ],
  setup() {
    const { previewSize } = useLiveAppDesignPreview();
    return { previewSize };
  },
  computed: {
    isLegacyHeader: {
      get() {
        return this.app.design.regions.header.isLegacy;
      },
      set(newValue) {
        if (!this.app.design) {
          return;
        }
        this.app.design.regions.header.isLegacy = !!newValue;
      },
    },
    displayHeaderTitle: {
      get() {
        return this.app.design.regions.header.title.show;
      },
      set(newValue) {
        if (!this.app.design) {
          return;
        }
        this.app.design.regions.header.title.show = !!newValue;
      },
    },
    displayAppLogo: {
      get() {
        return this.app.design.regions.header.logo.show;
      },
      set(newValue) {
        if (!this.app.design) {
          return;
        }
        this.app.design.regions.header.logo.show = !!newValue;
      },
    },
    displayPageMenu: {
      get() {
        return this.app.design.regions.header.menu.show;
      },
      set(newValue) {
        if (!this.app.design) {
          return;
        }
        this.app.design.regions.header.menu.show = !!newValue;
      },
    },
    isCustomTitle: {
      get() {
        return this.app.design.regions.header.title.isCustom;
      },
      set(newValue) {
        if (!this.app.design) {
          return;
        }
        this.app.design.regions.header.title.isCustom = !!newValue;
      },
    },
    legacyTitleDisplayOptions() {
      return [
        {
          label: 'text',
          value: false,
        },
        {
          label: 'image',
          value: true,
        },
      ];
    },
    borderThicknessOptions() {
      return designOptionsCollection.borderThicknessOptions;
    },
    sizeOptions() {
      return designOptionsCollection.buttonSizeOptions;
    },
    buttonCustomColorOptions() {
      return designOptionsCollection.buttonCustomColorOptions;
    },
    buttonCustomBorderColorOptions() {
      return designOptionsCollection.buttonCustomBorderColorOptions;
    },
    titleAlignmentOptions() {
      return headerDesignOptionsCollection.titleAlignmentOptions;
    },
    menuAlignmentOptions() {
      const hasTitleOrLogo = this.displayHeaderTitle || this.displayAppLogo;
      if (this.app.design.regions.header.layout.menuPosition === 'right' && hasTitleOrLogo) {
        return headerDesignOptionsCollection.menuAlignmentOptions.filter((option) => option.value !== 'center');
      }
      return headerDesignOptionsCollection.menuAlignmentOptions;
    },
    menuPositionOptions() {
      return headerDesignOptionsCollection.menuPositionOptions;
    },
    menuFormatOptions() {
      return headerDesignOptionsCollection.menuFormatOptions;
    },
    menuButtonFormatOptions() {
      return headerDesignOptionsCollection.menuButtonFormatOptions;
    },
    menuActiveLinkTextStyleOptions() {
      return headerDesignOptionsCollection.menuActiveLinkTextStyleOptions;
    },
    menuActiveLinkTextColorOptions() {
      return headerDesignOptionsCollection.menuActiveLinkTextColorOptions;
    },
    menuActiveLinkFillColorOptions() {
      return headerDesignOptionsCollection.menuActiveLinkFillColorOptions;
    },
    menuActiveLinkBackgroundColorOptions() {
      return headerDesignOptionsCollection.menuActiveLinkBackgroundColorOptions;
    },
    menuActiveLinkOutlineColorOptions() {
      return headerDesignOptionsCollection.menuActiveLinkOutlineColorOptions;
    },
    mobileMenuLinkBackgroundColorOptions() {
      return headerDesignOptionsCollection.mobileMenuLinkBackgroundColorOptions;
    },
    logoTypeOptions() {
      return headerDesignOptionsCollection.logoTypeOptions;
    },
    shouldShowLayoutToggle() {
      const isPreviewDesktop = this.previewSize === 'desktop';
      return isPreviewDesktop && (this.displayPageMenu || this.displayAppLogo || this.displayHeaderTitle);
    },
    shouldShowTitleLogoAlignmentOption() {
      const isMenuBelow = this.app.design.regions.header.layout.menuPosition === 'below';
      const hasTitleOrLogo = this.displayHeaderTitle || this.displayAppLogo;
      return hasTitleOrLogo && (isMenuBelow || !this.displayPageMenu);
    },
  },
  watch: {
    // if the pageMenuPosition is set to 'right' after being set to 'below', we need to reset
    // the pageMenuAlignment and titleAlignment back to 'left' for better UX
    'app.design.regions.header.layout.menuPosition': function (newMenuPosition, oldMenuPosition) {
      if (oldMenuPosition === 'below' && newMenuPosition === 'right') {
        this.app.design.regions.header.layout.menuAlignment = 'left';
        this.app.design.regions.header.layout.titleAlignment = 'left';
      }
    },
  },
};
</script>

<style lang="scss">
.toolbox-wrapper {
  font-size: 14px;
}
</style>
