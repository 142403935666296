<template>
  <div class="clearfix kn-map-wrapper">
    <div
      class="kn-map-search"
      style="position: relative;"
    >
      <div class="click-interceptor" />
      <div class="kn-map-fields">
        <span class="kn-label kn-search-label">
          {{ trans('Search') }}
        </span>
        <input
          class="kn-map-input input"
          name="map_origin"
          placeholder="City, State, or Zip"
          :value="startingAddress"
        >
        <span style="margin: 0 5px">
          {{ trans('within') }}
        </span>
        <div class="kn-select kn-map-dropdown">
          <select
            name="map_range"
            :value="defaultRange"
          >
            <option value="5">
              5 {{ units }}
            </option>
            <option value="10">
              10 {{ units }}
            </option>
            <option value="25">
              25 {{ units }}
            </option>
            <option value="50">
              50 {{ units }}
            </option>
            <option value="100">
              100 {{ units }}
            </option>
            <option value="100000">
              {{ trans('Any') }}
            </option>
          </select>
        </div>
        <input
          class="kn-map-submit kn-button is-primary"
          type="submit"
          :value="trans('Search')"
        >
        <span class="reset-container">
          <a
            v-if="isSearching"
            href="#"
            class="kn-button is-link reset"
          >
            {{ trans('reset') }}
          </a>
        </span>
      </div>
    </div>

    <RecordNavigation
      v-if="object"
      :allow-limit="false"
      :current-page="1"
      :object-key="object.key"
      :filters="filters"
      :filter-menu-links="filterMenuLinks"
      :records="records"
      :records-per-page="25"
      :show-keyword-search="false"
      :show-export-button="false"
      :show-filters="showFilters"
      :show-filter-menu="showFilterMenu"
      :show-pagination="false"
      :total-records="0"
      :total-pages="1"
    />
    <div
      v-if="showMap"
      class="kn-map-results"
      :style="`position: relative; width: ${mapWidth}px; height: ${mapHeight}px`"
    >
      <div
        v-tippy
        content="Click to edit map settings"
        class="click-interceptor"
        style="cursor: pointer;"
        @click="$router.push(`${routePrefix}/settings`)"
      />
      <div
        v-if="isGoogleMaps"
        :id="`kn-map-${view.key}`"
        class="kn-google-map"
      >
        <GoogleMap
          :width="mapWidth"
          :height="mapHeight"
          :view="view"
          :records="records"
        />
      </div>
      <div
        v-else
        :id="`kn-map-${view.key}`"
        class="kn-here-map"
      >
        <MapLibreMap
          :width="mapWidth"
          :height="mapHeight"
          :records="records"
          :view="view"
        />
      </div>
    </div>

    <div
      v-if="showMap"
      class="kn-map-list"
      :style="`width: ${listWidth}px; height: ${mapHeight}px;`"
    >
      <template
        v-for="(record, recordIndex) in records"
        :key="record.id"
      >
        <DetailsView
          v-if="recordIndex === 0 || view.key === `new`"
          class="kn-list-item-container"
          :class="{ 'group has-util-dropdown': recordIndex === 0 }"
          :should-show-utility-dropdown="recordIndex === 0"
          :schema="detailsSchema"
          :data="record"
          :view="view"
          :route-prefix="routePrefixDetails"
          @delete="onDeleteDetail"
          @drop="onDropDetail"
        />
        <div
          v-else
          class="list-item-wrapper"
        >
          <div
            v-tippy
            content="Use the first location item to add and edit location details"
            class="list-item-overlay"
          />
          <DetailsView
            class="kn-list-item-container"
            :schema="detailsSchema"
            :data="record"
            :view="view"
            :is-editable="false"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import hasIn from 'lodash/hasIn';
import DetailsView from '@/components/renderer/shared/Details';
import DetailActionsMixin from '@/components/renderer/mixins/DetailActionsMixin.js';
import RecordNavigation from '@/components/renderer/shared/RecordNavigation';
import RendererUtils from '@/components/renderer/RenderUtils.js';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';
import QueryMixin from '@/components/renderer/mixins/QueryMixin';
import RequestUtils from '@/components/util/RequestUtils';
import GoogleMap from '@/components/renderer/map/GoogleMap';
import MapLibreMap from '@/components/renderer/map/MapLibreMap.vue';

export default {
  components: {
    MapLibreMap,
    DetailsView,
    RecordNavigation,
    GoogleMap,
  },
  mixins: [
    RendererUtils,
    DetailActionsMixin,
    QueryMixin,
    RequestUtils,
    TranslationUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      records: this.view.data.records,
      showMap: true,
    };
  },
  computed: {
    ...mapGetters([
      'app',
      'getField',
    ]),
    routePrefix() {
      return `/pages/${this.$route.params.pageKey}/views/${this.view.key}/map`;
    },
    routePrefixDetails() {
      return `${this.routePrefix}/list`;
    },
    viewClass() {
      return {};
    },
    formatVars() {
      // used by QueryMixin to set filters and other view settings
      return this.view.get('details');
    },
    detailsSchema: {
      get() {
        return this.view.getDetailsSchema();
      },
      set(newVal) {
        this.view.setDetailsSchema(newVal);
      },
    },
    addressKey() {
      return this.view.get('address_field').key;
    },
    titleKey() {
      return this.view.get('title_field').key;
    },
    listWidth() {
      return this.view.get('list_width');
    },
    mapWidth() {
      return this.view.get('map_width');
    },
    mapHeight() {
      return this.view.get('map_height');
    },
    units() {
      return this.view.get('units') || 'miles';
    },
    defaultRange() {
      return this.view.get('default_range');
    },
    startingAddress() {
      return this.view.get('starting_address');
    },
    isSearching() {
      return false;
    },
    isGoogleMaps() {
      return this.app.get('settings').mapsAndGeocoderProvider === 'google';
    },
  },
  watch: {
    'view.data': function (newVal) {
      this.setRecords(this.view.data);

      if (this.showMap) {
        this.showMap = false;

        // Wait a tick to ensure reactivity
        this.$nextTick(() => {
          this.showMap = true;
        });
      }
    },
  },
  created() {
    this.setRecords(this.view.data);

    this.initQueryVars();
  },
  mounted() {
    return this.$nextTick(() => {
      if (!hasIn(this.$refs, 'map')) {
        return;
      }

      return this.$refs.map.$mapCreated.then(() => this.$refs.map.fitBounds(this.bounds));
    });
  },
  methods: {
    queryRecordsRequest(queryVars, onSuccess, onError) {
      this.commitRequest({
        request: () => this.view.getData(queryVars),
        onSuccess: (response) => this.view.onGetDataSuccess(response, onSuccess),
        onError,
      });
    },
  },
};
</script>

<style lang="scss">
.kn-map-wrapper {
  margin-left: 7px;
}

.kn-map {
  overflow: auto;
}

.kn-map-results {
  float: left;
  margin-right: 15px;
  margin-top: 0;
}

.kn-map-list {
  float: left;
  overflow-y: auto;
  width: auto;
}

$base-spacing: .75em;

.kn-map {

  .kn-map-search {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .kn-search-label {
      margin-right: $base-spacing;
      margin-bottom: 0;
    }

    .kn-map-fields {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
  }

  .kn-map-dropdown { // Mile selection
    min-width: 100px;
  }

  .fail-alert {
    margin: 1rem 0;
  }

  .kn-google-map { // Sibling of .kn-map-list (sharing flex)
    flex-grow: 1;
  }

  .kn-map-list { // Sibling of .kn-google-map (sharing flex)
    min-width: 200px;
    padding: $base-spacing;
    padding-top: 1.5em;
    overflow: auto;

    table, td, tr, th {
      border: 0 !important;
    }

    .kn-list-content {
      margin-top: 0px !important;
    }

    .kn-list-item-container {
      cursor: pointer;
      padding-right: $base-spacing/2;
      transition: border 0.1s ease-out;
      border-left: 0;
      border-bottom:1px solid #ccc;
      // background-color: #fafafa;

      &:last-child {
        border-bottom:0px solid #ccc;
      }

      &:hover {
        // background-color: rgba(0, 0, 0, 0.05);
      }
      &.active {
        border-left: 4px solid rgba(0, 0, 0, 0.2);
        background-color: rgba(0, 0, 0, 0.05);
      }

      .kn-details-group .kn-details-group-column:first-child {
        padding-left: 0;
      }
    }
  }

  .kn-map-results {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; // Allow filters to sit on top
  }

  .kn-geo-distance {
    position: absolute;
    right: $base-spacing/4;
  }

  .kn-records-nav {
    min-width: 100%;
  }

  .kn-map-submit {
    margin-left: .375em;
    max-width: 140px;
    background-color: #00396D;
    color: #fff;
  }

  .kn-map-input {
    max-width: 165px;
  }

}
</style>
