<script setup>
import { computed, defineProps, ref, watch } from 'vue';
import { useStore } from 'vuex';

import FontAwesome from '@/components/ui/FontAwesome';
import DropdownMenu from '@/components/renderer/header/pieces/DropdownMenu';
import MobileNav from '@/components/renderer/header/pieces/MobileNav';

const store = useStore();

// Props
const props = defineProps({
  entryPages: {
    type: Array,
    required: true,
  },
  forceCloseMobileNav: {
    type: Boolean,
    default: false,
  },
});

// State
const isMobileNavOpen = ref(false);

// Computed
const headerDesign = computed(() => store.getters.app.attributes.design.regions.header);

// Title
const title = computed(() => {
  if (headerDesign.value.title.isCustom) {
    return headerDesign.value.title.text;
  }
  return store.getters.app.attributes.name;
});
const titleClasses = computed(() => {
  const classes = [];
  if (headerDesign.value.title.size === 's') {
    classes.push('knHeader__title--small');
  } else if (headerDesign.value.title.size === 'l') {
    classes.push('knHeader__title--large');
  } else {
    classes.push('knHeader__title--medium');
  }
  return classes;
});
const showTitle = computed(() => headerDesign.value.title.show);

// Logo
const logoIcon = computed(() => headerDesign.value.logo.icon?.icon);
const logoImageUrl = computed(() => headerDesign.value.logo.image?.url);
const showLogo = computed(() => headerDesign.value.logo.show);

// Page Menu
const showPageMenu = computed(() => headerDesign.value.menu.show);
const pageMenuFormat = computed(() => headerDesign.value.menu.format);
const pageMenuLinkClasses = computed(() => {
  const classes = [];
  if (headerDesign.value.menu.format === 'buttons') {
    classes.push('knHeader__menu-link--button');
  } else if (headerDesign.value.menu.format === 'tabs') {
    classes.push('knHeader__menu-link--tab');
  } else {
    classes.push('knHeader__menu-link--text');
  }
  return classes;
});

// Header classes
const headerClasses = computed(() => {
  const classes = [];

  if (headerDesign.value.options.sticky) {
    classes.push('knHeader--sticky');
  }
  if (headerDesign.value.options.raised) {
    classes.push('knHeader--raised');
  }
  if (headerDesign.value.options.bottomBorder.active) {
    classes.push('knHeader--bottom-border');
  }
  if (isMobileNavOpen.value) {
    classes.push('knHeader--mobile-nav-open');
  }
  if (!showPageMenu.value) {
    classes.push('knHeader--no-page-menu');
  }

  return classes;
});

// Methods
const openMobileNav = () => {
  isMobileNavOpen.value = true;
  // Prevent scrolling on body while mobile nav is open
  const bodyElement = document.getElementById('knack-body');
  if (bodyElement) {
    bodyElement.style.overflow = 'hidden';
  }
};
const closeMobileNav = () => {
  isMobileNavOpen.value = false;
  // Set overflow on body element back to inherit
  const bodyElement = document.getElementById('knack-body');
  if (bodyElement) {
    bodyElement.style.overflow = 'inherit';
  }
};
const toggleMobileNav = () => {
  if (isMobileNavOpen.value) {
    closeMobileNav();
  } else {
    openMobileNav();
  }
};

// Since we resize the preview container manually to mimic responsive behavior in the Preview page,
// we need a way to manually close the mobile nav when previewing on desktop mode
watch(() => props.forceCloseMobileNav, () => {
  if (props.forceCloseMobileNav) {
    closeMobileNav();
  }
});
</script>

<template>
  <header :class="['knHeader', ...headerClasses]">
    <div class="kn-container">
      <div class="knHeader__content">
        <!-- Logo/title -->
        <a
          v-if="showTitle || showLogo"
          class="knHeader__title-logo-wrapper"
          href="#"
          :title="title"
        >
          <div
            v-if="showLogo"
            :class="['knHeader__logo', `knHeader__logo--${headerDesign.logo.type}`]"
          >
            <FontAwesome
              v-if="headerDesign.logo.type === 'icon'"
              :name="logoIcon"
            />
            <img
              v-if="headerDesign.logo.type === 'custom' && logoImageUrl"
              :src="logoImageUrl"
              alt="Application Logo"
              class="knHeader__logo-image"
            >
          </div>
          <h1
            v-if="showTitle"
            :class="['knHeader__title', ...titleClasses]"
          >
            {{ title }}
          </h1>
        </a>

        <!-- Page menu -->
        <div
          v-if="showPageMenu"
          :class="['knHeader__menu', `knHeader__menu--${pageMenuFormat}`]"
        >
          <nav class="knHeader__menu-nav">
            <ul class="knHeader__menu-list">
              <li
                v-for="page in entryPages"
                :key="page.key"
                class="knHeader__menu-list-item"
              >
                <DropdownMenu
                  v-if="page.dropdownPages"
                  :entry-page="page"
                  :dropdown-pages="page.dropdownPages"
                  :dropdown-toggle-classes="pageMenuLinkClasses"
                />
                <a
                  v-else
                  :href="page.slug"
                  :class="[
                    'knHeader__menu-link',
                    {'knHeader__menu-link--active': !!page.isActive},
                    pageMenuLinkClasses
                  ]"
                >
                  <span
                    v-if="page.icon?.icon && page.icon?.align === 'left'"
                    class="knHeader__menu-link-icon knHeader__menu-link-icon--left"
                  >
                    <FontAwesome :name="page.icon.icon" />
                  </span>

                  {{ page.name }}

                  <span
                    v-if="page.icon?.icon && page.icon?.align === 'right'"
                    class="knHeader__menu-link-icon knHeader__menu-link-icon--right"
                  >
                    <FontAwesome :name="page.icon.icon" />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <!-- Mobile nav toggle -->
        <div
          v-if="showPageMenu"
          class="knHeader__mobile-controls"
        >
          <button
            class="knHeader__mobile-nav-toggle"
            @click="toggleMobileNav"
          >
            <FontAwesome
              v-if="isMobileNavOpen"
              name="fa-times"
            />
            <FontAwesome
              v-else
              name="fa-bars"
            />
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile nav -->
    <MobileNav
      :is-open="isMobileNavOpen"
      :close-mobile-nav="closeMobileNav"
      :entry-pages="entryPages"
    />
  </header>
</template>
