<script setup>
import {
  computed, defineProps, watch, watchEffect, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import {
  addStylesToHead,
  getClassesFromDesignSettings,
  getOverrideStylesFromDesignSettings,
  designSettingsAliases,
  removeStylesFromHead,
} from '@knack/style-engine';
import FontAwesome from '@/components/ui/FontAwesome';

const store = useStore();
const componentId = uuid();

// Props
const props = defineProps({
  linkType: {
    type: String,
    default: 'button',
  },
  text: {
    type: String,
    default: '',
  },
  icon: {
    type: Object,
    default: undefined,
  },
  route: {
    type: String,
    required: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  isMenuDesignActive: {
    type: Boolean,
    default: false,
  },
  isLinkDesignActive: {
    type: Boolean,
    default: false,
  },
  menuDesign: {
    type: Object,
    default: undefined,
  },
  linkDesign: {
    type: Object,
    default: undefined,
  },
});

// Computed
const appDesign = computed(() => store.getters.app.get('design'));

const linkTypeAlias = computed(() => {
  return props.linkType === 'button' ? designSettingsAliases.MENU_LINKS : designSettingsAliases.MENU_LINKS_TABS;
});

const designObject = computed(() => {
  const menuFormat = linkTypeAlias.value === designSettingsAliases.MENU_LINKS
    ? 'buttons' : 'tabs';

  // Use link design if available
  if (props.linkDesign && props.isLinkDesignActive) {
    if (!props.isMenuDesignActive) {
      // This ensures that the global design for menus is used as the base
      // design when link design is active but menu view design is not
      return {
        ...appDesign.value.general.menus.links[menuFormat],
        colors: {
          ...props.linkDesign[menuFormat].colors,
        },
      };
    }
    return props.linkDesign[menuFormat];
  }

  // Use menu design if available
  if (props.menuDesign && props.isMenuDesignActive) {
    return props.menuDesign[menuFormat];
  }

  return undefined;
});

const classes = computed(() => {
  const classNames = getClassesFromDesignSettings(
    linkTypeAlias.value,
    appDesign.value,
    designObject.value,
  );

  if (props.isActive) {
    classNames.push('knMenuLink--isActive');
  }

  return classNames;
});

// Methods
const appendOverrideStyles = () => {
  if (!designObject.value) {
    return;
  }

  const css = getOverrideStylesFromDesignSettings(
    componentId,
    linkTypeAlias.value,
    appDesign.value,
    designObject.value,
  );

  if (css) {
    addStylesToHead(css, componentId);
  }
};

const removeOverrideStyles = () => {
  removeStylesFromHead(componentId);
};

// Watchers
watchEffect(() => {
  if (designObject.value) {
    appendOverrideStyles();
  } else {
    removeOverrideStyles();
  }
});

watch(() => props.linkType, () => {
  appendOverrideStyles();
});

// Lifecycle methods
onUnmounted(() => {
  removeOverrideStyles();
});
</script>

<template>
  <RouterLink
    :to="props.route"
    class="knMenuLink"
    :class="classes"
    :data-kn-id="componentId"
  >
    <span
      v-if="props.icon?.icon && props.icon?.align === 'left'"
      class="knMenuLink__icon knMenuLink__icon--isLeft"
    >
      <FontAwesome :name="props.icon?.icon" />
    </span>

    {{ props.text ? props.text : '&nbsp;' }}

    <span
      v-if="props.icon?.icon && props.icon?.align === 'right'"
      class="knMenuLink__icon knMenuLink__icon--isRight"
    >
      <FontAwesome :name="props.icon?.icon" />
    </span>

    <slot />
  </RouterLink>
</template>
