<script setup>
import Icon from '@/components/ui/Icon';
import FontAwesome from '@/components/ui/FontAwesome';

defineProps({
  title: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  onClick: {
    type: Function,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  iconSize: {
    type: String,
    default: 'small',
  },
  className: {
    type: String,
    default: '',
  },
  variant: {
    type: String,
    default: '',
  },
  isFontAwesome: Boolean,
  testId: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['click']);

const click = () => {
  emit('click');
};
</script>

<template>
  <a
    class="inline-flex p-3 justify-center border border-solid border-default border-r-[0.5px] hover:border-brand-600 focus:border-brand-600 focus:bg-brand-50 rounded-lg hover:bg-brand-50 text-emphasis group font-semibold mr-0 min-w-0 text-base"
    :class="`icon-text-button kn-button is-${iconSize} ${className} ${variant}`"
    :data-test-id="testId"
    :aria-label="label"
    @click="click"
  >
    <FontAwesome
      v-if="isFontAwesome"
      :name="icon"
      class="fa text-emphasis group-hover:text-brand-400 h-4 w-4 mr-2"
    />
    <Icon
      v-else
      :type="icon"
      class="text-emphasis group-hover:text-brand-400 h-4 w-4 mr-2"
      data-cog="knack-asterisk"
    />
    <span>{{ title }}</span>
  </a>
</template>

<style lang="scss">
.icon-text-button {
  display: flex;
  width: auto;
  border-radius: 4px;
  border: 1px solid #d5dadf;
  background: #fff;
  margin-right: 4px;
  &.is-small {
    & svg {
      width: 24px;
      height: 24px;
    }
    width: auto;
  }

  &.brand-50 {
    border: 1px solid #d3a0cb;
    background: #fbeff9;
    &:hover {
      border: 1px solid #d497cb;
      background: #fce2f7;
    }
  }
  &:hover {
    background-color: $gray100;
    border: 1px solid $gray200;
  }
  & .fa {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
</style>
