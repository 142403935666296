<template>
  <Modal
    size="medium"
    title="Edit Column"
    @close="onClose"
  >
    <div
      class="text-base"
      style="font-size: .95rem;"
    >
      <template v-if="isDateType">
        <div class="margin-bottom-double">
          We want to ensure your dates are imported correctly. Choose the format that best matches your data.
        </div>
        <div class="flex margin-bottom-double center">
          <div id="format-chooser">
            <div
              class="border-b-0"
              style="border-bottom: 1px solid #eee; min-width: 190px; margin-bottom: .75em;"
            >
              <label class="text-default text-sm font-medium mb-2 leading-4">Date Format</label>
            </div>
            <form>
              <div class="input-labels tw-input-labels">
                <label
                  v-for="suggestion in dateFormats"
                  :key="suggestion.value"
                  class="text-emphasis text-base font-normal leading-4"
                >
                  <input
                    v-model="dateFormat"
                    type="radio"
                    name="dateFormat"
                    :value="suggestion.value"
                  >{{ suggestion.label }}
                </label>
              </div>
            </form>
          </div>
          <div class="overflow-auto">
            <table
              id="import-edit-date-format-table"
              class="table border-separate bg-white"
            >
              <thead>
                <tr>
                  <th class="rounded-tl-lg border-l-0 bg-subtle border border-gray-50 text-sm">
                    <span class="text-sm text-default font-medium items-center">
                      Your Data
                    </span>
                  </th>
                  <th class="rounded-tr-lg bg-subtle border border-gray-50 text-sm border-r-0">
                    <span class="text-sm text-default font-medium items-center">
                      Formatted
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in data">
                  <td class="border border-[#e3e3e3]/50 text-sm text-default font-normal items-center">
                    {{ row }}
                  </td>
                  <td class="border border-[#e3e3e3]/50 text-sm text-default font-normal items-center">
                    {{ formatDate(row) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>

      <template v-if="isNumericType">
        <div class="margin-bottom-double">
          We want to ensure your numbers are imported correctly. Choose the format that best matches your data.
        </div>
        <div class="flex margin-bottom-double center">
          <div id="format-chooser">
            <div
              class="border-b-0"
              style="border-bottom: 1px solid #eee; min-width: 190px; margin-bottom: .75em;"
            >
              <label class="text-default text-sm font-medium mb-2 leading-4">Decimal Separator</label>
            </div>
            <form>
              <div class="input-labels tw-input-labels">
                <label
                  v-for="suggestion in decimalFormats"
                  :key="suggestion.value"
                  class="text-emphasis text-base font-normal leading-4"
                >
                  <input
                    v-model="decimalFormat"
                    type="radio"
                    name="decimalFormat"
                    :value="suggestion.value"
                  >{{ suggestion.label }}
                </label>
              </div>
            </form>
          </div>
          <div class="overflow-auto">
            <table
              id="import-edit-numbers-format-table"
              class="table"
            >
              <thead>
                <tr>
                  <th class="rounded-t-lg border-x-0 bg-subtle border border-gray-50 text-sm">
                    <span class="text-sm text-default font-medium items-center">
                      Your Data
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in data">
                  <td class="border border-[#e3e3e3]/50 text-sm text-default font-normal items-center">
                    {{ row }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>

      <div class="submit-buttons flex justify-end">
        <a
          class="button save px-3 py-2.5 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium mr-0"
          @click="onSubmit"
        >
          Submit
        </a>
      </div>
    </div>
  </Modal>
</template>

<script>
import moment from 'moment-timezone';
import Modal from '@/components/ui/Modal';

export default {
  components: {
    Modal,
  },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    records: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'close',
    'submit',
  ],
  data() {
    return {
      dateFormat: 'MM/D/YY',
      dateFormats: [
        {
          value: 'MM/D/YY',
          label: 'Month/Day/Year',
        },
        {
          value: 'D/MM/YY',
          label: 'Day/Month/Year',
        },
        {
          value: 'YY/MM/D',
          label: 'Year/Month/Day',
        },
        {
          value: 'other',
          label: 'Other',
        },
      ],
      decimalFormat: 'dot',
      decimalFormats: [
        {
          value: 'dot',
          label: 'Dot',
        },
        {
          value: 'comma',
          label: 'Comma',
        },
      ],
    };
  },
  computed: {
    data() {
      return this.records.slice(0, 10).map((record) => record[this.field.mappedColumnIndex]);
    },

    isDateType() {
      const dateTypes = [
        window.Knack.config.DATE_TIME,
      ];

      return dateTypes.includes(this.field.type);
    },

    isNumericType() {
      // currently either/or
      return !this.isDateType;
    },

    format() {
      return (this.isDateType) ? this.dateFormat : this.decimalFormat;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date, this.format).format('MMMM Do, YYYY');
    },
    onClose() {
      return this.$emit('close');
    },
    onSubmit() {
      return this.$emit('submit', this.format);
    },
  },
};
</script>

<style lang="scss">
#format-chooser {
  margin-right: 2em;
  b {
    font-weight: 500;
  }
}
</style>
