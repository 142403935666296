<template>
  <div class="control kn-inputWrapper ">
    <input
      :id="input.id"
      v-model="localValue"
      class="input kn-input"
      :name="input.id"
      :disabled="isDisabled"
      :type="textType"
      data-cy="text-input"
      :placeholder="renderPlaceholder"
    >
  </div>
</template>

<script>
import hasIn from 'lodash/hasIn';

export default {
  name: 'TextInput',
  inheritAttrs: false,
  props: [
    'input',
    'field',
    'modelValue',
  ],
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    isDisabled() {
      if (!hasIn(this.field, 'read_only')) {
        return false;
      }

      return this.field.read_only;
    },
    textType() {
      if (hasIn(this.field, 'type') && this.field.type === 'password') {
        return 'password';
      }

      return 'text';
    },
    renderPlaceholder() {
      if (!this.field) return '';
      if (this.field.type === 'currency') {
        return 'Enter currency';
      }
      if (this.field.type === 'number') {
        return 'Enter a number';
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
</style>
