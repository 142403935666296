<template>
  <!-- This key ensures the mask is reactive -->
  <div
    :key="inputMask"
    class="control kn-inputWrapper"
  >
    <input
      :id="input.id"
      ref="input"
      v-model="localPhoneNumber"
      v-mask="maskOptions"
      class="input kn-input"
      :name="input.id"
      type="text"
      :placeholder="placeholderFromFormat"
    >
  </div>
</template>

<script>
import Inputmask from 'inputmask';
import constant from 'lodash/constant';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import times from 'lodash/times';

const mask = {
  mounted: function (el, binding) {
    Inputmask(binding.value).mask(el);
  },
};

export default {
  name: 'PhoneInput',
  directives: { mask },
  inheritAttrs: false,
  props: [
    'input',
    'field',
    'modelValue',
  ],
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    localPhoneNumber: {
      get() {
        if (!isEmpty(this.localValue) && !isEmpty(this.localValue.full)) {
          return !isNil(this.localValue.ext)
            ? `${this.localValue.full}${this.localValue.ext}`
            : this.localValue.full;
        }

        return this.localValue;
      },
      set(newValue) {
        this.localValue = newValue;
      },
    },
    maskOptions() {
      return {
        mask: this.inputMask,
      };
    },
    inputMask() {
      if (this.inputFormat === 'any') {
        return '';
      }

      if (this.input?.format?.extension !== true) {
        return this.inputFormat;
      }

      const extensionMask = times(5, constant(9)).join('');

      return `${this.inputFormat}x${extensionMask}`;
    },
    inputFormat() {
      return this.input?.format?.format?.replace(/\?/g, '') || 'any';
    },
    placeholderFromFormat() {
      if (this.inputFormat === 'any') {
        return '';
      }

      // converts all numbers to underscores for use in placeholder
      // Eg. (999) 999-9999 will become (___) ___-____
      return this.input?.format?.format
        ?.replace(/\?/g, '')
        .replace(/[\d]/g, '_')
        .replace('(_)', '(0)') || '';
    },
  },
  watch: {
    async inputMask() {
      await this.$nextTick();

      if (!this.$refs.input) {
        return;
      }

      // When input mask changes we need to let the plugin reset our value after it renders
      this.localPhoneNumber = this.$refs.input.value;
    },
  },
};
</script>

<style lang="scss">
</style>
