import TOC from '@/components/views/table/TOC';
import Source from '@/components/views/table/Source';
import Settings from '@/components/views/table/Settings';
import Design from '@/components/views/table/Design';
import Columns from '@/components/views/table/Columns';
import ColumnTOC from '@/components/views/table/ColumnTOC';
import ColumnProperties from '@/components/views/table/ColumnProperties';
import ColumnLayout from '@/components/views/table/ColumnLayout';
import ColumnDisplayRules from '@/components/views/table/ColumnDisplayRules';

export default [
  {
    path: ':pageKey/views/:viewKey/table',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/table/source',
    components: {
      toolbox: Source,
    },
  },
  {
    path: ':pageKey/views/:viewKey/table/columns',
    components: {
      toolbox: Columns,
    },
  },
  {
    path: ':pageKey/views/:viewKey/table/columns/:columnIndex',
    components: {
      toolbox: ColumnProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/table/columns/:columnIndex/properties',
    components: {
      toolbox: ColumnProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/table/columns/:columnIndex/layout',
    components: {
      toolbox: ColumnLayout,
    },
  },
  {
    path: ':pageKey/views/:viewKey/table/columns/:columnIndex/rules',
    components: {
      toolbox: ColumnDisplayRules,
    },
  },
  {
    path: ':pageKey/views/:viewKey/table/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/table/design',
    components: {
      toolbox: Design,
    },
  },
];
