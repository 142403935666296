<template>
  <div class="inputGroup">
    <Modal
      v-if="showApproveGeocoding"
      title="Enable Geocode Records"
      size="small"
      :z-index="2"
      @close="rejectGeocoding"
    >
      <p>
        Enabling this feature will <a
          href="https://learn.knack.com/article/so3jpxe1di-app-settings-map-provider#geocoding-of-records"
          target="_new"
          class="underline text-default"
        >geocode</a> this Address field’s values for all records in this object.
      </p>
      <p>
        If you’re using your own Google Maps API key, you will be responsible for any charges from Google for usage beyond their free limits.
      </p>
      <div class="submit-buttons flex justify-end">
        <a
          class="button save px-3 py-2.5 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium mr-0"
          @click="approveGeocoding"
        >
          Yes, geocode the records
        </a>
      </div>
    </Modal>

    <Modal
      v-if="showApproveAutocomplete"
      key="approve-autocomplete-modal"
      title="Enable Address Autocomplete"
      size="small"
      :z-index="2"
      @close="rejectAutocomplete"
    >
      <p v-if="!fieldLocal.format.enable_geocoding">
        Address Autocomplete requires the records on this field to be <a
          href="https://learn.knack.com/article/so3jpxe1di-app-settings-map-provider#geocoding-of-records"
          target="_new"
          class="underline text-default"
        >geocoded</a>.
      </p>
      <p v-if="mapProviderIsGoogle">
        Any address auto-completions <span v-if="!fieldLocal.format.enable_geocoding">or geocoding</span> will count toward your Google Maps API usage. You will be responsible for any charges from Google for usage beyond their free limits.
      </p>
      <div class="submit-buttons flex justify-end">
        <a
          class="button save px-3 py-2.5 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium mr-0"
          @click="approveAutocomplete"
        >
          Yes, enable address autocomplete
        </a>
      </div>
    </Modal>

    <div>
      <IfPlan :is-starter="true">
        <div class="mb-2">
          <UpgradeButton />
        </div>
      </IfPlan>
      <label class="text-default text-sm font-medium mb-2 leading-4">Geospatial options</label>
      <div class="input-labels tw-input-labels">
        <label class="items-baseline"><input
          v-model="fieldLocal.format.enable_geocoding"
          type="checkbox"
          :disabled="isStarter()"
          @change="onChangeGeocoding"
        >Enable Geocoding<HelpIcon text="Geocoding converts a location's address into coordinates of a real location on the earth's surface. This allows the address to be shown on a Map view or to be searched with the “is near (a zip code)” filter." /></label>
        <label
          v-if="!isLatLong"
          class="items-baseline"
        ><input
          v-model="fieldLocal.format.enable_address_autocomplete"
          type="checkbox"
          :disabled="isStarter()"
          @change="onChangeAutocomplete"
        >Enable Address Auto-complete</label>
      </div>
    </div>

    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Input</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="fieldLocal.format.input"
          type="radio"
          value="address"
        >Address</label>
        <label><input
          v-model="fieldLocal.format.input"
          type="radio"
          value="lat_long"
        >Latitude/Longitude</label>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Format</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="fieldLocal.format.format"
          type="radio"
          value="US"
        >U.S.</label>
        <label><input
          v-model="fieldLocal.format.format"
          type="radio"
          value="international"
        >International</label>
        <label><input
          v-model="fieldLocal.format.format"
          type="radio"
          value="international_country"
        >International (with country)</label>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/ui/Modal';
import HelpIcon from '@/components/ui/HelpIcon';
import { isStarter } from '@/lib/plan-helper';
import IfPlan from '@/components/util/IfPlan';
import UpgradeButton from '@/components/ui/UpgradeButton.vue';

export default {
  name: 'Address',
  components: {
    UpgradeButton,
    IfPlan,
    HelpIcon,
    Modal,
  },
  props: {
    fieldLocal: {
      type: Object,
      default: () => ({
        format: {
          enable_geocoding: false,
          enable_address_autocomplete: false,
          input: 'address',
          format: 'US',
        },
      }),
    },
  },
  data() {
    return {
      showApproveGeocoding: false,
      showApproveAutocomplete: false,
    };
  },
  computed: {
    isLatLong() {
      return this.fieldLocal.format.input === 'lat_long';
    },
    mapProviderIsGoogle() {
      return this.$store.getters.app.get('settings').mapsAndGeocoderProvider === 'google';
    },
  },
  methods: {
    isStarter,
    onChangeGeocoding() {
      if (this.fieldLocal.format.enable_geocoding) {
        if (this.mapProviderIsGoogle) {
          this.showApproveGeocoding = true;
        }
      }
    },
    approveGeocoding() {
      this.fieldLocal.format.enable_geocoding = true;

      this.showApproveGeocoding = false;
    },
    rejectGeocoding() {
      this.fieldLocal.format.enable_geocoding = false;

      this.showApproveGeocoding = false;
    },
    onChangeAutocomplete() {
      if (this.fieldLocal.format.enable_address_autocomplete) {
        // show approval if we need to activate gecoding or warn about google charges
        this.showApproveAutocomplete = (this.mapProviderIsGoogle || !this.fieldLocal.format.enable_geocoding);
      }
    },
    approveAutocomplete() {
      // auto complete also means geocoding also needs to happen
      this.fieldLocal.format.enable_geocoding = true;

      this.fieldLocal.format.enable_address_autocomplete = true;

      this.showApproveAutocomplete = false;
    },
    rejectAutocomplete() {
      this.fieldLocal.format.enable_address_autocomplete = false;

      this.showApproveAutocomplete = false;
    },
  },
};
</script>
