<template>
  <div
    class="kn-list-wrapper"
    style="min-width: 500px;"
  >
    <RecordNavigation
      :allow-limit="allowLimit"
      :current-records-page="queryVars.currentPage"
      :filters="filters"
      :filter-menu-links="filterMenuLinks"
      :object-key="object.key"
      :records="records"
      :records-per-page="queryVars.recordsPerPage"
      :show-keyword-search="keywordSearch"
      :show-export-button="showExportButton"
      :show-filters="showFilters"
      :show-filter-menu="showFilterMenu"
      :current-records-count="currentRecordsCount"
      :total-records="totalRecords"
      :total-records-pages="totalPages"
    />

    <div
      class="kn-list-items"
      :class="viewClass"
    >
      <template
        v-for="(record, recordIndex) in records"
        :key="recordIndex"
      >
        <DetailsView
          v-if="recordIndex === 0 || view.key === `new`"
          class="kn-list-item-container"
          :class="{ 'group has-util-dropdown': recordIndex === 0 }"
          :should-show-utility-dropdown="recordIndex === 0"
          :schema="detailsSchema"
          :data="record"
          :view="view"
          :route-prefix="routePrefixDetails"
          @delete="onDeleteDetail"
          @drop="onDropDetail"
        />
        <div
          v-else
          class="list-item-wrapper"
        >
          <div
            v-tippy
            content="Use the first list item to add and edit list details"
            class="list-item-overlay"
          />
          <DetailsView
            class="kn-list-item-container"
            :schema="detailsSchema"
            :data="record"
            :view="view"
            :is-editable="false"
          />
        </div>
      </template>
    </div>
    <RecordNavigation
      v-if="showBottomPagination"
      :allow-limit="allowLimit"
      :current-records-page="queryVars.currentPage"
      :filter-menu-links="filterMenuLinks"
      :object-key="object.key"
      :records="records"
      :show-export-button="false"
      :records-per-page="queryVars.recordsPerPage"
      :show-keyword-search="false"
      :show-filters="false"
      :show-filter-menu="false"
      :current-records-count="currentRecordsCount"
      :show-pagination-range="false"
      :total-records="totalRecords"
      :total-records-pages="totalPages"
    />
  </div>
</template>

<script>
import DetailsView from '@/components/renderer/shared/Details';
import RecordNavigation from '@/components/renderer/shared/RecordNavigation';
import RendererUtils from '@/components/renderer/RenderUtils';
import DetailActionsMixin from '@/components/renderer/mixins/DetailActionsMixin';
import QueryMixin from '@/components/renderer/mixins/QueryMixin';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  components: {
    DetailsView,
    RecordNavigation,
  },
  mixins: [
    RendererUtils,
    DetailActionsMixin,
    QueryMixin,
    RequestUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    routePrefixDetails() {
      return `${this.routePrefix}/items`;
    },
    viewClass() {
      const layouts = {
        'one-column': 'is-full',
        'three-column': 'is-one-third',
        'two-column': 'is-half',
      };
      const classes = [
        layouts[this.view.getDetailsLayout()],
      ];

      return classes;
    },
    detailsSchema: {
      get() {
        return this.view.getDetailsSchema();
      },
      set(newVal) {
        this.view.setDetailsSchema(newVal);
      },
    },
    showBottomPagination() {
      return this.view.attributes.display_pagination_below;
    },
  },
  watch: {
    'view.data': function (newVal) {
      this.setRecords(newVal);
    },
  },
  created() {
    this.setRecords(this.view.data);

    // transform view object into data for props
    // of RecordTools and TableComponent
    this.initQueryVars();
  },
  methods: {
    queryRecordsRequest(queryVars, onSuccess, onError) {
      this.commitRequest({
        request: () => this.view.getData(queryVars),
        onSuccess: (response) => this.view.onGetDataSuccess(response, onSuccess),
        onError,
      });
    },
  },
};
</script>

<style lang="scss">
.kn-list {
  width: 100%;
}

.kn-list-wrapper {

  .kn-list-item-container .kn-details-column {
    padding-left: 0!important;
  }

  .kn-list-items {
    display: grid;
    width: max-content;

    .kn-details-column {
      padding: 0 10px;
    }

    > * {
      width: 100%;
    }

    &.is-half {
      grid-template-columns: repeat(2, 1fr);
    }
    &.is-one-third {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
