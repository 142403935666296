<template>
  <view-toolbox
    back-title="Form Menu"
    title="Advanced Options"
  >
    <form>
      <div>
        <label>E-Commerce</label>
        <div v-if="hasNumericFields">
          <div v-if="viewEcommerceEnabled">
            <p>This form will redirect to a checkout page to accept payment.</p>
            <button
              class="button save"
              :disabled="hasPendingEcommerceUpdates"
              @click="disableFormEcommerce"
            >
              Remove Payment View
            </button>
          </div>
          <div v-else>
            <p>Add payment view to:</p>
            <p>Accept payments with this form.</p>
            <p>Create a customer to charge later.</p>
            <router-link :to="routeAppend($route.path, 'ecommerce')">
              <button
                class="button save"
                :disabled="hasPendingEcommerceUpdates"
              >
                Add Payment View
              </button>
            </router-link>
          </div>
        </div>
        <div v-else>
          <p>Add a numeric field if you wish to use E-Commerce features on this form.</p>
        </div>
      </div>
    </form>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import RequestUtils from '@/components/util/RequestUtils';
import PaymentUtils from '@/components/settings/ecommerce/PaymentUtils';

export default {
  name: 'FormAdvanced',
  components: {
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
    RequestUtils,
    PaymentUtils,
  ],
  computed: {
    hasNumericFields() {
      return this.numericFields.length > 0;
    },
    viewEcommerceEnabled() {
      return this.view.attributes.ecommerce === true;
    },
    hasPendingEcommerceUpdates() { // this wouldn't be necessary with a better UX...
      return _.hasIn(this.view.attributes, 'ecommerce') && this.view.attributes.ecommerce !== this.view.attributesRestore.ecommerce;
    },
  },
  methods: {
    disableFormEcommerce() {
      this.view.attributes.ecommerce = false;
      this.view.attributes.rules.submits = this.view.attributes.rules.submits.filter((rule) => !rule.ecommerce);
    },
  },
};
</script>

<style lang="scss">
</style>
