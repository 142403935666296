<template>
  <div id="sso-providers">
    <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Single Sign On</label>

    <div class="base-list">
      <div
        v-if="appSettings.sso_google"
        class="sso-item bg-white p-2 rounded-lg text-default border border-solid border-default"
        :class="{ 'not-selected': !view.sso_google, 'selected': view.sso_google }"
      >
        <div class="tools-container">
          <label
            class="pt-0 text-default"
            @click="changeSSOStatus($event, `sso_google`)"
          >
            <Icon
              v-if="view.sso_google"
              class="checked text-default"
              type="check-box-fill"
            />
            <Icon
              v-else
              class="not-checked"
              type="check-box-unchecked"
            />&nbsp;
            Google
          </label>
          <div class="tools">
            <router-link
              :to="`${pageLink}/sso/edit/sso_google`"
              class="button-square settings bg-transparent hover:bg-subtle rounded"
              data-cy="edit-sso-provider"
            >
              <Icon
                type="edit"
                class="h-4 w-4 text-default"
              />
            </router-link>
          </div>
        </div>
      </div>

      <div
        v-if="appSettings.sso_openid"
        class="sso-item bg-white p-2 rounded-lg text-default border border-solid border-default"
        :class="{ 'not-selected': !view.sso_openid, 'selected': view.sso_openid }"
      >
        <div class="tools-container">
          <label
            class="pt-0 text-default"
            @click="changeSSOStatus($event, `sso_openid`)"
          >
            <Icon
              v-if="view.sso_openid"
              class="checked text-default"
              type="check-box-fill"
            />
            <Icon
              v-else
              class="not-checked"
              type="check-box-unchecked"
            />&nbsp;
            OpenID
          </label>
          <div class="tools">
            <router-link
              :to="`${pageLink}/sso/edit/sso_openid`"
              class="button-square settings bg-transparent hover:bg-subtle rounded"
              data-cy="edit-sso-provider"
            >
              <Icon
                type="edit"
                class="h-4 w-4 text-default"
              />
            </router-link>
          </div>
        </div>
      </div>

      <IfPlan
        :level-is-minimum-of="2"
        :allow-if-sso="true"
      >
        <div>
          <div
            v-for="customSSO in sortedCustomSSO"
            :key="customSSO.name"
            class="sso-item bg-white p-2 rounded-lg text-default border border-solid border-default"
            :class="{ 'not-selected': !view[`sso_custom_${customSSO.name}`], 'selected': view[`sso_custom_${customSSO.name}`] }"
          >
            <div class="tools-container">
              <label
                class="pt-0 text-default"
                @click="changeSSOStatus($event, customSSO.name)"
              >
                <Icon
                  v-if="view[`sso_custom_${customSSO.name}`]"
                  class="checked text-default"
                  type="check-box-fill"
                />
                <Icon
                  v-else
                  class="not-checked"
                  type="check-box-unchecked"
                />&nbsp;
                <span class="custom-sso-name">{{ customSSO.name }}</span>&nbsp;({{ customSSO.type }})
              </label>
              <div class="tools">
                <a
                  v-if="customSSO.type === `saml`"
                  v-tippy
                  content="Download SSO metadata (SAML only)"
                  :href="`${apiBaseUrl}/v1/applications/${app.get(`id`)}/auth/${customSSO.name}/metadata`"
                  class="button-square settings bg-transparent hover:bg-subtle rounded"
                  target="_blank"
                >
                  <Icon
                    class="h-4 w-4 text-default"
                    type="download-export"
                  />
                </a>
                <router-link
                  :to="`${pageLink}/sso/edit/${customSSO.name}`"
                  class="button-square settings bg-transparent hover:bg-subtle rounded"
                  data-cy="edit-sso-provider"
                >
                  <Icon
                    type="edit"
                    class="h-4 w-4 text-default"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </IfPlan>
    </div>

    <router-link
      v-tippy
      content="Add a new Single Sign-On provider"
      :to="`${pageLink}/sso/add`"
      data-cy="add-sso-provider"
      class="button green-gray small h-10 w-fit p-3 rounded-lg border border-solid border-default bg-white leading-4 text-emphasis text-base no-underline font-medium capitalize group flex justify-center hover:bg-brand-50 hover:border-brand-600 mt-3"
    >
      <Icon
        class="text-default group-hover:text-brand-400 h-4 w-4 mr-2 fill-current"
        type="plus-thin"
      /> Add Provider
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import IfPlan from '@/components/util/IfPlan';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  name: 'SSO',
  components: {
    Icon,
    IfPlan,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentAuthProtocol: null,
      profileDataResponse: null,
      ssoTestSuccess: null,
      choices: [
        {
          label: 'On',
          value: true,
        },
        {
          label: 'Off',
          value: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'apiBaseUrl',
      'getRemovedSSOMethods',
      'app',
    ]),
    appSettings() {
      return this.$store.state.app.get('settings');
    },
    sortedCustomSSO() {
      return _.orderBy(this.appSettings.sso, [
        (sso) => String(sso.name).toLowerCase(),
      ]);
    },
    ssoTestCode() {
      return this.$store.getters.ssoTestCode;
    },
    pageLink() {
      return `/pages/${this.$route.params.pageKey}/views/${this.$route.params.viewKey}/login/settings`;
    },
    profileData() {
      // handle SAML reformatting
      // TODO: update to delete all top-level objects OR flatten object
      if (!_.isNil(this.profileDataResponse) && typeof this.profileDataResponse.user === 'object') {
        delete this.profileDataResponse.user.issuer;

        return this.profileDataResponse.user;
      }

      return this.profileDataResponse;
    },
    isCustomProvider() {
      return ([
        'google',
        'openid',
      ].indexOf(this.$route.params.provider) === -1);
    },
  },
  mounted() {
    if (this.ssoTestCode) {
      if (!this.isCustomProvider) {
        this.updateTestStatus();

        return;
      }

      this.requestUserProfile();
    }
  },
  methods: {
    changeSSOStatus(event, name) {
      switch (name) {
        case 'sso_google':
        case 'sso_openid':
          this.view[name] = !this.view[name];
          break;

        default: // this is the free form, custom SSO case
          this.view[`sso_custom_${name}`] = !this.view[`sso_custom_${name}`];
          break;
      }
    },
    requestUserProfile() {
      const strategyName = localStorage.getItem('sso-test-strategy-name');
      const returnUrl = window.location.href.split('#')[0];
      const code = this.ssoTestCode;

      this.commitRequest({
        request: () => window.Knack.Api.getSSOUserProfile(strategyName, returnUrl, code),
        onSuccess: (profileDataResponse) => {
          this.profileDataResponse = profileDataResponse;

          log(`profile: ${JSON.stringify(this.profileDataResponse)}`);

          localStorage.removeItem('sso-test-strategy-name');
          localStorage.removeItem('sso-test-code');
          localStorage.removeItem('sso-test-page-key');
          localStorage.removeItem('sso-test-view-key');
        },
      });
    },
    updateTestStatus() {
      localStorage.removeItem('sso-test-strategy-name');
      localStorage.removeItem('sso-test-code');
      localStorage.removeItem('sso-test-page-key');
      localStorage.removeItem('sso-test-view-key');

      this.ssoTestSuccess = true;
    },
  },
};
</script>

<style lang="scss">
.sso-item {
  align-items: center;
  display: flex;
  padding: .40625em .5em .40625em .75em;
  background-color: #e6e9ec;
  border-radius: .375em;
  border: 1px solid #e6e9ec;
  cursor: pointer;
  margin-bottom: .5em;

  &.not-selected {
    color: darken(#e6e9ec, 15%);

    .tools-container {

      label {
        color: darken(#e6e9ec, 45%);
      }
    }
  }

  &.selected {
    .tools-container {
      label {
        color: #91267f;
      }
    }
  }
}

.custom-sso-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#sso-providers {
  .base-list {
    .tools-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        display: flex;
        flex-grow: 1;
        font-size: .9em;
        font-weight: 600;
        padding-top: 4px;
      }

      .tools {
        display: flex;

        a {
          margin-left: .5em;
        }

        .icon {
          color: darken(#e6e9ec, 50%);
        }
      }

      .icon.not-checked {
        path {
          fill: darken(#e6e9ec, 25%);
        }
      }

      .icon.checked {
        color: #91267f;
      }
    }
    svg {
      height: 17px;
      width: 17px;
    }
  }
}
</style>
