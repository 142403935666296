import { computed, reactive} from 'vue';

type PreviewSizeType = 'desktop' | 'tablet' | 'mobile';

const state = reactive({
  previewSize: 'desktop',
  showNotificationPreview: false,
});

export default function useLiveAppDesignPreview() {
  const setPreviewSize = (newPreviewSize: PreviewSizeType) => {
    state.previewSize = newPreviewSize;
  };

  const setShowNotificationPreview = (newShowNotificationPreview: boolean) => {
    state.showNotificationPreview = newShowNotificationPreview;
  };

  const resetLiveAppDesignPreviewState = () => {
    state.showNotificationPreview = false;
    state.previewSize = 'desktop';
  };

  return {
    previewSize: computed(() => state.previewSize),
    showNotificationPreview: computed(() => state.showNotificationPreview),
    resetLiveAppDesignPreviewState,
    setPreviewSize,
    setShowNotificationPreview,
  };
}
