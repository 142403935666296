import $ from 'jquery';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import store from '@/store';
import { getFlagsmithEnabledFlags } from '@/lib/flagsmith-helper';

function segmentEnabled() {
  return process.env.VUE_APP_SEGMENT_WRITE_KEY !== '' && (!window.Cypress || store.state.app.settings.enableSegment);
}

export const isTestingEmail = (email) => {
  // By default we don't want to track knack emails
  if (process.env.VUE_APP_SEGMENT_TRACK_KNACK_EMAILS !== 'true') {
    const isKnackEmail = email.includes('@knack.com') || email.includes('@knackhq.com');
    if (isKnackEmail) {
      return true;
    }
  }

  const testEmailPrefixes = [
    'dev+appbuilder',
    'dev+cypress-',
    'dev+apiwrappertests-',
    'dev+benchmarktests-',
    'dev+playwright-',
    'dev.playwright.',
  ];

  return testEmailPrefixes.some((testEmailPrefix) => email.startsWith(testEmailPrefix));
};

// Set up analytics library in a module-friendly way
// Based on https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
async function loadSegment() {
  if (!segmentEnabled()) {
    return;
  }

  return new Promise(async (resolve) => {
    window.analytics = get(window, 'analytics', []);

    // If analytics.js has already been loaded
    if (!isNil(window.analytics.initialize) || window.analytics.invoked === true) {
      return resolve();
    }

    // The following is all boilerplate to stub methods on for the script loader, refactored slightly from their required snippet for readability
    window.analytics.invoked = true;

    window.analytics.SNIPPET_VERSION = '4.1.0';

    // Get the segment analytics script
    await new Promise((resolve) => {
      $.getScript(`https://cdn.segment.com/analytics.js/v1/${process.env.VUE_APP_SEGMENT_WRITE_KEY}/analytics.min.js`, async () => {
        let attempts = 0;

        // Retry if the library has not fully loaded yet,
        // introduces up to a 5 second delay when loading segment.
        // Unclear what causes this race condition as no code changes
        // seem to have been made around it.
        while (attempts < 10 && !window.analytics.identify) {
          await new Promise((resolve) => setTimeout(resolve, 500));

          attempts++;
        }

        resolve();
      });
    });

    const account = store.state.app.get('account');

    const appName = store.state.app.get('name');

    const appSlug = store.state.app.get('slug');

    const planName = get(account, 'product_plan.name', 'Trial');

    const monthlySpend = get(account, 'product_plan.price', 0);

    const planRecords = get(account, 'product_plan.records');

    const planApps = get(account, 'product_plan.apps');

    const v3Only = get(account, 'settings.v3_only') === true;

    const accountSlug = get(account, 'slug');

    if (!isTestingEmail(Knack.Api.email)) {
      const accountMeta = {
        name: account.name,
        plan: planName,
        monthly_spend: monthlySpend,
        plan_records: planRecords,
        plan_apps: planApps,
        referral: account.referral,
      };

      const meta = {
        email: Knack.Api.email,
        name: `${Knack.Api.first_name} ${Knack.Api.last_name}`,
        company: {
          id: Knack.Api.account_id,
          ...accountMeta
        },
        v3_only: v3Only,
        app_slug: appSlug,
        app_name: appName,
        v3_account_slug: accountSlug,
        plan: planName,
        traits: {
          email: Knack.Api.email,
          plan: planName,
          monthly_spend: monthlySpend,
          plan_records: planRecords,
          plan_apps: planApps,
        },
      };

      await new Promise((res) => {
        window.analytics.identify(Knack.Api.user_id, meta, () => res());
      });

      window.analytics.group(Knack.Api.account_id, {
        ...accountMeta,

        // Add flagsmith_ prefix to all flag names to avoid confusion
        ...Object.fromEntries(
          Object.entries(await getFlagsmithEnabledFlags()).map(([k, v]) => [`flagsmith_${k}`, v]),
        ),
      }, () => resolve());
    }
  });
}

export async function logEvent(event, metadata) {
  if (!segmentEnabled() || !store.state.app || !store.state.app.get || isTestingEmail(Knack.Api.email)) {
    return;
  }

  await loadSegment();

  const account = store.state.app.get('account');

  const extendedMetadata = {
    groupId: Knack.Api.account_id,
    account_id: Knack.Api.account_id,
    application_id: Knack.Api.application_id,
    plan: get(account, 'product_plan.name', 'Trial'),
    monthly_spend: get(account, 'product_plan.price', 0),
    ...metadata,
  };

  if (hasIn(window, 'analytics.track')) {
    window.analytics.track(event, extendedMetadata);
  }
}

export async function logPage() {
  if (!segmentEnabled() || !store.state.app || !store.state.app.get || isTestingEmail(Knack.Api.email)) {
    return;
  }

  await loadSegment();

  if (hasIn(window, 'analytics.page')) {
    window.analytics.page();
  }
}
