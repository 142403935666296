<template>
  <Popover placement="bottom-start">
    <template #trigger>
      <a
        v-tippy
        content="Settings, copy, & delete"
        class="settings buttonSquare-outline -color-secondary -size-small hover:bg-brand-50 bg-transparent border-0 rounded"
      >
        <Icon
          class="h-6 w-6 text-default"
          type="chevron-single"
        />
      </a>
    </template>

    <template #content>
      <div class="links">
        <RouterLink
          v-close-popper
          class="hover:text-emphasis"
          :class="listItemClasses"
          :to="`${baseUrl}/${objectKey}/settings/edit`"
        >
          <Icon type="settings" /> Settings
        </RouterLink>
        <RouterLink
          v-if="showCopy"
          v-close-popper
          class="hover:text-emphasis"
          :class="listItemClasses"
          :to="`${baseUrl}/${objectKey}/copy`"
        >
          <Icon type="copy" /> Copy
        </RouterLink>
        <RouterLink
          v-if="showDelete"
          v-close-popper
          class="text-destructive hover:text-destructive"
          :class="listItemClasses"
          :to="`${baseUrl}/${objectKey}/delete`"
        >
          <Icon type="delete" /> Delete
        </RouterLink>
      </div>
    </template>
  </Popover>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';

export default {
  components: {
    Icon,
    Popover,
  },
  props: {
    baseUrl: {
      type: String,
      default: '',
    },
    object: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    objectKey() {
      return this.object.key;
    },
    showCopy() {
      return !this.object.ecommerce && !this.object.ecommercePaymentMethods;
    },
    showDelete() {
      return this.object.profile_key !== 'all_users' && !this.object.ecommerce && !this.object.ecommercePaymentMethods;
    },
    listItemClasses() {
      return 'rounded-lg hover:bg-brand-50 text-base text-default';
    },
  },
};
</script>

<style lang="scss">
</style>
