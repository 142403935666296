<template>
  <select
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  >
    <option
      v-for="currency in processorCurrencies"
      :value="currency.value"
    >
      {{ currency.name }}
    </option>
  </select>
</template>

<script>
export default {
  props: [
    'processor',
    'modelValue',
  ],
  emits: [
    'update:modelValue',
  ],
  computed: {
    currencies() {
      return [
        {
          name: 'United Arab Emirates Dirham - AED',
          value: 'AED',
        },
        {
          name: 'Afghan Afghani - AFN',
          value: 'AFN',
        },
        {
          name: 'Albanian Lek - ALL',
          value: 'ALL',
        },
        {
          name: 'Armenian Dram - AMD',
          value: 'AMD',
        },
        {
          name: 'Netherlands Antillean Gulden - ANG',
          value: 'ANG',
        },
        {
          name: 'Angolan Kwanza - AOA',
          value: 'AOA',
        },
        {
          name: 'Argentine Peso - ARS',
          value: 'ARS',
        },
        {
          name: 'Australian Dollar - AUD',
          value: 'AUD',
          paypal: true,
        },
        {
          name: 'Aruban Florin - AWG',
          value: 'AWG',
        },
        {
          name: 'Azerbaijani Manat - AZN',
          value: 'AZN',
        },
        {
          name: 'Bosnia &amp; Herzegovina Convertible Mark - BAM',
          value: 'BAM',
        },
        {
          name: 'Barbadian Dollar - BBD',
          value: 'BBD',
        },
        {
          name: 'Bangladeshi Taka - BDT',
          value: 'BDT',
        },
        {
          name: 'Bulgarian Lev - BGN',
          value: 'BGN',
        },
        {
          name: 'Burundian Franc - BIF',
          value: 'BIF',
        },
        {
          name: 'Bermudian Dollar - BMD',
          value: 'BMD',
        },
        {
          name: 'Brunei Dollar - BND',
          value: 'BND',
        },
        {
          name: 'Bolivian Boliviano - BOB',
          value: 'BOB',
        },
        {
          name: 'Brazilian Real - BRL',
          value: 'BRL',
          paypal: true,
        },
        {
          name: 'Bahamian Dollar - BSD',
          value: 'BSD',
        },
        {
          name: 'Botswana Pula - BWP',
          value: 'BWP',
        },
        {
          name: 'Belize Dollar - BZD',
          value: 'BZD',
        },
        {
          name: 'Canadian Dollar - CAD',
          value: 'CAD',
          paypal: true,
        },
        {
          name: 'Congolese Franc - CDF',
          value: 'CDF',
        },
        {
          name: 'Swiss Franc - CHF',
          value: 'CHF',
          paypal: true,
        },
        {
          name: 'Chilean Peso - CLP',
          value: 'CLP',
        },
        {
          name: 'Chinese Renminbi Yuan - CNY',
          value: 'CNY',
        },
        {
          name: 'Colombian Peso - COP',
          value: 'COP',
        },
        {
          name: 'Costa Rican Colón - CRC',
          value: 'CRC',
        },
        {
          name: 'Cape Verdean Escudo - CVE',
          value: 'CVE',
        },
        {
          name: 'Czech Koruna - CZK',
          value: 'CZK',
          paypal: true,
        },
        {
          name: 'Djiboutian Franc - DJF',
          value: 'DJF',
        },
        {
          name: 'Danish Krone - DKK',
          value: 'DKK',
          paypal: true,
        },
        {
          name: 'Dominican Peso - DOP',
          value: 'DOP',
        },
        {
          name: 'Algerian Dinar - DZD',
          value: 'DZD',
        },
        {
          name: 'Estonian Kroon - EEK',
          value: 'EEK',
        },
        {
          name: 'Egyptian Pound - EGP',
          value: 'EGP',
        },
        {
          name: 'Ethiopian Birr - ETB',
          value: 'ETB',
        },
        {
          name: 'Euro - EUR',
          value: 'EUR',
          paypal: true,
        },
        {
          name: 'Fijian Dollar - FJD',
          value: 'FJD',
        },
        {
          name: 'Falkland Islands Pound - FKP',
          value: 'FKP',
        },
        {
          name: 'British Pound - GBP',
          value: 'GBP',
          paypal: true,
        },
        {
          name: 'Georgian Lari - GEL',
          value: 'GEL',
        },
        {
          name: 'Gibraltar Pound - GIP',
          value: 'GIP',
        },
        {
          name: 'Gambian Dalasi - GMD',
          value: 'GMD',
        },
        {
          name: 'Guinean Franc - GNF',
          value: 'GNF',
        },
        {
          name: 'Guatemalan Quetzal - GTQ',
          value: 'GTQ',
        },
        {
          name: 'Guyanese Dollar - GYD',
          value: 'GYD',
        },
        {
          name: 'Hong Kong Dollar - HKD',
          value: 'HKD',
          paypal: true,
        },
        {
          name: 'Honduran Lempira - HNL',
          value: 'HNL',
        },
        {
          name: 'Croatian Kuna - HRK',
          value: 'HRK',
        },
        {
          name: 'Haitian Gourde - HTG',
          value: 'HTG',
        },
        {
          name: 'Hungarian Forint - HUF',
          value: 'HUF',
          paypal: true,
        },
        {
          name: 'Indonesian Rupiah - IDR',
          value: 'IDR',
        },
        {
          name: 'Israeli New Sheqel - ILS',
          value: 'ILS',
          paypal: true,
        },
        {
          name: 'Indian Rupee - INR',
          value: 'INR',
        },
        {
          name: 'Icelandic Króna - ISK',
          value: 'ISK',
        },
        {
          name: 'Jamaican Dollar - JMD',
          value: 'JMD',
        },
        {
          name: 'Japanese Yen - JPY',
          value: 'JPY',
          paypal: true,
        },
        {
          name: 'Kenyan Shilling - KES',
          value: 'KES',
        },
        {
          name: 'Kyrgyzstani Som - KGS',
          value: 'KGS',
        },
        {
          name: 'Cambodian Riel - KHR',
          value: 'KHR',
        },
        {
          name: 'Comorian Franc - KMF',
          value: 'KMF',
        },
        {
          name: 'South Korean Won - KRW',
          value: 'KRW',
        },
        {
          name: 'Cayman Islands Dollar - KYD',
          value: 'KYD',
        },
        {
          name: 'Kazakhstani Tenge - KZT',
          value: 'KZT',
        },
        {
          name: 'Lao Kip - LAK',
          value: 'LAK',
        },
        {
          name: 'Lebanese Pound - LBP',
          value: 'LBP',
        },
        {
          name: 'Sri Lankan Rupee - LKR',
          value: 'LKR',
        },
        {
          name: 'Liberian Dollar - LRD',
          value: 'LRD',
        },
        {
          name: 'Lesotho Loti - LSL',
          value: 'LSL',
        },
        {
          name: 'Lithuanian Litas - LTL',
          value: 'LTL',
        },
        {
          name: 'Latvian Lats - LVL',
          value: 'LVL',
        },
        {
          name: 'Moroccan Dirham - MAD',
          value: 'MAD',
        },
        {
          name: 'Moldovan Leu - MDL',
          value: 'MDL',
        },
        {
          name: 'Malagasy Ariary - MGA',
          value: 'MGA',
        },
        {
          name: 'Macedonian Denar - MKD',
          value: 'MKD',
        },
        {
          name: 'Mongolian Tögrög - MNT',
          value: 'MNT',
        },
        {
          name: 'Macanese Pataca - MOP',
          value: 'MOP',
        },
        {
          name: 'Mauritanian Ouguiya - MRO',
          value: 'MRO',
        },
        {
          name: 'Mauritian Rupee - MUR',
          value: 'MUR',
        },
        {
          name: 'Maldivian Rufiyaa - MVR',
          value: 'MVR',
        },
        {
          name: 'Malawian Kwacha - MWK',
          value: 'MWK',
        },
        {
          name: 'Mexican Peso - MXN',
          value: 'MXN',
          paypal: true,
        },
        {
          name: 'Malaysian Ringgit - MYR',
          value: 'MYR',
          paypal: true,
        },
        {
          name: 'Mozambican Metical - MZN',
          value: 'MZN',
        },
        {
          name: 'Namibian Dollar - NAD',
          value: 'NAD',
        },
        {
          name: 'Nigerian Naira - NGN',
          value: 'NGN',
        },
        {
          name: 'Nicaraguan Córdoba - NIO',
          value: 'NIO',
        },
        {
          name: 'Norwegian Krone - NOK',
          value: 'NOK',
          paypal: true,
        },
        {
          name: 'Nepalese Rupee - NPR',
          value: 'NPR',
        },
        {
          name: 'New Zealand Dollar - NZD',
          value: 'NZD',
          paypal: true,
        },
        {
          name: 'Panamanian Balboa - PAB',
          value: 'PAB',
        },
        {
          name: 'Peruvian Nuevo Sol - PEN',
          value: 'PEN',
        },
        {
          name: 'Papua New Guinean Kina - PGK',
          value: 'PGK',
        },
        {
          name: 'Philippine Peso - PHP',
          value: 'PHP',
        },
        {
          name: 'Pakistani Rupee - PKR',
          value: 'PKR',
        },
        {
          name: 'Polish Złoty - PLN',
          value: 'PLN',
          paypal: true,
        },
        {
          name: 'Paraguayan Guaraní - PYG',
          value: 'PYG',
        },
        {
          name: 'Qatari Riyal - QAR',
          value: 'QAR',
        },
        {
          name: 'Romanian Leu - RON',
          value: 'RON',
        },
        {
          name: 'Serbian Dinar - RSD',
          value: 'RSD',
        },
        {
          name: 'Russian Ruble - RUB',
          value: 'RUB',
          paypal: true,
        },
        {
          name: 'Rwandan Franc - RWF',
          value: 'RWF',
        },
        {
          name: 'Saudi Riyal - SAR',
          value: 'SAR',
        },
        {
          name: 'Solomon Islands Dollar - SBD',
          value: 'SBD',
        },
        {
          name: 'Seychellois Rupee - SCR',
          value: 'SCR',
        },
        {
          name: 'Swedish Krona - SEK',
          value: 'SEK',
          paypal: true,
        },
        {
          name: 'Singapore Dollar - SGD',
          value: 'SGD',
          paypal: true,
        },
        {
          name: 'Saint Helenian Pound - SHP',
          value: 'SHP',
        },
        {
          name: 'Sierra Leonean Leone - SLL',
          value: 'SLL',
        },
        {
          name: 'Somali Shilling - SOS',
          value: 'SOS',
        },
        {
          name: 'Surinamese Dollar - SRD',
          value: 'SRD',
        },
        {
          name: 'São Tomé and Príncipe Dobra - STD',
          value: 'STD',
        },
        {
          name: 'Salvadoran Colón - SVC',
          value: 'SVC',
        },
        {
          name: 'Swazi Lilangeni - SZL',
          value: 'SZL',
        },
        {
          name: 'Thai Baht - THB',
          value: 'THB',
          paypal: true,
        },
        {
          name: 'Tajikistani Somoni - TJS',
          value: 'TJS',
        },
        {
          name: 'Tongan Paʻanga - TOP',
          value: 'TOP',
        },
        {
          name: 'Turkish Lira - TRY',
          value: 'TRY',
          paypal: true,
        },
        {
          name: 'Trinidad and Tobago Dollar - TTD',
          value: 'TTD',
        },
        {
          name: 'New Taiwan Dollar - TWD',
          value: 'TWD',
          paypal: true,
        },
        {
          name: 'Tanzanian Shilling - TZS',
          value: 'TZS',
        },
        {
          name: 'Ukrainian Hryvnia - UAH',
          value: 'UAH',
        },
        {
          name: 'Ugandan Shilling - UGX',
          value: 'UGX',
        },
        {
          name: 'United States Dollar - USD',
          value: 'USD',
          paypal: true,
        },
        {
          name: 'Uruguayan Peso - UYU',
          value: 'UYU',
        },
        {
          name: 'Uzbekistani Som - UZS',
          value: 'UZS',
        },
        {
          name: 'Vietnamese Đồng - VND',
          value: 'VND',
        },
        {
          name: 'Vanuatu Vatu - VUV',
          value: 'VUV',
        },
        {
          name: 'Samoan Tala - WST',
          value: 'WST',
        },
        {
          name: 'Central African Cfa Franc - XAF',
          value: 'XAF',
        },
        {
          name: 'East Caribbean Dollar - XCD',
          value: 'XCD',
        },
        {
          name: 'West African Cfa Franc - XOF',
          value: 'XOF',
        },
        {
          name: 'Cfp Franc - XPF',
          value: 'XPF',
        },
        {
          name: 'Yemeni Rial - YER',
          value: 'YER',
        },
        {
          name: 'South African Rand - ZAR',
          value: 'ZAR',
        },
        {
          name: 'Zambian Kwacha - ZMW',
          value: 'ZMW',
        },
      ];
    },
    processorCurrencies() {
      if (this.processor !== 'paypal') {
        return this.currencies;
      }

      return _.filter(this.currencies, {
        paypal: true,
      });
    },
  },
};
</script>
