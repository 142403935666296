<template>
  <Modal
    size="large"
    :title="`Performance - ${field.name}`"
    title-size="small"
    :back="backLink"
  >
    <div>
      <p>The following is everything that needs to update whenever this field is updated.</p>
      <p>This can have a big impact on performance!</p>
      <FieldPerformanceTree
        :impact-tree="impactTree"
      />
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import FieldPerformanceTree from '@/components/fields/FieldPerformanceTree';
import FieldUtils from '@/components/fields/FieldUtils';
import Modal from '@/components/ui/Modal';

export default {
  name: 'FieldPerformance',
  components: {
    FieldPerformanceTree,
    Modal,
  },
  mixins: [
    FieldUtils,
  ],
  data() {
    return {
      fieldLocal: null,
      impactTree: null,
      processedConnections: [],
    };
  },
  computed: {
    ...mapGetters([
      'getObject',
    ]),
    backLink() {
      return this.$route.path.split(`/${this.field.key}`)[0];
    },
  },
  created() {
    this.fieldLocal = this.field.raw();
    this.impactTree = this.generateImpactTree();
  },
  methods: {
    generateImpactTree() {
      log('getImpactTreeForObject!', this.object.key, this.fieldLocal.key);
      log(this.object);

      const fields = {};
      fields[this.fieldLocal.key] = this.addFieldImpact(
        this.fieldLocal.key,
        this.fieldLocal.name,
        this.fieldLocal.type,
        ['the current field'],
      );

      // Generate the impact tree for the local object and field
      return this.getImpactTreeForObject(this.object, fields);
    },
    addFieldImpact(key, name, type, reasons) {
      return {
        key,
        name,
        type,
        reasons,
      };
    },
    getImpactTreeForObject(object, fields, parentObject) {
      const impact = {
        key: object.key,
        name: object.name,
        fields,
        connections: [],
      };

      if (parentObject) {
        impact.parent = {
          key: parentObject.key,
          name: parentObject.name,
        };
      }

      // Check each field for local triggers
      const fieldLocals = this.checkObjectForLocalTriggers(
        this.object,
        impact.fields,
      );

      // Merge local fields into impact
      impact.fields = {
        ...impact.fields,
        ...fieldLocals,
      };

      log(`** ** ** ** ** Checking object connections ${object.name}`, object.conns);

      // Check each connected object
      object.conns.forEach((conn) => {
        // Return early if we've already processed this connection
        if (this.processedConnections.includes(conn.key)) {
          return;
        }

        // Ensure we only process this connection once
        this.processedConnections.push(conn.key);

        const connectedObject = this.getObject(conn.object);

        // Check for connected triggers
        const connectedFields = this.checkObjectForConnectedTriggers(
          connectedObject,
          impact.fields,
          conn.key,
        );

        log('connectedFields back: ', connectedFields);

        // Return if no connected fields were triggered
        if (isEmpty(connectedFields)) {
          return;
        }

        // Add this object and recursively check it's own connections
        impact.connections.push(
          {
            connection: conn,
            ...this.getImpactTreeForObject(connectedObject, connectedFields, object),
          },
        );
      });

      return impact;
    },
    checkObjectForLocalTriggers(object, triggerFields) {
      log(`checkObjectForLocalTriggers, object: ${object.key};`
        + ` triggerFields: ${Object.keys(triggerFields)}`);
      const impactedFields = {};

      // Check if any object fields are triggered by this field
      object.fields.forEach((localField) => {
        const reasons = this.getReasonsForLocalTriggers(localField, triggerFields);

        // Add to list if any reasons
        if (reasons.length > 0) {
          // Add to impacted Feilds
          if (!impactedFields[localField.key]) {
            impactedFields[localField.key] = this.addFieldImpact(
              localField.key,
              localField.name,
              localField.type,
              [],
            );
          }

          // Merge in reasons
          impactedFields[localField.key].reasons = [
            ...impactedFields[localField.key].reasons,
            ...reasons,
          ];
        }
      });

      log(`New impacted fields, empty? ${isEmpty(impactedFields)} `
        + `keys are: ${Object.keys(impactedFields)}`, impactedFields);

      // Recursively check the new updates for local triggers
      // We will very likely need a way to prevent infinite recursion here
      if (!isEmpty(impactedFields)) {
        const recursiveImpactedFields = this.checkObjectForLocalTriggers(object, impactedFields);
        log(`recursiveImpactedFields back: ${Object.keys(recursiveImpactedFields)}`);

        // Merge those in
        Object.keys(recursiveImpactedFields).forEach((recursiveFieldKey) => {
          const recursiveField = recursiveImpactedFields[recursiveFieldKey];

          // Check if the field exists
          if (impactedFields[recursiveField.key]) {
            // Merge in the reasons
            impactedFields[recursiveField.key].reasons = [
              ...impactedFields[recursiveField.key].reasons,
              ...recursiveField.reasons,
            ];
          } else {
            // Add the field
            impactedFields[recursiveField.key] = recursiveField;
          }
        });
      }

      return impactedFields;
    },
    getReasonsForLocalTriggers(localField, triggerFields) {
      const reasons = [];
      const triggeringFieldKeys = Object.keys(triggerFields);

      log(`checking ${localField.key} for local triggers; rules`, localField.rules);

      // Check if any conditional rules are triggered
      localField.getTriggersForConditionalRules(triggeringFieldKeys).forEach((trigger) => {
        reasons.push(
          `${triggerFields[trigger.key].name} is used in a conditional rule`,
        );
      });

      // Check if any validation rules are triggered
      localField.getTriggersForValidationRules(triggeringFieldKeys).forEach((trigger) => {
        reasons.push(
          `${triggerFields[trigger.key].name} is used in a validation rule`,
        );
      });

      // Check equations
      localField.getTriggersForEquation(triggeringFieldKeys).forEach((trigger) => {
        reasons.push(
          `uses <strong>${triggerFields[trigger.key].name}</strong> in an equation`,
        );
      });

      return reasons;
    },
    checkObjectForConnectedTriggers(object, triggerFields, connectionKey) {
      log(`checkObjectForConnectedTriggers, object: ${object.name};
        triggerFields: ${Object.keys(triggerFields)}`);
      const impactedFields = {};

      // Loop through fields that haven't yet

      // Check if any object fields are triggered by this field
      object.fields.forEach((localField) => {
        const reasons = this.getReasonsForConnectedTriggers(
          localField,
          triggerFields,
          connectionKey,
        );

        // Add to list if any reasons
        if (reasons.length) {
          // Add if this field has not already been triggered
          // Add to reasons
          if (!impactedFields[localField.key]) {
            impactedFields[localField.key] = this.addFieldImpact(
              localField.key,
              localField.name,
              localField.type,
              [],
            );
          }
          impactedFields[localField.key].reasons = [
            ...impactedFields[localField.key].reasons,
            ...reasons,
          ];
        }
      });

      return impactedFields;
    },
    getReasonsForConnectedTriggers(localField, triggerFields, connectionKey) {
      const reasons = [];
      const triggeringFieldKeys = Object.keys(triggerFields);

      log(`checking field ${localField.name}is formula: ${localField.isFormula()}`);

      // Check for a formula
      if (localField.isFormula()) {
        localField.getTriggersForFormula(triggeringFieldKeys).forEach((trigger) => {
          const fieldName = triggerFields[trigger.key].name;
          reasons.push(
            // Reason can be field or filter
            (trigger.type === 'field')
              ? `stores the ${localField.type} of <strong>${fieldName}</strong>`
              : `uses <strong>${fieldName}</strong> in a formula filter`,
          );
        });
      }

      // Check for connected equation
      // Equations store keys for connected fields as ${connectionfield.localfield}
      const connectedKeys = triggeringFieldKeys.map((fieldKey) => `${connectionKey}.${fieldKey}`);
      localField.getTriggersForEquation(connectedKeys).forEach((trigger) => {
        reasons.push(
          // Split the connection out
          `uses <strong>${triggerFields[trigger.key.split('.')[1]].name}</strong> in an equation`,
        );
      });

      return reasons;
    },
  },
};
</script>
