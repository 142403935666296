export function clearBuilderInactivityEvents() {
  const eventTypesToClear = [
    '-user-last_event',
    '-dashboard-last_event',
  ];

  for (let localStorageItemIndex = 0; localStorageItemIndex < localStorage.length; localStorageItemIndex++) {
    let matchesClearableEventType = false;
    const localStorageKeyAtCurrentIndex = localStorage.key(localStorageItemIndex);

    for (const eventTypeToClear of eventTypesToClear) {
      if (!localStorageKeyAtCurrentIndex.includes(eventTypeToClear)) {
        continue;
      }

      matchesClearableEventType = true;
    }

    if (!matchesClearableEventType) {
      continue;
    }

    localStorage.removeItem(localStorageKeyAtCurrentIndex);
  }
}
