import { mapGetters } from 'vuex';
import { flattenChildHierarchyRecursive } from '@/lib/page/page-helper';

export default {

  computed: {
    ...mapGetters([
      'getPageByKey',
      'userObjects',
    ]),
    ...mapGetters('page', [
      'startPages',
    ]),
  },
  methods: {

    /**
     * Returns a filtered list of every page the selectedView can be moved or copied to
     *
     * @param {View} selectedView
     * @returns {Page[]} An array of Page models
     */
    getAllowedTargets(selectedView) {
      // Cache all user roles for later
      const userRoleObjectKeys = {};

      this.userObjects.forEach((userObject) => {
        userRoleObjectKeys[userObject.profile_key] = userObject.key;
      });

      // Get all the objects the selected view can use for a source
      const potentialSourceObjects = selectedView.getAllPotentialConnectedSources();

      // What type of records the view works with
      const viewRecordType = selectedView.worksWithSpecificRecord() ? 'one' : 'many';

      // Get all the pages, in order
      const moveCopyTargets = flattenChildHierarchyRecursive(this.startPages);

      // Return the filtered pages as the allowed targets
      return moveCopyTargets.filter((target) => {
        if (!target.key) {
          return false;
        }

        /** @type {RawPage} targetPage */
        const targetPage = this.getPageByKey(target.key);
        if (!targetPage) {
          return false;
        }

        const isStartPage = (!targetPage.object);

        // Start pages can always show views working with many records
        if (isStartPage && viewRecordType === 'many') {
          return true;
        }

        // Otherwise get the objects this page knows about
        const pageObjects = [];

        // For record pages, add the page object
        if (targetPage.object) {
          pageObjects.push(targetPage.object);
        }

        // For auth pages, add use roles
        if (targetPage.authenticated && targetPage.isStartPage()) {
          const pageProfiles = [
            'all_users',
            ...targetPage.authentication_profiles,
          ];

          pageProfiles.forEach((profileKey) => {
            pageObjects.push(userRoleObjectKeys[profileKey]);
          });
        }

        // Include if any intersection between this page's objects and the view's potential sources
        return pageObjects.some((objectKey) => potentialSourceObjects[objectKey]);
      });
    },
  },
};
