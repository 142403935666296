<template>
  <div :class="`toolbox toolbox-theme-${theme} bg-muted`">
    <ToolboxTitle
      ref="toolboxTitle"
      :back-title="backTitle"
      :back-link="backLink"
      :back-count="backCount"
      :title="title"
      :theme="theme"
    />
    <ToolboxBody>
      <slot />
    </ToolboxBody>
  </div>
</template>

<script>
import ToolboxTitle from '@/components/layout/ToolboxTitle';
import ToolboxBody from '@/components/layout/ToolboxBody';

export default {
  components: {
    ToolboxTitle,
    ToolboxBody,
  },
  props: {
    backCount: {
      type: Number,
      default: 0,
    },
    backLink: {
      type: String,
      default: '',
    },
    backTitle: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'active',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.toolbox {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
}

.toolbox {
  .toggle-wrapper {
    margin-left: -1em;
    margin-right: -1em;
    margin-bottom: 0;
    max-width: unset;
  }

  .toggle-content {
    padding: 0 1em;

    > *:last-child {
      padding-bottom: 1.5rem;
    }
  }

  h3.toggle-trigger {
    border-bottom: 0;
    border-top: 1px solid $gray200;
    padding: .875rem 1rem;

    & > span {
      text-transform: uppercase;
    }

    .toggle-expand {
      margin: 0;
      height: 20px;
      width: 20px;

      svg {
        margin: 0;
        height: 20px;
        width: 20px;
      }
    }
  }
}

.toolbox-theme-active {

  h3.toggle-trigger {

    & > * {
      color: #247c9f;
    }
  }
}

.toolbox-theme-add {

  h3.toggle-trigger {

    & > * {
      color: $primary500;
    }
  }
}
</style>
