<template>
  <Loading
    v-if="isLoading"
    :is-loading="isLoading"
  />
  <div
    v-else
    class="integrations-list-container center pt-12"
  >
    <h1 class="center center-align text-left text-emphasis text-xl font-medium mb-1 mt-0">
      Import Data from an Integration
    </h1>
    <p class="text-default text-sm font-normal mb-6">
      Select an integration to start importing into Knack
    </p>
    <div class="integrations-list">
      <div>
        <IntegrationsListItem
          v-for="service in services"
          :key="service.id"
          :service-id="service.id"
          :service-name="service.name"
          :service-icon-url="service.icon_url"
          :is-connected="service.isConnected && appHasExistingIntegrationTable"
          :is-disabled="service.isConnected && appHasExistingIntegrationTable"
          :on-action-button-click="onActionButtonClick(service)"
          can-initiate-import
          @change="handleIntegrationConnectionChange"
          @close="refetchConnectedServices"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/renderer/shared/Loading';
import IntegrationsListItem from '@/components/settings/integrations/IntegrationsListItem.vue';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  components: {
    Loading,
    IntegrationsListItem,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      isLoading: true,
      services: [],
    };
  },
  computed: {
    availableServices() {
      return this.$store.getters['integrations/availableServices'];
    },
    connectedServices() {
      return this.$store.getters['integrations/connectedServices'];
    },
    isDataFetched() {
      return this.availableServices.length > 0;
    },
    appHasExistingIntegrationTable() {
      return this.$store.getters.objects.some((object) => object.get('integration') === 'QuickBooks');
    },
  },
  watch: {
    isDataFetched: {
      handler() {
        if (this.isDataFetched) {
          this.setServices();
        }
      },
      immediate: true,
    },
    connectedServices: {
      handler() {
        this.setServices();
      },
    },
  },
  methods: {
    setServices() {
      this.services = this.availableServices.map((service) => ({
        ...service,
        isConnected: this.connectedServices.some(
          (connectedService) => connectedService.service_id === service.id,
        ),
      }));
      this.isLoading = false;
    },
    startImport() {
      this.handleCloseVault();
      const isNewIntegrationImport = !this.appHasExistingIntegrationTable;
      this.commitRequest({
        request: () => (isNewIntegrationImport ? this.$store.dispatch('integrations/requestImport') : this.$store.dispatch('integrations/refetchData')),
        onSuccess: () => {
          this.$router.push('/schema/list');
          const notificationArgs = {
            type: 'success',
            title: 'Importing data from QuickBooks',
            text: 'You will receive a notification when your data is imported.',
            titleIcon: 'check',
          };
          if (!isNewIntegrationImport) {
            notificationArgs.title = 'Refreshing data from QuickBooks';
            notificationArgs.text = 'Your data is being refreshed. There may be a delay in seeing your latest data.';
          }
          this.renderNotification(notificationArgs);
          this.$store.dispatch('integrations/updateIsIntegrationImportInProgress', { isIntegrationImportInProgress: true });
        },
        onError: (error) => {
          const notificationArgs = {
            type: 'error',
            title: 'Error',
            text: error?.message ?? 'An error occurred while importing your data. Please try again.',
            titleIcon: 'error',
          };
          this.renderNotification(notificationArgs);
        },
      });
    },
    refreshIntegrationList() {
      this.isLoading = true;
      this.$store.dispatch('integrations/fetchConnectedServices');
    },
    handleIntegrationConnectionChange(connection) {
      if (connection?.state === 'callable') {
        this.startImport();
      }
    },
    async refetchConnectedServices() {
      await this.$store.dispatch('integrations/fetchConnectedServices');
    },
    handleCloseVault() {
      const vaultIframe = document.querySelector('iframe[src="https://vaultjs.apideck.com"]');
      vaultIframe?.remove();
      this.refreshIntegrationList();
    },
    onActionButtonClick(service) {
      return service.isConnected && !this.appHasExistingIntegrationTable ? this.startImport : undefined;
    },
    renderNotification({
      type,
      title,
      text,
      titleIcon,
    }) {
      this.$notify({
        group: 'top-right',
        duration: 8000,
        'animation-type': 'velocity',
        type,
        title,
        text,
        data: {
          titleIcon,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.integrations-list-container {
  width: 960px;
  margin: auto;
  margin-top: 1em;

  & > h1 {
    margin: 1.5em auto;
    @include font-h2;
  }
}

.integrations-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;

  > div {
    width: 468px;
  }
}

.integration.disabled {
  background-color: transparent;
  cursor: default;

  &:hover {
    border-color: #E7E4E6;
  }

  .integration-text {
    color: #898088;
  }
}
</style>
