<template>
  <Modal
    :title="isMenuPage ? 'Dropdown Menu Settings' : 'Page Settings'"
    :back="backLink"
  >
    <div class="body">
      <FormWrapper
        :errors="errors"
        :show-submit="true"
        submit-text="Save Settings"
        class="margin-bottom-double mb-0"
        @submit="onSave"
      >
        <template v-if="page">
          <div
            v-if="showMenuName"
            data-field-name="name"
            class="max-w-full"
          >
            <label class="text-default text-sm font-medium mb-2 leading-4">Name</label>
            <input
              v-model="localPage.name"
              type="text"
              data-cy="page-name"
            >
          </div>

          <div
            v-if="showPageUrl"
            class="max-w-full"
          >
            <label class="text-default text-sm font-medium mb-2 leading-4">Page URL</label>
            <input
              v-model="localPage.slug"
              type="text"
            >
          </div>

          <template v-if="showMenuOptions">
            <div>
              <label class="text-default text-sm font-medium mb-2 leading-4">Page Menu</label>
              <div class="input-labels tw-input-labels">
                <label
                  v-if="!isMenuPage"
                  style="margin-bottom: .75em;"
                  data-cy="page-menu-display-menu"
                >
                  <input
                    v-model="localPage.page_menu_display"
                    type="checkbox"
                  >Display the Page Menu when viewing this page
                </label>
                <label data-cy="page-menu-include-page">
                  <input
                    v-model="localPage.ignore_entry_scene_menu"
                    type="checkbox"
                    :true-value="false"
                    :false-value="true"
                  >Include this page in the Page Menu
                </label>
              </div>
            </div>
          </template>

          <div v-if="showIconOptions">
            <label class="text-default text-sm font-medium mb-2 leading-4">Icon</label>
            <p><IconPicker v-model:icon="localPage.icon" /></p>
          </div>

          <template v-if="!isMenuPage">
            <div>
              <label class="text-default text-sm font-medium mb-2 leading-4">Options</label>
              <div class="input-labels tw-input-labels">
                <label><input
                  v-model="localPage.print"
                  type="checkbox"
                >Show a link to print this page</label>
                <label><input
                  v-model="localPage.modal"
                  type="checkbox"
                >Display page in a modal popup</label>
                <span v-if="localPage.modal">
                  <label class="input-labels__sub-label"><input
                    v-model="localPage.modal_prevent_background_click_close"
                    type="checkbox"
                  >Keep modal open until action</label>
                </span>
              </div>
            </div>

            <div
              v-if="showRoleAccess"
              class="max-w-full"
            >
              <label class="text-default text-sm font-medium mb-2 leading-4">Permissions</label>
              <div class="input-labels tw-input-labels">
                <label><input
                  v-model="localPage.limit_profile_access"
                  type="radio"
                  :value="false"
                >Give permission to all users</label>
                <label><input
                  v-model="localPage.limit_profile_access"
                  type="radio"
                  :value="true"
                >Limit permissions to specific roles</label>
              </div>
              <div
                v-if="localPage.limit_profile_access"
                id="quickstart-permissions"
                data-cy="quickstart-permissions-picker"
                class="mt-2"
              >
                <MultipleChoice
                  v-model="localPage.allowed_profiles"
                  :input="{format: {type: `multi`, options: roleObjectOptions}}"
                />
              </div>
            </div>
          </template>
        </template>
      </FormWrapper>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import hasIn from 'lodash/hasIn';
import IconPicker from '@/components/ui/inputs/IconPicker';
import FormWrapper from '@/components/ui/FormWrapper';
import Modal from '@/components/ui/Modal';
import MultipleChoice from '@/components/renderer/form/inputs/MultipleChoice';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  name: 'PageSettings',
  components: {
    FormWrapper,
    IconPicker,
    Modal,
    MultipleChoice,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      localPage: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  computed: {
    ...mapGetters([
      'roleObjects',
    ]),
    backLink() {
      return this.$route.path.split('/settings')[0];
    },
    page() {
      return this.$store.getters.activePage;
    },
    pageObject() {
      if (this.page.object) {
        return this.page.getPageObject();
      }

      return false;
    },
    showMenuName() {
      return true;
    },
    showPageUrl() {
      return !this.isMenuPage;
    },
    showMenuOptions() {
      if (this.page.isTopLevelPage(true) && !this.page.isUserPage()) {
        return true;
      }

      return false;
    },
    showIconOptions() {
      if (this.page.isUserPage()) {
        return false;
      }

      return this.page.isTopLevelPage(true);
    },
    showRoleAccess() {
      return this.page.isUserPage() && this.roleObjects.length > 0;
    },
    isMenuPage() {
      return this.page.isMenuPage();
    },
    pageIcon: {

      get() {
        return this.localPage.icon;
      },

      set(icon) {
        this.localPage.icon = icon;
      },

    },
    roleObjectOptions() {
      return this.roleObjects.map((roleObject) => ({
        label: roleObject.name,
        value: roleObject.get('profile_key'),
      }));
    },
  },
  watch: {
    page: {
      handler(newVar) {
        if (newVar) {
          this.setLocalPage();
        }
      },
      deep: true,
    },
  },
  created() {
    this.setLocalPage();
  },
  methods: {
    setLocalPage() {
      if (this.page) {
        this.localPage = JSON.parse(JSON.stringify(this.page.raw));

        if (!this.localPage.icon) {
          const defaultIcon = {
            icon: '', align: 'left',
          };

          this.localPage.icon = defaultIcon;
        }

        if (!hasIn(this.localPage, 'modal_prevent_background_click_close')) {
          this.localPage.modal_prevent_background_click_close = false;
        }
      }
    },
    onSave() {
      this.commitRequest({
        validate: () => this.page.validate(this.localPage),
        request: () => this.$store.dispatch(
          'page/api/updateSettings',
          { pageKey: this.page.key, updates: this.localPage },
        ),
        onSuccess: ({ scene }) => {
          // this.setLocalPage()
          this.$router.push(`/pages/${this.$route.params.pageKey}`);
        },
      });
    },
    onCancel() {
      this.$router.push(`/pages/${this.$route.params.pageKey}`);
    },
  },
};
</script>

<style lang="scss">
.input-labels .input-labels__sub-label {
  margin-left: 30px;
}
</style>
