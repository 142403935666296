<script setup>
import { computed, defineEmits, defineProps } from 'vue';
import { useStore } from 'vuex';

import MultipleChoice from '@/components/renderer/form/inputs/MultipleChoice';

const store = useStore();

const emit = defineEmits([
  'update:modelValue',
]);

const props = defineProps({
  modelValue: {
    type: [
      Object,
      String,
      Array,
      Boolean,
    ],
    required: true,
  },
  input: {
    type: Object,
    default: () => ({}),
  },
  field: {
    type: Object,
    default: () => ({}),
  },
});

const input = computed(() => {
  const profileOptions = [];

  store.getters.roleObjects.forEach((roleObject) => {
    profileOptions.push({
      value: roleObject.get('profile_key'),
      label: roleObject.get('name'),
    });
  });

  return {
    ...props.input,
    type: 'multiple_choice',
    format: {
      type: 'multi',
      options: profileOptions,
    },
  };
});
</script>

<script>
export default {
  name: 'UserRoles',
};
</script>

<template>
  <MultipleChoice
    :model-value="props.modelValue"
    :input="input"
    :field="props.field"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>
