<template>
  <div
    class="hover-icon-wrapper"
    @mouseover="activeType = hoverType"
    @mouseout="activeType = type"
  >
    <Icon :type="activeType" />
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  name: 'HoverIcon',
  components: {
    Icon,
  },
  props: [
    'type',
    'hoverType',
  ],
  data() {
    return {
      activeType: this.type,
    };
  },
};
</script>

<style lang="scss">
.hover-icon-wrapper {
  width: 100%;
  height: 100%;

  svg {
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
}
</style>
