<template>
  <div class="control kn-inputWrapper has-icon">
    <i class="fa fa-calculator" />
    <p class="kn-equation input is-equation">
      {{ equationValue }}
    </p>
    <input
      name="key"
      type="hidden"
      :value="field.key"
    >
    <input
      :id="field.key"
      name="value"
      type="hidden"
      :value="equationValue"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EquationHelper } from '@knack/equation-helper';
import moment from 'moment-timezone';
import { formatNumber, stripNumber } from '@/util/FormatHelper';

export default {
  name: 'EquationInput',
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    formValues: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      type: [
        Number,
        String,
      ],
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      equationValue: '(Equation)',
    };
  },
  computed: {
    ...mapGetters([
      'appTimezoneFormatted',
      'getField',
    ]),
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  watch: {
    formValues: {
      handler(newValue) {
        this.equationValue = this.calculateEquation(this.input, newValue, this.input.format.equation_type);
      },
      deep: true,
    },
  },
  mounted() {
    // editing a record
    if (this.localValue) {
      this.equationValue = this.localValue;
    }
  },
  methods: {
    calculateEquation(equationField, vals, type) {
      let { equation } = equationField.format;

      if (!equationField.key) {
        // This is bad form, might need to remove this.
        equationField.key = equationField.id;
      }

      if (!equation) {
        equation = '';
      }

      let calc = '';
      let val;

      if (typeof equation === 'string') {
        calc = equation;

        for (const field of Object.keys(vals)) {
          const fieldModel = this.input;
          const fieldValue = vals[field];

          if (!fieldValue) {
            val = 0;
          } else if (Array.isArray(fieldValue)) {
            // Don't try to replace symbols in the equation.
            // Currently the only symbol is FIELD_DEFAULT_REPLACE_WITH_FIRST which will be
            // replaced using hasFieldKey or hasConnectionFieldKey below.
            val = fieldValue.filter((fieldVal) => typeof fieldVal !== 'symbol').join(',');
          } else if (fieldValue) {
            val = (fieldModel) ? stripNumber(fieldModel, fieldValue) : 0;
          }

          if (fieldModel && fieldModel.type === 'boolean') {
            const fieldFormat = fieldModel.format.format || fieldModel.format;

            switch (fieldFormat) {
              case 'true_false':
                val = (fieldValue === 'True') ? 1 : 0;
                break;

              case 'on_off':
                val = (fieldValue === 'On') ? 1 : 0;
                break;

              default:
                val = (fieldValue === 'Yes') ? 1 : 0;
                break;
            }
          } else {
            val = Number(String(val)
              .replace(/[^0-9.-]+/g, ''))
              .toFixed(4);
          }

          calc = calc.replace(
            new RegExp(
              `{${field.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}}`,
              'g',
            ),
            val,
          );
        }

        calc = calc.replace(/currentTime\(\)/g, new Date().valueOf());
        calc = calc.replace(/currentDate\(\)/g, new Date().setUTCHours(0, 0, 0, 0).valueOf());

        const hasConnectionFieldKey = new RegExp(/{field_\d+.field_\d+}/g);
        const hasFieldKey = new RegExp(/{field_\d+}/g);

        // replace unmatched fields:
        calc = calc.replace(hasConnectionFieldKey, 0);
        calc = calc.replace(hasFieldKey, 0);
      } else { // legacy equations
        for (const item of equation) {
          if (item.type === 'text') {
            if (item.text) {
              calc += item.text;
            }
          } else if (item.field && item.field.key) {
            const field = this.getField(item.field.key);

            if (field) {
              let val = vals[item.field.key] || 0;

              val = stripNumber(field, val);
                  val = Number(String(val).replace(/[^0-9\.\-]+/g, ``)).toFixed(4) // eslint-disable-line

              calc += val;
            } else {
              calc += 0;
            }
          }
        }
      }

      try {
        if (['equation', 'numeric'].includes(type)) {
          val = EquationHelper.evalNumericEquation(
            calc,
            this.appTimezoneFormatted,
            equationField.format.equation_type === 'date',
          );

          val = Number(val).toFixed(4);
        } else {
          calc = EquationHelper.evalStringEquation(calc, true, false, this.appTimezoneFormatted);

            val = eval(calc) // eslint-disable-line
        }
      } catch (err) {
        log(err);

        val = 0;
      }

      if (equationField.format.equation_type === 'date' && equationField.format.date_result === 'date') {
        let formattedNumber = Number(formatNumber(val, equationField.format.date_format) * 1000);

        if (equationField.format.date_type !== 'years') {
          formattedNumber = EquationHelper.convertDateVal(formattedNumber, equationField.format.date_type);
        } else {
          formattedNumber = formattedNumber * 365.25 * 24 * 60 * 60;
        }

        let dateFormat = '';

        if (equationField.format.date_format !== 'Ignore Date') {
          dateFormat += equationField.format.date_format.toUpperCase();
        }

        if (equationField.format.time_format !== 'Ignore Time') {
          dateFormat += ` ${(equationField.format.time_format === 'HH:MM am') ? 'h:m a' : 'H:m'}`;
        }

        formattedNumber = moment.tz(new Date(Number(formattedNumber)), this.appTimezoneFormatted);

        const offset = formattedNumber._offset;

        formattedNumber.subtract(offset, 'minutes');

        formattedNumber = formattedNumber.format(dateFormat);

        return formattedNumber;
      }

      return val;
    },
  },
};
</script>

<style lang="scss">
</style>
