<template>
  <div class="toolbox-wrapper">
    <Toolbox
      back-title="design"
      title="Menus"
    >
      <p class="mb-2">
        Customize
        <a
          href="https://learn.knack.com/article/5lbfjnp6p2-live-app-design#menus"
          class="text-default underline"
          target="_blank"
        >
          global design settings</a> for menu formats
      </p>
      <p class="mb-0">
        {{ menusTooltip }}
      </p>

      <Notification
        v-if="showThemeWarning"
        class="notification--wrapperWhite margin-bottom"
      >
        <ThemeWarning />
      </Notification>

      <!-- Buttons -->
      <Toggle
        class="margin-bottom"
        toggle-content-classes="px-4"
      >
        <template #title>
          <span class="text-base text-emphasis font-semibold">
            Buttons
          </span>
        </template>
        <template #content>
          <form
            style="position: relative;"
            class="p-0"
            :class="{'isDisabled': showThemeWarning}"
          >
            <p class="text-subtle text-sm font-normal mb-4">
              The following settings apply to menus that display links as buttons
            </p>
            <MenusOptions menu-format="buttons" />
          </form>
        </template>
      </Toggle>

      <hr class="border-0 border-t border-solid border-subtle">

      <!-- Tabs -->
      <Toggle
        class="margin-bottom"
        toggle-content-classes="px-4"
      >
        <template #title>
          <span class="text-base text-emphasis font-semibold">
            Tabs
          </span>
        </template>
        <template #content>
          <form
            style="position: relative;"
            :class="{'isDisabled': showThemeWarning}"
          >
            <p class="text-subtle text-sm font-normal mb-4">
              The following settings apply to menus that display links as tabs
            </p>
            <MenusOptions menu-format="tabs" />
          </form>
        </template>
      </Toggle>
    </Toolbox>
  </div>
</template>

<script>
import ApplicationDesignUtils from '@/components/util/ApplicationDesignUtils';
import MenusOptions from '@/components/settings/design/options/MenusOptions';
import Notification from '@/components/ui/notifications/Notification';
import ThemeWarning from '@/components/ui/ThemeWarning';
import Toggle from '@/components/ui/Toggle';
import Toolbox from '@/components/layout/Toolbox';

export default {
  name: 'MenuDesignSettings',
  components: {
    MenusOptions,
    Notification,
    ThemeWarning,
    Toolbox,
    Toggle,
  },
  mixins: [
    ApplicationDesignUtils,
  ],
  data() {
    return {
      menusTooltip: 'Menus are formatted as buttons by default. A tab format can be enabled for each individual view',
      buttonsTooltip: 'The default format is to display links as buttons. You can also configure a menu to display links as tabs',
      tabsTooltip: 'Individual menus can be configured to display links as tabs instead of buttons',
    };
  },
  computed: {
    showThemeWarning() {
      return this.app.getThemeLevel() < 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.helpIcon {
  vertical-align: -3px;
}
.isDisabled div * {
  opacity: .5;
  pointer-events: none;
}
</style>
