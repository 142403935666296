<template>
  <BodyWrapper>
    <RouterView name="modal" />
    <div
      id="settings-app"
      class="body-wrapper m-0 mb-10 px-6 py-2 max-w-xl"
    >
      <div v-if="ecommerceEnabled">
        <div class="margin-bottom-double mb-0">
          <p class="text-default text-base mb-4">
            E-Commerce is currently <strong>enabled.</strong>
          </p>
          <button
            class="button delete medium bg-white rounded-lg
              border border-destructive p-3 text-base text-destructive leading-4 font-medium"
            @click="toggleDisableModal(true)"
          >
            Disable E-Commerce
          </button>

          <EcommerceDisable
            v-if="showEcommerceDisable"
            @disable="toggleDisableModal(false)"
            @close="toggleDisableModal(false)"
          />
        </div>

        <hr class="my-8 border-0 border-t border-solid border-subtle">

        <p class="text-sm text-default font-medium mb-2">
          Processors
        </p>
        <div>
          <p
            v-if="!hasPaymentProcessors"
            class="text-subtle text-base font-normal mb-4"
          >
            You currently have no payment processors added
          </p>
          <div
            v-if="hasPaymentProcessors"
            class="base-list margin-bottom mb-4"
          >
            <div
              v-for="processor in paymentProcessors"
              :key="processor.key"
              class="processor-item [&:not(:last-child)]:mb-2 last:mb-0 cursor-auto h-12
                py-2 px-3 rounded-lg border border-solid border-default bg-white"
            >
              <div class="tools-container">
                <label class="truncate text-base text-emphasis font-semibold mb-0">{{ processor.name }}</label>
                <div class="tools">
                  <RouterLink
                    v-tippy
                    content="Edit processor settings"
                    :to="`/settings/ecommerce/${processor.key}/edit`"
                    class="button-square setting group bg-transparent min-w-auto h-auto w-auto"
                  >
                    <Icon
                      type="pencil"
                      class="h-4 w-4 text-default group-hover:text-default"
                    />
                  </RouterLink>
                  <RouterLink
                    v-tippy
                    content="Delete this processor"
                    :to="`/settings/ecommerce/${processor.key}/delete`"
                    class="button-square setting group bg-transparent min-w-auto h-auto w-auto"
                  >
                    <Icon
                      type="delete"
                      class="h-4 w-4 text-default group-hover:text-default"
                    />
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
          <RouterLink
            to="/settings/ecommerce/add"
            class="button green-gray small p-3 rounded-lg border border-solid border-default bg-white leading-none
              hover:bg-brand-50 hover:border-brand-600 group text-emphasis text-base font-medium"
          >
            <Icon
              type="plus-thin"
              class="fill-[#564D54] group-hover:fill-[#AC519D]"
            /> Add Processor
          </RouterLink>
        </div>
      </div>

      <div v-else>
        <p class="text-default text-base mb-4">
          E-commerce is currently <strong>disabled.</strong>
        </p>
        <button
          class="button save bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium"
          @click="toggleEnableModal(true)"
        >
          Enable E-Commerce
        </button>

        <EcommerceEnable
          v-if="showEcommerceEnable"
          @enable="toggleEnableModal(false)"
          @close="toggleEnableModal(false)"
        />
      </div>
    </div>
  </BodyWrapper>
</template>

<script>
import BodyWrapper from '@/components/layout/BodyWrapper';
import Icon from '@/components/ui/Icon';
import EcommerceEnable from '@/components/settings/ecommerce/EcommerceEnable';
import EcommerceDisable from '@/components/settings/ecommerce/EcommerceDisable';

export default {
  components: {
    BodyWrapper,
    Icon,
    EcommerceEnable,
    EcommerceDisable,
  },
  data() {
    return {
      showEcommerceEnable: false,
      showEcommerceDisable: false,
    };
  },
  computed: {
    app() {
      return this.$store.state.app;
    },
    ecommerceEnabled() {
      return this.app.attributes.ecommerce.enabled;
    },
    paymentProcessors() {
      return this.app.attributes.payment_processors;
    },
    hasPaymentProcessors() {
      return this.paymentProcessors.length;
    },
  },
  watch: {

    /**
     * This shouldn't be necessary but `enable/disable` events aren't being registered
     * in this component coming from async actions in the child ecommerce modals, this watch prevents
     * a cyclical modal behavior whereby if you both enable and disable without a refresh you
     * will be stuck in an endless loop of modals
     */
    ecommerceEnabled() {
      this.toggleEnableModal(false);
      this.toggleDisableModal(false);
    },
  },
  methods: {
    toggleEnableModal(state) {
      this.showEcommerceEnable = state;
    },
    toggleDisableModal(state) {
      this.showEcommerceDisable = state;
    },
  },
};
</script>
<style lang="scss">
.processor-item {
  align-items: center;
  display: flex;
  padding: .8125em 1em .8125em 1.5em;
  background-color: $gray50;
  border-radius: .375em;
  border: 1px solid $gray50;
  cursor: pointer;
  margin-bottom: .5em;

  &:hover {
    background-color: $gray50;
    border: 1px solid $gray100;
  }
}

#settings-app {
  .base-list {
    .tools-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        flex-grow: 1;
        color: #91267f;
        font-size: .875em;
        font-weight: 600;
      }

      .tools {
        display: flex;

        a {
          margin-left: .5em;
        }
      }
    }
    svg {
      height: 17px;
      width: 17px;
    }
  }
}
</style>
