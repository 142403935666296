<template>
  <nav
    id="objects-nav"
    class="main-panel"
  >
    <div
      ref="objectsNav"
      class="left-toolbox toolbox-body bg-muted"
    >
      <div class="title-wrapper p-4 m-0">
        <div class="title border-b-0 h-fit">
          <h3 class="text-emphasis">
            Tables
          </h3>

          <div v-if="showAddButton">
            <a
              v-tippy
              content="Add a new table"
              class="button-icon-square"
              :class="addButtonClasses"
              :href="builderNextBaseUrl + '/tables/add'"
              data-cy="add-object"
            >
              <Icon
                class="h-4 w-4 hover:fill-current"
                type="add"
              />
            </a>
          </div>
        </div>
      </div>

      <!-- Regular Objects -->
      <RecyclableSortableItems
        class="margin-bottom"
        :items="standardObjects"
        :item-size="sortableItemSize"
        @sort="onSortMainObjects"
      >
        <template #default="{item}">
          <RouterLink
            :id="`object-link-${item.id}`"
            v-slot="{ isActive, navigate }"
            :key="item.key"
            :to="urlRoot + item.key"
          >
            <li
              :id="`object-li-${item.id}`"
              class="nav-item"
              :class="{'router-link-active': isActive}"
              data-cy="nav-object-link"
              @click="navigate"
            >
              <ObjectNavLink
                v-if="item.get(`type`) === `StandardObject`"
                :obj="item"
                :link="urlRoot"
                :settings="showSettings"
                :is-active="isActive"
                :class="[
                  {
                    'hover:bg-brand-50': !isActive,
                    'bg-brand-100': isActive,
                    'bg-brand-50': isPopoverPresent(`object-link-${item.id}`) && !isActive,
                  },
                  objectNavLinkClasses
                ]"
              >
                <span :class="getObjectNavLinkIconActiveClasses(isActive)">
                  <Icon
                    class="w-4 h-4 shrink-0 mr-0"
                    :class="{ 'fill-[url(#svg-brand-gradient)]': isActive }"
                    :type="getIconType(item)"
                  />
                </span>
                <span
                  v-tippy
                  :content="getTooltipText(item.name)"
                  class="label transition truncate font-normal"
                  :class="getObjectNavLinkContentActiveClasses(isActive)"
                >
                  {{ item.name }}
                </span>
                <CountIndicator
                  v-if="showTaskCount && item.tasks.length"
                  v-tippy
                  content="The number of tasks this table has"
                  :count="item.tasks.length"
                  class="kn-botIndicator text-default"
                  :class="{
                    'bg-subtle': !isActive,
                    'bg-brand-50 text-emphasis': isActive
                  }"
                />
              </ObjectNavLink>
            </li>
          </RouterLink>
        </template>
      </RecyclableSortableItems>

      <hr class="block mx-4 mb-2 h-px bg-subtle border-none">

      <!-- User Account Objects -->
      <div class="title-wrapper p-4 m-0">
        <div class="title border-b-0 h-fit">
          <h3
            class="text-emphasis"
            :class="{disabled: !usersAreEnabled}"
          >
            User Roles
          </h3>
          <RouterLink
            v-if="showAddButton && usersAreEnabled"
            v-tippy
            content="Add a user role"
            :to="urlRoot + 'add?user=true'"
            class="button-icon-square add"
            :class="addButtonClasses"
          >
            <Icon
              class="h-4 w-4 hover:fill-current"
              type="add"
            />
          </RouterLink>
        </div>
      </div>

      <template v-if="!usersAreEnabled">
        <div
          class="px-4 mb-4 pt-0"
          style="padding: .5em 1em 1em; font-size: .875em;"
        >
          <RouterLink :to="urlRoot + 'enable-users'">
            <span
              style="font-style: italic;"
              class="inline-flex items-center not-italic p-3 rounded-lg border border-solid border-default bg-white
                leading-none text-emphasis text-base font-medium hover:bg-brand-50 hover:border-brand-600 h-10"
            >
              Enable Users
            </span>
          </RouterLink>
        </div>
      </template>

      <ul
        v-else
        class="margin-bottom"
      >
        <RouterLink
          v-if="accountObject"
          v-slot="{ navigate, isActive }"
          :to="urlRoot + accountObject.key"
        >
          <li
            :class="{'router-link-active': isActive}"
            class="nav-item"
            data-cy="nav-account-link"
            @click="navigate"
          >
            <ObjectNavLink
              :obj="accountObject"
              :link="urlRoot"
              :settings="showSettings"
              :parent="roleObjects.length > 0"
              :class="[
                {
                  'hover:bg-brand-50': !isActive,
                  'bg-brand-100': isActive,
                },
                objectNavLinkClasses
              ]"
            >
              <span :class="getObjectNavLinkIconActiveClasses(isActive)">
                <Icon
                  class="w-4 h-4 shrink-0 mr-0"
                  :class="{ 'fill-[url(#svg-brand-gradient)]': isActive }"
                  type="user"
                />
              </span>
              <span
                v-tippy
                :content="getTooltipText(accountObject.name)"
                class="transition truncate font-normal"
                :class="getObjectNavLinkContentActiveClasses(isActive)"
              >
                {{ accountObject.name }}
              </span>
              <CountIndicator
                v-if="showTaskCount && accountObject.tasks.length"
                v-tippy
                content="The number of tasks this table has"
                :count="accountObject.tasks.length"
                class="kn-botIndicator text-default"
                :class="{
                  'bg-subtle': !isActive,
                  'bg-brand-50 text-emphasis': isActive
                }"
              />
            </ObjectNavLink>

            <RecyclableSortableItems
              id="user-role-nav"
              :items="roleObjects"
              :item-size="sortableItemSize"
              @sort="onSortUserObjects"
            >
              <template #default="{item}">
                <RouterLink
                  v-slot="{ isActive, navigate }"
                  :key="item.key"
                  :to="urlRoot + item.key"
                  custom
                >
                  <div
                    :id="`role-object-nav-${item.id}`"
                    :class="{'router-link-active': isActive}"
                    class="nav-item nav-item-child"
                    @click="navigate"
                  >
                    <ObjectNavLink
                      feature-id="role_more_button"
                      :obj="item"
                      :link="urlRoot"
                      :settings="showSettings"
                      :child="true"
                      :class="[
                        {
                          'hover:bg-brand-50': !isActive,
                          'bg-brand-100': isActive,
                          'bg-brand-50': isPopoverPresent(`role-object-nav-${item.id}`) && !isActive,
                        },
                        objectNavLinkClasses
                      ]"
                    >
                      <span :class="getObjectNavLinkIconActiveClasses(isActive)">
                        <Icon
                          class="w-4 h-4 ml-4 shrink-0 mr-0"
                          :class="{ 'fill-[url(#svg-brand-gradient)]': isActive }"
                          type="user"
                        />
                      </span>
                      <span
                        v-tippy
                        :content="getTooltipText(item.name)"
                        class="transition truncate font-normal"
                        :class="getObjectNavLinkContentActiveClasses(isActive)"
                      >
                        {{ item.name }}
                      </span>
                      <CountIndicator
                        v-if="showTaskCount && item.tasks.length"
                        v-tippy
                        content="The number of tasks this table has"
                        :count="item.tasks.length"
                        class="kn-botIndicator text-default"
                        :class="{
                          'bg-subtle': !isActive,
                          'bg-brand-50 text-emphasis': isActive
                        }"
                      />
                    </ObjectNavLink>
                  </div>
                </RouterLink>
              </template>
            </RecyclableSortableItems>
          </li>
        </RouterLink>
      </ul>

      <hr class="block mx-4 mb-2 h-px bg-subtle border-none">

      <!-- Payment Objects -->
      <div class="title-wrapper p-4 m-0">
        <div class="title border-b-0 h-fit">
          <h3
            class="text-emphasis"
            :class="{ disabled: !paymentsAreEnabled }"
          >
            Customer Payments
          </h3>
        </div>
      </div>

      <template v-if="!paymentsAreEnabled">
        <div
          class="p-0"
          style="padding: .5em 1em 1em; margin-bottom: 2em; font-size: .875em; font-style: italic;"
        >
          <a
            class="text-emphasis text-base inline-flex font-medium h-10 p-3 rounded-lg border border-solid border-default bg-white not-italic ml-4 hover:bg-brand-50 hover:border-brand-600 items-center leading-4"
            @click="showEcommerceEnable = true"
          >
            Enable E-Commerce
          </a>
        </div>

        <EcommerceEnable
          v-if="showEcommerceEnable"
          @enable="onEcommerceEnabled"
          @close="showEcommerceEnable = false"
        />
      </template>

      <RecyclableSortableItems
        v-else
        class="margin-bottom pb-4"
        :items="ecommerceObjects"
        :item-size="ecommerceSortableItemSize"
        @sort="onSortPaymentObjects"
      >
        <template #default="{item}">
          <RouterLink
            v-slot="{ isActive, navigate }"
            :key="item.key"
            :to="urlRoot + item.key"
            custom
          >
            <div
              :id="`ecom-object-nav-${item.id}`"
              :class="{'router-link-active': isActive}"
              class="nav-item"
              @click="navigate"
            >
              <ObjectNavLink
                :obj="item"
                :link="urlRoot"
                :settings="showSettings"
                :class="[
                  {
                    'hover:bg-brand-50': !isActive,
                    'bg-brand-100': isActive,
                    'bg-brand-50': isPopoverPresent(`ecom-object-nav-${item.id}`) && !isActive,
                  },
                  objectNavLinkClasses
                ]"
              >
                <span :class="getObjectNavLinkIconActiveClasses(isActive)">
                  <Icon
                    class="w-4 h-4 shrink-0 mr-0"
                    :class="{ 'fill-[url(#svg-brand-gradient)]': isActive }"
                    type="credit-card"
                  />
                </span>
                <span
                  v-tippy
                  :content="getTooltipText(item.name)"
                  class="transition truncate font-normal"
                  :class="getObjectNavLinkContentActiveClasses(isActive)"
                >
                  {{ item.name }}
                </span>
                <CountIndicator
                  v-if="showTaskCount && item.tasks.length"
                  v-tippy
                  content="The number of tasks this table has"
                  :count="item.tasks.length"
                  class="kn-botIndicator text-default"
                  :class="{
                    'bg-subtle': !isActive,
                    'bg-brand-50 text-emphasis': isActive
                  }"
                />
              </ObjectNavLink>
            </div>
          </RouterLink>
        </template>
      </RecyclableSortableItems>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import ObjectNavLink from '@/components/objects/ObjectNavLink';
import Icon from '@/components/ui/Icon';
import UIUtil from '@/util/UI';
import RecyclableSortableItems from '@/components/ui/lists/RecyclableSortableItems';
import RequestUtils from '@/components/util/RequestUtils';
import EcommerceEnable from '@/components/settings/ecommerce/EcommerceEnable';
import CountIndicator from '@/components/ui/CountIndicator';
import { getBuilderNextBaseUrl } from '@/lib/url-helper';

export default {
  components: {
    Icon,
    ObjectNavLink,
    RecyclableSortableItems,
    EcommerceEnable,
    CountIndicator,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    showSettings: {
      type: Boolean,
      default: true,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    showTaskCount: {
      type: Boolean,
      default: false,
    },
    navContext: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sortableItemSize: 40,
      ecommerceSortableItemSize: 40,
      showEcommerceEnable: false,
    };
  },
  computed: {
    ...mapGetters([
      'activeObject',
      'objects',
      'standardObjects',
      'accountObject',
      'roleObjects',
      'ecommerceObjects',
      'hasFeature',
    ]),
    ...mapGetters('notifications', [
      'activeSocketNotification',
    ]),
    usersAreEnabled() {
      return this.$store.state.app.usersAreEnabled();
    },
    paymentsAreEnabled() {
      return get(this.app, 'attributes.ecommerce.enabled', false) || Boolean(this.objects.find((obj) => obj.ecommerce));
    },
    urlRoot() {
      const prefix = this.navContext === 'fields' ? 'schema/list' : this.navContext;

      return `/${prefix}/objects/`;
    },
    addButtonClasses() {
      return 'flex px-1 py-3 justify-center items-center rounded text-white bg-gradient-primary';
    },
    objectNavLinkClasses() {
      return 'rounded-lg py-2 mx-4 h-auto group';
    },
    builderNextBaseUrl() {
      return getBuilderNextBaseUrl();
    }
  },
  watch: {

    /**
       * This shouldn't be necessary but `enable` events aren't being registered
       * in this component coming from async actions in the child ecommerce modal, this watch
       * waits for next tick then attempts to show the newly added ecommerce object
       */
    paymentsAreEnabled(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$nextTick(() => {
          this.onEcommerceEnabled();
        });
      }
    },
  },
  mounted() {
    this.scrollToActiveObject();
  },
  methods: {
    async scrollToActiveObject(attemptNumber = 1) {
      // Only attempt to scroll if there is an active object.
      if (!this.activeObject) {
        return;
      }

      // Let other processing happen first, this isn't as important.
      await this.$nextTick();

      // Scroll to to active link.
      const $active = this.$el.querySelector('.router-link-active');

      if (!$active) {
        // Limit the number of attempts to 5.
        if (attemptNumber >= 5) {
          return;
        }

        // If the active link is not found, try again after a slight delay.
        setTimeout(() => {
          this.scrollToActiveObject(attemptNumber + 1);
        }, 50);

        return;
      }

      // Check if it's visible in the upper section of the navigation panel.
      const rect = $active.getBoundingClientRect();

      const isVisible = (rect.top >= 0) && (rect.bottom <= window.innerHeight - 200);

      if (isVisible) {
        return;
      }

      // Active page is not visible, so let's move it into view.
      const scrollable = new UIUtil.ScrollTo(this.$refs.objectsNav);

      const scrollDestination = this.$refs.objectsNav.scrollTop + rect.top - (window.innerHeight / 2 - 25);

      scrollable.go(scrollDestination);
    },

    // TODO:: to consider: moving these as individual methods on this.app, letting the model worry about these orders
    onSortMainObjects(event, sortedMainObjects) {
      this.commitSortObjects(
        sortedMainObjects.map((obj) => obj.key)
          .concat(this.roleObjects.map((obj) => obj.key))
          .concat(this.ecommerceObjects.map((obj) => obj.key)),
      );
    },

    onSortUserObjects(event, sortedUserObjects) {
      this.commitSortObjects(
        this.standardObjects.map((obj) => obj.key)
          .concat(sortedUserObjects.map((obj) => obj.key))
          .concat(this.ecommerceObjects.map((obj) => obj.key)),
      );
    },

    onSortPaymentObjects(event, sortedPaymentObjects) {
      this.commitSortObjects(
        this.standardObjects.map((obj) => obj.key)
          .concat(this.roleObjects.map((obj) => obj.key))
          .concat(sortedPaymentObjects.map((obj) => obj.key)),
      );
    },

    commitSortObjects(sortedObjects) {
      this.commitRequest({
        request: () => this.app.reorderObjects(sortedObjects),
        globalLoading: false,
        onSuccess: (response) => {
          this.$store.commit('updateObjectOrder', response.order);
        },
      });
    },

    onEcommerceEnabled() {
      this.showEcommerceEnable = false;
      const ecommObject = this.objects.find((obj) => obj.ecommerce);

      if (!isNil(ecommObject)) {
        this.$router.push(`/schema/list/objects/${ecommObject.key}`);
      }
    },

    getTooltipText(objectName) {
      return `View ${objectName} ${this.navContext}`;
    },
    isIntegrationImportInProgress(object) {
      return !_.isEmpty(this.activeSocketNotification) && this.activeSocketNotification === 'progress' && object.get('integration');
    },
    getIconType(object) {
      if (!object.get('integration')) return 'table-cells';
      if (this.isIntegrationImportInProgress(object)) return 'loading-spinner';
      return object.get('integration')?.toLowerCase();
    },
    isPopoverPresent(parentId) {
      const parentElement = document.getElementById(parentId);
      if (!parentElement) {
        return false;
      }

      return Array.from(parentElement.children).some((child) => child.classList.contains('popover-toggled'));
    },
    getObjectNavLinkIconActiveClasses(isActive) {
      return {
        'text-default group-hover:text-brand-400': !isActive,
        'text-brand': isActive,
      };
    },
    getObjectNavLinkContentActiveClasses(isActive) {
      return {
        'text-default group-hover:text-emphasis': !isActive,
        'text-emphasis': isActive,
      };
    },
  },
};
</script>

<style lang="scss">
#objects-nav {
  height: 100%;
  overflow: auto;

  .toolbox-body {
    padding: 0;
  }

  .title-wrapper {
    background-color: inherit;
  }

  h3.disabled {
    color: $gray400;

    svg {
      color: $gray400;
    }
  }

  .nav-item {
    position: relative;

    > ul {
      overflow: hidden;
    }

    > a {
      height: 32px;
      padding-left: 0.75rem;
      color: $gray800;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0.5rem;

      .name {
        padding-right: 0.5rem;
      }

      .name svg {
        height: 16px;
        width: 16px;
        color: $gray400;
        margin-right: 0.5rem;
      }

      /*
        & > a:hover .name *, &.router-link-active > a .name *, a .settings:hover > a {
          color: $secondary500;
        }
        */

      &.router-link-active > a .name {
        font-weight: 500;

        .kn-botIndicator {
          background-color: $secondary50;
        }
      }
    }
  }

  #user-role-nav > li > a {
    padding-left: 28px;
  }

  #user-role-nav
    > .vue-recycle-scroller__item-wrapper
    > .vue-recycle-scroller__item-view
    > li
    > a {
    padding-left: 29px;
    display: flex;
    justify-content: space-between;
  }

  .tree-branch {
    border-left: 1px solid $gray300;
    border-bottom: 1px solid $gray300;
    width: 8px;
    position: absolute;
    top: -16px;
    height: 32px;
    margin-left: -10px;
  }

  .tree-branch-parent {
    border-left: 1px solid $gray300;
    position: absolute;
    top: 32px;
    height: 12px;
    margin-left:4px;
  }
}
</style>

<style lang="scss">
#objects-nav .nav-item-child > a {
  // Indent the child nav-items.
  padding-left: 29px;
}
</style>
