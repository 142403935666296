<template>
  <view-toolbox
    :back-link="`/pages/${page.key}/views/${view.key}/calendar`"
    back-title="Edit Calendar"
    title="Build Event Details"
  >
    <p class="mb-2 text-sm font-normal leading-4">
      Users can click on a calendar event to show more details.
    </p>

    <Toggle
      v-if="hasActiveView"
      id="column-properties-toggle"
      :is-open="true"
    >
      <template #title>
        <span>Details Title & Description</span>
      </template>

      <template #content>
        <div class="inputGroup border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
          <div class="mb-4">
            <label
              for="title"
              class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label"
            >
              Details Title
            </label>
            <input
              v-model="viewTitle"
              type="text"
              name="title"
            >
          </div>
          <div class="mb-0">
            <label
              for="title"
              class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label"
            >
              Description
            </label>
            <textarea
              v-model="viewRaw.details.description"
              name="description"
            />
          </div>
        </div>
      </template>
    </Toggle>

    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Details Layout</span>
      </template>
      <template #content>
        <div class="inputGroup border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
          <div class="mb-4">
            <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Label Format</label>
            <LabelFormat
              :model-value="viewRaw"
              @update-property:model-value="onUpdateViewProperty"
            />
          </div>
          <div clas="mb-0">
            <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Layout</label>
            <DetailsLayout />
          </div>
        </div>
      </template>
    </Toggle>

    <Toggle
      id="details-properties-toggle"
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Add Details</span>
      </template>

      <template #content>
        <div>
          <AddItems
            :view="view"
            :object="object"
          >
            <p class="text-sm leading-5">
              Drag or click options below to add event details.
            </p>
          </AddItems>
        </div>
      </template>
    </Toggle>
  </view-toolbox>
</template>

<script>
import AddItems from '@/components/views/AddItems';
import DetailsLayout from '@/components/views/shared/DetailsLayout';
import LabelFormat from '@/components/views/shared/settings/LabelFormat';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'CalendarEventDetailsBuild',
  components: {
    AddItems,
    DetailsLayout,
    LabelFormat,
    Toggle,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    viewTitle: {
      get() {
        return this.viewRaw?.details?.title || 'Event Details';
      },
      set(title) {
        this.viewRaw.details.title = title;
      },
    },
  },
};
</script>

<style lang="scss">
</style>
