<template>
  <div class="toolbox-wrapper">
    <Toolbox
      back-title="design"
      title="Grids"
    >
      <p>Customize the design defaults for grids</p>
      <Notification
        v-if="showThemeWarning"
        class="notification--wrapperWhite margin-bottom"
      >
        <ThemeWarning />
      </Notification>
      <form
        style="position: relative;"
        :class="{'isDisabled': showThemeWarning}"
      >
        <div
          v-if="showThemeWarning"
          v-tippy
          content="Only available on newer themes"
          class="click-interceptor"
        />
        <TablesOptions />
      </form>
    </Toolbox>
  </div>
</template>

<script>
import Toolbox from '@/components/layout/Toolbox';
import ApplicationDesignUtils from '@/components/util/ApplicationDesignUtils';
import Notification from '@/components/ui/notifications/Notification';
import TablesOptions from '@/components/settings/design/options/TablesOptions';
import ThemeWarning from '@/components/ui/ThemeWarning';

export default {
  name: 'TableDesignSettings',
  components: {
    Notification,
    ThemeWarning,
    TablesOptions,
    Toolbox,
  },
  mixins: [
    ApplicationDesignUtils,
  ],
  data() {
    return {
      previewTableHover: false,
    };
  },
  computed: {
    showThemeWarning() {
      return this.app.getThemeLevel() < 2;
    },
  },
  // methods: {
  //   /**
  //    * Toggle the hover state of the second row in the preview table
  //    *
  //    * TODO: Implementation passed on for now, left for possible future re-implementation
  //    */
  //   togglePreviewTableHover () {

  //     this.previewTableHover = !this.previewTableHover

  //     // Find the second row of the sample table in the preview area and give it the hover style
  //     const row = document.getElementsByClassName(`kn-table-element`)[0].querySelector(`tbody tr:nth-of-type(2)`)

  //     if (this.previewTableHover) {

  //       row.classList.add(`is-hover`)
  //     } else {

  //       row.classList.remove(`is-hover`)
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>

.isDisabled div * {
  opacity: .5;
  pointer-events: none;
}

.tableSettings_divider {
  border-top: 1px solid #cdcdcd;
  margin-bottom: 1em;
}

</style>
