<template>
  <header
    class="header"
    :class="{ 'is-active': searchActive }"
  >
    <button
      type="button"
      class="header_close"
      @click="$emit(`close`)"
    >
      <Icon
        type="close"
        class="header_icon"
      />
    </button>
    <h1 class="header_title">
      {{ title }}
    </h1>
    <div class="header_subhead">
      <HelpBreadcrumbs
        v-if="showBreadcrumbs"
        class="header_description"
        :breadcrumbs="breadcrumbs"
        @selectPreviousPage="(index) => $emit(`selectPreviousPage`, index)"
      />
      <p
        v-else
        class="header_description"
      >
        {{ caption }}
      </p>
      <HelpSearch v-model:expanded="searchActive" />
    </div>
  </header>
</template>

<script>
import Icon from '@/components/ui/Icon';
import HelpSearch from '@/components/builder/help/HelpSearch';
import HelpBreadcrumbs from '@/components/builder/help/HelpBreadcrumbs';

export default {
  components: {
    Icon,
    HelpBreadcrumbs,
    HelpSearch,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Learn & Build',
    },
    caption: {
      type: String,
      default: '',
    },
  },
  emits: [
    'close',
    'selectPreviousPage',
  ],
  data() {
    return {
      searchActive: false,
    };
  },
  computed: {
    showBreadcrumbs() {
      return this.breadcrumbs.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;

  padding: $spacing-sm $spacing-md;

  border-bottom: 1px solid #D9DADB;

  transition: border-bottom-color .2s ease-out;

  &.is-active {
    border-bottom-color: $fuchsia;
  }

  &_title {
    margin-bottom: $spacing-xs;
    margin-top: 0;

    font-size: calculateRem(20px);
    line-height: 1.2;
    font-weight: bold;
  }

  &_close {
    align-items: center;
    appearance: none;
    display: flex;
    justify-content: center;
    position: absolute;
    right: calculateEm(15px, 20px);
    top: calculateEm(15px, 16px);

    height: 28px;
    padding: 0;
    width: 28px;

    border: none;
    border-radius: 3px;
    background: transparent;

    transition: background-color .3s ease-out;

    &:hover,
    &:focus {
      background-color: rgba(142, 146, 147, 0.25);

      .header_icon {
        color: #202426;
      }
    }
  }

  &_icon {
    height: 20px;
    width: 20px;
  }

  &_subhead {
    position: relative;
  }

  &_description {
    margin-bottom: 0;

    font-size: calculateRem(14px);
    line-height: 1.43;
  }
}
</style>
