<template>
  <div>
    <div
      class="kn-colorInput rounded-lg items-stretch max-w-[116px]"
      :data-feature="dataFeature"
      data-feature-x-offset="-24"
      data-feature-y-offset="-24"
    >
      <button
        ref="colorPicker"
        class="kn-colorInput_preview bg-transparent py-2 pl-2 border border-r-transparent border-default rounded-l-lg
          text-base text-emphasis hover:border-emphasis hover:border-r-emphasis focus:hover:border-emphasis
          focus:border-default focus:outline-none focus:outline-default placeholder:text-subtle"
        :class="{ 'is-expanded': isPickerOpen }"
      >
        <div
          class="kn-colorPicker"
          :style="colorPreview"
        />
      </button>
      <ColorPickerTextInput
        v-model="color"
        class="kn-colorInput_input"
        @error="markError"
      />
    </div>
    <p
      v-if="error"
      class="error-msg"
    >
      {{ error.message }}
    </p>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';
import $ from 'jquery';

import 'spectrum-colorpicker';

import NamedColors from '@/util/NamedColors';
import ColorPickerTextInput from '@/components/ui/inputs/ColorPickerTextInput';

export default {
  components: {
    ColorPickerTextInput,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: () => null,
    },
    showTextValue: {
      type: Boolean,
      default: false,
    },
    dataFeature: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    const savedColor = isNil(this.modelValue) && !isNil(this.defaultValue)
      ? this.defaultValue
      : this.modelValue;

    return {
      savedColor,
      isPickerOpen: false,
      error: false,
    };
  },
  computed: {
    colorPreview() {
      return {
        backgroundColor: this.color,
      };
    },
    color: {

      get() {
        if (!isNil(this.modelValue)) {
          return this.modelValue;
        }

        return this.defaultValue;
      },

      set(value) {
        let color = value;

        // if the color is a named color, force lowercase
        if (NamedColors.includes(value.toLowerCase())) {
          color = value.toLowerCase();
        }

        this.syncColor(color);
      },
    },
  },
  mounted() {
    $(this.$refs.colorPicker).spectrum({
      clickoutFiresChange: false,
      color: this.color,
      change: (color) => {
        const colorHex = color.toHexString();

        this.savedColor = colorHex;
        this.syncColor(colorHex);
      },
      move: (color) => {
        this.syncColor(color.toHexString());
      },
      show: () => {
        this.savedColor = this.modelValue;
        this.isPickerOpen = true;

        this.$store.commit(
          'checklists/setHighlightedFeatures',
          this.$store.getters['checklists/highlightedFeatures'].filter((f) => f !== this.dataFeature),
        );
      },
      hide: () => {
        if (this.savedColor !== this.modelValue) {
          this.syncColor(this.savedColor);
        }

        this.isPickerOpen = false;
      },
    });
  },
  beforeUnmount() {
    $(this.$refs.colorPicker).spectrum('destroy');
  },
  methods: {
    syncColor(color) {
      $(this.$refs.colorPicker).spectrum('set', color);

      this.$emit('update:modelValue', color);
    },
    markError(message) {
      if (isNil(message)) {
        this.error = false;

        return;
      }

      this.error = {
        message,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.kn-colorInput {
  display:flex;
  align-items:center;
  background-color: #fff;
  border-radius: .3em;
  max-width: 108px;
}

.kn-colorInput_preview {
  border: 1px solid #d6d6de;
  border-right-color: transparent;
  border-radius: .1875rem 0 0 .1875rem;
  color: $gray200;
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: calc(.25rem + 8px);
  padding: .3125rem;
  position: relative;
  vertical-align: middle;

  /* &::after {
    border-color: #5a5a65 transparent transparent;
    border-style: solid;
    border-width: 6px 3px 0;
    clear: both;
    content: '';
    height: 0;
    position: absolute;
    right: 10%;
    top: 42%;
    width: 0;
  } */

  &.is-expanded {
    border-color: $primary500;
  }
}

.kn-colorInput_input {
  background-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-color: transparent;
  margin-left: -2px;
  margin-right: 0;
  min-width: 0;
  padding-left: .25rem;
  z-index: 2;
}

.kn-colorPicker {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid $gray200;
}
</style>
