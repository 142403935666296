import findIndex from 'lodash/findIndex';

/**
 * Stores the raw view data for each page from the app.
 */
class ViewCache {
  /**
   * A map of all the views for each page.
   * pageKey => [ view, view, view ]
   *
   * @type {Object<string, object[]>}
   */
  viewsByPageKey = {};

  /**
   * A complete map of every view.
   * viewKey => view
   *
   * @type {Object<string, object>}
   */
  viewsByKey = {};

  /**
   * Gets all views for a page as an array.
   *
   * @param {string} pageKey
   * @returns {object[]}
   */
  getViewsByPage(pageKey) {
    return this.viewsByPageKey[pageKey] || [];
  }

  /**
   * Gets a view with the given view key.
   *
   * @param {string} viewKey
   * @returns {object | undefined}
   */
  getViewByKey(viewKey) {
    return this.viewsByKey[viewKey];
  }

  /**
   * Adds a single view to the cache.
   *
   * @param {object} viewData
   * @param {string} pageKey
   */
  addView(viewData, pageKey) {
    const viewKey = viewData.key;

    if (!this.viewsByPageKey[pageKey]) {
      this.viewsByPageKey[pageKey] = [];
    }

    this.viewsByPageKey[pageKey].push(viewData);
    this.viewsByKey[viewKey] = viewData;
  }

  /**
   * Sets a view in the cache or adds it if it doesn't exist.
   *
   * @param {object} viewData
   * @param {string} pageKey
   */
  setView(viewData, pageKey) {
    const viewKey = viewData.key;

    if (!this.viewsByPageKey[pageKey]) {
      this.viewsByPageKey[pageKey] = [];
    }

    const views = this.viewsByPageKey[pageKey];
    const existingViewIndex = findIndex(views, (rawView) => rawView.key === viewKey);

    if (existingViewIndex === -1) {
      views.push(viewData);
    } else {
      views[existingViewIndex] = viewData;
    }

    this.viewsByKey[viewKey] = viewData;
  }

  /**
   * Removes a page from the cache.
   *
   * @param {string} viewKey
   * @param {string} pageKey
   */
  removeView(viewKey, pageKey) {
    if (!this.getViewByKey(viewKey)) {
      return;
    }

    this.viewsByPageKey[pageKey] = this.viewsByPageKey[pageKey].filter(
      (currentView) => currentView.key !== viewKey,
    );

    delete this.viewsByKey[viewKey];
  }

  /**
   * Sets multiple views from a single page to the cache.
   *
   * @param {object[]} newViews
   * @param {string} pageKey
   */
  setViewsForPage(newViews, pageKey) {
    this.viewsByPageKey[pageKey] = [];

    // Loads all the view data into View objects and caches them.
    newViews.forEach((newViewData) => {
      this.addView(newViewData, pageKey);
    });
  }

  /**
   * Removes all the views for the given page.
   *
   * @param {string} pageKey
   */
  clearViewsForPage(pageKey) {
    if (!this.viewsByPageKey[pageKey]) {
      return;
    }

    this.viewsByPageKey[pageKey].forEach((view) => {
      delete this.viewsByKey[view.key];
    });

    delete this.viewsByPageKey[pageKey];
  }

  /**
   * Removes all data from the view cache.
   */
  clearAllViews() {
    this.viewsByPageKey = {};
    this.viewsByKey = {};
  }
}

const singletonViewCache = new ViewCache();

export default singletonViewCache;
