<script setup>
import {
  computed,
  defineProps,
  onUnmounted,
  watch,
  watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import {
  addStylesToHead,
  designSettingsAliases,
  getClassesFromDesignSettings,
  getOverrideStylesFromDesignSettings,
  getPropertyFromDesignSettings,
  removeStylesFromHead,
} from '@knack/style-engine';
import FontAwesome from '@/components/ui/FontAwesome';

const store = useStore();
const componentId = uuid();

// Props
const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  itemSchema: {
    type: Object,
    default: null,
  },
  icon: {
    type: Object,
    default: null,
  },
  linkType: {
    type: String,
    default: 'page',
  },
});

// Computed
const appDesign = computed(() => store.getters.app.get('design'));

const hasCustomDesignSettings = computed(() => props.itemSchema?.link_design_active && props.itemSchema?.link_design);

const customDesignSettings = computed(() => {
  // Use the custom link design settings (for overrides) if available
  if (hasCustomDesignSettings.value) {
    return props.itemSchema?.link_design;
  }
  return undefined;
});

const iconStyles = computed(() => (
  // Do not allow icon color to override the view links color.
  { ...props.icon.style, color: '' }
));

const linkTypeAlias = computed(() => {
  const viewLinkTypes = {
    page: designSettingsAliases.VIEW_LINKS,
    action: designSettingsAliases.VIEW_LINKS_ACTION,
    delete: designSettingsAliases.VIEW_LINKS_DELETE,
  };
  return viewLinkTypes[props.linkType];
});

const localIcon = computed(() => {
  // First check the prop
  if (props.icon?.icon) {
    return props.icon;
  }

  // Then the item schema
  if (props.itemSchema?.icon?.icon) {
    return props.itemSchema.icon;
  }

  // Finally, the design settings
  const designSettingsIcon = getPropertyFromDesignSettings(
    linkTypeAlias.value,
    appDesign.value,
    'icon',
  );
  if (designSettingsIcon) {
    return designSettingsIcon;
  }

  // Otherwise, there's no icon
  return null;
});

const classes = computed(() => getClassesFromDesignSettings(
  linkTypeAlias.value,
  appDesign.value,
  customDesignSettings.value,
));

// Methods
const appendOverrideStyles = () => {
  if (!hasCustomDesignSettings.value) {
    return;
  }

  const css = getOverrideStylesFromDesignSettings(
    componentId,
    linkTypeAlias.value,
    appDesign.value,
    customDesignSettings.value,
  );

  if (css) {
    addStylesToHead(css, componentId);
  }
};

const removeOverrideStyles = () => {
  removeStylesFromHead(componentId);
};

// Watchers
watchEffect(() => {
  if (hasCustomDesignSettings.value) {
    appendOverrideStyles();
  } else {
    removeOverrideStyles();
  }
});

watch(() => props.linkType, () => {
  appendOverrideStyles();
});

// Lifecycle methods
onUnmounted(() => {
  removeOverrideStyles();
});
</script>

<template>
  <a
    class="knViewLink"
    :class="classes"
    :data-kn-id="componentId"
  >
    <!-- Left icon -->
    <span
      v-if="localIcon?.icon && localIcon?.align === 'left'"
      class="knViewLink__icon knViewLink__icon--isLeft icon is-left"
    >
      <FontAwesome
        :name="localIcon.icon"
        :style="iconStyles"
      />
    </span>

    <!-- Text/label -->
    <span
      v-if="text"
      class="knViewLink__label"
      v-html="text"
    />
    <span
      v-else
      class="knViewLink__label"
    >
      <slot />
    </span>

    <!-- Right icon -->
    <span
      v-if="localIcon?.icon && localIcon?.align === 'right'"
      class="knViewLink__icon knViewLink__icon--isRight icon is-right"
    >
      <FontAwesome
        :name="localIcon.icon"
        :style="iconStyles"
      />
    </span>
  </a>
</template>
