import FormHelpShareFeedback from '@/store/forms/help/FormHelpShareFeedback';
import getApiBaseModule from '@/store/modules/api/api-base';

export const SHARE_FEEDBACK_RESET_FORM = '📢 Share Feedback - Resetting Form';
export const SHARE_FEEDBACK_UPDATE_FORM = '📢 Share Feedback - Updating Form';
export const SHARE_FEEDBACK_VALIDATION = '📢 Share Feedback - After Validation';
export const SHARE_FEEDBACK_READY = '📢🌱 Share Feedback - Ready';
export const SHARE_FEEDBACK_PENDING = '📢⏳ Share Feedback - Pending';
export const SHARE_FEEDBACK_SUCCESS = '📢✅ Share Feedback - Success';
export const SHARE_FEEDBACK_FAILURE = '📢❌ Share Feedback - Failure';

const events = {
  RESET_FORM: SHARE_FEEDBACK_RESET_FORM,
  UPDATE_FORM: SHARE_FEEDBACK_UPDATE_FORM,
  VALIDATION: SHARE_FEEDBACK_VALIDATION,
  READY: SHARE_FEEDBACK_READY,
  PENDING: SHARE_FEEDBACK_PENDING,
  SUCCESS: SHARE_FEEDBACK_SUCCESS,
  FAILURE: SHARE_FEEDBACK_FAILURE,
};

/**
 * Gets the form.
 *
 * @returns {FormHelpShareFeedback}
 */
const getForm = () => new FormHelpShareFeedback();

const apiBase = getApiBaseModule(events, getForm);

export default {
  // Full namespace: api/help/shareFeedback
  shareFeedback: {
    namespaced: true,
    state: apiBase.state,
    getters: apiBase.getters,
    mutations: apiBase.mutations,
    actions: apiBase.actions,
  },
};
