<template>
  <div
    class="import-map-field"
    :data-cy="field.name"
  >
    <ImportMapEdit
      v-if="showEdit"
      :field="field"
      :records="records"
      @close="showEdit = false"
      @submit="onSubmitEditFormat"
    />

    <div class="map-row level-equal min-h-[51px]">
      <label class="field text-emphasis text-base font-normal leading-4">
        <input
          v-model="field.mapped"
          type="checkbox"
        >
        <TypeIcon
          :field="field"
          class="text-subtle"
        />
        {{ field.name }}
      </label>
      <div class="column level-right">
        <select
          v-if="field.mapped && !field.complex"
          v-model="field.mappedColumnIndex"
          class="h-10 text-base font-normal leading-5"
        >
          <option
            v-for="column in getAvailableColumnsToMap(field)"
            :value="column.value"
          >
            {{ column.label }}
          </option>
        </select>
      </div>
    </div>

    <template v-if="field.mapped && field.complex">
      <div
        v-for="part in fieldParts"
        :key="part.key"
        class="map-sub-row level-equal"
        :data-cy="part.label"
      >
        <label class="field text-emphasis text-base font-normal leading-4">
          <input
            v-model="part.mapped"
            type="checkbox"
          > {{ part.label }}
        </label>
        <div class="column">
          <select
            v-if="part.mapped"
            v-model="part.mappedColumnIndex"
            class="h-10 text-base font-normal leading-5"
          >
            <option
              v-for="column in getAvailableColumnsToMap(part)"
              :value="column.value"
            >
              {{ column.label }}
            </option>
          </select>
        </div>
      </div>
    </template>

    <template v-if="isMappedConnection">
      <div class="map-sub-row map-connection border-l-0 -ml-0.5">
        <div class="top level-equal">
          <label class="text-emphasis text-base font-normal leading-4">Which <b>{{ connectedObjectName }}</b> field matches the <b>{{ getColumnName(field.mappedColumnIndex) }}</b> column?</label>
          <select
            v-model="field.connectionMatchField"
            class="h-10 text-base py-2 pl-3 leading-5"
          >
            <option
              v-for="connectedObjectField in importMatchableConnectedObjectFields"
              :key="connectedObjectField.key"
              :value="connectedObjectField.key"
            >
              {{ connectedObjectField.name }}
            </option>
          </select>
        </div>
        <div class="level-equal">
          <label class="text-emphasis text-base font-normal leading-4">What if no match is found?</label>
          <select
            v-model="field.connectionNoMatchRule"
            class="h-10 text-base py-2 pl-3 leading-5"
          >
            <option value="skip">
              Skip this {{ getColumnName(field.mappedColumnIndex) }} value
            </option>
            <option value="insert">
              Insert a new {{ connectedObjectName }} with this {{ getColumnName(field.mappedColumnIndex) }} value
            </option>
          </select>
        </div>
      </div>
    </template>

    <template v-if="isDateType">
      <div class="map-sub-row map-connection border-l-0 -ml-0.5">
        <div class="font-bold">
          Imported date/time format
        </div>
        <div class="top level-equal">
          <div>
            <div>Select the date format</div>
            <div
              v-for="format in getDateFormatOptions()"
              :key="format.key"
            >
              <label>
                <input
                  v-model="selectedDateFormat"
                  type="radio"
                  :value="format.key"
                  :checked="matchPredictedDateFormat(format.key)"
                >
                <span class="text-emphasis text-base font-normal">{{ format.label }}</span>
              </label>
            </div>
          </div>
        </div>

        <div class="top level-equal">
          <div>
            <div>Select the time format</div>
            <div
              v-for="format in getTimeFormatOptions()"
              :key="format.key"
            >
              <label>
                <input
                  v-model="selectedTimeFormat"
                  type="radio"
                  :value="format.key"
                  :checked="matchPredictedTimeFormat(format.key)"
                >
                <span class="text-emphasis text-base font-normal">{{ format.label }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import ImportMapEdit from '@/components/records/import/ImportMapEdit';
import Icon from '@/components/ui/Icon';
import TypeIcon from '@/components/fields/TypeIcon';

export default {
  components: {
    ImportMapEdit,
    Icon,
    TypeIcon,
  },
  props: {
    field: {
      type: Object,
      default: () => {},
    },
    fieldTypes: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    availableColumns: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    mappedColumnIndexes: {
      type: Array,
      default: () => [],
    },
    records: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showEdit: false,
      connectedObject: null,
      selectedDateFormat: null,
      selectedTimeFormat: null,
    };
  },
  computed: {
    isMappedConnection() {
      return (this.field.mapped && this.field.type === 'connection' && this.field.mappedColumnIndex !== false);
    },
    connectedObjectName() {
      if (!this.connectedObject) {
        return '';
      }

      return this.connectedObject.inflections.singular;
    },
    importMatchableConnectedObjectFields() {
      if (!this.connectedObject) {
        return [];
      }

      return this.connectedObject.fields
        .filter((field) => field.isImportMatchable());
    },
    fieldParts() {
      return this.field.parts || [];
    },
    showFormatEdit() {
      log(`showFormatEdit(), type is: ${this.field.type}`);

      const formatTypes = [
        window.Knack.config.DATE_TIME,
        window.Knack.config.CURRENCY,
        window.Knack.config.NUMBER,
      ];

      return formatTypes.includes(this.field.type);
    },
    isDateType() {
      return (this.field.type === 'date_time');
    },
  },
  watch: {
    'field.mapped': function (isMapped, isMappedOld) {
      if (isMapped === false) {
        return;
      }

      this.setMapProperties();
    },
    selectedDateFormat(format) {
      this.field.format = {
        ...this.field.format,
        date_format: format,
      };
    },
    selectedTimeFormat(format) {
      this.field.format = {
        ...this.field.format,
        time_format: format,
      };
    },
  },
  created() {
    if (this.field.mapped) {
      this.setMapProperties();
    }
  },
  methods: {
    setMapProperties() {
      this.setFieldPrediction(this.field);

      // for connections, set connection map field to the connections display field
      if (this.field.type === 'connection') {
        const field = this.$store.getters.activeObject.getField(this.field.key);

        this.connectedObject = this.$store.getters.getObject(field.get('relationship').object);

        this.field.connectionMatchField = this.connectedObject.identifier;
        this.field.connectionNoMatchRule = 'skip';
      } else {
        this.connectedObject = null;
      }

      // make sure all mappings are still available
      if (this.getMappedColumnCount(this.field.mappedColumnIndex) > 1) {
        this.field.mappedColumnIndex = false;
      }

      if (this.field.complex) {
        return this.field.parts.forEach((part) => {
          // make sure this part's mapped column is still available
          if (this.getMappedColumnCount(part.mappedColumnIndex) > 1) {
            part.mapped = false;
            part.mappedColumnIndex = false;
          }
        });
      }
    },

    getMappedColumnCount(columnIndex) {
      let count = 0;

      // all the fields
      this.mappedColumnIndexes.forEach((index) => {
        if (index === columnIndex) {
          count++;
        }
      });

      return count;
    },

    setFieldPrediction(field, mapIfFound) {
      log('ImportMapField.methods.setFieldPrediction()');
      log('field:');
      log(field);
      log('mapIfFound:');
      log(mapIfFound);

      // if complex, return if any parts map
      if (field.complex) {
        return field.parts.forEach((part) => {
          this.setFieldPrediction(part, mapIfFound);

          if (part.mapped) {
            field.mapped = true;
          }
        });
      }

      // if no index
      if (field.mappedColumnIndex === false) {
        log(`checking if any columns match field with name ${field.name}`);

        const columnMatchingFieldName = this.availableColumns.find((column) => {
          log(`column name ${column.name}`);

          return field.name === column.name;
        });

        log('columnMatchingFieldName');
        log(columnMatchingFieldName);

        if (columnMatchingFieldName) {
          field.mappedColumnIndex = columnMatchingFieldName.index;

          return;
        }

        // check for prediction
        if (field.predictedIndex) {
          field.mappedColumnIndex = field.predictedIndex;
        }
      }
    },
    getAvailableColumnsToMap(field) {
      const options = [];

      // nothing is mapped yet, so add a default
      if (field.mappedColumnIndex === false) {
        options.push({
          value: false, label: 'Select a column',
        });
      }

      // add the available columns
      this.availableColumns.forEach((col, availableIndex) => {
        options.push({
          value: col.index,
          label: `${col.index + 1} ${col.name}`,
        });
      });

      // add the current column
      if (field.mappedColumnIndex !== false) {
        options.push({
          value: field.mappedColumnIndex,
          label: `${field.mappedColumnIndex + 1} ${this.getColumnName(field.mappedColumnIndex)}`,
        });
      }

      // sort by value, which is the index
      sortBy(options, [
        'value',
      ]);

      return options;
    },
    getColumnName(columnIndex) {
      return this.columns[columnIndex].name;
    },

    onSubmitEditFormat(format) {
      log('onSubmitEditFormat', format);

      this.showEdit = false;
    },

    getDateFormatOptions() {
      const fieldType = this.fieldTypes.find(
        (fieldType) => fieldType.key === 'date_time',
      );
      return fieldType ? fieldType.formats.date : [];
    },

    getTimeFormatOptions() {
      const fieldType = this.fieldTypes.find(
        (fieldType) => fieldType.key === 'date_time',
      );
      return fieldType ? fieldType.formats.time : [];
    },

    matchPredictedDateFormat(format) {
      if (!this.field.format || this.field.format.date_format === 'M D, yyyy') {
        return format === 'mm/dd/yyyy';
      }
      return this.field.format.date_format === format;
    },

    matchPredictedTimeFormat(format) {
      if (!this.field.format) {
        return format === 'Ignore Time';
      }
      return this.field.format.time_format === format;
    },
  },
};
</script>

<style lang="scss">
.edit-map {
  display: flex;
  svg {
    height: 16px;
    width: 16px;
    margin: 0;
    margin-left: 5px;
  }
}

.import-map-field {
  .field {
    display: flex;
    align-items: center;
  }
}

#map-existing div.map-connection {
    border-left: 1px solid #dfe2e8;
    margin-top: 0;
    padding-top: .75em;
    margin-left: 5px;
    margin-bottom: 1em;
    padding-left: 1em;

  > div {
    margin-bottom: 1em;
  }

  label {
    line-height: 1.35em;
    margin-top: .15em;
    padding-right: .25em;
    margin-right: .35em;
  }
}
</style>
