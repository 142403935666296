import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { NEW_PAGE_KEY } from '@/lib/page/page-constants';
import store from '@/store';

/**
 * The base page class.
 * This holds common logic for the Page classes.
 */
export class BasePage {
  get getPagePath() {
    return `/pages/${this.key}`;
  }

  get getPageRoute() {
    const query = store.getters['routes/getQuery']('pages');

    return `${this.getPagePath}?${query}`;
  }

  /**
   * Gets whether or not this page is the global login page.
   *
   * @returns {boolean}
   */
  isGlobalLoginPage() {
    return (this.key === store.getters['page/globalLoginPage']?.key);
  }

  /**
   * Gets whether or not this page is a top level page.
   *
   * @param {boolean} [excludeMenuParent]
   * @returns {boolean}
   */
  isTopLevelPage(excludeMenuParent = false) {
    if (store.getters.isGlobalLogin) {
      return this.isTopLevelPageGlobalLogin(excludeMenuParent);
    }

    return !this.getParentPage(excludeMenuParent);
  }

  /**
   * Gets whether or not this page is a top level parent for an app with global login page enabled.
   *
   * @param {boolean} [excludeMenuParent]
   * @returns {boolean}
   */
  isTopLevelPageGlobalLogin(excludeMenuParent = false) {
    if (excludeMenuParent && this.isMenuPage()) {
      // With exclude menu parent on, menu pages can never be top level pages (their children can be though).
      return false;
    }

    const parentPage = this.getParentPage();

    // User pages work differently than other pages for global login pages since they aren't
    // children of the global login page.
    if (this.isUserPage()) {
      return !parentPage;
    }

    if (!parentPage) {
      // This would be the top level login.
      return false;
    }

    const grandParent = parentPage.getParentPage();

    if (!excludeMenuParent || !grandParent) {
      // If a children of the login page have no grandparent.
      return !grandParent;
    }

    return (parentPage.isMenuPage() && !grandParent.getParentPage());
  }

  /**
   * Gets whether or not this page is a "start" page.
   * A start page is a top level page that isn't a login or any page protected by top-level login.
   *
   * @returns {boolean}
   */
  isStartPage() {
    const firstStartPage = this.getStartPageHierarchy()[0];

    // This page is a start page if it is the highest start page in its start page ladder.
    return (firstStartPage?.key === this.key);
  }

  /**
   * Gets whether or not this page is a top level page that is protected by a top-level login.
   *
   * @returns {boolean}
   */
  isStartPageWithLogin() {
    // include all child pages of login pages that don't have a parent

    const parentPage = this.getParentPage(true);

    if (!parentPage || !parentPage.isLoginPage()) {
      // The parent page must be a login page.
      return false;
    }

    const grandParent = parentPage.getParentPage(true);

    if (grandParent?.isGlobalLoginPage()) {
      // The grandparent being the global login page is equivalent to there being no grandparent.
      return true;
    }

    return !grandParent;
  }

  isNewPage() {
    // The value of `new` is deprecated.
    return this.key === NEW_PAGE_KEY || this.key === 'new';
  }

  isLoginPage() {
    return this.type === 'authentication';
  }

  isUserPage() {
    return this.type === 'user';
  }

  isMenuPage() {
    return this.type === 'menu';
  }

  isAuthenticated() {
    return this.authenticated;
  }

  canHavePageRules() {
    if (this.isLoginPage() || this.isMenuPage()) {
      return false;
    }

    // A page can have rules if a user is logged in or it knows about an object
    return this.isAuthenticated() || this.object;
  }

  hasChildPages() {
    return !isEmpty(this.children) || !isEmpty(this.menuChildren);
  }

  /**
   * Gets the menu children for the page.
   *
   * @returns {BasePage[]}
   */
  getMenuChildren() {
    return this.menuChildren || [];
  }

  /**
   * Determines whether or not this page is a menu page and it has any menu children
   * that are authenticated.
   *
   * @returns {boolean}
   */
  hasAuthenticatedMenuChildren() {
    if (!this.isMenuPage()) {
      return false;
    }

    return this.getMenuChildren().some((childPage) => childPage.isAuthenticated());
  }

  /**
   * Determines whether or not this page is a menu page and it has any menu children
   * that are non-authenticated pages.
   *
   * @returns {boolean}
   */
  hasNonAuthenticatedMenuChildren() {
    if (!this.isMenuPage()) {
      return false;
    }

    return this.getMenuChildren().some((childPage) => !childPage.isAuthenticated());
  }

  /**
   * Gets the list of authentication profiles allowed for this page.
   * This is the naming we're trying to use: userRoles [not profiles], authorized for which can access
   *
   * Jon Note: I know pageHelper.parsePageAuthentication() does some menu child logic around
   * authentication_profiles, but it isn't recursive, does this duplicate that?
   * Can we just check `this.authentication_profiles` here?
   *
   * @returns {[]}
   */
  get authorizedUserRoles() {
    const menuChildren = this.getMenuChildren();

    if (isEmpty(menuChildren)) {
      return this.authentication_profiles;
    }

    return menuChildren.reduce((final, menuChild) => {
      if (!isEmpty(menuChild.authentication_profiles)) {
        return final.concat(menuChild.authorizedUserRoles);
      }

      return final;
    }, []);
  }

  /**
   * Determines if the given role has access to the page.
   *
   * Jon Note: I know pageHelper.parsePageAuthentication() does some menu child logic around
   * authentication_profiles, does this duplicate that? Can we just check `this.authentication_profiles` here?
   *
   * @param {string} profileKey
   * @returns {boolean}
   */
  roleHasAccess(profileKey) {
    const menuChildren = this.getMenuChildren();

    if (isEmpty(menuChildren)) {
      return includes(this.authentication_profiles, profileKey);
    }

    return menuChildren.some((menuChildPage) => includes(menuChildPage.authentication_profiles, profileKey));
  }

  /**
   * Gets the hierarchy of ancestors (and possibly descendents) of this page that could be
   * start pages (though only the top level one is the actual start page).
   *
   * @returns {RawPage[]}
   */
  getStartPageHierarchy() {
    const startablePageFilter = (page) =>

      // Return true for pages that could be start pages.
      (!page.isLoginPage() && !page.isMenuPage() && !page.object);

    return this.getPageHierarchy({
      pageFilter: startablePageFilter,
    });
  }

  /**
   * Gets the hierarchy of ancestors (and possibly descendents) of this page.
   *
   * @param {object} [options]
   * @param {function} [options.pageFilter] - Filters out pages.
   * @returns {RawPage[]}
   */
  getPageHierarchy(options) {
    const { pageFilter } = options || {};

    const path = [];

    let page = this;

    while (page) {
      if (!pageFilter || pageFilter(page)) {
        path.unshift(page);
      }

      page = page.getParentPage();
    }

    if (!isEmpty(path)) {
      return path;
    }

    const firstChildPage = this.getFirstChild();

    if (firstChildPage) {
      return firstChildPage.getPageHierarchy(options);
    }

    if (this.isLoginPage()) {
      log(`No start pages found for ${this.key} login page. This means there is a problem with the schema.`);
    }

    return [];
  }

  /**
   * Gets page slug(s) for the link url for this page.
   *
   * @returns {string}
   */
  getSlugPathForLiveAppUrl() {
    return this.getStartPageHierarchy().map((page) => page.slug).join('/');
  }

  /**
   * Gets the top start page associated with this page.
   * This may return the same page (the RawPage version) or a parent, grand*parent, or
   * child of this page.
   *
   * @returns {RawPage | undefined}
   */
  getStartPage() {
    return this.getStartPageHierarchy()[0];
  }
}

export default BasePage;
