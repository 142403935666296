import Joi from '@hapi/joi';

/**
 * The base form object.
 */
export default class FormBase {
  /**
   * Gets an initialized validation object.
   *
   * @returns {{passed: boolean, errors: Array, errorsByKey: Object<string, string>}}
   */
  static initValidation() {
    return {
      passed: true,
      errors: [],
      errorsByKey: {},
    };
  }

  /**
   * Empties all the keys in this form.
   */
  clearForm() {
    for (const key of Object.keys(this)) {
      this[key] = '';
    }
  }

  /**
   * Validates the form.
   *
   * @param {Object} [validationSchema]
   * @param {Object} [validationOptions]
   * @returns {{passed: boolean, errors: Array, errorsByKey: Object<string, string>}}
   * @private
   */
  _validateForm(validationSchema, validationOptions = {}) {
    if (!validationSchema) {
      // If no validation is needed, then no validationSchema can be sent and the validation will auto pass.
      return FormBase.initValidation();
    }

    const fullValidationOptions = {
      abortEarly: false,
      allowUnknown: true,
      ...validationOptions,
    };

    const validationResult = Joi.validate(this, validationSchema, fullValidationOptions);

    if (!validationResult.error) {
      return FormBase.initValidation();
    }

    const errors = validationResult.error.details.map((validationError) => ({
      field: validationError.context.key,
      message: validationError.message.replace(/^"([^"]+)"/, '$1'),
    }));

    const errorsByKey = errors.reduce((final, validationError) => {
      const key = validationError.field;

      final[key] = validationError.message;

      return final;
    }, {});

    return {
      passed: false,
      errors,
      errorsByKey,
    };
  }
}
