import VTooltip from 'v-tooltip';
import VueShortkey from 'vue-shortkey';
import Notifications from '@kyvg/vue3-notification';
import VueTippy from 'vue-tippy';
import VueClipboard from 'vue3-clipboard';

import 'v-tooltip/dist/v-tooltip.css';

import router from '@/router/index';
import store from '@/store/index';

function initAppPlugins(app) {
  app.use(store);
  app.use(router);
  app.use(Notifications);

  // Define router append function.
  // Taken from https://next.router.vuejs.org/guide/migration/#router-view-keep-alive-and-transition
  const routeAppend = (path, pathToAppend) => path + (path.endsWith('/') ? '' : '/') + pathToAppend;
  app.config.globalProperties.routeAppend = routeAppend;
  app.config.compilerOptions.whitespace = 'preserve';

  // Keyboard shortcuts via v-shortkey directive
  app.use(VueShortkey, {
    prevent: [
      '.redactor-editor',
    ],
  });

  app.use(VTooltip, {
    defaultDelay: {
      show: 350,
      hide: 0,
    },
    animation: 'fade',
    boundary: document.body,
  });

  app.use(VueTippy, {
    defaultProps: {
      delay: [
        400,
        0,
      ],
      duration: [
        400,
        100,
      ],
      inertia: true,
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              enabled: false,
            },
          },
          {
            name: 'hide',
            options: {
              enabled: false,
            },
          },
        ],
      },
      // Custom theme defined in tooltips.scss.
      theme: 'kn-dark',
    },
  });

  // Custom Directives
  app.directive('focus', {

    // When the bound element is inserted into the DOM...
    mounted($el) {
      // Focus the element
      $el.focus();
    },
  });

  /**
   * v-intersect
   *
   * Attaches an intersection observer to a Vue Component that updates a flag
   * when an intersection is detected.
   *
   * EXAMPLE USAGE:
   *
   *    <div v-intersect="{ bind: 'isIntersecting' }">...</div>
   *
   * NOTE: be sure the bound variable exists as a target component data property:
   *
   *    data () {
   *      return {
   *        isIntersecting: false
   *      }
   *    }
   */
  app.directive('intersect', {
    mounted: ($el, binding) => {
      const handleIntersect = (entries) => {
        if (!entries.length || !binding.value) {
          return;
        }

        const visibility = entries[0].intersectionRatio;

        // NOTE: not using the intersection observer's "isIntersecting" properly because
        // an intersection ratio of 1.0 means that the element is fully visible in viewport and
        // is still technically an "intersection", but we are not targeting this case.
        binding.instance[binding.value.bind] = visibility > 0 && visibility < 1;
      };

      // handler is called when element is 0% and 100% visible
      const intersectionObserver = new IntersectionObserver(handleIntersect, {
        threshold: [
          0.0,
          1.0,
        ],
      });

      intersectionObserver.observe($el);
    },
  });

  app.use(VueClipboard, {
    autoSetContainer: true,
  });
}

export default initAppPlugins;
