<template>
  <div
    :id="shouldShowEmptyState ? undefined : 'kn-records-table'"
  >
    <RouterView
      name="modal"
      :base-url="`/records/objects/${object?.key}`"
    />

    <RecordsEmpty
      v-if="shouldShowEmptyState"
      :object="object"
    />

    <div
      v-else
      class="rendererPane records-table"
    >
      <div
        v-if="!objectIsLoaded"
        id="object-records-loading"
      >
        Loading Records...
      </div>

      <TableWrapper
        v-else
        class="border border-solid border-default border-x-0 border-b-0 pb-6 bg-gray-50"
      >
        <template #navigation>
          <RecordsTableNavigation
            :records-keyword-search="queryVars.search"
            :object-key="$route.params.objectKey"
            :current-records-page="queryVars.currentPage"
            :records-per-page="queryVars.recordsPerPage"
            :records-filters="queryVars.filters"
            :total-records="totalRecords"
            :total-records-pages="totalPages"
            :current-records-count="currentRecordsCount"
            :allow-limit="true"
            @update="onUpdateQueryVars"
          />
        </template>
        <template #table>
          <VTable
            class="mb-6"
            :allow-sorting="true"
            :columns="columns"
            :draggable="false"
            :records="activeRecords"
            :render-table-totals="false"
            :show-checkboxes="true"
            :checked-record-ids="checkedRecordIds"
            :show-builder-links="true"
            :sort="queryVars.sort"
            :force-truncate="true"
            :show-inline-edit="true"
            @click-field-action="onClickFieldAction"
            @toggle-checkboxes="onToggleCheckboxes"
            @sort="(sort) => onUpdateQueryVars({ sort: sort })"
          />
        </template>
      </TableWrapper>
    </div>
    <SocketNotification
      v-if="shouldShowNotification"
      :object="object"
      :notification="notificationToShow"
      @clear-fetched-notification="handleClearFetchedNotification"
    />
  </div>
</template>

<script>
import {
  mapMutations,
  mapGetters,
} from 'vuex';
import TableWrapper from '@/components/renderer/table/TableWrapper';
import VTable from '@/components/renderer/table/Table';
import RecordsTableNavigation from '@/components/records/RecordsTableNavigation';
import RecordsEmpty from '@/components/records/RecordsEmpty';
import QueryMixin from '@/components/renderer/mixins/QueryMixin';
import { eventBus } from '@/store/bus';
import RequestUtils from '@/components/util/RequestUtils';
import SocketNotification from '@/components/ui/notifications/SocketNotification';

export default {
  components: {
    SocketNotification,
    TableWrapper,
    VTable,
    RecordsTableNavigation,
    RecordsEmpty,
  },
  mixins: [
    QueryMixin,
    RequestUtils,
  ],
  data() {
    return {
      queryKey: 'builder_table',
      deletedRecordIds: [],
      fetchedNotification: null,
    };
  },
  computed: {
    ...mapGetters('notifications', [
      'socketNotifications',
      'activeSocketNotification',
      'batchDeleteCompleted',
      'batchDeleteCanceled',
      'batchTaskCompleted',
      'batchUpdateCompleted',
      'batchUpdateCanceled',
      'importCompleted',
      'importCanceled',
      'importFailed',
    ]),
    ...mapGetters([
      'checkedRecordIds',
    ]),
    objectIsLoaded() {
      // set in QueryMixin
      return this.initRecords;
    },
    object() {
      return this.$store.getters.getObject(this.$route.params.objectKey);
    },
    columns() {
      if (!this.object) {
        return [];
      }

      return this.object.getFieldColumns();
    },
    hasActiveBatchJob() {
      if (!this.object) {
        return false;
      }

      if (this.object.status !== 'current') {
        return true;
      }

      if (this.object.key === this.activeSocketNotification?.object_key) {
        return true;
      }

      return false;
    },
    hasQueuedBatchJob() {
      return this.activeSocketNotification?.category === 'queued';
    },
    activeRecords() {
      return this.records.filter(({ id }) => !this.deletedRecordIds.includes(id));
    },
    shouldShowEmptyState() {
      const hasRecords = this.totalRecords > 0;
      const isFiltering = this.queryVars?.filters?.rules?.length > 0;
      const isSearching = this.queryVars?.search;

      return this.objectIsLoaded && !isSearching && !isFiltering && !hasRecords;
    },
    queryParams() {
      if (this.$route.fullPath.includes('?')) {
        return `?${this.$route.fullPath.split('?')[1]}`;
      }

      return '';
    },
    notificationToShow() {
      if (this.activeSocketNotification) {
        return this.activeSocketNotification;
      }
      return this.fetchedNotification;
    },
    shouldShowNotification() {
      return !!this.notificationToShow;
    },

  },
  watch: {
    '$route.params.objectKey': function () {
      // reset query vars
      this.resetQueryVars();
      this.setCheckedRecordIds([]);

      this.queryRecords();
    },
    batchDeleteCompleted(newValue) {
      if (newValue === true) {
        this.queryRecords();
      }
    },
    batchDeleteCanceled(newValue) {
      if (newValue === true) {
        this.queryRecords();
      }
    },
    batchTaskCompleted(newValue) {
      if (newValue === true) {
        this.queryRecords();
      }
    },
    batchUpdateCompleted(newValue) {
      if (newValue === true) {
        this.queryRecords();
      }
    },
    batchUpdateCanceled(newValue) {
      if (newValue === true) {
        this.queryRecords();
      }
    },
    importCompleted(newValue) {
      if (newValue === true) {
        this.queryRecords();
      }
    },
    importCanceled(newValue) {
      if (newValue === true) {
        this.queryRecords();
      }
    },
    importFailed(newValue) {
      if (newValue === true) {
        this.queryRecords();
      }
    },
  },
  mounted() {
    // listen for any record expanding coming from the table component
    eventBus.$on('expandRecord', this.onExpandRecord);
    eventBus.$on('expandRecordHistory', this.onExpandRecordHistory);

    // listen for any table cell updates
    eventBus.$on('recordUpdate', this.onUpdateRecord);
    eventBus.$on('recordCreate', this.onCreateRecord);
    eventBus.$on('recordsDelete', this.onDeleteRecords);

    // listen for non-socket batch updates
    eventBus.$on('recordsUpdate', this.onUpdateRecords);

    // listen for field changes
    eventBus.$on('field.update', this.onUpdateField);
  },
  created() {
    this.queryRecords();
    if (!this.activeSocketNotification) {
      this.fetchJobInformation();
    }
  },
  beforeUnmount() {
    eventBus.$off('expandRecord', this.onExpandRecord);
    eventBus.$off('expandRecordHistory', this.onExpandRecordHistory);
    eventBus.$off('recordUpdate', this.onUpdateRecord);
    eventBus.$off('recordCreate', this.onCreateRecord);
    eventBus.$off('recordsDelete', this.onDeleteRecords);
    eventBus.$off('recordsUpdate', this.onUpdateRecords);
    eventBus.$off('field.update', this.onUpdateField);
  },
  methods: {
    ...mapMutations([
      'setCheckedRecordIds',
      'loadRecords',
    ]),
    onToggleCheckboxes(recordIds) {
      this.setCheckedRecordIds(recordIds);
    },
    queryRecordsRequest(queryVars, onSuccess, onError, onAbort) {
      if (!this.$route.params.objectKey) {
        onAbort();
        return;
      }
      // Determine if the most recent socket notification matches the active socket notification
      const isLastSocketNotificationMatchingActive = (
        this.socketNotifications[this.socketNotifications.length - 1]?.object_key
        === this.activeSocketNotification?.object_key
      );
      this.commitRequest({
        request: () => window.Knack.Api.getRecords(
          this.$route.params.objectKey,
          queryVars.filters,
          queryVars.search,
          queryVars.currentPage,
          queryVars.recordsPerPage,
          queryVars.sort.field,
          queryVars.sort.order,
        ),
        onSuccess,
        onError,
        globalLoading: this.socketNotifications.length === 0 || isLastSocketNotificationMatchingActive,
      });
    },
    async fetchJobInformation() {
      const jobId = this.$route.query.jobid;
      if (!jobId) {
        return;
      }
      this.commitRequest({
        request: () => window.Knack.Api.getJobLogs(this.app.attributes.id, jobId),
        onSuccess: (response) => {
          const job = response[0];
          if (job && job.status !== 'Running') {
            let category;
            if (job.status === 'Finished') category = 'complete';
            else if (job.status === 'Failed') category = 'failed';
            else if (job.status === 'Canceled') category = 'canceled';
            this.fetchedNotification = {
              jobType: 'import',
              category,
              processed_count: job.processed_count,
              total_count: job.total_count,
              timestamp: new Date(job.ended_at).getTime() / 1000,
              failed_rows: job.failed_rows,
            };
          }
        },
      });
    },
    handleClearFetchedNotification() {
      this.fetchedNotification = null;
    },
    onUpdateField(field) {
      this.records = this.object.reformatRecordsByFields(this.records, [
        field.key,
      ]);
    },
    onExpandRecord(recordId) {
      this.$router.push(`/records/objects/${this.object.key}/record/${recordId}/edit${this.queryParams}`);
    },
    onExpandRecordHistory(recordId) {
      this.$router.push(`/records/objects/${this.object.key}/record/${recordId}/history${this.queryParams}`);
    },
    onUpdateRecord(record) {
      const recordIndex = this.records.findIndex((recordInState) => recordInState.id === record.id);

      if (recordIndex < 0) {
        return;
      }

      const records = [
        ...this.records.slice(0, recordIndex),
        record,
        ...this.records.slice(recordIndex + 1),
      ];

      this.records = records;
    },
    onCreateRecord(record) {
      this.records.push(record);

      this.incrementTotalRecords();
    },

    onBeforeDeleteRecords(recordIds) {
      // The below comment is true after a batch completes,
      // but introduces a confusing delay as records have to be fetched first.
      // To make it clear that the records have in fact been deleted,
      // remove them locally as well.

      this.deletedRecordIds = recordIds;
    },

    async onDeleteRecords() {
      // if records are deleted we need to refresh the current set.
      // We can't just remove the deleted records as we'll need to replace them from the next page
      await this.queryRecords();

      this.deletedRecordIds = [];
    },
    onUpdateRecords() {
      this.queryRecords();
    },
    onClickFieldAction(event, eventVars = {}) {
      const { fieldKey, columnIndex } = eventVars;
      const urlPrefix = `/records/objects/${this.$route.params.objectKey}/fields`;

      const routes = {
        settings: `${urlPrefix}/${fieldKey}/settings`,
        type: `${urlPrefix}/${fieldKey}/type`,
        create: `${urlPrefix}/add/index/${columnIndex}`,
        delete: `${urlPrefix}/${fieldKey}/delete`,
      };

      /*
        // KLUDGE:: `() => {}` seems to fix a promise issue that an upcoming release will fix
        // https://github.com/vuejs/vue-router/issues/2932
        */
      this.$router.push(routes[event], () => {});
    },
  },
};
</script>

<style lang="scss">
#object-records-loading {
  padding: 2em;
}

#kn-records-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: .95em;

  tbody {
    font-size: .95em;
  }

  .kn-keyword-search {
    float: left;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .kn-table-wrapper {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 140px);

    .recordsNav {
      padding: .75em 3em .25em 2em;
    }

    .kn-table-element {
      overflow-x: auto;
      overflow-y: scroll;
      flex: 1;

      table {
        border-collapse: separate;
        padding: 0 2em 1em;
      }

      td, th {
        border-top-width: 0;
        border-left-width: 0;

        &:first-child {
          border-left-width: 1px;
        }

        a {
          color: $gray800;
        }
      }

      tr:nth-child(odd) td:first-child,
      tr:nth-child(odd) td:nth-child(2) {
        background-color: #ffffff;
      }

      tr:nth-child(even) td:first-child,
      tr:nth-child(even) td:nth-child(2) {
        background-color: #fafafa;
      }

      table thead th:first-child,
      table tr td:first-child,
      table thead th:nth-child(2),
      table tr td:nth-child(2) {
        position: sticky;
        z-index: 8;
      }

      table thead th:first-child,
      table tr td:first-child {
        left: 0;
      }

      table thead th:nth-child(2),
      table tr td:nth-child(2) {
        left: 30px;
      }

      table thead th:first-child,
      table thead th:nth-child(2) {
        top: 0;
        z-index: 9;
      }

      & table thead th {
        position: sticky;
        top: 0;

        border-bottom: 0;
        border-top: 0;
        z-index: 2;

        font-weight: 500;
        line-height: 1;

        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 1px;
          background-color: #dbdbdb;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          left: 0;
          height: 1px;
          background-color: #dbdbdb;
        }
      }
    }
  }
}
</style>
