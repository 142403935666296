<template>
  <div>
    <div
      v-if="showDetailsState"
      class="view-state-modal-wrapper"
    >
      <div class="view-state-modal">
        <h1>{{ detailsTitle }}</h1>
        <p v-if="detailsDescription">
          {{ detailsDescription }}
        </p>
        <DetailsView
          should-show-utility-dropdown
          class="group has-util-dropdown"
          :class="detailsClass"
          :data="detailsData"
          :schema="detailsSchema"
          :view="view"
          :route-prefix="`/pages/${$route.params.pageKey}/views/${view.key}/calendar/details`"
          @delete="onDeleteDetail"
          @drop="onDropDetail"
        />
      </div>
    </div>

    <div
      v-if="showFormState"
      class="view-state-modal-wrapper"
    >
      <div class="view-state-modal">
        <h1>{{ formTitle }}</h1>
        <p v-if="formDescription">
          {{ formDescription }}
        </p>
        <InputsView
          :schema="inputGroups"
          :view="view"
          :route-prefix="`/pages/${$route.params.pageKey}/views/${view.key}/calendar/form`"
          @delete="onDeleteInput"
          @drop="onDropInput"
        />
      </div>
    </div>

    <div class="kn-calendar-wrapper">
      <RecordNavigation
        :allow-limit="allowLimit"
        :current-page="queryVars.currentPage"
        :object-key="object.key"
        :filters="filters"
        :filter-menu-links="filterMenuLinks"
        :records="records"
        :records-per-page="queryVars.recordsPerPage"
        :show-keyword-search="false"
        :show-export-button="false"
        :show-filters="showFilters"
        :show-filter-menu="showFilterMenu"
        :show-pagination="false"
        :total-records="totalRecords"
        :total-pages="totalPages"
      >
        <a
          v-if="canDownloadRSS"
          :href="`${feedLink}/rss`"
          class="kn-rss kn-button is-link"
          style="margin-right: .25em;"
          target="_blank"
        >
          <FontAwesome
            name="rss"
            class="fa-margin-left"
          />&nbsp;subscribe to RSS
        </a>
        <a
          v-if="canDownloadICal"
          :href="`${feedLink}/ical`"
          class="kn-ical kn-button is-link"
          style="margin-right: .25em;"
          target="_blank"
        >
          download events
        </a>
      </RecordNavigation>

      <div class="click-interceptor" />

      <FullCalendar
        v-if="isCalendarView && showFullCalendar"
        ref="calendar"
        :events="parsedEvents"
        :config="fullCalendarConfig"
        :view="calendarView"
      />

      <div v-if="isListView">
        <ul class="kn-cal-list">
          <li
            v-for="event in parsedEvents"
            :key="event.id"
          >
            <span class="kn-cal-list-day">
              {{ listPartsForEvent(event).day }}
              {{ ' ' }}
            </span>
            <span class="kn-cal-list-date">
              {{ listPartsForEvent(event).date }}
              {{ ' ' }}
            </span>
            <span class="kn-cal-list-time">
              {{ listPartsForEvent(event).time }}
              {{ ' ' }}
            </span>
            <span class="kn-cal-list-label">
              <span :style="`color: ${event.color}`">
                {{ event[labelKey] }}
              </span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import RendererUtils from '@/components/renderer/RenderUtils';
import FullCalendar from '@/components/renderer/calendar/FullCalendar';
import DetailActionsMixin from '@/components/renderer/mixins/DetailActionsMixin';
import InputActionsMixin from '@/components/renderer/mixins/InputActionsMixin';
import QueryMixin from '@/components/renderer/mixins/QueryMixin';
import DetailsView from '@/components/renderer/shared/Details';
import InputsView from '@/components/renderer/shared/Inputs';
import RecordNavigation from '@/components/renderer/shared/RecordNavigation';
import FontAwesome from '@/components/ui/FontAwesome';
import RequestUtils from '@/components/util/RequestUtils';
import DateLib from '@/util/Dates';

export default {
  components: {
    DetailsView,
    FontAwesome,
    FullCalendar,
    InputsView,
    RecordNavigation,
  },
  mixins: [
    DetailActionsMixin,
    InputActionsMixin,
    QueryMixin,
    RendererUtils,
    RequestUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      oldDate: null,
      showFullCalendar: true,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    // These two route computeds help disambiguate drag drop operations since calendar can have both
    routePrefixInputs() {
      return `${this.routePrefix}/form`;
    },
    routePrefixDetails() {
      return `${this.routePrefix}/details`;
    },
    fullCalendarConfig() {
      return {
        weekends: !this.view.get('events').exclude_weekends,
        firstDay: this.view.get('events').week_start === 'sunday' ? 0 : 1,
        aspectRatio: this.aspectRatio,
        slotMinTime: this.view.get('events').time_min || '00:00:00',
        slotMaxTime: this.view.get('events').time_max || '24:00:00',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        eventDataTransform: (eventData) => ({
          ...eventData,

          // The event start and end can not be moment objects.
          start: eventData.start.toISOString(),
          end: eventData.end.toISOString(),
        }),
      };
    },
    calendarView() {
      const calendarViewMap = {
        agendaDay: 'timeGridDay',
        agendaWeek: 'timeGridWeek',
        month: 'dayGridMonth',
      };

      return calendarViewMap[this.view.get('events').view];
    },
    aspectRatio() {
      if (this.view.get('events').exclude_weekends) {
        return this.windowWidth / 1530;
      }

      if (this.windowWidth < 867) {
        return this.windowWidth / 1200;
      }

      return this.windowWidth / 1000;
    },
    parsedEvents() {
      return this
        .records
        .map((record) => ({ ...record, ...this.parseEvent(record) }))
        .filter((record) => record.start);
    },
    feedLink() {
      return `${window.Knack.Api.api_uri}scenes/${this.view.sceneKey}/views/${this.view.get('key')}/records/applications/${window.Knack.Api.application_id}`;
    },
    hasEvents() {
      return !isEmpty(this.records);
    },
    isCalendarView() {
      return this.view.get('events').display_type === 'calendar';
    },
    isListView() {
      return this.view.get('events').display_type === 'list';
    },
    canDownloadICal() {
      return this.view.get('events').ical === true;
    },
    canDownloadRSS() {
      return this.view.get('events').rss === true;
    },
    hasCustomFilters() {
      return this.view.get('filters_custom');
    },

    detailsClass() {
      const classes = {};

      return classes;
    },
    detailsData() {
      return this.view.data[0];
    },
    detailsDescription() {
      return this.view.get('details').description;
    },
    detailsTitle() {
      return this.view.get('details').title || 'Event Details';
    },
    formDescription() {
      return this.view.get('form').description;
    },
    formTitle() {
      return this.view.get('form').title || 'Add Event';
    },
    detailsSchema: {
      get() {
        return this.view.getDetailsSchema();
      },
      set(newVal) {
        this.view.setDetailsSchema(newVal);
      },
    },
    inputGroups: {
      get() {
        if (this.view.get('form')) {
          return this.view.get('form').groups;
        }

        return [];
      },
      set(newVal) {
        this.view.get('form').groups = newVal;
      },
    },
    viewState() {
      // KLUDGE: not sure that deciphering routes should be the responsibility of this component, but struggled to get functional props working in calendar route. Revisit that and use a prop instead?
      if (this.$route.path.indexOf('calendar/details') > -1) {
        return 'details';
      }

      if (this.$route.path.indexOf('calendar/form') > -1) {
        return 'form';
      }

      return 'default';
    },
    showDetailsState() {
      return this.viewState === 'details';
    },
    showFormState() {
      return this.viewState === 'form';
    },
    labelKey() {
      return this.view.get('events').label_field.key;
    },
    eventKey() {
      return this.view.get('events').event_field.key;
    },
    dateField() {
      const field = this.object.getField(this.eventKey);

      if (!field) {
        return null;
      }

      return field.raw();
    },
    objectKey() {
      return this.view.get('source').object;
    },
    sourceFilters() {
      return this.view.get('source').criteria;
    },
    recordLimit() {
      return this.view.get('source').limit;
    },
    searchTerm() {
      return ``;
    },
    page() {
      return 1;
    },
  },
  watch: {
    'view.data': function (newVal) {
      this.setRecords(newVal);
    },
  },
  created() {
    this.setRecords(this.view.data);

    // transform view object into data for props
    // of RecordTools and TableComponent
    this.initQueryVars();
  },
  mounted() {
    const handleResize = () => {
      this.windowWidth = window.innerWidth;
    };

    window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
  },
  methods: {
    parseEvent(record) {
      const rawDate = record[`${this.eventKey}_raw`] || record[this.eventKey];
      if (!rawDate) {
        return {};
      }

      let eventDate;

      if (moment.isMoment(rawDate)) {
        // If the rawDate is already a moment instance, then we don't need to parse anything.
        // To support ranges in the future, look into using moment durations here.
        eventDate = {
          start: moment(rawDate),
        };
      } else {
        eventDate = DateLib.convertKnackDateToFullCalendar(rawDate);
      }

      const event = {
        title: String(record[this.labelKey]),
        id: record.id,
        start: eventDate.start,
      };

      // ignoring time?
      if (this.dateField.format.time_format === 'Ignore Time') {
        // no time, set to all day
        event.allDay = true;
      } else {
        // add start and check for all day
        if (rawDate.all_day) {
          event.allDay = true;
        }
      }

      if (record[`${this.eventKey}_raw`] && record[`${this.eventKey}_raw`].to) {
        event.end = eventDate.end;
      } else {
        event.end = event.start;
      }

      let color = this.view.get('events').event_color_default || '#3366cc';

      if (this.view.get('events').event_colors) {
        const pass = false;

        this.view.get('events').event_colors.forEach((rule) => {
          // TODO this logic won't work
          if (!pass) {
            if (pass) {
              color = rule.color;
            }
          }
        });
      }

      event.color = color;

      return event;
    },
    listPartsForEvent(event) {
      let time = 'All day';
      let day;
      let date;

      const eventTime = event[this.eventKey];
      const startTime = event.start;

      // If the event is all day then we don't need to parse the time.
      if (!event.allDay) {
        // When using sample data, the dates are momentJS instances.
        if (moment.isMoment(eventTime)) {
          time = eventTime.format('h:mm a');
        } else {
          const timeParts = eventTime.split(' ');

          if (timeParts.length > 1) {
            timeParts.shift();
            time = timeParts.join(' ');
          }
        }
      }

      // When using sample data, the dates are momentJS instances.
      if (moment.isMoment(startTime)) {
        day = startTime.format('ddd');
        date = startTime.format('MMM Do');
      } else {
        const parts = String(event.start).split(' ');

        day = parts[0];
        date = `${parts[1]} ${parts[2]}`;
      }

      const isNewDate = date !== this.oldDate;

      // ToDo for some reason this is causing an infinit loop:
      // http://v3.us.private.knack.com/knack-v3-validation/v310-20-18/pages/scene_15/views/view_21/calendar
      // this.oldDate = date

      return {
        time,
        day: isNewDate ? day : '',
        date: isNewDate ? date : '',
      };
    },
  },
};
</script>

<style lang="scss">
// Required to make the fullcalendar component expand to the full width.
.kn-calendar {
  flex-grow: 1;
}

// Styling for the fullcalendar component.
.fc {
  .fc-button {
    padding-top: .2em;
    padding-bottom: .2em;
  }

  .fc-button-primary {
    background-color: #e6e6e6;
    background-image: linear-gradient(180deg, #fff, #e6e6e6);
    background-repeat: repeat-x;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
    color: #333333;

    &:disabled {
      background-color: #e6e6e6;
      background-image: none;
      border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
      color: #333333;
    }
  }

  .fc-button-active {
    background-color: #ccc !important;
    background-image: none !important;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25) !important;
    color: #333333 !important;
  }
}
</style>

<style lang="scss" scoped>
.kn-calendar-wrapper {
  min-width: 800px;
  position: relative;
}

.icon {
  width: 18px;
  height: 18px;
}

ul.kn-cal-list {
  list-style: disc outside;

  li:nth-child(2n) {
    background-color: rgba(0, 0, 0, .02);
  }
}

.view-state-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .2);
  z-index: 101;

  .view-state-modal {
    width: max-content;
    max-width: 100%;
    min-width: 620px;
    background: #fff;
    padding: 1em;
    margin: 1em auto;
    box-shadow: 0 2px 7px 0 rgba(49, 49, 93, 0.12);

    h1 {
      font-size: 1.5em;
      line-height: 1.25em;
      padding-bottom: .25em;
      font-weight: bold;
      border-bottom: 1px solid #dbdbdb;
      margin-bottom: .5em;
    }
  }
}
</style>
