<template>
  <view-toolbox
    back-title="Column"
    title="Column Layout"
  >
    <form v-if="column">
      <div id="column-width">
        <label class="text-default text-sm font-medium mb-2 leading-4">Column Width</label>
        <div class="input-labels tw-input-labels">
          <label>
            <input
              v-model="column.width.type"
              type="radio"
              name="width_type"
              value="default"
            >
            <span>Default</span>
          </label>
          <label>
            <input
              v-model="column.width.type"
              type="radio"
              name="width_type"
              value="custom"
            >
            <span>Custom&nbsp;&nbsp;</span>
            <span
              v-if="column.width.type === 'custom'"
              class="width-options"
            >
              <input
                v-model="column.width.amount"
                type="text"
              >
              <select v-model="column.width.units">
                <option value="px">
                  pixels
                </option>
                <option value="pt">
                  percent
                </option>
              </select>
            </span>
          </label>
        </div>
      </div>

      <div>
        <label class="text-default text-sm font-medium mb-2 leading-4">Align</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="column.align"
            type="radio"
            name="align"
            value="left"
          >Left</label>
          <label><input
            v-model="column.align"
            type="radio"
            name="align"
            value="center"
          >Center</label>
          <label><input
            v-model="column.align"
            type="radio"
            name="align"
            value="right"
          >Right</label>
        </div>
      </div>
    </form>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'TableColumnLayout',
  components: {
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    columnIndex() {
      return this.$route.params.columnIndex;
    },
    column() {
      if (!this.hasActiveView) {
        return undefined;
      }

      if (!this.columnIndex) {
        return {
          width: {},
        };
      }

      const column = this.viewRaw.columns[this.columnIndex];

      column.width = {
        type: 'default', units: 'px', amount: 50, ...column.width,
      };

      return column;
    },
  },
};
</script>

<style lang="scss" scoped>
#column-width {
  .width-options {
    select {
      width: 100px; margin-left: 8px;
    }
    input {
      width: 50px;
    }
  }
}
</style>
