<template>
  <view-toolbox
    back-title="Edit Calendar"
    :back-count="1"
    title="Build Form"
  >
    <p class="mb-4 text-sm font-normal leading-4">
      Users can click on a calendar date to add a new event.
    </p>

    <template v-if="hasActiveView">
      <div>
        <label
          for="title"
          class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label"
        >
          Form Title
        </label>
        <input
          v-model="viewRaw.form.title"
          type="text"
          name="title"
        >
      </div>
      <div>
        <label
          for="title"
          class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label"
        >
          Description
        </label>
        <textarea
          v-model="viewRaw.form.description"
          name="description"
        />
      </div>
    </template>

    <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label"><b>Form Inputs</b></label>
    <AddItems
      view-type="form"
    >
      <p class="text-sm leading-5">
        Drag or click options below to add new field inputs.
      </p>
    </AddItems>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import AddItems from '@/components/views/AddItems';

export default {
  name: 'FormInputsBuild',
  components: {
    ViewToolbox,
    AddItems,
  },
  mixins: [
    ViewUtils,
  ],
};
</script>

<style lang="scss">
</style>
