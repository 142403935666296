import isEmpty from 'lodash/isEmpty';

export default {
  methods: {
    async refreshActivePage(pageKey) {
      await this.$store.dispatch('setActivePageByKey', { pageKey });
    },
  },
  computed: {
    page() {
      return this.$store.getters.activePage;
    },
    pageObject() {
      if (!isEmpty(this.page.object)) {
        return this.$store.getters.getObject(this.page.object);
      }

      return false;
    },
    objectRaw() {
      if (!isEmpty(this.pageObject)) {
        return this.pageObject.raw();
      }

      return false;
    },
  },
};
