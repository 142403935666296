import moment from 'moment-timezone';

const DateLib = {
  convertKnackDateToFullCalendar(oldDate) {
    const date = oldDate.date || oldDate;

    let start = new Date(date);

    if (String(start) === 'Invalid Date') {
      start = new Date(moment(date, 'MM/DD/YYYY HH:mma').valueOf());
    }

    let hours = start.getHours();

    if (oldDate.hours) {
      hours = Number(oldDate.hours);

      if (oldDate.am_pm.toLowerCase() === 'pm' && hours !== 12) {
        hours += 12;
      }

      if (oldDate.am_pm.toLowerCase() === 'am' && hours === 12) {
        hours = 0;
      }
    }

    start.setHours(hours);

    if (oldDate.minutes) {
      start.setMinutes(oldDate.minutes);
    }

    const newDate = {
      start: moment(start),
    };

    if (oldDate.to) {
      const end = new Date(oldDate.to.date);
      let hours = Number(oldDate.to.hours);

      if (oldDate.to.am_pm.toLowerCase() === 'pm' && hours !== 12) {
        hours += 12;
      }

      if (oldDate.to.am_pm.toLowerCase() === 'am' && hours === 12) {
        hours = 0;
      }

      end.setHours(hours);
      end.setMinutes(oldDate.to.minutes);
      newDate.end = moment(end);
    }

    return newDate;
  },
};

export default DateLib;
