<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/ui/Icon';
import Ribbon from '@/components/layout/Ribbon';
import RibbonLinkText from '@/components/layout/RibbonLinkText';

const store = useStore();

const connectedServices = computed(() => store.getters['integrations/connectedServices']);
const availableServices = computed(() => store.getters['integrations/availableServices']);
const hasAllIntegrations = computed(() => {
  const connectedServiceIds = connectedServices.value.map((service) => service.service_id);
  const availableServiceIds = availableServices.value.map((service) => service.id);
  return availableServiceIds.every((id) => connectedServiceIds.includes(id));
});
</script>

<template>
  <Ribbon>
    <div class="ribbon_mainRow border-none h-auto p-6">
      <h2 class="ribbonName p-0 mr-12">
        <Icon
          type="puzzle-piece"
          class="ribbonName_icon h-8 w-8 mr-2 text-subtle"
        />
        <span class="ribbonName_title text-emphasis text-xl font-medium">Integrations</span>
      </h2>
      <template v-if="!hasAllIntegrations">
        <RouterLink
          to="/settings/integrations/add"
          class="iconLink ribbonLink h-auto p-3 rounded-lg border border-solid border-default
            bg-white leading-none text-emphasis text-base font-medium"
          data-cy="add-new-integration"
        >
          <Icon
            type="plus-thin"
            class="iconLink_icon iconLink_icon-add ribbonLink_icon mr-2 fill-[#564D54] h-4 w-4"
          />
          <RibbonLinkText
            text="Add New Integration"
            classes="m-0"
          />
        </RouterLink>
      </template>
    </div>
  </Ribbon>
</template>
