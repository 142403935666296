<template>
  <view-toolbox
    back-title="Payment Rules"
    title="Email Rules"
  >
    <p
      class="text-sm leading-5 mb-0"
      style="margin-bottom: 1em"
    >
      Add rules to send emails after the payment is submitted.
      <router-link
        v-if="hasRules"
        id="view-mail-delivery-history"
        :to="`${routePrefix}/history`"
        class="fuchsia underline text-default"
      >
        View email delivery history
      </router-link>
    </p>
    <RulesList
      v-model:rules="emailRules"
      :allow-email-action="true"
      :allow-email-action-basic="true"
      :allow-record-actions="false"
      :item-title="'Email Rule'"
      group-list-item-classes="bg-subtle"
    >
      <template #no-criteria>
        <p>
          This email is sent with every form submission
        </p>
      </template>
    </RulesList>
  </view-toolbox>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import ViewToolbox from '@/components/views/ViewToolbox';
import RulesList from '@/components/rules/RulesList';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'CheckoutEmailRules',
  components: {
    RulesList,
    ViewToolbox,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    emailRules: {
      get() {
        return this.viewRaw.rules.emails || [];
      },
      set(newValue) {
        this.viewRaw.rules.emails = newValue;
      },
    },
    hasRules() {
      return !isEmpty(this.emailRules);
    },
    routePrefix() {
      return `/pages/${this.$route.params.pageKey}/views/${this.view.key}/checkout/emails`;
    },
  },
};
</script>
