import User from '@/store/models/User';

const storeState = {
  user: new User(),
};

const storeGetters = {
  user: (state) => state.user,
};

// mutations
const storeMutations = {
  loadUser(state, user) {
    state.user = new User(user);
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
};
