<template>
  <div
    v-tippy="tooltipSettings"
    :class="classes"
    data-cy="help-icon"
  >
    <div class="helpIcon_wrapper">
      <Icon
        class="text-default"
        :type="icon"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    /**
     * Copy text content for tooltip, overrides `text` when present
     * @param {String}
     */
    copy: {
      type: String,
      default: '',
    },

    /**
     * An actionable help icon has a pointer and is highlighted fuchsia, indicating it is clickable
     * @param {Boolean}
     */
    actionable: {
      type: Boolean,
      default: false,
    },

    /**
     * Set the icon for the tooltip. Defaults to `help-circle`
     * @param {String}
     */
    icon: {
      type: String,
      default: 'help-circle',
    },

    /**
     * Text content for tooltip
     * @param {String}
     */
    text: {
      type: String,
      default: '',
    },

    /**
     * Define a custom class for the tooltip
     * @param {String}
     */
    tooltipTheme: {
      type: String,
      default: '',
    },

    /**
     * Optional tooltip placement.
     * @param {string}
     */
    tooltipPlacement: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    helpText() {
      const characterLimit = 750;

      let text = this.copy || this.text;
      if (text.length > characterLimit) {
        text = `${text.substring(0, characterLimit)}&hellip;`;
      }

      return `<div class='kn-help-tip'>${text}</div>`;
    },

    classes() {
      return [
        'helpIcon',
        'transition',
        {
          'helpIcon-actionable': this.actionable,
        },
      ];
    },

    tooltipSettings() {
      const placement = (this.tooltipPlacement) ? { placement: this.tooltipPlacement } : {};

      return {
        content: this.helpText,
        allowHTML: true,
        theme: 'kn-dark help-icon',
        ...placement,
      };
    },
  },
};
</script>

<style lang="scss">
.kn-help-tip {
  max-width: 320px;
}

.helpIcon {
  cursor: default;
  margin-left: $spacing-xxs;
  display: inline-block;
  vertical-align: middle;
  line-height: 100%;
}

.helpIcon-actionable {
  cursor: pointer !important;
}

.helpIcon-actionable .helpIcon_wrapper {
  &:hover {
    color: $gray500 !important;
  }
}

.helpIcon_wrapper {
  display: flex;
  justify-content: center;

  color: $gray400 !important;

  width: 16px;
  height: 16px;

  svg {
    width: 16px !important;
    height: 16px !important;
    margin: 0;
  }
}

.field-item .helpIcon_wrapper {
  color: $primary500 !important;
}
</style>
