<template>
  <div class="connection-details">
    <p v-if="isForeign">
      This field can connect <b><span>{{ connection.has }}</span> {{ foreignName }}</b> {{ recordInflection(connection.has) }} with <b>{{ connection.belongs_to }} {{ localName }}</b> {{ recordInflection(connection.belongs_to) }}.
    </p>
    <p v-else>
      This field can connect <b><span>{{ connection.belongs_to }}</span> {{ localName }}</b> {{ recordInflection(connection.belongs_to) }} with <b>{{ connection.has }} {{ foreignName }}</b> {{ recordInflection(connection.has) }}.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    connection: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isForeign() {
      return this.connection.relationship_type === 'foreign';
    },
    localName() {
      const localObject = this.$store.getters.activeObject;

      return localObject?.inflections.singular ?? 'Unknown';
    },
    foreignName() {
      const foreignObject = this.$store.getters.getObject(this.connection.object);

      return foreignObject?.inflections.singular ?? 'Unknown';
    },
  },
  methods: {
    recordInflection(which) {
      return (which === 'one') ? 'record' : 'records';
    },
  },
};
</script>

<style lang="scss">
.connection-details {
  overflow: auto;
  padding: .5em .125em;

  .capitalize {
    text-transform: capitalize;
  }

  p {
    padding-left: 12px;
    margin-bottom: .25em;
    @include font-caption;
  }
  p.foreign {
    font-style: italic;
  }
}
</style>
