<template>
  <BodyWrapper>
    <div
      id="settings-app"
      class="body-wrapper m-0 px-6 py-2"
    >
      <RouterView />
    </div>
  </BodyWrapper>
</template>

<script>
import BodyWrapper from '@/components/layout/BodyWrapper';
import { completeChecklistStep } from '@/checklists/helpers';

export default {
  components: {
    BodyWrapper,
  },
  created() {
    completeChecklistStep('api_js_css');
  },
};
</script>

<style lang="scss">
#settings-sub-nav {
  margin: .4em 0 1.25em;
}
</style>
