<template>
  <div class="rule-basic-email-action">
    <div>
      <div class="email-recipients">
        <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Recipients</label>
        <ActionList
          v-model:items="item.email.recipients"
          :default-item="defaultRecipient"
          scope-name="recipient"
          class="naked"
        >
          <template #default="{recipient}">
            <div class="recipient-item">
              <input
                v-model="recipient.email"
                type="text"
                @keydown.space.prevent
              >
            </div>
          </template>
        </ActionList>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import ActionList from '@/components/ui/lists/ActionList';

export default {
  name: 'BasicEmailAction',
  components: {
    ActionList,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    'item.email.recipients': {
      deep: true,
      handler: function removeSpacesHandler() {
        if (!this.item?.email?.recipients) {
          return;
        }

        this.item.email.recipients.forEach((recipient) => {
          if (!recipient.email || recipient.email.indexOf(' ') === -1) {
            return;
          }
          recipient.email = recipient.email.replaceAll(' ', '');
        });
      },
    },
  },
  created() {
    // set defaults
    this.item.email = {
      ...this.defaultItem().email,
      ...this.item.email,
    };

    const legacyRuleEmails = get(this.item, 'email.email');

    // This code can be removed when v2 is no longer used.
    if (legacyRuleEmails) {
      this.item.email.recipients = [];

      const legacyEmails = String(legacyRuleEmails)
        .split(',')
        .map((legacyEmail) => legacyEmail.trim())
        .filter(Boolean);

      legacyEmails.forEach((legacyEmail) => {
        this.item.email.recipients.push(
          this.defaultRecipient(legacyEmail),
        );
      });
    }

    // Make sure the V2 email is not set -- v3 uses recipients instead of an email string.
    delete this.item.email.email;
  },
  methods: {
    defaultItem() {
      return {
        action: 'email_notification',
        email: {
          recipients: [
            this.defaultRecipient(),
          ],
        },
        criteria: [],
      };
    },

    defaultRecipient(defaultEmail) {
      return {
        email: String(defaultEmail || window.Knack.Api.email),
      };
    },
  },
};
</script>

<style lang="scss">
.rule-basic-email-action {

  .recipient-item {
    flex-grow: 1;

    > * {
      margin-right: 5px;
    }
  }
}
</style>
