<template>
  <div class="kn-input-rating">
    <RateIt
      v-model:rating="localValue"
      :show-rating="false"
      :max-rating="maxRating"
      :increment="increment"
      :item-size="20"
      icon-type="star"
    />
    <BuilderButton
      type="button"
      theme="link"
      @click="onResetRating"
    >
      <Icon
        v-if="hasRating"
        class="kn-rating-reset"
        type="close"
      />
    </BuilderButton>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import RateIt from '@/components/ui/rating/RateIt';
import BuilderButton from '@/components/ui/BuilderButton';

export default {
  name: 'RatingInput',
  components: {
    BuilderButton,
    Icon,
    RateIt,
  },
  inheritAttrs: false,
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: [Number, String],
      default: 0,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {};
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue ? Number(this.modelValue) : 0;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    hasRating() {
      return this.localValue > 0;
    },
    maxRating() {
      return parseInt(this.field.format.max, 10);
    },
    increment() {
      return !this.field.format.allow_half ? 1 : 0.5;
    },
  },
  methods: {
    onResetRating() {
      this.localValue = 0;
    },
  },
};
</script>
