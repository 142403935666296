<template>
  <div class="kn-table-wrapper">
    <Loading :is-loading="isRequestingRecords" />

    <slot name="navigation" />

    <slot name="table" />

    <slot name="lowerNavigation" />
  </div>
</template>

<script>
import Loading from '@/components/renderer/shared/Loading';

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isRequestingRecords: false,
    };
  },
};
</script>
<style>
.kn-table-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.kn-loading-table {
  position:relative;
}
</style>
