<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Allow Half Stars</label>
      <div>
        <input
          v-model="fieldLocal.format.allow_half"
          type="checkbox"
        >
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Number of Stars</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="fieldLocal.format.max"
          type="radio"
          value="3"
        >3</label>
        <label><input
          v-model="fieldLocal.format.max"
          type="radio"
          value="4"
        >4</label>
        <label><input
          v-model="fieldLocal.format.max"
          type="radio"
          value="5"
        >5</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
