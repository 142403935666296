<template>
  <view-toolbox
    title="List Item Properties"
    back-title="List Items"
    :back-count="8"
  >
    <DetailProperties
      :item="item"
    />
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DetailProperties from '@/components/views/shared/DetailProperties';

export default {
  name: 'DetailsItemProperties',
  components: {
    ViewToolbox,
    DetailProperties,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    item() {
      const {
        layoutIndex,
        groupIndex,
        columnIndex,
        itemIndex,
      } = this.$route.params;

      return this
        .viewRaw
        ?.columns?.[layoutIndex]
        ?.groups[groupIndex]
        ?.columns[columnIndex][itemIndex];
    },
  },
};
</script>

<style lang="scss">
</style>
