<template>
  <view-toolbox
    :back-link="`/pages/${page.key}/views/${view.key}/checkout`"
    back-title="Edit Checkout"
    title="Settings"
  >
    <form class="margin-bottom-double">
      <div>
        <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Payment Action</label>
        <p v-if="insertsChargeRecord">
          Inserts a <strong>{{ chargeName }}</strong> record connected to this page's <strong>{{ sourceObjectName }}</strong>
        </p>
        <p v-if="optionallySavesPayment">
          Optionally stores payment information that can be charged later.
        </p>
        <p v-if="savesPayment">
          Stores payment information that can be charged later.
        </p>
        <router-link
          :to="routeAppend($route.path, 'change')"
          class="button save p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600"
        >
          Change action
        </router-link>
      </div>
      <div>
        <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Payment Processors</label>
        <div
          v-for="processor in paymentProcessors"
          :key="processor.key"
          class="input-labels tw-input-labels"
        >
          <label><input
            v-model="selectedPaymentProcessors"
            type="checkbox"
            :value="processor.key"
            @change="onProcessorToggled(processor)"
          > {{ processor.name }}</label>
        </div>
      </div>
      <div>
        <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Test Mode</label>
        <IfPlan :level-is-maximum-of="1">
          <div
            class="input-labels tw-input-labels"
          >
            <label><input
              type="checkbox"
              value="ecommerce_test_mode"
              checked="checked"
              disabled="disabled"
            > Use the test mode of your payment processors</label>
          </div>
        </IfPlan>
        <IfPlan :level-is-minimum-of="2">
          <div class="input-labels tw-input-labels">
            <label><input
              v-model="viewRaw.ecommerce_test_mode"
              type="checkbox"
            > Use the test mode of your payment processors</label>
          </div>
        </IfPlan>
      </div>
      <div v-if="insertsChargeRecord">
        <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Payment Total Field</label>
        <div>
          <select
            v-model="totalField"
            class="text-base py-2 pl-3 leading-5"
            @change="totalFieldUpdated"
          >
            <option
              v-for="field in numericFields"
              :key="field.key"
              :value="field.key"
            >
              {{ field.name }}
            </option>
          </select>
          <p
            v-if="!totalField"
            class="error-alert form-errors margin-top"
          >
            <strong>No numeric field found in the source table.</strong>
          </p>
        </div>
      </div>
      <div v-if="insertsChargeRecord">
        <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Description Field</label>
        <div>
          <select
            v-model="viewRaw.charge_description_field"
            class="text-base py-2 pl-3 leading-5"
          >
            <option
              v-for="field in fields"
              :key="field.key"
              :value="field.key"
            >
              {{ field.name }}
            </option>
          </select>
        </div>
      </div>
      <div>
        <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Currency</label>
        <Currencies v-model="currency" />
      </div>
      <div>
        <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Submit Payment Button Text</label>
        <input
          v-model="viewRaw.submit_button_text"
          type="text"
        >
      </div>
      <div v-if="view.type === `customer`">
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Update Billing Button Text</label>
          <input
            v-model="viewRaw.update_billing_button_text"
            type="text"
          >
        </div>
      </div>
      <div v-if="view.type === `customer`">
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label">Remove Card Button Text</label>
          <input
            v-model="viewRaw.remove_card_button_text"
            type="text"
          >
        </div>
      </div>
    </form>
  </view-toolbox>
</template>
<script>
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import Currencies from '@/components/settings/ecommerce/Currencies';
import PaymentUtils from '@/components/settings/ecommerce/PaymentUtils';
import IfPlan from '@/components/util/IfPlan';

export default {
  components: {
    ViewToolbox,
    Currencies,
    IfPlan,
  },
  mixins: [
    ViewUtils,
    PaymentUtils,
  ],
  data() {
    return {

      currency: '',
      totalField: '',
      selectedPaymentProcessors: [],
    };
  },
  computed: {
    insertsChargeRecord() {
      if (this.view.type !== 'customer') {
        return true;
      }

      return false;
    },
    savesPayment() {
      if (this.view.type === 'customer') {
        return true;
      }

      return this.view.type === 'charge' && !this.view.customerOptional;
    },
    optionallySavesPayment() {
      return this.view.type === 'charge' && this.view.customerOptional;
    },
    sourceObjectName() {
      if (isNil(this.viewRaw.source.object)) {
        return;
      }

      return this.getObject(this.viewRaw.source.object).name;
    },
    firstField() {
      return this.numericFields.length > 0 ? this.numericFields[0] : null;
    },
    isChargeView() {
      return this.view.type === 'charge';
    },
    viewHasActiveUpdates() {
      return this.$store.getters.viewHasActiveUpdates;
    },
  },
  watch: {
    currency(newCurrency, oldCurrency) {
      for (const processor of this.viewRaw.payment_processors) {
        processor.currency = newCurrency;
      }
    },
    viewHasActiveUpdates(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.initialize();
      }
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      const existingProcessor = (this.viewRaw.payment_processors && this.viewRaw.payment_processors.length > 0) ? this.viewRaw.payment_processors[0] : undefined;

      this.selectedPaymentProcessors = this.viewRaw.payment_processors.map((processor) => processor.payment_processor_key);

      this.currency = get(existingProcessor, 'currency', 'USD');

      if (this.firstField && this.insertsChargeRecord) {
        this.totalField = get(existingProcessor, 'total_field_key', this.firstField.key);
      }

      if (!this.viewRaw.charge_description_field) {
        this.viewRaw.charge_description_field = this.fields.length > 0 ? this.fields[0].key : null;
      }
    },
    onProcessorToggled(processor) {
      // Checks for multiple processors of the same type
      this.onUpdateProcessor(processor.key);

      // For each selected processor make sure is reflected in viewRaw
      for (const paymentProcessorKey of this.selectedPaymentProcessors) {
        const paymentProcessor = this.paymentProcessors.find((processor) => processor.key === paymentProcessorKey);

        const processorInProcessors = this.viewRaw.payment_processors.filter((processor) => processor.payment_processor_key === paymentProcessorKey).length > 0;

        if (!processorInProcessors) {
          this.viewRaw.payment_processors.push({
            type: paymentProcessor.processor,
            payment_processor_key: paymentProcessorKey,
            total_field_key: this.totalField,
            currency: this.currency,
          });
        }
      }

      // If we've unchecked a processor remove it from viewRaw array
      if (!this.selectedPaymentProcessors.includes(processor.key)) {
        this.viewRaw.payment_processors = this.viewRaw.payment_processors.filter((rawProcessor) => rawProcessor.payment_processor_key !== processor.key);
      }
    },
    totalFieldUpdated() {
      for (const processor of this.viewRaw.payment_processors) {
        processor.total_field_key = this.totalField;
      }
    },
  },
};
</script>
