<template>
  <div
    class="menu-links kn-menuWrapper control"
    :class="{'menu-links--tabs': menuFormat === 'tabs', 'isActiveMenuFromAddView': isActiveMenuFromAddView}"
  >
    <ConfirmModal
      v-if="showConfirmDelete"
      title="Delete this link?"
      confirm-type="alert"
      @close="showConfirmDelete = false"
      @confirm="onConfirmDelete"
    >
      <p
        v-if="!isMenuLinkToChildPage"
        class="mb-0"
      >
        This menu link will be permanently removed.
      </p>
      <p
        v-else
        class="mb-0"
      >
        You'll also be deleting any Pages and Views this links to.
      </p>
    </ConfirmModal>

    <!-- EMPTY -->
    <template v-if="linksAreEmpty">
      <EmptyFieldsDropZone
        buildable="menu"
        items-alias="links"
        items-action="add"
        :build-path="addMenuLinkPath"
      />
    </template>

    <template v-else>
      <nav class="menu-links__nav">
        <ul class="menu-links__list">
          <template
            v-for="(item, i_index) in view.get('links')"
            :key="showEdit ? `show-edit-${i_index}` : i_index"
          >
            <li
              class="menu-links__list-item"
              :class="{'menu-links__list-item--has-child-link': item.type === 'scene'}"
            >
              <!-- If editing Menu view -->
              <template v-if="showEdit">
                <RouterLink
                  v-slot="{ navigate }"
                  :to="routePrefix + '/links/' + i_index"
                  custom
                >
                  <div
                    class="item-wrapper"
                    :class="[itemClasses(item, i_index)]"
                    :data-item="i_index"
                    draggable="true"
                    @click="navigate"
                    @dragstart="onDragStart"
                    @dragend="onDragEnd"
                  >
                    <div
                      class="drop-target left"
                      @dragover="onDragOver"
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    />

                    <div
                      class="menu-links__edit-drag-image"
                      data-drag-image
                    >
                      <SharedMenuLink
                        :link-type="menuLinkType"
                        :text="item.name"
                        :icon="item.icon"
                        :route="routePrefix + '/links/' + i_index"
                        :is-active="isLinkActive(i_index, view.attributes.key)"
                        :menu-design="menuDesign"
                        :link-design="item.link_design"
                        :is-menu-design-active="isMenuDesignActive"
                        :is-link-design-active="item.link_design_active"
                      />
                      <ViewChildLink
                        v-if="item.type === 'scene'"
                        top="50%"
                        right="15px"
                        :tooltip="'Navigate to this page'"
                        :url="typeof item.scene === 'string' ? `/pages/${getMenuPageKey(item.scene)}` : ''"
                      />

                      <div
                        class="overlay"
                        @click="activateItem"
                      />

                      <div
                        v-if="showEdit"
                        class="item-links gap-1 bg-subtle rounded-t-lg border-b-0 p-1"
                      >
                        <a
                          v-tippy
                          content="Edit this menu item"
                          class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
                        >
                          <Icon
                            class="text-default"
                            type="edit"
                          />
                        </a>
                        <a
                          v-tippy
                          content="Delete this menu item"
                          class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
                          @click="onDeleteItem($event, i_index)"
                        >
                          <Icon
                            class="text-default"
                            type="delete"
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="showEdit"
                      class="drop-target right"
                      @dragover="onDragOver"
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    />
                  </div>
                </RouterLink>
              </template>

              <!-- If viewing only -->
              <template v-else>
                <SharedMenuLink
                  :link-type="menuLinkType"
                  :text="item.name"
                  :icon="item.icon"
                  :route="item.url"
                  :is-active="item.isActive"
                />
              </template>
            </li>
          </template>
        </ul>
      </nav>
    </template>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';
import { mapGetters } from 'vuex';

import EmptyFieldsDropZone from '@/components/renderer/shared/EmptyFieldsDropZone';
import Icon from '@/components/ui/Icon';
import ConfirmModal from '@/components/ui/ConfirmModal';
import RendererUtils from '@/components/renderer/RenderUtils';
import DragDropUtils from '@/components/renderer/mixins/DragDropUtils';
import ViewChildLink from '@/components/renderer/shared/ViewChildLink';
import SharedMenuLink from '@/components/shared/SharedMenuLink';
import useMenuPreviewActiveLinks from '@/composables/ui/useMenuPreviewActiveLinks';

export default {
  components: {
    EmptyFieldsDropZone,
    Icon,
    ConfirmModal,
    ViewChildLink,
    SharedMenuLink,
  },
  mixins: [
    DragDropUtils,
    RendererUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
    isViewOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isLinkActive } = useMenuPreviewActiveLinks();
    return { isLinkActive };
  },
  data() {
    return {
      activeItemSlot: null,
      showConfirmDelete: false,
      activeItemIndex: 0,
    };
  },
  computed: {
    ...mapGetters([
      'menuKeyForAddingNewView',
    ]),
    isActiveMenuFromAddView() {
      return this.view.key === this.menuKeyForAddingNewView;
    },
    showEdit() {
      return !this.isViewOnly;
    },
    linksAreEmpty() {
      return !this.view.get('links') || this.view.get('links').length === 0;
    },
    addMenuLinkPath() {
      return `/pages/${this.$route.params.pageKey}/views/${this.view.key}/menu`;
    },
    menuDesign() {
      return this.view?.attributes?.menu_links_design;
    },
    isMenuDesignActive() {
      return !!this.view?.attributes?.menu_links_design_active;
    },
    menuFormat() {
      return this.view.get('format');
    },
    menuLinkType() {
      if (this.menuFormat === 'tabs') {
        return 'tab';
      }
      return 'button';
    },
    pageSlug() {
      return !isNil(this.page) ? this.page.get('slug') : '';
    },
    isMenuLinkToChildPage() {
      if (this.view.type !== 'menu') return false;

      const activeMenuLink = this.view.links[this.activeItemIndex];

      // Check if multiple links point to the same child page as the active link
      if (this.view.links.filter((link) => link.scene === activeMenuLink.scene).length > 1) {
        // If true, a link to the child page is still on the view and the corresponding page and views will not be deleted
        return false;
      }

      // Finally, check if any child page corresponds to the active menu link
      return this.page.children?.some((childPage) => childPage.slug === activeMenuLink.scene);
    },
  },
  methods: {
    itemClasses(item, index) {
      return [
        'kn-link',
        `kn-link-${index + 1}`,
        {
          isNew: (this.isActiveMenuFromAddView && index === this.view.get('links').length - 1),
          'kn-link-page': item.type !== 'url',
        },
      ];
    },
    getMenuPageKey(scene) {
      const page = this.$store.getters.getPageBySlug(scene);

      return page ? page.key : '';
    },
    onDrop(event) {
      log('onDrop', event);

      event.preventDefault();
      event.stopPropagation();

      const oldItemIndex = Number(event.dataTransfer.getData('item'));
      const newItem = event.dataTransfer.getData('new');
      const $parent = event.target.parentElement;
      let itemIndex = Number($parent.getAttribute('data-item'));

      if (event.target.classList.contains('right')) {
        itemIndex++;
      }

      let oldItems;
      let item;

      if (!newItem) {
        item = this.view.get('links').splice(oldItemIndex, 1)[0];

        // extract items from old column
        oldItems = this.view.get('links').splice(0);

        if (oldItemIndex < itemIndex) {
          itemIndex--;
        }

        // insert active item
        if (oldItems) {
          oldItems.splice(itemIndex, 0, item);
          this.view.attributes.links = oldItems;
        }

        return this.resetDragDrop();
      }

      // item was stringified from add menu
      item = JSON.parse(newItem);
      const newItems = this.view.get('links').splice(0);

      newItems.splice(itemIndex, 0, item);
      this.view.attributes.links = newItems;

      return this.resetDragDrop();
    },
    resetDragDrop() {
      return log('reset!', this.view.get('links'));
    },
    routeToItems() {
      return this.$router.push(`${this.routePrefix}/links`);
    },
    activateItem(event) {
      if (isNil(event.target.nextElementSibling)) {
        return;
      }

      return event.target.nextElementSibling.querySelector('a').click();
    },
    onDeleteItem(event, itemIndex) {
      this.activeItemIndex = itemIndex;
      this.showConfirmDelete = true;
    },
    onConfirmDelete() {
      log(`onConfirmDelete, itemIndex: ${this.activeItemIndex}`);

      this.showConfirmDelete = false;
      this.view.get('links').splice(this.activeItemIndex, 1);
      this.routeToItems();
    },
  },
};
</script>

<style lang="scss">
.menu-links {
  .menu-links__nav {
    .menu-links__list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
    }
    .menu-links__list-item {
      display: inline-block;
      margin: 0 0 5px;
      &:not(:last-child) {
        margin-right: 5px;
      }

      .item-wrapper {
        margin: 0;
        font-size: inherit;
      }
    }
  }
}

// Styles specific to menus in tabs format
.menu-links--tabs .menu-links__nav .menu-links__list .menu-links__list-item {
  margin-right: 0;

  .knMenuLink--tab {
    border-radius: 0;
  }

  &:first-child .knMenuLink--tab {
    border-radius: .35em 0 0 .35em;
    &.knMenuLink--rounded {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
  }
  &:last-child .knMenuLink--tab {
    border-radius: 0 .35em .35em 0;
    &.knMenuLink--rounded {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }
  &:not(:last-child) .knMenuLink--tab {
    &.knMenuLink--filled {
      margin-right: 1px;
    }
    &.knMenuLink--outlined,
    &.knMenuLink--legacy {
      margin-right: -1px;
      &:hover {
        z-index: 1;
      }
      &.knMenuLink--border-thick {
        margin-right: -2px;
      }
    }
  }
}

// Styles specific to Menu view editing
.view {
  .menu-links__edit-drag-image {
    position: relative;
  }

  .menu-links__list-item {
    .knMenuLink {
      position: relative;
    }
    .item-links {
      left: -2px;
    }
  }

  .menu-links__list-item--has-child-link {
    .knMenuLink {
      padding-right: 50px;
      transition: none;
    }
    .view-child-link {
      transform: translateY(-50%);
    }
  }

  &.is-active .menu-links__list-item {
    &:hover {
      .item-links {
        opacity: 1;
        visibility: visible;
        border: 0;
      }
    }

    .item-wrapper .overlay {
      // Expand overlay by 1px on each side to cover extra whitespace between
      // menu link and edit links
      top: -1px;
      left: -1px;
      height: calc(100% + 2px);
      width: calc(100% + 2px);
    }

    .item-wrapper .router-link-active {
      box-shadow: none;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        outline: 1px solid $active;
        outline-offset: 2px;
      }
      &.knMenuLink--border-thick:after {
        outline-offset: 3px;
      }

      // The position of the active outline has to be sligthly modified to account
      // for the lack of top/left/right borders, and the thicker bottom border
      &.knMenuLink--underlined:after {
        outline-offset: 2px;
        top: 1px;
        left: 1px;
        bottom: -1px;
        right: 1px;
      }

      ~ .item-links {
        opacity: 1;
        visibility: visible;
        border: 0;
        background-color: $active;
        color: #fff;

        > * {
          color: #fff;
        }
      }

      ~ .overlay {
        // Expand overlay by 3px on each side to account for outline offset
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
      }
    }
  }
}

.isActiveMenuFromAddView * {
  cursor: none;
  pointer-events: none;
}

.kn-menu .kn-link.isNew {
  border: 1px solid $green !important;
}
</style>
