<template>
  <div>
    <GlobalLoading
      :is-loading="isLoading"
      :local="true"
    />
    <Chart
      v-if="hasReportData"
      ref="highchartsPreview"
      width="100%"
      :options="options"
      :highcharts="highchartsInstance"
    />
    <div
      v-else
      class="min-w-[58px]"
      width="100%"
    >
      {{ noDataMessage }}
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import isEmpty from 'lodash/isEmpty';
import hasIn from 'lodash/hasIn';
import get from 'lodash/get';
import { Chart as VueHighcharts } from 'highcharts-vue';
import highchartsExportDataInit from 'highcharts/modules/export-data';
import highchartsAccessibilityInit from 'highcharts/modules/accessibility';

import GlobalLoading from '@/components/ui/GlobalLoading';
import RequestUtils from '@/components/util/RequestUtils';
import { eventBus } from '@/store/bus';
import { formatNumber } from '@/util/FormatHelper';

highchartsExportDataInit(Highcharts);
highchartsAccessibilityInit(Highcharts);

export default {
  components: {
    GlobalLoading,
    Chart: VueHighcharts,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    view: {
      type: Object,
      default: () => {},
    },
    report: {
      type: Object,
      default: () => {},
    },
    reportData: {
      type: Object,
      default: () => {},
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    reportIndex: {
      type: Number,
      default: 0,
    },
    shouldShowDataTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      // Use v3 Highcharts dependency version instead of the dependency version
      // that comes with highcharts-vue library
      highchartsInstance: Highcharts,
    };
  },
  computed: {

    hasReportData() {
      if (!isEmpty(this.reportData) && !isEmpty(this.reportData.records)) {
        return true;
      }

      return false;
    },

    options() {
      if (!this.hasReportData) {
        return this.getEmptyOptions();
      }

      let options;

      switch (this.report.type) {
        case 'pie':

          options = this.getPieOptions(this.report, this.reportData.records, this.rowIndex);
          break;

        case 'bar':

          options = this.getBarOptions(this.report, this.reportData.records, this.reportData.filters);
          break;

        case 'line':

          options = this.getLineOptions(this.report, this.reportData.records, this.reportData.filters);
          break;

        case 'area':

          options = this.getLineOptions(this.report, this.reportData.records, this.reportData.filters);
          break;

        default:
          // Defaults are handled by the (!options) check below.
          break;
      }

      if (!options) {
        options = this.getEmptyOptions();
      }

      // Set some defaults. Removing animation reduces the thrashing that happens from options reacting
      // ToDo:: more abstraction of defaults that all the charts are setting
      options.chart.animation = false;

      // Remove animation for charts with plot options
      if (options.plotOptions?.series) {
        options.plotOptions.series.animation = false;
      }

      // Necessary to render data table in preview without a table title
      // Aligns with Live App
      options.exporting.tableCaption = false;

      return options;
    },
    noDataMessage() {
      let message = 'No data.';

      if (hasIn(this.reportData, 'max_data')) {
        message = 'This report has too many groupings to render efficiently.';

        if (this.reportData.filter_length) {
          message += ` The current count is ${this.reportData.filter_length}.`;
        }

        message += ' Try to add some filters so the number of groupings is below 3,000.';
      }

      return message;
    },
  },
  watch: {
    shouldShowDataTable: {
      handler() {
        this.handleRenderDataTable();
      },
    },
    reportData: {
      handler() {
        this.handleRenderDataTable();
      },
      deep: true,
    },
    hasReportData: {
      handler(newVal, oldVal) {
        if (!newVal && oldVal && this.shouldShowDataTable) {
          // want to remove the data table from the preview if a report is not being generated
          this.$refs.highchartsPreview?.chart?.hideData();
        }
      },
    },
  },
  created() {
    eventBus.$on(`reloadReportData.${this.view.key}`, ({ rowIndex, reportIndex }) => {
      if (this.rowIndex === Number(rowIndex) && this.reportIndex === Number(reportIndex)) {
        this.reloadReportData();
      }
    });
  },
  mounted() {
    this.handleRenderDataTable();
  },
  methods: {
    handleRenderDataTable() {
      if (this.shouldShowDataTable) {
        this.$nextTick(() => {
          // Render data table
          this.$refs.highchartsPreview?.chart?.viewData();
          // Prevent scrolling behavior which is caused by highcharts lib internally calling table.focus() when viewData is called
          const dataTableDiv = this.$refs.highchartsPreview?.$el.nextSibling;
          const table = dataTableDiv?.getElementsByTagName('table')[0];
          table.focus = null;
        });
      } else {
        // Hide data table
        this.$nextTick(() => this.$refs.highchartsPreview?.chart?.hideData());
      }
    },

    getEmptyOptions() {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: 'No data',
        },
        series: [
          {
            type: this.report.type,
            data: [],
          },
        ],
        colors: Highcharts.getOptions().colors,
      };
    },
    getPieOptions(report, records) {
      const _this = this;

      const { Knack } = window;

      const data = [];

      const filters = [];

      let seriesName;

      // grouped or not?
      if (report.groups[0].type === 'totals' || report.groups[0].type === 'averages') {
        let count = 0;

        for (const calc of report.calculations) {
          const label = calc.label || '-';

          const value = records[0] ? Knack.toNumber(records[0][`calc_${count}`]) : 0;

          data.unshift([
            label,
            value,
          ]);

          filters.unshift(records[0][`filters_${count}`]);

          count++;
        }

        seriesName = (report.groups[0].type === 'totals') ? 'Total' : 'Average';
      } else {
        for (const record of records) {
          const label = String(record.group_0) || '-';

          data.unshift([
            label,
            Knack.toNumber(record.calc_0),
          ]);

          filters.unshift(record.filters_0);
        }

          seriesName = report.calculations[0].label // eslint-disable-line
      }

      let layout = {
        chart_width: 300,
        legend_width: 170,
      };

      if (report.layout && report.layout.dimensions && report.layout.dimensions === 'custom') {
        layout = report.layout;
      }

        let chartWidth = Number(layout.chart_width) + 30 + Number(layout.legend_width) // eslint-disable-line

        let calcFormat = {} // eslint-disable-line

      const calc = report.calculations[0] ?? {};

      const field = this.$store.getters.getField(calc.field);

      if (calc.calculation !== 'count' && calc.field && field) {
        calcFormat = field.get('format');
      }

      // const exportLinks = (report.options && report.options.export_links)

      const dataLabels = (report.layout && typeof report.layout.data_labels !== 'undefined') ? report.layout.data_labels : true;

      const childRecords = (report.options && report.options.child_records);

      // check for additional colors
      const colors = this.generateAdditionalColors(data.length);

      const legendTitle = report.groups[0].label;
      const legend = this.setLegendProperties('pie', legendTitle, report.layout);

      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          width: chartWidth,
          height: layout.chart_height,
          events: {
            aftergetTableAST(e) {
              // Formatting data table cells according to their field settings.
              // Pie chart reports only have one grouping and one calculation at most so
              // there is no need to leverage `formatDataTableCells` method here.
              e.tree.children[1].children.forEach((row) => {
                row.children.forEach((cell, i) => {
                  if (i !== 0) {
                    row.children[i].textContent = String(formatNumber(cell.textContent, calcFormat));
                  }
                });
              });
            },
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          csv: {
            columnHeaderFormatter(item) {
              // Apply appropriate data labels to data table columm headers.
              // Necessary because by default Highcharts applies generic labels to column headers.
              // Pie chart reports only have one grouping and one calculation at most so
              // there is no need to leverage `getFormattedDataTableColumnHeaders` method here.
              if (!item || item instanceof Highcharts.Axis) {
                return legendTitle;
              }
              return item.name;
            },
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tooltip: {
          formatter() {
            return `${this.point.name} - ${this.series.name}<br /><b>${String(formatNumber(this.y, calcFormat)).replace('&nbsp;', ' ')}</b> (${Math.round(this.percentage * 100) / 100}%)`;
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: dataLabels,
              formatter() {
                return `${Math.round(this.percentage * 100) / 100} %`;
              },
              distance: -35,
              style: {
                fontWeight: 'bold',
                color: 'white',
                textShadow: 'none',
              },
            },
            events: {
              click(event) {
                if (!report.preview && childRecords) {
                  _this.expandGroupRecords(report, filters[event.point.index]);
                }
              },
            },
          },
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              formatter() {
                return `${Math.round(this.percentage * 100) / 100} %`;
              },
              filter: {
                property: 'percentage',
                operator: '>',
                value: 4,
              },
              style: {
                textOutline: 'none',
              },
            },
            showInLegend: true,
          },
        },
        legend,
        series: [
          {
            type: 'pie',
            name: seriesName,
            data,
          },
        ],
        colors,
      };
    },
    getBarOptions(report, records, groupFilters) {
      const _this = this;

      const { Knack } = window;

      let minNumber = 0;

      const categories = [];

      const filters = [];

      if (groupFilters && groupFilters[0]) {
        for (const filter of groupFilters[0]) {
          let categoryName = '-';

          if (filter.header) {
            categoryName = filter.header;
          }

          categories.push(categoryName);
        }
      }

      // const isFieldGroups = (report.groups.length === 1 && report.groups[0].type === 'totals')

      // data series

      const series = [];

      // let g = 0

      let col = 0;

      let rows;

      if (report.groups.length > 1 && report.calculations.length) {
        rows = _this.buildRowData(report, records, groupFilters, filters, minNumber);

        for (const row of rows) {
          series.push(row);
        }
      } else {
        for (const calculation of report.calculations) {
          const calcName = calculation.label || '-';

          const row = {
            name: calcName,
            data: [],
            calculation: calculation.calculation,
            filters: calculation.filters,
            index: col,
          };

          if (calculation.field) {
            row.field = calculation.field;
          }

            let g = 0 // eslint-disable-line

          for (const record of records) {
            filters.push(record[`filters_${col}`]);

            const number = Knack.toNumber(record[`calc_${col}`]);

            if (number < minNumber) {
              minNumber = number;
            }

            row.data.push(number);

            g++;
          }

          series.push(row);

          col++;
        }
      }

      if (!report.layout) {
        report.layout = {};
      }

      let calcFormat = {};

      const calc = report.calculations[0] ?? {};

      const field = this.$store.getters.getField(calc.field);

      if (calc.calculation !== 'count' && calc.field && field) {
        calcFormat = field.get('format');
      }

      // const exportLinks = (report.options && report.options.export_links) ? true : false

      const barType = (report.layout.bar_type) ? report.layout.bar_type : 'bar';

      const dataLabels = (report.layout && typeof report.layout.data_labels !== 'undefined') ? report.layout.data_labels : true;

      const childRecords = (report.options && report.options.child_records);

      const hideNegatives = (report.options && report.options.hide_negatives);

      if (hideNegatives) {
        minNumber = 0;
      }

      const xLabelRotation = (barType === 'column' && report.layout.tilt_labels) ? -45 : 0;

      const yLabelRotation = (barType === 'bar' && report.layout.tilt_labels) ? -45 : 0;

      const firstGroupingField = get(report, 'groups.0.label', '');

      // Chart Width and Height
      let layout = {
        chart_width: 500,
        chart_height: 350,
        legend_width: 170,
      };

      if (report.layout && report.layout.dimensions && report.layout.dimensions === 'custom') {
        layout = _.extend(layout, report.layout);
      } else {
        // auto size
        if (barType === 'bar') {
          let barLength = 0;

          for (const cat of categories) {
            if (cat && cat.length && cat.length > 0) {
              barLength = cat.length;
            }
          }

          let lineHeight = Math.ceil(barLength / 22) * 10;

          if (lineHeight < 12) {
            lineHeight = 12;
          }

          const chartHeight = records.length * lineHeight * (col + 1) + 120; // 20px per calculation per data item plus top and bottom margins

          if (chartHeight > layout.chart_height) {
            layout.chart_height = chartHeight;
          }
        } else {

          // layout.chart_width = series.length * 40 * (c+1) + 120 // 20px per calculation per data item plus top and bottom margins

        }
      }

      const legend = this.setLegendProperties('bar', '', report.layout);

      if (report.groups.length > 1) {
        legend.reversed = false;
      }

        let chartWidth = Number(layout.chart_width) // eslint-disable-line

      if (legend.align === 'right') {
        chartWidth += 150;
      }

      // overlap
      const allowOverlap = (barType === 'bar');

      // check for additional colors
      const colors = this.generateAdditionalColors(series.length);

      // highchart
      return {
        chart: {
          type: barType,
          width: chartWidth,
          height: layout.chart_height,
          events: {
            aftergetTableAST(e) {
              const formatDataTableCellsArgs = {
                e,
                series: this.series,
                calcFormat,
              };

              // Formatting data table cells according to their field settings.
              _this.formatDataTableCells(formatDataTableCellsArgs);
            },
          },
        },
        series: series.map((item, i) => ({ ...item, color: colors[i] })), // @TODO extract to function where I can comment better
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          csv: {
            columnHeaderFormatter(item, key) {
              // Apply appropriate data labels to data table columm headers.
              // Necessary because by default Highcharts applies generic labels to column headers. 
              return _this.getFormattedDataTableColumnHeaders(item, key);
            },
          },
        },
        title: {
          text: null,
        },
        legend,
        xAxis: {
          categories,
          title: {
            text: firstGroupingField,
            style: {
              'font-style': 'italic',
              color: '#888',
            },
          },
          labels: {
            overflow: 'justify',
            rotation: xLabelRotation,
          },
        },
        yAxis: {
          min: minNumber,
          title: {
            text: null,
            style: {
              'font-style': 'italic',
              color: '#888',
            },
          },
          labels: {
            overflow: 'justify',
            rotation: yLabelRotation,
            formatter() {
              const format = (field && field.get('format')) ? field.get('format') : {};

              if (field && field.get('type') === 'currency') {
                format.precision = 0;
                format.mark_thousands = 'comma';
              }

              return String(formatNumber(this.value, format)).replace('&nbsp;', ' ');
            },
          },
        },
        tooltip: {
          formatter() {
            const field = _this.$store.getters.getField(this.series.userOptions.field) || _this.$store.getters.getField(calc.field);

            const format = (field && field.get('format')) ? field.get('format') : {};

            if (this.series.userOptions.calculation === 'average') {
              format.precision = 2;
            }

            if (field && field.get('type') === 'currency') {
              format.precision = 2;
              format.mark_thousands = 'comma';
            }

            return `${this.key} - ${this.series.name}<br /><b>${String(formatNumber(this.y, format)).replace('&nbsp;', ' ')}</b>`;
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              allowOverlap,
              enabled: dataLabels,
              formatter() {
                const field = this.series.userOptions.field === 'count' ? null : _this.$store.getters.getField(this.series.userOptions.field) || _this.$store.getters.getField(calc.field);

                const format = (field && field.get('format')) ? field.get('format') : {};

                if (this.series.userOptions.calculation === 'average') {
                  format.precision = 2;
                }

                if (field && field.get('type') === 'currency') {
                  format.precision = 2;
                  format.mark_thousands = 'comma';
                }

                if (!this.y && report.options && report.options.exclude_empties) {
                  return null;
                }

                return String(formatNumber(this.y, format)).replace('&nbsp;', ' ');
              },
              style: {
                textShadow: 'none',
              },
            },
            events: {
              click(event) {
                const rows = []; // TODO: figure out where rows is being defined

                if (rows && rows.length) {
                  const row = rows[event.point.series.options.index];

                  const filter = filters[row.filter_indexes[event.point.index]];

                  if (!report.preview && childRecords) {
                    _this.expandGroupRecords(report, filter);
                  }
                } else {
                  const index = records.length * event.point.series.userOptions.index + event.point.index;

                  const filtersClone = _.clone(filters[index]);

                  if (!report.preview && childRecords) {
                    _this.expandGroupRecords(report, filtersClone);
                  }
                }
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
        colors,
      };
    },
    getLineOptions(report, records, groupFilters) {
      const _this = this;

      const { Knack } = window;

      let minNumber = 0;

      let labelWidth = 0;

      // categories
      const categories = [];

      const filters = [];

      if (!groupFilters || !groupFilters[0]) {
        return;
      }

      for (const filter of groupFilters[0]) {
        let name = '-';

        if (filter.header) {
          name = filter.header;
        }

        if (name.length > labelWidth) {
          labelWidth = name.length;
        }

        categories.push(name);
      }

      // const isFieldGroups = (report.groups.length === 1 && report.groups[0].type === 'totals')

      // data series

      const series = [];

      // const g = 0

      let col = 0;

      if (report.groups.length > 1 && report.calculations.length) {
        const rows = _this.buildRowData(report, records, groupFilters, filters, minNumber);

        for (const row of rows) {
          series.push(row);
        }
      } else {
        for (const calc of report.calculations) {
          const name = calc.label || '-';

          const row = {
            name,
            data: [],
            calculation: calc.calculation,
            filters: calc.filters,
            index: col,
          };

          if (calc.field) {
            row.field = calc.field;
          }

          let g = 0 // eslint-disable-line

          for (const record of records) {
            filters.push(record[`filters_${col}`]);

            const number = Knack.toNumber(record[`calc_${col}`]);

            if (number < minNumber) {
              minNumber = number;
            }

            row.data.push(number);

            g++;
          }

          series.push(row);

          col++;
        }
      }

      if (!report.layout) {
        report.layout = {};
      }

      let calcFormat = {};

      const calc = report.calculations[0] ?? {};

      const field = this.$store.getters.getField(calc.field);

      if (calc.calculation !== 'count' && calc.field && field) {
        calcFormat = field.get('format');
      }

      // const exportLinks = (report.options && report.options.export_links)

      // const barType = (report.layout.bar_type) ? report.layout.bar_type : 'bar'

      const dataLabels = (report.layout && typeof report.layout.data_labels !== 'undefined') ? report.layout.data_labels : true;

      const childRecords = (report.options && report.options.child_records);

      const hideNegatives = (report.options && report.options.hide_negatives);

      if (hideNegatives) {
        minNumber = 0;
      }

      const xLabelRotation = (report.layout.tilt_labels) ? -45 : 0;

      const yLabelRotation = 0;

      // Chart Width and Height
      let layout = {
        chart_width: 500,
        chart_height: 350,
        legend_width: 170,
      };

      let chartWidth;

      if (report.layout && report.layout.dimensions && report.layout.dimensions === 'custom') {
        layout = _.extend(layout, report.layout);
      } else {
        chartWidth = labelWidth * 7 * categories.length + 50; // 3px per character per group plus margins

        if (chartWidth > layout.chart_width) {

          // layout.chart_width = chartWidth
        }
      }

      const legend = this.setLegendProperties('bar', '', report.layout);

      chartWidth = Number(layout.chart_width);// + 30 + Number(layout.legend_width)

      if (legend.align === 'right') {
        chartWidth += (Number(layout.legend_width) + 20);
      }

      const stacking = (report.layout && report.layout.stacking && report.layout.stacking !== 'none') ? report.layout.stacking : null;

      // check for additional colors
      const colors = this.generateAdditionalColors(series.length);

      return {
        chart: {
          type: report.type,
          spacingRight: 60,
          width: chartWidth,
          height: layout.chart_height,
          events: {
            aftergetTableAST(e) {
              const formatDataTableCellsArgs = {
                e,
                series: this.series,
                calcFormat,
              };

              // Formatting data table cells according to their field settings.
              _this.formatDataTableCells(formatDataTableCellsArgs);
            },
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          csv: {
            columnHeaderFormatter(item, key) {
              // Apply appropriate data labels to data table columm headers.
              // Necessary because by default Highcharts applies generic labels to column headers.
              return _this.getFormattedDataTableColumnHeaders(item, key);
            },
          },
        },
        title: {
          text: null,
        },
        legend,
        xAxis: {
          categories,
          title: {
            text: null,
          },
          labels: {
            rotation: xLabelRotation,
          },
        },
        yAxis: {
          min: minNumber,
          title: {
            text: null,
          },
          labels: {
            overflow: 'justify',
            rotation: yLabelRotation,
            formatter() {
              const format = (field && field.get('format')) ? field.get('format') : {};

              if (field && field.get('type') === 'currency') {
                format.precision = 0;
                format.mark_thousands = 'comma';
              }

              return String(formatNumber(this.value, format)).replace('&nbsp;', ' ');
            },
          },
        },
        tooltip: {
          formatter() {
            const field = _this.$store.getters.getField(this.series.userOptions.field) || _this.$store.getters.getField(calc.field);

            const format = (field && field.get('format')) ? field.get('format') : {};

            if (this.series.userOptions.calculation === 'average') {
              format.precision = 2;
            }

            if (field && field.get('type') === 'currency') {
              format.precision = 2;
              format.mark_thousands = 'comma';
            }

            return `${this.key} - ${this.series.name}<br /><b>${String(formatNumber(this.y, format)).replace('&nbsp;', ' ')}</b>`;
          },
        },
        plotOptions: {
          series: {
            stacking,
            dataLabels: {
              enabled: dataLabels,
              formatter() {
                const field = _this.$store.getters.getField(this.series.userOptions.field) || _this.$store.getters.getField(calc.field);

                const format = (field && field.get('format')) ? field.get('format') : {};

                if (this.series.userOptions.calculation === 'average') {
                  format.precision = 2;
                }

                if (field && field.get('type') === 'currency') {
                  format.precision = 2;

                  format.mark_thousands = 'comma';
                }

                return String(formatNumber(this.y, format)).replace('&nbsp;', ' ');
              },
            },
            events: {
              click(event) {
                let rows; // TODO: figure out where rows is being defined

                if (rows && rows.length) {
                  const row = rows[event.point.series.options.index];

                  const filter = filters[row.filter_indexes[event.point.index]];

                  if (!report.preview && childRecords) {
                    _this.expandGroupRecords(report, filter);
                  }
                } else {
                  const index = records.length * event.point.series.userOptions.index + event.point.index;

                  const filtersClone = _.clone(filters[index]);

                  if (!report.preview && childRecords) {
                    _this.expandGroupRecords(report, filtersClone);
                  }
                }
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
        series: series.map((item, i) => ({ ...item, color: colors[i] })),
        colors,
      };
    },
    buildRowData(report, records, groupFilters, filters = [], minNumber = 0) {
      const { Knack } = window;

      const rows = [];

      let index = 0;

      if (!groupFilters) {
        return;
      }

      log('groupFilters', typeof groupFilters[1], groupFilters);

      if (groupFilters[1]) {
        groupFilters[1].forEach((filter) => {
          rows.push({
            name: filter.header,
            data: [],
            filter_indexes: [],
            calculation: report.calculations[0].calculation,
            filters: report.calculations[0].filters,
            index: index++,
          });
        });
      }

      let groups = [];

      const tempData = [];

      if (rows.length) {
        for (const record of records) {
          filters.push(record.filters_0);

          const number = Knack.toNumber(record.calc_0);

          if (number < minNumber) {
            minNumber = number;
          }

          // push group_0 to list of groups so we can ensure each row has data for each group
          groups.push(record.group_0);

          tempData.push({
            calc: number,
            group: record.group_0,
            secondary_group: record.group_1,
            filter_index: filters.length - 1,
          });
        }
      }

      groups = _.uniq(groups, (group) => group);

      // move temp data into row.data so the rows can be used in a highcharts series
      for (const row of rows) {
        for (const group of groups) {
          const dataPoint = _.find(tempData, (tempData) => tempData.group === group && tempData.secondary_group === row.name);

          // if no data exists for this row and grouping, insert a null to ensure data alignment between the series
          if (!dataPoint) {
            row.data.push(null);

            row.filter_indexes.push(null);
          } else {
            row.data.push({
              y: dataPoint.calc,
            });

            row.filter_indexes.push(dataPoint.filter_index);
          }
        }
      }

      return rows;
    },
    setLegendProperties(type, title, layout) {
      let legend = (layout && layout.legend) ? layout.legend : false;

      if (legend === false) {
        legend = (type === 'pie') ? 'right' : 'bottom';
      }

      if (legend === 'none') {
        return {
          enabled: false,
        };
      }

      if (legend === 'right') {
        const legendWidth = (layout && layout.legend_width) ? Number(layout.legend_width) : 170;

        return {
          enabled: true,
          layout: 'vertical',
          align: 'right',
          width: legendWidth,
          verticalAlign: 'top',
          itemStyle: {
            width: legendWidth - 15,
            color: '#555',
            cursor: 'pointer',
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '13px',
          },
          itemMarginBottom: 4,
          itemMarginTop: 2,
          title: {
            text: title,
          },
          reversed: type === 'bar', // does this need to be a tertiary and set to undefined
        };
      }

      return {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
      };
    },
    generateAdditionalColors(seriesLength) {
      if (seriesLength <= 10) {
        return Highcharts.getOptions().colors;
      }

      const add = seriesLength - 10;

      const colorsArr = Highcharts.getOptions().colors.slice();

      for (let i = 1; i <= add; i++) { // eslint-disable-line id-length
        colorsArr.push(this.generateColor(i));
      }

      return colorsArr;
    },
    generateColor(index) {
      const value = index % 255;

      const standard = parseInt((value + 150) / 2) + (index * 3 % 255);

      const alt = parseInt((255 - value + 150) / 2) - (index * 3 % 255);

      switch (index % 8) {
        case 0:

          return this.rgbToHex(standard, standard, standard);

        case 7:

          return this.rgbToHex(standard, standard, alt);

        case 6:

          return this.rgbToHex(standard, alt, alt);

        case 5:

          return this.rgbToHex(alt, alt, alt);

        case 4:

          return this.rgbToHex(alt, alt, standard);

        case 3:

          return this.rgbToHex(alt, standard, standard);

        case 2:

          return this.rgbToHex(standard, standard, alt);
        default:

          return this.rgbToHex(alt, standard, alt);
      }
    },
    rgbToHex(red, green, blue) {
      return `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}`;
    },
    reloadReportData() {
      log(`report ${this.rowIndex}-${this.reportIndex} is reloadReportData()`);

      this.isLoading = true;

      return this.commitRequest({
        request: () => this.view.loadIndividualReportData(this.rowIndex, this.reportIndex),
        globalLoading: false,
        onSuccess: () => {
          this.isLoading = false;
        },
      });
    },
    getFormattedDataTableColumnHeaders(item, key) {
      if (!item || item instanceof Highcharts.Axis) {
        return this.report.groups[0]?.label ?? ''
      }

      if (this.report.groups.length > 1) {
        // More than one data group, want to customize column headers to better understand data.
        // At most, there are two groups in a report so we can assume the following code will suffice for our needs.
        return {
          topLevelColumnTitle: this.report.groups[1].label,
          columnTitle: key === 'y' ? item.name : key,
        };
      }

      return item.name;
    },
    formatDataTableCells(formatDataTableCellsParams) {
      const {
        e,
        series,
        calcFormat,
      } = formatDataTableCellsParams;

      // Formatting data table cells according to their field settings.
      e.tree.children[1].children.forEach((row) => {
        row.children.forEach((cell, i) => {
          if (i !== 0) {
            let calculationFieldFormat = {};

            if (this.report.groups.length > 1) {
              // If there are more than one report groupings, there can only be one report calculation.
              // Format data table cells according to their corresponding calculation field setting.
              calculationFieldFormat = calcFormat;
            } else {
              // There can potentially be more than one report calculation.
              // Need to render each data table cell in the correct format (according to its calculation field format).
              // Series are constructed per report and are what the report renders.
              // Need to get the field from the constructed `series` and use it to
              // get the corresponding field format and
              // retrieve the corresponding report calculation for the data table cell
              // to format the data table cell accordingly.
              const calculationField = series[i - 1].options.field;
              const calculationObjField = this.$store.getters.getField(calculationField);
              const calculationObj = this.report.calculations.find(
                (calculation) => calculation.field === calculationField,
              );

              if (calculationObj.calculation !== 'count' && calculationObj.field && calculationField) {
                calculationFieldFormat = calculationObjField?.format;
              }
            }

            row.children[i].textContent = String(formatNumber(cell.textContent, calculationFieldFormat));
          }
        });
      });
    },
  },
};
</script>
