<template>
  <SortableItems
    v-if="!listIsEmpty"
    :class="listClasses"
    :items="items"
    :can-sort="canSort"
    @sort="$emit(`sort`, items)"
  >
    <template #default="{item, itemIndex}">
      <li
        class="mb-2 items-center last:mb-0"
        :class="actionListItemClasses"
      >
        <slot v-bind="slotOptions(item, items, itemIndex)" />

        <div
          v-if="actionTrigger === 'buttons' && !hideButtons"
          class="actions p-0 ml-2 self-baseline mt-3"
        >
          <a
            v-if="items.length > 1 || canBeEmpty"
            class="delete-link-button mr-2"
            @click="onDeleteItem(itemIndex)"
          >
            <HoverIcon
              class="text-default h-4 w-4"
              type="close"
              hover-type="close"
            />
          </a>
          <a
            v-tippy="{ allowHTML: true }"
            :content="(addIsDisabled && addDisabledMessage) ? addDisabledMessage : ''"
            class="add-link-button"
            :class="{'is-disabled': addIsDisabled}"
            data-cy="add-link-button"
            @click="onAddItem"
          >
            <HoverIcon
              class="text-default h-4 w-4"
              type="add"
              hover-type="add"
            />
          </a>
        </div>

        <div v-if="actionTrigger === 'dropdown'">
          <Popover ref="popover">
            <template #trigger>
              <a class="button-square settings">
                <Icon type="more-horiz" />
              </a>
            </template>
            <template #content>
              <div class="links">
                <slot
                  :item="item"
                  name="links"
                />
                <a
                  v-if="items.length > 1 || canBeEmpty"
                  class="delete-link-button"
                  @click="onDeleteItem(itemIndex)"
                >
                  <Icon type="delete" /> Delete Item
                </a>
                <a
                  class="add-action-item"
                  @click="onAddItem"
                >
                  <Icon type="add-circle-outline" /> Add Item
                </a>
              </div>
            </template>
          </Popover>
        </div>
      </li>
    </template>
  </SortableItems>
  <div
    v-else-if="listIsEmpty && emptyText"
    class="flex"
  >
    <a
      class="inline-flex items-center h-10 w-fit p-3 rounded-lg border border-solid border-default bg-white leading-4 text-emphasis text-base no-underline font-medium capitalize group flex justify-center hover:bg-brand-50 hover:border-brand-600"
      @click="onAddItem"
    >
      <Icon
        class="text-default h-4 w-4 mr-2 group-hover:text-brand-400"
        type="plus-thin"
      />
      {{ emptyText }}
    </a>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';

import HoverIcon from '@/components/ui/HoverIcon';
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';
import SortableItems from '@/components/ui/lists/SortableItems';

export default {
  components: {
    HoverIcon,
    Icon,
    Popover,
    SortableItems,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    canBeEmpty: {
      type: Boolean,
      default: false,
    },
    startWithDefault: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: () => '',
    },
    actionTrigger: {
      type: String,
      default: 'buttons', // buttons | dropdown
    },
    nested: {
      type: Boolean,
      default: false,
    },
    scopeName: {
      type: String,
      default: 'item',
    },
    defaultItem: {
      type: Function,
      default: () => ({
        field: null,
        operator: null,
        value: null,
      }),
    },
    addIsDisabled: {
      type: Boolean,
      default: false,
    },
    addDisabledMessage: {
      type: String,
      default: '',
    },
    canSort: {
      type: Boolean,
      default: false,
    },
    sortDelay: {
      type: Number,
      default: 200,
    },
    actionListItemClasses: {
      type: String,
      default: '',
    },
  },
  emits: [
    'delete:item',
    'sort',
    'update:items',
  ],
  computed: {
    listIsEmpty() {
      return isEmpty(this.items);
    },
    listClasses() {
      return {
        'base-list': true,
        'action-list': true,
        'list--nested': this.nested,
      };
    },
  },
  created() {
    if (this.startWithDefault && isEmpty(this.items)) {
      // Make sure there is an item if the list can not be empty.
      this.$emit('update:items', [
        this.defaultItem(),
      ]);
    }
  },
  methods: {
    onAddItem() {
      if (this.addIsDisabled) {
        return;
      }

      const newItemsList = [
        ...this.items,
        this.defaultItem(),
      ];

      this.$emit('update:items', newItemsList);
    },
    onDeleteItem(itemIndex) {
      if (!this.canBeEmpty && this.items.length <= 1) {
        return;
      }

      const newItemsList = [
        ...this.items,
      ];

      newItemsList.splice(itemIndex, 1);

      this.$emit('update:items', newItemsList);
      this.$emit('delete:item');
    },
    slotOptions(item, items, itemIndex) {
      return {
        [`${this.scopeName}`]: item,
        [`${this.scopeName}s`]: items,
        [`${this.scopeName}Index`]: itemIndex,
        [`${this.scopeName}Key`]: uuid(),
      };
    },
  },
};
</script>

<style lang="scss">
.action-list li {
  display: flex;
  align-items: top;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: flex-start;
    min-width: 0;

    & > input,
    & .input,
    & > select {
      margin-right: .25rem;
      min-width: 0;
      flex: 1;
    }

    & > span {
      font-size: 1em;
      line-height: 2.2em;
      min-width: min-content;
    }
  }

  .actions {
    display: flex;
    max-width: 44px;
    padding: 8px 0 0 8px;
    text-align: right;
    min-width: min-content;

    a {
      margin-left: 0;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .delete-link-button {
    color: $error500;
    margin-right: .25rem;
  }
  .add-link-button {
    color: $add500;

    &.is-disabled {
      opacity: 0.75;
      color: #aaa;
    }
  }
}

.action-list.hide-delete .delete-link-button {
  display: none;
}
.action-list.hide-add .add-link-button {
  display: none;
}
</style>
