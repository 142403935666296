<template>
  <nav
    data-cy="nav-app-options"
    class="appOptions"
  >
    <Popover
      allow-vertical-scroll
      placement="bottom-start"
    >
      <template #trigger>
        <button
          v-tippy
          content="More options"
          type="button"
          class="buttonSquare-outline appOptions_trigger hover:bg-brand-50 bg-transparent border-0 rounded-lg h-9 w-9 p-0 flex justify-center items-center"
          data-cy="app-options-open"
        >
          <Icon
            type="chevron-single"
            class="appOptions_icon h-6 w-6 text-default"
          />
        </button>
      </template>
      <template #content>
        <div class="links">
          <template v-if="firstPageSlug">
            <a
              v-close-popper
              target="_blank"
              :href="getActivePageLiveAppLink"
              :class="linkItemClasses"
              data-cy="app-option-go-to-live-app"
            >
              <Icon
                class="text-inherit"
                type="open-in-new"
              /> Go to Live App
            </a>
            <button
              v-clipboard:copy="getActivePageLiveAppLink"
              v-close-popper
              target="_blank"
              type="button"
              class="link"
              :class="linkItemClasses"
              data-cy="app-option-copy-live-app-url"
            >
              <Icon
                class="text-inherit"
                type="link"
              /> Copy Live App URL
            </button>
          </template>
          <a
            v-close-popper
            :href="appDashboardUrl"
            :class="linkItemClasses"
          >
            <Icon
              class="text-inherit"
              type="knack"
            /> Go to Account Dashboard
          </a>
          <hr class="kn-popover_divider mx-2">
          <h1 class="kn-popover_heading text-subtle capitalize px-2 mt-0">
            Settings
          </h1>
          <router-link
            v-for="link in filteredSettingsLinks"
            :key="link.link"
            v-close-popper
            :to="link.link"
            :class="linkItemClasses"
          >
            <Icon
              class="text-inherit"
              :type="link.icon"
            />
            {{ link.text }}
          </router-link>
        </div>
      </template>
    </Popover>
  </nav>
</template>

<script>
import LiveAppMixin from '@/components/builder/menus/LiveAppMixin';
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';
import { INTEGRATIONS } from '@/constants/featureflags';

const MENU_LINKS = [
  {
    icon: 'app-settings',
    text: 'App Settings',
    link: '/settings/app',
  },
  {
    icon: 'live-app-design',
    text: 'Live App Design',
    link: '/settings/design',
  },
  {
    icon: 'lock-outline',
    text: 'User Logins',
    link: '/settings/users',
  },
  {
    icon: 'credit-cards',
    text: 'E-Commerce',
    link: '/settings/ecommerce',
  },
  {
    icon: 'api-code',
    text: 'API & Code',
    link: '/settings/api',
  },
  {
    icon: 'embed',
    text: 'Embed',
    link: '/settings/embeds',
  },
  {
    icon: 'puzzle-piece-solid',
    text: 'Integrations',
    link: '/settings/integrations',
  },
];

export default {
  name: 'AppOptionsMenu',
  components: {
    Icon,
    Popover,
  },
  mixins: [
    LiveAppMixin,
  ],
  data() {
    return {
      settingsLinks: MENU_LINKS,
      appDashboardUrl: process.env.VUE_APP_DASHBOARD_URL || '/',
    };
  },
  computed: {
    filteredSettingsLinks() {
      return this.settingsLinks.filter((link) => (link.link === '/settings/integrations' ? this.$store.getters.hasFeature(INTEGRATIONS) : true));
    },
    linkItemClasses() {
      return 'rounded hover:bg-brand-50 text-base text-default hover:text-emphasis';
    },
  },
};
</script>

<style lang="scss">
.buttonSquare-outline.appOptions_trigger {
  @include button-app-options;

  &:hover,
  &:focus {
    background-color: $primary800;
  }

  .appOptions_icon {
    @include icon-18;
    color: $white100;
  }
}

.appOptions .v-popover {
  height: 20px;
}

.v-popover.open .appOptions_trigger {
  background-color: rgba(54, 14, 50, 0.8);
}
</style>
