<template>
  <FormWrapper
    :errors="validation.errors"
    class="help-form"
    @submit="onSubmit"
  >
    <p
      v-if="isFailure && !isInvalid"
      class="error-alert form-errors helpFormError"
    >
      <strong>One or more errors occurred:</strong>
      <ul>
        <li
          v-for="(error, errorIndex) in errors"
          :key="errorIndex"
          v-html="error.message || error"
        />
      </ul>
    </p>

    <div v-else-if="isPending">
      <IconLoading />
    </div>

    <div v-else-if="isSuccess">
      <div class="help-form-success">
        Your request has been submitted.
      </div>
    </div>

    <div
      v-else
      class="drawerForm"
    >
      <slot />
    </div>

    <footer class="drawerForm_footer flex justify-end">
      <div
        v-if="isReady || isInvalid"
        class="flex gap-2"
      >
        <BuilderButton
          theme="confirm"
          class="help-form-submit margin-right-sm p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium order-2 h-10 mr-0"
          :disabled="isPending"
          @click="onSubmit"
        >
          Submit
        </BuilderButton>
        <BuilderButton
          theme="cancel"
          class="help-form-cancel p-3 rounded-lg bg-transparent border-0 text-emphasis font-medium m-0 leading-4 order-1 h-10"
          @click="onClear"
        >
          Clear Form
        </BuilderButton>
      </div>

      <div v-else-if="isFailure && !isInvalid">
        <BuilderButton
          theme="confirm"
          @click="onDismissErrors"
        >
          Back To Form
        </BuilderButton>
      </div>

      <div v-else-if="isSuccess">
        <BuilderButton
          theme="confirm"
          @click="onDismissSuccess"
        >
          Back To Home
        </BuilderButton>
      </div>
    </footer>
  </FormWrapper>
</template>

<script>
import IconLoading from '@/components/builder/icons/IconLoading';
import BuilderButton from '@/components/ui/BuilderButton';
import FormWrapper from '@/components/ui/FormWrapper';

export default {
  name: 'HelpForm',
  components: {
    BuilderButton,
    FormWrapper,
    IconLoading,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    storeNamespace: {
      type: String,
      required: true,
    },
  },
  emits: [
    'clear',
    'dismissErrors',
    'dismissSuccess',
    'submit',
  ],
  computed: {
    errors() {
      return this.$store.getters[`${this.storeNamespace}/errors`];
    },
    isFailure() {
      return this.$store.getters[`${this.storeNamespace}/isFailure`];
    },
    isInvalid() {
      return this.$store.getters[`${this.storeNamespace}/isInvalid`];
    },
    isPending() {
      return this.$store.getters[`${this.storeNamespace}/isPending`];
    },
    isReady() {
      return this.$store.getters[`${this.storeNamespace}/isReady`];
    },
    isSuccess() {
      return this.$store.getters[`${this.storeNamespace}/isSuccess`];
    },
    validation() {
      return this.$store.getters[`${this.storeNamespace}/validation`];
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit');
    },
    onClear() {
      this.$emit('clear');
    },
    onDismissErrors() {
      this.$emit('dismissErrors');
    },
    onDismissSuccess() {
      this.$emit('dismissSuccess');
    },
  },
};
</script>

<style lang="scss">
.help-form {
  .form-errors {
    margin-bottom: 0;
  }

  .input-labels label {
    font-weight: 400;
  }

  .help-description {
    color: #616668;
    font-size: .875rem;
    margin-bottom: .5rem;
    line-height: 1.43;
    text-align: left;
  }

  .margin-0 {
    margin: 0;
  }

  .emphasis {
    font-style: italic;
  }
}

.help-form-success {
  font-size: 1.25em;
  font-weight: 500;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
}

.drawerForm_footer {
  display: flex;
  padding-bottom: 2rem;
}
</style>
