<template>
  <RouterView
    name="modal"
    :base-url="`/connections/objects/${object?.key}`"
  />
  <div
    class="flex gap-6 px-6"
    v-bind="$attrs"
  >
    <div class="w-[500px] bg-muted rounded-lg px-4 py-3 max-h-fit">
      <div class="flex items-center justify-between mb-4">
        <div class="font-medium text-emphasis">
          <span class="text-gradient-primary">Start Here:</span> <b>Build a Connected Database</b>
        </div>
      </div>
      <div class="text-sm">
        Connect your tables to easily pull in related values across them and keep data synced, showing users just what
        matters to them. Escape the Excel chaos. Connections are your “VLookups” and ”SQL Joins” made easier.
      </div>
      <div class="mt-3">
        <div class="wistia-container">
          <iframe
            src="https://fast.wistia.net/embed/iframe/kfqlz3wams"
            allow="autoplay; fullscreen"
            class="w-full"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!object?.conns.length"
      class="body-padding-full py-0 flex-1 max-w-[650px]"
    >
      <div class="flex flex-col items-center mt-12">
        <Icon
          class="h-12 w-12 text-muted mb-6"
          type="disconnect"
        />
        <p class="text-emphasis font-semibold text-center mb-4">
          Create a connection
        </p>
        <a
          href="https://learn.knack.com/article/do520qb4jz-about-connections"
          target="_blank"
          class="text-default underline text-center"
        >
          Learn more about connections<span class="relative">
            <Icon
              class="h-4 w-4 text-emphasis ml-2 absolute bottom-[1px]"
              type="arrow-top-right-on-square"
            />
          </span>
        </a>
      </div>
    </div>
    <div
      v-else
      class="flex-1 max-w-[680px] rounded-lg bg-muted px-4 py-3 max-h-[680px] overflow-y-auto"
    >
      <div
        id="connections-list-wrapper"
        class="body-padding-full px-0 py-0 -mb-4"
      >
        <div class="font-medium text-emphasis">
          {{ object?.inflections.singular }}'s connections
        </div>

        <div
          id="connection-objects"
          class="margin-bottom mt-2"
        >
          <div
            v-for="(conns, connObject) in connectionsByObject(object?.conns)"
            :key="`${object?.key}-${connObject}`"
            class="connection-object"
          >
            <div class="expand justify-between mb-2">
              <div class="object truncate">
                <b class="text-sm font-medium text-default">{{ objectName(connObject) }}</b>
              </div>
              <Icon
                class="h-4 w-4 text-default text-right"
                type="arrow-drop-right"
              />
            </div>
            <div class="connections border-l-0 p-0 m-0">
              <div
                v-for="conn in conns"
                :key="`${object?.key}-${connObject}-${conn.key}`"
                class="connection bg-white rounded-lg p-2 border-0"
                @mouseover="onOverConnection(conn.key)"
                @mouseout="onOutConnection(conn.key)"
              >
                <div class="conn-trigger level">
                  <div class="level-left overflow-visible">
                    <Icon
                      class="text-default"
                      type="arrow-drop-right"
                    />
                    <div class="conn-name level truncate max-w-[220px]">
                      <span class="text-emphasis text-sm font-medium">
                        {{ connObjectOwnerName(conn) }} {{ conn.name }}
                      </span>
                    </div>
                    <RouterLink
                      v-if="conn.relationship_type === `foreign`"
                      v-tippy="{ allowHTML: true }"
                      :content="`This connection field exists<br />on the ${getObject(conn.object).name} table.`"
                      :to="`/connections/objects/${conn.object}/fields/`"
                      class="foreignObjectLink"
                    >
                      <Icon
                        class="h-4 w-4 text-default"
                        type="arrow-top-right-on-square"
                      />
                    </RouterLink>
                  </div>
                  <RouterLink
                    v-slot="{ href, isActive, navigate }"
                    :to="getEditConnectionLink(conn)"
                    custom
                  >
                    <a
                      v-tippy
                      content="Edit this connection!"
                      :class="{'router-link-active': isActive}"
                      class="buttonSquare -size-small"
                      :href="href"
                      @click.stop="navigate"
                    >
                      <Icon
                        class="h-4 w-4 text-default"
                        type="settings"
                      />
                    </a>
                  </RouterLink>
                </div>
                <div class="conn-details">
                  <ConnectionDetails
                    class="pb-0 px-0"
                    :connection="conn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon.vue';
import ConnectionDetails from '@/components/connections/ConnectionDetails.vue';
import FieldUtils from '@/components/fields/FieldUtils';
import UIUtil from '@/util/UI';
import QueryMixin from '@/components/renderer/mixins/QueryMixin';

export default {
  components: {
    ConnectionDetails,
    Icon,
  },
  mixins: [
    FieldUtils,
    QueryMixin,
  ],
  inheritAttrs: false,
  computed: {
    ...mapGetters([
      'getObject',
    ]),
  },
  watch: {

    '$route.params.objectKey': function () {
      this.setToggles();
    },

    'object.conns': function (newVal, oldVal) {
      // Reset toggles if a new connection is added
      if (oldVal && newVal && newVal.length > oldVal.length) {
        this.setToggles();
      }
    },
  },
  mounted() {
    this.setToggles();
  },
  methods: {
    onOverConnection(fieldKey) {
      const $field = document.getElementById(`field-wrapper-${fieldKey}`);

      if (!$field) {
        return;
      }

      $field.classList.add('highlight');
    },
    onOutConnection(fieldKey) {
      const $field = document.getElementById(`field-wrapper-${fieldKey}`);

      if (!$field) {
        return;
      }

      $field.classList.remove('highlight');
    },
    getEditConnectionLink(connection) {
      let connectionOwnerObjectKey = this.object.key;

      // Ff foreign then get the owning object from the connection
      if (connection.relationship_type === 'foreign') {
        connectionOwnerObjectKey = connection.object;
      }

      return `/connections/objects/${connectionOwnerObjectKey}/fields/${connection.key}/settings`;
    },
    async setToggles() {
      await this.$nextTick();

      // If only open with max number of connected objects, use document.querySelectorAll(`.connection-object`).length < 5
      const openToggles = true;

      document.querySelectorAll('.connection-object').forEach((group) => {
        const $trigger = group.firstElementChild;

        if ($trigger.getAttribute('data-has-toggle')) {
          return;
        }

        return new UIUtil.SlideToggle(group.lastElementChild, {
          trigger: $trigger,
          isOpen: openToggles,
        });
      });

      document.querySelectorAll('.connection').forEach((group) => {
        const $trigger = group.firstElementChild;

        if ($trigger.getAttribute('data-has-toggle')) {
          return;
        }

        return new UIUtil.SlideToggle(group.lastElementChild, {
          trigger: $trigger,
          isOpen: (!openToggles && group.parentElement.childNodes.length === 1),
        });
      });
    },
    connectionsByObject(conns) {
      return _.groupBy(conns, (conn) => conn.object);
    },
    objectName(objectKey) {
      const object = this.$store.getters.getObject(objectKey);

      return object?.name;
    },
    connObjectOwnerName(conn) {
      // if local then this object is the owner
      if (conn.relationship_type === 'local') {
        return '';
      }

      return this.objectName(conn.object);
    },
  },
};
</script>

<style scoped>
.wistia-container {
  position: relative;
  width: 100%;
  /* 16:9 aspect ratio */
  padding-bottom: 56.25%; /* (9 / 16) * 100 = 56.25% */
  height: 0;
}

.wistia-container > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
