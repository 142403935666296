<template>
  <div
    class="panel-divider b-0"
    @mousedown="enableLeftPanelResizing"
    @dblclick="handleLeftPanelResizeOnDoubleClick"
  >
    <div class="divider">
      <div class="line border-0" />
    </div>
  </div>
</template>

<script>
import {
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
import { LEFT_PANEL_MIN_WIDTH_PX } from '@/store/modules/ui/ui';

export default {
  computed: {
    ...mapGetters('ui', [
      'leftPanelWidth',
      'leftPanelMaxWidth',
    ]),
  },
  watch: {
    leftPanelMaxWidth(newVal) {
      if (this.leftPanelWidth > newVal) {
        this.setLeftPanelWidth(
          Math.max(LEFT_PANEL_MIN_WIDTH_PX, newVal),
        );
      }
    },
  },
  methods: {
    ...mapMutations('ui', [
      'enableLeftPanelResizing',
      'setLeftPanelWidth',
    ]),
    ...mapActions('ui', [
      'handleLeftPanelResizeOnDoubleClick',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.panel-divider {
  position: relative;
  height: 100%;
  width: 1px;
  z-index: 11;

  div {
    height: 100%;
  }

  .divider {
    position: relative;
    right: 7px;
    width: 24px;
    padding-left: 7px;
    padding-right: 16px;
    z-index: 1;
    cursor: col-resize;

    .line {
      border-left: 1px solid $gray200;
    }

    &:hover {
      .line {
        border-left: 1px solid $primary500;
        opacity: 0.5;
        transition: all 0ms linear 200ms;
      }
    }

    &:active {
      cursor: col-resize;

      .line {
        border-left: 1px solid $primary500;
        opacity: 1.0;
        transition: none;
      }
    }
  }
}
</style>
