<template>
  <Overlay
    id="import"
    class="full-width full-body left-title"
    @close="onClose"
  >
    <template #title>
      <h1 class="text-base font-semibold items-center">
        Import into {{ importTitle }}
      </h1>
    </template>

    <ImportWizard @import-started="onClose" />
  </Overlay>
</template>

<script>
import Overlay from '@/components/ui/Overlay';
import Icon from '@/components/ui/Icon';
import ImportWizard from '@/components/records/import/ImportWizard';
import RouteUtils from '@/components/util/RouteUtils';

export default {
  components: {
    Overlay,
    Icon,
    ImportWizard,
  },
  mixins: [
    RouteUtils,
  ],
  data() {
    return {};
  },
  computed: {
    objectName() {
      return this.$store.getters.activeObject.name;
    },

    importTitle() {
      return this.objectName;
    },
  },
  methods: {
    onClose() {
      this.routerPushMaintainQuery(`/records/objects/${this.$route.params.objectKey}`);
    },
  },
};
</script>

<style lang="scss">
#import > .body {
  background: #f3f4f7;
}
</style>
