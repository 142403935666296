<template>
  <div
    id="bots"
    class="builderLayout_builderPane"
    :class="{'builderPane-noToolbox': !hasObjects }"
    @mousemove="handleLeftPanelResizeOnDrag"
    @mouseup="cancelLeftPanelResizing"
    @mouseleave="cancelLeftPanelResizing"
  >
    <RouterView
      name="modal"
    />

    <ToolboxRouter
      v-if="hasObjects"
      class="main-panel builderLayout_toolbox"
      :style="{ flexBasis: leftPanelFlexBasis}"
    />

    <PanelDivider />

    <div
      id="bots-body-wrapper"
      class="main-body builderLayout_main  border-solid border-x-0 border-b-0 border-t border-subtle -ml-px"
    >
      <TasksRibbon
        v-if="showRibbon"
        class="builderLayout_ribbon border-0"
      />

      <BodyWrapper id="bots-body">
        <RouterView
          name="body"
        />
      </BodyWrapper>

      <SocketNotification
        v-if="shouldDisplaySocketNotification"
        :object="activeObject"
        :notification="activeSocketNotification"
      />
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';
import BodyWrapper from '@/components/layout/BodyWrapper';
import ToolboxRouter from '@/components/layout/ToolboxRouter';
import PanelDivider from '@/components/layout/PanelDivider';
import TasksRibbon from '@/components/tasks/TasksRibbon';
import SocketNotification from '@/components/ui/notifications/SocketNotification';

export default {
  components: {
    BodyWrapper,
    ToolboxRouter,
    TasksRibbon,
    SocketNotification,
    PanelDivider,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'objects',
    ]),
    ...mapGetters('ui', [
      'leftPanelFlexBasis',
      'leftPanelWidth',
    ]),
    ...mapGetters('notifications', [
      'activeSocketNotification',
    ]),
    hasObjects() {
      return this.objects.length > 0;
    },
    activeObject() {
      if (this.$route.params.objectKey) {
        return this.$store.getters.getObject(this.$route.params.objectKey);
      }

      return false;
    },
    showRibbon() {
      return this.hasObjects && this.activeObject;
    },
    shouldDisplaySocketNotification() {
      return this.activeObject.status !== 'current' || (!_.isEmpty(this.activeSocketNotification) && this.activeObject.key === this.activeSocketNotification.object_key);
    },
  },
  mounted() {
    if (!this.leftPanelWidth) {
      this.setInitialLeftPanelWidth();
    }
  },
  methods: {
    ...mapActions('ui', [
      'handleLeftPanelResizeOnDrag',
      'cancelLeftPanelResizing',
      'setInitialLeftPanelWidth',
    ]),
  },
};
</script>
