<template>
  <div class="kn-signature">
    <div
      :id="field.key"
      class="kn-signature__field"
    />
    <a
      class="kn-signature__button text-default"
      @click="resetSignature"
    >
      reset
    </a>
  </div>
</template>

<script>
import $ from 'jquery';
  import jSignature from '@/lib/jSignature.min.js' // eslint-disable-line

export default {
  name: 'SignatureInput',
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: [String, Object],
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  mounted() {
    setTimeout(() => {
      const $signature = $(this.$el).find('.kn-signature__field');

      $signature.jSignature({
        UndoButton: true,
        signatureLine: true,
      });

      // resize & position Undo button
      const $undoButton = $signature.find('input[type=button]');
      const $canvas = $signature.first('canvas.JSignature');

      $undoButton.css({
        width: 'unset',
        top: '6px',
        left: `${Math.round(($canvas.width() - 139) / 2)}px`,
      });

      // emit changes
      $signature.on('change', (event) => {
        this.$emit('update:modelValue', {
          svg: $(event.target).jSignature('getData', 'svg')[1],
          base30: $(event.target).jSignature('getData', 'base30')[1],
        });
      });

      if (_.hasIn(this, 'localValue.base30')) {
        $signature.jSignature('setData', `data:base30,${this.localValue.base30}`);
      }
    }, 100);
  },
  methods: {
    resetSignature(event) {
      // Trigger the signature's 'reset' method, so it's visually empty on the screen
      $(this.$el).find('.kn-signature__field').jSignature('reset');

      // We also need to reset the value to an empty string so the field is actually empty.
      // Otherwise, the field is saved as an empty signature object
      this.$emit('update:modelValue', '');
    },
  },
};
</script>

<style>
.kn-signature {
  overflow: auto;
}
.kn-signature__field {
  border: 1px dashed #ccc;
  min-width: 500px;
  min-height: 125px;
  cursor: default;
}
</style>
