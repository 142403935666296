<template>
  <InputsView
    :schema="inputGroups"
    :view="view"
    :route-prefix="routePrefixInputs"
    :can-be-empty="true"
    @delete="onDeleteInput"
    @drop="onDropInput"
  />
</template>

<script>
import InputActionsMixin from '@/components/renderer/mixins/InputActionsMixin.js';
import InputsView from '@/components/renderer/shared/Inputs';
import RendererUtils from '@/components/renderer/RenderUtils.js';

export default {
  components: {
    InputsView,
  },
  mixins: [
    RendererUtils,
    InputActionsMixin,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  computed: {
    routePrefixInputs() {
      return `${this.routePrefix}/inputs`;
    },
    inputGroups: {

      get() {
        return this.view.get('groups');
      },
      set(newVal) {
        this.view.set('groups', newVal);
      },
    },
  },
};
</script>

<style lang="scss">
.kn-form {
  min-width: 600px;
}

.kn-content button, .kn-content input, .kn-content select, .kn-content textarea, .kn-form button, .kn-form input, .kn-form select, .kn-form textarea {
  margin: 0;
}

.kn-form .kn-button {
  min-width: 140px;
  align-items: center;
  border-radius: .35em;
  display: inline-flex;
  font-size: 14px;
  height: 32px;
  justify-content: flex-start;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  vertical-align: top;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  white-space: nowrap;
  text-decoration: none strong;
  text-decoration-color: inherit;
}
</style>
