<template>
  <div
    id="pages"
    :class="{'active-view': activeView, 'is-new-view': isNew, 'is-new-page': isNewPage}"
  >
    <RouterView
      v-if="activeItemsAreReady"
      :back-link="`/pages/${activePageKey}`"
      name="modal"
    />

    <NavigationGuardConfirm
      v-if="navigationGuard.showConfirm"
      :path="navigationGuard.path"
      @save="onSaveChangesAndContinue()"
      @cancel="onCancelChangesAndContinue()"
      @close="navigationGuard.showConfirm = false"
    />

    <div
      id="pages-wrapper"
      class="builderLayout_builderPane"
      @mousemove="handleLeftPanelResizeOnDrag"
      @mouseup="cancelLeftPanelResizing"
      @mouseleave="cancelLeftPanelResizing"
    >
      <div
        v-if="showToolbox"
        id="pages-toolbox"
        class="builderLayout_toolbox"
        :class="[{'has-active-updates':hasActiveUpdates}, widthClass]"
        :style="{ flexBasis: leftPanelFlexBasis}"
      >
        <ToolboxErrorBoundary>
          <RouterView
            v-slot="{ Component }"
            name="toolbox"
            :errors="errors"
          >
            <Transition :name="transitionName">
              <component
                :is="Component"
                v-if="isSafeToolBox(Component) || activeItemsAreReady"
              />
              <div
                v-else
                class="hidden"
              >
                Waiting for active view and active page...
              </div>
            </Transition>
          </RouterView>
        </ToolboxErrorBoundary>
        <Transition
          name="slide-up"
          mode="out-in"
        >
          <ToolboxSave
            v-if="hasActiveUpdates"
            data-test="page-toolbox-save"
            data-feature="save_page_button"
            @save="onSaveUpdates"
            @cancel="onCancelUpdates"
          />
        </Transition>
      </div>
      <PanelDivider />
      <div
        id="page-wrapper"
        :class="pageWrapperClasses"
        class="builderLayout_main border-solid border-x-0 border-b-0 border-t border-subtle -ml-px"
      >
        <PageRibbon
          ref="pageRibbon"
          class="builderLayout_ribbon border-b-subtle pb-3"
        />
        <BodyWrapper
          id="page-body"
          class="mb-4"
          :class="{rendererPane: $route.params.pageKey}"
        >
          <PageViews v-if="$route.params.pageKey" />
          <PagesIntro v-else />
        </BodyWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';

import BodyWrapper from '@/components/layout/BodyWrapper';
import PanelDivider from '@/components/layout/PanelDivider';
import NavigationGuardConfirm from '@/components/ui/NavigationGuardConfirm';
import PageRibbon from '@/components/pages/PageRibbon';
import PageUtils from '@/components/pages/PageUtils';
import PageViews from '@/components/pages/PageViews';
import PagesIntro from '@/components/pages/PagesIntro';
import ToolboxSave from '@/components/layout/ToolboxSave';
import RequestUtils from '@/components/util/RequestUtils';
import ToolboxErrorBoundary from '@/components/layout/ToolboxErrorBoundary';
import { eventBus } from '@/store/bus';

export default {
  name: 'Pages',
  components: {
    ToolboxErrorBoundary,
    ToolboxSave,
    BodyWrapper,
    PageRibbon,
    PageViews,
    PagesIntro,
    NavigationGuardConfirm,
    PanelDivider,
  },
  mixins: [
    RequestUtils,
    PageUtils,
  ],
  data() {
    return {
      transitionName: 'slide-left',
      navigationGuard: {
        showConfirm: false,
        path: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'objects',
      'isEmptyPages',
    ]),
    ...mapGetters('ui', [
      'leftPanelFlexBasis',
      'leftPanelWidth',
    ]),
    pageWrapperClasses() {
      return {
        'page-wrapper--full': this.isEmptyPages() && this.$route.path.endsWith('/pages'),
      };
    },
    showToolbox() {
      // KLUDGE: this first too conditionals feel too fragile

      if (this.$route.path.indexOf('pages/add') > -1) {
        return true;
      }

      if (this.$route.query.confirmNewPage) {
        return true;
      }

      return (this.hasObjects && !this.isEmptyPages());
    },
    hasObjects() {
      return this.objects.length > 0;
    },
    widthClass() {
      return this.$store.getters.toolboxExpandClass(this.$route, 'builderLayout_toolbox-large');
    },

    activePage() {
      return this.$store.getters.activePage;
    },

    // Modal route is using this for the back link. Perhaps a more elegant way for modal to handle that, but pages doesn't always have an active page so can't directly use {{activePage.key}}
    activePageKey() {
      if (this.activePage) {
        return this.activePage.key;
      }

      return '';
    },

    activeView() {
      return this.$store.getters.activeView;
    },

    isNew() {
      if (!this.activeView) {
        return false;
      }

      return this.activeView.key === 'new';
    },
    isNewPage() {
      return Boolean(this.activePage && this.activePage.isNewPage());
    },
    hasActiveUpdates() {
      const { getters } = this.$store;

      return getters.viewHasActiveUpdates || getters.pageHasActiveUpdates;
    },

    /**
     * Determines whether the active view and/or active page have been loaded.
     * In order to avoid type errors, we are opting to not render the toolbox until the
     * active page and/or active view is loaded for pages that have a pageKey and/or viewKey
     * respectively.
     *
     * @returns {boolean}
     */
    activeItemsAreReady() {
      const { params } = this.$route;
      const { getters } = this.$store;

      const hideBecauseOfPage = (params.pageKey && getters.activePage?.key !== params.pageKey);
      const hideBecauseOfView = (params.viewKey && getters.activeView?.key !== params.viewKey);

      return !hideBecauseOfPage && !hideBecauseOfView;
    },
  },
  created() {
    // ViewToolbox submits this when the user hits enter in form inputs located in the left toolbox
    eventBus.$on('saveViewFromFormEnter', this.saveUpdates);
  },
  unmounted() {
    eventBus.$off('saveViewFromFormEnter', this.saveUpdates);
  },
  beforeRouteLeave(to, from) {
    log('Pages.beforeRouteLeave () heading to', to.path, 'from', from.path);

    if (!this.canLeave(to.path)) {
      // store to for later
      this.navigationGuard.path = to.path;

      // show modal
      this.navigationGuard.showConfirm = true;

      return false;
    }

    return true;
  },

  beforeRouteUpdate(to, from) {
    // TODO: figure out more elegant navigation guard, depending on how we want
    // users to be notified (model etc)
    // we also have other route changes we might want to handle differently e.g. someone has
    // updated a view content but then also wants to "move" or "copy" it (those trigger route changes)
    if (!this.canLeave(to.path)) {
      // store to for later
      this.navigationGuard.path = to.path;

      // show modal
      this.navigationGuard.showConfirm = true;

      return false;
    }

    const toDepth = to.path.split('/').length;
    const fromDepth = from.path.split('/').length;

    // more pronounced Transition when first activating a view
    if (from.name === 'page' && to.path.indexOf('/views/') > -1) {
      this.transitionName = 'slide-left';
    } else {
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }

    log('Pages Transition', toDepth, fromDepth, this.transitionName);

    return true;
  },
  mounted() {
    if (!this.leftPanelWidth) {
      this.setInitialLeftPanelWidth();
    }
  },

  methods: {
    ...mapActions('ui', [
      'handleLeftPanelResizeOnDrag',
      'cancelLeftPanelResizing',
      'setInitialLeftPanelWidth',
    ]),
    cancelUpdates() {
      this.activeView.cancelUpdates();
      this.$store.commit('clearViewErrors');
    },
    onConfirmNavigationGuardExit() {
      this.navigationGuard.showConfirm = false;

      this.cancelUpdates();
    },
    onCancelUpdates() {
      this.cancelUpdates();

      this.$router.push(
        `/pages/${this.activePage.key}/views/${this.activeView.key}/${this.activeView.type}`,
      );
    },
    onCancelChangesAndContinue() {
      this.navigationGuard.showConfirm = false;

      this.cancelUpdates();
    },

    // Check for any unsaved changes
    canLeave(toPath) {
      if (!this.activeView || !this.$store.getters.viewHasActiveUpdates) {
        return true;
      }

      // logout/login should always leave
      if (toPath === '/login') {
        return true;
      }

      // /view/view_1/delete should trigger the leave guard
      if (toPath.indexOf(`/views/${this.activeView.key}/delete`) > -1) {
        return false;
      }

      // move/copy should also trigger the leave guard
      if (toPath.includes('/move') || toPath.includes('/copy')) {
        return false;
      }

      if (toPath.indexOf(`/views/${this.activeView.key}/`) > -1) {
        return true;
      }

      return false;
    },
    onSaveChangesAndContinue() {
      this.saveUpdates();
    },
    onSaveUpdates() {
      this.saveUpdates();
    },
    saveUpdates() {
      if (!this.activeView) {
        return;
      }

      this.commitRequest({
        validate: () => this.activeView.validate(),
        request: () => this.activeView.save(),
        onSuccess: () => {
          this.$store.commit('setViewHasActiveUpdates', false);
          this.$store.commit('clearViewErrors');

          // Confirmed save from navigation guard, so continue to that path
          if (this.navigationGuard.showConfirm) {
            this.navigationGuard.showConfirm = false;

            return this.$router.push(this.navigationGuard.path);
          }

          this.refreshActivePage(this.activePage.key);

          return this.$router.push(`/pages/${this.activePage.key}`);
        },
        onError: (errors) => {
          // Make sure to only show the validation error at the toolbox body level.
          this.errors = [];

          this.$store.commit('setViewErrors', errors);
        },
      });
    },

    /**
     * Does not wait for active page or active view loads if the toolbox component is not in
     * danger of throwing type errors. This avoids unnecessary transitions on these components.
     *
     * @param {{type: {name: string}}} component
     * @returns {boolean}
     */
    isSafeToolBox(component) {
      const safeComponentNames = ['PagesNav'];

      return safeComponentNames.includes(component?.type?.name);
    },
  },
};
</script>

<style lang="scss">
#page-wrapper {
  flex-grow: 1;
  height: 100%;

  #page-body {
    flex-grow: 1;
  }
}

#pages {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ribbon-links {
    &> a {
      margin-left: 10px;
      padding: 2px 12px;
      background-color: #ededf1;
      border-radius: 50px;
      font-size: .95em;

      &>span, &>svg {
        color: #771b66;//#229064;
      }
    }

    svg {
      vertical-align: middle;
      width: 16px;
      height: 16px;
    }
  }

  .save-actions {
    z-index: 101;
  }
}

#pages-wrapper {
  flex-grow: 1;
  height: 100%;
}

#pages-toolbox {
  overflow: hidden;
  position: relative;

  .toolbox-body {
    position: relative;
  }

  &.has-active-updates .toolbox-body {
    margin-bottom: 30px;
  }

  .hidden {
    display: none;
  }
}

#pages .toolbox {
  z-index: 91;
}

#pages-wrapper.width-large #pages-toolbox {
  #pages-toolbox {
    width: 450px;
  }

  #page-wrapper {
    left: 450px;
  }
}

#pages-wrapper.width-max {
  #pages-toolbox {
    width: 580px;
  }

  #page-wrapper {
    left: 580px;
  }
}

.slide-up-enter-active {
  transition: all 0.2s ease-in;
}

.slide-up-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(40px);
}

</style>
