<template>
  <span
    data-cy="indicator-count"
    class="indicator-count h-6 w-6 p-2 rounded-md text-sm leading-4 flex justify-center items-center ml-1"
  >{{ count }}</span>
</template>

<script>
export default {
  name: 'CountIndicator',
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.indicator-count {
  height: 18px;
  width: 18px;
  border-radius: 1em;
  background-color: $gray100;
  color: $gray500;
  display: inline-block;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.125rem;
  margin-left: .5rem;
  min-width: 1.125rem;
  padding-left: .25rem;
  padding-right: .25rem;
  text-align: center;
  flex-shrink: 0;
}

.left-toolbox .nav-item:not(.router-link-active) > a:hover .indicator-count {
  background-color: $gray200;
}

.is-active .indicator-count {
  background-color: $primary100;
  color: $primary600;
}
</style>
