import Joi from '@hapi/joi';
import { valueFormatter } from '@knack/formatter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import store from '@/store/index';
import Field from '@/store/models/Field';
import Task from '@/store/models/Task';
import SchemaUtils from '@/store/utils/SchemaUtils';

class Obj {
  constructor(object) {
    this.setObject(object);
  }

  setObject(object) {
    if (object.fields) {
      this.setFields(object);
    }

    this.setProperties(object);

    if (this.attributes.fields) {
      delete this.attributes.fields;
    }
  }

  setProperties(object) {
    this.profile_key = object.profile_key;
    this.connections = object.connections;
    this.inflections = object.inflections;
    this.attributes = object;

    if (this.connections) {
      this.setConnections();
    }

    if (!this.attributes.identifier && object.fields && object.fields.length) {
      this.attributes.identifier = object.fields[0].key;
    }

    if (!object.sort && !isEmpty(this.fields)) {
      const sort = (object.sort) ? object.sort : {
        field: this.fields[0].key,
        order: 'asc',
      };

      this.attributes.sort = sort;
    }

    return this;
  }

  setFields(object) {
    this.fields = object.fields.map((field) => new Field(field, object));

    this.fieldsByKey = {};

    this.fields.forEach((field) => {
      this.fieldsByKey[field.key] = field;
    });
  }

  setConnections() {
    this.conns = [];

    if (!this.connections) {
      return this.conns;
    }

    this.connections.outbound.forEach((conn) => {
      conn.relationship_type = 'local';

      this.conns.push(conn);
    });

    this.connections.inbound.forEach((conn) => {
      this.conns.push({
        belongs_to: conn.has,
        field: conn.field,
        has: conn.belongs_to,
        key: conn.key,
        name: conn.name,
        object: conn.object,
        relationship_type: 'foreign',
      });
    });

    return this.conns;
  }

  raw() {
    return this.attributes;
  }

  get name() {
    return this.attributes.name;
  }

  get identifier() {
    return this.attributes.identifier;
  }

  get ecommerce() {
    return this.attributes.ecommerce;
  }

  get ecommercePaymentMethods() {
    return this.attributes.ecommerce_payment_methods;
  }

  get key() {
    return this.attributes.key;
  }

  get sort() {
    return this.attributes.sort;
  }

  get tasks() {
    return this.attributes.tasks.map((task) => new Task(task));
  }

  set tasks(newValue) {
    this.attributes.tasks = newValue.map((task) => (task instanceof Task ? task.attributes : task));
  }

  set sort(sort) {
    this.attributes.sort = sort;
  }

  get(property) {
    return this.attributes[property];
  }

  getField(fieldKey) {
    return this.fieldsByKey[fieldKey];
  }

  getFieldByName(fieldName) {
    return this.fields.find((field) => field.name === fieldName);
  }

  getFields() {
    return this.fields;
  }

  getFieldsThatStoreDates() {
    return this.fields.filter((field) => field.storesDateValues());
  }

  getUserRoleField() {
    const rolesField = this.fields.find((field) => field.type === 'user_roles');
  }

  getAddressFields() {
    return this.fields.filter((field) => field.type === 'address');
  }

  getFieldsThatStoreNumbers() {
    return this.fields.filter((field) => field.storesNumericValues());
  }

  getFieldsForReports(isChart = true) {
    let hasNumerics = false;

    const fields = [];

    // these get combined with all numeric types
    const validFieldType = [
      window.Knack.config.DATE_TIME,
      window.Knack.config.SHORT_TEXT,
      window.Knack.config.CONCATENATION,
      window.Knack.config.NAME,
      window.Knack.config.BOOLEAN,
      window.Knack.config.MULTIPLE_CHOICE,
      window.Knack.config.CONNECTION,
    ];

    this.fields.forEach((field) => {
      if (field.storesNumericValues() || validFieldType.includes(field.type)) {
        if (field.storesNumericValues()) {
          hasNumerics = true;
        }

        let value = field.key;
        let label = `by <b>${field.name}</b>`;

        if (isChart) {
          value = `${field.type}-${value}`;
        }

        if (field.type === window.Knack.config.MULTIPLE_CHOICE) {
          label = `${label} choices`;
        }

        if (field.type === window.Knack.config.CONNECTION) {
          label = `${label} connections`;
        }

        fields.push({
          value,
          label,
          key: field.key,
        });
      }
    });

    if (hasNumerics && isChart) {
      fields.push({
        value: 'totals',
        label: '<b>Field Totals</b>',
      });

      fields.push({
        value: 'averages',
        label: '<b>Field Averages</b>',
      });
    }

    return fields;
  }

  hasFieldsThatStoreNumbers() {
    return this.getFieldsThatStoreNumbers() > 0;
  }

  // includeDateEquations is a kludge to mantain v2 parity
  // See https://knack.atlassian.net/browse/BV3-1472
  getManyConnectedFieldsThatStoreNumbersAsOptions(includeDateEquations = false) {
    const options = [];

    this.conns.forEach((conn) => {
      if (conn.has !== 'many') {
        return false;
      }

      const connectedObject = store.getters.getObject(conn.object);

      connectedObject.fields.forEach((field) => {
        let include = false;

        if (field.storesNumericValues()) {
          include = true;
        }

        if (includeDateEquations && field.isDateEquation()) {
          include = true;
        }

        if (include) {
          options.push({
            value: `${field.key}.${conn.key}`,
            label: `${connectedObject.inflections.singular} (${conn.name}) > ${field.name}`,
          });
        }
      });
    });

    return options;
  }

  getMutableFields() {
    return this.fields.filter((field) => {
      if (window.Knack.config.fields[field.type].input_type === 'none') {
        return false;
      }

      // Equation is example of field type where input_type = display
      if (window.Knack.config.fields[field.type].input_type === 'display') {
        return false;
      }

      if (this.get('read_only') && this.get('read_only') === true) {
        return false;
      }

      if (field.get('read_only') && field.get('read_only') === true) {
        return false;
      }

      return true;
    });
  }

  // used to populate <selects> for choosing a field
  getFieldOptions({ ignoreTypes = [] } = {}) {
    const options = [];

    this.fields.forEach((field) => {
      if (ignoreTypes.includes(field.type)) {
        return;
      }

      // only add the User Roles option, if roles are available
      if (field.type === window.Knack.config.USER_ROLES) {
        const hasUserRoles = store.getters.hasRoleObjects;

        if (!hasUserRoles) {
          return;
        }
      }

      options.push({
        value: field.key,
        label: field.name,
      });
    });

    return options;
  }

  // used to populate <selects> for choosing a field
  getDisplayFieldOptions() {
    const options = [];

    this.fields.forEach((field) => {
      if (field.canBeADisplayField()) {
        options.push({
          value: field.key,
          label: field.name,
        });
      }
    });

    return options;
  }

  getUserRoleField() {
    return this.fields.find((field) => field.type === window.Knack.config.USER_ROLES);
  }

  // used to populate <selects> for choosing a field
  getSortFieldOptions() {
    const options = [];

    this.fields.forEach((field) => {
      if (field.canBeASortField()) {
        options.push({
          value: field.key,
          label: field.name,
        });
      }
    });

    return options;
  }

  getEmailFieldOptions() {
    const options = [];

    this.fields.forEach((field) => {
      if (field.type !== 'email') {
        return;
      }

      options.push({
        value: field.key,
        label: field.name,
      });
    });

    // connected fields
    this.conns.forEach((conn) => {
      const connectedObject = store.getters.getObject(conn.object);

      connectedObject.fields.forEach((field) => {
        if (field.type !== 'email') {
          return;
        }

        let connName = connectedObject.name;

        if (!(conn.name == connName)) {
          connName += ` (${conn.name})`;
        }

        connName += ` > ${field.name}`;

        options.push({
          value: `${conn.key}-${field.key}`,
          label: connName,
        });
      });
    });

    return options;
  }

  // prefix by role
  getFieldOptionsByRole() {
    // ignore the user roles field if no user roles exist
    const hasUserRoles = store.getters.hasRoleObjects;
    const ignoreTypes = [];

    if (!hasUserRoles) {
      ignoreTypes.push(window.Knack.config.USER_ROLES);
    }

    let options = this.getFieldOptions({
      ignoreTypes,
    });

    options = options.map((option) => ({
      value: `${this.get('profile_key')}-${option.value}`,
      label: `${this.name} > ${option.label}`,
    }));

    return options;
  }

  getManyConnectionsAsOptions() {
    const options = [];

    this.conns.forEach((conn) => {
      if (conn.has !== 'many') {
        return;
      }

      const connectionObject = store.getters.getObject(conn.object);

      options.push({
        value: conn.key,
        label: `${connectionObject.name} (${conn.name})`,
      });
    });

    return options;
  }

  getConnection(connectionKey) {
    return this.conns.find((conn) => conn.key === connectionKey);
  }

  /**
   * Gets fields from connected objects, grouped into an array of connections
   *
   * @param {object} [excludeOptions] - will exclude any connections with properties matching the following key/values:
   *   - belongs_to: one | many
   *   - has: one | many
   *   - relationship_type: local | foreign (whether the connection field lives on this local object or the connected foreign one)
   * @returns {array} connectedObjectFields
   */
  getConnectedFields(excludeOptions = {}) {
    const connectedObjectFields = [];

    this.conns.forEach((conn) => {
      const exclude = Object.keys(excludeOptions).some((excludeProperty) =>

        // Checks if the exclude property exists in the connection and equals the value in excludeOptions
        (conn[excludeProperty] && conn[excludeProperty] === excludeOptions[excludeProperty]));

      if (exclude) {
        return;
      }

      const connObject = store.getters.getObject(conn.object);

      const { object: connFieldObject } = store.getters.getFieldWithObject(conn.key);

      connectedObjectFields.push({
        conn,
        name: `${connFieldObject.inflections.singular} ${conn.name}`,
        fields: connObject.fields,
      });
    });

    return connectedObjectFields;
  }

  getConnectedFieldOptions(excludeOptions = {}) {
    const options = [];

    this.conns.forEach((conn) => {
      const exclude = Object.keys(excludeOptions).some((excludeProperty) =>

        // Checks if the exclude property exists in the connection and equals the value in excludeOptions
        (conn[excludeProperty] && conn[excludeProperty] === excludeOptions[excludeProperty]));

      if (exclude) {
        return;
      }

      const connObject = store.getters.getObject(conn.object);

      connObject.fields.forEach((field) => {
        let connName = connObject.get('name');

        if (conn.name !== connName) {
          connName += ` (${conn.name})`;
        }

        options.push({
          value: `${conn.key}-${field.key}`,
          label: `${connName} > ${field.name}`,
        });
      });
    });

    return options;
  }

  // used for generating table columns; used by builder records
  getFieldColumns() {
    return this.fields.map((field) => {
      const column = {
        type: 'field',
        field: {
          key: field.key,
        },
        header: field.name,
        objectKey: this.key,
        sortable: true,
        truncate: true,
        integration: field.get('integration'),
      };

      return { ...SchemaUtils.columnDefaults(), ...column };
    });
  }

  // used for generating object forms, like builder > data > add record
  getFieldInputs() {
    const inputs = [];

    this.fields.forEach((field) => {
      if (field.isFormInput()) {
        inputs.push(field.getAsFormInput());
      }
    });

    return inputs;
  }

  getFieldDefaults() {
    const values = {};

    this.fields.forEach((field) => {
      if (!field.isFormInput()) {
        return;
      }

      values[field.key] = field.getDefaultValue();
    });

    return values;
  }

  getEcommerceFields() {
    return this.fields.filter((field) => _.hasIn(window.Knack.config.fields[field.type], 'numeric') && window.Knack.config.fields[field.type].numeric);
  }

  canAddFieldType(type) {
    if (type.type === window.Knack.config.COUNT) {
      return this.hasConnectionsToMany();
    }

    if (type.isFormula) {
      return this.hasConnectionsToManyWithNumericFields();
    }

    return true;
  }

  canAddRecordsFromBuilderUI() {
    // Object types that don't allow adding records directly from the builder UI
    const disallowedObjectTypes = ['EcommercePaymentObject'];
    return !disallowedObjectTypes.includes(this.attributes.type);
  }

  isUser() {
    return !isEmpty(this.profile_key);
  }

  isAccount() {
    return (this.profile_key && this.profile_key === 'all_users');
  }

  isRole() {
    return (this.profile_key && this.profile_key !== 'all_users');
  }

  hasEcommerceField() {
    return this.fields.some((field) => _.hasIn(window.Knack.config.fields[field.type], 'numeric') && window.Knack.config.fields[field.type].numeric);
  }

  hasAddressField() {
    return this.fields.some((field) => field.type === window.Knack.config.ADDRESS);
  }

  hasGeocodedAddressField() {
    return this.hasAddressField() && this.fields.some((field) => get(field, 'format.enable_geocoding', false) === true);
  }

  hasDateField() {
    return this.getFieldsThatStoreDates().length > 0;
  }

  hasPasswordField() {
    return this.fields.some((field) => field.isPassword());
  }

  hasConnectionsToMany() {
    const connectionsToMany = this.conns.filter((conn) => conn.has === 'many');

    return connectionsToMany.length > 0;
  }

  hasConnectionsToManyWithNumericFields() {
    return this.getManyConnectedFieldsThatStoreNumbersAsOptions().length > 0;
  }

  hasActiveJobs() {
    const activeSocketNotifications = store.getters['notifications/getSocketNotificationsByObjectKey'](this.key).filter(({ category }) => category !== 'complete');

    return this.get('status') !== 'current' || activeSocketNotifications.length > 0;
  }

  async clearAllRecords() {
    const response = await window.Knack.Api.clearAllRecords(this.key);

    // TODO: add logic to clear records or refetch data?

    return response;
  }

  async setApprovalTemplate() {
    const response = await window.Knack.Api.getAccountTemplate(this.profile_key, 'approvals');

    this.setTemplate(response.template, 'approvals');

    return response;
  }

  setTemplate(template, templateType) {
    const { app } = store.getters;

    if (isNil(app.users.templates)) {
      app.users.templates = {};
    }

    if (isNil(app.users.templates[this.profile_key])) {
      app.users.templates[this.profile_key] = {};
    }

    app.users.templates[this.profile_key][templateType] = template;
  }

  addField(field, insertIndex) {
    if (isNil(insertIndex)) {
      insertIndex = this.fields.length;
    }

    this.fields.splice(insertIndex, 0, field);

    this.fieldsByKey[field.key] = field;
  }

  async deleteField(fieldKey) {
    const response = await window.Knack.Api.deleteField(this.key, fieldKey);

    delete this.fieldsByKey[fieldKey];

    const commitPayload = {
      objectKey: this.key,
      fieldKey,
    };

    store.commit('removeField', commitPayload);

    return response;
  }

  async updateField(key, name, type, required, unique, defaultValue, format, rules, validation) {
    const response = await window.Knack.Api.updateField(this.key, key, name, type, required, unique, defaultValue, format, rules, validation);

    await store.dispatch('updateField', { field: response.field });

    return response;
  }

  async copyFields(targetObjectKey, targetObjectName, fields, userRole = false) {
    const response = await window.Knack.Api.copyFields(this.key, targetObjectKey, targetObjectName, fields, userRole);

    store.commit('updateObject', response.object);

    return response;
  }

  async deleteObject() {
    const response = await window.Knack.Api.deleteObject(this.key);

    store.commit('removeObject', this.key);

    return response;
  }

  validate() {
    const validationSchema = {
      _id: Joi.string().optional(),
      name: Joi.string()
        .invalid(store.getters.objects.filter((obj) => obj.key !== this.key).map((obj) => obj.name))
        .empty('')
        .required(),
      key: Joi.string().empty('').optional(), // not required because undefined before create
      status: Joi.string(),
      identifier: Joi.string(),
      fields: Joi.array(),
      user: Joi.boolean(),
      connections: Joi.object().keys({
        outbound: Joi.array(),
        inbound: Joi.array(),
      }),
      conns: Joi.array(),
      inflections: Joi.object(),
      profile_key: Joi.string(),
      tasks: Joi.array(),
      sort: Joi.object(),
    };

    const validationOptions = {
      abortEarly: false,
      allowUnknown: true,
    };

    const validationResult = Joi.validate(this.attributes, validationSchema, validationOptions);

    return {
      error: validationResult.error,
      errorMap: {
        'error.name.any.empty': 'Please enter a Table name.',
        'error.name.any.invalid': 'Please enter a unique Table Name. <b><%= context.value %></b> is already being used.',
      },
    };
  }

  async create(template = '') {
    const { name, fields, user } = this.attributes;

    const response = await window.Knack.Api.createObject(name, fields, template, user);

    // this addObject still needs to be here because response.changes doesn't have insert...
    store.commit('addObject', response.object);

    return response;
  }

  async copy(newObjectName, newObjectFields) {
    const response = await window.Knack.Api.copyObject(this.key, newObjectName, newObjectFields, null, this.isRole());

    // this ensures that RequestUtils' update.objects iteration will have this new object in the store
    store.commit('addObject', response.object);

    return response;
  }

  async update() {
    const { name, identifier, sort } = this.attributes;

    const response = await window.Knack.Api.updateObject(this.key, name, identifier, sort);

    store.commit('updateObject', response.object);

    return response;
  }

  getTaskHistory(taskKey) {
    return window.Knack.Api.getTaskHistory(this.key, taskKey);
  }

  getEligibleFieldsForEquationField(equationFieldKey, equationType) {
    const equationFields = [];

    for (const field of this.fields) {
      if (equationFieldKey === field.key) {
        continue;
      }

      if (field.canBeUsedInEquations(equationType)) {
        equationFields.push({
          key: field.key,
          displayText: `${field.name}`,
          value: `${field.key}`,
          relation: this.name,
          type: field.type,
        });
      }
    }

    // these are on another object
    for (const inboundField of this.get('connections').inbound) {
      if (inboundField.belongs_to === 'one') {
        const inboundObject = store.getters.getObject(inboundField.object);

        for (const field of inboundObject.fields) {
          if (equationFieldKey === field.key) {
            continue;
          }

          if (field.canBeUsedInEquations(equationType)) {
            equationFields.push({
              key: field.key,
              displayText: `${inboundObject.inflections.singular} ${inboundField.name}.${field.name}`,
              value: `${inboundField.key}.${field.key}`,
              relation: `${inboundObject.name} > ${this.name}`,
              type: field.type,
            });
          }
        }
      }
    }

    // these are going to another object from equation field object
    for (const outboundField of this.get('connections').outbound) {
      if (outboundField.has === 'one') {
        const outboundObject = store.getters.getObject(outboundField.object);

        for (const field of outboundObject.fields) {
          if (equationFieldKey === field.key) {
            continue;
          }

          if (field.canBeUsedInEquations(equationType)) {
            equationFields.push({
              key: field.key,
              displayText: `${this.inflections.singular} ${outboundField.name}.${field.name}`,
              value: `${outboundField.key}.${field.key}`,
              relation: `${this.name} > ${outboundObject.name}`,
              type: field.type,
            });
          }
        }
      }
    }

    return equationFields;
  }

  getRequiredFieldsForField(field, targetObjectKey) {
    // if this field does not belong to this object ignore it
    if (!field?.key || !this.getField(field.key)) {
      return [];
    }

    const dependentFields = [];

    for (const rule of field.rules) {
      for (const criteria of rule.criteria) {
        dependentFields.push(criteria.field);
      }

      for (const value of rule.values) {
        dependentFields.push(value.input);
      }
    }

    if (field.type === 'equation' || field.type === 'concatenation') {
      if (typeof field.format.equation === 'string') {
        const connFields = field.format.equation.match(new RegExp(/{field_\d+.field_\d+}/g));
        let fields = field.format.equation.match(new RegExp(/{field_\d+}/g));

        if (!fields) {
          fields = [];
        }

        fields = fields.concat(connFields);

        for (let equationField of fields) {
          if (!equationField) {
            continue;
          }

          if (targetObjectKey === this.key) {
            continue;
          }

          equationField = equationField.replace(/{|}/g, '');

          // handle connected fields
          equationField = equationField.split('.');

          if (equationField.length > 1) {
            equationField = equationField[1];
          } else {
            equationField = equationField[0];
          }

          // if this field does not belong to this object ignore it
          if (!this.getField(equationField)) {
            continue;
          }

          dependentFields.push(equationField);
        }
      } else { // legacy equations
        for (const equationPart of field.format.equation) {
          if (targetObjectKey === this.key) {
            continue;
          }

          if (equationPart.type === 'field') {
            dependentFields.push(equationPart.field.key);
          }
        }
      }
    }

    if (field.type === 'sum' || field.type === 'count' || field.type === 'average' || field.type === 'min' || field.type === 'max') {
      dependentFields.push(field.format.connection.key || field.format.connection);
    }

    return dependentFields;
  }

  getUserRoleFields() {
    const userRoleFields = [];

    for (const field of this.fields) {
      if (!field.immutable) {
        continue;
      }

      userRoleFields.push(field.key);
    }

    return userRoleFields;
  }

  getFieldKeysEligibleForCopyExisting() {
    let { fields } = this;

    if (this.profile_key && this.profile_key === 'all_users') {
      fields = fields.filter((field) => {
        const isRestrictedFieldType = [
          'multiple_choice',
          'password',
          'user_roles',
        ].includes(field.type);

        return (!isRestrictedFieldType || !field.immutable);
      });
    }

    if (this.profile_key && this.profile_key.includes('profile_')) {
      fields = fields.filter((field) => !field.immutable);
    }

    // check for connection to another object before filtering the fields based on field type
    if (this.conns.length > 0) {
      fields = fields.filter((field) => this.canCopyField(field));
    }

    fields = fields.map((field) => field.key);

    return fields;
  }

  getFieldKeysEligibleForCopyNew() {
    let { fields } = this;

    if (this.profile_key && this.profile_key === 'all_users') {
      fields = fields.filter((field) => {
        const isRestrictedFieldType = [
          'multiple_choice',
          'password',
          'user_roles',
        ].includes(field.type);

        return (!isRestrictedFieldType || !field.immutable);
      });
    }

    // check for connection to another object before filtering the fields based on field type
    if (this.conns.length > 0) {
      fields = fields.filter((field) => this.canCopyField(field));
    }

    fields = fields.map((field) => field.key);

    return fields;
  }

  canCopyField(field) {
    let shouldReturnField = true;

    // these field types require a connection to another object
    // do not want to copy these field types since error will occur due to connection implications
    const connectionRequiredFieldTypes = [
      'count',
      'average',
      'max',
      'min',
      'sum',
    ];

    // these field types can be dependent on connectionRequiredFieldTypes and will need to be further evaluated
    const conditionalConnectionRequiredFieldTypes = [
      'equation',
      'concatenation',
    ];

    if (connectionRequiredFieldTypes.includes(field.type)) {
      shouldReturnField = false;
    } else if (conditionalConnectionRequiredFieldTypes.includes(field.type)) {
      const requiredFields = this.getRequiredFieldsForField(field);

      if (requiredFields.length > 0) {
        // check if at least one of the required fields is of `connectionRequiredFieldType`
        shouldReturnField = !requiredFields.some((requiredFieldId) => {
          const requiredField = this.getField(requiredFieldId);

          return connectionRequiredFieldTypes.includes(requiredField.type);
        });
      }
    }

    return shouldReturnField;
  }

  async getAccountInfoTemplate() {
    const response = await window.Knack.Api.getAccountTemplate(this.profile_key, 'intros');

    this.setTemplate(response.template, 'intros');

    return response;
  }

  async updateAccountTemplate(templateType) {
    const { app } = store.getters;

    const template = app.users.templates[this.profile_key][templateType];

    const response = await window.Knack.Api.updateAccountTemplate(this.profile_key, templateType, template);

    return response;
  }

  reformatRecordsByFields(records, fieldKeys) {
    const fields = fieldKeys.map((fieldKey) => this.getField(fieldKey));

    const options = {
      application: {
        getUserObject: () => {
        },
        getField: store.getters.getField,
      },
    };

    const reformattedRecords = records.map((record) => {
      for (const field of fields) {
        const formattedValue = valueFormatter.formatValue({
          type: field.type,
          format: field.format,
        }, record[`${field.key}_raw`], 'both', options);

        record[field.key] = formattedValue;
      }

      return record;
    });

    return reformattedRecords;
  }
}

export default Obj;
