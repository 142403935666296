<template>
  <div class="body-wrapper">
    <ObjectsEmpty v-if="noObjects" />

    <EmptyStateGeneric
      v-else
      top
    >
      <div class="mb-2">
        <Icon
          type="arrow-right-circle"
          class="tw-rotate-180 h-auto w-[110px] fill-[url(#svg-brand-gradient)] opacity-40"
        />
      </div>

      <h2 class="margin-bottom-lg text-xl text-emphasis font-medium mb-2">
        Create or Update a Table
      </h2>

      <p class="text-default mb-6">
        Select a table to update from the left panel or create a new one
      </p>
    </EmptyStateGeneric>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import EmptyStateGeneric from '@/components/ui/EmptyStateGeneric';
import ObjectsEmpty from '@/components/objects/ObjectsEmpty';

export default {
  components: {
    Icon,
    ObjectsEmpty,
    EmptyStateGeneric,
  },
  props: {
    layout: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    noObjects() {
      return this.$store.getters.objects.length === 0;
    },
  },
};
</script>
