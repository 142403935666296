<template>
  <div class="control kn-inputWrapper">
    <div v-if="isUnique">
      <div class="link_label">
        <input
          id="link_label"
          v-model="localLabel"
          class="input kn-input"
          name="label"
          type="text"
        >
        <label
          class="help"
          for="link_label"
        >
          {{ trans("Email Text") }}
        </label>
      </div>
      <div class="link_url">
        <input
          :id="input.id"
          v-model="localEmail"
          class="input kn-input"
          name="email"
          type="text"
        >
        <label
          class="help"
          :for="field.key"
        >
          {{ trans("Email Address") }}
        </label>
      </div>
    </div>
    <input
      v-else
      :id="input.id"
      v-model="localEmail"
      class="input kn-input"
      name="email"
      type="email"
    >
    <input
      name="key"
      type="hidden"
      :value="input.id"
    >
  </div>
</template>

<script>
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';

export default {
  name: 'EmailInput',
  mixins: [
    TranslationUtils,
  ],
  inheritAttrs: false,
  props: [
    'input',
    'field',
    'modelValue',
  ],
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    localEmail: {
      get() {
        return this.localValue.email;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          email: newValue,
        });
      },
    },
    localLabel: {
      get() {
        return this.localValue.label;
      },
      set(newValue) {
        this.$emit('update:modelValue', {
          ...this.localValue,
          label: newValue,
        });
      },
    },
    textFormat() {
      if (!this.input.format || !this.input.format.text_format) {
        return 'unique';
      }

      return this.input.format.text_format;
    },
    isUnique() {
      return this.textFormat === 'unique';
    },
  },
};
</script>
