<script setup>
import {
  computed,
  defineProps,
  ref,
  onMounted,
  watch,
} from 'vue';

import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentPlugin from '@fullcalendar/moment';
import timeGridPlugin from '@fullcalendar/timegrid';

const props = defineProps({
  config: {
    type: Object,
    default: () => ({}),
  },
  events: {
    type: Array,
    default: () => [],
  },
  view: {
    type: String,
    default: () => 'timeGridWeek',
  },
});

const calendar = ref(null);

const calendarOptions = computed(() => ({
  plugins: [dayGridPlugin, momentPlugin, timeGridPlugin],
  initialView: props.view,
  events: props.events,
  ...props.config,
}));

onMounted(() => {
  // Set the view based on the view prop.
  // This is just in case the props.view changed before the mounting finished.
  calendar?.value?.getApi().changeView(props.view);
});

// Watch for the view props to change and update the calendar if it does.
watch(() => props.view, (newValue) => {
  calendar?.value?.getApi().changeView(newValue);
});
</script>

<template>
  <FullCalendar
    ref="calendar"
    :options="calendarOptions"
  />
</template>
