<template>
  <view-toolbox
    back-title="page"
    title="Search Menu"
  >
    <div>
      <toc-links :items="items" />
    </div>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import TocLinks from '@/components/views/TocLinks';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'FormTOC',
  components: {
    ViewToolbox,
    TocLinks,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    items() {
      return [
        {
          title: 'Source',
          copy: 'which records to search',
          link: 'source',
          filters: this.activeView?.hasSourceFilters?.(),
        },
        {
          title: 'Settings',
          copy: 'options and settings',
          link: 'settings',
        },
        {
          title: 'Search Inputs',
          copy: 'add fields for users to search by',
          link: 'inputs',
        },
        {
          title: 'Search Results & Actions',
          copy: 'build the search results',
          link: `results/${this.viewRaw.results_type}`,
        },
      ];
    },
  },
};
</script>
