<template>
  <div class="payment-form">
    <div v-if="hasStripePaymentMethod">
      <form id="payment-form">
        <div class="control ecom-card-number">
          <label
            for="card-number"
            class="kn-label"
          >Card Number</label>
          <input
            id="card-number"
            type="text"
            placeholder="1234 1234 1234 1234"
          >
        </div>
        <div class="horizontal-payment">
          <div class="control card-expiration">
            <label class="kn-label">Expiration</label>
            <input
              type="text"
              placeholder="MM/YY"
            >
          </div>
          <div class="control">
            <label class="kn-label">CVC</label>
            <input
              type="text"
              placeholder="CVC"
            >
          </div>
        </div>
        <div
          v-if="showSavePaymentCheckbox"
          id="optional-store-payment-details"
        >
          <label>
            <input
              id="optional-store-payment-details-checkbox"
              name="optional-store-payment-details"
              type="checkbox"
            > Store Payment Details
          </label>
        </div>
        <button class="kn-button is-primary">
          {{ submitText }}
        </button>
      </form>
    </div>
    <div v-if="hasPayPalPaymentMethod && hasStripePaymentMethod">
      OR
    </div>
    <div v-if="hasPayPalPaymentMethod">
      <button class="kn-paypal-button kn-button">
        <span>Check out with</span>
        <img
          src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
          alt="PayPal"
        >
      </button>
    </div>
  </div>
</template>

<script>
import PaymentUtils from '@/components/settings/ecommerce/PaymentUtils';

export default {
  mixins: [
    PaymentUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  computed: {
    submitText() {
      const { submit_button_text: submitButtonText, ecommerce_test_mode: ecomTestMode } = this.view.attributes;

      if (ecomTestMode) {
        return `${submitButtonText} Test Mode`;
      }

      return submitButtonText;
    },
    showSavePaymentCheckbox() {
      return this.view.type === 'charge' && this.view.customerOptional;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-form {
  padding: 5px 7px;

  div {
    padding-top: 5px;
  }
}

.horizontal-payment {
  display: flex;
}

.control.card-expiration {
  padding-right: 20px;
}
</style>
