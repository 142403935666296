<script setup>
import isNil from 'lodash/isNil';
import { computed, defineProps } from 'vue';

import LinkRender from '@/components/renderer/records/LinkRender';
import ParagraphRender from '@/components/renderer/records/ParagraphRender';
import RatingRender from '@/components/renderer/records/RatingRender';
import SignatureRender from '@/components/renderer/records/SignatureRender';
import TruncateRender from '@/components/renderer/records/TruncateRender';

const DEFAULT_RENDER = 'DefaultRender';

/**
 * Use the fields type in order to determine whether to render a special component
 * or use the default rendering for a field.
 *
 * @param {object} props
 * @param {object} props.field
 * @param {boolean} props.truncate - enable text truncation
 * @return {string}
 */
const getComponentForField = ({ field, truncate }) => {
  const fieldTypeComponentsMapping = {
    action: LinkRender,
    signature: SignatureRender,
    rating: RatingRender,
    paragraph_text: truncate ? TruncateRender : ParagraphRender,
    rich_text: truncate ? TruncateRender : DEFAULT_RENDER,
    default: DEFAULT_RENDER,
  };

  if (!field || field.key === 'source') {
    return fieldTypeComponentsMapping.default;
  }

  // is this a formula showing a rating?
  if (field.rendersAsRating()) {
    return fieldTypeComponentsMapping.rating;
  }

  if (isNil(fieldTypeComponentsMapping[field.type])) {
    return fieldTypeComponentsMapping.default;
  }

  return fieldTypeComponentsMapping[field.type];
};

/**
 * Get the field that should be rendered.
 *
 * @param {object} props
 * @param {object} props.field
 * @return {object}
 */
const getRenderedField = ({ field }) => {
  if (field && field.isFormula()) {
    // check if child is rating
    const connectedField = field.getFormulaConnectedField();

    if (connectedField && connectedField.rendersAsRating()) {
      return connectedField;
    }
  }

  return field;
};

/**
 * Get the value to render for connections.
 *
 * @param {object} field
 * @param {object | string} modelValue
 * @param {object} viewItem
 * @param {string} displayValue
 * @return {string}
 */
const getRenderedConnectionValue = (field, modelValue, viewItem, displayValue) => {
  if (!modelValue) {
    if (displayValue) {
      return displayValue;
    }
    // A space ensures the proper item dimensions are rendered, otherwise it's not tall enough
    return ' ';
  }

  if (!Array.isArray(modelValue)) {
    return displayValue;
  }

  let separator = viewItem.conn_separator || field.conn_separator || 'new_line'; // comma | new_line

  separator = (separator === 'comma')
    ? ', '
    : '<br />';

  // We can't use the raw value data, but must split up the displayValue,
  // since fields from connected objects can include complex objects in the raw value
  const displaySeparator = displayValue.includes('<br />') ? '<br />' : ', ';
  const displayValues = displayValue.split(displaySeparator);

  return displayValues.map((val) => val?.identifier ?? val).join(separator);
};

/**
 * Get the value that should be rendered.
 *
 * @param {object} props
 * @param {object} props.field
 * @param {object | string} props.modelValue
 * @param {string} props.displayValue
 * @param {object} props.viewItem
 * @param {boolean} props.isConnectedValues
 * @return {string}
 */
const getRenderedValue = ({
  field, modelValue, displayValue, viewItem, isConnectedValues,
}) => {
  if (!field) {
    return displayValue;
  }

  if (field.type === 'connection' || isConnectedValues) {
    return getRenderedConnectionValue(field, modelValue, viewItem, displayValue);
  }

  return displayValue;
};

const props = defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
  field: {
    type: Object,
    default: null,
  },
  viewItem: {
    type: Object,
    default: () => ({}),
  },
  displayValue: {
    type: String,
    required: true,
  },
  modelValue: {
    type: null,
    required: true,
  },
  truncate: {
    type: Boolean,
    default: false,
  },
  modifyValueFunction: {
    type: Function,
    default: null,
  },

  // this means the values live in records connected to the view's main object.
  // These can be separated by commas or new lines
  isConnectedValues: {
    type: Boolean,
    default: false,
  },
});

const getValue = (componentName) => {
  let renderedValue = getRenderedValue(props);

  if (props.modifyValueFunction) {
    renderedValue = props.modifyValueFunction(renderedValue);
  }

  // Wrap the value with an anchor so the preview shows a link but only for default render.
  if (componentName === DEFAULT_RENDER && props.viewItem?.conn_link) {
    return `<a href="">${renderedValue}</a>`;
  }

  return renderedValue;
};

const componentToRender = computed(() => getComponentForField(props));
const renderValue = computed(() => getValue(componentToRender.value));
</script>

<script>
export default {
  name: 'FieldValueWrapper',
};
</script>

<template>
  <span
    v-if="componentToRender === DEFAULT_RENDER"
    v-html="renderValue"
  />
  <component
    :is="componentToRender"
    v-else
    :field="getRenderedField(props)"
    :model-value="props.modelValue"
    :view-item="props.viewItem"
    :display-value="renderValue"
    :editable="props.editable"
  />
</template>
