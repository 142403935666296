<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Email Text Format</label>
      <select
        v-model="fieldLocal.format.text_format"
        class="text-base py-2 pl-3 leading-5"
      >
        <option value="url">
          Use the Email address
        </option>
        <option value="same">
          Use the same text for all emails
        </option>
        <option value="unique">
          Use unique text for each email
        </option>
      </select>
    </div>
    <div v-if="fieldLocal.format.text_format === 'same'">
      <label
        for="label"
        class="text-default text-sm font-medium mb-2 leading-4"
      >
        Email Text
      </label>
      <input
        v-model="fieldLocal.format.label"
        name="label"
        type="text"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Email',
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
