<template>
  <tr class="kn-table-totals">
    <td v-if="showCheckboxes" />
    <td v-if="showBuilderLinks" />
    <td v-for="(column, columnIndex) in filteredColumns">
      <strong>{{ summaryForColumn(column, columnIndex, total) }}</strong>
    </td>
  </tr>
</template>

<script>
import isNil from 'lodash/isNil';
import { mapGetters } from 'vuex';
import TableSummariesUtils from '@/components/renderer/mixins/TableSummariesUtils';

export default {
  mixins: [
    TableSummariesUtils,
  ],
  props: {
    total: {
      type: Object,
      default: [],
    },
    columns: {
      type: Array,
      default: [],
    },
    records: {
      type: Array,
      default: [],
    },
    showCheckboxes: {
      type: Boolean,
      default: false,
    },
    showBuilderLinks: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'getField',
    ]),
    filteredColumns() {
      return this.columns.filter((column) => column.grouping === false);
    },
  },
  methods: {
    summaryForColumn(column, columnIndex, total) {
      const columnLabel = columnIndex === 0 ? total.label : '';

      let numOfEmptyRows = 0;

      if (column.ignore_summary || (column.type !== 'field' && column.link_type !== 'field')) {
        return columnLabel;
      }

      const field = this.getField(column.field.key);

      if (isNil(field)) {
        return columnLabel;
      }

      const format = field.get('format') || {};

      const isDateEquation = (field.get('type') === window.Knack.config.EQUATION && format.equation_type && format.equation_type === 'date' && (!format.date_result || format.date_result === 'date'));

      if (!window.Knack.config.fields[field.get('type')].numeric || field.get('type') === window.Knack.config.AUTO_INCREMENT || isDateEquation) {
        return columnLabel;
      }

      // to compute average, we need to know the total and the count. we can't just use row count because
      // blank values should not be factored into average
      let calc = total.calc === 'average' ? { sum: '', count: 0 } : '';

      this.records.forEach((record) => {
        let val = '';

        // timer?
        if (field.get('type') === window.Knack.config.TIMER) {
          val = record[`${field.key}_raw`] || '';

          if (val) {
            val = val.total_time;
          }

          calc = this.performCalculations(total.calc, calc, val);

          return calc;
        }

        if (field.get('type') === 'equation' && format.equation_type && format.equation_type === 'date') {
          return calc;
        }

        if (!record) {
          return;
        }

        val = record[`${field.key}_raw`];

        if (typeof val === 'undefined' || val === '') {
          numOfEmptyRows += 1;
        }

        calc = this.buildCalc(total, this.viewKey, column, field, calc, val);

        return calc;
      });

      // finish average calculation
      if (total.calc === 'average') {
        calc = (calc.count === 0 ? '' : calc.sum / calc.count);
      }

      calc = this.formatCalc(total, field, calc);

      const isEmptyColumn = numOfEmptyRows === this.records.length;

      if (isEmptyColumn) return '';

      return calc;
    },
  },
};
</script>

<style lang="scss">
tr.kn-table-totals {
  background-color: #eee !important;
}
</style>
