/**
 * @param {object} groups
 * @param {number} groupIndex
 * @param {number} columnIndex
 * @param {number} itemIndex
 */
export function splitGroupColumn(groups, groupIndex, columnIndex, itemIndex) {
  // get all the items in this column
  const colItems = groups[groupIndex].columns[columnIndex].keys.splice(0);

  // get the target item out of the current column for the new split group
  const pairedItem = colItems.splice(itemIndex, 1)[0];

  // get all the items after this one for a new group
  const afterItems = colItems.splice(itemIndex);

  // add items back to the original group
  groups[groupIndex].columns[columnIndex].keys = colItems;

  // splice in a new group with the new split column
  groups.splice(groupIndex + 1, 0, {
    columns: [
      {
        keys: [
          pairedItem,
        ],
      },
    ],
  });

  // splice in a new group with any remaining items
  if (afterItems.length) {
    groups.splice(groupIndex + 2, 0, {
      columns: [
        {
          keys: afterItems,
        },
      ],
    });
  }

  return groups;
}

/**
 * @param {object} groups
 * @param {string} viewKey
 * @returns {object}
 */
export function updateViewLocationKey(groups, viewKey) {
  return groups.map((group) => {
    group.columns = group.columns.map((column) => {
      column.keys = column.keys.map((key) => (key === 'new' ? viewKey : key));

      return column;
    });

    return group;
  });
}

/**
 * Gets the groups with the views filled in.
 *
 * @param {Page} page
 * @param {boolean} [excludeChildViews]
 * @returns {object}
 */
export function getFilledPageGroups(page, excludeChildViews) {
  const seenViewKeys = [];

  return page.groups.map((group) => ({
    columns: group.columns.map((column) => ({
      views: column.keys.map((viewKey) => page.getView(viewKey)).filter((view) => {
        if (!view) {
          return false;
        }

        if (seenViewKeys.includes(view.key)) {
          return false;
        }

        seenViewKeys.push(view.key);

        // don't include child views like "add connected record form"
        if (excludeChildViews && view.get('parent')) {
          return false;
        }

        return true;
      }),
    })),
  }));
}
