import { reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

const state = reactive({
  activeLinks: [],
  currentViewKey: null,
});

let stopWatcher = null;

export default function useMenuPreviewActiveLinks() {
  const route = useRoute();

  const toggleLinkActive = (linkIndex, viewKey) => {
    if (state.currentViewKey !== viewKey) {
      state.currentViewKey = viewKey;
      state.activeLinks = [];
    }

    const index = state.activeLinks.indexOf(Number(linkIndex));
    if (index > -1) {
      state.activeLinks.splice(index, 1);
    } else {
      state.activeLinks.push(Number(linkIndex));
    }
  };

  const isLinkActive = (linkIndex, viewKey) => {
    if (state.currentViewKey !== viewKey) {
      return false;
    }

    return state.activeLinks.includes(Number(linkIndex));
  };

  // Stop current watcher if there is one
  if (stopWatcher) {
    stopWatcher();
  }

  stopWatcher = watch(() => route.params, (newParams) => {
    // Clear active links if there is no linkIndex as a parameter
    if (!newParams.linkIndex && newParams.linkIndex !== '0') {
      state.activeLinks = [];
    }
  });

  return {
    toggleLinkActive,
    isLinkActive,
  };
}
