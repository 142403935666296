<template>
  <svg
    style="width:0; height:0; position:absolute;"
    aria-hidden="true"
    focusable="false"
  >
    <linearGradient
      id="svg-brand-gradient"
      x2="1"
      y2="1"
    >
      <stop
        offset="0%"
        stop-color="#86307B"
      />
      <stop
        offset="100%"
        stop-color="#E61875"
      />
    </linearGradient>
  </svg>
  <svg
    style="width:0; height:0; position:absolute;"
    aria-hidden="true"
    focusable="false"
  ><linearGradient
    id="svg-bg-gradient"
    x1="00%"
    y1="100%"
    x2="0%"
    y2="0%"
  ><stop
    offset="0%"
    stop-color="#7a226b"
  /><stop
    offset="100%"
    stop-color="#ff5dae"
  /></linearGradient></svg>
  <svg
    aria-hidden="true"
    focusable="false"
    style="width: 0px; height: 0px; position: absolute;"
  >
    <linearGradient
      id="svg-gradient-2"
      x1="100%"
      y1="100%"
      x2="0%"
      y2="0%"
    >
      <stop
        stop-color="#AC519D"
        offset="0%"
      />
      <stop
        stop-color="#FF5DAE"
        offset="87.50%"
      />
    </linearGradient>
  </svg>
  <svg
    aria-hidden="true"
    focusable="false"
    style="width: 0px; height: 0px; position: absolute;"
  >
    <linearGradient
      id="svg-gradient-1"
      x1="100%"
      y1="100%"
      x2="0%"
      y2="0%"
    >
      <stop
        stop-color="#86307B"
        offset="0%"
      />
      <stop
        stop-color="#E61875"
        offset="87.50%"
      />
    </linearGradient>
  </svg>
</template>

<script>
export default {
  name: 'SvgGradients',
};
</script>
