<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Labels</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="fieldLocal.format.format"
          type="radio"
          value="yes_no"
        >Yes/No</label>
        <label><input
          v-model="fieldLocal.format.format"
          type="radio"
          value="on_off"
        >On/Off</label>
        <label><input
          v-model="fieldLocal.format.format"
          type="radio"
          value="true_false"
        >True/False</label>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Default</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="fieldLocal.format.default"
          type="radio"
          :value="true"
        >{{ yesLabel }}</label>
        <label><input
          v-model="fieldLocal.format.default"
          type="radio"
          :value="false"
        >{{ noLabel }}</label>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Require "{{ capitalize(yesLabel) }}"</label>
      <div class="input-labels tw-input-labels">
        <label class="items-baseline"><input
          v-model="requiredVal"
          type="checkbox"
        >Required</label>
      </div>
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Input</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="fieldLocal.format.input"
          type="radio"
          value="dropdown"
        >Drop-down</label>
        <label><input
          v-model="fieldLocal.format.input"
          type="radio"
          value="radios"
        >Radio Buttons</label>
        <label><input
          v-model="fieldLocal.format.input"
          type="radio"
          value="checkbox"
        >Checkbox</label>
      </div>
    </div>
    <div v-if="fieldLocal.format.input === `checkbox`">
      <label class="text-default text-sm font-medium mb-2 leading-4">Checkbox Text</label>
      <div class="input-labels tw-input-labels">
        <input
          v-model="fieldLocal.format.text"
          data-cy="checkbox-text"
          type="text"
        >
      </div>
    </div>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import hasIn from 'lodash/hasIn';

export default {
  name: 'Boolean',
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labels: {
        yes_no: {
          yes: 'Yes',
          no: 'No',
        },
        on_off: {
          yes: 'On',
          no: 'Off',
        },
        true_false: {
          yes: 'True',
          no: 'False',
        },
      },
    };
  },
  computed: {
    yesLabel() {
      return this.labels[this.fieldLocal.format.format].yes;
    },
    noLabel() {
      return this.labels[this.fieldLocal.format.format].no;
    },
    requiredVal: {
      get() {
        // Note: `required` should be set on field and field format should be the same
        // so just picking the "top level" value for get
        return this.fieldLocal.required;
      },
      set(newVal) {
        this.fieldLocal.required = newVal;
        this.fieldLocal.format.required = newVal;
      },
    },
  },
  mounted() {
    // if field.format.default doesn't exist create it & set it to false
    // source: boolean-v2.html:14
    if (!hasIn(this.fieldLocal.format, 'default')) {
      this.fieldLocal.format.default = false;
    }
  },
  methods: {
    capitalize,
  },
};
</script>
