<template>
  <div
    :class="[
      data.class,
      data.staticClass,
      `kn-item-label${itemLabelAlignment}`,
      'clearfix',
      'kn-item',
    ]"
    :style="[data.style, data.staticStyle]"
  >
    <template v-if="item.type === 'divider'">
      <hr class="kn-item-divider">
    </template>
    <template v-else>
      <label
        v-if="showLabel"
        class="kn-item_label"
        :style="labelStyle"
        v-html="item.name"
      />
      <div class="kn-value kn-item_value">
        <template v-if="itemIsLinkType(item.type)">
          <ViewLink
            :item-schema="item"
            :icon="item.icon"
            :link-type="getItemLinkType(item.type)"
          >
            <FieldValueWrapper
              v-if="['scene', 'scene_link'].includes(item.type) && item.link_type === 'field'"
              :field="field"
              :is-connected-values="isShowingConnectedValues"
              :view-item="item"
              :model-value="fieldValueRaw"
              :modify-value-function="modifyValue"
              :display-value="fieldValue"
            />
            <template v-else>
              <span v-html="renderContent(getViewLinkText(item))" />
            </template>
          </ViewLink>
        </template>
        <template v-else>
          <FontAwesome
            v-if="item?.icon?.icon && item?.icon?.align === 'left'"
            :name="sanitizedIcon"
            class="fa-margin-right"
          />

          <FieldValueWrapper
            v-if="renderAsField"
            :field="field"
            :is-connected-values="isShowingConnectedValues"
            :view-item="item"
            :model-value="fieldValueRaw"
            :modify-value-function="modifyValue"
            :display-value="fieldValue"
          />
          <span
            v-else
            v-html="renderContent(itemContent)"
          />

          <FontAwesome
            v-if="item?.icon?.icon && item?.icon?.align === 'right'"
            :name="sanitizedIcon"
            class="fa-margin-left"
          />
        </template>

        <ViewChildLink
          v-if="isEditable && (item.type === 'scene_link' || item.type === 'scene')"
          tooltip="Navigate to this page"
          :url="(typeof item.scene) === 'string' ? `/pages/${getLinkPageKey}` : ''"
        />
      </div>
    </template>

    <div
      v-tippy="{
        delay: [500, 100],
      }"
      content="Click to edit, drag to move"
      class="overlay"
      @click="onActivateItem"
    />

    <div class="item-links gap-1 bg-subtle rounded-t-lg border-b-0 p-1">
      <a
        v-tippy
        content="Edit this details"
        class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
      >
        <Icon
          class="text-default"
          type="edit"
        />
      </a>
      <span
        v-tippy
        content="Delete this details"
        class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
        @click.prevent="onDeleteItem"
      >
        <Icon
          class="text-default"
          type="delete"
        />
      </span>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import FieldValueWrapper from '@/components/renderer/records/FieldValueWrapper';
import ViewChildLink from '@/components/renderer/shared/ViewChildLink';
import FontAwesome from '@/components/ui/FontAwesome';
import Icon from '@/components/ui/Icon';
import ViewLink from '@/components/shared/ViewLink';

export default {
  name: 'DetailsItem',
  components: {
    FieldValueWrapper,
    FontAwesome,
    Icon,
    ViewChildLink,
    ViewLink,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      required: true,
    },
    viewLabelAlignment: {
      type: String,
      default: 'left',
      required: false,
    },
    labelWidth: {
      type: String,
      required: true,
      default: 'unset',
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'delete:item',
  ],
  computed: {
    field() {
      return this.$store.getters.getField(this.item.key);
    },

    fieldValue() {
      let value = this.data[this.itemKey];

      // If content is blank we want to show a placeholder value so there's something to click on
      if (value === '' || isNil(value)) {
        value = `<em style="opacity: .6;">${this.item.name} value</em>`;
      }

      return String(value);
    },

    /**
     * A connection property exists when the item is displaying fields from connected records.
     * `connection` stores the field key of the connection being used.
     *
     * @return {boolean}
     */
    isShowingConnectedValues() {
      return Boolean(this.item.connection);
    },

    fieldValueRaw() {
      const recordValueRaw = this.data[`${this.itemKey}_raw`];

      // we need to use both isNil and isEmpty for our check here
      // because these values can be: numbers, strings, or objects
      // and for example !isEmpty(10) returns false
      if (!isNil(recordValueRaw) || !isEmpty(recordValueRaw)) {
        return cloneDeep(recordValueRaw);
      }

      const recordValue = this.data[this.item.key];

      if (!isNil(recordValue) || !isEmpty(recordValue)) {
        return cloneDeep(recordValue);
      }

      if (!this?.field || !this.field?.field) {
        return null;
      }

      const { field } = this.field;

      return field.getDefaultValue();
    },

    renderAsField() {
      if (!this.item.type || this.item.type === 'field') {
        return true;
      }

      if (this.isLink && this.item?.link_type === 'field') {
        return true;
      }

      return false;
    },

    isLink() {
      return ['scene_link', 'link', 'scene'].includes(this.item.type);
    },

    itemKey() {
      let itemKey = this.item.key;

      // If the item has a connection, use the key of the connected record
      if (this.item.connection?.key) {
        itemKey = `${this.item.connection.key}.${itemKey}`;
      }

      return itemKey;
    },

    itemLabelAlignment() {
      if (!this.item.format?.label_custom) {
        return this.viewLabelAlignment;
      }

      return this.item.format.label_format;
    },

    showLabel() {
      if (this.itemLabelAlignment === 'none') {
        return false;
      }

      return (![
        'divider',
        'copy',
        'special_title',
        'delete',
        'scene_link',
        'action_link',
      ].includes(this.item.type) && !isNil(this.item.type));
    },

    labelStyle() {
      if (this.itemLabelAlignment === 'top') {
        return {
          width: '100%',
        };
      }

      return {
        minWidth: this.labelWidth,
        maxWidth: this.labelWidth,
      };
    },

    getLinkPageKey() {
      const page = this.$store.getters.getPageBySlug(this.item.scene);

      // TypeError protection while rendering a preview of the view before saving for the first time
      return page?.key || '';
    },

    sanitizedIcon() {
      const { icon } = this.item.icon;

      if (icon.indexOf('fa-') > -1) {
        return icon.slice(3);
      }

      return icon;
    },

    itemContent() {
      const { item } = this;

      // divider
      if (item.type === 'divider') {
        return '<hr />';
      }

      // copy
      if (item.type === 'copy' || item.type === 'special_title') {
        let content;

        if (item.value) {
          content = `<h3 class="kn-item_heading">${item.value}</h3>`;
        }

        if (item.copy) {
          content += `<p class="kn-item_content">${item.copy}</p>`;
        }

        return content;
      }

      // delete links
      if (item.type === 'delete') {
        return `<a class="kn-item_link">${item.link_text}</a>`;
      }

      // TODO:ActionLinks: this should be evaluated like a display rule, Eg. checkRule() in V2
      if (item.type === 'action_link') {
        if (isEmpty(item.action_rules) || isEmpty(item.action_rules[0].link_text)) {
          return `<a class="kn-item_link">${item.link_text}</a>`;
        }

        return `<a class="kn-item_link">${item.action_rules[0].link_text}</a>`;
      }

      // page links
      // link and scene types come from the add-view wizard
      if (this.isLink) {
        return `<a class="kn-item_link">${item.link_text}</a>`;
      }

      return this.fieldValue;
    },
  },
  methods: {
    getItemLinkType(itemType) {
      if (itemType === 'action_link') {
        return 'action';
      }
      if (itemType === 'link' || itemType === 'scene_link') {
        return 'page'; // the default are links to child pages
      }
      return itemType;
    },

    itemIsLinkType(itemType) {
      return ['link', 'scene_link', 'action_link', 'delete'].includes(itemType);
    },

    getViewLinkText(item) {
      if (item.type === 'action_link') {
        return item.action_rules[0]?.link_text || '';
      }
      return item.link_text;
    },

    onActivateItem(event) {
      return event.target.nextElementSibling.querySelector('a').click();
    },

    onDeleteItem(event) {
      this.$emit('delete:item', event);
    },

    modifyValue(content) {
      return this.renderContent(content);
    },

    renderContent(content, props) {
      if (!this.item.format || !this.item.format.styles) {
        return content;
      }

      let styledContent = '';

      this.item.format.styles.forEach((style) => {
        styledContent += `<${style}>`;
      });

      styledContent += content;
      this.item.format.styles.slice(0).reverse().forEach((style) => {
        styledContent += `</${style}>`;
      });

      return styledContent;
    },
  },
};
</script>

<style lang="scss" scoped>
.kn-item {
    position: relative;
    padding: 2px 6px 6px 4px;
    max-width: 600px;
    border-radius: .3em;
    border-top-left-radius: 0;
    border: 1px solid #fff;
    cursor: move;
    display: flex;

    input, textarea {
      padding: 0.4em .5em;
      font-size: 1em;
      line-height: 1.25em;
      border-radius: .3em;
      color: #4b5054;
      width: 100%;
      border: 1px solid #d6d6de;
      font-weight: 300;
    }
  }

  .kn-item-labelnone{
    .kn-item_label {
      display: none;
    }
  }

  .kn-item-labelleft {
    .kn-item_label {
      text-align: left;
    }
  }

  .kn-item-labelright {
    .kn-item_label {
      text-align: right;
    }
  }

  .kn-item-labeltop {
    flex-direction: column;
    .kn-item_label {
      width: 100%;
    }
  }

  .kn-item_heading {
    margin-bottom: 0;
  }

  .kn-item_label {
    display: block;
    font-weight: 500;
    padding: .2em;
    background-color: #e5e5e5;
    flex-shrink: 0;
  }

  .kn-item-divider {
    width: 100%;
    border-top: 1px solid #A3ABAE;
    padding: 0;
    margin: .75rem 0;
  }
</style>
