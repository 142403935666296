<template>
  <div class="kn-checkbox">
    <div
      v-for="option in options"
      :key="option.label"
      class="control"
    >
      <label class="option checkbox">
        <input
          v-model="inputValue"
          data-cy="kn-checkbox-true"
          type="checkbox"
          class="checkbox"
          :name="name"
          @change="$emit('update:modelValue', inputValue)"
        >
        {{ checkboxText }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    options: {
      type: Array,
    },
    checkboxText: {
      type: String,
    },
    modelValue: {
      type: [
        String,
        Boolean,
      ],
    },
    valueRaw: {
      type: Array,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    const truthyValues = [
      true,
      'True',
      'Yes',
      'On',
    ];

    return {
      inputValue: truthyValues.includes(this.modelValue),
      inputValueRaw: this.valueRaw,
    };
  },
  methods: {
    onChangeValue(newValue) {
      if (newValue === 'true' || newValue === 'false') {
        this.$emit('update:modelValue', newValue === 'true');

        return;
      }

      this.$emit('update:modelValue', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.option {
  display: flex;
  align-items: center;
}

label.option.checkbox {
  font-weight: 400;
  font-style: normal;
}
</style>
