import {
  addLocalView,
  deleteLocalView,
  replaceLocalView,
  updateLocalView,
} from '@/lib/page/page-view-helper';
import { NEW_VIEW_KEY } from '@/lib/page/page-constants';
import Page from '@/store/models/Page';

const storeState = {};
const storeGetters = {};
const storeMutations = {};

const storeActions = {

  /**
   * Adds a view in the local page object (not on the server).
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {object} context.rootGetters
   * @param {object} payload
   * @param {object} payload.viewData
   * @param {Page} [payload.addPage] - If provided the view will be added to this page object.
   * @param {string} [payload.rawPageKey] - If provided the view will be added to the raw page object for this key.
   * @param {string} [payload.locationType]
   * @param {number} [payload.groupIndex] - Use -1 to push.
   * @param {number} [payload.columnIndex]
   * @param {number} [payload.itemIndex]
   * @returns {Promise<View>}
   */
  async addViewLocally(
    { commit, rootGetters },
    {
      viewData, addPage, rawPageKey, locationType, groupIndex, columnIndex, itemIndex,
    },
  ) {
    log(
      'page-views.addViewLocally()',
      {
        viewData, addPage, rawPageKey, locationType, groupIndex, columnIndex, itemIndex,
      },
    );

    let page;
    let isActivePage = false;

    /** @type {Page} activePage */
    const { activePage, activeView } = rootGetters;

    if (addPage) {
      page = addPage;
    } else if (rawPageKey) {
      page = rootGetters.getPageByKey(rawPageKey);
    } else {
      page = activePage;
      isActivePage = true;
    }

    if (!page) {
      return;
    }

    const newView = addLocalView(
      page,
      viewData,
      locationType,
      groupIndex,
      columnIndex,
      itemIndex,
    );

    // If there's a live preview with a preview ID, then the preview data used during the create process will likely not be accurate. Reload using the preview ID.
    const loadData = Boolean(rootGetters.pagePreviewId) || (newView.source && newView.source.object);

    // Also check to see if we should add the view to the active page.
    const shouldUpdateActivePage = (!isActivePage && activePage && activePage.key === page.key);

    if (shouldUpdateActivePage) {
      activePage.setView(newView);

      activePage.groups = page.groups;

      if (activeView && activeView.key === viewData.key) {
        commit('activeView', activePage.getView(viewData.key), { root: true });
      }
    }

    // See if we should load the new view preview data.
    if ((page instanceof Page || shouldUpdateActivePage) && loadData) {
      log('page-views.addViewLocally() - Loading view data');

      newView.loadData();
    }

    return newView;
  },

  /**
   * Deletes a view in the local page object (not on the server).
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {object} context.rootGetters
   * @param {object} payload
   * @param {string} [payload.rawPageKey]
   * @param {string} payload.viewKey
   * @returns {Promise}
   */
  async deleteViewLocally(
    { commit, rootGetters },
    { rawPageKey, viewKey },
  ) {
    log('page-views.deleteViewLocally()', { rawPageKey, viewKey });

    /** @type {Page} activePage */
    const { activePage } = rootGetters;
    const page = (rawPageKey) ? rootGetters.getPageByKey(rawPageKey) : activePage;

    if (!page) {
      return;
    }

    deleteLocalView(page, viewKey);

    // Also check to see if we should remove the view from the active page.
    // If the view being deleted is a new view added by addViewLocally we need to ensure
    // it's removed from the active page.
    const shouldRemoveViewFromActivePage = (rawPageKey && activePage && activePage.key === page.key)
      || viewKey === NEW_VIEW_KEY;

    if (shouldRemoveViewFromActivePage) {
      activePage.removeView(viewKey);

      activePage.groups = page.groups;
    }
  },

  /**
   * Deletes a view in the local page object (not on the server).
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {object} context.rootGetters
   * @param {object} payload
   * @param {{}} payload.viewData
   * @returns {Promise<View | undefined>}
   */
  async replaceViewLocally(
    { commit, rootGetters },
    { viewData },
  ) {
    log('page-views.replaceViewLocally()', { viewData });

    /** @type {Page} activePage */
    const { activePage } = rootGetters;

    if (!activePage) {
      return;
    }

    return replaceLocalView(activePage, viewData);
  },

  /**
   * Updates a view in the local page object (not on the server).
   *
   * @param {object} context
   * @param {function(string, object)} context.commit
   * @param {object} context.rootGetters
   * @param {object} payload
   * @param {string} payload.rawPageKey
   * @param {string} payload.viewKey
   * @param {{}} payload.viewUpdates
   * @returns {Promise}
   */
  async updateViewLocally(
    { commit, dispatch, rootGetters },
    { rawPageKey, viewKey, viewUpdates },
  ) {
    log('page-views.updateViewLocally()', { rawPageKey, viewKey, viewUpdates });

    const { activePage } = rootGetters;
    const page = rootGetters.getPageByKey(rawPageKey);

    if (!page) {
      return;
    }

    updateLocalView(page, viewKey, viewUpdates);

    // Also check to see if we should update the active page's view.
    if (activePage && activePage.key === page.key) {
      const activePageView = activePage.getView(viewKey);

      if (activePageView) {
        activePageView.setView(viewUpdates);
      }
    }

    // Check login views in case view.allowed_profiles needs to sync page.authentication_profiles
    if (viewUpdates?.type === 'login') {
      page.authentication_profiles = viewUpdates.allowed_profiles || [];

      if (activePage && activePage.key === page.key) {
        activePage.authentication_profiles = viewUpdates.allowed_profiles || [];
      }

      // Process page will push these changes to all parents and children of the page.
      await dispatch('page/processPage', { page }, { root: true });
    }
  },
};

export default {
  // Full namespace: page/view
  view: {
    namespaced: true,
    state: storeState,
    getters: storeGetters,
    mutations: storeMutations,
    actions: storeActions,
  },
};
