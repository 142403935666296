<template>
  <div
    id="kn-layout"
    :class="{ 'preview-layout': isPreview, builderLayout: true }"
  >
    <GlobalLoading :is-loading="isLoadingRequest" />

    <template v-if="showBuilderLayout">
      <Topbar
        v-if="appIsLoaded"
        class="builderLayout_topbar bg-white h-16"
      />
      <Sidebar
        v-if="appIsLoaded"
        class="builderLayout_sidebar"
      />
      <div
        id="content"
        class="builderLayout_builderContent rounded-tl-lg overflow-auto"
      >
        <BillingAddressCheck v-if="showBillingBanner" />
        <RouterView />
        <Transition name="slide-in-right">
          <Help v-if="showHelpDrawer" />
        </Transition>
      </div>
    </template>

    <template v-if="showAppStatus">
      <div
        id="content_full"
        class="builderLayout_fullContent"
      >
        <div class="appStatus">
          <EmptyState
            v-if="accountStatusIsTrialExpired"
            title="Your Knack trial has expired"
            description="Please select a plan to keep using your app."
            image="empty-state_xl_warning"
            action="Select a plan"
            @action="routeToUrl('/#billing')"
          />
          <EmptyState
            v-if="accountIsSuspended"
            title="Your Knack account has been suspended"
            description="We had issues charging your credit card, so your account is currently suspended. Please update your card to restore access."
            image="empty-state_xl_warning"
            action="Update your payment method"
            @action="routeToUrl('/#billing')"
          />
          <EmptyState
            v-else-if="accountStatusIsFrozen"
            title="Your Knack account is frozen"
            description="This account has been frozen. You can unfreeze this account to activate this app."
            image="empty-state_xl_warning"
            action="Unfreeze your account"
            @action="routeToUrl('/#billing')"
          />
          <EmptyState
            v-if="accountStatusIsCancelled"
            title="Your Knack account has been cancelled"
            description="Create a new account or reach out to Knack support if you believe this is an error."
            image="empty-state_xl_warning"
            action="Create a new account"
            @action="routeToUrl('/#register')"
          />
        </div>
      </div>
    </template>

    <InactivityTimeout v-if="appIsLoaded" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isKnackInternal, isTrial } from '@/lib/plan-helper';
import BillingAddressCheck from '@/components/user/BillingAddressCheck';
import EmptyState from '@/components/ui/EmptyState';
import GlobalLoading from '@/components/ui/GlobalLoading';
import Help from '@/components/builder/help/Help';
import InactivityTimeout from '@/components/renderer/shared/InactivityTimeout';
import Sidebar from '@/components/Sidebar';
import Topbar from '@/components/Topbar';

export default {
  name: 'App',
  components: {
    EmptyState,
    Sidebar,
    Topbar,
    GlobalLoading,
    InactivityTimeout,
    Help,
    BillingAddressCheck,
  },
  computed: {
    ...mapGetters([
      'app',
      'accountStatusIsActive',
      'accountStatusIsTrialExpired',
      'accountStatusIsFrozen',
      'accountStatusIsCancelled',
      'accountIsSuspended',
      'isLoadingRequest',
      'isSharedBuilder',
    ]),
    isPreview() {
      return this.$route.path.includes('/live-app-preview');
    },
    appIsLoaded() {
      return Boolean(this.app && this.app.get);
    },
    showBuilderLayout() {
      if (!this.appIsLoaded) {
        return true;
      }

      if (this.appIsLoaded && !this.showAppStatus) {
        return true;
      }

      return false;
    },
    showAppStatus() {
      if (this.appIsLoaded && !this.accountStatusIsActive) {
        return true;
      }

      return false;
    },
    showHelpDrawer() {
      return this.$store.getters.isHelpDrawerExpanded(this.$route);
    },
    showBillingBanner() {
      if (!this.appIsLoaded) {
        return false;
      }

      // No banner if the account is internal plan or a trial plan
      if (isKnackInternal() || isTrial()) {
        return false;
      }

      return this.app.attributes.showBillingAddressPrompt;
    },
  },
  methods: {
    routeToUrl(url) {
      log('routeTo', url);

      window.location = url;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/scss/loader.scss';

.appStatus {
  height: 100vh;
  width: 100%;
}

.appStatus > * {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
