<template>
  <div class="border border-solid border-subtle border-x-0 border-t-0">
    <label class="text-default text-sm font-medium mb-2 leading-4 tw-toolbox-label mt-0.5">Pagination</label>
    <select
      v-model="localRowsPerPage"
      class="margin-bottom-half text-base py-2 pl-3 leading-5"
    >
      <option value="10">
        Display 10 records at a time
      </option>
      <option value="25">
        Display 25 records at a time
      </option>
      <option value="50">
        Display 50 records at a time
      </option>
      <option value="100">
        Display 100 records at a time
      </option>
    </select>
    <div class="input-labels tw-input-labels mt-2">
      <label><input
        v-model="localAllowLimt"
        type="checkbox"
      >Let the user change the amount</label>
      <label><input
        v-model="localDisplayBelow"
        type="checkbox"
      >Repeat pagination controls below the view</label>
    </div>
  </div>
</template>

<div class="clearfix">
      </div>

<script>

export default {
  props: {
    rowsPerPage: {
      type: String,
      default: '25',
    },
    allowLimit: {
      type: Boolean,
      default: false,
    },
    displayBelow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:allowLimit', 'update:displayBelow', 'update:rowsPerPage'],
  data() {
    return {
      localRowsPerPage: this.rowsPerPage,
      localAllowLimt: this.allowLimit,
      localDisplayBelow: this.displayBelow,
    };
  },
  watch: {
    localRowsPerPage(newVal) {
      this.$emit('update:rowsPerPage', this.localRowsPerPage);
    },

    localAllowLimt(newVal) {
      this.$emit('update:allowLimit', this.localAllowLimt);
    },

    localDisplayBelow(newVal) {
      log('change: localAllowLimt!!!!');
      this.$emit('update:displayBelow', this.localDisplayBelow);
    },
  },
};
</script>
