<template>
  <div
    :style="`width: ${width}px; height: ${height}px`"
  />
</template>

<script>
import {
  Map, NavigationControl, Marker, LngLatBounds,
} from 'maplibre-gl';
import isNil from 'lodash/isNil';
import RuleHelper from '@knack/rules-helper';
import { mapGetters } from 'vuex';

export default {
  name: 'MapLibreMap',
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    records: {
      type: Array,
      default: () => [],
    },
    view: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      map: null,
    };
  },
  computed: {
    ...mapGetters([
      'app',
      'getField',
    ]),
  },
  mounted() {
    const apiKey = process.env.VUE_APP_MAPTILER_API_KEY;

    this.map = new Map({
      container: this.$el,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${apiKey}`,
      center: [-73.985708, 40.75773],
      zoom: 15,
    });

    this.map.addControl(new NavigationControl(), 'top-right');

    this.renderPins(this.records);
  },
  methods: {
    renderPins(records) {
      if (records.length === 0) return;

      const bounds = new LngLatBounds();

      const pinColorRules = this.view.get('pin_colors');

      records.forEach((record) => {
        const address = record[`${this.view.get('address_field').key}_raw`];

        if (isNil(address) || isNil(address.latitude)) {
          return;
        }

        let pinColor = this.view.get('pin_color_default') || '#FE7569';

        pinColorRules.forEach((rule) => {
          const passed = RuleHelper.checkRule(
            rule,
            record[`${rule.field}_raw`],
            this.getField(rule.field),
            this.app,
          );

          if (passed) {
            pinColor = rule.color;
          }
        });

        const marker = new Marker({ color: pinColor })
          .setLngLat([address.longitude, address.latitude]);

        bounds.extend([address.longitude, address.latitude]);
        marker.addTo(this.map);
      });

      if (!bounds.isEmpty()) {
        this.map.fitBounds(bounds, { padding: 100, maxZoom: 13, animate: false });
      }
    },
  },
};
</script>

<style scoped>
@import '~maplibre-gl/dist/maplibre-gl.css';
</style>
