<template>
  <ActionList
    :items="items"
    :default-item="defaultItem"
    :nested="nested"
    :can-be-empty="canBeEmpty"
    :start-with-default="startWithDefault"
    :empty-text="emptyText"
    :hide-buttons="hideButtons"
    class="field-list"
    @delete:item="$emit('delete:item')"
    @update:items="$emit('update:items', $event)"
  >
    <template #default="{item, itemIndex}">
      <FieldListItem
        :item="item"
        :default-item="defaultItem"
        :item-index="itemIndex"
        :object-key="objectKey"
        :available-fields="availableFields"
        :show-text-field="showTextField"
        :value-can-be-field="valuesCanBeField"
        :values-restrict-calculated-fields="valuesRestrictCalculatedFields"
        :show-boolean-options="showBooleanOptions"
        :boolean-match="booleanMatchLocal"
        :logged-in-user-roles="loggedInUserRoles"
        :use-validation-operators="useValidationOperators"
        :rule-type="ruleType"
        :is-toolbox-child="isToolboxChild"
        @update-boolean-match="onUpdateBooleanMatch"
        @update:item="onUpdateItem(itemIndex, $event)"
      />
    </template>
  </ActionList>
</template>
<script>
import ActionList from '@/components/ui/lists/ActionList';
import FieldListItem from '@/components/ui/lists/FieldListItem';

export default {
  components: {
    ActionList,
    FieldListItem,
  },
  props: {
    objectKey: {
      type: String,
      default: '',
    },
    availableFields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    canBeEmpty: {
      type: Boolean,
      default: false,
    },
    startWithDefault: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: () => '',
    },
    valuesRestrictCalculatedFields: {
      type: Boolean,
      default: false,
    },
    valuesCanBeField: {
      type: Boolean,
      default: false,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    showTextField: {
      type: Boolean,
      default: false,
    },

    // can map a connection to a user role "To the logged-in Account"
    loggedInUserRoles: {
      type: Array,
      default: () => [],
    },

    // like adding and/or
    showBooleanOptions: {
      type: Boolean,
      default: false,
    },
    booleanMatch: {
      type: String,
      default: 'and',
    },
    useValidationOperators: {
      type: Boolean,
      default: false,
    },
    ruleType: {
      type: String,
      default: '',
    },
  },
  emits: [
    'delete:item',
    'update:booleanMatch',
    'update:items',
  ],
  data() {
    return {

      // boolean match is the and/or dropdown that can optionally precede a criteria item in some criteria lists
      booleanMatchLocal: this.booleanMatch,
      isToolboxChild: false,
    };
  },
  computed: {
    object() {
      return this.$store.getters.getObject(this.objectKey);
    },
  },
  mounted() {
    if (!this.$el?.closest || typeof this.$el.closest !== 'function') {
      return;
    }

    // TODO: This needs to be a passed in prop because this.$el.closest is not reliable.
    this.isToolboxChild = Boolean(this.$el.closest('.toolbox'));
  },
  methods: {
    onUpdateBooleanMatch(newValue) {
      this.booleanMatchLocal = newValue;

      this.$emit('update:booleanMatch', newValue);
    },
    onUpdateItem(itemIndex, newItem) {
      const itemsCopy = this.items.slice();
      itemsCopy[itemIndex] = newItem;

      this.$emit('update:items', itemsCopy);
    },
    defaultItem() {
      let field = null;
      let fieldKey = null;

      if (this.availableFields.length) {
        fieldKey = this.availableFields[0].value;
        field = this.$store.getters.getField(fieldKey.split('-').pop());
      } else {
        field = this.object.fields[0];
        fieldKey = field.key;
      }

      if (this.valuesCanBeField) {
        return {
          field: fieldKey,
          operator: field.getFirstRuleOperator(),
          value: field.getFilterDefault(),
          value_type: 'custom',
          value_field: field.key,
        };
      }

      return {
        field: fieldKey,
        operator: field.getFirstRuleOperator(),
        value: field.getFilterDefault(),
      };
    },
  },
};
</script>

<style lang="scss">
.field-list {
  .field-list-item {
    display: flex;
    // flex-grow: 1;

    > * {
      margin-right: .5em;
    }
    > *:last-child {
      margin-right: 0;
    }
  }
}
</style>
