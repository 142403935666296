<template>
  <div class="kn-divider">
    <hr>
  </div>
</template>

<script>
export default {
  name: 'DividerStatic',
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
