<template>
  <div
    :class="{'is-single-line': isSingleLine}"
    class="kn-input-rich_text"
    data-cy="rich-text-input"
  >
    <Redactor
      ref="richText"
      v-model="localValue"
      :input="input"
      :has-fields-list="hasFieldsList"
      :config="redactorOptions"
    />
    <FieldsListDropdown
      v-if="hasFieldsList"
      :object="localObject"
      :show-connection-fields="showConnectionFields"
      :additional-options="nonFieldTemplateOptions"
      class="hover:bg-transparent"
      :class="richTextButtonClasses"
      @click="handleFieldClick"
    />
  </div>
</template>

<script>
import unescape from 'lodash/unescape';
import Redactor from '@/lib/redactor/VueRedactor';

import FieldsListDropdown from '@/components/ui/lists/FieldsListDropdown';
import { FieldTemplateConverter, nonFieldTemplateOptions } from '@/util/FieldTemplates';

export default {
  name: 'RichTextInput',
  components: {
    Redactor,
    FieldsListDropdown,
  },
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    object: {
      type: Object,
      default: () => null,
    },
    modelValue: {
      type: String,
      default: '',
    },
    showConnectionFields: {
      type: Boolean,
      default: true,
    },
    hasFieldsList: {
      type: Boolean,
      default: false,
    },
    fieldListRow: {
      type: Number,
      default: 1,
    },
    excludeButtons: {
      type: Array,
      default: () => [],
    },
    excludePlugins: {
      type: Array,
      default: () => [],
    },
    additionalOptions: {
      type: Object,
      default: () => {},
    },
    isSingleLine: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    const buttons = [
      'html',
      'formatting',
      'bold',
      'italic',
      'deleted',
      'link',
      'unorderedlist',
      'orderedlist',
      'outdent',
      'indent',
      'alignment',
      'horizontalrule',
    ].filter((button) => !this.excludeButtons.includes(button));

    const plugins = [
      'fontcolor',
      'image_web_link',
      'video',
      'table',
    ].filter((plugin) => !this.excludePlugins.includes(plugin));

    return {
      localValue: '',
      redactorApp: {},
      redactorOptions: {
        autoresize: true,
        removeComments: true,
        buttons,
        plugins,
        ...this.additionalOptions,
      },
      // a convertor util that uses regex to replace field keys with names and vice versa in the rich text content
      fieldConverter: null,
      nonFieldTemplateOptions: null,
    };
  },
  computed: {
    localObject() {
      return this.object || this.$store.getters.getObject(this.field.objectKey);
    },
    richTextButtonClasses() {
      return {
        'rich_text-button': true,
        [`rich_text-button--${this.fieldListRow}`]: this.fieldListRow > 1,
      };
    },
  },
  watch: {
    localValue(newValue) {
      // necessary because VueRedactor escapes HTML character entities
      const unescaped = unescape(newValue);

      this.$emit('update:modelValue', this.fieldConverter.replaceFieldNamesWithKeys(unescaped));
    },
  },
  created() {
    this.initFieldConverter();

    this.localValue = this.fieldConverter.replaceFieldKeysWithNames(this.modelValue);
  },
  methods: {
    handleFieldClick(interpolatedFieldName) {
      this.$refs.richText.insert(interpolatedFieldName);
    },
    initFieldConverter() {
      this.fieldConverter = new FieldTemplateConverter(this.localObject);

      this.nonFieldTemplateOptions = nonFieldTemplateOptions;
    },
  },
};
</script>

<style lang="scss">
// not applying when scoped
.kn-input-rich_text.is-single-line {

  .redactor-editor {
    padding: 12px 8px;
  }

  p {
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
  .kn-input-rich_text {
    position: relative;
  }

  .rich_text-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: .8rem;

    &:hover {
      background-color: rgba(255,255,255,.7);
    }

    &--2 {
      top: 32px;
    }

    &--3 {
      top: 64px;
    }
  }
</style>
