<template>
  <ConfirmModal
    confirm-type="alert"
    confirm-text="Disable"
    title="Disable E-Commerce?"
    @confirm="onClickDisableEcommerce"
    @cancel="onClose"
    @close="onClose"
  >
    <p>This will disable all payment views in your app. You will no longer be able to charge your users.</p>
    <p>Note: This will <strong>not</strong> delete any charge records currently stored in your database.</p>
  </ConfirmModal>
</template>

<script>
import ConfirmModal from '@/components/ui/ConfirmModal';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  components: {
    ConfirmModal,
  },
  mixins: [
    RequestUtils,
  ],
  emits: ['close', 'disable'],
  methods: {
    onClickDisableEcommerce() {
      this.commitRequest({
        request: () => this.app.disableEcommerce(),
        onSuccess: () => {
          this.$emit('disable');
        },
      });
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
