<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">
        Display Format
        <HelpIcon
          text="The format in which the name will be displayed in record columns and views."
        />
      </label>
      <div>
        <select
          v-model="fieldLocal.format.format"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="First Last">
            First Last (Roger Wilco)
          </option>
          <option value="Title First Last">
            Title First Last (Mr. Roger Wilco)
          </option>
          <option value="Last, First">
            Last, First (Wilco, Roger)
          </option>
          <option value="First Middle Last">
            First Middle Last (Roger M. Wilco)
          </option>
          <option value="Last, First Middle">
            Last, First Middle (Wilco, Roger M.)
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import HelpIcon from '@/components/ui/HelpIcon.vue';

export default {
  name: 'Name',
  components: { HelpIcon },
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
