<template>
  <view-toolbox
    back-title="page"
    title="Edit Details"
  >
    <div>
      <div class="margin-bottom details-source-container rounded-lg bg-subtle p-2">
        <div class="level-left details-source-inner-container">
          <Icon
            type="records"
            class="details-source-icon text-default mr-2 h-4 w-4"
          />
          <div
            class="details-source-label"
            v-html="connectionLabel"
          />
        </div>
      </div>
      <toc-links :items="items" />
    </div>
  </view-toolbox>
</template>

<script>
import isNil from 'lodash/isNil';
import ViewToolbox from '@/components/views/ViewToolbox';
import TocLinks from '@/components/views/TocLinks';
import ViewUtils from '@/components/views/ViewUtils';
import Icon from '@/components/ui/Icon';
import StringUtils from '@/components/util/StringUtils';

export default {
  name: 'DetailsTOC',
  components: {
    Icon,
    ViewToolbox,
    TocLinks,
  },
  mixins: [
    ViewUtils,
    StringUtils,
  ],
  data() {
    return {
      items: [
        {
          title: 'Settings',
          copy: 'design & layout',
          link: 'settings',
        },
        {
          title: 'Actions & Fields',
          copy: 'add fields and links',
          link: 'items',
        },
        {
          title: 'Rules',
          copy: 'define rules to show or hide fields',
          link: 'rules',
        },
      ],
    };
  },

  computed: {
    /**
     * @returns {string} - text explaining the source of the object for the detailed view
     */
    connectionLabel() {
      // get the primary object name, indefinite article (a or an), check if view is linked to auth
      const objectNameSingular = this.object.get('inflections').singular;
      const authedView = !!this.viewRaw.source.authenticated_user;
      const indefiniteArticle = this.indefiniteArticle(objectNameSingular);
      let primaryConnection;
      let secondaryConnection;

      // find any primary connection
      const objectKey = this.viewRaw.source.connection_key;
      if (objectKey) {
        const connectionField = this.$store.getters.getField(objectKey);

        const connectedObjectKey = (connectionField.objectKey === this.viewRaw.source.object)
          ? connectionField.get('relationship').object
          : connectionField.objectKey;

        const connectedObject = this.$store.getters.getObject(connectedObjectKey);

        primaryConnection = connectedObject.inflections.singular;
      }

      // find any parent connection
      const parentObjectKey = this.viewRaw.source.parent_source?.object;
      if (parentObjectKey) {
        const grandparentObject = this.$store.getters.getObject(parentObjectKey);

        if (!isNil(grandparentObject)) {
          secondaryConnection = grandparentObject.inflections.singular;
        }
      }

      let label = 'This details view displays';

      // combine the primary and connected objects
      // there are 4 scenarios covered here
      // 1. object with no connections and not linked to the user
      // 2. object with no connections, but linked to the user (because it's the role)
      // 3. object with a connection to the logged in user
      // 4. object with a connection to another object with a connection to the logged in user

      // when there are no connections
      if (!primaryConnection) {
        if (!authedView) { // #1
          return `${label} ${indefiniteArticle} <b>${objectNameSingular}</b> record.`;
        }

        // #2
        return `${label} the <b>${objectNameSingular}</b> logged-in to this page.`;
      }

      // when there's at least one connection
      label += ` ${indefiniteArticle} <b>${objectNameSingular}</b> record`;
      const connector = (this.viewRaw.source.authenticated_user) ? 'the logged-in' : 'this page\'s';
      if (!secondaryConnection) { // #3
        return `${label} connected to ${connector} <b>${primaryConnection}</b>.`;
      }

      // #4
      return `${label} connected to the <b>${primaryConnection}</b> record connected to ${connector} <b>${secondaryConnection}</b>.`;
    },
  },
};
</script>

<style scoped>
.details-source-container {
  border-radius: .34em;
  padding: .55em .75em;
  background-color: rgba(56, 57, 60, 0.08);
}

.details-source-inner-container {
  align-items: flex-start;
}

.details-source-icon {
  flex-shrink: 0;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 2px;
}

.details-source-label {
  line-height: 1.35em;
  font-size: .96em;
}
</style>
