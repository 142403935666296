<template>
  <div class="body-wrapper">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>

export default {
  name: 'Hello',
  data() {
    return {
      msg: 'Welcome to Your Knack App!',
    };
  },
};
</script>
