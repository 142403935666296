<template>
  <view-toolbox
    back-title="Edit List"
    title="Design"
  >
    <form>
      <div>
        <label for="title">
          Title
        </label>
        <input
          v-model="viewRaw.title"
          type="text"
          name="title"
        >
      </div>
      <div>
        <label for="title">
          Description
        </label>
        <textarea
          v-model="viewRaw.description"
          name="description"
        />
      </div>
      <label class="label-header">Customize Design</label>
      <div class="customization-options">
        <DesignPropertyPicker
          design-property="labelFormat"
          label-text="Label Format"
          input-type="dropdown"
          :options="alignmentOptions"
        />
      </div>
    </form>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DesignPropertyPicker from '@/components/settings/design/DesignPropertyPicker';

export default {
  name: 'TableSettings',
  components: {
    ViewToolbox,
    DesignPropertyPicker,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    alignmentOptions() {
      return [
        {
          value: 'left-aligned',
          label: 'Left-aligned',
        },
        {
          value: 'right-aligned',
          label: 'Right-aligned',
        },
      ];
    },
  },
};
</script>
