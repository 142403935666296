<template>
  <a
    :class="linkClasses"
    :data-cy="dataCyName"
  >
    <div
      v-if="parent"
      class="tree-branch-parent border-default border-solid border-l-2 border-r-0 absolute top-10 h-3 ml-3"
    />
    <div
      v-if="child"
      class="tree-branch border-default border-solid border-l-2 border-r-0 border-t-0 border-b-2 w-4 absolute -top-[18px] h-10"
    />
    <div class="name flex items-center gap-2 min-w-0 text-base">
      <slot>
        <Icon type="table-cells" />
        <span class="transition truncate">
          {{ obj.name }} !!
        </span>
      </slot>
    </div>

    <div v-if="settings">
      <a
        v-tippy
        content="Settings, copy, & delete"
        data-cy="object-settings-icon"
        class="settings buttonSquare-outline -size-small -color-secondary flex w-6 h-6 rounded text-default justify-center items-center mr-2 hover:bg-brand-200 border-0"
        :class="{ 'text-emphasis': isActive }"
        :data-feature="featureId"
        data-feature-x-offset="-34"
        data-feature-y-offset="-14"
        @click.prevent="onOpenPopover()"
      >
        <Icon
          class="h-4 w-4"
          type="chevron-single"
        />
      </a>

      <Popover
        v-if="popoverIsOpen"
        :open="true"
        :arrow-offset="18"
        :window-offset="popoverHorizontalOffset"
        @hide="onClosePopover()"
      >
        <template #content>
          <div class="links p-0">
            <RouterLink
              v-close-popper
              :to="link + obj.key + '/settings/edit'"
              data-cy="object-settings"
              class="hover:text-emphasis"
              :class="linkItemClasses"
            >
              <Icon type="settings" /> Settings
            </RouterLink>
            <RouterLink
              v-if="showCopy"
              v-close-popper
              :to="link + obj.key + '/copy'"
              data-cy="object-copy"
              class="hover:text-emphasis"
              :class="linkItemClasses"
            >
              <Icon type="copy" /> Copy
            </RouterLink>
            <hr
              v-if="showEcommDelete || showDelete"
              class="block m-2 h-px bg-subtle border-none"
            >
            <a
              v-if="showEcommDelete"
              v-close-popper
              class="text-destructive hover:text-destructive"
              :class="linkItemClasses"
              @click="showEcommerceDisable = true"
            >
              <Icon type="delete" /> Delete
            </a>
            <RouterLink
              v-else-if="showDelete"
              v-close-popper
              :to="link + obj.key + '/delete'"
              data-cy="object-delete"
              class="text-destructive hover:text-destructive"
              :class="linkItemClasses"
            >
              <Icon type="delete" /> Delete
            </RouterLink>
          </div>
        </template>
      </Popover>
    </div>

    <EcommerceDisable
      v-if="showEcommerceDisable"
      @confirm="onEcommerceDisabled"
      @cancel="showEcommerceDisable = false"
      @close="showEcommerceDisable = false"
    />
  </a>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover';
import EcommerceDisable from '@/components/settings/ecommerce/EcommerceDisable';

export default {
  components: {
    Icon,
    Popover,
    EcommerceDisable,
  },
  props: {
    settings: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: Boolean,
      default: false,
    },
    child: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
    obj: {
      type: Object,
      default: () => {},
    },
    showTaskCount: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    featureId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popoverIsOpen: false,
      showEcommerceDisable: false,
      popoverHorizontalOffset: 0,
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    showCopy() {
      return !this.obj.ecommerce && !this.obj.ecommercePaymentMethods;
    },
    showDelete() {
      return this.obj.profile_key !== 'all_users';
    },
    ecommerceEnabled() {
      return get(this.app, 'attributes.ecommerce.enabled', false);
    },
    showEcommDelete() {
      return this.ecommerceEnabled && (this.obj.ecommerce || this.obj.attributes.ecommerce_payment_methods);
    },
    linkClasses() {
      return {
        'popover-toggled': this.popoverIsOpen,
      };
    },
    dataCyName() {
      return `Object ${this.obj?.name}`;
    },
    linkItemClasses() {
      return 'rounded hover:bg-brand-50 text-base text-default';
    },
  },
  watch: {

    /**
     * This shouldn't be necessary but `enable/disable` events aren't being registered
     * in this component coming from async actions in the child ecommerce modals, this watch prevents
     * a cyclical modal behavior whereby if you both enable and disable without a refresh you
     * will be stuck in an endless loop of modals
     */
    ecommerceEnabled(newValue, oldValue) {
      if (newValue === false) {
        this.onEcommerceDisabled();
      }
    },
  },
  mounted() {
    this.popoverHorizontalOffset = -40;
  },
  methods: {
    onOpenPopover() {
      this.$store.commit(
        'checklists/setHighlightedFeatures',
        this.$store.getters['checklists/highlightedFeatures'].filter((f) => f !== this.featureId),
      );

      this.popoverIsOpen = true;
    },
    onClosePopover() {
      this.popoverIsOpen = false;
    },
    onEcommerceDisabled() {
      this.showEcommerceDisable = false;
      this.$router.push('/schema/list');
    },
  },
};
</script>
