<template>
  <Modal
    :title="title"
    :back="backLink"
    data-cy="task-modal"
  >
    <nav class="tabsMenu margin-bottom-double border-0 -mt-2 mb-6">
      <RouterLink
        v-slot="{ isActive }"
        :to="`task`"
        class="tabsMenu_tab"
        :class="routerLinkClasses"
        data-cy="task-nav-settings"
      >
        <span :class="[linkItemContentWrapperClasses, getLinkItemContentWrapperActiveClasses(isActive)]">
          <Icon
            type="task-check"
            class="tabsMenu_icon text-default h-4 w-4"
            :class="{ 'fill-brand': isActive }"
          />Task
        </span>
      </RouterLink>

      <button
        v-if="isActionTabDisabled"
        type="button"
        class="tabsMenu_tab tabsMenu_tab-disabled text-subtle bg-transparent"
        data-cy="task-nav-action-disabled"
        :disabled="true"
      >
        <Icon
          type="action-task"
          class="tabsMenu_icon fill-subtle h-4 w-4"
        />Action
      </button>
      <RouterLink
        v-else
        v-slot="{ isActive }"
        class="tabsMenu_tab"
        :class="routerLinkClasses"
        data-cy="task-nav-action"
        :to="'action'"
      >
        <span :class="[linkItemContentWrapperClasses, getLinkItemContentWrapperActiveClasses(isActive)]">
          <Icon
            type="action-task"
            class="tabsMenu_icon"
            :class="{ 'fill-brand': isActive }"
          />Action
        </span>
      </RouterLink>

      <button
        v-if="isOtherTabDisabled"
        type="button"
        class="tabsMenu_tab tabsMenu_tab-disabled text-subtle bg-transparent"
        data-cy="task-nav-history-disabled"
        :disabled="true"
      >
        <Icon
          type="history"
          class="tabsMenu_icon"
        />History
      </button>
      <RouterLink
        v-else
        v-slot="{ isActive }"
        class="tabsMenu_tab"
        :class="routerLinkClasses"
        data-cy="task-nav-history"
        :to="'history'"
      >
        <span :class="[linkItemContentWrapperClasses, getLinkItemContentWrapperActiveClasses(isActive)]">
          <Icon
            type="history"
            class="tabsMenu_icon text-default h-4 w-4"
            :class="{ 'fill-brand': isActive }"
          />History
        </span>
      </RouterLink>

      <button
        v-if="isOtherTabDisabled"
        type="button"
        class="tabsMenu_tab tabsMenu_tab-disabled text-subtle bg-transparent"
        data-cy="task-nav-run-disabled"
        :disabled="true"
      >
        <Icon
          type="play-run-arrow"
          class="tabsMenu_icon"
        />Run
      </button>
      <RouterLink
        v-else
        v-slot="{ isActive }"
        class="tabsMenu_tab"
        :class="routerLinkClasses"
        data-cy="task-nav-run"
        :to="'run'"
      >
        <span :class="[linkItemContentWrapperClasses, getLinkItemContentWrapperActiveClasses(isActive)]">
          <Icon
            type="play-run-arrow"
            class="tabsMenu_icon text-default h-4 w-4"
            :class="{ 'fill-brand': isActive }"
          />Run
        </span>
      </RouterLink>
    </nav>

    <div
      v-if="errors && errors.length"
      class="error-alert form-errors rounded-lg p-4 bg-destructive mb-6 border-0"
    >
      <div class="mb-1">
        <Icon
          class="block text-destructive-emphasis h-4 w-4"
          type="exclamation-triangle"
        />
      </div>
      <b class="text-destructive-emphasis text-base">Please correct the following errors:</b>
      <ul class="pl-[22px] text-destructive-emphasis text-base">
        <li
          v-for="(error, errorIndex) in errors"
          :key="errorIndex"
          v-html="error.message || error"
        />
      </ul>
    </div>

    <RouterView
      v-model:task-name="task.name"
      v-model:repeat="task.schedule.repeat"
      v-model:date="task.schedule.date"
      v-model:time="task.schedule.time"
      v-model:status="task.run_status"
      v-model:action="task.action"
    />

    <div
      v-if="showSaveButton"
      class="submit-buttons p-0 m-0 flex justify-end"
    >
      <RouterLink
        v-if="isActionTabDisabled"
        v-slot="{ navigate }"
        :to="'action'"
        custom
      >
        <button
          type="button"
          class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium"
          :class="{ disabled: taskNameIsEmpty }"
          data-cy="task-next"
          :disabled="taskNameIsEmpty"
          @click="navigate"
        >
          Next
        </button>
      </RouterLink>
      <a
        v-else
        class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium"
        data-cy="task-save"
        @click="onClickUpdateTask"
      >
        Save Task
      </a>
    </div>
  </Modal>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import FieldUtils from '@/components/fields/FieldUtils';
import Icon from '@/components/ui/Icon';
import Modal from '@/components/ui/Modal';
import RequestUtils from '@/components/util/RequestUtils';
import Task from '@/store/models/Task';

import { logEvent } from '@/lib/segment-helper';

export default {
  name: 'TaskModal',
  components: {
    Icon,
    Modal,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  props: {
    taskKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      task: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  computed: {
    backLink() {
      return `/tasks/objects/${this.object.key}`;
    },
    title() {
      return this.isAddTask ? 'Add Task' : 'Edit Task';
    },
    isAddTask() {
      return /\/add\/task$/.test(this.$route.path);
    },
    isActionTabDisabled() {
      return /\/add\/task$/.test(this.$route.path);
    },
    isOtherTabDisabled() {
      return /\/add\/(task|action)$/.test(this.$route.path);
    },
    showSaveButton() {
      return !/\/(history|run)$/.test(this.$route.path);
    },
    taskNameIsEmpty() {
      return isEmpty(get(this.task, 'name'));
    },
    routerLinkClasses() {
      return 'border-0 p-0 flex justify-center mr-2';
    },
    linkItemContentWrapperClasses() {
      return 'flex items-center text-default w-full h-full rounded-lg px-2 py-1';
    },
  },
  created() {
    if (isEmpty(this.taskKey)) {
      this.taskModel = new Task({
        name: '',
        object_key: this.$route.params.objectKey,
        type: 'actions',
        schedule: {
          repeat: 'daily',
        },
        run_status: 'running',
        action: {
          action: 'record',
          criteria: [],
          values: [],
          email: {},
        },
      });
    } else {
      this.taskModel = this.object.tasks.find(({ key }) => key === this.taskKey);
    }

    if (isNil(this.taskModel.action.email)) {
      this.taskModel.action.email = {};
    }

    // set a local state since we don't persist changes until save
    this.task = cloneDeep(this.taskModel.raw());

    // Default our next scheduled date to now
    let nextScheduledDate = new Date();

    let nextScheduledTime = `${nextScheduledDate.getHours()}:${this.padDatePart(String(nextScheduledDate.getMinutes()), 2)}`;

    // Check the schedule to see if we have to set it in the future
    if (this.task.key && this.task.schedule.date) {
      nextScheduledDate = new Date(this.task.schedule.date);
      nextScheduledTime = this.task.schedule.time;

      // If the scheduled date is in the past, add the next increment
      const nextDateStr = `${nextScheduledDate.toDateString()} ${this.format12HourTime(nextScheduledTime)}`;
      if (new Date(nextDateStr) < new Date()) {
        switch (this.task.schedule.repeat.toLowerCase()) {
          case 'weekly':
            nextScheduledDate.setDate(nextScheduledDate.getDate() + 7);
            break;

          case 'monthly':
            nextScheduledDate.setMonth(nextScheduledDate.getMonth() + 1);
            break;

          case 'daily':
          default:
            nextScheduledDate.setDate(nextScheduledDate.getDate() + 1);
            break;
        }
      }
    }

    // Update the schedule
    this.task.schedule.date = this.getFormattedDate(nextScheduledDate);
    this.task.schedule.time = nextScheduledTime;
  },
  methods: {
    format12HourTime(time) {
      // we need to normalize AM/PM suffixes for further processing
      time = time.toLowerCase();

      // drop am/pm from time, increment time +12 hours if past noon:
      if (time.indexOf('am') > -1) {
        time = time.replace('am', '');
      } else {
        time = time.replace('pm', '');
        time = time.split(':');
        time[0] = (parseInt(time[0]) !== 12) ? parseInt(time[0]) + 12 : 12;
        time = time.join(':');
      }

      return time;
    },
    getFormattedDate(dateToFormat) {
      return `${this.padDatePart((dateToFormat.getMonth() + 1), 2)}/${this.padDatePart(dateToFormat.getDate(), 2)}/${dateToFormat.getFullYear()}`;
    },
    padDatePart(padString, padCount) {
      return (String(1e15 + padString)).slice(-padCount);
    },
    onClickUpdateTask() {
      const taskModel = new Task(this.task);

      this.commitRequest({
        validate: () => taskModel.validate(),
        request: () => (isEmpty(this.taskKey) ? taskModel.create() : taskModel.update()),
        onSuccess: () => {
          logEvent('task_add', {
            name: this.task.name,
          });

          this.$router.push(this.backLink);
        },
      });
    },
    getLinkItemContentWrapperActiveClasses(isActive) {
      return {
        'hover:bg-brand-50 hover:text-emphasis': !isActive,
        'text-emphasis bg-brand-100': isActive,
      };
    },
  },
};
</script>
