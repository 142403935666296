<script setup>
import { onErrorCaptured, ref } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';
import { useStore } from 'vuex';

import Toolbox from '@/components/layout/Toolbox';

const error = ref(null);
const toolbox = ref(null);
const store = useStore();

const goBack = () => {
  if (toolbox?.value?.$refs?.toolboxTitle?.navigateBack) {
    toolbox.value.$refs.toolboxTitle.navigateBack();
  }
};

const resetApp = () => {
  store.commit('routes/resetAllRoutes');

  window.location = window.location.href.replace(
    /^(https?:\/\/[^/]+\/[^/]+\/[^/]+).*$/i,
    '$1/',
  );
};

onErrorCaptured((capturedError) => {
  // We want to console log the error, so it can be reported.
  console.error( // eslint-disable-line no-console
    `Toolbox Error Boundary Captured: ${capturedError.message}`,
    capturedError,
  );

  error.value = capturedError;

  // By returning false we prevent the error from bubbling.
  return false;
});

onBeforeRouteUpdate(() => {
  error.value = null;
});
</script>

<template>
  <Toolbox
    v-if="error"
    ref="toolbox"
    back-title="Previous"
    :back-count="1"
    title="Error"
    class="no-padding"
    theme="active"
  >
    <p>
      Something went wrong.
    </p>
    <div>
      <button
        type="button"
        @click="goBack"
      >
        Go Back
      </button>
    </div>

    <div>
      <button
        type="button"
        @click="resetApp"
      >
        Click here to restart app
      </button>
      All progress will be lost.
    </div>
  </Toolbox>
  <slot v-else />
</template>
