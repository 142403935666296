import debounce from 'lodash/debounce';

import filterSchemaFields from './ui-filter-schema-fields';

// based off of widest Pages ribbon
export const MIN_RIBBON_WIDTH_PX = 750;
export const NAV_SIDEBAR_WIDTH_PX = 64;
export const LEFT_PANEL_MIN_WIDTH_PX = 300;
export const LEFT_PANEL_MAX_WIDTH_PX = 800;

let windowResizeHandler;

const baseState = {

  /**
   * State of the width of the browser's viewport in pixels.
   *
   * @type {number}
   */
  viewportWidth: null,

  /**
   * State of the height of the browser's viewport in pixels.
   *
   * @type {number}
   */
  viewportHeight: null,

  /**
   * Current width of the left panel / toolbox in pixels.
   *
   * @type {number}
   */
  leftPanelWidth: null,

  /**
    * Flag indicating that a panel resize (i.e. a single click + mouse drag) is in progress.
    *
    * @type {boolean}
   */
  isPanelResizing: false,

  /**
   * Right panel / main body width breakpoints in pixels.
   *
   * @type {Array<number>}
   */
  rightPanelBreakpointWidths: [
    MIN_RIBBON_WIDTH_PX + 250,
    MIN_RIBBON_WIDTH_PX,
    MIN_RIBBON_WIDTH_PX - 50,
  ],
};

const storeGetters = {

  /**
   * Gets the width of the browser's viewport in pixels.
   *
   * @param {object} state
   * @returns {number}
   */
  viewportWidth: (state) => state.viewportWidth,

  /**
   * Gets the height of the browser's viewport in pixels.
   *
   * @param {object} state
   * @returns {number}
   */
  viewportHeight: (state) => state.viewportHeight,

  /**
   * Gets flex basis string to apply to the left panel's flex context.
   *
   * @param {object} state
   * @returns {string}
   */
  leftPanelFlexBasis: (state) => {
    const panelWidth = state.leftPanelWidth || LEFT_PANEL_MIN_WIDTH_PX;

    return `${panelWidth}px !important`;
  },

  /**
   * Gets the left panel's current width in pixels.
   *
   * @param {object} state
   * @returns {string}
   */
  leftPanelWidth: (state) => state.leftPanelWidth,

  /**
   * Gets the left panel's current valid maximum width in pixels.
   *
   * @param {object} state
   * @returns {number}
   */
  leftPanelMaxWidth: (state, getters) => {
    const leftWidth = NAV_SIDEBAR_WIDTH_PX + getters.leftPanelWidth;

    return getters.viewportWidth - leftWidth > MIN_RIBBON_WIDTH_PX
      ? LEFT_PANEL_MAX_WIDTH_PX
      : getters.viewportWidth - MIN_RIBBON_WIDTH_PX;
  },

  /**
   * Gets the current width of the main builder body wrapper to the right
   * of the toolbox / left panel.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {number}
   */
  rightBodyWidth: (state, getters) => getters.viewportWidth - state.leftPanelWidth - NAV_SIDEBAR_WIDTH_PX,

  /**
   * Gets ribbon breakdown class by type of ribbon element targeted.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {object} - class name map
   */
  ribbonBreakdownClasses: (state, getters) => {
    const rightWidth = getters.rightBodyWidth;
    const breakPoints = [...state.rightPanelBreakpointWidths];
    const breakpoint = (number) => (number < breakPoints.length ? breakPoints[number] : 0);

    const classMap = {
      spacing: '',
      label: '',
    };

    if (rightWidth > breakpoint(0)) {
      return classMap;
    }

    if (rightWidth < breakpoint(1) && rightWidth >= breakpoint(2)) {
      classMap.spacing = 'thin';
      classMap.label = '';
    } else {
      classMap.spacing = 'thinner';
      classMap.label = 'hidden';
    }

    return classMap;
  },

  /**
   * Gets class name to determine ribbon spacing.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {string}
   */
  ribbonSpacingClass: (state, getters) => getters.ribbonBreakdownClasses?.spacing ?? '',

  /**
   * Gets class name to determine whether to show labels on a ribbon.
   *
   * @param {object} state
   * @param {object} getters
   * @returns {string}
   */
  ribbonLabelClass: (state, getters) => getters.ribbonBreakdownClasses?.label,

  /**
   * Gets class name to determine left panel breakpoint behavior.
   *
   * @param {object} state
   * @returns {string}
   */
  leftPanelBreakdownClass: (state) => (state.leftPanelWidth > 336 ? 'md' : 'sm'),

  /**
   * Returns right panel / main body width breakpoints in pixels.
   *
   * @return {Array<number>}
   */
  rightPanelBreakpointWidths: (state) => state.rightPanelBreakpointWidths,
};

const storeMutations = {

  /**
   * Updates the state of the browser viewport's width and height.
   *
   * @param {object} state
   * @param {object} dimensions - viewport dimensions
   * @param {number} dimensions.width
   * @param {number} dimensions.height
   */
  setViewportDimensions(state, dimensions) {
    const { width, height } = dimensions;

    if (width && height) {
      state.viewportWidth = width;
      state.viewportHeight = height;
    }
  },

  /**
   * Sets left panel resizing flag.
   *
   * @param {object} state
   */
  enableLeftPanelResizing(state) {
    state.isPanelResizing = true;
  },

  /**
   * Unsets left panel resizing flag.
   *
   * @param {object} state
   */
  disableLeftPanelResizing(state) {
    state.isPanelResizing = false;
  },

  /**
   * Sets left panel current width.
   *
   * @param {object} state
   * @param {number} widthInPixels
   */
  setLeftPanelWidth(state, widthInPixels) {
    state.leftPanelWidth = widthInPixels;
  },
};

const storeActions = {

  /**
   * Determines left panel width parameters and commits them to the store.
   *
   * @param {object} context
   * @param {object} context.state
   * @param {function(string, *)} context.commit
   */
  setInitialLeftPanelWidth({ state, commit }) {
    commit('setLeftPanelWidth', state.leftPanelWidth ?? LEFT_PANEL_MIN_WIDTH_PX);
  },

  /**
   * Commits updated left panel current width on mouse drag.
   *
   * @param {object} context
   * @param {object} context.state
   * @param {object} context.getters
   * @param {function(string, *)} context.commit
   * @param {object} mouseEvent
   */
  handleLeftPanelResizeOnDrag({ state, getters, commit }, mouseEvent) {
    if (!state.isPanelResizing) {
      return;
    }

    const updatedWidth = mouseEvent.clientX - NAV_SIDEBAR_WIDTH_PX;

    if (
      updatedWidth >= LEFT_PANEL_MIN_WIDTH_PX
      && updatedWidth <= getters.leftPanelMaxWidth
    ) {
      commit('setLeftPanelWidth', updatedWidth);
    }
  },

  /**
   * Commits new left panel current width on panel double-click.
   *
   * @param {object} context
   * @param {object} context.getters
   * @param {function(string, *)} context.commit
   */
  handleLeftPanelResizeOnDoubleClick({ getters, commit }) {
    if (getters.leftPanelWidth < getters.leftPanelMaxWidth) {
      commit('setLeftPanelWidth', getters.leftPanelMaxWidth);
    } else {
      commit('setLeftPanelWidth', LEFT_PANEL_MIN_WIDTH_PX);
    }
  },

  /**
   * Commits mutation to unset left panel resizing flag.
   *
   * @param {object} context
   * @param {object} context.state
   * @param {function(string, *)} context.commit
   */
  cancelLeftPanelResizing({ state, commit }) {
    if (!state.isPanelResizing) {
      return;
    }

    commit('disableLeftPanelResizing');
  },

  /**
   * Initializes the listeners.
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {function(string, *)} context.dispatch
   */
  async initiateListeners({ commit, dispatch }) {
    if (windowResizeHandler) {
      await dispatch('removeListeners');
    }

    windowResizeHandler = debounce((event) => {
      commit('setViewportDimensions', {
        width: event.target.innerWidth,
        height: event.target.innerHeight,
      });
    }, 100);

    window.addEventListener('resize', windowResizeHandler);
  },

  /**
   * Removes the listeners.
   */
  async removeListeners() {
    if (!windowResizeHandler) {
      return;
    }

    window.removeEventListener('resize', windowResizeHandler);

    windowResizeHandler = undefined;
  },
};

export default {
  // Full namespace: ui
  ui: {
    namespaced: true,
    state: baseState,
    getters: storeGetters,
    mutations: storeMutations,
    actions: storeActions,
    modules: {
      ...filterSchemaFields,
    },
  },
};
