import ObjectSettings from '@/components/objects/ObjectSettings';
import ObjectCopy from '@/components/objects/ObjectCopy';
import ObjectDelete from '@/components/objects/ObjectDelete';
import ObjectSettingsMain from '@/components/objects/ObjectSettingsMain';
import ObjectTemplateApproval from '@/components/objects/ObjectTemplateApproval';
import ObjectTemplateInfo from '@/components/objects/ObjectTemplateInfo';

export const getSharedObjectPaths = (bodyComponent) => [
  {
    path: 'settings',
    components: {
      modal: ObjectSettings,
    },
    children: [
      {
        path: 'edit',
        component: ObjectSettingsMain,
      },
      {
        path: 'approval',
        component: ObjectTemplateApproval,
      },
      {
        path: 'info',
        component: ObjectTemplateInfo,
      },
    ],
  },
  {
    path: 'copy',
    components: {
      modal: ObjectCopy,
    },
  },
  {
    path: 'delete',
    components: {
      modal: ObjectDelete,
    },
  },
];
