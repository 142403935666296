<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import BodyWrapper from '@/components/layout/BodyWrapper';
import Icon from '@/components/ui/Icon.vue';
import BuilderButton from '@/components/ui/BuilderButton.vue';
import EmptyStateGeneric from '@/components/ui/EmptyStateGeneric.vue';
import IntegrationsListItem from '@/components/settings/integrations/IntegrationsListItem.vue';

const router = useRouter();
const store = useStore();

const connectedServices = computed(() => store.getters['integrations/connectedServices']);

const refetchConnectedServices = async () => {
  await store.dispatch('integrations/fetchConnectedServices');
};
</script>

<template>
  <BodyWrapper>
    <div
      id="settings-integrations-wrapper"
      class="body-wrapper m-0 mb-10 px-6 py-2"
    >
      <RouterView name="modal" />

      <EmptyStateGeneric v-if="connectedServices.length === 0">
        <Icon
          type="add-integration-sparkles"
          class="empty-state__image margin-bottom-sm"
        />

        <h2 class="empty-state__title margin-bottom-md">
          No integrations are connected
        </h2>

        <BuilderButton
          theme="add"
          size="lg"
          class="margin-bottom-md"
          data-cy="add-first-integration"
          @click="router.push('/settings/integrations/add')"
        >
          <Icon type="add" /> Add Integration
        </BuilderButton>
      </EmptyStateGeneric>

      <div
        v-else
        class="integrations max-w-xl"
      >
        <IntegrationsListItem
          v-for="service in connectedServices"
          :key="service.id"
          :service-id="service.service_id"
          :service-name="service.name"
          :service-icon-url="service.icon"
          is-connected
          @close="refetchConnectedServices"
        />
      </div>
    </div>
  </BodyWrapper>
</template>
