import castArray from 'lodash/castArray';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { getChildPageOrder } from '@/lib/page/view-child-page-helper';
import store from '@/store';

/**
 * Formats a list of page names into a label.
 * This does reverse the pageNames list.
 *
 * @param {string[]} pageNames
 * @returns {string}
 */
function formatPageNames(pageNames) {
  return [...pageNames].reverse().join(' > ');
}

/**
 * Generates an error for one or more self referencing pages.
 *
 * @param {Array<RawPage | Page>}pages
 */
function getSelfReferencePageError(pages) {
  const pageIds = castArray(pages).map((page) => page.key).join(', ');

  return new Error(`A page has referenced itself as its parent: ${pageIds}`);
}

/**
 * Gets the name of the page with all the page hierarchy prepending it.
 * This processes the hierarchy recursively.
 *
 * @param {RawPage} page
 * @param {boolean} [skipLoginPage]
 * @param {string[]} [pageNames]
 * @returns {string}
 */
export function parseHierarchyRecursive(page, skipLoginPage, pageNames = []) {
  if (!page) {
    return formatPageNames(pageNames);
  } if (skipLoginPage && page.isLoginPage()) {
    return formatPageNames(pageNames);
  }

  pageNames.push(
    get(page, 'raw.name', 'Unknown Page'),
  );

  if (page.parent) {
    return parseHierarchyRecursive(page.parent, skipLoginPage, pageNames);
  }

  return formatPageNames(pageNames);
}

/**
 * Gets a flattened list of the pages and all their descendants.
 * This processes the hierarchy recursively.
 *
 * @param {RawPage[]} pages
 * @param {Array<{label: string, slug: string, key: string}>} [pagesList]
 * @param {string[]} [pageNames]
 * @returns {Array<{label: string, slug: string, key: string}>}
 */
export function flattenChildHierarchyRecursive(pages, pagesList = [], pageNames = []) {
  if (isEmpty(pages)) {
    return pagesList;
  }

  return pages.reduce((final, page) => {
    const newPageNames = [
      ...pageNames,
      page.raw.name,
    ];

    final.push({
      label: newPageNames.join(' > '),
      slug: page.slug,
      key: page.key,
    });

    if (page.raw.object && !page.children) {
      return final;
    }

    return flattenChildHierarchyRecursive(page.children || [], final, newPageNames);
  }, pagesList);
}

/**
 * Builds the groups from the views.
 *
 * @param {object[]} views
 */
export function getGroupsFromViews(views) {
  return views.reduce((final, view) => {
    if (isEmpty(view)) {
      return final;
    }

    final.push({
      columns: [
        {
          width: 100,
          keys: [view.key],
        },
      ],
    });

    return final;
  }, []);
}

/**
 * Sets the raw page to authenticated.
 *
 * @param {RawPage} rawPage
 */
export function setAuthenticated(rawPage) {
  // This page requires authentication!
  rawPage.authenticated = true;

  // We also want to track which user roles ('profiles') can access this page.
  if (rawPage.raw.type === 'authentication') {
    // For auth pages, get the allowed profiles from the login view.
    rawPage.views.forEach((view) => {
      if (view.type === 'login') {
        rawPage.authentication_profiles = (view.limit_profile_access)
          ? (view.allowed_profiles || [])
          : [];
      }
    });
  } else if (rawPage.raw.type === 'user') {
    // For user pages, get from page properties.
    rawPage.authentication_profiles = (rawPage.raw.limit_profile_access)
      ? (rawPage.raw.allowed_profiles || [])
      : [];
  }
}

/**
 * Generates the page structure from the given pages.
 *
 * @param {RawPage[]} pages
 * @param {number} depth
 * @param {string[]} [pagesOrder]
 * @returns {{pagesStructure: object[], pagesOrder: string[]}}
 */
export function generatePagesStructure(pages, depth = 0, pagesOrder = []) {
  const pagesStructure = pages.map((page) => {
    if (!page) {
      return null;
    } if (!depth && (page.parent || page.menuParent)) {
      // Only top level pages will be returned.
      return null;
    }

    if (!depth) {
      // Only consider the order of the top level pages.
      pagesOrder.push(page.key);
    }

    const childrenProperty = {};
    const menuChildrenProperty = {};

    if (!isEmpty(page.children)) {
      const childData = generatePagesStructure(
        page.children,
        depth + 1,
      );

      childrenProperty.children = childData.pagesStructure;
    } else if (!isEmpty(page.menuChildren)) {
      const menuChildData = generatePagesStructure(
        page.menuChildren,
        depth + 1,
      );

      menuChildrenProperty.menuChildren = menuChildData.pagesStructure;
    }

    return {
      key: page.key,
      depth: page.depth,
      authenticated: Boolean(page.authenticated),
      authentication_profiles: page.authentication_profiles || [],
      ...childrenProperty,
      ...menuChildrenProperty,
    };
  }).filter(Boolean);

  return {
    pagesStructure,
    pagesOrder,
  };
}

/**
 * Recursively parses the page structure from the top level pages down to the lowest child page.
 * This injects structure information into the Page objects and sets up the parent-child
 * links.
 *
 * @example Example Page Structure
 *   [{
 *     key: `1`,
 *     slug: `one`,
 *     depth: 1,
 *     authenticated: false,
 *     authentication_profiles: [],
 *     children: [{
 *       key: `11`,
 *       slug: `one-one`,
 *       depth: 2,
 *       authenticated: false,
 *       authentication_profiles: [],
 *     }, {
 *       key: `12`,
 *       slug: `one-two`,
 *       depth: 2,
 *       authenticated: false,
 *       authentication_profiles: [],
 *       children: [{
 *         key: `121`,
 *         slug: `one-two-one`,
 *         depth: 3,
 *         authenticated: false,
 *         authentication_profiles: [],
 *       }]
 *     }, {
 *       key: `2`,
 *       slug: `two`,
 *       depth: 1,
 *       authenticated: true,
 *       authentication_profiles: [],
 *       menuChildren: [{
 *         key: `2-1`,
 *         slug: `two-one`,
 *         depth: 2,
 *         authenticated: true,
 *         authentication_profiles: [],
 *         children: [{
 *           key: `2-1-1`,
 *           slug: `two-one-one`,
 *           depth: 3,
 *           authenticated: true,
 *           authentication_profiles: [],
 *         }]
 *       }]
 *     }]
 *   }]
 *
 * @param {object[]} pagesStructure
 * @param {string[]} [pagesOrder]
 * @param {Object<string, Page>} [pagesByKey]
 * @param {Page} [parentPage]
 * @param {Page} [menuParentPage]
 * @returns {{pages: Page[], pagesOrder: string[]}}
 */
export function parsePagesStructureByDepth(
  pagesStructure,
  pagesOrder,
  pagesByKey = {},
  parentPage,
  menuParentPage,
) {
  const pages = pagesStructure.map((pageStructure) => {
    const page = pagesByKey[pageStructure.key];

    if (!page) {
      return null;
    }

    if (isArray(pagesOrder)) {
      pagesOrder.push(page.key);
    }

    page.depth = pageStructure.depth;
    page.authenticated = pageStructure.authenticated || false;
    page.authentication_profiles = pageStructure.authentication_profiles || [];

    if (pageStructure.children) {
      page.children = parsePagesStructureByDepth(
        pageStructure.children,
        undefined,
        pagesByKey,
        page,
      ).pages;
    } else {
      page.children = [];
    }

    if (pageStructure.menuChildren) {
      page.menuChildren = parsePagesStructureByDepth(
        pageStructure.menuChildren,
        undefined,
        pagesByKey,
        undefined,
        page,
      ).pages;
    } else {
      page.menuChildren = [];
    }

    if (parentPage) {
      page.parent = parentPage;
    }

    if (menuParentPage) {
      page.menuParent = menuParentPage;
    }

    return page;
  }).filter(Boolean);

  return {
    pages,
    pagesOrder,
  };
}

/**
 * Parses the page structure data starting at the top level down to the children.
 * This is used when no page structure is available in order to generate everything the
 * pages need, though it does not generate an actual page structure itself.
 *
 * @param {RawPage[]} pages
 * @param {boolean} [previousDepth]
 */
export function parsePagesByDepth(pages, previousDepth = -1) {
  pages.forEach((page) => {
    page.depth = previousDepth + 1;

    // Determine the authentication values of the page.
    parsePageAuthentication(page);

    if (!isEmpty(page.menuChildren)) {
      parsePagesByDepth(page.menuChildren, page.depth);
    }

    if (!isEmpty(page.children)) {
      parsePagesByDepth(page.children, page.depth);

      page.children = sortPages(page.children, getChildPageOrder(page));
    }
  });
}

/**
 * Sets the authentication of the page.
 *
 * @param {RawPage} rawPage
 */
function parsePageAuthentication(rawPage) {
  if (rawPage.isLoginPage() || rawPage.isUserPage()) {
    setAuthenticated(rawPage);

    return;
  }

  if (rawPage.parent) {
    rawPage.authenticated = Boolean(rawPage.parent.authenticated);
    rawPage.authentication_profiles = rawPage.parent.authentication_profiles || [];

    return;
  }

  if (!isEmpty(rawPage.menuChildren)) {
    rawPage.authenticated = rawPage.menuChildren.some(
      (menuChildPage) => menuChildPage.authenticated,
    );
    rawPage.authentication_profiles = rawPage.menuChildren.reduce(
      (final, menuChildPage) => final.concat(menuChildPage.authentication_profiles || []),
      [],
    );

    return;
  }

  rawPage.authenticated = false;
  rawPage.authentication_profiles = [];
}

/**
 * Sorts the pages using the given pagesOrder array.
 *
 * @param {RawPage[]} pages
 * @param {string[]} pagesOrder
 * @param {string} [sortKey]
 * @returns {object[]}
 */
export function sortPages(pages, pagesOrder, sortKey = 'key') {
  if (isEmpty(pagesOrder) || pagesOrder.length < 2) {
    return pages;
  }

  return sortBy(pages, (page) => {
    let position = findIndex(pagesOrder, (pageKey) => pageKey === page[sortKey]);

    if (position === -1) {
      position = findParentIndex(page, pagesOrder, sortKey);
    }

    return (position !== -1) ? position : Infinity;
  });
}

/**
 * Finds the index in the pages order for the parent or grand-parents of the page.
 * If the parent isn't in the pagesOrder, then the grandparent(s) will be used.
 *
 * @param {RawPage} page
 * @param {string[]} pagesOrder
 * @param {string} [sortKey]
 * @returns {number}
 */
function findParentIndex(page, pagesOrder, sortKey = 'key') {
  let parentPage = page.parent || page.menuParent;
  let depth = 1;

  while (parentPage) {
    const position = findIndex(pagesOrder, (pageKey) => pageKey === parentPage[sortKey]);

    if (position !== -1) {
      return position + (depth / 10);
    }

    parentPage = parentPage.parent || parentPage.menuParent;
    depth += 1;
  }

  return -1;
}

/**
 * Injects the parent page object into the given page.
 * Sets the parent on this page to a RawPage version of the parent.
 * Adds this page to the parent's children if it doesn't already exist there.
 *
 * @param {RawPage} page
 * @returns {{ pagesOrder: string[] }}
 */
export function injectParentIntoPage(page) {
  const parentSlug = page.raw.parent;

  // Case 1: No parent slug is defined in the raw page data.
  if (!parentSlug) {
    if (page.parent) {
      // If the page has a parent object, then be sure to clear it (the parent has been removed).
      page.parent = undefined;
    }

    return {};
  }

  const parentPage = store.getters['page/getPageBySlug'](parentSlug);

  // Case 2: The parent page object does not exist in the store.
  if (!parentPage) {
    if (page.parent) {
      // If the page has a parent object, then be sure to clear it (the parent is invalid).
      page.parent = undefined;
    }

    return {};
  }

  // Case 3: The parent page is the same as the current page (invalid parent looping).
  if (parentSlug === page.raw.slug) {
    if (page.parent) {
      // If the page has a parent object, then be sure to clear it (the parent is invalid).
      page.parent = undefined;
    }

    // Not throwing an error here because it is unclear how the calling flows would handle it.
    return {};
  }

  // Case 4: A valid parent page object exists and can be set.
  page.parent = parentPage;

  // Make sure the parent knows that the current page is a child of it.
  const hasMeAsChild = parentPage.children.find(
    (childPage) => childPage.key === page.key,
  );

  if (hasMeAsChild) {
    return {};
  }

  parentPage.children.push(page);

  // Add the new child page to its correct place in the pagesOrder.
  const childKeys = parentPage.children.map((childPage) => childPage.key);

  const pagesOrder = [...store.getters['page/pagesOrder']];

  const lastChildKey = intersection(pagesOrder, childKeys).pop();

  const lastChildIndex = findIndex(
    pagesOrder,
    (pageKey) => pageKey === lastChildKey,
  );

  pagesOrder.splice(lastChildIndex + 1, 0, page.key);

  return { pagesOrder };
}

/**
 * Processes the menu children for a menu page.
 *
 * @param {RawPage} page
 */
export function processPageMenuChildren(page) {
  // Reset the menu children to empty array.
  page.menuChildren = [];

  const menuPages = page.raw.menu_pages;

  if (!page.isMenuPage() || !isArray(menuPages) || isEmpty(menuPages)) {
    return;
  }

  menuPages.forEach((menuPageKey) => {
    const menuChild = store.getters['page/getPageByKey'](menuPageKey);

    if (!menuChild) {
      return;
    }

    // Mark that the current page is the menu parent of this menu child.
    menuChild.menuParent = page;

    // Make sure the menu child is in the parent's array of children.
    const hasChild = page.menuChildren.find((childPage) => childPage.key === menuChild.key);

    if (!hasChild) {
      page.menuChildren.push(menuChild);
    }
  });
}

/**
 * Processes the menu parent for the given page.
 * If a menu parent exists (menu attribute) then the menuParent is set and the page is added
 *   as a child to the menuParent.
 * Otherwise the menuParent is set to undefined and this page is removed for the menuParent children.
 *
 * @param {RawPage} page
 */
export function processPageMenuParent(page) {
  const pageData = page.raw;

  if (!pageData.menu) {
    // If there used to be a menu parent, we need to update its children.
    if (page.menuParent) {
      // Remove this page from the parent if it is there.
      page.menuParent.menuChildren = page.menuParent.menuChildren.filter((siblingPage) => siblingPage.key !== page.key);
    }

    page.menuParent = undefined;

    return;
  }

  const menuParent = store.getters['page/getPageByKey'](pageData.menu);

  const hasMeAsMenuChild = menuParent.menuChildren.find(
    (childPage) => childPage.key === page.key,
  );

  if (!hasMeAsMenuChild) {
    menuParent.menuChildren.push(page);
  }

  page.menuParent = menuParent;
}

/**
 * Prepares all the pages to make sure they are setup for page structure generation.
 * This method is only intended to be used when all the pages are set for the first time.
 *
 * @returns {Promise<void>}
 * @throws {Error} - If a page has itself for a parent.
 */
export function preparePagesForStructure() {
  const topLevelPages = [];
  const orphans = [];
  const selfReferences = [];

  store.getters['page/pages'].forEach((page) => {
    const { isOrphan, isSelfReferenced } = processPageForInitialLoad(page);

    if (isSelfReferenced) {
      selfReferences.push(page);

      return;
    }

    if (isOrphan) {
      orphans.push(page);

      return;
    }

    if (!page.parent && !page.menuParent) {
      topLevelPages.push(page);
    }
  });

  if (!isEmpty(selfReferences)) {
    throw getSelfReferencePageError(selfReferences);
  }

  const deleteCount = deleteOrphanPages(orphans);

  if (deleteCount) {
    log('Number of orphan pages found:', deleteCount);
  }

  // Add depth and authentication properties to the pages.
  parsePagesByDepth(topLevelPages);

  return { hasSelfReference: false };
}

/**
 * Deletes all the given pages and their children.
 * Orphans are only removed from the main collection of pages. They still exist in the key and
 * slug maps, so we will mark them with the `isOrphan` flag.
 *
 * @param {RawPage[]} pages
 * @param {number} [count]
 * @returns {number} - The number of orphans that were deleted.
 */
function deleteOrphanPages(pages, count = 0) {
  for (const page of pages) {
    count += 1;

    if (page.children) {
      deleteOrphanPages(page.children, count);
    }

    if (page.menuChildren) {
      deleteOrphanPages(page.menuChildren, count);
    }

    // Mark this page as an orphan.
    page.isOrphan = true;

    store.commit('page/unsetPage', { page, keepMapData: true });
  }

  return count;
}

/**
 * Processes everything needed for a page right after the pages were first loaded.
 *
 * @param {RawPage} page
 * @returns {{ isOrphan: boolean, isSelfReferenced: boolean }}
 */
export function processPageForInitialLoad(page) {
  const pageData = page.raw;
  const parentSlug = pageData.parent;

  if (parentSlug) {
    // There are apps that have themselves as their parent. Since this is a very bad state
    // that we can't know the correct way to fix. We need to indicate it and forward the user to
    // an error page so their schema can be fixed.
    if (parentSlug === page.raw.slug) {
      return { isSelfReferenced: true };
    }

    // All the pages were pre-loaded, so I don't have to worry about page order here.
    const parentPage = store.getters['page/getPageBySlug'](parentSlug);

    // We came across an app that had non-menu children attached to a menu page. The assumption is
    // that these were once orphans but a new page was created with the same slug as their dead
    // parent. These will show up in non-menu pages, but hidden for menu flows, so we want to make
    // sure to remove them here.
    if (!parentPage || parentPage.isMenuPage()) {
      return { isOrphan: true };
    }

    page.parent = parentPage;

    const hasMeAsChild = parentPage.children.find(
      (childPage) => childPage.key === page.key,
    );

    if (!hasMeAsChild) {
      parentPage.children.push(page);
    }
  }

  if (pageData.type === 'menu' && isArray(pageData.menu_pages) && !isEmpty(pageData.menu_pages)) {
    pageData.menu_pages.forEach((menuPageKey) => {
      // All the pages were pre-loaded, so I don't have to worry about page order here.
      const menuChild = store.getters['page/getPageByKey'](menuPageKey);

      if (!menuChild) {
        return;
      }

      menuChild.menuParent = page;

      const hasMeAsMenuChild = page.menuChildren.find(
        (childPage) => childPage.key === page.key,
      );

      if (!hasMeAsMenuChild) {
        page.menuChildren.push(menuChild);
      }
    });
  }

  return {
    isOrphan: false,
    isSelfReferenced: false,
  };
}

/**
 * Gets an array of display-friendly user roles with access to a page.
 *
 *   - If the page is public, no user roles are returned.
 *   - If any user may access a protected page, a single role called 'any user' is returned.
 *   - Otherwise, a list of human-friendly user role names is returned.
 *
 * @param {object} params
 * @param {Page} params.page
 * @param {function(string): (Obj | undefined)} params.userObjectsByProfileKey
 * @returns {Array}
 */
export function getUserRolesWithPageAccessFriendly({
  page,
  userObjectsByProfileKey,
}) {
  if (
    isEmpty(page)
      || isEmpty(userObjectsByProfileKey)
      || (!page.authenticated && page.type !== 'authentication')
  ) {
    return [];
  }

  const userRolesWithPageAccess = page.authentication_profiles
    .map((profileKey) => userObjectsByProfileKey?.[profileKey]?.inflections.plural)
    .filter(Boolean);

  return isEmpty(userRolesWithPageAccess) ? ['any user'] : userRolesWithPageAccess;
}

/**
 * Gets display-friendly markup to describe the access type permitted for a page.
 *
 * @param {object} params
 * @param {BasePage} params.page
 * @param {Array} params.userRolesWithPageAccess
 * @returns {string} - display markup
 */
export function getPageAccessDescriptionMarkup({
  page,
  userRolesWithPageAccess,
}) {
  const rolesWithAccess = [...userRolesWithPageAccess];

  // for publicly accessible pages excluding login pages
  if (rolesWithAccess.length === 0) {
    return `<strong>${page.name}</strong> can be publicly accessed`;
  }

  let userRoleDisplayText;
  if (rolesWithAccess.length === 1) {
    [userRoleDisplayText] = rolesWithAccess;
  } else {
    const lastRole = rolesWithAccess.pop();
    const oxfordComma = rolesWithAccess.length > 1 ? ',' : '';

    userRoleDisplayText = `${rolesWithAccess.join(', ')}${oxfordComma} and ${lastRole}`;
  }

  // login pages can be confusing so let's give them specific text
  if (page.type === 'authentication') {
    return `<strong>${page.name}</strong> lets ${userRoleDisplayText} log in`;
  }

  return `<strong>${page.name}</strong> can be accessed by ${userRoleDisplayText}`;
}
