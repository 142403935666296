<template>
  <ul
    ref="group-list"
    class="group-list base-list"
  >
    <li
      v-for="(item, itemIndex) in localItems"
      :key="item.key"
      class="groupListItem bg-muted rounded-lg p-4"
      :class="groupListItemClasses"
      data-cy="list-item"
    >
      <div class="item-title border-b-0">
        <div class="title">
          <a
            v-if="itemMove"
            class="button-square move-item bg-transparent rounded hover:bg-subtle"
          >
            <Icon
              class="text-default"
              type="drag-cursor"
            />
          </a>
          <span class="text-emphasis">
            {{ itemTitle }} <span v-if="showItemNumber">
              #{{ itemIndex + 1 }}
            </span>
          </span>
          <span
            v-if="isImageOrFileField"
            class="kn-required"
          >&nbsp;*</span>
        </div>
        <div class="title-buttons">
          <a
            v-if="itemCopy"
            v-tippy
            content="Copy"
            class="button-square bg-transparent rounded hover:bg-subtle"
            @click="onCopyRule(itemIndex)"
          >
            <Icon
              class="text-default"
              type="copy"
            />
          </a>
          <a
            v-if="itemAdd"
            v-tippy
            content="Add"
            class="button-square bg-transparent rounded hover:bg-subtle"
            @click="onAddRule(itemIndex)"
          >
            <Icon
              class="text-default"
              type="add-circle-outline"
            />
          </a>
          <a
            v-if="showItemDelete(item, itemIndex)"
            v-tippy
            content="Delete"
            class="button-square bg-transparent rounded hover:bg-subtle"
            @click="onDeleteRule(itemIndex)"
          >
            <Icon
              class="text-default"
              type="delete"
            />
          </a>
        </div>
      </div>
      <div class="sections vertical">
        <slot v-bind="slotOptions(item, itemIndex)" />
      </div>
    </li>
  </ul>
</template>

<script>
import { Sortable } from '@shopify/draggable';
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    itemTitle: {
      type: String,
      default: 'Rule',
    },
    isImageOrFileField: {
      type: Boolean,
      default: false,
    },
    defaultItemAdd: {
      type: Object,
      default: null,
    },
    itemAdd: {
      type: Boolean,
      default: false,
    },
    itemCopy: {
      type: Boolean,
      default: true,
    },
    itemDelete: {
      type: Boolean,
      default: true,
    },
    itemMove: {
      type: Boolean,
      default: true,
    },
    keyPrefix: {
      type: String,
      default: null,
    },
    preCopyItem: {
      type: Function,
      default: null,
    },
    canDeleteItem: {
      type: Function,
      default: null,
    },
    showItemNumber: {
      type: Boolean,
      default: true,
    },
    scopeName: {
      type: String,
      default: 'item',
    },
    commitSortsLocally: {
      type: Boolean,
      default: true,
    },
    canBeEmpty: {
      type: Boolean,
      default: true,
    },
    groupListItemClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['update:items'],
  data: () => ({
    localItems: () => ([]),
  }),
  computed: {
    ...mapGetters([
      'getUniqueKey',
    ]),
  },
  watch: {
    items: {
      handler(newVal) {
        this.localItems = this.addUniqueKeys(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.localItems = this.addUniqueKeys(this.items);

    this.sortable = new Sortable(this.$refs['group-list'], {
      draggable: 'li.groupListItem',
      distance: 5,
      handle: '.move-item',
    });

    this.sortable.on('sortable:stop', (event) => {
      const { oldIndex, newIndex } = event.data;

      this.localItems = this.move(this.localItems, oldIndex, newIndex);

      this.$emit('update:items', this.localItems);
    });
  },
  methods: {
    addUniqueKeys(items) {
      return items.map((item) => this.addUniqueKey(item, items));
    },
    addUniqueKey(item, items) {
      if (!item.key) {
        let uniqueKey = this.getUniqueKey(this.keyPrefix);

        // Ensure this ID doesn't exist in this collection
        while (items.find((itemToCheck) => itemToCheck.key && itemToCheck.key === uniqueKey)) {
          uniqueKey = this.getUniqueKey(this.keyPrefix);
        }

        item.key = uniqueKey;
      }

      return item;
    },
    slotOptions(item, itemIndex) {
      return {
        [this.scopeName]: item,
        [`${this.scopeName}Index`]: itemIndex,
      };
    },
    showItemDelete(item, itemIndex) {
      if (!this.canBeEmpty && this.items.length === 1) {
        return false;
      }

      if (!this.itemDelete) {
        return false;
      }

      if (this.canDeleteItem) {
        return this.canDeleteItem(item, itemIndex);
      }

      return true;
    },
    onAddRule(itemIndex) {
      const newItem = JSON.parse(JSON.stringify(this.defaultItemAdd));

      this.localItems.splice(itemIndex + 1, 0, newItem);

      // ensure computed set is triggered
      this.$emit('update:items', this.localItems);
    },
    onCopyRule(itemIndex) {
      let newItem = JSON.parse(JSON.stringify(this.items[itemIndex]));

      // Delete the key so a new one can be created
      delete newItem.key;

      // Add a new unique key
      newItem = this.addUniqueKey(newItem, this.localItems);

      if (this.preCopyItem) {
        newItem = this.preCopyItem(newItem);
      }

      this.localItems.splice(itemIndex + 1, 0, newItem);

      // ensure computed set is triggered
      this.$emit('update:items', this.localItems);
    },
    onDeleteRule(itemIndex) {
      this.localItems.splice(itemIndex, 1);

      // ensure computed set is triggered
      this.$emit('update:items', this.localItems);
    },
    move(items, oldIndex, newIndex) {
      const itemRemovedArray = [
        ...items.slice(0, oldIndex),
        ...items.slice(oldIndex + 1, items.length),
      ];

      return [
        ...itemRemovedArray.slice(0, newIndex),
        items[oldIndex],
        ...itemRemovedArray.slice(newIndex, itemRemovedArray.length),
      ];
    },
  },
};
</script>

<style lang="scss">
.group-list {

  > li {
    flex-direction: column;

    .item-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: .5rem;
      border-bottom: 1px solid $gray100;
      margin-bottom: .75rem;

      > div {
        display: flex;
        align-items: center;

        a.move-item {
          margin-right: .5em;
        }

        span {
          @include font-h6;
          font-weight: 600;
        }
      }

      .title-buttons a {
        margin-left: .25rem;
      }

      .button-square {
        min-width: 24px;
        height: 24px;

        svg {
          height: 16px !important;
          width: 16px !important;
        }
      }

      svg {
        height: 17px;
        width: 17px;
      }

      svg.icon-delete {
        height: 20px;
        width: 20px;
      }

      svg.icon-move {
        opacity: .8;
      }
    }
  }
  .sections {
    font-size: 1em;
  }

  textarea {
    min-height: 75px;
    line-height: 1.4em;
  }

  .sections > div > div {

    &:not(.width-full) {
      border-top: 1px solid $gray100;
      margin-top: 1em;
      padding-top: 1em;
    }

    &:first-child:not(.width-full) {
      border-top: 0;
      padding-top: 0;
      margin-top: 0;
    }

    > label {
      color: $gray800;
      @include font-body;
      font-weight: 600;
      margin-bottom: .25em;
      display: block;
    }

    > p {
      margin-top: -.25em;
      margin-bottom: .25em;
    }

    .input-labels label {
      margin: 0;
      font-weight: 500;
      color: #3a3d46;
      display: flex;
      align-items: center;
    }
  }

  form > div > p {
    color: #4b5054;
    font-weight: 300;
  }

  p {
    line-height: 2em;
    margin-bottom: 0;
  }

  &.small .sections > div:not(.width-full) {
    margin-top: 1rem;
  }
}

.toolbox-body li .item-title {
  border-bottom: 1px solid $gray200;
}
</style>
