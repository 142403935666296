<template>
  <view-toolbox
    back-title="Edit Checkout"
    title="Payment Rules"
  >
    <div>
      <toc-links :items="items" />
    </div>
  </view-toolbox>
</template>
<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import TocLinks from '@/components/views/TocLinks';

export default {
  components: {
    ViewToolbox,
    TocLinks,
  },
  data() {
    return {
      items: [
        {
          title: 'Submit Rules',
          copy: 'what happens after the form submits',
          link: 'submit',
        },
        {
          title: 'Record Rules',
          copy: 'insert and update records',
          link: 'record',
        },
      ],
    };
  },
};
</script>
