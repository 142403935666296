import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'activePage',
      'roleObjects',
      'allPages',
    ]),
    showRoleFilter() {
      // Return if login pages exist.
      return this.allPages.some((page) => page.isAuthenticated());
    },
    roleFilterOptions() {
      const options = [];

      options.push({
        name: 'All Pages',
        icon: 'pages',
        slug: '',
        key: 'all',
        matchCases: [],
      });

      if (!this.showRoleFilter) {
        return options;
      }

      const publicPagesExist = this.allPages.some((page) => !page.isAuthenticated());

      if (publicPagesExist) {
        options.push({
          name: 'All Public Pages',
          icon: 'lock-outline',
          slug: 'public',
          key: 'public',
          matchCases: [
            {
              authenticated: false,
              authentication_profiles: 'any',
            },
          ],
        });
      }

      options.push({
        name: 'All Login Required Pages',
        icon: 'lock',
        slug: 'logged-in',
        key: 'auth',
        matchCases: [
          {
            authenticated: true,
            authentication_profiles: 'any',
          },
        ],
      });

      this.roleObjects.map((role) => {
        options.push({
          name: `${role.inflections.plural}`,
          icon: 'user',
          slug: role.profile_key,
          key: role.key,
          matchCases: [
            // Public pages
            {
              authenticated: false,
              authentication_profiles: 'any',
            },
            // Auth pages without specific role assigned
            {
              authenticated: true,
              authentication_profiles: [],
            },
            // Auth pages assigned to this role
            {
              authenticated: true,
              authentication_profiles: [
                role.profile_key,
              ],
            },
          ],
        });
      });

      return options;
    },
  },
};
