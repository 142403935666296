import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

/**
 * @typedef FilterMatcher
 * @type {object}
 * @property {boolean} authenticated - Whether or not to match is the page is authenticated.
 * @property {string | string[]} authentication_profiles - The profile keys to match.
 */

/**
 * Matcher to determines if the given page should be visible based on the given filters.
 *
 * @param {RawPage} page
 * @param {object} context
 * @param {FilterMatcher[]} context.filterMatches
 * @returns {boolean}
 */
function matcherShouldPageBeVisible(page, { filterMatches }) {
  const pageIsAuthenticated = page.isAuthenticated();

  return filterMatches.some(({ authenticated, authentication_profiles: profiles }) => {
    if (page.isMenuPage()) {
      // For now this only hides menus based on authenticated, not on profiles.
      // TODO: Add profile support for hiding menus.
      if (authenticated && page.hasAuthenticatedMenuChildren()) {
        return true;
      }

      if (!authenticated && page.hasNonAuthenticatedMenuChildren()) {
        return true;
      }

      return false;
    }

    if (pageIsAuthenticated !== authenticated) {
      // If the page doesn't match on authentication and we are evaluating a public page and
      // we're matching on non-specific profiles we should show the page if it has visible children.
      if (!pageIsAuthenticated && profiles === 'any' && !isEmpty(page.children)) {
        return true;
      }

      // If this match case doesn't match on authentication and no children match try next case.
      return false;
    }

    if (profiles === 'any') {
      return true;
    }

    if (!isArray(profiles)) {
      // Profiles must be either 'any' or an array of profile keys.
      return false;
    }

    if (isEmpty(profiles) && isEmpty(page.authorizedUserRoles)) {
      // If the profiles array is empty and the authorized user roles is empty, then it is valid.
      return true;
    }

    return profiles.some((profile) => page.roleHasAccess(profile));
  });
}

/**
 * Filters the given list of pages based on the filter matches provided.
 *
 * @param {RawPage[]} pages
 * @param {FilterMatcher[]} filterMatches
 * @param {{filterDeep: boolean}} [filterConfig]
 * @returns {RawPage[]}
 */
function filterPages({ pages, filterMatches, filterConfig }) {
  if (isEmpty(filterMatches)) {
    return pages;
  }

  const { filterDeep } = filterConfig || { filterDeep: true };

  return pages.reduce((matches, page) => {
    const itemHasChildren = !isEmpty(page.children);

    let matchPage = page;

    // Filter the children as well.
    // I am pretty sure this children processing is not working, but it is hard to tell exactly
    // what it was supposed to be doing. If we can remove this we could greatly increase the
    // efficiency of these filters.
    if (filterDeep && itemHasChildren) {
      const matchedChildren = filterPages({
        pages: page.children,
        filterMatches,
        filterConfig,
      });

      // Does this even work?
      matchPage = Object.assign(Object.create(Object.getPrototypeOf(page)), page, {
        children: matchedChildren,
      });
    }

    if (matcherShouldPageBeVisible(matchPage, { filterMatches })) {
      matches.push(page);
    }

    return matches;
  }, []);
}

export default filterPages;
