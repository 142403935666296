<script setup>
import { useStore } from 'vuex';
import { computed, defineProps } from 'vue';

import { designOptionsCollection } from '@knack/style-engine';
import AppDesignPropertyPicker from '@/components/settings/design/AppDesignPropertyPicker';

const store = useStore();

const props = defineProps({
  viewLinkType: {
    type: String,
    required: true,
  },
  customDesignObject: {
    type: Object,
    default: undefined,
  },
  hideIconOption: {
    type: Boolean,
    default: false,
  },
});

const design = computed(() => {
  const appDesign = store.getters.app.get('design');
  return props.customDesignObject || appDesign.general.views.links[props.viewLinkType];
});

const designPropertyBasePath = computed(() => {
  if (props.customDesignObject) {
    return '';
  }
  return `general.views.links.${props.viewLinkType}.`;
});

const {
  buttonFormatOptions,
  buttonSizeOptions,
  borderThicknessOptions,
  buttonCustomColorOptions,
} = designOptionsCollection;

const buttonCustomBorderColorOptions = buttonCustomColorOptions.map((option) => {
  if (option.value === false) {
    return {
      ...option,
      label: 'Same as text color',
    };
  }
  return option;
});
</script>

<template>
  <div class="customization-options m-0">
    <AppDesignPropertyPicker
      :custom-design-object="customDesignObject"
      :design-property="`${designPropertyBasePath}format`"
      :options="buttonFormatOptions"
      label-text="Style"
    />

    <template v-if="design.format !== 'legacy'">
      <!-- Size -->
      <AppDesignPropertyPicker
        v-if="design.format !== 'text'"
        :custom-design-object="customDesignObject"
        :design-property="`${designPropertyBasePath}size`"
        :options="buttonSizeOptions"
        label-text="Size"
      />

      <!-- Text Color -->
      <AppDesignPropertyPicker
        :custom-design-object="customDesignObject"
        :design-property="`${designPropertyBasePath}colors.text.custom`"
        label-text="Text color"
        input-type="radio"
        :options="buttonCustomColorOptions"
      />
      <div
        v-if="design.colors.text.custom"
        class="selectionGroup -mt-2 mb-4"
        style="margin-top: -18px;"
      >
        <AppDesignPropertyPicker
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}colors.text.color`"
          label-text=""
          input-type="color"
        />
      </div>

      <!-- Outline/Fill color -->
      <template v-if="design.format === 'fill' || design.format === 'outline'">
        <AppDesignPropertyPicker
          :custom-design-object="customDesignObject"
          :design-property="`${designPropertyBasePath}colors.button.custom`"
          :label-text="design.format === 'fill' ? 'Fill color' : 'Outline color'"
          input-type="radio"
          :options="design.format === 'fill' ?
            buttonCustomColorOptions : buttonCustomBorderColorOptions"
        />
        <div
          v-if="design.colors.button.custom"
          class="selectionGroup -mt-2 mb-4"
          style="margin-top: -18px;"
        >
          <AppDesignPropertyPicker
            :custom-design-object="customDesignObject"
            :design-property="`${designPropertyBasePath}colors.button.color`"
            label-text=""
            input-type="color"
          />
        </div>
      </template>

      <!-- Text options -->
      <AppDesignPropertyPicker
        :custom-design-object="customDesignObject"
        :design-property="`${designPropertyBasePath}uppercase`"
        label-text="Text Options"
        description-text="Uppercase"
        input-type="checkbox"
      />

      <!-- Outline/Fill options -->
      <AppDesignPropertyPicker
        v-if="design.format === 'outline'"
        :custom-design-object="customDesignObject"
        :design-property="`${designPropertyBasePath}borderThickness`"
        label-text="Outline Options"
        :options="borderThicknessOptions"
      />
      <AppDesignPropertyPicker
        v-if="design.format !== 'text'"
        :custom-design-object="customDesignObject"
        :design-property="`${designPropertyBasePath}rounded`"
        :label-text="design.format === 'fill' ? 'Fill Options' : ''"
        description-text="Round Corners"
        input-type="checkbox"
        style="margin-bottom: 0;"
      />
      <AppDesignPropertyPicker
        v-if="design.format !== 'text'"
        :custom-design-object="customDesignObject"
        :design-property="`${designPropertyBasePath}raised`"
        label-text=""
        description-text="Raised"
        input-type="checkbox"
      />

      <!-- Icon -->
      <AppDesignPropertyPicker
        v-if="!hideIconOption"
        :custom-design-object="customDesignObject"
        :design-property="`${designPropertyBasePath}icon`"
        label-text="Default Icon"
        input-type="icon"
        help-text="This icon can be overriden with display rules or view properties"
      />
    </template>
  </div>
</template>
