<template>
  <div v-if="pageObject">
    <ul
      ref="multiRecords"
      class="linked-record-views"
    >
      <!-- VIEW ADDONS -->

      <li
        v-if="showForms"
        class="mb-3 p-0"
      >
        <div class="checkbox mr-0 pl-0 contents">
          <input
            id="multiform"
            v-model="viewOptions.multiForm"
            type="checkbox"
            class="mr-2"
          >
        </div>
        <div class="view-label">
          <label
            for="multiform"
            data-cy="child-page-edit"
          >
            <span class="text-base leading-4 font-normal"><b>edit</b> each record with a <b>form</b></span>
          </label>
        </div>
      </li>

      <li class="mb-3 p-0">
        <div class="checkbox checked mr-0 pl-0 contents">
          <input
            id="multiDetails"
            v-model="viewOptions.multiDetails"
            type="checkbox"
            class="mr-2"
            checked
          >
        </div>
        <div class="view-label">
          <label
            for="multiDetails"
            data-cy="child-page-details"
          >
            <span class="text-base leading-4 font-normal">a <b>details</b> page with...</span>
          </label>
        </div>
      </li>
      <ul
        v-if="possibleViewsToAdd.length"
        id="add-view-addons"
        ref="multiDetailAddonViews"
        class="border-0 ml-0 pl-0"
        style="overflow: hidden; max-height: 0px;"
      >
        <ActionList
          id="add-detail-views-list"
          v-model:items="viewOptions.multiDetailAddonViews"
          :can-be-empty="true"
          :default-item="defaultDetailsView"
          class="naked"
          action-list-item-classes="bg-subtle rounded-lg p-4"
        >
          <template #default="props">
            <div>
              <PageAddViewPicker
                v-model:view-type="props.item.viewType"
                v-tippy
                content="Select a different view"
                data-cy="view-type-selector"
                :can-show-views-for-multiple-records="hasAvailableViewsForMultipleRecords"
                :can-show-map-views="hasAvailableMapViews"
                :can-show-calendar-views="hasAvailableCalendarViews"
                class="view-selector"
              />
              &nbsp;
              <Dropdown
                v-if="props.item.viewType"
                v-model="props.item.source"
                data-cy="source-picker"
                :options="getViewOptionsBasedOnType(props.item.viewType)"
                class="is-text source-picker"
              />
            </div>
          </template>
        </ActionList>

        <li
          class="mb-3 p-0 mt-4"
          style="border: 0; padding: 0; padding-bottom: .25em; padding-top: .25em;"
        >
          <a
            id="add-detail-views"
            class="green-text p-3 rounded-lg text-base font-medium m-0 leading-4 border border-solid border-default bg-white text-emphasis hover:bg-brand-50 hover:border-brand-600 group capitalize"
            @click="onAddDetailsView"
          >
            <Icon
              type="plus-thin"
              class="w-4 h-4 text-subtle group-hover:text-brand-400 top-0.5 right-0"
              data-cy="add-additional-view"
            /> Add an additional view
          </a>
        </li>
      </ul>

      <!-- EXISTING PAGE LINKS -->
      <li
        v-if="showExistingPageOption"
        class="mb-3 p-0"
      >
        <div class="checkbox checked contents">
          <input
            id="multi-existing-page"
            v-model="viewOptions.multiExistingPage"
            type="checkbox"
            class="mr-2"
            checked
          >
        </div>
        <div class="view-label">
          <label
            for="multi-existing-page"
            data-cy="add-existing-page-link"
          >
            <span class="text-base leading-4 font-normal">an <b>existing page</b></span>
          </label>
        </div>
      </li>

      <ul
        v-if="showExistingPageOption"
        id="add-view-addons"
        ref="multiExistingPages"
        class="border-0 ml-0 pl-0"
        style="overflow: hidden; max-height: 0px;"
      >
        <ActionList
          id="add-detail-views-list"
          v-model:items="viewOptions.multiExistingPages"
          :can-be-empty="true"
          :default-item="defaultExistingPageToLinkTo"
          class="naked"
          action-list-item-classes="bg-subtle rounded-lg p-4"
        >
          <template #default="{item}">
            <select
              v-model="item.page"
              style="min-width: 0;"
            >
              <option
                v-for="linkableExistingPage in linkableExistingPages"
                :key="linkableExistingPage.key"
                :value="linkableExistingPage.page"
              >
                {{ linkableExistingPage.label }}
              </option>
            </select>
          </template>
        </ActionList>

        <li
          class="mb-3 mt-4 p-0"
          style="border: 0; padding: 0; padding-bottom: .25em; padding-top: .25em;"
        >
          <a
            id="add-detail-views"
            class="green-text p-3 rounded-lg text-base font-medium m-0 leading-4 border border-solid border-default bg-white text-emphasis hover:bg-brand-50 hover:border-brand-600 group capitalize"
            @click="onAddExistingPageLink"
          >
            <Icon
              class="w-4 h-4 text-subtle group-hover:text-brand-400 top-0.5 right-0"
              type="plus-thin"
            /> Add an additional link
          </a>
        </li>
      </ul>
    </ul>

    <div
      class="flex justify-end"
      style="margin-top: 1.25em;"
    >
      <a
        class="button orange-fill medium p-3 rounded-lg bg-gradient-primary border-0 text-base font-medium m-0 leading-4"
        data-cy="add-view-wizard-continue"
        @click="onSubmit()"
      >
        Continue
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageAddViewPicker from '@/components/pages/PageAddViewPicker';
import Icon from '@/components/ui/Icon';
import UIMixin from '@/components/ui/UIMixin.js';
import ActionList from '@/components/ui/lists/ActionList';
import Dropdown from '@/components/ui/Dropdown';
import PageAddUtils from '@/components/pages/PageAddUtils.js';
import { NEW_VIEW_KEY } from '@/lib/page/page-constants';

export default {
  components: {
    Icon,
    PageAddViewPicker,
    ActionList,
    Dropdown,
  },
  mixins: [
    PageAddUtils,
    UIMixin,
  ],
  props: {
    options: {
      type: Object,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'submit',
    'update:options',
  ],
  data() {
    return {

      viewOptions: {
        multiRecords: true,
        multiForm: false,
        multiDetails: false,
        multiDetailAddonViews: [],
        multiExistingPage: false,
        multiExistingPages: [],
      },
      views: () => [],
      possibleViewsToAdd: () => ([]),
      hasAvailableViewsForMultipleRecords: false,
      hasAvailableCalendarViews: false,
      hasAvailableMapViews: false,
    };
  },
  computed: {
    ...mapGetters([
      'activePage',
      'getObject',
    ]),
    pageObject() {
      return this.getObject(this.options.source.object);
    },
    linkableExistingPages() {
      return this.$store.getters.getDetailPagesByObject(this.options.source.object);
    },
    showExistingPageOption() {
      return this.linkableExistingPages.length;
    },
    showForms() {
      // ecommerce objects are read only
      return !this.pageObject.get('ecommerce');
    },
    objectNameSingular() {
      return this.pageObject.inflections.singular;
    },
    objectNamePlural() {
      return this.pageObject.inflections.plural;
    },
    recordQuantity() {
      let which = 'many';

      if (this.options.recordQuantity) {
        which = this.options.recordQuantity;
      } else if (this.options.isSinglePage && this.page.get('object')) {
        which = 'one';
      }

      return which;
    },
  },
  watch: {
    'options.source.object': function (newVal) {
      // reset any additional addon views since these are object dependent
      this.viewOptions.multiDetails = false;
      this.viewOptions.multiDetailAddonViews = [];
      this.viewOptions.multiExistingPage = false;
      this.viewOptions.multiExistingPages = [];
    },

    'viewOptions.multiDetails': function (newVal) {
      if (!this.$refs.multiDetailAddonViews) {
        return;
      }

      // if empty set a default page to use
      if (newVal && this.viewOptions.multiDetailAddonViews.length === 0) {
        this.viewOptions.multiDetailAddonViews.push(this.defaultDetailsView());
      }

      this.$nextTick(() => {
        this.slideToggle(this.$refs.multiDetailAddonViews, newVal, {
          visibility: 'visible',
        });
      });
    },
    'viewOptions.multiExistingPage': async function (newVal) {
      log('multiExistingPage watch!!!!', this.$refs.multiExistingPages);

      if (!this.$refs.multiExistingPages) {
        return;
      }

      // if empty set a default page to use
      if (newVal && this.viewOptions.multiExistingPages.length === 0) {
        this.viewOptions.multiExistingPages.push(this.defaultExistingPageToLinkTo());
      }

      await this.$nextTick();

      this.slideToggle(this.$refs.multiExistingPages, newVal, {

        visibility: 'visible',
      });
    },

    // By doing a deep watch here we're using any change to any link to rebuild and replace the view being previewed
    viewOptions: {

      handler(newVal, oldVal) {
        this.buildViewModel();
      },
      deep: true,
    },
  },
  created() {
    this.generatePossibleViewOptions();
  },
  methods: {
    generatePossibleViewOptions() {
      const sourceObject = this.getObject(this.options.source.object);

      this.possibleViewsToAdd = [
        {
          key: this.options.source.object,
          objectKey: this.options.source.object,
          value: this.options.source.object,
          view: 'details',
          label: `of the <strong>${sourceObject.inflections.singular}</strong>`,
        },
        {
          key: this.options.source.object,
          objectKey: this.options.source.object,
          value: this.options.source.object,
          view: 'form',
          label: `to edit the <strong>${sourceObject.inflections.singular}</strong>`,
        },
      ];

      this.pageObject.conns.forEach((conn) => {
        // to one connections
        if (conn.has === 'one') {
          const connectedObjectModel = this.getConnectedObjectModel(conn);

          this.possibleViewsToAdd.push({
            key: conn.key,
            objectKey: conn.object,
            value: `${conn.object}-${conn.key}`,
            view: 'details',
            label: `of the connected <strong>${connectedObjectModel.inflections.singular}</strong>`,
          });

          this.possibleViewsToAdd.push({
            key: conn.key,
            objectKey: conn.object,
            value: `${conn.object}-${conn.key}`,
            view: 'form',
            label: `to add a connected <b>${connectedObjectModel.inflections.singular}</b>`,
          });

          return;
        }

        // to many connections
        this.hasAvailableViewsForMultipleRecords = true;

        const connectedObjectModel = this.getConnectedObjectModel(conn);
        const hasAddressField = connectedObjectModel.hasAddressField();
        const hasDateField = connectedObjectModel.hasDateField();

        if (!this.hasAvailableMapViews) {
          this.hasAvailableMapViews = hasAddressField;
        }

        if (!this.hasAvailableCalendarViews) {
          this.hasAvailableCalendarViews = hasDateField;
        }

        this.possibleViewsToAdd.push({
          key: conn.key,
          objectKey: conn.object,
          value: `${conn.object}-${conn.key}`,
          view: 'records',
          hasDateField,
          hasAddressField,
          label: `of connected <strong>${connectedObjectModel.inflections.plural}</strong>`,
        });

        this.possibleViewsToAdd.push({
          key: conn.key,
          objectKey: conn.object,
          value: `${conn.object}-${conn.key}`,
          view: 'form',
          label: `to add a connected <b>${connectedObjectModel.inflections.singular}</b>`,
        });
      });
    },

    // this builds the actual view model and replaces it in the page's views so it can render in the preview
    async buildViewModel() {
      if (!this.pageObject) {
        return;
      }

      // create records view
      const recordsView = {
        name: this.objectNamePlural,
        type: this.options.type,
        source: this.options.source || {
          object: this.pageObject.key,
        },
      };

      // checked options (like edit/details/connected views) to add to this view
      const viewOptions = {
        pagesToAdd: [],
      };

      // add a link to an edit form to this view
      if (this.viewOptions.multiForm) {
        const pageName = `Edit ${this.objectNameSingular}`;
        const page = {
          name: pageName,
          object: this.pageObject.key,
          parent: this.activePage.slug,
        };
        const views = [
          {
            name: pageName,
            type: 'form',
            action: 'update',
          },
        ];
        const formPage = {
          type: 'form',
          page: this.buildPageModel(page, views),
        };

        viewOptions.pagesToAdd.push(formPage);
      }

      // add a link to a details form to this view
      if (this.viewOptions.multiDetails) {
        // start the details views
        const pageName = `${this.objectNameSingular} Details`;
        let views = [
          {
            name: pageName,
            type: 'details',
          },
        ];

        // add any detail addon views
        if (this.viewOptions.multiDetailAddonViews) {
          // start with an empty views array otherwise the default details view is added twice
          views = [];

          this.viewOptions.multiDetailAddonViews.forEach((addonView) => {
            const sourceObjectKey = addonView.source.split('-')[0];
            const sourceConnectionFieldKey = addonView.source.split('-')[1];

            const sourceObject = this.getObject(sourceObjectKey);

            const name = sourceObject.inflections.singular;

            const viewToAdd = {
              name,
              type: addonView.viewType,
              source: {
                object: sourceObjectKey,
              },
            };

            if (addonView.viewType === 'form') {
              viewToAdd.action = 'update';
            }

            // connections put fields in the key
            if (sourceConnectionFieldKey) {
              const connectionField = this.$store.getters.getField(sourceConnectionFieldKey);

              viewToAdd.source.connection_key = sourceConnectionFieldKey;
              viewToAdd.source.relationship_type = (connectionField.objectKey === viewToAdd.source.object) ? 'foreign' : 'local';

              if (addonView.viewType === 'form') {
                viewToAdd.action = 'create';
              }
            }

            // form
            views.push(viewToAdd);
          });
        }

        // add the details page
        const page = {
          name: pageName,
          object: this.pageObject.key,
          parent: this.activePage.slug,
        };

        const detailsPage = {
          type: 'details',
          page: this.buildPageModel(page, views),
        };

        log('adding detailsPage', detailsPage, views);
        viewOptions.pagesToAdd.push(detailsPage);
      }

      // add any links to existing pages
      if (this.viewOptions.multiExistingPage) {
        this.viewOptions.multiExistingPages.forEach((page) => {
          page = this.$store.getters.getPageBySlug(page.page);
          log('existing page', page);

          const linkPage = {
            type: 'link',
            slug: page.slug,
            name: page.name,
          };

          viewOptions.pagesToAdd.push(linkPage);
        });
      }

      const view = this.buildViewSchema(this.page.raw, recordsView, viewOptions);

      view.key = NEW_VIEW_KEY;
      log(`buildRecordsView() type: ${this.options.type}`, viewOptions, view);

      const newView = await this.$store.dispatch('page/view/replaceViewLocally', {
        viewData: view,
      });

      // emit view updates to ViewAdd (parent) usage of ViewAddOptions
      this.$emit('update:options', newView);
    },

    defaultExistingPageToLinkTo() {
      return {
        page: this.linkableExistingPages[0].page,
      };
    },

    defaultDetailsView() {
      return {
        source: this.options.source.object,
        viewType: 'details',
      };
    },

    getViewOptionsBasedOnType(viewType) {
      log('getOptions for viewType:', viewType, this.possibleViewsToAdd);

      return this.possibleViewsToAdd.filter((possibleView) => {
        if (viewType === 'form' || viewType === 'details') {
          return possibleView.view === viewType;
        }

        if (viewType === 'map' && !possibleView.hasAddressField) {
          return false;
        }

        if (viewType === 'calendar' && !possibleView.hasDateField) {
          return false;
        }

        return possibleView.view === 'records'; // every view by this point should have view === records
      });
    },

    getConnectedObjectModel(conn) {
      return this.getObject(conn.object);
    },

    onAddDetailsView() {
      this.viewOptions.multiDetailAddonViews.push(this.defaultDetailsView());
    },

    onAddExistingPageLink() {
      this.viewOptions.multiExistingPages.push(this.defaultExistingPageToLinkTo());
    },

    onSubmit() {
      this.$emit('submit', this.viewOptions); // , this.buildPageViews())
    },
  },
};
</script>

<style lang="scss" scoped>
.source-picker {
  line-height: 2.2em;
}
</style>

<style lang="scss">
#add-detail-views-list {
  .add-link-button {
    display: none;
  }
}

#add-detail-views {

  .icon {
    width: 18px;
    height: 18px;
    position: relative;
    right: -1px;
    top: 4px;
  }
}

.linked-record-views {

  font-size: .95em;

  .checkbox {
    cursor: pointer;
    padding-left: 5px;
    margin-right: 4px;
  }

  .view-label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  label {
    line-height: 1em;
    font-weight: 400;
  }

  > ul {
    margin-left: 10px;
    padding-left: 1em;
    border-left: 1px solid #dddee2;
  }
  ul {
    transition: all 300ms ease-out;
    overflow: hidden;
  }
  li {
    padding: .65em 0;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: .25em;
    }
    em {
      font-weight: 500;
      font-style: normal;
      color: #000;
    }
  }

  input[type=checkbox] {
    cursor: pointer;
    margin: 0;
    line-height: 1em;
    font-size: 1em;
  }

  .action-list li {
    padding: .35em .5em .5em .35em;
    background: #e7e8ec;
  }
}
</style>
