<template>
  <button
    class="flex gap-1 justify-center p-3 rounded-lg border border-solid border-default bg-white leading-none
          hover:bg-brand-50 hover:border-brand-600 text-emphasis text-base font-medium mr-2"
    @click="copyText"
  >
    <Icon
      class="h-4 w-4"
      :type="isCopied ? 'check' : 'clipboard-document'"
    />
    <span v-if="isCopied">Copied!</span>
    <span v-else>
      {{ buttonText }}
    </span>
  </button>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    textToCopy: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  methods: {
    async copyText() {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.textToCopy);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = this.textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Unable to copy to clipboard');
        }
        document.body.removeChild(textArea);
      }

      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    },
  },
};
</script>
