<template>
  <div :class="inputClass">
    <Select
      v-if="isSelect"
      v-model="localValue"
      :field="field"
      :options="localOptions"
      :name="name"
      :input="input"
    />
    <Radios
      v-else-if="isRadios"
      v-model="localValue"
      :options="localOptions"
      :name="name"
    />
    <Checkboxes
      v-else-if="isCheckboxes"
      v-model="localValue"
      :options="localOptions"
    />
    <MultiSelect
      v-else-if="isMultiSelect"
      ref="multiSelect"
      v-model="localMultiValue"
      v-shortkey.once="['esc']"
      class="kn-multiSelect"
      data-cy="multiple-choice-picker"
      :show-labels="false"
      :options="localOptions"
      :multiple="true"
      :custom-label="customLabel"
      :close-on-select="true"
      :hide-selected="true"
      track-by="value"
      label="label"
      placeholder="Select..."
      @shortkey="close"
    />
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Select from '@/components/renderer/form/inputs/Select';
import Checkboxes from '@/components/renderer/form/inputs/MultipleChoiceCheckboxes';
import Radios from '@/components/renderer/form/inputs/Radios';

export default {
  name: 'MultipleChoiceInput',
  components: {
    Radios,
    Checkboxes,
    Select,
    MultiSelect,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [
        Object,
        String,
        Array,
        Boolean,
      ],
      required: true,
    },
    input: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    useSelectInput: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      valueHasChanged: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue ?? '';
      },
      set(newValue) {
        const emitValue = (newValue === 'kn-blank') ? '' : newValue;

        this.$emit('update:modelValue', emitValue ?? '');
      },
    },

    // responsible for converting this.localValue to value/label pairs for MultiSelect compatibility
    localMultiValue: {

      get() {
        if (this.localValue === 'kn-blank' || this.localValue[0] === 'kn-blank') {
          return [];
        }

        const value = isArray(this.localValue) ? this.localValue : isEmpty(this.localValue) ? [] : this.localValue.split(', ');

        // this is an edit, so return active values
        if (this.isMultiSelect && !isEmpty(value)) {
          const values = [];

          value.forEach((option) => {

            const optionValue = option?.value || option;

            // does this option exist?
            const existingOption = this.localOptions.find((option) => option.value === optionValue);

            if (existingOption) {
              values.push({
                value: optionValue,
                label: existingOption.label,
              });
            }
          });

          return values;
        }

        // if there's a default value display that within the input
        if (this.input.format.default && this.input.format.default !== 'kn-blank' && !this.valueHasChanged) {
          return [
            {
              label: this.input.format.default,
              value: this.input.format.default,
            },
          ];
        }

        // otherwise this is set to blank so return empty
        return [];
      },
      set(newValue) {
        this.valueHasChanged = true;

        // flatten for local value
        this.localValue = newValue.map((val) => (!isNil(val.value) ? val.value : val));
      },

    },
    name() {
      return this.input.key;
    },
    format() {
      return this.input.format || {};
    },
    localInputType() {
      if (this.useSelectInput || !this.format.type) {
        return 'select';
      }

      return this.format.type;
    },
    isSelect() {
      return (this.localInputType === 'select' || this.localInputType === 'single' || this.localInputType === 'dropdown');
    },
    isRadios() {
      return (this.localInputType.includes('radio')); // will return true for singular or plural
    },
    isCheckboxes() {
      return (this.localInputType.includes('checkbox')); // will return true for singular or plural
    },
    isMultiSelect() {
      return (this.localInputType === 'multi' || this.localInputType === 'chosen');
    },
    inputClass() {
      if (this.isCheckboxes) {
        return 'kn-checkbox';
      }

      if (this.isRadios) {
        return 'kn-radio';
      }

      return 'kn-select';
    },
    // this ensures we can use options from multiple sources, like connection values of id/identifiers
    localOptions() {
      const options = this.options.length ? this.options : this.format.options;

      return (options || []).map((option) => {
        if (typeof option === 'string' || typeof option === 'number') {
          return {
            label: option,
            value: option,
          };
        }

        if (!option) {
          return undefined;
        }

        if (option.id) {
          return {
            label: option.identifier,
            value: option.id,
          };
        }

        if (isNil(option.label) || isNil(option.value)) {
          return undefined;
        }

        return option;
      }).filter(Boolean);
    },
  },
  mounted() {
    // this ensures any default is processed and kn-blank is handled
    this.localValue = this.modelValue;
  },
  methods: {
    customLabel({ label }) {
      return `${label}`;
    },
    close(event) {
      if (this.$refs.multiSelect.isOpen) {
        this.$refs.multiSelect.deactivate();
        event.stopPropagation();
      }
    },
  },
};
</script>

<style lang="scss">
</style>
