<template>
  <div class="left-toolbox bg-muted">
    <div class="title-wrapper p-4 m-0">
      <div class="title border-b-0 h-auto">
        <h3 class="text-emphasis font-semibold text-base">
          Settings
        </h3>
      </div>
    </div>
    <ul
      id="settings-menu-links"
      class="py-0 px-4"
    >
      <RouterLink
        v-slot="{ href, isActive, navigate }"
        to="/settings/app"
        custom
      >
        <li
          data-cy="app-settings-link"
          :class="getListItemClasses(isActive)"
        >
          <a
            :href="href"
            class="text-inherit"
            @click="navigate"
          >
            <Icon
              :type="getIconType('settings-app-settings')"
              class="settings mr-2 h-8 w-8"
              :class="{ 'fill-[url(#svg-brand-gradient)]': isActive }"
            />
            <span :class="listItemTextClasses">
              App Settings
            </span>
          </a>
        </li>
      </RouterLink>
      <RouterLink
        v-slot="{ href, isActive, navigate }"
        to="/settings/design"
        custom
      >
        <li
          :class="getListItemClasses(isActive)"
          data-cy="design-settings-link"
          data-feature="live_app_design_button"
          data-feature-y-offset="-2"
        >
          <a
            :href="href"
            class="text-inherit"
            @click="navigate"
          >
            <Icon
              :type="getIconType('settings-live-app-design')"
              class="settings mr-2 h-8 w-8"
              :class="{ 'fill-[url(#svg-brand-gradient)]': isActive }"
            />
            <span :class="listItemTextClasses">
              Live App Design
            </span>
          </a>
        </li>
      </RouterLink>
      <RouterLink
        v-slot="{ href, isActive, navigate }"
        to="/settings/users"
        custom
      >
        <li
          :class="getListItemClasses(isActive)"
          data-cy="login-settings-link"
        >
          <a
            :href="href"
            class="text-inherit"
            @click="navigate"
          >
            <Icon
              :type="getIconType('settings-user-logins')"
              class="settings mr-2 h-8 w-8"
              :class="{ 'fill-[url(#svg-brand-gradient)]': isActive }"
            />
            <span :class="listItemTextClasses">
              User Logins
            </span>
          </a>
        </li>
      </RouterLink>
      <RouterLink
        v-slot="{ href, isActive, navigate }"
        to="/settings/ecommerce"
        custom
      >
        <li
          :class="getListItemClasses(isActive)"
          data-cy="ecommerce-settings-link"
        >
          <a
            :href="href"
            class="text-inherit"
            @click="navigate"
          >
            <Icon
              :type="getIconType('settings-ecommerce')"
              class="settings mr-2 h-8 w-8"
              :class="{ 'fill-[url(#svg-brand-gradient)]': isActive }"
            />
            <span :class="listItemTextClasses">
              E-Commerce
            </span>
          </a>
        </li>
      </RouterLink>
      <RouterLink
        v-slot="{ href, isActive, navigate }"
        to="/settings/api"
        custom
      >
        <li
          :class="getListItemClasses(isActive)"
          data-cy="code-settings-link"
        >
          <a
            :href="href"
            class="text-inherit"
            @click="navigate"
          >
            <Icon
              :type="getIconType('settings-api-and-code')"
              class="settings mr-2 h-8 w-8"
              :class="{ 'fill-[url(#svg-brand-gradient)]': isActive }"
            />
            <span :class="listItemTextClasses">
              API &amp; Code
            </span>
          </a>
        </li>
      </RouterLink>
      <RouterLink
        v-slot="{ href, isActive, navigate }"
        to="/settings/embeds"
        custom
      >
        <li
          :class="getListItemClasses(isActive)"
          data-cy="embed-settings-link"
        >
          <a
            :href="href"
            class="text-inherit"
            @click="navigate"
          >
            <Icon
              :type="getIconType('settings-embeds')"
              class="settings mr-2 h-8 w-8"
              :class="{ 'fill-[url(#svg-brand-gradient)]': isActive }"
            />
            <span :class="listItemTextClasses">
              Embed
            </span>
          </a>
        </li>
      </RouterLink>
      <RouterLink
        v-if="hasIntegrationsEnabled"
        v-slot="{ href, isActive, navigate }"
        to="/settings/integrations"
        custom
      >
        <li
          class="intercom-integrations-settings"
          :class="getListItemClasses(isActive)"
          data-cy="integrations-settings-link"
        >
          <a
            :href="href"
            class="text-inherit"
            @click="navigate"
          >
            <Icon
              type="puzzle-piece-outline-no-stroke"
              class="settings mr-2 h-8 w-8"
              :class="{ 'svg-brand-gradient-stroke': isActive }"
            />
            <span :class="listItemTextClasses">
              Integrations
              <div class="badge-wrapper">
                <Badge intent="brand">
                  Preview
                </Badge>
              </div>
            </span>
          </a>
        </li>
      </RouterLink>
    </ul>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Badge from '@/components/ui/Badge';
import { INTEGRATIONS } from '@/constants/featureflags';

export default {
  components: {
    Icon,
    Badge,
  },
  computed: {
    hasIntegrationsEnabled() {
      return this.$store.getters.hasFeature(INTEGRATIONS);
    },
    listItemTextClasses() {
      return 'settings-link-text text-inherit font-normal text-base';
    },
  },
  methods: {
    getIconType(type) {
      return `${type}-outline`;
    },
    getListItemClasses(isActive) {
      if (isActive) {
        return 'rounded-xl router-link-active bg-brand-100 text-emphasis';
      }
      return 'rounded-xl hover:bg-brand-50 hover:text-emphasis';
    },
  },
};
</script>

<style lang="scss">
#settings-menu-links {
  padding: .5em;

  // Set the svg colors: see https://tympanus.net/codrops/2015/07/16/styling-svg-use-content-css/.
  svg.settings > use {
    color: $gray500;
    --background-color: #fff;
    --secondary-color: #{$gray200};
  }

  li {
    border-radius: .375em;
    padding: .5em;
    margin-bottom: .5em;

    &:hover {
      background-color: $gray100;
    }
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $gray400;
    transition: all 300ms ease-out;

    &:hover {
      svg {
        opacity: 1;
      }
    }

    p {
      font-size: .96em;
      line-height: 1.25em;
      margin-right: 1em;
    }
  }

  svg.settings {
    width: 32px;
    height: 32px;
    margin-right: .75em;
  }

  svg.icon-arrow-forward {
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: all 300ms ease-out;

    use {
      fill: $secondary500;
    }
  }

  .settings-link-text {
    color: $gray700;
    @include font-body;
    font-weight: 500;
    flex-grow: 1;
  }

  a:hover {
   /* background-color: #f8f9fb;*/
  }

  li.router-link-active {

    background-color: $white100;

    /*svg.settings {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }*/

    svg.icon-arrow-forward {
      opacity: 1;
    }

    .settings-link-text  {
      color: $secondary500;
      font-weight: 600;
    }
  }
}
.badge-wrapper {
  font-weight: 400;
  margin-left: $spacing-xxs;
}
</style>
