<template>
  <div class="fieldName items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis mt-2">
    <TypeIcon
      icon-classes="text-subtle"
      :field="field"
    />
    <span>{{ fieldName }}</span>
  </div>
</template>

<script>
import TypeIcon from '@/components/fields/TypeIcon';

export default {
  name: 'FieldName',
  components: {
    TypeIcon,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    connection: {
      type: String,
      default: '',
    },
    thumb: {
      type: String,
      default: '',
    },
  },
  computed: {
    fieldName() {
      let name = '';

      if (this.connection) {
        name = `${this.connection} > `;
      }

      if (this.field?.name) {
        name += this.field.name;
      }

      if (this.thumb) {
        name += `:${this.thumb}`;
      }

      return name;
    },
  },
};
</script>

<style lang="scss">
.fieldName {
  display: flex;
  align-items: center;

  .field-icon {
    flex-shrink: 1;
    width: 15px; height: 15px; margin-right: .25em;
  }
}
</style>
