<template>
  <div class="inputGroup">
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Link Text Format</label>
      <select
        v-model="fieldLocal.format.text_format"
        class="text-base py-2 pl-3 leading-5"
      >
        <option value="url">
          Use the URL
        </option>
        <option value="same">
          Use the same text for all links
        </option>
        <option value="unique">
          Use unique text for each link
        </option>
      </select>
    </div>
    <div v-if="fieldLocal.format.text_format === 'same'">
      <label
        for="label"
        class="text-default text-sm font-medium mb-2 leading-4"
      >
        Link Text
      </label>
      <input
        v-model="fieldLocal.format.label"
        type="text"
      >
    </div>
    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4"><input
        v-model="target"
        type="checkbox"
      > Open in new window</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Link',
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    target: {
      get() {
        return this.fieldLocal.format.target === '_blank';
      },
      set(newValue) {
        this.fieldLocal.format.target = (newValue === true) ? '_blank' : '_self';
      },
    },
  },
};
</script>
