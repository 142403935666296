<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue';

const emit = defineEmits(['update:value']);

const props = defineProps({
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  label: {
    type: String,
    default: '',
  },
  unit: {
    type: String,
    default: '',
  },
  value: {
    type: [Number, String],
    required: true,
  },
});

const localValue = ref(props.value);
const errorMessage = ref('');

const onInputChange = (e) => {
  const { value } = e.target;
  localValue.value = value;

  if (!value) {
    errorMessage.value = 'Please provide a valid value';
  } else if (value < props.min || value > props.max) {
    errorMessage.value = `Please provide a value between ${props.min} and ${props.max}`;
  } else {
    errorMessage.value = '';
    emit('update:value', value);
  }
};

// Watch for changes to props.value to account for updates outside of this component
watch(() => props.value, () => {
  localValue.value = props.value;

  if (props.value >= props.min && props.value <= props.max) {
    errorMessage.value = '';
  }
});
</script>

<template>
  <div class="kn-range-slider">
    <label
      v-if="props.label"
      class="kn-range-slider__label"
    >
      {{ label }}
    </label>

    <div class="kn-range-slider__inputs">
      <input
        type="range"
        class="kn-range-slider__slider"
        :min="props.min"
        :max="props.max"
        :value="localValue"
        @input="onInputChange"
      >
      <input
        type="number"
        class="kn-range-slider__input"
        :min="props.min"
        :max="props.max"
        :value="localValue"
        @input="onInputChange"
      >
      <span class="kn-range-slider__unit">{{ unit }}</span>
    </div>

    <p
      v-if="errorMessage"
      class="error-msg"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<style lang="scss">
.kn-range-slider {

  .kn-range-slider__label {
    font-size: 14px;
    font-weight: 600;
  }

  .kn-range-slider__inputs {
    display: flex;
    align-items: center;
  }

  .kn-range-slider__slider {
    width: 100%;
    height: 8px;
    padding: 0;
    border-radius: 5px;
    background-color: #e1e1e2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
    &:focus {
      border: none !important;
    }

    // Track
    &::-webkit-slider-runnable-track  {
      -webkit-appearance: none;
      appearance: none;
      box-shadow: none;
      border: none;
      border-radius: 5px;
      background: transparent;
    }
    &::-moz-range-track {
      -webkit-appearance: none;
      appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    // Thumb
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $primary500;
      cursor: pointer;
    }
    &::-moz-range-thumb {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $primary500;
      cursor: pointer;
    }
  }

  .kn-range-slider__input {
    height: auto;
    line-height: normal;
    margin-left: 15px;
    max-width: 75px;
  }

  .kn-range-slider__unit {
    margin-left: 5px;
  }

  .error-msg {
    margin-top: 0.5rem;
  }
}
</style>
