<template>
  <WizardStep
    :wizard-step="wizardStep"
    :step="step"
    @update:wizardStep="$emit('update:wizardStep', step)"
  >
    <template #edit>
      <div class="mb-4 text-base font-semibold tracking-[.32px]">
        using <strong>{{ totalFieldDisplayName }}</strong> as the payment total field
      </div>
    </template>
    <template #intro>
      <div class="mb-2">
        Which numeric <strong>{{ totalObjectDisplayName }}</strong> field will be used as the payment total?
      </div>
    </template>
    <div>
      <div>
        <div v-if="hasMultipleFieldOptions">
          <div
            v-for="field in numericFields"
            :key="field.key"
            class="input-labels tw-input-labels"
          >
            <label><input
              v-model="totalField"
              type="radio"
              name="totalField"
              :value="field.key"
            > {{ field.name }}</label>
          </div>
        </div>
        <div
          v-else-if="firstField"
          class="margin-bottom-sm"
        >
          The <strong>{{ firstField.name }}</strong> field will be used as the total amount.
        </div>
      </div>
      <div class="margin-top flex justify-end">
        <button
          type="button"
          class="button orange-fill medium p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium"
          @click="onNext"
        >
          Continue
        </button>
      </div>
    </div>
  </WizardStep>
</template>

<script>
import { mapGetters } from 'vuex';
import WizardStep from '@/components/ui/WizardSection';
import PaymentUtils from '@/components/settings/ecommerce/PaymentUtils';

export default {
  components: {
    WizardStep,
  },
  mixins: [
    PaymentUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    wizardStep: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:wizardStep', 'next'],
  data() {
    return {

      totalField: null,
    };
  },
  computed: {
    ...mapGetters([
      'getField',
      'getObject',
    ]),
    totalFieldDisplayName() {
      const field = this.getField(this.totalField);

      if (_.isNil(field)) {
        return;
      }

      return field.name;
    },
    totalObjectDisplayName() {
      const field = this.getField(this.totalField);

      const object = this.getObject(field.objectKey);

      if (_.isNil(object)) {
        return;
      }

      return object.name;
    },
    hasMultipleFieldOptions() {
      return this.numericFields.length > 1;
    },
    firstField() {
      return this.numericFields.length > 0 ? this.numericFields[0] : null;
    },
  },
  created() {
    if (_.isNil(this.totalField)) {
      const existingProcessor = (this.view.paymentProcessors && this.view.paymentProcessors.length > 0) ? this.view.paymentProcessors[0] : undefined;

      this.totalField = _.get(existingProcessor, 'total_field_key', this.firstField.key);
    }
  },
  methods: {
    onNext() {
      if (this.view.key === 'new') {
        this.view.summaryFields = [
          {
            field: this.totalField,
            text: 'Charge Total',
          },
        ];
      }

      this.view.totalField = this.totalField; // used by payment proocessor

      this.$emit('next');
    },
  },
};
</script>

<style lang="scss">
</style>
