import LogRocket from 'logrocket';
import get from 'lodash/get';

import { shouldEnableLogRocket } from '@/lib/env-helper';
import store from '@/store';

type LogRocketEvent = 'NavigateToBuilderNext' | 'NavigateToDashboard';

// eslint-disable-next-line import/prefer-default-export
export const safeLogRocketTrack = (event: LogRocketEvent) => {
  const account = store.state.app.get('account');
  const isAppHipaa = get(account, 'settings.hipaa.enabled') === true;

  if (shouldEnableLogRocket() && !isAppHipaa) {
    LogRocket.track(event);
  }
};
