<template>
  <ViewToolbox
    v-if="view"
    back-title="Form"
    :back-count="6"
    title="Input Properties"
  >
    <InputProperties
      :input="input"
    />
  </ViewToolbox>
</template>

<script>
import InputProperties from '@/components/views/shared/InputProperties';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';

export default {
  name: 'FormInputProperties',
  components: {
    ViewToolbox,
    InputProperties,
  },
  mixins: [
    ViewUtils,
  ],
  computed: {
    input() {
      const {
        columnIndex,
        inputIndex,
        rowIndex,
      } = this.$route.params;

      const item = this
        .viewRaw
        ?.groups?.[rowIndex]
        ?.columns[columnIndex]
        ?.inputs[inputIndex];

      return item || {};
    },
  },
};
</script>
