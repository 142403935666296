<template>
  <a
    :href="billingUrl"
    class="flex gap-2 items-center justify-center w-[100px] h-[24px] border-brand-gradient"
  >
    <Icon
      type="stars"
      class="fill-[url(#svg-bg-gradient)] h-[16px] w-[16px]"
      style="transform: rotate(90deg)"
    />
    <span class="text-gradient-primary text-sm text-gradient font-medium">
      Upgrade
    </span>
  </a>
</template>

<script>
import Icon from '@/components/ui/Icon.vue';

export default {
  name: 'UpgradeButton',
  components: {
    Icon,
  },
  computed: {
    billingUrl() {
      return `${process.env.VUE_APP_DASHBOARD_URL}/settings/billing/plans`;
    },
  },
};
</script>
