<template>
  <Modal
    title="Redirect to a new Child Page"
    size="medium"
    @close="$emit('close')"
  >
    <PageAddWizard
      :parent-page="$store.getters.activePage"
      :page-vars="pageVars"
      @add-page="onAddPage"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';
import PageAddWizard from '@/components/pages/PageAddWizard';

export default {
  components: {
    Modal,
    PageAddWizard,
  },
  props: {
    pageVars: {
      type: Object,
      default: null,
    },
  },
  emits: ['submit', 'close'],
  methods: {
    onAddPage(newPage) {
      log('adding child page!!!!', newPage);
      this.$emit('submit', newPage);
    },
  },
};
</script>
