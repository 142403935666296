<template>
  <div>
    <!-- INTRO -->
    <form class="kn-builder-form margin-bottom">
      <div v-if="usersAreOptional">
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="page.authenticated"
            type="radio"
            :value="false"
          >No, a login is not required</label>
          <label><input
            v-model="page.authenticated"
            type="radio"
            :value="true"
          >Yes, a user must login to access this page</label>
        </div>
      </div>

      <div v-if="showRoleAccess">
        <label v-if="usersAreOptional">
          Which logged-in users can access this page?
        </label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="limit_profile_access"
            type="radio"
            :value="false"
          >Give permission to all users</label>
          <label><input
            v-model="limit_profile_access"
            type="radio"
            :value="true"
          >Limit permissions to specific user roles</label>
        </div>

        <div
          v-if="limit_profile_access"
          id="quickstart-permissions"
          data-cy="quickstart-permissions-picker"
          class="input-labels"
          style="margin-top: .5em;"
        >
          <MultipleChoice
            v-model="page.raw.allowed_profiles"
            :input="{format: {type: `multi`, options: roleObjectOptions}}"
          />
        </div>
      </div>
    </form>

    <div class="flex justify-end">
      <a
        class="button orange-fill small  p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium"
        data-cy="continue"
        @click="submit()"
      >
        Continue
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultipleChoice from '@/components/renderer/form/inputs/MultipleChoice';

export default {
  components: {
    MultipleChoice,
  },
  props: {
    page: {
      type: Object,
      default: () => ({
        authenticated: false,
        allowed_profiles: [],
      }),
    },
    usersAreOptional: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'submit',
  ],
  data() {
    return {
      limit_profile_access: false,
    };
  },
  computed: {
    ...mapGetters([
      'roleObjects',
    ]),
    isUserPage() {
      return this.page.type === 'user';
    },
    showAuthenticationOptions() {
      return !this.isUserPage || !this.page.type === 'authentication';
    },
    showRoleAccess() {
      return (this.isUserPage || this.page.type === 'authentication' || this.page.authenticated);
    },
    roleObjectOptions() {
      return this.roleObjects.map((roleObject) => ({
        label: roleObject.name,
        value: roleObject.get('profile_key'),
      }));
    },
  },
  watch: {
    'page.allowed_profiles': function (newVar) {
      // we need to also set this temporary page var
      this.page.authentication_profiles = newVar;
    },
  },
  created() {
    if (this.page.allowed_profiles && this.page.allowed_profiles.length > 0) {
      this.limit_profile_access = true;
    }
  },
  methods: {
    submit() {
      log('add users save');

      return this.$emit('submit');
    },
  },
};
</script>

<style lang="scss">
</style>
