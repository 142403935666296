<template>
  <WizardStep
    :wizard-step="wizardStep"
    :step="step"
    @update:wizardStep="$emit('update:wizardStep', step)"
  >
    <template #edit>
      <div class="mb-4 text-base font-semibold tracking-[.32px]">
        <span v-html="editText" />
      </div>
    </template>
    <template #intro>
      <div class="mb-2">
        {{ introText }}
      </div>
    </template>
    <div>
      <div v-if="canSavePayment">
        <div v-if="!isCustomerView">
           <div class="tw-input-labels">
            <label class="radioLabel">
              <input
                v-model="savesPayment"
                type="radio"
                :value="'no'"
              > <span><strong>No</strong>, a new payment method will need to be added for every charge.</span>
            </label>
          </div>
          <div class="input-labels tw-input-labels">
            <label class="radioLabel">
              <input
                v-model="savesPayment"
                type="radio"
                :value="'yes'"
              > <span><strong>Yes</strong>, the payment method will be saved. Previously saved payment methods can be used for this charge.</span>
            </label>
          </div>
          <div class="input-labels tw-input-labels">
            <label class="radioLabel">
              <input
                v-model="savesPayment"
                type="radio"
                :value="'optional'"
              > <span><strong>Optional</strong>, the user has the option to save their payment method.</span>
            </label>
          </div>
        </div>
        <div
          v-if="willSavePayment"
          class="margin-top-md mt-0"
        >
          <p v-if="!isCustomerView">
            The payment method will be saved for which user?
          </p>
          <div
            v-for="field in customerFields"
            :key="field.key"
            class="input-labels tw-input-labels"
          >
            <label><input
              v-model="chargeCustomer"
              type="radio"
              :value="field.key"
            > {{ field.display_name }}</label>
          </div>
        </div>
      </div>
      <div v-else>
        <p>
          The user's payment information will <strong>not</strong> be saved for future charges.
          <HelpIcon text="Saving a user’s payment method is not available with <strong>PayPal</strong>. A <strong>Stripe</strong> processor is required to allow users to enter credit card information that can be saved for future purchases." />
        </p>
      </div>
    </div>
    <div class="margin-top mt-4 flex justify-end">
      <button
        type="button"
        class="button orange-fill medium text-white px-3 py-2 bg-gradient-primary rounded-lg border-0 text-base font-medium h-10"
        @click="onNext"
      >
        Next
      </button>
    </div>
  </WizardStep>
</template>

<script>
import { mapGetters } from 'vuex';
import WizardStep from '@/components/ui/WizardSection';
import PaymentUtils from '@/components/settings/ecommerce/PaymentUtils';
import HelpIcon from '@/components/ui/HelpIcon';

export default {
  components: {
    HelpIcon,
    WizardStep,
  },
  mixins: [
    PaymentUtils,
  ],
  props: {
    page: {
      type: Object,
      required: true,
    },
    view: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    wizardStep: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:wizardStep', 'next'],
  data() {
    return {

      savesPayment: 'no',
      chargeCustomer: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'getField',
      'getObjectByRole',
    ]),
    editText() {
      if (this.savesPayment === 'no' && !this.isCustomerView) {
        return '<strong>Not</strong> saving payment method';
      }

      const selectedField = this.customerFields.find((field) => field.logged_in_user || field.profile_key === this.chargeCustomer);

      if (selectedField) {
        return `Payment <strong>will</strong> be saved on <strong>${selectedField.display_name}</strong>`;
      }

      return 'N/A';
    },
    introText() {
      if (this.isCustomerView) {
        return 'The payment method will be saved for which user?';
      }

      return 'Save the payment method for future use?';
    },
    mainAccountObject() {
      return this.getObjectByRole('all_users');
    },
    customerFields() {
      const trackedDisplayNames = [];
      const sourceObject = (this.view.source && !this.view.source.object) ? this.mainAccountObject : this.getObject(this.view.source.object);
      const possibleCustomerFields = [];

      const checkConnectedObject = (field, connectedObject) => {
        let customerField;

        // toss out the charge object as an option
        if (connectedObject.get('ecommerce')) {
          return;
        }

        for (const connectedField of connectedObject.fields) {
          if (connectedField.type !== 'connection') {
            continue;
          }

          if (connectedField.get('relationship').has !== 'one') {
            continue;
          }

          const furtherConnectedObject = this.getObject(connectedField.get('relationship').object);

          log('found further connected object');
          log(furtherConnectedObject);

          if (!furtherConnectedObject || !furtherConnectedObject.get('profile_key')) {
            continue;
          }

          // the customer connected to the company connected to this page's invoice
          const displayName = `The ${furtherConnectedObject.get('inflections').singular} connected to this page's ${sourceObject.get('inflections').singular} through the ${sourceObject.get('inflections').singular}'s ${connectedObject.get('inflections').singular}`;

          if (trackedDisplayNames.includes(displayName)) {
            continue;
          }

          trackedDisplayNames.push(displayName);

          // need to attach more to this for further connections
          connectedField.display_name = displayName;
          connectedField.first_connection = field.key;

          possibleCustomerFields.push(connectedField);
        }

        if (!customerField && connectedObject.profile_key) {
          const displayName = `The ${connectedObject.get('inflections').singular} connected to this page's ${sourceObject.get('inflections').singular}`;

          if (trackedDisplayNames.includes(displayName)) {
            return;
          }

          trackedDisplayNames.push(displayName);

          // need to attach more to this for further connections
          field.display_name = displayName;

          possibleCustomerFields.push(field);
        }
      };

      // loop over the source object, looking for connections to a user, so we can see if the user has a customer ID
      for (const field of sourceObject.fields) {
        if (field.type !== 'connection') {
          continue;
        }

        if (field.get('relationship').has !== 'one') {
          continue;
        }

        const connectedObject = this.getObject(field.get('relationship').object);

        checkConnectedObject(field, connectedObject);
      }

      if (sourceObject.profile_key) {
        const displayName = `This page's ${this.getObject(sourceObject.key).get('inflections').singular}`;

        possibleCustomerFields.push({
          key: sourceObject.profile_key,
          profile_key: sourceObject.profile_key,
          display_name: displayName,
        });
      }

      if (this.page.authenticated) {
        possibleCustomerFields.push({
          display_name: 'The logged-in user',
          logged_in_user: true,
        });
      }

      return possibleCustomerFields;
    },
    canSavePayment() {
      return (this.page.authenticated || this.isCustomerView) && !this.hasPayPalPaymentMethod;
    },
    isCustomerView() {
      return this.view.type === 'customer';
    },
    willSavePayment() {
      return this.savesPayment !== 'no' || this.isCustomerView;
    },
  },
  created() {
    if (this.view.type !== 'checkout') {
      this.chargeCustomer = this.view.chargeCustomer === '' ? undefined : this.view.chargeCustomer;
      this.savesPayment = this.view.customerOptional ? 'optional' : 'yes';
    } else {
      this.chargeCustomer = this.customerFields.length > 0 ? this.customerFields[0].key : undefined;
      this.savesPayment = this.view.type === 'customer' ? 'yes' : 'no';
    }
  },
  methods: {
    onNext() {
      if (!this.isCustomerView) {
        this.view.type = (this.savesPayment !== 'no') ? 'charge' : 'checkout';
      }

      this.view.customerOptional = this.willSavePayment && this.savesPayment === 'optional';

      // Set defaults and change in if/else trees
      this.view.chargeCustomer = undefined;
      this.view.loggedInUser = false;
      this.view.firstConnection = false;

      if (this.willSavePayment) {
        // logged-in user
        if (this.chargeCustomer === '' || _.isNil(this.chargeCustomer)) {
          this.view.chargeCustomer = '';
          this.view.loggedInUser = true;

          if (this.view.type === 'form' || this.isCustomerView) {
            const accountObject = this.mainAccountObject;

            this.view.source = {
              authenticated_user: true,
              object: accountObject.key,
            };

            this.view.attributes.checkout_page.source = this.view.source;
          }
        } else if (this.chargeCustomer === 'all_users' || this.chargeCustomer.includes('profile_')) {
          if (this.view.type === 'form' || this.isCustomerView) {
            const accountObject = this.getObjectByRole(this.chargeCustomer);

            this.view.source = {
              authenticated_user: true,
              object: accountObject.key,
            };

            this.view.attributes.checkout_page.source = this.view.source;
          }

          this.view.chargeCustomer = '';
        } else { // any other selection
          const selectedField = this.customerFields.find((field) => field.key && field.key === this.chargeCustomer);

          this.view.chargeCustomer = this.chargeCustomer;

          if (selectedField && selectedField.first_connection) {
            this.view.firstConnection = selectedField.first_connection;
          }
        }
      }

      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
.input-labels label.radioLabel {
  font-weight: normal;
}
</style>
