<template>
  <form>
    <p v-if="isDividerType">
      Dividers have no options to configure. They are happy to just be dividers ;)
    </p>

    <template v-if="hasField && field">
      <div class="mb-4">
        <label class="tw-toolbox-label">Field</label>
        <FieldName :field="field" />
      </div>

      <div
        v-if="showReadOnly"
        class="mb-4"
      >
        <label class="tw-toolbox-label">Read Only</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="input.read_only"
            type="checkbox"
          >Display the field value without a form input</label>
        </div>
      </div>

      <div
        v-if="showLabel"
        class="mb-4"
      >
        <label class="tw-toolbox-label">Label</label>
        <input
          v-model="input.label"
          type="text"
        >
      </div>
      <div class="mb-4">
        <label class="tw-toolbox-label">Instructions</label>
        <textarea v-model="input.instructions" />
      </div>

      <div
        v-if="isMultipleChoice && !isImmutable"
        class="mb-4"
      >
        <label class="tw-toolbox-label">Add Options</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="input.allow_option_inserts"
            type="checkbox"
          >Allow users to add new options to choose.</label>
        </div>
      </div>

      <template v-if="showPassword">
        <div class="mb-4">
          <label class="tw-toolbox-label">Password Required</label>
          <div class="input-labels tw-input-labels">
            <label><input
              v-model="input.action_authenticate"
              type="checkbox"
            >Require the user's current password to submit the form</label>
          </div>
        </div>

        <div
          v-if="input.action_authenticate"
          class="mb-4"
          style="padding-left: 20px; margin-top: -.5em;"
        >
          <label
            class="tw-toolbox-label"
            style="font-weight: 500;"
          >
            Current Password Label
          </label>
          <input v-model="passwordAuthLabel">
        </div>

        <div class="mb-4">
          <label class="tw-toolbox-label">Update the Password</label>
          <div class="input-labels tw-input-labels">
            <label><input
              v-model="input.action_update"
              type="checkbox"
            >Update the user's password</label>
          </div>
        </div>

        <div
          v-if="input.action_update"
          class="mg-4"
          style="padding-left: 20px; margin-top: -.5em;"
        >
          <label
            class="tw-toolbox-label"
            style="font-weight: 500;"
          >
            Update Password Label
          </label>
          <input v-model="passwordUpdateLabel">
        </div>
      </template>

      <template v-if="isConnection">
        <div v-if="allowConnectedRecordCreation">
          <label class="tw-toolbox-label">Create Records</label>
          <div class="input-labels tw-input-labels">
            <label><input
              v-model="input.allow_option_inserts"
              type="checkbox"
            >Allow users to create new records to choose.</label>
          </div>
        </div>
        <div class="mb-4">
          <label class="tw-toolbox-label">Filters</label>
          <p class="margin-bottom-half text-sm font-normal mb-3 text-default">
            Filter which records are available to choose.
          </p>
          <FieldList
            v-if="hasFilters"
            :items="connectionFilters"
            :object-key="connectionObjectKey"
            :can-be-empty="true"
            class="is-grouped hide-add"
            style="font-size: .9em;"
            @update:items="onUpdateConnectionFilters"
          />
          <a
            class="button tiny fuchsia-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base capitalize font-medium"
            @click="onAddFilter"
          >
            <Icon
              class="text-default group-hover:text-brand-400 h-4 w-4 mr-2 fill-current"
              type="plus-thin"
            />
            add filter
          </a>
        </div>
        <div
          v-if="hasConnectionParentOptions"
          class="mb-4"
        >
          <label class="tw-toolbox-label">Show</label>
          <select
            v-model="connectedParentOption"
            class="text-base py-2 pl-3 leading-5"
          >
            <option
              v-for="option in connectionParentOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </template>
    </template>

    <template v-if="isCopy">
      <div class="mb-4">
        <label class="tw-toolbox-label">Title</label>
        <input
          v-model="input.label"
          type="text"
        >
      </div>
      <div class="mb-0">
        <label class="tw-toolbox-label">Copy</label>
        <textarea v-model="input.copy" />
      </div>
    </template>
  </form>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import FieldList from '@/components/ui/lists/FieldList';
import FieldName from '@/components/fields/FieldName';
import ParentConnectionsMixin from '@/components/views/form/ParentConnectionsMixin';
import ViewUtils from '@/components/views/ViewUtils';
import Icon from '@/components/ui/Icon';

import { buildViewSchema } from '@/lib/schema-helper';

export default {
  name: 'SharedInputProperties',
  components: {
    FieldName,
    FieldList,
    Icon,
  },
  mixins: [
    ViewUtils,
    ParentConnectionsMixin,
  ],
  props: {
    input: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      restorableChildView: null,
    };
  },
  watch: {
    'input.allow_option_inserts': function (newVal) {
      // could be a multiple choice
      if (!this.isConnection) {
        return;
      }

      // if disabled, delete the child view
      if (!newVal) {
        if (this.input.view) {
          // save for later in case they toggle it back
          this.restorableChildView = this.input.view;

          delete this.input.view;
        }

        return;
      }

      // no need to create a child view one already existed
      if (this.restorableChildView) {
        this.input.view = this.restorableChildView;

        return;
      }

      // if we already have an existing child view, use that
      if (get(this, 'input.view.name')) {
        return;
      }

      if (!this.field) {
        return;
      }

      // this is a connection with inserts enabled that now needs a child form view created for inserting records to connect to
      const formObject = this.$store.getters.getObject(this.field.relationship.object);

      const view = {
        name: `Add ${formObject.inflections.singular}`,
        type: 'form',
        action: 'insert',
      };

      view.source = {
        object: formObject.key,
      };

      this.input.view = buildViewSchema(this.activePage.raw, view);
    },
  },
  computed: {
    ...mapGetters([
      'activePage',
    ]),
    field() {
      if (this.input.field) {
        const fieldKey = this.input.field.key;

        return this.getField(fieldKey);
      }

      return null;
    },
    allowConnectedRecordCreation() {
      // Allow registration forms
      if (this.view.source?.type === 'registration') {
        return true;
      }

      // Don't allow if this is already a child form that is creating a connected record
      if (this.view.isChildView()) {
        return false;
      }

      return true;
    },
    connectionObjectKey() {
      return this.field?.getConnectedObject().key;
    },
    hasField() {
      return this.input.field;
    },
    showLabel() {
      return this.hasField;
    },
    isDividerType() {
      return this.input.type === 'divider';
    },
    isCopy() {
      return (this.input.type === 'copy' || this.input.type === 'section_break');
    },
    isMultipleChoice() {
      return this.field?.type === 'multiple_choice';
    },
    isImmutable() {
      return this.field?.get('immutable') ?? false;
    },
    showPassword() {
      if (!this.isPassword) {
        return false;
      }

      if (!this.viewRaw.source.authenticated_user) {
        return false;
      }

      if (this.viewRaw.action !== 'update') {
        return false;
      }

      return true;
    },
    isPassword() {
      return this.field?.type === 'password';

      /*
      // password
      if (type == Knack.config.PASSWORD) {

        log('password input!'); log(input);

        // only show the password action on update forms for a logged-in user
        // this action enables authentication function
        var is_authenticated = this.options.source.authenticated_user;

        if (is_authenticated && this.options.action == 'update') {

          $form.find('.input-password').show();
          $form.find('#input-label, .input_read_only').hide();

          if (typeof input.action_update === 'undefined') {
            input.action_update = true;
            input.label_update = 'New Password';
            input.action_authenticate = false;
            input.label_authenticate = 'Current Password';
          }

          $form.find('input[type=checkbox]').change(function(event) {
            $(this).closest('.view-input').find('.pass-label').toggle($(this).is(':checked'));
          });

          $form.find('input[name=action_update]').prop('checked', input.action_update).change();
          $form.find('input[name=action_authenticate]').prop('checked', input.action_authenticate).change();
          $form.find('input[name=label_update]').val(input.label_update);
          $form.find('input[name=label_authenticate]').val(input.label_authenticate);

        } else {

          $form.find(':input[name=label]').val(input.label_update);
        }
      */
    },
    showReadOnly() {
      if (this.input.type === 'section_break' || this.isPassword) {
        return false;
      }

      if (this.viewRaw.action !== 'update') {
        return false;
      }

      return true;
    },
    isConnection() {
      return this.field?.type === 'connection';
    },
    hasFilters() {
      return this.connectionFilters.length > 0;
    },
    connectionFilters: {
      get() {
        if (this.input.source) {
          return this.input.source.filters || [];
        }

        return [];
      },
      set(newVal) {
        if (!this.input.source) {
          this.input.source = {
            filters: newVal,
          };
        }

        this.input.source.filters = newVal;
      },
    },
    hasConnectionParentOptions() {
      // see ParentConnectionsMixin
      return this.connectionParentOptions.length > 0;
    },
    passwordAuthLabel: {

      get() {
        return this.input.label_authenticate || 'Current Password';
      },

      set(newValue) {
        this.input.label_authenticate = newValue;
      },
    },
    passwordUpdateLabel: {

      get() {
        return this.input.label_update || 'New Password';
      },

      set(newValue) {
        this.input.label_update = newValue;
      },
    },
    connectedParentOption: {

      get() {
        const { input } = this;

        if (input.source) {
          if (input.source.remote_key) {
            return `${input.source.connection_key}-${input.source.remote_key}-${input.source.type}`;
          }

          if (input.source.connections && input.source.connections[0]) {
            return `${input.source.connections[0].field.key}-${input.source.connections[0].source.field.key}-input`;
          }
        }

        return '';
      },
      set(newValue) {
        const conn = newValue.split('-');
        const source = { ...this.input.source };

        if (conn[2] === 'input') {
          source.connections = [
            {
              field: {
                key: conn[0],
              },
              source: {
                type: 'input',
                field: {
                  key: conn[1],
                },
              },
            },
          ];
          delete source.type;
          delete source.remote_key;
          delete source.connection_key;
        } else {
          source.type = conn[2];
          source.connection_key = conn[0];
          source.remote_key = conn[1];

          delete source.connections;
        }

        this.input.source = source;
      },
    },
  },
  methods: {
    defaultFilter() {
      if (!this.field) {
        return {};
      }

      const field = this.field.getConnectedObject().fields[0];

      return {
        field: field.key, operator: field.getFirstRuleOperator(), value: field.getFilterDefault(),
      };
    },
    onAddFilter() {
      this.connectionFilters = [
        ...this.connectionFilters,
        this.defaultFilter(),
      ];
    },
    onUpdateConnectionFilters(newFilters) {
      this.connectionFilters = newFilters;
    },
  },
};
</script>
