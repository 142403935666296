import { isProduction } from '@/lib/env-helper';

export let log;

const query = new URLSearchParams(window.location.search);
// Allow logging when adding debug query param (used in CRM)
// but in this case could be used to enable logging in production
// as well (but not source maps).
if (!isProduction() || query.get('debug') === 'true') {
  log = function () {
    window.console.log(...arguments);
  };
} else {
  log = () => {};
}
