<template>
  <div :class="inputClass">
    <Select
      v-if="isSelect || !hasFormatInput"
      v-model="localValue"
      class="kn-multiSelect"
      :options="options"
      :input="input"
      :field="field"
    />
    <Radios
      v-else-if="isRadios"
      v-model="localValue"
      :name="field.key"
      :options="options"
    />
    <Checkboxes
      v-else-if="isCheckboxes"
      v-model="localValue"
      :input="input"
      :options="options"
      :field="field"
      :name="field.key"
      :checkbox-text="checkboxText"
      :hide-label="true"
      :true-label="trueLabel"
    />
  </div>
</template>

<script>
import hasIn from 'lodash/hasIn';
import Radios from '@/components/renderer/form/inputs/Radios';
import Checkboxes from '@/components/renderer/form/inputs/Checkboxes';
import Select from '@/components/renderer/form/inputs/Select';

export default {
  name: 'BooleanInput',
  components: {
    Radios,
    Checkboxes,
    Select,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [
        String,
        Boolean,
      ],
      required: true,
    },
    input: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      required: true,
    },
    useSelectInput: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    localValue: {
      get() {
        return [
          true,
          'Yes',
          'On',
          'True',
        ].includes(this.modelValue);
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    booleanFormat() {
      return this.field.format.format;
    },
    checkboxText() {
      return this.field.format.text;
    },
    trueLabel() {
      if (this.booleanFormat === 'on_off') {
        return 'On';
      }

      if (this.booleanFormat === 'true_false') {
        return 'True';
      }

      return 'Yes';
    },
    falseLabel() {
      if (this.booleanFormat === 'on_off') {
        return 'Off';
      }

      if (this.booleanFormat === 'true_false') {
        return 'False';
      }

      return 'No';
    },
    hasFormatInput() {
      return hasIn(this.field.format, 'input');
    },
    isRadios() {
      return (this.field.format.input === 'radios');
    },
    isCheckboxes() {
      return (this.field.format.input === 'checkbox');
    },
    isSelect() {
      return (this.useSelectInput || this.field.format.input === 'dropdown');
    },
    inputClass() {
      return {
        'kn-checkbox': this.isCheckboxes,
        'kn-radio': this.isRadios,
        'kn-select': !this.isCheckboxes && !this.isRadios,
      };
    },
    options() {
      if (this.isCheckboxes && !this.useSelectInput) {
        return [
          {
            label: '',
            value: 'True',
          },
        ];
      }

      return [
        {
          label: this.trueLabel, value: true,
        },
        {
          label: this.falseLabel, value: false,
        },
      ];
    },
  },
};
</script>
