<template>
  <div class="inputGroup">
    <RequiredSetting
      :field-local="fieldLocal"
    />

    <div
      v-if="connectedObject"
      id="connection-field-settings"
    >
      <div>
        <label class="text-default text-sm font-medium mb-2 leading-4">Relationship</label>
        <div class="connection-relationship">
          <p
            v-if="isNew"
            class="margin-bottom text-default"
          >
            This field connects <b>{{ object.inflections.plural }}</b> with
            <select
              v-model="fieldLocal.relationship.object"
              class="text-base py-2 pl-3 leading-5"
            >
              <option
                v-for="object in objectsByMenuOrder"
                :key="object.key"
                :value="object.key"
              >
                {{ object.inflections.plural }}
              </option>
            </select>
          </p>

          <label class="margin-bottom-half text-default">
            <div>
              Each <b>{{ object.inflections.singular }}</b> connects with&nbsp;
              <select
                v-model="fieldLocal.relationship.has"
                class="text-base py-2 pl-3 leading-5"
              >
                <option value="one">
                  one
                </option>
                <option value="many">
                  many
                </option>
              </select>&nbsp;&nbsp;<b>{{ fromName }}</b>
            </div>
          </label>

          <label class="text-default">
            <div>
              Each <b>{{ connectedObject.inflections.singular }}</b> connects with&nbsp;
              <select
                v-model="fieldLocal.relationship.belongs_to"
                class="text-base py-2 pl-3 leading-5"
              >
                <option value="one">
                  one
                </option>
                <option value="many">
                  many
                </option>
              </select>&nbsp;&nbsp;<b>{{ toName }}</b>
            </div>
          </label>
        </div>
      </div>
    </div>

    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Format</label>
      <select
        v-model="fieldLocal.format.input"
        class="text-base py-2 pl-3 leading-5"
      >
        <option value="chosen">
          Searchable Dropdown
        </option>
        <option
          v-if="fieldLocal.relationship.has === `many`"
          value="checkbox"
        >
          Checkboxes
        </option>
        <option
          v-if="fieldLocal.relationship.has === `one`"
          value="radio"
        >
          Radio Buttons
        </option>
      </select>
    </div>

    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Default Value</label>
      <select
        v-model="fieldLocal.format.conn_default"
        class="text-base py-2 pl-3 leading-5"
      >
        <option value="none">
          None
        </option>
        <option value="first">
          First Option
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';
import { mapGetters } from 'vuex';
import FieldUtils from '@/components/fields/FieldUtils';
import RequiredSetting from '@/components/fields/settings/shared/RequiredSetting';

export default {
  name: 'Connection',
  components: {
    RequiredSetting,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    fieldLocal: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters([
      'objects',
      'objectsByMenuOrder',
    ]),

    connectedObject() {
      return this.$store.getters.getObject(this.fieldLocal.relationship.object);
    },

    fromName() {
      const inflection = (this.fieldLocal.relationship.has === 'one') ? 'singular' : 'plural';

      return this.connectedObject.inflections[inflection];
    },

    toName() {
      const inflection = (this.fieldLocal.relationship.belongs_to === 'one') ? 'singular' : 'plural';

      return this.object.inflections[inflection];
    },
  },
  watch: {
    'fieldLocal.relationship.has': function (newVal, oldVal) {
      // Make sure the format matches what's available for the connection type
      if (newVal === 'one' && this.fieldLocal.format.input === 'checkbox') {
        this.fieldLocal.format.input = 'radio';
      }

      if (newVal === 'many' && this.fieldLocal.format.input === 'radio') {
        this.fieldLocal.format.input = 'checkbox';
      }
    },
  },
  created() {
    if (isNil(this.fieldLocal.format)) {
      this.fieldLocal.format = {};
    }

    if (!this.fieldLocal.format.input) {
      this.fieldLocal.format.input = 'chosen';
    }

    if (!this.fieldLocal.format.conn_default) {
      this.fieldLocal.format.conn_default = 'none';
    }
  },
};
</script>

<style lang="scss">
#connection-field-settings {
  width: 100%;
  max-width: 100%;

  &>div > div {
    max-width: 320px;
  }

  .connection-relationship {
    width: 100%;
    max-width: 100%;

    label {
      font-weight: 400;
    }

    select {
      width: auto;
    }
  }
}
</style>
