<template>
  <div>
    <span v-html="truncatedText" />
    <span v-if="shouldDisplayTruncate">
      &nbsp;
      <a
        href="#"
        class="underline"
        @click="toggleTruncation"
      >
        {{ truncationToggleText }}
      </a>
    </span>
  </div>
</template>
<script>
import { defaultCharacterLimit, isCharacterLimitValid } from '@/lib/schema-helper';

export default {
  props: {
    displayValue: {
      type: String,
      default: '',
    },
    viewItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      truncated: true,
    };
  },
  computed: {
    characterLimit() {
      // Use defaultCharacterLimit if value is invalid
      if (!isCharacterLimitValid(this.viewItem.character_limit)) {
        return defaultCharacterLimit;
      }

      return parseInt(this.viewItem.character_limit, 10);
    },
    shouldDisplayTruncate() {
      return this.viewItem.truncate === true && this.displayValue.length > this.characterLimit;
    },
    truncationToggleText() {
      return this.truncated ? 'view more' : 'view less';
    },
    truncatedText() {
      if (this.shouldDisplayTruncate && this.truncated) {
        return `${this.displayValue.substring(0, this.characterLimit).trim()}...`;
      }
      return this.displayValue;
    },
  },
  methods: {
    toggleTruncation(event) {
      event.preventDefault();

      this.truncated = !this.truncated;
    },
  },
};
</script>
