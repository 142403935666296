<script setup>
import { computed } from 'vue';
import ChoicePicker from '@/components/ui/inputs/ChoicePicker';
import Icon from '@/components/ui/Icon';
import Ribbon from '@/components/layout/Ribbon';
import useLiveAppDesignPreview from '@/composables/ui/useLiveAppDesignPreview';

const {
  previewSize, showNotificationPreview, setPreviewSize, setShowNotificationPreview,
} = useLiveAppDesignPreview();

const localPreviewSize = computed({
  get() {
    return previewSize.value;
  },
  set(value) {
    setPreviewSize(value);
  },
});

const localShowNotificationPreview = computed({
  get() {
    return showNotificationPreview.value;
  },
  set(value) {
    setShowNotificationPreview(value);
  },
});
</script>

<template>
  <Ribbon class="kn-design-ribbon">
    <div class="ribbon_mainRow border-none h-auto p-6">
      <h2 class="ribbonName p-0 mr-12">
        <Icon
          type="settings-live-app-design-outline"
          class="ribbonName_icon h-8 w-8 mr-2 text-subtle"
        />
        <span class="ribbonName_title text-emphasis text-xl font-medium">Live App Design</span>
      </h2>

      <div class="kn-design-ribbon__responsive-toggle mr-12">
        <ChoicePicker
          v-model="localPreviewSize"
          borderless
          choice-classes="p-2 rounded-lg"
          choice-active-classes="text-brand rounded-lg"
          icon-classes="h-6 w-6"
          :default-choice="previewSize"
          :choices="[
            { tooltip: 'Change the preview size to Desktop', value: 'desktop', icon: 'desktop' },
            { tooltip: 'Change the preview size to Tablet', value: 'tablet', icon: 'tablet' },
            { tooltip: 'Change the preview size to Mobile', value: 'mobile', icon: 'mobile' },
          ]"
        />
      </div>

      <div class="kn-design-ribbon__option">
        <label class="kn-design-ribbon__option-label text-emphasis text-sm font-medium mb-0">
          <input
            v-model="localShowNotificationPreview"
            type="checkbox"
            class="kn-design-ribbon__option-input"
          >Show Notification Preview<br>
        </label>
      </div>
    </div>
  </Ribbon>
</template>

<style lang="scss">
.kn-design-ribbon {
  font-size: 14px;

  .ribbon_mainRow {
    line-height: inherit;
  }

  &__responsive-toggle {
    display: inline-flex;
    align-items: center;
  }

  &__option-label {
    display: inline-flex;
    align-items: center;
  }
}
</style>
