<template>
  <div class="control kn-inputWrapper">
    <template v-if="isUrl">
      <input
        :id="input.id"
        v-model="localValue"
        class="input kn-input"
        :name="input.id"
        type="text"
      >
    </template>
    <template v-else>
      <input
        class="image"
        :name="field.key"
        type="hidden"
        :value="ids"
      >
      <div
        v-if="hasLocalValue"
        class="kn-asset-current"
        :data-asset-id="localValue.id"
      >
        <div
          v-if="hasThumbUrl"
          class="image--remove"
        >
          <a
            title="View original image"
            :href="localValue.url"
            target="_blank"
          >
            <figure class="image is-64x64">
              <img :src="localValue.thumb_url">
            </figure>
          </a>
          <a
            :data-asset-id="localValue.id"
            data-click="img-remove-v2"
          >
            {{ trans("remove") }}
          </a>
        </div>
        <div
          v-else-if="hasUrl"
          class="image--remove"
        >
          <img :src="localValue.url">
          <a
            :data-asset-id="localValue.id"
            data-click="img-remove-v2"
            @click="onClickRemove"
          >
            {{ trans("remove") }}
          </a>
        </div>
      </div>

      <div
        class="fileUpload"
        :style="fileUploadStyle"
        @click="previewImageNewTab"
      >
        <FileUpload
          ref="upload"
          :name="uploadName"
          label-idle="Drag & drop or click to upload"
          :allow-multiple="false"
          :allow-file-type-validation="true"
          :accepted-file-types="['image/*']"
          :files="localFiles"
          :required="isRequired"
          @updatefiles="updateLocalFile"
        />
      </div>
    </template>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import hasIn from 'lodash/hasIn';
import get from 'lodash/get';
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils';
import { getInitialLocalFilesArray, getKnackFormatFromFilepondObject, uploadFilepondFiles } from '@/util/AssetsHelper';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';

const FileUpload = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFilePoster);

export default {
  name: 'ImageInput',
  components: {
    FileUpload,
  },
  mixins: [
    TranslationUtils,
  ],
  inheritAttrs: false,
  props: {
    input: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      type: [
        Array, // filepond file array
        Object, // upload
        String, // url
      ],
      required: true,
    },
    shouldUploadOnAdd: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      localFiles: [],
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        log('image set localValue', newValue);

        this.$emit('update:modelValue', newValue);
      },
    },
    uploadName() {
      return `file-${this.field.key}`;
    },
    hasFiles() {
      return !isEmpty(this.localFiles);
    },
    apiUri() {
      return window.Knack.Api.api_uri;
    },
    isUrl() {
      return get(this, 'input.format.source') === 'url';
    },
    hasLocalValue() {
      return !isEmpty(this.localValue);
    },
    hasThumbUrl() {
      return !isNil(this.localValue.thumb_url) && !isEmpty(this.localValue.thumb_url);
    },
    hasUrl() {
      return !isNil(this.localValue.url) && !isEmpty(this.localValue.url);
    },
    ids() {
      return hasIn(this.localValue, 'id') && !isNil(this.localValue.id) ? this.localValue.id : (!isEmpty(this.localValue) ? this.localValue : '');
    },
    fileUploadStyle() {
      return isString(this.localValue) ? 'cursor: pointer;' : '';
    },
    isRequired() {
      return Boolean(this.field?.get?.('required'));
    },
  },
  created() {
    getInitialLocalFilesArray(this.modelValue).then((result) => {
      this.localFiles = result;
    });
  },
  methods: {
    previewImageNewTab() {
      // When localValue is a string (i.e., an image id), open it in a new tab for previewing
      if (isString(this.localValue) && this.$refs.upload.files[0]) {
        const link = this.$refs.upload.files[0].getMetadata().url;
        const preview = window.open(link, '_blank');

        preview.focus();
      }
    },
    async updateLocalFile(files) {
      const hasFiles = !isEmpty(files);
      // This method runs on initializing async files as well
      // so evaluate if we're moving from the filepond config (built in getInitialLocalFilesArray) to determine if we need to upload this asset
      const isInitialUpdate = !isEmpty(this.localFiles) && hasIn(this.localFiles[0], 'options');

      // This prevents the uploading from happening more than once because this method can fire multiple times after an image is selected.
      const isAlreadyUploading = !isEmpty(this.localFiles) && hasIn(this.localFiles[0], 'id');

      this.localFiles = files;

      if (this.shouldUploadOnAdd && hasFiles && !isInitialUpdate && !isAlreadyUploading) {
        const response = await uploadFilepondFiles(this.field.key, files);

        this.localValue = response[this.field.key];

        return;
      }

      this.localValue = getKnackFormatFromFilepondObject(this.input, files[0]);
    },
  },
};
</script>
