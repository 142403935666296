import TOC from '@/components/views/checkout/TOC';
import Settings from '@/components/views/checkout/Settings';
import Summary from '@/components/views/checkout/Summary';
import Details from '@/components/views/checkout/Details';
import ItemProperties from '@/components/views/checkout/ItemProperties';
import Emails from '@/components/views/checkout/EmailRules';
import EmailHistory from '@/components/views/EmailHistory';
import Rules from '@/components/views/checkout/Rules';
import SubmitRules from '@/components/views/checkout/SubmitRules';
import RecordRules from '@/components/views/checkout/RecordRules';
import ChangePaymentAction from '@/components/views/shared/ChangePaymentAction';

export default [
  {
    path: ':pageKey/views/:viewKey/checkout',
    components: {
      toolbox: TOC,
    },
  },
  {
    path: ':pageKey/views/:viewKey/checkout/settings',
    components: {
      toolbox: Settings,
    },
  },
  {
    path: ':pageKey/views/:viewKey/checkout/settings/change',
    components: {
      toolbox: Settings,
      modal: ChangePaymentAction,
    },
    props: {
      modal: {
        backLink: './',
      },
    },
  },
  {
    path: ':pageKey/views/:viewKey/checkout/summary',
    components: {
      toolbox: Summary,
    },
  },
  {
    path: ':pageKey/views/:viewKey/checkout/details',
    components: {
      toolbox: Details,
    },
  },
  {
    path: ':pageKey/views/:viewKey/checkout/details/layouts/:layoutIndex/groups/:groupIndex/columns/:columnIndex/items/:itemIndex',
    name: 'checkoutInputProperties',
    components: {
      toolbox: ItemProperties,
    },
  },
  {
    path: ':pageKey/views/:viewKey/checkout/emails',
    components: {
      toolbox: Emails,
    },
  },
  {
    path: ':pageKey/views/:viewKey/checkout/emails/history',
    components: {
      toolbox: Emails,
      modal: EmailHistory,
    },
    props: {
      modal: {
        backLink: './',
      },
    },
  },
  {
    path: ':pageKey/views/:viewKey/checkout/rules',
    components: {
      toolbox: Rules,
    },
  },
  {
    path: ':pageKey/views/:viewKey/checkout/rules/submit',
    components: {
      toolbox: SubmitRules,
    },
  },
  {
    path: ':pageKey/views/:viewKey/checkout/rules/record',
    components: {
      toolbox: RecordRules,
    },
  },
];
