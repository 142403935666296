<template>
  <view-toolbox
    back-title="Edit Calendar"
    title="Design"
  >
    <form v-if="hasActiveView">
      <div>
        <label>Title</label>
        <input
          v-model="viewRaw.title"
          type="text"
        >
      </div>
      <div>
        <label>Description</label>
        <textarea v-model="viewRaw.description" />
      </div>
      <div>
        <label>Label Format</label>
        <LabelFormat
          :model-value="viewRaw"
          @update-property:model-value="onUpdateViewProperty"
        />
      </div>
    </form>
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DesignUtils from '@/components/views/DesignUtils';
import LabelFormat from '@/components/views/shared/settings/LabelFormat';

export default {
  name: 'CalendarSettings',
  components: {
    ViewToolbox,
    LabelFormat,
  },
  mixins: [
    ViewUtils,
    DesignUtils,
  ],
};
</script>
