import SchemaUtils from '@/store/utils/SchemaUtils';
import { eventBus } from '@/store/bus';

/*
  Requirements for consumers:
  * this.routePrefix: the hash prefix to route different actions to
  * this.inputGroups: the array of groups to act on
*/
export default {
  mounted() {
    this.addFormItemEventListener();
  },
  updated() {
    this.addFormItemEventListener();
  },
  beforeUnmount() {
    eventBus.$off(`addViewItem.${this.view.key}.form`);
  },
  methods: {
    populateInputGroups(input) {
      // this view method ensures the schema is correct and adds the input
      this.view.addItemToInputsCollection(this.inputGroups, input);
    },
    addFormItemEventListener() {
      eventBus.$off(`addViewItem.${this.view.key}.form`);
      eventBus.$on(`addViewItem.${this.view.key}.form`, ({ item, event }) => {
        const input = { ...SchemaUtils.inputDefaults(item.type), ...item };

        // add to input collection
        this.view.addItemToInputsCollection(this.inputGroups, input);
      });
    },
    onDeleteInput({ groupIndex, columnIndex, itemIndex }) {
      this.inputGroups[groupIndex].columns[columnIndex].inputs.splice(itemIndex, 1);

      if (!this.inputGroups[groupIndex].columns[columnIndex].inputs.length) {
        this.inputGroups[groupIndex].columns.splice(columnIndex, 1);
      }

      if (!this.inputGroups[groupIndex].columns.length) {
        this.inputGroups.splice(groupIndex, 1);
      }

      return this.routeToInputs();
    },

    routeToInputs() {
      const prefix = this.routePrefixInputs || this.routePrefix;

      this.$router.push(`${prefix}`);
    },

    onDropInput({ event, newItem }) {
      const maxColumnCount = 3;

      const oldGroupIndex = Number(event.dataTransfer.getData('group'));
      const oldColumnIndex = Number(event.dataTransfer.getData('column'));
      const oldItemIndex = Number(event.dataTransfer.getData('item'));

      // where the item was dragged to
      const $dropTarget = event.target;
      const dropTargetType = $dropTarget.dataset.targetType || 'column-same'; // column-new, group-new

      const groupIndex = Number($dropTarget.getAttribute('data-group'));
      const columnIndex = Number($dropTarget.getAttribute('data-column'));
      let itemIndex = Number($dropTarget.getAttribute('data-item'));

      let oldItems;
      let item;

      // Do we have a new item?
      if (!newItem) {
        newItem = event.dataTransfer.getData('new');
      }

      // Validate that we can move into a new column
      if (!newItem && dropTargetType === 'column-new') {
        const columnCount = this.inputGroups[groupIndex].columns.length;

        // If moving between groups, then reject if max columns already exist
        if (oldGroupIndex !== groupIndex && columnCount === maxColumnCount) {
          return;
        }

        // If moving in the same group, and there are already max columns, we can allow if the old column input count will be zero (so reject if its greater than 1)
        const oldInputCount = this.inputGroups[oldGroupIndex].columns[oldColumnIndex].inputs.length;

        if (oldGroupIndex === groupIndex && columnCount === 3 && oldInputCount > 1) {
          return;
        }
      }

      // set the item to add (could be from an insert or drag)
      if (newItem) {
        // Validate that we can add a new column
        if (dropTargetType === 'column-new' && this.inputGroups[groupIndex] && this.inputGroups[groupIndex].columns.length === maxColumnCount) {
          return false;
        }

        // item maybe stringified from add menu
        item = (typeof newItem === 'string') ? JSON.parse(newItem) : newItem;

        // item was stringified from add menu
        item = { ...SchemaUtils.inputDefaults(), ...item };
      } else {
        item = this.inputGroups[oldGroupIndex].columns[oldColumnIndex].inputs.splice(oldItemIndex, 1)[0];

        // extract items from old column
        oldItems = this.inputGroups[oldGroupIndex].columns[oldColumnIndex].inputs.splice(0);
      }

      // set defaults on an emtpy form
      if (this.inputGroups.length === 0) {
        this.populateInputGroups(item);

        return this.finishInputsDragDrop(item);
      }

      // dragging down in the same column needs an item index adjustment
      if (!newItem && oldGroupIndex === groupIndex && oldColumnIndex === columnIndex && oldItemIndex < itemIndex) {
        itemIndex--;
      }

      log('groupIndex', groupIndex, 'columnIndex', columnIndex, 'itemIndex', itemIndex, 'oldGroupIndex', oldGroupIndex, 'oldColumnIndex', oldColumnIndex, 'oldItemIndex', oldItemIndex);

      // insert a new group
      if (dropTargetType === 'group-new') {
        // add old items back
        if (oldItems) {
          this.inputGroups[oldGroupIndex].columns[oldColumnIndex].inputs = oldItems;
        }

        // add new group
        this.inputGroups.splice(groupIndex, 0, {
          columns: [
            {
              inputs: [
                item,
              ],
            },
          ],
        });

        return this.finishInputsDragDrop(item);
      }

      // new column
      if (dropTargetType === 'column-new') {
        // add old items back
        if (oldItems) {
          this.inputGroups[oldGroupIndex].columns[oldColumnIndex].inputs = oldItems;
        }

        log('ADDING NEW COLUMN!', this.inputGroups[groupIndex].columns.length);

        // insert new column at beginning
        if (columnIndex === 0) {
          this.inputGroups[groupIndex].columns.unshift({
            inputs: [
              item,
            ],
          });

        // insert new column at end
        } else if (columnIndex + 1 > this.inputGroups[groupIndex].columns.length) {
          this.inputGroups[groupIndex].columns.push({
            inputs: [
              item,
            ],
          });

        // insert new middle column
        } else {
          this.inputGroups[groupIndex].columns.splice(1, 0, {
            inputs: [
              item,
            ],
          });
        }

        return this.finishInputsDragDrop(item);
      }

      // moving within the same column
      if (!newItem && oldGroupIndex === groupIndex && oldColumnIndex === columnIndex) {
        // extract items from column and insert active item
        if (oldItems) {
          oldItems.splice(itemIndex, 0, item);
          this.inputGroups[oldGroupIndex].columns[oldColumnIndex].inputs = oldItems;
        }

        return this.finishInputsDragDrop(item);
      }

      // existing column

      // extract items from new column and insert active item
      const newItems = this.inputGroups[groupIndex].columns[columnIndex].inputs.splice(0);

      newItems.splice(itemIndex, 0, item);

      // add old items back
      if (oldItems) {
        this.inputGroups[oldGroupIndex].columns[oldColumnIndex].inputs = oldItems;
      }

      this.inputGroups[groupIndex].columns[columnIndex].inputs = newItems;

      return this.finishInputsDragDrop(item);
    },

    finishInputsDragDrop(item) {
      // clear any empty groups and columns
      this.inputGroups = this.inputGroups.filter((group) => {
        group.columns = group.columns.filter((column) => column.inputs.length);

        return group.columns.length;
      });

      if (!item.isNew) {
        this.routeToInputs();
      }
    },
  },
};
