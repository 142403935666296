<template>
  <div
    class="billingAddressCheck"
    :class="{ 'is-hidden': hide }"
  >
    <Icon
      type="warning"
      class="billingAddressCheck_icon"
    />
    <span class="billingAddressCheck_message">
      <strong>Urgent Billing Reminder:</strong> Knack requires each account to have a billing address on file along with their billing payment information. <strong>To avoid account disruption, <a :href="billingUrl">please update your billing address as soon as possible here.</a></strong><br><br>
      Note: If you are not the account owner, please reach out to your account owner to resolve this issue. If you don’t know who your account owner is, feel free to reach out to <a href="mailto:support@knack.com">support@knack.com</a>.
    </span>
    <button
      type="button"
      class="billingAddressCheck_close"
      @click="close"
    >
      <Icon
        type="close"
        class="close_icon"
      />
    </button>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  name: 'BillingAddressCheck',
  components: {
    Icon,
  },
  data() {
    return {
      hide: false,
    };
  },
  computed: {
    billingUrl() {
      return `${process.env.VUE_APP_DASHBOARD_URL}/settings/billing`;
    },
  },
  methods: {
    close() {
      this.hide = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.billingAddressCheck {
  position: absolute;
  bottom: 2.5rem;

  width: calc(50% + 32px);
  transform: translate(calc(50% - 64px));

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: $spacing-xs $spacing-sm;

  background: $error100;

  border: 1px solid $error500;
  box-sizing: border-box;
  border-radius: 4px;

  z-index: 1060;

  &.is-hidden {
    display: none !important;
  }
}

.billingAddressCheck_icon {
  height: 1.125em;
  width: 1.125em;
  color: $error500;
  margin-top: 0.125rem;
  flex-shrink: 0;
  align-self: start;
}

.billingAddressCheck_message {
  margin-left: $spacing-xs;
}

.billingAddressCheck_close {
  align-self: start;
  align-items: center;
  appearance: none;
  display: flex;
  justify-content: center;

  height: 20px;
  padding: 0;
  width: 20px;

  margin-top: 0.125rem;
  margin-left: 0.5em;

  border: none;
  border-radius: 3px;
  background: transparent;
  color: $gray300;

  transition: background-color .3s ease-out;

  &:hover,
  &:focus {
    background-color: rgba(142, 146, 147, 0.25);

    .close_icon {
      color: $gray900;
    }
  }
}
</style>
