<template>
  <div class="kn-options">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="control kn-inputWrapper [&:not(:last-child)]:mb-2 last:mb-0"
    >
      <label class="option radio items-baseline">
        <input
          v-if="option.value !== 'kn-blank'"
          v-model="localValue"
          :data-cy="'kn-options-' + option.value"
          type="radio"
          :name="`${uniqueName}`"
          :value="option.value"
          class="radio m-0 mr-2"
        >
        <span class="text-emphasis text-base font-normal" :class="textClasses">{{ stripHTML(option.label) }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';

export default {
  name: 'Radios',
  props: [
    'name',
    'options',
    'modelValue',
    'defaultValue',
    'textClasses',
  ],
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        let localValue = this.modelValue;

        if (!isNil(this.defaultValue) && isNil(this.modelValue)) {
          localValue = this.defaultValue;
        }

        // connections will send in arrays of value strings
        if (isArray(localValue)) {
          return localValue[0];
        }

        return localValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    uniqueName() {
      // groups of radios require a unique name
      // otherwise there will be name collisions
      // within lists Eg. conditional rules
      return uniqueId(`${this.name}_radios_`);
    },
  },
  methods: {
    dequote(string) {
      if (!isString(string)) {
        return string;
      }

      return String(string).replace(/"/g, '&quot;');
    },
    stripHTML(string) {
      const elem = document.createElement('div');
      elem.innerHTML = string;

      return get(elem, 'textContent', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.option {
  display: flex;
  align-items: center;
}

label.option.radio {
  font-weight: 400;
  font-style: normal;
}
</style>
