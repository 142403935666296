<template>
  <Ribbon
    v-if="objectKey"
    id="schema-ribbon"
  >
    <div
      v-if="tabStyle === 'legacy'"
      class="ribbon_mainRow h-min py-3"
    >
      <h2 class="ribbonName mr-12">
        <Icon
          :type="objectIcon"
          class="ribbonName_icon text-default h-6 w-6"
        />
        <RouterLink
          :to="``"
          class="ribbonName_link truncate text-emphasis text-xl"
          v-html="object?.name"
        />
        <ObjectEditDropdown
          base-url="/schema/list/objects"
          :object="object"
        />
      </h2>

      <ObjectTypeLinks
        #content
        :object="object"
        active-type="fields"
      />
    </div>
    <ObjectTypeTabs
      v-if="tabStyle === 'connections-split'"
      :object="object"
      active-tab="fields"
    />
    <div
      class="ribbon_subRow border-0 gap-2 py-3 h-auto"
    >
      <RouterLink
        v-if="objectKey"
        v-tippy
        data-feature="add_field_button"
        data-feature-x-offset="-26"
        data-feature-y-offset="-26"
        content="Add a new field"
        :to="`/schema/list/objects/${objectKey}/fields/add`"
        class="iconLink ribbonLink ribbonLink-persist p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group"
        style="margin-left: -8px;"
        data-cy="add-field"
      >
        <Icon
          type="plus-thin"
          class="iconLink_icon iconLink_icon-add ribbonLink_icon text-default mr-0 group-hover:text-brand-400 h-4 w-4"
        />
        <RibbonLinkText
          text="Add Field"
          class="text-base font-medium"
        />
      </RouterLink>
      <FilterInput
        :key="objectKey"
        ref="filterInput"
        class="filter-input"
        input-classes="mr-0 placeholder:text-muted"
        icon-classes="text-muted"
        :placeholder="filterInputPlaceholderText"
        @filter-input="handleFilterInput"
        @clear="clearSchemaFieldFilters"
      />
      <IconButton
        v-tippy
        class="-ml-2 hover:bg-subtle"
        icon-classes="text-default"
        :content="filterFieldsIconTooltipText"
        :icon-type-primary="filterFieldsIconType"
        :icon-type-secondary="filterFieldsIconTypeOnHover"
        @click="toggleFieldKeyFiltering"
      />
    </div>
  </Ribbon>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import Ribbon from '@/components/layout/Ribbon';
import Icon from '@/components/ui/Icon';
import IconButton from '@/components/ui/IconButton';
import ObjectTypeLinks from '@/components/objects/ObjectTypeLinks';
import ObjectEditDropdown from '@/components/objects/ObjectEditDropdown';
import FilterInput from '@/components/ui/inputs/FilterInput';
import FieldUtils from '@/components/fields/FieldUtils';
import RibbonDivider from '@/components/layout/RibbonDivider';
import RibbonLinkText from '@/components/layout/RibbonLinkText';
import ObjectTypeTabs from '@/components/objects/ObjectTypeTabs.vue';
import { AB_TESTING_OBJECT_TABS } from '@/constants/featureflags';

export default {
  components: {
    ObjectTypeTabs,
    Ribbon,
    Icon,
    IconButton,
    ObjectTypeLinks,
    ObjectEditDropdown,
    FilterInput,
    RibbonDivider,
    RibbonLinkText,
  },
  mixins: [
    FieldUtils,
  ],
  props: {
    layout: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      filterText: '',
    };
  },
  computed: {
    ...mapGetters([
      'getObject',
      'checkedRecordIds',
      'getFeatureValue',
    ]),
    ...mapGetters('ui/filterSchemaFields', {
      fieldKeyFilteringEnabled: 'getFilteringByKeyEnabled',
    }),
    app() {
      return this.$store.state.app;
    },
    addConnectionLink() {
      if (this.$route.path.indexOf('add') > -1) {
        return `/schema/list/objects/${this.object.key}/fields/add/connection`;
      }

      return `/schema/list/objects/${this.object.key}/connections/add`;
    },
    isAccountObject() {
      return this.object.profile_key === 'all_users';
    },
    objectKey() {
      return this.$route.params.objectKey;
    },
    activeObjectName() {
      const activeObject = this.getObject(this.objectKey);

      return !_.isNil(activeObject) ? activeObject.name : '';
    },
    showCheckboxActions() {
      return !_.isEmpty(this.checkedRecordIds);
    },
    exportUrl() {
      const queryVars = _.map(this.$route.query, (value, key) => `&${key}=${encodeURIComponent(value)}`).join('');

      if (_.isNil(queryVars)) {
        return `${window.Knack.Api.api_uri}objects/${this.objectKey}/records/export/applications/${this.app.attributes.id}?type=`;
      }

      return `${window.Knack.Api.api_uri}objects/${this.objectKey}/records/export/applications/${this.app.attributes.id}?${queryVars}&type=`;
    },
    filterFieldsIconType() {
      return this.fieldKeyFilteringEnabled ? 'id-alt' : 'id-alt-off';
    },
    filterFieldsIconTypeOnHover() {
      return this.filterFieldsIconType === 'id-alt' ? 'id-alt-off' : 'id-alt';
    },
    filterFieldsIconTooltipText() {
      const showOrHide = this.fieldKeyFilteringEnabled ? 'Hide' : 'Show';

      return `${showOrHide} field keys`;
    },
    filterInputPlaceholderText() {
      const appended = this.fieldKeyFilteringEnabled ? ' and key' : '';

      return `Filter fields by name${appended}`;
    },
    objectIcon() {
      return this.object?.get('integration')?.toLowerCase() ?? 'table-cells';
    },
    tabStyle() {
      return this.getFeatureValue(AB_TESTING_OBJECT_TABS);
    },
  },
  unmounted() {
    this.clearSchemaFieldFilters();
  },
  methods: {
    ...mapActions('ui/filterSchemaFields', [
      'applySchemaFieldFilters',
      'clearSchemaFieldFilters',
    ]),
    ...mapMutations('ui/filterSchemaFields', [
      'setSchemaFieldFilterByProperties',
    ]),
    handleFilterInput(input) {
      this.filterText = input;

      this.applySchemaFieldFilters({
        filterText: this.filterText,
      });
    },
    toggleFieldKeyFiltering() {
      const filterBy = ['name'];
      if (!this.fieldKeyFilteringEnabled) {
        filterBy.push('key');
      }
      this.setSchemaFieldFilterByProperties(filterBy);

      // clear filter input text
      if (this.$refs.filterInput) {
        this.$refs.filterInput.reset();
      }
      this.filterText = '';
      this.applySchemaFieldFilters({
        filterText: this.filterText,
      });
    },
  },
};
</script>

<style lang="scss">
#schema-ribbon {
}
</style>

<style lang="scss" scoped>
.filter-input {
  width: 240px;
  margin-right: 16px;
}
</style>
