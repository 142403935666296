<template>
  <div class="themeWarning">
    <div class="themeWarning__copy">
      This section contains settings only available on a <strong>newer theme</strong>.
    </div>
    <div
      class="themeAction"
      @click.prevent="$router.push(`/settings/design/theme`)"
    >
      <div class="themeAction__title text-default">
        Go to Theme Settings <Icon type="arrow-go" />
      </div>
      <div class="themeAction__copy text-default text-xs">
        Update your theme to access the latest customization options.
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
};
</script>

<style lang="scss">

  .themeWarning__copy {
    margin-bottom: 12px;
    font-size: 14px;
  }

  .themeAction {
    padding: 8px;
    border-radius: 4px;
    background-color: $gray50;
    border: 1px solid $gray100;
    margin-bottom: 4px;
    cursor: pointer;
  }

  .notification .themeAction {
    margin: 0;
  }

  .themeAction__title {
    margin-bottom: 4px;

    font-size: 13px;
    font-weight: 600;
    line-height: 140%;
    display: flex;
    align-items: center;

    color: $fuchsia;

    flex: none;
    order: 0;
    flex-grow: 0;

    svg {
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }

  .themeAction__copy {
    margin: 0;
    font-size: 13px;
    color: $gray500;
  }

  .themeWarning__wrapper {
    padding: 1em;
    background: #fff;
    border: 1px solid $gray200;
    border-radius: .25rem;
    margin-bottom: 1em;
  }
</style>
