<template>
  <div
    id="knack-login"
    class="fullscreen-bg"
  >
    <div
      id="knack-reset-pass"
      class="kn-view kn-form"
    >
      <div class="view-header">
        <p class="description">
          {{ trans('pass_reset_copy') }}
        </p>
      </div>
      <form @submit.prevent="submitForm">
        <ul class="kn-form-group clearfix kn-form-group-1">
          <li class="kn-form-col">
            <div
              id="kn-input-email"
              class="kn-input kn-input-email"
              data-input-id="email"
            >
              <label
                for="email"
                class="knack-input-label"
              >
                <span class="kn-input-label">
                  Email Address
                  <span class="kn-required">
                    *
                  </span>
                  <span
                    v-if="hasError('email')"
                    class="label__error"
                  >
                    {{ getError('email') }}
                  </span>
                </span>
              </label>
              <div class="input kn-input">
                <input
                  id="email"
                  v-model="email"
                  :class="{'input--error': hasError('email')}"
                  name="email"
                  type="email"
                  style="margin-bottom:9px;"
                >
                <input
                  name="key"
                  type="hidden"
                  value="email"
                >
              </div>
              <p
                class="kn-instructions"
                style="display: none;"
              />
            </div>
          </li>
        </ul>
        <div class="kn-submit">
          <input
            name="view_key"
            type="hidden"
            value=""
          >
          <input
            name="view_name"
            type="hidden"
            value=""
          >
          <input
            type="submit"
            value="Submit"
          >
          <div
            class="kn-spinner"
            style="display: none"
          />
        </div>
      </form>
      <p style="margin-top: 15px; margin-bottom: 0px;">
        <a href="/">
          back
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';
import RequestUtils from '@/components/util/RequestUtils';
import Api from '@/lib/api-wrapper';

export default {
  mixins: [
    TranslationUtils,
    RequestUtils,
  ],
  data() {
    return {
      email: '',
      password: '',
      errors: [],
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
  },
  methods: {
    getError(inputName) {
      return this.errors.find((error) => inputName in error)[inputName];
    },
    hasError(inputName) {
      if (this.errors.length === 0) {
        return false;
      }

      if (!this.errors.some((error) => inputName in error)) {
        return false;
      }

      return true;
    },
    submitForm() {
      const requestBody = {
        email: {
          email: this.email,
        },
      };

      this.commitRequest({
        request: () => Api.forgotPassword(requestBody),
        onSuccess: () => {
          this.$notify({
            group: 'bottom-right',
            type: 'success',
            duration: 5000,
            title: 'Success',
            text: `An email containing a link to reset your password has been sent to ${this.email}.`,
            data: {
              titleIcon: 'check',
            },
          });

          return this.$router.push('/login');
        },
      });

      // TODO: react to this.errors with handleErrors
      // return this.handleErrors()
    },
    handleErrors() {
      const error = {
        email: TranslationUtils.methods.trans('email_not_found'),
      };

      this.errors.push(error);

      log('errors');
      log(this.errors);
    },
  },
};
</script>

<style scoped>
.fullscreen-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #eee;
    overflow: hidden;
}

#knack-reset-pass {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 2rem;
    margin-top: 50px;
    border-radius: 4px;
    border: 1px solid #dadada;
}

.fullscreen-wrapper {
    max-width: 560px;
    margin: auto;
    padding: 30px;
}

#kn-logo {
  margin: auto;
  width: 80px;
  margin-bottom: 10px;
}

#kn-logo .icon-logo {
    font-size: 80px;
    color: #821b79;
}

.icon-logo:before {
    content: "j";
}

[class*=" icon-"]:before, [class^=icon-]:before, [data-icon]:before {
  font-family: knack-builder!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-variant: normal!important;
  text-transform: none!important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.register-form {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dadada;
    padding: 30px;
}

.register-form .kn-login-form {
    width: 100%;
}

.kn-password__options-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

#login-form input[type=submit] {
    width: 100%;
    border-radius: 2px;
    border-color:inherit;
}

.register-form input[type=submit], #knack-login input[type=submit], .register-form button {
    background: #f4820a none repeat scroll 0 0;
    color: #fff;
}

.register-form label.remember, #forgot-pass {
    display: inherit;
    font-weight: 400;
    font-size: .85rem;
    line-height: inherit;
    margin-bottom: inherit;
    margin:0;
}

.fullscreen-wrapper p.footer {
    text-align: center;
    font-size: .95em;
    margin-top: 30px;
    color: #999;
}

.kn-form-col {
  max-width: 300px;
}

.input--error {
  border: 1px solid #f77;
}

.label__error {
  font-size: 12px;
  color: #f22;
}
</style>
