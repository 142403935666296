<template>
  <div>
    <div class="impactTree__object">
      <Icon
        class="m-0 mr-1 h-4 w-4"
        type="table-cells"
      />
      <span class="impactTree__objectLabel text-base">
        {{ impactTree.name }}
      </span>
      <span
        v-if="impactTree.connection"
        class="impactTree__connLabel"
        v-html="getConnectionLabel(impactTree.connection)"
      />
    </div>
    <div class="impactFields">
      <div
        v-for="field in impactTree.fields"
        :key="field.key"
        class="impactField inline-flex items-center"
      >
        <FieldName
          v-tippy
          content="Edit this field"
          :field="field"
          style="white-space: nowrap;"
          class="bg-subtle p-2 rounded text-default mr-2"
          @click="linkToField(impactTree.key, field.key)"
        />
        <span
          class="impactField__reasons text-base"
          v-html="`${field.reasons.join(', ')}`"
        />
      </div>
    </div>
    <div class="impactConnections">
      <div
        v-for="connectionTree in impactTree.connections"
        :key="connectionTree.connectionKey"
      >
        <FieldPerformanceTree
          :impact-tree="connectionTree"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import FieldName from '@/components/fields/FieldName';

export default {
  name: 'FieldPerformanceTree',
  components: {
    FieldName,
    Icon,
  },
  props: {
    impactTree: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getConnectionLabel(connection) {
      let objectName = this.impactTree.parent.name;

      if (connection.relationship_type === 'foreign') {
        objectName = this.impactTree.name;
      }

      return `(connected via ${objectName} > ${connection.name})`;
    },
    linkToField(objectKey, fieldKey) {
      this.$router.push(`/schema/list/objects/${objectKey}/fields/${fieldKey}/settings`);
    },
  },
};
</script>

<style lang="scss" scoped>
.impactTree__object {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 12px;
}
.impactTree__objectLabel {
  font-size: .95rem;
  font-weight: 500;
}
.impactTree__connLabel {
  font-size: .875rem;
  color: $darkerGray;
  margin-left: 8px;
}
.impactFields {
  margin-left: 8px;
  border-left: 1px solid $lightGray;
  padding-left: 12px;
}
.impactField {
  font-size: .9rem;
  display: flex;
  align-items: flex-start;
  padding: 8px 0;

  .fieldName {
    display: flex;
    align-items: center;
    background: #ebeced;
    padding: 0 4px;
    border-radius: 2px;
    cursor: pointer;
  }
}
.icon-object {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}
.field-icon {
  align-self: center;
}
.impactField__reasons {
  font-size: .9rem;
  color: $darkestGray;
  margin-left: 4px;
}
.impactConnections {
  margin-left: 8px;
  border-left: 1px solid none;
  padding-left: 12px;
}
</style>
