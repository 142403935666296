import { h } from 'vue';

const SignatureRender = (props) => h(
  'div', {
    innerHTML: props.displayValue,
  },
);

SignatureRender.props = {
  displayValue: {
    type: null,
    required: true,
  },
};

export default SignatureRender;
