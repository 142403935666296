<template>
  <div>
    <form
      class="kn-builder-form light-labels"
      @submit.prevent="submit"
    >
      <div v-if="objects.length > 0">
        <div class="input-labels tw-input-labels">
          <label class="text-sm font-medium mb-2 leading-4 text-default">
            <input
              v-model="isNewObjectLocal"
              type="radio"
              :value="true"
              data-cy="object-copy-new-object"
            >
            New Table
          </label>
          <label class="text-sm font-medium mb-2 leading-4 text-default">
            <input
              v-model="isNewObjectLocal"
              type="radio"
              :value="false"
              data-cy="object-copy-existing-object"
            >
            Existing Table
          </label>
        </div>
      </div>
      <div v-if="isNewObjectLocal">
        <label class="mb-2 text-sm leading-4 font-medium text-default">New Table Name</label>
        <input
          v-model="newObjectNameLocal"
          type="text"
          data-cy="object-copy-new-object-name"
        >
      </div>
      <div v-else>
        <label class="mb-2 text-sm leading-4 font-medium text-default">Select a Table to Copy Fields to</label>
        <select
          v-model="existingObjectKeyLocal"
          data-cy="object-copy-existing-object"
        >
          <option
            v-for="obj in objects"
            :key="obj.name"
            :value="obj.key"
          >
            {{ obj.name }}
          </option>
        </select>
      </div>
    </form>
    <div class="submit-buttons flex justify-end">
      <button
        class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium m-0"
        data-cy="object-copy-continue"
        @click="submit"
      >
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FieldUtils from '@/components/fields/FieldUtils';

export default {
  mixins: [
    FieldUtils,
  ],
  props: {
    isNewObject: {
      type: Boolean,
      default: true,
    },
    newObjectName: {
      type: String,
      default: '',
    },
    existingObjectKey: {
      type: String,
      default: '',
    },
  },
  emits: [
    'submit',
    'update:isNewObject',
  ],
  data() {
    return {
      newObjectNameLocal: this.newObjectName,
      existingObjectKeyLocal: this.existingObjectKey,
    };
  },
  computed: {
    ...mapGetters([
      'objects',
    ]),
    isNewObjectLocal: {
      get() {
        return this.isNewObject;
      },
      set(newValue) {
        this.$emit('update:isNewObject', newValue);
      },
    },
  },

  methods: {
    submit() {
      return this.$emit('submit', {
        isNewObject: this.isNewObjectLocal,
        newObjectName: this.newObjectNameLocal,
        existingObjectKey: this.existingObjectKeyLocal,
      });
    },
  },
};
</script>
