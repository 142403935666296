<template>
  <view-toolbox
    back-title="Column"
    title="Column Properties"
  >
    <ColumnProperties :errors="errors" />
  </view-toolbox>
</template>

<script>
import ViewToolbox from '@/components/views/ViewToolbox';
import ColumnProperties from '@/components/views/shared/ColumnProperties';

export default {
  name: 'TableColumnProperties',
  components: {
    ViewToolbox,
    ColumnProperties,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
