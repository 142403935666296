export default {
  methods: {
    slideToggle(element, isOpen, options) {
      const defaults = {
        visibility: 'auto',
      };

      options = { ...defaults, ...options };

      let height = 0;

      Array.from(element.children).forEach((child) => {
        height += Number(child.getBoundingClientRect().height);
      });

      height = `${height}px`;

      const transitionEnd = function (event) {
        if (event.propertyName !== 'max-height') {
          return;
        }

        element.style.maxHeight = '';
        element.style.overflow = options.visibility;

        return element.removeEventListener('transitionend', transitionEnd);
      };

      if (isOpen) {
        element.style.overflow = 'hidden';
        element.addEventListener('transitionend', transitionEnd, false);
        element.style.maxHeight = height;

        return;
      }

      element.style.maxHeight = height;

      return this.nextAnimationFrame(() => {
        element.style.maxHeight = '0px';
        element.style.overflow = 'hidden';
      });
    },

    nextAnimationFrame(callback) {
      return window.requestAnimationFrame(() => window.requestAnimationFrame(callback));
    },
  },
};
