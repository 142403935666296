<template>
  <div :class="viewClass">
    <DetailsView
      should-show-utility-dropdown
      class="group has-util-dropdown"
      :class="viewClass"
      :data="data"
      :is-editable="isEditable"
      :schema="detailsSchema"
      :view="view"
      :route-prefix="routePrefixDetails"
      @delete="onDeleteDetail"
      @drop="onDropDetail"
    />
  </div>
</template>

<script>
import DetailsView from '@/components/renderer/shared/Details';
import RenderUtils from '@/components/renderer/RenderUtils.js';
import DetailActionsMixin from '@/components/renderer/mixins/DetailActionsMixin.js';

export default {
  components: {
    DetailsView,
  },
  mixins: [
    RenderUtils,
    DetailActionsMixin,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    routePrefixDetails() {
      return `${this.routePrefix}/items`;
    },
    viewClass() {
      const classes = {};

      return classes;
    },
    detailsSchema: {
      get() {
        return this.view.getDetailsSchema();
      },
      set(newVal) {
        this.view.setDetailsSchema(newVal);
      },
    },
    data() {
      return this.view.data.records[0];
    },
  },
};
</script>

<style lang="scss">
.kn-details {
  min-width: 420px;
  width: max-content;
}

.is-full-width .kn-details {
  width: 100%;
}
</style>
