<template>
  <ViewToolbox
    back-title="Edit Menu"
    title="Design"
  >
    <div>
      <label
        for="title"
        class="text-default text-sm font-medium mb-2 leading-4"
      >
        Title
      </label>
      <input
        v-model="viewRaw.title"
        type="text"
        name="title"
      >
    </div>
    <div>
      <label
        for="title"
        class="text-default text-sm font-medium mb-2 leading-4"
      >
        Description
      </label>
      <textarea
        v-model="viewRaw.description"
        name="description"
      />
    </div>

    <div>
      <label class="text-default text-sm font-medium mb-2 leading-4">Tabbed Menu</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="viewRaw.format"
          type="checkbox"
          true-value="tabs"
        > Simulate tabs by showing the menu on any linked child pages</label>
      </div>
    </div>

    <div v-if="viewRaw.format === 'tabs'">
      <label class="text-default text-sm font-medium mb-2 leading-4">Auto Link</label>
      <div class="input-labels tw-input-labels">
        <label><input
          v-model="viewRaw.auto_link"
          type="checkbox"
        > Navigate to the first link automatically</label>
      </div>
    </div>
    <!--
    <h2>Design</h2>
    <p>
      <em>
        Override <a class="green underline">
          theme defaults
        </a> with custom design!
      </em>
    </p>

    <div class="customization-options">
      <DesignPropertyPicker
        design-property="format"
        label-text="format"
        :options="[`button`, `tab`]" />
      <DesignPropertyPicker
        design-property="style"
        label-text="style"
        :options="[`fill`, `outline`]" />
      <DesignPropertyPicker
        v-if="view.design.style === `fill`"
        design-property="fillColor"
        label-text="fill color"
        input-type="color" />
      <DesignPropertyPicker
        v-if="view.design.style === `outline`"
        design-property="outlineColor"
        label-text="outline color"
        input-type="color" />
      <DesignPropertyPicker
        design-property="textColor"
        label-text="text color"
        input-type="color" />
    </div>

    <label class="label-header">Customize Design</label>
    <div class="customization-options">
      <DesignPropertyPicker
        design-property="format"
        label-text="format"
        :options="[`button`, `tab`]" />
      <DesignPropertyPicker
        design-property="style"
        label-text="button style"
        :options="['outline', 'fill']" />
      <DesignPropertyPicker
        v-if="designStyle === `fill`"
        design-property="fillColor"
        label-text="fill color"
        input-type="color"
        description-text="A darker shade is used for the hover state" />
      <DesignPropertyPicker
        v-if="designStyle === `outline`"
        design-property="outlineColor"
        label-text="border color"
        input-type="color" />
      <DesignPropertyPicker
        design-property="textColor"
        label-text="font color"
        input-type="color" />
      <DesignPropertyPicker
        design-property="hoverStyle"
        label-text="hover style"
        :options="[
          {
            label: `Outline color as fill`,
            value: `outline`
          },
          {
            label: `Text color as fill`,
            value: `text`
          }
        ]"
        input-type="radio" />
    </div>
    -->
  </ViewToolbox>
</template>

<script>
import isNil from 'lodash/isNil';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import DesignUtils from '@/components/views/DesignUtils';
import DesignPropertyPicker from '@/components/settings/design/DesignPropertyPicker';

export default {
  name: 'TableSettings',
  components: {
    ViewToolbox,
    DesignPropertyPicker,
  },
  mixins: [
    ViewUtils,
    DesignUtils,
  ],
  computed: {
    designStyle() {
      if (isNil(this.view.design.style)) {
        return this.app.design.menu.style;
      }

      return this.view.design.style;
    },
  },
};
</script>
