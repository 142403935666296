<template>
  <Toolbox
    id="page-rules-toolbox"
    title="Page Rules"
    theme="active"
    back-title="Pages"
    :back-count="1"
    class="no-padding"
  >
    <form
      v-if="page"
      class="mb-24"
      style="margin-bottom: 4em;"
    >
      <p style="margin-bottom: 1em;">
        Add rules to hide views, show messages, or redirect the user based on this page's content.
      </p>
      <Notification v-if="showCorruptedRuleWarning">
        <p>One of your rules below contained fields that were deleted or no longer apply to this page.</p>
        <p>Please inspect your rules carefully and correct any rules that show blank fields, or reach out to support if you believe this is an error.</p>
      </Notification>
      <GroupList
        v-model:items="localRules"
        class="small"
        data-cy="page-rules-list"
        item-title="Page Rule"
        style="font-size: 0.875rem"
        group-list-item-classes="bg-subtle"
      >
        <template #default="{item}">
          <!-- WHEN CRITERIA -->
          <div data-cy="page-rule-item">
            <div>
              <label class="tw-toolbox-label font-medium leading-4 text-sm text-default">When the following criteria is true</label>
              <div v-if="!item.criteria || !item.criteria.length">
                <a
                  class="fuchsia underline no-underline p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group text-base font-medium inline-flex items-center"
                  data-cy="page-rule-add-criteria"
                  @click="onAddCriteria(item)"
                >
                  <Icon
                    class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
                    type="plus-thin"
                  />
                  Add Criteria
                </a>. Currently running with every page.
              </div>
              <div v-else>
                <FieldList
                  :available-fields="availableFields"
                  :object-key="pageObject ? pageObject.key : ''"
                  :items="item.criteria"
                  :can-be-empty="false"
                  rule-list="filters"
                  class="naked"
                  @update:items="onUpdatePageRuleCriteria(item, $event)"
                />
              </div>
            </div>

            <div>
              <label class="tw-toolbox-label text-default text-sm font-medium leading-4">Run the following action</label>
              <select
                v-model="item.action"
                class="text-base py-2 pl-3 leading-5"
                data-cy="page-rule-action-select"
              >
                <option value="hide_views">
                  Hide views
                </option>
                <option value="message">
                  Show a message
                </option>
                <option
                  v-if="showParentPageOption"
                  value="parent_page"
                >
                  Redirect to the parent page
                </option>
                <option
                  v-if="redirectPages.length"
                  value="existing_page"
                >
                  Redirect to an existing page
                </option>
                <option value="url">
                  Redirect to another website URL
                </option>
              </select>
            </div>

            <!-- MESSAGE -->
            <div
              v-if="item.action === `message`"
              key="page-rule-message"
            >
              <label class="tw-toolbox-label text-default text-sm font-medium leading-4">Message to show</label>
              <Redactor
                v-model="item.message"
                :input="item"
                :config="redactorOptions"
              />
              <div class="input-labels margin-bottom level-left top">
                <label class="tw-toolbox-label text-default text-sm font-medium leading-4">Type:&nbsp;&nbsp;</label>
                <div class="tw-input-labels">
                  <label class="text-base text-emphasis font-normal"><input
                    v-model="item.type"
                    type="radio"
                    value="neutral"
                  >Neutral</label>
                  <label class="text-base text-emphasis font-normal"><input
                    v-model="item.type"
                    type="radio"
                    value="confirmation"
                  >Confirmation</label>
                  <label class="text-base text-emphasis font-normal"><input
                    v-model="item.type"
                    type="radio"
                    value="warning"
                  >Warning</label>
                  <label class="text-base text-emphasis font-normal"><input
                    v-model="item.type"
                    type="radio"
                    value="alert"
                  >Red Alert</label>
                </div>
              </div>
              <div class="input-labels tw-input-labels">
                <label class="items-start"><input
                  v-model="item.close_link"
                  type="checkbox"
                > Allow users to close message and ignore</label>
              </div>
            </div>

            <div
              v-if="item.action === `existing_page`"
              key="page-rule-existing-page"
            >
              <label class="tw-toolbox-label text-default text-sm font-medium leading-4">Redirect to</label>
              <select
                v-model="item.existing_page"
                class="text-base py-2 pl-3 leading-5"
                data-cy="page-rule-existing-page"
              >
                <option
                  v-for="redirectPage in redirectPages"
                  :key="redirectPage.key"
                  :value="redirectPage.slug"
                >
                  {{ redirectPage.label || redirectPage.name }}
                </option>
              </select>
            </div>

            <div
              v-if="item.action === `url`"
              key="page-rule-url"
            >
              <label class="tw-toolbox-label text-default text-sm font-medium leading-4">Redirect to the following URL</label>
              <input
                v-model="item.url"
                type="text"
              >
            </div>

            <div
              v-if="item.action === `hide_views`"
              key="page-rule-hide-views"
            >
              <label class="tw-toolbox-label text-default text-sm font-medium leading-4">Select which views to hide</label>
              <div class="input-labels tw-input-labels">
                <label
                  v-for="view in orderedViews"
                  class="text-base text-emphasis font-normal"
                  :key="view.key"
                >
                  <input
                    v-model="item.view_keys"
                    type="checkbox"
                    :value="view.key"
                  > {{ view.name }}
                </label>
              </div>
            </div>
          </div>
        </template>
      </GroupList>
      <div>
        <a
          class="button small green-gray text-base p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group"
          data-cy="page-rule-add-rule"
          @click="onAddListItem"
        >
          <Icon
            class="text-default fill-current group-hover:text-brand-600"
            type="plus-thin"
          /> Add Rule
        </a>
      </div>
    </form>
    <Transition name="slide-up">
      <ToolboxSave
        v-if="rulesHaveChanged"
        data-test="page-rules-toolbox-save"
        @save="onSave"
        @cancel="onCancel"
      />
    </Transition>
  </Toolbox>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import FieldList from '@/components/ui/lists/FieldList';
import GroupList from '@/components/ui/lists/GroupList';
import Icon from '@/components/ui/Icon';
import Notification from '@/components/ui/notifications/Notification';
import Redactor from '@/lib/redactor/VueRedactor';
import Toolbox from '@/components/layout/Toolbox';
import ToolboxSave from '@/components/layout/ToolboxSave';
import RequestUtils from '@/components/util/RequestUtils';
import { getFilledPageGroups } from '@/lib/page/page-groups-helper';

export default {
  name: 'PageRules',
  components: {
    FieldList,
    GroupList,
    Icon,
    Notification,
    Redactor,
    Toolbox,
    ToolboxSave,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      // See setLocalRules() below for an explanation
      showCorruptedRuleWarning: false,
      localRules: [],
      redactorOptions: {
        autoresize: true,
        removeComments: true,
        buttons: [
          'html',
          'formatting',
          'bold',
          'italic',
          'deleted',
          'link',
          'unorderedlist',
          'orderedlist',
          'outdent',
          'indent',
        ],
        plugins: [
          'fontcolor',
          'image_web_link',
        ],
      },
      rulesHaveChanged: false,
    };
  },
  computed: {
    ...mapGetters([
      'activePage',
      'allUserObjects',
      'getField',
      'getStarterPlusChildPagesByObject',
    ]),
    ...mapGetters('page', [
      'startPages',
    ]),
    orderedViews() {
      return getFilledPageGroups(this.page, true).reduce((views, { columns }) => {
        for (const column of columns) {
          views.push(...column.views);
        }

        return views;
      }, []);
    },
    page() {
      return this.activePage;
    },
    pageObject() {
      if (this.page.object) {
        return this.page.getPageObject();
      }

      return false;
    },
    redirectPages() {
      if (this.pageObject) {
        return this.getStarterPlusChildPagesByObject(this.pageObject.key);
      }

      return this.startPages;
    },
    pageViews() {
      return this.page.views.filter((view) => !view.isChildView());
    },
    showParentPageOption() {
      return !this.page.isStartPage() && !this.page.isStartPageWithLogin();
    },
    availableFields() {
      let fields = [];

      // Exclude setting for getConnectedFieldOptions() -- exclude connection to many records
      const excludeConnections = {
        has: 'many',
      };

      log('availableFields == == == ', this.pageObject, this.page.isAuthenticated());

      // If details page, get this page's fields and any connected fields
      if (this.pageObject) {
        fields = [
          ...this.pageObject.getFieldOptions(),
          ...this.pageObject.getConnectedFieldOptions(excludeConnections),
        ];
      }

      // If login page, get fields for any roles that have access
      if (this.page.isAuthenticated()) {
        // If the page only allows specific roles, only include those
        const userObjects = (this.page.hasUserRoleOptions()) ? this.page.getAccountPlusRoleObjects() : this.allUserObjects;

        userObjects.forEach((obj) => {
          fields = [
            ...fields,
            ...obj.getFieldOptionsByRole(),
            ...obj.getConnectedFieldOptions(excludeConnections),
          ];
        });
      }

      return fields;
    },
  },
  watch: {
    page(newVal, oldVal) {
      // Reset our vars for tracking changes
      this.hasInitiatedRules = false;
      this.rulesHaveChanged = false;

      this.setLocalRules();
    },
    localRules: {
      handler(newVal, oldVal) {
        // Show the save button when rules change
        if (this.hasInitiatedRules) {
          this.rulesHaveChanged = true;
        }
      },
      deep: true,
    },
  },
  created() {
    this.setLocalRules();
  },
  methods: {
    setLocalRules() {
      if (!this.page || !this.page.get('rules')) {
        this.localRules = [];

        this.$nextTick(() => {
          this.hasInitiatedRules = true;
        });

        return;
      }

      this.localRules = cloneDeep(this.page.get('rules'));

      // Let's check if any fields exist in the criteria that may have come from a deleted field or an incorrect setting from V2
      // V2 allowed the following: If the page had no object, it would check all views, and allow fields from the first view object.
      // But this means the page has no reference to a record for that object, so these rules can't be applied.
      this.localRules = this.localRules.map((rule) => {
        rule.criteria = rule.criteria.filter((criteria) => {
          const missingField = !this.availableFields.some((field) => field.value === criteria.field);

          if (missingField) {
            this.showCorruptedRuleWarning = true;
          }

          return !missingField;
        });

        // If no valid rule criteria remain, add a default one
        if (!rule.criteria.length) {
          rule.criteria.push(this.defaultCriteria());
        }

        return rule;
      });

      this.$nextTick(() => {
        this.hasInitiatedRules = true;
      });
    },
    defaultItem() {
      const existingPage = this.redirectPages.length ? this.redirectPages[0].slug : '';

      return {
        action: 'message',
        criteria: [
          this.defaultCriteria(),
        ],
        message: '',
        close_link: false,
        existing_page: existingPage,
        view_keys: [],
        url: '',
        type: 'neutral',
      };
    },
    defaultCriteria() {
      let defaultFieldKey = this.availableFields[0].value;

      if (defaultFieldKey.includes('-')) {
        defaultFieldKey = defaultFieldKey.split('-')[1];
      }

      const defaultField = this.getField(defaultFieldKey);

      return {
        field: this.availableFields[0].value,
        operator: defaultField.getRuleOperators()[0],
        value: '',
      };
    },
    onUpdatePageRuleCriteria(rule, newCriteria) {
      rule.criteria = newCriteria;
    },
    onAddListItem() {
      this.localRules.push(this.defaultItem());
    },
    onAddCriteria(item) {
      if (!item.criteria) {
        ({ ...item, criteria: [] });
      }

      item.criteria.push(this.defaultCriteria());
    },
    onSave() {
      // page rules need to set a key so server can return the matching rule
      this.localRules = this.localRules.map((rule, index) => {
        rule.key = `submit_${index}`;

        return rule;
      });

      const ruleUpdates = {
        pageKey: this.page.key,
        rules: this.localRules,
      };

      this.commitRequest({
        validate: () => this.page.validate({
          rules: this.localRules,
        }),
        request: () => this.$store.dispatch('page/api/updateRules', ruleUpdates),
        onSuccess: () => {
          this.$store.commit('clearViewErrors');
          this.$router.push(`/pages/${this.$route.params.pageKey}`)
        },
        onError: (errors) => {
          // Make sure to only show the validation error at the toolbox body level.
          this.errors = [];

          this.$store.commit('setViewErrors', errors);
        },
      });
    },
    onCancel() {
      this.$router.push(`/pages/${this.$route.params.pageKey}`);
    },
  },
};
</script>

<style lang="scss">
#page-rules-toolbox .toolbox-body {
  position: static;
}
</style>
