<script setup>
import {
  computed,
  defineEmits,
  defineProps,
  reactive,
} from 'vue';

const emit = defineEmits([
  'mouse-move',
  'selected',
]);

const props = defineProps({
  fill: {
    type: Number,
    default: 0,
  },
  size: {
    type: Number,
    default: 50,
  },
  index: {
    type: Number,
    required: true,
  },
  activeColor: {
    type: String,
    required: true,
  },
  inactiveColor: {
    type: String,
    required: true,
  },
  borderColor: {
    type: String,
    default: '#999',
  },
  borderWidth: {
    type: Number,
    default: 0,
  },
  spacing: {
    type: Number,
    default: 0,
  },
  customProps: {
    type: Object,
    default: () => ({}),
  },
  rtl: {
    type: Boolean,
    default: false,
  },
});

/* State */

const data = reactive({
  points: [],
  originalWidth: 400,
  originalHeight: 300,
  borders: 0,
  opacity: 0.1,
});

const src = computed(() => props.customProps.src);
const opacityStyle = computed(() => `opacity: ${props.customProps.opacity}`);
const fillPercentage = computed(() => `${props.fill}%`);
const rectX = computed(() => (
  props.rtl ? `${100 - props.fill}%` : 0
));

const width = computed(() => (
  parseInt(props.size, 10) + parseInt(props.borderWidth * data.borders, 10)
));

const height = computed(() => (
  (data.originalHeight / data.originalWidth) * width.value
));
const spacing = computed(() => {
  const spacingWithBorder = props.spacing + (props.borderWidth / 2);

  return `${spacingWithBorder}px`;
});

/* Local Vars */

const fillId = Math.random().toString(36).substring(7);
const fillIdMask = `url(#${fillId})`;

const img = new Image();
img.onload = () => {
  data.originalHeight = img.height;
  data.originalWidth = img.width;
};
img.src = src;

/* Methods */
const getPosition = ($event) => {
  // Calculate position in percentage.
  const width = (92 / 100) * (props.size + props.borderWidth);
  const offset = (props.rtl) ? Math.min($event.offsetX, 45) : Math.max($event.offsetX, 1);
  const position = Math.round((100 / width) * offset);

  return Math.min(position, 100);
};

const mouseMoving = ($event) => {
  emit('mouse-move', {
    event: $event,
    position: getPosition($event),
    id: props.index,
  });
};

const selected = ($event) => {
  emit('selected', {
    id: props.index,
    position: getPosition($event),
  });
};
</script>

<script>
export default {
  name: 'RatingImage',
};
</script>

<template>
  <div :style="{ display: 'inline-block', 'margin-right': spacing }">
    <svg
      :width="width"
      :height="height"
      @mousemove="mouseMoving"
      @click="selected"
    >
      <mask
        :id="fillId"
        x="0"
        y="0"
      >
        <rect
          fill="#fff"
          :width="fillPercentage"
          height="100%"
          :x="rectX"
        />
      </mask>

      <image
        :xlink:href="src"
        :mask="fillIdMask"
        :height="height"
        :width="width"
      />
      <image
        :xlink:href="src"
        :height="height"
        :width="width"
        :style="opacityStyle"
      />
    </svg>
  </div>
</template>
