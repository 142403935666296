<!-- TODO: MOVE TO DESIGN -->
<template>
  <ViewToolbox
    v-if="hasActiveView"
    back-title="Edit Calendar"
    title="Settings"
  >
    <div class="border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Display</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="viewRaw.events.display_type"
            type="radio"
            value="calendar"
          >Calendar</label>
          <label><input
            v-model="viewRaw.events.display_type"
            type="radio"
            value="list"
          >List</label>
        </div>
      </div>
      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Starting Range</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="viewRaw.events.view"
            type="radio"
            value="month"
            data-cy="calendar-range-month"
          >Month</label>
          <label><input
            v-model="viewRaw.events.view"
            type="radio"
            value="agendaWeek"
            data-cy="calendar-range-week"
          >Week</label>
          <label><input
            v-model="viewRaw.events.view"
            type="radio"
            value="agendaDay"
            data-cy="calendar-range-day"
          >Day</label>
        </div>
      </div>
      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Date Field</label>
        <select
          v-model="viewRaw.events.event_field.key"
          class="field-list-field text-base py-2 pl-3 leading-5"
        >
          <option
            v-for="field in dateFields"
            :key="field.key"
            :value="field.key"
          >
            {{ field.name }}
          </option>
        </select>
      </div>
      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Label Field</label>
        <select
          v-model="viewRaw.events.label_field.key"
          class="field-list-field text-base py-2 pl-3 leading-5"
        >
          <option
            v-for="field in object.fields"
            :key="field.key"
            :value="field.key"
          >
            {{ field.name }}
          </option>
        </select>
      </div>
      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Week Start</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="viewRaw.events.week_start"
            type="radio"
            value="sunday"
          >Sunday</label>
          <label><input
            v-model="viewRaw.events.week_start"
            type="radio"
            value="monday"
          >Monday</label>
        </div>
      </div>
      <div class="input-labels tw-input-labels mb-4">
        <label><input
          v-model="viewRaw.events.exclude_weekends"
          type="checkbox"
        >Exclude Weekends</label>
      </div>
      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Starting Time</label>
        <select
          v-model="startingTime"
          class="text-base py-2 pl-3 leading-5"
        >
          <option value="0:00">
            12:00am
          </option>
          <option value="0:30">
            12:30am
          </option>
          <option value="1:00">
            1:00am
          </option>
          <option value="1:30">
            1:30am
          </option>
          <option value="2:00">
            2:00am
          </option>
          <option value="2:30">
            2:30am
          </option>
          <option value="3:00">
            3:00am
          </option>
          <option value="3:30">
            3:30am
          </option>
          <option value="4:00">
            4:00am
          </option>
          <option value="4:30">
            4:30am
          </option>
          <option value="5:00">
            5:00am
          </option>
          <option value="5:30">
            5:30am
          </option>
          <option value="6:00">
            6:00am
          </option>
          <option value="6:30">
            6:30am
          </option>
          <option value="7:00">
            7:00am
          </option>
          <option value="7:30">
            7:30am
          </option>
          <option value="8:00">
            8:00am
          </option>
          <option value="8:30">
            8:30am
          </option>
          <option value="9:00">
            9:00am
          </option>
          <option value="9:30">
            9:30am
          </option>
          <option value="10:00">
            10:00am
          </option>
          <option value="10:30">
            10:30am
          </option>
          <option value="11:00">
            11:00am
          </option>
          <option value="11:30">
            11:30am
          </option>
          <option value="12:00">
            12:00pm
          </option>
          <option value="12:30">
            12:30pm
          </option>
          <option value="13:00">
            1:00pm
          </option>
          <option value="13:30">
            1:30pm
          </option>
          <option value="14:00">
            2:00pm
          </option>
          <option value="14:30">
            2:00pm
          </option>
          <option value="15:00">
            3:00pm
          </option>
          <option value="15:30">
            3:30pm
          </option>
          <option value="16:00">
            4:00pm
          </option>
          <option value="16:30">
            4:30pm
          </option>
          <option value="17:00">
            5:00pm
          </option>
          <option value="17:30">
            5:30pm
          </option>
          <option value="18:00">
            6:00pm
          </option>
          <option value="18:30">
            6:30pm
          </option>
          <option value="19:00">
            7:00pm
          </option>
          <option value="19:30">
            7:30pm
          </option>
          <option value="20:00">
            8:00pm
          </option>
          <option value="20:30">
            8:30pm
          </option>
          <option value="21:00">
            9:00pm
          </option>
          <option value="21:30">
            9:30pm
          </option>
          <option value="22:00">
            10:00pm
          </option>
          <option value="22:30">
            10:30pm
          </option>
          <option value="23:00">
            11:00pm
          </option>
          <option value="23:30">
            11:30pm
          </option>
        </select>
      </div>
      <div class="mb-4">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Ending Time</label>
        <select
          class="text-base py-2 pl-3 leading-5"
          v-model="endingTime"
        >
          <option value="0:30">
            12:30am
          </option>
          <option value="1:00">
            1:00am
          </option>
          <option value="1:30">
            1:30am
          </option>
          <option value="2:00">
            2:00am
          </option>
          <option value="2:30">
            2:30am
          </option>
          <option value="3:00">
            3:00am
          </option>
          <option value="3:30">
            3:30am
          </option>
          <option value="4:00">
            4:00am
          </option>
          <option value="4:30">
            4:30am
          </option>
          <option value="5:00">
            5:00am
          </option>
          <option value="5:30">
            5:30am
          </option>
          <option value="6:00">
            6:00am
          </option>
          <option value="6:30">
            6:30am
          </option>
          <option value="7:00">
            7:00am
          </option>
          <option value="7:30">
            7:30am
          </option>
          <option value="8:00">
            8:00am
          </option>
          <option value="8:30">
            8:30am
          </option>
          <option value="9:00">
            9:00am
          </option>
          <option value="9:30">
            9:30am
          </option>
          <option value="10:00">
            10:00am
          </option>
          <option value="10:30">
            10:30am
          </option>
          <option value="11:00">
            11:00am
          </option>
          <option value="11:30">
            11:30am
          </option>
          <option value="12:00">
            12:00pm
          </option>
          <option value="12:30">
            12:30pm
          </option>
          <option value="13:00">
            1:00pm
          </option>
          <option value="13:30">
            1:30pm
          </option>
          <option value="14:00">
            2:00pm
          </option>
          <option value="14:30">
            2:00pm
          </option>
          <option value="15:00">
            3:00pm
          </option>
          <option value="15:30">
            3:30pm
          </option>
          <option value="16:00">
            4:00pm
          </option>
          <option value="16:30">
            4:30pm
          </option>
          <option value="17:00">
            5:00pm
          </option>
          <option value="17:30">
            5:30pm
          </option>
          <option value="18:00">
            6:00pm
          </option>
          <option value="18:30">
            6:30pm
          </option>
          <option value="19:00">
            7:00pm
          </option>
          <option value="19:30">
            7:30pm
          </option>
          <option value="20:00">
            8:00pm
          </option>
          <option value="20:30">
            8:30pm
          </option>
          <option value="21:00">
            9:00pm
          </option>
          <option value="21:30">
            9:30pm
          </option>
          <option value="22:00">
            10:00pm
          </option>
          <option value="22:30">
            10:30pm
          </option>
          <option value="23:00">
            11:00pm
          </option>
          <option value="23:30">
            11:30pm
          </option>
          <option value="24:00">
            12:00 midnight
          </option>
        </select>
      </div>
      <div class="mb-0">
        <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Feeds</label>
        <div class="input-labels tw-input-labels">
          <label><input
            v-model="viewRaw.events.rss"
            type="checkbox"
          >Show RSS Feed Link</label>
          <label><input
            id="feed_ical"
            v-model="viewRaw.events.ical"
            type="checkbox"
          >Show iCal Download Link</label>
        </div>
      </div>
    </div>

    <Toggle
      id="column-properties-toggle"
      class="margin-top-double mt-2.5"
      :is-open="true"
    >
      <template #title>
        <span>Event Options</span>
      </template>

      <template #content>
        <div class="grid-verticalGaps border border-solid border-subtle border-x-0 border-t-0 pb-6 mb-0">
          <div>
             <div class="input-label tw-input-labels">
              <label><input
                v-model="viewRaw.events.show_details"
                type="checkbox"
              >Click on an event to show details</label>
              <label><input
                v-model="viewRaw.events.allow_add"
                type="checkbox"
              >Click on the calendar to add events</label>
              <label><input
                v-model="viewRaw.events.allow_edit"
                type="checkbox"
              >Drag events to different times</label>
            </div>
          </div>
          <div>
            <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Label Format</label>
            <LabelFormat
              :model-value="viewRaw"
              @update-property:model-value="onUpdateViewProperty"
            />
          </div>
          <div>
            <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Event Colors</label>
            <div class="level-left margin-bottom-half">
              <ColorPicker v-model="eventColorDefault" />&nbsp;default color
            </div>
            <ActionList
              id="map-colors"
              :key="actionListKey"
              v-model:items="viewRaw.events.event_colors"
              :default-item="defaultEventColor"
              :can-be-empty="true"
              :can-sort="true"
              class="hide-add margin-bottom-half"
              @sort="onSortColors"
            >
              <template #default="{ item, itemIndex }">
                <Icon type="drag-handle" />
                <div>
                  <ColorPicker v-model="item.color" />
                </div>
                <FieldListItem
                  :object-key="object.key"
                  :item="item"
                  @update:item="onUpdateEventColor(itemIndex, $event)"
                />
              </template>
            </ActionList>
            <a
              class="button small fuchsia-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 text-base font-medium mt-3 group"
              @click="addCustomColor"
            >
              <Icon
                class="text-default h-4 w-4 mr-1 group-hover:text-brand-400"
                type="plus-thin"
              />
              add custom color
            </a>
          </div>
        </div>
      </template>
    </Toggle>

    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Filter Options</span>
      </template>

      <template #content>
        <Filters
          v-model:filter-type="viewRaw.filter_type"
          v-model:filter-fields="viewRaw.filter_fields"
          v-model:preset-filters="viewRaw.preset_filters"
          v-model:allow-preset-filters="viewRaw.allow_preset_filters"
          v-model:menu-filters="viewRaw.menu_filters"
          v-model:connection-sources="viewRaw.filter_connection_sources"
          :object="object"
        />
      </template>
    </Toggle>

    <Toggle
      :is-open="true"
      class="mt-2.5"
    >
      <template #title>
        <span>Title & Description</span>
      </template>

      <template #content>
        <div class="grid-verticalGaps">
          <div>
            <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Title</label>
            <input
              v-model="viewRaw.title"
              type="text"
            >
          </div>
          <div>
            <label class="text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">Description</label>
            <textarea v-model="viewRaw.description" />
          </div>
        </div>
      </template>
    </Toggle>
  </ViewToolbox>
</template>

<script>
import { mapGetters } from 'vuex';

import ActionList from '@/components/ui/lists/ActionList';
import ColorPicker from '@/components/ui/inputs/ColorPicker';
import FieldListItem from '@/components/ui/lists/FieldListItem';
import Filters from '@/components/views/shared/settings/Filters';
import LabelFormat from '@/components/views/shared/settings/LabelFormat';
import Toggle from '@/components/ui/Toggle';
import ViewToolbox from '@/components/views/ViewToolbox';
import ViewUtils from '@/components/views/ViewUtils';
import Icon from '@/components/ui/Icon';

export default {
  name: 'CalendarSettings',
  components: {
    ActionList,
    ColorPicker,
    Filters,
    FieldListItem,
    LabelFormat,
    Toggle,
    ViewToolbox,
    Icon,
  },
  mixins: [
    ViewUtils,
  ],
  data() {
    return {
      actionListKey: 0,
    };
  },
  computed: {
    ...mapGetters('ui', [
      'leftPanelWidth',
    ]),
    dateFields() {
      return this.object.getFieldsThatStoreDates();
    },
    startingTime: {
      get() {
        return this.viewRaw.events.time_min || '0:00';
      },
      set(newVal) {
        this.viewRaw.events.time_min = newVal;
      },
    },
    endingTime: {
      get() {
        return this.viewRaw.events.time_max || '24:00';
      },
      set(newVal) {
        this.viewRaw.events.time_max = newVal;
      },
    },
    eventColorDefault: {
      get() {
        return this.viewRaw.events.event_color_default || '#3366cc';
      },
      set(newVal) {
        this.viewRaw.events.event_color_default = newVal;
      },
    },
    showDragIcon() {
      return this.leftPanelWidth > 385;
    },
  },
  methods: {
    defaultEventColor() {
      const field = this.object.fields[0];

      return {
        color: '#ff0000',
        field: field.key,
        value: '',
        operator: field.getFirstRuleOperator(),
      };
    },
    addCustomColor() {
      this.viewRaw.events.event_colors.push(this.defaultEventColor());
    },
    onUpdateEventColor(colorItemIndex, newColorData) {
      this.viewRaw.events.event_colors[colorItemIndex] = newColorData;
    },
    onSortColors(items) {
      this.viewRaw.events.event_colors = items;

      this.actionListKey++;
    },
  },
};
</script>
<style lang="scss">
#map-colors {
  li {
    justify-content: unset;
  }

  .field-list-item {
    display: flex;
    flex: 1 1 128px;
    flex-flow: row wrap;
    padding-left: 0.5em;

    & > * {
      flex: 1 1 30% !important;
    }
  }

  .actions {
    padding-left: 4px;
    padding-right: 0;

    .delete-link-button {
      margin-right: 0;
    }
  }
}
</style>
