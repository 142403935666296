import isEmpty from 'lodash/isEmpty';

import Api from '@/lib/api-wrapper';

/**
 * Determines if the current user has app rights to the given application id.
 *
 * @param {string} applicationId
 * @returns {boolean}
 */
export const isUserAuthorizedToAccessApplication = (applicationId) => {
  if (isEmpty(Api.app_rights)) {
    return false;
  }

  return Api.app_rights.some(
    (appRight) => appRight.app_id === applicationId,
  );
};
