import partial from 'lodash/partial';

export default {
  computed: {
    connectionParentOptions() {
      const options = [];
      const { activePage } = this.$store.getters;
      const formObject = this.$store.getters.getObject(this.viewRaw.source.object);

      // ensure that connected fields have relationship properties
      if (!this.field.get('relationship')) {
        const connectedObject = formObject.conns.find((conn) => conn.object === this.field.objectKey);

        this.field.set('relationship', {
          object: this.field.objectKey,
          has: connectedObject.has,
          belongs_to: connectedObject.belongs_to,
        });
      }

      const inputConnectedObject = this.$store.getters.getObject(this.field.get('relationship').object);

      let inputs;
      if (this.viewRaw.type === 'search') {
        inputs = this.view.getFields('inputs');
      } else {
        inputs = this.view.getInputs();
      }

      // check if there's an input on this form or search
      // that can conditionally control this connection
      if (!this.ignoreViewInConnectionParentOptions) {
        inputs.forEach((parentInput) => {
          const fieldKey = parentInput?.field?.key || parentInput?.key;
          if (!fieldKey) {
            return;
          }

          const parentField = this.$store.getters.getField(fieldKey);
          if (!parentField) {
            return;
          }

          if (parentField.type === 'connection') {
            // check if this input's connection field object has any other connection fields to the parentInput's object
            inputConnectedObject.fields.forEach((field) => {
              if (field.type === 'connection') {
                if (field.get('relationship').object === parentField.get('relationship').object) {
                  options.push({
                    connectionKey: field.key,
                    remote: parentField.key,
                    name: parentField.name,
                    type: 'input',
                  }); // local is the form object, remote is the connected
                }
              }
            });

            // check if this input's connected object has any other connection fields to the parentInput's object
            this.$store.getters.getObject(parentField.get('relationship').object).fields.forEach((field) => {
              if (field.type === 'connection') {
                if (field.get('relationship').object === this.field.get('relationship').object) {
                  options.push({
                    connectionKey: field.key,
                    remote: parentField.key,
                    name: parentField.name,
                    type: 'input',
                  }); // local is the form object, remote is the connected
                }
              }
            });
          }
        });
      }

      const formConnectionField = this.$store.getters.getField(this.viewRaw.source.connection_key);

      // Check if this view source is based off the page object.
      // We're looking for an object that both the page object and the input object connect to.
      // This only applies if this view is NOT connected to the logged-in user.
      if (formConnectionField && !this.viewRaw.source.authenticated_user) {
        const formConnectionObjectKey = (this.viewRaw.source.relationship_type === 'foreign') ? formConnectionField.objectKey : formConnectionField.get('relationship').object;

        inputConnectedObject.conns.forEach((conn) => {
          if (conn.object === formConnectionObjectKey && this.field.key !== conn.key) {
            options.push({
              connectionKey: conn.key,
              remote: formConnectionField.key,
              name: formConnectionField.get('name'),
              type: 'scene',
            }); // local is the form object, remote is the connected
          }
        });
      }

      // Check if the object we're selecting has an identical parent connection to this.
      // If so, they may want to limit to those.
      // Example:
      // There are multiple companies and each company has multiple employees and divisions.
      // Connect employee to divisions, displaying just the employee's company divisions, not all
      // the divisions for every company
      // Scenarios:
      // 1. This parent object (company) could be selected in the scene.
      // 2. It could be an edit company, in which case it knows about a company.
      // 3. It could be connected to the logged-in user

      // Forms that update an object that has a parent connection to the same parent object the
      // child connection has.
      if (this.viewRaw.action === 'update') {
        // check all the object connections
        formObject.conns.forEach((conn) => {
          if (conn.relationship_type === 'local' && conn.has === 'one') {
            // see if the parent object has the same connection
            inputConnectedObject.conns.forEach((conn2) => {
              if (conn2.relationship_type === 'local' && conn2.has === 'one' && conn.object === conn2.object) {
                options.push({
                  connectionKey: conn2.key,
                  remote: conn.key,
                  name: conn.name,
                  type: 'record',
                }); // local is the form object, remote is the connected object
              }
            });
          }
        });
      } else {
        // see if the parent object has the same connection
        inputConnectedObject.conns.forEach((conn2) => {
          // is this object in the scene hash?
          let parentSlug = this.$store.getters.activePage.slug;

          while (parentSlug) {
            const parentPage = this.$store.getters.getPageBySlug(parentSlug);

            if (!parentPage) {
              parentSlug = false;

              continue;
            }

            if (parentPage.object && parentPage.object === conn2.object) {
              parentSlug = false;

              const pageObjectName = this.$store.getters.getObject(parentPage.object).inflections.singular;

              let connectionName;
              if (conn2.relationship_type === 'local') {
                connectionName = `${pageObjectName} (${inputConnectedObject.name} > ${conn2.name})`;
              } else {
                connectionName = `${pageObjectName} (${pageObjectName} > ${conn2.name})`;
              }

              options.push({
                connectionKey: conn2.key,
                remote: 'null',
                name: connectionName,
                type: 'scene',
              }); // local is the form object, remote is the connected object
            } else {
              parentSlug = parentPage.parent;
            }
          }
        });
      }

      // connected to logged in user!
      if (activePage.isAuthenticated()) {
        activePage.getAccountPlusRoleObjects().forEach((userObject) => {
          // check direct connections
          userObject.conns.forEach((conn) => {
            if (((conn.relationship_type === 'local' && (conn.belongs_to === 'many' || conn.has === 'many')) || (conn.relationship_type === 'foreign' && (conn.belongs_to === 'many' || conn.has === 'many'))) && conn.object === inputConnectedObject.key) {
              const connectionName = (conn.relationship_type === 'local') ? conn.name : `${inputConnectedObject.inflections.singular} > ${conn.name}`;
              options.push({
                connectionKey: conn.key,
                remote: 'null',
                name: `${userObject.inflections.singular} (${connectionName})`,
                type: 'user',
                userObject: userObject.key,
              }); // local is the form object, remote is the connected object
            }
          });

          // check all parents: user > connections
          userObject.conns.forEach((conn) => {
            if ((conn.relationship_type === 'local' && conn.has === 'one') || (conn.relationship_type === 'foreign' && conn.has === 'many')) {
              // see if the connected input object has the same connection
              inputConnectedObject.conns.forEach((conn2) => {
                if (conn2.relationship_type === 'local' && conn.object === conn2.object) {
                  let connectionName = conn.name;

                  if (conn.relationship_type !== 'local') {
                    connectionName = this.$store.getters.getObject(conn2.object).inflections.plural;
                  }

                  options.push({
                    connectionKey: conn2.key,
                    remote: conn.key,
                    name: `${userObject.inflections.singular} > ${connectionName} (${conn2.name})`,
                    type: 'user',
                    userObject: userObject.key,
                  }); // local is the form object, remote is the connected object
                }
              });
            }
          });

          // check all grandparents: user > parent > connections
          userObject.conns.forEach((conn) => {
            const connectedObject = this.$store.getters.getObject(conn.object);

            connectedObject.conns.forEach((conn2) => {
              if (conn2.relationship_type === 'local' && conn2.has === 'many' && conn2.object === inputConnectedObject.key) {
                options.push({
                  connectionKey: conn2.key,
                  remote: conn.key,
                  name: `${userObject.inflections.singular} > ${connectedObject.name} (${conn2.name})`,
                  type: 'user',
                  userObject: userObject.key,
                }); // local is the form object, remote is the connected object
              }
            });
          });
        });
      }

      if (!options.length) {
        return [];
      }

      // convert these to value/label pairs for <select /> consumption
      const mapToSelectable = partial(
        this.mapConnectionParentOptionToSelectable,
        inputConnectedObject.inflections.plural,
      );

      const firstSelectable = {
        label: `all the ${inputConnectedObject.inflections.singular} records`,
        value: '',
      };

      return [
        firstSelectable,
        ...options.map(mapToSelectable),
      ];
    },
  },
  methods: {
    mapConnectionParentOptionToSelectable(inflection, option) {
      let label = `${inflection} connected to this form's ${option.name}`;

      if (option.type !== 'input') {
        label = `${inflection} connected to`;

        if (option.type === 'user') {
          label += ` the logged-in ${option.name}`;
        } else if (option.type === 'scene') {
          label += ` this page's ${option.name}`;
        } else if (option.type === 'record') {
          label += ` this record's ${option.name}`;
        }
      }

      return {
        label,
        value: `${option.connectionKey}-${option.remote}-${option.type}`,
      };
    },

    mapConnectionParentOptionsFromSelectable(selectableOption) {
      const [connectionKey, remoteKey, type] = selectableOption.split('-');

      return {
        connectionKey,
        remoteKey,
        type,
      };
    },
  },
};
