<template>
  <Teleport :to="`#modal-${zIndex}`">
    <section
      class="modal_bg"
      v-bind="$attrs"
      @mousedown.self="onMouseDownBackground"
      @scroll.self="onScrollBackground"
      @mouseup.self="onMouseUpBackground"
    >
      <div
        class="modal rounded-lg border-0 bg-default"
        :class="classList"
      >
        <div
          data-cy="modal-title"
          :class="titleClasses"
        >
          <slot
            v-if="showTitle"
            name="title"
          >
            <h1 class="text-xl">
              {{ title }}
            </h1>
          </slot>
          <button
            v-if="showClose"
            data-cy="close-modal"
            class="modal_close relative inset-0 p-0 hover:bg-transparent h-6 w-6"
            @click="closeModal"
          >
            <Icon
              type="close"
              class="modal_closeIcon h-5 w-5 text-default"
            />
          </button>
        </div>
        <p
          v-if="description"
          class="mb-0 p-6 pt-2 text-xs text-default"
        >
          {{ description }}
        </p>
        <div
          class="modal_body m-0 px-6"
          :class="{ 'pb-6': !hasFooter }"
        >
          <slot />
        </div>
        <div
          v-if="hasFooter"
          class="modal_footer border-0 m-0 p-6"
        >
          <slot name="footer" />
        </div>
      </div>
    </section>
  </Teleport>
</template>

<script>
import isNil from 'lodash/isNil';
import Icon from '@/components/ui/Icon';

export default {
  name: 'Modal',
  components: {
    Icon,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: [
        String,
        undefined,
      ],
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    back: {
      type: [
        String,
        undefined,
      ],
      default: undefined,
    },

    /**
       * Portal-Vue limits ability to put classes into anything but the root element
       * of the component, this allows bypassing and adding styles to the modal itself
       * @prop {array}
       */
    classes: {
      type: Array,
      default: () => [],
    },
    zIndex: {
      type: Number,
      default: 1,
    },
    size: {
      type: [
        String,
        undefined,
      ],
      default: undefined,
      validator(value) {
        return ['tiny', 'small', 'smallish', 'medium', 'large', 'full-screen'].includes(value);
      },
    },
    level: {
      type: [
        String,
        undefined,
      ],
      default: undefined,
    },
    titleSize: {
      type: String,
      default: 'large',
    },
    titleCenter: {
      type: Boolean,
      default: false,
    },
    hasSidebar: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    preventBackgroundClickToClose: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      backgroundMouseDown: false,
      backgroundHasScrolledFromClick: false,
    };
  },
  computed: {
    hasFooter() {
      return !isNil(this.$slots.footer);
    },
    classList() {
      const classList = [
        ...this.classes,
      ];

      if (this.size) {
        classList.push(this.size);
      }

      if (this.level) {
        classList.push(`level${this.level}`);
      }

      if (this.hasSidebar) {
        classList.push('has-sidebar');
      }

      if (this.titleSize === 'small') {
        classList.push(`modal_title-${this.titleSize}`);
      }

      if (this.isCentered) {
        classList.push('center');
      }

      return classList;
    },
    titleClasses() {
      return [
        'modal_title',
        'rounded-t-xl',
        'p-6',
        'border-0',
        'bg-transparent',
        'text-emphasis',
        'font-medium',
        'flex',
        'flex-row',
        'items-start',
        'justify-between',
        `modal_title-${this.titleSize}`,
        {
          'pb-0': this.description,
          'modal_title-hidden': !this.showTitle,
          'modal_title-center': this.titleCenter,
        },
      ];
    },
  },
  methods: {
    onMouseDownBackground() {
      this.backgroundMouseDown = true;
    },
    onScrollBackground() {
      // Track if this scroll is from a mouse dragging a scrollbar
      if (this.backgroundMouseDown) {
        this.backgroundHasScrolledFromClick = true;
      }
    },
    onMouseUpBackground() {
      // Ignore background clicks
      if (this.preventBackgroundClickToClose) {
        return;
      }

      // Ignore mouse clicks that originated from the modal and not the bg
      if (!this.backgroundMouseDown) {
        return;
      }

      // Reset our mouse down tracker
      this.backgroundMouseDown = false;

      // Ignore mouse clicks from dragging the scrollbar
      if (this.backgroundHasScrolledFromClick) {
        this.backgroundHasScrolledFromClick = false;
        return;
      }

      this.closeModal();
    },
    closeModal() {
      if (!this.showClose) {
        return;
      }

      // we can't just use router.back because the modal itself could have many child routes
      if (!isNil(this.back)) {
        this.$router.push(this.back);
      }

      this.$emit('close');
    },
  },
};
</script>
