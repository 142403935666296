class SlideToggle {
  constructor(domElement, options = {}) {
    this.el = domElement;
    this.trigger = options.trigger;
    this.isOpen = options.isOpen || false;

    this.el.style.overflow = 'hidden';

    if (!this.isOpen) {
      this.el.style.maxHeight = '0px';
      this.el.style.visibility = 'hidden';
    } else {
      // mimics the styles set during `toggleOpen`
      this.el.style.maxHeight = 'none';
      this.el.style.overflow = 'visible';
    }

    if (!this.trigger) {
      return;
    }

    this.trigger.classList.toggle('open', this.isOpen);

    this.trigger.onclick = () => this.toggle();

    this.trigger.setAttribute('data-has-toggle', true);
  }

  toggle(open) {
    if (open !== undefined) {
      this.isOpen = open;
    } else {
      this.isOpen = !this.isOpen;
    }

    this.el.dispatchEvent(new CustomEvent('toggle', {
      bubbles: true,
      detail: {
        open: this.isOpen,
      },
    }));

    log('UI.toggle', this.isOpen, this.el);

    // toggle open
    if (this.isOpen) {
      this.toggleOpen(this.el);

      return;
    }

    // toggle close
    this.toggleClose(this.el);
  }

  toggleOpen(domElement) {
    if (this.trigger) {
      this.trigger.classList.add('open');
    }

    const transitionEnd = function (event) {
      if (event.propertyName === 'max-height') {
        domElement.style.maxHeight = 'none';
        domElement.style.overflow = 'visible';
        domElement.removeEventListener('transitionend', transitionEnd);
      }
    };

    domElement.addEventListener('transitionend', transitionEnd, false);

    let height = 0;

    Array.from(domElement.children).forEach((child) => {
      let elHeight = parseFloat(child.getBoundingClientRect().height);

      // add margins
      const styles = window.getComputedStyle(child);

      elHeight += parseFloat(styles.marginBottom) + parseFloat(styles.marginTop);

      height += elHeight;
    });

    domElement.style.visibility = 'visible';
    domElement.style.maxHeight = `${height}px`;
  }

  toggleClose(domElement) {
    if (this.trigger) {
      this.trigger.classList.remove('open');
    }

    let height = 0;

    Array.from(domElement.children).forEach((child) => {
      height += Number(child.getBoundingClientRect().height);

      // add margins
      const styles = window.getComputedStyle(child);

      height += parseFloat(styles.marginBottom) + parseFloat(styles.marginTop);
    });

    domElement.style.maxHeight = `${height}px`;
    domElement.style.overflow = 'hidden';

    const transitionEnd = function (event) {
      if (event.propertyName === 'max-height') {
        domElement.style.visibility = 'hidden';
        domElement.removeEventListener('transitionend', transitionEnd);
      }
    };

    domElement.addEventListener('transitionend', transitionEnd, false);

    this.nextFrame(() => {
      domElement.style.maxHeight = '0px';
    });
  }

  nextFrame(callback) {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(callback);
    });
  }
}

class ScrollTo {
  constructor(domElement, options = {}) {
    this.el = domElement;
    this.increment = 20;
    this.duration = 300;

    // these get reset with each go()
    this.currentTime = 20;
    this.start = 0;
    this.change = 0;
  }

  easeInOutQuad(tVariable, bVariable, cVariable, dVariable) {
    tVariable /= dVariable / 2;

    if (tVariable < 1) {
      return cVariable / 2 * tVariable * tVariable + bVariable;
    }

    tVariable--;

    return -cVariable / 2 * (tVariable * (tVariable - 2) - 1) + bVariable;
  }

  animateScroll() {
    this.currentTime += this.increment;
    const val = this.easeInOutQuad(this.currentTime, this.start, this.change, this.duration);

    this.el.scrollTop = val;

    if (this.currentTime >= this.duration) {
      return;
    }

    return setTimeout(() => this.animateScroll(), this.increment);
  }

  go (destination) { // eslint-disable-line

    this.start = this.el.scrollTop;
    this.change = destination - this.start;
    this.currentTime = 20;

    return this.animateScroll(this.start, this.change);
  }
}

export default {
  SlideToggle,
  ScrollTo,
};
