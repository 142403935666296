<template>
  <div
    class="accordion"
    :class="{ 'is-collapsed': !expanded }"
  >
    <button
      ref="trigger"
      type="button"
      class="drawerItem accordion_trigger"
      @click="toggleExpanded"
    >
      <slot name="description" />
      <Icon
        type="chevron-single"
        class="accordion_indicator"
      />
    </button>

    <div
      ref="expandable"
      class="accordion_content"
    >
      <slot name="items" />
    </div>
  </div>
</template>

<script>
import UIUtil from '@/util/UI';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      expanded: false,
    };
  },
  mounted() {
    this.initSlideToggle(this.expanded);
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    initSlideToggle(openState) {
      this.$nextTick(() => {
        /* eslint-disable no-unused-vars */
        this.slideToggle = new UIUtil.SlideToggle(this.$refs.expandable, {
          trigger: this.$refs.trigger,
          isOpen: openState,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  transition: background-color .2s ease-out;

  &.is-collapsed {
    background-color: transparent;

    .accordion_trigger {
      box-shadow: none;
    }

    .accordion_indicator {
      transform: rotate(0deg);
    }
  }

  &_item {
    &:hover {
      background-color: $gray100;
    }
  }

  &_indicator {
    flex: 0 0 auto;
    height: 20px;
    margin-right: -10px;
    transition: transform .2s ease-out;
    transform: rotate(180deg);
  }

  &_content {
    max-height: 0px;

    transition: max-height .2s ease-out;
  }
}

.drawerItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: unset;
  border-bottom: unset;
  padding: 8px 16px;

  span {
    flex: 1 1 auto;
  }
}
</style>
