<template>
  <div :class="inputWrapperClasses">
    <template v-if="label && inputType !== `checkbox` && inputType !== `image`">
      <header class="m-0 mb-2">
        <label class="m-0 text-default text-sm font-medium mb-2 leading-4">{{ label }}</label>
        <DesignPropertyPickerDropdown
          v-if="!isKnackDesignDefault && showRevert"
          :is-app-level-design="true"
          :has-header="false"
          :default-value="defaultDesignPropertyValue"
          @revert="onRevert(defaultDesignPropertyValue)"
          @replace="onReplace(designPropertyValue)"
        />
      </header>
      <p
        v-if="helpText"
        class="m-0 mb-2 text-subtle text-xs"
      >
        {{ helpText }}
      </p>
    </template>

    <!-- We still might want a label for checkboxes -->
    <header
      v-if="showCheckboxHeader"
      class="m-0 mb-2"
    >
      <label>{{ label }}</label>
    </header>

    <span v-if="inputType === `choice`">
      <ChoicePicker
        v-model="designPropertyValue"
        capitalize="true"
        :choices="options"
        :default-choice="defaultDesignPropertyValue"
        :is-disabled="isDisabled"
        choice-classes="px-2 py-1 text-sm"
      />
    </span>

    <span v-else-if="inputType === `color`">
      <ColorPicker
        v-model="designPropertyValue"
        :show-text-value="true"
        :default-value="defaultDesignPropertyValue"
        :data-feature="dataFeature"
      />
    </span>

    <span v-else-if="inputType === `dropdown`">
      <select
        v-model="designPropertyValue"
        class="w-full"
      >
        <option
          v-for="option in options"
          :key="option.label"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </span>

    <span
      v-else-if="inputType === `radio`"
      class="block"
    >
      <Radios
        v-model="designPropertyValue"
        :options="options"
      />
    </span>

    <span
      v-else-if="inputType === `checkbox`"
      class="block"
    >
      <label class="kn-checkbox_wrapper items-baseline">
        <input
          v-model="designPropertyValue"
          type="checkbox"
          class="mb-0 mr-2"
        >
        <span class="checkbox-label text-base text-emphasis text-normal">{{ descriptionText }}</span>
        <Icon
          v-if="showPreviewIcon"
          class="live-preview-icon"
          type="live-app-preview"
          @mouseenter="showPreview"
          @mouseleave="showPreview"
        />

        <DesignPropertyPickerDropdown
          v-if="!isKnackDesignDefault && showRevert"
          :is-app-level-design="true"
          :has-header="false"
          :default-value="defaultDesignPropertyValue"
          @revert="onRevert(defaultDesignPropertyValue)"
          @replace="onReplace(designPropertyValue)"
        />
      </label>
      <p
        v-if="helpText"
        class="m-0 mt-1 text-subtle text-xs"
      >{{ helpText }}</p>
    </span>

    <template v-else-if="inputType === `text`">
      <TextInput
        v-model="designPropertyValue"
        :placeholder="inputPlaceholderText"
        :full="fullWidthTextInput"
      />
    </template>

    <div v-else-if="inputType === `image`">
      <header>
        <label>{{ label }}</label>
      </header>
      <span class="design-image-wrapper">
        <ImageInput
          v-model="localImageValue"
          theme="primary"
          @update:modelValue="onUpdateImage"
        />
        <div class="flex-center align-start">
          <span class="design-description-text w-auto not-italic text-left text-default text-xs">
            JPG, GIF, and PNG files are accepted with a 5MB file size limit
          </span>
        </div>
      </span>
    </div>

    <span v-else-if="inputType === `icon`">
      <IconPicker
        :ignore-alignment="ignoreIconAlignment"
        :icon="designPropertyValue"
        @update:icon="designPropertyValue = $event"
      />
    </span>

    <span v-else>{{ designPropertyValue }}</span>

    <span
      v-if="showDescriptionText"
      class="design-description-text mt-2 text-subtle text-sm font-normal not-italic"
    >
      {{ descriptionText }}
      <Icon
        v-if="showPreviewIcon"
        class="live-preview-icon"
        type="live-app-preview"
        @mouseenter="showPreview"
        @mouseleave="showPreview"
      />
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import isNil from 'lodash/isNil';
import { v3DesignSchemaDefault } from '@knack/style-engine';

import Icon from '@/components/ui/Icon';
import Radios from '@/components/renderer/form/inputs/Radios';
import ChoicePicker from '@/components/ui/inputs/ChoicePicker';
import ColorPicker from '@/components/ui/inputs/ColorPicker';
import DesignPropertyPickerDropdown from '@/components/settings/design/DesignPropertyPickerDropdown';
import TextInput from '@/components/builder/inputs/Text';
import IconPicker from '@/components/ui/inputs/IconPicker';
import ImageInput from '@/components/builder/inputs/Image';
import RequestUtils from '@/components/util/RequestUtils';
import { logEvent } from '@/lib/segment-helper';

export default {
  components: {
    ChoicePicker,
    ColorPicker,
    DesignPropertyPickerDropdown,
    Icon,
    IconPicker,
    ImageInput,
    Radios,
    TextInput,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    modelValue: {
      type: [
        String,
        Object,
        Array,
        Number,
        Boolean,
      ],
      default: null,
    },
    customDesignObject: {
      type: Object,
      default: undefined,
    },
    designProperty: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
      default: '',
    },
    fullWidthTextInput: {
      type: Boolean,
      default: false,
    },
    helpText: {
      type: String,
      default: '',
    },
    ignoreIconAlignment: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: 'choice',
    },
    inputPlaceholderText: {
      type: String,
      default: 'Type Icon Name',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInline: {
      type: Boolean,
      default: false,
    },
    descriptionText: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default() {
        return [
          'small',
          'medium',
          'large',
        ];
      },
    },
    showRevert: {
      type: Boolean,
      default: false,
    },
    showPreviewIcon: {
      type: Boolean,
      default: false,
    },
    dataFeature: {
      type: String,
      default: '',
    },
  },
  emits: [
    'input',
    'preview',
  ],
  data() {
    return {
      localValue: this.modelValue,
      localImageValue: null,
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    designObject() {
      return this.customDesignObject || this.app.design;
    },
    defaultDesignObject() {
      return this.customDesignObject || v3DesignSchemaDefault;
    },
    inputWrapperClasses() {
      return {
        'design-input-wrapper': true,
        'design-input-wrapper--inline mb-2': this.isInline,
        'design-input-wrapper--disabled': this.isDisabled,
        '[&:not(:last-child)]:mb-4 last:mb-0': !this.isInline,
      };
    },
    isKnackDesignDefault() {
      const defaultValue = this.defaultDesignPropertyValue;
      const currentValue = get(this.designObject, this.designProperty);

      return (this.isComplexOptions)
        ? currentValue === defaultValue.value
        : currentValue === defaultValue;
    },
    isComplexOptions() {
      return this.options[0].value !== undefined;
    },
    label() {
      if (isEmpty(this.labelText)) {
        return '';
      }

      return this.labelText;
    },
    designPropertyValue: {
      get() {
        if (!isNil(this.localValue)) {
          return this.localValue;
        }

        return get(this.designObject, this.designProperty);
      },
      set(value) {
        if (!isNil(this.localValue)) {
          this.localValue = value;

          this.$emit('input', value);
          return;
        }

        // sync v3 design setting
        set(this.designObject, this.designProperty, value);
      },
    },
    defaultDesignPropertyValue() {
      const defaultValue = get(this.defaultDesignObject, this.designProperty);

      return this.isComplexOptions
        ? this.options.find((option) => option.value === defaultValue)
        : defaultValue;
    },

    /**
     * If a checkbox includes a label, show it like other components
     */
    showCheckboxHeader() {
      return this.inputType === 'checkbox' && this.label;
    },

    /**
     * Show the description text, unless this is a checkbox which handles the
     * description differently
     */
    showDescriptionText() {
      return this.descriptionText && this.inputType !== 'checkbox';
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  created() {
    if (this.inputType === 'image') {
      this.localImageValue = this.designPropertyValue;
    }
  },
  methods: {
    onRevert() {
      set(this.designObject, this.designProperty, get(this.defaultDesignObject, this.designProperty));
    },
    onUpdateImage(file) {
      if (typeof file === 'string') {
        // if we have a string the asset already exists do nothing
        return;
      }

      if (file.value === null) {
        // removing property, set value to null & logo url to empty string
        this.designPropertyValue = file.value;

        set(this.app, 'logo', '');

        return;
      }

      this.commitRequest({
        request: () => window.Knack.Api.uploadWebFileForLogo(file.asset),
        onSuccess: async (response) => {
          logEvent('logo_changed');

          // save sanitized filename to value & set design schema
          file.value.filename = response.logo;

          const imageMeta = await this.getImageMeta(response.logo_url);

          this.designPropertyValue = {
            ...file.value,
            width: imageMeta.width,
            height: imageMeta.height,
            url: response.logo_url,
          };

          set(this.app, 'logo', response.logo_url);
        },
      });
    },

    getImageMeta(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();

        // On successful loading of the image
        img.onload = () => resolve(img);

        // On error when loading the image
        img.onerror = () => reject('There was a problem with the image uploaded.');

        img.src = url;
      });
    },

    /**
     * Emit a `preview` event whenever the live-app-preview icon is hovered.
     * Best to treat this as a toggle.
     */
    showPreview() {
      this.$emit('preview');
    },
  },
};
</script>

<style lang="scss" scoped>
.helpIcon {
  top: -2px;
  position: relative;
}

.design-input-wrapper {
  margin-bottom: 1.25rem;

  &--inline {
    margin-bottom: 0;
  }

  &--disabled {
    > span:not(.design-description-text) {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  & > input {
    margin-right: 5px;
  }

  & header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  & header label {
    @include font-body;
    margin-right: 2px;
    font-weight: 600;
    text-transform: capitalize;
  }

  & > span {
    display: flex;
    align-items: center;
  }

  .disabled-color-preview {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }

  .horizontal-icon {
    width: 20px;
  }

  .text-value {
    margin-left: 5px;
  }

  .design-description-text {
    @include font-caption;
    font-style: italic;
    color:$gray600;
    margin-top: 4px;
  }

  & > .design-image-wrapper {
    display: block;
  }
}

.flex-center {
  display: flex;
  justify-content: center;

  & > span {
    width: 70%;
    text-align:center;
  }
}

.kn-checkbox_wrapper {
  display: flex;
  align-items: flex-start;

  input[type="checkbox"] {
    margin-top: 0.2em;
  }

  .helpIcon {
    top: 1px;
    margin-left: 0;
  }
}

.live-preview-icon {
  width: 18px;
  height: 18px;
  align-self: center;
  color: #8E9293;
}
</style>
