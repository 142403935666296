<template>
  <div class="kn-submit">
    <BuilderButton
      class="border-0 rounded-lg order-2"
      type="submit"
      theme="confirm"
      size="lg"
      :is-loading="isLoading"
      :disabled="isDisabled"
      @click.prevent="$emit('submit')"
    >
      {{ buttonText }}
    </BuilderButton>
    <div
      v-if="showCancel"
      class="kn-submit__cancel order-1 ml-0 w-max"
      @click="$emit('cancel')"
    >
      <button class="bg-transparent border-0 p-3 leading-4 text-emphasis text-base font-medium mb-0">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import BuilderButton from '@/components/ui/BuilderButton';

export default {
  components: {
    BuilderButton,
    Icon,
  },
  props: {
    buttonText: {
      type: String,
      default: 'Submit',
    },
    primary: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    isLoading: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: '',
    },
  },
  emits: [
    'cancel',
    'submit',
  ],
  computed: {
    isDisabled() {
      return this.disabled || this.isLoading;
    },
  },
};
</script>
<style lang="scss" scoped>
.kn-submit {
  // padding-left: 19px;
  text-align: center;
  margin-top: 10px;
  padding-bottom: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &__cancel {
    cursor: pointer;
    margin-left: 10px;
  }

  .kn-content button, .kn-content input, .kn-content select, .kn-content textarea, .kn-form button, .kn-form input, .kn-form select, .kn-form textarea {
    margin: 0;
  }

  /*
    Set the color of the icon
  */
  svg {
    fill: #FFFFFF;
  }
}

.submit-buttons {
  .kn-submit {
    margin: 0;
    padding: 0;
    justify-content: flex-start;
  }
}
</style>

<style lang="scss">
  .kn-submit .icon-close path {
    fill: #aaa;
  }

  .kn-submit__cancel {
    width: 2em;
    color: gray;
  }

  #kn-table-cell-popover .form-wrapper {

    .knButton {
      border-radius: .25em;
    }

    .knButton__content {

      font-size: .9rem;
    }
  }
</style>
