<template>
  <div id="settings-index">
    <h1>Settings</h1>
    <ul id="settings-links">
      <li>
        <RouterLink to="/settings/app">
          <h3>App Settings</h3>
          <Icon type="settings-app-settings" />
          <p>App name, timezone, security, and more.</p>
        </RouterLink>
      </li>
      <li>
        <RouterLink to="settings/design">
          <h3>Live App Design</h3>
          <Icon type="settings-live-app-design" />
          <p>Logos, colors, and themes</p>
        </RouterLink>
      </li>
      <li>
        <RouterLink to="settings/users">
          <h3>User Logins</h3>
          <Icon type="settings-user-logins" />
          <p>User settings & security</p>
        </RouterLink>
      </li>
      <li>
        <RouterLink to="settings/ecommerce">
          <h3>E-Commerce</h3>
          <Icon type="settings-ecommerce" />
          <p>Payment processors &amp; settings</p>
        </RouterLink>
      </li>
      <li>
        <RouterLink to="settings/api">
          <h3>API &amp; Code</h3>
          <Icon type="settings-api-and-code" />
          <p>API access, custom JavaScript & CSS</p>
        </RouterLink>
      </li>
      <li>
        <RouterLink to="settings/embeds">
          <h3>Embeds</h3>
          <Icon type="settings-embeds" />
          <p>Embed this app into your websites</p>
        </RouterLink>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      transitionName: 'slide-left',
    };
  },
  computed: {
    hasActiveUpdates() {
      return false;
    },
  },
};
</script>

<style lang="scss">
#settings-index {
  padding: 2em 3em;
  position: absolute;
  width: 100%;
  height: 100%;

  h1 {
    color: #d26c12;
  }
.st0{fill:#FFFFFF;}
.st1{fill:#1a8e5f;}
.st2{fill:#1a8e5f;}
.st3{fill: #d4e5d4;stroke: #1a8e5f; stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
}

#settings-links {
  margin-top: 1em;

  li {
    margin-right: 1.5em;
    margin-bottom: 1em;
    float: left;
    transition: all 200ms ease-out;

    a {
      display: block;
      background-color: #eff1f6;
      border-radius: .5em;
      padding: .5em 1em;
      float: left;
      width: 180px;
      height: 165px;
      position: relative;
      cursor: pointer;
    }

    a > * {
       position: absolute;
    }

    svg {
       width: 64px;
       height: 64px;
       top: 12px;
       left: 55px;
       transition: all 200ms ease-out;
    }

    h3 {
      line-height: 1.75em;
      top: 80px;
      left: 0; right: 0;
      text-align: center;
      color: #136b47;
      font-weight: 500;
      font-size: 1.1em;
    }

    p {
      top: 112px;
      left: 1em; right: 1em;
      font-size: .9em;
      line-height: 135%;
      text-align: center;
      color: #2c3e50;
    }

    &:hover {
      background-color: #e6e8ec;
      svg {
        margin-top: -3px;
      }

      h3 {
        font-weight: 600;
      }
    }

    h3 {
      text-align: center;
    }
  }
}
</style>
